import { NgModule, NgZone } from '@angular/core';
import { MessageEncoder } from './message-encoder';
import { MyPhoneService } from './myphone.service';
import { WEB_SOCKET_FACTORY, WebSocketFactory } from './websocket-factory';
import { Logger } from './logger';
import { BASE_PATH, LogoutApiService, MyPhoneApiService } from '@api';
import { LocalStorageService } from 'ngx-webstorage';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedMyphoneService } from '@webclient/myphone/shared-myphone.service';
import { DataTransportService } from '@webclient/myphone/data-transport.service';
import { WorkerTransportService } from '@webclient/myphone/worker-transport.service';
import { isSharedWorkerEnabled } from '@webclient/myphone/shared-worker-state';
import { sharedWorkerFactory } from './shared-worker-factory';
import { SHARED_WORKER_FACTORY } from '@webclient/myphone/myphone-tokens';
import { ExtendedSwPushService } from '@webclient/notifications/extended-sw-push.service';
import { TokenService } from '@webclient/auth/token.service';

function dataTransportFactory(
    httpClient: HttpClient,
    storage: LocalStorageService,
    _encoder: MessageEncoder,
    zone: NgZone,
    workerFactory: SharedWorker
) {
    if (isSharedWorkerEnabled(storage)) {
        return new WorkerTransportService(_encoder, zone, workerFactory);
    }
    else {
        return new DataTransportService(_encoder, httpClient);
    }
}

export function webSocketFactory() {
    return (url: string) => new WebSocket(url);
}

export function myPhoneServiceFactory(
    storage: LocalStorageService,
    http: HttpClient,
    router: Router,
    zone: NgZone,
    _encoder: MessageEncoder,
    myPhoneApiService: MyPhoneApiService,
    logoutApiService: LogoutApiService,
    dataService: DataTransportService,
    webSocketFactory : WebSocketFactory, logger: Logger, route: ActivatedRoute,
    basePath: string, sharedWorker: SharedWorker, swPush: ExtendedSwPushService, tokenService: TokenService) {
    return isSharedWorkerEnabled(storage) ?
        new SharedMyphoneService(router, zone, storage, dataService, logger, basePath, sharedWorker) :
        new MyPhoneService(http, router, zone, _encoder, myPhoneApiService, logoutApiService, dataService, swPush, storage, tokenService, webSocketFactory, logger, route, basePath);
}

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [Logger, MessageEncoder,
        {
            provide: SHARED_WORKER_FACTORY,
            deps: [LocalStorageService],
            useFactory: sharedWorkerFactory
        },
        {
            provide: DataTransportService,
            deps: [
                HttpClient,
                LocalStorageService,
                MessageEncoder,
                NgZone,
                SHARED_WORKER_FACTORY
            ],
            useFactory: dataTransportFactory
        },
        {
            provide: WEB_SOCKET_FACTORY,
            useFactory: webSocketFactory
        },
        {
            provide: MyPhoneService,
            deps: [
                LocalStorageService,
                HttpClient,
                Router,
                NgZone,
                MessageEncoder,
                MyPhoneApiService,
                LogoutApiService,
                DataTransportService,
                WEB_SOCKET_FACTORY,
                Logger,
                ActivatedRoute,
                BASE_PATH,
                SHARED_WORKER_FACTORY,
                ExtendedSwPushService,
                TokenService
            ],
            useFactory: myPhoneServiceFactory
        }
    ],
})
export class MyPhoneModule {
}
