import { from, throwError } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { fromFetch } from 'rxjs/fetch';

export function basePost(data: {link: string, basePath: string, body: BodyInit, headers: Headers }) {
    return fromFetch(new URL(`/MyPhone/${data.link}`, data.basePath).href,
        {
            method: 'POST',
            headers: data.headers,
            body: data.body
        }).pipe(switchMap(response => {
        if (response.ok) {
            return from(response.blob());
        }
        else {
            const options = {
                status: response.status,
                statusText: response.statusText,
                url: response.url
            };
            return throwError(() => new HttpErrorResponse(options));
        }
    }));
}
