import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { DialogComponent } from '@webclient/modal/dialog';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog';
import { TranslateModule } from '@ngx-translate/core';
import { FieldsModule } from '@webclient/fields';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ValidationErrorDirective, ValidationErrorsComponent } from 'ngx-valdemort';
import { ModalButtons } from '@webclient/modal/message-box';
import { DnSelectComponent } from '@webclient/standalones/dn-select';
import { NgIf, NgSwitchCase } from '@angular/common';
import { FormModel } from 'ngx-mf';
import { Observable, throwError } from 'rxjs';
import { MakeCallUserRecordGreetingRequestBody, UsersApiService } from '@xapi';
import { XapiErrorService } from '@office/xapi-error.service';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { RequestRecordVMGreetingFromPhone } from '@myphone';

type CstaForm = FormModel<Omit<MakeCallUserRecordGreetingRequestBody, 'dn'> & { dn: string | null }>;

@Component({
    selector: 'app-prompt-csta-recording',
    template: `
        <app-modal-dialog header="_i18n.RecordMediaPromptFile"
                          [formGroup]="recordingCstaForm"
                          [buttons]="buttons" (submitted)="onSubmit()"
                          [submit$]="submit$"
                          [validateBeforeSubmit]="validateBeforeSubmit"
        >
            <p data-qa="message">
                {{ (isAdminView ? '_i18n.RecordCstaPromptText' : '_i18n.RecordCstaPromptTextSelf') | translate }}
            </p>
            <field-input
                formControlName="filename"
                label="_i18n.RecordMediaPromptFileInfo"
                maxlength="45"
                data-qa="file-name-recording"
                placeholder="_i18n.AudioFileName"
            >
                <val-errors controlName="filename">
                    <ng-template valError='required'>{{'_i18n.FileNameIsRequired' | translate}}</ng-template>
                    <ng-template valError='pattern'>{{'_i18n.FileNameCannotContainFollowingCharacters' | translate }}</ng-template>
                    <ng-template valError="invalid">{{'_i18n.VoicemailGreetingsMsgInvalidFileName' | translate}}</ng-template>
                </val-errors>
            </field-input>
            <field-wrapper *ngIf="isAdminView" label="_i18n.ExtensionNumber" data-qa="csta-dest-ext">
                <app-dn-select formControlName="dn"
                               [isObject]="false"
                               [clearable]="false"
                               [searchDnTypes]="['Extension']">
                </app-dn-select>
                <val-errors controlName="dn">
                </val-errors>
            </field-wrapper>
        </app-modal-dialog>
    `,
    imports: [
        ModalDialogComponent,
        TranslateModule,
        FieldsModule,
        ReactiveFormsModule,
        ValidationErrorDirective,
        ValidationErrorsComponent,
        DnSelectComponent,
        NgSwitchCase,
        NgIf
    ],
    standalone: true,
})
export class PromptCstaRecordingComponent extends DialogComponent implements OnInit {
    readonly buttons = ModalButtons.OkCancel;
    dn: string;
    id: number;
    isAdminView?: boolean | null;

    recordingCstaForm: CstaForm;
    validateBeforeSubmit: () => boolean;

    constructor(
        private usersApiService: UsersApiService,
        private xapiErrorService: XapiErrorService,
        private cd: ChangeDetectorRef,
        private myPhoneSvc: MyPhoneService
    ) {
        super();
        this.recordingCstaForm = new FormGroup<CstaForm['controls']>({
            filename: new FormControl('sample.wav', {
                nonNullable: true,
                validators: [Validators.required, Validators.pattern('^[^<>:"\/\\?*|]*$'), Validators.maxLength(45)],
            }),
            dn: new FormControl<string | null>(null, { validators: [Validators.required] })
        });
        this.validateBeforeSubmit = this.createDialogValidate(this.recordingCstaForm);
    }

    ngOnInit() {
        if (!this.isAdminView) {
            this.recordingCstaForm.controls.dn.disable();
        }
        else if (this.dn) {
            this.recordingCstaForm.controls.dn?.patchValue(this.dn);
        }
    }

    submit$ = (): Observable<unknown> => {
        const val = this.recordingCstaForm.value;
        if (this.isAdminView) {
            if (!this.id || !val.dn) {
                return throwError(() => new Error('Id or DN is not provided to make request'));
            }
            return this.usersApiService.makeCallUserRecordGreeting({
                id: this.id,
                makeCallUserRecordGreetingRequestBody: {
                    dn: val.dn,
                    filename: val.filename
                } }).pipe(
                this.handleError(this.xapiErrorService, this.cd, this.recordingCstaForm)
            );
        }
        else {
            return this.myPhoneSvc.get(new RequestRecordVMGreetingFromPhone({ FileName: val.filename })).pipe(
                this.handleError(this.xapiErrorService, this.cd, this.recordingCstaForm)
            );
        }
    };
}
