import { ContactDb, IContactDb } from './contact-db';
import { AppContact } from './contact';
import { Observable } from 'rxjs';
import { ResponseSystemParameters } from '@myphone';

interface BridgeItem {
    prefix?: string;
    contacts: ContactDb;
}

export class BridgesContactDb implements IContactDb {
    readonly _bridges : { [id: string] : BridgeItem } = {};

    public constructor(private systemParameters$?: Observable<ResponseSystemParameters>) {
    }

    public addPhone(bridgeId: string, prefix: string, phoneNumber: string, contact: AppContact) {
        let container = this._bridges[bridgeId];
        if (!container) {
            container = { contacts: new ContactDb(this.systemParameters$), prefix };
            this._bridges[bridgeId] = container;
        }
        container.contacts.addPhone(phoneNumber, contact);
    }

    public findByEmail(email: string): AppContact | undefined {
        for (const bridge of Object.values(this._bridges)) {
            const contact = bridge.contacts.findByEmail(email);
            if (contact) {
                return contact;
            }
        }
        return undefined;
    }

    public findPhone(phoneNumber: string, hintBridgeId?: string, includeHiddenContacts?: boolean): AppContact | undefined {
        if (hintBridgeId) {
            const bridge = this._bridges[hintBridgeId];
            if (!bridge) {
                return undefined;
            }

            // A phone number might be prefixed or not
            // First suppose it's prefixed
            const contact = bridge.contacts.findPhone(phoneNumber, undefined, includeHiddenContacts);

            // Now let's add prefix if contact still not found
            return contact || (bridge.prefix ? bridge.contacts.findPhone(bridge.prefix + phoneNumber, undefined, includeHiddenContacts) : undefined);
        }

        for (const bridge of Object.values(this._bridges)) {
            const contact = bridge.contacts.findPhone(phoneNumber, undefined, includeHiddenContacts);
            if (contact) {
                return contact;
            }
        }
        return undefined;
    }
}
