import type { CheckOption } from '@webclient/fields/types';
import { EnumFilterValue } from '@office/standalones/odata-search';

export enum CallType {
    All = 0,
    OnlyAnswered = 1,
    OnlyUnanswered = 2,
}

const callTypeOptions: CheckOption<CallType>[] = [
    { value: CallType.All, label: '_i18n.ReportsCallTypeAll' },
    { value: CallType.OnlyAnswered, label: '_i18n.ReportsCallTypeOnlyAnswered' },
    { value: CallType.OnlyUnanswered, label: '_i18n.ReportsCallTypeOnlyUnanswered' },
];

export interface CallTypeFilterParams {
    callType: CallType | null
}

export function newCallTypeFilterValue() {
    return new EnumFilterValue('_i18n.ReportsCallType', 'callType', callTypeOptions);
}
