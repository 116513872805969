<ng-select [items]="groupMembers$ | async"
           bindValue="Number"
           [placeholder]="selectedNumber ? '' : indeterminate ? (indeterminatePlaceholder | translate) : ((placeholder || '_i18n.SearchOrEnterNumber') |translate) + (showPlaceholderEllipsis ? '...' : '')"
           [clearable]="clearable && !!selectedNumber"
           [searchable]="true"
           (open)="open()"
           [disabled]="disabled"
           [loading]="loading"
           (search)="searching($event)"
           [searchFn]="customSearchFn"
           [virtualScroll]="true"
           (close) ="close()"
           (clear)="clearSearch()"
           (scrollToEnd)="onScrollToEnd()"
           [(ngModel)]="selectedNumber" (change)="onItemSelected($event)"
           data-qa="select">
    <ng-template ng-label-tmp ng-option-tmp let-item="item">
        <div class="d-flex align-items-center">
            <span class="text-truncate" [title]="getDnDisplay(item)">
                {{getDnDisplay(item)}}
            </span>
        </div>
    </ng-template>
</ng-select>
