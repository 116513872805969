import { Observable } from 'rxjs';
import { MyCall } from '../phone/mycall';
import { PushChatMessage } from '@webclient/notifications/push-chat-message';

export const callNotificationDisplayTimeout = 30000;
export const chatNotificationDisplayTimeout = 10000;
export const actionAnswer = 'answer';
export const actionDecline = 'decline';

export class LocalConnectionAction {
    action: string;
    localConnectionId?: number;
    callId?: number;

    constructor(init?: Partial<LocalConnectionAction>) {
        Object.assign(this, init);
    }
}

export interface NotificationServiceImpl {
    createCallNotification(myCall: MyCall, icon: string, silent: boolean): void;
    removeCallNotification(myCall: MyCall): void;
    createChatNotification(message: PushChatMessage, silent: boolean): void;
    removeChatNotification(conversationId?: number): void;

    readonly notificationActions$: Observable<LocalConnectionAction>;
}
