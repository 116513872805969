import type { AbstractControl, FormControl } from '@angular/forms';
import { FormControlDirective, FormControlName, FormGroupDirective, NgControl, NgModel } from '@angular/forms';
import { Injector } from '@angular/core';

// memorizing ids allows to use shorter values
// estimated amount below 200 values
const usedIds: Record<string, boolean> = {};

export function getUniqueFieldId(prefix = 'field'): string {
    let id: string;

    do {
        id = prefix + '-' + Math.random().toString(16).substring(2, 6);
    } while (usedIds[id]);

    usedIds[id] = true;
    return id;
}

export function setControlEnabled<T extends AbstractControl>(control: T, enabled: boolean | null | undefined, emitEvent = false) {
    if (enabled) {
        control.enable({ emitEvent });
    }
    else {
        control.disable({ emitEvent });
    }
}

export function setControlsEnabled<T extends AbstractControl>(controls: T[], enabled: boolean | null | undefined, emitEvent = false) {
    controls.forEach(c => setControlEnabled(c, enabled, emitEvent));
}

/** Get the access to form control, bound to value accessor. Do not use control for manual value change. */
export function getComponentControl(injector: Injector): FormControl | undefined {
    const injectedControl = injector.get(NgControl, null);

    if (!injectedControl) {
        return undefined;
    }

    switch (injectedControl.constructor) {
        case NgModel: {
            return (injectedControl as NgModel).control;
        }
        case FormControlName: {
            return injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        }
        default: {
            return (injectedControl as FormControlDirective).form;
        }
    }
}
