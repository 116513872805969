/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { LinkMyGroupPartnerRequestBody } from '../model/link-my-group-partner-request-body';
// @ts-ignore
import { PbxGroup } from '../model/pbx-group';
// @ts-ignore
import { PbxODataErrorsODataError } from '../model/pbx-o-data-errors-o-data-error';
// @ts-ignore
import { PbxResellerInfo } from '../model/pbx-reseller-info';
// @ts-ignore
import { PbxRestrictions } from '../model/pbx-restrictions';
// @ts-ignore
import { PbxRightsCollectionResponse } from '../model/pbx-rights-collection-response';
// @ts-ignore
import { PbxUserGroupCollectionResponse } from '../model/pbx-user-group-collection-response';
// @ts-ignore
import { ReplaceMyGroupLicenseKeyRequestBody } from '../model/replace-my-group-license-key-request-body';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetMyGroupRequestParams {
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface GetMyGroupPartnerInfoRequestParams {
    /** Usage: resellerId&#x3D;{resellerId} */
    resellerId: string;
}

export interface LinkMyGroupPartnerRequestParams {
    linkMyGroupPartnerRequestBody?: LinkMyGroupPartnerRequestBody;
}

export interface ListMyGroupMembersRequestParams {
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ListMyGroupRightsRequestParams {
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ReplaceMyGroupLicenseKeyRequestParams {
    replaceMyGroupLicenseKeyRequestBody?: ReplaceMyGroupLicenseKeyRequestBody;
}

export interface UpdateMyGroupRequestParams {
    /** New property values */
    pbxGroup: PbxGroup;
}


@Injectable({
  providedIn: 'root'
})
export class MyGroupApiService {

    protected basePath = '/xapi/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get MyGroup
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyGroup(requestParameters: GetMyGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxGroup>;
    public getMyGroup(requestParameters: GetMyGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxGroup>>;
    public getMyGroup(requestParameters: GetMyGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxGroup>>;
    public getMyGroup(requestParameters: GetMyGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyGroup`;
        return this.httpClient.request<PbxGroup>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getMyGroupQuery(requestParameters: GetMyGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyGroupQuery(requestParameters: GetMyGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyGroupQuery(requestParameters: GetMyGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyGroupQuery(requestParameters: GetMyGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/MyGroup`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke function GetMyGroupPartnerInfo
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyGroupPartnerInfo(requestParameters: GetMyGroupPartnerInfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxResellerInfo>;
    public getMyGroupPartnerInfo(requestParameters: GetMyGroupPartnerInfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxResellerInfo>>;
    public getMyGroupPartnerInfo(requestParameters: GetMyGroupPartnerInfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxResellerInfo>>;
    public getMyGroupPartnerInfo(requestParameters: GetMyGroupPartnerInfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const resellerId = requestParameters.resellerId;
        if (resellerId === null || resellerId === undefined) {
            throw new Error('Required parameter resellerId was null or undefined when calling getMyGroupPartnerInfo.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyGroup/Pbx.GetMyGroupPartnerInfo(resellerId=${this.configuration.encodeParam({name: "resellerId", value: resellerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
        return this.httpClient.request<PbxResellerInfo>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getMyGroupPartnerInfoQuery(requestParameters: GetMyGroupPartnerInfoRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyGroupPartnerInfoQuery(requestParameters: GetMyGroupPartnerInfoRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyGroupPartnerInfoQuery(requestParameters: GetMyGroupPartnerInfoRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyGroupPartnerInfoQuery(requestParameters: GetMyGroupPartnerInfoRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const resellerId = requestParameters.resellerId;
        if (resellerId === null || resellerId === undefined) {
        throw new Error('Required parameter resellerId was null or undefined when calling getMyGroupPartnerInfo.');
        }

    return `/MyGroup/Pbx.GetMyGroupPartnerInfo(resellerId=${this.configuration.encodeParam({name: "resellerId", value: resellerId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
}

    /**
     * Invoke function GetRestrictions
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyGroupRestrictions(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxRestrictions>;
    public getMyGroupRestrictions(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxRestrictions>>;
    public getMyGroupRestrictions(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxRestrictions>>;
    public getMyGroupRestrictions(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyGroup/Pbx.GetRestrictions()`;
        return this.httpClient.request<PbxRestrictions>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getMyGroupRestrictionsQuery(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyGroupRestrictionsQuery(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyGroupRestrictionsQuery(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyGroupRestrictionsQuery(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {

    return `/MyGroup/Pbx.GetRestrictions()`;
}

    /**
     * Invoke action LinkMyGroupPartner
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public linkMyGroupPartner(requestParameters: LinkMyGroupPartnerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public linkMyGroupPartner(requestParameters: LinkMyGroupPartnerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public linkMyGroupPartner(requestParameters: LinkMyGroupPartnerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public linkMyGroupPartner(requestParameters: LinkMyGroupPartnerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const linkMyGroupPartnerRequestBody = requestParameters.linkMyGroupPartnerRequestBody;

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyGroup/Pbx.LinkMyGroupPartner`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: linkMyGroupPartnerRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public linkMyGroupPartnerQuery(requestParameters: LinkMyGroupPartnerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public linkMyGroupPartnerQuery(requestParameters: LinkMyGroupPartnerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public linkMyGroupPartnerQuery(requestParameters: LinkMyGroupPartnerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public linkMyGroupPartnerQuery(requestParameters: LinkMyGroupPartnerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const linkMyGroupPartnerRequestBody = requestParameters.linkMyGroupPartnerRequestBody;

    return `/MyGroup/Pbx.LinkMyGroupPartner`;
}

    /**
     * Get Members from MyGroup
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMyGroupMembers(requestParameters: ListMyGroupMembersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxUserGroupCollectionResponse>;
    public listMyGroupMembers(requestParameters: ListMyGroupMembersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxUserGroupCollectionResponse>>;
    public listMyGroupMembers(requestParameters: ListMyGroupMembersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxUserGroupCollectionResponse>>;
    public listMyGroupMembers(requestParameters: ListMyGroupMembersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyGroup/Members`;
        return this.httpClient.request<PbxUserGroupCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listMyGroupMembersQuery(requestParameters: ListMyGroupMembersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyGroupMembersQuery(requestParameters: ListMyGroupMembersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyGroupMembersQuery(requestParameters: ListMyGroupMembersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyGroupMembersQuery(requestParameters: ListMyGroupMembersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/MyGroup/Members`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Get Rights from MyGroup
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMyGroupRights(requestParameters: ListMyGroupRightsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxRightsCollectionResponse>;
    public listMyGroupRights(requestParameters: ListMyGroupRightsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxRightsCollectionResponse>>;
    public listMyGroupRights(requestParameters: ListMyGroupRightsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxRightsCollectionResponse>>;
    public listMyGroupRights(requestParameters: ListMyGroupRightsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyGroup/Rights`;
        return this.httpClient.request<PbxRightsCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listMyGroupRightsQuery(requestParameters: ListMyGroupRightsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyGroupRightsQuery(requestParameters: ListMyGroupRightsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyGroupRightsQuery(requestParameters: ListMyGroupRightsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyGroupRightsQuery(requestParameters: ListMyGroupRightsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/MyGroup/Rights`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke action ReplaceMyGroupLicenseKey
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public replaceMyGroupLicenseKey(requestParameters: ReplaceMyGroupLicenseKeyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public replaceMyGroupLicenseKey(requestParameters: ReplaceMyGroupLicenseKeyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public replaceMyGroupLicenseKey(requestParameters: ReplaceMyGroupLicenseKeyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public replaceMyGroupLicenseKey(requestParameters: ReplaceMyGroupLicenseKeyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const replaceMyGroupLicenseKeyRequestBody = requestParameters.replaceMyGroupLicenseKeyRequestBody;

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyGroup/Pbx.ReplaceMyGroupLicenseKey`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: replaceMyGroupLicenseKeyRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public replaceMyGroupLicenseKeyQuery(requestParameters: ReplaceMyGroupLicenseKeyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public replaceMyGroupLicenseKeyQuery(requestParameters: ReplaceMyGroupLicenseKeyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public replaceMyGroupLicenseKeyQuery(requestParameters: ReplaceMyGroupLicenseKeyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public replaceMyGroupLicenseKeyQuery(requestParameters: ReplaceMyGroupLicenseKeyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const replaceMyGroupLicenseKeyRequestBody = requestParameters.replaceMyGroupLicenseKeyRequestBody;

    return `/MyGroup/Pbx.ReplaceMyGroupLicenseKey`;
}

    /**
     * Invoke action UnlinkMyGroupPartner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlinkMyGroupPartner(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public unlinkMyGroupPartner(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public unlinkMyGroupPartner(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public unlinkMyGroupPartner(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyGroup/Pbx.UnlinkMyGroupPartner`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public unlinkMyGroupPartnerQuery(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public unlinkMyGroupPartnerQuery(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public unlinkMyGroupPartnerQuery(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public unlinkMyGroupPartnerQuery(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {

    return `/MyGroup/Pbx.UnlinkMyGroupPartner`;
}

    /**
     * Update MyGroup
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMyGroup(requestParameters: UpdateMyGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateMyGroup(requestParameters: UpdateMyGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateMyGroup(requestParameters: UpdateMyGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateMyGroup(requestParameters: UpdateMyGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const pbxGroup = requestParameters.pbxGroup;
        if (pbxGroup === null || pbxGroup === undefined) {
            throw new Error('Required parameter pbxGroup was null or undefined when calling updateMyGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyGroup`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pbxGroup,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public updateMyGroupQuery(requestParameters: UpdateMyGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateMyGroupQuery(requestParameters: UpdateMyGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateMyGroupQuery(requestParameters: UpdateMyGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateMyGroupQuery(requestParameters: UpdateMyGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const pbxGroup = requestParameters.pbxGroup;
        if (pbxGroup === null || pbxGroup === undefined) {
        throw new Error('Required parameter pbxGroup was null or undefined when calling updateMyGroup.');
        }

    return `/MyGroup`;
}

}
