import {
    AfterViewInit, Component, Input, ViewChild
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'progress-modal',
    template: `
        <div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
             aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div id="modalProgress" class="modal-dialog" [class.modal-lg]='size==="lg"' [class.modal-sm]='size==="sm"'>
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 class="modal-title" data-qa="modal-title">{{header | translate}}</h4>
                    </div>
                    <div class="modal-body">
                        <progressbar [max]="max" [value]="value">{{value}}/{{max}}</progressbar>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class ProgressModalComponent implements AfterViewInit {
    @Input()
    header = '';

    @Input()
    max: number;

    // Modal size
    @Input()
    size = '';

    @Input()
    value: number;

    @ViewChild('childModal', { static: false })
    childModal!: ModalDirective;

    ngAfterViewInit() {
        this.childModal.show();
    }
}
