<div class="player" data-qa="audio-player">
    <ng-container *ngIf="errorMessage ?? false">
        <span class="error">{{errorMessage! | translate}}</span>
    </ng-container>

    <ng-container *ngIf="!(errorMessage ?? false)">
        <button type="button" class="btn btn-plain rounded-circle" (click)="onPlayPause()" data-qa="on-pause">
            <i *ngIf="playButton" app-play-solid-icon></i>
            <i *ngIf="!playButton" app-pause-solid-icon></i>
        </button>
        <span class="px-1" data-qa="timeline">{{current}} / {{length ?? '0:00'}}</span>
        <div class="track-segment" #timeline (click)="onTimelineClick($event)" data-qa="on-timeline-click" role="button">
            <div class="line">
                <div class="lineProgress" #progress></div>
            </div>
        </div>
        <button *ngIf="showResetUploadButtons" type="button" class="btn btn-plain" (click)="handleAction(AudioPlayerAction.reset)" title="{{'_i18n.Reset' | translate }}" data-qa="reset">
            <i app-rotate-left-solid-icon></i>
        </button>
        <button *ngIf="showResetUploadButtons" type="button" class="btn btn-plain" (click)="handleAction(AudioPlayerAction.upload)" title="{{'_i18n.Upload' | translate }}" data-qa="upload">
            <i app-upload-solid-icon></i>
        </button>
    </ng-container>

    <button
        type="button"
        *ngIf="showDownloadButtons ?? download.observed"
        class="btn btn-plain"
        (click)="handleAction(AudioPlayerAction.download)"
        title="{{'_i18n.Download' | translate }}"
        data-qa="download">
        <i app-download-solid-icon></i>
    </button>
</div>
