<field-wrapper [description]="description" [label]="label" [inlineLabel]="inlineLabel" class="mb-0" data-qa="prompt-selector">
<div class="d-flex gap-2 flex-grow-1 mw-0 flex-wrap" [ngClass]="{'flex-column': !iconButtons}">
        <ng-select
            #ngSelect
            [formControl]="formControl"
            [items]="prompts$ | async"
            [clearable]="clearable"
            [bindValue]="matchedProperty"
            [searchable]="true"
            [searchFn]="search"
            [placeholder]="indeterminate ? (indeterminatePlaceholder | translate) : ''"
            data-qa="select">
            <ng-template ng-label-tmp let-item="item">
                <ng-container *ngIf="typify(item) as prompt">
                    {{(prompt.DisplayName | translate) || getFallbackDisplayName(prompt)}}
                </ng-container>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <div class="d-flex align-items-center gap-2" *ngIf="typify(item) as prompt">
                    <span class="flex-grow-1 text-truncate">{{(prompt.DisplayName | translate) || getFallbackDisplayName(prompt)}}</span>
                    <a
                        *ngIf="!restrictedAccess && prompt.FileLink"
                        class="btn btn-plain-sm"
                        app-arrow-to-bottom-solid-icon
                        download
                        (click)="downloadFile(prompt, $event)">
                    </a>
                    <a
                        *ngIf="!restrictedAccess && prompt.CanBeDeleted"
                        class="btn btn-plain-sm"
                        app-trash-alt-solid-icon
                        (click)="deletePromptFromMenu($event, prompt)">
                    </a>
                </div>
            </ng-template>
        </ng-select>

    <div *ngIf="!restrictedAccess" class="buttons d-flex flex-row gap-2" [ngClass]="{ iconic: iconButtons }">
        <input type="file" hidden accept="audio/x-wav" #file (change)="onFileSelected(file.files)">
        <div
            [tooltip]="'_i18n.MaxAmountOfPromptsReached'|translate"
            triggers="pointerenter:pointerleave"
            [isDisabled]="!maxPromptsReached">
            <button
                type="button"
                class="btn btn-border"
                [disabled]="disabled || maxPromptsReached"
                (click)="uploadPrompt()"
                data-qa="upload">
                <span class="svg-sm" app-arrow-alt-circle-up-solid-icon></span>
                <span *ngIf="!iconButtons">{{'_i18n.Upload'|translate}}</span>
            </button>
        </div>

        <button
            type="button"
            class="btn btn-border"
            data-qa="delete"
            [title]="'_i18n.Delete'|translate"
            (click)="deleteSelectedPrompt()"
            [disabled]="!canDeleteSelectedPrompt">
            <span class="svg-sm" app-trash-alt-solid-icon></span>
            <span *ngIf="!iconButtons">{{'_i18n.Delete'|translate}}</span>
        </button>
        <div
            *ngIf="isRecordingAllowed"
            [tooltip]="'_i18n.MaxAmountOfPromptsReached'|translate"
            triggers="pointerenter:pointerleave"
            [isDisabled]="!maxPromptsReached">
            <button
                type="button"
                data-qa="record"
                [disabled]="(disabled || maxPromptsReached) || (isMicrophoneDisabled$ | async)"
                class="btn btn-border"
                (click)="record()">
                <span class="svg-sm" app-microphone-alt-solid-icon></span>
                <span *ngIf="!iconButtons">{{'_i18n.Record'|translate}}</span>
            </button>
        </div>
        <button
            *ngIf="isCstaRecordingAllowed"
            type="button"
            class="btn btn-border csta-record"
            data-qa="record_csta"
            (click)="recordCsta()"
            >
            <div style="position: relative">
                <span class="svg-sm" app-phone-office-solid-icon></span>
                <span style="position: absolute; width: 12px; height: 12px; top: 10px; left: 15px" app-microphone-alt-solid-icon></span>
            </div>
            <span class="ms-2" *ngIf="!iconButtons">{{'_i18n.IpPhoneRecordLabel'|translate}}</span>
        </button>
    </div>
</div>
    <val-errors [control]="formControl">
        <ng-template valError="required">{{'_i18n.PromptIsRequired' | translate}}</ng-template>
        <ng-template valError="missing">{{'_i18n.PromptIsMissing' | translate}}</ng-template>
        <ng-template valError="ExtNotSaved">{{ '_i18n.PromptExtensionIsNotSaved' | translate}}</ng-template>
    </val-errors>
</field-wrapper>
