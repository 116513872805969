import { Injectable } from '@angular/core';
import { TokenService } from '@webclient/auth/token.service';
import { BehaviorSubject, map, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, share, switchMap } from 'rxjs/operators';
import {
    decodeToken,
    getRoles,
    hasMyGroupRole, MyToken,
} from '@webclient/auth/token-funcs';
import { publishRef } from '@webclient/rx-share-utils';
import { Wtf } from '@office/admin-utils';

@Injectable({
    providedIn: 'root'
})
export class TokenInfoService {
    public roles$: Observable<string[]>;
    public myToken$: Observable<MyToken>;
    /// This is a MC style token that targets common tenant
    public readonly hasMyGroupRole$: Observable<boolean>;
    public readonly refresh$ = new BehaviorSubject(true);

    constructor(private tokenService: TokenService) {
        const accessToken$: Observable<string | undefined> = this.tokenService.accessToken$.pipe(
            catchError(() => of(undefined))
        );

        const token$ = this.refresh$.pipe(
            switchMap(() => accessToken$),
            map((token : string | undefined) =>
                decodeToken(token)
            ),
            // Token should be returned even if refCount zero
            share({
                connector: () => new ReplaySubject<any>(1),
                // resetOnRefCountZero: false
            }),
        );

        this.roles$ = token$.pipe(
            map((token : any | undefined) =>
                (token ? getRoles(token) : [])
            ),
        );

        this.myToken$ = token$.pipe(
            map(token => {
                const roles = token ? getRoles(token) : [];

                return {
                    singleCompany: roles.includes('SingleCompany'),
                    id: token?.jti || Wtf,
                    machineAdmin: roles.includes('MachineAdmin'),
                    roles,
                    globalAdmin: roles.includes('GlobalAdmin'),
                    outboundRules: roles.includes('Trunks'),
                    paid: roles.includes('Paid'),
                    userName: token?.unique_name ?? '',
                    myRole: token ? token.MaxRole : 'users',
                    adminSection: roles.some(role =>
                        ['Users', 'Trunks', 'Reports', 'Groups.Create', 'PhoneSystemAdmin', 'Groups.ReadWrite'].includes(role) && token.MaxRole !== 'receptionists'
                    ),
                    audit: roles.some(role => ['Admin', 'GlobalAdmin'].includes(role)),
                    admin: roles.includes('Admin'),
                    groups: roles.includes('Groups.Create'),
                    reports: roles.includes('Reports'),
                    callHandling: roles.includes('PhoneSystemAdmin'),
                    trunks: roles.includes('Trunks'),
                    officeHours: roles.includes('Groups.Create') || roles.includes('Groups.ReadWrite'),
                    users: roles.includes('Users'),
                    enterprise: roles.includes('Enterprise')
                };
            }),
            publishRef()
        );

        this.hasMyGroupRole$ = this.roles$.pipe(
            map(hasMyGroupRole),
        );
    }

    getNewAccessToken() {
        // TODO this causes double token request on login
        this.tokenService.invalidate();
        this.refresh$.next(true);
    }
}
