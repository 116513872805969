import {
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { extraHeaders } from '@webclient/extra-headers';

@Injectable()
export class XSRFInterceptor implements HttpInterceptor {
    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Don't bypass i18n
        if (!/^i18n\/.*/.test(request.url)) {
            request = request.clone({
                setHeaders: extraHeaders
            });
        }
        return next.handle(request);
    }
}
