function setDefaultCodec(mLine : string, payload : string) {
    const elements = mLine.split(' ');
    const newLine = elements.slice(0, 3);
    newLine.push(payload);
    for (let i = 3; i < elements.length; i++) {
        if (elements[i] !== payload) {
            newLine.push(elements[i]);
        }
    }
    return newLine.join(' ');
}

function findLineInRange(sdpLines : string[], startLine : number, endLine : number, prefix : string, substr : string) {
    const realEndLine = endLine !== -1 ? endLine : sdpLines.length;
    for (let i = startLine; i < realEndLine; ++i) {
        if (sdpLines[i].indexOf(prefix) === 0) {
            if (!substr || sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
                return i;
            }
        }
    }
    return null;
}

function findLine(sdpLines : string[], prefix : string, substr : string) {
    return findLineInRange(sdpLines, 0, -1, prefix, substr);
}

function getCodecPayloadTypeFromLine(sdpLine : string) {
    const pattern = new RegExp('a=rtpmap:(\\d+) [a-zA-Z0-9-]+\\/\\d+');
    const result = sdpLine.match(pattern);
    return result && result.length === 2 ? result[1] : null;
}

function getCodecPayloadType(sdpLines : string[], codec : string) {
    const index = findLine(sdpLines, 'a=rtpmap', codec);
    return index ? getCodecPayloadTypeFromLine(sdpLines[index]) : null;
}

export function maybePreferCodec(sdp : string, type : string, codec : string) {
    if (!codec) {
        // trace("No preference on " + str + ".");
        return sdp;
    }
    // trace("Prefer " + str + ": " + codec);
    const sdpLines = sdp.split('\r\n');
    const mLineIndex = findLine(sdpLines, 'm=', type);
    if (mLineIndex === null) {
        return sdp;
    }
    const payload = getCodecPayloadType(sdpLines, codec);
    if (payload) {
        sdpLines[mLineIndex] = setDefaultCodec(sdpLines[mLineIndex], payload);
    }
    sdp = sdpLines.join('\r\n');
    return sdp;
}
