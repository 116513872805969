import { SoundService } from './sound.service';
import { ChatUnhandledMessageService } from './chat-unhandled-message.service';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { AsideComponent } from './aside/aside.component';
import { NavComponent } from './aside/nav.component';
import { LayoutComponent } from './layout.component';
import { ProfileImageModule } from '../profile-image/profile-image.module';
import { WebmeetingModule } from '../webmeeting/webmeeting.module';
import { PhoneModule } from '../phone/phone.module';
import { MyPhoneModule } from '../myphone/myphone.module';
import { IdleService } from './idle.service';
import { WebRTCModule } from '../webrtc/webrtc.module';
import { ChatToasterModule } from '../chat-toaster/chat-toaster.module';
import { QrcodeModule } from '@webclient/qrcode/qrcode-module';
import { SmsModule } from '@webclient/sms/sms.module';
import { HeaderModule } from './header/header.module';
import { DialerModule } from '../call-adapter/dialer.module';
import { UnreadNotificationsService } from './unread-notifications.service';
import { InitialRedirectComponent } from './initial-redirect/initial-redirect.component';
import { WebmeetingHolderComponent } from './webmeeting-holder/webmeeting-holder.component';
import { WebmeetingComponent } from './webmeeting-holder/webmeeting.component';
import { ValdemortModule } from 'ngx-valdemort';
import { DefaultValidationErrorsComponent } from './default-validation-errors.component';
import { ConvertServerErrorPipe } from '@webclient/convert-server-error';

@NgModule({
    declarations: [
        AsideComponent,
        NavComponent,
        LayoutComponent,
        InitialRedirectComponent,
        WebmeetingComponent,
        WebmeetingHolderComponent,
    ],
    imports: [
        DefaultValidationErrorsComponent,
        ValdemortModule,
        HeaderModule,
        ChatToasterModule,
        WebRTCModule,
        SmsModule,
        WebmeetingModule,
        MyPhoneModule,
        PhoneModule,
        SharedModule,
        ProfileImageModule,
        RouterModule,
        QrcodeModule,
        DialerModule,
        ConvertServerErrorPipe,
        // RouterModule.forChild(routes),
    ],
    providers: [
        UnreadNotificationsService,
        ChatUnhandledMessageService,
        IdleService,
        SoundService,
    ],
})

export class LayoutModule {
}
