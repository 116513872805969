import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Registration } from '@myphone';
import {
    DeviceService
} from '@webclient/phone/device.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { DialerVisibilityService } from '@webclient/call-adapter/dialer-visibility.service';
import selectPhoneIcon from 'img/icons/font-awesome/regular/dot-circle.svg';
import unselectedPhoneIcon from 'img/icons/font-awesome/regular/circle.svg';

@Component({
    selector: 'dialer-select-phone',
    templateUrl: 'dialer-select-phone.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['dialer-select-phone.component.scss']
})
export class DialerSelectPhoneComponent {
    readonly selectPhoneIcon = selectPhoneIcon;
    readonly unselectedPhoneIcon = unselectedPhoneIcon;

    userSelectedPhoneDevice$: Observable<Registration|undefined>;
    phones$: Observable<Registration[]>;
    hasPhones$: Observable<boolean>;

    constructor(
        private deviceService: DeviceService,
        public callsAdapterService: DialerVisibilityService,
        private router: Router
    ) {
        this.userSelectedPhoneDevice$ = deviceService.selectedPhoneDevice$;
        this.phones$ = deviceService.phones$;
        this.hasPhones$ = deviceService.phones$.pipe(
            map(phones => phones && phones.length > 0)
        );
    }

    public selectedPhonesDeviceChange(phone: Registration) {
        this.deviceService.selectPhoneDevice(phone);
    }

    public goToMediaDeviceOptions() {
        this.router.navigate(['/settings/media']);
        this.callsAdapterService.toggleDialer.next(true);
    }
}
