import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

/** Wrapper for any input, textarea or select component. Not for checkbox-like fields. */
@Component({
    selector: 'field-wrapper',
    template: `
        <div [ngClass]="{'d-flex align-items-start gap-2': inlineLabel}">
            <div class="d-flex align-items-center form-label" *ngIf="label">
                <label data-qa="label" [for]="labelFor" [ngClass]="{ inlineLabel, inlineLabelAuto }">
                    {{label! | translate}}
                </label>
                <field-hint *ngIf="hint" [text]="hint"></field-hint>
            </div>
            <ng-content></ng-content>
        </div>
        <div class="form-control-validation" data-qa="validation">
            <ng-content select="val-errors,show-error-warning"></ng-content>
        </div>
        <div *ngIf="description" class="form-text d-flex align-items-center gap-1" data-qa="description">
            <span *ngIf="description !== 'true'">{{description | translate}}</span>
            <ng-content select="[description]"></ng-content>
        </div>
    `,
    // partial view, pass updates further
    changeDetection: ChangeDetectionStrategy.Default,
    styles: ['.inlineLabel:not(.inlineLabelAuto) { min-width: 120px; } .inlineLabel { line-height: 32px; }']
})
export class FieldWrapperComponent {
    @Input() label: string | null;
    @Input() labelFor?: string;
    @Input() description?: string;
    @Input() hint?: string;

    @HostBinding('class.required')
    @Input() required = false;

    @HostBinding('class.disabled')
    @Input() disabled = false;

    @Input() inlineLabel?: boolean;

    /** Do not set minimum width 120px */
    @Input() inlineLabelAuto?: boolean;

    @HostBinding('class.form-input')
    readonly formInput = true;
}
