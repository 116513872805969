import { Injectable } from '@angular/core';
import { MyPhoneService } from '../../myphone/myphone.service';
import { ModalService } from '../../modal/app-modal.service';
import { ConferenceWrapper } from '../conference-wrapper';
import dayjs from 'dayjs';

import { MyPhoneSession } from '../../myphone/myphone-session';
import { AppContactType, localBridgeId } from '../../myphone/app-contact-type';
import { SimpleConferenceParticipantVM } from '../simple-conference-participant-vm';
import { TranslateService } from '@ngx-translate/core';
import {
    ConferenceParticipant,
    ConferenceParticipants,
    ConferenceParticipantStatus,
    Conferences,
    ConferenceState,
    MeetingType,
    RequestChangeConference,
    RequestGetConferenceSchedule,
    RequestUpsertScheduledConference,
    ResponseAcknowledge
} from '@myphone';
import {
    combineLatest, forkJoin, NEVER, Observable, of
} from 'rxjs';
import {
    catchError, map, switchMap, tap
} from 'rxjs/operators';
import { ContactSearcherService } from '@webclient/shared/service/contact-searcher.service';
import { publishRef } from '@webclient/rx-share-utils';

@Injectable({
    providedIn: 'root'
})
export class ConferenceViewService {
    audioConferences$: Observable<ConferenceWrapper[]>;

    constructor(private myPhoneService: MyPhoneService,
                private translateService : TranslateService,
                private contactService: ContactSearcherService,
                private modalService: ModalService) {
        this.audioConferences$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => {
                return session.conferences$.pipe(
                    // Put audio conference into wrapper
                    map(m => ConferenceViewService.convertToWrapers(m)),
                    // Calculate is started, using time offset received from server
                    // TODO: speak with Stepan most propbaly that is more logical to implement on server side
                    tap(wrappers => this.updateIsStarted(wrappers, session.timeSync.delta)),
                    catchError((error: unknown) => {
                        this.modalService.error(error);
                        return of([]);
                    })
                );
            }),
            publishRef()
        );
    }

    private static extractParticipants(praticipants: ConferenceParticipants): ConferenceParticipant[] {
        if (!praticipants || !praticipants.Items) {
            return [];
        }

        return praticipants.Items;
    }

    public getPrivateConferenceObservable(): Observable<ConferenceWrapper[]> {
        return this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.myInfo.PrivateConference$),
            switchMap(privateConf => {
                const participants = ConferenceViewService.extractParticipants(privateConf);
                if (participants.length > 0) {
                    return ConferenceWrapper.forPrivateConference(this.translateService, participants).pipe(map(x => [x]));
                }
                else {
                    return of([]);
                }
            }),
            publishRef()
        );
    }

    public getAudioConferenceObservable(confId: number): Observable<ConferenceWrapper> {
        confId = +confId;
        if (!confId) {
            console.error(`getAudioConferenceObservable: confId='${confId}' is not a number`, confId);
        }

        return this.audioConferences$.pipe(
            switchMap((wrappers: ConferenceWrapper[]) => {
                const targetConf = wrappers.find(x => x.audioConference.Id === confId);
                return targetConf
                    ? of(targetConf)
                    : NEVER;
            }),
            // do: create view models of participants - before ref count
            publishRef()
        );
    }

    private buildVmList(session: MyPhoneSession, participants: ConferenceParticipant[]) {
        if (participants.length > 0) {
            return forkJoin(participants.map((p: ConferenceParticipant) => {
                return ConferenceViewService.buildSimpleParticipantVm(this.contactService, p.Number, p.DisplayName).pipe(map(participants => {
                    participants.model = p;
                    return participants;
                }));
            })).pipe(
                map(vmList => {
                    ConferenceViewService.updateVisibility(vmList);
                    return vmList;
                }),
                map(vmList => vmList.filter(vm => vm.IsVisible))
            );
        }
        else {
            return of([]);
        }
    }

    static buildSimpleParticipantVm(contactService: ContactSearcherService, phoneNumber: string, displayName: string)
        : Observable<SimpleConferenceParticipantVM> {
        // console.log(phoneNumber);
        return contactService.requestContactByNumber(phoneNumber).pipe(map(contact => {
            const vm = new SimpleConferenceParticipantVM();
            vm.Number = phoneNumber;
            if (!contact.isDummy) {
                vm.IsExtension = contact.type === AppContactType.Extension && contact.bridgeId === localBridgeId;
                vm.Name = contact.firstNameLastName || contact.company || '';
                if (contact.emailAddress) {
                    vm.Email = contact.emailAddress;
                }
            }
            else {
                vm.IsExtension = false;
                vm.Name = displayName;
            }
            return vm;
        }));
    }

    public getScheduledConferenceDetailsObservable(confId: number): Observable<RequestUpsertScheduledConference> {
        const conference$ = this.getAudioConferenceObservable(confId);
        return conference$.pipe(switchMap(wrapper => {
            const req = new RequestGetConferenceSchedule();
            req.Id = wrapper.audioConference.ScheduleID;
            req.PinCode = wrapper.audioConference.PinCode;

            // TODO: add response type check
            return this.myPhoneService.get<RequestUpsertScheduledConference>(req)
                .pipe(catchError(
                    () => of(new RequestUpsertScheduledConference({ EmailBody: '' }))
                ));
        }));
    }

    public getScheduledConferenceParticipantsObservable(scheduledConference$: Observable<RequestUpsertScheduledConference>): Observable<SimpleConferenceParticipantVM[]> {
        return combineLatest([scheduledConference$, this.myPhoneService.myPhoneSession]).pipe(
            switchMap(([response]) => {
                const internal$ = response.InternalParticipants ? forkJoin(response.InternalParticipants.split(',').map((num: string) => {
                    return ConferenceViewService.buildSimpleParticipantVm(this.contactService, num, '');
                })).pipe(
                    publishRef()
                ) : of([]);

                const external$ = response.CallToExternalNumbers ? forkJoin(response.CallToExternalNumbers.split(',').map((num: string) => {
                    return ConferenceViewService.buildSimpleParticipantVm(this.contactService, num, '');
                })).pipe(
                    publishRef()
                ) : of([]);

                const contacts$ = combineLatest([external$, internal$]).pipe(map(([external, internal]) => external.concat(internal)));

                const emails$ = contacts$.pipe(
                    switchMap(contacts => {
                        const emailsThatBelongsToSomeNumber = new Set<string>();
                        contacts.forEach(participant => {
                            emailsThatBelongsToSomeNumber.add(participant.Email);
                        });
                        let viewModelFromEmails: Observable<SimpleConferenceParticipantVM[]> = of([]);

                        const useDeprecatedEmails = !response.webMeetingScheduleState || response.webMeetingScheduleState.Type === MeetingType.AudioConference;
                        if (useDeprecatedEmails) {
                            const emailsParticipant = (response.Emails ? response.Emails.split(',') : [])
                                .filter(email => !emailsThatBelongsToSomeNumber.has(email));
                            viewModelFromEmails = emailsParticipant.length > 0 ?
                                forkJoin(emailsParticipant.map(email =>
                                    this.contactService.requestContactByEmail(email).pipe(map(contact => {
                                        const vm = new SimpleConferenceParticipantVM();
                                        vm.Email = email;
                                        if (!contact.isDummy) {
                                            vm.Name = contact.firstNameLastName;
                                            vm.Number = contact.extensionNumber;
                                        }
                                        return vm;
                                    }))
                                )) : of([]);
                        }
                        else {
                            const hasParticipants = response.webMeetingScheduleState.Participants && response.webMeetingScheduleState.Participants.Items;
                            if (hasParticipants) {
                                viewModelFromEmails = of(response.webMeetingScheduleState.Participants.Items
                                    .filter(item => !emailsThatBelongsToSomeNumber.has(item.Email))
                                    .map(item => {
                                        return new SimpleConferenceParticipantVM({
                                            Email: item.Email,
                                            Name: item.Name
                                        });
                                    })
                                );
                            }
                        }
                        return viewModelFromEmails;
                    }),
                    publishRef()
                );

                return combineLatest([contacts$, emails$]).pipe(map(([contacts, emails]) =>
                    contacts.concat(emails)
                ));
            }),
            // tap(x => console.log(x)),
            catchError((error: unknown) => {
                console.log(error);
                return of([]);
            }));
    }

    private static isGoodStatus(status: ConferenceParticipantStatus): boolean {
        return (status !== ConferenceParticipantStatus.DISCONNECTED) && (status !== ConferenceParticipantStatus.FAILEDTOREACH);
    }

    // Unfortunately ConferenceParticipants is not a "Participants" they more similar to "Conncection" abstraction
    // So, we have can have several conections for the same 'Participant' that we trying to identify by number
    // And next... we show only good connections for 'Participant' and other we hide
    // This code taken from Windows Client
    private static updateVisibility(srcItems: SimpleConferenceParticipantVM[]) {
        srcItems.forEach((item) => {
            if (ConferenceViewService.isGoodStatus(item.model.Status)) {
                item.IsVisible = true;
            }
            else {
                const siblings = srcItems.filter(x => x.Number === item.Number);
                const anyConnected = siblings.some(x => ConferenceViewService.isGoodStatus(x.model.Status));
                if (anyConnected) {
                    item.IsVisible = false;
                }
                else {
                    item.IsVisible = (siblings.pop() === item);
                }
            }
        });
    }

    getAudioConferenceParticipantViewModels(confId: number) {
        const conference$ = this.getAudioConferenceObservable(confId);

        return combineLatest([conference$, this.myPhoneService.myPhoneSession]).pipe(
            switchMap(([wrapper, session]) => {
                const participants = wrapper.audioOrPrivateParticipants;
                return this.buildVmList(session, participants.filter(x => Boolean(x.Number)));
            }));
    }

    getPrivateConferenceParticipantsObservable() {
        const conference$ = this.getPrivateConferenceObservable();

        return combineLatest([conference$, this.myPhoneService.myPhoneSession]).pipe(
            switchMap((x) => {
                const [wrappers, session] = x;
                if (!wrappers.length) {
                    return [];
                }
                // wrappers[0] - because we have only ONE private conference
                const participants = wrappers[0].audioOrPrivateParticipants;
                return this.buildVmList(session, participants);
            }));
    }

    private static convertToWrapers(obj: Conferences): ConferenceWrapper[] {
        if (obj.Items === undefined || obj.Items.length === 0) {
            return [];
        }
        // StartAt for Instant audio cofnerences (Created with 700*123*0000 then *) is empty
        // so, add that additional check (!x.StartAt && x.IsActive)
        const isActiveConference = (x: ConferenceState) => (!x.StartAt && x.IsActive);

        const confStartShowDate = dayjs().subtract(2, 'days');
        return obj.Items.filter(x => isActiveConference(x) || dayjs.utc(x.StartAt).isSameOrAfter(confStartShowDate)).map(confState => {
            return (confState.Type === MeetingType.Mixed || confState.Type === MeetingType.WebMeeting) ?
                ConferenceWrapper.forWebmeeting(confState) : ConferenceWrapper.forAudioConference(confState);
        });
    }

    // Calculate new value for us started
    private updateIsStarted(wrappers: ConferenceWrapper[], deltaMs: number) {
        const now = dayjs();
        wrappers.forEach((wrapper) => {
            // TODO: move to wrapper
            if (!wrapper.audioConference.IsScheduled) {
                wrapper.isStarted = true;
            }
            else {
                const localStartDate = dayjs.utc(wrapper.audioConference.StartAt).local();
                wrapper.isStarted = dayjs(now)
                    .add(deltaMs, 'milliseconds')
                    .isAfter(localStartDate);
            }
        });
    }

    addConferenceParticipant(phone: string, pin: string): Observable<any> {
        const request = new RequestChangeConference();
        request.method = 'add';
        request.member = phone;
        request.pin = pin;

        return this.myPhoneService.get(request).pipe(catchError((error: unknown) => {
            this.modalService.error(error);
            return of(false);
        }));
    }

    sendRequestChangeConference(request: RequestChangeConference, pin?: string): Observable<any> {
        if (pin) {
            request.pin = pin;
        }

        return this.myPhoneService.get(request).pipe(catchError((error: unknown) => {
            this.modalService.error(error);
            return of(false);
        }));
    }

    callConferenceParticipant(participant: ConferenceParticipant, conference: ConferenceWrapper) {
        const request: RequestChangeConference = new RequestChangeConference();
        request.method = 'add';
        request.member = participant.Number.toString();

        this.sendRequestChangeConference(request, conference.getPinCode())
            .subscribe({
                next: (response: ResponseAcknowledge) => {
                    if (response !== undefined) {
                        if (!response.Success) {
                            this.modalService.error('$Message :{response.Message} Error Code :{response.ErrorCode}');
                            console.log(response.Message);
                            console.log(response.ErrorCode);
                        }
                    }
                },
                error: (err: unknown) => {
                    this.modalService.error(err);
                }
            });
    }

    dropConferenceParticipant(participant: ConferenceParticipant, conference: ConferenceWrapper) {
        const request: RequestChangeConference = new RequestChangeConference();
        request.method = 'drop';
        request.member = participant.Id.toString();

        this.sendRequestChangeConference(request, conference.getPinCode())
            .subscribe({
                next: (response: ResponseAcknowledge) => {
                    if (response !== undefined) {
                        if (!response.Success) {
                            this.modalService.error('$Message :{response.Message} Error Code :{response.ErrorCode}');
                        }
                    }
                },
                error: (err: unknown) => {
                    this.modalService.error(err);
                }
            });
    }

    toggleMuteParticipant(participant: ConferenceParticipant, conference: ConferenceWrapper) {
        const request = new RequestChangeConference();
        request.method = 'mute';
        request.member = participant.Id.toString();
        const isMuted = participant.Status !== ConferenceParticipantStatus.MUTED;
        request.mute = isMuted ? '1' : '0';

        this.sendRequestChangeConference(request, conference.getPinCode())
            .subscribe({
                next: (response: ResponseAcknowledge) => {
                    if (response !== undefined) {
                        if (!response.Success) {
                            this.modalService.error('$Message :{response.Message} Error Code :{response.ErrorCode}');
                            console.log(response.Message);
                            console.log(response.ErrorCode);
                        }
                    }
                },
                error: (err: unknown) => {
                    this.modalService.error(err);
                }
            });
    }

    joinLinkObservable(conference$: Observable<RequestUpsertScheduledConference>) {
        return combineLatest([this.myPhoneService.myPhoneSession, conference$]).pipe(
            map(([session, schedule]) => {
                const originalLink = schedule.webMeetingScheduleState?.JoinLink;
                if (originalLink) {
                    const originalUrl = new URL(originalLink);
                    const url = new URL(originalUrl.pathname, session.domainUrl);
                    return url.href;
                }
                else {
                    return '';
                }
            })
        );
    }

    getColorClassByStatus(status: number): string {
        switch (status) {
            case ConferenceParticipantStatus.CONNECTED:
            case ConferenceParticipantStatus.MUTED:
            case ConferenceParticipantStatus.HELDBYCONFERENCE:
            case ConferenceParticipantStatus.HELDBYPARTICIPANT:
            case ConferenceParticipantStatus.JOINING:
                return 'normalStatus';

            case ConferenceParticipantStatus.FAILEDTOREACH:
            case ConferenceParticipantStatus.DISCONNECTED:
                return 'failedStatus';

            default:
                return '';
        }
    }

    getTextByStatus(status: number): string {
        switch (status) {
            case ConferenceParticipantStatus.CONNECTED:
                return '_i18n.Connected';
            case ConferenceParticipantStatus.MUTED:
                return '_i18n.Muted';
            case ConferenceParticipantStatus.HELDBYCONFERENCE:
                return '_i18n.HeldByConference';
            case ConferenceParticipantStatus.HELDBYPARTICIPANT:
                return '_i18n.HeldByParticipant';
            case ConferenceParticipantStatus.JOINING:
                return '_i18n.Joining';
            case ConferenceParticipantStatus.FAILEDTOREACH:
                return '_i18n.FailedToReach';
            case ConferenceParticipantStatus.DISCONNECTED:
                return '_i18n.DisConnected';

            default:
                return '';
        }
    }
}
