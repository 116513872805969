import { Phones } from '../myphone/phones';

const sanitizeNumber = new RegExp('[^%+*#0-9a-zA-Z]', 'g');

export function sanitizePhoneNumber(phoneNumber: string): string {
    return phoneNumber ? phoneNumber.replace(sanitizeNumber, '') : '';
}

export function fitsPhoneNumber(sanitizedPhones: Partial<Phones>, phoneNumber: string): boolean {
    if (!phoneNumber) {
        return false;
    }

    const sanitized = sanitizePhoneNumber(phoneNumber);
    if (sanitized.length === 0) {
        return false;
    }

    function sw(str?: string) {
        if (str === undefined) {
            return false;
        }
        return str.indexOf(sanitized) >= 0;
    }

    return sw(sanitizedPhones.extension) ||
           sw(sanitizedPhones.mobile) ||
           sw(sanitizedPhones.mobile2) ||
           sw(sanitizedPhones.business) ||
           sw(sanitizedPhones.business2) ||
           sw(sanitizedPhones.home) ||
           sw(sanitizedPhones.businessFax) ||
           sw(sanitizedPhones.other) ||
           sw(sanitizedPhones.pager);
}
