import { Component } from '@angular/core';
import { WebmeetingHolderService } from '@webclient/layout/webmeeting-holder/webmeeting-holder.service';

@Component({
    selector: 'app-webmeeting-holder',
    template: `
        <webmeeting *ngIf="(service.isActive|async) || (service.isMeetingMainScreen|async)" [class.d-none]="!(service.isMeetingMainScreen|async)"></webmeeting>
<!--        <div *ngIf="service.isInvalid$ | async" [class.d-none]="!(service.isMeetingMainScreen|async)"-->
<!--             [class.d-flex]="(service.isMeetingMainScreen|async)"-->
<!--             class="bg-dark align-items-center justify-content-center invalid">-->
<!--            <div class="modal-container flex-center column-start">-->
<!--                <img class="logo-img" src="{{logoImage}}"/>-->
<!--                <div class="flex-center">-->
<!--                    <p [translate]="'_i18n.WebMeetingUrlIsNotAvailable'"></p>-->
<!--                </div>-->

<!--            </div>-->
<!--        </div>-->
    `,
    styleUrls: ['./webmeeting-holder.component.scss'],
})
export class WebmeetingHolderComponent {
    constructor(public service: WebmeetingHolderService) { }
}
