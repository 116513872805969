import {
    ChangeDetectionStrategy,
    Component, EventEmitter, Input, OnChanges, Output, Self, SimpleChanges
} from '@angular/core';
import type { PageChangedEvent } from 'ngx-bootstrap/pagination/pagination.component';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { DestroyService } from '@webclient/services/destroy.service';
import { takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaginationModule } from 'ngx-bootstrap/pagination';

export type PageIndex = { startIndex: number; endIndex: number }

export type PageSize = 10 | 25 | 50| 100;

export const PAGE_SIZES: PageSize[] = [10, 25, 50, 100];
export const DEFAULT_ITEMS_PER_PAGE = PAGE_SIZES[2];

@Component({
    selector: 'app-paging',
    templateUrl: 'paging.component.html',
    providers: [DestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, TranslateModule, IconsModule, NgSelectModule, PaginationModule, ReactiveFormsModule, FormsModule]
})
export class PagingComponent implements OnChanges {
    // to accept async we need to accept null
    @Input() totalItems: number | null = 0;

    // to accept async we need to accept null
    @Input() itemsPerPage: number | null = DEFAULT_ITEMS_PER_PAGE;

    // to accept async we need to accept null
    @Input() currentPage: number|null = 1;

    @Input() maxVisiblePages = 5;
    @Input() showBoundaryLinks = false;
    @Input() showPageIndexes = false;
    @Input() showPageSizes = false;
    @Input() size :'small' | 'normal' = 'normal';

    @Output() pageChanged = new EventEmitter<PageChangedEvent>();
    @Output() itemsPerPageChanged = new EventEmitter<PageSize>();

    readonly pageSizeControl = new FormControl<PageSize>(DEFAULT_ITEMS_PER_PAGE);

    readonly PAGE_SIZES = PAGE_SIZES;

    constructor(@Self() destroy$: DestroyService) {
        this.pageSizeControl.valueChanges
            .pipe(filter(Boolean), distinctUntilChanged(), takeUntil(destroy$))
            .subscribe(pageSize => {
                this.itemsPerPageChanged.emit(pageSize);
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.itemsPerPage && typeof this.itemsPerPage === 'number') {
            this.pageSizeControl.setValue(this.itemsPerPage as PageSize, { emitEvent: false });
        }
    }

    get isSmall(): boolean {
        return this.size === 'small';
    }

    // next getters are executed only for non nullable currentPage, totalItems and itemsPerPage because of *ngIf

    get pageIndexes(): PageIndex {
        return {
            startIndex: ((this.currentPage! - 1) * this.itemsPerPage!) + 1,
            endIndex: Math.min(this.currentPage! * this.itemsPerPage!, this.totalItems!),
        };
    }

    get totalPages(): number {
        return Math.max(1, Math.ceil(this.totalItems! / this.itemsPerPage!));
    }

    get showFirstPageLink() : boolean {
        return (this.currentPage! > Math.ceil((this.maxVisiblePages + 1) / 2)) && (this.totalPages > this.maxVisiblePages);
    }

    get showFirstPageLinkEllipsis() : boolean {
        return this.currentPage! > (Math.ceil(this.maxVisiblePages / 2) + 1) && (this.totalPages > (this.maxVisiblePages + 1));
    }

    get showLastPageLink() : boolean {
        return this.currentPage! <= (this.totalPages - Math.ceil(this.maxVisiblePages / 2)) && (this.totalPages > this.maxVisiblePages);
    }

    get showLastPageLinkEllipsis() : boolean {
        return this.currentPage! < (this.totalPages - Math.ceil(this.maxVisiblePages / 2)) && (this.totalPages > (this.maxVisiblePages + 1));
    }

    get areBoundaryLinksVisible() : boolean {
        return this.showBoundaryLinks && this.totalItems! > (this.maxVisiblePages * this.itemsPerPage!);
    }

    get itemsOnThisPage(): number {
        return Math.min(this.totalItems! - (this.currentPage! - 1) * this.itemsPerPage!, this.itemsPerPage!);
    }
}
