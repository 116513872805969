import { Injectable, OnDestroy } from '@angular/core';
import * as RecordRTC from 'recordrtc';
import dayjs from 'dayjs';
import { Subject } from 'rxjs';
import type { RecordedAudioOutput } from './types';

const MaxRecordingTime = 60 * 2 * 1000;

export function toString(value: any) {
    let val = value;
    if (!value) {
        val = '00';
    }
    if (value < 10) {
        val = '0' + value;
    }
    return val;
}

@Injectable()
export class RecordingService implements OnDestroy {
    private stream: MediaStream | null;
    recorder: any;
    private stopRecordingTimeout: any|undefined;
    private interval: any|undefined;
    private startTime: dayjs.Dayjs | null;
    private readonly _recorded = new Subject<RecordedAudioOutput>();
    private readonly _recordingTime = new Subject<string>();
    private readonly _recordingFinished = new Subject<string>();

    readonly recordedBlob$ = this._recorded.asObservable();
    readonly recordingTime$ = this._recordingTime.asObservable();
    readonly recordingFinished$ = this._recordingFinished.asObservable();

    ngOnDestroy() {
        this.stopMedia();
    }

    startRecording(filename: string) {
        if (this.recorder) {
            return;
        }
        this._recordingTime.next('00:00');
        navigator.mediaDevices.getUserMedia({ audio: true }).then((s: MediaStream) => {
            this.stream = s;
            this.record(filename);
        }).catch(error => {
            this._recordingFinished.next('Recording failed');
        });
    }

    abortRecording() {
        this.stopMedia();
    }

    stopRecording(filename: string) {
        if (this.recorder) {
            this.recorder.stop((blob: Blob) => {
                if (this.startTime) {
                    const wavName = filename + '.wav';
                    this.stopMedia();
                    this._recorded.next({ blob, title: wavName });
                }
                this._recordingFinished.next('Recording stopped');
            }, () => {
                this.stopMedia();
                this._recordingFinished.next('Recording failed');
            });
        }
    }

    blobToDataURL(blob: Blob): Promise<string> {
        return new Promise((fulfill, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = (e) => fulfill(reader.result as string);
            reader.readAsDataURL(blob);
        });
    }

    /** Private method always at the end * */
    protected record(filename: string) {
        if (this.stream) {
            this.recorder = new RecordRTC.StereoAudioRecorder(this.stream, {
                type: 'audio',
                mimeType: 'audio/wav',
                desiredSampRate: 8000,
                numberOfAudioChannels: 1,
            });
        }
        this.recorder.record();
        this.startTime = dayjs();
        this.stopRecordingTimeout = setTimeout(() => {
            this.stopRecording(filename);
        }, MaxRecordingTime);
        this.interval = setInterval(
            () => {
                const currentTime = dayjs();
                const diffTime = dayjs.duration(currentTime.diff(this.startTime));
                const time = toString(diffTime.minutes()) + ':' + toString(diffTime.seconds());
                this._recordingTime.next(time);
            },
            1000
        );
    }

    private stopMedia() {
        if (this.recorder) {
            this.recorder = null;
            if (this.stopRecordingTimeout !== undefined) {
                clearTimeout(this.stopRecordingTimeout);
                this.stopRecordingTimeout = undefined;
            }
            if (this.interval !== undefined) {
                clearInterval(this.interval);
                this.interval = undefined;
            }
            this.startTime = null;
            if (this.stream) {
                this.stream.getAudioTracks().forEach((track: { stop: () => unknown; }) => track.stop());
                this.stream = null;
            }
        }
    }
}
