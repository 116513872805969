import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { userImage as defaultAvatarImage } from '@webclient/assets';
import { ProfileImageDialogComponent } from '@webclient/profile-image/profile-image-dialog.component';
import { ModalService } from '@webclient/modal/app-modal.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SimpleControlValueAccessor } from '@office/simple-control-value-accessor';
import { ModalResult } from '@webclient/modal/message-box';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-profile-image',
    templateUrl: 'profile-image.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: ProfileImageComponent
        }],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileImageComponent extends SimpleControlValueAccessor<string> implements ControlValueAccessor {
    @Input() label: string;
    @Input() editorHeader: string;
    @Input() defaultImage = '';

    value: string;

    constructor(
        private modalService: ModalService,
        private translate: TranslateService,
        cd: ChangeDetectorRef
    ) {
        super(cd);
    }

    @ViewChild('file', { static: false })
    fileUpload: ElementRef<HTMLInputElement>;

    get thumbnailSrc(): string {
        return this.value || this.defaultImage || defaultAvatarImage;
    }

    upload() {
        this.fileUpload.nativeElement.value = '';
        this.fileUpload.nativeElement.click();
    }

    remove() {
        this.value = '';
        this.valueChanged(this.value);
    }

    onFileSelected() {
        const file = this.fileUpload.nativeElement.files?.[0];
        if (!file) {
            return;
        }
        else if (file.size > 16 * 1024 * 1024) {
            this.modalService.error('_i18n.DocumentLargerLimit');
            return;
        }

        const reader = new FileReader();
        reader.onload = (evt: ProgressEvent<FileReader>) => {
            this.showProfileImageCrop(evt.target?.result as string);
        };
        reader.readAsDataURL(file);
    }

    writeValue(b64: string) {
        this.value = b64;
        this.cd.markForCheck();
    }

    valueChanged(b64: string) {
        this.value = b64;
        this.markAsTouched();
        this.onChange(b64 || '');
        this.cd.markForCheck();
    }

    private showProfileImageCrop(b64: string) {
        // canvas may show empty image for tiff/tif, so we decline such files.
        if (!String(b64).startsWith('data:image') || b64.startsWith('data:image/tif')) {
            this.translate.get('_i18n.InvalidImageFormatAllowedFormatsAre', {
                formats: 'JPG, JPEG, PNG, BMP, GIF'
            }).subscribe(message => {
                this.modalService.error(message);
            });
            return;
        }

        const myImage = new Image();
        myImage.src = b64;

        this.modalService.showComponent(ProfileImageDialogComponent, {
            initialState: {
                header: this.editorHeader,
                myImage
            }
        }).subscribe(output => {
            if (output.decision === ModalResult.Ok) {
                // if user chose something bad, image cropper shows empty image,
                // if then user clicks OK, he clears the value
                this.valueChanged(output.payload || '');
            }
        });
    }
}
