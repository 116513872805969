/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

/* istanbul ignore file */
// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

/**
 * ErrorCodes enum.
 * @exports ErrorCodes
 * @enum {string}
 * @property {number} ConferenceWithPinDoesNotExist=-1 ConferenceWithPinDoesNotExist value
 * @property {number} ConferenceWithPinAlreadyExists=-2 ConferenceWithPinAlreadyExists value
 * @property {number} ConferencePinAndIdDoesNotMatch=-3 ConferencePinAndIdDoesNotMatch value
 * @property {number} ConferenceAccessDenied=-4 ConferenceAccessDenied value
 * @property {number} ConferenceIsCancelled=-5 ConferenceIsCancelled value
 * @property {number} ConferencePinIsReadOnly=-6 ConferencePinIsReadOnly value
 * @property {number} ConferenceInvalidPin=-7 ConferenceInvalidPin value
 * @property {number} CannotGeneratePin=-8 CannotGeneratePin value
 * @property {number} FwdProfileDoesNotExist=-9 FwdProfileDoesNotExist value
 * @property {number} FwdProfileOverrideExpirationRequired=-10 FwdProfileOverrideExpirationRequired value
 * @property {number} Success=0 Success value
 * @property {number} NoSuchRequest=1 NoSuchRequest value
 * @property {number} ExceptionOccured=2 ExceptionOccured value
 * @property {number} RequestIsNotSupported=3 RequestIsNotSupported value
 * @property {number} ServerIsBusy=4 ServerIsBusy value
 * @property {number} BridgeNotFound=5 BridgeNotFound value
 * @property {number} CannotCleanOwnExtension=6 CannotCleanOwnExtension value
 * @property {number} SetWakeupCallResult=7 SetWakeupCallResult value
 * @property {number} ExtensionNotFound=8 ExtensionNotFound value
 * @property {number} NoPermission=9 NoPermission value
 * @property {number} WebMeetingNoEmail=12 WebMeetingNoEmail value
 * @property {number} WebMeetingNoAccess=13 WebMeetingNoAccess value
 * @property {number} WebMeetingInvalidOrganizer=16 WebMeetingInvalidOrganizer value
 * @property {number} WebMeetingInvalidParameters=17 WebMeetingInvalidParameters value
 * @property {number} WebMeetingInvalidParticipant=18 WebMeetingInvalidParticipant value
 * @property {number} WebMeetingInvalidPin=19 WebMeetingInvalidPin value
 * @property {number} WebMeetingAccessDenied=20 WebMeetingAccessDenied value
 * @property {number} WebMeetingNotFound=21 WebMeetingNotFound value
 * @property {number} WebMeetingCannotDeleteQM=22 WebMeetingCannotDeleteQM value
 * @property {number} WebMeetingPinIsReadonly=23 WebMeetingPinIsReadonly value
 * @property {number} WebMeetingNumberToCallIsReadonly=24 WebMeetingNumberToCallIsReadonly value
 * @property {number} WebMeetingInvalidWmUser=25 WebMeetingInvalidWmUser value
 * @property {number} ExtensionEmailRequired=30 ExtensionEmailRequired value
 * @property {number} QueueNumberRequired=31 QueueNumberRequired value
 * @property {number} ChatIsDisabled=32 ChatIsDisabled value
 * @property {number} PersonalContactRequired=33 PersonalContactRequired value
 * @property {number} RequiredFieldIsEmpty=34 RequiredFieldIsEmpty value
 * @property {number} ContactNotFound=35 ContactNotFound value
 * @property {number} ContactIsReadonly=36 ContactIsReadonly value
 * @property {number} ActionIsNotAllowed=37 ActionIsNotAllowed value
 * @property {number} FileNotFound=38 FileNotFound value
 * @property {number} OwnRecordingsDenied=39 OwnRecordingsDenied value
 * @property {number} InvalidValue=40 InvalidValue value
 * @property {number} InvalidMedia=41 InvalidMedia value
 * @property {number} InvalidOperation=42 InvalidOperation value
 * @property {number} OperationFailed=43 OperationFailed value
 * @property {number} StillProcessing=44 StillProcessing value
 */
export const ErrorCodes = $root.ErrorCodes = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[-1] = "ConferenceWithPinDoesNotExist"] = -1;
    values[valuesById[-2] = "ConferenceWithPinAlreadyExists"] = -2;
    values[valuesById[-3] = "ConferencePinAndIdDoesNotMatch"] = -3;
    values[valuesById[-4] = "ConferenceAccessDenied"] = -4;
    values[valuesById[-5] = "ConferenceIsCancelled"] = -5;
    values[valuesById[-6] = "ConferencePinIsReadOnly"] = -6;
    values[valuesById[-7] = "ConferenceInvalidPin"] = -7;
    values[valuesById[-8] = "CannotGeneratePin"] = -8;
    values[valuesById[-9] = "FwdProfileDoesNotExist"] = -9;
    values[valuesById[-10] = "FwdProfileOverrideExpirationRequired"] = -10;
    values[valuesById[0] = "Success"] = 0;
    values[valuesById[1] = "NoSuchRequest"] = 1;
    values[valuesById[2] = "ExceptionOccured"] = 2;
    values[valuesById[3] = "RequestIsNotSupported"] = 3;
    values[valuesById[4] = "ServerIsBusy"] = 4;
    values[valuesById[5] = "BridgeNotFound"] = 5;
    values[valuesById[6] = "CannotCleanOwnExtension"] = 6;
    values[valuesById[7] = "SetWakeupCallResult"] = 7;
    values[valuesById[8] = "ExtensionNotFound"] = 8;
    values[valuesById[9] = "NoPermission"] = 9;
    values[valuesById[12] = "WebMeetingNoEmail"] = 12;
    values[valuesById[13] = "WebMeetingNoAccess"] = 13;
    values[valuesById[16] = "WebMeetingInvalidOrganizer"] = 16;
    values[valuesById[17] = "WebMeetingInvalidParameters"] = 17;
    values[valuesById[18] = "WebMeetingInvalidParticipant"] = 18;
    values[valuesById[19] = "WebMeetingInvalidPin"] = 19;
    values[valuesById[20] = "WebMeetingAccessDenied"] = 20;
    values[valuesById[21] = "WebMeetingNotFound"] = 21;
    values[valuesById[22] = "WebMeetingCannotDeleteQM"] = 22;
    values[valuesById[23] = "WebMeetingPinIsReadonly"] = 23;
    values[valuesById[24] = "WebMeetingNumberToCallIsReadonly"] = 24;
    values[valuesById[25] = "WebMeetingInvalidWmUser"] = 25;
    values[valuesById[30] = "ExtensionEmailRequired"] = 30;
    values[valuesById[31] = "QueueNumberRequired"] = 31;
    values[valuesById[32] = "ChatIsDisabled"] = 32;
    values[valuesById[33] = "PersonalContactRequired"] = 33;
    values[valuesById[34] = "RequiredFieldIsEmpty"] = 34;
    values[valuesById[35] = "ContactNotFound"] = 35;
    values[valuesById[36] = "ContactIsReadonly"] = 36;
    values[valuesById[37] = "ActionIsNotAllowed"] = 37;
    values[valuesById[38] = "FileNotFound"] = 38;
    values[valuesById[39] = "OwnRecordingsDenied"] = 39;
    values[valuesById[40] = "InvalidValue"] = 40;
    values[valuesById[41] = "InvalidMedia"] = 41;
    values[valuesById[42] = "InvalidOperation"] = 42;
    values[valuesById[43] = "OperationFailed"] = 43;
    values[valuesById[44] = "StillProcessing"] = 44;
    return values;
})();

/**
 * RecordingAction enum.
 * @exports RecordingAction
 * @enum {string}
 * @property {number} Start=0 Start value
 * @property {number} Stop=1 Stop value
 * @property {number} Pause=2 Pause value
 * @property {number} Resume=3 Resume value
 */
export const RecordingAction = $root.RecordingAction = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Start"] = 0;
    values[valuesById[1] = "Stop"] = 1;
    values[valuesById[2] = "Pause"] = 2;
    values[valuesById[3] = "Resume"] = 3;
    return values;
})();

/**
 * ActionType enum.
 * @exports ActionType
 * @enum {string}
 * @property {number} NoUpdates=0 NoUpdates value
 * @property {number} FullUpdate=1 FullUpdate value
 * @property {number} Inserted=2 Inserted value
 * @property {number} Updated=3 Updated value
 * @property {number} Deleted=4 Deleted value
 */
export const ActionType = $root.ActionType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "NoUpdates"] = 0;
    values[valuesById[1] = "FullUpdate"] = 1;
    values[valuesById[2] = "Inserted"] = 2;
    values[valuesById[3] = "Updated"] = 3;
    values[valuesById[4] = "Deleted"] = 4;
    return values;
})();

/**
 * CallHistoryType enum.
 * @exports CallHistoryType
 * @enum {string}
 * @property {number} AllCalls=0 AllCalls value
 * @property {number} Missed=1 Missed value
 * @property {number} Received=2 Received value
 * @property {number} Outgoing=3 Outgoing value
 */
export const CallHistoryType = $root.CallHistoryType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "AllCalls"] = 0;
    values[valuesById[1] = "Missed"] = 1;
    values[valuesById[2] = "Received"] = 2;
    values[valuesById[3] = "Outgoing"] = 3;
    return values;
})();

/**
 * DayOfWeek enum.
 * @exports DayOfWeek
 * @enum {string}
 * @property {number} Sunday=0 Sunday value
 * @property {number} Monday=1 Monday value
 * @property {number} Tuesday=2 Tuesday value
 * @property {number} Wednesday=3 Wednesday value
 * @property {number} Thursday=4 Thursday value
 * @property {number} Friday=5 Friday value
 * @property {number} Saturday=6 Saturday value
 */
export const DayOfWeek = $root.DayOfWeek = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Sunday"] = 0;
    values[valuesById[1] = "Monday"] = 1;
    values[valuesById[2] = "Tuesday"] = 2;
    values[valuesById[3] = "Wednesday"] = 3;
    values[valuesById[4] = "Thursday"] = 4;
    values[valuesById[5] = "Friday"] = 5;
    values[valuesById[6] = "Saturday"] = 6;
    return values;
})();

/**
 * HoursType enum.
 * @exports HoursType
 * @enum {string}
 * @property {number} AllHours=1 AllHours value
 * @property {number} OfficeHours=2 OfficeHours value
 * @property {number} OutOfOfficeHours=3 OutOfOfficeHours value
 * @property {number} SpecificHours=4 SpecificHours value
 * @property {number} SpecificHoursExcludingHolidays=5 SpecificHoursExcludingHolidays value
 * @property {number} OutOfSpecificHours=6 OutOfSpecificHours value
 * @property {number} OutOfSpecificHoursIncludingHolidays=7 OutOfSpecificHoursIncludingHolidays value
 * @property {number} Never=8 Never value
 * @property {number} BreakTime=9 BreakTime value
 */
export const HoursType = $root.HoursType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "AllHours"] = 1;
    values[valuesById[2] = "OfficeHours"] = 2;
    values[valuesById[3] = "OutOfOfficeHours"] = 3;
    values[valuesById[4] = "SpecificHours"] = 4;
    values[valuesById[5] = "SpecificHoursExcludingHolidays"] = 5;
    values[valuesById[6] = "OutOfSpecificHours"] = 6;
    values[valuesById[7] = "OutOfSpecificHoursIncludingHolidays"] = 7;
    values[valuesById[8] = "Never"] = 8;
    values[valuesById[9] = "BreakTime"] = 9;
    return values;
})();

/**
 * SortContactsBy enum.
 * @exports SortContactsBy
 * @enum {string}
 * @property {number} FirstName=1 FirstName value
 * @property {number} LastName=2 LastName value
 * @property {number} Number=3 Number value
 */
export const SortContactsBy = $root.SortContactsBy = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "FirstName"] = 1;
    values[valuesById[2] = "LastName"] = 2;
    values[valuesById[3] = "Number"] = 3;
    return values;
})();

/**
 * SearchByMask enum.
 * @exports SearchByMask
 * @enum {string}
 * @property {number} SBM_FirstName=1 SBM_FirstName value
 * @property {number} SBM_LastName=2 SBM_LastName value
 * @property {number} SBM_Company=4 SBM_Company value
 * @property {number} SBM_Email=8 SBM_Email value
 * @property {number} SBM_ExtNumber=16 SBM_ExtNumber value
 * @property {number} SBM_OtherNumber=32 SBM_OtherNumber value
 */
export const SearchByMask = $root.SearchByMask = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "SBM_FirstName"] = 1;
    values[valuesById[2] = "SBM_LastName"] = 2;
    values[valuesById[4] = "SBM_Company"] = 4;
    values[valuesById[8] = "SBM_Email"] = 8;
    values[valuesById[16] = "SBM_ExtNumber"] = 16;
    values[valuesById[32] = "SBM_OtherNumber"] = 32;
    return values;
})();

/**
 * DnType enum.
 * @exports DnType
 * @enum {string}
 * @property {number} None=0 None value
 * @property {number} Extension=1 Extension value
 * @property {number} Queue=2 Queue value
 * @property {number} RingGroup=4 RingGroup value
 * @property {number} IVR=8 IVR value
 * @property {number} Fax=16 Fax value
 * @property {number} Conference=32 Conference value
 * @property {number} Parking=64 Parking value
 * @property {number} ExternalLine=128 ExternalLine value
 * @property {number} SpecialMenu=256 SpecialMenu value
 * @property {number} Service=512 Service value
 */
export const DnType = $root.DnType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "None"] = 0;
    values[valuesById[1] = "Extension"] = 1;
    values[valuesById[2] = "Queue"] = 2;
    values[valuesById[4] = "RingGroup"] = 4;
    values[valuesById[8] = "IVR"] = 8;
    values[valuesById[16] = "Fax"] = 16;
    values[valuesById[32] = "Conference"] = 32;
    values[valuesById[64] = "Parking"] = 64;
    values[valuesById[128] = "ExternalLine"] = 128;
    values[valuesById[256] = "SpecialMenu"] = 256;
    values[valuesById[512] = "Service"] = 512;
    return values;
})();

/**
 * UsersFolder enum.
 * @exports UsersFolder
 * @enum {string}
 * @property {number} VoiceMailFolder=0 VoiceMailFolder value
 * @property {number} RecordingFolder=1 RecordingFolder value
 * @property {number} ConfigurationFolder=2 ConfigurationFolder value
 * @property {number} GreetingsFolder=3 GreetingsFolder value
 * @property {number} NotificationsFolder=4 NotificationsFolder value
 * @property {number} GroupPrompts=5 GroupPrompts value
 */
export const UsersFolder = $root.UsersFolder = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "VoiceMailFolder"] = 0;
    values[valuesById[1] = "RecordingFolder"] = 1;
    values[valuesById[2] = "ConfigurationFolder"] = 2;
    values[valuesById[3] = "GreetingsFolder"] = 3;
    values[valuesById[4] = "NotificationsFolder"] = 4;
    values[valuesById[5] = "GroupPrompts"] = 5;
    return values;
})();

/**
 * ContactType enum.
 * @exports ContactType
 * @enum {string}
 * @property {number} LocalUser=0 LocalUser value
 * @property {number} CompanyPhonebook=1 CompanyPhonebook value
 * @property {number} PersonalPhonebook=2 PersonalPhonebook value
 * @property {number} BridgeExtension=3 BridgeExtension value
 * @property {number} ExternalContact=4 ExternalContact value
 */
export const ContactType = $root.ContactType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "LocalUser"] = 0;
    values[valuesById[1] = "CompanyPhonebook"] = 1;
    values[valuesById[2] = "PersonalPhonebook"] = 2;
    values[valuesById[3] = "BridgeExtension"] = 3;
    values[valuesById[4] = "ExternalContact"] = 4;
    return values;
})();

/**
 * ContactOptionMask enum.
 * @exports ContactOptionMask
 * @enum {string}
 * @property {number} CO_3CX=1 CO_3CX value
 * @property {number} CO_CRM=2 CO_CRM value
 * @property {number} CO_M365=4 CO_M365 value
 */
export const ContactOptionMask = $root.ContactOptionMask = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "CO_3CX"] = 1;
    values[valuesById[2] = "CO_CRM"] = 2;
    values[valuesById[4] = "CO_M365"] = 4;
    return values;
})();

/**
 * DeleteHistoryType enum.
 * @exports DeleteHistoryType
 * @enum {string}
 * @property {number} IdList=0 IdList value
 * @property {number} All=1 All value
 * @property {number} StartingFrom=2 StartingFrom value
 * @property {number} ConnectionIdList=3 ConnectionIdList value
 */
export const DeleteHistoryType = $root.DeleteHistoryType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "IdList"] = 0;
    values[valuesById[1] = "All"] = 1;
    values[valuesById[2] = "StartingFrom"] = 2;
    values[valuesById[3] = "ConnectionIdList"] = 3;
    return values;
})();

/**
 * QueueCallDealStatus enum.
 * @exports QueueCallDealStatus
 * @enum {string}
 * @property {number} NotDealt=0 NotDealt value
 * @property {number} Dealing=1 Dealing value
 * @property {number} Dealt=2 Dealt value
 */
export const QueueCallDealStatus = $root.QueueCallDealStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "NotDealt"] = 0;
    values[valuesById[1] = "Dealing"] = 1;
    values[valuesById[2] = "Dealt"] = 2;
    return values;
})();

/**
 * ChatStatusType enum.
 * @exports ChatStatusType
 * @enum {string}
 * @property {number} Online=0 Online value
 * @property {number} Away=1 Away value
 * @property {number} DoNotDisturb=2 DoNotDisturb value
 * @property {number} Invisible=3 Invisible value
 * @property {number} Offline=4 Offline value
 */
export const ChatStatusType = $root.ChatStatusType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Online"] = 0;
    values[valuesById[1] = "Away"] = 1;
    values[valuesById[2] = "DoNotDisturb"] = 2;
    values[valuesById[3] = "Invisible"] = 3;
    values[valuesById[4] = "Offline"] = 4;
    return values;
})();

/**
 * WebMeetingRole enum.
 * @exports WebMeetingRole
 * @enum {string}
 * @property {number} Organizer=0 Organizer value
 * @property {number} Moderator=1 Moderator value
 * @property {number} Participant=2 Participant value
 */
export const WebMeetingRole = $root.WebMeetingRole = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Organizer"] = 0;
    values[valuesById[1] = "Moderator"] = 1;
    values[valuesById[2] = "Participant"] = 2;
    return values;
})();

/**
 * MeetingType enum.
 * @exports MeetingType
 * @enum {string}
 * @property {number} AudioConference=0 AudioConference value
 * @property {number} WebMeeting=1 WebMeeting value
 * @property {number} Mixed=2 Mixed value
 */
export const MeetingType = $root.MeetingType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "AudioConference"] = 0;
    values[valuesById[1] = "WebMeeting"] = 1;
    values[valuesById[2] = "Mixed"] = 2;
    return values;
})();

/**
 * WebMeetingProfile enum.
 * @exports WebMeetingProfile
 * @enum {string}
 * @property {number} Webmeeting=0 Webmeeting value
 * @property {number} Webinar=1 Webinar value
 */
export const WebMeetingProfile = $root.WebMeetingProfile = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Webmeeting"] = 0;
    values[valuesById[1] = "Webinar"] = 1;
    return values;
})();

/**
 * CalendarServiceType enum.
 * @exports CalendarServiceType
 * @enum {string}
 * @property {number} NoService=0 NoService value
 * @property {number} Google=1 Google value
 * @property {number} Office365=2 Office365 value
 * @property {number} Outlook=3 Outlook value
 * @property {number} IcsFile=4 IcsFile value
 * @property {number} Tcx=5 Tcx value
 */
export const CalendarServiceType = $root.CalendarServiceType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "NoService"] = 0;
    values[valuesById[1] = "Google"] = 1;
    values[valuesById[2] = "Office365"] = 2;
    values[valuesById[3] = "Outlook"] = 3;
    values[valuesById[4] = "IcsFile"] = 4;
    values[valuesById[5] = "Tcx"] = 5;
    return values;
})();

/**
 * ChatFileState enum.
 * @exports ChatFileState
 * @enum {string}
 * @property {number} CF_Uploading=0 CF_Uploading value
 * @property {number} CF_Available=1 CF_Available value
 * @property {number} CF_Deleted=2 CF_Deleted value
 */
export const ChatFileState = $root.ChatFileState = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CF_Uploading"] = 0;
    values[valuesById[1] = "CF_Available"] = 1;
    values[valuesById[2] = "CF_Deleted"] = 2;
    return values;
})();

/**
 * ContactAddedByEnum enum.
 * @exports ContactAddedByEnum
 * @enum {string}
 * @property {number} AB_Tcx=0 AB_Tcx value
 * @property {number} AB_Crm=1 AB_Crm value
 * @property {number} AB_Office365=2 AB_Office365 value
 */
export const ContactAddedByEnum = $root.ContactAddedByEnum = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "AB_Tcx"] = 0;
    values[valuesById[1] = "AB_Crm"] = 1;
    values[valuesById[2] = "AB_Office365"] = 2;
    return values;
})();

/**
 * ChatMessageType enum.
 * @exports ChatMessageType
 * @enum {string}
 * @property {number} CMT_Normal=0 CMT_Normal value
 * @property {number} CMT_Closed=1 CMT_Closed value
 * @property {number} CMT_Dealt=2 CMT_Dealt value
 * @property {number} CMT_Reserved=3 CMT_Reserved value
 * @property {number} CMT_Taken=4 CMT_Taken value
 * @property {number} CMT_Transferred=5 CMT_Transferred value
 * @property {number} CMT_Whisper=6 CMT_Whisper value
 * @property {number} CMT_Emergency=7 CMT_Emergency value
 * @property {number} CMT_License=8 CMT_License value
 * @property {number} CMT_WebMeeting=9 CMT_WebMeeting value
 * @property {number} CMT_Blocked=10 CMT_Blocked value
 * @property {number} CMT_AutoReply=11 CMT_AutoReply value
 * @property {number} CMT_ClosedByProvider=12 CMT_ClosedByProvider value
 * @property {number} CMT_PersonInDnd=13 CMT_PersonInDnd value
 * @property {number} CMT_MessageTooLong=14 CMT_MessageTooLong value
 * @property {number} CMT_GenericError=15 CMT_GenericError value
 * @property {number} CMT_AutoClosed=16 CMT_AutoClosed value
 * @property {number} CMT_ParticipantAdded=17 CMT_ParticipantAdded value
 * @property {number} CMT_ParticipantRemoved=18 CMT_ParticipantRemoved value
 * @property {number} CMT_Muted=19 CMT_Muted value
 * @property {number} CMT_CallInitiated=20 CMT_CallInitiated value
 */
export const ChatMessageType = $root.ChatMessageType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CMT_Normal"] = 0;
    values[valuesById[1] = "CMT_Closed"] = 1;
    values[valuesById[2] = "CMT_Dealt"] = 2;
    values[valuesById[3] = "CMT_Reserved"] = 3;
    values[valuesById[4] = "CMT_Taken"] = 4;
    values[valuesById[5] = "CMT_Transferred"] = 5;
    values[valuesById[6] = "CMT_Whisper"] = 6;
    values[valuesById[7] = "CMT_Emergency"] = 7;
    values[valuesById[8] = "CMT_License"] = 8;
    values[valuesById[9] = "CMT_WebMeeting"] = 9;
    values[valuesById[10] = "CMT_Blocked"] = 10;
    values[valuesById[11] = "CMT_AutoReply"] = 11;
    values[valuesById[12] = "CMT_ClosedByProvider"] = 12;
    values[valuesById[13] = "CMT_PersonInDnd"] = 13;
    values[valuesById[14] = "CMT_MessageTooLong"] = 14;
    values[valuesById[15] = "CMT_GenericError"] = 15;
    values[valuesById[16] = "CMT_AutoClosed"] = 16;
    values[valuesById[17] = "CMT_ParticipantAdded"] = 17;
    values[valuesById[18] = "CMT_ParticipantRemoved"] = 18;
    values[valuesById[19] = "CMT_Muted"] = 19;
    values[valuesById[20] = "CMT_CallInitiated"] = 20;
    return values;
})();

/**
 * ChatRecipientType enum.
 * @exports ChatRecipientType
 * @enum {string}
 * @property {number} CRT_Local=0 CRT_Local value
 * @property {number} CRT_3cxBridge=1 CRT_3cxBridge value
 * @property {number} CRT_Anonymous=2 CRT_Anonymous value
 * @property {number} CRT_External=3 CRT_External value
 * @property {number} CRT_System=5 CRT_System value
 */
export const ChatRecipientType = $root.ChatRecipientType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CRT_Local"] = 0;
    values[valuesById[1] = "CRT_3cxBridge"] = 1;
    values[valuesById[2] = "CRT_Anonymous"] = 2;
    values[valuesById[3] = "CRT_External"] = 3;
    values[valuesById[5] = "CRT_System"] = 5;
    return values;
})();

/**
 * ChatRecipientStatus enum.
 * @exports ChatRecipientStatus
 * @enum {string}
 * @property {number} CRS_Available=0 CRS_Available value
 * @property {number} CRS_Offline=1 CRS_Offline value
 * @property {number} CRS_Busy=2 CRS_Busy value
 */
export const ChatRecipientStatus = $root.ChatRecipientStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CRS_Available"] = 0;
    values[valuesById[1] = "CRS_Offline"] = 1;
    values[valuesById[2] = "CRS_Busy"] = 2;
    return values;
})();

/**
 * ExternalChatCloseReason enum.
 * @exports ExternalChatCloseReason
 * @enum {string}
 * @property {number} ECCR_LogoutByAnonymous=0 ECCR_LogoutByAnonymous value
 * @property {number} ECCR_LogoutByExtension=1 ECCR_LogoutByExtension value
 * @property {number} ECCR_BlockedByExtension=2 ECCR_BlockedByExtension value
 * @property {number} ECCR_IsHandled=3 ECCR_IsHandled value
 * @property {number} ECCR_NoAgentsAvailable=4 ECCR_NoAgentsAvailable value
 * @property {number} ECCR_NotAvailable=5 ECCR_NotAvailable value
 * @property {number} ECCR_AutoClose=6 ECCR_AutoClose value
 */
export const ExternalChatCloseReason = $root.ExternalChatCloseReason = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "ECCR_LogoutByAnonymous"] = 0;
    values[valuesById[1] = "ECCR_LogoutByExtension"] = 1;
    values[valuesById[2] = "ECCR_BlockedByExtension"] = 2;
    values[valuesById[3] = "ECCR_IsHandled"] = 3;
    values[valuesById[4] = "ECCR_NoAgentsAvailable"] = 4;
    values[valuesById[5] = "ECCR_NotAvailable"] = 5;
    values[valuesById[6] = "ECCR_AutoClose"] = 6;
    return values;
})();

/**
 * ChatDeliveryStatus enum.
 * @exports ChatDeliveryStatus
 * @enum {string}
 * @property {number} CDS_NotDelivered=0 CDS_NotDelivered value
 * @property {number} CDS_Delivered=1 CDS_Delivered value
 * @property {number} CDS_Failed=2 CDS_Failed value
 */
export const ChatDeliveryStatus = $root.ChatDeliveryStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CDS_NotDelivered"] = 0;
    values[valuesById[1] = "CDS_Delivered"] = 1;
    values[valuesById[2] = "CDS_Failed"] = 2;
    return values;
})();

/**
 * ForwardDestinationType enum.
 * @exports ForwardDestinationType
 * @enum {string}
 * @property {number} FD_Disconnect=0 FD_Disconnect value
 * @property {number} FD_VoiceMail=1 FD_VoiceMail value
 * @property {number} FD_Internal=2 FD_Internal value
 * @property {number} FD_External=3 FD_External value
 * @property {number} FD_Rebound=4 FD_Rebound value
 * @property {number} FD_Deflect=5 FD_Deflect value
 */
export const ForwardDestinationType = $root.ForwardDestinationType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "FD_Disconnect"] = 0;
    values[valuesById[1] = "FD_VoiceMail"] = 1;
    values[valuesById[2] = "FD_Internal"] = 2;
    values[valuesById[3] = "FD_External"] = 3;
    values[valuesById[4] = "FD_Rebound"] = 4;
    values[valuesById[5] = "FD_Deflect"] = 5;
    return values;
})();

/**
 * GroupHoursMode enum.
 * @exports GroupHoursMode
 * @enum {string}
 * @property {number} GHM_Default=0 GHM_Default value
 * @property {number} GHM_ForceOpened=1 GHM_ForceOpened value
 * @property {number} GHM_ForceClosed=2 GHM_ForceClosed value
 * @property {number} GHM_ForceBreak=4 GHM_ForceBreak value
 * @property {number} GHM_ForceCustomOperator=8 GHM_ForceCustomOperator value
 * @property {number} GHM_ForceHoliday=16 GHM_ForceHoliday value
 */
export const GroupHoursMode = $root.GroupHoursMode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "GHM_Default"] = 0;
    values[valuesById[1] = "GHM_ForceOpened"] = 1;
    values[valuesById[2] = "GHM_ForceClosed"] = 2;
    values[valuesById[4] = "GHM_ForceBreak"] = 4;
    values[valuesById[8] = "GHM_ForceCustomOperator"] = 8;
    values[valuesById[16] = "GHM_ForceHoliday"] = 16;
    return values;
})();

/**
 * FileType enum.
 * @exports FileType
 * @enum {string}
 * @property {number} FT_Uknown=0 FT_Uknown value
 * @property {number} FT_Image=1 FT_Image value
 * @property {number} FT_Audio=2 FT_Audio value
 * @property {number} FT_Video=3 FT_Video value
 */
export const FileType = $root.FileType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "FT_Uknown"] = 0;
    values[valuesById[1] = "FT_Image"] = 1;
    values[valuesById[2] = "FT_Audio"] = 2;
    values[valuesById[3] = "FT_Video"] = 3;
    return values;
})();

/**
 * CrmRequestState enum.
 * @exports CrmRequestState
 * @enum {string}
 * @property {number} CRS_Preparing=0 CRS_Preparing value
 * @property {number} CRS_Ready=1 CRS_Ready value
 * @property {number} CRS_Error=2 CRS_Error value
 */
export const CrmRequestState = $root.CrmRequestState = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CRS_Preparing"] = 0;
    values[valuesById[1] = "CRS_Ready"] = 1;
    values[valuesById[2] = "CRS_Error"] = 2;
    return values;
})();

export const Login = $root.Login = (() => {

    /**
     * Properties of a Login.
     * @exports ILogin
     * @property {string} User Login User
     * @property {string} [Password] Login Password
     * @property {string} [ClientVersion] Login ClientVersion
     * @property {string} [ClientInfo] Login ClientInfo
     * @property {string} [ProtocolVersion] Login ProtocolVersion
     */

    /**
     * Constructs a new Login.
     * @exports Login
     * @classdesc Represents a Login.
     * @constructor
     * @param {Partial<Login>} [properties] Properties to set
     */
    function Login(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Login User.
     * @member {string} User
     * @memberof Login
     * @instance
     */

    /**
     * Login Password.
     * @member {string} Password
     * @memberof Login
     * @instance
     */

    /**
     * Login ClientVersion.
     * @member {string} ClientVersion
     * @memberof Login
     * @instance
     */

    /**
     * Login ClientInfo.
     * @member {string} ClientInfo
     * @memberof Login
     * @instance
     */

    /**
     * Login ProtocolVersion.
     * @member {string} ProtocolVersion
     * @memberof Login
     * @instance
     */

    /**
     * Encodes the specified Login message. Does not implicitly {@link Login.verify|verify} messages.
     * @function encode
     * @memberof Login
     * @static
     * @param {Login} message Login message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Login.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.User);
        if (message.Password != null && Object.hasOwnProperty.call(message, "Password"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Password);
        if (message.ClientVersion != null && Object.hasOwnProperty.call(message, "ClientVersion"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.ClientVersion);
        if (message.ClientInfo != null && Object.hasOwnProperty.call(message, "ClientInfo"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.ClientInfo);
        if (message.ProtocolVersion != null && Object.hasOwnProperty.call(message, "ProtocolVersion"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.ProtocolVersion);
        return writer;
    };

    /**
     * Decodes a Login message from the specified reader or buffer.
     * @function decode
     * @memberof Login
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Login} Login
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Login.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Login();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.User = reader.string();
                    break;
                }
            case 2: {
                    message.Password = reader.string();
                    break;
                }
            case 3: {
                    message.ClientVersion = reader.string();
                    break;
                }
            case 4: {
                    message.ClientInfo = reader.string();
                    break;
                }
            case 5: {
                    message.ProtocolVersion = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a Login message to GenericMessage
     * @function toGenericMessage
     * @memberof Login
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    Login.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 100, LoginRequest : this});
    };

    /**
     * Login typeId
     * @member {number} typeId
     * @memberof Login
     * @instance
     */
    Login.prototype.typeId = 100;
    /**
     * Login typeName
     * @member {string} typeName
     * @memberof Login
     * @instance
     */
    Login.prototype.typeName = "LoginRequest";

    return Login;
})();

export const LoginInfo = $root.LoginInfo = (() => {

    /**
     * Properties of a LoginInfo.
     * @exports ILoginInfo
     * @property {number} [ExtensionId] LoginInfo ExtensionId
     * @property {boolean} [IsAuthenticated] LoginInfo IsAuthenticated
     * @property {string} [ValidationMessage] LoginInfo ValidationMessage
     * @property {string} [Nonce] LoginInfo Nonce
     * @property {string} [SessionId] LoginInfo SessionId
     * @property {number} [AddpTimeout] LoginInfo AddpTimeout
     * @property {string} [ServerVersion] LoginInfo ServerVersion
     * @property {boolean} [UpdateAvailable] LoginInfo UpdateAvailable
     * @property {number} [LicenseType] LoginInfo LicenseType
     * @property {string} [LicenseProduct] LoginInfo LicenseProduct
     * @property {string} [PbxVersion] LoginInfo PbxVersion
     * @property {DateTime} [LicenseExpiredAt] LoginInfo LicenseExpiredAt
     */

    /**
     * Constructs a new LoginInfo.
     * @exports LoginInfo
     * @classdesc Represents a LoginInfo.
     * @constructor
     * @param {Partial<LoginInfo>} [properties] Properties to set
     */
    function LoginInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LoginInfo ExtensionId.
     * @member {number} ExtensionId
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo IsAuthenticated.
     * @member {boolean} IsAuthenticated
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo ValidationMessage.
     * @member {string} ValidationMessage
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo Nonce.
     * @member {string} Nonce
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo SessionId.
     * @member {string} SessionId
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo AddpTimeout.
     * @member {number} AddpTimeout
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo ServerVersion.
     * @member {string} ServerVersion
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo UpdateAvailable.
     * @member {boolean} UpdateAvailable
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo LicenseType.
     * @member {number} LicenseType
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo LicenseProduct.
     * @member {string} LicenseProduct
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo PbxVersion.
     * @member {string} PbxVersion
     * @memberof LoginInfo
     * @instance
     */

    /**
     * LoginInfo LicenseExpiredAt.
     * @member {DateTime} LicenseExpiredAt
     * @memberof LoginInfo
     * @instance
     */

    /**
     * Encodes the specified LoginInfo message. Does not implicitly {@link LoginInfo.verify|verify} messages.
     * @function encode
     * @memberof LoginInfo
     * @static
     * @param {LoginInfo} message LoginInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LoginInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ExtensionId != null && Object.hasOwnProperty.call(message, "ExtensionId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ExtensionId);
        if (message.IsAuthenticated != null && Object.hasOwnProperty.call(message, "IsAuthenticated"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsAuthenticated);
        if (message.ValidationMessage != null && Object.hasOwnProperty.call(message, "ValidationMessage"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.ValidationMessage);
        if (message.Nonce != null && Object.hasOwnProperty.call(message, "Nonce"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Nonce);
        if (message.SessionId != null && Object.hasOwnProperty.call(message, "SessionId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.SessionId);
        if (message.AddpTimeout != null && Object.hasOwnProperty.call(message, "AddpTimeout"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.AddpTimeout);
        if (message.ServerVersion != null && Object.hasOwnProperty.call(message, "ServerVersion"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.ServerVersion);
        if (message.UpdateAvailable != null && Object.hasOwnProperty.call(message, "UpdateAvailable"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.UpdateAvailable);
        if (message.LicenseType != null && Object.hasOwnProperty.call(message, "LicenseType"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.LicenseType);
        if (message.LicenseProduct != null && Object.hasOwnProperty.call(message, "LicenseProduct"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.LicenseProduct);
        if (message.PbxVersion != null && Object.hasOwnProperty.call(message, "PbxVersion"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.PbxVersion);
        if (message.LicenseExpiredAt != null && Object.hasOwnProperty.call(message, "LicenseExpiredAt"))
            $root.DateTime.encode(message.LicenseExpiredAt, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a LoginInfo message from the specified reader or buffer.
     * @function decode
     * @memberof LoginInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LoginInfo} LoginInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LoginInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.LoginInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ExtensionId = reader.int32();
                    break;
                }
            case 2: {
                    message.IsAuthenticated = reader.bool();
                    break;
                }
            case 3: {
                    message.ValidationMessage = reader.string();
                    break;
                }
            case 4: {
                    message.Nonce = reader.string();
                    break;
                }
            case 5: {
                    message.SessionId = reader.string();
                    break;
                }
            case 6: {
                    message.AddpTimeout = reader.int32();
                    break;
                }
            case 7: {
                    message.ServerVersion = reader.string();
                    break;
                }
            case 8: {
                    message.UpdateAvailable = reader.bool();
                    break;
                }
            case 9: {
                    message.LicenseType = reader.int32();
                    break;
                }
            case 10: {
                    message.LicenseProduct = reader.string();
                    break;
                }
            case 11: {
                    message.PbxVersion = reader.string();
                    break;
                }
            case 12: {
                    message.LicenseExpiredAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a LoginInfo message to GenericMessage
     * @function toGenericMessage
     * @memberof LoginInfo
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    LoginInfo.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 200, LoginResponse : this});
    };

    /**
     * LoginInfo typeId
     * @member {number} typeId
     * @memberof LoginInfo
     * @instance
     */
    LoginInfo.prototype.typeId = 200;
    /**
     * LoginInfo typeName
     * @member {string} typeName
     * @memberof LoginInfo
     * @instance
     */
    LoginInfo.prototype.typeName = "LoginResponse";

    return LoginInfo;
})();

export const Logout = $root.Logout = (() => {

    /**
     * Properties of a Logout.
     * @exports ILogout
     */

    /**
     * Constructs a new Logout.
     * @exports Logout
     * @classdesc Represents a Logout.
     * @constructor
     * @param {Partial<Logout>} [properties] Properties to set
     */
    function Logout(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified Logout message. Does not implicitly {@link Logout.verify|verify} messages.
     * @function encode
     * @memberof Logout
     * @static
     * @param {Logout} message Logout message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Logout.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a Logout message from the specified reader or buffer.
     * @function decode
     * @memberof Logout
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Logout} Logout
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Logout.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Logout();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a Logout message to GenericMessage
     * @function toGenericMessage
     * @memberof Logout
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    Logout.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 101, LogoutRequest : this});
    };

    /**
     * Logout typeId
     * @member {number} typeId
     * @memberof Logout
     * @instance
     */
    Logout.prototype.typeId = 101;
    /**
     * Logout typeName
     * @member {string} typeName
     * @memberof Logout
     * @instance
     */
    Logout.prototype.typeName = "LogoutRequest";

    return Logout;
})();

export const Timestamp = $root.Timestamp = (() => {

    /**
     * Properties of a Timestamp.
     * @exports ITimestamp
     * @property {number} seconds Timestamp seconds
     * @property {number} nanos Timestamp nanos
     */

    /**
     * Constructs a new Timestamp.
     * @exports Timestamp
     * @classdesc Represents a Timestamp.
     * @constructor
     * @param {Partial<Timestamp>} [properties] Properties to set
     */
    function Timestamp(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Timestamp seconds.
     * @member {number} seconds
     * @memberof Timestamp
     * @instance
     */

    /**
     * Timestamp nanos.
     * @member {number} nanos
     * @memberof Timestamp
     * @instance
     */

    /**
     * Encodes the specified Timestamp message. Does not implicitly {@link Timestamp.verify|verify} messages.
     * @function encode
     * @memberof Timestamp
     * @static
     * @param {Timestamp} message Timestamp message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Timestamp.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int64(message.seconds);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.nanos);
        return writer;
    };

    /**
     * Decodes a Timestamp message from the specified reader or buffer.
     * @function decode
     * @memberof Timestamp
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Timestamp} Timestamp
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Timestamp.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Timestamp();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.seconds = reader.int64();
                    break;
                }
            case 2: {
                    message.nanos = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return Timestamp;
})();

export const DateTime = $root.DateTime = (() => {

    /**
     * Properties of a DateTime.
     * @exports IDateTime
     * @property {number} [Year] DateTime Year
     * @property {number} [Month] DateTime Month
     * @property {number} [Day] DateTime Day
     * @property {number} [Hour] DateTime Hour
     * @property {number} [Minute] DateTime Minute
     * @property {number} [Second] DateTime Second
     */

    /**
     * Constructs a new DateTime.
     * @exports DateTime
     * @classdesc Represents a DateTime.
     * @constructor
     * @param {Partial<DateTime>} [properties] Properties to set
     */
    function DateTime(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DateTime Year.
     * @member {number} Year
     * @memberof DateTime
     * @instance
     */

    /**
     * DateTime Month.
     * @member {number} Month
     * @memberof DateTime
     * @instance
     */

    /**
     * DateTime Day.
     * @member {number} Day
     * @memberof DateTime
     * @instance
     */

    /**
     * DateTime Hour.
     * @member {number} Hour
     * @memberof DateTime
     * @instance
     */

    /**
     * DateTime Minute.
     * @member {number} Minute
     * @memberof DateTime
     * @instance
     */

    /**
     * DateTime Second.
     * @member {number} Second
     * @memberof DateTime
     * @instance
     */

    /**
     * Encodes the specified DateTime message. Does not implicitly {@link DateTime.verify|verify} messages.
     * @function encode
     * @memberof DateTime
     * @static
     * @param {DateTime} message DateTime message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DateTime.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Year != null && Object.hasOwnProperty.call(message, "Year"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Year);
        if (message.Month != null && Object.hasOwnProperty.call(message, "Month"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Month);
        if (message.Day != null && Object.hasOwnProperty.call(message, "Day"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Day);
        if (message.Hour != null && Object.hasOwnProperty.call(message, "Hour"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.Hour);
        if (message.Minute != null && Object.hasOwnProperty.call(message, "Minute"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.Minute);
        if (message.Second != null && Object.hasOwnProperty.call(message, "Second"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.Second);
        return writer;
    };

    /**
     * Decodes a DateTime message from the specified reader or buffer.
     * @function decode
     * @memberof DateTime
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DateTime} DateTime
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DateTime.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DateTime();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Year = reader.int32();
                    break;
                }
            case 2: {
                    message.Month = reader.int32();
                    break;
                }
            case 3: {
                    message.Day = reader.int32();
                    break;
                }
            case 4: {
                    message.Hour = reader.int32();
                    break;
                }
            case 5: {
                    message.Minute = reader.int32();
                    break;
                }
            case 6: {
                    message.Second = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return DateTime;
})();

export const ForwardDestination = $root.ForwardDestination = (() => {

    /**
     * Properties of a ForwardDestination.
     * @exports IForwardDestination
     * @property {ForwardDestinationType} FwdType ForwardDestination FwdType
     * @property {string} [Number] ForwardDestination Number
     */

    /**
     * Constructs a new ForwardDestination.
     * @exports ForwardDestination
     * @classdesc Represents a ForwardDestination.
     * @constructor
     * @param {Partial<ForwardDestination>} [properties] Properties to set
     */
    function ForwardDestination(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ForwardDestination FwdType.
     * @member {ForwardDestinationType} FwdType
     * @memberof ForwardDestination
     * @instance
     */

    /**
     * ForwardDestination Number.
     * @member {string} Number
     * @memberof ForwardDestination
     * @instance
     */

    /**
     * Encodes the specified ForwardDestination message. Does not implicitly {@link ForwardDestination.verify|verify} messages.
     * @function encode
     * @memberof ForwardDestination
     * @static
     * @param {ForwardDestination} message ForwardDestination message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForwardDestination.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.FwdType);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Number);
        return writer;
    };

    /**
     * Decodes a ForwardDestination message from the specified reader or buffer.
     * @function decode
     * @memberof ForwardDestination
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ForwardDestination} ForwardDestination
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForwardDestination.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ForwardDestination();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.FwdType = reader.int32();
                    break;
                }
            case 2: {
                    message.Number = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ForwardDestination;
})();

export const AvailableFPType = $root.AvailableFPType = (() => {

    /**
     * Properties of an AvailableFPType.
     * @exports IAvailableFPType
     * @property {number} [NoAnswerTimeout] AvailableFPType NoAnswerTimeout
     * @property {boolean} [RingMyMobile] AvailableFPType RingMyMobile
     * @property {boolean} [UsePhoneBusyStatus] AvailableFPType UsePhoneBusyStatus
     * @property {boolean} [NoAnswerAllCalls] AvailableFPType NoAnswerAllCalls
     * @property {ForwardDestination} [NoAnswerExternal] AvailableFPType NoAnswerExternal
     * @property {ForwardDestination} [NoAnswerInternal] AvailableFPType NoAnswerInternal
     * @property {boolean} [BusyAllCalls] AvailableFPType BusyAllCalls
     * @property {ForwardDestination} [BusyExternal] AvailableFPType BusyExternal
     * @property {ForwardDestination} [BusyInternal] AvailableFPType BusyInternal
     * @property {boolean} [NotRegisteredAllCalls] AvailableFPType NotRegisteredAllCalls
     * @property {ForwardDestination} [NotRegisteredExternal] AvailableFPType NotRegisteredExternal
     * @property {ForwardDestination} [NotRegisteredInternal] AvailableFPType NotRegisteredInternal
     */

    /**
     * Constructs a new AvailableFPType.
     * @exports AvailableFPType
     * @classdesc Represents an AvailableFPType.
     * @constructor
     * @param {Partial<AvailableFPType>} [properties] Properties to set
     */
    function AvailableFPType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AvailableFPType NoAnswerTimeout.
     * @member {number} NoAnswerTimeout
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType RingMyMobile.
     * @member {boolean} RingMyMobile
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType UsePhoneBusyStatus.
     * @member {boolean} UsePhoneBusyStatus
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType NoAnswerAllCalls.
     * @member {boolean} NoAnswerAllCalls
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType NoAnswerExternal.
     * @member {ForwardDestination} NoAnswerExternal
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType NoAnswerInternal.
     * @member {ForwardDestination} NoAnswerInternal
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType BusyAllCalls.
     * @member {boolean} BusyAllCalls
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType BusyExternal.
     * @member {ForwardDestination} BusyExternal
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType BusyInternal.
     * @member {ForwardDestination} BusyInternal
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType NotRegisteredAllCalls.
     * @member {boolean} NotRegisteredAllCalls
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType NotRegisteredExternal.
     * @member {ForwardDestination} NotRegisteredExternal
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * AvailableFPType NotRegisteredInternal.
     * @member {ForwardDestination} NotRegisteredInternal
     * @memberof AvailableFPType
     * @instance
     */

    /**
     * Encodes the specified AvailableFPType message. Does not implicitly {@link AvailableFPType.verify|verify} messages.
     * @function encode
     * @memberof AvailableFPType
     * @static
     * @param {AvailableFPType} message AvailableFPType message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AvailableFPType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.NoAnswerTimeout != null && Object.hasOwnProperty.call(message, "NoAnswerTimeout"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.NoAnswerTimeout);
        if (message.RingMyMobile != null && Object.hasOwnProperty.call(message, "RingMyMobile"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.RingMyMobile);
        if (message.UsePhoneBusyStatus != null && Object.hasOwnProperty.call(message, "UsePhoneBusyStatus"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.UsePhoneBusyStatus);
        if (message.NoAnswerAllCalls != null && Object.hasOwnProperty.call(message, "NoAnswerAllCalls"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.NoAnswerAllCalls);
        if (message.NoAnswerExternal != null && Object.hasOwnProperty.call(message, "NoAnswerExternal"))
            $root.ForwardDestination.encode(message.NoAnswerExternal, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.NoAnswerInternal != null && Object.hasOwnProperty.call(message, "NoAnswerInternal"))
            $root.ForwardDestination.encode(message.NoAnswerInternal, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.BusyAllCalls != null && Object.hasOwnProperty.call(message, "BusyAllCalls"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.BusyAllCalls);
        if (message.BusyExternal != null && Object.hasOwnProperty.call(message, "BusyExternal"))
            $root.ForwardDestination.encode(message.BusyExternal, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.BusyInternal != null && Object.hasOwnProperty.call(message, "BusyInternal"))
            $root.ForwardDestination.encode(message.BusyInternal, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.NotRegisteredAllCalls != null && Object.hasOwnProperty.call(message, "NotRegisteredAllCalls"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.NotRegisteredAllCalls);
        if (message.NotRegisteredExternal != null && Object.hasOwnProperty.call(message, "NotRegisteredExternal"))
            $root.ForwardDestination.encode(message.NotRegisteredExternal, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.NotRegisteredInternal != null && Object.hasOwnProperty.call(message, "NotRegisteredInternal"))
            $root.ForwardDestination.encode(message.NotRegisteredInternal, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes an AvailableFPType message from the specified reader or buffer.
     * @function decode
     * @memberof AvailableFPType
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AvailableFPType} AvailableFPType
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AvailableFPType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AvailableFPType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.NoAnswerTimeout = reader.int32();
                    break;
                }
            case 2: {
                    message.RingMyMobile = reader.bool();
                    break;
                }
            case 3: {
                    message.UsePhoneBusyStatus = reader.bool();
                    break;
                }
            case 4: {
                    message.NoAnswerAllCalls = reader.bool();
                    break;
                }
            case 5: {
                    message.NoAnswerExternal = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.NoAnswerInternal = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.BusyAllCalls = reader.bool();
                    break;
                }
            case 8: {
                    message.BusyExternal = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.BusyInternal = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.NotRegisteredAllCalls = reader.bool();
                    break;
                }
            case 11: {
                    message.NotRegisteredExternal = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.NotRegisteredInternal = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return AvailableFPType;
})();

export const AwayFPType = $root.AwayFPType = (() => {

    /**
     * Properties of an AwayFPType.
     * @exports IAwayFPType
     * @property {boolean} [AllHoursInternal] AwayFPType AllHoursInternal
     * @property {boolean} [AllHoursExternal] AwayFPType AllHoursExternal
     * @property {ForwardDestination} [InternalRule] AwayFPType InternalRule
     * @property {ForwardDestination} [ExternalRule] AwayFPType ExternalRule
     */

    /**
     * Constructs a new AwayFPType.
     * @exports AwayFPType
     * @classdesc Represents an AwayFPType.
     * @constructor
     * @param {Partial<AwayFPType>} [properties] Properties to set
     */
    function AwayFPType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AwayFPType AllHoursInternal.
     * @member {boolean} AllHoursInternal
     * @memberof AwayFPType
     * @instance
     */

    /**
     * AwayFPType AllHoursExternal.
     * @member {boolean} AllHoursExternal
     * @memberof AwayFPType
     * @instance
     */

    /**
     * AwayFPType InternalRule.
     * @member {ForwardDestination} InternalRule
     * @memberof AwayFPType
     * @instance
     */

    /**
     * AwayFPType ExternalRule.
     * @member {ForwardDestination} ExternalRule
     * @memberof AwayFPType
     * @instance
     */

    /**
     * Encodes the specified AwayFPType message. Does not implicitly {@link AwayFPType.verify|verify} messages.
     * @function encode
     * @memberof AwayFPType
     * @static
     * @param {AwayFPType} message AwayFPType message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AwayFPType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.AllHoursInternal != null && Object.hasOwnProperty.call(message, "AllHoursInternal"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.AllHoursInternal);
        if (message.AllHoursExternal != null && Object.hasOwnProperty.call(message, "AllHoursExternal"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.AllHoursExternal);
        if (message.InternalRule != null && Object.hasOwnProperty.call(message, "InternalRule"))
            $root.ForwardDestination.encode(message.InternalRule, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.ExternalRule != null && Object.hasOwnProperty.call(message, "ExternalRule"))
            $root.ForwardDestination.encode(message.ExternalRule, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes an AwayFPType message from the specified reader or buffer.
     * @function decode
     * @memberof AwayFPType
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AwayFPType} AwayFPType
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AwayFPType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AwayFPType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.AllHoursInternal = reader.bool();
                    break;
                }
            case 2: {
                    message.AllHoursExternal = reader.bool();
                    break;
                }
            case 3: {
                    message.InternalRule = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.ExternalRule = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return AwayFPType;
})();

export const ExceptionFPType = $root.ExceptionFPType = (() => {

    /**
     * Properties of an ExceptionFPType.
     * @exports IExceptionFPType
     * @property {Array.<ExceptionRule>} [Exceptions] ExceptionFPType Exceptions
     */

    /**
     * Constructs a new ExceptionFPType.
     * @exports ExceptionFPType
     * @classdesc Represents an ExceptionFPType.
     * @constructor
     * @param {Partial<ExceptionFPType>} [properties] Properties to set
     */
    function ExceptionFPType(properties) {
        this.Exceptions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ExceptionFPType Exceptions.
     * @member {Array.<ExceptionRule>} Exceptions
     * @memberof ExceptionFPType
     * @instance
     */
    ExceptionFPType.prototype.Exceptions = $util.emptyArray;

    /**
     * Encodes the specified ExceptionFPType message. Does not implicitly {@link ExceptionFPType.verify|verify} messages.
     * @function encode
     * @memberof ExceptionFPType
     * @static
     * @param {ExceptionFPType} message ExceptionFPType message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExceptionFPType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Exceptions != null && message.Exceptions.length)
            for (let i = 0; i < message.Exceptions.length; ++i)
                $root.ExceptionRule.encode(message.Exceptions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes an ExceptionFPType message from the specified reader or buffer.
     * @function decode
     * @memberof ExceptionFPType
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ExceptionFPType} ExceptionFPType
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExceptionFPType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExceptionFPType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Exceptions && message.Exceptions.length))
                        message.Exceptions = [];
                    message.Exceptions.push($root.ExceptionRule.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ExceptionFPType;
})();

export const ExceptionRule = $root.ExceptionRule = (() => {

    /**
     * Properties of an ExceptionRule.
     * @exports IExceptionRule
     * @property {string} [CallerId] ExceptionRule CallerId
     * @property {OfficeHoursRule} [Hours] ExceptionRule Hours
     * @property {ForwardDestination} [Destination] ExceptionRule Destination
     */

    /**
     * Constructs a new ExceptionRule.
     * @exports ExceptionRule
     * @classdesc Represents an ExceptionRule.
     * @constructor
     * @param {Partial<ExceptionRule>} [properties] Properties to set
     */
    function ExceptionRule(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ExceptionRule CallerId.
     * @member {string} CallerId
     * @memberof ExceptionRule
     * @instance
     */

    /**
     * ExceptionRule Hours.
     * @member {OfficeHoursRule} Hours
     * @memberof ExceptionRule
     * @instance
     */

    /**
     * ExceptionRule Destination.
     * @member {ForwardDestination} Destination
     * @memberof ExceptionRule
     * @instance
     */

    /**
     * Encodes the specified ExceptionRule message. Does not implicitly {@link ExceptionRule.verify|verify} messages.
     * @function encode
     * @memberof ExceptionRule
     * @static
     * @param {ExceptionRule} message ExceptionRule message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExceptionRule.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.CallerId != null && Object.hasOwnProperty.call(message, "CallerId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.CallerId);
        if (message.Hours != null && Object.hasOwnProperty.call(message, "Hours"))
            $root.OfficeHoursRule.encode(message.Hours, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.Destination != null && Object.hasOwnProperty.call(message, "Destination"))
            $root.ForwardDestination.encode(message.Destination, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes an ExceptionRule message from the specified reader or buffer.
     * @function decode
     * @memberof ExceptionRule
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ExceptionRule} ExceptionRule
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExceptionRule.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExceptionRule();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.CallerId = reader.string();
                    break;
                }
            case 2: {
                    message.Hours = $root.OfficeHoursRule.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.Destination = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ExceptionRule;
})();

export const ForwardingProfile = $root.ForwardingProfile = (() => {

    /**
     * Properties of a ForwardingProfile.
     * @exports IForwardingProfile
     * @property {ActionType} Action ForwardingProfile Action
     * @property {number} Id ForwardingProfile Id
     * @property {string} [Name] ForwardingProfile Name
     * @property {string} [CustomMessage] ForwardingProfile CustomMessage
     * @property {string} [CustomName] ForwardingProfile CustomName
     * @property {AvailableFPType} [Available] ForwardingProfile Available
     * @property {AwayFPType} [Away] ForwardingProfile Away
     * @property {OfficeHoursFPType} [DefaultHours] ForwardingProfile DefaultHours
     * @property {ExceptionFPType} [Exceptions] ForwardingProfile Exceptions
     * @property {boolean} [BlockPush] ForwardingProfile BlockPush
     */

    /**
     * Constructs a new ForwardingProfile.
     * @exports ForwardingProfile
     * @classdesc Represents a ForwardingProfile.
     * @constructor
     * @param {Partial<ForwardingProfile>} [properties] Properties to set
     */
    function ForwardingProfile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ForwardingProfile Action.
     * @member {ActionType} Action
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * ForwardingProfile Id.
     * @member {number} Id
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * ForwardingProfile Name.
     * @member {string} Name
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * ForwardingProfile CustomMessage.
     * @member {string} CustomMessage
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * ForwardingProfile CustomName.
     * @member {string} CustomName
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * ForwardingProfile Available.
     * @member {AvailableFPType} Available
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * ForwardingProfile Away.
     * @member {AwayFPType} Away
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * ForwardingProfile DefaultHours.
     * @member {OfficeHoursFPType} DefaultHours
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * ForwardingProfile Exceptions.
     * @member {ExceptionFPType} Exceptions
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * ForwardingProfile BlockPush.
     * @member {boolean} BlockPush
     * @memberof ForwardingProfile
     * @instance
     */

    /**
     * Encodes the specified ForwardingProfile message. Does not implicitly {@link ForwardingProfile.verify|verify} messages.
     * @function encode
     * @memberof ForwardingProfile
     * @static
     * @param {ForwardingProfile} message ForwardingProfile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForwardingProfile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Name);
        if (message.CustomMessage != null && Object.hasOwnProperty.call(message, "CustomMessage"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.CustomMessage);
        if (message.CustomName != null && Object.hasOwnProperty.call(message, "CustomName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.CustomName);
        if (message.Available != null && Object.hasOwnProperty.call(message, "Available"))
            $root.AvailableFPType.encode(message.Available, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.Away != null && Object.hasOwnProperty.call(message, "Away"))
            $root.AwayFPType.encode(message.Away, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.DefaultHours != null && Object.hasOwnProperty.call(message, "DefaultHours"))
            $root.OfficeHoursFPType.encode(message.DefaultHours, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.Exceptions != null && Object.hasOwnProperty.call(message, "Exceptions"))
            $root.ExceptionFPType.encode(message.Exceptions, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.BlockPush != null && Object.hasOwnProperty.call(message, "BlockPush"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.BlockPush);
        return writer;
    };

    /**
     * Decodes a ForwardingProfile message from the specified reader or buffer.
     * @function decode
     * @memberof ForwardingProfile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ForwardingProfile} ForwardingProfile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForwardingProfile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ForwardingProfile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Name = reader.string();
                    break;
                }
            case 4: {
                    message.CustomMessage = reader.string();
                    break;
                }
            case 5: {
                    message.CustomName = reader.string();
                    break;
                }
            case 6: {
                    message.Available = $root.AvailableFPType.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.Away = $root.AwayFPType.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.DefaultHours = $root.OfficeHoursFPType.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.Exceptions = $root.ExceptionFPType.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.BlockPush = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ForwardingProfile;
})();

export const ForwardingProfiles = $root.ForwardingProfiles = (() => {

    /**
     * Properties of a ForwardingProfiles.
     * @exports IForwardingProfiles
     * @property {ActionType} Action ForwardingProfiles Action
     * @property {Array.<ForwardingProfile>} [Items] ForwardingProfiles Items
     */

    /**
     * Constructs a new ForwardingProfiles.
     * @exports ForwardingProfiles
     * @classdesc Represents a ForwardingProfiles.
     * @constructor
     * @param {Partial<ForwardingProfiles>} [properties] Properties to set
     */
    function ForwardingProfiles(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ForwardingProfiles Action.
     * @member {ActionType} Action
     * @memberof ForwardingProfiles
     * @instance
     */

    /**
     * ForwardingProfiles Items.
     * @member {Array.<ForwardingProfile>} Items
     * @memberof ForwardingProfiles
     * @instance
     */
    ForwardingProfiles.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified ForwardingProfiles message. Does not implicitly {@link ForwardingProfiles.verify|verify} messages.
     * @function encode
     * @memberof ForwardingProfiles
     * @static
     * @param {ForwardingProfiles} message ForwardingProfiles message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForwardingProfiles.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.ForwardingProfile.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ForwardingProfiles message from the specified reader or buffer.
     * @function decode
     * @memberof ForwardingProfiles
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ForwardingProfiles} ForwardingProfiles
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForwardingProfiles.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ForwardingProfiles();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.ForwardingProfile.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ForwardingProfiles;
})();

export const OfficeHoursFPType = $root.OfficeHoursFPType = (() => {

    /**
     * Properties of an OfficeHoursFPType.
     * @exports IOfficeHoursFPType
     * @property {OfficeHoursRule} [ExtSpecHours] OfficeHoursFPType ExtSpecHours
     * @property {OfficeHoursRule} [AwayHours] OfficeHoursFPType AwayHours
     */

    /**
     * Constructs a new OfficeHoursFPType.
     * @exports OfficeHoursFPType
     * @classdesc Represents an OfficeHoursFPType.
     * @constructor
     * @param {Partial<OfficeHoursFPType>} [properties] Properties to set
     */
    function OfficeHoursFPType(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * OfficeHoursFPType ExtSpecHours.
     * @member {OfficeHoursRule} ExtSpecHours
     * @memberof OfficeHoursFPType
     * @instance
     */

    /**
     * OfficeHoursFPType AwayHours.
     * @member {OfficeHoursRule} AwayHours
     * @memberof OfficeHoursFPType
     * @instance
     */

    /**
     * Encodes the specified OfficeHoursFPType message. Does not implicitly {@link OfficeHoursFPType.verify|verify} messages.
     * @function encode
     * @memberof OfficeHoursFPType
     * @static
     * @param {OfficeHoursFPType} message OfficeHoursFPType message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OfficeHoursFPType.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ExtSpecHours != null && Object.hasOwnProperty.call(message, "ExtSpecHours"))
            $root.OfficeHoursRule.encode(message.ExtSpecHours, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.AwayHours != null && Object.hasOwnProperty.call(message, "AwayHours"))
            $root.OfficeHoursRule.encode(message.AwayHours, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes an OfficeHoursFPType message from the specified reader or buffer.
     * @function decode
     * @memberof OfficeHoursFPType
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {OfficeHoursFPType} OfficeHoursFPType
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OfficeHoursFPType.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OfficeHoursFPType();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ExtSpecHours = $root.OfficeHoursRule.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.AwayHours = $root.OfficeHoursRule.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return OfficeHoursFPType;
})();

export const OfficeHoursRule = $root.OfficeHoursRule = (() => {

    /**
     * Properties of an OfficeHoursRule.
     * @exports IOfficeHoursRule
     * @property {HoursType} [RuleHoursType] OfficeHoursRule RuleHoursType
     * @property {Array.<TimeRange>} [Days] OfficeHoursRule Days
     */

    /**
     * Constructs a new OfficeHoursRule.
     * @exports OfficeHoursRule
     * @classdesc Represents an OfficeHoursRule.
     * @constructor
     * @param {Partial<OfficeHoursRule>} [properties] Properties to set
     */
    function OfficeHoursRule(properties) {
        this.Days = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * OfficeHoursRule RuleHoursType.
     * @member {HoursType} RuleHoursType
     * @memberof OfficeHoursRule
     * @instance
     */

    /**
     * OfficeHoursRule Days.
     * @member {Array.<TimeRange>} Days
     * @memberof OfficeHoursRule
     * @instance
     */
    OfficeHoursRule.prototype.Days = $util.emptyArray;

    /**
     * Encodes the specified OfficeHoursRule message. Does not implicitly {@link OfficeHoursRule.verify|verify} messages.
     * @function encode
     * @memberof OfficeHoursRule
     * @static
     * @param {OfficeHoursRule} message OfficeHoursRule message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    OfficeHoursRule.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.RuleHoursType != null && Object.hasOwnProperty.call(message, "RuleHoursType"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.RuleHoursType);
        if (message.Days != null && message.Days.length)
            for (let i = 0; i < message.Days.length; ++i)
                $root.TimeRange.encode(message.Days[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes an OfficeHoursRule message from the specified reader or buffer.
     * @function decode
     * @memberof OfficeHoursRule
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {OfficeHoursRule} OfficeHoursRule
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    OfficeHoursRule.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.OfficeHoursRule();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.RuleHoursType = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Days && message.Days.length))
                        message.Days = [];
                    message.Days.push($root.TimeRange.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return OfficeHoursRule;
})();

export const TimeRange = $root.TimeRange = (() => {

    /**
     * Properties of a TimeRange.
     * @exports ITimeRange
     * @property {DayOfWeek} [Day] TimeRange Day
     * @property {DateTime} [From] TimeRange From
     * @property {DateTime} [To] TimeRange To
     */

    /**
     * Constructs a new TimeRange.
     * @exports TimeRange
     * @classdesc Represents a TimeRange.
     * @constructor
     * @param {Partial<TimeRange>} [properties] Properties to set
     */
    function TimeRange(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * TimeRange Day.
     * @member {DayOfWeek} Day
     * @memberof TimeRange
     * @instance
     */

    /**
     * TimeRange From.
     * @member {DateTime} From
     * @memberof TimeRange
     * @instance
     */

    /**
     * TimeRange To.
     * @member {DateTime} To
     * @memberof TimeRange
     * @instance
     */

    /**
     * Encodes the specified TimeRange message. Does not implicitly {@link TimeRange.verify|verify} messages.
     * @function encode
     * @memberof TimeRange
     * @static
     * @param {TimeRange} message TimeRange message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    TimeRange.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Day != null && Object.hasOwnProperty.call(message, "Day"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Day);
        if (message.From != null && Object.hasOwnProperty.call(message, "From"))
            $root.DateTime.encode(message.From, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.To != null && Object.hasOwnProperty.call(message, "To"))
            $root.DateTime.encode(message.To, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a TimeRange message from the specified reader or buffer.
     * @function decode
     * @memberof TimeRange
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {TimeRange} TimeRange
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    TimeRange.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.TimeRange();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Day = reader.int32();
                    break;
                }
            case 2: {
                    message.From = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.To = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return TimeRange;
})();

export const RequestUpdateFwdProfile = $root.RequestUpdateFwdProfile = (() => {

    /**
     * Properties of a RequestUpdateFwdProfile.
     * @exports IRequestUpdateFwdProfile
     * @property {ForwardingProfiles} [Update] RequestUpdateFwdProfile Update
     */

    /**
     * Constructs a new RequestUpdateFwdProfile.
     * @exports RequestUpdateFwdProfile
     * @classdesc Represents a RequestUpdateFwdProfile.
     * @constructor
     * @param {Partial<RequestUpdateFwdProfile>} [properties] Properties to set
     */
    function RequestUpdateFwdProfile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateFwdProfile Update.
     * @member {ForwardingProfiles} Update
     * @memberof RequestUpdateFwdProfile
     * @instance
     */

    /**
     * Encodes the specified RequestUpdateFwdProfile message. Does not implicitly {@link RequestUpdateFwdProfile.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateFwdProfile
     * @static
     * @param {RequestUpdateFwdProfile} message RequestUpdateFwdProfile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateFwdProfile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Update != null && Object.hasOwnProperty.call(message, "Update"))
            $root.ForwardingProfiles.encode(message.Update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestUpdateFwdProfile message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateFwdProfile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateFwdProfile} RequestUpdateFwdProfile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateFwdProfile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateFwdProfile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Update = $root.ForwardingProfiles.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateFwdProfile message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateFwdProfile
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateFwdProfile.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 108, UpdateFwdProfiles : this});
    };

    /**
     * RequestUpdateFwdProfile typeId
     * @member {number} typeId
     * @memberof RequestUpdateFwdProfile
     * @instance
     */
    RequestUpdateFwdProfile.prototype.typeId = 108;
    /**
     * RequestUpdateFwdProfile typeName
     * @member {string} typeName
     * @memberof RequestUpdateFwdProfile
     * @instance
     */
    RequestUpdateFwdProfile.prototype.typeName = "UpdateFwdProfiles";

    return RequestUpdateFwdProfile;
})();

export const RequestUpdateFavorites = $root.RequestUpdateFavorites = (() => {

    /**
     * Properties of a RequestUpdateFavorites.
     * @exports IRequestUpdateFavorites
     * @property {ActionType} Action RequestUpdateFavorites Action
     * @property {Array.<string>} [ExtensionNumbers] RequestUpdateFavorites ExtensionNumbers
     */

    /**
     * Constructs a new RequestUpdateFavorites.
     * @exports RequestUpdateFavorites
     * @classdesc Represents a RequestUpdateFavorites.
     * @constructor
     * @param {Partial<RequestUpdateFavorites>} [properties] Properties to set
     */
    function RequestUpdateFavorites(properties) {
        this.ExtensionNumbers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateFavorites Action.
     * @member {ActionType} Action
     * @memberof RequestUpdateFavorites
     * @instance
     */

    /**
     * RequestUpdateFavorites ExtensionNumbers.
     * @member {Array.<string>} ExtensionNumbers
     * @memberof RequestUpdateFavorites
     * @instance
     */
    RequestUpdateFavorites.prototype.ExtensionNumbers = $util.emptyArray;

    /**
     * Encodes the specified RequestUpdateFavorites message. Does not implicitly {@link RequestUpdateFavorites.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateFavorites
     * @static
     * @param {RequestUpdateFavorites} message RequestUpdateFavorites message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateFavorites.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.ExtensionNumbers != null && message.ExtensionNumbers.length)
            for (let i = 0; i < message.ExtensionNumbers.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.ExtensionNumbers[i]);
        return writer;
    };

    /**
     * Decodes a RequestUpdateFavorites message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateFavorites
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateFavorites} RequestUpdateFavorites
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateFavorites.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateFavorites();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.ExtensionNumbers && message.ExtensionNumbers.length))
                        message.ExtensionNumbers = [];
                    message.ExtensionNumbers.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateFavorites message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateFavorites
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateFavorites.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 169, UpdateFavorites : this});
    };

    /**
     * RequestUpdateFavorites typeId
     * @member {number} typeId
     * @memberof RequestUpdateFavorites
     * @instance
     */
    RequestUpdateFavorites.prototype.typeId = 169;
    /**
     * RequestUpdateFavorites typeName
     * @member {string} typeName
     * @memberof RequestUpdateFavorites
     * @instance
     */
    RequestUpdateFavorites.prototype.typeName = "UpdateFavorites";

    return RequestUpdateFavorites;
})();

export const Registration = $root.Registration = (() => {

    /**
     * Properties of a Registration.
     * @exports IRegistration
     * @property {ActionType} Action Registration Action
     * @property {number} Id Registration Id
     * @property {string} [Contact] Registration Contact
     * @property {string} [SourceAddress] Registration SourceAddress
     * @property {string} [UserAgent] Registration UserAgent
     * @property {DateTime} [ExpiresAt] Registration ExpiresAt
     */

    /**
     * Constructs a new Registration.
     * @exports Registration
     * @classdesc Represents a Registration.
     * @constructor
     * @param {Partial<Registration>} [properties] Properties to set
     */
    function Registration(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Registration Action.
     * @member {ActionType} Action
     * @memberof Registration
     * @instance
     */

    /**
     * Registration Id.
     * @member {number} Id
     * @memberof Registration
     * @instance
     */

    /**
     * Registration Contact.
     * @member {string} Contact
     * @memberof Registration
     * @instance
     */

    /**
     * Registration SourceAddress.
     * @member {string} SourceAddress
     * @memberof Registration
     * @instance
     */

    /**
     * Registration UserAgent.
     * @member {string} UserAgent
     * @memberof Registration
     * @instance
     */

    /**
     * Registration ExpiresAt.
     * @member {DateTime} ExpiresAt
     * @memberof Registration
     * @instance
     */

    /**
     * Encodes the specified Registration message. Does not implicitly {@link Registration.verify|verify} messages.
     * @function encode
     * @memberof Registration
     * @static
     * @param {Registration} message Registration message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Registration.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Contact != null && Object.hasOwnProperty.call(message, "Contact"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Contact);
        if (message.SourceAddress != null && Object.hasOwnProperty.call(message, "SourceAddress"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.SourceAddress);
        if (message.UserAgent != null && Object.hasOwnProperty.call(message, "UserAgent"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.UserAgent);
        if (message.ExpiresAt != null && Object.hasOwnProperty.call(message, "ExpiresAt"))
            $root.DateTime.encode(message.ExpiresAt, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a Registration message from the specified reader or buffer.
     * @function decode
     * @memberof Registration
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Registration} Registration
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Registration.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Registration();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Contact = reader.string();
                    break;
                }
            case 4: {
                    message.SourceAddress = reader.string();
                    break;
                }
            case 5: {
                    message.UserAgent = reader.string();
                    break;
                }
            case 6: {
                    message.ExpiresAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return Registration;
})();

export const Registrations = $root.Registrations = (() => {

    /**
     * Properties of a Registrations.
     * @exports IRegistrations
     * @property {ActionType} Action Registrations Action
     * @property {Array.<Registration>} [Items] Registrations Items
     */

    /**
     * Constructs a new Registrations.
     * @exports Registrations
     * @classdesc Represents a Registrations.
     * @constructor
     * @param {Partial<Registrations>} [properties] Properties to set
     */
    function Registrations(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Registrations Action.
     * @member {ActionType} Action
     * @memberof Registrations
     * @instance
     */

    /**
     * Registrations Items.
     * @member {Array.<Registration>} Items
     * @memberof Registrations
     * @instance
     */
    Registrations.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified Registrations message. Does not implicitly {@link Registrations.verify|verify} messages.
     * @function encode
     * @memberof Registrations
     * @static
     * @param {Registrations} message Registrations message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Registrations.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.Registration.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a Registrations message from the specified reader or buffer.
     * @function decode
     * @memberof Registrations
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Registrations} Registrations
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Registrations.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Registrations();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.Registration.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return Registrations;
})();

export const VoiceMail = $root.VoiceMail = (() => {

    /**
     * Properties of a VoiceMail.
     * @exports IVoiceMail
     * @property {ActionType} Action VoiceMail Action
     * @property {number} Id VoiceMail Id
     * @property {UsersFolder} [Folder] VoiceMail Folder
     * @property {string} [Filename] VoiceMail Filename
     * @property {string} [CallerId] VoiceMail CallerId
     * @property {string} [CallerName] VoiceMail CallerName
     * @property {DateTime} [ReceivedAt] VoiceMail ReceivedAt
     * @property {number} [Duration] VoiceMail Duration
     * @property {boolean} [IsNew] VoiceMail IsNew
     * @property {string} [Transcription] VoiceMail Transcription
     * @property {Contact} [CallerContact] VoiceMail CallerContact
     */

    /**
     * Constructs a new VoiceMail.
     * @exports VoiceMail
     * @classdesc Represents a VoiceMail.
     * @constructor
     * @param {Partial<VoiceMail>} [properties] Properties to set
     */
    function VoiceMail(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * VoiceMail Action.
     * @member {ActionType} Action
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail Id.
     * @member {number} Id
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail Folder.
     * @member {UsersFolder} Folder
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail Filename.
     * @member {string} Filename
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail CallerId.
     * @member {string} CallerId
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail CallerName.
     * @member {string} CallerName
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail ReceivedAt.
     * @member {DateTime} ReceivedAt
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail Duration.
     * @member {number} Duration
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail IsNew.
     * @member {boolean} IsNew
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail Transcription.
     * @member {string} Transcription
     * @memberof VoiceMail
     * @instance
     */

    /**
     * VoiceMail CallerContact.
     * @member {Contact} CallerContact
     * @memberof VoiceMail
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * Encodes the specified VoiceMail message. Does not implicitly {@link VoiceMail.verify|verify} messages.
     * @function encode
     * @memberof VoiceMail
     * @static
     * @param {VoiceMail} message VoiceMail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VoiceMail.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Folder != null && Object.hasOwnProperty.call(message, "Folder"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Folder);
        if (message.Filename != null && Object.hasOwnProperty.call(message, "Filename"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Filename);
        if (message.CallerId != null && Object.hasOwnProperty.call(message, "CallerId"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.CallerId);
        if (message.CallerName != null && Object.hasOwnProperty.call(message, "CallerName"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.CallerName);
        if (message.ReceivedAt != null && Object.hasOwnProperty.call(message, "ReceivedAt"))
            $root.DateTime.encode(message.ReceivedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.Duration != null && Object.hasOwnProperty.call(message, "Duration"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.Duration);
        if (message.IsNew != null && Object.hasOwnProperty.call(message, "IsNew"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.IsNew);
        if (message.Transcription != null && Object.hasOwnProperty.call(message, "Transcription"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.Transcription);
        if (message.CallerContact != null && Object.hasOwnProperty.call(message, "CallerContact"))
            $root.Contact.encode(message.CallerContact, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a VoiceMail message from the specified reader or buffer.
     * @function decode
     * @memberof VoiceMail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {VoiceMail} VoiceMail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VoiceMail.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VoiceMail();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Folder = reader.int32();
                    break;
                }
            case 4: {
                    message.Filename = reader.string();
                    break;
                }
            case 5: {
                    message.CallerId = reader.string();
                    break;
                }
            case 6: {
                    message.CallerName = reader.string();
                    break;
                }
            case 7: {
                    message.ReceivedAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.Duration = reader.int32();
                    break;
                }
            case 9: {
                    message.IsNew = reader.bool();
                    break;
                }
            case 10: {
                    message.Transcription = reader.string();
                    break;
                }
            case 11: {
                    message.CallerContact = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return VoiceMail;
})();

export const VoiceMails = $root.VoiceMails = (() => {

    /**
     * Properties of a VoiceMails.
     * @exports IVoiceMails
     * @property {ActionType} Action VoiceMails Action
     * @property {Array.<VoiceMail>} [Items] VoiceMails Items
     */

    /**
     * Constructs a new VoiceMails.
     * @exports VoiceMails
     * @classdesc Represents a VoiceMails.
     * @constructor
     * @param {Partial<VoiceMails>} [properties] Properties to set
     */
    function VoiceMails(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * VoiceMails Action.
     * @member {ActionType} Action
     * @memberof VoiceMails
     * @instance
     */

    /**
     * VoiceMails Items.
     * @member {Array.<VoiceMail>} Items
     * @memberof VoiceMails
     * @instance
     */
    VoiceMails.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified VoiceMails message. Does not implicitly {@link VoiceMails.verify|verify} messages.
     * @function encode
     * @memberof VoiceMails
     * @static
     * @param {VoiceMails} message VoiceMails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    VoiceMails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.VoiceMail.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a VoiceMails message from the specified reader or buffer.
     * @function decode
     * @memberof VoiceMails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {VoiceMails} VoiceMails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    VoiceMails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.VoiceMails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.VoiceMail.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return VoiceMails;
})();

export const GroupMember = $root.GroupMember = (() => {

    /**
     * Properties of a GroupMember.
     * @exports IGroupMember
     * @property {ActionType} Action GroupMember Action
     * @property {number} Id GroupMember Id
     * @property {string} [ExtensionNumber] GroupMember ExtensionNumber
     * @property {string} [FirstName] GroupMember FirstName
     * @property {string} [LastName] GroupMember LastName
     * @property {boolean} [IsRegistered] GroupMember IsRegistered
     * @property {boolean} [IsDnd] GroupMember IsDnd
     * @property {string} [CurrentProfile] GroupMember CurrentProfile
     * @property {string} [CurrentStatus] GroupMember CurrentStatus
     * @property {boolean} [QueueStatus] GroupMember QueueStatus
     * @property {boolean} [IsBusy] GroupMember IsBusy
     * @property {ChatStatusType} [ChatStatus] GroupMember ChatStatus
     * @property {string} [MobileNumber] GroupMember MobileNumber
     * @property {string} [CurrentProfileInternalName] GroupMember CurrentProfileInternalName
     * @property {LocalConnections} [Connections] GroupMember Connections
     * @property {ForwardDestinationType} [AwayFwdType] GroupMember AwayFwdType
     * @property {string} [AwayNumber] GroupMember AwayNumber
     * @property {string} [EmailAddress] GroupMember EmailAddress
     * @property {boolean} [StatusTemporarilyChanged] GroupMember StatusTemporarilyChanged
     * @property {string} [OverrideCurrentProfile] GroupMember OverrideCurrentProfile
     * @property {string} [OverrideCurrentStatus] GroupMember OverrideCurrentStatus
     * @property {DateTime} [OverrideExpiresAtUTCTime] GroupMember OverrideExpiresAtUTCTime
     * @property {string} [OverrideCurrentProfileInternalName] GroupMember OverrideCurrentProfileInternalName
     * @property {string} [OverrideAttachedData] GroupMember OverrideAttachedData
     * @property {ForwardDestinationType} [OverrideFwdType] GroupMember OverrideFwdType
     * @property {string} [OverrideNumber] GroupMember OverrideNumber
     * @property {boolean} [IsRinging] GroupMember IsRinging
     * @property {string} [ContactImage] GroupMember ContactImage
     * @property {boolean} [IsInternal] GroupMember IsInternal
     * @property {boolean} [IsQueueAgent] GroupMember IsQueueAgent
     * @property {DateTime} [MonitorExpiration] GroupMember MonitorExpiration
     */

    /**
     * Constructs a new GroupMember.
     * @exports GroupMember
     * @classdesc Represents a GroupMember.
     * @constructor
     * @param {Partial<GroupMember>} [properties] Properties to set
     */
    function GroupMember(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GroupMember Action.
     * @member {ActionType} Action
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember Id.
     * @member {number} Id
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember ExtensionNumber.
     * @member {string} ExtensionNumber
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember FirstName.
     * @member {string} FirstName
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember LastName.
     * @member {string} LastName
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember IsRegistered.
     * @member {boolean} IsRegistered
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember IsDnd.
     * @member {boolean} IsDnd
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember CurrentProfile.
     * @member {string} CurrentProfile
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember CurrentStatus.
     * @member {string} CurrentStatus
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember QueueStatus.
     * @member {boolean} QueueStatus
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember IsBusy.
     * @member {boolean} IsBusy
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember ChatStatus.
     * @member {ChatStatusType} ChatStatus
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember MobileNumber.
     * @member {string} MobileNumber
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember CurrentProfileInternalName.
     * @member {string} CurrentProfileInternalName
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember Connections.
     * @member {LocalConnections} Connections
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember AwayFwdType.
     * @member {ForwardDestinationType} AwayFwdType
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember AwayNumber.
     * @member {string} AwayNumber
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember EmailAddress.
     * @member {string} EmailAddress
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember StatusTemporarilyChanged.
     * @member {boolean} StatusTemporarilyChanged
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember OverrideCurrentProfile.
     * @member {string} OverrideCurrentProfile
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember OverrideCurrentStatus.
     * @member {string} OverrideCurrentStatus
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember OverrideExpiresAtUTCTime.
     * @member {DateTime} OverrideExpiresAtUTCTime
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember OverrideCurrentProfileInternalName.
     * @member {string} OverrideCurrentProfileInternalName
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember OverrideAttachedData.
     * @member {string} OverrideAttachedData
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember OverrideFwdType.
     * @member {ForwardDestinationType} OverrideFwdType
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember OverrideNumber.
     * @member {string} OverrideNumber
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember IsRinging.
     * @member {boolean} IsRinging
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember ContactImage.
     * @member {string} ContactImage
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember IsInternal.
     * @member {boolean} IsInternal
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember IsQueueAgent.
     * @member {boolean} IsQueueAgent
     * @memberof GroupMember
     * @instance
     */

    /**
     * GroupMember MonitorExpiration.
     * @member {DateTime} MonitorExpiration
     * @memberof GroupMember
     * @instance
     */

    /**
     * Encodes the specified GroupMember message. Does not implicitly {@link GroupMember.verify|verify} messages.
     * @function encode
     * @memberof GroupMember
     * @static
     * @param {GroupMember} message GroupMember message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupMember.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.ExtensionNumber != null && Object.hasOwnProperty.call(message, "ExtensionNumber"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.ExtensionNumber);
        if (message.FirstName != null && Object.hasOwnProperty.call(message, "FirstName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.FirstName);
        if (message.LastName != null && Object.hasOwnProperty.call(message, "LastName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.LastName);
        if (message.IsRegistered != null && Object.hasOwnProperty.call(message, "IsRegistered"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.IsRegistered);
        if (message.IsDnd != null && Object.hasOwnProperty.call(message, "IsDnd"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.IsDnd);
        if (message.CurrentProfile != null && Object.hasOwnProperty.call(message, "CurrentProfile"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.CurrentProfile);
        if (message.CurrentStatus != null && Object.hasOwnProperty.call(message, "CurrentStatus"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.CurrentStatus);
        if (message.QueueStatus != null && Object.hasOwnProperty.call(message, "QueueStatus"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.QueueStatus);
        if (message.IsBusy != null && Object.hasOwnProperty.call(message, "IsBusy"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.IsBusy);
        if (message.ChatStatus != null && Object.hasOwnProperty.call(message, "ChatStatus"))
            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.ChatStatus);
        if (message.MobileNumber != null && Object.hasOwnProperty.call(message, "MobileNumber"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.MobileNumber);
        if (message.CurrentProfileInternalName != null && Object.hasOwnProperty.call(message, "CurrentProfileInternalName"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.CurrentProfileInternalName);
        if (message.Connections != null && Object.hasOwnProperty.call(message, "Connections"))
            $root.LocalConnections.encode(message.Connections, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.AwayFwdType != null && Object.hasOwnProperty.call(message, "AwayFwdType"))
            writer.uint32(/* id 16, wireType 0 =*/128).int32(message.AwayFwdType);
        if (message.AwayNumber != null && Object.hasOwnProperty.call(message, "AwayNumber"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.AwayNumber);
        if (message.EmailAddress != null && Object.hasOwnProperty.call(message, "EmailAddress"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.EmailAddress);
        if (message.StatusTemporarilyChanged != null && Object.hasOwnProperty.call(message, "StatusTemporarilyChanged"))
            writer.uint32(/* id 19, wireType 0 =*/152).bool(message.StatusTemporarilyChanged);
        if (message.OverrideCurrentProfile != null && Object.hasOwnProperty.call(message, "OverrideCurrentProfile"))
            writer.uint32(/* id 20, wireType 2 =*/162).string(message.OverrideCurrentProfile);
        if (message.OverrideCurrentStatus != null && Object.hasOwnProperty.call(message, "OverrideCurrentStatus"))
            writer.uint32(/* id 21, wireType 2 =*/170).string(message.OverrideCurrentStatus);
        if (message.OverrideExpiresAtUTCTime != null && Object.hasOwnProperty.call(message, "OverrideExpiresAtUTCTime"))
            $root.DateTime.encode(message.OverrideExpiresAtUTCTime, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        if (message.OverrideCurrentProfileInternalName != null && Object.hasOwnProperty.call(message, "OverrideCurrentProfileInternalName"))
            writer.uint32(/* id 23, wireType 2 =*/186).string(message.OverrideCurrentProfileInternalName);
        if (message.OverrideAttachedData != null && Object.hasOwnProperty.call(message, "OverrideAttachedData"))
            writer.uint32(/* id 24, wireType 2 =*/194).string(message.OverrideAttachedData);
        if (message.OverrideFwdType != null && Object.hasOwnProperty.call(message, "OverrideFwdType"))
            writer.uint32(/* id 25, wireType 0 =*/200).int32(message.OverrideFwdType);
        if (message.OverrideNumber != null && Object.hasOwnProperty.call(message, "OverrideNumber"))
            writer.uint32(/* id 26, wireType 2 =*/210).string(message.OverrideNumber);
        if (message.IsRinging != null && Object.hasOwnProperty.call(message, "IsRinging"))
            writer.uint32(/* id 27, wireType 0 =*/216).bool(message.IsRinging);
        if (message.ContactImage != null && Object.hasOwnProperty.call(message, "ContactImage"))
            writer.uint32(/* id 28, wireType 2 =*/226).string(message.ContactImage);
        if (message.IsInternal != null && Object.hasOwnProperty.call(message, "IsInternal"))
            writer.uint32(/* id 29, wireType 0 =*/232).bool(message.IsInternal);
        if (message.IsQueueAgent != null && Object.hasOwnProperty.call(message, "IsQueueAgent"))
            writer.uint32(/* id 30, wireType 0 =*/240).bool(message.IsQueueAgent);
        if (message.MonitorExpiration != null && Object.hasOwnProperty.call(message, "MonitorExpiration"))
            $root.DateTime.encode(message.MonitorExpiration, writer.uint32(/* id 31, wireType 2 =*/250).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a GroupMember message from the specified reader or buffer.
     * @function decode
     * @memberof GroupMember
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GroupMember} GroupMember
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupMember.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GroupMember();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.ExtensionNumber = reader.string();
                    break;
                }
            case 4: {
                    message.FirstName = reader.string();
                    break;
                }
            case 5: {
                    message.LastName = reader.string();
                    break;
                }
            case 6: {
                    message.IsRegistered = reader.bool();
                    break;
                }
            case 7: {
                    message.IsDnd = reader.bool();
                    break;
                }
            case 8: {
                    message.CurrentProfile = reader.string();
                    break;
                }
            case 9: {
                    message.CurrentStatus = reader.string();
                    break;
                }
            case 10: {
                    message.QueueStatus = reader.bool();
                    break;
                }
            case 11: {
                    message.IsBusy = reader.bool();
                    break;
                }
            case 12: {
                    message.ChatStatus = reader.int32();
                    break;
                }
            case 13: {
                    message.MobileNumber = reader.string();
                    break;
                }
            case 14: {
                    message.CurrentProfileInternalName = reader.string();
                    break;
                }
            case 15: {
                    message.Connections = $root.LocalConnections.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.AwayFwdType = reader.int32();
                    break;
                }
            case 17: {
                    message.AwayNumber = reader.string();
                    break;
                }
            case 18: {
                    message.EmailAddress = reader.string();
                    break;
                }
            case 19: {
                    message.StatusTemporarilyChanged = reader.bool();
                    break;
                }
            case 20: {
                    message.OverrideCurrentProfile = reader.string();
                    break;
                }
            case 21: {
                    message.OverrideCurrentStatus = reader.string();
                    break;
                }
            case 22: {
                    message.OverrideExpiresAtUTCTime = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 23: {
                    message.OverrideCurrentProfileInternalName = reader.string();
                    break;
                }
            case 24: {
                    message.OverrideAttachedData = reader.string();
                    break;
                }
            case 25: {
                    message.OverrideFwdType = reader.int32();
                    break;
                }
            case 26: {
                    message.OverrideNumber = reader.string();
                    break;
                }
            case 27: {
                    message.IsRinging = reader.bool();
                    break;
                }
            case 28: {
                    message.ContactImage = reader.string();
                    break;
                }
            case 29: {
                    message.IsInternal = reader.bool();
                    break;
                }
            case 30: {
                    message.IsQueueAgent = reader.bool();
                    break;
                }
            case 31: {
                    message.MonitorExpiration = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return GroupMember;
})();

export const GroupMembers = $root.GroupMembers = (() => {

    /**
     * Properties of a GroupMembers.
     * @exports IGroupMembers
     * @property {ActionType} Action GroupMembers Action
     * @property {Array.<GroupMember>} [Items] GroupMembers Items
     */

    /**
     * Constructs a new GroupMembers.
     * @exports GroupMembers
     * @classdesc Represents a GroupMembers.
     * @constructor
     * @param {Partial<GroupMembers>} [properties] Properties to set
     */
    function GroupMembers(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GroupMembers Action.
     * @member {ActionType} Action
     * @memberof GroupMembers
     * @instance
     */

    /**
     * GroupMembers Items.
     * @member {Array.<GroupMember>} Items
     * @memberof GroupMembers
     * @instance
     */
    GroupMembers.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified GroupMembers message. Does not implicitly {@link GroupMembers.verify|verify} messages.
     * @function encode
     * @memberof GroupMembers
     * @static
     * @param {GroupMembers} message GroupMembers message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupMembers.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.GroupMember.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a GroupMembers message from the specified reader or buffer.
     * @function decode
     * @memberof GroupMembers
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GroupMembers} GroupMembers
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupMembers.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GroupMembers();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.GroupMember.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return GroupMembers;
})();

export const Group = $root.Group = (() => {

    /**
     * Properties of a Group.
     * @exports IGroup
     * @property {ActionType} Action Group Action
     * @property {number} Id Group Id
     * @property {string} [Name] Group Name
     * @property {GroupMembers} [Members] Group Members
     * @property {string} [Language] Group Language
     * @property {GroupHoursMode} [CurrentGroupHours] Group CurrentGroupHours
     * @property {DateTime} [OverrideExpiresAt] Group OverrideExpiresAt
     * @property {ForwardDestination} [OpenedHoursOperator] Group OpenedHoursOperator
     * @property {ForwardDestination} [BreakTimeOperator] Group BreakTimeOperator
     * @property {ForwardDestination} [ClosedHoursOperator] Group ClosedHoursOperator
     * @property {ForwardDestination} [CustomOperator] Group CustomOperator
     * @property {string} [OpenedHoursPrompt] Group OpenedHoursPrompt
     * @property {string} [ClosedHoursPrompt] Group ClosedHoursPrompt
     * @property {string} [BreakTimePrompt] Group BreakTimePrompt
     * @property {string} [CustomPrompt] Group CustomPrompt
     * @property {boolean} [DisableCustomPrompt] Group DisableCustomPrompt
     * @property {boolean} [DisableOpenedHoursPrompt] Group DisableOpenedHoursPrompt
     * @property {boolean} [DisableClosedHoursPrompt] Group DisableClosedHoursPrompt
     * @property {boolean} [DisableBreakTimePrompt] Group DisableBreakTimePrompt
     * @property {boolean} [AllowCallService] Group AllowCallService
     */

    /**
     * Constructs a new Group.
     * @exports Group
     * @classdesc Represents a Group.
     * @constructor
     * @param {Partial<Group>} [properties] Properties to set
     */
    function Group(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Group Action.
     * @member {ActionType} Action
     * @memberof Group
     * @instance
     */

    /**
     * Group Id.
     * @member {number} Id
     * @memberof Group
     * @instance
     */

    /**
     * Group Name.
     * @member {string} Name
     * @memberof Group
     * @instance
     */

    /**
     * Group Members.
     * @member {GroupMembers} Members
     * @memberof Group
     * @instance
     */

    /**
     * Group Language.
     * @member {string} Language
     * @memberof Group
     * @instance
     */

    /**
     * Group CurrentGroupHours.
     * @member {GroupHoursMode} CurrentGroupHours
     * @memberof Group
     * @instance
     */

    /**
     * Group OverrideExpiresAt.
     * @member {DateTime} OverrideExpiresAt
     * @memberof Group
     * @instance
     */

    /**
     * Group OpenedHoursOperator.
     * @member {ForwardDestination} OpenedHoursOperator
     * @memberof Group
     * @instance
     */

    /**
     * Group BreakTimeOperator.
     * @member {ForwardDestination} BreakTimeOperator
     * @memberof Group
     * @instance
     */

    /**
     * Group ClosedHoursOperator.
     * @member {ForwardDestination} ClosedHoursOperator
     * @memberof Group
     * @instance
     */

    /**
     * Group CustomOperator.
     * @member {ForwardDestination} CustomOperator
     * @memberof Group
     * @instance
     */

    /**
     * Group OpenedHoursPrompt.
     * @member {string} OpenedHoursPrompt
     * @memberof Group
     * @instance
     */

    /**
     * Group ClosedHoursPrompt.
     * @member {string} ClosedHoursPrompt
     * @memberof Group
     * @instance
     */

    /**
     * Group BreakTimePrompt.
     * @member {string} BreakTimePrompt
     * @memberof Group
     * @instance
     */

    /**
     * Group CustomPrompt.
     * @member {string} CustomPrompt
     * @memberof Group
     * @instance
     */

    /**
     * Group DisableCustomPrompt.
     * @member {boolean} DisableCustomPrompt
     * @memberof Group
     * @instance
     */

    /**
     * Group DisableOpenedHoursPrompt.
     * @member {boolean} DisableOpenedHoursPrompt
     * @memberof Group
     * @instance
     */

    /**
     * Group DisableClosedHoursPrompt.
     * @member {boolean} DisableClosedHoursPrompt
     * @memberof Group
     * @instance
     */

    /**
     * Group DisableBreakTimePrompt.
     * @member {boolean} DisableBreakTimePrompt
     * @memberof Group
     * @instance
     */

    /**
     * Group AllowCallService.
     * @member {boolean} AllowCallService
     * @memberof Group
     * @instance
     */

    /**
     * Encodes the specified Group message. Does not implicitly {@link Group.verify|verify} messages.
     * @function encode
     * @memberof Group
     * @static
     * @param {Group} message Group message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Group.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Name);
        if (message.Members != null && Object.hasOwnProperty.call(message, "Members"))
            $root.GroupMembers.encode(message.Members, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.Language != null && Object.hasOwnProperty.call(message, "Language"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Language);
        if (message.CurrentGroupHours != null && Object.hasOwnProperty.call(message, "CurrentGroupHours"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.CurrentGroupHours);
        if (message.OverrideExpiresAt != null && Object.hasOwnProperty.call(message, "OverrideExpiresAt"))
            $root.DateTime.encode(message.OverrideExpiresAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.OpenedHoursOperator != null && Object.hasOwnProperty.call(message, "OpenedHoursOperator"))
            $root.ForwardDestination.encode(message.OpenedHoursOperator, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.BreakTimeOperator != null && Object.hasOwnProperty.call(message, "BreakTimeOperator"))
            $root.ForwardDestination.encode(message.BreakTimeOperator, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.ClosedHoursOperator != null && Object.hasOwnProperty.call(message, "ClosedHoursOperator"))
            $root.ForwardDestination.encode(message.ClosedHoursOperator, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.CustomOperator != null && Object.hasOwnProperty.call(message, "CustomOperator"))
            $root.ForwardDestination.encode(message.CustomOperator, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.OpenedHoursPrompt != null && Object.hasOwnProperty.call(message, "OpenedHoursPrompt"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.OpenedHoursPrompt);
        if (message.ClosedHoursPrompt != null && Object.hasOwnProperty.call(message, "ClosedHoursPrompt"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.ClosedHoursPrompt);
        if (message.BreakTimePrompt != null && Object.hasOwnProperty.call(message, "BreakTimePrompt"))
            writer.uint32(/* id 15, wireType 2 =*/122).string(message.BreakTimePrompt);
        if (message.CustomPrompt != null && Object.hasOwnProperty.call(message, "CustomPrompt"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.CustomPrompt);
        if (message.DisableCustomPrompt != null && Object.hasOwnProperty.call(message, "DisableCustomPrompt"))
            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.DisableCustomPrompt);
        if (message.DisableOpenedHoursPrompt != null && Object.hasOwnProperty.call(message, "DisableOpenedHoursPrompt"))
            writer.uint32(/* id 18, wireType 0 =*/144).bool(message.DisableOpenedHoursPrompt);
        if (message.DisableClosedHoursPrompt != null && Object.hasOwnProperty.call(message, "DisableClosedHoursPrompt"))
            writer.uint32(/* id 19, wireType 0 =*/152).bool(message.DisableClosedHoursPrompt);
        if (message.DisableBreakTimePrompt != null && Object.hasOwnProperty.call(message, "DisableBreakTimePrompt"))
            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.DisableBreakTimePrompt);
        if (message.AllowCallService != null && Object.hasOwnProperty.call(message, "AllowCallService"))
            writer.uint32(/* id 21, wireType 0 =*/168).bool(message.AllowCallService);
        return writer;
    };

    /**
     * Decodes a Group message from the specified reader or buffer.
     * @function decode
     * @memberof Group
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Group} Group
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Group.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Group();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Name = reader.string();
                    break;
                }
            case 5: {
                    message.Members = $root.GroupMembers.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.Language = reader.string();
                    break;
                }
            case 7: {
                    message.CurrentGroupHours = reader.int32();
                    break;
                }
            case 8: {
                    message.OverrideExpiresAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.OpenedHoursOperator = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.BreakTimeOperator = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.ClosedHoursOperator = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.CustomOperator = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.OpenedHoursPrompt = reader.string();
                    break;
                }
            case 14: {
                    message.ClosedHoursPrompt = reader.string();
                    break;
                }
            case 15: {
                    message.BreakTimePrompt = reader.string();
                    break;
                }
            case 16: {
                    message.CustomPrompt = reader.string();
                    break;
                }
            case 17: {
                    message.DisableCustomPrompt = reader.bool();
                    break;
                }
            case 18: {
                    message.DisableOpenedHoursPrompt = reader.bool();
                    break;
                }
            case 19: {
                    message.DisableClosedHoursPrompt = reader.bool();
                    break;
                }
            case 20: {
                    message.DisableBreakTimePrompt = reader.bool();
                    break;
                }
            case 21: {
                    message.AllowCallService = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return Group;
})();

export const Groups = $root.Groups = (() => {

    /**
     * Properties of a Groups.
     * @exports IGroups
     * @property {ActionType} Action Groups Action
     * @property {Array.<Group>} [Items] Groups Items
     * @property {boolean} FromLocalPbx Groups FromLocalPbx
     * @property {string} [RemotePbx] Groups RemotePbx
     * @property {string} [BridgeNumber] Groups BridgeNumber
     * @property {boolean} [IsConnected] Groups IsConnected
     * @property {boolean} [IsAllowed] Groups IsAllowed
     * @property {string} [Prefix] Groups Prefix
     */

    /**
     * Constructs a new Groups.
     * @exports Groups
     * @classdesc Represents a Groups.
     * @constructor
     * @param {Partial<Groups>} [properties] Properties to set
     */
    function Groups(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Groups Action.
     * @member {ActionType} Action
     * @memberof Groups
     * @instance
     */

    /**
     * Groups Items.
     * @member {Array.<Group>} Items
     * @memberof Groups
     * @instance
     */
    Groups.prototype.Items = $util.emptyArray;

    /**
     * Groups FromLocalPbx.
     * @member {boolean} FromLocalPbx
     * @memberof Groups
     * @instance
     */

    /**
     * Groups RemotePbx.
     * @member {string} RemotePbx
     * @memberof Groups
     * @instance
     */

    /**
     * Groups BridgeNumber.
     * @member {string} BridgeNumber
     * @memberof Groups
     * @instance
     */

    /**
     * Groups IsConnected.
     * @member {boolean} IsConnected
     * @memberof Groups
     * @instance
     */

    /**
     * Groups IsAllowed.
     * @member {boolean} IsAllowed
     * @memberof Groups
     * @instance
     */

    /**
     * Groups Prefix.
     * @member {string} Prefix
     * @memberof Groups
     * @instance
     */

    /**
     * Encodes the specified Groups message. Does not implicitly {@link Groups.verify|verify} messages.
     * @function encode
     * @memberof Groups
     * @static
     * @param {Groups} message Groups message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Groups.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.Group.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.FromLocalPbx);
        if (message.RemotePbx != null && Object.hasOwnProperty.call(message, "RemotePbx"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.RemotePbx);
        if (message.BridgeNumber != null && Object.hasOwnProperty.call(message, "BridgeNumber"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.BridgeNumber);
        if (message.IsConnected != null && Object.hasOwnProperty.call(message, "IsConnected"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.IsConnected);
        if (message.IsAllowed != null && Object.hasOwnProperty.call(message, "IsAllowed"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.IsAllowed);
        if (message.Prefix != null && Object.hasOwnProperty.call(message, "Prefix"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.Prefix);
        return writer;
    };

    /**
     * Decodes a Groups message from the specified reader or buffer.
     * @function decode
     * @memberof Groups
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Groups} Groups
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Groups.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Groups();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.Group.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.FromLocalPbx = reader.bool();
                    break;
                }
            case 4: {
                    message.RemotePbx = reader.string();
                    break;
                }
            case 5: {
                    message.BridgeNumber = reader.string();
                    break;
                }
            case 6: {
                    message.IsConnected = reader.bool();
                    break;
                }
            case 7: {
                    message.IsAllowed = reader.bool();
                    break;
                }
            case 8: {
                    message.Prefix = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a Groups message to GenericMessage
     * @function toGenericMessage
     * @memberof Groups
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    Groups.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 208, GroupsInfo : this});
    };

    /**
     * Groups typeId
     * @member {number} typeId
     * @memberof Groups
     * @instance
     */
    Groups.prototype.typeId = 208;
    /**
     * Groups typeName
     * @member {string} typeName
     * @memberof Groups
     * @instance
     */
    Groups.prototype.typeName = "GroupsInfo";

    return Groups;
})();

export const GroupId = $root.GroupId = (() => {

    /**
     * Properties of a GroupId.
     * @exports IGroupId
     * @property {ActionType} Action GroupId Action
     * @property {number} Id GroupId Id
     * @property {string} [Name] GroupId Name
     * @property {boolean} [AllowChangePresence] GroupId AllowChangePresence
     * @property {boolean} [ShowGroupMembers] GroupId ShowGroupMembers
     * @property {boolean} [ShowGroupCalls] GroupId ShowGroupCalls
     * @property {boolean} [ShowMyPresence] GroupId ShowMyPresence
     * @property {boolean} [ShowMyCalls] GroupId ShowMyCalls
     * @property {boolean} [PerformOperations] GroupId PerformOperations
     * @property {boolean} [AssignClearOperations] GroupId AssignClearOperations
     * @property {boolean} [AllowToManageCompanyBook] GroupId AllowToManageCompanyBook
     * @property {string} [RoleName] GroupId RoleName
     * @property {string} [Number] GroupId Number
     * @property {boolean} [IsPrimaryGroup] GroupId IsPrimaryGroup
     * @property {boolean} [CanSeeGroupRecordings] GroupId CanSeeGroupRecordings
     */

    /**
     * Constructs a new GroupId.
     * @exports GroupId
     * @classdesc Represents a GroupId.
     * @constructor
     * @param {Partial<GroupId>} [properties] Properties to set
     */
    function GroupId(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GroupId Action.
     * @member {ActionType} Action
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId Id.
     * @member {number} Id
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId Name.
     * @member {string} Name
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId AllowChangePresence.
     * @member {boolean} AllowChangePresence
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId ShowGroupMembers.
     * @member {boolean} ShowGroupMembers
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId ShowGroupCalls.
     * @member {boolean} ShowGroupCalls
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId ShowMyPresence.
     * @member {boolean} ShowMyPresence
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId ShowMyCalls.
     * @member {boolean} ShowMyCalls
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId PerformOperations.
     * @member {boolean} PerformOperations
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId AssignClearOperations.
     * @member {boolean} AssignClearOperations
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId AllowToManageCompanyBook.
     * @member {boolean} AllowToManageCompanyBook
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId RoleName.
     * @member {string} RoleName
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId Number.
     * @member {string} Number
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId IsPrimaryGroup.
     * @member {boolean} IsPrimaryGroup
     * @memberof GroupId
     * @instance
     */

    /**
     * GroupId CanSeeGroupRecordings.
     * @member {boolean} CanSeeGroupRecordings
     * @memberof GroupId
     * @instance
     */

    /**
     * Encodes the specified GroupId message. Does not implicitly {@link GroupId.verify|verify} messages.
     * @function encode
     * @memberof GroupId
     * @static
     * @param {GroupId} message GroupId message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupId.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Name);
        if (message.AllowChangePresence != null && Object.hasOwnProperty.call(message, "AllowChangePresence"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.AllowChangePresence);
        if (message.ShowGroupMembers != null && Object.hasOwnProperty.call(message, "ShowGroupMembers"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.ShowGroupMembers);
        if (message.ShowGroupCalls != null && Object.hasOwnProperty.call(message, "ShowGroupCalls"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.ShowGroupCalls);
        if (message.ShowMyPresence != null && Object.hasOwnProperty.call(message, "ShowMyPresence"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.ShowMyPresence);
        if (message.ShowMyCalls != null && Object.hasOwnProperty.call(message, "ShowMyCalls"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.ShowMyCalls);
        if (message.PerformOperations != null && Object.hasOwnProperty.call(message, "PerformOperations"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.PerformOperations);
        if (message.AssignClearOperations != null && Object.hasOwnProperty.call(message, "AssignClearOperations"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.AssignClearOperations);
        if (message.AllowToManageCompanyBook != null && Object.hasOwnProperty.call(message, "AllowToManageCompanyBook"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.AllowToManageCompanyBook);
        if (message.RoleName != null && Object.hasOwnProperty.call(message, "RoleName"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.RoleName);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.Number);
        if (message.IsPrimaryGroup != null && Object.hasOwnProperty.call(message, "IsPrimaryGroup"))
            writer.uint32(/* id 14, wireType 0 =*/112).bool(message.IsPrimaryGroup);
        if (message.CanSeeGroupRecordings != null && Object.hasOwnProperty.call(message, "CanSeeGroupRecordings"))
            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.CanSeeGroupRecordings);
        return writer;
    };

    /**
     * Decodes a GroupId message from the specified reader or buffer.
     * @function decode
     * @memberof GroupId
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GroupId} GroupId
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupId.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GroupId();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Name = reader.string();
                    break;
                }
            case 4: {
                    message.AllowChangePresence = reader.bool();
                    break;
                }
            case 5: {
                    message.ShowGroupMembers = reader.bool();
                    break;
                }
            case 6: {
                    message.ShowGroupCalls = reader.bool();
                    break;
                }
            case 7: {
                    message.ShowMyPresence = reader.bool();
                    break;
                }
            case 8: {
                    message.ShowMyCalls = reader.bool();
                    break;
                }
            case 9: {
                    message.PerformOperations = reader.bool();
                    break;
                }
            case 10: {
                    message.AssignClearOperations = reader.bool();
                    break;
                }
            case 11: {
                    message.AllowToManageCompanyBook = reader.bool();
                    break;
                }
            case 12: {
                    message.RoleName = reader.string();
                    break;
                }
            case 13: {
                    message.Number = reader.string();
                    break;
                }
            case 14: {
                    message.IsPrimaryGroup = reader.bool();
                    break;
                }
            case 15: {
                    message.CanSeeGroupRecordings = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return GroupId;
})();

export const GroupIds = $root.GroupIds = (() => {

    /**
     * Properties of a GroupIds.
     * @exports IGroupIds
     * @property {ActionType} Action GroupIds Action
     * @property {Array.<GroupId>} [Items] GroupIds Items
     */

    /**
     * Constructs a new GroupIds.
     * @exports GroupIds
     * @classdesc Represents a GroupIds.
     * @constructor
     * @param {Partial<GroupIds>} [properties] Properties to set
     */
    function GroupIds(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GroupIds Action.
     * @member {ActionType} Action
     * @memberof GroupIds
     * @instance
     */

    /**
     * GroupIds Items.
     * @member {Array.<GroupId>} Items
     * @memberof GroupIds
     * @instance
     */
    GroupIds.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified GroupIds message. Does not implicitly {@link GroupIds.verify|verify} messages.
     * @function encode
     * @memberof GroupIds
     * @static
     * @param {GroupIds} message GroupIds message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GroupIds.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.GroupId.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a GroupIds message from the specified reader or buffer.
     * @function decode
     * @memberof GroupIds
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GroupIds} GroupIds
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GroupIds.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GroupIds();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.GroupId.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return GroupIds;
})();

export const CallParticipant = $root.CallParticipant = (() => {

    /**
     * Properties of a CallParticipant.
     * @exports ICallParticipant
     * @property {ActionType} Action CallParticipant Action
     * @property {number} Id CallParticipant Id
     * @property {string} [CallerName] CallParticipant CallerName
     * @property {string} [CallerID] CallParticipant CallerID
     */

    /**
     * Constructs a new CallParticipant.
     * @exports CallParticipant
     * @classdesc Represents a CallParticipant.
     * @constructor
     * @param {Partial<CallParticipant>} [properties] Properties to set
     */
    function CallParticipant(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CallParticipant Action.
     * @member {ActionType} Action
     * @memberof CallParticipant
     * @instance
     */

    /**
     * CallParticipant Id.
     * @member {number} Id
     * @memberof CallParticipant
     * @instance
     */

    /**
     * CallParticipant CallerName.
     * @member {string} CallerName
     * @memberof CallParticipant
     * @instance
     */

    /**
     * CallParticipant CallerID.
     * @member {string} CallerID
     * @memberof CallParticipant
     * @instance
     */

    /**
     * Encodes the specified CallParticipant message. Does not implicitly {@link CallParticipant.verify|verify} messages.
     * @function encode
     * @memberof CallParticipant
     * @static
     * @param {CallParticipant} message CallParticipant message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CallParticipant.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.CallerName != null && Object.hasOwnProperty.call(message, "CallerName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.CallerName);
        if (message.CallerID != null && Object.hasOwnProperty.call(message, "CallerID"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.CallerID);
        return writer;
    };

    /**
     * Decodes a CallParticipant message from the specified reader or buffer.
     * @function decode
     * @memberof CallParticipant
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CallParticipant} CallParticipant
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CallParticipant.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CallParticipant();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.CallerName = reader.string();
                    break;
                }
            case 4: {
                    message.CallerID = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return CallParticipant;
})();

export const CallParticipants = $root.CallParticipants = (() => {

    /**
     * Properties of a CallParticipants.
     * @exports ICallParticipants
     * @property {ActionType} Action CallParticipants Action
     * @property {Array.<CallParticipant>} [Items] CallParticipants Items
     */

    /**
     * Constructs a new CallParticipants.
     * @exports CallParticipants
     * @classdesc Represents a CallParticipants.
     * @constructor
     * @param {Partial<CallParticipants>} [properties] Properties to set
     */
    function CallParticipants(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CallParticipants Action.
     * @member {ActionType} Action
     * @memberof CallParticipants
     * @instance
     */

    /**
     * CallParticipants Items.
     * @member {Array.<CallParticipant>} Items
     * @memberof CallParticipants
     * @instance
     */
    CallParticipants.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified CallParticipants message. Does not implicitly {@link CallParticipants.verify|verify} messages.
     * @function encode
     * @memberof CallParticipants
     * @static
     * @param {CallParticipants} message CallParticipants message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CallParticipants.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.CallParticipant.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a CallParticipants message from the specified reader or buffer.
     * @function decode
     * @memberof CallParticipants
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CallParticipants} CallParticipants
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CallParticipants.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CallParticipants();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.CallParticipant.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return CallParticipants;
})();

/**
 * CallState enum.
 * @exports CallState
 * @enum {string}
 * @property {number} Unknown=0 Unknown value
 * @property {number} Initiating=1 Initiating value
 * @property {number} Routing=2 Routing value
 * @property {number} Talking=3 Talking value
 * @property {number} Transferring=4 Transferring value
 * @property {number} Rerouting=5 Rerouting value
 */
export const CallState = $root.CallState = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "Unknown"] = 0;
    values[valuesById[1] = "Initiating"] = 1;
    values[valuesById[2] = "Routing"] = 2;
    values[valuesById[3] = "Talking"] = 3;
    values[valuesById[4] = "Transferring"] = 4;
    values[valuesById[5] = "Rerouting"] = 5;
    return values;
})();

/**
 * LocalConnectionState enum.
 * @exports LocalConnectionState
 * @enum {string}
 * @property {number} UnknownState=0 UnknownState value
 * @property {number} Ringing=1 Ringing value
 * @property {number} Dialing=2 Dialing value
 * @property {number} Connected=3 Connected value
 * @property {number} WaitingForNewParty=4 WaitingForNewParty value
 * @property {number} TryingToTransfer=5 TryingToTransfer value
 */
export const LocalConnectionState = $root.LocalConnectionState = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "UnknownState"] = 0;
    values[valuesById[1] = "Ringing"] = 1;
    values[valuesById[2] = "Dialing"] = 2;
    values[valuesById[3] = "Connected"] = 3;
    values[valuesById[4] = "WaitingForNewParty"] = 4;
    values[valuesById[5] = "TryingToTransfer"] = 5;
    return values;
})();

/**
 * ConnectionCapabilities enum.
 * @exports ConnectionCapabilities
 * @enum {string}
 * @property {number} CC_None=0 CC_None value
 * @property {number} CC_Drop=1 CC_Drop value
 * @property {number} CC_Divert=2 CC_Divert value
 * @property {number} CC_Transfer=4 CC_Transfer value
 * @property {number} CC_Pickup=8 CC_Pickup value
 * @property {number} CC_BargeIn=16 CC_BargeIn value
 * @property {number} CC_Quality=32 CC_Quality value
 */
export const ConnectionCapabilities = $root.ConnectionCapabilities = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CC_None"] = 0;
    values[valuesById[1] = "CC_Drop"] = 1;
    values[valuesById[2] = "CC_Divert"] = 2;
    values[valuesById[4] = "CC_Transfer"] = 4;
    values[valuesById[8] = "CC_Pickup"] = 8;
    values[valuesById[16] = "CC_BargeIn"] = 16;
    values[valuesById[32] = "CC_Quality"] = 32;
    return values;
})();

/**
 * LocalConnectionConferenceType enum.
 * @exports LocalConnectionConferenceType
 * @enum {string}
 * @property {number} LCCT_NotAConference=0 LCCT_NotAConference value
 * @property {number} LCCT_AdHocPublic=1 LCCT_AdHocPublic value
 * @property {number} LCCT_AdHocPrivate=2 LCCT_AdHocPrivate value
 * @property {number} LCCT_Scheduled=3 LCCT_Scheduled value
 */
export const LocalConnectionConferenceType = $root.LocalConnectionConferenceType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "LCCT_NotAConference"] = 0;
    values[valuesById[1] = "LCCT_AdHocPublic"] = 1;
    values[valuesById[2] = "LCCT_AdHocPrivate"] = 2;
    values[valuesById[3] = "LCCT_Scheduled"] = 3;
    return values;
})();

export const LocalConnection = $root.LocalConnection = (() => {

    /**
     * Properties of a LocalConnection.
     * @exports ILocalConnection
     * @property {ActionType} Action LocalConnection Action
     * @property {number} Id LocalConnection Id
     * @property {number} [CallId] LocalConnection CallId
     * @property {number} [LegId] LocalConnection LegId
     * @property {LocalConnectionState} [State] LocalConnection State
     * @property {DnType} [OriginatorType] LocalConnection OriginatorType
     * @property {string} [OriginatorDn] LocalConnection OriginatorDn
     * @property {string} [OriginatorName] LocalConnection OriginatorName
     * @property {DnType} [OtherPartyType] LocalConnection OtherPartyType
     * @property {string} [OtherPartyDisplayName] LocalConnection OtherPartyDisplayName
     * @property {string} [OtherPartyCallerId] LocalConnection OtherPartyCallerId
     * @property {boolean} [IsIncoming] LocalConnection IsIncoming
     * @property {DateTime} [StartedAt] LocalConnection StartedAt
     * @property {DateTime} [AnsweredAt] LocalConnection AnsweredAt
     * @property {DnType} [OwnerType] LocalConnection OwnerType
     * @property {string} [OwnerDn] LocalConnection OwnerDn
     * @property {string} [OwnerDisplayName] LocalConnection OwnerDisplayName
     * @property {string} [LocalID] LocalConnection LocalID
     * @property {number} [CallCapabilitiesMask] LocalConnection CallCapabilitiesMask
     * @property {number} [ReplacesCall] LocalConnection ReplacesCall
     * @property {number} [ReplacesLeg] LocalConnection ReplacesLeg
     * @property {string} [OtherPartyDn] LocalConnection OtherPartyDn
     * @property {string} [OwnerCallerId] LocalConnection OwnerCallerId
     * @property {boolean} [SLABreach] LocalConnection SLABreach
     * @property {string} [DeviceContact] LocalConnection DeviceContact
     * @property {boolean} [Recording] LocalConnection Recording
     * @property {string} [tag3cx] LocalConnection tag3cx
     * @property {boolean} [RecordingPaused] LocalConnection RecordingPaused
     * @property {boolean} [DeviceDirectControl] LocalConnection DeviceDirectControl
     * @property {string} [OtherPartyCompanyContactRefs] LocalConnection OtherPartyCompanyContactRefs
     * @property {string} [SIPDialogID] LocalConnection SIPDialogID
     * @property {string} [CrmContactUrl] LocalConnection CrmContactUrl
     * @property {number} [ConferenceStateID] LocalConnection ConferenceStateID
     * @property {LocalConnectionConferenceType} [ConferenceType] LocalConnection ConferenceType
     * @property {string} [ConferenceName] LocalConnection ConferenceName
     * @property {string} [ConferenceOwner] LocalConnection ConferenceOwner
     */

    /**
     * Constructs a new LocalConnection.
     * @exports LocalConnection
     * @classdesc Represents a LocalConnection.
     * @constructor
     * @param {Partial<LocalConnection>} [properties] Properties to set
     */
    function LocalConnection(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LocalConnection Action.
     * @member {ActionType} Action
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection Id.
     * @member {number} Id
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection CallId.
     * @member {number} CallId
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection LegId.
     * @member {number} LegId
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection State.
     * @member {LocalConnectionState} State
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OriginatorType.
     * @member {DnType} OriginatorType
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OriginatorDn.
     * @member {string} OriginatorDn
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OriginatorName.
     * @member {string} OriginatorName
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OtherPartyType.
     * @member {DnType} OtherPartyType
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OtherPartyDisplayName.
     * @member {string} OtherPartyDisplayName
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OtherPartyCallerId.
     * @member {string} OtherPartyCallerId
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection IsIncoming.
     * @member {boolean} IsIncoming
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection StartedAt.
     * @member {DateTime} StartedAt
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection AnsweredAt.
     * @member {DateTime} AnsweredAt
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OwnerType.
     * @member {DnType} OwnerType
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OwnerDn.
     * @member {string} OwnerDn
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OwnerDisplayName.
     * @member {string} OwnerDisplayName
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection LocalID.
     * @member {string} LocalID
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection CallCapabilitiesMask.
     * @member {number} CallCapabilitiesMask
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection ReplacesCall.
     * @member {number} ReplacesCall
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection ReplacesLeg.
     * @member {number} ReplacesLeg
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OtherPartyDn.
     * @member {string} OtherPartyDn
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OwnerCallerId.
     * @member {string} OwnerCallerId
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection SLABreach.
     * @member {boolean} SLABreach
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection DeviceContact.
     * @member {string} DeviceContact
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection Recording.
     * @member {boolean} Recording
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection tag3cx.
     * @member {string} tag3cx
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection RecordingPaused.
     * @member {boolean} RecordingPaused
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection DeviceDirectControl.
     * @member {boolean} DeviceDirectControl
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection OtherPartyCompanyContactRefs.
     * @member {string} OtherPartyCompanyContactRefs
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection SIPDialogID.
     * @member {string} SIPDialogID
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection CrmContactUrl.
     * @member {string} CrmContactUrl
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection ConferenceStateID.
     * @member {number} ConferenceStateID
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection ConferenceType.
     * @member {LocalConnectionConferenceType} ConferenceType
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection ConferenceName.
     * @member {string} ConferenceName
     * @memberof LocalConnection
     * @instance
     */

    /**
     * LocalConnection ConferenceOwner.
     * @member {string} ConferenceOwner
     * @memberof LocalConnection
     * @instance
     */

    /**
     * Encodes the specified LocalConnection message. Does not implicitly {@link LocalConnection.verify|verify} messages.
     * @function encode
     * @memberof LocalConnection
     * @static
     * @param {LocalConnection} message LocalConnection message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LocalConnection.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.CallId != null && Object.hasOwnProperty.call(message, "CallId"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.CallId);
        if (message.LegId != null && Object.hasOwnProperty.call(message, "LegId"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.LegId);
        if (message.State != null && Object.hasOwnProperty.call(message, "State"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.State);
        if (message.OriginatorType != null && Object.hasOwnProperty.call(message, "OriginatorType"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.OriginatorType);
        if (message.OriginatorDn != null && Object.hasOwnProperty.call(message, "OriginatorDn"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.OriginatorDn);
        if (message.OriginatorName != null && Object.hasOwnProperty.call(message, "OriginatorName"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.OriginatorName);
        if (message.OtherPartyType != null && Object.hasOwnProperty.call(message, "OtherPartyType"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.OtherPartyType);
        if (message.OtherPartyDisplayName != null && Object.hasOwnProperty.call(message, "OtherPartyDisplayName"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.OtherPartyDisplayName);
        if (message.OtherPartyCallerId != null && Object.hasOwnProperty.call(message, "OtherPartyCallerId"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.OtherPartyCallerId);
        if (message.IsIncoming != null && Object.hasOwnProperty.call(message, "IsIncoming"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.IsIncoming);
        if (message.StartedAt != null && Object.hasOwnProperty.call(message, "StartedAt"))
            $root.DateTime.encode(message.StartedAt, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.AnsweredAt != null && Object.hasOwnProperty.call(message, "AnsweredAt"))
            $root.DateTime.encode(message.AnsweredAt, writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.OwnerType != null && Object.hasOwnProperty.call(message, "OwnerType"))
            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.OwnerType);
        if (message.OwnerDn != null && Object.hasOwnProperty.call(message, "OwnerDn"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.OwnerDn);
        if (message.OwnerDisplayName != null && Object.hasOwnProperty.call(message, "OwnerDisplayName"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.OwnerDisplayName);
        if (message.LocalID != null && Object.hasOwnProperty.call(message, "LocalID"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.LocalID);
        if (message.CallCapabilitiesMask != null && Object.hasOwnProperty.call(message, "CallCapabilitiesMask"))
            writer.uint32(/* id 19, wireType 0 =*/152).int32(message.CallCapabilitiesMask);
        if (message.ReplacesCall != null && Object.hasOwnProperty.call(message, "ReplacesCall"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.ReplacesCall);
        if (message.ReplacesLeg != null && Object.hasOwnProperty.call(message, "ReplacesLeg"))
            writer.uint32(/* id 21, wireType 0 =*/168).int32(message.ReplacesLeg);
        if (message.OtherPartyDn != null && Object.hasOwnProperty.call(message, "OtherPartyDn"))
            writer.uint32(/* id 22, wireType 2 =*/178).string(message.OtherPartyDn);
        if (message.OwnerCallerId != null && Object.hasOwnProperty.call(message, "OwnerCallerId"))
            writer.uint32(/* id 23, wireType 2 =*/186).string(message.OwnerCallerId);
        if (message.SLABreach != null && Object.hasOwnProperty.call(message, "SLABreach"))
            writer.uint32(/* id 24, wireType 0 =*/192).bool(message.SLABreach);
        if (message.DeviceContact != null && Object.hasOwnProperty.call(message, "DeviceContact"))
            writer.uint32(/* id 25, wireType 2 =*/202).string(message.DeviceContact);
        if (message.Recording != null && Object.hasOwnProperty.call(message, "Recording"))
            writer.uint32(/* id 26, wireType 0 =*/208).bool(message.Recording);
        if (message.tag3cx != null && Object.hasOwnProperty.call(message, "tag3cx"))
            writer.uint32(/* id 27, wireType 2 =*/218).string(message.tag3cx);
        if (message.RecordingPaused != null && Object.hasOwnProperty.call(message, "RecordingPaused"))
            writer.uint32(/* id 28, wireType 0 =*/224).bool(message.RecordingPaused);
        if (message.DeviceDirectControl != null && Object.hasOwnProperty.call(message, "DeviceDirectControl"))
            writer.uint32(/* id 29, wireType 0 =*/232).bool(message.DeviceDirectControl);
        if (message.OtherPartyCompanyContactRefs != null && Object.hasOwnProperty.call(message, "OtherPartyCompanyContactRefs"))
            writer.uint32(/* id 30, wireType 2 =*/242).string(message.OtherPartyCompanyContactRefs);
        if (message.SIPDialogID != null && Object.hasOwnProperty.call(message, "SIPDialogID"))
            writer.uint32(/* id 32, wireType 2 =*/258).string(message.SIPDialogID);
        if (message.CrmContactUrl != null && Object.hasOwnProperty.call(message, "CrmContactUrl"))
            writer.uint32(/* id 33, wireType 2 =*/266).string(message.CrmContactUrl);
        if (message.ConferenceStateID != null && Object.hasOwnProperty.call(message, "ConferenceStateID"))
            writer.uint32(/* id 34, wireType 0 =*/272).int32(message.ConferenceStateID);
        if (message.ConferenceType != null && Object.hasOwnProperty.call(message, "ConferenceType"))
            writer.uint32(/* id 35, wireType 0 =*/280).int32(message.ConferenceType);
        if (message.ConferenceName != null && Object.hasOwnProperty.call(message, "ConferenceName"))
            writer.uint32(/* id 36, wireType 2 =*/290).string(message.ConferenceName);
        if (message.ConferenceOwner != null && Object.hasOwnProperty.call(message, "ConferenceOwner"))
            writer.uint32(/* id 37, wireType 2 =*/298).string(message.ConferenceOwner);
        return writer;
    };

    /**
     * Decodes a LocalConnection message from the specified reader or buffer.
     * @function decode
     * @memberof LocalConnection
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LocalConnection} LocalConnection
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LocalConnection.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.LocalConnection();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.CallId = reader.int32();
                    break;
                }
            case 4: {
                    message.LegId = reader.int32();
                    break;
                }
            case 5: {
                    message.State = reader.int32();
                    break;
                }
            case 6: {
                    message.OriginatorType = reader.int32();
                    break;
                }
            case 7: {
                    message.OriginatorDn = reader.string();
                    break;
                }
            case 8: {
                    message.OriginatorName = reader.string();
                    break;
                }
            case 9: {
                    message.OtherPartyType = reader.int32();
                    break;
                }
            case 10: {
                    message.OtherPartyDisplayName = reader.string();
                    break;
                }
            case 11: {
                    message.OtherPartyCallerId = reader.string();
                    break;
                }
            case 12: {
                    message.IsIncoming = reader.bool();
                    break;
                }
            case 13: {
                    message.StartedAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.AnsweredAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 15: {
                    message.OwnerType = reader.int32();
                    break;
                }
            case 16: {
                    message.OwnerDn = reader.string();
                    break;
                }
            case 17: {
                    message.OwnerDisplayName = reader.string();
                    break;
                }
            case 18: {
                    message.LocalID = reader.string();
                    break;
                }
            case 19: {
                    message.CallCapabilitiesMask = reader.int32();
                    break;
                }
            case 20: {
                    message.ReplacesCall = reader.int32();
                    break;
                }
            case 21: {
                    message.ReplacesLeg = reader.int32();
                    break;
                }
            case 22: {
                    message.OtherPartyDn = reader.string();
                    break;
                }
            case 23: {
                    message.OwnerCallerId = reader.string();
                    break;
                }
            case 24: {
                    message.SLABreach = reader.bool();
                    break;
                }
            case 25: {
                    message.DeviceContact = reader.string();
                    break;
                }
            case 26: {
                    message.Recording = reader.bool();
                    break;
                }
            case 27: {
                    message.tag3cx = reader.string();
                    break;
                }
            case 28: {
                    message.RecordingPaused = reader.bool();
                    break;
                }
            case 29: {
                    message.DeviceDirectControl = reader.bool();
                    break;
                }
            case 30: {
                    message.OtherPartyCompanyContactRefs = reader.string();
                    break;
                }
            case 32: {
                    message.SIPDialogID = reader.string();
                    break;
                }
            case 33: {
                    message.CrmContactUrl = reader.string();
                    break;
                }
            case 34: {
                    message.ConferenceStateID = reader.int32();
                    break;
                }
            case 35: {
                    message.ConferenceType = reader.int32();
                    break;
                }
            case 36: {
                    message.ConferenceName = reader.string();
                    break;
                }
            case 37: {
                    message.ConferenceOwner = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return LocalConnection;
})();

export const LocalConnections = $root.LocalConnections = (() => {

    /**
     * Properties of a LocalConnections.
     * @exports ILocalConnections
     * @property {ActionType} Action LocalConnections Action
     * @property {Array.<LocalConnection>} [Items] LocalConnections Items
     */

    /**
     * Constructs a new LocalConnections.
     * @exports LocalConnections
     * @classdesc Represents a LocalConnections.
     * @constructor
     * @param {Partial<LocalConnections>} [properties] Properties to set
     */
    function LocalConnections(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * LocalConnections Action.
     * @member {ActionType} Action
     * @memberof LocalConnections
     * @instance
     */

    /**
     * LocalConnections Items.
     * @member {Array.<LocalConnection>} Items
     * @memberof LocalConnections
     * @instance
     */
    LocalConnections.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified LocalConnections message. Does not implicitly {@link LocalConnections.verify|verify} messages.
     * @function encode
     * @memberof LocalConnections
     * @static
     * @param {LocalConnections} message LocalConnections message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    LocalConnections.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.LocalConnection.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a LocalConnections message from the specified reader or buffer.
     * @function decode
     * @memberof LocalConnections
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {LocalConnections} LocalConnections
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    LocalConnections.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.LocalConnections();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.LocalConnection.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return LocalConnections;
})();

export const Call = $root.Call = (() => {

    /**
     * Properties of a Call.
     * @exports ICall
     * @property {ActionType} Action Call Action
     * @property {number} Id Call Id
     * @property {string} [Owner] Call Owner
     * @property {string} [TalkingTo] Call TalkingTo
     * @property {string} [RoutingTo] Call RoutingTo
     * @property {DateTime} [StartedAt] Call StartedAt
     * @property {DateTime} [AnsweredAt] Call AnsweredAt
     */

    /**
     * Constructs a new Call.
     * @exports Call
     * @classdesc Represents a Call.
     * @constructor
     * @param {Partial<Call>} [properties] Properties to set
     */
    function Call(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Call Action.
     * @member {ActionType} Action
     * @memberof Call
     * @instance
     */

    /**
     * Call Id.
     * @member {number} Id
     * @memberof Call
     * @instance
     */

    /**
     * Call Owner.
     * @member {string} Owner
     * @memberof Call
     * @instance
     */

    /**
     * Call TalkingTo.
     * @member {string} TalkingTo
     * @memberof Call
     * @instance
     */

    /**
     * Call RoutingTo.
     * @member {string} RoutingTo
     * @memberof Call
     * @instance
     */

    /**
     * Call StartedAt.
     * @member {DateTime} StartedAt
     * @memberof Call
     * @instance
     */

    /**
     * Call AnsweredAt.
     * @member {DateTime} AnsweredAt
     * @memberof Call
     * @instance
     */

    /**
     * Encodes the specified Call message. Does not implicitly {@link Call.verify|verify} messages.
     * @function encode
     * @memberof Call
     * @static
     * @param {Call} message Call message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Call.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Owner != null && Object.hasOwnProperty.call(message, "Owner"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Owner);
        if (message.TalkingTo != null && Object.hasOwnProperty.call(message, "TalkingTo"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.TalkingTo);
        if (message.RoutingTo != null && Object.hasOwnProperty.call(message, "RoutingTo"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.RoutingTo);
        if (message.StartedAt != null && Object.hasOwnProperty.call(message, "StartedAt"))
            $root.DateTime.encode(message.StartedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.AnsweredAt != null && Object.hasOwnProperty.call(message, "AnsweredAt"))
            $root.DateTime.encode(message.AnsweredAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a Call message from the specified reader or buffer.
     * @function decode
     * @memberof Call
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Call} Call
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Call.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Call();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 4: {
                    message.Owner = reader.string();
                    break;
                }
            case 5: {
                    message.TalkingTo = reader.string();
                    break;
                }
            case 6: {
                    message.RoutingTo = reader.string();
                    break;
                }
            case 7: {
                    message.StartedAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.AnsweredAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return Call;
})();

export const Calls = $root.Calls = (() => {

    /**
     * Properties of a Calls.
     * @exports ICalls
     * @property {ActionType} Action Calls Action
     * @property {Array.<Call>} [Items] Calls Items
     */

    /**
     * Constructs a new Calls.
     * @exports Calls
     * @classdesc Represents a Calls.
     * @constructor
     * @param {Partial<Calls>} [properties] Properties to set
     */
    function Calls(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Calls Action.
     * @member {ActionType} Action
     * @memberof Calls
     * @instance
     */

    /**
     * Calls Items.
     * @member {Array.<Call>} Items
     * @memberof Calls
     * @instance
     */
    Calls.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified Calls message. Does not implicitly {@link Calls.verify|verify} messages.
     * @function encode
     * @memberof Calls
     * @static
     * @param {Calls} message Calls message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Calls.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.Call.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a Calls message from the specified reader or buffer.
     * @function decode
     * @memberof Calls
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Calls} Calls
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Calls.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Calls();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.Call.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return Calls;
})();

/**
 * ConferenceParticipantStatus enum.
 * @exports ConferenceParticipantStatus
 * @enum {string}
 * @property {number} CONNECTED=0 CONNECTED value
 * @property {number} MUTED=1 MUTED value
 * @property {number} HELDBYCONFERENCE=2 HELDBYCONFERENCE value
 * @property {number} HELDBYPARTICIPANT=3 HELDBYPARTICIPANT value
 * @property {number} JOINING=4 JOINING value
 * @property {number} FAILEDTOREACH=5 FAILEDTOREACH value
 * @property {number} DISCONNECTED=6 DISCONNECTED value
 */
export const ConferenceParticipantStatus = $root.ConferenceParticipantStatus = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "CONNECTED"] = 0;
    values[valuesById[1] = "MUTED"] = 1;
    values[valuesById[2] = "HELDBYCONFERENCE"] = 2;
    values[valuesById[3] = "HELDBYPARTICIPANT"] = 3;
    values[valuesById[4] = "JOINING"] = 4;
    values[valuesById[5] = "FAILEDTOREACH"] = 5;
    values[valuesById[6] = "DISCONNECTED"] = 6;
    return values;
})();

export const ConferenceParticipant = $root.ConferenceParticipant = (() => {

    /**
     * Properties of a ConferenceParticipant.
     * @exports IConferenceParticipant
     * @property {ActionType} Action ConferenceParticipant Action
     * @property {number} Id ConferenceParticipant Id
     * @property {string} [Number] ConferenceParticipant Number
     * @property {string} [DisplayName] ConferenceParticipant DisplayName
     * @property {ConferenceParticipantStatus} [Status] ConferenceParticipant Status
     */

    /**
     * Constructs a new ConferenceParticipant.
     * @exports ConferenceParticipant
     * @classdesc Represents a ConferenceParticipant.
     * @constructor
     * @param {Partial<ConferenceParticipant>} [properties] Properties to set
     */
    function ConferenceParticipant(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ConferenceParticipant Action.
     * @member {ActionType} Action
     * @memberof ConferenceParticipant
     * @instance
     */

    /**
     * ConferenceParticipant Id.
     * @member {number} Id
     * @memberof ConferenceParticipant
     * @instance
     */

    /**
     * ConferenceParticipant Number.
     * @member {string} Number
     * @memberof ConferenceParticipant
     * @instance
     */

    /**
     * ConferenceParticipant DisplayName.
     * @member {string} DisplayName
     * @memberof ConferenceParticipant
     * @instance
     */

    /**
     * ConferenceParticipant Status.
     * @member {ConferenceParticipantStatus} Status
     * @memberof ConferenceParticipant
     * @instance
     */

    /**
     * Encodes the specified ConferenceParticipant message. Does not implicitly {@link ConferenceParticipant.verify|verify} messages.
     * @function encode
     * @memberof ConferenceParticipant
     * @static
     * @param {ConferenceParticipant} message ConferenceParticipant message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ConferenceParticipant.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Number);
        if (message.DisplayName != null && Object.hasOwnProperty.call(message, "DisplayName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.DisplayName);
        if (message.Status != null && Object.hasOwnProperty.call(message, "Status"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.Status);
        return writer;
    };

    /**
     * Decodes a ConferenceParticipant message from the specified reader or buffer.
     * @function decode
     * @memberof ConferenceParticipant
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ConferenceParticipant} ConferenceParticipant
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ConferenceParticipant.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ConferenceParticipant();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Number = reader.string();
                    break;
                }
            case 4: {
                    message.DisplayName = reader.string();
                    break;
                }
            case 5: {
                    message.Status = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ConferenceParticipant;
})();

export const ConferenceParticipants = $root.ConferenceParticipants = (() => {

    /**
     * Properties of a ConferenceParticipants.
     * @exports IConferenceParticipants
     * @property {ActionType} Action ConferenceParticipants Action
     * @property {Array.<ConferenceParticipant>} [Items] ConferenceParticipants Items
     */

    /**
     * Constructs a new ConferenceParticipants.
     * @exports ConferenceParticipants
     * @classdesc Represents a ConferenceParticipants.
     * @constructor
     * @param {Partial<ConferenceParticipants>} [properties] Properties to set
     */
    function ConferenceParticipants(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ConferenceParticipants Action.
     * @member {ActionType} Action
     * @memberof ConferenceParticipants
     * @instance
     */

    /**
     * ConferenceParticipants Items.
     * @member {Array.<ConferenceParticipant>} Items
     * @memberof ConferenceParticipants
     * @instance
     */
    ConferenceParticipants.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified ConferenceParticipants message. Does not implicitly {@link ConferenceParticipants.verify|verify} messages.
     * @function encode
     * @memberof ConferenceParticipants
     * @static
     * @param {ConferenceParticipants} message ConferenceParticipants message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ConferenceParticipants.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.ConferenceParticipant.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ConferenceParticipants message from the specified reader or buffer.
     * @function decode
     * @memberof ConferenceParticipants
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ConferenceParticipants} ConferenceParticipants
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ConferenceParticipants.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ConferenceParticipants();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.ConferenceParticipant.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ConferenceParticipants;
})();

export const WebRtcInboundRule = $root.WebRtcInboundRule = (() => {

    /**
     * Properties of a WebRtcInboundRule.
     * @exports IWebRtcInboundRule
     * @property {ActionType} Action WebRtcInboundRule Action
     * @property {number} Id WebRtcInboundRule Id
     * @property {boolean} [IsEnabled] WebRtcInboundRule IsEnabled
     * @property {string} [DID] WebRtcInboundRule DID
     * @property {string} [CallUrl] WebRtcInboundRule CallUrl
     */

    /**
     * Constructs a new WebRtcInboundRule.
     * @exports WebRtcInboundRule
     * @classdesc Represents a WebRtcInboundRule.
     * @constructor
     * @param {Partial<WebRtcInboundRule>} [properties] Properties to set
     */
    function WebRtcInboundRule(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebRtcInboundRule Action.
     * @member {ActionType} Action
     * @memberof WebRtcInboundRule
     * @instance
     */

    /**
     * WebRtcInboundRule Id.
     * @member {number} Id
     * @memberof WebRtcInboundRule
     * @instance
     */

    /**
     * WebRtcInboundRule IsEnabled.
     * @member {boolean} IsEnabled
     * @memberof WebRtcInboundRule
     * @instance
     */

    /**
     * WebRtcInboundRule DID.
     * @member {string} DID
     * @memberof WebRtcInboundRule
     * @instance
     */

    /**
     * WebRtcInboundRule CallUrl.
     * @member {string} CallUrl
     * @memberof WebRtcInboundRule
     * @instance
     */

    /**
     * Encodes the specified WebRtcInboundRule message. Does not implicitly {@link WebRtcInboundRule.verify|verify} messages.
     * @function encode
     * @memberof WebRtcInboundRule
     * @static
     * @param {WebRtcInboundRule} message WebRtcInboundRule message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebRtcInboundRule.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.IsEnabled != null && Object.hasOwnProperty.call(message, "IsEnabled"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.IsEnabled);
        if (message.DID != null && Object.hasOwnProperty.call(message, "DID"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.DID);
        if (message.CallUrl != null && Object.hasOwnProperty.call(message, "CallUrl"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.CallUrl);
        return writer;
    };

    /**
     * Decodes a WebRtcInboundRule message from the specified reader or buffer.
     * @function decode
     * @memberof WebRtcInboundRule
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebRtcInboundRule} WebRtcInboundRule
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebRtcInboundRule.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebRtcInboundRule();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.IsEnabled = reader.bool();
                    break;
                }
            case 4: {
                    message.DID = reader.string();
                    break;
                }
            case 5: {
                    message.CallUrl = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return WebRtcInboundRule;
})();

export const WebRtcInboundRules = $root.WebRtcInboundRules = (() => {

    /**
     * Properties of a WebRtcInboundRules.
     * @exports IWebRtcInboundRules
     * @property {ActionType} Action WebRtcInboundRules Action
     * @property {Array.<WebRtcInboundRule>} [Items] WebRtcInboundRules Items
     */

    /**
     * Constructs a new WebRtcInboundRules.
     * @exports WebRtcInboundRules
     * @classdesc Represents a WebRtcInboundRules.
     * @constructor
     * @param {Partial<WebRtcInboundRules>} [properties] Properties to set
     */
    function WebRtcInboundRules(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebRtcInboundRules Action.
     * @member {ActionType} Action
     * @memberof WebRtcInboundRules
     * @instance
     */

    /**
     * WebRtcInboundRules Items.
     * @member {Array.<WebRtcInboundRule>} Items
     * @memberof WebRtcInboundRules
     * @instance
     */
    WebRtcInboundRules.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified WebRtcInboundRules message. Does not implicitly {@link WebRtcInboundRules.verify|verify} messages.
     * @function encode
     * @memberof WebRtcInboundRules
     * @static
     * @param {WebRtcInboundRules} message WebRtcInboundRules message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebRtcInboundRules.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.WebRtcInboundRule.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a WebRtcInboundRules message from the specified reader or buffer.
     * @function decode
     * @memberof WebRtcInboundRules
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebRtcInboundRules} WebRtcInboundRules
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebRtcInboundRules.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebRtcInboundRules();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.WebRtcInboundRule.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return WebRtcInboundRules;
})();

export const DIDRuleInfo = $root.DIDRuleInfo = (() => {

    /**
     * Properties of a DIDRuleInfo.
     * @exports IDIDRuleInfo
     * @property {ActionType} Action DIDRuleInfo Action
     * @property {number} Id DIDRuleInfo Id
     * @property {HoursType} [ActiveHoursType] DIDRuleInfo ActiveHoursType
     * @property {string} [DIDName] DIDRuleInfo DIDName
     * @property {string} [DIDNumber] DIDRuleInfo DIDNumber
     * @property {boolean} [CalledAtSpecifiedTime] DIDRuleInfo CalledAtSpecifiedTime
     * @property {boolean} [CalledOutOfSpecifiedTime] DIDRuleInfo CalledOutOfSpecifiedTime
     * @property {boolean} [CalledOnHolidays] DIDRuleInfo CalledOnHolidays
     */

    /**
     * Constructs a new DIDRuleInfo.
     * @exports DIDRuleInfo
     * @classdesc Represents a DIDRuleInfo.
     * @constructor
     * @param {Partial<DIDRuleInfo>} [properties] Properties to set
     */
    function DIDRuleInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DIDRuleInfo Action.
     * @member {ActionType} Action
     * @memberof DIDRuleInfo
     * @instance
     */

    /**
     * DIDRuleInfo Id.
     * @member {number} Id
     * @memberof DIDRuleInfo
     * @instance
     */

    /**
     * DIDRuleInfo ActiveHoursType.
     * @member {HoursType} ActiveHoursType
     * @memberof DIDRuleInfo
     * @instance
     */

    /**
     * DIDRuleInfo DIDName.
     * @member {string} DIDName
     * @memberof DIDRuleInfo
     * @instance
     */

    /**
     * DIDRuleInfo DIDNumber.
     * @member {string} DIDNumber
     * @memberof DIDRuleInfo
     * @instance
     */

    /**
     * DIDRuleInfo CalledAtSpecifiedTime.
     * @member {boolean} CalledAtSpecifiedTime
     * @memberof DIDRuleInfo
     * @instance
     */

    /**
     * DIDRuleInfo CalledOutOfSpecifiedTime.
     * @member {boolean} CalledOutOfSpecifiedTime
     * @memberof DIDRuleInfo
     * @instance
     */

    /**
     * DIDRuleInfo CalledOnHolidays.
     * @member {boolean} CalledOnHolidays
     * @memberof DIDRuleInfo
     * @instance
     */

    /**
     * Encodes the specified DIDRuleInfo message. Does not implicitly {@link DIDRuleInfo.verify|verify} messages.
     * @function encode
     * @memberof DIDRuleInfo
     * @static
     * @param {DIDRuleInfo} message DIDRuleInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DIDRuleInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.ActiveHoursType != null && Object.hasOwnProperty.call(message, "ActiveHoursType"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ActiveHoursType);
        if (message.DIDName != null && Object.hasOwnProperty.call(message, "DIDName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.DIDName);
        if (message.DIDNumber != null && Object.hasOwnProperty.call(message, "DIDNumber"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.DIDNumber);
        if (message.CalledAtSpecifiedTime != null && Object.hasOwnProperty.call(message, "CalledAtSpecifiedTime"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.CalledAtSpecifiedTime);
        if (message.CalledOutOfSpecifiedTime != null && Object.hasOwnProperty.call(message, "CalledOutOfSpecifiedTime"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.CalledOutOfSpecifiedTime);
        if (message.CalledOnHolidays != null && Object.hasOwnProperty.call(message, "CalledOnHolidays"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.CalledOnHolidays);
        return writer;
    };

    /**
     * Decodes a DIDRuleInfo message from the specified reader or buffer.
     * @function decode
     * @memberof DIDRuleInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DIDRuleInfo} DIDRuleInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DIDRuleInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DIDRuleInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.ActiveHoursType = reader.int32();
                    break;
                }
            case 4: {
                    message.DIDName = reader.string();
                    break;
                }
            case 5: {
                    message.DIDNumber = reader.string();
                    break;
                }
            case 6: {
                    message.CalledAtSpecifiedTime = reader.bool();
                    break;
                }
            case 7: {
                    message.CalledOutOfSpecifiedTime = reader.bool();
                    break;
                }
            case 8: {
                    message.CalledOnHolidays = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return DIDRuleInfo;
})();

export const DIDRuleInfos = $root.DIDRuleInfos = (() => {

    /**
     * Properties of a DIDRuleInfos.
     * @exports IDIDRuleInfos
     * @property {ActionType} Action DIDRuleInfos Action
     * @property {Array.<DIDRuleInfo>} [Items] DIDRuleInfos Items
     */

    /**
     * Constructs a new DIDRuleInfos.
     * @exports DIDRuleInfos
     * @classdesc Represents a DIDRuleInfos.
     * @constructor
     * @param {Partial<DIDRuleInfos>} [properties] Properties to set
     */
    function DIDRuleInfos(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * DIDRuleInfos Action.
     * @member {ActionType} Action
     * @memberof DIDRuleInfos
     * @instance
     */

    /**
     * DIDRuleInfos Items.
     * @member {Array.<DIDRuleInfo>} Items
     * @memberof DIDRuleInfos
     * @instance
     */
    DIDRuleInfos.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified DIDRuleInfos message. Does not implicitly {@link DIDRuleInfos.verify|verify} messages.
     * @function encode
     * @memberof DIDRuleInfos
     * @static
     * @param {DIDRuleInfos} message DIDRuleInfos message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    DIDRuleInfos.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.DIDRuleInfo.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a DIDRuleInfos message from the specified reader or buffer.
     * @function decode
     * @memberof DIDRuleInfos
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {DIDRuleInfos} DIDRuleInfos
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    DIDRuleInfos.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.DIDRuleInfos();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.DIDRuleInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return DIDRuleInfos;
})();

export const MyExtensionInfo = $root.MyExtensionInfo = (() => {

    /**
     * Properties of a MyExtensionInfo.
     * @exports IMyExtensionInfo
     * @property {ActionType} Action MyExtensionInfo Action
     * @property {number} Id MyExtensionInfo Id
     * @property {string} [Number] MyExtensionInfo Number
     * @property {string} [MobileNumber] MyExtensionInfo MobileNumber
     * @property {string} [Email] MyExtensionInfo Email
     * @property {boolean} [QueueStatus] MyExtensionInfo QueueStatus
     * @property {string} [FirstName] MyExtensionInfo FirstName
     * @property {string} [LastName] MyExtensionInfo LastName
     * @property {Registrations} [ActiveDevices] MyExtensionInfo ActiveDevices
     * @property {number} [CurrentProfile] MyExtensionInfo CurrentProfile
     * @property {VoiceMails} [VoiceMailBox] MyExtensionInfo VoiceMailBox
     * @property {GroupIds} [MyGroups] MyExtensionInfo MyGroups
     * @property {ForwardingProfiles} [MyProfiles] MyExtensionInfo MyProfiles
     * @property {boolean} [DND] MyExtensionInfo DND
     * @property {Calls} [MyCalls] MyExtensionInfo MyCalls
     * @property {number} [OfficeHours] MyExtensionInfo OfficeHours
     * @property {ChatStatusType} [ChatStatus] MyExtensionInfo ChatStatus
     * @property {LocalConnections} [Connections] MyExtensionInfo Connections
     * @property {LocalConnections} [OtherConnections] MyExtensionInfo OtherConnections
     * @property {ConferenceParticipants} [PrivateConference] MyExtensionInfo PrivateConference
     * @property {boolean} [AllowEditFwRules] MyExtensionInfo AllowEditFwRules
     * @property {string} [DeskphonePassword] MyExtensionInfo DeskphonePassword
     * @property {number} [MissedCallsCount] MyExtensionInfo MissedCallsCount
     * @property {string} [WebMeetingMD5Password] MyExtensionInfo WebMeetingMD5Password
     * @property {WebRtcInboundRules} [WebRtcInboundRules] MyExtensionInfo WebRtcInboundRules
     * @property {boolean} [HasCurrentProfileOverride] MyExtensionInfo HasCurrentProfileOverride
     * @property {number} [CurrentProfileOverride] MyExtensionInfo CurrentProfileOverride
     * @property {DateTime} [OverrideExpiresAtUTCTime] MyExtensionInfo OverrideExpiresAtUTCTime
     * @property {string} [OverrideCurrentStatus] MyExtensionInfo OverrideCurrentStatus
     * @property {string} [OverrideAttachedData] MyExtensionInfo OverrideAttachedData
     * @property {string} [SharedBlfs] MyExtensionInfo SharedBlfs
     * @property {string} [QuickMeetingLink] MyExtensionInfo QuickMeetingLink
     * @property {string} [WmFriendlyName] MyExtensionInfo WmFriendlyName
     * @property {boolean} [WmPrivateRooms] MyExtensionInfo WmPrivateRooms
     * @property {boolean} [WmModerateParticipants] MyExtensionInfo WmModerateParticipants
     * @property {string} [QuickMeetingOpenLink] MyExtensionInfo QuickMeetingOpenLink
     * @property {string} [ContactImage] MyExtensionInfo ContactImage
     * @property {boolean} [CanSeeRecordings] MyExtensionInfo CanSeeRecordings
     * @property {boolean} [CanDeleteRecordings] MyExtensionInfo CanDeleteRecordings
     * @property {string} [ProvLink] MyExtensionInfo ProvLink
     * @property {boolean} [AllowControlOwnRecordings] MyExtensionInfo AllowControlOwnRecordings
     * @property {PushSubscriptions} [Subscriptions] MyExtensionInfo Subscriptions
     * @property {boolean} [AllowWebrtcEndpoint] MyExtensionInfo AllowWebrtcEndpoint
     * @property {UserSettings} [Settings] MyExtensionInfo Settings
     * @property {DIDRuleInfos} [ActiveDids] MyExtensionInfo ActiveDids
     * @property {string} [Language] MyExtensionInfo Language
     * @property {boolean} [Office365Enabled] MyExtensionInfo Office365Enabled
     * @property {boolean} [HasExchangeLicense] MyExtensionInfo HasExchangeLicense
     * @property {DateTime} [MonitorExpiration] MyExtensionInfo MonitorExpiration
     */

    /**
     * Constructs a new MyExtensionInfo.
     * @exports MyExtensionInfo
     * @classdesc Represents a MyExtensionInfo.
     * @constructor
     * @param {Partial<MyExtensionInfo>} [properties] Properties to set
     */
    function MyExtensionInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MyExtensionInfo Action.
     * @member {ActionType} Action
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo Id.
     * @member {number} Id
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo Number.
     * @member {string} Number
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo MobileNumber.
     * @member {string} MobileNumber
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo Email.
     * @member {string} Email
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo QueueStatus.
     * @member {boolean} QueueStatus
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo FirstName.
     * @member {string} FirstName
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo LastName.
     * @member {string} LastName
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo ActiveDevices.
     * @member {Registrations} ActiveDevices
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo CurrentProfile.
     * @member {number} CurrentProfile
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo VoiceMailBox.
     * @member {VoiceMails} VoiceMailBox
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo MyGroups.
     * @member {GroupIds} MyGroups
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo MyProfiles.
     * @member {ForwardingProfiles} MyProfiles
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo DND.
     * @member {boolean} DND
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo MyCalls.
     * @member {Calls} MyCalls
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo OfficeHours.
     * @member {number} OfficeHours
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo ChatStatus.
     * @member {ChatStatusType} ChatStatus
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo Connections.
     * @member {LocalConnections} Connections
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo OtherConnections.
     * @member {LocalConnections} OtherConnections
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo PrivateConference.
     * @member {ConferenceParticipants} PrivateConference
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo AllowEditFwRules.
     * @member {boolean} AllowEditFwRules
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo DeskphonePassword.
     * @member {string} DeskphonePassword
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo MissedCallsCount.
     * @member {number} MissedCallsCount
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo WebMeetingMD5Password.
     * @member {string} WebMeetingMD5Password
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo WebRtcInboundRules.
     * @member {WebRtcInboundRules} WebRtcInboundRules
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo HasCurrentProfileOverride.
     * @member {boolean} HasCurrentProfileOverride
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo CurrentProfileOverride.
     * @member {number} CurrentProfileOverride
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo OverrideExpiresAtUTCTime.
     * @member {DateTime} OverrideExpiresAtUTCTime
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo OverrideCurrentStatus.
     * @member {string} OverrideCurrentStatus
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo OverrideAttachedData.
     * @member {string} OverrideAttachedData
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo SharedBlfs.
     * @member {string} SharedBlfs
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo QuickMeetingLink.
     * @member {string} QuickMeetingLink
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo WmFriendlyName.
     * @member {string} WmFriendlyName
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo WmPrivateRooms.
     * @member {boolean} WmPrivateRooms
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo WmModerateParticipants.
     * @member {boolean} WmModerateParticipants
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo QuickMeetingOpenLink.
     * @member {string} QuickMeetingOpenLink
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo ContactImage.
     * @member {string} ContactImage
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo CanSeeRecordings.
     * @member {boolean} CanSeeRecordings
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo CanDeleteRecordings.
     * @member {boolean} CanDeleteRecordings
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo ProvLink.
     * @member {string} ProvLink
     * @memberof MyExtensionInfo
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * MyExtensionInfo AllowControlOwnRecordings.
     * @member {boolean} AllowControlOwnRecordings
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo Subscriptions.
     * @member {PushSubscriptions} Subscriptions
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo AllowWebrtcEndpoint.
     * @member {boolean} AllowWebrtcEndpoint
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo Settings.
     * @member {UserSettings} Settings
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo ActiveDids.
     * @member {DIDRuleInfos} ActiveDids
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo Language.
     * @member {string} Language
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo Office365Enabled.
     * @member {boolean} Office365Enabled
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo HasExchangeLicense.
     * @member {boolean} HasExchangeLicense
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * MyExtensionInfo MonitorExpiration.
     * @member {DateTime} MonitorExpiration
     * @memberof MyExtensionInfo
     * @instance
     */

    /**
     * Encodes the specified MyExtensionInfo message. Does not implicitly {@link MyExtensionInfo.verify|verify} messages.
     * @function encode
     * @memberof MyExtensionInfo
     * @static
     * @param {MyExtensionInfo} message MyExtensionInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MyExtensionInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Number);
        if (message.MobileNumber != null && Object.hasOwnProperty.call(message, "MobileNumber"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.MobileNumber);
        if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Email);
        if (message.QueueStatus != null && Object.hasOwnProperty.call(message, "QueueStatus"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.QueueStatus);
        if (message.FirstName != null && Object.hasOwnProperty.call(message, "FirstName"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.FirstName);
        if (message.LastName != null && Object.hasOwnProperty.call(message, "LastName"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.LastName);
        if (message.ActiveDevices != null && Object.hasOwnProperty.call(message, "ActiveDevices"))
            $root.Registrations.encode(message.ActiveDevices, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.CurrentProfile != null && Object.hasOwnProperty.call(message, "CurrentProfile"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.CurrentProfile);
        if (message.VoiceMailBox != null && Object.hasOwnProperty.call(message, "VoiceMailBox"))
            $root.VoiceMails.encode(message.VoiceMailBox, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.MyGroups != null && Object.hasOwnProperty.call(message, "MyGroups"))
            $root.GroupIds.encode(message.MyGroups, writer.uint32(/* id 12, wireType 2 =*/98).fork()).ldelim();
        if (message.MyProfiles != null && Object.hasOwnProperty.call(message, "MyProfiles"))
            $root.ForwardingProfiles.encode(message.MyProfiles, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.DND != null && Object.hasOwnProperty.call(message, "DND"))
            writer.uint32(/* id 14, wireType 0 =*/112).bool(message.DND);
        if (message.MyCalls != null && Object.hasOwnProperty.call(message, "MyCalls"))
            $root.Calls.encode(message.MyCalls, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.OfficeHours != null && Object.hasOwnProperty.call(message, "OfficeHours"))
            writer.uint32(/* id 16, wireType 0 =*/128).int32(message.OfficeHours);
        if (message.ChatStatus != null && Object.hasOwnProperty.call(message, "ChatStatus"))
            writer.uint32(/* id 17, wireType 0 =*/136).int32(message.ChatStatus);
        if (message.Connections != null && Object.hasOwnProperty.call(message, "Connections"))
            $root.LocalConnections.encode(message.Connections, writer.uint32(/* id 18, wireType 2 =*/146).fork()).ldelim();
        if (message.OtherConnections != null && Object.hasOwnProperty.call(message, "OtherConnections"))
            $root.LocalConnections.encode(message.OtherConnections, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        if (message.PrivateConference != null && Object.hasOwnProperty.call(message, "PrivateConference"))
            $root.ConferenceParticipants.encode(message.PrivateConference, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        if (message.AllowEditFwRules != null && Object.hasOwnProperty.call(message, "AllowEditFwRules"))
            writer.uint32(/* id 22, wireType 0 =*/176).bool(message.AllowEditFwRules);
        if (message.DeskphonePassword != null && Object.hasOwnProperty.call(message, "DeskphonePassword"))
            writer.uint32(/* id 23, wireType 2 =*/186).string(message.DeskphonePassword);
        if (message.MissedCallsCount != null && Object.hasOwnProperty.call(message, "MissedCallsCount"))
            writer.uint32(/* id 24, wireType 0 =*/192).int32(message.MissedCallsCount);
        if (message.WebMeetingMD5Password != null && Object.hasOwnProperty.call(message, "WebMeetingMD5Password"))
            writer.uint32(/* id 25, wireType 2 =*/202).string(message.WebMeetingMD5Password);
        if (message.WebRtcInboundRules != null && Object.hasOwnProperty.call(message, "WebRtcInboundRules"))
            $root.WebRtcInboundRules.encode(message.WebRtcInboundRules, writer.uint32(/* id 26, wireType 2 =*/210).fork()).ldelim();
        if (message.HasCurrentProfileOverride != null && Object.hasOwnProperty.call(message, "HasCurrentProfileOverride"))
            writer.uint32(/* id 27, wireType 0 =*/216).bool(message.HasCurrentProfileOverride);
        if (message.CurrentProfileOverride != null && Object.hasOwnProperty.call(message, "CurrentProfileOverride"))
            writer.uint32(/* id 28, wireType 0 =*/224).int32(message.CurrentProfileOverride);
        if (message.OverrideExpiresAtUTCTime != null && Object.hasOwnProperty.call(message, "OverrideExpiresAtUTCTime"))
            $root.DateTime.encode(message.OverrideExpiresAtUTCTime, writer.uint32(/* id 29, wireType 2 =*/234).fork()).ldelim();
        if (message.OverrideCurrentStatus != null && Object.hasOwnProperty.call(message, "OverrideCurrentStatus"))
            writer.uint32(/* id 30, wireType 2 =*/242).string(message.OverrideCurrentStatus);
        if (message.OverrideAttachedData != null && Object.hasOwnProperty.call(message, "OverrideAttachedData"))
            writer.uint32(/* id 31, wireType 2 =*/250).string(message.OverrideAttachedData);
        if (message.SharedBlfs != null && Object.hasOwnProperty.call(message, "SharedBlfs"))
            writer.uint32(/* id 32, wireType 2 =*/258).string(message.SharedBlfs);
        if (message.QuickMeetingLink != null && Object.hasOwnProperty.call(message, "QuickMeetingLink"))
            writer.uint32(/* id 33, wireType 2 =*/266).string(message.QuickMeetingLink);
        if (message.WmFriendlyName != null && Object.hasOwnProperty.call(message, "WmFriendlyName"))
            writer.uint32(/* id 34, wireType 2 =*/274).string(message.WmFriendlyName);
        if (message.WmPrivateRooms != null && Object.hasOwnProperty.call(message, "WmPrivateRooms"))
            writer.uint32(/* id 35, wireType 0 =*/280).bool(message.WmPrivateRooms);
        if (message.WmModerateParticipants != null && Object.hasOwnProperty.call(message, "WmModerateParticipants"))
            writer.uint32(/* id 36, wireType 0 =*/288).bool(message.WmModerateParticipants);
        if (message.QuickMeetingOpenLink != null && Object.hasOwnProperty.call(message, "QuickMeetingOpenLink"))
            writer.uint32(/* id 37, wireType 2 =*/298).string(message.QuickMeetingOpenLink);
        if (message.ContactImage != null && Object.hasOwnProperty.call(message, "ContactImage"))
            writer.uint32(/* id 38, wireType 2 =*/306).string(message.ContactImage);
        if (message.CanSeeRecordings != null && Object.hasOwnProperty.call(message, "CanSeeRecordings"))
            writer.uint32(/* id 39, wireType 0 =*/312).bool(message.CanSeeRecordings);
        if (message.CanDeleteRecordings != null && Object.hasOwnProperty.call(message, "CanDeleteRecordings"))
            writer.uint32(/* id 40, wireType 0 =*/320).bool(message.CanDeleteRecordings);
        if (message.ProvLink != null && Object.hasOwnProperty.call(message, "ProvLink"))
            writer.uint32(/* id 41, wireType 2 =*/330).string(message.ProvLink);
        if (message.AllowControlOwnRecordings != null && Object.hasOwnProperty.call(message, "AllowControlOwnRecordings"))
            writer.uint32(/* id 42, wireType 0 =*/336).bool(message.AllowControlOwnRecordings);
        if (message.Subscriptions != null && Object.hasOwnProperty.call(message, "Subscriptions"))
            $root.PushSubscriptions.encode(message.Subscriptions, writer.uint32(/* id 43, wireType 2 =*/346).fork()).ldelim();
        if (message.AllowWebrtcEndpoint != null && Object.hasOwnProperty.call(message, "AllowWebrtcEndpoint"))
            writer.uint32(/* id 44, wireType 0 =*/352).bool(message.AllowWebrtcEndpoint);
        if (message.Settings != null && Object.hasOwnProperty.call(message, "Settings"))
            $root.UserSettings.encode(message.Settings, writer.uint32(/* id 45, wireType 2 =*/362).fork()).ldelim();
        if (message.ActiveDids != null && Object.hasOwnProperty.call(message, "ActiveDids"))
            $root.DIDRuleInfos.encode(message.ActiveDids, writer.uint32(/* id 46, wireType 2 =*/370).fork()).ldelim();
        if (message.Language != null && Object.hasOwnProperty.call(message, "Language"))
            writer.uint32(/* id 47, wireType 2 =*/378).string(message.Language);
        if (message.Office365Enabled != null && Object.hasOwnProperty.call(message, "Office365Enabled"))
            writer.uint32(/* id 48, wireType 0 =*/384).bool(message.Office365Enabled);
        if (message.HasExchangeLicense != null && Object.hasOwnProperty.call(message, "HasExchangeLicense"))
            writer.uint32(/* id 49, wireType 0 =*/392).bool(message.HasExchangeLicense);
        if (message.MonitorExpiration != null && Object.hasOwnProperty.call(message, "MonitorExpiration"))
            $root.DateTime.encode(message.MonitorExpiration, writer.uint32(/* id 50, wireType 2 =*/402).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a MyExtensionInfo message from the specified reader or buffer.
     * @function decode
     * @memberof MyExtensionInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MyExtensionInfo} MyExtensionInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MyExtensionInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MyExtensionInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Number = reader.string();
                    break;
                }
            case 4: {
                    message.MobileNumber = reader.string();
                    break;
                }
            case 5: {
                    message.Email = reader.string();
                    break;
                }
            case 6: {
                    message.QueueStatus = reader.bool();
                    break;
                }
            case 7: {
                    message.FirstName = reader.string();
                    break;
                }
            case 8: {
                    message.LastName = reader.string();
                    break;
                }
            case 9: {
                    message.ActiveDevices = $root.Registrations.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.CurrentProfile = reader.int32();
                    break;
                }
            case 11: {
                    message.VoiceMailBox = $root.VoiceMails.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.MyGroups = $root.GroupIds.decode(reader, reader.uint32());
                    break;
                }
            case 13: {
                    message.MyProfiles = $root.ForwardingProfiles.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.DND = reader.bool();
                    break;
                }
            case 15: {
                    message.MyCalls = $root.Calls.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.OfficeHours = reader.int32();
                    break;
                }
            case 17: {
                    message.ChatStatus = reader.int32();
                    break;
                }
            case 18: {
                    message.Connections = $root.LocalConnections.decode(reader, reader.uint32());
                    break;
                }
            case 20: {
                    message.OtherConnections = $root.LocalConnections.decode(reader, reader.uint32());
                    break;
                }
            case 21: {
                    message.PrivateConference = $root.ConferenceParticipants.decode(reader, reader.uint32());
                    break;
                }
            case 22: {
                    message.AllowEditFwRules = reader.bool();
                    break;
                }
            case 23: {
                    message.DeskphonePassword = reader.string();
                    break;
                }
            case 24: {
                    message.MissedCallsCount = reader.int32();
                    break;
                }
            case 25: {
                    message.WebMeetingMD5Password = reader.string();
                    break;
                }
            case 26: {
                    message.WebRtcInboundRules = $root.WebRtcInboundRules.decode(reader, reader.uint32());
                    break;
                }
            case 27: {
                    message.HasCurrentProfileOverride = reader.bool();
                    break;
                }
            case 28: {
                    message.CurrentProfileOverride = reader.int32();
                    break;
                }
            case 29: {
                    message.OverrideExpiresAtUTCTime = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 30: {
                    message.OverrideCurrentStatus = reader.string();
                    break;
                }
            case 31: {
                    message.OverrideAttachedData = reader.string();
                    break;
                }
            case 32: {
                    message.SharedBlfs = reader.string();
                    break;
                }
            case 33: {
                    message.QuickMeetingLink = reader.string();
                    break;
                }
            case 34: {
                    message.WmFriendlyName = reader.string();
                    break;
                }
            case 35: {
                    message.WmPrivateRooms = reader.bool();
                    break;
                }
            case 36: {
                    message.WmModerateParticipants = reader.bool();
                    break;
                }
            case 37: {
                    message.QuickMeetingOpenLink = reader.string();
                    break;
                }
            case 38: {
                    message.ContactImage = reader.string();
                    break;
                }
            case 39: {
                    message.CanSeeRecordings = reader.bool();
                    break;
                }
            case 40: {
                    message.CanDeleteRecordings = reader.bool();
                    break;
                }
            case 41: {
                    message.ProvLink = reader.string();
                    break;
                }
            case 42: {
                    message.AllowControlOwnRecordings = reader.bool();
                    break;
                }
            case 43: {
                    message.Subscriptions = $root.PushSubscriptions.decode(reader, reader.uint32());
                    break;
                }
            case 44: {
                    message.AllowWebrtcEndpoint = reader.bool();
                    break;
                }
            case 45: {
                    message.Settings = $root.UserSettings.decode(reader, reader.uint32());
                    break;
                }
            case 46: {
                    message.ActiveDids = $root.DIDRuleInfos.decode(reader, reader.uint32());
                    break;
                }
            case 47: {
                    message.Language = reader.string();
                    break;
                }
            case 48: {
                    message.Office365Enabled = reader.bool();
                    break;
                }
            case 49: {
                    message.HasExchangeLicense = reader.bool();
                    break;
                }
            case 50: {
                    message.MonitorExpiration = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a MyExtensionInfo message to GenericMessage
     * @function toGenericMessage
     * @memberof MyExtensionInfo
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    MyExtensionInfo.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 201, MyInfo : this});
    };

    /**
     * MyExtensionInfo typeId
     * @member {number} typeId
     * @memberof MyExtensionInfo
     * @instance
     */
    MyExtensionInfo.prototype.typeId = 201;
    /**
     * MyExtensionInfo typeName
     * @member {string} typeName
     * @memberof MyExtensionInfo
     * @instance
     */
    MyExtensionInfo.prototype.typeName = "MyInfo";

    return MyExtensionInfo;
})();

export const RequestSetLanguage = $root.RequestSetLanguage = (() => {

    /**
     * Properties of a RequestSetLanguage.
     * @exports IRequestSetLanguage
     * @property {string} [Language] RequestSetLanguage Language
     */

    /**
     * Constructs a new RequestSetLanguage.
     * @exports RequestSetLanguage
     * @classdesc Represents a RequestSetLanguage.
     * @constructor
     * @param {Partial<RequestSetLanguage>} [properties] Properties to set
     */
    function RequestSetLanguage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetLanguage Language.
     * @member {string} Language
     * @memberof RequestSetLanguage
     * @instance
     */

    /**
     * Encodes the specified RequestSetLanguage message. Does not implicitly {@link RequestSetLanguage.verify|verify} messages.
     * @function encode
     * @memberof RequestSetLanguage
     * @static
     * @param {RequestSetLanguage} message RequestSetLanguage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetLanguage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Language != null && Object.hasOwnProperty.call(message, "Language"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Language);
        return writer;
    };

    /**
     * Decodes a RequestSetLanguage message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetLanguage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetLanguage} RequestSetLanguage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetLanguage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetLanguage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Language = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetLanguage message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetLanguage
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetLanguage.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 514, SetLanguage : this});
    };

    /**
     * RequestSetLanguage typeId
     * @member {number} typeId
     * @memberof RequestSetLanguage
     * @instance
     */
    RequestSetLanguage.prototype.typeId = 514;
    /**
     * RequestSetLanguage typeName
     * @member {string} typeName
     * @memberof RequestSetLanguage
     * @instance
     */
    RequestSetLanguage.prototype.typeName = "SetLanguage";

    return RequestSetLanguage;
})();

export const UserSettings = $root.UserSettings = (() => {

    /**
     * Properties of a UserSettings.
     * @exports IUserSettings
     * @property {Array.<string>} [ChatBlockedUsers] UserSettings ChatBlockedUsers
     */

    /**
     * Constructs a new UserSettings.
     * @exports UserSettings
     * @classdesc Represents a UserSettings.
     * @constructor
     * @param {Partial<UserSettings>} [properties] Properties to set
     */
    function UserSettings(properties) {
        this.ChatBlockedUsers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * UserSettings ChatBlockedUsers.
     * @member {Array.<string>} ChatBlockedUsers
     * @memberof UserSettings
     * @instance
     */
    UserSettings.prototype.ChatBlockedUsers = $util.emptyArray;

    /**
     * Encodes the specified UserSettings message. Does not implicitly {@link UserSettings.verify|verify} messages.
     * @function encode
     * @memberof UserSettings
     * @static
     * @param {UserSettings} message UserSettings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    UserSettings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ChatBlockedUsers != null && message.ChatBlockedUsers.length)
            for (let i = 0; i < message.ChatBlockedUsers.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.ChatBlockedUsers[i]);
        return writer;
    };

    /**
     * Decodes a UserSettings message from the specified reader or buffer.
     * @function decode
     * @memberof UserSettings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {UserSettings} UserSettings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    UserSettings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.UserSettings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.ChatBlockedUsers && message.ChatBlockedUsers.length))
                        message.ChatBlockedUsers = [];
                    message.ChatBlockedUsers.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return UserSettings;
})();

export const RequestUpdateUserSettings = $root.RequestUpdateUserSettings = (() => {

    /**
     * Properties of a RequestUpdateUserSettings.
     * @exports IRequestUpdateUserSettings
     * @property {UserSettings} [Settings] RequestUpdateUserSettings Settings
     */

    /**
     * Constructs a new RequestUpdateUserSettings.
     * @exports RequestUpdateUserSettings
     * @classdesc Represents a RequestUpdateUserSettings.
     * @constructor
     * @param {Partial<RequestUpdateUserSettings>} [properties] Properties to set
     */
    function RequestUpdateUserSettings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateUserSettings Settings.
     * @member {UserSettings} Settings
     * @memberof RequestUpdateUserSettings
     * @instance
     */

    /**
     * Encodes the specified RequestUpdateUserSettings message. Does not implicitly {@link RequestUpdateUserSettings.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateUserSettings
     * @static
     * @param {RequestUpdateUserSettings} message RequestUpdateUserSettings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateUserSettings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Settings != null && Object.hasOwnProperty.call(message, "Settings"))
            $root.UserSettings.encode(message.Settings, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestUpdateUserSettings message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateUserSettings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateUserSettings} RequestUpdateUserSettings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateUserSettings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateUserSettings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Settings = $root.UserSettings.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateUserSettings message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateUserSettings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateUserSettings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 192, UpdateUserSettings : this});
    };

    /**
     * RequestUpdateUserSettings typeId
     * @member {number} typeId
     * @memberof RequestUpdateUserSettings
     * @instance
     */
    RequestUpdateUserSettings.prototype.typeId = 192;
    /**
     * RequestUpdateUserSettings typeName
     * @member {string} typeName
     * @memberof RequestUpdateUserSettings
     * @instance
     */
    RequestUpdateUserSettings.prototype.typeName = "UpdateUserSettings";

    return RequestUpdateUserSettings;
})();

export const ConferenceState = $root.ConferenceState = (() => {

    /**
     * Properties of a ConferenceState.
     * @exports IConferenceState
     * @property {ActionType} Action ConferenceState Action
     * @property {number} Id ConferenceState Id
     * @property {ConferenceParticipants} [Participants] ConferenceState Participants
     * @property {string} [Name] ConferenceState Name
     * @property {string} [Description] ConferenceState Description
     * @property {string} [StartAt] ConferenceState StartAt
     * @property {string} [EndAt] ConferenceState EndAt
     * @property {boolean} [IsActive] ConferenceState IsActive
     * @property {boolean} [IsScheduled] ConferenceState IsScheduled
     * @property {string} [PinCode] ConferenceState PinCode
     * @property {number} [ScheduleID] ConferenceState ScheduleID
     * @property {boolean} [Cancelled] ConferenceState Cancelled
     * @property {boolean} [DisableUserInteractions] ConferenceState DisableUserInteractions
     * @property {MeetingType} [Type] ConferenceState Type
     * @property {ConferenceParticipants} [webMeetingParticipants] ConferenceState webMeetingParticipants
     * @property {string} [OwnedBy] ConferenceState OwnedBy
     */

    /**
     * Constructs a new ConferenceState.
     * @exports ConferenceState
     * @classdesc Represents a ConferenceState.
     * @constructor
     * @param {Partial<ConferenceState>} [properties] Properties to set
     */
    function ConferenceState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ConferenceState Action.
     * @member {ActionType} Action
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState Id.
     * @member {number} Id
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState Participants.
     * @member {ConferenceParticipants} Participants
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState Name.
     * @member {string} Name
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState Description.
     * @member {string} Description
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState StartAt.
     * @member {string} StartAt
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState EndAt.
     * @member {string} EndAt
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState IsActive.
     * @member {boolean} IsActive
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState IsScheduled.
     * @member {boolean} IsScheduled
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState PinCode.
     * @member {string} PinCode
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState ScheduleID.
     * @member {number} ScheduleID
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState Cancelled.
     * @member {boolean} Cancelled
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState DisableUserInteractions.
     * @member {boolean} DisableUserInteractions
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState Type.
     * @member {MeetingType} Type
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState webMeetingParticipants.
     * @member {ConferenceParticipants} webMeetingParticipants
     * @memberof ConferenceState
     * @instance
     */

    /**
     * ConferenceState OwnedBy.
     * @member {string} OwnedBy
     * @memberof ConferenceState
     * @instance
     */

    /**
     * Encodes the specified ConferenceState message. Does not implicitly {@link ConferenceState.verify|verify} messages.
     * @function encode
     * @memberof ConferenceState
     * @static
     * @param {ConferenceState} message ConferenceState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ConferenceState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Participants != null && Object.hasOwnProperty.call(message, "Participants"))
            $root.ConferenceParticipants.encode(message.Participants, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Name);
        if (message.Description != null && Object.hasOwnProperty.call(message, "Description"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Description);
        if (message.StartAt != null && Object.hasOwnProperty.call(message, "StartAt"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.StartAt);
        if (message.EndAt != null && Object.hasOwnProperty.call(message, "EndAt"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.EndAt);
        if (message.IsActive != null && Object.hasOwnProperty.call(message, "IsActive"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.IsActive);
        if (message.IsScheduled != null && Object.hasOwnProperty.call(message, "IsScheduled"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.IsScheduled);
        if (message.PinCode != null && Object.hasOwnProperty.call(message, "PinCode"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.PinCode);
        if (message.ScheduleID != null && Object.hasOwnProperty.call(message, "ScheduleID"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.ScheduleID);
        if (message.Cancelled != null && Object.hasOwnProperty.call(message, "Cancelled"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.Cancelled);
        if (message.DisableUserInteractions != null && Object.hasOwnProperty.call(message, "DisableUserInteractions"))
            writer.uint32(/* id 13, wireType 0 =*/104).bool(message.DisableUserInteractions);
        if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.Type);
        if (message.webMeetingParticipants != null && Object.hasOwnProperty.call(message, "webMeetingParticipants"))
            $root.ConferenceParticipants.encode(message.webMeetingParticipants, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.OwnedBy != null && Object.hasOwnProperty.call(message, "OwnedBy"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.OwnedBy);
        return writer;
    };

    /**
     * Decodes a ConferenceState message from the specified reader or buffer.
     * @function decode
     * @memberof ConferenceState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ConferenceState} ConferenceState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ConferenceState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ConferenceState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Participants = $root.ConferenceParticipants.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.Name = reader.string();
                    break;
                }
            case 5: {
                    message.Description = reader.string();
                    break;
                }
            case 6: {
                    message.StartAt = reader.string();
                    break;
                }
            case 7: {
                    message.EndAt = reader.string();
                    break;
                }
            case 8: {
                    message.IsActive = reader.bool();
                    break;
                }
            case 9: {
                    message.IsScheduled = reader.bool();
                    break;
                }
            case 10: {
                    message.PinCode = reader.string();
                    break;
                }
            case 11: {
                    message.ScheduleID = reader.int32();
                    break;
                }
            case 12: {
                    message.Cancelled = reader.bool();
                    break;
                }
            case 13: {
                    message.DisableUserInteractions = reader.bool();
                    break;
                }
            case 14: {
                    message.Type = reader.int32();
                    break;
                }
            case 15: {
                    message.webMeetingParticipants = $root.ConferenceParticipants.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.OwnedBy = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ConferenceState message to GenericMessage
     * @function toGenericMessage
     * @memberof ConferenceState
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ConferenceState.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 181, UpdateWebMeetingParticipants : this});
    };

    /**
     * ConferenceState typeId
     * @member {number} typeId
     * @memberof ConferenceState
     * @instance
     */
    ConferenceState.prototype.typeId = 181;
    /**
     * ConferenceState typeName
     * @member {string} typeName
     * @memberof ConferenceState
     * @instance
     */
    ConferenceState.prototype.typeName = "UpdateWebMeetingParticipants";

    return ConferenceState;
})();

export const RequestUpsertScheduledConference = $root.RequestUpsertScheduledConference = (() => {

    /**
     * Properties of a RequestUpsertScheduledConference.
     * @exports IRequestUpsertScheduledConference
     * @property {number} [Id] RequestUpsertScheduledConference Id
     * @property {string} [StartAtUTC] RequestUpsertScheduledConference StartAtUTC
     * @property {string} [EndAtUTC] RequestUpsertScheduledConference EndAtUTC
     * @property {string} [Name] RequestUpsertScheduledConference Name
     * @property {string} [Description] RequestUpsertScheduledConference Description
     * @property {string} [Moderators] RequestUpsertScheduledConference Moderators
     * @property {boolean} [DisableUserInteractions] RequestUpsertScheduledConference DisableUserInteractions
     * @property {string} [ConferencePIN] RequestUpsertScheduledConference ConferencePIN
     * @property {string} [ExternalNumberOfConference] RequestUpsertScheduledConference ExternalNumberOfConference
     * @property {string} [Emails] RequestUpsertScheduledConference Emails
     * @property {string} [EmailBody] RequestUpsertScheduledConference EmailBody
     * @property {boolean} [DeleteSchedule] RequestUpsertScheduledConference DeleteSchedule
     * @property {string} [InternalParticipants] RequestUpsertScheduledConference InternalParticipants
     * @property {string} [CallToExternalNumbers] RequestUpsertScheduledConference CallToExternalNumbers
     * @property {CalendarServiceType} [CalendarType] RequestUpsertScheduledConference CalendarType
     * @property {WebMeetingState} [webMeetingScheduleState] RequestUpsertScheduledConference webMeetingScheduleState
     */

    /**
     * Constructs a new RequestUpsertScheduledConference.
     * @exports RequestUpsertScheduledConference
     * @classdesc Represents a RequestUpsertScheduledConference.
     * @constructor
     * @param {Partial<RequestUpsertScheduledConference>} [properties] Properties to set
     */
    function RequestUpsertScheduledConference(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpsertScheduledConference Id.
     * @member {number} Id
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference StartAtUTC.
     * @member {string} StartAtUTC
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference EndAtUTC.
     * @member {string} EndAtUTC
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference Name.
     * @member {string} Name
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference Description.
     * @member {string} Description
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference Moderators.
     * @member {string} Moderators
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference DisableUserInteractions.
     * @member {boolean} DisableUserInteractions
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference ConferencePIN.
     * @member {string} ConferencePIN
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference ExternalNumberOfConference.
     * @member {string} ExternalNumberOfConference
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference Emails.
     * @member {string} Emails
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference EmailBody.
     * @member {string} EmailBody
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference DeleteSchedule.
     * @member {boolean} DeleteSchedule
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference InternalParticipants.
     * @member {string} InternalParticipants
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference CallToExternalNumbers.
     * @member {string} CallToExternalNumbers
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference CalendarType.
     * @member {CalendarServiceType} CalendarType
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * RequestUpsertScheduledConference webMeetingScheduleState.
     * @member {WebMeetingState} webMeetingScheduleState
     * @memberof RequestUpsertScheduledConference
     * @instance
     */

    /**
     * Encodes the specified RequestUpsertScheduledConference message. Does not implicitly {@link RequestUpsertScheduledConference.verify|verify} messages.
     * @function encode
     * @memberof RequestUpsertScheduledConference
     * @static
     * @param {RequestUpsertScheduledConference} message RequestUpsertScheduledConference message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpsertScheduledConference.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.StartAtUTC != null && Object.hasOwnProperty.call(message, "StartAtUTC"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.StartAtUTC);
        if (message.EndAtUTC != null && Object.hasOwnProperty.call(message, "EndAtUTC"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.EndAtUTC);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Name);
        if (message.Description != null && Object.hasOwnProperty.call(message, "Description"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Description);
        if (message.Moderators != null && Object.hasOwnProperty.call(message, "Moderators"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Moderators);
        if (message.DisableUserInteractions != null && Object.hasOwnProperty.call(message, "DisableUserInteractions"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.DisableUserInteractions);
        if (message.ConferencePIN != null && Object.hasOwnProperty.call(message, "ConferencePIN"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.ConferencePIN);
        if (message.ExternalNumberOfConference != null && Object.hasOwnProperty.call(message, "ExternalNumberOfConference"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.ExternalNumberOfConference);
        if (message.Emails != null && Object.hasOwnProperty.call(message, "Emails"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.Emails);
        if (message.EmailBody != null && Object.hasOwnProperty.call(message, "EmailBody"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.EmailBody);
        if (message.DeleteSchedule != null && Object.hasOwnProperty.call(message, "DeleteSchedule"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.DeleteSchedule);
        if (message.InternalParticipants != null && Object.hasOwnProperty.call(message, "InternalParticipants"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.InternalParticipants);
        if (message.CallToExternalNumbers != null && Object.hasOwnProperty.call(message, "CallToExternalNumbers"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.CallToExternalNumbers);
        if (message.CalendarType != null && Object.hasOwnProperty.call(message, "CalendarType"))
            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.CalendarType);
        if (message.webMeetingScheduleState != null && Object.hasOwnProperty.call(message, "webMeetingScheduleState"))
            $root.WebMeetingState.encode(message.webMeetingScheduleState, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestUpsertScheduledConference message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpsertScheduledConference
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpsertScheduledConference} RequestUpsertScheduledConference
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpsertScheduledConference.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpsertScheduledConference();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.StartAtUTC = reader.string();
                    break;
                }
            case 3: {
                    message.EndAtUTC = reader.string();
                    break;
                }
            case 4: {
                    message.Name = reader.string();
                    break;
                }
            case 5: {
                    message.Description = reader.string();
                    break;
                }
            case 6: {
                    message.Moderators = reader.string();
                    break;
                }
            case 7: {
                    message.DisableUserInteractions = reader.bool();
                    break;
                }
            case 8: {
                    message.ConferencePIN = reader.string();
                    break;
                }
            case 9: {
                    message.ExternalNumberOfConference = reader.string();
                    break;
                }
            case 10: {
                    message.Emails = reader.string();
                    break;
                }
            case 11: {
                    message.EmailBody = reader.string();
                    break;
                }
            case 12: {
                    message.DeleteSchedule = reader.bool();
                    break;
                }
            case 13: {
                    message.InternalParticipants = reader.string();
                    break;
                }
            case 14: {
                    message.CallToExternalNumbers = reader.string();
                    break;
                }
            case 15: {
                    message.CalendarType = reader.int32();
                    break;
                }
            case 16: {
                    message.webMeetingScheduleState = $root.WebMeetingState.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpsertScheduledConference message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpsertScheduledConference
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpsertScheduledConference.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 133, ScheduleConferenceRequest : this});
    };

    /**
     * RequestUpsertScheduledConference typeId
     * @member {number} typeId
     * @memberof RequestUpsertScheduledConference
     * @instance
     */
    RequestUpsertScheduledConference.prototype.typeId = 133;
    /**
     * RequestUpsertScheduledConference typeName
     * @member {string} typeName
     * @memberof RequestUpsertScheduledConference
     * @instance
     */
    RequestUpsertScheduledConference.prototype.typeName = "ScheduleConferenceRequest";

    return RequestUpsertScheduledConference;
})();

export const WebMeetingParticipant = $root.WebMeetingParticipant = (() => {

    /**
     * Properties of a WebMeetingParticipant.
     * @exports IWebMeetingParticipant
     * @property {ActionType} Action WebMeetingParticipant Action
     * @property {string} [Key] WebMeetingParticipant Key
     * @property {string} [Name] WebMeetingParticipant Name
     * @property {string} [Email] WebMeetingParticipant Email
     * @property {boolean} [Moderator] WebMeetingParticipant Moderator
     * @property {string} [ExtensionNumber] WebMeetingParticipant ExtensionNumber
     * @property {string} [NotificationStatus] WebMeetingParticipant NotificationStatus
     */

    /**
     * Constructs a new WebMeetingParticipant.
     * @exports WebMeetingParticipant
     * @classdesc Represents a WebMeetingParticipant.
     * @constructor
     * @param {Partial<WebMeetingParticipant>} [properties] Properties to set
     */
    function WebMeetingParticipant(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebMeetingParticipant Action.
     * @member {ActionType} Action
     * @memberof WebMeetingParticipant
     * @instance
     */

    /**
     * WebMeetingParticipant Key.
     * @member {string} Key
     * @memberof WebMeetingParticipant
     * @instance
     */

    /**
     * WebMeetingParticipant Name.
     * @member {string} Name
     * @memberof WebMeetingParticipant
     * @instance
     */

    /**
     * WebMeetingParticipant Email.
     * @member {string} Email
     * @memberof WebMeetingParticipant
     * @instance
     */

    /**
     * WebMeetingParticipant Moderator.
     * @member {boolean} Moderator
     * @memberof WebMeetingParticipant
     * @instance
     */

    /**
     * WebMeetingParticipant ExtensionNumber.
     * @member {string} ExtensionNumber
     * @memberof WebMeetingParticipant
     * @instance
     */

    /**
     * WebMeetingParticipant NotificationStatus.
     * @member {string} NotificationStatus
     * @memberof WebMeetingParticipant
     * @instance
     */

    /**
     * Encodes the specified WebMeetingParticipant message. Does not implicitly {@link WebMeetingParticipant.verify|verify} messages.
     * @function encode
     * @memberof WebMeetingParticipant
     * @static
     * @param {WebMeetingParticipant} message WebMeetingParticipant message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebMeetingParticipant.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Key != null && Object.hasOwnProperty.call(message, "Key"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Key);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Name);
        if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Email);
        if (message.Moderator != null && Object.hasOwnProperty.call(message, "Moderator"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.Moderator);
        if (message.ExtensionNumber != null && Object.hasOwnProperty.call(message, "ExtensionNumber"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.ExtensionNumber);
        if (message.NotificationStatus != null && Object.hasOwnProperty.call(message, "NotificationStatus"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.NotificationStatus);
        return writer;
    };

    /**
     * Decodes a WebMeetingParticipant message from the specified reader or buffer.
     * @function decode
     * @memberof WebMeetingParticipant
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebMeetingParticipant} WebMeetingParticipant
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebMeetingParticipant.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebMeetingParticipant();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Key = reader.string();
                    break;
                }
            case 3: {
                    message.Name = reader.string();
                    break;
                }
            case 4: {
                    message.Email = reader.string();
                    break;
                }
            case 5: {
                    message.Moderator = reader.bool();
                    break;
                }
            case 6: {
                    message.ExtensionNumber = reader.string();
                    break;
                }
            case 7: {
                    message.NotificationStatus = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return WebMeetingParticipant;
})();

export const WebMeetings = $root.WebMeetings = (() => {

    /**
     * Properties of a WebMeetings.
     * @exports IWebMeetings
     * @property {ActionType} Action WebMeetings Action
     * @property {Array.<WebMeetingState>} [Items] WebMeetings Items
     */

    /**
     * Constructs a new WebMeetings.
     * @exports WebMeetings
     * @classdesc Represents a WebMeetings.
     * @constructor
     * @param {Partial<WebMeetings>} [properties] Properties to set
     */
    function WebMeetings(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebMeetings Action.
     * @member {ActionType} Action
     * @memberof WebMeetings
     * @instance
     */

    /**
     * WebMeetings Items.
     * @member {Array.<WebMeetingState>} Items
     * @memberof WebMeetings
     * @instance
     */
    WebMeetings.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified WebMeetings message. Does not implicitly {@link WebMeetings.verify|verify} messages.
     * @function encode
     * @memberof WebMeetings
     * @static
     * @param {WebMeetings} message WebMeetings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebMeetings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.WebMeetingState.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a WebMeetings message from the specified reader or buffer.
     * @function decode
     * @memberof WebMeetings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebMeetings} WebMeetings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebMeetings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebMeetings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.WebMeetingState.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a WebMeetings message to GenericMessage
     * @function toGenericMessage
     * @memberof WebMeetings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    WebMeetings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 224, ActiveWebMeetings : this});
    };

    /**
     * WebMeetings typeId
     * @member {number} typeId
     * @memberof WebMeetings
     * @instance
     */
    WebMeetings.prototype.typeId = 224;
    /**
     * WebMeetings typeName
     * @member {string} typeName
     * @memberof WebMeetings
     * @instance
     */
    WebMeetings.prototype.typeName = "ActiveWebMeetings";

    return WebMeetings;
})();

export const WebMeetingParticipants = $root.WebMeetingParticipants = (() => {

    /**
     * Properties of a WebMeetingParticipants.
     * @exports IWebMeetingParticipants
     * @property {ActionType} Action WebMeetingParticipants Action
     * @property {Array.<WebMeetingParticipant>} [Items] WebMeetingParticipants Items
     */

    /**
     * Constructs a new WebMeetingParticipants.
     * @exports WebMeetingParticipants
     * @classdesc Represents a WebMeetingParticipants.
     * @constructor
     * @param {Partial<WebMeetingParticipants>} [properties] Properties to set
     */
    function WebMeetingParticipants(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebMeetingParticipants Action.
     * @member {ActionType} Action
     * @memberof WebMeetingParticipants
     * @instance
     */

    /**
     * WebMeetingParticipants Items.
     * @member {Array.<WebMeetingParticipant>} Items
     * @memberof WebMeetingParticipants
     * @instance
     */
    WebMeetingParticipants.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified WebMeetingParticipants message. Does not implicitly {@link WebMeetingParticipants.verify|verify} messages.
     * @function encode
     * @memberof WebMeetingParticipants
     * @static
     * @param {WebMeetingParticipants} message WebMeetingParticipants message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebMeetingParticipants.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.WebMeetingParticipant.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a WebMeetingParticipants message from the specified reader or buffer.
     * @function decode
     * @memberof WebMeetingParticipants
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebMeetingParticipants} WebMeetingParticipants
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebMeetingParticipants.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebMeetingParticipants();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.WebMeetingParticipant.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return WebMeetingParticipants;
})();

export const RequestGetWebMeetings = $root.RequestGetWebMeetings = (() => {

    /**
     * Properties of a RequestGetWebMeetings.
     * @exports IRequestGetWebMeetings
     */

    /**
     * Constructs a new RequestGetWebMeetings.
     * @exports RequestGetWebMeetings
     * @classdesc Represents a RequestGetWebMeetings.
     * @constructor
     * @param {Partial<RequestGetWebMeetings>} [properties] Properties to set
     */
    function RequestGetWebMeetings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestGetWebMeetings message. Does not implicitly {@link RequestGetWebMeetings.verify|verify} messages.
     * @function encode
     * @memberof RequestGetWebMeetings
     * @static
     * @param {RequestGetWebMeetings} message RequestGetWebMeetings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetWebMeetings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestGetWebMeetings message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetWebMeetings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetWebMeetings} RequestGetWebMeetings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetWebMeetings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetWebMeetings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetWebMeetings message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetWebMeetings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetWebMeetings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 154, GetWebMeetings : this});
    };

    /**
     * RequestGetWebMeetings typeId
     * @member {number} typeId
     * @memberof RequestGetWebMeetings
     * @instance
     */
    RequestGetWebMeetings.prototype.typeId = 154;
    /**
     * RequestGetWebMeetings typeName
     * @member {string} typeName
     * @memberof RequestGetWebMeetings
     * @instance
     */
    RequestGetWebMeetings.prototype.typeName = "GetWebMeetings";

    return RequestGetWebMeetings;
})();

export const WebMeetingState = $root.WebMeetingState = (() => {

    /**
     * Properties of a WebMeetingState.
     * @exports IWebMeetingState
     * @property {ActionType} Action WebMeetingState Action
     * @property {number} [Id] WebMeetingState Id
     * @property {string} [StartAtUTC] WebMeetingState StartAtUTC
     * @property {number} [Duration] WebMeetingState Duration
     * @property {string} [Name] WebMeetingState Name
     * @property {string} [Description] WebMeetingState Description
     * @property {WebMeetingParticipant} [Organizer] WebMeetingState Organizer
     * @property {boolean} [HideParticipants] WebMeetingState HideParticipants
     * @property {boolean} [ForceModerator] WebMeetingState ForceModerator
     * @property {boolean} [CanChangeMedia] WebMeetingState CanChangeMedia
     * @property {boolean} [NeedOrganizer] WebMeetingState NeedOrganizer
     * @property {string} [PreferredMCU] WebMeetingState PreferredMCU
     * @property {WebMeetingParticipants} [Participants] WebMeetingState Participants
     * @property {WebMeetingRole} [UserRole] WebMeetingState UserRole
     * @property {MeetingType} [Type] WebMeetingState Type
     * @property {string} [Pin] WebMeetingState Pin
     * @property {string} [NumberToCall] WebMeetingState NumberToCall
     * @property {boolean} [DisableUserInteractions] WebMeetingState DisableUserInteractions
     * @property {number} [BitrateData] WebMeetingState BitrateData
     * @property {number} [BitrateVideo] WebMeetingState BitrateVideo
     * @property {string} [VideoFormat] WebMeetingState VideoFormat
     * @property {string} [ParticipantsProperties] WebMeetingState ParticipantsProperties
     * @property {boolean} [IsPrivateRoom] WebMeetingState IsPrivateRoom
     * @property {string} [EmailBody] WebMeetingState EmailBody
     * @property {WebMeetingProfile} [MeetingProfile] WebMeetingState MeetingProfile
     * @property {CalendarServiceType} [CalendarType] WebMeetingState CalendarType
     * @property {string} [JoinLink] WebMeetingState JoinLink
     */

    /**
     * Constructs a new WebMeetingState.
     * @exports WebMeetingState
     * @classdesc Represents a WebMeetingState.
     * @constructor
     * @param {Partial<WebMeetingState>} [properties] Properties to set
     */
    function WebMeetingState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebMeetingState Action.
     * @member {ActionType} Action
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState Id.
     * @member {number} Id
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState StartAtUTC.
     * @member {string} StartAtUTC
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState Duration.
     * @member {number} Duration
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState Name.
     * @member {string} Name
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState Description.
     * @member {string} Description
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState Organizer.
     * @member {WebMeetingParticipant} Organizer
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState HideParticipants.
     * @member {boolean} HideParticipants
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState ForceModerator.
     * @member {boolean} ForceModerator
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState CanChangeMedia.
     * @member {boolean} CanChangeMedia
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState NeedOrganizer.
     * @member {boolean} NeedOrganizer
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState PreferredMCU.
     * @member {string} PreferredMCU
     * @memberof WebMeetingState
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * WebMeetingState Participants.
     * @member {WebMeetingParticipants} Participants
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState UserRole.
     * @member {WebMeetingRole} UserRole
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState Type.
     * @member {MeetingType} Type
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState Pin.
     * @member {string} Pin
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState NumberToCall.
     * @member {string} NumberToCall
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState DisableUserInteractions.
     * @member {boolean} DisableUserInteractions
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState BitrateData.
     * @member {number} BitrateData
     * @memberof WebMeetingState
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * WebMeetingState BitrateVideo.
     * @member {number} BitrateVideo
     * @memberof WebMeetingState
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * WebMeetingState VideoFormat.
     * @member {string} VideoFormat
     * @memberof WebMeetingState
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * WebMeetingState ParticipantsProperties.
     * @member {string} ParticipantsProperties
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState IsPrivateRoom.
     * @member {boolean} IsPrivateRoom
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState EmailBody.
     * @member {string} EmailBody
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState MeetingProfile.
     * @member {WebMeetingProfile} MeetingProfile
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState CalendarType.
     * @member {CalendarServiceType} CalendarType
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * WebMeetingState JoinLink.
     * @member {string} JoinLink
     * @memberof WebMeetingState
     * @instance
     */

    /**
     * Encodes the specified WebMeetingState message. Does not implicitly {@link WebMeetingState.verify|verify} messages.
     * @function encode
     * @memberof WebMeetingState
     * @static
     * @param {WebMeetingState} message WebMeetingState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebMeetingState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.StartAtUTC != null && Object.hasOwnProperty.call(message, "StartAtUTC"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.StartAtUTC);
        if (message.Duration != null && Object.hasOwnProperty.call(message, "Duration"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.Duration);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Name);
        if (message.Description != null && Object.hasOwnProperty.call(message, "Description"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Description);
        if (message.Organizer != null && Object.hasOwnProperty.call(message, "Organizer"))
            $root.WebMeetingParticipant.encode(message.Organizer, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.HideParticipants != null && Object.hasOwnProperty.call(message, "HideParticipants"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.HideParticipants);
        if (message.ForceModerator != null && Object.hasOwnProperty.call(message, "ForceModerator"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.ForceModerator);
        if (message.CanChangeMedia != null && Object.hasOwnProperty.call(message, "CanChangeMedia"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.CanChangeMedia);
        if (message.NeedOrganizer != null && Object.hasOwnProperty.call(message, "NeedOrganizer"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.NeedOrganizer);
        if (message.PreferredMCU != null && Object.hasOwnProperty.call(message, "PreferredMCU"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.PreferredMCU);
        if (message.Participants != null && Object.hasOwnProperty.call(message, "Participants"))
            $root.WebMeetingParticipants.encode(message.Participants, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.UserRole != null && Object.hasOwnProperty.call(message, "UserRole"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.UserRole);
        if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
            writer.uint32(/* id 16, wireType 0 =*/128).int32(message.Type);
        if (message.Pin != null && Object.hasOwnProperty.call(message, "Pin"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.Pin);
        if (message.NumberToCall != null && Object.hasOwnProperty.call(message, "NumberToCall"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.NumberToCall);
        if (message.DisableUserInteractions != null && Object.hasOwnProperty.call(message, "DisableUserInteractions"))
            writer.uint32(/* id 19, wireType 0 =*/152).bool(message.DisableUserInteractions);
        if (message.BitrateData != null && Object.hasOwnProperty.call(message, "BitrateData"))
            writer.uint32(/* id 20, wireType 0 =*/160).int32(message.BitrateData);
        if (message.BitrateVideo != null && Object.hasOwnProperty.call(message, "BitrateVideo"))
            writer.uint32(/* id 21, wireType 0 =*/168).int32(message.BitrateVideo);
        if (message.VideoFormat != null && Object.hasOwnProperty.call(message, "VideoFormat"))
            writer.uint32(/* id 22, wireType 2 =*/178).string(message.VideoFormat);
        if (message.ParticipantsProperties != null && Object.hasOwnProperty.call(message, "ParticipantsProperties"))
            writer.uint32(/* id 23, wireType 2 =*/186).string(message.ParticipantsProperties);
        if (message.IsPrivateRoom != null && Object.hasOwnProperty.call(message, "IsPrivateRoom"))
            writer.uint32(/* id 24, wireType 0 =*/192).bool(message.IsPrivateRoom);
        if (message.EmailBody != null && Object.hasOwnProperty.call(message, "EmailBody"))
            writer.uint32(/* id 25, wireType 2 =*/202).string(message.EmailBody);
        if (message.MeetingProfile != null && Object.hasOwnProperty.call(message, "MeetingProfile"))
            writer.uint32(/* id 26, wireType 0 =*/208).int32(message.MeetingProfile);
        if (message.CalendarType != null && Object.hasOwnProperty.call(message, "CalendarType"))
            writer.uint32(/* id 27, wireType 0 =*/216).int32(message.CalendarType);
        if (message.JoinLink != null && Object.hasOwnProperty.call(message, "JoinLink"))
            writer.uint32(/* id 28, wireType 2 =*/226).string(message.JoinLink);
        return writer;
    };

    /**
     * Decodes a WebMeetingState message from the specified reader or buffer.
     * @function decode
     * @memberof WebMeetingState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebMeetingState} WebMeetingState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebMeetingState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebMeetingState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.StartAtUTC = reader.string();
                    break;
                }
            case 4: {
                    message.Duration = reader.int32();
                    break;
                }
            case 5: {
                    message.Name = reader.string();
                    break;
                }
            case 6: {
                    message.Description = reader.string();
                    break;
                }
            case 7: {
                    message.Organizer = $root.WebMeetingParticipant.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.HideParticipants = reader.bool();
                    break;
                }
            case 9: {
                    message.ForceModerator = reader.bool();
                    break;
                }
            case 10: {
                    message.CanChangeMedia = reader.bool();
                    break;
                }
            case 11: {
                    message.NeedOrganizer = reader.bool();
                    break;
                }
            case 12: {
                    message.PreferredMCU = reader.string();
                    break;
                }
            case 13: {
                    message.Participants = $root.WebMeetingParticipants.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.UserRole = reader.int32();
                    break;
                }
            case 16: {
                    message.Type = reader.int32();
                    break;
                }
            case 17: {
                    message.Pin = reader.string();
                    break;
                }
            case 18: {
                    message.NumberToCall = reader.string();
                    break;
                }
            case 19: {
                    message.DisableUserInteractions = reader.bool();
                    break;
                }
            case 20: {
                    message.BitrateData = reader.int32();
                    break;
                }
            case 21: {
                    message.BitrateVideo = reader.int32();
                    break;
                }
            case 22: {
                    message.VideoFormat = reader.string();
                    break;
                }
            case 23: {
                    message.ParticipantsProperties = reader.string();
                    break;
                }
            case 24: {
                    message.IsPrivateRoom = reader.bool();
                    break;
                }
            case 25: {
                    message.EmailBody = reader.string();
                    break;
                }
            case 26: {
                    message.MeetingProfile = reader.int32();
                    break;
                }
            case 27: {
                    message.CalendarType = reader.int32();
                    break;
                }
            case 28: {
                    message.JoinLink = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a WebMeetingState message to GenericMessage
     * @function toGenericMessage
     * @memberof WebMeetingState
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    WebMeetingState.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 153, UpsertWebMeeting : this});
    };

    /**
     * WebMeetingState typeId
     * @member {number} typeId
     * @memberof WebMeetingState
     * @instance
     */
    WebMeetingState.prototype.typeId = 153;
    /**
     * WebMeetingState typeName
     * @member {string} typeName
     * @memberof WebMeetingState
     * @instance
     */
    WebMeetingState.prototype.typeName = "UpsertWebMeeting";

    return WebMeetingState;
})();

export const RequestWebMeetingPrivateRoom = $root.RequestWebMeetingPrivateRoom = (() => {

    /**
     * Properties of a RequestWebMeetingPrivateRoom.
     * @exports IRequestWebMeetingPrivateRoom
     * @property {string} [MeetingTitle] RequestWebMeetingPrivateRoom MeetingTitle
     * @property {string} [OrganizerProperties] RequestWebMeetingPrivateRoom OrganizerProperties
     * @property {string} [ParticipantsProperties] RequestWebMeetingPrivateRoom ParticipantsProperties
     * @property {boolean} [HideParticipants] RequestWebMeetingPrivateRoom HideParticipants
     * @property {boolean} [ForceModerator] RequestWebMeetingPrivateRoom ForceModerator
     * @property {boolean} [CanChangeMedia] RequestWebMeetingPrivateRoom CanChangeMedia
     * @property {number} [BitrateData] RequestWebMeetingPrivateRoom BitrateData
     * @property {number} [BitrateVideo] RequestWebMeetingPrivateRoom BitrateVideo
     * @property {string} [VideoFormat] RequestWebMeetingPrivateRoom VideoFormat
     * @property {string} [ParticipantName] RequestWebMeetingPrivateRoom ParticipantName
     * @property {boolean} [ClickToCall] RequestWebMeetingPrivateRoom ClickToCall
     * @property {string} [OrganizerKey] RequestWebMeetingPrivateRoom OrganizerKey
     * @property {string} [OpenLink] RequestWebMeetingPrivateRoom OpenLink
     * @property {number} [MeetingRowId] RequestWebMeetingPrivateRoom MeetingRowId
     */

    /**
     * Constructs a new RequestWebMeetingPrivateRoom.
     * @exports RequestWebMeetingPrivateRoom
     * @classdesc Represents a RequestWebMeetingPrivateRoom.
     * @constructor
     * @param {Partial<RequestWebMeetingPrivateRoom>} [properties] Properties to set
     */
    function RequestWebMeetingPrivateRoom(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestWebMeetingPrivateRoom MeetingTitle.
     * @member {string} MeetingTitle
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom OrganizerProperties.
     * @member {string} OrganizerProperties
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom ParticipantsProperties.
     * @member {string} ParticipantsProperties
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom HideParticipants.
     * @member {boolean} HideParticipants
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom ForceModerator.
     * @member {boolean} ForceModerator
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom CanChangeMedia.
     * @member {boolean} CanChangeMedia
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom BitrateData.
     * @member {number} BitrateData
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom BitrateVideo.
     * @member {number} BitrateVideo
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom VideoFormat.
     * @member {string} VideoFormat
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom ParticipantName.
     * @member {string} ParticipantName
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom ClickToCall.
     * @member {boolean} ClickToCall
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom OrganizerKey.
     * @member {string} OrganizerKey
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom OpenLink.
     * @member {string} OpenLink
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * RequestWebMeetingPrivateRoom MeetingRowId.
     * @member {number} MeetingRowId
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */

    /**
     * Encodes the specified RequestWebMeetingPrivateRoom message. Does not implicitly {@link RequestWebMeetingPrivateRoom.verify|verify} messages.
     * @function encode
     * @memberof RequestWebMeetingPrivateRoom
     * @static
     * @param {RequestWebMeetingPrivateRoom} message RequestWebMeetingPrivateRoom message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestWebMeetingPrivateRoom.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.MeetingTitle != null && Object.hasOwnProperty.call(message, "MeetingTitle"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.MeetingTitle);
        if (message.OrganizerProperties != null && Object.hasOwnProperty.call(message, "OrganizerProperties"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.OrganizerProperties);
        if (message.ParticipantsProperties != null && Object.hasOwnProperty.call(message, "ParticipantsProperties"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.ParticipantsProperties);
        if (message.HideParticipants != null && Object.hasOwnProperty.call(message, "HideParticipants"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.HideParticipants);
        if (message.ForceModerator != null && Object.hasOwnProperty.call(message, "ForceModerator"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.ForceModerator);
        if (message.CanChangeMedia != null && Object.hasOwnProperty.call(message, "CanChangeMedia"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.CanChangeMedia);
        if (message.BitrateData != null && Object.hasOwnProperty.call(message, "BitrateData"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.BitrateData);
        if (message.BitrateVideo != null && Object.hasOwnProperty.call(message, "BitrateVideo"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.BitrateVideo);
        if (message.VideoFormat != null && Object.hasOwnProperty.call(message, "VideoFormat"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.VideoFormat);
        if (message.ParticipantName != null && Object.hasOwnProperty.call(message, "ParticipantName"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.ParticipantName);
        if (message.ClickToCall != null && Object.hasOwnProperty.call(message, "ClickToCall"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.ClickToCall);
        if (message.OrganizerKey != null && Object.hasOwnProperty.call(message, "OrganizerKey"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.OrganizerKey);
        if (message.OpenLink != null && Object.hasOwnProperty.call(message, "OpenLink"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.OpenLink);
        if (message.MeetingRowId != null && Object.hasOwnProperty.call(message, "MeetingRowId"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.MeetingRowId);
        return writer;
    };

    /**
     * Decodes a RequestWebMeetingPrivateRoom message from the specified reader or buffer.
     * @function decode
     * @memberof RequestWebMeetingPrivateRoom
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestWebMeetingPrivateRoom} RequestWebMeetingPrivateRoom
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestWebMeetingPrivateRoom.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestWebMeetingPrivateRoom();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.MeetingTitle = reader.string();
                    break;
                }
            case 2: {
                    message.OrganizerProperties = reader.string();
                    break;
                }
            case 3: {
                    message.ParticipantsProperties = reader.string();
                    break;
                }
            case 4: {
                    message.HideParticipants = reader.bool();
                    break;
                }
            case 5: {
                    message.ForceModerator = reader.bool();
                    break;
                }
            case 6: {
                    message.CanChangeMedia = reader.bool();
                    break;
                }
            case 7: {
                    message.BitrateData = reader.int32();
                    break;
                }
            case 8: {
                    message.BitrateVideo = reader.int32();
                    break;
                }
            case 9: {
                    message.VideoFormat = reader.string();
                    break;
                }
            case 10: {
                    message.ParticipantName = reader.string();
                    break;
                }
            case 11: {
                    message.ClickToCall = reader.bool();
                    break;
                }
            case 12: {
                    message.OrganizerKey = reader.string();
                    break;
                }
            case 13: {
                    message.OpenLink = reader.string();
                    break;
                }
            case 14: {
                    message.MeetingRowId = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestWebMeetingPrivateRoom message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestWebMeetingPrivateRoom.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 158, CreatePrivateRoom : this});
    };

    /**
     * RequestWebMeetingPrivateRoom typeId
     * @member {number} typeId
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */
    RequestWebMeetingPrivateRoom.prototype.typeId = 158;
    /**
     * RequestWebMeetingPrivateRoom typeName
     * @member {string} typeName
     * @memberof RequestWebMeetingPrivateRoom
     * @instance
     */
    RequestWebMeetingPrivateRoom.prototype.typeName = "CreatePrivateRoom";

    return RequestWebMeetingPrivateRoom;
})();

export const ResponseWebMeetingPrivateRoom = $root.ResponseWebMeetingPrivateRoom = (() => {

    /**
     * Properties of a ResponseWebMeetingPrivateRoom.
     * @exports IResponseWebMeetingPrivateRoom
     * @property {string} [OpenLink] ResponseWebMeetingPrivateRoom OpenLink
     */

    /**
     * Constructs a new ResponseWebMeetingPrivateRoom.
     * @exports ResponseWebMeetingPrivateRoom
     * @classdesc Represents a ResponseWebMeetingPrivateRoom.
     * @constructor
     * @param {Partial<ResponseWebMeetingPrivateRoom>} [properties] Properties to set
     */
    function ResponseWebMeetingPrivateRoom(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseWebMeetingPrivateRoom OpenLink.
     * @member {string} OpenLink
     * @memberof ResponseWebMeetingPrivateRoom
     * @instance
     */

    /**
     * Encodes the specified ResponseWebMeetingPrivateRoom message. Does not implicitly {@link ResponseWebMeetingPrivateRoom.verify|verify} messages.
     * @function encode
     * @memberof ResponseWebMeetingPrivateRoom
     * @static
     * @param {ResponseWebMeetingPrivateRoom} message ResponseWebMeetingPrivateRoom message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseWebMeetingPrivateRoom.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.OpenLink != null && Object.hasOwnProperty.call(message, "OpenLink"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.OpenLink);
        return writer;
    };

    /**
     * Decodes a ResponseWebMeetingPrivateRoom message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseWebMeetingPrivateRoom
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseWebMeetingPrivateRoom} ResponseWebMeetingPrivateRoom
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseWebMeetingPrivateRoom.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseWebMeetingPrivateRoom();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.OpenLink = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseWebMeetingPrivateRoom message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseWebMeetingPrivateRoom
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseWebMeetingPrivateRoom.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 226, PrivateRoom : this});
    };

    /**
     * ResponseWebMeetingPrivateRoom typeId
     * @member {number} typeId
     * @memberof ResponseWebMeetingPrivateRoom
     * @instance
     */
    ResponseWebMeetingPrivateRoom.prototype.typeId = 226;
    /**
     * ResponseWebMeetingPrivateRoom typeName
     * @member {string} typeName
     * @memberof ResponseWebMeetingPrivateRoom
     * @instance
     */
    ResponseWebMeetingPrivateRoom.prototype.typeName = "PrivateRoom";

    return ResponseWebMeetingPrivateRoom;
})();

export const RequestWebMeetingError = $root.RequestWebMeetingError = (() => {

    /**
     * Properties of a RequestWebMeetingError.
     * @exports IRequestWebMeetingError
     * @property {string} [ErrorMessage] RequestWebMeetingError ErrorMessage
     * @property {number} [ErrorCode] RequestWebMeetingError ErrorCode
     * @property {number} [Severity] RequestWebMeetingError Severity
     * @property {string} [AttachedData] RequestWebMeetingError AttachedData
     */

    /**
     * Constructs a new RequestWebMeetingError.
     * @exports RequestWebMeetingError
     * @classdesc Represents a RequestWebMeetingError.
     * @constructor
     * @param {Partial<RequestWebMeetingError>} [properties] Properties to set
     */
    function RequestWebMeetingError(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestWebMeetingError ErrorMessage.
     * @member {string} ErrorMessage
     * @memberof RequestWebMeetingError
     * @instance
     */

    /**
     * RequestWebMeetingError ErrorCode.
     * @member {number} ErrorCode
     * @memberof RequestWebMeetingError
     * @instance
     */

    /**
     * RequestWebMeetingError Severity.
     * @member {number} Severity
     * @memberof RequestWebMeetingError
     * @instance
     */

    /**
     * RequestWebMeetingError AttachedData.
     * @member {string} AttachedData
     * @memberof RequestWebMeetingError
     * @instance
     */

    /**
     * Encodes the specified RequestWebMeetingError message. Does not implicitly {@link RequestWebMeetingError.verify|verify} messages.
     * @function encode
     * @memberof RequestWebMeetingError
     * @static
     * @param {RequestWebMeetingError} message RequestWebMeetingError message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestWebMeetingError.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ErrorMessage != null && Object.hasOwnProperty.call(message, "ErrorMessage"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ErrorMessage);
        if (message.ErrorCode != null && Object.hasOwnProperty.call(message, "ErrorCode"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ErrorCode);
        if (message.Severity != null && Object.hasOwnProperty.call(message, "Severity"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Severity);
        if (message.AttachedData != null && Object.hasOwnProperty.call(message, "AttachedData"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.AttachedData);
        return writer;
    };

    /**
     * Decodes a RequestWebMeetingError message from the specified reader or buffer.
     * @function decode
     * @memberof RequestWebMeetingError
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestWebMeetingError} RequestWebMeetingError
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestWebMeetingError.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestWebMeetingError();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ErrorMessage = reader.string();
                    break;
                }
            case 2: {
                    message.ErrorCode = reader.int32();
                    break;
                }
            case 3: {
                    message.Severity = reader.int32();
                    break;
                }
            case 4: {
                    message.AttachedData = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestWebMeetingError message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestWebMeetingError
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestWebMeetingError.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 159, SendError : this});
    };

    /**
     * RequestWebMeetingError typeId
     * @member {number} typeId
     * @memberof RequestWebMeetingError
     * @instance
     */
    RequestWebMeetingError.prototype.typeId = 159;
    /**
     * RequestWebMeetingError typeName
     * @member {string} typeName
     * @memberof RequestWebMeetingError
     * @instance
     */
    RequestWebMeetingError.prototype.typeName = "SendError";

    return RequestWebMeetingError;
})();

export const RequestPutWebMeetingReports = $root.RequestPutWebMeetingReports = (() => {

    /**
     * Properties of a RequestPutWebMeetingReports.
     * @exports IRequestPutWebMeetingReports
     * @property {Array.<string>} [Statistics] RequestPutWebMeetingReports Statistics
     * @property {Array.<string>} [Reports] RequestPutWebMeetingReports Reports
     * @property {string} [SessionId] RequestPutWebMeetingReports SessionId
     */

    /**
     * Constructs a new RequestPutWebMeetingReports.
     * @exports RequestPutWebMeetingReports
     * @classdesc Represents a RequestPutWebMeetingReports.
     * @constructor
     * @param {Partial<RequestPutWebMeetingReports>} [properties] Properties to set
     */
    function RequestPutWebMeetingReports(properties) {
        this.Statistics = [];
        this.Reports = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestPutWebMeetingReports Statistics.
     * @member {Array.<string>} Statistics
     * @memberof RequestPutWebMeetingReports
     * @instance
     */
    RequestPutWebMeetingReports.prototype.Statistics = $util.emptyArray;

    /**
     * RequestPutWebMeetingReports Reports.
     * @member {Array.<string>} Reports
     * @memberof RequestPutWebMeetingReports
     * @instance
     */
    RequestPutWebMeetingReports.prototype.Reports = $util.emptyArray;

    /**
     * RequestPutWebMeetingReports SessionId.
     * @member {string} SessionId
     * @memberof RequestPutWebMeetingReports
     * @instance
     */

    /**
     * Encodes the specified RequestPutWebMeetingReports message. Does not implicitly {@link RequestPutWebMeetingReports.verify|verify} messages.
     * @function encode
     * @memberof RequestPutWebMeetingReports
     * @static
     * @param {RequestPutWebMeetingReports} message RequestPutWebMeetingReports message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestPutWebMeetingReports.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Statistics != null && message.Statistics.length)
            for (let i = 0; i < message.Statistics.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Statistics[i]);
        if (message.Reports != null && message.Reports.length)
            for (let i = 0; i < message.Reports.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Reports[i]);
        if (message.SessionId != null && Object.hasOwnProperty.call(message, "SessionId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.SessionId);
        return writer;
    };

    /**
     * Decodes a RequestPutWebMeetingReports message from the specified reader or buffer.
     * @function decode
     * @memberof RequestPutWebMeetingReports
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestPutWebMeetingReports} RequestPutWebMeetingReports
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestPutWebMeetingReports.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestPutWebMeetingReports();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Statistics && message.Statistics.length))
                        message.Statistics = [];
                    message.Statistics.push(reader.string());
                    break;
                }
            case 2: {
                    if (!(message.Reports && message.Reports.length))
                        message.Reports = [];
                    message.Reports.push(reader.string());
                    break;
                }
            case 3: {
                    message.SessionId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestPutWebMeetingReports message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestPutWebMeetingReports
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestPutWebMeetingReports.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 157, PutWebMeetingReports : this});
    };

    /**
     * RequestPutWebMeetingReports typeId
     * @member {number} typeId
     * @memberof RequestPutWebMeetingReports
     * @instance
     */
    RequestPutWebMeetingReports.prototype.typeId = 157;
    /**
     * RequestPutWebMeetingReports typeName
     * @member {string} typeName
     * @memberof RequestPutWebMeetingReports
     * @instance
     */
    RequestPutWebMeetingReports.prototype.typeName = "PutWebMeetingReports";

    return RequestPutWebMeetingReports;
})();

export const RequestPutWebMeetingReports2 = $root.RequestPutWebMeetingReports2 = (() => {

    /**
     * Properties of a RequestPutWebMeetingReports2.
     * @exports IRequestPutWebMeetingReports2
     * @property {string} MeetingId RequestPutWebMeetingReports2 MeetingId
     * @property {string} SessionId RequestPutWebMeetingReports2 SessionId
     * @property {string} [Report] RequestPutWebMeetingReports2 Report
     * @property {string} [MeetingTitle] RequestPutWebMeetingReports2 MeetingTitle
     * @property {string} [OrganizerExtension] RequestPutWebMeetingReports2 OrganizerExtension
     * @property {string} [OrganizerName] RequestPutWebMeetingReports2 OrganizerName
     * @property {string} [OrganizerEmail] RequestPutWebMeetingReports2 OrganizerEmail
     * @property {number} [Duration] RequestPutWebMeetingReports2 Duration
     * @property {number} [BandwidthUp] RequestPutWebMeetingReports2 BandwidthUp
     * @property {number} [BandwidthDown] RequestPutWebMeetingReports2 BandwidthDown
     * @property {number} [Participants] RequestPutWebMeetingReports2 Participants
     * @property {number} [MaxParticipants] RequestPutWebMeetingReports2 MaxParticipants
     * @property {number} [Invites] RequestPutWebMeetingReports2 Invites
     * @property {number} [OpenLinks] RequestPutWebMeetingReports2 OpenLinks
     * @property {boolean} [Recorded] RequestPutWebMeetingReports2 Recorded
     * @property {string} [Mcu] RequestPutWebMeetingReports2 Mcu
     * @property {DateTime} [MeetingBegin] RequestPutWebMeetingReports2 MeetingBegin
     * @property {number} [MeetingRowId] RequestPutWebMeetingReports2 MeetingRowId
     * @property {string} [MeetingObjects] RequestPutWebMeetingReports2 MeetingObjects
     * @property {DateTime} [SentAt] RequestPutWebMeetingReports2 SentAt
     */

    /**
     * Constructs a new RequestPutWebMeetingReports2.
     * @exports RequestPutWebMeetingReports2
     * @classdesc Represents a RequestPutWebMeetingReports2.
     * @constructor
     * @param {Partial<RequestPutWebMeetingReports2>} [properties] Properties to set
     */
    function RequestPutWebMeetingReports2(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestPutWebMeetingReports2 MeetingId.
     * @member {string} MeetingId
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 SessionId.
     * @member {string} SessionId
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 Report.
     * @member {string} Report
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 MeetingTitle.
     * @member {string} MeetingTitle
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 OrganizerExtension.
     * @member {string} OrganizerExtension
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 OrganizerName.
     * @member {string} OrganizerName
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 OrganizerEmail.
     * @member {string} OrganizerEmail
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 Duration.
     * @member {number} Duration
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 BandwidthUp.
     * @member {number} BandwidthUp
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 BandwidthDown.
     * @member {number} BandwidthDown
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 Participants.
     * @member {number} Participants
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 MaxParticipants.
     * @member {number} MaxParticipants
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 Invites.
     * @member {number} Invites
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 OpenLinks.
     * @member {number} OpenLinks
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 Recorded.
     * @member {boolean} Recorded
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 Mcu.
     * @member {string} Mcu
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 MeetingBegin.
     * @member {DateTime} MeetingBegin
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 MeetingRowId.
     * @member {number} MeetingRowId
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 MeetingObjects.
     * @member {string} MeetingObjects
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * RequestPutWebMeetingReports2 SentAt.
     * @member {DateTime} SentAt
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */

    /**
     * Encodes the specified RequestPutWebMeetingReports2 message. Does not implicitly {@link RequestPutWebMeetingReports2.verify|verify} messages.
     * @function encode
     * @memberof RequestPutWebMeetingReports2
     * @static
     * @param {RequestPutWebMeetingReports2} message RequestPutWebMeetingReports2 message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestPutWebMeetingReports2.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.MeetingId);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.SessionId);
        if (message.Report != null && Object.hasOwnProperty.call(message, "Report"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Report);
        if (message.MeetingTitle != null && Object.hasOwnProperty.call(message, "MeetingTitle"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.MeetingTitle);
        if (message.OrganizerExtension != null && Object.hasOwnProperty.call(message, "OrganizerExtension"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.OrganizerExtension);
        if (message.OrganizerName != null && Object.hasOwnProperty.call(message, "OrganizerName"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.OrganizerName);
        if (message.OrganizerEmail != null && Object.hasOwnProperty.call(message, "OrganizerEmail"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.OrganizerEmail);
        if (message.Duration != null && Object.hasOwnProperty.call(message, "Duration"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.Duration);
        if (message.BandwidthUp != null && Object.hasOwnProperty.call(message, "BandwidthUp"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.BandwidthUp);
        if (message.BandwidthDown != null && Object.hasOwnProperty.call(message, "BandwidthDown"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.BandwidthDown);
        if (message.Participants != null && Object.hasOwnProperty.call(message, "Participants"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.Participants);
        if (message.MaxParticipants != null && Object.hasOwnProperty.call(message, "MaxParticipants"))
            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.MaxParticipants);
        if (message.Invites != null && Object.hasOwnProperty.call(message, "Invites"))
            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.Invites);
        if (message.OpenLinks != null && Object.hasOwnProperty.call(message, "OpenLinks"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.OpenLinks);
        if (message.Recorded != null && Object.hasOwnProperty.call(message, "Recorded"))
            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.Recorded);
        if (message.Mcu != null && Object.hasOwnProperty.call(message, "Mcu"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.Mcu);
        if (message.MeetingBegin != null && Object.hasOwnProperty.call(message, "MeetingBegin"))
            $root.DateTime.encode(message.MeetingBegin, writer.uint32(/* id 17, wireType 2 =*/138).fork()).ldelim();
        if (message.MeetingRowId != null && Object.hasOwnProperty.call(message, "MeetingRowId"))
            writer.uint32(/* id 18, wireType 0 =*/144).int32(message.MeetingRowId);
        if (message.MeetingObjects != null && Object.hasOwnProperty.call(message, "MeetingObjects"))
            writer.uint32(/* id 19, wireType 2 =*/154).string(message.MeetingObjects);
        if (message.SentAt != null && Object.hasOwnProperty.call(message, "SentAt"))
            $root.DateTime.encode(message.SentAt, writer.uint32(/* id 20, wireType 2 =*/162).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestPutWebMeetingReports2 message from the specified reader or buffer.
     * @function decode
     * @memberof RequestPutWebMeetingReports2
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestPutWebMeetingReports2} RequestPutWebMeetingReports2
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestPutWebMeetingReports2.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestPutWebMeetingReports2();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.MeetingId = reader.string();
                    break;
                }
            case 2: {
                    message.SessionId = reader.string();
                    break;
                }
            case 3: {
                    message.Report = reader.string();
                    break;
                }
            case 4: {
                    message.MeetingTitle = reader.string();
                    break;
                }
            case 5: {
                    message.OrganizerExtension = reader.string();
                    break;
                }
            case 6: {
                    message.OrganizerName = reader.string();
                    break;
                }
            case 7: {
                    message.OrganizerEmail = reader.string();
                    break;
                }
            case 8: {
                    message.Duration = reader.int32();
                    break;
                }
            case 9: {
                    message.BandwidthUp = reader.int32();
                    break;
                }
            case 10: {
                    message.BandwidthDown = reader.int32();
                    break;
                }
            case 11: {
                    message.Participants = reader.int32();
                    break;
                }
            case 12: {
                    message.MaxParticipants = reader.int32();
                    break;
                }
            case 13: {
                    message.Invites = reader.int32();
                    break;
                }
            case 14: {
                    message.OpenLinks = reader.int32();
                    break;
                }
            case 15: {
                    message.Recorded = reader.bool();
                    break;
                }
            case 16: {
                    message.Mcu = reader.string();
                    break;
                }
            case 17: {
                    message.MeetingBegin = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 18: {
                    message.MeetingRowId = reader.int32();
                    break;
                }
            case 19: {
                    message.MeetingObjects = reader.string();
                    break;
                }
            case 20: {
                    message.SentAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestPutWebMeetingReports2 message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestPutWebMeetingReports2
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestPutWebMeetingReports2.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 509, PutWebMeetingReports2 : this});
    };

    /**
     * RequestPutWebMeetingReports2 typeId
     * @member {number} typeId
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */
    RequestPutWebMeetingReports2.prototype.typeId = 509;
    /**
     * RequestPutWebMeetingReports2 typeName
     * @member {string} typeName
     * @memberof RequestPutWebMeetingReports2
     * @instance
     */
    RequestPutWebMeetingReports2.prototype.typeName = "PutWebMeetingReports2";

    return RequestPutWebMeetingReports2;
})();

export const RequestGetConferenceSchedule = $root.RequestGetConferenceSchedule = (() => {

    /**
     * Properties of a RequestGetConferenceSchedule.
     * @exports IRequestGetConferenceSchedule
     * @property {number} Id RequestGetConferenceSchedule Id
     * @property {string} PinCode RequestGetConferenceSchedule PinCode
     */

    /**
     * Constructs a new RequestGetConferenceSchedule.
     * @exports RequestGetConferenceSchedule
     * @classdesc Represents a RequestGetConferenceSchedule.
     * @constructor
     * @param {Partial<RequestGetConferenceSchedule>} [properties] Properties to set
     */
    function RequestGetConferenceSchedule(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetConferenceSchedule Id.
     * @member {number} Id
     * @memberof RequestGetConferenceSchedule
     * @instance
     */

    /**
     * RequestGetConferenceSchedule PinCode.
     * @member {string} PinCode
     * @memberof RequestGetConferenceSchedule
     * @instance
     */

    /**
     * Encodes the specified RequestGetConferenceSchedule message. Does not implicitly {@link RequestGetConferenceSchedule.verify|verify} messages.
     * @function encode
     * @memberof RequestGetConferenceSchedule
     * @static
     * @param {RequestGetConferenceSchedule} message RequestGetConferenceSchedule message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetConferenceSchedule.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.PinCode);
        return writer;
    };

    /**
     * Decodes a RequestGetConferenceSchedule message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetConferenceSchedule
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetConferenceSchedule} RequestGetConferenceSchedule
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetConferenceSchedule.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetConferenceSchedule();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.PinCode = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetConferenceSchedule message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetConferenceSchedule
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetConferenceSchedule.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 134, GetSchedule : this});
    };

    /**
     * RequestGetConferenceSchedule typeId
     * @member {number} typeId
     * @memberof RequestGetConferenceSchedule
     * @instance
     */
    RequestGetConferenceSchedule.prototype.typeId = 134;
    /**
     * RequestGetConferenceSchedule typeName
     * @member {string} typeName
     * @memberof RequestGetConferenceSchedule
     * @instance
     */
    RequestGetConferenceSchedule.prototype.typeName = "GetSchedule";

    return RequestGetConferenceSchedule;
})();

export const RequestCreateConferenceScheduleTemplate = $root.RequestCreateConferenceScheduleTemplate = (() => {

    /**
     * Properties of a RequestCreateConferenceScheduleTemplate.
     * @exports IRequestCreateConferenceScheduleTemplate
     */

    /**
     * Constructs a new RequestCreateConferenceScheduleTemplate.
     * @exports RequestCreateConferenceScheduleTemplate
     * @classdesc Represents a RequestCreateConferenceScheduleTemplate.
     * @constructor
     * @param {Partial<RequestCreateConferenceScheduleTemplate>} [properties] Properties to set
     */
    function RequestCreateConferenceScheduleTemplate(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestCreateConferenceScheduleTemplate message. Does not implicitly {@link RequestCreateConferenceScheduleTemplate.verify|verify} messages.
     * @function encode
     * @memberof RequestCreateConferenceScheduleTemplate
     * @static
     * @param {RequestCreateConferenceScheduleTemplate} message RequestCreateConferenceScheduleTemplate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestCreateConferenceScheduleTemplate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestCreateConferenceScheduleTemplate message from the specified reader or buffer.
     * @function decode
     * @memberof RequestCreateConferenceScheduleTemplate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestCreateConferenceScheduleTemplate} RequestCreateConferenceScheduleTemplate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestCreateConferenceScheduleTemplate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestCreateConferenceScheduleTemplate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestCreateConferenceScheduleTemplate message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestCreateConferenceScheduleTemplate
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestCreateConferenceScheduleTemplate.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 135, CreateConferenceScheduleTemplate : this});
    };

    /**
     * RequestCreateConferenceScheduleTemplate typeId
     * @member {number} typeId
     * @memberof RequestCreateConferenceScheduleTemplate
     * @instance
     */
    RequestCreateConferenceScheduleTemplate.prototype.typeId = 135;
    /**
     * RequestCreateConferenceScheduleTemplate typeName
     * @member {string} typeName
     * @memberof RequestCreateConferenceScheduleTemplate
     * @instance
     */
    RequestCreateConferenceScheduleTemplate.prototype.typeName = "CreateConferenceScheduleTemplate";

    return RequestCreateConferenceScheduleTemplate;
})();

export const Conferences = $root.Conferences = (() => {

    /**
     * Properties of a Conferences.
     * @exports IConferences
     * @property {ActionType} Action Conferences Action
     * @property {Array.<ConferenceState>} [Items] Conferences Items
     */

    /**
     * Constructs a new Conferences.
     * @exports Conferences
     * @classdesc Represents a Conferences.
     * @constructor
     * @param {Partial<Conferences>} [properties] Properties to set
     */
    function Conferences(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Conferences Action.
     * @member {ActionType} Action
     * @memberof Conferences
     * @instance
     */

    /**
     * Conferences Items.
     * @member {Array.<ConferenceState>} Items
     * @memberof Conferences
     * @instance
     */
    Conferences.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified Conferences message. Does not implicitly {@link Conferences.verify|verify} messages.
     * @function encode
     * @memberof Conferences
     * @static
     * @param {Conferences} message Conferences message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Conferences.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.ConferenceState.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a Conferences message from the specified reader or buffer.
     * @function decode
     * @memberof Conferences
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Conferences} Conferences
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Conferences.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Conferences();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.ConferenceState.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a Conferences message to GenericMessage
     * @function toGenericMessage
     * @memberof Conferences
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    Conferences.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 219, ActiveConferences : this});
    };

    /**
     * Conferences typeId
     * @member {number} typeId
     * @memberof Conferences
     * @instance
     */
    Conferences.prototype.typeId = 219;
    /**
     * Conferences typeName
     * @member {string} typeName
     * @memberof Conferences
     * @instance
     */
    Conferences.prototype.typeName = "ActiveConferences";

    return Conferences;
})();

export const RequestMyInfo = $root.RequestMyInfo = (() => {

    /**
     * Properties of a RequestMyInfo.
     * @exports IRequestMyInfo
     */

    /**
     * Constructs a new RequestMyInfo.
     * @exports RequestMyInfo
     * @classdesc Represents a RequestMyInfo.
     * @constructor
     * @param {Partial<RequestMyInfo>} [properties] Properties to set
     */
    function RequestMyInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestMyInfo message. Does not implicitly {@link RequestMyInfo.verify|verify} messages.
     * @function encode
     * @memberof RequestMyInfo
     * @static
     * @param {RequestMyInfo} message RequestMyInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestMyInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestMyInfo message from the specified reader or buffer.
     * @function decode
     * @memberof RequestMyInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestMyInfo} RequestMyInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestMyInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestMyInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestMyInfo message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestMyInfo
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestMyInfo.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 102, GetMyInfo : this});
    };

    /**
     * RequestMyInfo typeId
     * @member {number} typeId
     * @memberof RequestMyInfo
     * @instance
     */
    RequestMyInfo.prototype.typeId = 102;
    /**
     * RequestMyInfo typeName
     * @member {string} typeName
     * @memberof RequestMyInfo
     * @instance
     */
    RequestMyInfo.prototype.typeName = "GetMyInfo";

    return RequestMyInfo;
})();

/**
 * FPStatusOverrideAction enum.
 * @exports FPStatusOverrideAction
 * @enum {string}
 * @property {number} FPOverride_ResetOverrideProfile=1 FPOverride_ResetOverrideProfile value
 * @property {number} FPOverride_SetAsOverrideProfile=2 FPOverride_SetAsOverrideProfile value
 * @property {number} FPOverride_SetCurrentLeaveOverride=3 FPOverride_SetCurrentLeaveOverride value
 */
export const FPStatusOverrideAction = $root.FPStatusOverrideAction = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "FPOverride_ResetOverrideProfile"] = 1;
    values[valuesById[2] = "FPOverride_SetAsOverrideProfile"] = 2;
    values[valuesById[3] = "FPOverride_SetCurrentLeaveOverride"] = 3;
    return values;
})();

export const RequestChangeStatus = $root.RequestChangeStatus = (() => {

    /**
     * Properties of a RequestChangeStatus.
     * @exports IRequestChangeStatus
     * @property {number} [ProfileId] RequestChangeStatus ProfileId
     * @property {string} [CustomMessage] RequestChangeStatus CustomMessage
     * @property {boolean} [QueueStatus] RequestChangeStatus QueueStatus
     * @property {boolean} [DND] RequestChangeStatus DND
     * @property {string} [FirstName] RequestChangeStatus FirstName
     * @property {string} [LastName] RequestChangeStatus LastName
     * @property {number} [OfficeHours] RequestChangeStatus OfficeHours
     * @property {ChatStatusType} [ChatStatus] RequestChangeStatus ChatStatus
     * @property {string} [CustomName] RequestChangeStatus CustomName
     * @property {GroupIds} [MyGroupSettings] RequestChangeStatus MyGroupSettings
     * @property {string} [MobileNumber] RequestChangeStatus MobileNumber
     * @property {FPStatusOverrideAction} [OverrideProfileAction] RequestChangeStatus OverrideProfileAction
     * @property {DateTime} [OverrideExpiresAtUTCtime] RequestChangeStatus OverrideExpiresAtUTCtime
     * @property {number} [OverrideExpiresSeconds] RequestChangeStatus OverrideExpiresSeconds
     * @property {string} [OverrideAttachedData] RequestChangeStatus OverrideAttachedData
     * @property {boolean} [IsInternal] RequestChangeStatus IsInternal
     * @property {string} [TargetExtension] RequestChangeStatus TargetExtension
     * @property {string} [InternalProfileName] RequestChangeStatus InternalProfileName
     * @property {string} [Email] RequestChangeStatus Email
     */

    /**
     * Constructs a new RequestChangeStatus.
     * @exports RequestChangeStatus
     * @classdesc Represents a RequestChangeStatus.
     * @constructor
     * @param {Partial<RequestChangeStatus>} [properties] Properties to set
     */
    function RequestChangeStatus(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestChangeStatus ProfileId.
     * @member {number} ProfileId
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus CustomMessage.
     * @member {string} CustomMessage
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus QueueStatus.
     * @member {boolean} QueueStatus
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus DND.
     * @member {boolean} DND
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus FirstName.
     * @member {string} FirstName
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus LastName.
     * @member {string} LastName
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus OfficeHours.
     * @member {number} OfficeHours
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus ChatStatus.
     * @member {ChatStatusType} ChatStatus
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus CustomName.
     * @member {string} CustomName
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus MyGroupSettings.
     * @member {GroupIds} MyGroupSettings
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus MobileNumber.
     * @member {string} MobileNumber
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus OverrideProfileAction.
     * @member {FPStatusOverrideAction} OverrideProfileAction
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus OverrideExpiresAtUTCtime.
     * @member {DateTime} OverrideExpiresAtUTCtime
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus OverrideExpiresSeconds.
     * @member {number} OverrideExpiresSeconds
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus OverrideAttachedData.
     * @member {string} OverrideAttachedData
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus IsInternal.
     * @member {boolean} IsInternal
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus TargetExtension.
     * @member {string} TargetExtension
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus InternalProfileName.
     * @member {string} InternalProfileName
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * RequestChangeStatus Email.
     * @member {string} Email
     * @memberof RequestChangeStatus
     * @instance
     */

    /**
     * Encodes the specified RequestChangeStatus message. Does not implicitly {@link RequestChangeStatus.verify|verify} messages.
     * @function encode
     * @memberof RequestChangeStatus
     * @static
     * @param {RequestChangeStatus} message RequestChangeStatus message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestChangeStatus.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ProfileId != null && Object.hasOwnProperty.call(message, "ProfileId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ProfileId);
        if (message.CustomMessage != null && Object.hasOwnProperty.call(message, "CustomMessage"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.CustomMessage);
        if (message.QueueStatus != null && Object.hasOwnProperty.call(message, "QueueStatus"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.QueueStatus);
        if (message.DND != null && Object.hasOwnProperty.call(message, "DND"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.DND);
        if (message.FirstName != null && Object.hasOwnProperty.call(message, "FirstName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.FirstName);
        if (message.LastName != null && Object.hasOwnProperty.call(message, "LastName"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.LastName);
        if (message.OfficeHours != null && Object.hasOwnProperty.call(message, "OfficeHours"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.OfficeHours);
        if (message.ChatStatus != null && Object.hasOwnProperty.call(message, "ChatStatus"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.ChatStatus);
        if (message.CustomName != null && Object.hasOwnProperty.call(message, "CustomName"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.CustomName);
        if (message.MyGroupSettings != null && Object.hasOwnProperty.call(message, "MyGroupSettings"))
            $root.GroupIds.encode(message.MyGroupSettings, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.MobileNumber != null && Object.hasOwnProperty.call(message, "MobileNumber"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.MobileNumber);
        if (message.OverrideProfileAction != null && Object.hasOwnProperty.call(message, "OverrideProfileAction"))
            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.OverrideProfileAction);
        if (message.OverrideExpiresAtUTCtime != null && Object.hasOwnProperty.call(message, "OverrideExpiresAtUTCtime"))
            $root.DateTime.encode(message.OverrideExpiresAtUTCtime, writer.uint32(/* id 13, wireType 2 =*/106).fork()).ldelim();
        if (message.OverrideExpiresSeconds != null && Object.hasOwnProperty.call(message, "OverrideExpiresSeconds"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.OverrideExpiresSeconds);
        if (message.OverrideAttachedData != null && Object.hasOwnProperty.call(message, "OverrideAttachedData"))
            writer.uint32(/* id 15, wireType 2 =*/122).string(message.OverrideAttachedData);
        if (message.IsInternal != null && Object.hasOwnProperty.call(message, "IsInternal"))
            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.IsInternal);
        if (message.TargetExtension != null && Object.hasOwnProperty.call(message, "TargetExtension"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.TargetExtension);
        if (message.InternalProfileName != null && Object.hasOwnProperty.call(message, "InternalProfileName"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.InternalProfileName);
        if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
            writer.uint32(/* id 19, wireType 2 =*/154).string(message.Email);
        return writer;
    };

    /**
     * Decodes a RequestChangeStatus message from the specified reader or buffer.
     * @function decode
     * @memberof RequestChangeStatus
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestChangeStatus} RequestChangeStatus
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestChangeStatus.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestChangeStatus();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ProfileId = reader.int32();
                    break;
                }
            case 2: {
                    message.CustomMessage = reader.string();
                    break;
                }
            case 3: {
                    message.QueueStatus = reader.bool();
                    break;
                }
            case 4: {
                    message.DND = reader.bool();
                    break;
                }
            case 5: {
                    message.FirstName = reader.string();
                    break;
                }
            case 6: {
                    message.LastName = reader.string();
                    break;
                }
            case 7: {
                    message.OfficeHours = reader.int32();
                    break;
                }
            case 8: {
                    message.ChatStatus = reader.int32();
                    break;
                }
            case 9: {
                    message.CustomName = reader.string();
                    break;
                }
            case 10: {
                    message.MyGroupSettings = $root.GroupIds.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.MobileNumber = reader.string();
                    break;
                }
            case 12: {
                    message.OverrideProfileAction = reader.int32();
                    break;
                }
            case 13: {
                    message.OverrideExpiresAtUTCtime = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 14: {
                    message.OverrideExpiresSeconds = reader.int32();
                    break;
                }
            case 15: {
                    message.OverrideAttachedData = reader.string();
                    break;
                }
            case 16: {
                    message.IsInternal = reader.bool();
                    break;
                }
            case 17: {
                    message.TargetExtension = reader.string();
                    break;
                }
            case 18: {
                    message.InternalProfileName = reader.string();
                    break;
                }
            case 19: {
                    message.Email = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestChangeStatus message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestChangeStatus
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestChangeStatus.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 103, ChangeStatus : this});
    };

    /**
     * RequestChangeStatus typeId
     * @member {number} typeId
     * @memberof RequestChangeStatus
     * @instance
     */
    RequestChangeStatus.prototype.typeId = 103;
    /**
     * RequestChangeStatus typeName
     * @member {string} typeName
     * @memberof RequestChangeStatus
     * @instance
     */
    RequestChangeStatus.prototype.typeName = "ChangeStatus";

    return RequestChangeStatus;
})();

export const Contact = $root.Contact = (() => {

    /**
     * Properties of a Contact.
     * @exports IContact
     * @property {number} Id Contact Id
     * @property {string} [FirstName] Contact FirstName
     * @property {string} [LastName] Contact LastName
     * @property {string} [Number] Contact Number
     * @property {string} [ExtensionNumber] Contact ExtensionNumber
     * @property {ContactType} [ContactType] Contact ContactType
     * @property {string} [Company] Contact Company
     * @property {string} [AddressNumberOrData0] Contact AddressNumberOrData0
     * @property {string} [AddressNumberOrData1] Contact AddressNumberOrData1
     * @property {string} [AddressNumberOrData2] Contact AddressNumberOrData2
     * @property {string} [AddressNumberOrData3] Contact AddressNumberOrData3
     * @property {string} [AddressNumberOrData4] Contact AddressNumberOrData4
     * @property {string} [AddressNumberOrData5] Contact AddressNumberOrData5
     * @property {string} [AddressNumberOrData6] Contact AddressNumberOrData6
     * @property {string} [AddressNumberOrData7] Contact AddressNumberOrData7
     * @property {string} [AddressNumberOrData8] Contact AddressNumberOrData8
     * @property {string} [AddressNumberOrData9] Contact AddressNumberOrData9
     * @property {ActionType} Action Contact Action
     * @property {string} [ContactImage] Contact ContactImage
     * @property {boolean} [IsEditable] Contact IsEditable
     * @property {string} [CrmContactData] Contact CrmContactData
     * @property {ContactAddedByEnum} [AddedBy] Contact AddedBy
     * @property {DnType} [DnType] Contact DnType
     * @property {string} [OpenLink] Contact OpenLink
     * @property {boolean} [AvailableForTransfer] Contact AvailableForTransfer
     */

    /**
     * Constructs a new Contact.
     * @exports Contact
     * @classdesc Represents a Contact.
     * @constructor
     * @param {Partial<Contact>} [properties] Properties to set
     */
    function Contact(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Contact Id.
     * @member {number} Id
     * @memberof Contact
     * @instance
     */

    /**
     * Contact FirstName.
     * @member {string} FirstName
     * @memberof Contact
     * @instance
     */

    /**
     * Contact LastName.
     * @member {string} LastName
     * @memberof Contact
     * @instance
     */

    /**
     * Contact Number.
     * @member {string} Number
     * @memberof Contact
     * @instance
     */

    /**
     * Contact ExtensionNumber.
     * @member {string} ExtensionNumber
     * @memberof Contact
     * @instance
     */

    /**
     * Contact ContactType.
     * @member {ContactType} ContactType
     * @memberof Contact
     * @instance
     */

    /**
     * Contact Company.
     * @member {string} Company
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData0.
     * @member {string} AddressNumberOrData0
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData1.
     * @member {string} AddressNumberOrData1
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData2.
     * @member {string} AddressNumberOrData2
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData3.
     * @member {string} AddressNumberOrData3
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData4.
     * @member {string} AddressNumberOrData4
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData5.
     * @member {string} AddressNumberOrData5
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData6.
     * @member {string} AddressNumberOrData6
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData7.
     * @member {string} AddressNumberOrData7
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData8.
     * @member {string} AddressNumberOrData8
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddressNumberOrData9.
     * @member {string} AddressNumberOrData9
     * @memberof Contact
     * @instance
     */

    /**
     * Contact Action.
     * @member {ActionType} Action
     * @memberof Contact
     * @instance
     */

    /**
     * Contact ContactImage.
     * @member {string} ContactImage
     * @memberof Contact
     * @instance
     */

    /**
     * Contact IsEditable.
     * @member {boolean} IsEditable
     * @memberof Contact
     * @instance
     */

    /**
     * Contact CrmContactData.
     * @member {string} CrmContactData
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AddedBy.
     * @member {ContactAddedByEnum} AddedBy
     * @memberof Contact
     * @instance
     */

    /**
     * Contact DnType.
     * @member {DnType} DnType
     * @memberof Contact
     * @instance
     */

    /**
     * Contact OpenLink.
     * @member {string} OpenLink
     * @memberof Contact
     * @instance
     */

    /**
     * Contact AvailableForTransfer.
     * @member {boolean} AvailableForTransfer
     * @memberof Contact
     * @instance
     */

    /**
     * Encodes the specified Contact message. Does not implicitly {@link Contact.verify|verify} messages.
     * @function encode
     * @memberof Contact
     * @static
     * @param {Contact} message Contact message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Contact.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.FirstName != null && Object.hasOwnProperty.call(message, "FirstName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.FirstName);
        if (message.LastName != null && Object.hasOwnProperty.call(message, "LastName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.LastName);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Number);
        if (message.ExtensionNumber != null && Object.hasOwnProperty.call(message, "ExtensionNumber"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.ExtensionNumber);
        if (message.ContactType != null && Object.hasOwnProperty.call(message, "ContactType"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.ContactType);
        if (message.Company != null && Object.hasOwnProperty.call(message, "Company"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.Company);
        if (message.AddressNumberOrData0 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData0"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.AddressNumberOrData0);
        if (message.AddressNumberOrData1 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData1"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.AddressNumberOrData1);
        if (message.AddressNumberOrData2 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData2"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.AddressNumberOrData2);
        if (message.AddressNumberOrData3 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData3"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.AddressNumberOrData3);
        if (message.AddressNumberOrData4 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData4"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.AddressNumberOrData4);
        if (message.AddressNumberOrData5 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData5"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.AddressNumberOrData5);
        if (message.AddressNumberOrData6 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData6"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.AddressNumberOrData6);
        if (message.AddressNumberOrData7 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData7"))
            writer.uint32(/* id 15, wireType 2 =*/122).string(message.AddressNumberOrData7);
        if (message.AddressNumberOrData8 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData8"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.AddressNumberOrData8);
        if (message.AddressNumberOrData9 != null && Object.hasOwnProperty.call(message, "AddressNumberOrData9"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.AddressNumberOrData9);
        writer.uint32(/* id 18, wireType 0 =*/144).int32(message.Action);
        if (message.ContactImage != null && Object.hasOwnProperty.call(message, "ContactImage"))
            writer.uint32(/* id 19, wireType 2 =*/154).string(message.ContactImage);
        if (message.IsEditable != null && Object.hasOwnProperty.call(message, "IsEditable"))
            writer.uint32(/* id 20, wireType 0 =*/160).bool(message.IsEditable);
        if (message.CrmContactData != null && Object.hasOwnProperty.call(message, "CrmContactData"))
            writer.uint32(/* id 22, wireType 2 =*/178).string(message.CrmContactData);
        if (message.AddedBy != null && Object.hasOwnProperty.call(message, "AddedBy"))
            writer.uint32(/* id 23, wireType 0 =*/184).int32(message.AddedBy);
        if (message.DnType != null && Object.hasOwnProperty.call(message, "DnType"))
            writer.uint32(/* id 24, wireType 0 =*/192).int32(message.DnType);
        if (message.OpenLink != null && Object.hasOwnProperty.call(message, "OpenLink"))
            writer.uint32(/* id 25, wireType 2 =*/202).string(message.OpenLink);
        if (message.AvailableForTransfer != null && Object.hasOwnProperty.call(message, "AvailableForTransfer"))
            writer.uint32(/* id 26, wireType 0 =*/208).bool(message.AvailableForTransfer);
        return writer;
    };

    /**
     * Decodes a Contact message from the specified reader or buffer.
     * @function decode
     * @memberof Contact
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Contact} Contact
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Contact.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Contact();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.FirstName = reader.string();
                    break;
                }
            case 3: {
                    message.LastName = reader.string();
                    break;
                }
            case 4: {
                    message.Number = reader.string();
                    break;
                }
            case 5: {
                    message.ExtensionNumber = reader.string();
                    break;
                }
            case 6: {
                    message.ContactType = reader.int32();
                    break;
                }
            case 7: {
                    message.Company = reader.string();
                    break;
                }
            case 8: {
                    message.AddressNumberOrData0 = reader.string();
                    break;
                }
            case 9: {
                    message.AddressNumberOrData1 = reader.string();
                    break;
                }
            case 10: {
                    message.AddressNumberOrData2 = reader.string();
                    break;
                }
            case 11: {
                    message.AddressNumberOrData3 = reader.string();
                    break;
                }
            case 12: {
                    message.AddressNumberOrData4 = reader.string();
                    break;
                }
            case 13: {
                    message.AddressNumberOrData5 = reader.string();
                    break;
                }
            case 14: {
                    message.AddressNumberOrData6 = reader.string();
                    break;
                }
            case 15: {
                    message.AddressNumberOrData7 = reader.string();
                    break;
                }
            case 16: {
                    message.AddressNumberOrData8 = reader.string();
                    break;
                }
            case 17: {
                    message.AddressNumberOrData9 = reader.string();
                    break;
                }
            case 18: {
                    message.Action = reader.int32();
                    break;
                }
            case 19: {
                    message.ContactImage = reader.string();
                    break;
                }
            case 20: {
                    message.IsEditable = reader.bool();
                    break;
                }
            case 22: {
                    message.CrmContactData = reader.string();
                    break;
                }
            case 23: {
                    message.AddedBy = reader.int32();
                    break;
                }
            case 24: {
                    message.DnType = reader.int32();
                    break;
                }
            case 25: {
                    message.OpenLink = reader.string();
                    break;
                }
            case 26: {
                    message.AvailableForTransfer = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return Contact;
})();

export const RequestLookupContact = $root.RequestLookupContact = (() => {

    /**
     * Properties of a RequestLookupContact.
     * @exports IRequestLookupContact
     * @property {string} [Input] RequestLookupContact Input
     * @property {number} [Offset] RequestLookupContact Offset
     * @property {number} [Count] RequestLookupContact Count
     * @property {boolean} [ExtIncluded] RequestLookupContact ExtIncluded
     * @property {SortContactsBy} [SortBy] RequestLookupContact SortBy
     * @property {boolean} [MustHaveEmail] RequestLookupContact MustHaveEmail
     * @property {number} [SearchBy] RequestLookupContact SearchBy
     * @property {boolean} [SearchBridge] RequestLookupContact SearchBridge
     * @property {boolean} [SearchCompany] RequestLookupContact SearchCompany
     * @property {boolean} [SearchPersonal] RequestLookupContact SearchPersonal
     * @property {number} [SysExtsMask] RequestLookupContact SysExtsMask
     * @property {boolean} [MustHaveMobile] RequestLookupContact MustHaveMobile
     * @property {number} [IncludeCompanyOptions] RequestLookupContact IncludeCompanyOptions
     * @property {number} [IncludePersonalOptions] RequestLookupContact IncludePersonalOptions
     */

    /**
     * Constructs a new RequestLookupContact.
     * @exports RequestLookupContact
     * @classdesc Represents a RequestLookupContact.
     * @constructor
     * @param {Partial<RequestLookupContact>} [properties] Properties to set
     */
    function RequestLookupContact(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestLookupContact Input.
     * @member {string} Input
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact Offset.
     * @member {number} Offset
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact Count.
     * @member {number} Count
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact ExtIncluded.
     * @member {boolean} ExtIncluded
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact SortBy.
     * @member {SortContactsBy} SortBy
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact MustHaveEmail.
     * @member {boolean} MustHaveEmail
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact SearchBy.
     * @member {number} SearchBy
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact SearchBridge.
     * @member {boolean} SearchBridge
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact SearchCompany.
     * @member {boolean} SearchCompany
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact SearchPersonal.
     * @member {boolean} SearchPersonal
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact SysExtsMask.
     * @member {number} SysExtsMask
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact MustHaveMobile.
     * @member {boolean} MustHaveMobile
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact IncludeCompanyOptions.
     * @member {number} IncludeCompanyOptions
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * RequestLookupContact IncludePersonalOptions.
     * @member {number} IncludePersonalOptions
     * @memberof RequestLookupContact
     * @instance
     */

    /**
     * Encodes the specified RequestLookupContact message. Does not implicitly {@link RequestLookupContact.verify|verify} messages.
     * @function encode
     * @memberof RequestLookupContact
     * @static
     * @param {RequestLookupContact} message RequestLookupContact message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestLookupContact.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Input != null && Object.hasOwnProperty.call(message, "Input"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Input);
        if (message.Offset != null && Object.hasOwnProperty.call(message, "Offset"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Offset);
        if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Count);
        if (message.ExtIncluded != null && Object.hasOwnProperty.call(message, "ExtIncluded"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.ExtIncluded);
        if (message.SortBy != null && Object.hasOwnProperty.call(message, "SortBy"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.SortBy);
        if (message.MustHaveEmail != null && Object.hasOwnProperty.call(message, "MustHaveEmail"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.MustHaveEmail);
        if (message.SearchBy != null && Object.hasOwnProperty.call(message, "SearchBy"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.SearchBy);
        if (message.SearchBridge != null && Object.hasOwnProperty.call(message, "SearchBridge"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.SearchBridge);
        if (message.SearchCompany != null && Object.hasOwnProperty.call(message, "SearchCompany"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.SearchCompany);
        if (message.SearchPersonal != null && Object.hasOwnProperty.call(message, "SearchPersonal"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.SearchPersonal);
        if (message.SysExtsMask != null && Object.hasOwnProperty.call(message, "SysExtsMask"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.SysExtsMask);
        if (message.MustHaveMobile != null && Object.hasOwnProperty.call(message, "MustHaveMobile"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.MustHaveMobile);
        if (message.IncludeCompanyOptions != null && Object.hasOwnProperty.call(message, "IncludeCompanyOptions"))
            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.IncludeCompanyOptions);
        if (message.IncludePersonalOptions != null && Object.hasOwnProperty.call(message, "IncludePersonalOptions"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.IncludePersonalOptions);
        return writer;
    };

    /**
     * Decodes a RequestLookupContact message from the specified reader or buffer.
     * @function decode
     * @memberof RequestLookupContact
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestLookupContact} RequestLookupContact
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestLookupContact.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestLookupContact();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Input = reader.string();
                    break;
                }
            case 2: {
                    message.Offset = reader.int32();
                    break;
                }
            case 3: {
                    message.Count = reader.int32();
                    break;
                }
            case 4: {
                    message.ExtIncluded = reader.bool();
                    break;
                }
            case 5: {
                    message.SortBy = reader.int32();
                    break;
                }
            case 6: {
                    message.MustHaveEmail = reader.bool();
                    break;
                }
            case 7: {
                    message.SearchBy = reader.int32();
                    break;
                }
            case 8: {
                    message.SearchBridge = reader.bool();
                    break;
                }
            case 9: {
                    message.SearchCompany = reader.bool();
                    break;
                }
            case 10: {
                    message.SearchPersonal = reader.bool();
                    break;
                }
            case 11: {
                    message.SysExtsMask = reader.int32();
                    break;
                }
            case 12: {
                    message.MustHaveMobile = reader.bool();
                    break;
                }
            case 13: {
                    message.IncludeCompanyOptions = reader.int32();
                    break;
                }
            case 14: {
                    message.IncludePersonalOptions = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestLookupContact message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestLookupContact
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestLookupContact.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 104, LookupContact : this});
    };

    /**
     * RequestLookupContact typeId
     * @member {number} typeId
     * @memberof RequestLookupContact
     * @instance
     */
    RequestLookupContact.prototype.typeId = 104;
    /**
     * RequestLookupContact typeName
     * @member {string} typeName
     * @memberof RequestLookupContact
     * @instance
     */
    RequestLookupContact.prototype.typeName = "LookupContact";

    return RequestLookupContact;
})();

export const RequestExactMatch = $root.RequestExactMatch = (() => {

    /**
     * Properties of a RequestExactMatch.
     * @exports IRequestExactMatch
     * @property {string} [Number] RequestExactMatch Number
     * @property {number} [DnType] RequestExactMatch DnType
     * @property {boolean} [SearchCompany] RequestExactMatch SearchCompany
     * @property {boolean} [SearchPersonal] RequestExactMatch SearchPersonal
     * @property {boolean} [SearchBridge] RequestExactMatch SearchBridge
     */

    /**
     * Constructs a new RequestExactMatch.
     * @exports RequestExactMatch
     * @classdesc Represents a RequestExactMatch.
     * @constructor
     * @param {Partial<RequestExactMatch>} [properties] Properties to set
     */
    function RequestExactMatch(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestExactMatch Number.
     * @member {string} Number
     * @memberof RequestExactMatch
     * @instance
     */

    /**
     * RequestExactMatch DnType.
     * @member {number} DnType
     * @memberof RequestExactMatch
     * @instance
     */

    /**
     * RequestExactMatch SearchCompany.
     * @member {boolean} SearchCompany
     * @memberof RequestExactMatch
     * @instance
     */

    /**
     * RequestExactMatch SearchPersonal.
     * @member {boolean} SearchPersonal
     * @memberof RequestExactMatch
     * @instance
     */

    /**
     * RequestExactMatch SearchBridge.
     * @member {boolean} SearchBridge
     * @memberof RequestExactMatch
     * @instance
     */

    /**
     * Encodes the specified RequestExactMatch message. Does not implicitly {@link RequestExactMatch.verify|verify} messages.
     * @function encode
     * @memberof RequestExactMatch
     * @static
     * @param {RequestExactMatch} message RequestExactMatch message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestExactMatch.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Number);
        if (message.DnType != null && Object.hasOwnProperty.call(message, "DnType"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.DnType);
        if (message.SearchCompany != null && Object.hasOwnProperty.call(message, "SearchCompany"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.SearchCompany);
        if (message.SearchPersonal != null && Object.hasOwnProperty.call(message, "SearchPersonal"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.SearchPersonal);
        if (message.SearchBridge != null && Object.hasOwnProperty.call(message, "SearchBridge"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.SearchBridge);
        return writer;
    };

    /**
     * Decodes a RequestExactMatch message from the specified reader or buffer.
     * @function decode
     * @memberof RequestExactMatch
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestExactMatch} RequestExactMatch
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestExactMatch.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestExactMatch();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Number = reader.string();
                    break;
                }
            case 2: {
                    message.DnType = reader.int32();
                    break;
                }
            case 3: {
                    message.SearchCompany = reader.bool();
                    break;
                }
            case 4: {
                    message.SearchPersonal = reader.bool();
                    break;
                }
            case 5: {
                    message.SearchBridge = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestExactMatch message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestExactMatch
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestExactMatch.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 193, ExactMatchRequest : this});
    };

    /**
     * RequestExactMatch typeId
     * @member {number} typeId
     * @memberof RequestExactMatch
     * @instance
     */
    RequestExactMatch.prototype.typeId = 193;
    /**
     * RequestExactMatch typeName
     * @member {string} typeName
     * @memberof RequestExactMatch
     * @instance
     */
    RequestExactMatch.prototype.typeName = "ExactMatchRequest";

    return RequestExactMatch;
})();

export const ResponseExactMatch = $root.ResponseExactMatch = (() => {

    /**
     * Properties of a ResponseExactMatch.
     * @exports IResponseExactMatch
     * @property {Contact} [Contact] ResponseExactMatch Contact
     */

    /**
     * Constructs a new ResponseExactMatch.
     * @exports ResponseExactMatch
     * @classdesc Represents a ResponseExactMatch.
     * @constructor
     * @param {Partial<ResponseExactMatch>} [properties] Properties to set
     */
    function ResponseExactMatch(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseExactMatch Contact.
     * @member {Contact} Contact
     * @memberof ResponseExactMatch
     * @instance
     */

    /**
     * Encodes the specified ResponseExactMatch message. Does not implicitly {@link ResponseExactMatch.verify|verify} messages.
     * @function encode
     * @memberof ResponseExactMatch
     * @static
     * @param {ResponseExactMatch} message ResponseExactMatch message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseExactMatch.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Contact != null && Object.hasOwnProperty.call(message, "Contact"))
            $root.Contact.encode(message.Contact, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseExactMatch message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseExactMatch
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseExactMatch} ResponseExactMatch
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseExactMatch.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseExactMatch();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Contact = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseExactMatch message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseExactMatch
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseExactMatch.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 240, ExactMatchResponse : this});
    };

    /**
     * ResponseExactMatch typeId
     * @member {number} typeId
     * @memberof ResponseExactMatch
     * @instance
     */
    ResponseExactMatch.prototype.typeId = 240;
    /**
     * ResponseExactMatch typeName
     * @member {string} typeName
     * @memberof ResponseExactMatch
     * @instance
     */
    ResponseExactMatch.prototype.typeName = "ExactMatchResponse";

    return ResponseExactMatch;
})();

export const RequestBatchExactMatch = $root.RequestBatchExactMatch = (() => {

    /**
     * Properties of a RequestBatchExactMatch.
     * @exports IRequestBatchExactMatch
     * @property {Array.<RequestExactMatch>} [ToSearch] RequestBatchExactMatch ToSearch
     */

    /**
     * Constructs a new RequestBatchExactMatch.
     * @exports RequestBatchExactMatch
     * @classdesc Represents a RequestBatchExactMatch.
     * @constructor
     * @param {Partial<RequestBatchExactMatch>} [properties] Properties to set
     */
    function RequestBatchExactMatch(properties) {
        this.ToSearch = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestBatchExactMatch ToSearch.
     * @member {Array.<RequestExactMatch>} ToSearch
     * @memberof RequestBatchExactMatch
     * @instance
     */
    RequestBatchExactMatch.prototype.ToSearch = $util.emptyArray;

    /**
     * Encodes the specified RequestBatchExactMatch message. Does not implicitly {@link RequestBatchExactMatch.verify|verify} messages.
     * @function encode
     * @memberof RequestBatchExactMatch
     * @static
     * @param {RequestBatchExactMatch} message RequestBatchExactMatch message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestBatchExactMatch.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ToSearch != null && message.ToSearch.length)
            for (let i = 0; i < message.ToSearch.length; ++i)
                $root.RequestExactMatch.encode(message.ToSearch[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestBatchExactMatch message from the specified reader or buffer.
     * @function decode
     * @memberof RequestBatchExactMatch
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestBatchExactMatch} RequestBatchExactMatch
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestBatchExactMatch.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestBatchExactMatch();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.ToSearch && message.ToSearch.length))
                        message.ToSearch = [];
                    message.ToSearch.push($root.RequestExactMatch.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestBatchExactMatch message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestBatchExactMatch
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestBatchExactMatch.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 529, BatchExactMatch : this});
    };

    /**
     * RequestBatchExactMatch typeId
     * @member {number} typeId
     * @memberof RequestBatchExactMatch
     * @instance
     */
    RequestBatchExactMatch.prototype.typeId = 529;
    /**
     * RequestBatchExactMatch typeName
     * @member {string} typeName
     * @memberof RequestBatchExactMatch
     * @instance
     */
    RequestBatchExactMatch.prototype.typeName = "BatchExactMatch";

    return RequestBatchExactMatch;
})();

export const ResponseBatchExactMatch = $root.ResponseBatchExactMatch = (() => {

    /**
     * Properties of a ResponseBatchExactMatch.
     * @exports IResponseBatchExactMatch
     * @property {Array.<ResponseExactMatch>} [Result] ResponseBatchExactMatch Result
     */

    /**
     * Constructs a new ResponseBatchExactMatch.
     * @exports ResponseBatchExactMatch
     * @classdesc Represents a ResponseBatchExactMatch.
     * @constructor
     * @param {Partial<ResponseBatchExactMatch>} [properties] Properties to set
     */
    function ResponseBatchExactMatch(properties) {
        this.Result = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseBatchExactMatch Result.
     * @member {Array.<ResponseExactMatch>} Result
     * @memberof ResponseBatchExactMatch
     * @instance
     */
    ResponseBatchExactMatch.prototype.Result = $util.emptyArray;

    /**
     * Encodes the specified ResponseBatchExactMatch message. Does not implicitly {@link ResponseBatchExactMatch.verify|verify} messages.
     * @function encode
     * @memberof ResponseBatchExactMatch
     * @static
     * @param {ResponseBatchExactMatch} message ResponseBatchExactMatch message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseBatchExactMatch.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Result != null && message.Result.length)
            for (let i = 0; i < message.Result.length; ++i)
                $root.ResponseExactMatch.encode(message.Result[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseBatchExactMatch message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseBatchExactMatch
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseBatchExactMatch} ResponseBatchExactMatch
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseBatchExactMatch.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseBatchExactMatch();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Result && message.Result.length))
                        message.Result = [];
                    message.Result.push($root.ResponseExactMatch.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseBatchExactMatch message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseBatchExactMatch
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseBatchExactMatch.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 263, BatchExactMatchResult : this});
    };

    /**
     * ResponseBatchExactMatch typeId
     * @member {number} typeId
     * @memberof ResponseBatchExactMatch
     * @instance
     */
    ResponseBatchExactMatch.prototype.typeId = 263;
    /**
     * ResponseBatchExactMatch typeName
     * @member {string} typeName
     * @memberof ResponseBatchExactMatch
     * @instance
     */
    ResponseBatchExactMatch.prototype.typeName = "BatchExactMatchResult";

    return ResponseBatchExactMatch;
})();

export const RequestGetContactById = $root.RequestGetContactById = (() => {

    /**
     * Properties of a RequestGetContactById.
     * @exports IRequestGetContactById
     * @property {number} Id RequestGetContactById Id
     * @property {ContactType} [ContactType] RequestGetContactById ContactType
     */

    /**
     * Constructs a new RequestGetContactById.
     * @exports RequestGetContactById
     * @classdesc Represents a RequestGetContactById.
     * @constructor
     * @param {Partial<RequestGetContactById>} [properties] Properties to set
     */
    function RequestGetContactById(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetContactById Id.
     * @member {number} Id
     * @memberof RequestGetContactById
     * @instance
     */

    /**
     * RequestGetContactById ContactType.
     * @member {ContactType} ContactType
     * @memberof RequestGetContactById
     * @instance
     */

    /**
     * Encodes the specified RequestGetContactById message. Does not implicitly {@link RequestGetContactById.verify|verify} messages.
     * @function encode
     * @memberof RequestGetContactById
     * @static
     * @param {RequestGetContactById} message RequestGetContactById message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetContactById.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.ContactType != null && Object.hasOwnProperty.call(message, "ContactType"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ContactType);
        return writer;
    };

    /**
     * Decodes a RequestGetContactById message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetContactById
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetContactById} RequestGetContactById
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetContactById.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetContactById();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.ContactType = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetContactById message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetContactById
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetContactById.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 194, GetContactById : this});
    };

    /**
     * RequestGetContactById typeId
     * @member {number} typeId
     * @memberof RequestGetContactById
     * @instance
     */
    RequestGetContactById.prototype.typeId = 194;
    /**
     * RequestGetContactById typeName
     * @member {string} typeName
     * @memberof RequestGetContactById
     * @instance
     */
    RequestGetContactById.prototype.typeName = "GetContactById";

    return RequestGetContactById;
})();

export const ResponseLookup = $root.ResponseLookup = (() => {

    /**
     * Properties of a ResponseLookup.
     * @exports IResponseLookup
     * @property {Array.<Contact>} [Entries] ResponseLookup Entries
     * @property {number} [TotalCount] ResponseLookup TotalCount
     */

    /**
     * Constructs a new ResponseLookup.
     * @exports ResponseLookup
     * @classdesc Represents a ResponseLookup.
     * @constructor
     * @param {Partial<ResponseLookup>} [properties] Properties to set
     */
    function ResponseLookup(properties) {
        this.Entries = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseLookup Entries.
     * @member {Array.<Contact>} Entries
     * @memberof ResponseLookup
     * @instance
     */
    ResponseLookup.prototype.Entries = $util.emptyArray;

    /**
     * ResponseLookup TotalCount.
     * @member {number} TotalCount
     * @memberof ResponseLookup
     * @instance
     */

    /**
     * Encodes the specified ResponseLookup message. Does not implicitly {@link ResponseLookup.verify|verify} messages.
     * @function encode
     * @memberof ResponseLookup
     * @static
     * @param {ResponseLookup} message ResponseLookup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseLookup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Entries != null && message.Entries.length)
            for (let i = 0; i < message.Entries.length; ++i)
                $root.Contact.encode(message.Entries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.TotalCount != null && Object.hasOwnProperty.call(message, "TotalCount"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.TotalCount);
        return writer;
    };

    /**
     * Decodes a ResponseLookup message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseLookup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseLookup} ResponseLookup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseLookup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseLookup();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Entries && message.Entries.length))
                        message.Entries = [];
                    message.Entries.push($root.Contact.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    message.TotalCount = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseLookup message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseLookup
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseLookup.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 202, LookupResult : this});
    };

    /**
     * ResponseLookup typeId
     * @member {number} typeId
     * @memberof ResponseLookup
     * @instance
     */
    ResponseLookup.prototype.typeId = 202;
    /**
     * ResponseLookup typeName
     * @member {string} typeName
     * @memberof ResponseLookup
     * @instance
     */
    ResponseLookup.prototype.typeName = "LookupResult";

    return ResponseLookup;
})();

export const RequestGetFile = $root.RequestGetFile = (() => {

    /**
     * Properties of a RequestGetFile.
     * @exports IRequestGetFile
     * @property {UsersFolder} Folder RequestGetFile Folder
     * @property {string} [FileName] RequestGetFile FileName
     * @property {string} [Extension] RequestGetFile Extension
     */

    /**
     * Constructs a new RequestGetFile.
     * @exports RequestGetFile
     * @classdesc Represents a RequestGetFile.
     * @constructor
     * @param {Partial<RequestGetFile>} [properties] Properties to set
     */
    function RequestGetFile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetFile Folder.
     * @member {UsersFolder} Folder
     * @memberof RequestGetFile
     * @instance
     */

    /**
     * RequestGetFile FileName.
     * @member {string} FileName
     * @memberof RequestGetFile
     * @instance
     */

    /**
     * RequestGetFile Extension.
     * @member {string} Extension
     * @memberof RequestGetFile
     * @instance
     */

    /**
     * Encodes the specified RequestGetFile message. Does not implicitly {@link RequestGetFile.verify|verify} messages.
     * @function encode
     * @memberof RequestGetFile
     * @static
     * @param {RequestGetFile} message RequestGetFile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetFile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Folder);
        if (message.FileName != null && Object.hasOwnProperty.call(message, "FileName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.FileName);
        if (message.Extension != null && Object.hasOwnProperty.call(message, "Extension"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Extension);
        return writer;
    };

    /**
     * Decodes a RequestGetFile message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetFile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetFile} RequestGetFile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetFile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetFile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Folder = reader.int32();
                    break;
                }
            case 2: {
                    message.FileName = reader.string();
                    break;
                }
            case 3: {
                    message.Extension = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetFile message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetFile
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetFile.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 105, GetFile : this});
    };

    /**
     * RequestGetFile typeId
     * @member {number} typeId
     * @memberof RequestGetFile
     * @instance
     */
    RequestGetFile.prototype.typeId = 105;
    /**
     * RequestGetFile typeName
     * @member {string} typeName
     * @memberof RequestGetFile
     * @instance
     */
    RequestGetFile.prototype.typeName = "GetFile";

    return RequestGetFile;
})();

export const RequestPutFile = $root.RequestPutFile = (() => {

    /**
     * Properties of a RequestPutFile.
     * @exports IRequestPutFile
     * @property {UsersFolder} Folder RequestPutFile Folder
     * @property {string} [FileName] RequestPutFile FileName
     * @property {Uint8Array} [Content] RequestPutFile Content
     * @property {string} [Extension] RequestPutFile Extension
     */

    /**
     * Constructs a new RequestPutFile.
     * @exports RequestPutFile
     * @classdesc Represents a RequestPutFile.
     * @constructor
     * @param {Partial<RequestPutFile>} [properties] Properties to set
     */
    function RequestPutFile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestPutFile Folder.
     * @member {UsersFolder} Folder
     * @memberof RequestPutFile
     * @instance
     */

    /**
     * RequestPutFile FileName.
     * @member {string} FileName
     * @memberof RequestPutFile
     * @instance
     */

    /**
     * RequestPutFile Content.
     * @member {Uint8Array} Content
     * @memberof RequestPutFile
     * @instance
     */

    /**
     * RequestPutFile Extension.
     * @member {string} Extension
     * @memberof RequestPutFile
     * @instance
     */

    /**
     * Encodes the specified RequestPutFile message. Does not implicitly {@link RequestPutFile.verify|verify} messages.
     * @function encode
     * @memberof RequestPutFile
     * @static
     * @param {RequestPutFile} message RequestPutFile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestPutFile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Folder);
        if (message.FileName != null && Object.hasOwnProperty.call(message, "FileName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.FileName);
        if (message.Content != null && Object.hasOwnProperty.call(message, "Content"))
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.Content);
        if (message.Extension != null && Object.hasOwnProperty.call(message, "Extension"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Extension);
        return writer;
    };

    /**
     * Decodes a RequestPutFile message from the specified reader or buffer.
     * @function decode
     * @memberof RequestPutFile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestPutFile} RequestPutFile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestPutFile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestPutFile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Folder = reader.int32();
                    break;
                }
            case 2: {
                    message.FileName = reader.string();
                    break;
                }
            case 3: {
                    message.Content = reader.bytes();
                    break;
                }
            case 4: {
                    message.Extension = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestPutFile message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestPutFile
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestPutFile.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 113, PutFile : this});
    };

    /**
     * RequestPutFile typeId
     * @member {number} typeId
     * @memberof RequestPutFile
     * @instance
     */
    RequestPutFile.prototype.typeId = 113;
    /**
     * RequestPutFile typeName
     * @member {string} typeName
     * @memberof RequestPutFile
     * @instance
     */
    RequestPutFile.prototype.typeName = "PutFile";

    return RequestPutFile;
})();

export const ResponseFile = $root.ResponseFile = (() => {

    /**
     * Properties of a ResponseFile.
     * @exports IResponseFile
     * @property {Uint8Array} [Content] ResponseFile Content
     */

    /**
     * Constructs a new ResponseFile.
     * @exports ResponseFile
     * @classdesc Represents a ResponseFile.
     * @constructor
     * @param {Partial<ResponseFile>} [properties] Properties to set
     */
    function ResponseFile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseFile Content.
     * @member {Uint8Array} Content
     * @memberof ResponseFile
     * @instance
     */

    /**
     * Encodes the specified ResponseFile message. Does not implicitly {@link ResponseFile.verify|verify} messages.
     * @function encode
     * @memberof ResponseFile
     * @static
     * @param {ResponseFile} message ResponseFile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseFile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Content != null && Object.hasOwnProperty.call(message, "Content"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.Content);
        return writer;
    };

    /**
     * Decodes a ResponseFile message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseFile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseFile} ResponseFile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseFile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseFile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Content = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseFile message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseFile
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseFile.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 203, FileResult : this});
    };

    /**
     * ResponseFile typeId
     * @member {number} typeId
     * @memberof ResponseFile
     * @instance
     */
    ResponseFile.prototype.typeId = 203;
    /**
     * ResponseFile typeName
     * @member {string} typeName
     * @memberof ResponseFile
     * @instance
     */
    ResponseFile.prototype.typeName = "FileResult";

    return ResponseFile;
})();

export const QueueCallRecord = $root.QueueCallRecord = (() => {

    /**
     * Properties of a QueueCallRecord.
     * @exports IQueueCallRecord
     * @property {number} Id QueueCallRecord Id
     * @property {string} [QueueNumber] QueueCallRecord QueueNumber
     * @property {DateTime} [TimeStart] QueueCallRecord TimeStart
     * @property {string} [Waiting] QueueCallRecord Waiting
     * @property {string} [Polling] QueueCallRecord Polling
     * @property {string} [FromUserPart] QueueCallRecord FromUserPart
     * @property {string} [FromDisplayName] QueueCallRecord FromDisplayName
     * @property {QueueCallDealStatus} [DealStatus] QueueCallRecord DealStatus
     * @property {boolean} [IsManager] QueueCallRecord IsManager
     * @property {Contact} [Queue] QueueCallRecord Queue
     * @property {Contact} [User] QueueCallRecord User
     */

    /**
     * Constructs a new QueueCallRecord.
     * @exports QueueCallRecord
     * @classdesc Represents a QueueCallRecord.
     * @constructor
     * @param {Partial<QueueCallRecord>} [properties] Properties to set
     */
    function QueueCallRecord(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueueCallRecord Id.
     * @member {number} Id
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord QueueNumber.
     * @member {string} QueueNumber
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord TimeStart.
     * @member {DateTime} TimeStart
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord Waiting.
     * @member {string} Waiting
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord Polling.
     * @member {string} Polling
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord FromUserPart.
     * @member {string} FromUserPart
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord FromDisplayName.
     * @member {string} FromDisplayName
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord DealStatus.
     * @member {QueueCallDealStatus} DealStatus
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord IsManager.
     * @member {boolean} IsManager
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord Queue.
     * @member {Contact} Queue
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * QueueCallRecord User.
     * @member {Contact} User
     * @memberof QueueCallRecord
     * @instance
     */

    /**
     * Encodes the specified QueueCallRecord message. Does not implicitly {@link QueueCallRecord.verify|verify} messages.
     * @function encode
     * @memberof QueueCallRecord
     * @static
     * @param {QueueCallRecord} message QueueCallRecord message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueueCallRecord.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.QueueNumber != null && Object.hasOwnProperty.call(message, "QueueNumber"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.QueueNumber);
        if (message.TimeStart != null && Object.hasOwnProperty.call(message, "TimeStart"))
            $root.DateTime.encode(message.TimeStart, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.Waiting != null && Object.hasOwnProperty.call(message, "Waiting"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Waiting);
        if (message.Polling != null && Object.hasOwnProperty.call(message, "Polling"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Polling);
        if (message.FromUserPart != null && Object.hasOwnProperty.call(message, "FromUserPart"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.FromUserPart);
        if (message.FromDisplayName != null && Object.hasOwnProperty.call(message, "FromDisplayName"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.FromDisplayName);
        if (message.DealStatus != null && Object.hasOwnProperty.call(message, "DealStatus"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.DealStatus);
        if (message.IsManager != null && Object.hasOwnProperty.call(message, "IsManager"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.IsManager);
        if (message.Queue != null && Object.hasOwnProperty.call(message, "Queue"))
            $root.Contact.encode(message.Queue, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.User != null && Object.hasOwnProperty.call(message, "User"))
            $root.Contact.encode(message.User, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a QueueCallRecord message from the specified reader or buffer.
     * @function decode
     * @memberof QueueCallRecord
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {QueueCallRecord} QueueCallRecord
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueueCallRecord.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.QueueCallRecord();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.QueueNumber = reader.string();
                    break;
                }
            case 3: {
                    message.TimeStart = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.Waiting = reader.string();
                    break;
                }
            case 5: {
                    message.Polling = reader.string();
                    break;
                }
            case 6: {
                    message.FromUserPart = reader.string();
                    break;
                }
            case 7: {
                    message.FromDisplayName = reader.string();
                    break;
                }
            case 8: {
                    message.DealStatus = reader.int32();
                    break;
                }
            case 9: {
                    message.IsManager = reader.bool();
                    break;
                }
            case 10: {
                    message.Queue = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.User = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return QueueCallRecord;
})();

export const RequestQueueCalls = $root.RequestQueueCalls = (() => {

    /**
     * Properties of a RequestQueueCalls.
     * @exports IRequestQueueCalls
     * @property {DateTime} [TimeStart] RequestQueueCalls TimeStart
     * @property {number} [RecordLimit] RequestQueueCalls RecordLimit
     * @property {number} [RecordOffset] RequestQueueCalls RecordOffset
     * @property {string} [Search] RequestQueueCalls Search
     */

    /**
     * Constructs a new RequestQueueCalls.
     * @exports RequestQueueCalls
     * @classdesc Represents a RequestQueueCalls.
     * @constructor
     * @param {Partial<RequestQueueCalls>} [properties] Properties to set
     */
    function RequestQueueCalls(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestQueueCalls TimeStart.
     * @member {DateTime} TimeStart
     * @memberof RequestQueueCalls
     * @instance
     */

    /**
     * RequestQueueCalls RecordLimit.
     * @member {number} RecordLimit
     * @memberof RequestQueueCalls
     * @instance
     */

    /**
     * RequestQueueCalls RecordOffset.
     * @member {number} RecordOffset
     * @memberof RequestQueueCalls
     * @instance
     */

    /**
     * RequestQueueCalls Search.
     * @member {string} Search
     * @memberof RequestQueueCalls
     * @instance
     */

    /**
     * Encodes the specified RequestQueueCalls message. Does not implicitly {@link RequestQueueCalls.verify|verify} messages.
     * @function encode
     * @memberof RequestQueueCalls
     * @static
     * @param {RequestQueueCalls} message RequestQueueCalls message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestQueueCalls.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.TimeStart != null && Object.hasOwnProperty.call(message, "TimeStart"))
            $root.DateTime.encode(message.TimeStart, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.RecordLimit != null && Object.hasOwnProperty.call(message, "RecordLimit"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.RecordLimit);
        if (message.RecordOffset != null && Object.hasOwnProperty.call(message, "RecordOffset"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.RecordOffset);
        if (message.Search != null && Object.hasOwnProperty.call(message, "Search"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Search);
        return writer;
    };

    /**
     * Decodes a RequestQueueCalls message from the specified reader or buffer.
     * @function decode
     * @memberof RequestQueueCalls
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestQueueCalls} RequestQueueCalls
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestQueueCalls.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestQueueCalls();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.TimeStart = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.RecordLimit = reader.int32();
                    break;
                }
            case 3: {
                    message.RecordOffset = reader.int32();
                    break;
                }
            case 4: {
                    message.Search = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestQueueCalls message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestQueueCalls
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestQueueCalls.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 145, GetQueueCalls : this});
    };

    /**
     * RequestQueueCalls typeId
     * @member {number} typeId
     * @memberof RequestQueueCalls
     * @instance
     */
    RequestQueueCalls.prototype.typeId = 145;
    /**
     * RequestQueueCalls typeName
     * @member {string} typeName
     * @memberof RequestQueueCalls
     * @instance
     */
    RequestQueueCalls.prototype.typeName = "GetQueueCalls";

    return RequestQueueCalls;
})();

export const RequestQueueCallsCount = $root.RequestQueueCallsCount = (() => {

    /**
     * Properties of a RequestQueueCallsCount.
     * @exports IRequestQueueCallsCount
     * @property {DateTime} [TimeStart] RequestQueueCallsCount TimeStart
     * @property {string} [Search] RequestQueueCallsCount Search
     */

    /**
     * Constructs a new RequestQueueCallsCount.
     * @exports RequestQueueCallsCount
     * @classdesc Represents a RequestQueueCallsCount.
     * @constructor
     * @param {Partial<RequestQueueCallsCount>} [properties] Properties to set
     */
    function RequestQueueCallsCount(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestQueueCallsCount TimeStart.
     * @member {DateTime} TimeStart
     * @memberof RequestQueueCallsCount
     * @instance
     */

    /**
     * RequestQueueCallsCount Search.
     * @member {string} Search
     * @memberof RequestQueueCallsCount
     * @instance
     */

    /**
     * Encodes the specified RequestQueueCallsCount message. Does not implicitly {@link RequestQueueCallsCount.verify|verify} messages.
     * @function encode
     * @memberof RequestQueueCallsCount
     * @static
     * @param {RequestQueueCallsCount} message RequestQueueCallsCount message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestQueueCallsCount.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.TimeStart != null && Object.hasOwnProperty.call(message, "TimeStart"))
            $root.DateTime.encode(message.TimeStart, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.Search != null && Object.hasOwnProperty.call(message, "Search"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Search);
        return writer;
    };

    /**
     * Decodes a RequestQueueCallsCount message from the specified reader or buffer.
     * @function decode
     * @memberof RequestQueueCallsCount
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestQueueCallsCount} RequestQueueCallsCount
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestQueueCallsCount.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestQueueCallsCount();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.TimeStart = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.Search = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestQueueCallsCount message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestQueueCallsCount
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestQueueCallsCount.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 146, GetQueueCallsCount : this});
    };

    /**
     * RequestQueueCallsCount typeId
     * @member {number} typeId
     * @memberof RequestQueueCallsCount
     * @instance
     */
    RequestQueueCallsCount.prototype.typeId = 146;
    /**
     * RequestQueueCallsCount typeName
     * @member {string} typeName
     * @memberof RequestQueueCallsCount
     * @instance
     */
    RequestQueueCallsCount.prototype.typeName = "GetQueueCallsCount";

    return RequestQueueCallsCount;
})();

export const RequestSetQueueCallData = $root.RequestSetQueueCallData = (() => {

    /**
     * Properties of a RequestSetQueueCallData.
     * @exports IRequestSetQueueCallData
     * @property {number} Id RequestSetQueueCallData Id
     * @property {QueueCallDealStatus} [DealStatus] RequestSetQueueCallData DealStatus
     * @property {boolean} [IsVisible] RequestSetQueueCallData IsVisible
     * @property {string} [QueueNumber] RequestSetQueueCallData QueueNumber
     */

    /**
     * Constructs a new RequestSetQueueCallData.
     * @exports RequestSetQueueCallData
     * @classdesc Represents a RequestSetQueueCallData.
     * @constructor
     * @param {Partial<RequestSetQueueCallData>} [properties] Properties to set
     */
    function RequestSetQueueCallData(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetQueueCallData Id.
     * @member {number} Id
     * @memberof RequestSetQueueCallData
     * @instance
     */

    /**
     * RequestSetQueueCallData DealStatus.
     * @member {QueueCallDealStatus} DealStatus
     * @memberof RequestSetQueueCallData
     * @instance
     */

    /**
     * RequestSetQueueCallData IsVisible.
     * @member {boolean} IsVisible
     * @memberof RequestSetQueueCallData
     * @instance
     */

    /**
     * RequestSetQueueCallData QueueNumber.
     * @member {string} QueueNumber
     * @memberof RequestSetQueueCallData
     * @instance
     */

    /**
     * Encodes the specified RequestSetQueueCallData message. Does not implicitly {@link RequestSetQueueCallData.verify|verify} messages.
     * @function encode
     * @memberof RequestSetQueueCallData
     * @static
     * @param {RequestSetQueueCallData} message RequestSetQueueCallData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetQueueCallData.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.DealStatus != null && Object.hasOwnProperty.call(message, "DealStatus"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.DealStatus);
        if (message.IsVisible != null && Object.hasOwnProperty.call(message, "IsVisible"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.IsVisible);
        if (message.QueueNumber != null && Object.hasOwnProperty.call(message, "QueueNumber"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.QueueNumber);
        return writer;
    };

    /**
     * Decodes a RequestSetQueueCallData message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetQueueCallData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetQueueCallData} RequestSetQueueCallData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetQueueCallData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetQueueCallData();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.DealStatus = reader.int32();
                    break;
                }
            case 3: {
                    message.IsVisible = reader.bool();
                    break;
                }
            case 4: {
                    message.QueueNumber = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetQueueCallData message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetQueueCallData
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetQueueCallData.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 147, SetQueueCallData : this});
    };

    /**
     * RequestSetQueueCallData typeId
     * @member {number} typeId
     * @memberof RequestSetQueueCallData
     * @instance
     */
    RequestSetQueueCallData.prototype.typeId = 147;
    /**
     * RequestSetQueueCallData typeName
     * @member {string} typeName
     * @memberof RequestSetQueueCallData
     * @instance
     */
    RequestSetQueueCallData.prototype.typeName = "SetQueueCallData";

    return RequestSetQueueCallData;
})();

export const RequestSetQueueCallDataBulk = $root.RequestSetQueueCallDataBulk = (() => {

    /**
     * Properties of a RequestSetQueueCallDataBulk.
     * @exports IRequestSetQueueCallDataBulk
     * @property {Array.<number>} [IdList] RequestSetQueueCallDataBulk IdList
     * @property {Array.<string>} [QueueNumbers] RequestSetQueueCallDataBulk QueueNumbers
     * @property {QueueCallDealStatus} [DealStatus] RequestSetQueueCallDataBulk DealStatus
     * @property {boolean} [IsVisible] RequestSetQueueCallDataBulk IsVisible
     */

    /**
     * Constructs a new RequestSetQueueCallDataBulk.
     * @exports RequestSetQueueCallDataBulk
     * @classdesc Represents a RequestSetQueueCallDataBulk.
     * @constructor
     * @param {Partial<RequestSetQueueCallDataBulk>} [properties] Properties to set
     */
    function RequestSetQueueCallDataBulk(properties) {
        this.IdList = [];
        this.QueueNumbers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetQueueCallDataBulk IdList.
     * @member {Array.<number>} IdList
     * @memberof RequestSetQueueCallDataBulk
     * @instance
     */
    RequestSetQueueCallDataBulk.prototype.IdList = $util.emptyArray;

    /**
     * RequestSetQueueCallDataBulk QueueNumbers.
     * @member {Array.<string>} QueueNumbers
     * @memberof RequestSetQueueCallDataBulk
     * @instance
     */
    RequestSetQueueCallDataBulk.prototype.QueueNumbers = $util.emptyArray;

    /**
     * RequestSetQueueCallDataBulk DealStatus.
     * @member {QueueCallDealStatus} DealStatus
     * @memberof RequestSetQueueCallDataBulk
     * @instance
     */

    /**
     * RequestSetQueueCallDataBulk IsVisible.
     * @member {boolean} IsVisible
     * @memberof RequestSetQueueCallDataBulk
     * @instance
     */

    /**
     * Encodes the specified RequestSetQueueCallDataBulk message. Does not implicitly {@link RequestSetQueueCallDataBulk.verify|verify} messages.
     * @function encode
     * @memberof RequestSetQueueCallDataBulk
     * @static
     * @param {RequestSetQueueCallDataBulk} message RequestSetQueueCallDataBulk message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetQueueCallDataBulk.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdList != null && message.IdList.length)
            for (let i = 0; i < message.IdList.length; ++i)
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdList[i]);
        if (message.QueueNumbers != null && message.QueueNumbers.length)
            for (let i = 0; i < message.QueueNumbers.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.QueueNumbers[i]);
        if (message.DealStatus != null && Object.hasOwnProperty.call(message, "DealStatus"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.DealStatus);
        if (message.IsVisible != null && Object.hasOwnProperty.call(message, "IsVisible"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.IsVisible);
        return writer;
    };

    /**
     * Decodes a RequestSetQueueCallDataBulk message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetQueueCallDataBulk
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetQueueCallDataBulk} RequestSetQueueCallDataBulk
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetQueueCallDataBulk.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetQueueCallDataBulk();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.IdList && message.IdList.length))
                        message.IdList = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.IdList.push(reader.int32());
                    } else
                        message.IdList.push(reader.int32());
                    break;
                }
            case 2: {
                    if (!(message.QueueNumbers && message.QueueNumbers.length))
                        message.QueueNumbers = [];
                    message.QueueNumbers.push(reader.string());
                    break;
                }
            case 3: {
                    message.DealStatus = reader.int32();
                    break;
                }
            case 4: {
                    message.IsVisible = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetQueueCallDataBulk message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetQueueCallDataBulk
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetQueueCallDataBulk.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 149, SetQueueCallDataBulk : this});
    };

    /**
     * RequestSetQueueCallDataBulk typeId
     * @member {number} typeId
     * @memberof RequestSetQueueCallDataBulk
     * @instance
     */
    RequestSetQueueCallDataBulk.prototype.typeId = 149;
    /**
     * RequestSetQueueCallDataBulk typeName
     * @member {string} typeName
     * @memberof RequestSetQueueCallDataBulk
     * @instance
     */
    RequestSetQueueCallDataBulk.prototype.typeName = "SetQueueCallDataBulk";

    return RequestSetQueueCallDataBulk;
})();

export const ResponseQueueCalls = $root.ResponseQueueCalls = (() => {

    /**
     * Properties of a ResponseQueueCalls.
     * @exports IResponseQueueCalls
     * @property {Array.<QueueCallRecord>} [Records] ResponseQueueCalls Records
     */

    /**
     * Constructs a new ResponseQueueCalls.
     * @exports ResponseQueueCalls
     * @classdesc Represents a ResponseQueueCalls.
     * @constructor
     * @param {Partial<ResponseQueueCalls>} [properties] Properties to set
     */
    function ResponseQueueCalls(properties) {
        this.Records = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseQueueCalls Records.
     * @member {Array.<QueueCallRecord>} Records
     * @memberof ResponseQueueCalls
     * @instance
     */
    ResponseQueueCalls.prototype.Records = $util.emptyArray;

    /**
     * Encodes the specified ResponseQueueCalls message. Does not implicitly {@link ResponseQueueCalls.verify|verify} messages.
     * @function encode
     * @memberof ResponseQueueCalls
     * @static
     * @param {ResponseQueueCalls} message ResponseQueueCalls message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseQueueCalls.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Records != null && message.Records.length)
            for (let i = 0; i < message.Records.length; ++i)
                $root.QueueCallRecord.encode(message.Records[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseQueueCalls message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseQueueCalls
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseQueueCalls} ResponseQueueCalls
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseQueueCalls.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseQueueCalls();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Records && message.Records.length))
                        message.Records = [];
                    message.Records.push($root.QueueCallRecord.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseQueueCalls message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseQueueCalls
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseQueueCalls.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 223, QueueCalls : this});
    };

    /**
     * ResponseQueueCalls typeId
     * @member {number} typeId
     * @memberof ResponseQueueCalls
     * @instance
     */
    ResponseQueueCalls.prototype.typeId = 223;
    /**
     * ResponseQueueCalls typeName
     * @member {string} typeName
     * @memberof ResponseQueueCalls
     * @instance
     */
    ResponseQueueCalls.prototype.typeName = "QueueCalls";

    return ResponseQueueCalls;
})();

/**
 * CallHistoryViaRole enum.
 * @exports CallHistoryViaRole
 * @enum {string}
 * @property {number} ForwardedBy=1 ForwardedBy value
 * @property {number} PickedUpFrom=2 PickedUpFrom value
 * @property {number} TransferredBy=3 TransferredBy value
 * @property {number} JoinedBy=4 JoinedBy value
 */
export const CallHistoryViaRole = $root.CallHistoryViaRole = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "ForwardedBy"] = 1;
    values[valuesById[2] = "PickedUpFrom"] = 2;
    values[valuesById[3] = "TransferredBy"] = 3;
    values[valuesById[4] = "JoinedBy"] = 4;
    return values;
})();

export const CallHistoryParty = $root.CallHistoryParty = (() => {

    /**
     * Properties of a CallHistoryParty.
     * @exports ICallHistoryParty
     * @property {number} Id CallHistoryParty Id
     * @property {DateTime} [StartTime] CallHistoryParty StartTime
     * @property {DateTime} [ConnectedAt] CallHistoryParty ConnectedAt
     * @property {DateTime} [EndedAt] CallHistoryParty EndedAt
     * @property {string} [PartyDN] CallHistoryParty PartyDN
     * @property {DnType} [PartyDNType] CallHistoryParty PartyDNType
     * @property {string} [PartyDNName] CallHistoryParty PartyDNName
     * @property {string} [PartyCallerId] CallHistoryParty PartyCallerId
     * @property {CallHistoryViaRole} [ViaRole] CallHistoryParty ViaRole
     * @property {string} [ViaDN] CallHistoryParty ViaDN
     * @property {DnType} [ViaDNType] CallHistoryParty ViaDNType
     * @property {string} [ViaDNName] CallHistoryParty ViaDNName
     * @property {string} [ViaCallerId] CallHistoryParty ViaCallerId
     * @property {CallHistoryType} [CallType] CallHistoryParty CallType
     * @property {Contact} [PartyContact] CallHistoryParty PartyContact
     * @property {Contact} [ViaContact] CallHistoryParty ViaContact
     */

    /**
     * Constructs a new CallHistoryParty.
     * @exports CallHistoryParty
     * @classdesc Represents a CallHistoryParty.
     * @constructor
     * @param {Partial<CallHistoryParty>} [properties] Properties to set
     */
    function CallHistoryParty(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CallHistoryParty Id.
     * @member {number} Id
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty StartTime.
     * @member {DateTime} StartTime
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty ConnectedAt.
     * @member {DateTime} ConnectedAt
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty EndedAt.
     * @member {DateTime} EndedAt
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty PartyDN.
     * @member {string} PartyDN
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty PartyDNType.
     * @member {DnType} PartyDNType
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty PartyDNName.
     * @member {string} PartyDNName
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty PartyCallerId.
     * @member {string} PartyCallerId
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty ViaRole.
     * @member {CallHistoryViaRole} ViaRole
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty ViaDN.
     * @member {string} ViaDN
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty ViaDNType.
     * @member {DnType} ViaDNType
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty ViaDNName.
     * @member {string} ViaDNName
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty ViaCallerId.
     * @member {string} ViaCallerId
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty CallType.
     * @member {CallHistoryType} CallType
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty PartyContact.
     * @member {Contact} PartyContact
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * CallHistoryParty ViaContact.
     * @member {Contact} ViaContact
     * @memberof CallHistoryParty
     * @instance
     */

    /**
     * Encodes the specified CallHistoryParty message. Does not implicitly {@link CallHistoryParty.verify|verify} messages.
     * @function encode
     * @memberof CallHistoryParty
     * @static
     * @param {CallHistoryParty} message CallHistoryParty message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CallHistoryParty.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.StartTime != null && Object.hasOwnProperty.call(message, "StartTime"))
            $root.DateTime.encode(message.StartTime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.ConnectedAt != null && Object.hasOwnProperty.call(message, "ConnectedAt"))
            $root.DateTime.encode(message.ConnectedAt, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.EndedAt != null && Object.hasOwnProperty.call(message, "EndedAt"))
            $root.DateTime.encode(message.EndedAt, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.PartyDN != null && Object.hasOwnProperty.call(message, "PartyDN"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.PartyDN);
        if (message.PartyDNType != null && Object.hasOwnProperty.call(message, "PartyDNType"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.PartyDNType);
        if (message.PartyDNName != null && Object.hasOwnProperty.call(message, "PartyDNName"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.PartyDNName);
        if (message.PartyCallerId != null && Object.hasOwnProperty.call(message, "PartyCallerId"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.PartyCallerId);
        if (message.ViaRole != null && Object.hasOwnProperty.call(message, "ViaRole"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.ViaRole);
        if (message.ViaDN != null && Object.hasOwnProperty.call(message, "ViaDN"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.ViaDN);
        if (message.ViaDNType != null && Object.hasOwnProperty.call(message, "ViaDNType"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.ViaDNType);
        if (message.ViaDNName != null && Object.hasOwnProperty.call(message, "ViaDNName"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.ViaDNName);
        if (message.ViaCallerId != null && Object.hasOwnProperty.call(message, "ViaCallerId"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.ViaCallerId);
        if (message.CallType != null && Object.hasOwnProperty.call(message, "CallType"))
            writer.uint32(/* id 14, wireType 0 =*/112).int32(message.CallType);
        if (message.PartyContact != null && Object.hasOwnProperty.call(message, "PartyContact"))
            $root.Contact.encode(message.PartyContact, writer.uint32(/* id 15, wireType 2 =*/122).fork()).ldelim();
        if (message.ViaContact != null && Object.hasOwnProperty.call(message, "ViaContact"))
            $root.Contact.encode(message.ViaContact, writer.uint32(/* id 16, wireType 2 =*/130).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a CallHistoryParty message from the specified reader or buffer.
     * @function decode
     * @memberof CallHistoryParty
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CallHistoryParty} CallHistoryParty
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CallHistoryParty.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CallHistoryParty();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.StartTime = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.ConnectedAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.EndedAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.PartyDN = reader.string();
                    break;
                }
            case 6: {
                    message.PartyDNType = reader.int32();
                    break;
                }
            case 7: {
                    message.PartyDNName = reader.string();
                    break;
                }
            case 8: {
                    message.PartyCallerId = reader.string();
                    break;
                }
            case 9: {
                    message.ViaRole = reader.int32();
                    break;
                }
            case 10: {
                    message.ViaDN = reader.string();
                    break;
                }
            case 11: {
                    message.ViaDNType = reader.int32();
                    break;
                }
            case 12: {
                    message.ViaDNName = reader.string();
                    break;
                }
            case 13: {
                    message.ViaCallerId = reader.string();
                    break;
                }
            case 14: {
                    message.CallType = reader.int32();
                    break;
                }
            case 15: {
                    message.PartyContact = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            case 16: {
                    message.ViaContact = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return CallHistoryParty;
})();

export const CallHistoryConnections = $root.CallHistoryConnections = (() => {

    /**
     * Properties of a CallHistoryConnections.
     * @exports ICallHistoryConnections
     * @property {Array.<CallHistoryParty>} [parties] CallHistoryConnections parties
     */

    /**
     * Constructs a new CallHistoryConnections.
     * @exports CallHistoryConnections
     * @classdesc Represents a CallHistoryConnections.
     * @constructor
     * @param {Partial<CallHistoryConnections>} [properties] Properties to set
     */
    function CallHistoryConnections(properties) {
        this.parties = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CallHistoryConnections parties.
     * @member {Array.<CallHistoryParty>} parties
     * @memberof CallHistoryConnections
     * @instance
     */
    CallHistoryConnections.prototype.parties = $util.emptyArray;

    /**
     * Encodes the specified CallHistoryConnections message. Does not implicitly {@link CallHistoryConnections.verify|verify} messages.
     * @function encode
     * @memberof CallHistoryConnections
     * @static
     * @param {CallHistoryConnections} message CallHistoryConnections message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CallHistoryConnections.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.parties != null && message.parties.length)
            for (let i = 0; i < message.parties.length; ++i)
                $root.CallHistoryParty.encode(message.parties[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a CallHistoryConnections message from the specified reader or buffer.
     * @function decode
     * @memberof CallHistoryConnections
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CallHistoryConnections} CallHistoryConnections
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CallHistoryConnections.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CallHistoryConnections();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.parties && message.parties.length))
                        message.parties = [];
                    message.parties.push($root.CallHistoryParty.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return CallHistoryConnections;
})();

/**
 * Quality enum.
 * @exports Quality
 * @enum {string}
 * @property {number} UnknownQuality=0 UnknownQuality value
 * @property {number} AllGood=1 AllGood value
 * @property {number} NetworkToYou=2 NetworkToYou value
 * @property {number} NetworkFromYou=3 NetworkFromYou value
 * @property {number} NetworkYouAndPbx=4 NetworkYouAndPbx value
 * @property {number} NetworkToParty=5 NetworkToParty value
 * @property {number} NetworkFromParty=6 NetworkFromParty value
 * @property {number} NetworkPartyAndPbx=7 NetworkPartyAndPbx value
 * @property {number} ToPBx=8 ToPBx value
 * @property {number} FromPbx=9 FromPbx value
 * @property {number} FromToPbx=10 FromToPbx value
 */
export const Quality = $root.Quality = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "UnknownQuality"] = 0;
    values[valuesById[1] = "AllGood"] = 1;
    values[valuesById[2] = "NetworkToYou"] = 2;
    values[valuesById[3] = "NetworkFromYou"] = 3;
    values[valuesById[4] = "NetworkYouAndPbx"] = 4;
    values[valuesById[5] = "NetworkToParty"] = 5;
    values[valuesById[6] = "NetworkFromParty"] = 6;
    values[valuesById[7] = "NetworkPartyAndPbx"] = 7;
    values[valuesById[8] = "ToPBx"] = 8;
    values[valuesById[9] = "FromPbx"] = 9;
    values[valuesById[10] = "FromToPbx"] = 10;
    return values;
})();

/**
 * MSEndpointType enum.
 * @exports MSEndpointType
 * @enum {string}
 * @property {number} Local=1 Local value
 * @property {number} External=2 External value
 * @property {number} Stun=3 Stun value
 * @property {number} Tunnel=4 Tunnel value
 */
export const MSEndpointType = $root.MSEndpointType = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "Local"] = 1;
    values[valuesById[2] = "External"] = 2;
    values[valuesById[3] = "Stun"] = 3;
    values[valuesById[4] = "Tunnel"] = 4;
    return values;
})();

export const RequestCallReport = $root.RequestCallReport = (() => {

    /**
     * Properties of a RequestCallReport.
     * @exports IRequestCallReport
     * @property {number} ActiveConnection RequestCallReport ActiveConnection
     */

    /**
     * Constructs a new RequestCallReport.
     * @exports RequestCallReport
     * @classdesc Represents a RequestCallReport.
     * @constructor
     * @param {Partial<RequestCallReport>} [properties] Properties to set
     */
    function RequestCallReport(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestCallReport ActiveConnection.
     * @member {number} ActiveConnection
     * @memberof RequestCallReport
     * @instance
     */

    /**
     * Encodes the specified RequestCallReport message. Does not implicitly {@link RequestCallReport.verify|verify} messages.
     * @function encode
     * @memberof RequestCallReport
     * @static
     * @param {RequestCallReport} message RequestCallReport message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestCallReport.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ActiveConnection);
        return writer;
    };

    /**
     * Decodes a RequestCallReport message from the specified reader or buffer.
     * @function decode
     * @memberof RequestCallReport
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestCallReport} RequestCallReport
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestCallReport.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestCallReport();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ActiveConnection = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestCallReport message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestCallReport
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestCallReport.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 555, CallReport : this});
    };

    /**
     * RequestCallReport typeId
     * @member {number} typeId
     * @memberof RequestCallReport
     * @instance
     */
    RequestCallReport.prototype.typeId = 555;
    /**
     * RequestCallReport typeName
     * @member {string} typeName
     * @memberof RequestCallReport
     * @instance
     */
    RequestCallReport.prototype.typeName = "CallReport";

    return RequestCallReport;
})();

export const RequestMonitor = $root.RequestMonitor = (() => {

    /**
     * Properties of a RequestMonitor.
     * @exports IRequestMonitor
     * @property {string} DN RequestMonitor DN
     * @property {number} Days RequestMonitor Days
     */

    /**
     * Constructs a new RequestMonitor.
     * @exports RequestMonitor
     * @classdesc Represents a RequestMonitor.
     * @constructor
     * @param {Partial<RequestMonitor>} [properties] Properties to set
     */
    function RequestMonitor(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestMonitor DN.
     * @member {string} DN
     * @memberof RequestMonitor
     * @instance
     */

    /**
     * RequestMonitor Days.
     * @member {number} Days
     * @memberof RequestMonitor
     * @instance
     */

    /**
     * Encodes the specified RequestMonitor message. Does not implicitly {@link RequestMonitor.verify|verify} messages.
     * @function encode
     * @memberof RequestMonitor
     * @static
     * @param {RequestMonitor} message RequestMonitor message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestMonitor.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.DN);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Days);
        return writer;
    };

    /**
     * Decodes a RequestMonitor message from the specified reader or buffer.
     * @function decode
     * @memberof RequestMonitor
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestMonitor} RequestMonitor
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestMonitor.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestMonitor();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.DN = reader.string();
                    break;
                }
            case 2: {
                    message.Days = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestMonitor message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestMonitor
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestMonitor.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 556, Monitor : this});
    };

    /**
     * RequestMonitor typeId
     * @member {number} typeId
     * @memberof RequestMonitor
     * @instance
     */
    RequestMonitor.prototype.typeId = 556;
    /**
     * RequestMonitor typeName
     * @member {string} typeName
     * @memberof RequestMonitor
     * @instance
     */
    RequestMonitor.prototype.typeName = "Monitor";

    return RequestMonitor;
})();

export const QualityReport = $root.QualityReport = (() => {

    /**
     * Properties of a QualityReport.
     * @exports IQualityReport
     * @property {number} CallID QualityReport CallID
     * @property {Quality} Summary QualityReport Summary
     * @property {QualityReport.LegReport} [Party1] QualityReport Party1
     * @property {QualityReport.LegReport} [Party2] QualityReport Party2
     * @property {boolean} Transcoding QualityReport Transcoding
     * @property {number} [MOS] QualityReport MOS
     */

    /**
     * Constructs a new QualityReport.
     * @exports QualityReport
     * @classdesc Represents a QualityReport.
     * @constructor
     * @param {Partial<QualityReport>} [properties] Properties to set
     */
    function QualityReport(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * QualityReport CallID.
     * @member {number} CallID
     * @memberof QualityReport
     * @instance
     */

    /**
     * QualityReport Summary.
     * @member {Quality} Summary
     * @memberof QualityReport
     * @instance
     */

    /**
     * QualityReport Party1.
     * @member {QualityReport.LegReport} Party1
     * @memberof QualityReport
     * @instance
     */

    /**
     * QualityReport Party2.
     * @member {QualityReport.LegReport} Party2
     * @memberof QualityReport
     * @instance
     */

    /**
     * QualityReport Transcoding.
     * @member {boolean} Transcoding
     * @memberof QualityReport
     * @instance
     */

    /**
     * QualityReport MOS.
     * @member {number} MOS
     * @memberof QualityReport
     * @instance
     */

    /**
     * Encodes the specified QualityReport message. Does not implicitly {@link QualityReport.verify|verify} messages.
     * @function encode
     * @memberof QualityReport
     * @static
     * @param {QualityReport} message QualityReport message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QualityReport.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.CallID);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Summary);
        if (message.Party1 != null && Object.hasOwnProperty.call(message, "Party1"))
            $root.QualityReport.LegReport.encode(message.Party1, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.Party2 != null && Object.hasOwnProperty.call(message, "Party2"))
            $root.QualityReport.LegReport.encode(message.Party2, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        writer.uint32(/* id 5, wireType 0 =*/40).bool(message.Transcoding);
        if (message.MOS != null && Object.hasOwnProperty.call(message, "MOS"))
            writer.uint32(/* id 6, wireType 5 =*/53).float(message.MOS);
        return writer;
    };

    /**
     * Decodes a QualityReport message from the specified reader or buffer.
     * @function decode
     * @memberof QualityReport
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {QualityReport} QualityReport
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QualityReport.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.QualityReport();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.CallID = reader.int32();
                    break;
                }
            case 2: {
                    message.Summary = reader.int32();
                    break;
                }
            case 3: {
                    message.Party1 = $root.QualityReport.LegReport.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.Party2 = $root.QualityReport.LegReport.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.Transcoding = reader.bool();
                    break;
                }
            case 6: {
                    message.MOS = reader.float();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a QualityReport message to GenericMessage
     * @function toGenericMessage
     * @memberof QualityReport
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    QualityReport.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 262, CallQualityReport : this});
    };

    /**
     * QualityReport typeId
     * @member {number} typeId
     * @memberof QualityReport
     * @instance
     */
    QualityReport.prototype.typeId = 262;
    /**
     * QualityReport typeName
     * @member {string} typeName
     * @memberof QualityReport
     * @instance
     */
    QualityReport.prototype.typeName = "CallQualityReport";

    QualityReport.LegReport = (function() {

        /**
         * Properties of a LegReport.
         * @memberof QualityReport
         * @property {string} Number LegReport Number
         * @property {string} UserAgent LegReport UserAgent
         * @property {string} [Address] LegReport Address
         * @property {MSEndpointType} [Type] LegReport Type
         * @property {string} Codec LegReport Codec
         * @property {number} [MOSFromPBX] LegReport MOSFromPBX
         * @property {number} [RTT] LegReport RTT
         * @property {number} [RxJitter] LegReport RxJitter
         * @property {number} [RxLost] LegReport RxLost
         * @property {number} [RxPackets] LegReport RxPackets
         * @property {number} [MOSToPBX] LegReport MOSToPBX
         * @property {number} [TxJitter] LegReport TxJitter
         * @property {number} [TxLost] LegReport TxLost
         * @property {number} [TxPackets] LegReport TxPackets
         * @property {number} [TxBursts] LegReport TxBursts
         * @property {number} [TxBurstLen] LegReport TxBurstLen
         * @property {number} [Duration] LegReport Duration
         * @property {string} [TunnelAddress] LegReport TunnelAddress
         */

        /**
         * Constructs a new LegReport.
         * @memberof QualityReport
         * @classdesc Represents a LegReport.
         * @constructor
         * @param {Partial<QualityReport.LegReport>} [properties] Properties to set
         */
        function LegReport(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * LegReport Number.
         * @member {string} Number
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport UserAgent.
         * @member {string} UserAgent
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport Address.
         * @member {string} Address
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport Type.
         * @member {MSEndpointType} Type
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport Codec.
         * @member {string} Codec
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport MOSFromPBX.
         * @member {number} MOSFromPBX
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport RTT.
         * @member {number} RTT
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport RxJitter.
         * @member {number} RxJitter
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport RxLost.
         * @member {number} RxLost
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport RxPackets.
         * @member {number} RxPackets
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport MOSToPBX.
         * @member {number} MOSToPBX
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport TxJitter.
         * @member {number} TxJitter
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport TxLost.
         * @member {number} TxLost
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport TxPackets.
         * @member {number} TxPackets
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport TxBursts.
         * @member {number} TxBursts
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport TxBurstLen.
         * @member {number} TxBurstLen
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport Duration.
         * @member {number} Duration
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * LegReport TunnelAddress.
         * @member {string} TunnelAddress
         * @memberof QualityReport.LegReport
         * @instance
         */

        /**
         * Encodes the specified LegReport message. Does not implicitly {@link QualityReport.LegReport.verify|verify} messages.
         * @function encode
         * @memberof QualityReport.LegReport
         * @static
         * @param {QualityReport.LegReport} message LegReport message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        LegReport.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Number);
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.UserAgent);
            if (message.Address != null && Object.hasOwnProperty.call(message, "Address"))
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.Address);
            if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.Type);
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Codec);
            if (message.MOSFromPBX != null && Object.hasOwnProperty.call(message, "MOSFromPBX"))
                writer.uint32(/* id 6, wireType 5 =*/53).float(message.MOSFromPBX);
            if (message.RTT != null && Object.hasOwnProperty.call(message, "RTT"))
                writer.uint32(/* id 7, wireType 5 =*/61).float(message.RTT);
            if (message.RxJitter != null && Object.hasOwnProperty.call(message, "RxJitter"))
                writer.uint32(/* id 8, wireType 5 =*/69).float(message.RxJitter);
            if (message.RxLost != null && Object.hasOwnProperty.call(message, "RxLost"))
                writer.uint32(/* id 9, wireType 5 =*/77).float(message.RxLost);
            if (message.RxPackets != null && Object.hasOwnProperty.call(message, "RxPackets"))
                writer.uint32(/* id 10, wireType 0 =*/80).int32(message.RxPackets);
            if (message.MOSToPBX != null && Object.hasOwnProperty.call(message, "MOSToPBX"))
                writer.uint32(/* id 11, wireType 5 =*/93).float(message.MOSToPBX);
            if (message.TxJitter != null && Object.hasOwnProperty.call(message, "TxJitter"))
                writer.uint32(/* id 12, wireType 5 =*/101).float(message.TxJitter);
            if (message.TxLost != null && Object.hasOwnProperty.call(message, "TxLost"))
                writer.uint32(/* id 13, wireType 5 =*/109).float(message.TxLost);
            if (message.TxPackets != null && Object.hasOwnProperty.call(message, "TxPackets"))
                writer.uint32(/* id 14, wireType 0 =*/112).int32(message.TxPackets);
            if (message.TxBursts != null && Object.hasOwnProperty.call(message, "TxBursts"))
                writer.uint32(/* id 15, wireType 0 =*/120).int32(message.TxBursts);
            if (message.TxBurstLen != null && Object.hasOwnProperty.call(message, "TxBurstLen"))
                writer.uint32(/* id 16, wireType 0 =*/128).int32(message.TxBurstLen);
            if (message.Duration != null && Object.hasOwnProperty.call(message, "Duration"))
                writer.uint32(/* id 17, wireType 0 =*/136).int32(message.Duration);
            if (message.TunnelAddress != null && Object.hasOwnProperty.call(message, "TunnelAddress"))
                writer.uint32(/* id 18, wireType 2 =*/146).string(message.TunnelAddress);
            return writer;
        };

        /**
         * Decodes a LegReport message from the specified reader or buffer.
         * @function decode
         * @memberof QualityReport.LegReport
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {QualityReport.LegReport} LegReport
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        LegReport.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.QualityReport.LegReport();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1: {
                        message.Number = reader.string();
                        break;
                    }
                case 2: {
                        message.UserAgent = reader.string();
                        break;
                    }
                case 3: {
                        message.Address = reader.string();
                        break;
                    }
                case 4: {
                        message.Type = reader.int32();
                        break;
                    }
                case 5: {
                        message.Codec = reader.string();
                        break;
                    }
                case 6: {
                        message.MOSFromPBX = reader.float();
                        break;
                    }
                case 7: {
                        message.RTT = reader.float();
                        break;
                    }
                case 8: {
                        message.RxJitter = reader.float();
                        break;
                    }
                case 9: {
                        message.RxLost = reader.float();
                        break;
                    }
                case 10: {
                        message.RxPackets = reader.int32();
                        break;
                    }
                case 11: {
                        message.MOSToPBX = reader.float();
                        break;
                    }
                case 12: {
                        message.TxJitter = reader.float();
                        break;
                    }
                case 13: {
                        message.TxLost = reader.float();
                        break;
                    }
                case 14: {
                        message.TxPackets = reader.int32();
                        break;
                    }
                case 15: {
                        message.TxBursts = reader.int32();
                        break;
                    }
                case 16: {
                        message.TxBurstLen = reader.int32();
                        break;
                    }
                case 17: {
                        message.Duration = reader.int32();
                        break;
                    }
                case 18: {
                        message.TunnelAddress = reader.string();
                        break;
                    }
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return LegReport;
    })();

    return QualityReport;
})();

export const CallHistoryRecord = $root.CallHistoryRecord = (() => {

    /**
     * Properties of a CallHistoryRecord.
     * @exports ICallHistoryRecord
     * @property {string} DnOwner CallHistoryRecord DnOwner
     * @property {number} Id CallHistoryRecord Id
     * @property {CallHistoryConnections} [connections] CallHistoryRecord connections
     * @property {Array.<QualityReport>} [Quality] CallHistoryRecord Quality
     */

    /**
     * Constructs a new CallHistoryRecord.
     * @exports CallHistoryRecord
     * @classdesc Represents a CallHistoryRecord.
     * @constructor
     * @param {Partial<CallHistoryRecord>} [properties] Properties to set
     */
    function CallHistoryRecord(properties) {
        this.Quality = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * CallHistoryRecord DnOwner.
     * @member {string} DnOwner
     * @memberof CallHistoryRecord
     * @instance
     */

    /**
     * CallHistoryRecord Id.
     * @member {number} Id
     * @memberof CallHistoryRecord
     * @instance
     */

    /**
     * CallHistoryRecord connections.
     * @member {CallHistoryConnections} connections
     * @memberof CallHistoryRecord
     * @instance
     */

    /**
     * CallHistoryRecord Quality.
     * @member {Array.<QualityReport>} Quality
     * @memberof CallHistoryRecord
     * @instance
     */
    CallHistoryRecord.prototype.Quality = $util.emptyArray;

    /**
     * Encodes the specified CallHistoryRecord message. Does not implicitly {@link CallHistoryRecord.verify|verify} messages.
     * @function encode
     * @memberof CallHistoryRecord
     * @static
     * @param {CallHistoryRecord} message CallHistoryRecord message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    CallHistoryRecord.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.DnOwner);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.connections != null && Object.hasOwnProperty.call(message, "connections"))
            $root.CallHistoryConnections.encode(message.connections, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.Quality != null && message.Quality.length)
            for (let i = 0; i < message.Quality.length; ++i)
                $root.QualityReport.encode(message.Quality[i], writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a CallHistoryRecord message from the specified reader or buffer.
     * @function decode
     * @memberof CallHistoryRecord
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {CallHistoryRecord} CallHistoryRecord
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    CallHistoryRecord.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.CallHistoryRecord();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.DnOwner = reader.string();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.connections = $root.CallHistoryConnections.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    if (!(message.Quality && message.Quality.length))
                        message.Quality = [];
                    message.Quality.push($root.QualityReport.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return CallHistoryRecord;
})();

export const RequestCallHistory = $root.RequestCallHistory = (() => {

    /**
     * Properties of a RequestCallHistory.
     * @exports IRequestCallHistory
     * @property {CallHistoryType} CallType RequestCallHistory CallType
     * @property {string} DnOwner RequestCallHistory DnOwner
     * @property {number} RecordLimit RequestCallHistory RecordLimit
     * @property {number} RecordOffset RequestCallHistory RecordOffset
     * @property {string} [Search] RequestCallHistory Search
     * @property {DateTime} [From] RequestCallHistory From
     * @property {DateTime} [To] RequestCallHistory To
     */

    /**
     * Constructs a new RequestCallHistory.
     * @exports RequestCallHistory
     * @classdesc Represents a RequestCallHistory.
     * @constructor
     * @param {Partial<RequestCallHistory>} [properties] Properties to set
     */
    function RequestCallHistory(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestCallHistory CallType.
     * @member {CallHistoryType} CallType
     * @memberof RequestCallHistory
     * @instance
     */

    /**
     * RequestCallHistory DnOwner.
     * @member {string} DnOwner
     * @memberof RequestCallHistory
     * @instance
     */

    /**
     * RequestCallHistory RecordLimit.
     * @member {number} RecordLimit
     * @memberof RequestCallHistory
     * @instance
     */

    /**
     * RequestCallHistory RecordOffset.
     * @member {number} RecordOffset
     * @memberof RequestCallHistory
     * @instance
     */

    /**
     * RequestCallHistory Search.
     * @member {string} Search
     * @memberof RequestCallHistory
     * @instance
     */

    /**
     * RequestCallHistory From.
     * @member {DateTime} From
     * @memberof RequestCallHistory
     * @instance
     */

    /**
     * RequestCallHistory To.
     * @member {DateTime} To
     * @memberof RequestCallHistory
     * @instance
     */

    /**
     * Encodes the specified RequestCallHistory message. Does not implicitly {@link RequestCallHistory.verify|verify} messages.
     * @function encode
     * @memberof RequestCallHistory
     * @static
     * @param {RequestCallHistory} message RequestCallHistory message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestCallHistory.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.CallType);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.DnOwner);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.RecordLimit);
        writer.uint32(/* id 4, wireType 0 =*/32).int32(message.RecordOffset);
        if (message.Search != null && Object.hasOwnProperty.call(message, "Search"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Search);
        if (message.From != null && Object.hasOwnProperty.call(message, "From"))
            $root.DateTime.encode(message.From, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.To != null && Object.hasOwnProperty.call(message, "To"))
            $root.DateTime.encode(message.To, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestCallHistory message from the specified reader or buffer.
     * @function decode
     * @memberof RequestCallHistory
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestCallHistory} RequestCallHistory
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestCallHistory.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestCallHistory();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.CallType = reader.int32();
                    break;
                }
            case 2: {
                    message.DnOwner = reader.string();
                    break;
                }
            case 3: {
                    message.RecordLimit = reader.int32();
                    break;
                }
            case 4: {
                    message.RecordOffset = reader.int32();
                    break;
                }
            case 5: {
                    message.Search = reader.string();
                    break;
                }
            case 6: {
                    message.From = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.To = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestCallHistory message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestCallHistory
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestCallHistory.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 106, GetCallHistory : this});
    };

    /**
     * RequestCallHistory typeId
     * @member {number} typeId
     * @memberof RequestCallHistory
     * @instance
     */
    RequestCallHistory.prototype.typeId = 106;
    /**
     * RequestCallHistory typeName
     * @member {string} typeName
     * @memberof RequestCallHistory
     * @instance
     */
    RequestCallHistory.prototype.typeName = "GetCallHistory";

    return RequestCallHistory;
})();

export const RequestCallHistoryCount = $root.RequestCallHistoryCount = (() => {

    /**
     * Properties of a RequestCallHistoryCount.
     * @exports IRequestCallHistoryCount
     * @property {CallHistoryType} CallType RequestCallHistoryCount CallType
     * @property {string} DnOwner RequestCallHistoryCount DnOwner
     * @property {string} [Search] RequestCallHistoryCount Search
     * @property {DateTime} [From] RequestCallHistoryCount From
     * @property {DateTime} [To] RequestCallHistoryCount To
     */

    /**
     * Constructs a new RequestCallHistoryCount.
     * @exports RequestCallHistoryCount
     * @classdesc Represents a RequestCallHistoryCount.
     * @constructor
     * @param {Partial<RequestCallHistoryCount>} [properties] Properties to set
     */
    function RequestCallHistoryCount(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestCallHistoryCount CallType.
     * @member {CallHistoryType} CallType
     * @memberof RequestCallHistoryCount
     * @instance
     */

    /**
     * RequestCallHistoryCount DnOwner.
     * @member {string} DnOwner
     * @memberof RequestCallHistoryCount
     * @instance
     */

    /**
     * RequestCallHistoryCount Search.
     * @member {string} Search
     * @memberof RequestCallHistoryCount
     * @instance
     */

    /**
     * RequestCallHistoryCount From.
     * @member {DateTime} From
     * @memberof RequestCallHistoryCount
     * @instance
     */

    /**
     * RequestCallHistoryCount To.
     * @member {DateTime} To
     * @memberof RequestCallHistoryCount
     * @instance
     */

    /**
     * Encodes the specified RequestCallHistoryCount message. Does not implicitly {@link RequestCallHistoryCount.verify|verify} messages.
     * @function encode
     * @memberof RequestCallHistoryCount
     * @static
     * @param {RequestCallHistoryCount} message RequestCallHistoryCount message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestCallHistoryCount.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.CallType);
        writer.uint32(/* id 2, wireType 2 =*/18).string(message.DnOwner);
        if (message.Search != null && Object.hasOwnProperty.call(message, "Search"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Search);
        if (message.From != null && Object.hasOwnProperty.call(message, "From"))
            $root.DateTime.encode(message.From, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.To != null && Object.hasOwnProperty.call(message, "To"))
            $root.DateTime.encode(message.To, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestCallHistoryCount message from the specified reader or buffer.
     * @function decode
     * @memberof RequestCallHistoryCount
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestCallHistoryCount} RequestCallHistoryCount
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestCallHistoryCount.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestCallHistoryCount();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.CallType = reader.int32();
                    break;
                }
            case 2: {
                    message.DnOwner = reader.string();
                    break;
                }
            case 3: {
                    message.Search = reader.string();
                    break;
                }
            case 4: {
                    message.From = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.To = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestCallHistoryCount message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestCallHistoryCount
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestCallHistoryCount.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 107, GetCallHistoryCount : this});
    };

    /**
     * RequestCallHistoryCount typeId
     * @member {number} typeId
     * @memberof RequestCallHistoryCount
     * @instance
     */
    RequestCallHistoryCount.prototype.typeId = 107;
    /**
     * RequestCallHistoryCount typeName
     * @member {string} typeName
     * @memberof RequestCallHistoryCount
     * @instance
     */
    RequestCallHistoryCount.prototype.typeName = "GetCallHistoryCount";

    return RequestCallHistoryCount;
})();

export const ResponseCallHistory = $root.ResponseCallHistory = (() => {

    /**
     * Properties of a ResponseCallHistory.
     * @exports IResponseCallHistory
     * @property {Array.<CallHistoryRecord>} [Records] ResponseCallHistory Records
     */

    /**
     * Constructs a new ResponseCallHistory.
     * @exports ResponseCallHistory
     * @classdesc Represents a ResponseCallHistory.
     * @constructor
     * @param {Partial<ResponseCallHistory>} [properties] Properties to set
     */
    function ResponseCallHistory(properties) {
        this.Records = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseCallHistory Records.
     * @member {Array.<CallHistoryRecord>} Records
     * @memberof ResponseCallHistory
     * @instance
     */
    ResponseCallHistory.prototype.Records = $util.emptyArray;

    /**
     * Encodes the specified ResponseCallHistory message. Does not implicitly {@link ResponseCallHistory.verify|verify} messages.
     * @function encode
     * @memberof ResponseCallHistory
     * @static
     * @param {ResponseCallHistory} message ResponseCallHistory message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseCallHistory.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Records != null && message.Records.length)
            for (let i = 0; i < message.Records.length; ++i)
                $root.CallHistoryRecord.encode(message.Records[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseCallHistory message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseCallHistory
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseCallHistory} ResponseCallHistory
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseCallHistory.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseCallHistory();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Records && message.Records.length))
                        message.Records = [];
                    message.Records.push($root.CallHistoryRecord.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseCallHistory message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseCallHistory
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseCallHistory.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 204, CallHistory : this});
    };

    /**
     * ResponseCallHistory typeId
     * @member {number} typeId
     * @memberof ResponseCallHistory
     * @instance
     */
    ResponseCallHistory.prototype.typeId = 204;
    /**
     * ResponseCallHistory typeName
     * @member {string} typeName
     * @memberof ResponseCallHistory
     * @instance
     */
    ResponseCallHistory.prototype.typeName = "CallHistory";

    return ResponseCallHistory;
})();

export const ResponseCallHistoryCount = $root.ResponseCallHistoryCount = (() => {

    /**
     * Properties of a ResponseCallHistoryCount.
     * @exports IResponseCallHistoryCount
     * @property {number} Count ResponseCallHistoryCount Count
     */

    /**
     * Constructs a new ResponseCallHistoryCount.
     * @exports ResponseCallHistoryCount
     * @classdesc Represents a ResponseCallHistoryCount.
     * @constructor
     * @param {Partial<ResponseCallHistoryCount>} [properties] Properties to set
     */
    function ResponseCallHistoryCount(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseCallHistoryCount Count.
     * @member {number} Count
     * @memberof ResponseCallHistoryCount
     * @instance
     */

    /**
     * Encodes the specified ResponseCallHistoryCount message. Does not implicitly {@link ResponseCallHistoryCount.verify|verify} messages.
     * @function encode
     * @memberof ResponseCallHistoryCount
     * @static
     * @param {ResponseCallHistoryCount} message ResponseCallHistoryCount message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseCallHistoryCount.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Count);
        return writer;
    };

    /**
     * Decodes a ResponseCallHistoryCount message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseCallHistoryCount
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseCallHistoryCount} ResponseCallHistoryCount
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseCallHistoryCount.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseCallHistoryCount();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Count = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseCallHistoryCount message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseCallHistoryCount
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseCallHistoryCount.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 205, CallHistoryCount : this});
    };

    /**
     * ResponseCallHistoryCount typeId
     * @member {number} typeId
     * @memberof ResponseCallHistoryCount
     * @instance
     */
    ResponseCallHistoryCount.prototype.typeId = 205;
    /**
     * ResponseCallHistoryCount typeName
     * @member {string} typeName
     * @memberof ResponseCallHistoryCount
     * @instance
     */
    ResponseCallHistoryCount.prototype.typeName = "CallHistoryCount";

    return ResponseCallHistoryCount;
})();

export const RequestDeleteCallHistory = $root.RequestDeleteCallHistory = (() => {

    /**
     * Properties of a RequestDeleteCallHistory.
     * @exports IRequestDeleteCallHistory
     * @property {DeleteHistoryType} Type RequestDeleteCallHistory Type
     * @property {Array.<number>} [IdList] RequestDeleteCallHistory IdList
     * @property {DateTime} [StartFrom] RequestDeleteCallHistory StartFrom
     */

    /**
     * Constructs a new RequestDeleteCallHistory.
     * @exports RequestDeleteCallHistory
     * @classdesc Represents a RequestDeleteCallHistory.
     * @constructor
     * @param {Partial<RequestDeleteCallHistory>} [properties] Properties to set
     */
    function RequestDeleteCallHistory(properties) {
        this.IdList = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestDeleteCallHistory Type.
     * @member {DeleteHistoryType} Type
     * @memberof RequestDeleteCallHistory
     * @instance
     */

    /**
     * RequestDeleteCallHistory IdList.
     * @member {Array.<number>} IdList
     * @memberof RequestDeleteCallHistory
     * @instance
     */
    RequestDeleteCallHistory.prototype.IdList = $util.emptyArray;

    /**
     * RequestDeleteCallHistory StartFrom.
     * @member {DateTime} StartFrom
     * @memberof RequestDeleteCallHistory
     * @instance
     */

    /**
     * Encodes the specified RequestDeleteCallHistory message. Does not implicitly {@link RequestDeleteCallHistory.verify|verify} messages.
     * @function encode
     * @memberof RequestDeleteCallHistory
     * @static
     * @param {RequestDeleteCallHistory} message RequestDeleteCallHistory message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestDeleteCallHistory.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Type);
        if (message.IdList != null && message.IdList.length)
            for (let i = 0; i < message.IdList.length; ++i)
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.IdList[i]);
        if (message.StartFrom != null && Object.hasOwnProperty.call(message, "StartFrom"))
            $root.DateTime.encode(message.StartFrom, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestDeleteCallHistory message from the specified reader or buffer.
     * @function decode
     * @memberof RequestDeleteCallHistory
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestDeleteCallHistory} RequestDeleteCallHistory
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestDeleteCallHistory.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestDeleteCallHistory();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Type = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.IdList && message.IdList.length))
                        message.IdList = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.IdList.push(reader.int32());
                    } else
                        message.IdList.push(reader.int32());
                    break;
                }
            case 3: {
                    message.StartFrom = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestDeleteCallHistory message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestDeleteCallHistory
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestDeleteCallHistory.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 148, DeleteCallHistory : this});
    };

    /**
     * RequestDeleteCallHistory typeId
     * @member {number} typeId
     * @memberof RequestDeleteCallHistory
     * @instance
     */
    RequestDeleteCallHistory.prototype.typeId = 148;
    /**
     * RequestDeleteCallHistory typeName
     * @member {string} typeName
     * @memberof RequestDeleteCallHistory
     * @instance
     */
    RequestDeleteCallHistory.prototype.typeName = "DeleteCallHistory";

    return RequestDeleteCallHistory;
})();

export const ExtensionInfo = $root.ExtensionInfo = (() => {

    /**
     * Properties of an ExtensionInfo.
     * @exports IExtensionInfo
     * @property {ActionType} Action ExtensionInfo Action
     * @property {number} Id ExtensionInfo Id
     * @property {DnType} [Type] ExtensionInfo Type
     * @property {string} [Number] ExtensionInfo Number
     * @property {string} [FirstName] ExtensionInfo FirstName
     * @property {string} [LastName] ExtensionInfo LastName
     * @property {string} [MobileNumber] ExtensionInfo MobileNumber
     * @property {string} [EmailAddress] ExtensionInfo EmailAddress
     * @property {string} [Other] ExtensionInfo Other
     * @property {string} [ContactImage] ExtensionInfo ContactImage
     * @property {boolean} [HideInPhoneBook] ExtensionInfo HideInPhoneBook
     */

    /**
     * Constructs a new ExtensionInfo.
     * @exports ExtensionInfo
     * @classdesc Represents an ExtensionInfo.
     * @constructor
     * @param {Partial<ExtensionInfo>} [properties] Properties to set
     */
    function ExtensionInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ExtensionInfo Action.
     * @member {ActionType} Action
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo Id.
     * @member {number} Id
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo Type.
     * @member {DnType} Type
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo Number.
     * @member {string} Number
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo FirstName.
     * @member {string} FirstName
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo LastName.
     * @member {string} LastName
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo MobileNumber.
     * @member {string} MobileNumber
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo EmailAddress.
     * @member {string} EmailAddress
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo Other.
     * @member {string} Other
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo ContactImage.
     * @member {string} ContactImage
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * ExtensionInfo HideInPhoneBook.
     * @member {boolean} HideInPhoneBook
     * @memberof ExtensionInfo
     * @instance
     */

    /**
     * Encodes the specified ExtensionInfo message. Does not implicitly {@link ExtensionInfo.verify|verify} messages.
     * @function encode
     * @memberof ExtensionInfo
     * @static
     * @param {ExtensionInfo} message ExtensionInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ExtensionInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Type);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Number);
        if (message.FirstName != null && Object.hasOwnProperty.call(message, "FirstName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.FirstName);
        if (message.LastName != null && Object.hasOwnProperty.call(message, "LastName"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.LastName);
        if (message.MobileNumber != null && Object.hasOwnProperty.call(message, "MobileNumber"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.MobileNumber);
        if (message.EmailAddress != null && Object.hasOwnProperty.call(message, "EmailAddress"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.EmailAddress);
        if (message.Other != null && Object.hasOwnProperty.call(message, "Other"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.Other);
        if (message.ContactImage != null && Object.hasOwnProperty.call(message, "ContactImage"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.ContactImage);
        if (message.HideInPhoneBook != null && Object.hasOwnProperty.call(message, "HideInPhoneBook"))
            writer.uint32(/* id 11, wireType 0 =*/88).bool(message.HideInPhoneBook);
        return writer;
    };

    /**
     * Decodes an ExtensionInfo message from the specified reader or buffer.
     * @function decode
     * @memberof ExtensionInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ExtensionInfo} ExtensionInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ExtensionInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ExtensionInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Type = reader.int32();
                    break;
                }
            case 4: {
                    message.Number = reader.string();
                    break;
                }
            case 5: {
                    message.FirstName = reader.string();
                    break;
                }
            case 6: {
                    message.LastName = reader.string();
                    break;
                }
            case 7: {
                    message.MobileNumber = reader.string();
                    break;
                }
            case 8: {
                    message.EmailAddress = reader.string();
                    break;
                }
            case 9: {
                    message.Other = reader.string();
                    break;
                }
            case 10: {
                    message.ContactImage = reader.string();
                    break;
                }
            case 11: {
                    message.HideInPhoneBook = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ExtensionInfo;
})();

export const RequestGetExtensions = $root.RequestGetExtensions = (() => {

    /**
     * Properties of a RequestGetExtensions.
     * @exports IRequestGetExtensions
     * @property {DnType} [Type] RequestGetExtensions Type
     */

    /**
     * Constructs a new RequestGetExtensions.
     * @exports RequestGetExtensions
     * @classdesc Represents a RequestGetExtensions.
     * @constructor
     * @param {Partial<RequestGetExtensions>} [properties] Properties to set
     */
    function RequestGetExtensions(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetExtensions Type.
     * @member {DnType} Type
     * @memberof RequestGetExtensions
     * @instance
     */

    /**
     * Encodes the specified RequestGetExtensions message. Does not implicitly {@link RequestGetExtensions.verify|verify} messages.
     * @function encode
     * @memberof RequestGetExtensions
     * @static
     * @param {RequestGetExtensions} message RequestGetExtensions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetExtensions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Type);
        return writer;
    };

    /**
     * Decodes a RequestGetExtensions message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetExtensions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetExtensions} RequestGetExtensions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetExtensions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetExtensions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Type = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetExtensions message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetExtensions
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetExtensions.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 109, GetExtensions : this});
    };

    /**
     * RequestGetExtensions typeId
     * @member {number} typeId
     * @memberof RequestGetExtensions
     * @instance
     */
    RequestGetExtensions.prototype.typeId = 109;
    /**
     * RequestGetExtensions typeName
     * @member {string} typeName
     * @memberof RequestGetExtensions
     * @instance
     */
    RequestGetExtensions.prototype.typeName = "GetExtensions";

    return RequestGetExtensions;
})();

export const ResponseGetExtensions = $root.ResponseGetExtensions = (() => {

    /**
     * Properties of a ResponseGetExtensions.
     * @exports IResponseGetExtensions
     * @property {ActionType} Action ResponseGetExtensions Action
     * @property {Array.<ExtensionInfo>} [Items] ResponseGetExtensions Items
     */

    /**
     * Constructs a new ResponseGetExtensions.
     * @exports ResponseGetExtensions
     * @classdesc Represents a ResponseGetExtensions.
     * @constructor
     * @param {Partial<ResponseGetExtensions>} [properties] Properties to set
     */
    function ResponseGetExtensions(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseGetExtensions Action.
     * @member {ActionType} Action
     * @memberof ResponseGetExtensions
     * @instance
     */

    /**
     * ResponseGetExtensions Items.
     * @member {Array.<ExtensionInfo>} Items
     * @memberof ResponseGetExtensions
     * @instance
     */
    ResponseGetExtensions.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified ResponseGetExtensions message. Does not implicitly {@link ResponseGetExtensions.verify|verify} messages.
     * @function encode
     * @memberof ResponseGetExtensions
     * @static
     * @param {ResponseGetExtensions} message ResponseGetExtensions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGetExtensions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.ExtensionInfo.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseGetExtensions message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseGetExtensions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseGetExtensions} ResponseGetExtensions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGetExtensions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseGetExtensions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.ExtensionInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseGetExtensions message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseGetExtensions
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseGetExtensions.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 206, Extensions : this});
    };

    /**
     * ResponseGetExtensions typeId
     * @member {number} typeId
     * @memberof ResponseGetExtensions
     * @instance
     */
    ResponseGetExtensions.prototype.typeId = 206;
    /**
     * ResponseGetExtensions typeName
     * @member {string} typeName
     * @memberof ResponseGetExtensions
     * @instance
     */
    ResponseGetExtensions.prototype.typeName = "Extensions";

    return ResponseGetExtensions;
})();

export const ResponseAcknowledge = $root.ResponseAcknowledge = (() => {

    /**
     * Properties of a ResponseAcknowledge.
     * @exports IResponseAcknowledge
     * @property {boolean} Success ResponseAcknowledge Success
     * @property {number} [ErrorCode] ResponseAcknowledge ErrorCode
     * @property {string} [Message] ResponseAcknowledge Message
     * @property {string} [ExceptionType] ResponseAcknowledge ExceptionType
     * @property {string} [ExceptionMessage] ResponseAcknowledge ExceptionMessage
     * @property {ErrorCodes} [ErrorType] ResponseAcknowledge ErrorType
     * @property {string} [Parameter] ResponseAcknowledge Parameter
     */

    /**
     * Constructs a new ResponseAcknowledge.
     * @exports ResponseAcknowledge
     * @classdesc Represents a ResponseAcknowledge.
     * @constructor
     * @param {Partial<ResponseAcknowledge>} [properties] Properties to set
     */
    function ResponseAcknowledge(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseAcknowledge Success.
     * @member {boolean} Success
     * @memberof ResponseAcknowledge
     * @instance
     */

    /**
     * ResponseAcknowledge ErrorCode.
     * @member {number} ErrorCode
     * @memberof ResponseAcknowledge
     * @instance
     */

    /**
     * ResponseAcknowledge Message.
     * @member {string} Message
     * @memberof ResponseAcknowledge
     * @instance
     */

    /**
     * ResponseAcknowledge ExceptionType.
     * @member {string} ExceptionType
     * @memberof ResponseAcknowledge
     * @instance
     */

    /**
     * ResponseAcknowledge ExceptionMessage.
     * @member {string} ExceptionMessage
     * @memberof ResponseAcknowledge
     * @instance
     */

    /**
     * ResponseAcknowledge ErrorType.
     * @member {ErrorCodes} ErrorType
     * @memberof ResponseAcknowledge
     * @instance
     */

    /**
     * ResponseAcknowledge Parameter.
     * @member {string} Parameter
     * @memberof ResponseAcknowledge
     * @instance
     */

    /**
     * Encodes the specified ResponseAcknowledge message. Does not implicitly {@link ResponseAcknowledge.verify|verify} messages.
     * @function encode
     * @memberof ResponseAcknowledge
     * @static
     * @param {ResponseAcknowledge} message ResponseAcknowledge message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseAcknowledge.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.Success);
        if (message.ErrorCode != null && Object.hasOwnProperty.call(message, "ErrorCode"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ErrorCode);
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Message);
        if (message.ExceptionType != null && Object.hasOwnProperty.call(message, "ExceptionType"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.ExceptionType);
        if (message.ExceptionMessage != null && Object.hasOwnProperty.call(message, "ExceptionMessage"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.ExceptionMessage);
        if (message.ErrorType != null && Object.hasOwnProperty.call(message, "ErrorType"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.ErrorType);
        if (message.Parameter != null && Object.hasOwnProperty.call(message, "Parameter"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.Parameter);
        return writer;
    };

    /**
     * Decodes a ResponseAcknowledge message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseAcknowledge
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseAcknowledge} ResponseAcknowledge
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseAcknowledge.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseAcknowledge();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Success = reader.bool();
                    break;
                }
            case 2: {
                    message.ErrorCode = reader.int32();
                    break;
                }
            case 3: {
                    message.Message = reader.string();
                    break;
                }
            case 4: {
                    message.ExceptionType = reader.string();
                    break;
                }
            case 5: {
                    message.ExceptionMessage = reader.string();
                    break;
                }
            case 6: {
                    message.ErrorType = reader.int32();
                    break;
                }
            case 7: {
                    message.Parameter = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseAcknowledge message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseAcknowledge
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseAcknowledge.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 207, Acknowledge : this});
    };

    /**
     * ResponseAcknowledge typeId
     * @member {number} typeId
     * @memberof ResponseAcknowledge
     * @instance
     */
    ResponseAcknowledge.prototype.typeId = 207;
    /**
     * ResponseAcknowledge typeName
     * @member {string} typeName
     * @memberof ResponseAcknowledge
     * @instance
     */
    ResponseAcknowledge.prototype.typeName = "Acknowledge";

    return ResponseAcknowledge;
})();

export const ChatRecipient = $root.ChatRecipient = (() => {

    /**
     * Properties of a ChatRecipient.
     * @exports IChatRecipient
     * @property {string} [ExtNumber] ChatRecipient ExtNumber
     * @property {string} [Name] ChatRecipient Name
     * @property {string} [BridgeNumber] ChatRecipient BridgeNumber
     * @property {string} [Email] ChatRecipient Email
     * @property {Contact} [Contact] ChatRecipient Contact
     * @property {number} [IdRecipient] ChatRecipient IdRecipient
     * @property {ChatRecipientType} [RecipientType] ChatRecipient RecipientType
     * @property {string} [PhoneNumber] ChatRecipient PhoneNumber
     * @property {string} [IpAddress] ChatRecipient IpAddress
     * @property {boolean} [IsModerator] ChatRecipient IsModerator
     * @property {string} [MemberGid] ChatRecipient MemberGid
     * @property {string} [UserData] ChatRecipient UserData
     */

    /**
     * Constructs a new ChatRecipient.
     * @exports ChatRecipient
     * @classdesc Represents a ChatRecipient.
     * @constructor
     * @param {Partial<ChatRecipient>} [properties] Properties to set
     */
    function ChatRecipient(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatRecipient ExtNumber.
     * @member {string} ExtNumber
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient Name.
     * @member {string} Name
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient BridgeNumber.
     * @member {string} BridgeNumber
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient Email.
     * @member {string} Email
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient Contact.
     * @member {Contact} Contact
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient IdRecipient.
     * @member {number} IdRecipient
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient RecipientType.
     * @member {ChatRecipientType} RecipientType
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient PhoneNumber.
     * @member {string} PhoneNumber
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient IpAddress.
     * @member {string} IpAddress
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient IsModerator.
     * @member {boolean} IsModerator
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient MemberGid.
     * @member {string} MemberGid
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * ChatRecipient UserData.
     * @member {string} UserData
     * @memberof ChatRecipient
     * @instance
     */

    /**
     * Encodes the specified ChatRecipient message. Does not implicitly {@link ChatRecipient.verify|verify} messages.
     * @function encode
     * @memberof ChatRecipient
     * @static
     * @param {ChatRecipient} message ChatRecipient message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatRecipient.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ExtNumber != null && Object.hasOwnProperty.call(message, "ExtNumber"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ExtNumber);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Name);
        if (message.BridgeNumber != null && Object.hasOwnProperty.call(message, "BridgeNumber"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.BridgeNumber);
        if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Email);
        if (message.Contact != null && Object.hasOwnProperty.call(message, "Contact"))
            $root.Contact.encode(message.Contact, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.IdRecipient != null && Object.hasOwnProperty.call(message, "IdRecipient"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.IdRecipient);
        if (message.RecipientType != null && Object.hasOwnProperty.call(message, "RecipientType"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.RecipientType);
        if (message.PhoneNumber != null && Object.hasOwnProperty.call(message, "PhoneNumber"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.PhoneNumber);
        if (message.IpAddress != null && Object.hasOwnProperty.call(message, "IpAddress"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.IpAddress);
        if (message.IsModerator != null && Object.hasOwnProperty.call(message, "IsModerator"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.IsModerator);
        if (message.MemberGid != null && Object.hasOwnProperty.call(message, "MemberGid"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.MemberGid);
        if (message.UserData != null && Object.hasOwnProperty.call(message, "UserData"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.UserData);
        return writer;
    };

    /**
     * Decodes a ChatRecipient message from the specified reader or buffer.
     * @function decode
     * @memberof ChatRecipient
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatRecipient} ChatRecipient
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatRecipient.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatRecipient();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ExtNumber = reader.string();
                    break;
                }
            case 2: {
                    message.Name = reader.string();
                    break;
                }
            case 3: {
                    message.BridgeNumber = reader.string();
                    break;
                }
            case 4: {
                    message.Email = reader.string();
                    break;
                }
            case 5: {
                    message.Contact = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.IdRecipient = reader.int32();
                    break;
                }
            case 7: {
                    message.RecipientType = reader.int32();
                    break;
                }
            case 8: {
                    message.PhoneNumber = reader.string();
                    break;
                }
            case 9: {
                    message.IpAddress = reader.string();
                    break;
                }
            case 10: {
                    message.IsModerator = reader.bool();
                    break;
                }
            case 11: {
                    message.MemberGid = reader.string();
                    break;
                }
            case 12: {
                    message.UserData = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatRecipient;
})();

export const ChatRecipientEx = $root.ChatRecipientEx = (() => {

    /**
     * Properties of a ChatRecipientEx.
     * @exports IChatRecipientEx
     * @property {ChatRecipient} [Recipient] ChatRecipientEx Recipient
     * @property {boolean} [IsAnonymousActive] ChatRecipientEx IsAnonymousActive
     * @property {boolean} [IsRemoved] ChatRecipientEx IsRemoved
     * @property {boolean} [IsWhisperer] ChatRecipientEx IsWhisperer
     * @property {boolean} [CanAddUsers] ChatRecipientEx CanAddUsers
     * @property {boolean} [CanBeDeleted] ChatRecipientEx CanBeDeleted
     * @property {ChatRecipientStatus} [Staus] ChatRecipientEx Staus
     */

    /**
     * Constructs a new ChatRecipientEx.
     * @exports ChatRecipientEx
     * @classdesc Represents a ChatRecipientEx.
     * @constructor
     * @param {Partial<ChatRecipientEx>} [properties] Properties to set
     */
    function ChatRecipientEx(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatRecipientEx Recipient.
     * @member {ChatRecipient} Recipient
     * @memberof ChatRecipientEx
     * @instance
     */

    /**
     * ChatRecipientEx IsAnonymousActive.
     * @member {boolean} IsAnonymousActive
     * @memberof ChatRecipientEx
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatRecipientEx IsRemoved.
     * @member {boolean} IsRemoved
     * @memberof ChatRecipientEx
     * @instance
     */

    /**
     * ChatRecipientEx IsWhisperer.
     * @member {boolean} IsWhisperer
     * @memberof ChatRecipientEx
     * @instance
     */

    /**
     * ChatRecipientEx CanAddUsers.
     * @member {boolean} CanAddUsers
     * @memberof ChatRecipientEx
     * @instance
     */

    /**
     * ChatRecipientEx CanBeDeleted.
     * @member {boolean} CanBeDeleted
     * @memberof ChatRecipientEx
     * @instance
     */

    /**
     * ChatRecipientEx Staus.
     * @member {ChatRecipientStatus} Staus
     * @memberof ChatRecipientEx
     * @instance
     */

    /**
     * Encodes the specified ChatRecipientEx message. Does not implicitly {@link ChatRecipientEx.verify|verify} messages.
     * @function encode
     * @memberof ChatRecipientEx
     * @static
     * @param {ChatRecipientEx} message ChatRecipientEx message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatRecipientEx.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Recipient != null && Object.hasOwnProperty.call(message, "Recipient"))
            $root.ChatRecipient.encode(message.Recipient, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.IsAnonymousActive != null && Object.hasOwnProperty.call(message, "IsAnonymousActive"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsAnonymousActive);
        if (message.IsRemoved != null && Object.hasOwnProperty.call(message, "IsRemoved"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.IsRemoved);
        if (message.IsWhisperer != null && Object.hasOwnProperty.call(message, "IsWhisperer"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.IsWhisperer);
        if (message.CanAddUsers != null && Object.hasOwnProperty.call(message, "CanAddUsers"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.CanAddUsers);
        if (message.CanBeDeleted != null && Object.hasOwnProperty.call(message, "CanBeDeleted"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.CanBeDeleted);
        if (message.Staus != null && Object.hasOwnProperty.call(message, "Staus"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.Staus);
        return writer;
    };

    /**
     * Decodes a ChatRecipientEx message from the specified reader or buffer.
     * @function decode
     * @memberof ChatRecipientEx
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatRecipientEx} ChatRecipientEx
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatRecipientEx.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatRecipientEx();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Recipient = $root.ChatRecipient.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.IsAnonymousActive = reader.bool();
                    break;
                }
            case 3: {
                    message.IsRemoved = reader.bool();
                    break;
                }
            case 4: {
                    message.IsWhisperer = reader.bool();
                    break;
                }
            case 5: {
                    message.CanAddUsers = reader.bool();
                    break;
                }
            case 6: {
                    message.CanBeDeleted = reader.bool();
                    break;
                }
            case 7: {
                    message.Staus = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatRecipientEx;
})();

export const ChatRecipientRef = $root.ChatRecipientRef = (() => {

    /**
     * Properties of a ChatRecipientRef.
     * @exports IChatRecipientRef
     * @property {number} IdRecipient ChatRecipientRef IdRecipient
     * @property {ChatDeliveryStatus} [Delivery] ChatRecipientRef Delivery
     * @property {boolean} [IsRead] ChatRecipientRef IsRead
     * @property {boolean} [IsSender] ChatRecipientRef IsSender
     * @property {string} [MemberGid] ChatRecipientRef MemberGid
     * @property {string} [Emoji] ChatRecipientRef Emoji
     */

    /**
     * Constructs a new ChatRecipientRef.
     * @exports ChatRecipientRef
     * @classdesc Represents a ChatRecipientRef.
     * @constructor
     * @param {Partial<ChatRecipientRef>} [properties] Properties to set
     */
    function ChatRecipientRef(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatRecipientRef IdRecipient.
     * @member {number} IdRecipient
     * @memberof ChatRecipientRef
     * @instance
     */

    /**
     * ChatRecipientRef Delivery.
     * @member {ChatDeliveryStatus} Delivery
     * @memberof ChatRecipientRef
     * @instance
     */

    /**
     * ChatRecipientRef IsRead.
     * @member {boolean} IsRead
     * @memberof ChatRecipientRef
     * @instance
     */

    /**
     * ChatRecipientRef IsSender.
     * @member {boolean} IsSender
     * @memberof ChatRecipientRef
     * @instance
     */

    /**
     * ChatRecipientRef MemberGid.
     * @member {string} MemberGid
     * @memberof ChatRecipientRef
     * @instance
     */

    /**
     * ChatRecipientRef Emoji.
     * @member {string} Emoji
     * @memberof ChatRecipientRef
     * @instance
     */

    /**
     * Encodes the specified ChatRecipientRef message. Does not implicitly {@link ChatRecipientRef.verify|verify} messages.
     * @function encode
     * @memberof ChatRecipientRef
     * @static
     * @param {ChatRecipientRef} message ChatRecipientRef message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatRecipientRef.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdRecipient);
        if (message.Delivery != null && Object.hasOwnProperty.call(message, "Delivery"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Delivery);
        if (message.IsRead != null && Object.hasOwnProperty.call(message, "IsRead"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.IsRead);
        if (message.IsSender != null && Object.hasOwnProperty.call(message, "IsSender"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.IsSender);
        if (message.MemberGid != null && Object.hasOwnProperty.call(message, "MemberGid"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.MemberGid);
        if (message.Emoji != null && Object.hasOwnProperty.call(message, "Emoji"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Emoji);
        return writer;
    };

    /**
     * Decodes a ChatRecipientRef message from the specified reader or buffer.
     * @function decode
     * @memberof ChatRecipientRef
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatRecipientRef} ChatRecipientRef
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatRecipientRef.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatRecipientRef();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdRecipient = reader.int32();
                    break;
                }
            case 2: {
                    message.Delivery = reader.int32();
                    break;
                }
            case 3: {
                    message.IsRead = reader.bool();
                    break;
                }
            case 4: {
                    message.IsSender = reader.bool();
                    break;
                }
            case 5: {
                    message.MemberGid = reader.string();
                    break;
                }
            case 6: {
                    message.Emoji = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatRecipientRef;
})();

export const RequestSetMessageEmoji = $root.RequestSetMessageEmoji = (() => {

    /**
     * Properties of a RequestSetMessageEmoji.
     * @exports IRequestSetMessageEmoji
     * @property {string} [MsgGid] RequestSetMessageEmoji MsgGid
     * @property {string} [MemberGid] RequestSetMessageEmoji MemberGid
     * @property {string} [Emoji] RequestSetMessageEmoji Emoji
     * @property {number} [IdMessage] RequestSetMessageEmoji IdMessage
     */

    /**
     * Constructs a new RequestSetMessageEmoji.
     * @exports RequestSetMessageEmoji
     * @classdesc Represents a RequestSetMessageEmoji.
     * @constructor
     * @param {Partial<RequestSetMessageEmoji>} [properties] Properties to set
     */
    function RequestSetMessageEmoji(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetMessageEmoji MsgGid.
     * @member {string} MsgGid
     * @memberof RequestSetMessageEmoji
     * @instance
     */

    /**
     * RequestSetMessageEmoji MemberGid.
     * @member {string} MemberGid
     * @memberof RequestSetMessageEmoji
     * @instance
     */

    /**
     * RequestSetMessageEmoji Emoji.
     * @member {string} Emoji
     * @memberof RequestSetMessageEmoji
     * @instance
     */

    /**
     * RequestSetMessageEmoji IdMessage.
     * @member {number} IdMessage
     * @memberof RequestSetMessageEmoji
     * @instance
     */

    /**
     * Encodes the specified RequestSetMessageEmoji message. Does not implicitly {@link RequestSetMessageEmoji.verify|verify} messages.
     * @function encode
     * @memberof RequestSetMessageEmoji
     * @static
     * @param {RequestSetMessageEmoji} message RequestSetMessageEmoji message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetMessageEmoji.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.MsgGid != null && Object.hasOwnProperty.call(message, "MsgGid"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.MsgGid);
        if (message.MemberGid != null && Object.hasOwnProperty.call(message, "MemberGid"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.MemberGid);
        if (message.Emoji != null && Object.hasOwnProperty.call(message, "Emoji"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Emoji);
        if (message.IdMessage != null && Object.hasOwnProperty.call(message, "IdMessage"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.IdMessage);
        return writer;
    };

    /**
     * Decodes a RequestSetMessageEmoji message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetMessageEmoji
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetMessageEmoji} RequestSetMessageEmoji
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetMessageEmoji.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetMessageEmoji();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.MsgGid = reader.string();
                    break;
                }
            case 2: {
                    message.MemberGid = reader.string();
                    break;
                }
            case 3: {
                    message.Emoji = reader.string();
                    break;
                }
            case 4: {
                    message.IdMessage = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetMessageEmoji message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetMessageEmoji
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetMessageEmoji.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 524, SetMessageEmoji : this});
    };

    /**
     * RequestSetMessageEmoji typeId
     * @member {number} typeId
     * @memberof RequestSetMessageEmoji
     * @instance
     */
    RequestSetMessageEmoji.prototype.typeId = 524;
    /**
     * RequestSetMessageEmoji typeName
     * @member {string} typeName
     * @memberof RequestSetMessageEmoji
     * @instance
     */
    RequestSetMessageEmoji.prototype.typeName = "SetMessageEmoji";

    return RequestSetMessageEmoji;
})();

export const RequestEditChatMessage = $root.RequestEditChatMessage = (() => {

    /**
     * Properties of a RequestEditChatMessage.
     * @exports IRequestEditChatMessage
     * @property {number} [IdMessage] RequestEditChatMessage IdMessage
     * @property {string} [MsgGid] RequestEditChatMessage MsgGid
     * @property {string} [Message] RequestEditChatMessage Message
     */

    /**
     * Constructs a new RequestEditChatMessage.
     * @exports RequestEditChatMessage
     * @classdesc Represents a RequestEditChatMessage.
     * @constructor
     * @param {Partial<RequestEditChatMessage>} [properties] Properties to set
     */
    function RequestEditChatMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestEditChatMessage IdMessage.
     * @member {number} IdMessage
     * @memberof RequestEditChatMessage
     * @instance
     */

    /**
     * RequestEditChatMessage MsgGid.
     * @member {string} MsgGid
     * @memberof RequestEditChatMessage
     * @instance
     */

    /**
     * RequestEditChatMessage Message.
     * @member {string} Message
     * @memberof RequestEditChatMessage
     * @instance
     */

    /**
     * Encodes the specified RequestEditChatMessage message. Does not implicitly {@link RequestEditChatMessage.verify|verify} messages.
     * @function encode
     * @memberof RequestEditChatMessage
     * @static
     * @param {RequestEditChatMessage} message RequestEditChatMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestEditChatMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdMessage != null && Object.hasOwnProperty.call(message, "IdMessage"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdMessage);
        if (message.MsgGid != null && Object.hasOwnProperty.call(message, "MsgGid"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.MsgGid);
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Message);
        return writer;
    };

    /**
     * Decodes a RequestEditChatMessage message from the specified reader or buffer.
     * @function decode
     * @memberof RequestEditChatMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestEditChatMessage} RequestEditChatMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestEditChatMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestEditChatMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdMessage = reader.int32();
                    break;
                }
            case 2: {
                    message.MsgGid = reader.string();
                    break;
                }
            case 3: {
                    message.Message = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestEditChatMessage message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestEditChatMessage
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestEditChatMessage.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 525, EditChatMessage : this});
    };

    /**
     * RequestEditChatMessage typeId
     * @member {number} typeId
     * @memberof RequestEditChatMessage
     * @instance
     */
    RequestEditChatMessage.prototype.typeId = 525;
    /**
     * RequestEditChatMessage typeName
     * @member {string} typeName
     * @memberof RequestEditChatMessage
     * @instance
     */
    RequestEditChatMessage.prototype.typeName = "EditChatMessage";

    return RequestEditChatMessage;
})();

export const NotificationChatMessageChanged = $root.NotificationChatMessageChanged = (() => {

    /**
     * Properties of a NotificationChatMessageChanged.
     * @exports INotificationChatMessageChanged
     * @property {number} [IdMessage] NotificationChatMessageChanged IdMessage
     * @property {string} [MsgGid] NotificationChatMessageChanged MsgGid
     * @property {number} [IdConversation] NotificationChatMessageChanged IdConversation
     * @property {string} [CnvGid] NotificationChatMessageChanged CnvGid
     * @property {Array.<ChatRecipientRef>} [Recipients] NotificationChatMessageChanged Recipients
     * @property {string} [Message] NotificationChatMessageChanged Message
     * @property {DateTime} [EditedAt] NotificationChatMessageChanged EditedAt
     */

    /**
     * Constructs a new NotificationChatMessageChanged.
     * @exports NotificationChatMessageChanged
     * @classdesc Represents a NotificationChatMessageChanged.
     * @constructor
     * @param {Partial<NotificationChatMessageChanged>} [properties] Properties to set
     */
    function NotificationChatMessageChanged(properties) {
        this.Recipients = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotificationChatMessageChanged IdMessage.
     * @member {number} IdMessage
     * @memberof NotificationChatMessageChanged
     * @instance
     */

    /**
     * NotificationChatMessageChanged MsgGid.
     * @member {string} MsgGid
     * @memberof NotificationChatMessageChanged
     * @instance
     */

    /**
     * NotificationChatMessageChanged IdConversation.
     * @member {number} IdConversation
     * @memberof NotificationChatMessageChanged
     * @instance
     */

    /**
     * NotificationChatMessageChanged CnvGid.
     * @member {string} CnvGid
     * @memberof NotificationChatMessageChanged
     * @instance
     */

    /**
     * NotificationChatMessageChanged Recipients.
     * @member {Array.<ChatRecipientRef>} Recipients
     * @memberof NotificationChatMessageChanged
     * @instance
     */
    NotificationChatMessageChanged.prototype.Recipients = $util.emptyArray;

    /**
     * NotificationChatMessageChanged Message.
     * @member {string} Message
     * @memberof NotificationChatMessageChanged
     * @instance
     */

    /**
     * NotificationChatMessageChanged EditedAt.
     * @member {DateTime} EditedAt
     * @memberof NotificationChatMessageChanged
     * @instance
     */

    /**
     * Encodes the specified NotificationChatMessageChanged message. Does not implicitly {@link NotificationChatMessageChanged.verify|verify} messages.
     * @function encode
     * @memberof NotificationChatMessageChanged
     * @static
     * @param {NotificationChatMessageChanged} message NotificationChatMessageChanged message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotificationChatMessageChanged.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdMessage != null && Object.hasOwnProperty.call(message, "IdMessage"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdMessage);
        if (message.MsgGid != null && Object.hasOwnProperty.call(message, "MsgGid"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.MsgGid);
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.IdConversation);
        if (message.CnvGid != null && Object.hasOwnProperty.call(message, "CnvGid"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.CnvGid);
        if (message.Recipients != null && message.Recipients.length)
            for (let i = 0; i < message.Recipients.length; ++i)
                $root.ChatRecipientRef.encode(message.Recipients[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Message);
        if (message.EditedAt != null && Object.hasOwnProperty.call(message, "EditedAt"))
            $root.DateTime.encode(message.EditedAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a NotificationChatMessageChanged message from the specified reader or buffer.
     * @function decode
     * @memberof NotificationChatMessageChanged
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NotificationChatMessageChanged} NotificationChatMessageChanged
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotificationChatMessageChanged.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NotificationChatMessageChanged();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdMessage = reader.int32();
                    break;
                }
            case 2: {
                    message.MsgGid = reader.string();
                    break;
                }
            case 3: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 4: {
                    message.CnvGid = reader.string();
                    break;
                }
            case 5: {
                    if (!(message.Recipients && message.Recipients.length))
                        message.Recipients = [];
                    message.Recipients.push($root.ChatRecipientRef.decode(reader, reader.uint32()));
                    break;
                }
            case 6: {
                    message.Message = reader.string();
                    break;
                }
            case 7: {
                    message.EditedAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a NotificationChatMessageChanged message to GenericMessage
     * @function toGenericMessage
     * @memberof NotificationChatMessageChanged
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    NotificationChatMessageChanged.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 258, ChatMessageChanged : this});
    };

    /**
     * NotificationChatMessageChanged typeId
     * @member {number} typeId
     * @memberof NotificationChatMessageChanged
     * @instance
     */
    NotificationChatMessageChanged.prototype.typeId = 258;
    /**
     * NotificationChatMessageChanged typeName
     * @member {string} typeName
     * @memberof NotificationChatMessageChanged
     * @instance
     */
    NotificationChatMessageChanged.prototype.typeName = "ChatMessageChanged";

    return NotificationChatMessageChanged;
})();

export const ForwardInfo = $root.ForwardInfo = (() => {

    /**
     * Properties of a ForwardInfo.
     * @exports IForwardInfo
     * @property {number} [OriginalMsgId] ForwardInfo OriginalMsgId
     */

    /**
     * Constructs a new ForwardInfo.
     * @exports ForwardInfo
     * @classdesc Represents a ForwardInfo.
     * @constructor
     * @param {Partial<ForwardInfo>} [properties] Properties to set
     */
    function ForwardInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ForwardInfo OriginalMsgId.
     * @member {number} OriginalMsgId
     * @memberof ForwardInfo
     * @instance
     */

    /**
     * Encodes the specified ForwardInfo message. Does not implicitly {@link ForwardInfo.verify|verify} messages.
     * @function encode
     * @memberof ForwardInfo
     * @static
     * @param {ForwardInfo} message ForwardInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ForwardInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.OriginalMsgId != null && Object.hasOwnProperty.call(message, "OriginalMsgId"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.OriginalMsgId);
        return writer;
    };

    /**
     * Decodes a ForwardInfo message from the specified reader or buffer.
     * @function decode
     * @memberof ForwardInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ForwardInfo} ForwardInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ForwardInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ForwardInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.OriginalMsgId = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ForwardInfo;
})();

export const ChatMessage = $root.ChatMessage = (() => {

    /**
     * Properties of a ChatMessage.
     * @exports IChatMessage
     * @property {number} Id ChatMessage Id
     * @property {string} [SenderNumber] ChatMessage SenderNumber
     * @property {string} [SenderName] ChatMessage SenderName
     * @property {string} [SenderBridgeNumber] ChatMessage SenderBridgeNumber
     * @property {ChatRecipient} [Recipient] ChatMessage Recipient
     * @property {string} [Message] ChatMessage Message
     * @property {DateTime} [Time] ChatMessage Time
     * @property {boolean} [IsNew] ChatMessage IsNew
     * @property {string} [Party] ChatMessage Party
     * @property {string} [PartyNew] ChatMessage PartyNew
     * @property {ChatFile} [File] ChatMessage File
     * @property {boolean} [IsAnonymousActive] ChatMessage IsAnonymousActive
     * @property {number} [IdConversation] ChatMessage IdConversation
     * @property {Array.<ChatRecipientRef>} [Recipients] ChatMessage Recipients
     * @property {ChatMessageType} [MessageType] ChatMessage MessageType
     * @property {string} [MsgGid] ChatMessage MsgGid
     * @property {string} [CnvGid] ChatMessage CnvGid
     * @property {string} [Param] ChatMessage Param
     * @property {number} [ReplyOnIdMessage] ChatMessage ReplyOnIdMessage
     * @property {string} [ReplyOnMsgGid] ChatMessage ReplyOnMsgGid
     * @property {DateTime} [EditedAt] ChatMessage EditedAt
     * @property {ForwardInfo} [Forwarded] ChatMessage Forwarded
     */

    /**
     * Constructs a new ChatMessage.
     * @exports ChatMessage
     * @classdesc Represents a ChatMessage.
     * @constructor
     * @param {Partial<ChatMessage>} [properties] Properties to set
     */
    function ChatMessage(properties) {
        this.Recipients = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatMessage Id.
     * @member {number} Id
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage SenderNumber.
     * @member {string} SenderNumber
     * @memberof ChatMessage
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatMessage SenderName.
     * @member {string} SenderName
     * @memberof ChatMessage
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatMessage SenderBridgeNumber.
     * @member {string} SenderBridgeNumber
     * @memberof ChatMessage
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatMessage Recipient.
     * @member {ChatRecipient} Recipient
     * @memberof ChatMessage
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatMessage Message.
     * @member {string} Message
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage Time.
     * @member {DateTime} Time
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage IsNew.
     * @member {boolean} IsNew
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage Party.
     * @member {string} Party
     * @memberof ChatMessage
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatMessage PartyNew.
     * @member {string} PartyNew
     * @memberof ChatMessage
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatMessage File.
     * @member {ChatFile} File
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage IsAnonymousActive.
     * @member {boolean} IsAnonymousActive
     * @memberof ChatMessage
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatMessage IdConversation.
     * @member {number} IdConversation
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage Recipients.
     * @member {Array.<ChatRecipientRef>} Recipients
     * @memberof ChatMessage
     * @instance
     */
    ChatMessage.prototype.Recipients = $util.emptyArray;

    /**
     * ChatMessage MessageType.
     * @member {ChatMessageType} MessageType
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage MsgGid.
     * @member {string} MsgGid
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage CnvGid.
     * @member {string} CnvGid
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage Param.
     * @member {string} Param
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage ReplyOnIdMessage.
     * @member {number} ReplyOnIdMessage
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage ReplyOnMsgGid.
     * @member {string} ReplyOnMsgGid
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage EditedAt.
     * @member {DateTime} EditedAt
     * @memberof ChatMessage
     * @instance
     */

    /**
     * ChatMessage Forwarded.
     * @member {ForwardInfo} Forwarded
     * @memberof ChatMessage
     * @instance
     */

    /**
     * Encodes the specified ChatMessage message. Does not implicitly {@link ChatMessage.verify|verify} messages.
     * @function encode
     * @memberof ChatMessage
     * @static
     * @param {ChatMessage} message ChatMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.SenderNumber != null && Object.hasOwnProperty.call(message, "SenderNumber"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.SenderNumber);
        if (message.SenderName != null && Object.hasOwnProperty.call(message, "SenderName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.SenderName);
        if (message.SenderBridgeNumber != null && Object.hasOwnProperty.call(message, "SenderBridgeNumber"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.SenderBridgeNumber);
        if (message.Recipient != null && Object.hasOwnProperty.call(message, "Recipient"))
            $root.ChatRecipient.encode(message.Recipient, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Message);
        if (message.Time != null && Object.hasOwnProperty.call(message, "Time"))
            $root.DateTime.encode(message.Time, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.IsNew != null && Object.hasOwnProperty.call(message, "IsNew"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.IsNew);
        if (message.Party != null && Object.hasOwnProperty.call(message, "Party"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.Party);
        if (message.PartyNew != null && Object.hasOwnProperty.call(message, "PartyNew"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.PartyNew);
        if (message.File != null && Object.hasOwnProperty.call(message, "File"))
            $root.ChatFile.encode(message.File, writer.uint32(/* id 11, wireType 2 =*/90).fork()).ldelim();
        if (message.IsAnonymousActive != null && Object.hasOwnProperty.call(message, "IsAnonymousActive"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.IsAnonymousActive);
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.IdConversation);
        if (message.Recipients != null && message.Recipients.length)
            for (let i = 0; i < message.Recipients.length; ++i)
                $root.ChatRecipientRef.encode(message.Recipients[i], writer.uint32(/* id 14, wireType 2 =*/114).fork()).ldelim();
        if (message.MessageType != null && Object.hasOwnProperty.call(message, "MessageType"))
            writer.uint32(/* id 15, wireType 0 =*/120).int32(message.MessageType);
        if (message.MsgGid != null && Object.hasOwnProperty.call(message, "MsgGid"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.MsgGid);
        if (message.CnvGid != null && Object.hasOwnProperty.call(message, "CnvGid"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.CnvGid);
        if (message.Param != null && Object.hasOwnProperty.call(message, "Param"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.Param);
        if (message.ReplyOnIdMessage != null && Object.hasOwnProperty.call(message, "ReplyOnIdMessage"))
            writer.uint32(/* id 19, wireType 0 =*/152).int32(message.ReplyOnIdMessage);
        if (message.ReplyOnMsgGid != null && Object.hasOwnProperty.call(message, "ReplyOnMsgGid"))
            writer.uint32(/* id 20, wireType 2 =*/162).string(message.ReplyOnMsgGid);
        if (message.EditedAt != null && Object.hasOwnProperty.call(message, "EditedAt"))
            $root.DateTime.encode(message.EditedAt, writer.uint32(/* id 21, wireType 2 =*/170).fork()).ldelim();
        if (message.Forwarded != null && Object.hasOwnProperty.call(message, "Forwarded"))
            $root.ForwardInfo.encode(message.Forwarded, writer.uint32(/* id 22, wireType 2 =*/178).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ChatMessage message from the specified reader or buffer.
     * @function decode
     * @memberof ChatMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatMessage} ChatMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.SenderNumber = reader.string();
                    break;
                }
            case 3: {
                    message.SenderName = reader.string();
                    break;
                }
            case 4: {
                    message.SenderBridgeNumber = reader.string();
                    break;
                }
            case 5: {
                    message.Recipient = $root.ChatRecipient.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.Message = reader.string();
                    break;
                }
            case 7: {
                    message.Time = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.IsNew = reader.bool();
                    break;
                }
            case 9: {
                    message.Party = reader.string();
                    break;
                }
            case 10: {
                    message.PartyNew = reader.string();
                    break;
                }
            case 11: {
                    message.File = $root.ChatFile.decode(reader, reader.uint32());
                    break;
                }
            case 12: {
                    message.IsAnonymousActive = reader.bool();
                    break;
                }
            case 13: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 14: {
                    if (!(message.Recipients && message.Recipients.length))
                        message.Recipients = [];
                    message.Recipients.push($root.ChatRecipientRef.decode(reader, reader.uint32()));
                    break;
                }
            case 15: {
                    message.MessageType = reader.int32();
                    break;
                }
            case 16: {
                    message.MsgGid = reader.string();
                    break;
                }
            case 17: {
                    message.CnvGid = reader.string();
                    break;
                }
            case 18: {
                    message.Param = reader.string();
                    break;
                }
            case 19: {
                    message.ReplyOnIdMessage = reader.int32();
                    break;
                }
            case 20: {
                    message.ReplyOnMsgGid = reader.string();
                    break;
                }
            case 21: {
                    message.EditedAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 22: {
                    message.Forwarded = $root.ForwardInfo.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatMessage;
})();

export const ChatFile = $root.ChatFile = (() => {

    /**
     * Properties of a ChatFile.
     * @exports IChatFile
     * @property {string} [FileName] ChatFile FileName
     * @property {string} [FileLink] ChatFile FileLink
     * @property {ChatFileState} [FileState] ChatFile FileState
     * @property {number} [Progress] ChatFile Progress
     * @property {boolean} [HasPreview] ChatFile HasPreview
     * @property {number} [FileSize] ChatFile FileSize
     * @property {number} [Width] ChatFile Width
     * @property {number} [Height] ChatFile Height
     * @property {FileType} [Type] ChatFile Type
     * @property {string} [Format] ChatFile Format
     * @property {number} [DurationMs] ChatFile DurationMs
     */

    /**
     * Constructs a new ChatFile.
     * @exports ChatFile
     * @classdesc Represents a ChatFile.
     * @constructor
     * @param {Partial<ChatFile>} [properties] Properties to set
     */
    function ChatFile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatFile FileName.
     * @member {string} FileName
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile FileLink.
     * @member {string} FileLink
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile FileState.
     * @member {ChatFileState} FileState
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile Progress.
     * @member {number} Progress
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile HasPreview.
     * @member {boolean} HasPreview
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile FileSize.
     * @member {number} FileSize
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile Width.
     * @member {number} Width
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile Height.
     * @member {number} Height
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile Type.
     * @member {FileType} Type
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile Format.
     * @member {string} Format
     * @memberof ChatFile
     * @instance
     */

    /**
     * ChatFile DurationMs.
     * @member {number} DurationMs
     * @memberof ChatFile
     * @instance
     */

    /**
     * Encodes the specified ChatFile message. Does not implicitly {@link ChatFile.verify|verify} messages.
     * @function encode
     * @memberof ChatFile
     * @static
     * @param {ChatFile} message ChatFile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatFile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.FileName != null && Object.hasOwnProperty.call(message, "FileName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.FileName);
        if (message.FileLink != null && Object.hasOwnProperty.call(message, "FileLink"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.FileLink);
        if (message.FileState != null && Object.hasOwnProperty.call(message, "FileState"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.FileState);
        if (message.Progress != null && Object.hasOwnProperty.call(message, "Progress"))
            writer.uint32(/* id 4, wireType 5 =*/37).float(message.Progress);
        if (message.HasPreview != null && Object.hasOwnProperty.call(message, "HasPreview"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.HasPreview);
        if (message.FileSize != null && Object.hasOwnProperty.call(message, "FileSize"))
            writer.uint32(/* id 6, wireType 0 =*/48).uint64(message.FileSize);
        if (message.Width != null && Object.hasOwnProperty.call(message, "Width"))
            writer.uint32(/* id 7, wireType 0 =*/56).int32(message.Width);
        if (message.Height != null && Object.hasOwnProperty.call(message, "Height"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.Height);
        if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.Type);
        if (message.Format != null && Object.hasOwnProperty.call(message, "Format"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.Format);
        if (message.DurationMs != null && Object.hasOwnProperty.call(message, "DurationMs"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.DurationMs);
        return writer;
    };

    /**
     * Decodes a ChatFile message from the specified reader or buffer.
     * @function decode
     * @memberof ChatFile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatFile} ChatFile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatFile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatFile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.FileName = reader.string();
                    break;
                }
            case 2: {
                    message.FileLink = reader.string();
                    break;
                }
            case 3: {
                    message.FileState = reader.int32();
                    break;
                }
            case 4: {
                    message.Progress = reader.float();
                    break;
                }
            case 5: {
                    message.HasPreview = reader.bool();
                    break;
                }
            case 6: {
                    message.FileSize = reader.uint64();
                    break;
                }
            case 7: {
                    message.Width = reader.int32();
                    break;
                }
            case 8: {
                    message.Height = reader.int32();
                    break;
                }
            case 9: {
                    message.Type = reader.int32();
                    break;
                }
            case 10: {
                    message.Format = reader.string();
                    break;
                }
            case 11: {
                    message.DurationMs = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatFile;
})();

export const NotificationChatFileProgress = $root.NotificationChatFileProgress = (() => {

    /**
     * Properties of a NotificationChatFileProgress.
     * @exports INotificationChatFileProgress
     * @property {number} Id NotificationChatFileProgress Id
     * @property {string} [Party] NotificationChatFileProgress Party
     * @property {ChatFile} [File] NotificationChatFileProgress File
     * @property {number} [IdConversation] NotificationChatFileProgress IdConversation
     */

    /**
     * Constructs a new NotificationChatFileProgress.
     * @exports NotificationChatFileProgress
     * @classdesc Represents a NotificationChatFileProgress.
     * @constructor
     * @param {Partial<NotificationChatFileProgress>} [properties] Properties to set
     */
    function NotificationChatFileProgress(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotificationChatFileProgress Id.
     * @member {number} Id
     * @memberof NotificationChatFileProgress
     * @instance
     */

    /**
     * NotificationChatFileProgress Party.
     * @member {string} Party
     * @memberof NotificationChatFileProgress
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * NotificationChatFileProgress File.
     * @member {ChatFile} File
     * @memberof NotificationChatFileProgress
     * @instance
     */

    /**
     * NotificationChatFileProgress IdConversation.
     * @member {number} IdConversation
     * @memberof NotificationChatFileProgress
     * @instance
     */

    /**
     * Encodes the specified NotificationChatFileProgress message. Does not implicitly {@link NotificationChatFileProgress.verify|verify} messages.
     * @function encode
     * @memberof NotificationChatFileProgress
     * @static
     * @param {NotificationChatFileProgress} message NotificationChatFileProgress message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotificationChatFileProgress.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.Party != null && Object.hasOwnProperty.call(message, "Party"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Party);
        if (message.File != null && Object.hasOwnProperty.call(message, "File"))
            $root.ChatFile.encode(message.File, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.IdConversation);
        return writer;
    };

    /**
     * Decodes a NotificationChatFileProgress message from the specified reader or buffer.
     * @function decode
     * @memberof NotificationChatFileProgress
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NotificationChatFileProgress} NotificationChatFileProgress
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotificationChatFileProgress.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NotificationChatFileProgress();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.Party = reader.string();
                    break;
                }
            case 3: {
                    message.File = $root.ChatFile.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.IdConversation = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a NotificationChatFileProgress message to GenericMessage
     * @function toGenericMessage
     * @memberof NotificationChatFileProgress
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    NotificationChatFileProgress.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 232, ChatFileProgress : this});
    };

    /**
     * NotificationChatFileProgress typeId
     * @member {number} typeId
     * @memberof NotificationChatFileProgress
     * @instance
     */
    NotificationChatFileProgress.prototype.typeId = 232;
    /**
     * NotificationChatFileProgress typeName
     * @member {string} typeName
     * @memberof NotificationChatFileProgress
     * @instance
     */
    NotificationChatFileProgress.prototype.typeName = "ChatFileProgress";

    return NotificationChatFileProgress;
})();

export const RequestSendChatMessage = $root.RequestSendChatMessage = (() => {

    /**
     * Properties of a RequestSendChatMessage.
     * @exports IRequestSendChatMessage
     * @property {string} [Message] RequestSendChatMessage Message
     * @property {Array.<ChatRecipient>} [Recipients] RequestSendChatMessage Recipients
     * @property {string} [SipFrom] RequestSendChatMessage SipFrom
     */

    /**
     * Constructs a new RequestSendChatMessage.
     * @exports RequestSendChatMessage
     * @classdesc Represents a RequestSendChatMessage.
     * @constructor
     * @param {Partial<RequestSendChatMessage>} [properties] Properties to set
     */
    function RequestSendChatMessage(properties) {
        this.Recipients = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSendChatMessage Message.
     * @member {string} Message
     * @memberof RequestSendChatMessage
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * RequestSendChatMessage Recipients.
     * @member {Array.<ChatRecipient>} Recipients
     * @memberof RequestSendChatMessage
     * @instance
     * @deprecated Avoid using it
     */
    RequestSendChatMessage.prototype.Recipients = $util.emptyArray;

    /**
     * RequestSendChatMessage SipFrom.
     * @member {string} SipFrom
     * @memberof RequestSendChatMessage
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * Encodes the specified RequestSendChatMessage message. Does not implicitly {@link RequestSendChatMessage.verify|verify} messages.
     * @function encode
     * @memberof RequestSendChatMessage
     * @static
     * @param {RequestSendChatMessage} message RequestSendChatMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSendChatMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Message);
        if (message.Recipients != null && message.Recipients.length)
            for (let i = 0; i < message.Recipients.length; ++i)
                $root.ChatRecipient.encode(message.Recipients[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.SipFrom != null && Object.hasOwnProperty.call(message, "SipFrom"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.SipFrom);
        return writer;
    };

    /**
     * Decodes a RequestSendChatMessage message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSendChatMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSendChatMessage} RequestSendChatMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSendChatMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSendChatMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Message = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.Recipients && message.Recipients.length))
                        message.Recipients = [];
                    message.Recipients.push($root.ChatRecipient.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.SipFrom = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSendChatMessage message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSendChatMessage
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSendChatMessage.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 110, SendChatMessage : this});
    };

    /**
     * RequestSendChatMessage typeId
     * @member {number} typeId
     * @memberof RequestSendChatMessage
     * @instance
     */
    RequestSendChatMessage.prototype.typeId = 110;
    /**
     * RequestSendChatMessage typeName
     * @member {string} typeName
     * @memberof RequestSendChatMessage
     * @instance
     */
    RequestSendChatMessage.prototype.typeName = "SendChatMessage";

    return RequestSendChatMessage;
})();

export const RequestSendChatMessageToConversation = $root.RequestSendChatMessageToConversation = (() => {

    /**
     * Properties of a RequestSendChatMessageToConversation.
     * @exports IRequestSendChatMessageToConversation
     * @property {number} IdConversation RequestSendChatMessageToConversation IdConversation
     * @property {string} [Message] RequestSendChatMessageToConversation Message
     * @property {number} [ReplyOnIdMessage] RequestSendChatMessageToConversation ReplyOnIdMessage
     * @property {string} [ReplyOnMsgGid] RequestSendChatMessageToConversation ReplyOnMsgGid
     */

    /**
     * Constructs a new RequestSendChatMessageToConversation.
     * @exports RequestSendChatMessageToConversation
     * @classdesc Represents a RequestSendChatMessageToConversation.
     * @constructor
     * @param {Partial<RequestSendChatMessageToConversation>} [properties] Properties to set
     */
    function RequestSendChatMessageToConversation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSendChatMessageToConversation IdConversation.
     * @member {number} IdConversation
     * @memberof RequestSendChatMessageToConversation
     * @instance
     */

    /**
     * RequestSendChatMessageToConversation Message.
     * @member {string} Message
     * @memberof RequestSendChatMessageToConversation
     * @instance
     */

    /**
     * RequestSendChatMessageToConversation ReplyOnIdMessage.
     * @member {number} ReplyOnIdMessage
     * @memberof RequestSendChatMessageToConversation
     * @instance
     */

    /**
     * RequestSendChatMessageToConversation ReplyOnMsgGid.
     * @member {string} ReplyOnMsgGid
     * @memberof RequestSendChatMessageToConversation
     * @instance
     */

    /**
     * Encodes the specified RequestSendChatMessageToConversation message. Does not implicitly {@link RequestSendChatMessageToConversation.verify|verify} messages.
     * @function encode
     * @memberof RequestSendChatMessageToConversation
     * @static
     * @param {RequestSendChatMessageToConversation} message RequestSendChatMessageToConversation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSendChatMessageToConversation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Message);
        if (message.ReplyOnIdMessage != null && Object.hasOwnProperty.call(message, "ReplyOnIdMessage"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ReplyOnIdMessage);
        if (message.ReplyOnMsgGid != null && Object.hasOwnProperty.call(message, "ReplyOnMsgGid"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.ReplyOnMsgGid);
        return writer;
    };

    /**
     * Decodes a RequestSendChatMessageToConversation message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSendChatMessageToConversation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSendChatMessageToConversation} RequestSendChatMessageToConversation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSendChatMessageToConversation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSendChatMessageToConversation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.Message = reader.string();
                    break;
                }
            case 3: {
                    message.ReplyOnIdMessage = reader.int32();
                    break;
                }
            case 4: {
                    message.ReplyOnMsgGid = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSendChatMessageToConversation message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSendChatMessageToConversation
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSendChatMessageToConversation.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 190, SendMessageToConversation : this});
    };

    /**
     * RequestSendChatMessageToConversation typeId
     * @member {number} typeId
     * @memberof RequestSendChatMessageToConversation
     * @instance
     */
    RequestSendChatMessageToConversation.prototype.typeId = 190;
    /**
     * RequestSendChatMessageToConversation typeName
     * @member {string} typeName
     * @memberof RequestSendChatMessageToConversation
     * @instance
     */
    RequestSendChatMessageToConversation.prototype.typeName = "SendMessageToConversation";

    return RequestSendChatMessageToConversation;
})();

export const RequestSendChatFile = $root.RequestSendChatFile = (() => {

    /**
     * Properties of a RequestSendChatFile.
     * @exports IRequestSendChatFile
     * @property {string} Name RequestSendChatFile Name
     * @property {string} [Party] RequestSendChatFile Party
     * @property {number} [IdConversation] RequestSendChatFile IdConversation
     * @property {number} [IdSender] RequestSendChatFile IdSender
     * @property {string} [Message] RequestSendChatFile Message
     * @property {number} [ReplyOnIdMessage] RequestSendChatFile ReplyOnIdMessage
     * @property {string} [ReplyOnMsgGid] RequestSendChatFile ReplyOnMsgGid
     * @property {string} [MsgGid] RequestSendChatFile MsgGid
     */

    /**
     * Constructs a new RequestSendChatFile.
     * @exports RequestSendChatFile
     * @classdesc Represents a RequestSendChatFile.
     * @constructor
     * @param {Partial<RequestSendChatFile>} [properties] Properties to set
     */
    function RequestSendChatFile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSendChatFile Name.
     * @member {string} Name
     * @memberof RequestSendChatFile
     * @instance
     */

    /**
     * RequestSendChatFile Party.
     * @member {string} Party
     * @memberof RequestSendChatFile
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * RequestSendChatFile IdConversation.
     * @member {number} IdConversation
     * @memberof RequestSendChatFile
     * @instance
     */

    /**
     * RequestSendChatFile IdSender.
     * @member {number} IdSender
     * @memberof RequestSendChatFile
     * @instance
     */

    /**
     * RequestSendChatFile Message.
     * @member {string} Message
     * @memberof RequestSendChatFile
     * @instance
     */

    /**
     * RequestSendChatFile ReplyOnIdMessage.
     * @member {number} ReplyOnIdMessage
     * @memberof RequestSendChatFile
     * @instance
     */

    /**
     * RequestSendChatFile ReplyOnMsgGid.
     * @member {string} ReplyOnMsgGid
     * @memberof RequestSendChatFile
     * @instance
     */

    /**
     * RequestSendChatFile MsgGid.
     * @member {string} MsgGid
     * @memberof RequestSendChatFile
     * @instance
     */

    /**
     * Encodes the specified RequestSendChatFile message. Does not implicitly {@link RequestSendChatFile.verify|verify} messages.
     * @function encode
     * @memberof RequestSendChatFile
     * @static
     * @param {RequestSendChatFile} message RequestSendChatFile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSendChatFile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.Name);
        if (message.Party != null && Object.hasOwnProperty.call(message, "Party"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Party);
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.IdConversation);
        if (message.IdSender != null && Object.hasOwnProperty.call(message, "IdSender"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.IdSender);
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Message);
        if (message.ReplyOnIdMessage != null && Object.hasOwnProperty.call(message, "ReplyOnIdMessage"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.ReplyOnIdMessage);
        if (message.ReplyOnMsgGid != null && Object.hasOwnProperty.call(message, "ReplyOnMsgGid"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.ReplyOnMsgGid);
        if (message.MsgGid != null && Object.hasOwnProperty.call(message, "MsgGid"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.MsgGid);
        return writer;
    };

    /**
     * Decodes a RequestSendChatFile message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSendChatFile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSendChatFile} RequestSendChatFile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSendChatFile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSendChatFile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Name = reader.string();
                    break;
                }
            case 2: {
                    message.Party = reader.string();
                    break;
                }
            case 3: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 4: {
                    message.IdSender = reader.int32();
                    break;
                }
            case 5: {
                    message.Message = reader.string();
                    break;
                }
            case 6: {
                    message.ReplyOnIdMessage = reader.int32();
                    break;
                }
            case 7: {
                    message.ReplyOnMsgGid = reader.string();
                    break;
                }
            case 8: {
                    message.MsgGid = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSendChatFile message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSendChatFile
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSendChatFile.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 179, SendChatFile : this});
    };

    /**
     * RequestSendChatFile typeId
     * @member {number} typeId
     * @memberof RequestSendChatFile
     * @instance
     */
    RequestSendChatFile.prototype.typeId = 179;
    /**
     * RequestSendChatFile typeName
     * @member {string} typeName
     * @memberof RequestSendChatFile
     * @instance
     */
    RequestSendChatFile.prototype.typeName = "SendChatFile";

    return RequestSendChatFile;
})();

export const RequestGetMyMessages = $root.RequestGetMyMessages = (() => {

    /**
     * Properties of a RequestGetMyMessages.
     * @exports IRequestGetMyMessages
     * @property {boolean} OnlyNew RequestGetMyMessages OnlyNew
     * @property {string} [FromNumber] RequestGetMyMessages FromNumber
     * @property {string} [FromBridgeNumber] RequestGetMyMessages FromBridgeNumber
     * @property {DateTime} [StartingFrom] RequestGetMyMessages StartingFrom
     */

    /**
     * Constructs a new RequestGetMyMessages.
     * @exports RequestGetMyMessages
     * @classdesc Represents a RequestGetMyMessages.
     * @constructor
     * @param {Partial<RequestGetMyMessages>} [properties] Properties to set
     */
    function RequestGetMyMessages(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetMyMessages OnlyNew.
     * @member {boolean} OnlyNew
     * @memberof RequestGetMyMessages
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * RequestGetMyMessages FromNumber.
     * @member {string} FromNumber
     * @memberof RequestGetMyMessages
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * RequestGetMyMessages FromBridgeNumber.
     * @member {string} FromBridgeNumber
     * @memberof RequestGetMyMessages
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * RequestGetMyMessages StartingFrom.
     * @member {DateTime} StartingFrom
     * @memberof RequestGetMyMessages
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * Encodes the specified RequestGetMyMessages message. Does not implicitly {@link RequestGetMyMessages.verify|verify} messages.
     * @function encode
     * @memberof RequestGetMyMessages
     * @static
     * @param {RequestGetMyMessages} message RequestGetMyMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetMyMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.OnlyNew);
        if (message.FromNumber != null && Object.hasOwnProperty.call(message, "FromNumber"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.FromNumber);
        if (message.FromBridgeNumber != null && Object.hasOwnProperty.call(message, "FromBridgeNumber"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.FromBridgeNumber);
        if (message.StartingFrom != null && Object.hasOwnProperty.call(message, "StartingFrom"))
            $root.DateTime.encode(message.StartingFrom, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestGetMyMessages message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetMyMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetMyMessages} RequestGetMyMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetMyMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetMyMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.OnlyNew = reader.bool();
                    break;
                }
            case 2: {
                    message.FromNumber = reader.string();
                    break;
                }
            case 3: {
                    message.FromBridgeNumber = reader.string();
                    break;
                }
            case 4: {
                    message.StartingFrom = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetMyMessages message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetMyMessages
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetMyMessages.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 111, GetMyMessages : this});
    };

    /**
     * RequestGetMyMessages typeId
     * @member {number} typeId
     * @memberof RequestGetMyMessages
     * @instance
     */
    RequestGetMyMessages.prototype.typeId = 111;
    /**
     * RequestGetMyMessages typeName
     * @member {string} typeName
     * @memberof RequestGetMyMessages
     * @instance
     */
    RequestGetMyMessages.prototype.typeName = "GetMyMessages";

    return RequestGetMyMessages;
})();

export const RequestGetMyLastMessages = $root.RequestGetMyLastMessages = (() => {

    /**
     * Properties of a RequestGetMyLastMessages.
     * @exports IRequestGetMyLastMessages
     * @property {string} [Party] RequestGetMyLastMessages Party
     * @property {number} [Count] RequestGetMyLastMessages Count
     * @property {number} [StartFromId] RequestGetMyLastMessages StartFromId
     * @property {number} [IdConversation] RequestGetMyLastMessages IdConversation
     */

    /**
     * Constructs a new RequestGetMyLastMessages.
     * @exports RequestGetMyLastMessages
     * @classdesc Represents a RequestGetMyLastMessages.
     * @constructor
     * @param {Partial<RequestGetMyLastMessages>} [properties] Properties to set
     */
    function RequestGetMyLastMessages(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetMyLastMessages Party.
     * @member {string} Party
     * @memberof RequestGetMyLastMessages
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * RequestGetMyLastMessages Count.
     * @member {number} Count
     * @memberof RequestGetMyLastMessages
     * @instance
     */

    /**
     * RequestGetMyLastMessages StartFromId.
     * @member {number} StartFromId
     * @memberof RequestGetMyLastMessages
     * @instance
     */

    /**
     * RequestGetMyLastMessages IdConversation.
     * @member {number} IdConversation
     * @memberof RequestGetMyLastMessages
     * @instance
     */

    /**
     * Encodes the specified RequestGetMyLastMessages message. Does not implicitly {@link RequestGetMyLastMessages.verify|verify} messages.
     * @function encode
     * @memberof RequestGetMyLastMessages
     * @static
     * @param {RequestGetMyLastMessages} message RequestGetMyLastMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetMyLastMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Party != null && Object.hasOwnProperty.call(message, "Party"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Party);
        if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Count);
        if (message.StartFromId != null && Object.hasOwnProperty.call(message, "StartFromId"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.StartFromId);
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.IdConversation);
        return writer;
    };

    /**
     * Decodes a RequestGetMyLastMessages message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetMyLastMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetMyLastMessages} RequestGetMyLastMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetMyLastMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetMyLastMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Party = reader.string();
                    break;
                }
            case 2: {
                    message.Count = reader.int32();
                    break;
                }
            case 3: {
                    message.StartFromId = reader.int32();
                    break;
                }
            case 4: {
                    message.IdConversation = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetMyLastMessages message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetMyLastMessages
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetMyLastMessages.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 162, GetMyLastMessages : this});
    };

    /**
     * RequestGetMyLastMessages typeId
     * @member {number} typeId
     * @memberof RequestGetMyLastMessages
     * @instance
     */
    RequestGetMyLastMessages.prototype.typeId = 162;
    /**
     * RequestGetMyLastMessages typeName
     * @member {string} typeName
     * @memberof RequestGetMyLastMessages
     * @instance
     */
    RequestGetMyLastMessages.prototype.typeName = "GetMyLastMessages";

    return RequestGetMyLastMessages;
})();

export const RequestGetMessages = $root.RequestGetMessages = (() => {

    /**
     * Properties of a RequestGetMessages.
     * @exports IRequestGetMessages
     * @property {Array.<number>} [Ids] RequestGetMessages Ids
     */

    /**
     * Constructs a new RequestGetMessages.
     * @exports RequestGetMessages
     * @classdesc Represents a RequestGetMessages.
     * @constructor
     * @param {Partial<RequestGetMessages>} [properties] Properties to set
     */
    function RequestGetMessages(properties) {
        this.Ids = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetMessages Ids.
     * @member {Array.<number>} Ids
     * @memberof RequestGetMessages
     * @instance
     */
    RequestGetMessages.prototype.Ids = $util.emptyArray;

    /**
     * Encodes the specified RequestGetMessages message. Does not implicitly {@link RequestGetMessages.verify|verify} messages.
     * @function encode
     * @memberof RequestGetMessages
     * @static
     * @param {RequestGetMessages} message RequestGetMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Ids != null && message.Ids.length)
            for (let i = 0; i < message.Ids.length; ++i)
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Ids[i]);
        return writer;
    };

    /**
     * Decodes a RequestGetMessages message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetMessages} RequestGetMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Ids && message.Ids.length))
                        message.Ids = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Ids.push(reader.int32());
                    } else
                        message.Ids.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetMessages message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetMessages
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetMessages.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 526, GetMessagesByIds : this});
    };

    /**
     * RequestGetMessages typeId
     * @member {number} typeId
     * @memberof RequestGetMessages
     * @instance
     */
    RequestGetMessages.prototype.typeId = 526;
    /**
     * RequestGetMessages typeName
     * @member {string} typeName
     * @memberof RequestGetMessages
     * @instance
     */
    RequestGetMessages.prototype.typeName = "GetMessagesByIds";

    return RequestGetMessages;
})();

export const RequestGetQMLastMessages = $root.RequestGetQMLastMessages = (() => {

    /**
     * Properties of a RequestGetQMLastMessages.
     * @exports IRequestGetQMLastMessages
     * @property {number} [IdConversation] RequestGetQMLastMessages IdConversation
     * @property {number} [Count] RequestGetQMLastMessages Count
     * @property {number} [StartFromId] RequestGetQMLastMessages StartFromId
     */

    /**
     * Constructs a new RequestGetQMLastMessages.
     * @exports RequestGetQMLastMessages
     * @classdesc Represents a RequestGetQMLastMessages.
     * @constructor
     * @param {Partial<RequestGetQMLastMessages>} [properties] Properties to set
     */
    function RequestGetQMLastMessages(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetQMLastMessages IdConversation.
     * @member {number} IdConversation
     * @memberof RequestGetQMLastMessages
     * @instance
     */

    /**
     * RequestGetQMLastMessages Count.
     * @member {number} Count
     * @memberof RequestGetQMLastMessages
     * @instance
     */

    /**
     * RequestGetQMLastMessages StartFromId.
     * @member {number} StartFromId
     * @memberof RequestGetQMLastMessages
     * @instance
     */

    /**
     * Encodes the specified RequestGetQMLastMessages message. Does not implicitly {@link RequestGetQMLastMessages.verify|verify} messages.
     * @function encode
     * @memberof RequestGetQMLastMessages
     * @static
     * @param {RequestGetQMLastMessages} message RequestGetQMLastMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetQMLastMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Count);
        if (message.StartFromId != null && Object.hasOwnProperty.call(message, "StartFromId"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.StartFromId);
        return writer;
    };

    /**
     * Decodes a RequestGetQMLastMessages message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetQMLastMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetQMLastMessages} RequestGetQMLastMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetQMLastMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetQMLastMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.Count = reader.int32();
                    break;
                }
            case 3: {
                    message.StartFromId = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetQMLastMessages message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetQMLastMessages
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetQMLastMessages.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 502, GetQmLastMessages : this});
    };

    /**
     * RequestGetQMLastMessages typeId
     * @member {number} typeId
     * @memberof RequestGetQMLastMessages
     * @instance
     */
    RequestGetQMLastMessages.prototype.typeId = 502;
    /**
     * RequestGetQMLastMessages typeName
     * @member {string} typeName
     * @memberof RequestGetQMLastMessages
     * @instance
     */
    RequestGetQMLastMessages.prototype.typeName = "GetQmLastMessages";

    return RequestGetQMLastMessages;
})();

export const ResponseMyMessages = $root.ResponseMyMessages = (() => {

    /**
     * Properties of a ResponseMyMessages.
     * @exports IResponseMyMessages
     * @property {Array.<ChatMessage>} [Messages] ResponseMyMessages Messages
     */

    /**
     * Constructs a new ResponseMyMessages.
     * @exports ResponseMyMessages
     * @classdesc Represents a ResponseMyMessages.
     * @constructor
     * @param {Partial<ResponseMyMessages>} [properties] Properties to set
     */
    function ResponseMyMessages(properties) {
        this.Messages = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseMyMessages Messages.
     * @member {Array.<ChatMessage>} Messages
     * @memberof ResponseMyMessages
     * @instance
     */
    ResponseMyMessages.prototype.Messages = $util.emptyArray;

    /**
     * Encodes the specified ResponseMyMessages message. Does not implicitly {@link ResponseMyMessages.verify|verify} messages.
     * @function encode
     * @memberof ResponseMyMessages
     * @static
     * @param {ResponseMyMessages} message ResponseMyMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseMyMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Messages != null && message.Messages.length)
            for (let i = 0; i < message.Messages.length; ++i)
                $root.ChatMessage.encode(message.Messages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseMyMessages message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseMyMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseMyMessages} ResponseMyMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseMyMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseMyMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Messages && message.Messages.length))
                        message.Messages = [];
                    message.Messages.push($root.ChatMessage.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseMyMessages message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseMyMessages
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseMyMessages.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 209, MyChatMessages : this});
    };

    /**
     * ResponseMyMessages typeId
     * @member {number} typeId
     * @memberof ResponseMyMessages
     * @instance
     */
    ResponseMyMessages.prototype.typeId = 209;
    /**
     * ResponseMyMessages typeName
     * @member {string} typeName
     * @memberof ResponseMyMessages
     * @instance
     */
    ResponseMyMessages.prototype.typeName = "MyChatMessages";

    return ResponseMyMessages;
})();

export const RequestSetChatReceived = $root.RequestSetChatReceived = (() => {

    /**
     * Properties of a RequestSetChatReceived.
     * @exports IRequestSetChatReceived
     * @property {Array.<number>} [Items] RequestSetChatReceived Items
     */

    /**
     * Constructs a new RequestSetChatReceived.
     * @exports RequestSetChatReceived
     * @classdesc Represents a RequestSetChatReceived.
     * @constructor
     * @param {Partial<RequestSetChatReceived>} [properties] Properties to set
     */
    function RequestSetChatReceived(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetChatReceived Items.
     * @member {Array.<number>} Items
     * @memberof RequestSetChatReceived
     * @instance
     */
    RequestSetChatReceived.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified RequestSetChatReceived message. Does not implicitly {@link RequestSetChatReceived.verify|verify} messages.
     * @function encode
     * @memberof RequestSetChatReceived
     * @static
     * @param {RequestSetChatReceived} message RequestSetChatReceived message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetChatReceived.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Items[i]);
        return writer;
    };

    /**
     * Decodes a RequestSetChatReceived message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetChatReceived
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetChatReceived} RequestSetChatReceived
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetChatReceived.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetChatReceived();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.Items.push(reader.int32());
                    } else
                        message.Items.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetChatReceived message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetChatReceived
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetChatReceived.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 112, MessagesReceived : this});
    };

    /**
     * RequestSetChatReceived typeId
     * @member {number} typeId
     * @memberof RequestSetChatReceived
     * @instance
     */
    RequestSetChatReceived.prototype.typeId = 112;
    /**
     * RequestSetChatReceived typeName
     * @member {string} typeName
     * @memberof RequestSetChatReceived
     * @instance
     */
    RequestSetChatReceived.prototype.typeName = "MessagesReceived";

    return RequestSetChatReceived;
})();

export const RequestMyChatParties = $root.RequestMyChatParties = (() => {

    /**
     * Properties of a RequestMyChatParties.
     * @exports IRequestMyChatParties
     * @property {number} [From] RequestMyChatParties From
     * @property {number} [Count] RequestMyChatParties Count
     * @property {boolean} [FillParticipants] RequestMyChatParties FillParticipants
     * @property {boolean} [IsArchived] RequestMyChatParties IsArchived
     * @property {string} [SearchString] RequestMyChatParties SearchString
     * @property {boolean} [IsActive] RequestMyChatParties IsActive
     */

    /**
     * Constructs a new RequestMyChatParties.
     * @exports RequestMyChatParties
     * @classdesc Represents a RequestMyChatParties.
     * @constructor
     * @param {Partial<RequestMyChatParties>} [properties] Properties to set
     */
    function RequestMyChatParties(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestMyChatParties From.
     * @member {number} From
     * @memberof RequestMyChatParties
     * @instance
     */

    /**
     * RequestMyChatParties Count.
     * @member {number} Count
     * @memberof RequestMyChatParties
     * @instance
     */

    /**
     * RequestMyChatParties FillParticipants.
     * @member {boolean} FillParticipants
     * @memberof RequestMyChatParties
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * RequestMyChatParties IsArchived.
     * @member {boolean} IsArchived
     * @memberof RequestMyChatParties
     * @instance
     */

    /**
     * RequestMyChatParties SearchString.
     * @member {string} SearchString
     * @memberof RequestMyChatParties
     * @instance
     */

    /**
     * RequestMyChatParties IsActive.
     * @member {boolean} IsActive
     * @memberof RequestMyChatParties
     * @instance
     */

    /**
     * Encodes the specified RequestMyChatParties message. Does not implicitly {@link RequestMyChatParties.verify|verify} messages.
     * @function encode
     * @memberof RequestMyChatParties
     * @static
     * @param {RequestMyChatParties} message RequestMyChatParties message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestMyChatParties.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.From != null && Object.hasOwnProperty.call(message, "From"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.From);
        if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Count);
        if (message.FillParticipants != null && Object.hasOwnProperty.call(message, "FillParticipants"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.FillParticipants);
        if (message.IsArchived != null && Object.hasOwnProperty.call(message, "IsArchived"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.IsArchived);
        if (message.SearchString != null && Object.hasOwnProperty.call(message, "SearchString"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.SearchString);
        if (message.IsActive != null && Object.hasOwnProperty.call(message, "IsActive"))
            writer.uint32(/* id 6, wireType 0 =*/48).bool(message.IsActive);
        return writer;
    };

    /**
     * Decodes a RequestMyChatParties message from the specified reader or buffer.
     * @function decode
     * @memberof RequestMyChatParties
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestMyChatParties} RequestMyChatParties
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestMyChatParties.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestMyChatParties();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.From = reader.int32();
                    break;
                }
            case 2: {
                    message.Count = reader.int32();
                    break;
                }
            case 3: {
                    message.FillParticipants = reader.bool();
                    break;
                }
            case 4: {
                    message.IsArchived = reader.bool();
                    break;
                }
            case 5: {
                    message.SearchString = reader.string();
                    break;
                }
            case 6: {
                    message.IsActive = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestMyChatParties message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestMyChatParties
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestMyChatParties.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 138, GetMyChatParties : this});
    };

    /**
     * RequestMyChatParties typeId
     * @member {number} typeId
     * @memberof RequestMyChatParties
     * @instance
     */
    RequestMyChatParties.prototype.typeId = 138;
    /**
     * RequestMyChatParties typeName
     * @member {string} typeName
     * @memberof RequestMyChatParties
     * @instance
     */
    RequestMyChatParties.prototype.typeName = "GetMyChatParties";

    return RequestMyChatParties;
})();

export const ResponseMyChatParties = $root.ResponseMyChatParties = (() => {

    /**
     * Properties of a ResponseMyChatParties.
     * @exports IResponseMyChatParties
     * @property {Array.<string>} [Items] ResponseMyChatParties Items
     * @property {Array.<ChatMessage>} [LastMessages] ResponseMyChatParties LastMessages
     * @property {Array.<ChatPartyInfo>} [Participants] ResponseMyChatParties Participants
     */

    /**
     * Constructs a new ResponseMyChatParties.
     * @exports ResponseMyChatParties
     * @classdesc Represents a ResponseMyChatParties.
     * @constructor
     * @param {Partial<ResponseMyChatParties>} [properties] Properties to set
     */
    function ResponseMyChatParties(properties) {
        this.Items = [];
        this.LastMessages = [];
        this.Participants = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseMyChatParties Items.
     * @member {Array.<string>} Items
     * @memberof ResponseMyChatParties
     * @instance
     * @deprecated Avoid using it
     */
    ResponseMyChatParties.prototype.Items = $util.emptyArray;

    /**
     * ResponseMyChatParties LastMessages.
     * @member {Array.<ChatMessage>} LastMessages
     * @memberof ResponseMyChatParties
     * @instance
     */
    ResponseMyChatParties.prototype.LastMessages = $util.emptyArray;

    /**
     * ResponseMyChatParties Participants.
     * @member {Array.<ChatPartyInfo>} Participants
     * @memberof ResponseMyChatParties
     * @instance
     */
    ResponseMyChatParties.prototype.Participants = $util.emptyArray;

    /**
     * Encodes the specified ResponseMyChatParties message. Does not implicitly {@link ResponseMyChatParties.verify|verify} messages.
     * @function encode
     * @memberof ResponseMyChatParties
     * @static
     * @param {ResponseMyChatParties} message ResponseMyChatParties message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseMyChatParties.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Items[i]);
        if (message.LastMessages != null && message.LastMessages.length)
            for (let i = 0; i < message.LastMessages.length; ++i)
                $root.ChatMessage.encode(message.LastMessages[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.Participants != null && message.Participants.length)
            for (let i = 0; i < message.Participants.length; ++i)
                $root.ChatPartyInfo.encode(message.Participants[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseMyChatParties message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseMyChatParties
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseMyChatParties} ResponseMyChatParties
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseMyChatParties.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseMyChatParties();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push(reader.string());
                    break;
                }
            case 2: {
                    if (!(message.LastMessages && message.LastMessages.length))
                        message.LastMessages = [];
                    message.LastMessages.push($root.ChatMessage.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    if (!(message.Participants && message.Participants.length))
                        message.Participants = [];
                    message.Participants.push($root.ChatPartyInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseMyChatParties message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseMyChatParties
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseMyChatParties.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 220, MyChatParties : this});
    };

    /**
     * ResponseMyChatParties typeId
     * @member {number} typeId
     * @memberof ResponseMyChatParties
     * @instance
     */
    ResponseMyChatParties.prototype.typeId = 220;
    /**
     * ResponseMyChatParties typeName
     * @member {string} typeName
     * @memberof ResponseMyChatParties
     * @instance
     */
    ResponseMyChatParties.prototype.typeName = "MyChatParties";

    return ResponseMyChatParties;
})();

export const ChatPartyInfo = $root.ChatPartyInfo = (() => {

    /**
     * Properties of a ChatPartyInfo.
     * @exports IChatPartyInfo
     * @property {string} [Party] ChatPartyInfo Party
     * @property {Array.<ChatRecipientEx>} [Recipients] ChatPartyInfo Recipients
     * @property {boolean} [IsExternal] ChatPartyInfo IsExternal
     * @property {number} [IdConversation] ChatPartyInfo IdConversation
     * @property {boolean} [IsArchived] ChatPartyInfo IsArchived
     * @property {string} [PrivateName] ChatPartyInfo PrivateName
     * @property {string} [QueueNo] ChatPartyInfo QueueNo
     * @property {string} [QueueName] ChatPartyInfo QueueName
     * @property {string} [PublicName] ChatPartyInfo PublicName
     * @property {ChatRecipient} [TakenBy] ChatPartyInfo TakenBy
     * @property {number} [NumberOfMessages] ChatPartyInfo NumberOfMessages
     * @property {ExternalChatCloseReason} [CloseReason] ChatPartyInfo CloseReason
     * @property {string} [CnvGid] ChatPartyInfo CnvGid
     */

    /**
     * Constructs a new ChatPartyInfo.
     * @exports ChatPartyInfo
     * @classdesc Represents a ChatPartyInfo.
     * @constructor
     * @param {Partial<ChatPartyInfo>} [properties] Properties to set
     */
    function ChatPartyInfo(properties) {
        this.Recipients = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatPartyInfo Party.
     * @member {string} Party
     * @memberof ChatPartyInfo
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatPartyInfo Recipients.
     * @member {Array.<ChatRecipientEx>} Recipients
     * @memberof ChatPartyInfo
     * @instance
     */
    ChatPartyInfo.prototype.Recipients = $util.emptyArray;

    /**
     * ChatPartyInfo IsExternal.
     * @member {boolean} IsExternal
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * ChatPartyInfo IdConversation.
     * @member {number} IdConversation
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * ChatPartyInfo IsArchived.
     * @member {boolean} IsArchived
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * ChatPartyInfo PrivateName.
     * @member {string} PrivateName
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * ChatPartyInfo QueueNo.
     * @member {string} QueueNo
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * ChatPartyInfo QueueName.
     * @member {string} QueueName
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * ChatPartyInfo PublicName.
     * @member {string} PublicName
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * ChatPartyInfo TakenBy.
     * @member {ChatRecipient} TakenBy
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * ChatPartyInfo NumberOfMessages.
     * @member {number} NumberOfMessages
     * @memberof ChatPartyInfo
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatPartyInfo CloseReason.
     * @member {ExternalChatCloseReason} CloseReason
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * ChatPartyInfo CnvGid.
     * @member {string} CnvGid
     * @memberof ChatPartyInfo
     * @instance
     */

    /**
     * Encodes the specified ChatPartyInfo message. Does not implicitly {@link ChatPartyInfo.verify|verify} messages.
     * @function encode
     * @memberof ChatPartyInfo
     * @static
     * @param {ChatPartyInfo} message ChatPartyInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatPartyInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Party != null && Object.hasOwnProperty.call(message, "Party"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Party);
        if (message.Recipients != null && message.Recipients.length)
            for (let i = 0; i < message.Recipients.length; ++i)
                $root.ChatRecipientEx.encode(message.Recipients[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.IsExternal != null && Object.hasOwnProperty.call(message, "IsExternal"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.IsExternal);
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.IdConversation);
        if (message.IsArchived != null && Object.hasOwnProperty.call(message, "IsArchived"))
            writer.uint32(/* id 5, wireType 0 =*/40).bool(message.IsArchived);
        if (message.PrivateName != null && Object.hasOwnProperty.call(message, "PrivateName"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.PrivateName);
        if (message.QueueNo != null && Object.hasOwnProperty.call(message, "QueueNo"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.QueueNo);
        if (message.QueueName != null && Object.hasOwnProperty.call(message, "QueueName"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.QueueName);
        if (message.PublicName != null && Object.hasOwnProperty.call(message, "PublicName"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.PublicName);
        if (message.TakenBy != null && Object.hasOwnProperty.call(message, "TakenBy"))
            $root.ChatRecipient.encode(message.TakenBy, writer.uint32(/* id 10, wireType 2 =*/82).fork()).ldelim();
        if (message.NumberOfMessages != null && Object.hasOwnProperty.call(message, "NumberOfMessages"))
            writer.uint32(/* id 11, wireType 0 =*/88).int32(message.NumberOfMessages);
        if (message.CloseReason != null && Object.hasOwnProperty.call(message, "CloseReason"))
            writer.uint32(/* id 12, wireType 0 =*/96).int32(message.CloseReason);
        if (message.CnvGid != null && Object.hasOwnProperty.call(message, "CnvGid"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.CnvGid);
        return writer;
    };

    /**
     * Decodes a ChatPartyInfo message from the specified reader or buffer.
     * @function decode
     * @memberof ChatPartyInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatPartyInfo} ChatPartyInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatPartyInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatPartyInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Party = reader.string();
                    break;
                }
            case 2: {
                    if (!(message.Recipients && message.Recipients.length))
                        message.Recipients = [];
                    message.Recipients.push($root.ChatRecipientEx.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.IsExternal = reader.bool();
                    break;
                }
            case 4: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 5: {
                    message.IsArchived = reader.bool();
                    break;
                }
            case 6: {
                    message.PrivateName = reader.string();
                    break;
                }
            case 7: {
                    message.QueueNo = reader.string();
                    break;
                }
            case 8: {
                    message.QueueName = reader.string();
                    break;
                }
            case 9: {
                    message.PublicName = reader.string();
                    break;
                }
            case 10: {
                    message.TakenBy = $root.ChatRecipient.decode(reader, reader.uint32());
                    break;
                }
            case 11: {
                    message.NumberOfMessages = reader.int32();
                    break;
                }
            case 12: {
                    message.CloseReason = reader.int32();
                    break;
                }
            case 13: {
                    message.CnvGid = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatPartyInfo;
})();

export const RequestCreateConversation = $root.RequestCreateConversation = (() => {

    /**
     * Properties of a RequestCreateConversation.
     * @exports IRequestCreateConversation
     * @property {string} [Party] RequestCreateConversation Party
     * @property {string} [PublicName] RequestCreateConversation PublicName
     * @property {number} [IdConversation] RequestCreateConversation IdConversation
     * @property {string} [PrivateName] RequestCreateConversation PrivateName
     * @property {string} [UserData] RequestCreateConversation UserData
     * @property {string} [CnvGid] RequestCreateConversation CnvGid
     */

    /**
     * Constructs a new RequestCreateConversation.
     * @exports RequestCreateConversation
     * @classdesc Represents a RequestCreateConversation.
     * @constructor
     * @param {Partial<RequestCreateConversation>} [properties] Properties to set
     */
    function RequestCreateConversation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestCreateConversation Party.
     * @member {string} Party
     * @memberof RequestCreateConversation
     * @instance
     */

    /**
     * RequestCreateConversation PublicName.
     * @member {string} PublicName
     * @memberof RequestCreateConversation
     * @instance
     */

    /**
     * RequestCreateConversation IdConversation.
     * @member {number} IdConversation
     * @memberof RequestCreateConversation
     * @instance
     */

    /**
     * RequestCreateConversation PrivateName.
     * @member {string} PrivateName
     * @memberof RequestCreateConversation
     * @instance
     */

    /**
     * RequestCreateConversation UserData.
     * @member {string} UserData
     * @memberof RequestCreateConversation
     * @instance
     */

    /**
     * RequestCreateConversation CnvGid.
     * @member {string} CnvGid
     * @memberof RequestCreateConversation
     * @instance
     */

    /**
     * Encodes the specified RequestCreateConversation message. Does not implicitly {@link RequestCreateConversation.verify|verify} messages.
     * @function encode
     * @memberof RequestCreateConversation
     * @static
     * @param {RequestCreateConversation} message RequestCreateConversation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestCreateConversation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Party != null && Object.hasOwnProperty.call(message, "Party"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Party);
        if (message.PublicName != null && Object.hasOwnProperty.call(message, "PublicName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.PublicName);
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.IdConversation);
        if (message.PrivateName != null && Object.hasOwnProperty.call(message, "PrivateName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.PrivateName);
        if (message.UserData != null && Object.hasOwnProperty.call(message, "UserData"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.UserData);
        if (message.CnvGid != null && Object.hasOwnProperty.call(message, "CnvGid"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.CnvGid);
        return writer;
    };

    /**
     * Decodes a RequestCreateConversation message from the specified reader or buffer.
     * @function decode
     * @memberof RequestCreateConversation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestCreateConversation} RequestCreateConversation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestCreateConversation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestCreateConversation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Party = reader.string();
                    break;
                }
            case 2: {
                    message.PublicName = reader.string();
                    break;
                }
            case 3: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 4: {
                    message.PrivateName = reader.string();
                    break;
                }
            case 5: {
                    message.UserData = reader.string();
                    break;
                }
            case 6: {
                    message.CnvGid = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestCreateConversation message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestCreateConversation
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestCreateConversation.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 189, CreateConversation : this});
    };

    /**
     * RequestCreateConversation typeId
     * @member {number} typeId
     * @memberof RequestCreateConversation
     * @instance
     */
    RequestCreateConversation.prototype.typeId = 189;
    /**
     * RequestCreateConversation typeName
     * @member {string} typeName
     * @memberof RequestCreateConversation
     * @instance
     */
    RequestCreateConversation.prototype.typeName = "CreateConversation";

    return RequestCreateConversation;
})();

export const ResponseConversationInfo = $root.ResponseConversationInfo = (() => {

    /**
     * Properties of a ResponseConversationInfo.
     * @exports IResponseConversationInfo
     * @property {ChatPartyInfo} [Conversation] ResponseConversationInfo Conversation
     * @property {boolean} [IsNew] ResponseConversationInfo IsNew
     */

    /**
     * Constructs a new ResponseConversationInfo.
     * @exports ResponseConversationInfo
     * @classdesc Represents a ResponseConversationInfo.
     * @constructor
     * @param {Partial<ResponseConversationInfo>} [properties] Properties to set
     */
    function ResponseConversationInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseConversationInfo Conversation.
     * @member {ChatPartyInfo} Conversation
     * @memberof ResponseConversationInfo
     * @instance
     */

    /**
     * ResponseConversationInfo IsNew.
     * @member {boolean} IsNew
     * @memberof ResponseConversationInfo
     * @instance
     */

    /**
     * Encodes the specified ResponseConversationInfo message. Does not implicitly {@link ResponseConversationInfo.verify|verify} messages.
     * @function encode
     * @memberof ResponseConversationInfo
     * @static
     * @param {ResponseConversationInfo} message ResponseConversationInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseConversationInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Conversation != null && Object.hasOwnProperty.call(message, "Conversation"))
            $root.ChatPartyInfo.encode(message.Conversation, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.IsNew != null && Object.hasOwnProperty.call(message, "IsNew"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsNew);
        return writer;
    };

    /**
     * Decodes a ResponseConversationInfo message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseConversationInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseConversationInfo} ResponseConversationInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseConversationInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseConversationInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Conversation = $root.ChatPartyInfo.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.IsNew = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseConversationInfo message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseConversationInfo
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseConversationInfo.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 236, ConversationInfo : this});
    };

    /**
     * ResponseConversationInfo typeId
     * @member {number} typeId
     * @memberof ResponseConversationInfo
     * @instance
     */
    ResponseConversationInfo.prototype.typeId = 236;
    /**
     * ResponseConversationInfo typeName
     * @member {string} typeName
     * @memberof ResponseConversationInfo
     * @instance
     */
    ResponseConversationInfo.prototype.typeName = "ConversationInfo";

    return ResponseConversationInfo;
})();

export const RequestDeleteChat = $root.RequestDeleteChat = (() => {

    /**
     * Properties of a RequestDeleteChat.
     * @exports IRequestDeleteChat
     * @property {Array.<string>} [Items] RequestDeleteChat Items
     * @property {Array.<number>} [ConversationIds] RequestDeleteChat ConversationIds
     */

    /**
     * Constructs a new RequestDeleteChat.
     * @exports RequestDeleteChat
     * @classdesc Represents a RequestDeleteChat.
     * @constructor
     * @param {Partial<RequestDeleteChat>} [properties] Properties to set
     */
    function RequestDeleteChat(properties) {
        this.Items = [];
        this.ConversationIds = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestDeleteChat Items.
     * @member {Array.<string>} Items
     * @memberof RequestDeleteChat
     * @instance
     * @deprecated Avoid using it
     */
    RequestDeleteChat.prototype.Items = $util.emptyArray;

    /**
     * RequestDeleteChat ConversationIds.
     * @member {Array.<number>} ConversationIds
     * @memberof RequestDeleteChat
     * @instance
     */
    RequestDeleteChat.prototype.ConversationIds = $util.emptyArray;

    /**
     * Encodes the specified RequestDeleteChat message. Does not implicitly {@link RequestDeleteChat.verify|verify} messages.
     * @function encode
     * @memberof RequestDeleteChat
     * @static
     * @param {RequestDeleteChat} message RequestDeleteChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestDeleteChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.Items[i]);
        if (message.ConversationIds != null && message.ConversationIds.length)
            for (let i = 0; i < message.ConversationIds.length; ++i)
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ConversationIds[i]);
        return writer;
    };

    /**
     * Decodes a RequestDeleteChat message from the specified reader or buffer.
     * @function decode
     * @memberof RequestDeleteChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestDeleteChat} RequestDeleteChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestDeleteChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestDeleteChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push(reader.string());
                    break;
                }
            case 2: {
                    if (!(message.ConversationIds && message.ConversationIds.length))
                        message.ConversationIds = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.ConversationIds.push(reader.int32());
                    } else
                        message.ConversationIds.push(reader.int32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestDeleteChat message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestDeleteChat
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestDeleteChat.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 139, DeleteChat : this});
    };

    /**
     * RequestDeleteChat typeId
     * @member {number} typeId
     * @memberof RequestDeleteChat
     * @instance
     */
    RequestDeleteChat.prototype.typeId = 139;
    /**
     * RequestDeleteChat typeName
     * @member {string} typeName
     * @memberof RequestDeleteChat
     * @instance
     */
    RequestDeleteChat.prototype.typeName = "DeleteChat";

    return RequestDeleteChat;
})();

export const RequestUpdateConversation = $root.RequestUpdateConversation = (() => {

    /**
     * Properties of a RequestUpdateConversation.
     * @exports IRequestUpdateConversation
     * @property {number} IdConversation RequestUpdateConversation IdConversation
     * @property {boolean} [IsArchived] RequestUpdateConversation IsArchived
     * @property {string} [PrivateName] RequestUpdateConversation PrivateName
     * @property {string} [PublicName] RequestUpdateConversation PublicName
     * @property {Array.<ChatRecipientEx>} [ParticipantUpdates] RequestUpdateConversation ParticipantUpdates
     */

    /**
     * Constructs a new RequestUpdateConversation.
     * @exports RequestUpdateConversation
     * @classdesc Represents a RequestUpdateConversation.
     * @constructor
     * @param {Partial<RequestUpdateConversation>} [properties] Properties to set
     */
    function RequestUpdateConversation(properties) {
        this.ParticipantUpdates = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateConversation IdConversation.
     * @member {number} IdConversation
     * @memberof RequestUpdateConversation
     * @instance
     */

    /**
     * RequestUpdateConversation IsArchived.
     * @member {boolean} IsArchived
     * @memberof RequestUpdateConversation
     * @instance
     */

    /**
     * RequestUpdateConversation PrivateName.
     * @member {string} PrivateName
     * @memberof RequestUpdateConversation
     * @instance
     */

    /**
     * RequestUpdateConversation PublicName.
     * @member {string} PublicName
     * @memberof RequestUpdateConversation
     * @instance
     */

    /**
     * RequestUpdateConversation ParticipantUpdates.
     * @member {Array.<ChatRecipientEx>} ParticipantUpdates
     * @memberof RequestUpdateConversation
     * @instance
     */
    RequestUpdateConversation.prototype.ParticipantUpdates = $util.emptyArray;

    /**
     * Encodes the specified RequestUpdateConversation message. Does not implicitly {@link RequestUpdateConversation.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateConversation
     * @static
     * @param {RequestUpdateConversation} message RequestUpdateConversation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateConversation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.IsArchived != null && Object.hasOwnProperty.call(message, "IsArchived"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsArchived);
        if (message.PrivateName != null && Object.hasOwnProperty.call(message, "PrivateName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.PrivateName);
        if (message.PublicName != null && Object.hasOwnProperty.call(message, "PublicName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.PublicName);
        if (message.ParticipantUpdates != null && message.ParticipantUpdates.length)
            for (let i = 0; i < message.ParticipantUpdates.length; ++i)
                $root.ChatRecipientEx.encode(message.ParticipantUpdates[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestUpdateConversation message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateConversation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateConversation} RequestUpdateConversation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateConversation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateConversation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.IsArchived = reader.bool();
                    break;
                }
            case 3: {
                    message.PrivateName = reader.string();
                    break;
                }
            case 4: {
                    message.PublicName = reader.string();
                    break;
                }
            case 5: {
                    if (!(message.ParticipantUpdates && message.ParticipantUpdates.length))
                        message.ParticipantUpdates = [];
                    message.ParticipantUpdates.push($root.ChatRecipientEx.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateConversation message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateConversation
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateConversation.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 186, UpdateConversation : this});
    };

    /**
     * RequestUpdateConversation typeId
     * @member {number} typeId
     * @memberof RequestUpdateConversation
     * @instance
     */
    RequestUpdateConversation.prototype.typeId = 186;
    /**
     * RequestUpdateConversation typeName
     * @member {string} typeName
     * @memberof RequestUpdateConversation
     * @instance
     */
    RequestUpdateConversation.prototype.typeName = "UpdateConversation";

    return RequestUpdateConversation;
})();

export const RequestGetConversations = $root.RequestGetConversations = (() => {

    /**
     * Properties of a RequestGetConversations.
     * @exports IRequestGetConversations
     * @property {Array.<string>} [CnvGids] RequestGetConversations CnvGids
     */

    /**
     * Constructs a new RequestGetConversations.
     * @exports RequestGetConversations
     * @classdesc Represents a RequestGetConversations.
     * @constructor
     * @param {Partial<RequestGetConversations>} [properties] Properties to set
     */
    function RequestGetConversations(properties) {
        this.CnvGids = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetConversations CnvGids.
     * @member {Array.<string>} CnvGids
     * @memberof RequestGetConversations
     * @instance
     */
    RequestGetConversations.prototype.CnvGids = $util.emptyArray;

    /**
     * Encodes the specified RequestGetConversations message. Does not implicitly {@link RequestGetConversations.verify|verify} messages.
     * @function encode
     * @memberof RequestGetConversations
     * @static
     * @param {RequestGetConversations} message RequestGetConversations message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetConversations.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.CnvGids != null && message.CnvGids.length)
            for (let i = 0; i < message.CnvGids.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.CnvGids[i]);
        return writer;
    };

    /**
     * Decodes a RequestGetConversations message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetConversations
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetConversations} RequestGetConversations
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetConversations.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetConversations();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.CnvGids && message.CnvGids.length))
                        message.CnvGids = [];
                    message.CnvGids.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetConversations message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetConversations
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetConversations.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 510, GetConversations : this});
    };

    /**
     * RequestGetConversations typeId
     * @member {number} typeId
     * @memberof RequestGetConversations
     * @instance
     */
    RequestGetConversations.prototype.typeId = 510;
    /**
     * RequestGetConversations typeName
     * @member {string} typeName
     * @memberof RequestGetConversations
     * @instance
     */
    RequestGetConversations.prototype.typeName = "GetConversations";

    return RequestGetConversations;
})();

export const ResponseGetConversations = $root.ResponseGetConversations = (() => {

    /**
     * Properties of a ResponseGetConversations.
     * @exports IResponseGetConversations
     * @property {Array.<ChatPartyInfo>} [Conversations] ResponseGetConversations Conversations
     */

    /**
     * Constructs a new ResponseGetConversations.
     * @exports ResponseGetConversations
     * @classdesc Represents a ResponseGetConversations.
     * @constructor
     * @param {Partial<ResponseGetConversations>} [properties] Properties to set
     */
    function ResponseGetConversations(properties) {
        this.Conversations = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseGetConversations Conversations.
     * @member {Array.<ChatPartyInfo>} Conversations
     * @memberof ResponseGetConversations
     * @instance
     */
    ResponseGetConversations.prototype.Conversations = $util.emptyArray;

    /**
     * Encodes the specified ResponseGetConversations message. Does not implicitly {@link ResponseGetConversations.verify|verify} messages.
     * @function encode
     * @memberof ResponseGetConversations
     * @static
     * @param {ResponseGetConversations} message ResponseGetConversations message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGetConversations.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Conversations != null && message.Conversations.length)
            for (let i = 0; i < message.Conversations.length; ++i)
                $root.ChatPartyInfo.encode(message.Conversations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseGetConversations message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseGetConversations
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseGetConversations} ResponseGetConversations
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGetConversations.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseGetConversations();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Conversations && message.Conversations.length))
                        message.Conversations = [];
                    message.Conversations.push($root.ChatPartyInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseGetConversations message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseGetConversations
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseGetConversations.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 249, ResponseConversations : this});
    };

    /**
     * ResponseGetConversations typeId
     * @member {number} typeId
     * @memberof ResponseGetConversations
     * @instance
     */
    ResponseGetConversations.prototype.typeId = 249;
    /**
     * ResponseGetConversations typeName
     * @member {string} typeName
     * @memberof ResponseGetConversations
     * @instance
     */
    ResponseGetConversations.prototype.typeName = "ResponseConversations";

    return ResponseGetConversations;
})();

export const RequestTransferChatTo = $root.RequestTransferChatTo = (() => {

    /**
     * Properties of a RequestTransferChatTo.
     * @exports IRequestTransferChatTo
     * @property {number} IdConversation RequestTransferChatTo IdConversation
     * @property {ChatRecipient} [Participant] RequestTransferChatTo Participant
     */

    /**
     * Constructs a new RequestTransferChatTo.
     * @exports RequestTransferChatTo
     * @classdesc Represents a RequestTransferChatTo.
     * @constructor
     * @param {Partial<RequestTransferChatTo>} [properties] Properties to set
     */
    function RequestTransferChatTo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestTransferChatTo IdConversation.
     * @member {number} IdConversation
     * @memberof RequestTransferChatTo
     * @instance
     */

    /**
     * RequestTransferChatTo Participant.
     * @member {ChatRecipient} Participant
     * @memberof RequestTransferChatTo
     * @instance
     */

    /**
     * Encodes the specified RequestTransferChatTo message. Does not implicitly {@link RequestTransferChatTo.verify|verify} messages.
     * @function encode
     * @memberof RequestTransferChatTo
     * @static
     * @param {RequestTransferChatTo} message RequestTransferChatTo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestTransferChatTo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.Participant != null && Object.hasOwnProperty.call(message, "Participant"))
            $root.ChatRecipient.encode(message.Participant, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestTransferChatTo message from the specified reader or buffer.
     * @function decode
     * @memberof RequestTransferChatTo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestTransferChatTo} RequestTransferChatTo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestTransferChatTo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestTransferChatTo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.Participant = $root.ChatRecipient.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestTransferChatTo message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestTransferChatTo
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestTransferChatTo.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 187, TransferChatTo : this});
    };

    /**
     * RequestTransferChatTo typeId
     * @member {number} typeId
     * @memberof RequestTransferChatTo
     * @instance
     */
    RequestTransferChatTo.prototype.typeId = 187;
    /**
     * RequestTransferChatTo typeName
     * @member {string} typeName
     * @memberof RequestTransferChatTo
     * @instance
     */
    RequestTransferChatTo.prototype.typeName = "TransferChatTo";

    return RequestTransferChatTo;
})();

export const NotificationChatTransferred = $root.NotificationChatTransferred = (() => {

    /**
     * Properties of a NotificationChatTransferred.
     * @exports INotificationChatTransferred
     * @property {ChatPartyInfo} PartyInfo NotificationChatTransferred PartyInfo
     * @property {ChatRecipient} [TransferredBy] NotificationChatTransferred TransferredBy
     */

    /**
     * Constructs a new NotificationChatTransferred.
     * @exports NotificationChatTransferred
     * @classdesc Represents a NotificationChatTransferred.
     * @constructor
     * @param {Partial<NotificationChatTransferred>} [properties] Properties to set
     */
    function NotificationChatTransferred(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotificationChatTransferred PartyInfo.
     * @member {ChatPartyInfo} PartyInfo
     * @memberof NotificationChatTransferred
     * @instance
     */

    /**
     * NotificationChatTransferred TransferredBy.
     * @member {ChatRecipient} TransferredBy
     * @memberof NotificationChatTransferred
     * @instance
     */

    /**
     * Encodes the specified NotificationChatTransferred message. Does not implicitly {@link NotificationChatTransferred.verify|verify} messages.
     * @function encode
     * @memberof NotificationChatTransferred
     * @static
     * @param {NotificationChatTransferred} message NotificationChatTransferred message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotificationChatTransferred.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        $root.ChatPartyInfo.encode(message.PartyInfo, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.TransferredBy != null && Object.hasOwnProperty.call(message, "TransferredBy"))
            $root.ChatRecipient.encode(message.TransferredBy, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a NotificationChatTransferred message from the specified reader or buffer.
     * @function decode
     * @memberof NotificationChatTransferred
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NotificationChatTransferred} NotificationChatTransferred
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotificationChatTransferred.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NotificationChatTransferred();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.PartyInfo = $root.ChatPartyInfo.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.TransferredBy = $root.ChatRecipient.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a NotificationChatTransferred message to GenericMessage
     * @function toGenericMessage
     * @memberof NotificationChatTransferred
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    NotificationChatTransferred.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 237, ChatTransferred : this});
    };

    /**
     * NotificationChatTransferred typeId
     * @member {number} typeId
     * @memberof NotificationChatTransferred
     * @instance
     */
    NotificationChatTransferred.prototype.typeId = 237;
    /**
     * NotificationChatTransferred typeName
     * @member {string} typeName
     * @memberof NotificationChatTransferred
     * @instance
     */
    NotificationChatTransferred.prototype.typeName = "ChatTransferred";

    return NotificationChatTransferred;
})();

export const RequestAgentTakesOverChat = $root.RequestAgentTakesOverChat = (() => {

    /**
     * Properties of a RequestAgentTakesOverChat.
     * @exports IRequestAgentTakesOverChat
     * @property {number} IdConversation RequestAgentTakesOverChat IdConversation
     */

    /**
     * Constructs a new RequestAgentTakesOverChat.
     * @exports RequestAgentTakesOverChat
     * @classdesc Represents a RequestAgentTakesOverChat.
     * @constructor
     * @param {Partial<RequestAgentTakesOverChat>} [properties] Properties to set
     */
    function RequestAgentTakesOverChat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestAgentTakesOverChat IdConversation.
     * @member {number} IdConversation
     * @memberof RequestAgentTakesOverChat
     * @instance
     */

    /**
     * Encodes the specified RequestAgentTakesOverChat message. Does not implicitly {@link RequestAgentTakesOverChat.verify|verify} messages.
     * @function encode
     * @memberof RequestAgentTakesOverChat
     * @static
     * @param {RequestAgentTakesOverChat} message RequestAgentTakesOverChat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestAgentTakesOverChat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        return writer;
    };

    /**
     * Decodes a RequestAgentTakesOverChat message from the specified reader or buffer.
     * @function decode
     * @memberof RequestAgentTakesOverChat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestAgentTakesOverChat} RequestAgentTakesOverChat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestAgentTakesOverChat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestAgentTakesOverChat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestAgentTakesOverChat message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestAgentTakesOverChat
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestAgentTakesOverChat.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 188, AgentTakesOverChat : this});
    };

    /**
     * RequestAgentTakesOverChat typeId
     * @member {number} typeId
     * @memberof RequestAgentTakesOverChat
     * @instance
     */
    RequestAgentTakesOverChat.prototype.typeId = 188;
    /**
     * RequestAgentTakesOverChat typeName
     * @member {string} typeName
     * @memberof RequestAgentTakesOverChat
     * @instance
     */
    RequestAgentTakesOverChat.prototype.typeName = "AgentTakesOverChat";

    return RequestAgentTakesOverChat;
})();

export const NotificationConversationRemoved = $root.NotificationConversationRemoved = (() => {

    /**
     * Properties of a NotificationConversationRemoved.
     * @exports INotificationConversationRemoved
     * @property {number} IdConversation NotificationConversationRemoved IdConversation
     * @property {ChatRecipient} [TakenBy] NotificationConversationRemoved TakenBy
     */

    /**
     * Constructs a new NotificationConversationRemoved.
     * @exports NotificationConversationRemoved
     * @classdesc Represents a NotificationConversationRemoved.
     * @constructor
     * @param {Partial<NotificationConversationRemoved>} [properties] Properties to set
     */
    function NotificationConversationRemoved(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotificationConversationRemoved IdConversation.
     * @member {number} IdConversation
     * @memberof NotificationConversationRemoved
     * @instance
     */

    /**
     * NotificationConversationRemoved TakenBy.
     * @member {ChatRecipient} TakenBy
     * @memberof NotificationConversationRemoved
     * @instance
     */

    /**
     * Encodes the specified NotificationConversationRemoved message. Does not implicitly {@link NotificationConversationRemoved.verify|verify} messages.
     * @function encode
     * @memberof NotificationConversationRemoved
     * @static
     * @param {NotificationConversationRemoved} message NotificationConversationRemoved message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotificationConversationRemoved.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.TakenBy != null && Object.hasOwnProperty.call(message, "TakenBy"))
            $root.ChatRecipient.encode(message.TakenBy, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a NotificationConversationRemoved message from the specified reader or buffer.
     * @function decode
     * @memberof NotificationConversationRemoved
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NotificationConversationRemoved} NotificationConversationRemoved
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotificationConversationRemoved.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NotificationConversationRemoved();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.TakenBy = $root.ChatRecipient.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a NotificationConversationRemoved message to GenericMessage
     * @function toGenericMessage
     * @memberof NotificationConversationRemoved
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    NotificationConversationRemoved.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 235, ConversationRemoved : this});
    };

    /**
     * NotificationConversationRemoved typeId
     * @member {number} typeId
     * @memberof NotificationConversationRemoved
     * @instance
     */
    NotificationConversationRemoved.prototype.typeId = 235;
    /**
     * NotificationConversationRemoved typeName
     * @member {string} typeName
     * @memberof NotificationConversationRemoved
     * @instance
     */
    NotificationConversationRemoved.prototype.typeName = "ConversationRemoved";

    return NotificationConversationRemoved;
})();

export const RequestCloseConversation = $root.RequestCloseConversation = (() => {

    /**
     * Properties of a RequestCloseConversation.
     * @exports IRequestCloseConversation
     * @property {number} [IdConversation] RequestCloseConversation IdConversation
     * @property {boolean} [IsHandled] RequestCloseConversation IsHandled
     * @property {number} [CloseReason] RequestCloseConversation CloseReason
     */

    /**
     * Constructs a new RequestCloseConversation.
     * @exports RequestCloseConversation
     * @classdesc Represents a RequestCloseConversation.
     * @constructor
     * @param {Partial<RequestCloseConversation>} [properties] Properties to set
     */
    function RequestCloseConversation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestCloseConversation IdConversation.
     * @member {number} IdConversation
     * @memberof RequestCloseConversation
     * @instance
     */

    /**
     * RequestCloseConversation IsHandled.
     * @member {boolean} IsHandled
     * @memberof RequestCloseConversation
     * @instance
     */

    /**
     * RequestCloseConversation CloseReason.
     * @member {number} CloseReason
     * @memberof RequestCloseConversation
     * @instance
     */

    /**
     * Encodes the specified RequestCloseConversation message. Does not implicitly {@link RequestCloseConversation.verify|verify} messages.
     * @function encode
     * @memberof RequestCloseConversation
     * @static
     * @param {RequestCloseConversation} message RequestCloseConversation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestCloseConversation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.IsHandled != null && Object.hasOwnProperty.call(message, "IsHandled"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsHandled);
        if (message.CloseReason != null && Object.hasOwnProperty.call(message, "CloseReason"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.CloseReason);
        return writer;
    };

    /**
     * Decodes a RequestCloseConversation message from the specified reader or buffer.
     * @function decode
     * @memberof RequestCloseConversation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestCloseConversation} RequestCloseConversation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestCloseConversation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestCloseConversation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.IsHandled = reader.bool();
                    break;
                }
            case 3: {
                    message.CloseReason = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestCloseConversation message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestCloseConversation
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestCloseConversation.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 198, CloseConversation : this});
    };

    /**
     * RequestCloseConversation typeId
     * @member {number} typeId
     * @memberof RequestCloseConversation
     * @instance
     */
    RequestCloseConversation.prototype.typeId = 198;
    /**
     * RequestCloseConversation typeName
     * @member {string} typeName
     * @memberof RequestCloseConversation
     * @instance
     */
    RequestCloseConversation.prototype.typeName = "CloseConversation";

    return RequestCloseConversation;
})();

export const AnonymousSessionClosed = $root.AnonymousSessionClosed = (() => {

    /**
     * Properties of an AnonymousSessionClosed.
     * @exports IAnonymousSessionClosed
     * @property {number} [IdConversation] AnonymousSessionClosed IdConversation
     * @property {ExternalChatCloseReason} [CloseReason] AnonymousSessionClosed CloseReason
     */

    /**
     * Constructs a new AnonymousSessionClosed.
     * @exports AnonymousSessionClosed
     * @classdesc Represents an AnonymousSessionClosed.
     * @constructor
     * @param {Partial<AnonymousSessionClosed>} [properties] Properties to set
     */
    function AnonymousSessionClosed(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AnonymousSessionClosed IdConversation.
     * @member {number} IdConversation
     * @memberof AnonymousSessionClosed
     * @instance
     */

    /**
     * AnonymousSessionClosed CloseReason.
     * @member {ExternalChatCloseReason} CloseReason
     * @memberof AnonymousSessionClosed
     * @instance
     */

    /**
     * Encodes the specified AnonymousSessionClosed message. Does not implicitly {@link AnonymousSessionClosed.verify|verify} messages.
     * @function encode
     * @memberof AnonymousSessionClosed
     * @static
     * @param {AnonymousSessionClosed} message AnonymousSessionClosed message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AnonymousSessionClosed.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.CloseReason != null && Object.hasOwnProperty.call(message, "CloseReason"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.CloseReason);
        return writer;
    };

    /**
     * Decodes an AnonymousSessionClosed message from the specified reader or buffer.
     * @function decode
     * @memberof AnonymousSessionClosed
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AnonymousSessionClosed} AnonymousSessionClosed
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AnonymousSessionClosed.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AnonymousSessionClosed();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.CloseReason = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts an AnonymousSessionClosed message to GenericMessage
     * @function toGenericMessage
     * @memberof AnonymousSessionClosed
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    AnonymousSessionClosed.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 239, AnonymousClosed : this});
    };

    /**
     * AnonymousSessionClosed typeId
     * @member {number} typeId
     * @memberof AnonymousSessionClosed
     * @instance
     */
    AnonymousSessionClosed.prototype.typeId = 239;
    /**
     * AnonymousSessionClosed typeName
     * @member {string} typeName
     * @memberof AnonymousSessionClosed
     * @instance
     */
    AnonymousSessionClosed.prototype.typeName = "AnonymousClosed";

    return AnonymousSessionClosed;
})();

export const NotificationChatMessageStatus = $root.NotificationChatMessageStatus = (() => {

    /**
     * Properties of a NotificationChatMessageStatus.
     * @exports INotificationChatMessageStatus
     * @property {Array.<number>} [IdMessage] NotificationChatMessageStatus IdMessage
     * @property {ChatRecipientRef} [RecipientStatus] NotificationChatMessageStatus RecipientStatus
     * @property {number} [IdConversation] NotificationChatMessageStatus IdConversation
     * @property {Array.<string>} [MsgGid] NotificationChatMessageStatus MsgGid
     * @property {string} [CnvGid] NotificationChatMessageStatus CnvGid
     * @property {string} [SenderGid] NotificationChatMessageStatus SenderGid
     */

    /**
     * Constructs a new NotificationChatMessageStatus.
     * @exports NotificationChatMessageStatus
     * @classdesc Represents a NotificationChatMessageStatus.
     * @constructor
     * @param {Partial<NotificationChatMessageStatus>} [properties] Properties to set
     */
    function NotificationChatMessageStatus(properties) {
        this.IdMessage = [];
        this.MsgGid = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotificationChatMessageStatus IdMessage.
     * @member {Array.<number>} IdMessage
     * @memberof NotificationChatMessageStatus
     * @instance
     */
    NotificationChatMessageStatus.prototype.IdMessage = $util.emptyArray;

    /**
     * NotificationChatMessageStatus RecipientStatus.
     * @member {ChatRecipientRef} RecipientStatus
     * @memberof NotificationChatMessageStatus
     * @instance
     */

    /**
     * NotificationChatMessageStatus IdConversation.
     * @member {number} IdConversation
     * @memberof NotificationChatMessageStatus
     * @instance
     */

    /**
     * NotificationChatMessageStatus MsgGid.
     * @member {Array.<string>} MsgGid
     * @memberof NotificationChatMessageStatus
     * @instance
     */
    NotificationChatMessageStatus.prototype.MsgGid = $util.emptyArray;

    /**
     * NotificationChatMessageStatus CnvGid.
     * @member {string} CnvGid
     * @memberof NotificationChatMessageStatus
     * @instance
     */

    /**
     * NotificationChatMessageStatus SenderGid.
     * @member {string} SenderGid
     * @memberof NotificationChatMessageStatus
     * @instance
     */

    /**
     * Encodes the specified NotificationChatMessageStatus message. Does not implicitly {@link NotificationChatMessageStatus.verify|verify} messages.
     * @function encode
     * @memberof NotificationChatMessageStatus
     * @static
     * @param {NotificationChatMessageStatus} message NotificationChatMessageStatus message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotificationChatMessageStatus.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdMessage != null && message.IdMessage.length)
            for (let i = 0; i < message.IdMessage.length; ++i)
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdMessage[i]);
        if (message.RecipientStatus != null && Object.hasOwnProperty.call(message, "RecipientStatus"))
            $root.ChatRecipientRef.encode(message.RecipientStatus, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.IdConversation);
        if (message.MsgGid != null && message.MsgGid.length)
            for (let i = 0; i < message.MsgGid.length; ++i)
                writer.uint32(/* id 4, wireType 2 =*/34).string(message.MsgGid[i]);
        if (message.CnvGid != null && Object.hasOwnProperty.call(message, "CnvGid"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.CnvGid);
        if (message.SenderGid != null && Object.hasOwnProperty.call(message, "SenderGid"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.SenderGid);
        return writer;
    };

    /**
     * Decodes a NotificationChatMessageStatus message from the specified reader or buffer.
     * @function decode
     * @memberof NotificationChatMessageStatus
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NotificationChatMessageStatus} NotificationChatMessageStatus
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotificationChatMessageStatus.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NotificationChatMessageStatus();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.IdMessage && message.IdMessage.length))
                        message.IdMessage = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.IdMessage.push(reader.int32());
                    } else
                        message.IdMessage.push(reader.int32());
                    break;
                }
            case 2: {
                    message.RecipientStatus = $root.ChatRecipientRef.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 4: {
                    if (!(message.MsgGid && message.MsgGid.length))
                        message.MsgGid = [];
                    message.MsgGid.push(reader.string());
                    break;
                }
            case 5: {
                    message.CnvGid = reader.string();
                    break;
                }
            case 6: {
                    message.SenderGid = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a NotificationChatMessageStatus message to GenericMessage
     * @function toGenericMessage
     * @memberof NotificationChatMessageStatus
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    NotificationChatMessageStatus.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 241, DeliveryStatus : this});
    };

    /**
     * NotificationChatMessageStatus typeId
     * @member {number} typeId
     * @memberof NotificationChatMessageStatus
     * @instance
     */
    NotificationChatMessageStatus.prototype.typeId = 241;
    /**
     * NotificationChatMessageStatus typeName
     * @member {string} typeName
     * @memberof NotificationChatMessageStatus
     * @instance
     */
    NotificationChatMessageStatus.prototype.typeName = "DeliveryStatus";

    return NotificationChatMessageStatus;
})();

export const RequestWhisperMessage = $root.RequestWhisperMessage = (() => {

    /**
     * Properties of a RequestWhisperMessage.
     * @exports IRequestWhisperMessage
     * @property {number} IdConversation RequestWhisperMessage IdConversation
     * @property {string} [Message] RequestWhisperMessage Message
     * @property {string} [FileName] RequestWhisperMessage FileName
     * @property {number} [ReplyOnIdMessage] RequestWhisperMessage ReplyOnIdMessage
     */

    /**
     * Constructs a new RequestWhisperMessage.
     * @exports RequestWhisperMessage
     * @classdesc Represents a RequestWhisperMessage.
     * @constructor
     * @param {Partial<RequestWhisperMessage>} [properties] Properties to set
     */
    function RequestWhisperMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestWhisperMessage IdConversation.
     * @member {number} IdConversation
     * @memberof RequestWhisperMessage
     * @instance
     */

    /**
     * RequestWhisperMessage Message.
     * @member {string} Message
     * @memberof RequestWhisperMessage
     * @instance
     */

    /**
     * RequestWhisperMessage FileName.
     * @member {string} FileName
     * @memberof RequestWhisperMessage
     * @instance
     */

    /**
     * RequestWhisperMessage ReplyOnIdMessage.
     * @member {number} ReplyOnIdMessage
     * @memberof RequestWhisperMessage
     * @instance
     */

    /**
     * Encodes the specified RequestWhisperMessage message. Does not implicitly {@link RequestWhisperMessage.verify|verify} messages.
     * @function encode
     * @memberof RequestWhisperMessage
     * @static
     * @param {RequestWhisperMessage} message RequestWhisperMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestWhisperMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Message);
        if (message.FileName != null && Object.hasOwnProperty.call(message, "FileName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.FileName);
        if (message.ReplyOnIdMessage != null && Object.hasOwnProperty.call(message, "ReplyOnIdMessage"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.ReplyOnIdMessage);
        return writer;
    };

    /**
     * Decodes a RequestWhisperMessage message from the specified reader or buffer.
     * @function decode
     * @memberof RequestWhisperMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestWhisperMessage} RequestWhisperMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestWhisperMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestWhisperMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.Message = reader.string();
                    break;
                }
            case 3: {
                    message.FileName = reader.string();
                    break;
                }
            case 4: {
                    message.ReplyOnIdMessage = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestWhisperMessage message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestWhisperMessage
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestWhisperMessage.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 197, WhisperMessage : this});
    };

    /**
     * RequestWhisperMessage typeId
     * @member {number} typeId
     * @memberof RequestWhisperMessage
     * @instance
     */
    RequestWhisperMessage.prototype.typeId = 197;
    /**
     * RequestWhisperMessage typeName
     * @member {string} typeName
     * @memberof RequestWhisperMessage
     * @instance
     */
    RequestWhisperMessage.prototype.typeName = "WhisperMessage";

    return RequestWhisperMessage;
})();

export const RequestSetVoiceMailHeard = $root.RequestSetVoiceMailHeard = (() => {

    /**
     * Properties of a RequestSetVoiceMailHeard.
     * @exports IRequestSetVoiceMailHeard
     * @property {number} Id RequestSetVoiceMailHeard Id
     * @property {boolean} IsHeard RequestSetVoiceMailHeard IsHeard
     */

    /**
     * Constructs a new RequestSetVoiceMailHeard.
     * @exports RequestSetVoiceMailHeard
     * @classdesc Represents a RequestSetVoiceMailHeard.
     * @constructor
     * @param {Partial<RequestSetVoiceMailHeard>} [properties] Properties to set
     */
    function RequestSetVoiceMailHeard(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetVoiceMailHeard Id.
     * @member {number} Id
     * @memberof RequestSetVoiceMailHeard
     * @instance
     */

    /**
     * RequestSetVoiceMailHeard IsHeard.
     * @member {boolean} IsHeard
     * @memberof RequestSetVoiceMailHeard
     * @instance
     */

    /**
     * Encodes the specified RequestSetVoiceMailHeard message. Does not implicitly {@link RequestSetVoiceMailHeard.verify|verify} messages.
     * @function encode
     * @memberof RequestSetVoiceMailHeard
     * @static
     * @param {RequestSetVoiceMailHeard} message RequestSetVoiceMailHeard message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetVoiceMailHeard.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsHeard);
        return writer;
    };

    /**
     * Decodes a RequestSetVoiceMailHeard message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetVoiceMailHeard
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetVoiceMailHeard} RequestSetVoiceMailHeard
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetVoiceMailHeard.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetVoiceMailHeard();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.IsHeard = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetVoiceMailHeard message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetVoiceMailHeard
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetVoiceMailHeard.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 114, SetVMRead : this});
    };

    /**
     * RequestSetVoiceMailHeard typeId
     * @member {number} typeId
     * @memberof RequestSetVoiceMailHeard
     * @instance
     */
    RequestSetVoiceMailHeard.prototype.typeId = 114;
    /**
     * RequestSetVoiceMailHeard typeName
     * @member {string} typeName
     * @memberof RequestSetVoiceMailHeard
     * @instance
     */
    RequestSetVoiceMailHeard.prototype.typeName = "SetVMRead";

    return RequestSetVoiceMailHeard;
})();

export const RequestDeleteVoiceMail = $root.RequestDeleteVoiceMail = (() => {

    /**
     * Properties of a RequestDeleteVoiceMail.
     * @exports IRequestDeleteVoiceMail
     * @property {number} Id RequestDeleteVoiceMail Id
     */

    /**
     * Constructs a new RequestDeleteVoiceMail.
     * @exports RequestDeleteVoiceMail
     * @classdesc Represents a RequestDeleteVoiceMail.
     * @constructor
     * @param {Partial<RequestDeleteVoiceMail>} [properties] Properties to set
     */
    function RequestDeleteVoiceMail(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestDeleteVoiceMail Id.
     * @member {number} Id
     * @memberof RequestDeleteVoiceMail
     * @instance
     */

    /**
     * Encodes the specified RequestDeleteVoiceMail message. Does not implicitly {@link RequestDeleteVoiceMail.verify|verify} messages.
     * @function encode
     * @memberof RequestDeleteVoiceMail
     * @static
     * @param {RequestDeleteVoiceMail} message RequestDeleteVoiceMail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestDeleteVoiceMail.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        return writer;
    };

    /**
     * Decodes a RequestDeleteVoiceMail message from the specified reader or buffer.
     * @function decode
     * @memberof RequestDeleteVoiceMail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestDeleteVoiceMail} RequestDeleteVoiceMail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestDeleteVoiceMail.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestDeleteVoiceMail();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestDeleteVoiceMail message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestDeleteVoiceMail
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestDeleteVoiceMail.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 126, DeleteVM : this});
    };

    /**
     * RequestDeleteVoiceMail typeId
     * @member {number} typeId
     * @memberof RequestDeleteVoiceMail
     * @instance
     */
    RequestDeleteVoiceMail.prototype.typeId = 126;
    /**
     * RequestDeleteVoiceMail typeName
     * @member {string} typeName
     * @memberof RequestDeleteVoiceMail
     * @instance
     */
    RequestDeleteVoiceMail.prototype.typeName = "DeleteVM";

    return RequestDeleteVoiceMail;
})();

export const RequestPlayVoiceMail = $root.RequestPlayVoiceMail = (() => {

    /**
     * Properties of a RequestPlayVoiceMail.
     * @exports IRequestPlayVoiceMail
     * @property {number} Id RequestPlayVoiceMail Id
     */

    /**
     * Constructs a new RequestPlayVoiceMail.
     * @exports RequestPlayVoiceMail
     * @classdesc Represents a RequestPlayVoiceMail.
     * @constructor
     * @param {Partial<RequestPlayVoiceMail>} [properties] Properties to set
     */
    function RequestPlayVoiceMail(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestPlayVoiceMail Id.
     * @member {number} Id
     * @memberof RequestPlayVoiceMail
     * @instance
     */

    /**
     * Encodes the specified RequestPlayVoiceMail message. Does not implicitly {@link RequestPlayVoiceMail.verify|verify} messages.
     * @function encode
     * @memberof RequestPlayVoiceMail
     * @static
     * @param {RequestPlayVoiceMail} message RequestPlayVoiceMail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestPlayVoiceMail.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        return writer;
    };

    /**
     * Decodes a RequestPlayVoiceMail message from the specified reader or buffer.
     * @function decode
     * @memberof RequestPlayVoiceMail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestPlayVoiceMail} RequestPlayVoiceMail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestPlayVoiceMail.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestPlayVoiceMail();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestPlayVoiceMail message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestPlayVoiceMail
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestPlayVoiceMail.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 127, PlayVM : this});
    };

    /**
     * RequestPlayVoiceMail typeId
     * @member {number} typeId
     * @memberof RequestPlayVoiceMail
     * @instance
     */
    RequestPlayVoiceMail.prototype.typeId = 127;
    /**
     * RequestPlayVoiceMail typeName
     * @member {string} typeName
     * @memberof RequestPlayVoiceMail
     * @instance
     */
    RequestPlayVoiceMail.prototype.typeName = "PlayVM";

    return RequestPlayVoiceMail;
})();

/**
 * RejectAction enum.
 * @exports RejectAction
 * @enum {string}
 * @property {number} RA_Terminate=0 RA_Terminate value
 * @property {number} RA_Busy=1 RA_Busy value
 * @property {number} RA_NoAnswer=2 RA_NoAnswer value
 */
export const RejectAction = $root.RejectAction = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "RA_Terminate"] = 0;
    values[valuesById[1] = "RA_Busy"] = 1;
    values[valuesById[2] = "RA_NoAnswer"] = 2;
    return values;
})();

export const RequestDropCall = $root.RequestDropCall = (() => {

    /**
     * Properties of a RequestDropCall.
     * @exports IRequestDropCall
     * @property {number} LocalConnectionId RequestDropCall LocalConnectionId
     * @property {boolean} IsLocal RequestDropCall IsLocal
     * @property {RejectAction} [ActionIfRinging] RequestDropCall ActionIfRinging
     */

    /**
     * Constructs a new RequestDropCall.
     * @exports RequestDropCall
     * @classdesc Represents a RequestDropCall.
     * @constructor
     * @param {Partial<RequestDropCall>} [properties] Properties to set
     */
    function RequestDropCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestDropCall LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestDropCall
     * @instance
     */

    /**
     * RequestDropCall IsLocal.
     * @member {boolean} IsLocal
     * @memberof RequestDropCall
     * @instance
     */

    /**
     * RequestDropCall ActionIfRinging.
     * @member {RejectAction} ActionIfRinging
     * @memberof RequestDropCall
     * @instance
     */

    /**
     * Encodes the specified RequestDropCall message. Does not implicitly {@link RequestDropCall.verify|verify} messages.
     * @function encode
     * @memberof RequestDropCall
     * @static
     * @param {RequestDropCall} message RequestDropCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestDropCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsLocal);
        if (message.ActionIfRinging != null && Object.hasOwnProperty.call(message, "ActionIfRinging"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ActionIfRinging);
        return writer;
    };

    /**
     * Decodes a RequestDropCall message from the specified reader or buffer.
     * @function decode
     * @memberof RequestDropCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestDropCall} RequestDropCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestDropCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestDropCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            case 2: {
                    message.IsLocal = reader.bool();
                    break;
                }
            case 3: {
                    message.ActionIfRinging = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestDropCall message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestDropCall
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestDropCall.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 115, DropCall : this});
    };

    /**
     * RequestDropCall typeId
     * @member {number} typeId
     * @memberof RequestDropCall
     * @instance
     */
    RequestDropCall.prototype.typeId = 115;
    /**
     * RequestDropCall typeName
     * @member {string} typeName
     * @memberof RequestDropCall
     * @instance
     */
    RequestDropCall.prototype.typeName = "DropCall";

    return RequestDropCall;
})();

export const ResponseCallControl = $root.ResponseCallControl = (() => {

    /**
     * Properties of a ResponseCallControl.
     * @exports IResponseCallControl
     * @property {boolean} Success ResponseCallControl Success
     * @property {string} [Message] ResponseCallControl Message
     * @property {number} [LocalConnectionId] ResponseCallControl LocalConnectionId
     */

    /**
     * Constructs a new ResponseCallControl.
     * @exports ResponseCallControl
     * @classdesc Represents a ResponseCallControl.
     * @constructor
     * @param {Partial<ResponseCallControl>} [properties] Properties to set
     */
    function ResponseCallControl(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseCallControl Success.
     * @member {boolean} Success
     * @memberof ResponseCallControl
     * @instance
     */

    /**
     * ResponseCallControl Message.
     * @member {string} Message
     * @memberof ResponseCallControl
     * @instance
     */

    /**
     * ResponseCallControl LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof ResponseCallControl
     * @instance
     */

    /**
     * Encodes the specified ResponseCallControl message. Does not implicitly {@link ResponseCallControl.verify|verify} messages.
     * @function encode
     * @memberof ResponseCallControl
     * @static
     * @param {ResponseCallControl} message ResponseCallControl message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseCallControl.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.Success);
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Message);
        if (message.LocalConnectionId != null && Object.hasOwnProperty.call(message, "LocalConnectionId"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.LocalConnectionId);
        return writer;
    };

    /**
     * Decodes a ResponseCallControl message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseCallControl
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseCallControl} ResponseCallControl
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseCallControl.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseCallControl();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Success = reader.bool();
                    break;
                }
            case 2: {
                    message.Message = reader.string();
                    break;
                }
            case 3: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseCallControl message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseCallControl
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseCallControl.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 261, CallControlResponse : this});
    };

    /**
     * ResponseCallControl typeId
     * @member {number} typeId
     * @memberof ResponseCallControl
     * @instance
     */
    ResponseCallControl.prototype.typeId = 261;
    /**
     * ResponseCallControl typeName
     * @member {string} typeName
     * @memberof ResponseCallControl
     * @instance
     */
    ResponseCallControl.prototype.typeName = "CallControlResponse";

    return ResponseCallControl;
})();

/**
 * BargeInMode enum.
 * @exports BargeInMode
 * @enum {string}
 * @property {number} BargeIn=0 BargeIn value
 * @property {number} Listen=1 Listen value
 * @property {number} Whisper=2 Whisper value
 */
export const BargeInMode = $root.BargeInMode = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "BargeIn"] = 0;
    values[valuesById[1] = "Listen"] = 1;
    values[valuesById[2] = "Whisper"] = 2;
    return values;
})();

export const RequestBargeInCall = $root.RequestBargeInCall = (() => {

    /**
     * Properties of a RequestBargeInCall.
     * @exports IRequestBargeInCall
     * @property {number} LocalConnectionId RequestBargeInCall LocalConnectionId
     * @property {BargeInMode} mode RequestBargeInCall mode
     * @property {string} [DeviceContact] RequestBargeInCall DeviceContact
     * @property {boolean} [EnableCallControl] RequestBargeInCall EnableCallControl
     */

    /**
     * Constructs a new RequestBargeInCall.
     * @exports RequestBargeInCall
     * @classdesc Represents a RequestBargeInCall.
     * @constructor
     * @param {Partial<RequestBargeInCall>} [properties] Properties to set
     */
    function RequestBargeInCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestBargeInCall LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestBargeInCall
     * @instance
     */

    /**
     * RequestBargeInCall mode.
     * @member {BargeInMode} mode
     * @memberof RequestBargeInCall
     * @instance
     */

    /**
     * RequestBargeInCall DeviceContact.
     * @member {string} DeviceContact
     * @memberof RequestBargeInCall
     * @instance
     */

    /**
     * RequestBargeInCall EnableCallControl.
     * @member {boolean} EnableCallControl
     * @memberof RequestBargeInCall
     * @instance
     */

    /**
     * Encodes the specified RequestBargeInCall message. Does not implicitly {@link RequestBargeInCall.verify|verify} messages.
     * @function encode
     * @memberof RequestBargeInCall
     * @static
     * @param {RequestBargeInCall} message RequestBargeInCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestBargeInCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.mode);
        if (message.DeviceContact != null && Object.hasOwnProperty.call(message, "DeviceContact"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.DeviceContact);
        if (message.EnableCallControl != null && Object.hasOwnProperty.call(message, "EnableCallControl"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.EnableCallControl);
        return writer;
    };

    /**
     * Decodes a RequestBargeInCall message from the specified reader or buffer.
     * @function decode
     * @memberof RequestBargeInCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestBargeInCall} RequestBargeInCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestBargeInCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestBargeInCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            case 2: {
                    message.mode = reader.int32();
                    break;
                }
            case 3: {
                    message.DeviceContact = reader.string();
                    break;
                }
            case 4: {
                    message.EnableCallControl = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestBargeInCall message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestBargeInCall
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestBargeInCall.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 123, BargeInCall : this});
    };

    /**
     * RequestBargeInCall typeId
     * @member {number} typeId
     * @memberof RequestBargeInCall
     * @instance
     */
    RequestBargeInCall.prototype.typeId = 123;
    /**
     * RequestBargeInCall typeName
     * @member {string} typeName
     * @memberof RequestBargeInCall
     * @instance
     */
    RequestBargeInCall.prototype.typeName = "BargeInCall";

    return RequestBargeInCall;
})();

export const RequestDivertCall = $root.RequestDivertCall = (() => {

    /**
     * Properties of a RequestDivertCall.
     * @exports IRequestDivertCall
     * @property {number} LocalConnectionId RequestDivertCall LocalConnectionId
     * @property {boolean} IsLocal RequestDivertCall IsLocal
     * @property {string} Destination RequestDivertCall Destination
     * @property {boolean} vmail RequestDivertCall vmail
     */

    /**
     * Constructs a new RequestDivertCall.
     * @exports RequestDivertCall
     * @classdesc Represents a RequestDivertCall.
     * @constructor
     * @param {Partial<RequestDivertCall>} [properties] Properties to set
     */
    function RequestDivertCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestDivertCall LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestDivertCall
     * @instance
     */

    /**
     * RequestDivertCall IsLocal.
     * @member {boolean} IsLocal
     * @memberof RequestDivertCall
     * @instance
     */

    /**
     * RequestDivertCall Destination.
     * @member {string} Destination
     * @memberof RequestDivertCall
     * @instance
     */

    /**
     * RequestDivertCall vmail.
     * @member {boolean} vmail
     * @memberof RequestDivertCall
     * @instance
     */

    /**
     * Encodes the specified RequestDivertCall message. Does not implicitly {@link RequestDivertCall.verify|verify} messages.
     * @function encode
     * @memberof RequestDivertCall
     * @static
     * @param {RequestDivertCall} message RequestDivertCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestDivertCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsLocal);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.Destination);
        writer.uint32(/* id 4, wireType 0 =*/32).bool(message.vmail);
        return writer;
    };

    /**
     * Decodes a RequestDivertCall message from the specified reader or buffer.
     * @function decode
     * @memberof RequestDivertCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestDivertCall} RequestDivertCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestDivertCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestDivertCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            case 2: {
                    message.IsLocal = reader.bool();
                    break;
                }
            case 3: {
                    message.Destination = reader.string();
                    break;
                }
            case 4: {
                    message.vmail = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestDivertCall message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestDivertCall
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestDivertCall.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 116, DivertCall : this});
    };

    /**
     * RequestDivertCall typeId
     * @member {number} typeId
     * @memberof RequestDivertCall
     * @instance
     */
    RequestDivertCall.prototype.typeId = 116;
    /**
     * RequestDivertCall typeName
     * @member {string} typeName
     * @memberof RequestDivertCall
     * @instance
     */
    RequestDivertCall.prototype.typeName = "DivertCall";

    return RequestDivertCall;
})();

export const RequestPickupCall = $root.RequestPickupCall = (() => {

    /**
     * Properties of a RequestPickupCall.
     * @exports IRequestPickupCall
     * @property {number} LocalConnectionId RequestPickupCall LocalConnectionId
     * @property {boolean} [IsLocal] RequestPickupCall IsLocal
     * @property {string} [DeviceID] RequestPickupCall DeviceID
     * @property {boolean} [EnableCallControl] RequestPickupCall EnableCallControl
     */

    /**
     * Constructs a new RequestPickupCall.
     * @exports RequestPickupCall
     * @classdesc Represents a RequestPickupCall.
     * @constructor
     * @param {Partial<RequestPickupCall>} [properties] Properties to set
     */
    function RequestPickupCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestPickupCall LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestPickupCall
     * @instance
     */

    /**
     * RequestPickupCall IsLocal.
     * @member {boolean} IsLocal
     * @memberof RequestPickupCall
     * @instance
     */

    /**
     * RequestPickupCall DeviceID.
     * @member {string} DeviceID
     * @memberof RequestPickupCall
     * @instance
     */

    /**
     * RequestPickupCall EnableCallControl.
     * @member {boolean} EnableCallControl
     * @memberof RequestPickupCall
     * @instance
     */

    /**
     * Encodes the specified RequestPickupCall message. Does not implicitly {@link RequestPickupCall.verify|verify} messages.
     * @function encode
     * @memberof RequestPickupCall
     * @static
     * @param {RequestPickupCall} message RequestPickupCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestPickupCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        if (message.IsLocal != null && Object.hasOwnProperty.call(message, "IsLocal"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsLocal);
        if (message.DeviceID != null && Object.hasOwnProperty.call(message, "DeviceID"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.DeviceID);
        if (message.EnableCallControl != null && Object.hasOwnProperty.call(message, "EnableCallControl"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.EnableCallControl);
        return writer;
    };

    /**
     * Decodes a RequestPickupCall message from the specified reader or buffer.
     * @function decode
     * @memberof RequestPickupCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestPickupCall} RequestPickupCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestPickupCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestPickupCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            case 2: {
                    message.IsLocal = reader.bool();
                    break;
                }
            case 3: {
                    message.DeviceID = reader.string();
                    break;
                }
            case 4: {
                    message.EnableCallControl = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestPickupCall message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestPickupCall
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestPickupCall.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 117, PickupCall : this});
    };

    /**
     * RequestPickupCall typeId
     * @member {number} typeId
     * @memberof RequestPickupCall
     * @instance
     */
    RequestPickupCall.prototype.typeId = 117;
    /**
     * RequestPickupCall typeName
     * @member {string} typeName
     * @memberof RequestPickupCall
     * @instance
     */
    RequestPickupCall.prototype.typeName = "PickupCall";

    return RequestPickupCall;
})();

export const RequestTransferCall = $root.RequestTransferCall = (() => {

    /**
     * Properties of a RequestTransferCall.
     * @exports IRequestTransferCall
     * @property {number} LocalConnectionId RequestTransferCall LocalConnectionId
     * @property {boolean} IsLocal RequestTransferCall IsLocal
     * @property {string} Destination RequestTransferCall Destination
     */

    /**
     * Constructs a new RequestTransferCall.
     * @exports RequestTransferCall
     * @classdesc Represents a RequestTransferCall.
     * @constructor
     * @param {Partial<RequestTransferCall>} [properties] Properties to set
     */
    function RequestTransferCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestTransferCall LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestTransferCall
     * @instance
     */

    /**
     * RequestTransferCall IsLocal.
     * @member {boolean} IsLocal
     * @memberof RequestTransferCall
     * @instance
     */

    /**
     * RequestTransferCall Destination.
     * @member {string} Destination
     * @memberof RequestTransferCall
     * @instance
     */

    /**
     * Encodes the specified RequestTransferCall message. Does not implicitly {@link RequestTransferCall.verify|verify} messages.
     * @function encode
     * @memberof RequestTransferCall
     * @static
     * @param {RequestTransferCall} message RequestTransferCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestTransferCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsLocal);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.Destination);
        return writer;
    };

    /**
     * Decodes a RequestTransferCall message from the specified reader or buffer.
     * @function decode
     * @memberof RequestTransferCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestTransferCall} RequestTransferCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestTransferCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestTransferCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            case 2: {
                    message.IsLocal = reader.bool();
                    break;
                }
            case 3: {
                    message.Destination = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestTransferCall message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestTransferCall
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestTransferCall.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 118, TransferCall : this});
    };

    /**
     * RequestTransferCall typeId
     * @member {number} typeId
     * @memberof RequestTransferCall
     * @instance
     */
    RequestTransferCall.prototype.typeId = 118;
    /**
     * RequestTransferCall typeName
     * @member {string} typeName
     * @memberof RequestTransferCall
     * @instance
     */
    RequestTransferCall.prototype.typeName = "TransferCall";

    return RequestTransferCall;
})();

export const RequestMakeCall = $root.RequestMakeCall = (() => {

    /**
     * Properties of a RequestMakeCall.
     * @exports IRequestMakeCall
     * @property {string} Destination RequestMakeCall Destination
     * @property {boolean} [UseIntercomToSource] RequestMakeCall UseIntercomToSource
     * @property {string} [DeviceID] RequestMakeCall DeviceID
     * @property {boolean} [EnableCallControl] RequestMakeCall EnableCallControl
     */

    /**
     * Constructs a new RequestMakeCall.
     * @exports RequestMakeCall
     * @classdesc Represents a RequestMakeCall.
     * @constructor
     * @param {Partial<RequestMakeCall>} [properties] Properties to set
     */
    function RequestMakeCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestMakeCall Destination.
     * @member {string} Destination
     * @memberof RequestMakeCall
     * @instance
     */

    /**
     * RequestMakeCall UseIntercomToSource.
     * @member {boolean} UseIntercomToSource
     * @memberof RequestMakeCall
     * @instance
     */

    /**
     * RequestMakeCall DeviceID.
     * @member {string} DeviceID
     * @memberof RequestMakeCall
     * @instance
     */

    /**
     * RequestMakeCall EnableCallControl.
     * @member {boolean} EnableCallControl
     * @memberof RequestMakeCall
     * @instance
     */

    /**
     * Encodes the specified RequestMakeCall message. Does not implicitly {@link RequestMakeCall.verify|verify} messages.
     * @function encode
     * @memberof RequestMakeCall
     * @static
     * @param {RequestMakeCall} message RequestMakeCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestMakeCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.Destination);
        if (message.UseIntercomToSource != null && Object.hasOwnProperty.call(message, "UseIntercomToSource"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.UseIntercomToSource);
        if (message.DeviceID != null && Object.hasOwnProperty.call(message, "DeviceID"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.DeviceID);
        if (message.EnableCallControl != null && Object.hasOwnProperty.call(message, "EnableCallControl"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.EnableCallControl);
        return writer;
    };

    /**
     * Decodes a RequestMakeCall message from the specified reader or buffer.
     * @function decode
     * @memberof RequestMakeCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestMakeCall} RequestMakeCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestMakeCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestMakeCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Destination = reader.string();
                    break;
                }
            case 2: {
                    message.UseIntercomToSource = reader.bool();
                    break;
                }
            case 3: {
                    message.DeviceID = reader.string();
                    break;
                }
            case 4: {
                    message.EnableCallControl = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestMakeCall message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestMakeCall
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestMakeCall.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 119, MakeCall : this});
    };

    /**
     * RequestMakeCall typeId
     * @member {number} typeId
     * @memberof RequestMakeCall
     * @instance
     */
    RequestMakeCall.prototype.typeId = 119;
    /**
     * RequestMakeCall typeName
     * @member {string} typeName
     * @memberof RequestMakeCall
     * @instance
     */
    RequestMakeCall.prototype.typeName = "MakeCall";

    return RequestMakeCall;
})();

export const RequestAutoAnswerConnection = $root.RequestAutoAnswerConnection = (() => {

    /**
     * Properties of a RequestAutoAnswerConnection.
     * @exports IRequestAutoAnswerConnection
     * @property {number} LocalConnectionId RequestAutoAnswerConnection LocalConnectionId
     */

    /**
     * Constructs a new RequestAutoAnswerConnection.
     * @exports RequestAutoAnswerConnection
     * @classdesc Represents a RequestAutoAnswerConnection.
     * @constructor
     * @param {Partial<RequestAutoAnswerConnection>} [properties] Properties to set
     */
    function RequestAutoAnswerConnection(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestAutoAnswerConnection LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestAutoAnswerConnection
     * @instance
     */

    /**
     * Encodes the specified RequestAutoAnswerConnection message. Does not implicitly {@link RequestAutoAnswerConnection.verify|verify} messages.
     * @function encode
     * @memberof RequestAutoAnswerConnection
     * @static
     * @param {RequestAutoAnswerConnection} message RequestAutoAnswerConnection message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestAutoAnswerConnection.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        return writer;
    };

    /**
     * Decodes a RequestAutoAnswerConnection message from the specified reader or buffer.
     * @function decode
     * @memberof RequestAutoAnswerConnection
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestAutoAnswerConnection} RequestAutoAnswerConnection
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestAutoAnswerConnection.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestAutoAnswerConnection();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestAutoAnswerConnection message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestAutoAnswerConnection
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestAutoAnswerConnection.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 152, ConvertToAutoAnswer : this});
    };

    /**
     * RequestAutoAnswerConnection typeId
     * @member {number} typeId
     * @memberof RequestAutoAnswerConnection
     * @instance
     */
    RequestAutoAnswerConnection.prototype.typeId = 152;
    /**
     * RequestAutoAnswerConnection typeName
     * @member {string} typeName
     * @memberof RequestAutoAnswerConnection
     * @instance
     */
    RequestAutoAnswerConnection.prototype.typeName = "ConvertToAutoAnswer";

    return RequestAutoAnswerConnection;
})();

export const RequestJoinCalls = $root.RequestJoinCalls = (() => {

    /**
     * Properties of a RequestJoinCalls.
     * @exports IRequestJoinCalls
     * @property {string} DeviceContact RequestJoinCalls DeviceContact
     * @property {number} MovePartyOfLocalConnectionId RequestJoinCalls MovePartyOfLocalConnectionId
     * @property {number} ReplaceLocalConnectionIdWithMovedParty RequestJoinCalls ReplaceLocalConnectionIdWithMovedParty
     */

    /**
     * Constructs a new RequestJoinCalls.
     * @exports RequestJoinCalls
     * @classdesc Represents a RequestJoinCalls.
     * @constructor
     * @param {Partial<RequestJoinCalls>} [properties] Properties to set
     */
    function RequestJoinCalls(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestJoinCalls DeviceContact.
     * @member {string} DeviceContact
     * @memberof RequestJoinCalls
     * @instance
     */

    /**
     * RequestJoinCalls MovePartyOfLocalConnectionId.
     * @member {number} MovePartyOfLocalConnectionId
     * @memberof RequestJoinCalls
     * @instance
     */

    /**
     * RequestJoinCalls ReplaceLocalConnectionIdWithMovedParty.
     * @member {number} ReplaceLocalConnectionIdWithMovedParty
     * @memberof RequestJoinCalls
     * @instance
     */

    /**
     * Encodes the specified RequestJoinCalls message. Does not implicitly {@link RequestJoinCalls.verify|verify} messages.
     * @function encode
     * @memberof RequestJoinCalls
     * @static
     * @param {RequestJoinCalls} message RequestJoinCalls message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestJoinCalls.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.DeviceContact);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.MovePartyOfLocalConnectionId);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ReplaceLocalConnectionIdWithMovedParty);
        return writer;
    };

    /**
     * Decodes a RequestJoinCalls message from the specified reader or buffer.
     * @function decode
     * @memberof RequestJoinCalls
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestJoinCalls} RequestJoinCalls
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestJoinCalls.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestJoinCalls();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.DeviceContact = reader.string();
                    break;
                }
            case 2: {
                    message.MovePartyOfLocalConnectionId = reader.int32();
                    break;
                }
            case 3: {
                    message.ReplaceLocalConnectionIdWithMovedParty = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestJoinCalls message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestJoinCalls
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestJoinCalls.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 166, JoinCalls : this});
    };

    /**
     * RequestJoinCalls typeId
     * @member {number} typeId
     * @memberof RequestJoinCalls
     * @instance
     */
    RequestJoinCalls.prototype.typeId = 166;
    /**
     * RequestJoinCalls typeName
     * @member {string} typeName
     * @memberof RequestJoinCalls
     * @instance
     */
    RequestJoinCalls.prototype.typeName = "JoinCalls";

    return RequestJoinCalls;
})();

/**
 * OptConnectParticipant enum.
 * @exports OptConnectParticipant
 * @enum {string}
 * @property {number} THIS_CONNECTION=1 THIS_CONNECTION value
 * @property {number} OTHER_CONNECTION=2 OTHER_CONNECTION value
 * @property {number} BOTH_CONNECTIONS=3 BOTH_CONNECTIONS value
 */
export const OptConnectParticipant = $root.OptConnectParticipant = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[1] = "THIS_CONNECTION"] = 1;
    values[valuesById[2] = "OTHER_CONNECTION"] = 2;
    values[valuesById[3] = "BOTH_CONNECTIONS"] = 3;
    return values;
})();

export const RequestJoinCallParticipantsToConference = $root.RequestJoinCallParticipantsToConference = (() => {

    /**
     * Properties of a RequestJoinCallParticipantsToConference.
     * @exports IRequestJoinCallParticipantsToConference
     * @property {number} LocalConnectionId RequestJoinCallParticipantsToConference LocalConnectionId
     * @property {boolean} IsLocal RequestJoinCallParticipantsToConference IsLocal
     * @property {string} [ConferencePIN] RequestJoinCallParticipantsToConference ConferencePIN
     * @property {OptConnectParticipant} [Participants] RequestJoinCallParticipantsToConference Participants
     */

    /**
     * Constructs a new RequestJoinCallParticipantsToConference.
     * @exports RequestJoinCallParticipantsToConference
     * @classdesc Represents a RequestJoinCallParticipantsToConference.
     * @constructor
     * @param {Partial<RequestJoinCallParticipantsToConference>} [properties] Properties to set
     */
    function RequestJoinCallParticipantsToConference(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestJoinCallParticipantsToConference LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestJoinCallParticipantsToConference
     * @instance
     */

    /**
     * RequestJoinCallParticipantsToConference IsLocal.
     * @member {boolean} IsLocal
     * @memberof RequestJoinCallParticipantsToConference
     * @instance
     */

    /**
     * RequestJoinCallParticipantsToConference ConferencePIN.
     * @member {string} ConferencePIN
     * @memberof RequestJoinCallParticipantsToConference
     * @instance
     */

    /**
     * RequestJoinCallParticipantsToConference Participants.
     * @member {OptConnectParticipant} Participants
     * @memberof RequestJoinCallParticipantsToConference
     * @instance
     */

    /**
     * Encodes the specified RequestJoinCallParticipantsToConference message. Does not implicitly {@link RequestJoinCallParticipantsToConference.verify|verify} messages.
     * @function encode
     * @memberof RequestJoinCallParticipantsToConference
     * @static
     * @param {RequestJoinCallParticipantsToConference} message RequestJoinCallParticipantsToConference message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestJoinCallParticipantsToConference.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsLocal);
        if (message.ConferencePIN != null && Object.hasOwnProperty.call(message, "ConferencePIN"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.ConferencePIN);
        if (message.Participants != null && Object.hasOwnProperty.call(message, "Participants"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.Participants);
        return writer;
    };

    /**
     * Decodes a RequestJoinCallParticipantsToConference message from the specified reader or buffer.
     * @function decode
     * @memberof RequestJoinCallParticipantsToConference
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestJoinCallParticipantsToConference} RequestJoinCallParticipantsToConference
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestJoinCallParticipantsToConference.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestJoinCallParticipantsToConference();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            case 2: {
                    message.IsLocal = reader.bool();
                    break;
                }
            case 3: {
                    message.ConferencePIN = reader.string();
                    break;
                }
            case 4: {
                    message.Participants = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestJoinCallParticipantsToConference message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestJoinCallParticipantsToConference
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestJoinCallParticipantsToConference.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 136, JoinCallParticipantsToConference : this});
    };

    /**
     * RequestJoinCallParticipantsToConference typeId
     * @member {number} typeId
     * @memberof RequestJoinCallParticipantsToConference
     * @instance
     */
    RequestJoinCallParticipantsToConference.prototype.typeId = 136;
    /**
     * RequestJoinCallParticipantsToConference typeName
     * @member {string} typeName
     * @memberof RequestJoinCallParticipantsToConference
     * @instance
     */
    RequestJoinCallParticipantsToConference.prototype.typeName = "JoinCallParticipantsToConference";

    return RequestJoinCallParticipantsToConference;
})();

export const RequestUpdateContact = $root.RequestUpdateContact = (() => {

    /**
     * Properties of a RequestUpdateContact.
     * @exports IRequestUpdateContact
     * @property {ActionType} Action RequestUpdateContact Action
     * @property {Contact} NewContact RequestUpdateContact NewContact
     * @property {Uint8Array} [ImageContent] RequestUpdateContact ImageContent
     */

    /**
     * Constructs a new RequestUpdateContact.
     * @exports RequestUpdateContact
     * @classdesc Represents a RequestUpdateContact.
     * @constructor
     * @param {Partial<RequestUpdateContact>} [properties] Properties to set
     */
    function RequestUpdateContact(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateContact Action.
     * @member {ActionType} Action
     * @memberof RequestUpdateContact
     * @instance
     */

    /**
     * RequestUpdateContact NewContact.
     * @member {Contact} NewContact
     * @memberof RequestUpdateContact
     * @instance
     */

    /**
     * RequestUpdateContact ImageContent.
     * @member {Uint8Array} ImageContent
     * @memberof RequestUpdateContact
     * @instance
     */

    /**
     * Encodes the specified RequestUpdateContact message. Does not implicitly {@link RequestUpdateContact.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateContact
     * @static
     * @param {RequestUpdateContact} message RequestUpdateContact message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateContact.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        $root.Contact.encode(message.NewContact, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.ImageContent != null && Object.hasOwnProperty.call(message, "ImageContent"))
            writer.uint32(/* id 3, wireType 2 =*/26).bytes(message.ImageContent);
        return writer;
    };

    /**
     * Decodes a RequestUpdateContact message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateContact
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateContact} RequestUpdateContact
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateContact.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateContact();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.NewContact = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.ImageContent = reader.bytes();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateContact message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateContact
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateContact.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 120, UpdateContact : this});
    };

    /**
     * RequestUpdateContact typeId
     * @member {number} typeId
     * @memberof RequestUpdateContact
     * @instance
     */
    RequestUpdateContact.prototype.typeId = 120;
    /**
     * RequestUpdateContact typeName
     * @member {string} typeName
     * @memberof RequestUpdateContact
     * @instance
     */
    RequestUpdateContact.prototype.typeName = "UpdateContact";

    return RequestUpdateContact;
})();

export const RequestChangeConference = $root.RequestChangeConference = (() => {

    /**
     * Properties of a RequestChangeConference.
     * @exports IRequestChangeConference
     * @property {string} [method] RequestChangeConference method
     * @property {string} [member] RequestChangeConference member
     * @property {string} [mute] RequestChangeConference mute
     * @property {string} [hold] RequestChangeConference hold
     * @property {string} [pin] RequestChangeConference pin
     */

    /**
     * Constructs a new RequestChangeConference.
     * @exports RequestChangeConference
     * @classdesc Represents a RequestChangeConference.
     * @constructor
     * @param {Partial<RequestChangeConference>} [properties] Properties to set
     */
    function RequestChangeConference(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestChangeConference method.
     * @member {string} method
     * @memberof RequestChangeConference
     * @instance
     */

    /**
     * RequestChangeConference member.
     * @member {string} member
     * @memberof RequestChangeConference
     * @instance
     */

    /**
     * RequestChangeConference mute.
     * @member {string} mute
     * @memberof RequestChangeConference
     * @instance
     */

    /**
     * RequestChangeConference hold.
     * @member {string} hold
     * @memberof RequestChangeConference
     * @instance
     */

    /**
     * RequestChangeConference pin.
     * @member {string} pin
     * @memberof RequestChangeConference
     * @instance
     */

    /**
     * Encodes the specified RequestChangeConference message. Does not implicitly {@link RequestChangeConference.verify|verify} messages.
     * @function encode
     * @memberof RequestChangeConference
     * @static
     * @param {RequestChangeConference} message RequestChangeConference message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestChangeConference.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.method != null && Object.hasOwnProperty.call(message, "method"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.method);
        if (message.member != null && Object.hasOwnProperty.call(message, "member"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.member);
        if (message.mute != null && Object.hasOwnProperty.call(message, "mute"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.mute);
        if (message.hold != null && Object.hasOwnProperty.call(message, "hold"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.hold);
        if (message.pin != null && Object.hasOwnProperty.call(message, "pin"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.pin);
        return writer;
    };

    /**
     * Decodes a RequestChangeConference message from the specified reader or buffer.
     * @function decode
     * @memberof RequestChangeConference
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestChangeConference} RequestChangeConference
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestChangeConference.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestChangeConference();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.method = reader.string();
                    break;
                }
            case 2: {
                    message.member = reader.string();
                    break;
                }
            case 3: {
                    message.mute = reader.string();
                    break;
                }
            case 4: {
                    message.hold = reader.string();
                    break;
                }
            case 5: {
                    message.pin = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestChangeConference message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestChangeConference
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestChangeConference.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 121, ChangeConference : this});
    };

    /**
     * RequestChangeConference typeId
     * @member {number} typeId
     * @memberof RequestChangeConference
     * @instance
     */
    RequestChangeConference.prototype.typeId = 121;
    /**
     * RequestChangeConference typeName
     * @member {string} typeName
     * @memberof RequestChangeConference
     * @instance
     */
    RequestChangeConference.prototype.typeName = "ChangeConference";

    return RequestChangeConference;
})();

export const RequestGetSystemParameters = $root.RequestGetSystemParameters = (() => {

    /**
     * Properties of a RequestGetSystemParameters.
     * @exports IRequestGetSystemParameters
     */

    /**
     * Constructs a new RequestGetSystemParameters.
     * @exports RequestGetSystemParameters
     * @classdesc Represents a RequestGetSystemParameters.
     * @constructor
     * @param {Partial<RequestGetSystemParameters>} [properties] Properties to set
     */
    function RequestGetSystemParameters(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestGetSystemParameters message. Does not implicitly {@link RequestGetSystemParameters.verify|verify} messages.
     * @function encode
     * @memberof RequestGetSystemParameters
     * @static
     * @param {RequestGetSystemParameters} message RequestGetSystemParameters message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetSystemParameters.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestGetSystemParameters message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetSystemParameters
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetSystemParameters} RequestGetSystemParameters
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetSystemParameters.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetSystemParameters();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetSystemParameters message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetSystemParameters
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetSystemParameters.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 122, GetSystemParameters : this});
    };

    /**
     * RequestGetSystemParameters typeId
     * @member {number} typeId
     * @memberof RequestGetSystemParameters
     * @instance
     */
    RequestGetSystemParameters.prototype.typeId = 122;
    /**
     * RequestGetSystemParameters typeName
     * @member {string} typeName
     * @memberof RequestGetSystemParameters
     * @instance
     */
    RequestGetSystemParameters.prototype.typeName = "GetSystemParameters";

    return RequestGetSystemParameters;
})();

export const ResponseSystemParameters = $root.ResponseSystemParameters = (() => {

    /**
     * Properties of a ResponseSystemParameters.
     * @exports IResponseSystemParameters
     * @property {string} [VmailDialCode] ResponseSystemParameters VmailDialCode
     * @property {string} [IntercomDialCode] ResponseSystemParameters IntercomDialCode
     * @property {string} [Custom1Name] ResponseSystemParameters Custom1Name
     * @property {string} [Custom2Name] ResponseSystemParameters Custom2Name
     * @property {string} [WebMeetingURI] ResponseSystemParameters WebMeetingURI
     * @property {string} [WebRtcGatewayDn] ResponseSystemParameters WebRtcGatewayDn
     * @property {string} [WebMeetingParameters] ResponseSystemParameters WebMeetingParameters
     * @property {string} [WebMeetingBestMCU] ResponseSystemParameters WebMeetingBestMCU
     * @property {string} [DialCodeProfile] ResponseSystemParameters DialCodeProfile
     * @property {string} [LoggedInQueue] ResponseSystemParameters LoggedInQueue
     * @property {string} [LoggedOutQueue] ResponseSystemParameters LoggedOutQueue
     * @property {boolean} [IsLastFirst] ResponseSystemParameters IsLastFirst
     * @property {number} [PhonebookMinMatch] ResponseSystemParameters PhonebookMinMatch
     * @property {string} [CstaUserAgents] ResponseSystemParameters CstaUserAgents
     * @property {string} [HotelIvr] ResponseSystemParameters HotelIvr
     * @property {boolean} [ConfCallParticipants] ResponseSystemParameters ConfCallParticipants
     * @property {boolean} [ChatIsEnabled] ResponseSystemParameters ChatIsEnabled
     * @property {boolean} [AllowWebrtcEndpoint] ResponseSystemParameters AllowWebrtcEndpoint
     * @property {number} [MaxChatAttachmentFileSize] ResponseSystemParameters MaxChatAttachmentFileSize
     * @property {string} [PbxExternalHost] ResponseSystemParameters PbxExternalHost
     * @property {string} [PbxInternalHost] ResponseSystemParameters PbxInternalHost
     * @property {Array.<string>} [WebRtcCodecs] ResponseSystemParameters WebRtcCodecs
     * @property {boolean} [IsElectronEnabled] ResponseSystemParameters IsElectronEnabled
     * @property {boolean} [HideCrmContacts] ResponseSystemParameters HideCrmContacts
     * @property {number} [CurrentInOutOfficeMode] ResponseSystemParameters CurrentInOutOfficeMode
     * @property {number} [DefaultMaxImageSizeForProviders] ResponseSystemParameters DefaultMaxImageSizeForProviders
     * @property {string} [VapidPublicKey] ResponseSystemParameters VapidPublicKey
     * @property {string} [SystemLanguage] ResponseSystemParameters SystemLanguage
     * @property {boolean} [MultiCompanyMode] ResponseSystemParameters MultiCompanyMode
     * @property {boolean} [AllowChangePasswords] ResponseSystemParameters AllowChangePasswords
     * @property {boolean} [CrmConfigured] ResponseSystemParameters CrmConfigured
     * @property {boolean} [CrmAddContactAllowed] ResponseSystemParameters CrmAddContactAllowed
     * @property {boolean} [HasSystemOwner] ResponseSystemParameters HasSystemOwner
     * @property {string} [LicenseProduct] ResponseSystemParameters LicenseProduct
     * @property {DateTime} [LicenseExpiredAt] ResponseSystemParameters LicenseExpiredAt
     * @property {boolean} [PrependNameToCID] ResponseSystemParameters PrependNameToCID
     * @property {boolean} [HideInteractionHistory] ResponseSystemParameters HideInteractionHistory
     */

    /**
     * Constructs a new ResponseSystemParameters.
     * @exports ResponseSystemParameters
     * @classdesc Represents a ResponseSystemParameters.
     * @constructor
     * @param {Partial<ResponseSystemParameters>} [properties] Properties to set
     */
    function ResponseSystemParameters(properties) {
        this.WebRtcCodecs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseSystemParameters VmailDialCode.
     * @member {string} VmailDialCode
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters IntercomDialCode.
     * @member {string} IntercomDialCode
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters Custom1Name.
     * @member {string} Custom1Name
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters Custom2Name.
     * @member {string} Custom2Name
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters WebMeetingURI.
     * @member {string} WebMeetingURI
     * @memberof ResponseSystemParameters
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ResponseSystemParameters WebRtcGatewayDn.
     * @member {string} WebRtcGatewayDn
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters WebMeetingParameters.
     * @member {string} WebMeetingParameters
     * @memberof ResponseSystemParameters
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ResponseSystemParameters WebMeetingBestMCU.
     * @member {string} WebMeetingBestMCU
     * @memberof ResponseSystemParameters
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ResponseSystemParameters DialCodeProfile.
     * @member {string} DialCodeProfile
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters LoggedInQueue.
     * @member {string} LoggedInQueue
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters LoggedOutQueue.
     * @member {string} LoggedOutQueue
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters IsLastFirst.
     * @member {boolean} IsLastFirst
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters PhonebookMinMatch.
     * @member {number} PhonebookMinMatch
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters CstaUserAgents.
     * @member {string} CstaUserAgents
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters HotelIvr.
     * @member {string} HotelIvr
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters ConfCallParticipants.
     * @member {boolean} ConfCallParticipants
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters ChatIsEnabled.
     * @member {boolean} ChatIsEnabled
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters AllowWebrtcEndpoint.
     * @member {boolean} AllowWebrtcEndpoint
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters MaxChatAttachmentFileSize.
     * @member {number} MaxChatAttachmentFileSize
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters PbxExternalHost.
     * @member {string} PbxExternalHost
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters PbxInternalHost.
     * @member {string} PbxInternalHost
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters WebRtcCodecs.
     * @member {Array.<string>} WebRtcCodecs
     * @memberof ResponseSystemParameters
     * @instance
     */
    ResponseSystemParameters.prototype.WebRtcCodecs = $util.emptyArray;

    /**
     * ResponseSystemParameters IsElectronEnabled.
     * @member {boolean} IsElectronEnabled
     * @memberof ResponseSystemParameters
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ResponseSystemParameters HideCrmContacts.
     * @member {boolean} HideCrmContacts
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters CurrentInOutOfficeMode.
     * @member {number} CurrentInOutOfficeMode
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters DefaultMaxImageSizeForProviders.
     * @member {number} DefaultMaxImageSizeForProviders
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters VapidPublicKey.
     * @member {string} VapidPublicKey
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters SystemLanguage.
     * @member {string} SystemLanguage
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters MultiCompanyMode.
     * @member {boolean} MultiCompanyMode
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters AllowChangePasswords.
     * @member {boolean} AllowChangePasswords
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters CrmConfigured.
     * @member {boolean} CrmConfigured
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters CrmAddContactAllowed.
     * @member {boolean} CrmAddContactAllowed
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters HasSystemOwner.
     * @member {boolean} HasSystemOwner
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters LicenseProduct.
     * @member {string} LicenseProduct
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters LicenseExpiredAt.
     * @member {DateTime} LicenseExpiredAt
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters PrependNameToCID.
     * @member {boolean} PrependNameToCID
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * ResponseSystemParameters HideInteractionHistory.
     * @member {boolean} HideInteractionHistory
     * @memberof ResponseSystemParameters
     * @instance
     */

    /**
     * Encodes the specified ResponseSystemParameters message. Does not implicitly {@link ResponseSystemParameters.verify|verify} messages.
     * @function encode
     * @memberof ResponseSystemParameters
     * @static
     * @param {ResponseSystemParameters} message ResponseSystemParameters message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseSystemParameters.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.VmailDialCode != null && Object.hasOwnProperty.call(message, "VmailDialCode"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.VmailDialCode);
        if (message.IntercomDialCode != null && Object.hasOwnProperty.call(message, "IntercomDialCode"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.IntercomDialCode);
        if (message.Custom1Name != null && Object.hasOwnProperty.call(message, "Custom1Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Custom1Name);
        if (message.Custom2Name != null && Object.hasOwnProperty.call(message, "Custom2Name"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Custom2Name);
        if (message.WebMeetingURI != null && Object.hasOwnProperty.call(message, "WebMeetingURI"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.WebMeetingURI);
        if (message.WebRtcGatewayDn != null && Object.hasOwnProperty.call(message, "WebRtcGatewayDn"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.WebRtcGatewayDn);
        if (message.WebMeetingParameters != null && Object.hasOwnProperty.call(message, "WebMeetingParameters"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.WebMeetingParameters);
        if (message.WebMeetingBestMCU != null && Object.hasOwnProperty.call(message, "WebMeetingBestMCU"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.WebMeetingBestMCU);
        if (message.DialCodeProfile != null && Object.hasOwnProperty.call(message, "DialCodeProfile"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.DialCodeProfile);
        if (message.LoggedInQueue != null && Object.hasOwnProperty.call(message, "LoggedInQueue"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.LoggedInQueue);
        if (message.LoggedOutQueue != null && Object.hasOwnProperty.call(message, "LoggedOutQueue"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.LoggedOutQueue);
        if (message.IsLastFirst != null && Object.hasOwnProperty.call(message, "IsLastFirst"))
            writer.uint32(/* id 12, wireType 0 =*/96).bool(message.IsLastFirst);
        if (message.PhonebookMinMatch != null && Object.hasOwnProperty.call(message, "PhonebookMinMatch"))
            writer.uint32(/* id 13, wireType 0 =*/104).int32(message.PhonebookMinMatch);
        if (message.CstaUserAgents != null && Object.hasOwnProperty.call(message, "CstaUserAgents"))
            writer.uint32(/* id 15, wireType 2 =*/122).string(message.CstaUserAgents);
        if (message.HotelIvr != null && Object.hasOwnProperty.call(message, "HotelIvr"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.HotelIvr);
        if (message.ConfCallParticipants != null && Object.hasOwnProperty.call(message, "ConfCallParticipants"))
            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.ConfCallParticipants);
        if (message.ChatIsEnabled != null && Object.hasOwnProperty.call(message, "ChatIsEnabled"))
            writer.uint32(/* id 18, wireType 0 =*/144).bool(message.ChatIsEnabled);
        if (message.AllowWebrtcEndpoint != null && Object.hasOwnProperty.call(message, "AllowWebrtcEndpoint"))
            writer.uint32(/* id 19, wireType 0 =*/152).bool(message.AllowWebrtcEndpoint);
        if (message.MaxChatAttachmentFileSize != null && Object.hasOwnProperty.call(message, "MaxChatAttachmentFileSize"))
            writer.uint32(/* id 20, wireType 0 =*/160).uint64(message.MaxChatAttachmentFileSize);
        if (message.PbxExternalHost != null && Object.hasOwnProperty.call(message, "PbxExternalHost"))
            writer.uint32(/* id 21, wireType 2 =*/170).string(message.PbxExternalHost);
        if (message.PbxInternalHost != null && Object.hasOwnProperty.call(message, "PbxInternalHost"))
            writer.uint32(/* id 22, wireType 2 =*/178).string(message.PbxInternalHost);
        if (message.WebRtcCodecs != null && message.WebRtcCodecs.length)
            for (let i = 0; i < message.WebRtcCodecs.length; ++i)
                writer.uint32(/* id 23, wireType 2 =*/186).string(message.WebRtcCodecs[i]);
        if (message.IsElectronEnabled != null && Object.hasOwnProperty.call(message, "IsElectronEnabled"))
            writer.uint32(/* id 24, wireType 0 =*/192).bool(message.IsElectronEnabled);
        if (message.HideCrmContacts != null && Object.hasOwnProperty.call(message, "HideCrmContacts"))
            writer.uint32(/* id 25, wireType 0 =*/200).bool(message.HideCrmContacts);
        if (message.CurrentInOutOfficeMode != null && Object.hasOwnProperty.call(message, "CurrentInOutOfficeMode"))
            writer.uint32(/* id 26, wireType 0 =*/208).int32(message.CurrentInOutOfficeMode);
        if (message.DefaultMaxImageSizeForProviders != null && Object.hasOwnProperty.call(message, "DefaultMaxImageSizeForProviders"))
            writer.uint32(/* id 27, wireType 0 =*/216).uint64(message.DefaultMaxImageSizeForProviders);
        if (message.VapidPublicKey != null && Object.hasOwnProperty.call(message, "VapidPublicKey"))
            writer.uint32(/* id 28, wireType 2 =*/226).string(message.VapidPublicKey);
        if (message.SystemLanguage != null && Object.hasOwnProperty.call(message, "SystemLanguage"))
            writer.uint32(/* id 29, wireType 2 =*/234).string(message.SystemLanguage);
        if (message.MultiCompanyMode != null && Object.hasOwnProperty.call(message, "MultiCompanyMode"))
            writer.uint32(/* id 30, wireType 0 =*/240).bool(message.MultiCompanyMode);
        if (message.AllowChangePasswords != null && Object.hasOwnProperty.call(message, "AllowChangePasswords"))
            writer.uint32(/* id 31, wireType 0 =*/248).bool(message.AllowChangePasswords);
        if (message.CrmConfigured != null && Object.hasOwnProperty.call(message, "CrmConfigured"))
            writer.uint32(/* id 32, wireType 0 =*/256).bool(message.CrmConfigured);
        if (message.CrmAddContactAllowed != null && Object.hasOwnProperty.call(message, "CrmAddContactAllowed"))
            writer.uint32(/* id 33, wireType 0 =*/264).bool(message.CrmAddContactAllowed);
        if (message.HasSystemOwner != null && Object.hasOwnProperty.call(message, "HasSystemOwner"))
            writer.uint32(/* id 34, wireType 0 =*/272).bool(message.HasSystemOwner);
        if (message.LicenseProduct != null && Object.hasOwnProperty.call(message, "LicenseProduct"))
            writer.uint32(/* id 35, wireType 2 =*/282).string(message.LicenseProduct);
        if (message.LicenseExpiredAt != null && Object.hasOwnProperty.call(message, "LicenseExpiredAt"))
            $root.DateTime.encode(message.LicenseExpiredAt, writer.uint32(/* id 36, wireType 2 =*/290).fork()).ldelim();
        if (message.PrependNameToCID != null && Object.hasOwnProperty.call(message, "PrependNameToCID"))
            writer.uint32(/* id 37, wireType 0 =*/296).bool(message.PrependNameToCID);
        if (message.HideInteractionHistory != null && Object.hasOwnProperty.call(message, "HideInteractionHistory"))
            writer.uint32(/* id 38, wireType 0 =*/304).bool(message.HideInteractionHistory);
        return writer;
    };

    /**
     * Decodes a ResponseSystemParameters message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseSystemParameters
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseSystemParameters} ResponseSystemParameters
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseSystemParameters.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseSystemParameters();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.VmailDialCode = reader.string();
                    break;
                }
            case 2: {
                    message.IntercomDialCode = reader.string();
                    break;
                }
            case 3: {
                    message.Custom1Name = reader.string();
                    break;
                }
            case 4: {
                    message.Custom2Name = reader.string();
                    break;
                }
            case 5: {
                    message.WebMeetingURI = reader.string();
                    break;
                }
            case 6: {
                    message.WebRtcGatewayDn = reader.string();
                    break;
                }
            case 7: {
                    message.WebMeetingParameters = reader.string();
                    break;
                }
            case 8: {
                    message.WebMeetingBestMCU = reader.string();
                    break;
                }
            case 9: {
                    message.DialCodeProfile = reader.string();
                    break;
                }
            case 10: {
                    message.LoggedInQueue = reader.string();
                    break;
                }
            case 11: {
                    message.LoggedOutQueue = reader.string();
                    break;
                }
            case 12: {
                    message.IsLastFirst = reader.bool();
                    break;
                }
            case 13: {
                    message.PhonebookMinMatch = reader.int32();
                    break;
                }
            case 15: {
                    message.CstaUserAgents = reader.string();
                    break;
                }
            case 16: {
                    message.HotelIvr = reader.string();
                    break;
                }
            case 17: {
                    message.ConfCallParticipants = reader.bool();
                    break;
                }
            case 18: {
                    message.ChatIsEnabled = reader.bool();
                    break;
                }
            case 19: {
                    message.AllowWebrtcEndpoint = reader.bool();
                    break;
                }
            case 20: {
                    message.MaxChatAttachmentFileSize = reader.uint64();
                    break;
                }
            case 21: {
                    message.PbxExternalHost = reader.string();
                    break;
                }
            case 22: {
                    message.PbxInternalHost = reader.string();
                    break;
                }
            case 23: {
                    if (!(message.WebRtcCodecs && message.WebRtcCodecs.length))
                        message.WebRtcCodecs = [];
                    message.WebRtcCodecs.push(reader.string());
                    break;
                }
            case 24: {
                    message.IsElectronEnabled = reader.bool();
                    break;
                }
            case 25: {
                    message.HideCrmContacts = reader.bool();
                    break;
                }
            case 26: {
                    message.CurrentInOutOfficeMode = reader.int32();
                    break;
                }
            case 27: {
                    message.DefaultMaxImageSizeForProviders = reader.uint64();
                    break;
                }
            case 28: {
                    message.VapidPublicKey = reader.string();
                    break;
                }
            case 29: {
                    message.SystemLanguage = reader.string();
                    break;
                }
            case 30: {
                    message.MultiCompanyMode = reader.bool();
                    break;
                }
            case 31: {
                    message.AllowChangePasswords = reader.bool();
                    break;
                }
            case 32: {
                    message.CrmConfigured = reader.bool();
                    break;
                }
            case 33: {
                    message.CrmAddContactAllowed = reader.bool();
                    break;
                }
            case 34: {
                    message.HasSystemOwner = reader.bool();
                    break;
                }
            case 35: {
                    message.LicenseProduct = reader.string();
                    break;
                }
            case 36: {
                    message.LicenseExpiredAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 37: {
                    message.PrependNameToCID = reader.bool();
                    break;
                }
            case 38: {
                    message.HideInteractionHistory = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseSystemParameters message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseSystemParameters
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseSystemParameters.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 210, SystemParameters : this});
    };

    /**
     * ResponseSystemParameters typeId
     * @member {number} typeId
     * @memberof ResponseSystemParameters
     * @instance
     */
    ResponseSystemParameters.prototype.typeId = 210;
    /**
     * ResponseSystemParameters typeName
     * @member {string} typeName
     * @memberof ResponseSystemParameters
     * @instance
     */
    ResponseSystemParameters.prototype.typeName = "SystemParameters";

    return ResponseSystemParameters;
})();

export const RequestSetOfficeHoursMode = $root.RequestSetOfficeHoursMode = (() => {

    /**
     * Properties of a RequestSetOfficeHoursMode.
     * @exports IRequestSetOfficeHoursMode
     * @property {number} [CurrentInOutOfficeMode] RequestSetOfficeHoursMode CurrentInOutOfficeMode
     */

    /**
     * Constructs a new RequestSetOfficeHoursMode.
     * @exports RequestSetOfficeHoursMode
     * @classdesc Represents a RequestSetOfficeHoursMode.
     * @constructor
     * @param {Partial<RequestSetOfficeHoursMode>} [properties] Properties to set
     */
    function RequestSetOfficeHoursMode(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetOfficeHoursMode CurrentInOutOfficeMode.
     * @member {number} CurrentInOutOfficeMode
     * @memberof RequestSetOfficeHoursMode
     * @instance
     */

    /**
     * Encodes the specified RequestSetOfficeHoursMode message. Does not implicitly {@link RequestSetOfficeHoursMode.verify|verify} messages.
     * @function encode
     * @memberof RequestSetOfficeHoursMode
     * @static
     * @param {RequestSetOfficeHoursMode} message RequestSetOfficeHoursMode message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetOfficeHoursMode.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.CurrentInOutOfficeMode != null && Object.hasOwnProperty.call(message, "CurrentInOutOfficeMode"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.CurrentInOutOfficeMode);
        return writer;
    };

    /**
     * Decodes a RequestSetOfficeHoursMode message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetOfficeHoursMode
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetOfficeHoursMode} RequestSetOfficeHoursMode
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetOfficeHoursMode.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetOfficeHoursMode();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.CurrentInOutOfficeMode = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetOfficeHoursMode message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetOfficeHoursMode
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetOfficeHoursMode.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 512, SetOfficeHoursMode : this});
    };

    /**
     * RequestSetOfficeHoursMode typeId
     * @member {number} typeId
     * @memberof RequestSetOfficeHoursMode
     * @instance
     */
    RequestSetOfficeHoursMode.prototype.typeId = 512;
    /**
     * RequestSetOfficeHoursMode typeName
     * @member {string} typeName
     * @memberof RequestSetOfficeHoursMode
     * @instance
     */
    RequestSetOfficeHoursMode.prototype.typeName = "SetOfficeHoursMode";

    return RequestSetOfficeHoursMode;
})();

export const QueueStatistics = $root.QueueStatistics = (() => {

    /**
     * Properties of a QueueStatistics.
     * @exports IQueueStatistics
     * @property {ActionType} Action QueueStatistics Action
     * @property {string} [CurCallsWaiting] QueueStatistics CurCallsWaiting
     * @property {string} [CurCallsPolling] QueueStatistics CurCallsPolling
     * @property {string} [CurCallsRepolling] QueueStatistics CurCallsRepolling
     * @property {string} [CurCallsServicing] QueueStatistics CurCallsServicing
     * @property {string} [TotalCallsIn] QueueStatistics TotalCallsIn
     * @property {string} [TotalCallsServiced] QueueStatistics TotalCallsServiced
     * @property {string} [TotalCallsDroppedInWait] QueueStatistics TotalCallsDroppedInWait
     * @property {string} [TotalCallsDroppedInPoll] QueueStatistics TotalCallsDroppedInPoll
     * @property {string} [TotalAgentsDialed] QueueStatistics TotalAgentsDialed
     * @property {string} [TotalAgentsRefused] QueueStatistics TotalAgentsRefused
     * @property {string} [TotalAgentCallsExpired] QueueStatistics TotalAgentCallsExpired
     * @property {string} [TotalCallsReachedMaxTime] QueueStatistics TotalCallsReachedMaxTime
     * @property {string} [TotalUserRequestedEnd] QueueStatistics TotalUserRequestedEnd
     * @property {string} [TotalNoAgentsEnded] QueueStatistics TotalNoAgentsEnded
     * @property {string} [TotalTransfersFailed] QueueStatistics TotalTransfersFailed
     * @property {string} [TotalCallbacksRequested] QueueStatistics TotalCallbacksRequested
     * @property {string} [TotalCallbacksServiced] QueueStatistics TotalCallbacksServiced
     * @property {string} [TotalCallbacksFailed] QueueStatistics TotalCallbacksFailed
     * @property {string} [TotalWaitingTime] QueueStatistics TotalWaitingTime
     * @property {string} [TotalServicingTime] QueueStatistics TotalServicingTime
     * @property {string} [LongestWaitingTime] QueueStatistics LongestWaitingTime
     * @property {string} [TotalCallsEscalated] QueueStatistics TotalCallsEscalated
     * @property {string} [TotalCallsAbandoned] QueueStatistics TotalCallsAbandoned
     */

    /**
     * Constructs a new QueueStatistics.
     * @exports QueueStatistics
     * @classdesc Represents a QueueStatistics.
     * @constructor
     * @param {Partial<QueueStatistics>} [properties] Properties to set
     */
    function QueueStatistics(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueueStatistics Action.
     * @member {ActionType} Action
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics CurCallsWaiting.
     * @member {string} CurCallsWaiting
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics CurCallsPolling.
     * @member {string} CurCallsPolling
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics CurCallsRepolling.
     * @member {string} CurCallsRepolling
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics CurCallsServicing.
     * @member {string} CurCallsServicing
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallsIn.
     * @member {string} TotalCallsIn
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallsServiced.
     * @member {string} TotalCallsServiced
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallsDroppedInWait.
     * @member {string} TotalCallsDroppedInWait
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallsDroppedInPoll.
     * @member {string} TotalCallsDroppedInPoll
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalAgentsDialed.
     * @member {string} TotalAgentsDialed
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalAgentsRefused.
     * @member {string} TotalAgentsRefused
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalAgentCallsExpired.
     * @member {string} TotalAgentCallsExpired
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallsReachedMaxTime.
     * @member {string} TotalCallsReachedMaxTime
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalUserRequestedEnd.
     * @member {string} TotalUserRequestedEnd
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalNoAgentsEnded.
     * @member {string} TotalNoAgentsEnded
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalTransfersFailed.
     * @member {string} TotalTransfersFailed
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallbacksRequested.
     * @member {string} TotalCallbacksRequested
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallbacksServiced.
     * @member {string} TotalCallbacksServiced
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallbacksFailed.
     * @member {string} TotalCallbacksFailed
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalWaitingTime.
     * @member {string} TotalWaitingTime
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalServicingTime.
     * @member {string} TotalServicingTime
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics LongestWaitingTime.
     * @member {string} LongestWaitingTime
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallsEscalated.
     * @member {string} TotalCallsEscalated
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * QueueStatistics TotalCallsAbandoned.
     * @member {string} TotalCallsAbandoned
     * @memberof QueueStatistics
     * @instance
     */

    /**
     * Encodes the specified QueueStatistics message. Does not implicitly {@link QueueStatistics.verify|verify} messages.
     * @function encode
     * @memberof QueueStatistics
     * @static
     * @param {QueueStatistics} message QueueStatistics message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueueStatistics.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.CurCallsWaiting != null && Object.hasOwnProperty.call(message, "CurCallsWaiting"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.CurCallsWaiting);
        if (message.CurCallsPolling != null && Object.hasOwnProperty.call(message, "CurCallsPolling"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.CurCallsPolling);
        if (message.CurCallsRepolling != null && Object.hasOwnProperty.call(message, "CurCallsRepolling"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.CurCallsRepolling);
        if (message.CurCallsServicing != null && Object.hasOwnProperty.call(message, "CurCallsServicing"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.CurCallsServicing);
        if (message.TotalCallsIn != null && Object.hasOwnProperty.call(message, "TotalCallsIn"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.TotalCallsIn);
        if (message.TotalCallsServiced != null && Object.hasOwnProperty.call(message, "TotalCallsServiced"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.TotalCallsServiced);
        if (message.TotalCallsDroppedInWait != null && Object.hasOwnProperty.call(message, "TotalCallsDroppedInWait"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.TotalCallsDroppedInWait);
        if (message.TotalCallsDroppedInPoll != null && Object.hasOwnProperty.call(message, "TotalCallsDroppedInPoll"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.TotalCallsDroppedInPoll);
        if (message.TotalAgentsDialed != null && Object.hasOwnProperty.call(message, "TotalAgentsDialed"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.TotalAgentsDialed);
        if (message.TotalAgentsRefused != null && Object.hasOwnProperty.call(message, "TotalAgentsRefused"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.TotalAgentsRefused);
        if (message.TotalAgentCallsExpired != null && Object.hasOwnProperty.call(message, "TotalAgentCallsExpired"))
            writer.uint32(/* id 14, wireType 2 =*/114).string(message.TotalAgentCallsExpired);
        if (message.TotalCallsReachedMaxTime != null && Object.hasOwnProperty.call(message, "TotalCallsReachedMaxTime"))
            writer.uint32(/* id 15, wireType 2 =*/122).string(message.TotalCallsReachedMaxTime);
        if (message.TotalUserRequestedEnd != null && Object.hasOwnProperty.call(message, "TotalUserRequestedEnd"))
            writer.uint32(/* id 16, wireType 2 =*/130).string(message.TotalUserRequestedEnd);
        if (message.TotalNoAgentsEnded != null && Object.hasOwnProperty.call(message, "TotalNoAgentsEnded"))
            writer.uint32(/* id 17, wireType 2 =*/138).string(message.TotalNoAgentsEnded);
        if (message.TotalTransfersFailed != null && Object.hasOwnProperty.call(message, "TotalTransfersFailed"))
            writer.uint32(/* id 18, wireType 2 =*/146).string(message.TotalTransfersFailed);
        if (message.TotalCallbacksRequested != null && Object.hasOwnProperty.call(message, "TotalCallbacksRequested"))
            writer.uint32(/* id 19, wireType 2 =*/154).string(message.TotalCallbacksRequested);
        if (message.TotalCallbacksServiced != null && Object.hasOwnProperty.call(message, "TotalCallbacksServiced"))
            writer.uint32(/* id 20, wireType 2 =*/162).string(message.TotalCallbacksServiced);
        if (message.TotalCallbacksFailed != null && Object.hasOwnProperty.call(message, "TotalCallbacksFailed"))
            writer.uint32(/* id 21, wireType 2 =*/170).string(message.TotalCallbacksFailed);
        if (message.TotalWaitingTime != null && Object.hasOwnProperty.call(message, "TotalWaitingTime"))
            writer.uint32(/* id 22, wireType 2 =*/178).string(message.TotalWaitingTime);
        if (message.TotalServicingTime != null && Object.hasOwnProperty.call(message, "TotalServicingTime"))
            writer.uint32(/* id 23, wireType 2 =*/186).string(message.TotalServicingTime);
        if (message.LongestWaitingTime != null && Object.hasOwnProperty.call(message, "LongestWaitingTime"))
            writer.uint32(/* id 24, wireType 2 =*/194).string(message.LongestWaitingTime);
        if (message.TotalCallsEscalated != null && Object.hasOwnProperty.call(message, "TotalCallsEscalated"))
            writer.uint32(/* id 25, wireType 2 =*/202).string(message.TotalCallsEscalated);
        if (message.TotalCallsAbandoned != null && Object.hasOwnProperty.call(message, "TotalCallsAbandoned"))
            writer.uint32(/* id 26, wireType 2 =*/210).string(message.TotalCallsAbandoned);
        return writer;
    };

    /**
     * Decodes a QueueStatistics message from the specified reader or buffer.
     * @function decode
     * @memberof QueueStatistics
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {QueueStatistics} QueueStatistics
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueueStatistics.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.QueueStatistics();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 4: {
                    message.CurCallsWaiting = reader.string();
                    break;
                }
            case 5: {
                    message.CurCallsPolling = reader.string();
                    break;
                }
            case 6: {
                    message.CurCallsRepolling = reader.string();
                    break;
                }
            case 7: {
                    message.CurCallsServicing = reader.string();
                    break;
                }
            case 8: {
                    message.TotalCallsIn = reader.string();
                    break;
                }
            case 9: {
                    message.TotalCallsServiced = reader.string();
                    break;
                }
            case 10: {
                    message.TotalCallsDroppedInWait = reader.string();
                    break;
                }
            case 11: {
                    message.TotalCallsDroppedInPoll = reader.string();
                    break;
                }
            case 12: {
                    message.TotalAgentsDialed = reader.string();
                    break;
                }
            case 13: {
                    message.TotalAgentsRefused = reader.string();
                    break;
                }
            case 14: {
                    message.TotalAgentCallsExpired = reader.string();
                    break;
                }
            case 15: {
                    message.TotalCallsReachedMaxTime = reader.string();
                    break;
                }
            case 16: {
                    message.TotalUserRequestedEnd = reader.string();
                    break;
                }
            case 17: {
                    message.TotalNoAgentsEnded = reader.string();
                    break;
                }
            case 18: {
                    message.TotalTransfersFailed = reader.string();
                    break;
                }
            case 19: {
                    message.TotalCallbacksRequested = reader.string();
                    break;
                }
            case 20: {
                    message.TotalCallbacksServiced = reader.string();
                    break;
                }
            case 21: {
                    message.TotalCallbacksFailed = reader.string();
                    break;
                }
            case 22: {
                    message.TotalWaitingTime = reader.string();
                    break;
                }
            case 23: {
                    message.TotalServicingTime = reader.string();
                    break;
                }
            case 24: {
                    message.LongestWaitingTime = reader.string();
                    break;
                }
            case 25: {
                    message.TotalCallsEscalated = reader.string();
                    break;
                }
            case 26: {
                    message.TotalCallsAbandoned = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return QueueStatistics;
})();

export const QueueAgentStat = $root.QueueAgentStat = (() => {

    /**
     * Properties of a QueueAgentStat.
     * @exports IQueueAgentStat
     * @property {ActionType} Action QueueAgentStat Action
     * @property {string} [TotalTimesDialed] QueueAgentStat TotalTimesDialed
     * @property {string} [TotalTimesRefused] QueueAgentStat TotalTimesRefused
     * @property {string} [TotalTimesCallExpired] QueueAgentStat TotalTimesCallExpired
     * @property {string} [TotalCallsServiced] QueueAgentStat TotalCallsServiced
     * @property {string} [TotalTalkTime] QueueAgentStat TotalTalkTime
     * @property {string} [TotalCallsEscalated] QueueAgentStat TotalCallsEscalated
     * @property {string} [TotalLoggedInTime] QueueAgentStat TotalLoggedInTime
     * @property {string} [LastLogInUtc] QueueAgentStat LastLogInUtc
     * @property {string} [LastLogOutUtc] QueueAgentStat LastLogOutUtc
     */

    /**
     * Constructs a new QueueAgentStat.
     * @exports QueueAgentStat
     * @classdesc Represents a QueueAgentStat.
     * @constructor
     * @param {Partial<QueueAgentStat>} [properties] Properties to set
     */
    function QueueAgentStat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueueAgentStat Action.
     * @member {ActionType} Action
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * QueueAgentStat TotalTimesDialed.
     * @member {string} TotalTimesDialed
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * QueueAgentStat TotalTimesRefused.
     * @member {string} TotalTimesRefused
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * QueueAgentStat TotalTimesCallExpired.
     * @member {string} TotalTimesCallExpired
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * QueueAgentStat TotalCallsServiced.
     * @member {string} TotalCallsServiced
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * QueueAgentStat TotalTalkTime.
     * @member {string} TotalTalkTime
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * QueueAgentStat TotalCallsEscalated.
     * @member {string} TotalCallsEscalated
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * QueueAgentStat TotalLoggedInTime.
     * @member {string} TotalLoggedInTime
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * QueueAgentStat LastLogInUtc.
     * @member {string} LastLogInUtc
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * QueueAgentStat LastLogOutUtc.
     * @member {string} LastLogOutUtc
     * @memberof QueueAgentStat
     * @instance
     */

    /**
     * Encodes the specified QueueAgentStat message. Does not implicitly {@link QueueAgentStat.verify|verify} messages.
     * @function encode
     * @memberof QueueAgentStat
     * @static
     * @param {QueueAgentStat} message QueueAgentStat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueueAgentStat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.TotalTimesDialed != null && Object.hasOwnProperty.call(message, "TotalTimesDialed"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.TotalTimesDialed);
        if (message.TotalTimesRefused != null && Object.hasOwnProperty.call(message, "TotalTimesRefused"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.TotalTimesRefused);
        if (message.TotalTimesCallExpired != null && Object.hasOwnProperty.call(message, "TotalTimesCallExpired"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.TotalTimesCallExpired);
        if (message.TotalCallsServiced != null && Object.hasOwnProperty.call(message, "TotalCallsServiced"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.TotalCallsServiced);
        if (message.TotalTalkTime != null && Object.hasOwnProperty.call(message, "TotalTalkTime"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.TotalTalkTime);
        if (message.TotalCallsEscalated != null && Object.hasOwnProperty.call(message, "TotalCallsEscalated"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.TotalCallsEscalated);
        if (message.TotalLoggedInTime != null && Object.hasOwnProperty.call(message, "TotalLoggedInTime"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.TotalLoggedInTime);
        if (message.LastLogInUtc != null && Object.hasOwnProperty.call(message, "LastLogInUtc"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.LastLogInUtc);
        if (message.LastLogOutUtc != null && Object.hasOwnProperty.call(message, "LastLogOutUtc"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.LastLogOutUtc);
        return writer;
    };

    /**
     * Decodes a QueueAgentStat message from the specified reader or buffer.
     * @function decode
     * @memberof QueueAgentStat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {QueueAgentStat} QueueAgentStat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueueAgentStat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.QueueAgentStat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 3: {
                    message.TotalTimesDialed = reader.string();
                    break;
                }
            case 4: {
                    message.TotalTimesRefused = reader.string();
                    break;
                }
            case 5: {
                    message.TotalTimesCallExpired = reader.string();
                    break;
                }
            case 6: {
                    message.TotalCallsServiced = reader.string();
                    break;
                }
            case 7: {
                    message.TotalTalkTime = reader.string();
                    break;
                }
            case 8: {
                    message.TotalCallsEscalated = reader.string();
                    break;
                }
            case 9: {
                    message.TotalLoggedInTime = reader.string();
                    break;
                }
            case 10: {
                    message.LastLogInUtc = reader.string();
                    break;
                }
            case 11: {
                    message.LastLogOutUtc = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return QueueAgentStat;
})();

export const QueueAgent = $root.QueueAgent = (() => {

    /**
     * Properties of a QueueAgent.
     * @exports IQueueAgent
     * @property {ActionType} Action QueueAgent Action
     * @property {number} Id QueueAgent Id
     * @property {string} [Number] QueueAgent Number
     * @property {string} [FirstName] QueueAgent FirstName
     * @property {string} [LastName] QueueAgent LastName
     * @property {QueueAgentStat} [Stat] QueueAgent Stat
     * @property {LocalConnections} [ActiveCalls] QueueAgent ActiveCalls
     * @property {boolean} [QueueStatus] QueueAgent QueueStatus
     * @property {boolean} [Available] QueueAgent Available
     * @property {number} [Priority] QueueAgent Priority
     */

    /**
     * Constructs a new QueueAgent.
     * @exports QueueAgent
     * @classdesc Represents a QueueAgent.
     * @constructor
     * @param {Partial<QueueAgent>} [properties] Properties to set
     */
    function QueueAgent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueueAgent Action.
     * @member {ActionType} Action
     * @memberof QueueAgent
     * @instance
     */

    /**
     * QueueAgent Id.
     * @member {number} Id
     * @memberof QueueAgent
     * @instance
     */

    /**
     * QueueAgent Number.
     * @member {string} Number
     * @memberof QueueAgent
     * @instance
     */

    /**
     * QueueAgent FirstName.
     * @member {string} FirstName
     * @memberof QueueAgent
     * @instance
     */

    /**
     * QueueAgent LastName.
     * @member {string} LastName
     * @memberof QueueAgent
     * @instance
     */

    /**
     * QueueAgent Stat.
     * @member {QueueAgentStat} Stat
     * @memberof QueueAgent
     * @instance
     */

    /**
     * QueueAgent ActiveCalls.
     * @member {LocalConnections} ActiveCalls
     * @memberof QueueAgent
     * @instance
     */

    /**
     * QueueAgent QueueStatus.
     * @member {boolean} QueueStatus
     * @memberof QueueAgent
     * @instance
     */

    /**
     * QueueAgent Available.
     * @member {boolean} Available
     * @memberof QueueAgent
     * @instance
     */

    /**
     * QueueAgent Priority.
     * @member {number} Priority
     * @memberof QueueAgent
     * @instance
     */

    /**
     * Encodes the specified QueueAgent message. Does not implicitly {@link QueueAgent.verify|verify} messages.
     * @function encode
     * @memberof QueueAgent
     * @static
     * @param {QueueAgent} message QueueAgent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueueAgent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Number);
        if (message.FirstName != null && Object.hasOwnProperty.call(message, "FirstName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.FirstName);
        if (message.LastName != null && Object.hasOwnProperty.call(message, "LastName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.LastName);
        if (message.Stat != null && Object.hasOwnProperty.call(message, "Stat"))
            $root.QueueAgentStat.encode(message.Stat, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.ActiveCalls != null && Object.hasOwnProperty.call(message, "ActiveCalls"))
            $root.LocalConnections.encode(message.ActiveCalls, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.QueueStatus != null && Object.hasOwnProperty.call(message, "QueueStatus"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.QueueStatus);
        if (message.Available != null && Object.hasOwnProperty.call(message, "Available"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.Available);
        if (message.Priority != null && Object.hasOwnProperty.call(message, "Priority"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.Priority);
        return writer;
    };

    /**
     * Decodes a QueueAgent message from the specified reader or buffer.
     * @function decode
     * @memberof QueueAgent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {QueueAgent} QueueAgent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueueAgent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.QueueAgent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Number = reader.string();
                    break;
                }
            case 4: {
                    message.FirstName = reader.string();
                    break;
                }
            case 5: {
                    message.LastName = reader.string();
                    break;
                }
            case 6: {
                    message.Stat = $root.QueueAgentStat.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.ActiveCalls = $root.LocalConnections.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.QueueStatus = reader.bool();
                    break;
                }
            case 9: {
                    message.Available = reader.bool();
                    break;
                }
            case 10: {
                    message.Priority = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return QueueAgent;
})();

export const QueueAgents = $root.QueueAgents = (() => {

    /**
     * Properties of a QueueAgents.
     * @exports IQueueAgents
     * @property {ActionType} Action QueueAgents Action
     * @property {Array.<QueueAgent>} [Items] QueueAgents Items
     */

    /**
     * Constructs a new QueueAgents.
     * @exports QueueAgents
     * @classdesc Represents a QueueAgents.
     * @constructor
     * @param {Partial<QueueAgents>} [properties] Properties to set
     */
    function QueueAgents(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueueAgents Action.
     * @member {ActionType} Action
     * @memberof QueueAgents
     * @instance
     */

    /**
     * QueueAgents Items.
     * @member {Array.<QueueAgent>} Items
     * @memberof QueueAgents
     * @instance
     */
    QueueAgents.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified QueueAgents message. Does not implicitly {@link QueueAgents.verify|verify} messages.
     * @function encode
     * @memberof QueueAgents
     * @static
     * @param {QueueAgents} message QueueAgents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueueAgents.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.QueueAgent.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a QueueAgents message from the specified reader or buffer.
     * @function decode
     * @memberof QueueAgents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {QueueAgents} QueueAgents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueueAgents.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.QueueAgents();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.QueueAgent.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return QueueAgents;
})();

export const QueueStat = $root.QueueStat = (() => {

    /**
     * Properties of a QueueStat.
     * @exports IQueueStat
     * @property {ActionType} Action QueueStat Action
     * @property {number} Id QueueStat Id
     * @property {string} [Name] QueueStat Name
     * @property {string} [Number] QueueStat Number
     * @property {QueueAgents} [Agents] QueueStat Agents
     * @property {QueueStatistics} [Stat] QueueStat Stat
     * @property {LocalConnections} [WaitingCalls] QueueStat WaitingCalls
     * @property {LocalConnections} [PollingCalls] QueueStat PollingCalls
     * @property {LocalConnections} [CallBackCalls] QueueStat CallBackCalls
     * @property {boolean} [Available] QueueStat Available
     */

    /**
     * Constructs a new QueueStat.
     * @exports QueueStat
     * @classdesc Represents a QueueStat.
     * @constructor
     * @param {Partial<QueueStat>} [properties] Properties to set
     */
    function QueueStat(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * QueueStat Action.
     * @member {ActionType} Action
     * @memberof QueueStat
     * @instance
     */

    /**
     * QueueStat Id.
     * @member {number} Id
     * @memberof QueueStat
     * @instance
     */

    /**
     * QueueStat Name.
     * @member {string} Name
     * @memberof QueueStat
     * @instance
     */

    /**
     * QueueStat Number.
     * @member {string} Number
     * @memberof QueueStat
     * @instance
     */

    /**
     * QueueStat Agents.
     * @member {QueueAgents} Agents
     * @memberof QueueStat
     * @instance
     */

    /**
     * QueueStat Stat.
     * @member {QueueStatistics} Stat
     * @memberof QueueStat
     * @instance
     */

    /**
     * QueueStat WaitingCalls.
     * @member {LocalConnections} WaitingCalls
     * @memberof QueueStat
     * @instance
     */

    /**
     * QueueStat PollingCalls.
     * @member {LocalConnections} PollingCalls
     * @memberof QueueStat
     * @instance
     */

    /**
     * QueueStat CallBackCalls.
     * @member {LocalConnections} CallBackCalls
     * @memberof QueueStat
     * @instance
     */

    /**
     * QueueStat Available.
     * @member {boolean} Available
     * @memberof QueueStat
     * @instance
     */

    /**
     * Encodes the specified QueueStat message. Does not implicitly {@link QueueStat.verify|verify} messages.
     * @function encode
     * @memberof QueueStat
     * @static
     * @param {QueueStat} message QueueStat message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    QueueStat.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Name);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Number);
        if (message.Agents != null && Object.hasOwnProperty.call(message, "Agents"))
            $root.QueueAgents.encode(message.Agents, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.Stat != null && Object.hasOwnProperty.call(message, "Stat"))
            $root.QueueStatistics.encode(message.Stat, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.WaitingCalls != null && Object.hasOwnProperty.call(message, "WaitingCalls"))
            $root.LocalConnections.encode(message.WaitingCalls, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.PollingCalls != null && Object.hasOwnProperty.call(message, "PollingCalls"))
            $root.LocalConnections.encode(message.PollingCalls, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.CallBackCalls != null && Object.hasOwnProperty.call(message, "CallBackCalls"))
            $root.LocalConnections.encode(message.CallBackCalls, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.Available != null && Object.hasOwnProperty.call(message, "Available"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.Available);
        return writer;
    };

    /**
     * Decodes a QueueStat message from the specified reader or buffer.
     * @function decode
     * @memberof QueueStat
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {QueueStat} QueueStat
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    QueueStat.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.QueueStat();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Name = reader.string();
                    break;
                }
            case 4: {
                    message.Number = reader.string();
                    break;
                }
            case 5: {
                    message.Agents = $root.QueueAgents.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.Stat = $root.QueueStatistics.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.WaitingCalls = $root.LocalConnections.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.PollingCalls = $root.LocalConnections.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.CallBackCalls = $root.LocalConnections.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.Available = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return QueueStat;
})();

export const Queues = $root.Queues = (() => {

    /**
     * Properties of a Queues.
     * @exports IQueues
     * @property {ActionType} Action Queues Action
     * @property {Array.<QueueStat>} [Items] Queues Items
     */

    /**
     * Constructs a new Queues.
     * @exports Queues
     * @classdesc Represents a Queues.
     * @constructor
     * @param {Partial<Queues>} [properties] Properties to set
     */
    function Queues(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Queues Action.
     * @member {ActionType} Action
     * @memberof Queues
     * @instance
     */

    /**
     * Queues Items.
     * @member {Array.<QueueStat>} Items
     * @memberof Queues
     * @instance
     */
    Queues.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified Queues message. Does not implicitly {@link Queues.verify|verify} messages.
     * @function encode
     * @memberof Queues
     * @static
     * @param {Queues} message Queues message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Queues.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.QueueStat.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a Queues message from the specified reader or buffer.
     * @function decode
     * @memberof Queues
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Queues} Queues
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Queues.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Queues();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.QueueStat.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a Queues message to GenericMessage
     * @function toGenericMessage
     * @memberof Queues
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    Queues.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 211, QueuesInfo : this});
    };

    /**
     * Queues typeId
     * @member {number} typeId
     * @memberof Queues
     * @instance
     */
    Queues.prototype.typeId = 211;
    /**
     * Queues typeName
     * @member {string} typeName
     * @memberof Queues
     * @instance
     */
    Queues.prototype.typeName = "QueuesInfo";

    return Queues;
})();

export const IVRInfo = $root.IVRInfo = (() => {

    /**
     * Properties of a IVRInfo.
     * @exports IIVRInfo
     * @property {ActionType} Action IVRInfo Action
     * @property {number} Id IVRInfo Id
     * @property {string} [Name] IVRInfo Name
     * @property {string} [Number] IVRInfo Number
     * @property {LocalConnections} [WaitingCalls] IVRInfo WaitingCalls
     */

    /**
     * Constructs a new IVRInfo.
     * @exports IVRInfo
     * @classdesc Represents a IVRInfo.
     * @constructor
     * @param {Partial<IVRInfo>} [properties] Properties to set
     */
    function IVRInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IVRInfo Action.
     * @member {ActionType} Action
     * @memberof IVRInfo
     * @instance
     */

    /**
     * IVRInfo Id.
     * @member {number} Id
     * @memberof IVRInfo
     * @instance
     */

    /**
     * IVRInfo Name.
     * @member {string} Name
     * @memberof IVRInfo
     * @instance
     */

    /**
     * IVRInfo Number.
     * @member {string} Number
     * @memberof IVRInfo
     * @instance
     */

    /**
     * IVRInfo WaitingCalls.
     * @member {LocalConnections} WaitingCalls
     * @memberof IVRInfo
     * @instance
     */

    /**
     * Encodes the specified IVRInfo message. Does not implicitly {@link IVRInfo.verify|verify} messages.
     * @function encode
     * @memberof IVRInfo
     * @static
     * @param {IVRInfo} message IVRInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IVRInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Name);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Number);
        if (message.WaitingCalls != null && Object.hasOwnProperty.call(message, "WaitingCalls"))
            $root.LocalConnections.encode(message.WaitingCalls, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a IVRInfo message from the specified reader or buffer.
     * @function decode
     * @memberof IVRInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IVRInfo} IVRInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IVRInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IVRInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Name = reader.string();
                    break;
                }
            case 4: {
                    message.Number = reader.string();
                    break;
                }
            case 5: {
                    message.WaitingCalls = $root.LocalConnections.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return IVRInfo;
})();

export const IVRs = $root.IVRs = (() => {

    /**
     * Properties of a IVRs.
     * @exports IIVRs
     * @property {ActionType} Action IVRs Action
     * @property {Array.<IVRInfo>} [Items] IVRs Items
     */

    /**
     * Constructs a new IVRs.
     * @exports IVRs
     * @classdesc Represents a IVRs.
     * @constructor
     * @param {Partial<IVRs>} [properties] Properties to set
     */
    function IVRs(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * IVRs Action.
     * @member {ActionType} Action
     * @memberof IVRs
     * @instance
     */

    /**
     * IVRs Items.
     * @member {Array.<IVRInfo>} Items
     * @memberof IVRs
     * @instance
     */
    IVRs.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified IVRs message. Does not implicitly {@link IVRs.verify|verify} messages.
     * @function encode
     * @memberof IVRs
     * @static
     * @param {IVRs} message IVRs message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    IVRs.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.IVRInfo.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a IVRs message from the specified reader or buffer.
     * @function decode
     * @memberof IVRs
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {IVRs} IVRs
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    IVRs.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.IVRs();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.IVRInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a IVRs message to GenericMessage
     * @function toGenericMessage
     * @memberof IVRs
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    IVRs.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 213, IVRsInfo : this});
    };

    /**
     * IVRs typeId
     * @member {number} typeId
     * @memberof IVRs
     * @instance
     */
    IVRs.prototype.typeId = 213;
    /**
     * IVRs typeName
     * @member {string} typeName
     * @memberof IVRs
     * @instance
     */
    IVRs.prototype.typeName = "IVRsInfo";

    return IVRs;
})();

export const ParkingInfo = $root.ParkingInfo = (() => {

    /**
     * Properties of a ParkingInfo.
     * @exports IParkingInfo
     * @property {ActionType} Action ParkingInfo Action
     * @property {number} Id ParkingInfo Id
     * @property {string} [Name] ParkingInfo Name
     * @property {string} [Number] ParkingInfo Number
     * @property {LocalConnections} [WaitingCalls] ParkingInfo WaitingCalls
     */

    /**
     * Constructs a new ParkingInfo.
     * @exports ParkingInfo
     * @classdesc Represents a ParkingInfo.
     * @constructor
     * @param {Partial<ParkingInfo>} [properties] Properties to set
     */
    function ParkingInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ParkingInfo Action.
     * @member {ActionType} Action
     * @memberof ParkingInfo
     * @instance
     */

    /**
     * ParkingInfo Id.
     * @member {number} Id
     * @memberof ParkingInfo
     * @instance
     */

    /**
     * ParkingInfo Name.
     * @member {string} Name
     * @memberof ParkingInfo
     * @instance
     */

    /**
     * ParkingInfo Number.
     * @member {string} Number
     * @memberof ParkingInfo
     * @instance
     */

    /**
     * ParkingInfo WaitingCalls.
     * @member {LocalConnections} WaitingCalls
     * @memberof ParkingInfo
     * @instance
     */

    /**
     * Encodes the specified ParkingInfo message. Does not implicitly {@link ParkingInfo.verify|verify} messages.
     * @function encode
     * @memberof ParkingInfo
     * @static
     * @param {ParkingInfo} message ParkingInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ParkingInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Name);
        if (message.Number != null && Object.hasOwnProperty.call(message, "Number"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Number);
        if (message.WaitingCalls != null && Object.hasOwnProperty.call(message, "WaitingCalls"))
            $root.LocalConnections.encode(message.WaitingCalls, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ParkingInfo message from the specified reader or buffer.
     * @function decode
     * @memberof ParkingInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ParkingInfo} ParkingInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ParkingInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ParkingInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Name = reader.string();
                    break;
                }
            case 4: {
                    message.Number = reader.string();
                    break;
                }
            case 5: {
                    message.WaitingCalls = $root.LocalConnections.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ParkingInfo;
})();

export const Parkings = $root.Parkings = (() => {

    /**
     * Properties of a Parkings.
     * @exports IParkings
     * @property {ActionType} Action Parkings Action
     * @property {Array.<ParkingInfo>} [Items] Parkings Items
     */

    /**
     * Constructs a new Parkings.
     * @exports Parkings
     * @classdesc Represents a Parkings.
     * @constructor
     * @param {Partial<Parkings>} [properties] Properties to set
     */
    function Parkings(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Parkings Action.
     * @member {ActionType} Action
     * @memberof Parkings
     * @instance
     */

    /**
     * Parkings Items.
     * @member {Array.<ParkingInfo>} Items
     * @memberof Parkings
     * @instance
     */
    Parkings.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified Parkings message. Does not implicitly {@link Parkings.verify|verify} messages.
     * @function encode
     * @memberof Parkings
     * @static
     * @param {Parkings} message Parkings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Parkings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.ParkingInfo.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a Parkings message from the specified reader or buffer.
     * @function decode
     * @memberof Parkings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Parkings} Parkings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Parkings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Parkings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.ParkingInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a Parkings message to GenericMessage
     * @function toGenericMessage
     * @memberof Parkings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    Parkings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 212, ParkingsInfo : this});
    };

    /**
     * Parkings typeId
     * @member {number} typeId
     * @memberof Parkings
     * @instance
     */
    Parkings.prototype.typeId = 212;
    /**
     * Parkings typeName
     * @member {string} typeName
     * @memberof Parkings
     * @instance
     */
    Parkings.prototype.typeName = "ParkingsInfo";

    return Parkings;
})();

export const RequestConnectionAccessRights = $root.RequestConnectionAccessRights = (() => {

    /**
     * Properties of a RequestConnectionAccessRights.
     * @exports IRequestConnectionAccessRights
     * @property {number} LocalConnectionId RequestConnectionAccessRights LocalConnectionId
     */

    /**
     * Constructs a new RequestConnectionAccessRights.
     * @exports RequestConnectionAccessRights
     * @classdesc Represents a RequestConnectionAccessRights.
     * @constructor
     * @param {Partial<RequestConnectionAccessRights>} [properties] Properties to set
     */
    function RequestConnectionAccessRights(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestConnectionAccessRights LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestConnectionAccessRights
     * @instance
     */

    /**
     * Encodes the specified RequestConnectionAccessRights message. Does not implicitly {@link RequestConnectionAccessRights.verify|verify} messages.
     * @function encode
     * @memberof RequestConnectionAccessRights
     * @static
     * @param {RequestConnectionAccessRights} message RequestConnectionAccessRights message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestConnectionAccessRights.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        return writer;
    };

    /**
     * Decodes a RequestConnectionAccessRights message from the specified reader or buffer.
     * @function decode
     * @memberof RequestConnectionAccessRights
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestConnectionAccessRights} RequestConnectionAccessRights
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestConnectionAccessRights.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestConnectionAccessRights();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestConnectionAccessRights message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestConnectionAccessRights
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestConnectionAccessRights.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 124, GetAccessRights : this});
    };

    /**
     * RequestConnectionAccessRights typeId
     * @member {number} typeId
     * @memberof RequestConnectionAccessRights
     * @instance
     */
    RequestConnectionAccessRights.prototype.typeId = 124;
    /**
     * RequestConnectionAccessRights typeName
     * @member {string} typeName
     * @memberof RequestConnectionAccessRights
     * @instance
     */
    RequestConnectionAccessRights.prototype.typeName = "GetAccessRights";

    return RequestConnectionAccessRights;
})();

export const ResponseConnectionAccessRights = $root.ResponseConnectionAccessRights = (() => {

    /**
     * Properties of a ResponseConnectionAccessRights.
     * @exports IResponseConnectionAccessRights
     * @property {number} ConnectionCapabilities ResponseConnectionAccessRights ConnectionCapabilities
     */

    /**
     * Constructs a new ResponseConnectionAccessRights.
     * @exports ResponseConnectionAccessRights
     * @classdesc Represents a ResponseConnectionAccessRights.
     * @constructor
     * @param {Partial<ResponseConnectionAccessRights>} [properties] Properties to set
     */
    function ResponseConnectionAccessRights(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseConnectionAccessRights ConnectionCapabilities.
     * @member {number} ConnectionCapabilities
     * @memberof ResponseConnectionAccessRights
     * @instance
     */

    /**
     * Encodes the specified ResponseConnectionAccessRights message. Does not implicitly {@link ResponseConnectionAccessRights.verify|verify} messages.
     * @function encode
     * @memberof ResponseConnectionAccessRights
     * @static
     * @param {ResponseConnectionAccessRights} message ResponseConnectionAccessRights message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseConnectionAccessRights.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.ConnectionCapabilities);
        return writer;
    };

    /**
     * Decodes a ResponseConnectionAccessRights message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseConnectionAccessRights
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseConnectionAccessRights} ResponseConnectionAccessRights
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseConnectionAccessRights.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseConnectionAccessRights();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ConnectionCapabilities = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseConnectionAccessRights message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseConnectionAccessRights
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseConnectionAccessRights.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 214, ConnectionCapabilityMask : this});
    };

    /**
     * ResponseConnectionAccessRights typeId
     * @member {number} typeId
     * @memberof ResponseConnectionAccessRights
     * @instance
     */
    ResponseConnectionAccessRights.prototype.typeId = 214;
    /**
     * ResponseConnectionAccessRights typeName
     * @member {string} typeName
     * @memberof ResponseConnectionAccessRights
     * @instance
     */
    ResponseConnectionAccessRights.prototype.typeName = "ConnectionCapabilityMask";

    return ResponseConnectionAccessRights;
})();

export const RequestResetMyMissedCalls = $root.RequestResetMyMissedCalls = (() => {

    /**
     * Properties of a RequestResetMyMissedCalls.
     * @exports IRequestResetMyMissedCalls
     */

    /**
     * Constructs a new RequestResetMyMissedCalls.
     * @exports RequestResetMyMissedCalls
     * @classdesc Represents a RequestResetMyMissedCalls.
     * @constructor
     * @param {Partial<RequestResetMyMissedCalls>} [properties] Properties to set
     */
    function RequestResetMyMissedCalls(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestResetMyMissedCalls message. Does not implicitly {@link RequestResetMyMissedCalls.verify|verify} messages.
     * @function encode
     * @memberof RequestResetMyMissedCalls
     * @static
     * @param {RequestResetMyMissedCalls} message RequestResetMyMissedCalls message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestResetMyMissedCalls.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestResetMyMissedCalls message from the specified reader or buffer.
     * @function decode
     * @memberof RequestResetMyMissedCalls
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestResetMyMissedCalls} RequestResetMyMissedCalls
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestResetMyMissedCalls.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestResetMyMissedCalls();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestResetMyMissedCalls message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestResetMyMissedCalls
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestResetMyMissedCalls.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 125, ResetMyMissedCalls : this});
    };

    /**
     * RequestResetMyMissedCalls typeId
     * @member {number} typeId
     * @memberof RequestResetMyMissedCalls
     * @instance
     */
    RequestResetMyMissedCalls.prototype.typeId = 125;
    /**
     * RequestResetMyMissedCalls typeName
     * @member {string} typeName
     * @memberof RequestResetMyMissedCalls
     * @instance
     */
    RequestResetMyMissedCalls.prototype.typeName = "ResetMyMissedCalls";

    return RequestResetMyMissedCalls;
})();

export const ResponseExtensionsChanged = $root.ResponseExtensionsChanged = (() => {

    /**
     * Properties of a ResponseExtensionsChanged.
     * @exports IResponseExtensionsChanged
     * @property {DnType} [Type] ResponseExtensionsChanged Type
     * @property {Contact} [Update] ResponseExtensionsChanged Update
     */

    /**
     * Constructs a new ResponseExtensionsChanged.
     * @exports ResponseExtensionsChanged
     * @classdesc Represents a ResponseExtensionsChanged.
     * @constructor
     * @param {Partial<ResponseExtensionsChanged>} [properties] Properties to set
     */
    function ResponseExtensionsChanged(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseExtensionsChanged Type.
     * @member {DnType} Type
     * @memberof ResponseExtensionsChanged
     * @instance
     */

    /**
     * ResponseExtensionsChanged Update.
     * @member {Contact} Update
     * @memberof ResponseExtensionsChanged
     * @instance
     */

    /**
     * Encodes the specified ResponseExtensionsChanged message. Does not implicitly {@link ResponseExtensionsChanged.verify|verify} messages.
     * @function encode
     * @memberof ResponseExtensionsChanged
     * @static
     * @param {ResponseExtensionsChanged} message ResponseExtensionsChanged message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseExtensionsChanged.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Type);
        if (message.Update != null && Object.hasOwnProperty.call(message, "Update"))
            $root.Contact.encode(message.Update, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseExtensionsChanged message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseExtensionsChanged
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseExtensionsChanged} ResponseExtensionsChanged
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseExtensionsChanged.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseExtensionsChanged();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Type = reader.int32();
                    break;
                }
            case 2: {
                    message.Update = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseExtensionsChanged message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseExtensionsChanged
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseExtensionsChanged.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 215, ExtensionsChangedEvent : this});
    };

    /**
     * ResponseExtensionsChanged typeId
     * @member {number} typeId
     * @memberof ResponseExtensionsChanged
     * @instance
     */
    ResponseExtensionsChanged.prototype.typeId = 215;
    /**
     * ResponseExtensionsChanged typeName
     * @member {string} typeName
     * @memberof ResponseExtensionsChanged
     * @instance
     */
    ResponseExtensionsChanged.prototype.typeName = "ExtensionsChangedEvent";

    return ResponseExtensionsChanged;
})();

export const RequestGetFolder = $root.RequestGetFolder = (() => {

    /**
     * Properties of a RequestGetFolder.
     * @exports IRequestGetFolder
     * @property {UsersFolder} [Folder] RequestGetFolder Folder
     * @property {number} [IdGroup] RequestGetFolder IdGroup
     */

    /**
     * Constructs a new RequestGetFolder.
     * @exports RequestGetFolder
     * @classdesc Represents a RequestGetFolder.
     * @constructor
     * @param {Partial<RequestGetFolder>} [properties] Properties to set
     */
    function RequestGetFolder(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetFolder Folder.
     * @member {UsersFolder} Folder
     * @memberof RequestGetFolder
     * @instance
     */

    /**
     * RequestGetFolder IdGroup.
     * @member {number} IdGroup
     * @memberof RequestGetFolder
     * @instance
     */

    /**
     * Encodes the specified RequestGetFolder message. Does not implicitly {@link RequestGetFolder.verify|verify} messages.
     * @function encode
     * @memberof RequestGetFolder
     * @static
     * @param {RequestGetFolder} message RequestGetFolder message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetFolder.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Folder != null && Object.hasOwnProperty.call(message, "Folder"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Folder);
        if (message.IdGroup != null && Object.hasOwnProperty.call(message, "IdGroup"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.IdGroup);
        return writer;
    };

    /**
     * Decodes a RequestGetFolder message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetFolder
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetFolder} RequestGetFolder
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetFolder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetFolder();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Folder = reader.int32();
                    break;
                }
            case 2: {
                    message.IdGroup = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetFolder message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetFolder
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetFolder.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 128, GetFolderRequest : this});
    };

    /**
     * RequestGetFolder typeId
     * @member {number} typeId
     * @memberof RequestGetFolder
     * @instance
     */
    RequestGetFolder.prototype.typeId = 128;
    /**
     * RequestGetFolder typeName
     * @member {string} typeName
     * @memberof RequestGetFolder
     * @instance
     */
    RequestGetFolder.prototype.typeName = "GetFolderRequest";

    return RequestGetFolder;
})();

export const ResponseGetFolder = $root.ResponseGetFolder = (() => {

    /**
     * Properties of a ResponseGetFolder.
     * @exports IResponseGetFolder
     * @property {UsersFolder} [Folder] ResponseGetFolder Folder
     * @property {Array.<string>} [Files] ResponseGetFolder Files
     */

    /**
     * Constructs a new ResponseGetFolder.
     * @exports ResponseGetFolder
     * @classdesc Represents a ResponseGetFolder.
     * @constructor
     * @param {Partial<ResponseGetFolder>} [properties] Properties to set
     */
    function ResponseGetFolder(properties) {
        this.Files = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseGetFolder Folder.
     * @member {UsersFolder} Folder
     * @memberof ResponseGetFolder
     * @instance
     */

    /**
     * ResponseGetFolder Files.
     * @member {Array.<string>} Files
     * @memberof ResponseGetFolder
     * @instance
     */
    ResponseGetFolder.prototype.Files = $util.emptyArray;

    /**
     * Encodes the specified ResponseGetFolder message. Does not implicitly {@link ResponseGetFolder.verify|verify} messages.
     * @function encode
     * @memberof ResponseGetFolder
     * @static
     * @param {ResponseGetFolder} message ResponseGetFolder message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGetFolder.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Folder != null && Object.hasOwnProperty.call(message, "Folder"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Folder);
        if (message.Files != null && message.Files.length)
            for (let i = 0; i < message.Files.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.Files[i]);
        return writer;
    };

    /**
     * Decodes a ResponseGetFolder message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseGetFolder
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseGetFolder} ResponseGetFolder
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGetFolder.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseGetFolder();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Folder = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Files && message.Files.length))
                        message.Files = [];
                    message.Files.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseGetFolder message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseGetFolder
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseGetFolder.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 216, GetFolderResponse : this});
    };

    /**
     * ResponseGetFolder typeId
     * @member {number} typeId
     * @memberof ResponseGetFolder
     * @instance
     */
    ResponseGetFolder.prototype.typeId = 216;
    /**
     * ResponseGetFolder typeName
     * @member {string} typeName
     * @memberof ResponseGetFolder
     * @instance
     */
    ResponseGetFolder.prototype.typeName = "GetFolderResponse";

    return ResponseGetFolder;
})();

export const RequestDeleteFile = $root.RequestDeleteFile = (() => {

    /**
     * Properties of a RequestDeleteFile.
     * @exports IRequestDeleteFile
     * @property {UsersFolder} [Folder] RequestDeleteFile Folder
     * @property {string} [File] RequestDeleteFile File
     */

    /**
     * Constructs a new RequestDeleteFile.
     * @exports RequestDeleteFile
     * @classdesc Represents a RequestDeleteFile.
     * @constructor
     * @param {Partial<RequestDeleteFile>} [properties] Properties to set
     */
    function RequestDeleteFile(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestDeleteFile Folder.
     * @member {UsersFolder} Folder
     * @memberof RequestDeleteFile
     * @instance
     */

    /**
     * RequestDeleteFile File.
     * @member {string} File
     * @memberof RequestDeleteFile
     * @instance
     */

    /**
     * Encodes the specified RequestDeleteFile message. Does not implicitly {@link RequestDeleteFile.verify|verify} messages.
     * @function encode
     * @memberof RequestDeleteFile
     * @static
     * @param {RequestDeleteFile} message RequestDeleteFile message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestDeleteFile.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Folder != null && Object.hasOwnProperty.call(message, "Folder"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Folder);
        if (message.File != null && Object.hasOwnProperty.call(message, "File"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.File);
        return writer;
    };

    /**
     * Decodes a RequestDeleteFile message from the specified reader or buffer.
     * @function decode
     * @memberof RequestDeleteFile
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestDeleteFile} RequestDeleteFile
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestDeleteFile.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestDeleteFile();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Folder = reader.int32();
                    break;
                }
            case 2: {
                    message.File = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestDeleteFile message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestDeleteFile
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestDeleteFile.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 129, DeleteFile : this});
    };

    /**
     * RequestDeleteFile typeId
     * @member {number} typeId
     * @memberof RequestDeleteFile
     * @instance
     */
    RequestDeleteFile.prototype.typeId = 129;
    /**
     * RequestDeleteFile typeName
     * @member {string} typeName
     * @memberof RequestDeleteFile
     * @instance
     */
    RequestDeleteFile.prototype.typeName = "DeleteFile";

    return RequestDeleteFile;
})();

export const RequestPlayRecording = $root.RequestPlayRecording = (() => {

    /**
     * Properties of a RequestPlayRecording.
     * @exports IRequestPlayRecording
     * @property {string} [File] RequestPlayRecording File
     * @property {number} [Id] RequestPlayRecording Id
     */

    /**
     * Constructs a new RequestPlayRecording.
     * @exports RequestPlayRecording
     * @classdesc Represents a RequestPlayRecording.
     * @constructor
     * @param {Partial<RequestPlayRecording>} [properties] Properties to set
     */
    function RequestPlayRecording(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestPlayRecording File.
     * @member {string} File
     * @memberof RequestPlayRecording
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * RequestPlayRecording Id.
     * @member {number} Id
     * @memberof RequestPlayRecording
     * @instance
     */

    /**
     * Encodes the specified RequestPlayRecording message. Does not implicitly {@link RequestPlayRecording.verify|verify} messages.
     * @function encode
     * @memberof RequestPlayRecording
     * @static
     * @param {RequestPlayRecording} message RequestPlayRecording message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestPlayRecording.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.File != null && Object.hasOwnProperty.call(message, "File"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.File);
        if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        return writer;
    };

    /**
     * Decodes a RequestPlayRecording message from the specified reader or buffer.
     * @function decode
     * @memberof RequestPlayRecording
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestPlayRecording} RequestPlayRecording
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestPlayRecording.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestPlayRecording();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.File = reader.string();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestPlayRecording message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestPlayRecording
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestPlayRecording.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 130, PlayRec : this});
    };

    /**
     * RequestPlayRecording typeId
     * @member {number} typeId
     * @memberof RequestPlayRecording
     * @instance
     */
    RequestPlayRecording.prototype.typeId = 130;
    /**
     * RequestPlayRecording typeName
     * @member {string} typeName
     * @memberof RequestPlayRecording
     * @instance
     */
    RequestPlayRecording.prototype.typeName = "PlayRec";

    return RequestPlayRecording;
})();

export const ResponseContactChanged = $root.ResponseContactChanged = (() => {

    /**
     * Properties of a ResponseContactChanged.
     * @exports IResponseContactChanged
     * @property {Contact} [Update] ResponseContactChanged Update
     */

    /**
     * Constructs a new ResponseContactChanged.
     * @exports ResponseContactChanged
     * @classdesc Represents a ResponseContactChanged.
     * @constructor
     * @param {Partial<ResponseContactChanged>} [properties] Properties to set
     */
    function ResponseContactChanged(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseContactChanged Update.
     * @member {Contact} Update
     * @memberof ResponseContactChanged
     * @instance
     */

    /**
     * Encodes the specified ResponseContactChanged message. Does not implicitly {@link ResponseContactChanged.verify|verify} messages.
     * @function encode
     * @memberof ResponseContactChanged
     * @static
     * @param {ResponseContactChanged} message ResponseContactChanged message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseContactChanged.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Update != null && Object.hasOwnProperty.call(message, "Update"))
            $root.Contact.encode(message.Update, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseContactChanged message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseContactChanged
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseContactChanged} ResponseContactChanged
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseContactChanged.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseContactChanged();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Update = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseContactChanged message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseContactChanged
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseContactChanged.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 217, ContactChangedEvent : this});
    };

    /**
     * ResponseContactChanged typeId
     * @member {number} typeId
     * @memberof ResponseContactChanged
     * @instance
     */
    ResponseContactChanged.prototype.typeId = 217;
    /**
     * ResponseContactChanged typeName
     * @member {string} typeName
     * @memberof ResponseContactChanged
     * @instance
     */
    ResponseContactChanged.prototype.typeName = "ContactChangedEvent";

    return ResponseContactChanged;
})();

export const RequestServerTime = $root.RequestServerTime = (() => {

    /**
     * Properties of a RequestServerTime.
     * @exports IRequestServerTime
     */

    /**
     * Constructs a new RequestServerTime.
     * @exports RequestServerTime
     * @classdesc Represents a RequestServerTime.
     * @constructor
     * @param {Partial<RequestServerTime>} [properties] Properties to set
     */
    function RequestServerTime(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestServerTime message. Does not implicitly {@link RequestServerTime.verify|verify} messages.
     * @function encode
     * @memberof RequestServerTime
     * @static
     * @param {RequestServerTime} message RequestServerTime message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestServerTime.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestServerTime message from the specified reader or buffer.
     * @function decode
     * @memberof RequestServerTime
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestServerTime} RequestServerTime
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestServerTime.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestServerTime();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestServerTime message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestServerTime
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestServerTime.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 131, ServerTimeRequest : this});
    };

    /**
     * RequestServerTime typeId
     * @member {number} typeId
     * @memberof RequestServerTime
     * @instance
     */
    RequestServerTime.prototype.typeId = 131;
    /**
     * RequestServerTime typeName
     * @member {string} typeName
     * @memberof RequestServerTime
     * @instance
     */
    RequestServerTime.prototype.typeName = "ServerTimeRequest";

    return RequestServerTime;
})();

export const ResponseServerTime = $root.ResponseServerTime = (() => {

    /**
     * Properties of a ResponseServerTime.
     * @exports IResponseServerTime
     * @property {DateTime} [ArrivedAtServerLocalUTCtime] ResponseServerTime ArrivedAtServerLocalUTCtime
     * @property {DateTime} [SentAtServerLocalUTCtime] ResponseServerTime SentAtServerLocalUTCtime
     * @property {number} [ArrivedAtMSecs] ResponseServerTime ArrivedAtMSecs
     * @property {number} [SentAtMSecs] ResponseServerTime SentAtMSecs
     */

    /**
     * Constructs a new ResponseServerTime.
     * @exports ResponseServerTime
     * @classdesc Represents a ResponseServerTime.
     * @constructor
     * @param {Partial<ResponseServerTime>} [properties] Properties to set
     */
    function ResponseServerTime(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseServerTime ArrivedAtServerLocalUTCtime.
     * @member {DateTime} ArrivedAtServerLocalUTCtime
     * @memberof ResponseServerTime
     * @instance
     */

    /**
     * ResponseServerTime SentAtServerLocalUTCtime.
     * @member {DateTime} SentAtServerLocalUTCtime
     * @memberof ResponseServerTime
     * @instance
     */

    /**
     * ResponseServerTime ArrivedAtMSecs.
     * @member {number} ArrivedAtMSecs
     * @memberof ResponseServerTime
     * @instance
     */

    /**
     * ResponseServerTime SentAtMSecs.
     * @member {number} SentAtMSecs
     * @memberof ResponseServerTime
     * @instance
     */

    /**
     * Encodes the specified ResponseServerTime message. Does not implicitly {@link ResponseServerTime.verify|verify} messages.
     * @function encode
     * @memberof ResponseServerTime
     * @static
     * @param {ResponseServerTime} message ResponseServerTime message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseServerTime.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ArrivedAtServerLocalUTCtime != null && Object.hasOwnProperty.call(message, "ArrivedAtServerLocalUTCtime"))
            $root.DateTime.encode(message.ArrivedAtServerLocalUTCtime, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.SentAtServerLocalUTCtime != null && Object.hasOwnProperty.call(message, "SentAtServerLocalUTCtime"))
            $root.DateTime.encode(message.SentAtServerLocalUTCtime, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.ArrivedAtMSecs != null && Object.hasOwnProperty.call(message, "ArrivedAtMSecs"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ArrivedAtMSecs);
        if (message.SentAtMSecs != null && Object.hasOwnProperty.call(message, "SentAtMSecs"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.SentAtMSecs);
        return writer;
    };

    /**
     * Decodes a ResponseServerTime message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseServerTime
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseServerTime} ResponseServerTime
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseServerTime.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseServerTime();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ArrivedAtServerLocalUTCtime = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.SentAtServerLocalUTCtime = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.ArrivedAtMSecs = reader.int32();
                    break;
                }
            case 4: {
                    message.SentAtMSecs = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseServerTime message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseServerTime
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseServerTime.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 218, ServerTimeResponse : this});
    };

    /**
     * ResponseServerTime typeId
     * @member {number} typeId
     * @memberof ResponseServerTime
     * @instance
     */
    ResponseServerTime.prototype.typeId = 218;
    /**
     * ResponseServerTime typeName
     * @member {string} typeName
     * @memberof ResponseServerTime
     * @instance
     */
    ResponseServerTime.prototype.typeName = "ServerTimeResponse";

    return ResponseServerTime;
})();

export const RequestSetQueueStatus = $root.RequestSetQueueStatus = (() => {

    /**
     * Properties of a RequestSetQueueStatus.
     * @exports IRequestSetQueueStatus
     * @property {number} QueueId RequestSetQueueStatus QueueId
     * @property {number} [AgentId] RequestSetQueueStatus AgentId
     * @property {boolean} NewQueueStatus RequestSetQueueStatus NewQueueStatus
     */

    /**
     * Constructs a new RequestSetQueueStatus.
     * @exports RequestSetQueueStatus
     * @classdesc Represents a RequestSetQueueStatus.
     * @constructor
     * @param {Partial<RequestSetQueueStatus>} [properties] Properties to set
     */
    function RequestSetQueueStatus(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetQueueStatus QueueId.
     * @member {number} QueueId
     * @memberof RequestSetQueueStatus
     * @instance
     */

    /**
     * RequestSetQueueStatus AgentId.
     * @member {number} AgentId
     * @memberof RequestSetQueueStatus
     * @instance
     */

    /**
     * RequestSetQueueStatus NewQueueStatus.
     * @member {boolean} NewQueueStatus
     * @memberof RequestSetQueueStatus
     * @instance
     */

    /**
     * Encodes the specified RequestSetQueueStatus message. Does not implicitly {@link RequestSetQueueStatus.verify|verify} messages.
     * @function encode
     * @memberof RequestSetQueueStatus
     * @static
     * @param {RequestSetQueueStatus} message RequestSetQueueStatus message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetQueueStatus.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.QueueId);
        if (message.AgentId != null && Object.hasOwnProperty.call(message, "AgentId"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.AgentId);
        writer.uint32(/* id 3, wireType 0 =*/24).bool(message.NewQueueStatus);
        return writer;
    };

    /**
     * Decodes a RequestSetQueueStatus message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetQueueStatus
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetQueueStatus} RequestSetQueueStatus
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetQueueStatus.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetQueueStatus();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.QueueId = reader.int32();
                    break;
                }
            case 2: {
                    message.AgentId = reader.int32();
                    break;
                }
            case 3: {
                    message.NewQueueStatus = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetQueueStatus message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetQueueStatus
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetQueueStatus.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 132, SetQueueStatus : this});
    };

    /**
     * RequestSetQueueStatus typeId
     * @member {number} typeId
     * @memberof RequestSetQueueStatus
     * @instance
     */
    RequestSetQueueStatus.prototype.typeId = 132;
    /**
     * RequestSetQueueStatus typeName
     * @member {string} typeName
     * @memberof RequestSetQueueStatus
     * @instance
     */
    RequestSetQueueStatus.prototype.typeName = "SetQueueStatus";

    return RequestSetQueueStatus;
})();

export const RequestRecordCall = $root.RequestRecordCall = (() => {

    /**
     * Properties of a RequestRecordCall.
     * @exports IRequestRecordCall
     * @property {number} LocalConnectionId RequestRecordCall LocalConnectionId
     * @property {boolean} IsLocal RequestRecordCall IsLocal
     * @property {string} Folder RequestRecordCall Folder
     */

    /**
     * Constructs a new RequestRecordCall.
     * @exports RequestRecordCall
     * @classdesc Represents a RequestRecordCall.
     * @constructor
     * @param {Partial<RequestRecordCall>} [properties] Properties to set
     */
    function RequestRecordCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestRecordCall LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestRecordCall
     * @instance
     */

    /**
     * RequestRecordCall IsLocal.
     * @member {boolean} IsLocal
     * @memberof RequestRecordCall
     * @instance
     */

    /**
     * RequestRecordCall Folder.
     * @member {string} Folder
     * @memberof RequestRecordCall
     * @instance
     */

    /**
     * Encodes the specified RequestRecordCall message. Does not implicitly {@link RequestRecordCall.verify|verify} messages.
     * @function encode
     * @memberof RequestRecordCall
     * @static
     * @param {RequestRecordCall} message RequestRecordCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRecordCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsLocal);
        writer.uint32(/* id 3, wireType 2 =*/26).string(message.Folder);
        return writer;
    };

    /**
     * Decodes a RequestRecordCall message from the specified reader or buffer.
     * @function decode
     * @memberof RequestRecordCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestRecordCall} RequestRecordCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRecordCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestRecordCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            case 2: {
                    message.IsLocal = reader.bool();
                    break;
                }
            case 3: {
                    message.Folder = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestRecordCall message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestRecordCall
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestRecordCall.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 137, RecordCall : this});
    };

    /**
     * RequestRecordCall typeId
     * @member {number} typeId
     * @memberof RequestRecordCall
     * @instance
     */
    RequestRecordCall.prototype.typeId = 137;
    /**
     * RequestRecordCall typeName
     * @member {string} typeName
     * @memberof RequestRecordCall
     * @instance
     */
    RequestRecordCall.prototype.typeName = "RecordCall";

    return RequestRecordCall;
})();

export const RequestControlCallRecording = $root.RequestControlCallRecording = (() => {

    /**
     * Properties of a RequestControlCallRecording.
     * @exports IRequestControlCallRecording
     * @property {number} LocalConnectionId RequestControlCallRecording LocalConnectionId
     * @property {boolean} IsLocal RequestControlCallRecording IsLocal
     * @property {RecordingAction} RecAction RequestControlCallRecording RecAction
     * @property {string} [Folder] RequestControlCallRecording Folder
     */

    /**
     * Constructs a new RequestControlCallRecording.
     * @exports RequestControlCallRecording
     * @classdesc Represents a RequestControlCallRecording.
     * @constructor
     * @param {Partial<RequestControlCallRecording>} [properties] Properties to set
     */
    function RequestControlCallRecording(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestControlCallRecording LocalConnectionId.
     * @member {number} LocalConnectionId
     * @memberof RequestControlCallRecording
     * @instance
     */

    /**
     * RequestControlCallRecording IsLocal.
     * @member {boolean} IsLocal
     * @memberof RequestControlCallRecording
     * @instance
     */

    /**
     * RequestControlCallRecording RecAction.
     * @member {RecordingAction} RecAction
     * @memberof RequestControlCallRecording
     * @instance
     */

    /**
     * RequestControlCallRecording Folder.
     * @member {string} Folder
     * @memberof RequestControlCallRecording
     * @instance
     */

    /**
     * Encodes the specified RequestControlCallRecording message. Does not implicitly {@link RequestControlCallRecording.verify|verify} messages.
     * @function encode
     * @memberof RequestControlCallRecording
     * @static
     * @param {RequestControlCallRecording} message RequestControlCallRecording message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestControlCallRecording.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.LocalConnectionId);
        writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsLocal);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.RecAction);
        if (message.Folder != null && Object.hasOwnProperty.call(message, "Folder"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Folder);
        return writer;
    };

    /**
     * Decodes a RequestControlCallRecording message from the specified reader or buffer.
     * @function decode
     * @memberof RequestControlCallRecording
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestControlCallRecording} RequestControlCallRecording
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestControlCallRecording.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestControlCallRecording();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.LocalConnectionId = reader.int32();
                    break;
                }
            case 2: {
                    message.IsLocal = reader.bool();
                    break;
                }
            case 3: {
                    message.RecAction = reader.int32();
                    break;
                }
            case 4: {
                    message.Folder = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestControlCallRecording message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestControlCallRecording
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestControlCallRecording.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 167, RecordingControl : this});
    };

    /**
     * RequestControlCallRecording typeId
     * @member {number} typeId
     * @memberof RequestControlCallRecording
     * @instance
     */
    RequestControlCallRecording.prototype.typeId = 167;
    /**
     * RequestControlCallRecording typeName
     * @member {string} typeName
     * @memberof RequestControlCallRecording
     * @instance
     */
    RequestControlCallRecording.prototype.typeName = "RecordingControl";

    return RequestControlCallRecording;
})();

export const RequestGetMyRights = $root.RequestGetMyRights = (() => {

    /**
     * Properties of a RequestGetMyRights.
     * @exports IRequestGetMyRights
     */

    /**
     * Constructs a new RequestGetMyRights.
     * @exports RequestGetMyRights
     * @classdesc Represents a RequestGetMyRights.
     * @constructor
     * @param {Partial<RequestGetMyRights>} [properties] Properties to set
     */
    function RequestGetMyRights(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestGetMyRights message. Does not implicitly {@link RequestGetMyRights.verify|verify} messages.
     * @function encode
     * @memberof RequestGetMyRights
     * @static
     * @param {RequestGetMyRights} message RequestGetMyRights message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetMyRights.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestGetMyRights message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetMyRights
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetMyRights} RequestGetMyRights
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetMyRights.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetMyRights();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetMyRights message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetMyRights
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetMyRights.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 140, GetMyRights : this});
    };

    /**
     * RequestGetMyRights typeId
     * @member {number} typeId
     * @memberof RequestGetMyRights
     * @instance
     */
    RequestGetMyRights.prototype.typeId = 140;
    /**
     * RequestGetMyRights typeName
     * @member {string} typeName
     * @memberof RequestGetMyRights
     * @instance
     */
    RequestGetMyRights.prototype.typeName = "GetMyRights";

    return RequestGetMyRights;
})();

export const ResponseGetMyRights = $root.ResponseGetMyRights = (() => {

    /**
     * Properties of a ResponseGetMyRights.
     * @exports IResponseGetMyRights
     * @property {boolean} [CanModifyCompanyBook] ResponseGetMyRights CanModifyCompanyBook
     */

    /**
     * Constructs a new ResponseGetMyRights.
     * @exports ResponseGetMyRights
     * @classdesc Represents a ResponseGetMyRights.
     * @constructor
     * @param {Partial<ResponseGetMyRights>} [properties] Properties to set
     */
    function ResponseGetMyRights(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseGetMyRights CanModifyCompanyBook.
     * @member {boolean} CanModifyCompanyBook
     * @memberof ResponseGetMyRights
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * Encodes the specified ResponseGetMyRights message. Does not implicitly {@link ResponseGetMyRights.verify|verify} messages.
     * @function encode
     * @memberof ResponseGetMyRights
     * @static
     * @param {ResponseGetMyRights} message ResponseGetMyRights message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGetMyRights.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.CanModifyCompanyBook != null && Object.hasOwnProperty.call(message, "CanModifyCompanyBook"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.CanModifyCompanyBook);
        return writer;
    };

    /**
     * Decodes a ResponseGetMyRights message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseGetMyRights
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseGetMyRights} ResponseGetMyRights
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGetMyRights.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseGetMyRights();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.CanModifyCompanyBook = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseGetMyRights message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseGetMyRights
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseGetMyRights.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 221, MyRights : this});
    };

    /**
     * ResponseGetMyRights typeId
     * @member {number} typeId
     * @memberof ResponseGetMyRights
     * @instance
     */
    ResponseGetMyRights.prototype.typeId = 221;
    /**
     * ResponseGetMyRights typeName
     * @member {string} typeName
     * @memberof ResponseGetMyRights
     * @instance
     */
    ResponseGetMyRights.prototype.typeName = "MyRights";

    return ResponseGetMyRights;
})();

export const RequestGetGreetings = $root.RequestGetGreetings = (() => {

    /**
     * Properties of a RequestGetGreetings.
     * @exports IRequestGetGreetings
     */

    /**
     * Constructs a new RequestGetGreetings.
     * @exports RequestGetGreetings
     * @classdesc Represents a RequestGetGreetings.
     * @constructor
     * @param {Partial<RequestGetGreetings>} [properties] Properties to set
     */
    function RequestGetGreetings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestGetGreetings message. Does not implicitly {@link RequestGetGreetings.verify|verify} messages.
     * @function encode
     * @memberof RequestGetGreetings
     * @static
     * @param {RequestGetGreetings} message RequestGetGreetings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetGreetings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestGetGreetings message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetGreetings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetGreetings} RequestGetGreetings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetGreetings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetGreetings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetGreetings message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetGreetings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetGreetings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 141, GetGreetings : this});
    };

    /**
     * RequestGetGreetings typeId
     * @member {number} typeId
     * @memberof RequestGetGreetings
     * @instance
     */
    RequestGetGreetings.prototype.typeId = 141;
    /**
     * RequestGetGreetings typeName
     * @member {string} typeName
     * @memberof RequestGetGreetings
     * @instance
     */
    RequestGetGreetings.prototype.typeName = "GetGreetings";

    return RequestGetGreetings;
})();

export const RequestSetGreetings = $root.RequestSetGreetings = (() => {

    /**
     * Properties of a RequestSetGreetings.
     * @exports IRequestSetGreetings
     * @property {string} [Default] RequestSetGreetings Default
     * @property {string} [Available] RequestSetGreetings Available
     * @property {string} [Away] RequestSetGreetings Away
     * @property {string} [OutOfOffice] RequestSetGreetings OutOfOffice
     * @property {string} [Custom1] RequestSetGreetings Custom1
     * @property {string} [Custom2] RequestSetGreetings Custom2
     */

    /**
     * Constructs a new RequestSetGreetings.
     * @exports RequestSetGreetings
     * @classdesc Represents a RequestSetGreetings.
     * @constructor
     * @param {Partial<RequestSetGreetings>} [properties] Properties to set
     */
    function RequestSetGreetings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetGreetings Default.
     * @member {string} Default
     * @memberof RequestSetGreetings
     * @instance
     */

    /**
     * RequestSetGreetings Available.
     * @member {string} Available
     * @memberof RequestSetGreetings
     * @instance
     */

    /**
     * RequestSetGreetings Away.
     * @member {string} Away
     * @memberof RequestSetGreetings
     * @instance
     */

    /**
     * RequestSetGreetings OutOfOffice.
     * @member {string} OutOfOffice
     * @memberof RequestSetGreetings
     * @instance
     */

    /**
     * RequestSetGreetings Custom1.
     * @member {string} Custom1
     * @memberof RequestSetGreetings
     * @instance
     */

    /**
     * RequestSetGreetings Custom2.
     * @member {string} Custom2
     * @memberof RequestSetGreetings
     * @instance
     */

    /**
     * Encodes the specified RequestSetGreetings message. Does not implicitly {@link RequestSetGreetings.verify|verify} messages.
     * @function encode
     * @memberof RequestSetGreetings
     * @static
     * @param {RequestSetGreetings} message RequestSetGreetings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetGreetings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Default != null && Object.hasOwnProperty.call(message, "Default"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Default);
        if (message.Available != null && Object.hasOwnProperty.call(message, "Available"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Available);
        if (message.Away != null && Object.hasOwnProperty.call(message, "Away"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Away);
        if (message.OutOfOffice != null && Object.hasOwnProperty.call(message, "OutOfOffice"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.OutOfOffice);
        if (message.Custom1 != null && Object.hasOwnProperty.call(message, "Custom1"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Custom1);
        if (message.Custom2 != null && Object.hasOwnProperty.call(message, "Custom2"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Custom2);
        return writer;
    };

    /**
     * Decodes a RequestSetGreetings message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetGreetings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetGreetings} RequestSetGreetings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetGreetings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetGreetings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Default = reader.string();
                    break;
                }
            case 2: {
                    message.Available = reader.string();
                    break;
                }
            case 3: {
                    message.Away = reader.string();
                    break;
                }
            case 4: {
                    message.OutOfOffice = reader.string();
                    break;
                }
            case 5: {
                    message.Custom1 = reader.string();
                    break;
                }
            case 6: {
                    message.Custom2 = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetGreetings message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetGreetings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetGreetings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 142, SetGreetings : this});
    };

    /**
     * RequestSetGreetings typeId
     * @member {number} typeId
     * @memberof RequestSetGreetings
     * @instance
     */
    RequestSetGreetings.prototype.typeId = 142;
    /**
     * RequestSetGreetings typeName
     * @member {string} typeName
     * @memberof RequestSetGreetings
     * @instance
     */
    RequestSetGreetings.prototype.typeName = "SetGreetings";

    return RequestSetGreetings;
})();

export const ResponseGreetings = $root.ResponseGreetings = (() => {

    /**
     * Properties of a ResponseGreetings.
     * @exports IResponseGreetings
     * @property {string} [Default] ResponseGreetings Default
     * @property {string} [Available] ResponseGreetings Available
     * @property {string} [Away] ResponseGreetings Away
     * @property {string} [OutOfOffice] ResponseGreetings OutOfOffice
     * @property {string} [Custom1] ResponseGreetings Custom1
     * @property {string} [Custom2] ResponseGreetings Custom2
     */

    /**
     * Constructs a new ResponseGreetings.
     * @exports ResponseGreetings
     * @classdesc Represents a ResponseGreetings.
     * @constructor
     * @param {Partial<ResponseGreetings>} [properties] Properties to set
     */
    function ResponseGreetings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseGreetings Default.
     * @member {string} Default
     * @memberof ResponseGreetings
     * @instance
     */

    /**
     * ResponseGreetings Available.
     * @member {string} Available
     * @memberof ResponseGreetings
     * @instance
     */

    /**
     * ResponseGreetings Away.
     * @member {string} Away
     * @memberof ResponseGreetings
     * @instance
     */

    /**
     * ResponseGreetings OutOfOffice.
     * @member {string} OutOfOffice
     * @memberof ResponseGreetings
     * @instance
     */

    /**
     * ResponseGreetings Custom1.
     * @member {string} Custom1
     * @memberof ResponseGreetings
     * @instance
     */

    /**
     * ResponseGreetings Custom2.
     * @member {string} Custom2
     * @memberof ResponseGreetings
     * @instance
     */

    /**
     * Encodes the specified ResponseGreetings message. Does not implicitly {@link ResponseGreetings.verify|verify} messages.
     * @function encode
     * @memberof ResponseGreetings
     * @static
     * @param {ResponseGreetings} message ResponseGreetings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGreetings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Default != null && Object.hasOwnProperty.call(message, "Default"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Default);
        if (message.Available != null && Object.hasOwnProperty.call(message, "Available"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Available);
        if (message.Away != null && Object.hasOwnProperty.call(message, "Away"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Away);
        if (message.OutOfOffice != null && Object.hasOwnProperty.call(message, "OutOfOffice"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.OutOfOffice);
        if (message.Custom1 != null && Object.hasOwnProperty.call(message, "Custom1"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Custom1);
        if (message.Custom2 != null && Object.hasOwnProperty.call(message, "Custom2"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Custom2);
        return writer;
    };

    /**
     * Decodes a ResponseGreetings message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseGreetings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseGreetings} ResponseGreetings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGreetings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseGreetings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Default = reader.string();
                    break;
                }
            case 2: {
                    message.Available = reader.string();
                    break;
                }
            case 3: {
                    message.Away = reader.string();
                    break;
                }
            case 4: {
                    message.OutOfOffice = reader.string();
                    break;
                }
            case 5: {
                    message.Custom1 = reader.string();
                    break;
                }
            case 6: {
                    message.Custom2 = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseGreetings message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseGreetings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseGreetings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 222, Greetings : this});
    };

    /**
     * ResponseGreetings typeId
     * @member {number} typeId
     * @memberof ResponseGreetings
     * @instance
     */
    ResponseGreetings.prototype.typeId = 222;
    /**
     * ResponseGreetings typeName
     * @member {string} typeName
     * @memberof ResponseGreetings
     * @instance
     */
    ResponseGreetings.prototype.typeName = "Greetings";

    return ResponseGreetings;
})();

export const RequestPlayVMGreetingOnPhone = $root.RequestPlayVMGreetingOnPhone = (() => {

    /**
     * Properties of a RequestPlayVMGreetingOnPhone.
     * @exports IRequestPlayVMGreetingOnPhone
     * @property {string} [FileName] RequestPlayVMGreetingOnPhone FileName
     */

    /**
     * Constructs a new RequestPlayVMGreetingOnPhone.
     * @exports RequestPlayVMGreetingOnPhone
     * @classdesc Represents a RequestPlayVMGreetingOnPhone.
     * @constructor
     * @param {Partial<RequestPlayVMGreetingOnPhone>} [properties] Properties to set
     */
    function RequestPlayVMGreetingOnPhone(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestPlayVMGreetingOnPhone FileName.
     * @member {string} FileName
     * @memberof RequestPlayVMGreetingOnPhone
     * @instance
     */

    /**
     * Encodes the specified RequestPlayVMGreetingOnPhone message. Does not implicitly {@link RequestPlayVMGreetingOnPhone.verify|verify} messages.
     * @function encode
     * @memberof RequestPlayVMGreetingOnPhone
     * @static
     * @param {RequestPlayVMGreetingOnPhone} message RequestPlayVMGreetingOnPhone message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestPlayVMGreetingOnPhone.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.FileName != null && Object.hasOwnProperty.call(message, "FileName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.FileName);
        return writer;
    };

    /**
     * Decodes a RequestPlayVMGreetingOnPhone message from the specified reader or buffer.
     * @function decode
     * @memberof RequestPlayVMGreetingOnPhone
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestPlayVMGreetingOnPhone} RequestPlayVMGreetingOnPhone
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestPlayVMGreetingOnPhone.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestPlayVMGreetingOnPhone();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.FileName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestPlayVMGreetingOnPhone message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestPlayVMGreetingOnPhone
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestPlayVMGreetingOnPhone.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 143, PlayVMGreetingOnPhone : this});
    };

    /**
     * RequestPlayVMGreetingOnPhone typeId
     * @member {number} typeId
     * @memberof RequestPlayVMGreetingOnPhone
     * @instance
     */
    RequestPlayVMGreetingOnPhone.prototype.typeId = 143;
    /**
     * RequestPlayVMGreetingOnPhone typeName
     * @member {string} typeName
     * @memberof RequestPlayVMGreetingOnPhone
     * @instance
     */
    RequestPlayVMGreetingOnPhone.prototype.typeName = "PlayVMGreetingOnPhone";

    return RequestPlayVMGreetingOnPhone;
})();

export const RequestRecordVMGreetingFromPhone = $root.RequestRecordVMGreetingFromPhone = (() => {

    /**
     * Properties of a RequestRecordVMGreetingFromPhone.
     * @exports IRequestRecordVMGreetingFromPhone
     * @property {string} [FileName] RequestRecordVMGreetingFromPhone FileName
     */

    /**
     * Constructs a new RequestRecordVMGreetingFromPhone.
     * @exports RequestRecordVMGreetingFromPhone
     * @classdesc Represents a RequestRecordVMGreetingFromPhone.
     * @constructor
     * @param {Partial<RequestRecordVMGreetingFromPhone>} [properties] Properties to set
     */
    function RequestRecordVMGreetingFromPhone(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestRecordVMGreetingFromPhone FileName.
     * @member {string} FileName
     * @memberof RequestRecordVMGreetingFromPhone
     * @instance
     */

    /**
     * Encodes the specified RequestRecordVMGreetingFromPhone message. Does not implicitly {@link RequestRecordVMGreetingFromPhone.verify|verify} messages.
     * @function encode
     * @memberof RequestRecordVMGreetingFromPhone
     * @static
     * @param {RequestRecordVMGreetingFromPhone} message RequestRecordVMGreetingFromPhone message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRecordVMGreetingFromPhone.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.FileName != null && Object.hasOwnProperty.call(message, "FileName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.FileName);
        return writer;
    };

    /**
     * Decodes a RequestRecordVMGreetingFromPhone message from the specified reader or buffer.
     * @function decode
     * @memberof RequestRecordVMGreetingFromPhone
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestRecordVMGreetingFromPhone} RequestRecordVMGreetingFromPhone
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRecordVMGreetingFromPhone.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestRecordVMGreetingFromPhone();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.FileName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestRecordVMGreetingFromPhone message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestRecordVMGreetingFromPhone
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestRecordVMGreetingFromPhone.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 144, RecordVMGreetingFromPhone : this});
    };

    /**
     * RequestRecordVMGreetingFromPhone typeId
     * @member {number} typeId
     * @memberof RequestRecordVMGreetingFromPhone
     * @instance
     */
    RequestRecordVMGreetingFromPhone.prototype.typeId = 144;
    /**
     * RequestRecordVMGreetingFromPhone typeName
     * @member {string} typeName
     * @memberof RequestRecordVMGreetingFromPhone
     * @instance
     */
    RequestRecordVMGreetingFromPhone.prototype.typeName = "RecordVMGreetingFromPhone";

    return RequestRecordVMGreetingFromPhone;
})();

export const RequestUpdateWebRtcInboundRules = $root.RequestUpdateWebRtcInboundRules = (() => {

    /**
     * Properties of a RequestUpdateWebRtcInboundRules.
     * @exports IRequestUpdateWebRtcInboundRules
     * @property {ActionType} Action RequestUpdateWebRtcInboundRules Action
     * @property {Array.<WebRtcInboundRule>} [Items] RequestUpdateWebRtcInboundRules Items
     */

    /**
     * Constructs a new RequestUpdateWebRtcInboundRules.
     * @exports RequestUpdateWebRtcInboundRules
     * @classdesc Represents a RequestUpdateWebRtcInboundRules.
     * @constructor
     * @param {Partial<RequestUpdateWebRtcInboundRules>} [properties] Properties to set
     */
    function RequestUpdateWebRtcInboundRules(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateWebRtcInboundRules Action.
     * @member {ActionType} Action
     * @memberof RequestUpdateWebRtcInboundRules
     * @instance
     */

    /**
     * RequestUpdateWebRtcInboundRules Items.
     * @member {Array.<WebRtcInboundRule>} Items
     * @memberof RequestUpdateWebRtcInboundRules
     * @instance
     */
    RequestUpdateWebRtcInboundRules.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified RequestUpdateWebRtcInboundRules message. Does not implicitly {@link RequestUpdateWebRtcInboundRules.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateWebRtcInboundRules
     * @static
     * @param {RequestUpdateWebRtcInboundRules} message RequestUpdateWebRtcInboundRules message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateWebRtcInboundRules.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.WebRtcInboundRule.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestUpdateWebRtcInboundRules message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateWebRtcInboundRules
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateWebRtcInboundRules} RequestUpdateWebRtcInboundRules
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateWebRtcInboundRules.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateWebRtcInboundRules();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.WebRtcInboundRule.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateWebRtcInboundRules message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateWebRtcInboundRules
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateWebRtcInboundRules.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 150, UpdateWebRtcInboundRules : this});
    };

    /**
     * RequestUpdateWebRtcInboundRules typeId
     * @member {number} typeId
     * @memberof RequestUpdateWebRtcInboundRules
     * @instance
     */
    RequestUpdateWebRtcInboundRules.prototype.typeId = 150;
    /**
     * RequestUpdateWebRtcInboundRules typeName
     * @member {string} typeName
     * @memberof RequestUpdateWebRtcInboundRules
     * @instance
     */
    RequestUpdateWebRtcInboundRules.prototype.typeName = "UpdateWebRtcInboundRules";

    return RequestUpdateWebRtcInboundRules;
})();

export const RequestWelcomeEmail = $root.RequestWelcomeEmail = (() => {

    /**
     * Properties of a RequestWelcomeEmail.
     * @exports IRequestWelcomeEmail
     */

    /**
     * Constructs a new RequestWelcomeEmail.
     * @exports RequestWelcomeEmail
     * @classdesc Represents a RequestWelcomeEmail.
     * @constructor
     * @param {Partial<RequestWelcomeEmail>} [properties] Properties to set
     */
    function RequestWelcomeEmail(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestWelcomeEmail message. Does not implicitly {@link RequestWelcomeEmail.verify|verify} messages.
     * @function encode
     * @memberof RequestWelcomeEmail
     * @static
     * @param {RequestWelcomeEmail} message RequestWelcomeEmail message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestWelcomeEmail.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestWelcomeEmail message from the specified reader or buffer.
     * @function decode
     * @memberof RequestWelcomeEmail
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestWelcomeEmail} RequestWelcomeEmail
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestWelcomeEmail.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestWelcomeEmail();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestWelcomeEmail message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestWelcomeEmail
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestWelcomeEmail.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 151, SendWelcomeEmail : this});
    };

    /**
     * RequestWelcomeEmail typeId
     * @member {number} typeId
     * @memberof RequestWelcomeEmail
     * @instance
     */
    RequestWelcomeEmail.prototype.typeId = 151;
    /**
     * RequestWelcomeEmail typeName
     * @member {string} typeName
     * @memberof RequestWelcomeEmail
     * @instance
     */
    RequestWelcomeEmail.prototype.typeName = "SendWelcomeEmail";

    return RequestWelcomeEmail;
})();

export const RequestWmReinviteOrganizer = $root.RequestWmReinviteOrganizer = (() => {

    /**
     * Properties of a RequestWmReinviteOrganizer.
     * @exports IRequestWmReinviteOrganizer
     * @property {string} [ParticipantName] RequestWmReinviteOrganizer ParticipantName
     * @property {number} [MeetingRowId] RequestWmReinviteOrganizer MeetingRowId
     */

    /**
     * Constructs a new RequestWmReinviteOrganizer.
     * @exports RequestWmReinviteOrganizer
     * @classdesc Represents a RequestWmReinviteOrganizer.
     * @constructor
     * @param {Partial<RequestWmReinviteOrganizer>} [properties] Properties to set
     */
    function RequestWmReinviteOrganizer(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestWmReinviteOrganizer ParticipantName.
     * @member {string} ParticipantName
     * @memberof RequestWmReinviteOrganizer
     * @instance
     */

    /**
     * RequestWmReinviteOrganizer MeetingRowId.
     * @member {number} MeetingRowId
     * @memberof RequestWmReinviteOrganizer
     * @instance
     */

    /**
     * Encodes the specified RequestWmReinviteOrganizer message. Does not implicitly {@link RequestWmReinviteOrganizer.verify|verify} messages.
     * @function encode
     * @memberof RequestWmReinviteOrganizer
     * @static
     * @param {RequestWmReinviteOrganizer} message RequestWmReinviteOrganizer message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestWmReinviteOrganizer.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ParticipantName != null && Object.hasOwnProperty.call(message, "ParticipantName"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ParticipantName);
        if (message.MeetingRowId != null && Object.hasOwnProperty.call(message, "MeetingRowId"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.MeetingRowId);
        return writer;
    };

    /**
     * Decodes a RequestWmReinviteOrganizer message from the specified reader or buffer.
     * @function decode
     * @memberof RequestWmReinviteOrganizer
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestWmReinviteOrganizer} RequestWmReinviteOrganizer
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestWmReinviteOrganizer.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestWmReinviteOrganizer();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ParticipantName = reader.string();
                    break;
                }
            case 2: {
                    message.MeetingRowId = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestWmReinviteOrganizer message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestWmReinviteOrganizer
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestWmReinviteOrganizer.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 160, ReinviteOrganizer : this});
    };

    /**
     * RequestWmReinviteOrganizer typeId
     * @member {number} typeId
     * @memberof RequestWmReinviteOrganizer
     * @instance
     */
    RequestWmReinviteOrganizer.prototype.typeId = 160;
    /**
     * RequestWmReinviteOrganizer typeName
     * @member {string} typeName
     * @memberof RequestWmReinviteOrganizer
     * @instance
     */
    RequestWmReinviteOrganizer.prototype.typeName = "ReinviteOrganizer";

    return RequestWmReinviteOrganizer;
})();

export const RequestSetSharedBlfs = $root.RequestSetSharedBlfs = (() => {

    /**
     * Properties of a RequestSetSharedBlfs.
     * @exports IRequestSetSharedBlfs
     * @property {string} [SharedBlfs] RequestSetSharedBlfs SharedBlfs
     */

    /**
     * Constructs a new RequestSetSharedBlfs.
     * @exports RequestSetSharedBlfs
     * @classdesc Represents a RequestSetSharedBlfs.
     * @constructor
     * @param {Partial<RequestSetSharedBlfs>} [properties] Properties to set
     */
    function RequestSetSharedBlfs(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetSharedBlfs SharedBlfs.
     * @member {string} SharedBlfs
     * @memberof RequestSetSharedBlfs
     * @instance
     */

    /**
     * Encodes the specified RequestSetSharedBlfs message. Does not implicitly {@link RequestSetSharedBlfs.verify|verify} messages.
     * @function encode
     * @memberof RequestSetSharedBlfs
     * @static
     * @param {RequestSetSharedBlfs} message RequestSetSharedBlfs message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetSharedBlfs.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.SharedBlfs != null && Object.hasOwnProperty.call(message, "SharedBlfs"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.SharedBlfs);
        return writer;
    };

    /**
     * Decodes a RequestSetSharedBlfs message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetSharedBlfs
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetSharedBlfs} RequestSetSharedBlfs
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetSharedBlfs.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetSharedBlfs();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.SharedBlfs = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetSharedBlfs message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetSharedBlfs
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetSharedBlfs.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 161, SetSharedBlfs : this});
    };

    /**
     * RequestSetSharedBlfs typeId
     * @member {number} typeId
     * @memberof RequestSetSharedBlfs
     * @instance
     */
    RequestSetSharedBlfs.prototype.typeId = 161;
    /**
     * RequestSetSharedBlfs typeName
     * @member {string} typeName
     * @memberof RequestSetSharedBlfs
     * @instance
     */
    RequestSetSharedBlfs.prototype.typeName = "SetSharedBlfs";

    return RequestSetSharedBlfs;
})();

export const MyWebRTCEndpoint = $root.MyWebRTCEndpoint = (() => {

    /**
     * Properties of a MyWebRTCEndpoint.
     * @exports IMyWebRTCEndpoint
     * @property {ActionType} Action MyWebRTCEndpoint Action
     * @property {Array.<WebRTCCall>} [Items] MyWebRTCEndpoint Items
     * @property {boolean} [isWebRTCEnpointRegistered] MyWebRTCEndpoint isWebRTCEnpointRegistered
     * @property {string} [DeviceContact] MyWebRTCEndpoint DeviceContact
     */

    /**
     * Constructs a new MyWebRTCEndpoint.
     * @exports MyWebRTCEndpoint
     * @classdesc ======================================================================
     * @constructor
     * @param {Partial<MyWebRTCEndpoint>} [properties] Properties to set
     */
    function MyWebRTCEndpoint(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MyWebRTCEndpoint Action.
     * @member {ActionType} Action
     * @memberof MyWebRTCEndpoint
     * @instance
     */

    /**
     * MyWebRTCEndpoint Items.
     * @member {Array.<WebRTCCall>} Items
     * @memberof MyWebRTCEndpoint
     * @instance
     */
    MyWebRTCEndpoint.prototype.Items = $util.emptyArray;

    /**
     * MyWebRTCEndpoint isWebRTCEnpointRegistered.
     * @member {boolean} isWebRTCEnpointRegistered
     * @memberof MyWebRTCEndpoint
     * @instance
     */

    /**
     * MyWebRTCEndpoint DeviceContact.
     * @member {string} DeviceContact
     * @memberof MyWebRTCEndpoint
     * @instance
     */

    /**
     * Encodes the specified MyWebRTCEndpoint message. Does not implicitly {@link MyWebRTCEndpoint.verify|verify} messages.
     * @function encode
     * @memberof MyWebRTCEndpoint
     * @static
     * @param {MyWebRTCEndpoint} message MyWebRTCEndpoint message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MyWebRTCEndpoint.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.WebRTCCall.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.isWebRTCEnpointRegistered != null && Object.hasOwnProperty.call(message, "isWebRTCEnpointRegistered"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.isWebRTCEnpointRegistered);
        if (message.DeviceContact != null && Object.hasOwnProperty.call(message, "DeviceContact"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.DeviceContact);
        return writer;
    };

    /**
     * Decodes a MyWebRTCEndpoint message from the specified reader or buffer.
     * @function decode
     * @memberof MyWebRTCEndpoint
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MyWebRTCEndpoint} MyWebRTCEndpoint
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MyWebRTCEndpoint.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MyWebRTCEndpoint();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.WebRTCCall.decode(reader, reader.uint32()));
                    break;
                }
            case 3: {
                    message.isWebRTCEnpointRegistered = reader.bool();
                    break;
                }
            case 4: {
                    message.DeviceContact = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a MyWebRTCEndpoint message to GenericMessage
     * @function toGenericMessage
     * @memberof MyWebRTCEndpoint
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    MyWebRTCEndpoint.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 227, webRTCEndpoint : this});
    };

    /**
     * MyWebRTCEndpoint typeId
     * @member {number} typeId
     * @memberof MyWebRTCEndpoint
     * @instance
     */
    MyWebRTCEndpoint.prototype.typeId = 227;
    /**
     * MyWebRTCEndpoint typeName
     * @member {string} typeName
     * @memberof MyWebRTCEndpoint
     * @instance
     */
    MyWebRTCEndpoint.prototype.typeName = "webRTCEndpoint";

    return MyWebRTCEndpoint;
})();

/**
 * WebRTCEndpointSDPState enum.
 * @exports WebRTCEndpointSDPState
 * @enum {string}
 * @property {number} WRTCTerminate=0 WRTCTerminate value
 * @property {number} WRTCOffer=1 WRTCOffer value
 * @property {number} WRTCAnswer=2 WRTCAnswer value
 * @property {number} WRTCConfirm=3 WRTCConfirm value
 * @property {number} WRTCRequestForOffer=4 WRTCRequestForOffer value
 * @property {number} WRTCReject=5 WRTCReject value
 * @property {number} WRTCProcessingOffer=6 WRTCProcessingOffer value
 * @property {number} WRTCPreparingOffer=7 WRTCPreparingOffer value
 * @property {number} WRTCAnswerProvided=8 WRTCAnswerProvided value
 * @property {number} WRTCConfirmed=9 WRTCConfirmed value
 * @property {number} WRTCInitial=10 WRTCInitial value
 */
export const WebRTCEndpointSDPState = $root.WebRTCEndpointSDPState = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "WRTCTerminate"] = 0;
    values[valuesById[1] = "WRTCOffer"] = 1;
    values[valuesById[2] = "WRTCAnswer"] = 2;
    values[valuesById[3] = "WRTCConfirm"] = 3;
    values[valuesById[4] = "WRTCRequestForOffer"] = 4;
    values[valuesById[5] = "WRTCReject"] = 5;
    values[valuesById[6] = "WRTCProcessingOffer"] = 6;
    values[valuesById[7] = "WRTCPreparingOffer"] = 7;
    values[valuesById[8] = "WRTCAnswerProvided"] = 8;
    values[valuesById[9] = "WRTCConfirmed"] = 9;
    values[valuesById[10] = "WRTCInitial"] = 10;
    return values;
})();

/**
 * WebRTCHoldState enum.
 * @exports WebRTCHoldState
 * @enum {string}
 * @property {number} WebRTCHoldState_NOHOLD=0 WebRTCHoldState_NOHOLD value
 * @property {number} WebRTCHoldState_HELD=1 WebRTCHoldState_HELD value
 * @property {number} WebRTCHoldState_HOLD=2 WebRTCHoldState_HOLD value
 * @property {number} WebRTCHoldState_BOTH=3 WebRTCHoldState_BOTH value
 */
export const WebRTCHoldState = $root.WebRTCHoldState = (() => {
    const valuesById = {}, values = Object.create(valuesById);
    values[valuesById[0] = "WebRTCHoldState_NOHOLD"] = 0;
    values[valuesById[1] = "WebRTCHoldState_HELD"] = 1;
    values[valuesById[2] = "WebRTCHoldState_HOLD"] = 2;
    values[valuesById[3] = "WebRTCHoldState_BOTH"] = 3;
    return values;
})();

export const WebRTCCall = $root.WebRTCCall = (() => {

    /**
     * Properties of a WebRTCCall.
     * @exports IWebRTCCall
     * @property {ActionType} Action WebRTCCall Action
     * @property {number} Id WebRTCCall Id
     * @property {WebRTCEndpointSDPState} sdpType WebRTCCall sdpType
     * @property {string} [otherPartyDisplayname] WebRTCCall otherPartyDisplayname
     * @property {string} [otherPartyNumber] WebRTCCall otherPartyNumber
     * @property {number} [transactionId] WebRTCCall transactionId
     * @property {string} [sdp] WebRTCCall sdp
     * @property {string} [SIPDialogID] WebRTCCall SIPDialogID
     * @property {WebRTCHoldState} [holdState] WebRTCCall holdState
     */

    /**
     * Constructs a new WebRTCCall.
     * @exports WebRTCCall
     * @classdesc Represents a WebRTCCall.
     * @constructor
     * @param {Partial<WebRTCCall>} [properties] Properties to set
     */
    function WebRTCCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebRTCCall Action.
     * @member {ActionType} Action
     * @memberof WebRTCCall
     * @instance
     */

    /**
     * WebRTCCall Id.
     * @member {number} Id
     * @memberof WebRTCCall
     * @instance
     */

    /**
     * WebRTCCall sdpType.
     * @member {WebRTCEndpointSDPState} sdpType
     * @memberof WebRTCCall
     * @instance
     */

    /**
     * WebRTCCall otherPartyDisplayname.
     * @member {string} otherPartyDisplayname
     * @memberof WebRTCCall
     * @instance
     */

    /**
     * WebRTCCall otherPartyNumber.
     * @member {string} otherPartyNumber
     * @memberof WebRTCCall
     * @instance
     */

    /**
     * WebRTCCall transactionId.
     * @member {number} transactionId
     * @memberof WebRTCCall
     * @instance
     */

    /**
     * WebRTCCall sdp.
     * @member {string} sdp
     * @memberof WebRTCCall
     * @instance
     */

    /**
     * WebRTCCall SIPDialogID.
     * @member {string} SIPDialogID
     * @memberof WebRTCCall
     * @instance
     */

    /**
     * WebRTCCall holdState.
     * @member {WebRTCHoldState} holdState
     * @memberof WebRTCCall
     * @instance
     */

    /**
     * Encodes the specified WebRTCCall message. Does not implicitly {@link WebRTCCall.verify|verify} messages.
     * @function encode
     * @memberof WebRTCCall
     * @static
     * @param {WebRTCCall} message WebRTCCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebRTCCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        writer.uint32(/* id 3, wireType 0 =*/24).int32(message.sdpType);
        if (message.otherPartyDisplayname != null && Object.hasOwnProperty.call(message, "otherPartyDisplayname"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.otherPartyDisplayname);
        if (message.otherPartyNumber != null && Object.hasOwnProperty.call(message, "otherPartyNumber"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.otherPartyNumber);
        if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.transactionId);
        if (message.sdp != null && Object.hasOwnProperty.call(message, "sdp"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.sdp);
        if (message.SIPDialogID != null && Object.hasOwnProperty.call(message, "SIPDialogID"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.SIPDialogID);
        if (message.holdState != null && Object.hasOwnProperty.call(message, "holdState"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.holdState);
        return writer;
    };

    /**
     * Decodes a WebRTCCall message from the specified reader or buffer.
     * @function decode
     * @memberof WebRTCCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebRTCCall} WebRTCCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebRTCCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebRTCCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.sdpType = reader.int32();
                    break;
                }
            case 4: {
                    message.otherPartyDisplayname = reader.string();
                    break;
                }
            case 5: {
                    message.otherPartyNumber = reader.string();
                    break;
                }
            case 6: {
                    message.transactionId = reader.int32();
                    break;
                }
            case 7: {
                    message.sdp = reader.string();
                    break;
                }
            case 8: {
                    message.SIPDialogID = reader.string();
                    break;
                }
            case 9: {
                    message.holdState = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return WebRTCCall;
})();

export const RequestWebRTCChangeSDPState = $root.RequestWebRTCChangeSDPState = (() => {

    /**
     * Properties of a RequestWebRTCChangeSDPState.
     * @exports IRequestWebRTCChangeSDPState
     * @property {number} Id RequestWebRTCChangeSDPState Id
     * @property {WebRTCEndpointSDPState} sdpType RequestWebRTCChangeSDPState sdpType
     * @property {number} [transactionId] RequestWebRTCChangeSDPState transactionId
     * @property {string} [sdp] RequestWebRTCChangeSDPState sdp
     * @property {string} [destinationNumber] RequestWebRTCChangeSDPState destinationNumber
     * @property {string} [CallerDisplayName] RequestWebRTCChangeSDPState CallerDisplayName
     * @property {string} [CallerID] RequestWebRTCChangeSDPState CallerID
     * @property {number} [replaceConnectionId] RequestWebRTCChangeSDPState replaceConnectionId
     * @property {number} [sipRejectCode] RequestWebRTCChangeSDPState sipRejectCode
     */

    /**
     * Constructs a new RequestWebRTCChangeSDPState.
     * @exports RequestWebRTCChangeSDPState
     * @classdesc Represents a RequestWebRTCChangeSDPState.
     * @constructor
     * @param {Partial<RequestWebRTCChangeSDPState>} [properties] Properties to set
     */
    function RequestWebRTCChangeSDPState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestWebRTCChangeSDPState Id.
     * @member {number} Id
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */

    /**
     * RequestWebRTCChangeSDPState sdpType.
     * @member {WebRTCEndpointSDPState} sdpType
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */

    /**
     * RequestWebRTCChangeSDPState transactionId.
     * @member {number} transactionId
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */

    /**
     * RequestWebRTCChangeSDPState sdp.
     * @member {string} sdp
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */

    /**
     * RequestWebRTCChangeSDPState destinationNumber.
     * @member {string} destinationNumber
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */

    /**
     * RequestWebRTCChangeSDPState CallerDisplayName.
     * @member {string} CallerDisplayName
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */

    /**
     * RequestWebRTCChangeSDPState CallerID.
     * @member {string} CallerID
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */

    /**
     * RequestWebRTCChangeSDPState replaceConnectionId.
     * @member {number} replaceConnectionId
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */

    /**
     * RequestWebRTCChangeSDPState sipRejectCode.
     * @member {number} sipRejectCode
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */

    /**
     * Encodes the specified RequestWebRTCChangeSDPState message. Does not implicitly {@link RequestWebRTCChangeSDPState.verify|verify} messages.
     * @function encode
     * @memberof RequestWebRTCChangeSDPState
     * @static
     * @param {RequestWebRTCChangeSDPState} message RequestWebRTCChangeSDPState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestWebRTCChangeSDPState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.sdpType);
        if (message.transactionId != null && Object.hasOwnProperty.call(message, "transactionId"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.transactionId);
        if (message.sdp != null && Object.hasOwnProperty.call(message, "sdp"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.sdp);
        if (message.destinationNumber != null && Object.hasOwnProperty.call(message, "destinationNumber"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.destinationNumber);
        if (message.CallerDisplayName != null && Object.hasOwnProperty.call(message, "CallerDisplayName"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.CallerDisplayName);
        if (message.CallerID != null && Object.hasOwnProperty.call(message, "CallerID"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.CallerID);
        if (message.replaceConnectionId != null && Object.hasOwnProperty.call(message, "replaceConnectionId"))
            writer.uint32(/* id 8, wireType 0 =*/64).int32(message.replaceConnectionId);
        if (message.sipRejectCode != null && Object.hasOwnProperty.call(message, "sipRejectCode"))
            writer.uint32(/* id 9, wireType 0 =*/72).int32(message.sipRejectCode);
        return writer;
    };

    /**
     * Decodes a RequestWebRTCChangeSDPState message from the specified reader or buffer.
     * @function decode
     * @memberof RequestWebRTCChangeSDPState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestWebRTCChangeSDPState} RequestWebRTCChangeSDPState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestWebRTCChangeSDPState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestWebRTCChangeSDPState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.sdpType = reader.int32();
                    break;
                }
            case 3: {
                    message.transactionId = reader.int32();
                    break;
                }
            case 4: {
                    message.sdp = reader.string();
                    break;
                }
            case 5: {
                    message.destinationNumber = reader.string();
                    break;
                }
            case 6: {
                    message.CallerDisplayName = reader.string();
                    break;
                }
            case 7: {
                    message.CallerID = reader.string();
                    break;
                }
            case 8: {
                    message.replaceConnectionId = reader.int32();
                    break;
                }
            case 9: {
                    message.sipRejectCode = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestWebRTCChangeSDPState message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestWebRTCChangeSDPState.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 164, ChangeSDPState : this});
    };

    /**
     * RequestWebRTCChangeSDPState typeId
     * @member {number} typeId
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */
    RequestWebRTCChangeSDPState.prototype.typeId = 164;
    /**
     * RequestWebRTCChangeSDPState typeName
     * @member {string} typeName
     * @memberof RequestWebRTCChangeSDPState
     * @instance
     */
    RequestWebRTCChangeSDPState.prototype.typeName = "ChangeSDPState";

    return RequestWebRTCChangeSDPState;
})();

export const ResponseWebRTCChangeSDPState = $root.ResponseWebRTCChangeSDPState = (() => {

    /**
     * Properties of a ResponseWebRTCChangeSDPState.
     * @exports IResponseWebRTCChangeSDPState
     * @property {boolean} Success ResponseWebRTCChangeSDPState Success
     * @property {string} [Message] ResponseWebRTCChangeSDPState Message
     * @property {number} [CallId] ResponseWebRTCChangeSDPState CallId
     */

    /**
     * Constructs a new ResponseWebRTCChangeSDPState.
     * @exports ResponseWebRTCChangeSDPState
     * @classdesc Represents a ResponseWebRTCChangeSDPState.
     * @constructor
     * @param {Partial<ResponseWebRTCChangeSDPState>} [properties] Properties to set
     */
    function ResponseWebRTCChangeSDPState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseWebRTCChangeSDPState Success.
     * @member {boolean} Success
     * @memberof ResponseWebRTCChangeSDPState
     * @instance
     */

    /**
     * ResponseWebRTCChangeSDPState Message.
     * @member {string} Message
     * @memberof ResponseWebRTCChangeSDPState
     * @instance
     */

    /**
     * ResponseWebRTCChangeSDPState CallId.
     * @member {number} CallId
     * @memberof ResponseWebRTCChangeSDPState
     * @instance
     */

    /**
     * Encodes the specified ResponseWebRTCChangeSDPState message. Does not implicitly {@link ResponseWebRTCChangeSDPState.verify|verify} messages.
     * @function encode
     * @memberof ResponseWebRTCChangeSDPState
     * @static
     * @param {ResponseWebRTCChangeSDPState} message ResponseWebRTCChangeSDPState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseWebRTCChangeSDPState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.Success);
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Message);
        if (message.CallId != null && Object.hasOwnProperty.call(message, "CallId"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.CallId);
        return writer;
    };

    /**
     * Decodes a ResponseWebRTCChangeSDPState message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseWebRTCChangeSDPState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseWebRTCChangeSDPState} ResponseWebRTCChangeSDPState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseWebRTCChangeSDPState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseWebRTCChangeSDPState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Success = reader.bool();
                    break;
                }
            case 2: {
                    message.Message = reader.string();
                    break;
                }
            case 3: {
                    message.CallId = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseWebRTCChangeSDPState message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseWebRTCChangeSDPState
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseWebRTCChangeSDPState.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 228, ChangeSDPStateResponse : this});
    };

    /**
     * ResponseWebRTCChangeSDPState typeId
     * @member {number} typeId
     * @memberof ResponseWebRTCChangeSDPState
     * @instance
     */
    ResponseWebRTCChangeSDPState.prototype.typeId = 228;
    /**
     * ResponseWebRTCChangeSDPState typeName
     * @member {string} typeName
     * @memberof ResponseWebRTCChangeSDPState
     * @instance
     */
    ResponseWebRTCChangeSDPState.prototype.typeName = "ChangeSDPStateResponse";

    return ResponseWebRTCChangeSDPState;
})();

export const WebRTCTransferCall = $root.WebRTCTransferCall = (() => {

    /**
     * Properties of a WebRTCTransferCall.
     * @exports IWebRTCTransferCall
     * @property {number} Id WebRTCTransferCall Id
     * @property {string} [destination] WebRTCTransferCall destination
     * @property {number} [ToCallId] WebRTCTransferCall ToCallId
     */

    /**
     * Constructs a new WebRTCTransferCall.
     * @exports WebRTCTransferCall
     * @classdesc Represents a WebRTCTransferCall.
     * @constructor
     * @param {Partial<WebRTCTransferCall>} [properties] Properties to set
     */
    function WebRTCTransferCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WebRTCTransferCall Id.
     * @member {number} Id
     * @memberof WebRTCTransferCall
     * @instance
     */

    /**
     * WebRTCTransferCall destination.
     * @member {string} destination
     * @memberof WebRTCTransferCall
     * @instance
     */

    /**
     * WebRTCTransferCall ToCallId.
     * @member {number} ToCallId
     * @memberof WebRTCTransferCall
     * @instance
     */

    /**
     * Encodes the specified WebRTCTransferCall message. Does not implicitly {@link WebRTCTransferCall.verify|verify} messages.
     * @function encode
     * @memberof WebRTCTransferCall
     * @static
     * @param {WebRTCTransferCall} message WebRTCTransferCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WebRTCTransferCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.destination != null && Object.hasOwnProperty.call(message, "destination"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.destination);
        if (message.ToCallId != null && Object.hasOwnProperty.call(message, "ToCallId"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.ToCallId);
        return writer;
    };

    /**
     * Decodes a WebRTCTransferCall message from the specified reader or buffer.
     * @function decode
     * @memberof WebRTCTransferCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WebRTCTransferCall} WebRTCTransferCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WebRTCTransferCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WebRTCTransferCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.destination = reader.string();
                    break;
                }
            case 3: {
                    message.ToCallId = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return WebRTCTransferCall;
})();

export const RequestRegisterWebRTCEndpoint = $root.RequestRegisterWebRTCEndpoint = (() => {

    /**
     * Properties of a RequestRegisterWebRTCEndpoint.
     * @exports IRequestRegisterWebRTCEndpoint
     * @property {boolean} register RequestRegisterWebRTCEndpoint register
     * @property {boolean} [NeedsRegistration] RequestRegisterWebRTCEndpoint NeedsRegistration
     */

    /**
     * Constructs a new RequestRegisterWebRTCEndpoint.
     * @exports RequestRegisterWebRTCEndpoint
     * @classdesc Represents a RequestRegisterWebRTCEndpoint.
     * @constructor
     * @param {Partial<RequestRegisterWebRTCEndpoint>} [properties] Properties to set
     */
    function RequestRegisterWebRTCEndpoint(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestRegisterWebRTCEndpoint register.
     * @member {boolean} register
     * @memberof RequestRegisterWebRTCEndpoint
     * @instance
     */

    /**
     * RequestRegisterWebRTCEndpoint NeedsRegistration.
     * @member {boolean} NeedsRegistration
     * @memberof RequestRegisterWebRTCEndpoint
     * @instance
     */

    /**
     * Encodes the specified RequestRegisterWebRTCEndpoint message. Does not implicitly {@link RequestRegisterWebRTCEndpoint.verify|verify} messages.
     * @function encode
     * @memberof RequestRegisterWebRTCEndpoint
     * @static
     * @param {RequestRegisterWebRTCEndpoint} message RequestRegisterWebRTCEndpoint message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRegisterWebRTCEndpoint.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).bool(message.register);
        if (message.NeedsRegistration != null && Object.hasOwnProperty.call(message, "NeedsRegistration"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.NeedsRegistration);
        return writer;
    };

    /**
     * Decodes a RequestRegisterWebRTCEndpoint message from the specified reader or buffer.
     * @function decode
     * @memberof RequestRegisterWebRTCEndpoint
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestRegisterWebRTCEndpoint} RequestRegisterWebRTCEndpoint
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRegisterWebRTCEndpoint.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestRegisterWebRTCEndpoint();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.register = reader.bool();
                    break;
                }
            case 2: {
                    message.NeedsRegistration = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestRegisterWebRTCEndpoint message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestRegisterWebRTCEndpoint
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestRegisterWebRTCEndpoint.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 163, registerWebRTC : this});
    };

    /**
     * RequestRegisterWebRTCEndpoint typeId
     * @member {number} typeId
     * @memberof RequestRegisterWebRTCEndpoint
     * @instance
     */
    RequestRegisterWebRTCEndpoint.prototype.typeId = 163;
    /**
     * RequestRegisterWebRTCEndpoint typeName
     * @member {string} typeName
     * @memberof RequestRegisterWebRTCEndpoint
     * @instance
     */
    RequestRegisterWebRTCEndpoint.prototype.typeName = "registerWebRTC";

    return RequestRegisterWebRTCEndpoint;
})();

export const RequestChangeWebClientPassword = $root.RequestChangeWebClientPassword = (() => {

    /**
     * Properties of a RequestChangeWebClientPassword.
     * @exports IRequestChangeWebClientPassword
     * @property {string} [OldPassword] RequestChangeWebClientPassword OldPassword
     * @property {string} [NewPassword] RequestChangeWebClientPassword NewPassword
     */

    /**
     * Constructs a new RequestChangeWebClientPassword.
     * @exports RequestChangeWebClientPassword
     * @classdesc Represents a RequestChangeWebClientPassword.
     * @constructor
     * @param {Partial<RequestChangeWebClientPassword>} [properties] Properties to set
     */
    function RequestChangeWebClientPassword(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestChangeWebClientPassword OldPassword.
     * @member {string} OldPassword
     * @memberof RequestChangeWebClientPassword
     * @instance
     */

    /**
     * RequestChangeWebClientPassword NewPassword.
     * @member {string} NewPassword
     * @memberof RequestChangeWebClientPassword
     * @instance
     */

    /**
     * Encodes the specified RequestChangeWebClientPassword message. Does not implicitly {@link RequestChangeWebClientPassword.verify|verify} messages.
     * @function encode
     * @memberof RequestChangeWebClientPassword
     * @static
     * @param {RequestChangeWebClientPassword} message RequestChangeWebClientPassword message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestChangeWebClientPassword.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.OldPassword != null && Object.hasOwnProperty.call(message, "OldPassword"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.OldPassword);
        if (message.NewPassword != null && Object.hasOwnProperty.call(message, "NewPassword"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.NewPassword);
        return writer;
    };

    /**
     * Decodes a RequestChangeWebClientPassword message from the specified reader or buffer.
     * @function decode
     * @memberof RequestChangeWebClientPassword
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestChangeWebClientPassword} RequestChangeWebClientPassword
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestChangeWebClientPassword.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestChangeWebClientPassword();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.OldPassword = reader.string();
                    break;
                }
            case 2: {
                    message.NewPassword = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestChangeWebClientPassword message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestChangeWebClientPassword
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestChangeWebClientPassword.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 168, ChangeWCPassword : this});
    };

    /**
     * RequestChangeWebClientPassword typeId
     * @member {number} typeId
     * @memberof RequestChangeWebClientPassword
     * @instance
     */
    RequestChangeWebClientPassword.prototype.typeId = 168;
    /**
     * RequestChangeWebClientPassword typeName
     * @member {string} typeName
     * @memberof RequestChangeWebClientPassword
     * @instance
     */
    RequestChangeWebClientPassword.prototype.typeName = "ChangeWCPassword";

    return RequestChangeWebClientPassword;
})();

export const RequestUnreadMessagesCount = $root.RequestUnreadMessagesCount = (() => {

    /**
     * Properties of a RequestUnreadMessagesCount.
     * @exports IRequestUnreadMessagesCount
     */

    /**
     * Constructs a new RequestUnreadMessagesCount.
     * @exports RequestUnreadMessagesCount
     * @classdesc Represents a RequestUnreadMessagesCount.
     * @constructor
     * @param {Partial<RequestUnreadMessagesCount>} [properties] Properties to set
     */
    function RequestUnreadMessagesCount(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestUnreadMessagesCount message. Does not implicitly {@link RequestUnreadMessagesCount.verify|verify} messages.
     * @function encode
     * @memberof RequestUnreadMessagesCount
     * @static
     * @param {RequestUnreadMessagesCount} message RequestUnreadMessagesCount message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUnreadMessagesCount.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestUnreadMessagesCount message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUnreadMessagesCount
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUnreadMessagesCount} RequestUnreadMessagesCount
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUnreadMessagesCount.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUnreadMessagesCount();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUnreadMessagesCount message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUnreadMessagesCount
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUnreadMessagesCount.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 165, GetUnreadMessagesCount : this});
    };

    /**
     * RequestUnreadMessagesCount typeId
     * @member {number} typeId
     * @memberof RequestUnreadMessagesCount
     * @instance
     */
    RequestUnreadMessagesCount.prototype.typeId = 165;
    /**
     * RequestUnreadMessagesCount typeName
     * @member {string} typeName
     * @memberof RequestUnreadMessagesCount
     * @instance
     */
    RequestUnreadMessagesCount.prototype.typeName = "GetUnreadMessagesCount";

    return RequestUnreadMessagesCount;
})();

export const PartyWithUnreadMessages = $root.PartyWithUnreadMessages = (() => {

    /**
     * Properties of a PartyWithUnreadMessages.
     * @exports IPartyWithUnreadMessages
     * @property {string} [Party] PartyWithUnreadMessages Party
     * @property {number} [Count] PartyWithUnreadMessages Count
     * @property {number} [IdConversation] PartyWithUnreadMessages IdConversation
     * @property {boolean} [IsArchived] PartyWithUnreadMessages IsArchived
     */

    /**
     * Constructs a new PartyWithUnreadMessages.
     * @exports PartyWithUnreadMessages
     * @classdesc Represents a PartyWithUnreadMessages.
     * @constructor
     * @param {Partial<PartyWithUnreadMessages>} [properties] Properties to set
     */
    function PartyWithUnreadMessages(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PartyWithUnreadMessages Party.
     * @member {string} Party
     * @memberof PartyWithUnreadMessages
     * @instance
     */

    /**
     * PartyWithUnreadMessages Count.
     * @member {number} Count
     * @memberof PartyWithUnreadMessages
     * @instance
     */

    /**
     * PartyWithUnreadMessages IdConversation.
     * @member {number} IdConversation
     * @memberof PartyWithUnreadMessages
     * @instance
     */

    /**
     * PartyWithUnreadMessages IsArchived.
     * @member {boolean} IsArchived
     * @memberof PartyWithUnreadMessages
     * @instance
     */

    /**
     * Encodes the specified PartyWithUnreadMessages message. Does not implicitly {@link PartyWithUnreadMessages.verify|verify} messages.
     * @function encode
     * @memberof PartyWithUnreadMessages
     * @static
     * @param {PartyWithUnreadMessages} message PartyWithUnreadMessages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PartyWithUnreadMessages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Party != null && Object.hasOwnProperty.call(message, "Party"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Party);
        if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Count);
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.IdConversation);
        if (message.IsArchived != null && Object.hasOwnProperty.call(message, "IsArchived"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.IsArchived);
        return writer;
    };

    /**
     * Decodes a PartyWithUnreadMessages message from the specified reader or buffer.
     * @function decode
     * @memberof PartyWithUnreadMessages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PartyWithUnreadMessages} PartyWithUnreadMessages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PartyWithUnreadMessages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PartyWithUnreadMessages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Party = reader.string();
                    break;
                }
            case 2: {
                    message.Count = reader.int32();
                    break;
                }
            case 3: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 4: {
                    message.IsArchived = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return PartyWithUnreadMessages;
})();

export const ResponseUnreadMessagesCount = $root.ResponseUnreadMessagesCount = (() => {

    /**
     * Properties of a ResponseUnreadMessagesCount.
     * @exports IResponseUnreadMessagesCount
     * @property {Array.<PartyWithUnreadMessages>} [Items] ResponseUnreadMessagesCount Items
     */

    /**
     * Constructs a new ResponseUnreadMessagesCount.
     * @exports ResponseUnreadMessagesCount
     * @classdesc Represents a ResponseUnreadMessagesCount.
     * @constructor
     * @param {Partial<ResponseUnreadMessagesCount>} [properties] Properties to set
     */
    function ResponseUnreadMessagesCount(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseUnreadMessagesCount Items.
     * @member {Array.<PartyWithUnreadMessages>} Items
     * @memberof ResponseUnreadMessagesCount
     * @instance
     */
    ResponseUnreadMessagesCount.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified ResponseUnreadMessagesCount message. Does not implicitly {@link ResponseUnreadMessagesCount.verify|verify} messages.
     * @function encode
     * @memberof ResponseUnreadMessagesCount
     * @static
     * @param {ResponseUnreadMessagesCount} message ResponseUnreadMessagesCount message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseUnreadMessagesCount.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.PartyWithUnreadMessages.encode(message.Items[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseUnreadMessagesCount message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseUnreadMessagesCount
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseUnreadMessagesCount} ResponseUnreadMessagesCount
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseUnreadMessagesCount.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseUnreadMessagesCount();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.PartyWithUnreadMessages.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseUnreadMessagesCount message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseUnreadMessagesCount
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseUnreadMessagesCount.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 229, UnreadMessagesCount : this});
    };

    /**
     * ResponseUnreadMessagesCount typeId
     * @member {number} typeId
     * @memberof ResponseUnreadMessagesCount
     * @instance
     */
    ResponseUnreadMessagesCount.prototype.typeId = 229;
    /**
     * ResponseUnreadMessagesCount typeName
     * @member {string} typeName
     * @memberof ResponseUnreadMessagesCount
     * @instance
     */
    ResponseUnreadMessagesCount.prototype.typeName = "UnreadMessagesCount";

    return ResponseUnreadMessagesCount;
})();

export const RequestContactsByRef = $root.RequestContactsByRef = (() => {

    /**
     * Properties of a RequestContactsByRef.
     * @exports IRequestContactsByRef
     * @property {Array.<number>} [IntRefs] RequestContactsByRef IntRefs
     * @property {string} [StrRefs] RequestContactsByRef StrRefs
     */

    /**
     * Constructs a new RequestContactsByRef.
     * @exports RequestContactsByRef
     * @classdesc Represents a RequestContactsByRef.
     * @constructor
     * @param {Partial<RequestContactsByRef>} [properties] Properties to set
     */
    function RequestContactsByRef(properties) {
        this.IntRefs = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestContactsByRef IntRefs.
     * @member {Array.<number>} IntRefs
     * @memberof RequestContactsByRef
     * @instance
     */
    RequestContactsByRef.prototype.IntRefs = $util.emptyArray;

    /**
     * RequestContactsByRef StrRefs.
     * @member {string} StrRefs
     * @memberof RequestContactsByRef
     * @instance
     */

    /**
     * Encodes the specified RequestContactsByRef message. Does not implicitly {@link RequestContactsByRef.verify|verify} messages.
     * @function encode
     * @memberof RequestContactsByRef
     * @static
     * @param {RequestContactsByRef} message RequestContactsByRef message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestContactsByRef.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IntRefs != null && message.IntRefs.length)
            for (let i = 0; i < message.IntRefs.length; ++i)
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IntRefs[i]);
        if (message.StrRefs != null && Object.hasOwnProperty.call(message, "StrRefs"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.StrRefs);
        return writer;
    };

    /**
     * Decodes a RequestContactsByRef message from the specified reader or buffer.
     * @function decode
     * @memberof RequestContactsByRef
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestContactsByRef} RequestContactsByRef
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestContactsByRef.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestContactsByRef();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.IntRefs && message.IntRefs.length))
                        message.IntRefs = [];
                    if ((tag & 7) === 2) {
                        let end2 = reader.uint32() + reader.pos;
                        while (reader.pos < end2)
                            message.IntRefs.push(reader.int32());
                    } else
                        message.IntRefs.push(reader.int32());
                    break;
                }
            case 2: {
                    message.StrRefs = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestContactsByRef message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestContactsByRef
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestContactsByRef.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 170, GetContactsByRef : this});
    };

    /**
     * RequestContactsByRef typeId
     * @member {number} typeId
     * @memberof RequestContactsByRef
     * @instance
     */
    RequestContactsByRef.prototype.typeId = 170;
    /**
     * RequestContactsByRef typeName
     * @member {string} typeName
     * @memberof RequestContactsByRef
     * @instance
     */
    RequestContactsByRef.prototype.typeName = "GetContactsByRef";

    return RequestContactsByRef;
})();

export const RequestCleanExtension = $root.RequestCleanExtension = (() => {

    /**
     * Properties of a RequestCleanExtension.
     * @exports IRequestCleanExtension
     * @property {string} TargetExtension RequestCleanExtension TargetExtension
     */

    /**
     * Constructs a new RequestCleanExtension.
     * @exports RequestCleanExtension
     * @classdesc Represents a RequestCleanExtension.
     * @constructor
     * @param {Partial<RequestCleanExtension>} [properties] Properties to set
     */
    function RequestCleanExtension(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestCleanExtension TargetExtension.
     * @member {string} TargetExtension
     * @memberof RequestCleanExtension
     * @instance
     */

    /**
     * Encodes the specified RequestCleanExtension message. Does not implicitly {@link RequestCleanExtension.verify|verify} messages.
     * @function encode
     * @memberof RequestCleanExtension
     * @static
     * @param {RequestCleanExtension} message RequestCleanExtension message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestCleanExtension.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.TargetExtension);
        return writer;
    };

    /**
     * Decodes a RequestCleanExtension message from the specified reader or buffer.
     * @function decode
     * @memberof RequestCleanExtension
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestCleanExtension} RequestCleanExtension
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestCleanExtension.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestCleanExtension();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.TargetExtension = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestCleanExtension message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestCleanExtension
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestCleanExtension.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 171, CleanExtension : this});
    };

    /**
     * RequestCleanExtension typeId
     * @member {number} typeId
     * @memberof RequestCleanExtension
     * @instance
     */
    RequestCleanExtension.prototype.typeId = 171;
    /**
     * RequestCleanExtension typeName
     * @member {string} typeName
     * @memberof RequestCleanExtension
     * @instance
     */
    RequestCleanExtension.prototype.typeName = "CleanExtension";

    return RequestCleanExtension;
})();

export const RequestAssignExtension = $root.RequestAssignExtension = (() => {

    /**
     * Properties of a RequestAssignExtension.
     * @exports IRequestAssignExtension
     * @property {string} TargetExtension RequestAssignExtension TargetExtension
     * @property {string} [FirstName] RequestAssignExtension FirstName
     * @property {string} [LastName] RequestAssignExtension LastName
     * @property {string} [Email] RequestAssignExtension Email
     * @property {string} [Mobile] RequestAssignExtension Mobile
     */

    /**
     * Constructs a new RequestAssignExtension.
     * @exports RequestAssignExtension
     * @classdesc Represents a RequestAssignExtension.
     * @constructor
     * @param {Partial<RequestAssignExtension>} [properties] Properties to set
     */
    function RequestAssignExtension(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestAssignExtension TargetExtension.
     * @member {string} TargetExtension
     * @memberof RequestAssignExtension
     * @instance
     */

    /**
     * RequestAssignExtension FirstName.
     * @member {string} FirstName
     * @memberof RequestAssignExtension
     * @instance
     */

    /**
     * RequestAssignExtension LastName.
     * @member {string} LastName
     * @memberof RequestAssignExtension
     * @instance
     */

    /**
     * RequestAssignExtension Email.
     * @member {string} Email
     * @memberof RequestAssignExtension
     * @instance
     */

    /**
     * RequestAssignExtension Mobile.
     * @member {string} Mobile
     * @memberof RequestAssignExtension
     * @instance
     */

    /**
     * Encodes the specified RequestAssignExtension message. Does not implicitly {@link RequestAssignExtension.verify|verify} messages.
     * @function encode
     * @memberof RequestAssignExtension
     * @static
     * @param {RequestAssignExtension} message RequestAssignExtension message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestAssignExtension.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.TargetExtension);
        if (message.FirstName != null && Object.hasOwnProperty.call(message, "FirstName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.FirstName);
        if (message.LastName != null && Object.hasOwnProperty.call(message, "LastName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.LastName);
        if (message.Email != null && Object.hasOwnProperty.call(message, "Email"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Email);
        if (message.Mobile != null && Object.hasOwnProperty.call(message, "Mobile"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Mobile);
        return writer;
    };

    /**
     * Decodes a RequestAssignExtension message from the specified reader or buffer.
     * @function decode
     * @memberof RequestAssignExtension
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestAssignExtension} RequestAssignExtension
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestAssignExtension.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestAssignExtension();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.TargetExtension = reader.string();
                    break;
                }
            case 2: {
                    message.FirstName = reader.string();
                    break;
                }
            case 3: {
                    message.LastName = reader.string();
                    break;
                }
            case 4: {
                    message.Email = reader.string();
                    break;
                }
            case 5: {
                    message.Mobile = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestAssignExtension message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestAssignExtension
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestAssignExtension.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 172, AssignExtension : this});
    };

    /**
     * RequestAssignExtension typeId
     * @member {number} typeId
     * @memberof RequestAssignExtension
     * @instance
     */
    RequestAssignExtension.prototype.typeId = 172;
    /**
     * RequestAssignExtension typeName
     * @member {string} typeName
     * @memberof RequestAssignExtension
     * @instance
     */
    RequestAssignExtension.prototype.typeName = "AssignExtension";

    return RequestAssignExtension;
})();

export const RequestSetWakeupCall = $root.RequestSetWakeupCall = (() => {

    /**
     * Properties of a RequestSetWakeupCall.
     * @exports IRequestSetWakeupCall
     * @property {string} TargetExtension RequestSetWakeupCall TargetExtension
     * @property {DateTime} When RequestSetWakeupCall When
     */

    /**
     * Constructs a new RequestSetWakeupCall.
     * @exports RequestSetWakeupCall
     * @classdesc Represents a RequestSetWakeupCall.
     * @constructor
     * @param {Partial<RequestSetWakeupCall>} [properties] Properties to set
     */
    function RequestSetWakeupCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSetWakeupCall TargetExtension.
     * @member {string} TargetExtension
     * @memberof RequestSetWakeupCall
     * @instance
     */

    /**
     * RequestSetWakeupCall When.
     * @member {DateTime} When
     * @memberof RequestSetWakeupCall
     * @instance
     */

    /**
     * Encodes the specified RequestSetWakeupCall message. Does not implicitly {@link RequestSetWakeupCall.verify|verify} messages.
     * @function encode
     * @memberof RequestSetWakeupCall
     * @static
     * @param {RequestSetWakeupCall} message RequestSetWakeupCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSetWakeupCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.TargetExtension);
        $root.DateTime.encode(message.When, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestSetWakeupCall message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSetWakeupCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSetWakeupCall} RequestSetWakeupCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSetWakeupCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSetWakeupCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.TargetExtension = reader.string();
                    break;
                }
            case 2: {
                    message.When = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSetWakeupCall message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSetWakeupCall
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSetWakeupCall.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 173, SetWakeupCall : this});
    };

    /**
     * RequestSetWakeupCall typeId
     * @member {number} typeId
     * @memberof RequestSetWakeupCall
     * @instance
     */
    RequestSetWakeupCall.prototype.typeId = 173;
    /**
     * RequestSetWakeupCall typeName
     * @member {string} typeName
     * @memberof RequestSetWakeupCall
     * @instance
     */
    RequestSetWakeupCall.prototype.typeName = "SetWakeupCall";

    return RequestSetWakeupCall;
})();

export const RequestDeleteWakeupCall = $root.RequestDeleteWakeupCall = (() => {

    /**
     * Properties of a RequestDeleteWakeupCall.
     * @exports IRequestDeleteWakeupCall
     * @property {string} TargetExtension RequestDeleteWakeupCall TargetExtension
     */

    /**
     * Constructs a new RequestDeleteWakeupCall.
     * @exports RequestDeleteWakeupCall
     * @classdesc Represents a RequestDeleteWakeupCall.
     * @constructor
     * @param {Partial<RequestDeleteWakeupCall>} [properties] Properties to set
     */
    function RequestDeleteWakeupCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestDeleteWakeupCall TargetExtension.
     * @member {string} TargetExtension
     * @memberof RequestDeleteWakeupCall
     * @instance
     */

    /**
     * Encodes the specified RequestDeleteWakeupCall message. Does not implicitly {@link RequestDeleteWakeupCall.verify|verify} messages.
     * @function encode
     * @memberof RequestDeleteWakeupCall
     * @static
     * @param {RequestDeleteWakeupCall} message RequestDeleteWakeupCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestDeleteWakeupCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.TargetExtension);
        return writer;
    };

    /**
     * Decodes a RequestDeleteWakeupCall message from the specified reader or buffer.
     * @function decode
     * @memberof RequestDeleteWakeupCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestDeleteWakeupCall} RequestDeleteWakeupCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestDeleteWakeupCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestDeleteWakeupCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.TargetExtension = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestDeleteWakeupCall message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestDeleteWakeupCall
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestDeleteWakeupCall.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 174, DeleteWakeupCall : this});
    };

    /**
     * RequestDeleteWakeupCall typeId
     * @member {number} typeId
     * @memberof RequestDeleteWakeupCall
     * @instance
     */
    RequestDeleteWakeupCall.prototype.typeId = 174;
    /**
     * RequestDeleteWakeupCall typeName
     * @member {string} typeName
     * @memberof RequestDeleteWakeupCall
     * @instance
     */
    RequestDeleteWakeupCall.prototype.typeName = "DeleteWakeupCall";

    return RequestDeleteWakeupCall;
})();

export const RequestGetWakeupCalls = $root.RequestGetWakeupCalls = (() => {

    /**
     * Properties of a RequestGetWakeupCalls.
     * @exports IRequestGetWakeupCalls
     */

    /**
     * Constructs a new RequestGetWakeupCalls.
     * @exports RequestGetWakeupCalls
     * @classdesc Represents a RequestGetWakeupCalls.
     * @constructor
     * @param {Partial<RequestGetWakeupCalls>} [properties] Properties to set
     */
    function RequestGetWakeupCalls(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestGetWakeupCalls message. Does not implicitly {@link RequestGetWakeupCalls.verify|verify} messages.
     * @function encode
     * @memberof RequestGetWakeupCalls
     * @static
     * @param {RequestGetWakeupCalls} message RequestGetWakeupCalls message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetWakeupCalls.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestGetWakeupCalls message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetWakeupCalls
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetWakeupCalls} RequestGetWakeupCalls
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetWakeupCalls.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetWakeupCalls();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetWakeupCalls message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetWakeupCalls
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetWakeupCalls.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 175, GetWakeupCalls : this});
    };

    /**
     * RequestGetWakeupCalls typeId
     * @member {number} typeId
     * @memberof RequestGetWakeupCalls
     * @instance
     */
    RequestGetWakeupCalls.prototype.typeId = 175;
    /**
     * RequestGetWakeupCalls typeName
     * @member {string} typeName
     * @memberof RequestGetWakeupCalls
     * @instance
     */
    RequestGetWakeupCalls.prototype.typeName = "GetWakeupCalls";

    return RequestGetWakeupCalls;
})();

export const WakeupCall = $root.WakeupCall = (() => {

    /**
     * Properties of a WakeupCall.
     * @exports IWakeupCall
     * @property {string} [ExtensionNumber] WakeupCall ExtensionNumber
     * @property {DateTime} [When] WakeupCall When
     * @property {string} [Status] WakeupCall Status
     */

    /**
     * Constructs a new WakeupCall.
     * @exports WakeupCall
     * @classdesc Represents a WakeupCall.
     * @constructor
     * @param {Partial<WakeupCall>} [properties] Properties to set
     */
    function WakeupCall(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WakeupCall ExtensionNumber.
     * @member {string} ExtensionNumber
     * @memberof WakeupCall
     * @instance
     */

    /**
     * WakeupCall When.
     * @member {DateTime} When
     * @memberof WakeupCall
     * @instance
     */

    /**
     * WakeupCall Status.
     * @member {string} Status
     * @memberof WakeupCall
     * @instance
     */

    /**
     * Encodes the specified WakeupCall message. Does not implicitly {@link WakeupCall.verify|verify} messages.
     * @function encode
     * @memberof WakeupCall
     * @static
     * @param {WakeupCall} message WakeupCall message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WakeupCall.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ExtensionNumber != null && Object.hasOwnProperty.call(message, "ExtensionNumber"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ExtensionNumber);
        if (message.When != null && Object.hasOwnProperty.call(message, "When"))
            $root.DateTime.encode(message.When, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.Status != null && Object.hasOwnProperty.call(message, "Status"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Status);
        return writer;
    };

    /**
     * Decodes a WakeupCall message from the specified reader or buffer.
     * @function decode
     * @memberof WakeupCall
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WakeupCall} WakeupCall
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WakeupCall.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WakeupCall();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ExtensionNumber = reader.string();
                    break;
                }
            case 2: {
                    message.When = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.Status = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return WakeupCall;
})();

export const ResponseWakeupCalls = $root.ResponseWakeupCalls = (() => {

    /**
     * Properties of a ResponseWakeupCalls.
     * @exports IResponseWakeupCalls
     * @property {Array.<WakeupCall>} [WakeupCalls] ResponseWakeupCalls WakeupCalls
     */

    /**
     * Constructs a new ResponseWakeupCalls.
     * @exports ResponseWakeupCalls
     * @classdesc Represents a ResponseWakeupCalls.
     * @constructor
     * @param {Partial<ResponseWakeupCalls>} [properties] Properties to set
     */
    function ResponseWakeupCalls(properties) {
        this.WakeupCalls = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseWakeupCalls WakeupCalls.
     * @member {Array.<WakeupCall>} WakeupCalls
     * @memberof ResponseWakeupCalls
     * @instance
     */
    ResponseWakeupCalls.prototype.WakeupCalls = $util.emptyArray;

    /**
     * Encodes the specified ResponseWakeupCalls message. Does not implicitly {@link ResponseWakeupCalls.verify|verify} messages.
     * @function encode
     * @memberof ResponseWakeupCalls
     * @static
     * @param {ResponseWakeupCalls} message ResponseWakeupCalls message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseWakeupCalls.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.WakeupCalls != null && message.WakeupCalls.length)
            for (let i = 0; i < message.WakeupCalls.length; ++i)
                $root.WakeupCall.encode(message.WakeupCalls[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseWakeupCalls message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseWakeupCalls
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseWakeupCalls} ResponseWakeupCalls
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseWakeupCalls.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseWakeupCalls();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.WakeupCalls && message.WakeupCalls.length))
                        message.WakeupCalls = [];
                    message.WakeupCalls.push($root.WakeupCall.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseWakeupCalls message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseWakeupCalls
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseWakeupCalls.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 230, WakeupCalls : this});
    };

    /**
     * ResponseWakeupCalls typeId
     * @member {number} typeId
     * @memberof ResponseWakeupCalls
     * @instance
     */
    ResponseWakeupCalls.prototype.typeId = 230;
    /**
     * ResponseWakeupCalls typeName
     * @member {string} typeName
     * @memberof ResponseWakeupCalls
     * @instance
     */
    ResponseWakeupCalls.prototype.typeName = "WakeupCalls";

    return ResponseWakeupCalls;
})();

export const RequestOneTimeLogin = $root.RequestOneTimeLogin = (() => {

    /**
     * Properties of a RequestOneTimeLogin.
     * @exports IRequestOneTimeLogin
     */

    /**
     * Constructs a new RequestOneTimeLogin.
     * @exports RequestOneTimeLogin
     * @classdesc Represents a RequestOneTimeLogin.
     * @constructor
     * @param {Partial<RequestOneTimeLogin>} [properties] Properties to set
     */
    function RequestOneTimeLogin(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestOneTimeLogin message. Does not implicitly {@link RequestOneTimeLogin.verify|verify} messages.
     * @function encode
     * @memberof RequestOneTimeLogin
     * @static
     * @param {RequestOneTimeLogin} message RequestOneTimeLogin message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestOneTimeLogin.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestOneTimeLogin message from the specified reader or buffer.
     * @function decode
     * @memberof RequestOneTimeLogin
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestOneTimeLogin} RequestOneTimeLogin
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestOneTimeLogin.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestOneTimeLogin();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestOneTimeLogin message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestOneTimeLogin
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestOneTimeLogin.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 176, GetOneTimeLogin : this});
    };

    /**
     * RequestOneTimeLogin typeId
     * @member {number} typeId
     * @memberof RequestOneTimeLogin
     * @instance
     */
    RequestOneTimeLogin.prototype.typeId = 176;
    /**
     * RequestOneTimeLogin typeName
     * @member {string} typeName
     * @memberof RequestOneTimeLogin
     * @instance
     */
    RequestOneTimeLogin.prototype.typeName = "GetOneTimeLogin";

    return RequestOneTimeLogin;
})();

export const ResponseOneTimeLogin = $root.ResponseOneTimeLogin = (() => {

    /**
     * Properties of a ResponseOneTimeLogin.
     * @exports IResponseOneTimeLogin
     * @property {string} Token ResponseOneTimeLogin Token
     */

    /**
     * Constructs a new ResponseOneTimeLogin.
     * @exports ResponseOneTimeLogin
     * @classdesc Represents a ResponseOneTimeLogin.
     * @constructor
     * @param {Partial<ResponseOneTimeLogin>} [properties] Properties to set
     */
    function ResponseOneTimeLogin(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseOneTimeLogin Token.
     * @member {string} Token
     * @memberof ResponseOneTimeLogin
     * @instance
     */

    /**
     * Encodes the specified ResponseOneTimeLogin message. Does not implicitly {@link ResponseOneTimeLogin.verify|verify} messages.
     * @function encode
     * @memberof ResponseOneTimeLogin
     * @static
     * @param {ResponseOneTimeLogin} message ResponseOneTimeLogin message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseOneTimeLogin.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.Token);
        return writer;
    };

    /**
     * Decodes a ResponseOneTimeLogin message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseOneTimeLogin
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseOneTimeLogin} ResponseOneTimeLogin
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseOneTimeLogin.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseOneTimeLogin();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Token = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseOneTimeLogin message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseOneTimeLogin
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseOneTimeLogin.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 231, OneTimeLogin : this});
    };

    /**
     * ResponseOneTimeLogin typeId
     * @member {number} typeId
     * @memberof ResponseOneTimeLogin
     * @instance
     */
    ResponseOneTimeLogin.prototype.typeId = 231;
    /**
     * ResponseOneTimeLogin typeName
     * @member {string} typeName
     * @memberof ResponseOneTimeLogin
     * @instance
     */
    ResponseOneTimeLogin.prototype.typeName = "OneTimeLogin";

    return ResponseOneTimeLogin;
})();

export const PushSubscriptionData = $root.PushSubscriptionData = (() => {

    /**
     * Properties of a PushSubscriptionData.
     * @exports IPushSubscriptionData
     * @property {string} [DeliveryType] PushSubscriptionData DeliveryType
     * @property {string} [DestinationAddress] PushSubscriptionData DestinationAddress
     * @property {string} [ModelName] PushSubscriptionData ModelName
     * @property {string} [CustomName] PushSubscriptionData CustomName
     * @property {string} [Instance] PushSubscriptionData Instance
     * @property {string} [Options] PushSubscriptionData Options
     */

    /**
     * Constructs a new PushSubscriptionData.
     * @exports PushSubscriptionData
     * @classdesc Represents a PushSubscriptionData.
     * @constructor
     * @param {Partial<PushSubscriptionData>} [properties] Properties to set
     */
    function PushSubscriptionData(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PushSubscriptionData DeliveryType.
     * @member {string} DeliveryType
     * @memberof PushSubscriptionData
     * @instance
     */

    /**
     * PushSubscriptionData DestinationAddress.
     * @member {string} DestinationAddress
     * @memberof PushSubscriptionData
     * @instance
     */

    /**
     * PushSubscriptionData ModelName.
     * @member {string} ModelName
     * @memberof PushSubscriptionData
     * @instance
     */

    /**
     * PushSubscriptionData CustomName.
     * @member {string} CustomName
     * @memberof PushSubscriptionData
     * @instance
     */

    /**
     * PushSubscriptionData Instance.
     * @member {string} Instance
     * @memberof PushSubscriptionData
     * @instance
     */

    /**
     * PushSubscriptionData Options.
     * @member {string} Options
     * @memberof PushSubscriptionData
     * @instance
     */

    /**
     * Encodes the specified PushSubscriptionData message. Does not implicitly {@link PushSubscriptionData.verify|verify} messages.
     * @function encode
     * @memberof PushSubscriptionData
     * @static
     * @param {PushSubscriptionData} message PushSubscriptionData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushSubscriptionData.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.DeliveryType != null && Object.hasOwnProperty.call(message, "DeliveryType"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.DeliveryType);
        if (message.DestinationAddress != null && Object.hasOwnProperty.call(message, "DestinationAddress"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.DestinationAddress);
        if (message.ModelName != null && Object.hasOwnProperty.call(message, "ModelName"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.ModelName);
        if (message.CustomName != null && Object.hasOwnProperty.call(message, "CustomName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.CustomName);
        if (message.Instance != null && Object.hasOwnProperty.call(message, "Instance"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Instance);
        if (message.Options != null && Object.hasOwnProperty.call(message, "Options"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Options);
        return writer;
    };

    /**
     * Decodes a PushSubscriptionData message from the specified reader or buffer.
     * @function decode
     * @memberof PushSubscriptionData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PushSubscriptionData} PushSubscriptionData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushSubscriptionData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PushSubscriptionData();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.DeliveryType = reader.string();
                    break;
                }
            case 2: {
                    message.DestinationAddress = reader.string();
                    break;
                }
            case 3: {
                    message.ModelName = reader.string();
                    break;
                }
            case 4: {
                    message.CustomName = reader.string();
                    break;
                }
            case 5: {
                    message.Instance = reader.string();
                    break;
                }
            case 6: {
                    message.Options = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a PushSubscriptionData message to GenericMessage
     * @function toGenericMessage
     * @memberof PushSubscriptionData
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    PushSubscriptionData.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 178, RefreshSubscription : this});
    };

    /**
     * PushSubscriptionData typeId
     * @member {number} typeId
     * @memberof PushSubscriptionData
     * @instance
     */
    PushSubscriptionData.prototype.typeId = 178;
    /**
     * PushSubscriptionData typeName
     * @member {string} typeName
     * @memberof PushSubscriptionData
     * @instance
     */
    PushSubscriptionData.prototype.typeName = "RefreshSubscription";

    return PushSubscriptionData;
})();

export const PushSubscription = $root.PushSubscription = (() => {

    /**
     * Properties of a PushSubscription.
     * @exports IPushSubscription
     * @property {ActionType} Action PushSubscription Action
     * @property {number} Id PushSubscription Id
     * @property {PushSubscriptionData} [Subscription] PushSubscription Subscription
     * @property {DateTime} [LastActivity] PushSubscription LastActivity
     */

    /**
     * Constructs a new PushSubscription.
     * @exports PushSubscription
     * @classdesc Represents a PushSubscription.
     * @constructor
     * @param {Partial<PushSubscription>} [properties] Properties to set
     */
    function PushSubscription(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PushSubscription Action.
     * @member {ActionType} Action
     * @memberof PushSubscription
     * @instance
     */

    /**
     * PushSubscription Id.
     * @member {number} Id
     * @memberof PushSubscription
     * @instance
     */

    /**
     * PushSubscription Subscription.
     * @member {PushSubscriptionData} Subscription
     * @memberof PushSubscription
     * @instance
     */

    /**
     * PushSubscription LastActivity.
     * @member {DateTime} LastActivity
     * @memberof PushSubscription
     * @instance
     */

    /**
     * Encodes the specified PushSubscription message. Does not implicitly {@link PushSubscription.verify|verify} messages.
     * @function encode
     * @memberof PushSubscription
     * @static
     * @param {PushSubscription} message PushSubscription message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushSubscription.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Subscription != null && Object.hasOwnProperty.call(message, "Subscription"))
            $root.PushSubscriptionData.encode(message.Subscription, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.LastActivity != null && Object.hasOwnProperty.call(message, "LastActivity"))
            $root.DateTime.encode(message.LastActivity, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a PushSubscription message from the specified reader or buffer.
     * @function decode
     * @memberof PushSubscription
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PushSubscription} PushSubscription
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushSubscription.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PushSubscription();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Subscription = $root.PushSubscriptionData.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.LastActivity = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return PushSubscription;
})();

export const PushSubscriptions = $root.PushSubscriptions = (() => {

    /**
     * Properties of a PushSubscriptions.
     * @exports IPushSubscriptions
     * @property {ActionType} Action PushSubscriptions Action
     * @property {Array.<PushSubscription>} [Items] PushSubscriptions Items
     */

    /**
     * Constructs a new PushSubscriptions.
     * @exports PushSubscriptions
     * @classdesc Represents a PushSubscriptions.
     * @constructor
     * @param {Partial<PushSubscriptions>} [properties] Properties to set
     */
    function PushSubscriptions(properties) {
        this.Items = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * PushSubscriptions Action.
     * @member {ActionType} Action
     * @memberof PushSubscriptions
     * @instance
     */

    /**
     * PushSubscriptions Items.
     * @member {Array.<PushSubscription>} Items
     * @memberof PushSubscriptions
     * @instance
     */
    PushSubscriptions.prototype.Items = $util.emptyArray;

    /**
     * Encodes the specified PushSubscriptions message. Does not implicitly {@link PushSubscriptions.verify|verify} messages.
     * @function encode
     * @memberof PushSubscriptions
     * @static
     * @param {PushSubscriptions} message PushSubscriptions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    PushSubscriptions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Items != null && message.Items.length)
            for (let i = 0; i < message.Items.length; ++i)
                $root.PushSubscription.encode(message.Items[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a PushSubscriptions message from the specified reader or buffer.
     * @function decode
     * @memberof PushSubscriptions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {PushSubscriptions} PushSubscriptions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    PushSubscriptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.PushSubscriptions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Items && message.Items.length))
                        message.Items = [];
                    message.Items.push($root.PushSubscription.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return PushSubscriptions;
})();

export const RequestUpdatePushSubscriptions = $root.RequestUpdatePushSubscriptions = (() => {

    /**
     * Properties of a RequestUpdatePushSubscriptions.
     * @exports IRequestUpdatePushSubscriptions
     * @property {PushSubscriptions} [Subscriptions] RequestUpdatePushSubscriptions Subscriptions
     */

    /**
     * Constructs a new RequestUpdatePushSubscriptions.
     * @exports RequestUpdatePushSubscriptions
     * @classdesc Represents a RequestUpdatePushSubscriptions.
     * @constructor
     * @param {Partial<RequestUpdatePushSubscriptions>} [properties] Properties to set
     */
    function RequestUpdatePushSubscriptions(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdatePushSubscriptions Subscriptions.
     * @member {PushSubscriptions} Subscriptions
     * @memberof RequestUpdatePushSubscriptions
     * @instance
     */

    /**
     * Encodes the specified RequestUpdatePushSubscriptions message. Does not implicitly {@link RequestUpdatePushSubscriptions.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdatePushSubscriptions
     * @static
     * @param {RequestUpdatePushSubscriptions} message RequestUpdatePushSubscriptions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdatePushSubscriptions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Subscriptions != null && Object.hasOwnProperty.call(message, "Subscriptions"))
            $root.PushSubscriptions.encode(message.Subscriptions, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestUpdatePushSubscriptions message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdatePushSubscriptions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdatePushSubscriptions} RequestUpdatePushSubscriptions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdatePushSubscriptions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdatePushSubscriptions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Subscriptions = $root.PushSubscriptions.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdatePushSubscriptions message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdatePushSubscriptions
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdatePushSubscriptions.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 177, UpdatePushSubscriptions : this});
    };

    /**
     * RequestUpdatePushSubscriptions typeId
     * @member {number} typeId
     * @memberof RequestUpdatePushSubscriptions
     * @instance
     */
    RequestUpdatePushSubscriptions.prototype.typeId = 177;
    /**
     * RequestUpdatePushSubscriptions typeName
     * @member {string} typeName
     * @memberof RequestUpdatePushSubscriptions
     * @instance
     */
    RequestUpdatePushSubscriptions.prototype.typeName = "UpdatePushSubscriptions";

    return RequestUpdatePushSubscriptions;
})();

export const ChatTyping = $root.ChatTyping = (() => {

    /**
     * Properties of a ChatTyping.
     * @exports IChatTyping
     * @property {string} [Party] ChatTyping Party
     * @property {string} [User] ChatTyping User
     * @property {number} [IdConversation] ChatTyping IdConversation
     * @property {number} [IdRecipient] ChatTyping IdRecipient
     * @property {string} [CnvGid] ChatTyping CnvGid
     * @property {string} [MemberGid] ChatTyping MemberGid
     */

    /**
     * Constructs a new ChatTyping.
     * @exports ChatTyping
     * @classdesc Represents a ChatTyping.
     * @constructor
     * @param {Partial<ChatTyping>} [properties] Properties to set
     */
    function ChatTyping(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatTyping Party.
     * @member {string} Party
     * @memberof ChatTyping
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * ChatTyping User.
     * @member {string} User
     * @memberof ChatTyping
     * @instance
     */

    /**
     * ChatTyping IdConversation.
     * @member {number} IdConversation
     * @memberof ChatTyping
     * @instance
     */

    /**
     * ChatTyping IdRecipient.
     * @member {number} IdRecipient
     * @memberof ChatTyping
     * @instance
     */

    /**
     * ChatTyping CnvGid.
     * @member {string} CnvGid
     * @memberof ChatTyping
     * @instance
     */

    /**
     * ChatTyping MemberGid.
     * @member {string} MemberGid
     * @memberof ChatTyping
     * @instance
     */

    /**
     * Encodes the specified ChatTyping message. Does not implicitly {@link ChatTyping.verify|verify} messages.
     * @function encode
     * @memberof ChatTyping
     * @static
     * @param {ChatTyping} message ChatTyping message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatTyping.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Party != null && Object.hasOwnProperty.call(message, "Party"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.Party);
        if (message.User != null && Object.hasOwnProperty.call(message, "User"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.User);
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.IdConversation);
        if (message.IdRecipient != null && Object.hasOwnProperty.call(message, "IdRecipient"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.IdRecipient);
        if (message.CnvGid != null && Object.hasOwnProperty.call(message, "CnvGid"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.CnvGid);
        if (message.MemberGid != null && Object.hasOwnProperty.call(message, "MemberGid"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.MemberGid);
        return writer;
    };

    /**
     * Decodes a ChatTyping message from the specified reader or buffer.
     * @function decode
     * @memberof ChatTyping
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatTyping} ChatTyping
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatTyping.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatTyping();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Party = reader.string();
                    break;
                }
            case 2: {
                    message.User = reader.string();
                    break;
                }
            case 3: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 4: {
                    message.IdRecipient = reader.int32();
                    break;
                }
            case 5: {
                    message.CnvGid = reader.string();
                    break;
                }
            case 6: {
                    message.MemberGid = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ChatTyping message to GenericMessage
     * @function toGenericMessage
     * @memberof ChatTyping
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ChatTyping.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 180, UserTypingChat : this});
    };

    /**
     * ChatTyping typeId
     * @member {number} typeId
     * @memberof ChatTyping
     * @instance
     */
    ChatTyping.prototype.typeId = 180;
    /**
     * ChatTyping typeName
     * @member {string} typeName
     * @memberof ChatTyping
     * @instance
     */
    ChatTyping.prototype.typeName = "UserTypingChat";

    return ChatTyping;
})();

export const RequestBlacklistAnonymous = $root.RequestBlacklistAnonymous = (() => {

    /**
     * Properties of a RequestBlacklistAnonymous.
     * @exports IRequestBlacklistAnonymous
     * @property {string} [SessionId] RequestBlacklistAnonymous SessionId
     * @property {number} [ForMinutes] RequestBlacklistAnonymous ForMinutes
     * @property {string} [Reason] RequestBlacklistAnonymous Reason
     * @property {string} [MemberGid] RequestBlacklistAnonymous MemberGid
     */

    /**
     * Constructs a new RequestBlacklistAnonymous.
     * @exports RequestBlacklistAnonymous
     * @classdesc Represents a RequestBlacklistAnonymous.
     * @constructor
     * @param {Partial<RequestBlacklistAnonymous>} [properties] Properties to set
     */
    function RequestBlacklistAnonymous(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestBlacklistAnonymous SessionId.
     * @member {string} SessionId
     * @memberof RequestBlacklistAnonymous
     * @instance
     * @deprecated Avoid using it
     */

    /**
     * RequestBlacklistAnonymous ForMinutes.
     * @member {number} ForMinutes
     * @memberof RequestBlacklistAnonymous
     * @instance
     */

    /**
     * RequestBlacklistAnonymous Reason.
     * @member {string} Reason
     * @memberof RequestBlacklistAnonymous
     * @instance
     */

    /**
     * RequestBlacklistAnonymous MemberGid.
     * @member {string} MemberGid
     * @memberof RequestBlacklistAnonymous
     * @instance
     */

    /**
     * Encodes the specified RequestBlacklistAnonymous message. Does not implicitly {@link RequestBlacklistAnonymous.verify|verify} messages.
     * @function encode
     * @memberof RequestBlacklistAnonymous
     * @static
     * @param {RequestBlacklistAnonymous} message RequestBlacklistAnonymous message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestBlacklistAnonymous.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.SessionId != null && Object.hasOwnProperty.call(message, "SessionId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.SessionId);
        if (message.ForMinutes != null && Object.hasOwnProperty.call(message, "ForMinutes"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.ForMinutes);
        if (message.Reason != null && Object.hasOwnProperty.call(message, "Reason"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Reason);
        if (message.MemberGid != null && Object.hasOwnProperty.call(message, "MemberGid"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.MemberGid);
        return writer;
    };

    /**
     * Decodes a RequestBlacklistAnonymous message from the specified reader or buffer.
     * @function decode
     * @memberof RequestBlacklistAnonymous
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestBlacklistAnonymous} RequestBlacklistAnonymous
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestBlacklistAnonymous.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestBlacklistAnonymous();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.SessionId = reader.string();
                    break;
                }
            case 2: {
                    message.ForMinutes = reader.int32();
                    break;
                }
            case 3: {
                    message.Reason = reader.string();
                    break;
                }
            case 4: {
                    message.MemberGid = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestBlacklistAnonymous message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestBlacklistAnonymous
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestBlacklistAnonymous.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 182, BlockAnonymous : this});
    };

    /**
     * RequestBlacklistAnonymous typeId
     * @member {number} typeId
     * @memberof RequestBlacklistAnonymous
     * @instance
     */
    RequestBlacklistAnonymous.prototype.typeId = 182;
    /**
     * RequestBlacklistAnonymous typeName
     * @member {string} typeName
     * @memberof RequestBlacklistAnonymous
     * @instance
     */
    RequestBlacklistAnonymous.prototype.typeName = "BlockAnonymous";

    return RequestBlacklistAnonymous;
})();

export const NotificationC2CState = $root.NotificationC2CState = (() => {

    /**
     * Properties of a NotificationC2CState.
     * @exports INotificationC2CState
     * @property {boolean} [Connected] NotificationC2CState Connected
     */

    /**
     * Constructs a new NotificationC2CState.
     * @exports NotificationC2CState
     * @classdesc Represents a NotificationC2CState.
     * @constructor
     * @param {Partial<NotificationC2CState>} [properties] Properties to set
     */
    function NotificationC2CState(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotificationC2CState Connected.
     * @member {boolean} Connected
     * @memberof NotificationC2CState
     * @instance
     */

    /**
     * Encodes the specified NotificationC2CState message. Does not implicitly {@link NotificationC2CState.verify|verify} messages.
     * @function encode
     * @memberof NotificationC2CState
     * @static
     * @param {NotificationC2CState} message NotificationC2CState message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotificationC2CState.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Connected != null && Object.hasOwnProperty.call(message, "Connected"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.Connected);
        return writer;
    };

    /**
     * Decodes a NotificationC2CState message from the specified reader or buffer.
     * @function decode
     * @memberof NotificationC2CState
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NotificationC2CState} NotificationC2CState
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotificationC2CState.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NotificationC2CState();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Connected = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a NotificationC2CState message to GenericMessage
     * @function toGenericMessage
     * @memberof NotificationC2CState
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    NotificationC2CState.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 233, C2cState : this});
    };

    /**
     * NotificationC2CState typeId
     * @member {number} typeId
     * @memberof NotificationC2CState
     * @instance
     */
    NotificationC2CState.prototype.typeId = 233;
    /**
     * NotificationC2CState typeName
     * @member {string} typeName
     * @memberof NotificationC2CState
     * @instance
     */
    NotificationC2CState.prototype.typeName = "C2cState";

    return NotificationC2CState;
})();

export const RequestDisconnectAnonymous = $root.RequestDisconnectAnonymous = (() => {

    /**
     * Properties of a RequestDisconnectAnonymous.
     * @exports IRequestDisconnectAnonymous
     * @property {string} [SessionId] RequestDisconnectAnonymous SessionId
     * @property {boolean} [IsHandled] RequestDisconnectAnonymous IsHandled
     */

    /**
     * Constructs a new RequestDisconnectAnonymous.
     * @exports RequestDisconnectAnonymous
     * @classdesc Represents a RequestDisconnectAnonymous.
     * @constructor
     * @param {Partial<RequestDisconnectAnonymous>} [properties] Properties to set
     */
    function RequestDisconnectAnonymous(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestDisconnectAnonymous SessionId.
     * @member {string} SessionId
     * @memberof RequestDisconnectAnonymous
     * @instance
     */

    /**
     * RequestDisconnectAnonymous IsHandled.
     * @member {boolean} IsHandled
     * @memberof RequestDisconnectAnonymous
     * @instance
     */

    /**
     * Encodes the specified RequestDisconnectAnonymous message. Does not implicitly {@link RequestDisconnectAnonymous.verify|verify} messages.
     * @function encode
     * @memberof RequestDisconnectAnonymous
     * @static
     * @param {RequestDisconnectAnonymous} message RequestDisconnectAnonymous message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestDisconnectAnonymous.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.SessionId != null && Object.hasOwnProperty.call(message, "SessionId"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.SessionId);
        if (message.IsHandled != null && Object.hasOwnProperty.call(message, "IsHandled"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsHandled);
        return writer;
    };

    /**
     * Decodes a RequestDisconnectAnonymous message from the specified reader or buffer.
     * @function decode
     * @memberof RequestDisconnectAnonymous
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestDisconnectAnonymous} RequestDisconnectAnonymous
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestDisconnectAnonymous.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestDisconnectAnonymous();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.SessionId = reader.string();
                    break;
                }
            case 2: {
                    message.IsHandled = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestDisconnectAnonymous message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestDisconnectAnonymous
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestDisconnectAnonymous.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 183, DisconnectAnonymous : this});
    };

    /**
     * RequestDisconnectAnonymous typeId
     * @member {number} typeId
     * @memberof RequestDisconnectAnonymous
     * @instance
     */
    RequestDisconnectAnonymous.prototype.typeId = 183;
    /**
     * RequestDisconnectAnonymous typeName
     * @member {string} typeName
     * @memberof RequestDisconnectAnonymous
     * @instance
     */
    RequestDisconnectAnonymous.prototype.typeName = "DisconnectAnonymous";

    return RequestDisconnectAnonymous;
})();

export const RecordingParticipant = $root.RecordingParticipant = (() => {

    /**
     * Properties of a RecordingParticipant.
     * @exports IRecordingParticipant
     * @property {DnType} [DNType] RecordingParticipant DNType
     * @property {string} [DN] RecordingParticipant DN
     * @property {string} [CallerId] RecordingParticipant CallerId
     * @property {string} [DNName] RecordingParticipant DNName
     * @property {string} [DidNumber] RecordingParticipant DidNumber
     * @property {Contact} [CallerContact] RecordingParticipant CallerContact
     */

    /**
     * Constructs a new RecordingParticipant.
     * @exports RecordingParticipant
     * @classdesc Represents a RecordingParticipant.
     * @constructor
     * @param {Partial<RecordingParticipant>} [properties] Properties to set
     */
    function RecordingParticipant(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RecordingParticipant DNType.
     * @member {DnType} DNType
     * @memberof RecordingParticipant
     * @instance
     */

    /**
     * RecordingParticipant DN.
     * @member {string} DN
     * @memberof RecordingParticipant
     * @instance
     */

    /**
     * RecordingParticipant CallerId.
     * @member {string} CallerId
     * @memberof RecordingParticipant
     * @instance
     */

    /**
     * RecordingParticipant DNName.
     * @member {string} DNName
     * @memberof RecordingParticipant
     * @instance
     */

    /**
     * RecordingParticipant DidNumber.
     * @member {string} DidNumber
     * @memberof RecordingParticipant
     * @instance
     */

    /**
     * RecordingParticipant CallerContact.
     * @member {Contact} CallerContact
     * @memberof RecordingParticipant
     * @instance
     */

    /**
     * Encodes the specified RecordingParticipant message. Does not implicitly {@link RecordingParticipant.verify|verify} messages.
     * @function encode
     * @memberof RecordingParticipant
     * @static
     * @param {RecordingParticipant} message RecordingParticipant message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RecordingParticipant.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.DNType != null && Object.hasOwnProperty.call(message, "DNType"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.DNType);
        if (message.DN != null && Object.hasOwnProperty.call(message, "DN"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.DN);
        if (message.CallerId != null && Object.hasOwnProperty.call(message, "CallerId"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.CallerId);
        if (message.DNName != null && Object.hasOwnProperty.call(message, "DNName"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.DNName);
        if (message.DidNumber != null && Object.hasOwnProperty.call(message, "DidNumber"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.DidNumber);
        if (message.CallerContact != null && Object.hasOwnProperty.call(message, "CallerContact"))
            $root.Contact.encode(message.CallerContact, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RecordingParticipant message from the specified reader or buffer.
     * @function decode
     * @memberof RecordingParticipant
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RecordingParticipant} RecordingParticipant
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RecordingParticipant.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RecordingParticipant();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 2: {
                    message.DNType = reader.int32();
                    break;
                }
            case 3: {
                    message.DN = reader.string();
                    break;
                }
            case 4: {
                    message.CallerId = reader.string();
                    break;
                }
            case 5: {
                    message.DNName = reader.string();
                    break;
                }
            case 6: {
                    message.DidNumber = reader.string();
                    break;
                }
            case 7: {
                    message.CallerContact = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return RecordingParticipant;
})();

export const RecordingData = $root.RecordingData = (() => {

    /**
     * Properties of a RecordingData.
     * @exports IRecordingData
     * @property {number} Id RecordingData Id
     * @property {Timestamp} [StartTime] RecordingData StartTime
     * @property {Timestamp} [EndTime] RecordingData EndTime
     * @property {Array.<RecordingParticipant>} [Participants] RecordingData Participants
     * @property {string} [Transcription] RecordingData Transcription
     * @property {boolean} [IsArchived] RecordingData IsArchived
     * @property {boolean} [CanDelete] RecordingData CanDelete
     */

    /**
     * Constructs a new RecordingData.
     * @exports RecordingData
     * @classdesc Represents a RecordingData.
     * @constructor
     * @param {Partial<RecordingData>} [properties] Properties to set
     */
    function RecordingData(properties) {
        this.Participants = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RecordingData Id.
     * @member {number} Id
     * @memberof RecordingData
     * @instance
     */

    /**
     * RecordingData StartTime.
     * @member {Timestamp} StartTime
     * @memberof RecordingData
     * @instance
     */

    /**
     * RecordingData EndTime.
     * @member {Timestamp} EndTime
     * @memberof RecordingData
     * @instance
     */

    /**
     * RecordingData Participants.
     * @member {Array.<RecordingParticipant>} Participants
     * @memberof RecordingData
     * @instance
     */
    RecordingData.prototype.Participants = $util.emptyArray;

    /**
     * RecordingData Transcription.
     * @member {string} Transcription
     * @memberof RecordingData
     * @instance
     */

    /**
     * RecordingData IsArchived.
     * @member {boolean} IsArchived
     * @memberof RecordingData
     * @instance
     */

    /**
     * RecordingData CanDelete.
     * @member {boolean} CanDelete
     * @memberof RecordingData
     * @instance
     */

    /**
     * Encodes the specified RecordingData message. Does not implicitly {@link RecordingData.verify|verify} messages.
     * @function encode
     * @memberof RecordingData
     * @static
     * @param {RecordingData} message RecordingData message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RecordingData.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.StartTime != null && Object.hasOwnProperty.call(message, "StartTime"))
            $root.Timestamp.encode(message.StartTime, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.EndTime != null && Object.hasOwnProperty.call(message, "EndTime"))
            $root.Timestamp.encode(message.EndTime, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.Participants != null && message.Participants.length)
            for (let i = 0; i < message.Participants.length; ++i)
                $root.RecordingParticipant.encode(message.Participants[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.Transcription != null && Object.hasOwnProperty.call(message, "Transcription"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Transcription);
        if (message.IsArchived != null && Object.hasOwnProperty.call(message, "IsArchived"))
            writer.uint32(/* id 7, wireType 0 =*/56).bool(message.IsArchived);
        if (message.CanDelete != null && Object.hasOwnProperty.call(message, "CanDelete"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.CanDelete);
        return writer;
    };

    /**
     * Decodes a RecordingData message from the specified reader or buffer.
     * @function decode
     * @memberof RecordingData
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RecordingData} RecordingData
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RecordingData.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RecordingData();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.StartTime = $root.Timestamp.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.EndTime = $root.Timestamp.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    if (!(message.Participants && message.Participants.length))
                        message.Participants = [];
                    message.Participants.push($root.RecordingParticipant.decode(reader, reader.uint32()));
                    break;
                }
            case 6: {
                    message.Transcription = reader.string();
                    break;
                }
            case 7: {
                    message.IsArchived = reader.bool();
                    break;
                }
            case 9: {
                    message.CanDelete = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return RecordingData;
})();

export const ResponseRecordings = $root.ResponseRecordings = (() => {

    /**
     * Properties of a ResponseRecordings.
     * @exports IResponseRecordings
     * @property {Array.<RecordingData>} [Records] ResponseRecordings Records
     */

    /**
     * Constructs a new ResponseRecordings.
     * @exports ResponseRecordings
     * @classdesc Represents a ResponseRecordings.
     * @constructor
     * @param {Partial<ResponseRecordings>} [properties] Properties to set
     */
    function ResponseRecordings(properties) {
        this.Records = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseRecordings Records.
     * @member {Array.<RecordingData>} Records
     * @memberof ResponseRecordings
     * @instance
     */
    ResponseRecordings.prototype.Records = $util.emptyArray;

    /**
     * Encodes the specified ResponseRecordings message. Does not implicitly {@link ResponseRecordings.verify|verify} messages.
     * @function encode
     * @memberof ResponseRecordings
     * @static
     * @param {ResponseRecordings} message ResponseRecordings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseRecordings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Records != null && message.Records.length)
            for (let i = 0; i < message.Records.length; ++i)
                $root.RecordingData.encode(message.Records[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseRecordings message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseRecordings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseRecordings} ResponseRecordings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseRecordings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseRecordings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Records && message.Records.length))
                        message.Records = [];
                    message.Records.push($root.RecordingData.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseRecordings message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseRecordings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseRecordings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 234, ResponseRecordings : this});
    };

    /**
     * ResponseRecordings typeId
     * @member {number} typeId
     * @memberof ResponseRecordings
     * @instance
     */
    ResponseRecordings.prototype.typeId = 234;
    /**
     * ResponseRecordings typeName
     * @member {string} typeName
     * @memberof ResponseRecordings
     * @instance
     */
    ResponseRecordings.prototype.typeName = "ResponseRecordings";

    return ResponseRecordings;
})();

export const ParticipantFilter = $root.ParticipantFilter = (() => {

    /**
     * Properties of a ParticipantFilter.
     * @exports IParticipantFilter
     * @property {string} [DidNumber] ParticipantFilter DidNumber
     * @property {string} [DNName] ParticipantFilter DNName
     * @property {string} [CallerId] ParticipantFilter CallerId
     */

    /**
     * Constructs a new ParticipantFilter.
     * @exports ParticipantFilter
     * @classdesc Represents a ParticipantFilter.
     * @constructor
     * @param {Partial<ParticipantFilter>} [properties] Properties to set
     */
    function ParticipantFilter(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ParticipantFilter DidNumber.
     * @member {string} DidNumber
     * @memberof ParticipantFilter
     * @instance
     */

    /**
     * ParticipantFilter DNName.
     * @member {string} DNName
     * @memberof ParticipantFilter
     * @instance
     */

    /**
     * ParticipantFilter CallerId.
     * @member {string} CallerId
     * @memberof ParticipantFilter
     * @instance
     */

    /**
     * Encodes the specified ParticipantFilter message. Does not implicitly {@link ParticipantFilter.verify|verify} messages.
     * @function encode
     * @memberof ParticipantFilter
     * @static
     * @param {ParticipantFilter} message ParticipantFilter message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ParticipantFilter.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.DidNumber != null && Object.hasOwnProperty.call(message, "DidNumber"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.DidNumber);
        if (message.DNName != null && Object.hasOwnProperty.call(message, "DNName"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.DNName);
        if (message.CallerId != null && Object.hasOwnProperty.call(message, "CallerId"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.CallerId);
        return writer;
    };

    /**
     * Decodes a ParticipantFilter message from the specified reader or buffer.
     * @function decode
     * @memberof ParticipantFilter
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ParticipantFilter} ParticipantFilter
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ParticipantFilter.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ParticipantFilter();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.DidNumber = reader.string();
                    break;
                }
            case 2: {
                    message.DNName = reader.string();
                    break;
                }
            case 3: {
                    message.CallerId = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ParticipantFilter;
})();

export const RequestGetRecordings = $root.RequestGetRecordings = (() => {

    /**
     * Properties of a RequestGetRecordings.
     * @exports IRequestGetRecordings
     * @property {Timestamp} [After] RequestGetRecordings After
     * @property {Timestamp} [Before] RequestGetRecordings Before
     * @property {boolean} [IsArchived] RequestGetRecordings IsArchived
     * @property {boolean} [InternalCallsOnly] RequestGetRecordings InternalCallsOnly
     * @property {number} [Offset] RequestGetRecordings Offset
     * @property {number} [Limit] RequestGetRecordings Limit
     * @property {ParticipantFilter} [From] RequestGetRecordings From
     * @property {ParticipantFilter} [To] RequestGetRecordings To
     * @property {ParticipantFilter} [Filter] RequestGetRecordings Filter
     * @property {boolean} [PersonalOnly] RequestGetRecordings PersonalOnly
     * @property {string} [Transcription] RequestGetRecordings Transcription
     */

    /**
     * Constructs a new RequestGetRecordings.
     * @exports RequestGetRecordings
     * @classdesc Represents a RequestGetRecordings.
     * @constructor
     * @param {Partial<RequestGetRecordings>} [properties] Properties to set
     */
    function RequestGetRecordings(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetRecordings After.
     * @member {Timestamp} After
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings Before.
     * @member {Timestamp} Before
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings IsArchived.
     * @member {boolean} IsArchived
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings InternalCallsOnly.
     * @member {boolean} InternalCallsOnly
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings Offset.
     * @member {number} Offset
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings Limit.
     * @member {number} Limit
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings From.
     * @member {ParticipantFilter} From
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings To.
     * @member {ParticipantFilter} To
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings Filter.
     * @member {ParticipantFilter} Filter
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings PersonalOnly.
     * @member {boolean} PersonalOnly
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * RequestGetRecordings Transcription.
     * @member {string} Transcription
     * @memberof RequestGetRecordings
     * @instance
     */

    /**
     * Encodes the specified RequestGetRecordings message. Does not implicitly {@link RequestGetRecordings.verify|verify} messages.
     * @function encode
     * @memberof RequestGetRecordings
     * @static
     * @param {RequestGetRecordings} message RequestGetRecordings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetRecordings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.After != null && Object.hasOwnProperty.call(message, "After"))
            $root.Timestamp.encode(message.After, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.Before != null && Object.hasOwnProperty.call(message, "Before"))
            $root.Timestamp.encode(message.Before, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.IsArchived != null && Object.hasOwnProperty.call(message, "IsArchived"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.IsArchived);
        if (message.InternalCallsOnly != null && Object.hasOwnProperty.call(message, "InternalCallsOnly"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.InternalCallsOnly);
        if (message.Offset != null && Object.hasOwnProperty.call(message, "Offset"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.Offset);
        if (message.Limit != null && Object.hasOwnProperty.call(message, "Limit"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.Limit);
        if (message.From != null && Object.hasOwnProperty.call(message, "From"))
            $root.ParticipantFilter.encode(message.From, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.To != null && Object.hasOwnProperty.call(message, "To"))
            $root.ParticipantFilter.encode(message.To, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.Filter != null && Object.hasOwnProperty.call(message, "Filter"))
            $root.ParticipantFilter.encode(message.Filter, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.PersonalOnly != null && Object.hasOwnProperty.call(message, "PersonalOnly"))
            writer.uint32(/* id 10, wireType 0 =*/80).bool(message.PersonalOnly);
        if (message.Transcription != null && Object.hasOwnProperty.call(message, "Transcription"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.Transcription);
        return writer;
    };

    /**
     * Decodes a RequestGetRecordings message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetRecordings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetRecordings} RequestGetRecordings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetRecordings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetRecordings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.After = $root.Timestamp.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.Before = $root.Timestamp.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.IsArchived = reader.bool();
                    break;
                }
            case 4: {
                    message.InternalCallsOnly = reader.bool();
                    break;
                }
            case 5: {
                    message.Offset = reader.int32();
                    break;
                }
            case 6: {
                    message.Limit = reader.int32();
                    break;
                }
            case 7: {
                    message.From = $root.ParticipantFilter.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.To = $root.ParticipantFilter.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.Filter = $root.ParticipantFilter.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.PersonalOnly = reader.bool();
                    break;
                }
            case 11: {
                    message.Transcription = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetRecordings message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetRecordings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetRecordings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 184, GetRecordings : this});
    };

    /**
     * RequestGetRecordings typeId
     * @member {number} typeId
     * @memberof RequestGetRecordings
     * @instance
     */
    RequestGetRecordings.prototype.typeId = 184;
    /**
     * RequestGetRecordings typeName
     * @member {string} typeName
     * @memberof RequestGetRecordings
     * @instance
     */
    RequestGetRecordings.prototype.typeName = "GetRecordings";

    return RequestGetRecordings;
})();

export const RequestDeleteRecording = $root.RequestDeleteRecording = (() => {

    /**
     * Properties of a RequestDeleteRecording.
     * @exports IRequestDeleteRecording
     * @property {number} Id RequestDeleteRecording Id
     * @property {boolean} [Delete] RequestDeleteRecording Delete
     * @property {boolean} [Archive] RequestDeleteRecording Archive
     */

    /**
     * Constructs a new RequestDeleteRecording.
     * @exports RequestDeleteRecording
     * @classdesc Represents a RequestDeleteRecording.
     * @constructor
     * @param {Partial<RequestDeleteRecording>} [properties] Properties to set
     */
    function RequestDeleteRecording(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestDeleteRecording Id.
     * @member {number} Id
     * @memberof RequestDeleteRecording
     * @instance
     */

    /**
     * RequestDeleteRecording Delete.
     * @member {boolean} Delete
     * @memberof RequestDeleteRecording
     * @instance
     */

    /**
     * RequestDeleteRecording Archive.
     * @member {boolean} Archive
     * @memberof RequestDeleteRecording
     * @instance
     */

    /**
     * Encodes the specified RequestDeleteRecording message. Does not implicitly {@link RequestDeleteRecording.verify|verify} messages.
     * @function encode
     * @memberof RequestDeleteRecording
     * @static
     * @param {RequestDeleteRecording} message RequestDeleteRecording message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestDeleteRecording.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.Delete != null && Object.hasOwnProperty.call(message, "Delete"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.Delete);
        if (message.Archive != null && Object.hasOwnProperty.call(message, "Archive"))
            writer.uint32(/* id 3, wireType 0 =*/24).bool(message.Archive);
        return writer;
    };

    /**
     * Decodes a RequestDeleteRecording message from the specified reader or buffer.
     * @function decode
     * @memberof RequestDeleteRecording
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestDeleteRecording} RequestDeleteRecording
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestDeleteRecording.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestDeleteRecording();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.Delete = reader.bool();
                    break;
                }
            case 3: {
                    message.Archive = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestDeleteRecording message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestDeleteRecording
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestDeleteRecording.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 185, DeleteRecording : this});
    };

    /**
     * RequestDeleteRecording typeId
     * @member {number} typeId
     * @memberof RequestDeleteRecording
     * @instance
     */
    RequestDeleteRecording.prototype.typeId = 185;
    /**
     * RequestDeleteRecording typeName
     * @member {string} typeName
     * @memberof RequestDeleteRecording
     * @instance
     */
    RequestDeleteRecording.prototype.typeName = "DeleteRecording";

    return RequestDeleteRecording;
})();

export const ChatRating = $root.ChatRating = (() => {

    /**
     * Properties of a ChatRating.
     * @exports IChatRating
     * @property {number} [IdConversation] ChatRating IdConversation
     * @property {number} [Rating] ChatRating Rating
     * @property {string} [Comments] ChatRating Comments
     */

    /**
     * Constructs a new ChatRating.
     * @exports ChatRating
     * @classdesc Represents a ChatRating.
     * @constructor
     * @param {Partial<ChatRating>} [properties] Properties to set
     */
    function ChatRating(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatRating IdConversation.
     * @member {number} IdConversation
     * @memberof ChatRating
     * @instance
     */

    /**
     * ChatRating Rating.
     * @member {number} Rating
     * @memberof ChatRating
     * @instance
     */

    /**
     * ChatRating Comments.
     * @member {string} Comments
     * @memberof ChatRating
     * @instance
     */

    /**
     * Encodes the specified ChatRating message. Does not implicitly {@link ChatRating.verify|verify} messages.
     * @function encode
     * @memberof ChatRating
     * @static
     * @param {ChatRating} message ChatRating message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatRating.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.Rating != null && Object.hasOwnProperty.call(message, "Rating"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Rating);
        if (message.Comments != null && Object.hasOwnProperty.call(message, "Comments"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Comments);
        return writer;
    };

    /**
     * Decodes a ChatRating message from the specified reader or buffer.
     * @function decode
     * @memberof ChatRating
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatRating} ChatRating
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatRating.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatRating();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.Rating = reader.int32();
                    break;
                }
            case 3: {
                    message.Comments = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatRating;
})();

export const RequestChatRating = $root.RequestChatRating = (() => {

    /**
     * Properties of a RequestChatRating.
     * @exports IRequestChatRating
     * @property {ChatRating} [Rating] RequestChatRating Rating
     */

    /**
     * Constructs a new RequestChatRating.
     * @exports RequestChatRating
     * @classdesc Represents a RequestChatRating.
     * @constructor
     * @param {Partial<RequestChatRating>} [properties] Properties to set
     */
    function RequestChatRating(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestChatRating Rating.
     * @member {ChatRating} Rating
     * @memberof RequestChatRating
     * @instance
     */

    /**
     * Encodes the specified RequestChatRating message. Does not implicitly {@link RequestChatRating.verify|verify} messages.
     * @function encode
     * @memberof RequestChatRating
     * @static
     * @param {RequestChatRating} message RequestChatRating message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestChatRating.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Rating != null && Object.hasOwnProperty.call(message, "Rating"))
            $root.ChatRating.encode(message.Rating, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestChatRating message from the specified reader or buffer.
     * @function decode
     * @memberof RequestChatRating
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestChatRating} RequestChatRating
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestChatRating.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestChatRating();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Rating = $root.ChatRating.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestChatRating message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestChatRating
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestChatRating.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 191, SendChatRating : this});
    };

    /**
     * RequestChatRating typeId
     * @member {number} typeId
     * @memberof RequestChatRating
     * @instance
     */
    RequestChatRating.prototype.typeId = 191;
    /**
     * RequestChatRating typeName
     * @member {string} typeName
     * @memberof RequestChatRating
     * @instance
     */
    RequestChatRating.prototype.typeName = "SendChatRating";

    return RequestChatRating;
})();

export const NotificationChatRating = $root.NotificationChatRating = (() => {

    /**
     * Properties of a NotificationChatRating.
     * @exports INotificationChatRating
     * @property {ChatRating} [Rating] NotificationChatRating Rating
     */

    /**
     * Constructs a new NotificationChatRating.
     * @exports NotificationChatRating
     * @classdesc Represents a NotificationChatRating.
     * @constructor
     * @param {Partial<NotificationChatRating>} [properties] Properties to set
     */
    function NotificationChatRating(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * NotificationChatRating Rating.
     * @member {ChatRating} Rating
     * @memberof NotificationChatRating
     * @instance
     */

    /**
     * Encodes the specified NotificationChatRating message. Does not implicitly {@link NotificationChatRating.verify|verify} messages.
     * @function encode
     * @memberof NotificationChatRating
     * @static
     * @param {NotificationChatRating} message NotificationChatRating message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    NotificationChatRating.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Rating != null && Object.hasOwnProperty.call(message, "Rating"))
            $root.ChatRating.encode(message.Rating, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a NotificationChatRating message from the specified reader or buffer.
     * @function decode
     * @memberof NotificationChatRating
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {NotificationChatRating} NotificationChatRating
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    NotificationChatRating.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.NotificationChatRating();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Rating = $root.ChatRating.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a NotificationChatRating message to GenericMessage
     * @function toGenericMessage
     * @memberof NotificationChatRating
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    NotificationChatRating.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 238, ChatRating : this});
    };

    /**
     * NotificationChatRating typeId
     * @member {number} typeId
     * @memberof NotificationChatRating
     * @instance
     */
    NotificationChatRating.prototype.typeId = 238;
    /**
     * NotificationChatRating typeName
     * @member {string} typeName
     * @memberof NotificationChatRating
     * @instance
     */
    NotificationChatRating.prototype.typeName = "ChatRating";

    return NotificationChatRating;
})();

export const RequestMyQueues = $root.RequestMyQueues = (() => {

    /**
     * Properties of a RequestMyQueues.
     * @exports IRequestMyQueues
     */

    /**
     * Constructs a new RequestMyQueues.
     * @exports RequestMyQueues
     * @classdesc Represents a RequestMyQueues.
     * @constructor
     * @param {Partial<RequestMyQueues>} [properties] Properties to set
     */
    function RequestMyQueues(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestMyQueues message. Does not implicitly {@link RequestMyQueues.verify|verify} messages.
     * @function encode
     * @memberof RequestMyQueues
     * @static
     * @param {RequestMyQueues} message RequestMyQueues message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestMyQueues.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestMyQueues message from the specified reader or buffer.
     * @function decode
     * @memberof RequestMyQueues
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestMyQueues} RequestMyQueues
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestMyQueues.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestMyQueues();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestMyQueues message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestMyQueues
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestMyQueues.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 501, GetMyQueues : this});
    };

    /**
     * RequestMyQueues typeId
     * @member {number} typeId
     * @memberof RequestMyQueues
     * @instance
     */
    RequestMyQueues.prototype.typeId = 501;
    /**
     * RequestMyQueues typeName
     * @member {string} typeName
     * @memberof RequestMyQueues
     * @instance
     */
    RequestMyQueues.prototype.typeName = "GetMyQueues";

    return RequestMyQueues;
})();

export const ResponseMyQueues = $root.ResponseMyQueues = (() => {

    /**
     * Properties of a ResponseMyQueues.
     * @exports IResponseMyQueues
     * @property {Array.<string>} [QueueNumber] ResponseMyQueues QueueNumber
     */

    /**
     * Constructs a new ResponseMyQueues.
     * @exports ResponseMyQueues
     * @classdesc Represents a ResponseMyQueues.
     * @constructor
     * @param {Partial<ResponseMyQueues>} [properties] Properties to set
     */
    function ResponseMyQueues(properties) {
        this.QueueNumber = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseMyQueues QueueNumber.
     * @member {Array.<string>} QueueNumber
     * @memberof ResponseMyQueues
     * @instance
     */
    ResponseMyQueues.prototype.QueueNumber = $util.emptyArray;

    /**
     * Encodes the specified ResponseMyQueues message. Does not implicitly {@link ResponseMyQueues.verify|verify} messages.
     * @function encode
     * @memberof ResponseMyQueues
     * @static
     * @param {ResponseMyQueues} message ResponseMyQueues message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseMyQueues.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.QueueNumber != null && message.QueueNumber.length)
            for (let i = 0; i < message.QueueNumber.length; ++i)
                writer.uint32(/* id 1, wireType 2 =*/10).string(message.QueueNumber[i]);
        return writer;
    };

    /**
     * Decodes a ResponseMyQueues message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseMyQueues
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseMyQueues} ResponseMyQueues
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseMyQueues.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseMyQueues();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.QueueNumber && message.QueueNumber.length))
                        message.QueueNumber = [];
                    message.QueueNumber.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseMyQueues message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseMyQueues
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseMyQueues.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 245, MyQueues : this});
    };

    /**
     * ResponseMyQueues typeId
     * @member {number} typeId
     * @memberof ResponseMyQueues
     * @instance
     */
    ResponseMyQueues.prototype.typeId = 245;
    /**
     * ResponseMyQueues typeName
     * @member {string} typeName
     * @memberof ResponseMyQueues
     * @instance
     */
    ResponseMyQueues.prototype.typeName = "MyQueues";

    return ResponseMyQueues;
})();

export const RequestStartChatMonitoring = $root.RequestStartChatMonitoring = (() => {

    /**
     * Properties of a RequestStartChatMonitoring.
     * @exports IRequestStartChatMonitoring
     * @property {string} [QueueNumber] RequestStartChatMonitoring QueueNumber
     */

    /**
     * Constructs a new RequestStartChatMonitoring.
     * @exports RequestStartChatMonitoring
     * @classdesc Represents a RequestStartChatMonitoring.
     * @constructor
     * @param {Partial<RequestStartChatMonitoring>} [properties] Properties to set
     */
    function RequestStartChatMonitoring(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestStartChatMonitoring QueueNumber.
     * @member {string} QueueNumber
     * @memberof RequestStartChatMonitoring
     * @instance
     */

    /**
     * Encodes the specified RequestStartChatMonitoring message. Does not implicitly {@link RequestStartChatMonitoring.verify|verify} messages.
     * @function encode
     * @memberof RequestStartChatMonitoring
     * @static
     * @param {RequestStartChatMonitoring} message RequestStartChatMonitoring message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestStartChatMonitoring.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.QueueNumber != null && Object.hasOwnProperty.call(message, "QueueNumber"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.QueueNumber);
        return writer;
    };

    /**
     * Decodes a RequestStartChatMonitoring message from the specified reader or buffer.
     * @function decode
     * @memberof RequestStartChatMonitoring
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestStartChatMonitoring} RequestStartChatMonitoring
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestStartChatMonitoring.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestStartChatMonitoring();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.QueueNumber = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestStartChatMonitoring message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestStartChatMonitoring
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestStartChatMonitoring.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 195, StartChatMonitoring : this});
    };

    /**
     * RequestStartChatMonitoring typeId
     * @member {number} typeId
     * @memberof RequestStartChatMonitoring
     * @instance
     */
    RequestStartChatMonitoring.prototype.typeId = 195;
    /**
     * RequestStartChatMonitoring typeName
     * @member {string} typeName
     * @memberof RequestStartChatMonitoring
     * @instance
     */
    RequestStartChatMonitoring.prototype.typeName = "StartChatMonitoring";

    return RequestStartChatMonitoring;
})();

export const RequestStopChatMonitoring = $root.RequestStopChatMonitoring = (() => {

    /**
     * Properties of a RequestStopChatMonitoring.
     * @exports IRequestStopChatMonitoring
     */

    /**
     * Constructs a new RequestStopChatMonitoring.
     * @exports RequestStopChatMonitoring
     * @classdesc Represents a RequestStopChatMonitoring.
     * @constructor
     * @param {Partial<RequestStopChatMonitoring>} [properties] Properties to set
     */
    function RequestStopChatMonitoring(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestStopChatMonitoring message. Does not implicitly {@link RequestStopChatMonitoring.verify|verify} messages.
     * @function encode
     * @memberof RequestStopChatMonitoring
     * @static
     * @param {RequestStopChatMonitoring} message RequestStopChatMonitoring message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestStopChatMonitoring.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestStopChatMonitoring message from the specified reader or buffer.
     * @function decode
     * @memberof RequestStopChatMonitoring
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestStopChatMonitoring} RequestStopChatMonitoring
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestStopChatMonitoring.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestStopChatMonitoring();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestStopChatMonitoring message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestStopChatMonitoring
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestStopChatMonitoring.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 196, StopChatMonitoring : this});
    };

    /**
     * RequestStopChatMonitoring typeId
     * @member {number} typeId
     * @memberof RequestStopChatMonitoring
     * @instance
     */
    RequestStopChatMonitoring.prototype.typeId = 196;
    /**
     * RequestStopChatMonitoring typeName
     * @member {string} typeName
     * @memberof RequestStopChatMonitoring
     * @instance
     */
    RequestStopChatMonitoring.prototype.typeName = "StopChatMonitoring";

    return RequestStopChatMonitoring;
})();

export const ResponseChatMonitoring = $root.ResponseChatMonitoring = (() => {

    /**
     * Properties of a ResponseChatMonitoring.
     * @exports IResponseChatMonitoring
     * @property {Array.<ChatPartyInfo>} [Conversations] ResponseChatMonitoring Conversations
     * @property {Array.<ChatMessage>} [Messages] ResponseChatMonitoring Messages
     */

    /**
     * Constructs a new ResponseChatMonitoring.
     * @exports ResponseChatMonitoring
     * @classdesc Represents a ResponseChatMonitoring.
     * @constructor
     * @param {Partial<ResponseChatMonitoring>} [properties] Properties to set
     */
    function ResponseChatMonitoring(properties) {
        this.Conversations = [];
        this.Messages = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseChatMonitoring Conversations.
     * @member {Array.<ChatPartyInfo>} Conversations
     * @memberof ResponseChatMonitoring
     * @instance
     */
    ResponseChatMonitoring.prototype.Conversations = $util.emptyArray;

    /**
     * ResponseChatMonitoring Messages.
     * @member {Array.<ChatMessage>} Messages
     * @memberof ResponseChatMonitoring
     * @instance
     */
    ResponseChatMonitoring.prototype.Messages = $util.emptyArray;

    /**
     * Encodes the specified ResponseChatMonitoring message. Does not implicitly {@link ResponseChatMonitoring.verify|verify} messages.
     * @function encode
     * @memberof ResponseChatMonitoring
     * @static
     * @param {ResponseChatMonitoring} message ResponseChatMonitoring message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseChatMonitoring.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Conversations != null && message.Conversations.length)
            for (let i = 0; i < message.Conversations.length; ++i)
                $root.ChatPartyInfo.encode(message.Conversations[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.Messages != null && message.Messages.length)
            for (let i = 0; i < message.Messages.length; ++i)
                $root.ChatMessage.encode(message.Messages[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseChatMonitoring message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseChatMonitoring
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseChatMonitoring} ResponseChatMonitoring
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseChatMonitoring.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseChatMonitoring();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Conversations && message.Conversations.length))
                        message.Conversations = [];
                    message.Conversations.push($root.ChatPartyInfo.decode(reader, reader.uint32()));
                    break;
                }
            case 2: {
                    if (!(message.Messages && message.Messages.length))
                        message.Messages = [];
                    message.Messages.push($root.ChatMessage.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseChatMonitoring message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseChatMonitoring
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseChatMonitoring.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 242, ChatMonitoringFullState : this});
    };

    /**
     * ResponseChatMonitoring typeId
     * @member {number} typeId
     * @memberof ResponseChatMonitoring
     * @instance
     */
    ResponseChatMonitoring.prototype.typeId = 242;
    /**
     * ResponseChatMonitoring typeName
     * @member {string} typeName
     * @memberof ResponseChatMonitoring
     * @instance
     */
    ResponseChatMonitoring.prototype.typeName = "ChatMonitoringFullState";

    return ResponseChatMonitoring;
})();

export const ChatMonitoringEvent = $root.ChatMonitoringEvent = (() => {

    /**
     * Properties of a ChatMonitoringEvent.
     * @exports IChatMonitoringEvent
     * @property {ResponseConversationInfo} [OnConversationChanged] ChatMonitoringEvent OnConversationChanged
     * @property {AnonymousSessionClosed} [OnCloseConversation] ChatMonitoringEvent OnCloseConversation
     * @property {ChatTyping} [OnTyping] ChatMonitoringEvent OnTyping
     * @property {ChatMessage} [OnNewMessage] ChatMonitoringEvent OnNewMessage
     */

    /**
     * Constructs a new ChatMonitoringEvent.
     * @exports ChatMonitoringEvent
     * @classdesc Represents a ChatMonitoringEvent.
     * @constructor
     * @param {Partial<ChatMonitoringEvent>} [properties] Properties to set
     */
    function ChatMonitoringEvent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatMonitoringEvent OnConversationChanged.
     * @member {ResponseConversationInfo} OnConversationChanged
     * @memberof ChatMonitoringEvent
     * @instance
     */

    /**
     * ChatMonitoringEvent OnCloseConversation.
     * @member {AnonymousSessionClosed} OnCloseConversation
     * @memberof ChatMonitoringEvent
     * @instance
     */

    /**
     * ChatMonitoringEvent OnTyping.
     * @member {ChatTyping} OnTyping
     * @memberof ChatMonitoringEvent
     * @instance
     */

    /**
     * ChatMonitoringEvent OnNewMessage.
     * @member {ChatMessage} OnNewMessage
     * @memberof ChatMonitoringEvent
     * @instance
     */

    /**
     * Encodes the specified ChatMonitoringEvent message. Does not implicitly {@link ChatMonitoringEvent.verify|verify} messages.
     * @function encode
     * @memberof ChatMonitoringEvent
     * @static
     * @param {ChatMonitoringEvent} message ChatMonitoringEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatMonitoringEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.OnConversationChanged != null && Object.hasOwnProperty.call(message, "OnConversationChanged"))
            $root.ResponseConversationInfo.encode(message.OnConversationChanged, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.OnCloseConversation != null && Object.hasOwnProperty.call(message, "OnCloseConversation"))
            $root.AnonymousSessionClosed.encode(message.OnCloseConversation, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.OnTyping != null && Object.hasOwnProperty.call(message, "OnTyping"))
            $root.ChatTyping.encode(message.OnTyping, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.OnNewMessage != null && Object.hasOwnProperty.call(message, "OnNewMessage"))
            $root.ChatMessage.encode(message.OnNewMessage, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ChatMonitoringEvent message from the specified reader or buffer.
     * @function decode
     * @memberof ChatMonitoringEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatMonitoringEvent} ChatMonitoringEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatMonitoringEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatMonitoringEvent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.OnConversationChanged = $root.ResponseConversationInfo.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    message.OnCloseConversation = $root.AnonymousSessionClosed.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.OnTyping = $root.ChatTyping.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.OnNewMessage = $root.ChatMessage.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ChatMonitoringEvent message to GenericMessage
     * @function toGenericMessage
     * @memberof ChatMonitoringEvent
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ChatMonitoringEvent.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 243, ChatMonitoringNotification : this});
    };

    /**
     * ChatMonitoringEvent typeId
     * @member {number} typeId
     * @memberof ChatMonitoringEvent
     * @instance
     */
    ChatMonitoringEvent.prototype.typeId = 243;
    /**
     * ChatMonitoringEvent typeName
     * @member {string} typeName
     * @memberof ChatMonitoringEvent
     * @instance
     */
    ChatMonitoringEvent.prototype.typeName = "ChatMonitoringNotification";

    return ChatMonitoringEvent;
})();

export const RequestProviderMapping = $root.RequestProviderMapping = (() => {

    /**
     * Properties of a RequestProviderMapping.
     * @exports IRequestProviderMapping
     * @property {Array.<ProviderMapping>} [Mappings] RequestProviderMapping Mappings
     */

    /**
     * Constructs a new RequestProviderMapping.
     * @exports RequestProviderMapping
     * @classdesc Represents a RequestProviderMapping.
     * @constructor
     * @param {Partial<RequestProviderMapping>} [properties] Properties to set
     */
    function RequestProviderMapping(properties) {
        this.Mappings = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestProviderMapping Mappings.
     * @member {Array.<ProviderMapping>} Mappings
     * @memberof RequestProviderMapping
     * @instance
     */
    RequestProviderMapping.prototype.Mappings = $util.emptyArray;

    /**
     * Encodes the specified RequestProviderMapping message. Does not implicitly {@link RequestProviderMapping.verify|verify} messages.
     * @function encode
     * @memberof RequestProviderMapping
     * @static
     * @param {RequestProviderMapping} message RequestProviderMapping message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestProviderMapping.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Mappings != null && message.Mappings.length)
            for (let i = 0; i < message.Mappings.length; ++i)
                $root.ProviderMapping.encode(message.Mappings[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestProviderMapping message from the specified reader or buffer.
     * @function decode
     * @memberof RequestProviderMapping
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestProviderMapping} RequestProviderMapping
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestProviderMapping.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestProviderMapping();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Mappings && message.Mappings.length))
                        message.Mappings = [];
                    message.Mappings.push($root.ProviderMapping.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestProviderMapping message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestProviderMapping
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestProviderMapping.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 199, ProviderMapping : this});
    };

    /**
     * RequestProviderMapping typeId
     * @member {number} typeId
     * @memberof RequestProviderMapping
     * @instance
     */
    RequestProviderMapping.prototype.typeId = 199;
    /**
     * RequestProviderMapping typeName
     * @member {string} typeName
     * @memberof RequestProviderMapping
     * @instance
     */
    RequestProviderMapping.prototype.typeName = "ProviderMapping";

    return RequestProviderMapping;
})();

export const ProviderInfo = $root.ProviderInfo = (() => {

    /**
     * Properties of a ProviderInfo.
     * @exports IProviderInfo
     * @property {string} [ExternalNumber] ProviderInfo ExternalNumber
     * @property {string} [EncodedProvider] ProviderInfo EncodedProvider
     * @property {string} [Type] ProviderInfo Type
     * @property {string} [InstanceName] ProviderInfo InstanceName
     * @property {string} [LocalLogo] ProviderInfo LocalLogo
     * @property {string} [ProviderId] ProviderInfo ProviderId
     * @property {string} [LocalIcon] ProviderInfo LocalIcon
     * @property {boolean} [UsePhoneNumbers] ProviderInfo UsePhoneNumbers
     * @property {string} [UnavailableAutoReply] ProviderInfo UnavailableAutoReply
     * @property {string} [RouteTo] ProviderInfo RouteTo
     */

    /**
     * Constructs a new ProviderInfo.
     * @exports ProviderInfo
     * @classdesc Represents a ProviderInfo.
     * @constructor
     * @param {Partial<ProviderInfo>} [properties] Properties to set
     */
    function ProviderInfo(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderInfo ExternalNumber.
     * @member {string} ExternalNumber
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * ProviderInfo EncodedProvider.
     * @member {string} EncodedProvider
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * ProviderInfo Type.
     * @member {string} Type
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * ProviderInfo InstanceName.
     * @member {string} InstanceName
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * ProviderInfo LocalLogo.
     * @member {string} LocalLogo
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * ProviderInfo ProviderId.
     * @member {string} ProviderId
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * ProviderInfo LocalIcon.
     * @member {string} LocalIcon
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * ProviderInfo UsePhoneNumbers.
     * @member {boolean} UsePhoneNumbers
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * ProviderInfo UnavailableAutoReply.
     * @member {string} UnavailableAutoReply
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * ProviderInfo RouteTo.
     * @member {string} RouteTo
     * @memberof ProviderInfo
     * @instance
     */

    /**
     * Encodes the specified ProviderInfo message. Does not implicitly {@link ProviderInfo.verify|verify} messages.
     * @function encode
     * @memberof ProviderInfo
     * @static
     * @param {ProviderInfo} message ProviderInfo message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderInfo.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.ExternalNumber != null && Object.hasOwnProperty.call(message, "ExternalNumber"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.ExternalNumber);
        if (message.EncodedProvider != null && Object.hasOwnProperty.call(message, "EncodedProvider"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.EncodedProvider);
        if (message.Type != null && Object.hasOwnProperty.call(message, "Type"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Type);
        if (message.InstanceName != null && Object.hasOwnProperty.call(message, "InstanceName"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.InstanceName);
        if (message.LocalLogo != null && Object.hasOwnProperty.call(message, "LocalLogo"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.LocalLogo);
        if (message.ProviderId != null && Object.hasOwnProperty.call(message, "ProviderId"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.ProviderId);
        if (message.LocalIcon != null && Object.hasOwnProperty.call(message, "LocalIcon"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.LocalIcon);
        if (message.UsePhoneNumbers != null && Object.hasOwnProperty.call(message, "UsePhoneNumbers"))
            writer.uint32(/* id 8, wireType 0 =*/64).bool(message.UsePhoneNumbers);
        if (message.UnavailableAutoReply != null && Object.hasOwnProperty.call(message, "UnavailableAutoReply"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.UnavailableAutoReply);
        if (message.RouteTo != null && Object.hasOwnProperty.call(message, "RouteTo"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.RouteTo);
        return writer;
    };

    /**
     * Decodes a ProviderInfo message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderInfo
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderInfo} ProviderInfo
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderInfo();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.ExternalNumber = reader.string();
                    break;
                }
            case 2: {
                    message.EncodedProvider = reader.string();
                    break;
                }
            case 3: {
                    message.Type = reader.string();
                    break;
                }
            case 4: {
                    message.InstanceName = reader.string();
                    break;
                }
            case 5: {
                    message.LocalLogo = reader.string();
                    break;
                }
            case 6: {
                    message.ProviderId = reader.string();
                    break;
                }
            case 7: {
                    message.LocalIcon = reader.string();
                    break;
                }
            case 8: {
                    message.UsePhoneNumbers = reader.bool();
                    break;
                }
            case 9: {
                    message.UnavailableAutoReply = reader.string();
                    break;
                }
            case 10: {
                    message.RouteTo = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ProviderInfo;
})();

export const ProviderMapping = $root.ProviderMapping = (() => {

    /**
     * Properties of a ProviderMapping.
     * @exports IProviderMapping
     * @property {ProviderInfo} [Provider] ProviderMapping Provider
     * @property {Array.<string>} [DNs] ProviderMapping DNs
     * @property {Array.<string>} [Groups] ProviderMapping Groups
     */

    /**
     * Constructs a new ProviderMapping.
     * @exports ProviderMapping
     * @classdesc Represents a ProviderMapping.
     * @constructor
     * @param {Partial<ProviderMapping>} [properties] Properties to set
     */
    function ProviderMapping(properties) {
        this.DNs = [];
        this.Groups = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ProviderMapping Provider.
     * @member {ProviderInfo} Provider
     * @memberof ProviderMapping
     * @instance
     */

    /**
     * ProviderMapping DNs.
     * @member {Array.<string>} DNs
     * @memberof ProviderMapping
     * @instance
     */
    ProviderMapping.prototype.DNs = $util.emptyArray;

    /**
     * ProviderMapping Groups.
     * @member {Array.<string>} Groups
     * @memberof ProviderMapping
     * @instance
     */
    ProviderMapping.prototype.Groups = $util.emptyArray;

    /**
     * Encodes the specified ProviderMapping message. Does not implicitly {@link ProviderMapping.verify|verify} messages.
     * @function encode
     * @memberof ProviderMapping
     * @static
     * @param {ProviderMapping} message ProviderMapping message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ProviderMapping.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Provider != null && Object.hasOwnProperty.call(message, "Provider"))
            $root.ProviderInfo.encode(message.Provider, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.DNs != null && message.DNs.length)
            for (let i = 0; i < message.DNs.length; ++i)
                writer.uint32(/* id 2, wireType 2 =*/18).string(message.DNs[i]);
        if (message.Groups != null && message.Groups.length)
            for (let i = 0; i < message.Groups.length; ++i)
                writer.uint32(/* id 3, wireType 2 =*/26).string(message.Groups[i]);
        return writer;
    };

    /**
     * Decodes a ProviderMapping message from the specified reader or buffer.
     * @function decode
     * @memberof ProviderMapping
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ProviderMapping} ProviderMapping
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ProviderMapping.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ProviderMapping();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Provider = $root.ProviderInfo.decode(reader, reader.uint32());
                    break;
                }
            case 2: {
                    if (!(message.DNs && message.DNs.length))
                        message.DNs = [];
                    message.DNs.push(reader.string());
                    break;
                }
            case 3: {
                    if (!(message.Groups && message.Groups.length))
                        message.Groups = [];
                    message.Groups.push(reader.string());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ProviderMapping;
})();

export const RequestAvailableProviders = $root.RequestAvailableProviders = (() => {

    /**
     * Properties of a RequestAvailableProviders.
     * @exports IRequestAvailableProviders
     */

    /**
     * Constructs a new RequestAvailableProviders.
     * @exports RequestAvailableProviders
     * @classdesc Represents a RequestAvailableProviders.
     * @constructor
     * @param {Partial<RequestAvailableProviders>} [properties] Properties to set
     */
    function RequestAvailableProviders(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestAvailableProviders message. Does not implicitly {@link RequestAvailableProviders.verify|verify} messages.
     * @function encode
     * @memberof RequestAvailableProviders
     * @static
     * @param {RequestAvailableProviders} message RequestAvailableProviders message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestAvailableProviders.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestAvailableProviders message from the specified reader or buffer.
     * @function decode
     * @memberof RequestAvailableProviders
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestAvailableProviders} RequestAvailableProviders
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestAvailableProviders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestAvailableProviders();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestAvailableProviders message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestAvailableProviders
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestAvailableProviders.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 500, GetMyProviders : this});
    };

    /**
     * RequestAvailableProviders typeId
     * @member {number} typeId
     * @memberof RequestAvailableProviders
     * @instance
     */
    RequestAvailableProviders.prototype.typeId = 500;
    /**
     * RequestAvailableProviders typeName
     * @member {string} typeName
     * @memberof RequestAvailableProviders
     * @instance
     */
    RequestAvailableProviders.prototype.typeName = "GetMyProviders";

    return RequestAvailableProviders;
})();

export const ResponseAvailableProviders = $root.ResponseAvailableProviders = (() => {

    /**
     * Properties of a ResponseAvailableProviders.
     * @exports IResponseAvailableProviders
     * @property {Array.<ProviderInfo>} [Providers] ResponseAvailableProviders Providers
     */

    /**
     * Constructs a new ResponseAvailableProviders.
     * @exports ResponseAvailableProviders
     * @classdesc Represents a ResponseAvailableProviders.
     * @constructor
     * @param {Partial<ResponseAvailableProviders>} [properties] Properties to set
     */
    function ResponseAvailableProviders(properties) {
        this.Providers = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseAvailableProviders Providers.
     * @member {Array.<ProviderInfo>} Providers
     * @memberof ResponseAvailableProviders
     * @instance
     */
    ResponseAvailableProviders.prototype.Providers = $util.emptyArray;

    /**
     * Encodes the specified ResponseAvailableProviders message. Does not implicitly {@link ResponseAvailableProviders.verify|verify} messages.
     * @function encode
     * @memberof ResponseAvailableProviders
     * @static
     * @param {ResponseAvailableProviders} message ResponseAvailableProviders message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseAvailableProviders.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Providers != null && message.Providers.length)
            for (let i = 0; i < message.Providers.length; ++i)
                $root.ProviderInfo.encode(message.Providers[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseAvailableProviders message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseAvailableProviders
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseAvailableProviders} ResponseAvailableProviders
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseAvailableProviders.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseAvailableProviders();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Providers && message.Providers.length))
                        message.Providers = [];
                    message.Providers.push($root.ProviderInfo.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseAvailableProviders message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseAvailableProviders
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseAvailableProviders.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 244, MyProviders : this});
    };

    /**
     * ResponseAvailableProviders typeId
     * @member {number} typeId
     * @memberof ResponseAvailableProviders
     * @instance
     */
    ResponseAvailableProviders.prototype.typeId = 244;
    /**
     * ResponseAvailableProviders typeName
     * @member {string} typeName
     * @memberof ResponseAvailableProviders
     * @instance
     */
    ResponseAvailableProviders.prototype.typeName = "MyProviders";

    return ResponseAvailableProviders;
})();

export const RequestGetConversationEvents = $root.RequestGetConversationEvents = (() => {

    /**
     * Properties of a RequestGetConversationEvents.
     * @exports IRequestGetConversationEvents
     * @property {number} [IdConversation] RequestGetConversationEvents IdConversation
     * @property {number} [Offset] RequestGetConversationEvents Offset
     * @property {number} [Count] RequestGetConversationEvents Count
     */

    /**
     * Constructs a new RequestGetConversationEvents.
     * @exports RequestGetConversationEvents
     * @classdesc Represents a RequestGetConversationEvents.
     * @constructor
     * @param {Partial<RequestGetConversationEvents>} [properties] Properties to set
     */
    function RequestGetConversationEvents(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetConversationEvents IdConversation.
     * @member {number} IdConversation
     * @memberof RequestGetConversationEvents
     * @instance
     */

    /**
     * RequestGetConversationEvents Offset.
     * @member {number} Offset
     * @memberof RequestGetConversationEvents
     * @instance
     */

    /**
     * RequestGetConversationEvents Count.
     * @member {number} Count
     * @memberof RequestGetConversationEvents
     * @instance
     */

    /**
     * Encodes the specified RequestGetConversationEvents message. Does not implicitly {@link RequestGetConversationEvents.verify|verify} messages.
     * @function encode
     * @memberof RequestGetConversationEvents
     * @static
     * @param {RequestGetConversationEvents} message RequestGetConversationEvents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetConversationEvents.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.Offset != null && Object.hasOwnProperty.call(message, "Offset"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Offset);
        if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Count);
        return writer;
    };

    /**
     * Decodes a RequestGetConversationEvents message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetConversationEvents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetConversationEvents} RequestGetConversationEvents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetConversationEvents.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetConversationEvents();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.Offset = reader.int32();
                    break;
                }
            case 3: {
                    message.Count = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetConversationEvents message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetConversationEvents
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetConversationEvents.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 503, GetConversationEvents : this});
    };

    /**
     * RequestGetConversationEvents typeId
     * @member {number} typeId
     * @memberof RequestGetConversationEvents
     * @instance
     */
    RequestGetConversationEvents.prototype.typeId = 503;
    /**
     * RequestGetConversationEvents typeName
     * @member {string} typeName
     * @memberof RequestGetConversationEvents
     * @instance
     */
    RequestGetConversationEvents.prototype.typeName = "GetConversationEvents";

    return RequestGetConversationEvents;
})();

export const RequestGetQMConversationEvents = $root.RequestGetQMConversationEvents = (() => {

    /**
     * Properties of a RequestGetQMConversationEvents.
     * @exports IRequestGetQMConversationEvents
     * @property {number} [IdConversation] RequestGetQMConversationEvents IdConversation
     * @property {number} [Offset] RequestGetQMConversationEvents Offset
     * @property {number} [Count] RequestGetQMConversationEvents Count
     */

    /**
     * Constructs a new RequestGetQMConversationEvents.
     * @exports RequestGetQMConversationEvents
     * @classdesc Represents a RequestGetQMConversationEvents.
     * @constructor
     * @param {Partial<RequestGetQMConversationEvents>} [properties] Properties to set
     */
    function RequestGetQMConversationEvents(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetQMConversationEvents IdConversation.
     * @member {number} IdConversation
     * @memberof RequestGetQMConversationEvents
     * @instance
     */

    /**
     * RequestGetQMConversationEvents Offset.
     * @member {number} Offset
     * @memberof RequestGetQMConversationEvents
     * @instance
     */

    /**
     * RequestGetQMConversationEvents Count.
     * @member {number} Count
     * @memberof RequestGetQMConversationEvents
     * @instance
     */

    /**
     * Encodes the specified RequestGetQMConversationEvents message. Does not implicitly {@link RequestGetQMConversationEvents.verify|verify} messages.
     * @function encode
     * @memberof RequestGetQMConversationEvents
     * @static
     * @param {RequestGetQMConversationEvents} message RequestGetQMConversationEvents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetQMConversationEvents.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        if (message.Offset != null && Object.hasOwnProperty.call(message, "Offset"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Offset);
        if (message.Count != null && Object.hasOwnProperty.call(message, "Count"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.Count);
        return writer;
    };

    /**
     * Decodes a RequestGetQMConversationEvents message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetQMConversationEvents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetQMConversationEvents} RequestGetQMConversationEvents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetQMConversationEvents.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetQMConversationEvents();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 2: {
                    message.Offset = reader.int32();
                    break;
                }
            case 3: {
                    message.Count = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetQMConversationEvents message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetQMConversationEvents
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetQMConversationEvents.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 504, GetQMConversationEvents : this});
    };

    /**
     * RequestGetQMConversationEvents typeId
     * @member {number} typeId
     * @memberof RequestGetQMConversationEvents
     * @instance
     */
    RequestGetQMConversationEvents.prototype.typeId = 504;
    /**
     * RequestGetQMConversationEvents typeName
     * @member {string} typeName
     * @memberof RequestGetQMConversationEvents
     * @instance
     */
    RequestGetQMConversationEvents.prototype.typeName = "GetQMConversationEvents";

    return RequestGetQMConversationEvents;
})();

export const ResponseConversationEvents = $root.ResponseConversationEvents = (() => {

    /**
     * Properties of a ResponseConversationEvents.
     * @exports IResponseConversationEvents
     * @property {Array.<ConversationEvent>} [Events] ResponseConversationEvents Events
     */

    /**
     * Constructs a new ResponseConversationEvents.
     * @exports ResponseConversationEvents
     * @classdesc Represents a ResponseConversationEvents.
     * @constructor
     * @param {Partial<ResponseConversationEvents>} [properties] Properties to set
     */
    function ResponseConversationEvents(properties) {
        this.Events = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseConversationEvents Events.
     * @member {Array.<ConversationEvent>} Events
     * @memberof ResponseConversationEvents
     * @instance
     */
    ResponseConversationEvents.prototype.Events = $util.emptyArray;

    /**
     * Encodes the specified ResponseConversationEvents message. Does not implicitly {@link ResponseConversationEvents.verify|verify} messages.
     * @function encode
     * @memberof ResponseConversationEvents
     * @static
     * @param {ResponseConversationEvents} message ResponseConversationEvents message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseConversationEvents.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Events != null && message.Events.length)
            for (let i = 0; i < message.Events.length; ++i)
                $root.ConversationEvent.encode(message.Events[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseConversationEvents message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseConversationEvents
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseConversationEvents} ResponseConversationEvents
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseConversationEvents.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseConversationEvents();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Events && message.Events.length))
                        message.Events = [];
                    message.Events.push($root.ConversationEvent.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseConversationEvents message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseConversationEvents
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseConversationEvents.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 246, ConversationEvents : this});
    };

    /**
     * ResponseConversationEvents typeId
     * @member {number} typeId
     * @memberof ResponseConversationEvents
     * @instance
     */
    ResponseConversationEvents.prototype.typeId = 246;
    /**
     * ResponseConversationEvents typeName
     * @member {string} typeName
     * @memberof ResponseConversationEvents
     * @instance
     */
    ResponseConversationEvents.prototype.typeName = "ConversationEvents";

    return ResponseConversationEvents;
})();

export const ConversationEvent = $root.ConversationEvent = (() => {

    /**
     * Properties of a ConversationEvent.
     * @exports IConversationEvent
     * @property {number} [IdMessage] ConversationEvent IdMessage
     * @property {DateTime} [TimeSent] ConversationEvent TimeSent
     * @property {ChatMessageType} [MessageType] ConversationEvent MessageType
     * @property {ChatRecipient} [Sender] ConversationEvent Sender
     * @property {string} [Message] ConversationEvent Message
     */

    /**
     * Constructs a new ConversationEvent.
     * @exports ConversationEvent
     * @classdesc Represents a ConversationEvent.
     * @constructor
     * @param {Partial<ConversationEvent>} [properties] Properties to set
     */
    function ConversationEvent(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ConversationEvent IdMessage.
     * @member {number} IdMessage
     * @memberof ConversationEvent
     * @instance
     */

    /**
     * ConversationEvent TimeSent.
     * @member {DateTime} TimeSent
     * @memberof ConversationEvent
     * @instance
     */

    /**
     * ConversationEvent MessageType.
     * @member {ChatMessageType} MessageType
     * @memberof ConversationEvent
     * @instance
     */

    /**
     * ConversationEvent Sender.
     * @member {ChatRecipient} Sender
     * @memberof ConversationEvent
     * @instance
     */

    /**
     * ConversationEvent Message.
     * @member {string} Message
     * @memberof ConversationEvent
     * @instance
     */

    /**
     * Encodes the specified ConversationEvent message. Does not implicitly {@link ConversationEvent.verify|verify} messages.
     * @function encode
     * @memberof ConversationEvent
     * @static
     * @param {ConversationEvent} message ConversationEvent message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ConversationEvent.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdMessage != null && Object.hasOwnProperty.call(message, "IdMessage"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdMessage);
        if (message.TimeSent != null && Object.hasOwnProperty.call(message, "TimeSent"))
            $root.DateTime.encode(message.TimeSent, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.MessageType != null && Object.hasOwnProperty.call(message, "MessageType"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.MessageType);
        if (message.Sender != null && Object.hasOwnProperty.call(message, "Sender"))
            $root.ChatRecipient.encode(message.Sender, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.Message != null && Object.hasOwnProperty.call(message, "Message"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Message);
        return writer;
    };

    /**
     * Decodes a ConversationEvent message from the specified reader or buffer.
     * @function decode
     * @memberof ConversationEvent
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ConversationEvent} ConversationEvent
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ConversationEvent.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ConversationEvent();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdMessage = reader.int32();
                    break;
                }
            case 2: {
                    message.TimeSent = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.MessageType = reader.int32();
                    break;
                }
            case 4: {
                    message.Sender = $root.ChatRecipient.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.Message = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ConversationEvent;
})();

export const RequestRefreshConversation = $root.RequestRefreshConversation = (() => {

    /**
     * Properties of a RequestRefreshConversation.
     * @exports IRequestRefreshConversation
     * @property {number} [IdConversation] RequestRefreshConversation IdConversation
     */

    /**
     * Constructs a new RequestRefreshConversation.
     * @exports RequestRefreshConversation
     * @classdesc Represents a RequestRefreshConversation.
     * @constructor
     * @param {Partial<RequestRefreshConversation>} [properties] Properties to set
     */
    function RequestRefreshConversation(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestRefreshConversation IdConversation.
     * @member {number} IdConversation
     * @memberof RequestRefreshConversation
     * @instance
     */

    /**
     * Encodes the specified RequestRefreshConversation message. Does not implicitly {@link RequestRefreshConversation.verify|verify} messages.
     * @function encode
     * @memberof RequestRefreshConversation
     * @static
     * @param {RequestRefreshConversation} message RequestRefreshConversation message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestRefreshConversation.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdConversation);
        return writer;
    };

    /**
     * Decodes a RequestRefreshConversation message from the specified reader or buffer.
     * @function decode
     * @memberof RequestRefreshConversation
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestRefreshConversation} RequestRefreshConversation
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestRefreshConversation.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestRefreshConversation();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdConversation = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestRefreshConversation message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestRefreshConversation
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestRefreshConversation.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 505, RefreshConversation : this});
    };

    /**
     * RequestRefreshConversation typeId
     * @member {number} typeId
     * @memberof RequestRefreshConversation
     * @instance
     */
    RequestRefreshConversation.prototype.typeId = 505;
    /**
     * RequestRefreshConversation typeName
     * @member {string} typeName
     * @memberof RequestRefreshConversation
     * @instance
     */
    RequestRefreshConversation.prototype.typeName = "RefreshConversation";

    return RequestRefreshConversation;
})();

export const Param = $root.Param = (() => {

    /**
     * Properties of a Param.
     * @exports IParam
     * @property {string} Name Param Name
     * @property {string} [Value] Param Value
     */

    /**
     * Constructs a new Param.
     * @exports Param
     * @classdesc Represents a Param.
     * @constructor
     * @param {Partial<Param>} [properties] Properties to set
     */
    function Param(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Param Name.
     * @member {string} Name
     * @memberof Param
     * @instance
     */

    /**
     * Param Value.
     * @member {string} Value
     * @memberof Param
     * @instance
     */

    /**
     * Encodes the specified Param message. Does not implicitly {@link Param.verify|verify} messages.
     * @function encode
     * @memberof Param
     * @static
     * @param {Param} message Param message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    Param.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 2 =*/10).string(message.Name);
        if (message.Value != null && Object.hasOwnProperty.call(message, "Value"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Value);
        return writer;
    };

    /**
     * Decodes a Param message from the specified reader or buffer.
     * @function decode
     * @memberof Param
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {Param} Param
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    Param.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.Param();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Name = reader.string();
                    break;
                }
            case 2: {
                    message.Value = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return Param;
})();

export const WmBridgeParameters = $root.WmBridgeParameters = (() => {

    /**
     * Properties of a WmBridgeParameters.
     * @exports IWmBridgeParameters
     * @property {Array.<Param>} [Parameters] WmBridgeParameters Parameters
     */

    /**
     * Constructs a new WmBridgeParameters.
     * @exports WmBridgeParameters
     * @classdesc Represents a WmBridgeParameters.
     * @constructor
     * @param {Partial<WmBridgeParameters>} [properties] Properties to set
     */
    function WmBridgeParameters(properties) {
        this.Parameters = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WmBridgeParameters Parameters.
     * @member {Array.<Param>} Parameters
     * @memberof WmBridgeParameters
     * @instance
     */
    WmBridgeParameters.prototype.Parameters = $util.emptyArray;

    /**
     * Encodes the specified WmBridgeParameters message. Does not implicitly {@link WmBridgeParameters.verify|verify} messages.
     * @function encode
     * @memberof WmBridgeParameters
     * @static
     * @param {WmBridgeParameters} message WmBridgeParameters message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WmBridgeParameters.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Parameters != null && message.Parameters.length)
            for (let i = 0; i < message.Parameters.length; ++i)
                $root.Param.encode(message.Parameters[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a WmBridgeParameters message from the specified reader or buffer.
     * @function decode
     * @memberof WmBridgeParameters
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WmBridgeParameters} WmBridgeParameters
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WmBridgeParameters.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WmBridgeParameters();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Parameters && message.Parameters.length))
                        message.Parameters = [];
                    message.Parameters.push($root.Param.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a WmBridgeParameters message to GenericMessage
     * @function toGenericMessage
     * @memberof WmBridgeParameters
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    WmBridgeParameters.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 247, WmParams : this});
    };

    /**
     * WmBridgeParameters typeId
     * @member {number} typeId
     * @memberof WmBridgeParameters
     * @instance
     */
    WmBridgeParameters.prototype.typeId = 247;
    /**
     * WmBridgeParameters typeName
     * @member {string} typeName
     * @memberof WmBridgeParameters
     * @instance
     */
    WmBridgeParameters.prototype.typeName = "WmParams";

    return WmBridgeParameters;
})();

export const MeetingRow = $root.MeetingRow = (() => {

    /**
     * Properties of a MeetingRow.
     * @exports IMeetingRow
     * @property {ActionType} Action MeetingRow Action
     * @property {number} [Id] MeetingRow Id
     * @property {string} [Name] MeetingRow Name
     * @property {string} [Description] MeetingRow Description
     * @property {string} [Pin] MeetingRow Pin
     * @property {string} [Target] MeetingRow Target
     * @property {DateTime} [StartAt] MeetingRow StartAt
     * @property {DateTime} [EndAt] MeetingRow EndAt
     * @property {string} [MeetingObject] MeetingRow MeetingObject
     * @property {number} [MeetingType] MeetingRow MeetingType
     * @property {string} [Documents] MeetingRow Documents
     * @property {string} [UniqueName] MeetingRow UniqueName
     */

    /**
     * Constructs a new MeetingRow.
     * @exports MeetingRow
     * @classdesc Represents a MeetingRow.
     * @constructor
     * @param {Partial<MeetingRow>} [properties] Properties to set
     */
    function MeetingRow(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MeetingRow Action.
     * @member {ActionType} Action
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow Id.
     * @member {number} Id
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow Name.
     * @member {string} Name
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow Description.
     * @member {string} Description
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow Pin.
     * @member {string} Pin
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow Target.
     * @member {string} Target
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow StartAt.
     * @member {DateTime} StartAt
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow EndAt.
     * @member {DateTime} EndAt
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow MeetingObject.
     * @member {string} MeetingObject
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow MeetingType.
     * @member {number} MeetingType
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow Documents.
     * @member {string} Documents
     * @memberof MeetingRow
     * @instance
     */

    /**
     * MeetingRow UniqueName.
     * @member {string} UniqueName
     * @memberof MeetingRow
     * @instance
     */

    /**
     * Encodes the specified MeetingRow message. Does not implicitly {@link MeetingRow.verify|verify} messages.
     * @function encode
     * @memberof MeetingRow
     * @static
     * @param {MeetingRow} message MeetingRow message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MeetingRow.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Name);
        if (message.Description != null && Object.hasOwnProperty.call(message, "Description"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Description);
        if (message.Pin != null && Object.hasOwnProperty.call(message, "Pin"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Pin);
        if (message.Target != null && Object.hasOwnProperty.call(message, "Target"))
            writer.uint32(/* id 6, wireType 2 =*/50).string(message.Target);
        if (message.StartAt != null && Object.hasOwnProperty.call(message, "StartAt"))
            $root.DateTime.encode(message.StartAt, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.EndAt != null && Object.hasOwnProperty.call(message, "EndAt"))
            $root.DateTime.encode(message.EndAt, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.MeetingObject != null && Object.hasOwnProperty.call(message, "MeetingObject"))
            writer.uint32(/* id 9, wireType 2 =*/74).string(message.MeetingObject);
        if (message.MeetingType != null && Object.hasOwnProperty.call(message, "MeetingType"))
            writer.uint32(/* id 10, wireType 0 =*/80).int32(message.MeetingType);
        if (message.Documents != null && Object.hasOwnProperty.call(message, "Documents"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.Documents);
        if (message.UniqueName != null && Object.hasOwnProperty.call(message, "UniqueName"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.UniqueName);
        return writer;
    };

    /**
     * Decodes a MeetingRow message from the specified reader or buffer.
     * @function decode
     * @memberof MeetingRow
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MeetingRow} MeetingRow
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MeetingRow.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MeetingRow();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Name = reader.string();
                    break;
                }
            case 4: {
                    message.Description = reader.string();
                    break;
                }
            case 5: {
                    message.Pin = reader.string();
                    break;
                }
            case 6: {
                    message.Target = reader.string();
                    break;
                }
            case 7: {
                    message.StartAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.EndAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.MeetingObject = reader.string();
                    break;
                }
            case 10: {
                    message.MeetingType = reader.int32();
                    break;
                }
            case 11: {
                    message.Documents = reader.string();
                    break;
                }
            case 12: {
                    message.UniqueName = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return MeetingRow;
})();

export const RequestUpdateMeetingDocuments = $root.RequestUpdateMeetingDocuments = (() => {

    /**
     * Properties of a RequestUpdateMeetingDocuments.
     * @exports IRequestUpdateMeetingDocuments
     * @property {number} Id RequestUpdateMeetingDocuments Id
     * @property {string} [Documents] RequestUpdateMeetingDocuments Documents
     */

    /**
     * Constructs a new RequestUpdateMeetingDocuments.
     * @exports RequestUpdateMeetingDocuments
     * @classdesc Represents a RequestUpdateMeetingDocuments.
     * @constructor
     * @param {Partial<RequestUpdateMeetingDocuments>} [properties] Properties to set
     */
    function RequestUpdateMeetingDocuments(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateMeetingDocuments Id.
     * @member {number} Id
     * @memberof RequestUpdateMeetingDocuments
     * @instance
     */

    /**
     * RequestUpdateMeetingDocuments Documents.
     * @member {string} Documents
     * @memberof RequestUpdateMeetingDocuments
     * @instance
     */

    /**
     * Encodes the specified RequestUpdateMeetingDocuments message. Does not implicitly {@link RequestUpdateMeetingDocuments.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateMeetingDocuments
     * @static
     * @param {RequestUpdateMeetingDocuments} message RequestUpdateMeetingDocuments message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateMeetingDocuments.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.Documents != null && Object.hasOwnProperty.call(message, "Documents"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Documents);
        return writer;
    };

    /**
     * Decodes a RequestUpdateMeetingDocuments message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateMeetingDocuments
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateMeetingDocuments} RequestUpdateMeetingDocuments
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateMeetingDocuments.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateMeetingDocuments();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.Documents = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateMeetingDocuments message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateMeetingDocuments
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateMeetingDocuments.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 506, UpdateMeetingDocuments : this});
    };

    /**
     * RequestUpdateMeetingDocuments typeId
     * @member {number} typeId
     * @memberof RequestUpdateMeetingDocuments
     * @instance
     */
    RequestUpdateMeetingDocuments.prototype.typeId = 506;
    /**
     * RequestUpdateMeetingDocuments typeName
     * @member {string} typeName
     * @memberof RequestUpdateMeetingDocuments
     * @instance
     */
    RequestUpdateMeetingDocuments.prototype.typeName = "UpdateMeetingDocuments";

    return RequestUpdateMeetingDocuments;
})();

export const MeetingRows = $root.MeetingRows = (() => {

    /**
     * Properties of a MeetingRows.
     * @exports IMeetingRows
     * @property {ActionType} Action MeetingRows Action
     * @property {Array.<MeetingRow>} [Rows] MeetingRows Rows
     */

    /**
     * Constructs a new MeetingRows.
     * @exports MeetingRows
     * @classdesc Represents a MeetingRows.
     * @constructor
     * @param {Partial<MeetingRows>} [properties] Properties to set
     */
    function MeetingRows(properties) {
        this.Rows = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MeetingRows Action.
     * @member {ActionType} Action
     * @memberof MeetingRows
     * @instance
     */

    /**
     * MeetingRows Rows.
     * @member {Array.<MeetingRow>} Rows
     * @memberof MeetingRows
     * @instance
     */
    MeetingRows.prototype.Rows = $util.emptyArray;

    /**
     * Encodes the specified MeetingRows message. Does not implicitly {@link MeetingRows.verify|verify} messages.
     * @function encode
     * @memberof MeetingRows
     * @static
     * @param {MeetingRows} message MeetingRows message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MeetingRows.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Rows != null && message.Rows.length)
            for (let i = 0; i < message.Rows.length; ++i)
                $root.MeetingRow.encode(message.Rows[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a MeetingRows message from the specified reader or buffer.
     * @function decode
     * @memberof MeetingRows
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MeetingRows} MeetingRows
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MeetingRows.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MeetingRows();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Rows && message.Rows.length))
                        message.Rows = [];
                    message.Rows.push($root.MeetingRow.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a MeetingRows message to GenericMessage
     * @function toGenericMessage
     * @memberof MeetingRows
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    MeetingRows.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 248, NotificationMeetingRows : this});
    };

    /**
     * MeetingRows typeId
     * @member {number} typeId
     * @memberof MeetingRows
     * @instance
     */
    MeetingRows.prototype.typeId = 248;
    /**
     * MeetingRows typeName
     * @member {string} typeName
     * @memberof MeetingRows
     * @instance
     */
    MeetingRows.prototype.typeName = "NotificationMeetingRows";

    return MeetingRows;
})();

export const WmPollings = $root.WmPollings = (() => {

    /**
     * Properties of a WmPollings.
     * @exports IWmPollings
     * @property {ActionType} Action WmPollings Action
     * @property {Array.<WmPolling>} [Pollings] WmPollings Pollings
     */

    /**
     * Constructs a new WmPollings.
     * @exports WmPollings
     * @classdesc Represents a WmPollings.
     * @constructor
     * @param {Partial<WmPollings>} [properties] Properties to set
     */
    function WmPollings(properties) {
        this.Pollings = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WmPollings Action.
     * @member {ActionType} Action
     * @memberof WmPollings
     * @instance
     */

    /**
     * WmPollings Pollings.
     * @member {Array.<WmPolling>} Pollings
     * @memberof WmPollings
     * @instance
     */
    WmPollings.prototype.Pollings = $util.emptyArray;

    /**
     * Encodes the specified WmPollings message. Does not implicitly {@link WmPollings.verify|verify} messages.
     * @function encode
     * @memberof WmPollings
     * @static
     * @param {WmPollings} message WmPollings message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WmPollings.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Pollings != null && message.Pollings.length)
            for (let i = 0; i < message.Pollings.length; ++i)
                $root.WmPolling.encode(message.Pollings[i], writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a WmPollings message from the specified reader or buffer.
     * @function decode
     * @memberof WmPollings
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WmPollings} WmPollings
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WmPollings.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WmPollings();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    if (!(message.Pollings && message.Pollings.length))
                        message.Pollings = [];
                    message.Pollings.push($root.WmPolling.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a WmPollings message to GenericMessage
     * @function toGenericMessage
     * @memberof WmPollings
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    WmPollings.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 507, WebMeetingPollings : this});
    };

    /**
     * WmPollings typeId
     * @member {number} typeId
     * @memberof WmPollings
     * @instance
     */
    WmPollings.prototype.typeId = 507;
    /**
     * WmPollings typeName
     * @member {string} typeName
     * @memberof WmPollings
     * @instance
     */
    WmPollings.prototype.typeName = "WebMeetingPollings";

    return WmPollings;
})();

export const WmPolling = $root.WmPolling = (() => {

    /**
     * Properties of a WmPolling.
     * @exports IWmPolling
     * @property {ActionType} Action WmPolling Action
     * @property {number} Id WmPolling Id
     * @property {string} [Name] WmPolling Name
     * @property {string} [Owner] WmPolling Owner
     * @property {Array.<Param>} [Fields] WmPolling Fields
     */

    /**
     * Constructs a new WmPolling.
     * @exports WmPolling
     * @classdesc Represents a WmPolling.
     * @constructor
     * @param {Partial<WmPolling>} [properties] Properties to set
     */
    function WmPolling(properties) {
        this.Fields = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * WmPolling Action.
     * @member {ActionType} Action
     * @memberof WmPolling
     * @instance
     */

    /**
     * WmPolling Id.
     * @member {number} Id
     * @memberof WmPolling
     * @instance
     */

    /**
     * WmPolling Name.
     * @member {string} Name
     * @memberof WmPolling
     * @instance
     */

    /**
     * WmPolling Owner.
     * @member {string} Owner
     * @memberof WmPolling
     * @instance
     */

    /**
     * WmPolling Fields.
     * @member {Array.<Param>} Fields
     * @memberof WmPolling
     * @instance
     */
    WmPolling.prototype.Fields = $util.emptyArray;

    /**
     * Encodes the specified WmPolling message. Does not implicitly {@link WmPolling.verify|verify} messages.
     * @function encode
     * @memberof WmPolling
     * @static
     * @param {WmPolling} message WmPolling message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    WmPolling.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Name);
        if (message.Owner != null && Object.hasOwnProperty.call(message, "Owner"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Owner);
        if (message.Fields != null && message.Fields.length)
            for (let i = 0; i < message.Fields.length; ++i)
                $root.Param.encode(message.Fields[i], writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a WmPolling message from the specified reader or buffer.
     * @function decode
     * @memberof WmPolling
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {WmPolling} WmPolling
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    WmPolling.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.WmPolling();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.Name = reader.string();
                    break;
                }
            case 4: {
                    message.Owner = reader.string();
                    break;
                }
            case 5: {
                    if (!(message.Fields && message.Fields.length))
                        message.Fields = [];
                    message.Fields.push($root.Param.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return WmPolling;
})();

export const MeetingStateNotification = $root.MeetingStateNotification = (() => {

    /**
     * Properties of a MeetingStateNotification.
     * @exports IMeetingStateNotification
     * @property {ActionType} Action MeetingStateNotification Action
     * @property {number} [Id] MeetingStateNotification Id
     * @property {number} [State] MeetingStateNotification State
     * @property {boolean} [IsQuickMeeting] MeetingStateNotification IsQuickMeeting
     */

    /**
     * Constructs a new MeetingStateNotification.
     * @exports MeetingStateNotification
     * @classdesc Represents a MeetingStateNotification.
     * @constructor
     * @param {Partial<MeetingStateNotification>} [properties] Properties to set
     */
    function MeetingStateNotification(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MeetingStateNotification Action.
     * @member {ActionType} Action
     * @memberof MeetingStateNotification
     * @instance
     */

    /**
     * MeetingStateNotification Id.
     * @member {number} Id
     * @memberof MeetingStateNotification
     * @instance
     */

    /**
     * MeetingStateNotification State.
     * @member {number} State
     * @memberof MeetingStateNotification
     * @instance
     */

    /**
     * MeetingStateNotification IsQuickMeeting.
     * @member {boolean} IsQuickMeeting
     * @memberof MeetingStateNotification
     * @instance
     */

    /**
     * Encodes the specified MeetingStateNotification message. Does not implicitly {@link MeetingStateNotification.verify|verify} messages.
     * @function encode
     * @memberof MeetingStateNotification
     * @static
     * @param {MeetingStateNotification} message MeetingStateNotification message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MeetingStateNotification.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.Id);
        if (message.State != null && Object.hasOwnProperty.call(message, "State"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.State);
        if (message.IsQuickMeeting != null && Object.hasOwnProperty.call(message, "IsQuickMeeting"))
            writer.uint32(/* id 4, wireType 0 =*/32).bool(message.IsQuickMeeting);
        return writer;
    };

    /**
     * Decodes a MeetingStateNotification message from the specified reader or buffer.
     * @function decode
     * @memberof MeetingStateNotification
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MeetingStateNotification} MeetingStateNotification
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MeetingStateNotification.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MeetingStateNotification();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Id = reader.int32();
                    break;
                }
            case 3: {
                    message.State = reader.int32();
                    break;
                }
            case 4: {
                    message.IsQuickMeeting = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a MeetingStateNotification message to GenericMessage
     * @function toGenericMessage
     * @memberof MeetingStateNotification
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    MeetingStateNotification.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 250, MeetingState : this});
    };

    /**
     * MeetingStateNotification typeId
     * @member {number} typeId
     * @memberof MeetingStateNotification
     * @instance
     */
    MeetingStateNotification.prototype.typeId = 250;
    /**
     * MeetingStateNotification typeName
     * @member {string} typeName
     * @memberof MeetingStateNotification
     * @instance
     */
    MeetingStateNotification.prototype.typeName = "MeetingState";

    return MeetingStateNotification;
})();

export const MeetingStateNotifications = $root.MeetingStateNotifications = (() => {

    /**
     * Properties of a MeetingStateNotifications.
     * @exports IMeetingStateNotifications
     * @property {Array.<MeetingStateNotification>} [States] MeetingStateNotifications States
     */

    /**
     * Constructs a new MeetingStateNotifications.
     * @exports MeetingStateNotifications
     * @classdesc Represents a MeetingStateNotifications.
     * @constructor
     * @param {Partial<MeetingStateNotifications>} [properties] Properties to set
     */
    function MeetingStateNotifications(properties) {
        this.States = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * MeetingStateNotifications States.
     * @member {Array.<MeetingStateNotification>} States
     * @memberof MeetingStateNotifications
     * @instance
     */
    MeetingStateNotifications.prototype.States = $util.emptyArray;

    /**
     * Encodes the specified MeetingStateNotifications message. Does not implicitly {@link MeetingStateNotifications.verify|verify} messages.
     * @function encode
     * @memberof MeetingStateNotifications
     * @static
     * @param {MeetingStateNotifications} message MeetingStateNotifications message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    MeetingStateNotifications.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.States != null && message.States.length)
            for (let i = 0; i < message.States.length; ++i)
                $root.MeetingStateNotification.encode(message.States[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a MeetingStateNotifications message from the specified reader or buffer.
     * @function decode
     * @memberof MeetingStateNotifications
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {MeetingStateNotifications} MeetingStateNotifications
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    MeetingStateNotifications.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.MeetingStateNotifications();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.States && message.States.length))
                        message.States = [];
                    message.States.push($root.MeetingStateNotification.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a MeetingStateNotifications message to GenericMessage
     * @function toGenericMessage
     * @memberof MeetingStateNotifications
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    MeetingStateNotifications.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 251, MeetingStates : this});
    };

    /**
     * MeetingStateNotifications typeId
     * @member {number} typeId
     * @memberof MeetingStateNotifications
     * @instance
     */
    MeetingStateNotifications.prototype.typeId = 251;
    /**
     * MeetingStateNotifications typeName
     * @member {string} typeName
     * @memberof MeetingStateNotifications
     * @instance
     */
    MeetingStateNotifications.prototype.typeName = "MeetingStates";

    return MeetingStateNotifications;
})();

export const RequestCloseMeeting = $root.RequestCloseMeeting = (() => {

    /**
     * Properties of a RequestCloseMeeting.
     * @exports IRequestCloseMeeting
     * @property {number} [Id] RequestCloseMeeting Id
     */

    /**
     * Constructs a new RequestCloseMeeting.
     * @exports RequestCloseMeeting
     * @classdesc Represents a RequestCloseMeeting.
     * @constructor
     * @param {Partial<RequestCloseMeeting>} [properties] Properties to set
     */
    function RequestCloseMeeting(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestCloseMeeting Id.
     * @member {number} Id
     * @memberof RequestCloseMeeting
     * @instance
     */

    /**
     * Encodes the specified RequestCloseMeeting message. Does not implicitly {@link RequestCloseMeeting.verify|verify} messages.
     * @function encode
     * @memberof RequestCloseMeeting
     * @static
     * @param {RequestCloseMeeting} message RequestCloseMeeting message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestCloseMeeting.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        return writer;
    };

    /**
     * Decodes a RequestCloseMeeting message from the specified reader or buffer.
     * @function decode
     * @memberof RequestCloseMeeting
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestCloseMeeting} RequestCloseMeeting
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestCloseMeeting.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestCloseMeeting();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestCloseMeeting message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestCloseMeeting
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestCloseMeeting.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 511, CloseMeeting : this});
    };

    /**
     * RequestCloseMeeting typeId
     * @member {number} typeId
     * @memberof RequestCloseMeeting
     * @instance
     */
    RequestCloseMeeting.prototype.typeId = 511;
    /**
     * RequestCloseMeeting typeName
     * @member {string} typeName
     * @memberof RequestCloseMeeting
     * @instance
     */
    RequestCloseMeeting.prototype.typeName = "CloseMeeting";

    return RequestCloseMeeting;
})();

export const RequestForwardChatMessage = $root.RequestForwardChatMessage = (() => {

    /**
     * Properties of a RequestForwardChatMessage.
     * @exports IRequestForwardChatMessage
     * @property {number} [SrcIdMessage] RequestForwardChatMessage SrcIdMessage
     * @property {number} [DstIdConversation] RequestForwardChatMessage DstIdConversation
     */

    /**
     * Constructs a new RequestForwardChatMessage.
     * @exports RequestForwardChatMessage
     * @classdesc Represents a RequestForwardChatMessage.
     * @constructor
     * @param {Partial<RequestForwardChatMessage>} [properties] Properties to set
     */
    function RequestForwardChatMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestForwardChatMessage SrcIdMessage.
     * @member {number} SrcIdMessage
     * @memberof RequestForwardChatMessage
     * @instance
     */

    /**
     * RequestForwardChatMessage DstIdConversation.
     * @member {number} DstIdConversation
     * @memberof RequestForwardChatMessage
     * @instance
     */

    /**
     * Encodes the specified RequestForwardChatMessage message. Does not implicitly {@link RequestForwardChatMessage.verify|verify} messages.
     * @function encode
     * @memberof RequestForwardChatMessage
     * @static
     * @param {RequestForwardChatMessage} message RequestForwardChatMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestForwardChatMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.SrcIdMessage != null && Object.hasOwnProperty.call(message, "SrcIdMessage"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.SrcIdMessage);
        if (message.DstIdConversation != null && Object.hasOwnProperty.call(message, "DstIdConversation"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.DstIdConversation);
        return writer;
    };

    /**
     * Decodes a RequestForwardChatMessage message from the specified reader or buffer.
     * @function decode
     * @memberof RequestForwardChatMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestForwardChatMessage} RequestForwardChatMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestForwardChatMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestForwardChatMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.SrcIdMessage = reader.int32();
                    break;
                }
            case 2: {
                    message.DstIdConversation = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestForwardChatMessage message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestForwardChatMessage
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestForwardChatMessage.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 508, ForwardMessage : this});
    };

    /**
     * RequestForwardChatMessage typeId
     * @member {number} typeId
     * @memberof RequestForwardChatMessage
     * @instance
     */
    RequestForwardChatMessage.prototype.typeId = 508;
    /**
     * RequestForwardChatMessage typeName
     * @member {string} typeName
     * @memberof RequestForwardChatMessage
     * @instance
     */
    RequestForwardChatMessage.prototype.typeName = "ForwardMessage";

    return RequestForwardChatMessage;
})();

export const RequestCheckIpBlocked = $root.RequestCheckIpBlocked = (() => {

    /**
     * Properties of a RequestCheckIpBlocked.
     * @exports IRequestCheckIpBlocked
     * @property {string} [IpAddress] RequestCheckIpBlocked IpAddress
     */

    /**
     * Constructs a new RequestCheckIpBlocked.
     * @exports RequestCheckIpBlocked
     * @classdesc Represents a RequestCheckIpBlocked.
     * @constructor
     * @param {Partial<RequestCheckIpBlocked>} [properties] Properties to set
     */
    function RequestCheckIpBlocked(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestCheckIpBlocked IpAddress.
     * @member {string} IpAddress
     * @memberof RequestCheckIpBlocked
     * @instance
     */

    /**
     * Encodes the specified RequestCheckIpBlocked message. Does not implicitly {@link RequestCheckIpBlocked.verify|verify} messages.
     * @function encode
     * @memberof RequestCheckIpBlocked
     * @static
     * @param {RequestCheckIpBlocked} message RequestCheckIpBlocked message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestCheckIpBlocked.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IpAddress != null && Object.hasOwnProperty.call(message, "IpAddress"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.IpAddress);
        return writer;
    };

    /**
     * Decodes a RequestCheckIpBlocked message from the specified reader or buffer.
     * @function decode
     * @memberof RequestCheckIpBlocked
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestCheckIpBlocked} RequestCheckIpBlocked
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestCheckIpBlocked.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestCheckIpBlocked();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IpAddress = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestCheckIpBlocked message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestCheckIpBlocked
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestCheckIpBlocked.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 513, RqCheckIpBlocked : this});
    };

    /**
     * RequestCheckIpBlocked typeId
     * @member {number} typeId
     * @memberof RequestCheckIpBlocked
     * @instance
     */
    RequestCheckIpBlocked.prototype.typeId = 513;
    /**
     * RequestCheckIpBlocked typeName
     * @member {string} typeName
     * @memberof RequestCheckIpBlocked
     * @instance
     */
    RequestCheckIpBlocked.prototype.typeName = "RqCheckIpBlocked";

    return RequestCheckIpBlocked;
})();

export const ResponseCheckIpBlocked = $root.ResponseCheckIpBlocked = (() => {

    /**
     * Properties of a ResponseCheckIpBlocked.
     * @exports IResponseCheckIpBlocked
     * @property {string} [IpAddress] ResponseCheckIpBlocked IpAddress
     * @property {boolean} [IsBlocked] ResponseCheckIpBlocked IsBlocked
     */

    /**
     * Constructs a new ResponseCheckIpBlocked.
     * @exports ResponseCheckIpBlocked
     * @classdesc Represents a ResponseCheckIpBlocked.
     * @constructor
     * @param {Partial<ResponseCheckIpBlocked>} [properties] Properties to set
     */
    function ResponseCheckIpBlocked(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseCheckIpBlocked IpAddress.
     * @member {string} IpAddress
     * @memberof ResponseCheckIpBlocked
     * @instance
     */

    /**
     * ResponseCheckIpBlocked IsBlocked.
     * @member {boolean} IsBlocked
     * @memberof ResponseCheckIpBlocked
     * @instance
     */

    /**
     * Encodes the specified ResponseCheckIpBlocked message. Does not implicitly {@link ResponseCheckIpBlocked.verify|verify} messages.
     * @function encode
     * @memberof ResponseCheckIpBlocked
     * @static
     * @param {ResponseCheckIpBlocked} message ResponseCheckIpBlocked message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseCheckIpBlocked.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IpAddress != null && Object.hasOwnProperty.call(message, "IpAddress"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.IpAddress);
        if (message.IsBlocked != null && Object.hasOwnProperty.call(message, "IsBlocked"))
            writer.uint32(/* id 2, wireType 0 =*/16).bool(message.IsBlocked);
        return writer;
    };

    /**
     * Decodes a ResponseCheckIpBlocked message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseCheckIpBlocked
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseCheckIpBlocked} ResponseCheckIpBlocked
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseCheckIpBlocked.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseCheckIpBlocked();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IpAddress = reader.string();
                    break;
                }
            case 2: {
                    message.IsBlocked = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseCheckIpBlocked message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseCheckIpBlocked
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseCheckIpBlocked.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 252, RespCheckIpBlocked : this});
    };

    /**
     * ResponseCheckIpBlocked typeId
     * @member {number} typeId
     * @memberof ResponseCheckIpBlocked
     * @instance
     */
    ResponseCheckIpBlocked.prototype.typeId = 252;
    /**
     * ResponseCheckIpBlocked typeName
     * @member {string} typeName
     * @memberof ResponseCheckIpBlocked
     * @instance
     */
    ResponseCheckIpBlocked.prototype.typeName = "RespCheckIpBlocked";

    return ResponseCheckIpBlocked;
})();

export const RequestPreviousInteractions = $root.RequestPreviousInteractions = (() => {

    /**
     * Properties of a RequestPreviousInteractions.
     * @exports IRequestPreviousInteractions
     * @property {Contact} [Contact] RequestPreviousInteractions Contact
     */

    /**
     * Constructs a new RequestPreviousInteractions.
     * @exports RequestPreviousInteractions
     * @classdesc Represents a RequestPreviousInteractions.
     * @constructor
     * @param {Partial<RequestPreviousInteractions>} [properties] Properties to set
     */
    function RequestPreviousInteractions(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestPreviousInteractions Contact.
     * @member {Contact} Contact
     * @memberof RequestPreviousInteractions
     * @instance
     */

    /**
     * Encodes the specified RequestPreviousInteractions message. Does not implicitly {@link RequestPreviousInteractions.verify|verify} messages.
     * @function encode
     * @memberof RequestPreviousInteractions
     * @static
     * @param {RequestPreviousInteractions} message RequestPreviousInteractions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestPreviousInteractions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Contact != null && Object.hasOwnProperty.call(message, "Contact"))
            $root.Contact.encode(message.Contact, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestPreviousInteractions message from the specified reader or buffer.
     * @function decode
     * @memberof RequestPreviousInteractions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestPreviousInteractions} RequestPreviousInteractions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestPreviousInteractions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestPreviousInteractions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Contact = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestPreviousInteractions message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestPreviousInteractions
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestPreviousInteractions.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 515, GetPreviousInteractions : this});
    };

    /**
     * RequestPreviousInteractions typeId
     * @member {number} typeId
     * @memberof RequestPreviousInteractions
     * @instance
     */
    RequestPreviousInteractions.prototype.typeId = 515;
    /**
     * RequestPreviousInteractions typeName
     * @member {string} typeName
     * @memberof RequestPreviousInteractions
     * @instance
     */
    RequestPreviousInteractions.prototype.typeName = "GetPreviousInteractions";

    return RequestPreviousInteractions;
})();

export const InteractionDetails = $root.InteractionDetails = (() => {

    /**
     * Properties of an InteractionDetails.
     * @exports IInteractionDetails
     * @property {boolean} [IsChat] InteractionDetails IsChat
     * @property {number} [IdConversation] InteractionDetails IdConversation
     * @property {Contact} [LocalExtension] InteractionDetails LocalExtension
     * @property {DateTime} [LastActivity] InteractionDetails LastActivity
     * @property {CallHistoryType} [CallType] InteractionDetails CallType
     * @property {number} [Duration] InteractionDetails Duration
     * @property {string} [QueueNumber] InteractionDetails QueueNumber
     * @property {string} [QueueName] InteractionDetails QueueName
     * @property {boolean} [IsTaken] InteractionDetails IsTaken
     */

    /**
     * Constructs a new InteractionDetails.
     * @exports InteractionDetails
     * @classdesc Represents an InteractionDetails.
     * @constructor
     * @param {Partial<InteractionDetails>} [properties] Properties to set
     */
    function InteractionDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * InteractionDetails IsChat.
     * @member {boolean} IsChat
     * @memberof InteractionDetails
     * @instance
     */

    /**
     * InteractionDetails IdConversation.
     * @member {number} IdConversation
     * @memberof InteractionDetails
     * @instance
     */

    /**
     * InteractionDetails LocalExtension.
     * @member {Contact} LocalExtension
     * @memberof InteractionDetails
     * @instance
     */

    /**
     * InteractionDetails LastActivity.
     * @member {DateTime} LastActivity
     * @memberof InteractionDetails
     * @instance
     */

    /**
     * InteractionDetails CallType.
     * @member {CallHistoryType} CallType
     * @memberof InteractionDetails
     * @instance
     */

    /**
     * InteractionDetails Duration.
     * @member {number} Duration
     * @memberof InteractionDetails
     * @instance
     */

    /**
     * InteractionDetails QueueNumber.
     * @member {string} QueueNumber
     * @memberof InteractionDetails
     * @instance
     */

    /**
     * InteractionDetails QueueName.
     * @member {string} QueueName
     * @memberof InteractionDetails
     * @instance
     */

    /**
     * InteractionDetails IsTaken.
     * @member {boolean} IsTaken
     * @memberof InteractionDetails
     * @instance
     */

    /**
     * Encodes the specified InteractionDetails message. Does not implicitly {@link InteractionDetails.verify|verify} messages.
     * @function encode
     * @memberof InteractionDetails
     * @static
     * @param {InteractionDetails} message InteractionDetails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    InteractionDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IsChat != null && Object.hasOwnProperty.call(message, "IsChat"))
            writer.uint32(/* id 1, wireType 0 =*/8).bool(message.IsChat);
        if (message.IdConversation != null && Object.hasOwnProperty.call(message, "IdConversation"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.IdConversation);
        if (message.LocalExtension != null && Object.hasOwnProperty.call(message, "LocalExtension"))
            $root.Contact.encode(message.LocalExtension, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        if (message.LastActivity != null && Object.hasOwnProperty.call(message, "LastActivity"))
            $root.DateTime.encode(message.LastActivity, writer.uint32(/* id 4, wireType 2 =*/34).fork()).ldelim();
        if (message.CallType != null && Object.hasOwnProperty.call(message, "CallType"))
            writer.uint32(/* id 5, wireType 0 =*/40).int32(message.CallType);
        if (message.Duration != null && Object.hasOwnProperty.call(message, "Duration"))
            writer.uint32(/* id 6, wireType 0 =*/48).int32(message.Duration);
        if (message.QueueNumber != null && Object.hasOwnProperty.call(message, "QueueNumber"))
            writer.uint32(/* id 7, wireType 2 =*/58).string(message.QueueNumber);
        if (message.QueueName != null && Object.hasOwnProperty.call(message, "QueueName"))
            writer.uint32(/* id 8, wireType 2 =*/66).string(message.QueueName);
        if (message.IsTaken != null && Object.hasOwnProperty.call(message, "IsTaken"))
            writer.uint32(/* id 9, wireType 0 =*/72).bool(message.IsTaken);
        return writer;
    };

    /**
     * Decodes an InteractionDetails message from the specified reader or buffer.
     * @function decode
     * @memberof InteractionDetails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {InteractionDetails} InteractionDetails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    InteractionDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.InteractionDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IsChat = reader.bool();
                    break;
                }
            case 2: {
                    message.IdConversation = reader.int32();
                    break;
                }
            case 3: {
                    message.LocalExtension = $root.Contact.decode(reader, reader.uint32());
                    break;
                }
            case 4: {
                    message.LastActivity = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 5: {
                    message.CallType = reader.int32();
                    break;
                }
            case 6: {
                    message.Duration = reader.int32();
                    break;
                }
            case 7: {
                    message.QueueNumber = reader.string();
                    break;
                }
            case 8: {
                    message.QueueName = reader.string();
                    break;
                }
            case 9: {
                    message.IsTaken = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return InteractionDetails;
})();

export const ResponsePreviousInteractions = $root.ResponsePreviousInteractions = (() => {

    /**
     * Properties of a ResponsePreviousInteractions.
     * @exports IResponsePreviousInteractions
     * @property {Array.<InteractionDetails>} [Interactions] ResponsePreviousInteractions Interactions
     */

    /**
     * Constructs a new ResponsePreviousInteractions.
     * @exports ResponsePreviousInteractions
     * @classdesc Represents a ResponsePreviousInteractions.
     * @constructor
     * @param {Partial<ResponsePreviousInteractions>} [properties] Properties to set
     */
    function ResponsePreviousInteractions(properties) {
        this.Interactions = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponsePreviousInteractions Interactions.
     * @member {Array.<InteractionDetails>} Interactions
     * @memberof ResponsePreviousInteractions
     * @instance
     */
    ResponsePreviousInteractions.prototype.Interactions = $util.emptyArray;

    /**
     * Encodes the specified ResponsePreviousInteractions message. Does not implicitly {@link ResponsePreviousInteractions.verify|verify} messages.
     * @function encode
     * @memberof ResponsePreviousInteractions
     * @static
     * @param {ResponsePreviousInteractions} message ResponsePreviousInteractions message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponsePreviousInteractions.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Interactions != null && message.Interactions.length)
            for (let i = 0; i < message.Interactions.length; ++i)
                $root.InteractionDetails.encode(message.Interactions[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponsePreviousInteractions message from the specified reader or buffer.
     * @function decode
     * @memberof ResponsePreviousInteractions
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponsePreviousInteractions} ResponsePreviousInteractions
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponsePreviousInteractions.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponsePreviousInteractions();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Interactions && message.Interactions.length))
                        message.Interactions = [];
                    message.Interactions.push($root.InteractionDetails.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponsePreviousInteractions message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponsePreviousInteractions
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponsePreviousInteractions.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 253, PreviousInteractions : this});
    };

    /**
     * ResponsePreviousInteractions typeId
     * @member {number} typeId
     * @memberof ResponsePreviousInteractions
     * @instance
     */
    ResponsePreviousInteractions.prototype.typeId = 253;
    /**
     * ResponsePreviousInteractions typeName
     * @member {string} typeName
     * @memberof ResponsePreviousInteractions
     * @instance
     */
    ResponsePreviousInteractions.prototype.typeName = "PreviousInteractions";

    return ResponsePreviousInteractions;
})();

export const RequestUpdateGroup = $root.RequestUpdateGroup = (() => {

    /**
     * Properties of a RequestUpdateGroup.
     * @exports IRequestUpdateGroup
     * @property {number} IdGroup RequestUpdateGroup IdGroup
     * @property {string} [Name] RequestUpdateGroup Name
     * @property {string} [Language] RequestUpdateGroup Language
     * @property {GroupHoursMode} [CurrentGroupHours] RequestUpdateGroup CurrentGroupHours
     * @property {DateTime} [OverrideExpiresAt] RequestUpdateGroup OverrideExpiresAt
     * @property {ForwardDestination} [OpenedHoursOperator] RequestUpdateGroup OpenedHoursOperator
     * @property {ForwardDestination} [BreakTimeOperator] RequestUpdateGroup BreakTimeOperator
     * @property {ForwardDestination} [ClosedHoursOperator] RequestUpdateGroup ClosedHoursOperator
     * @property {ForwardDestination} [CustomOperator] RequestUpdateGroup CustomOperator
     * @property {string} [OpenedHoursPrompt] RequestUpdateGroup OpenedHoursPrompt
     * @property {string} [ClosedHoursPrompt] RequestUpdateGroup ClosedHoursPrompt
     * @property {string} [BreakTimePrompt] RequestUpdateGroup BreakTimePrompt
     * @property {string} [CustomPrompt] RequestUpdateGroup CustomPrompt
     * @property {boolean} [DisableCustomPrompt] RequestUpdateGroup DisableCustomPrompt
     * @property {boolean} [DisableOpenedHoursPrompt] RequestUpdateGroup DisableOpenedHoursPrompt
     * @property {boolean} [DisableClosedHoursPrompt] RequestUpdateGroup DisableClosedHoursPrompt
     * @property {boolean} [DisableBreakTimePrompt] RequestUpdateGroup DisableBreakTimePrompt
     */

    /**
     * Constructs a new RequestUpdateGroup.
     * @exports RequestUpdateGroup
     * @classdesc Represents a RequestUpdateGroup.
     * @constructor
     * @param {Partial<RequestUpdateGroup>} [properties] Properties to set
     */
    function RequestUpdateGroup(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateGroup IdGroup.
     * @member {number} IdGroup
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup Name.
     * @member {string} Name
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup Language.
     * @member {string} Language
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup CurrentGroupHours.
     * @member {GroupHoursMode} CurrentGroupHours
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup OverrideExpiresAt.
     * @member {DateTime} OverrideExpiresAt
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup OpenedHoursOperator.
     * @member {ForwardDestination} OpenedHoursOperator
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup BreakTimeOperator.
     * @member {ForwardDestination} BreakTimeOperator
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup ClosedHoursOperator.
     * @member {ForwardDestination} ClosedHoursOperator
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup CustomOperator.
     * @member {ForwardDestination} CustomOperator
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup OpenedHoursPrompt.
     * @member {string} OpenedHoursPrompt
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup ClosedHoursPrompt.
     * @member {string} ClosedHoursPrompt
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup BreakTimePrompt.
     * @member {string} BreakTimePrompt
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup CustomPrompt.
     * @member {string} CustomPrompt
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup DisableCustomPrompt.
     * @member {boolean} DisableCustomPrompt
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup DisableOpenedHoursPrompt.
     * @member {boolean} DisableOpenedHoursPrompt
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup DisableClosedHoursPrompt.
     * @member {boolean} DisableClosedHoursPrompt
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * RequestUpdateGroup DisableBreakTimePrompt.
     * @member {boolean} DisableBreakTimePrompt
     * @memberof RequestUpdateGroup
     * @instance
     */

    /**
     * Encodes the specified RequestUpdateGroup message. Does not implicitly {@link RequestUpdateGroup.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateGroup
     * @static
     * @param {RequestUpdateGroup} message RequestUpdateGroup message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateGroup.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdGroup);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Name);
        if (message.Language != null && Object.hasOwnProperty.call(message, "Language"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Language);
        if (message.CurrentGroupHours != null && Object.hasOwnProperty.call(message, "CurrentGroupHours"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.CurrentGroupHours);
        if (message.OverrideExpiresAt != null && Object.hasOwnProperty.call(message, "OverrideExpiresAt"))
            $root.DateTime.encode(message.OverrideExpiresAt, writer.uint32(/* id 5, wireType 2 =*/42).fork()).ldelim();
        if (message.OpenedHoursOperator != null && Object.hasOwnProperty.call(message, "OpenedHoursOperator"))
            $root.ForwardDestination.encode(message.OpenedHoursOperator, writer.uint32(/* id 6, wireType 2 =*/50).fork()).ldelim();
        if (message.BreakTimeOperator != null && Object.hasOwnProperty.call(message, "BreakTimeOperator"))
            $root.ForwardDestination.encode(message.BreakTimeOperator, writer.uint32(/* id 7, wireType 2 =*/58).fork()).ldelim();
        if (message.ClosedHoursOperator != null && Object.hasOwnProperty.call(message, "ClosedHoursOperator"))
            $root.ForwardDestination.encode(message.ClosedHoursOperator, writer.uint32(/* id 8, wireType 2 =*/66).fork()).ldelim();
        if (message.CustomOperator != null && Object.hasOwnProperty.call(message, "CustomOperator"))
            $root.ForwardDestination.encode(message.CustomOperator, writer.uint32(/* id 9, wireType 2 =*/74).fork()).ldelim();
        if (message.OpenedHoursPrompt != null && Object.hasOwnProperty.call(message, "OpenedHoursPrompt"))
            writer.uint32(/* id 10, wireType 2 =*/82).string(message.OpenedHoursPrompt);
        if (message.ClosedHoursPrompt != null && Object.hasOwnProperty.call(message, "ClosedHoursPrompt"))
            writer.uint32(/* id 11, wireType 2 =*/90).string(message.ClosedHoursPrompt);
        if (message.BreakTimePrompt != null && Object.hasOwnProperty.call(message, "BreakTimePrompt"))
            writer.uint32(/* id 12, wireType 2 =*/98).string(message.BreakTimePrompt);
        if (message.CustomPrompt != null && Object.hasOwnProperty.call(message, "CustomPrompt"))
            writer.uint32(/* id 13, wireType 2 =*/106).string(message.CustomPrompt);
        if (message.DisableCustomPrompt != null && Object.hasOwnProperty.call(message, "DisableCustomPrompt"))
            writer.uint32(/* id 14, wireType 0 =*/112).bool(message.DisableCustomPrompt);
        if (message.DisableOpenedHoursPrompt != null && Object.hasOwnProperty.call(message, "DisableOpenedHoursPrompt"))
            writer.uint32(/* id 15, wireType 0 =*/120).bool(message.DisableOpenedHoursPrompt);
        if (message.DisableClosedHoursPrompt != null && Object.hasOwnProperty.call(message, "DisableClosedHoursPrompt"))
            writer.uint32(/* id 16, wireType 0 =*/128).bool(message.DisableClosedHoursPrompt);
        if (message.DisableBreakTimePrompt != null && Object.hasOwnProperty.call(message, "DisableBreakTimePrompt"))
            writer.uint32(/* id 17, wireType 0 =*/136).bool(message.DisableBreakTimePrompt);
        return writer;
    };

    /**
     * Decodes a RequestUpdateGroup message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateGroup
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateGroup} RequestUpdateGroup
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateGroup.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateGroup();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdGroup = reader.int32();
                    break;
                }
            case 2: {
                    message.Name = reader.string();
                    break;
                }
            case 3: {
                    message.Language = reader.string();
                    break;
                }
            case 4: {
                    message.CurrentGroupHours = reader.int32();
                    break;
                }
            case 5: {
                    message.OverrideExpiresAt = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 6: {
                    message.OpenedHoursOperator = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 7: {
                    message.BreakTimeOperator = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 8: {
                    message.ClosedHoursOperator = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 9: {
                    message.CustomOperator = $root.ForwardDestination.decode(reader, reader.uint32());
                    break;
                }
            case 10: {
                    message.OpenedHoursPrompt = reader.string();
                    break;
                }
            case 11: {
                    message.ClosedHoursPrompt = reader.string();
                    break;
                }
            case 12: {
                    message.BreakTimePrompt = reader.string();
                    break;
                }
            case 13: {
                    message.CustomPrompt = reader.string();
                    break;
                }
            case 14: {
                    message.DisableCustomPrompt = reader.bool();
                    break;
                }
            case 15: {
                    message.DisableOpenedHoursPrompt = reader.bool();
                    break;
                }
            case 16: {
                    message.DisableClosedHoursPrompt = reader.bool();
                    break;
                }
            case 17: {
                    message.DisableBreakTimePrompt = reader.bool();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateGroup message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateGroup
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateGroup.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 516, UpdateGroup : this});
    };

    /**
     * RequestUpdateGroup typeId
     * @member {number} typeId
     * @memberof RequestUpdateGroup
     * @instance
     */
    RequestUpdateGroup.prototype.typeId = 516;
    /**
     * RequestUpdateGroup typeName
     * @member {string} typeName
     * @memberof RequestUpdateGroup
     * @instance
     */
    RequestUpdateGroup.prototype.typeName = "UpdateGroup";

    return RequestUpdateGroup;
})();

export const RequestGetChatTemplateCategories = $root.RequestGetChatTemplateCategories = (() => {

    /**
     * Properties of a RequestGetChatTemplateCategories.
     * @exports IRequestGetChatTemplateCategories
     * @property {string} [OwnerDn] RequestGetChatTemplateCategories OwnerDn
     */

    /**
     * Constructs a new RequestGetChatTemplateCategories.
     * @exports RequestGetChatTemplateCategories
     * @classdesc Represents a RequestGetChatTemplateCategories.
     * @constructor
     * @param {Partial<RequestGetChatTemplateCategories>} [properties] Properties to set
     */
    function RequestGetChatTemplateCategories(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetChatTemplateCategories OwnerDn.
     * @member {string} OwnerDn
     * @memberof RequestGetChatTemplateCategories
     * @instance
     */

    /**
     * Encodes the specified RequestGetChatTemplateCategories message. Does not implicitly {@link RequestGetChatTemplateCategories.verify|verify} messages.
     * @function encode
     * @memberof RequestGetChatTemplateCategories
     * @static
     * @param {RequestGetChatTemplateCategories} message RequestGetChatTemplateCategories message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetChatTemplateCategories.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.OwnerDn != null && Object.hasOwnProperty.call(message, "OwnerDn"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.OwnerDn);
        return writer;
    };

    /**
     * Decodes a RequestGetChatTemplateCategories message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetChatTemplateCategories
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetChatTemplateCategories} RequestGetChatTemplateCategories
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetChatTemplateCategories.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetChatTemplateCategories();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.OwnerDn = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetChatTemplateCategories message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetChatTemplateCategories
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetChatTemplateCategories.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 517, GetChatTemplateCategories : this});
    };

    /**
     * RequestGetChatTemplateCategories typeId
     * @member {number} typeId
     * @memberof RequestGetChatTemplateCategories
     * @instance
     */
    RequestGetChatTemplateCategories.prototype.typeId = 517;
    /**
     * RequestGetChatTemplateCategories typeName
     * @member {string} typeName
     * @memberof RequestGetChatTemplateCategories
     * @instance
     */
    RequestGetChatTemplateCategories.prototype.typeName = "GetChatTemplateCategories";

    return RequestGetChatTemplateCategories;
})();

export const ResponseGetChatTemplateCategories = $root.ResponseGetChatTemplateCategories = (() => {

    /**
     * Properties of a ResponseGetChatTemplateCategories.
     * @exports IResponseGetChatTemplateCategories
     * @property {Array.<ChatTemplateCategory>} [Categories] ResponseGetChatTemplateCategories Categories
     */

    /**
     * Constructs a new ResponseGetChatTemplateCategories.
     * @exports ResponseGetChatTemplateCategories
     * @classdesc Represents a ResponseGetChatTemplateCategories.
     * @constructor
     * @param {Partial<ResponseGetChatTemplateCategories>} [properties] Properties to set
     */
    function ResponseGetChatTemplateCategories(properties) {
        this.Categories = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseGetChatTemplateCategories Categories.
     * @member {Array.<ChatTemplateCategory>} Categories
     * @memberof ResponseGetChatTemplateCategories
     * @instance
     */
    ResponseGetChatTemplateCategories.prototype.Categories = $util.emptyArray;

    /**
     * Encodes the specified ResponseGetChatTemplateCategories message. Does not implicitly {@link ResponseGetChatTemplateCategories.verify|verify} messages.
     * @function encode
     * @memberof ResponseGetChatTemplateCategories
     * @static
     * @param {ResponseGetChatTemplateCategories} message ResponseGetChatTemplateCategories message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGetChatTemplateCategories.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Categories != null && message.Categories.length)
            for (let i = 0; i < message.Categories.length; ++i)
                $root.ChatTemplateCategory.encode(message.Categories[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseGetChatTemplateCategories message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseGetChatTemplateCategories
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseGetChatTemplateCategories} ResponseGetChatTemplateCategories
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGetChatTemplateCategories.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseGetChatTemplateCategories();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Categories && message.Categories.length))
                        message.Categories = [];
                    message.Categories.push($root.ChatTemplateCategory.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseGetChatTemplateCategories message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseGetChatTemplateCategories
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseGetChatTemplateCategories.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 254, ChatTemplateCategories : this});
    };

    /**
     * ResponseGetChatTemplateCategories typeId
     * @member {number} typeId
     * @memberof ResponseGetChatTemplateCategories
     * @instance
     */
    ResponseGetChatTemplateCategories.prototype.typeId = 254;
    /**
     * ResponseGetChatTemplateCategories typeName
     * @member {string} typeName
     * @memberof ResponseGetChatTemplateCategories
     * @instance
     */
    ResponseGetChatTemplateCategories.prototype.typeName = "ChatTemplateCategories";

    return ResponseGetChatTemplateCategories;
})();

export const ChatTemplateCategory = $root.ChatTemplateCategory = (() => {

    /**
     * Properties of a ChatTemplateCategory.
     * @exports IChatTemplateCategory
     * @property {number} [Id] ChatTemplateCategory Id
     * @property {string} [Name] ChatTemplateCategory Name
     * @property {string} [OwnerDn] ChatTemplateCategory OwnerDn
     * @property {number} [OrderIndex] ChatTemplateCategory OrderIndex
     */

    /**
     * Constructs a new ChatTemplateCategory.
     * @exports ChatTemplateCategory
     * @classdesc Represents a ChatTemplateCategory.
     * @constructor
     * @param {Partial<ChatTemplateCategory>} [properties] Properties to set
     */
    function ChatTemplateCategory(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatTemplateCategory Id.
     * @member {number} Id
     * @memberof ChatTemplateCategory
     * @instance
     */

    /**
     * ChatTemplateCategory Name.
     * @member {string} Name
     * @memberof ChatTemplateCategory
     * @instance
     */

    /**
     * ChatTemplateCategory OwnerDn.
     * @member {string} OwnerDn
     * @memberof ChatTemplateCategory
     * @instance
     */

    /**
     * ChatTemplateCategory OrderIndex.
     * @member {number} OrderIndex
     * @memberof ChatTemplateCategory
     * @instance
     */

    /**
     * Encodes the specified ChatTemplateCategory message. Does not implicitly {@link ChatTemplateCategory.verify|verify} messages.
     * @function encode
     * @memberof ChatTemplateCategory
     * @static
     * @param {ChatTemplateCategory} message ChatTemplateCategory message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatTemplateCategory.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Name);
        if (message.OwnerDn != null && Object.hasOwnProperty.call(message, "OwnerDn"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.OwnerDn);
        if (message.OrderIndex != null && Object.hasOwnProperty.call(message, "OrderIndex"))
            writer.uint32(/* id 4, wireType 0 =*/32).int32(message.OrderIndex);
        return writer;
    };

    /**
     * Decodes a ChatTemplateCategory message from the specified reader or buffer.
     * @function decode
     * @memberof ChatTemplateCategory
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatTemplateCategory} ChatTemplateCategory
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatTemplateCategory.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatTemplateCategory();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.Name = reader.string();
                    break;
                }
            case 3: {
                    message.OwnerDn = reader.string();
                    break;
                }
            case 4: {
                    message.OrderIndex = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatTemplateCategory;
})();

export const RequestUpdateCategory = $root.RequestUpdateCategory = (() => {

    /**
     * Properties of a RequestUpdateCategory.
     * @exports IRequestUpdateCategory
     * @property {ActionType} Action RequestUpdateCategory Action
     * @property {ChatTemplateCategory} [Category] RequestUpdateCategory Category
     */

    /**
     * Constructs a new RequestUpdateCategory.
     * @exports RequestUpdateCategory
     * @classdesc Represents a RequestUpdateCategory.
     * @constructor
     * @param {Partial<RequestUpdateCategory>} [properties] Properties to set
     */
    function RequestUpdateCategory(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateCategory Action.
     * @member {ActionType} Action
     * @memberof RequestUpdateCategory
     * @instance
     */

    /**
     * RequestUpdateCategory Category.
     * @member {ChatTemplateCategory} Category
     * @memberof RequestUpdateCategory
     * @instance
     */

    /**
     * Encodes the specified RequestUpdateCategory message. Does not implicitly {@link RequestUpdateCategory.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateCategory
     * @static
     * @param {RequestUpdateCategory} message RequestUpdateCategory message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateCategory.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Category != null && Object.hasOwnProperty.call(message, "Category"))
            $root.ChatTemplateCategory.encode(message.Category, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestUpdateCategory message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateCategory
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateCategory} RequestUpdateCategory
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateCategory.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateCategory();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Category = $root.ChatTemplateCategory.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateCategory message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateCategory
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateCategory.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 520, UpdateCategory : this});
    };

    /**
     * RequestUpdateCategory typeId
     * @member {number} typeId
     * @memberof RequestUpdateCategory
     * @instance
     */
    RequestUpdateCategory.prototype.typeId = 520;
    /**
     * RequestUpdateCategory typeName
     * @member {string} typeName
     * @memberof RequestUpdateCategory
     * @instance
     */
    RequestUpdateCategory.prototype.typeName = "UpdateCategory";

    return RequestUpdateCategory;
})();

export const RequestBulkUpdateCategory = $root.RequestBulkUpdateCategory = (() => {

    /**
     * Properties of a RequestBulkUpdateCategory.
     * @exports IRequestBulkUpdateCategory
     * @property {Array.<RequestUpdateCategory>} [Categories] RequestBulkUpdateCategory Categories
     */

    /**
     * Constructs a new RequestBulkUpdateCategory.
     * @exports RequestBulkUpdateCategory
     * @classdesc Represents a RequestBulkUpdateCategory.
     * @constructor
     * @param {Partial<RequestBulkUpdateCategory>} [properties] Properties to set
     */
    function RequestBulkUpdateCategory(properties) {
        this.Categories = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestBulkUpdateCategory Categories.
     * @member {Array.<RequestUpdateCategory>} Categories
     * @memberof RequestBulkUpdateCategory
     * @instance
     */
    RequestBulkUpdateCategory.prototype.Categories = $util.emptyArray;

    /**
     * Encodes the specified RequestBulkUpdateCategory message. Does not implicitly {@link RequestBulkUpdateCategory.verify|verify} messages.
     * @function encode
     * @memberof RequestBulkUpdateCategory
     * @static
     * @param {RequestBulkUpdateCategory} message RequestBulkUpdateCategory message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestBulkUpdateCategory.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Categories != null && message.Categories.length)
            for (let i = 0; i < message.Categories.length; ++i)
                $root.RequestUpdateCategory.encode(message.Categories[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestBulkUpdateCategory message from the specified reader or buffer.
     * @function decode
     * @memberof RequestBulkUpdateCategory
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestBulkUpdateCategory} RequestBulkUpdateCategory
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestBulkUpdateCategory.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestBulkUpdateCategory();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Categories && message.Categories.length))
                        message.Categories = [];
                    message.Categories.push($root.RequestUpdateCategory.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestBulkUpdateCategory message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestBulkUpdateCategory
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestBulkUpdateCategory.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 522, BulkUpdateCategory : this});
    };

    /**
     * RequestBulkUpdateCategory typeId
     * @member {number} typeId
     * @memberof RequestBulkUpdateCategory
     * @instance
     */
    RequestBulkUpdateCategory.prototype.typeId = 522;
    /**
     * RequestBulkUpdateCategory typeName
     * @member {string} typeName
     * @memberof RequestBulkUpdateCategory
     * @instance
     */
    RequestBulkUpdateCategory.prototype.typeName = "BulkUpdateCategory";

    return RequestBulkUpdateCategory;
})();

export const RequestGetChatTemplateLanguages = $root.RequestGetChatTemplateLanguages = (() => {

    /**
     * Properties of a RequestGetChatTemplateLanguages.
     * @exports IRequestGetChatTemplateLanguages
     */

    /**
     * Constructs a new RequestGetChatTemplateLanguages.
     * @exports RequestGetChatTemplateLanguages
     * @classdesc Represents a RequestGetChatTemplateLanguages.
     * @constructor
     * @param {Partial<RequestGetChatTemplateLanguages>} [properties] Properties to set
     */
    function RequestGetChatTemplateLanguages(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * Encodes the specified RequestGetChatTemplateLanguages message. Does not implicitly {@link RequestGetChatTemplateLanguages.verify|verify} messages.
     * @function encode
     * @memberof RequestGetChatTemplateLanguages
     * @static
     * @param {RequestGetChatTemplateLanguages} message RequestGetChatTemplateLanguages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetChatTemplateLanguages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        return writer;
    };

    /**
     * Decodes a RequestGetChatTemplateLanguages message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetChatTemplateLanguages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetChatTemplateLanguages} RequestGetChatTemplateLanguages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetChatTemplateLanguages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetChatTemplateLanguages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetChatTemplateLanguages message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetChatTemplateLanguages
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetChatTemplateLanguages.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 518, GetChatTemplateLanguages : this});
    };

    /**
     * RequestGetChatTemplateLanguages typeId
     * @member {number} typeId
     * @memberof RequestGetChatTemplateLanguages
     * @instance
     */
    RequestGetChatTemplateLanguages.prototype.typeId = 518;
    /**
     * RequestGetChatTemplateLanguages typeName
     * @member {string} typeName
     * @memberof RequestGetChatTemplateLanguages
     * @instance
     */
    RequestGetChatTemplateLanguages.prototype.typeName = "GetChatTemplateLanguages";

    return RequestGetChatTemplateLanguages;
})();

export const ResponseGetChatTemplateLanguages = $root.ResponseGetChatTemplateLanguages = (() => {

    /**
     * Properties of a ResponseGetChatTemplateLanguages.
     * @exports IResponseGetChatTemplateLanguages
     * @property {Array.<ChatTemplateLanguage>} [Languages] ResponseGetChatTemplateLanguages Languages
     */

    /**
     * Constructs a new ResponseGetChatTemplateLanguages.
     * @exports ResponseGetChatTemplateLanguages
     * @classdesc Represents a ResponseGetChatTemplateLanguages.
     * @constructor
     * @param {Partial<ResponseGetChatTemplateLanguages>} [properties] Properties to set
     */
    function ResponseGetChatTemplateLanguages(properties) {
        this.Languages = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseGetChatTemplateLanguages Languages.
     * @member {Array.<ChatTemplateLanguage>} Languages
     * @memberof ResponseGetChatTemplateLanguages
     * @instance
     */
    ResponseGetChatTemplateLanguages.prototype.Languages = $util.emptyArray;

    /**
     * Encodes the specified ResponseGetChatTemplateLanguages message. Does not implicitly {@link ResponseGetChatTemplateLanguages.verify|verify} messages.
     * @function encode
     * @memberof ResponseGetChatTemplateLanguages
     * @static
     * @param {ResponseGetChatTemplateLanguages} message ResponseGetChatTemplateLanguages message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGetChatTemplateLanguages.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Languages != null && message.Languages.length)
            for (let i = 0; i < message.Languages.length; ++i)
                $root.ChatTemplateLanguage.encode(message.Languages[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseGetChatTemplateLanguages message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseGetChatTemplateLanguages
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseGetChatTemplateLanguages} ResponseGetChatTemplateLanguages
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGetChatTemplateLanguages.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseGetChatTemplateLanguages();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Languages && message.Languages.length))
                        message.Languages = [];
                    message.Languages.push($root.ChatTemplateLanguage.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseGetChatTemplateLanguages message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseGetChatTemplateLanguages
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseGetChatTemplateLanguages.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 255, ChatTemplateLanguages : this});
    };

    /**
     * ResponseGetChatTemplateLanguages typeId
     * @member {number} typeId
     * @memberof ResponseGetChatTemplateLanguages
     * @instance
     */
    ResponseGetChatTemplateLanguages.prototype.typeId = 255;
    /**
     * ResponseGetChatTemplateLanguages typeName
     * @member {string} typeName
     * @memberof ResponseGetChatTemplateLanguages
     * @instance
     */
    ResponseGetChatTemplateLanguages.prototype.typeName = "ChatTemplateLanguages";

    return ResponseGetChatTemplateLanguages;
})();

export const ChatTemplateLanguage = $root.ChatTemplateLanguage = (() => {

    /**
     * Properties of a ChatTemplateLanguage.
     * @exports IChatTemplateLanguage
     * @property {number} [Id] ChatTemplateLanguage Id
     * @property {string} [Name] ChatTemplateLanguage Name
     * @property {string} [Locale] ChatTemplateLanguage Locale
     * @property {string} [Icon] ChatTemplateLanguage Icon
     */

    /**
     * Constructs a new ChatTemplateLanguage.
     * @exports ChatTemplateLanguage
     * @classdesc Represents a ChatTemplateLanguage.
     * @constructor
     * @param {Partial<ChatTemplateLanguage>} [properties] Properties to set
     */
    function ChatTemplateLanguage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatTemplateLanguage Id.
     * @member {number} Id
     * @memberof ChatTemplateLanguage
     * @instance
     */

    /**
     * ChatTemplateLanguage Name.
     * @member {string} Name
     * @memberof ChatTemplateLanguage
     * @instance
     */

    /**
     * ChatTemplateLanguage Locale.
     * @member {string} Locale
     * @memberof ChatTemplateLanguage
     * @instance
     */

    /**
     * ChatTemplateLanguage Icon.
     * @member {string} Icon
     * @memberof ChatTemplateLanguage
     * @instance
     */

    /**
     * Encodes the specified ChatTemplateLanguage message. Does not implicitly {@link ChatTemplateLanguage.verify|verify} messages.
     * @function encode
     * @memberof ChatTemplateLanguage
     * @static
     * @param {ChatTemplateLanguage} message ChatTemplateLanguage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatTemplateLanguage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 2, wireType 2 =*/18).string(message.Name);
        if (message.Locale != null && Object.hasOwnProperty.call(message, "Locale"))
            writer.uint32(/* id 3, wireType 2 =*/26).string(message.Locale);
        if (message.Icon != null && Object.hasOwnProperty.call(message, "Icon"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Icon);
        return writer;
    };

    /**
     * Decodes a ChatTemplateLanguage message from the specified reader or buffer.
     * @function decode
     * @memberof ChatTemplateLanguage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatTemplateLanguage} ChatTemplateLanguage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatTemplateLanguage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatTemplateLanguage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.Name = reader.string();
                    break;
                }
            case 3: {
                    message.Locale = reader.string();
                    break;
                }
            case 4: {
                    message.Icon = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatTemplateLanguage;
})();

export const ChatTemplate = $root.ChatTemplate = (() => {

    /**
     * Properties of a ChatTemplate.
     * @exports IChatTemplate
     * @property {number} [Id] ChatTemplate Id
     * @property {number} [IdCategory] ChatTemplate IdCategory
     * @property {number} [IdLanguage] ChatTemplate IdLanguage
     * @property {string} [Name] ChatTemplate Name
     * @property {string} [Template] ChatTemplate Template
     */

    /**
     * Constructs a new ChatTemplate.
     * @exports ChatTemplate
     * @classdesc Represents a ChatTemplate.
     * @constructor
     * @param {Partial<ChatTemplate>} [properties] Properties to set
     */
    function ChatTemplate(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ChatTemplate Id.
     * @member {number} Id
     * @memberof ChatTemplate
     * @instance
     */

    /**
     * ChatTemplate IdCategory.
     * @member {number} IdCategory
     * @memberof ChatTemplate
     * @instance
     */

    /**
     * ChatTemplate IdLanguage.
     * @member {number} IdLanguage
     * @memberof ChatTemplate
     * @instance
     */

    /**
     * ChatTemplate Name.
     * @member {string} Name
     * @memberof ChatTemplate
     * @instance
     */

    /**
     * ChatTemplate Template.
     * @member {string} Template
     * @memberof ChatTemplate
     * @instance
     */

    /**
     * Encodes the specified ChatTemplate message. Does not implicitly {@link ChatTemplate.verify|verify} messages.
     * @function encode
     * @memberof ChatTemplate
     * @static
     * @param {ChatTemplate} message ChatTemplate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ChatTemplate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Id != null && Object.hasOwnProperty.call(message, "Id"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Id);
        if (message.IdCategory != null && Object.hasOwnProperty.call(message, "IdCategory"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.IdCategory);
        if (message.IdLanguage != null && Object.hasOwnProperty.call(message, "IdLanguage"))
            writer.uint32(/* id 3, wireType 0 =*/24).int32(message.IdLanguage);
        if (message.Name != null && Object.hasOwnProperty.call(message, "Name"))
            writer.uint32(/* id 4, wireType 2 =*/34).string(message.Name);
        if (message.Template != null && Object.hasOwnProperty.call(message, "Template"))
            writer.uint32(/* id 5, wireType 2 =*/42).string(message.Template);
        return writer;
    };

    /**
     * Decodes a ChatTemplate message from the specified reader or buffer.
     * @function decode
     * @memberof ChatTemplate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ChatTemplate} ChatTemplate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ChatTemplate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ChatTemplate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Id = reader.int32();
                    break;
                }
            case 2: {
                    message.IdCategory = reader.int32();
                    break;
                }
            case 3: {
                    message.IdLanguage = reader.int32();
                    break;
                }
            case 4: {
                    message.Name = reader.string();
                    break;
                }
            case 5: {
                    message.Template = reader.string();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ChatTemplate;
})();

export const RequestGetChatTemplates = $root.RequestGetChatTemplates = (() => {

    /**
     * Properties of a RequestGetChatTemplates.
     * @exports IRequestGetChatTemplates
     * @property {number} [IdCategory] RequestGetChatTemplates IdCategory
     * @property {number} [IdLanguage] RequestGetChatTemplates IdLanguage
     */

    /**
     * Constructs a new RequestGetChatTemplates.
     * @exports RequestGetChatTemplates
     * @classdesc Represents a RequestGetChatTemplates.
     * @constructor
     * @param {Partial<RequestGetChatTemplates>} [properties] Properties to set
     */
    function RequestGetChatTemplates(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetChatTemplates IdCategory.
     * @member {number} IdCategory
     * @memberof RequestGetChatTemplates
     * @instance
     */

    /**
     * RequestGetChatTemplates IdLanguage.
     * @member {number} IdLanguage
     * @memberof RequestGetChatTemplates
     * @instance
     */

    /**
     * Encodes the specified RequestGetChatTemplates message. Does not implicitly {@link RequestGetChatTemplates.verify|verify} messages.
     * @function encode
     * @memberof RequestGetChatTemplates
     * @static
     * @param {RequestGetChatTemplates} message RequestGetChatTemplates message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetChatTemplates.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.IdCategory != null && Object.hasOwnProperty.call(message, "IdCategory"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.IdCategory);
        if (message.IdLanguage != null && Object.hasOwnProperty.call(message, "IdLanguage"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.IdLanguage);
        return writer;
    };

    /**
     * Decodes a RequestGetChatTemplates message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetChatTemplates
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetChatTemplates} RequestGetChatTemplates
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetChatTemplates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetChatTemplates();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.IdCategory = reader.int32();
                    break;
                }
            case 2: {
                    message.IdLanguage = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetChatTemplates message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetChatTemplates
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetChatTemplates.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 519, GetChatTemplates : this});
    };

    /**
     * RequestGetChatTemplates typeId
     * @member {number} typeId
     * @memberof RequestGetChatTemplates
     * @instance
     */
    RequestGetChatTemplates.prototype.typeId = 519;
    /**
     * RequestGetChatTemplates typeName
     * @member {string} typeName
     * @memberof RequestGetChatTemplates
     * @instance
     */
    RequestGetChatTemplates.prototype.typeName = "GetChatTemplates";

    return RequestGetChatTemplates;
})();

export const RequestUpdateChatTemplate = $root.RequestUpdateChatTemplate = (() => {

    /**
     * Properties of a RequestUpdateChatTemplate.
     * @exports IRequestUpdateChatTemplate
     * @property {ActionType} Action RequestUpdateChatTemplate Action
     * @property {ChatTemplate} [Template] RequestUpdateChatTemplate Template
     */

    /**
     * Constructs a new RequestUpdateChatTemplate.
     * @exports RequestUpdateChatTemplate
     * @classdesc Represents a RequestUpdateChatTemplate.
     * @constructor
     * @param {Partial<RequestUpdateChatTemplate>} [properties] Properties to set
     */
    function RequestUpdateChatTemplate(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestUpdateChatTemplate Action.
     * @member {ActionType} Action
     * @memberof RequestUpdateChatTemplate
     * @instance
     */

    /**
     * RequestUpdateChatTemplate Template.
     * @member {ChatTemplate} Template
     * @memberof RequestUpdateChatTemplate
     * @instance
     */

    /**
     * Encodes the specified RequestUpdateChatTemplate message. Does not implicitly {@link RequestUpdateChatTemplate.verify|verify} messages.
     * @function encode
     * @memberof RequestUpdateChatTemplate
     * @static
     * @param {RequestUpdateChatTemplate} message RequestUpdateChatTemplate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestUpdateChatTemplate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.Action);
        if (message.Template != null && Object.hasOwnProperty.call(message, "Template"))
            $root.ChatTemplate.encode(message.Template, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestUpdateChatTemplate message from the specified reader or buffer.
     * @function decode
     * @memberof RequestUpdateChatTemplate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestUpdateChatTemplate} RequestUpdateChatTemplate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestUpdateChatTemplate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestUpdateChatTemplate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.Action = reader.int32();
                    break;
                }
            case 2: {
                    message.Template = $root.ChatTemplate.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestUpdateChatTemplate message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestUpdateChatTemplate
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestUpdateChatTemplate.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 521, UpdateTemplate : this});
    };

    /**
     * RequestUpdateChatTemplate typeId
     * @member {number} typeId
     * @memberof RequestUpdateChatTemplate
     * @instance
     */
    RequestUpdateChatTemplate.prototype.typeId = 521;
    /**
     * RequestUpdateChatTemplate typeName
     * @member {string} typeName
     * @memberof RequestUpdateChatTemplate
     * @instance
     */
    RequestUpdateChatTemplate.prototype.typeName = "UpdateTemplate";

    return RequestUpdateChatTemplate;
})();

export const ResponseGetChatTemplates = $root.ResponseGetChatTemplates = (() => {

    /**
     * Properties of a ResponseGetChatTemplates.
     * @exports IResponseGetChatTemplates
     * @property {Array.<ChatTemplate>} [Templates] ResponseGetChatTemplates Templates
     */

    /**
     * Constructs a new ResponseGetChatTemplates.
     * @exports ResponseGetChatTemplates
     * @classdesc Represents a ResponseGetChatTemplates.
     * @constructor
     * @param {Partial<ResponseGetChatTemplates>} [properties] Properties to set
     */
    function ResponseGetChatTemplates(properties) {
        this.Templates = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseGetChatTemplates Templates.
     * @member {Array.<ChatTemplate>} Templates
     * @memberof ResponseGetChatTemplates
     * @instance
     */
    ResponseGetChatTemplates.prototype.Templates = $util.emptyArray;

    /**
     * Encodes the specified ResponseGetChatTemplates message. Does not implicitly {@link ResponseGetChatTemplates.verify|verify} messages.
     * @function encode
     * @memberof ResponseGetChatTemplates
     * @static
     * @param {ResponseGetChatTemplates} message ResponseGetChatTemplates message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseGetChatTemplates.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Templates != null && message.Templates.length)
            for (let i = 0; i < message.Templates.length; ++i)
                $root.ChatTemplate.encode(message.Templates[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseGetChatTemplates message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseGetChatTemplates
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseGetChatTemplates} ResponseGetChatTemplates
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseGetChatTemplates.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseGetChatTemplates();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Templates && message.Templates.length))
                        message.Templates = [];
                    message.Templates.push($root.ChatTemplate.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseGetChatTemplates message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseGetChatTemplates
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseGetChatTemplates.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 256, ChatTemplates : this});
    };

    /**
     * ResponseGetChatTemplates typeId
     * @member {number} typeId
     * @memberof ResponseGetChatTemplates
     * @instance
     */
    ResponseGetChatTemplates.prototype.typeId = 256;
    /**
     * ResponseGetChatTemplates typeName
     * @member {string} typeName
     * @memberof ResponseGetChatTemplates
     * @instance
     */
    ResponseGetChatTemplates.prototype.typeName = "ChatTemplates";

    return ResponseGetChatTemplates;
})();

export const RequestGetMeetingDetails = $root.RequestGetMeetingDetails = (() => {

    /**
     * Properties of a RequestGetMeetingDetails.
     * @exports IRequestGetMeetingDetails
     * @property {string} [OrganizerExtension] RequestGetMeetingDetails OrganizerExtension
     * @property {DateTime} [StartDate] RequestGetMeetingDetails StartDate
     * @property {DateTime} [EndDate] RequestGetMeetingDetails EndDate
     */

    /**
     * Constructs a new RequestGetMeetingDetails.
     * @exports RequestGetMeetingDetails
     * @classdesc Represents a RequestGetMeetingDetails.
     * @constructor
     * @param {Partial<RequestGetMeetingDetails>} [properties] Properties to set
     */
    function RequestGetMeetingDetails(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetMeetingDetails OrganizerExtension.
     * @member {string} OrganizerExtension
     * @memberof RequestGetMeetingDetails
     * @instance
     */

    /**
     * RequestGetMeetingDetails StartDate.
     * @member {DateTime} StartDate
     * @memberof RequestGetMeetingDetails
     * @instance
     */

    /**
     * RequestGetMeetingDetails EndDate.
     * @member {DateTime} EndDate
     * @memberof RequestGetMeetingDetails
     * @instance
     */

    /**
     * Encodes the specified RequestGetMeetingDetails message. Does not implicitly {@link RequestGetMeetingDetails.verify|verify} messages.
     * @function encode
     * @memberof RequestGetMeetingDetails
     * @static
     * @param {RequestGetMeetingDetails} message RequestGetMeetingDetails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetMeetingDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.OrganizerExtension != null && Object.hasOwnProperty.call(message, "OrganizerExtension"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.OrganizerExtension);
        if (message.StartDate != null && Object.hasOwnProperty.call(message, "StartDate"))
            $root.DateTime.encode(message.StartDate, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        if (message.EndDate != null && Object.hasOwnProperty.call(message, "EndDate"))
            $root.DateTime.encode(message.EndDate, writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a RequestGetMeetingDetails message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetMeetingDetails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetMeetingDetails} RequestGetMeetingDetails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetMeetingDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetMeetingDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.OrganizerExtension = reader.string();
                    break;
                }
            case 2: {
                    message.StartDate = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            case 3: {
                    message.EndDate = $root.DateTime.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetMeetingDetails message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetMeetingDetails
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetMeetingDetails.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 523, GetMeetingDetails : this});
    };

    /**
     * RequestGetMeetingDetails typeId
     * @member {number} typeId
     * @memberof RequestGetMeetingDetails
     * @instance
     */
    RequestGetMeetingDetails.prototype.typeId = 523;
    /**
     * RequestGetMeetingDetails typeName
     * @member {string} typeName
     * @memberof RequestGetMeetingDetails
     * @instance
     */
    RequestGetMeetingDetails.prototype.typeName = "GetMeetingDetails";

    return RequestGetMeetingDetails;
})();

export const ResponseMeetingDetails = $root.ResponseMeetingDetails = (() => {

    /**
     * Properties of a ResponseMeetingDetails.
     * @exports IResponseMeetingDetails
     * @property {Array.<RequestPutWebMeetingReports2>} [MeetingDetails] ResponseMeetingDetails MeetingDetails
     */

    /**
     * Constructs a new ResponseMeetingDetails.
     * @exports ResponseMeetingDetails
     * @classdesc Represents a ResponseMeetingDetails.
     * @constructor
     * @param {Partial<ResponseMeetingDetails>} [properties] Properties to set
     */
    function ResponseMeetingDetails(properties) {
        this.MeetingDetails = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseMeetingDetails MeetingDetails.
     * @member {Array.<RequestPutWebMeetingReports2>} MeetingDetails
     * @memberof ResponseMeetingDetails
     * @instance
     */
    ResponseMeetingDetails.prototype.MeetingDetails = $util.emptyArray;

    /**
     * Encodes the specified ResponseMeetingDetails message. Does not implicitly {@link ResponseMeetingDetails.verify|verify} messages.
     * @function encode
     * @memberof ResponseMeetingDetails
     * @static
     * @param {ResponseMeetingDetails} message ResponseMeetingDetails message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseMeetingDetails.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.MeetingDetails != null && message.MeetingDetails.length)
            for (let i = 0; i < message.MeetingDetails.length; ++i)
                $root.RequestPutWebMeetingReports2.encode(message.MeetingDetails[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseMeetingDetails message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseMeetingDetails
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseMeetingDetails} ResponseMeetingDetails
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseMeetingDetails.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseMeetingDetails();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.MeetingDetails && message.MeetingDetails.length))
                        message.MeetingDetails = [];
                    message.MeetingDetails.push($root.RequestPutWebMeetingReports2.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseMeetingDetails message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseMeetingDetails
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseMeetingDetails.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 257, MeetingDetails : this});
    };

    /**
     * ResponseMeetingDetails typeId
     * @member {number} typeId
     * @memberof ResponseMeetingDetails
     * @instance
     */
    ResponseMeetingDetails.prototype.typeId = 257;
    /**
     * ResponseMeetingDetails typeName
     * @member {string} typeName
     * @memberof ResponseMeetingDetails
     * @instance
     */
    ResponseMeetingDetails.prototype.typeName = "MeetingDetails";

    return ResponseMeetingDetails;
})();

export const RequestGetCrmContacts = $root.RequestGetCrmContacts = (() => {

    /**
     * Properties of a RequestGetCrmContacts.
     * @exports IRequestGetCrmContacts
     * @property {number} [PageNumber] RequestGetCrmContacts PageNumber
     * @property {number} [PageSize] RequestGetCrmContacts PageSize
     */

    /**
     * Constructs a new RequestGetCrmContacts.
     * @exports RequestGetCrmContacts
     * @classdesc Represents a RequestGetCrmContacts.
     * @constructor
     * @param {Partial<RequestGetCrmContacts>} [properties] Properties to set
     */
    function RequestGetCrmContacts(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestGetCrmContacts PageNumber.
     * @member {number} PageNumber
     * @memberof RequestGetCrmContacts
     * @instance
     */

    /**
     * RequestGetCrmContacts PageSize.
     * @member {number} PageSize
     * @memberof RequestGetCrmContacts
     * @instance
     */

    /**
     * Encodes the specified RequestGetCrmContacts message. Does not implicitly {@link RequestGetCrmContacts.verify|verify} messages.
     * @function encode
     * @memberof RequestGetCrmContacts
     * @static
     * @param {RequestGetCrmContacts} message RequestGetCrmContacts message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestGetCrmContacts.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.PageNumber != null && Object.hasOwnProperty.call(message, "PageNumber"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.PageNumber);
        if (message.PageSize != null && Object.hasOwnProperty.call(message, "PageSize"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.PageSize);
        return writer;
    };

    /**
     * Decodes a RequestGetCrmContacts message from the specified reader or buffer.
     * @function decode
     * @memberof RequestGetCrmContacts
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestGetCrmContacts} RequestGetCrmContacts
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestGetCrmContacts.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestGetCrmContacts();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.PageNumber = reader.int32();
                    break;
                }
            case 2: {
                    message.PageSize = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestGetCrmContacts message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestGetCrmContacts
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestGetCrmContacts.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 527, GetCrmContacts : this});
    };

    /**
     * RequestGetCrmContacts typeId
     * @member {number} typeId
     * @memberof RequestGetCrmContacts
     * @instance
     */
    RequestGetCrmContacts.prototype.typeId = 527;
    /**
     * RequestGetCrmContacts typeName
     * @member {string} typeName
     * @memberof RequestGetCrmContacts
     * @instance
     */
    RequestGetCrmContacts.prototype.typeName = "GetCrmContacts";

    return RequestGetCrmContacts;
})();

export const ResponseCrmContacts = $root.ResponseCrmContacts = (() => {

    /**
     * Properties of a ResponseCrmContacts.
     * @exports IResponseCrmContacts
     * @property {number} [PageNumber] ResponseCrmContacts PageNumber
     * @property {number} [PageSize] ResponseCrmContacts PageSize
     * @property {Array.<Contact>} [Entries] ResponseCrmContacts Entries
     */

    /**
     * Constructs a new ResponseCrmContacts.
     * @exports ResponseCrmContacts
     * @classdesc Represents a ResponseCrmContacts.
     * @constructor
     * @param {Partial<ResponseCrmContacts>} [properties] Properties to set
     */
    function ResponseCrmContacts(properties) {
        this.Entries = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseCrmContacts PageNumber.
     * @member {number} PageNumber
     * @memberof ResponseCrmContacts
     * @instance
     */

    /**
     * ResponseCrmContacts PageSize.
     * @member {number} PageSize
     * @memberof ResponseCrmContacts
     * @instance
     */

    /**
     * ResponseCrmContacts Entries.
     * @member {Array.<Contact>} Entries
     * @memberof ResponseCrmContacts
     * @instance
     */
    ResponseCrmContacts.prototype.Entries = $util.emptyArray;

    /**
     * Encodes the specified ResponseCrmContacts message. Does not implicitly {@link ResponseCrmContacts.verify|verify} messages.
     * @function encode
     * @memberof ResponseCrmContacts
     * @static
     * @param {ResponseCrmContacts} message ResponseCrmContacts message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseCrmContacts.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.PageNumber != null && Object.hasOwnProperty.call(message, "PageNumber"))
            writer.uint32(/* id 1, wireType 0 =*/8).int32(message.PageNumber);
        if (message.PageSize != null && Object.hasOwnProperty.call(message, "PageSize"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.PageSize);
        if (message.Entries != null && message.Entries.length)
            for (let i = 0; i < message.Entries.length; ++i)
                $root.Contact.encode(message.Entries[i], writer.uint32(/* id 3, wireType 2 =*/26).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseCrmContacts message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseCrmContacts
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseCrmContacts} ResponseCrmContacts
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseCrmContacts.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseCrmContacts();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.PageNumber = reader.int32();
                    break;
                }
            case 2: {
                    message.PageSize = reader.int32();
                    break;
                }
            case 3: {
                    if (!(message.Entries && message.Entries.length))
                        message.Entries = [];
                    message.Entries.push($root.Contact.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseCrmContacts message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseCrmContacts
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseCrmContacts.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 259, CrmContactList : this});
    };

    /**
     * ResponseCrmContacts typeId
     * @member {number} typeId
     * @memberof ResponseCrmContacts
     * @instance
     */
    ResponseCrmContacts.prototype.typeId = 259;
    /**
     * ResponseCrmContacts typeName
     * @member {string} typeName
     * @memberof ResponseCrmContacts
     * @instance
     */
    ResponseCrmContacts.prototype.typeName = "CrmContactList";

    return ResponseCrmContacts;
})();

export const RequestSearchCrmContacts = $root.RequestSearchCrmContacts = (() => {

    /**
     * Properties of a RequestSearchCrmContacts.
     * @exports IRequestSearchCrmContacts
     * @property {string} [InputString] RequestSearchCrmContacts InputString
     * @property {number} [MaxContactCount] RequestSearchCrmContacts MaxContactCount
     */

    /**
     * Constructs a new RequestSearchCrmContacts.
     * @exports RequestSearchCrmContacts
     * @classdesc Represents a RequestSearchCrmContacts.
     * @constructor
     * @param {Partial<RequestSearchCrmContacts>} [properties] Properties to set
     */
    function RequestSearchCrmContacts(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * RequestSearchCrmContacts InputString.
     * @member {string} InputString
     * @memberof RequestSearchCrmContacts
     * @instance
     */

    /**
     * RequestSearchCrmContacts MaxContactCount.
     * @member {number} MaxContactCount
     * @memberof RequestSearchCrmContacts
     * @instance
     */

    /**
     * Encodes the specified RequestSearchCrmContacts message. Does not implicitly {@link RequestSearchCrmContacts.verify|verify} messages.
     * @function encode
     * @memberof RequestSearchCrmContacts
     * @static
     * @param {RequestSearchCrmContacts} message RequestSearchCrmContacts message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    RequestSearchCrmContacts.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.InputString != null && Object.hasOwnProperty.call(message, "InputString"))
            writer.uint32(/* id 1, wireType 2 =*/10).string(message.InputString);
        if (message.MaxContactCount != null && Object.hasOwnProperty.call(message, "MaxContactCount"))
            writer.uint32(/* id 2, wireType 0 =*/16).int32(message.MaxContactCount);
        return writer;
    };

    /**
     * Decodes a RequestSearchCrmContacts message from the specified reader or buffer.
     * @function decode
     * @memberof RequestSearchCrmContacts
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {RequestSearchCrmContacts} RequestSearchCrmContacts
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    RequestSearchCrmContacts.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.RequestSearchCrmContacts();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.InputString = reader.string();
                    break;
                }
            case 2: {
                    message.MaxContactCount = reader.int32();
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a RequestSearchCrmContacts message to GenericMessage
     * @function toGenericMessage
     * @memberof RequestSearchCrmContacts
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    RequestSearchCrmContacts.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 528, SearchCrmContacts : this});
    };

    /**
     * RequestSearchCrmContacts typeId
     * @member {number} typeId
     * @memberof RequestSearchCrmContacts
     * @instance
     */
    RequestSearchCrmContacts.prototype.typeId = 528;
    /**
     * RequestSearchCrmContacts typeName
     * @member {string} typeName
     * @memberof RequestSearchCrmContacts
     * @instance
     */
    RequestSearchCrmContacts.prototype.typeName = "SearchCrmContacts";

    return RequestSearchCrmContacts;
})();

export const ResponseSearchCrmContacts = $root.ResponseSearchCrmContacts = (() => {

    /**
     * Properties of a ResponseSearchCrmContacts.
     * @exports IResponseSearchCrmContacts
     * @property {Array.<Contact>} [Entries] ResponseSearchCrmContacts Entries
     */

    /**
     * Constructs a new ResponseSearchCrmContacts.
     * @exports ResponseSearchCrmContacts
     * @classdesc Represents a ResponseSearchCrmContacts.
     * @constructor
     * @param {Partial<ResponseSearchCrmContacts>} [properties] Properties to set
     */
    function ResponseSearchCrmContacts(properties) {
        this.Entries = [];
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ResponseSearchCrmContacts Entries.
     * @member {Array.<Contact>} Entries
     * @memberof ResponseSearchCrmContacts
     * @instance
     */
    ResponseSearchCrmContacts.prototype.Entries = $util.emptyArray;

    /**
     * Encodes the specified ResponseSearchCrmContacts message. Does not implicitly {@link ResponseSearchCrmContacts.verify|verify} messages.
     * @function encode
     * @memberof ResponseSearchCrmContacts
     * @static
     * @param {ResponseSearchCrmContacts} message ResponseSearchCrmContacts message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ResponseSearchCrmContacts.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.Entries != null && message.Entries.length)
            for (let i = 0; i < message.Entries.length; ++i)
                $root.Contact.encode(message.Entries[i], writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ResponseSearchCrmContacts message from the specified reader or buffer.
     * @function decode
     * @memberof ResponseSearchCrmContacts
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ResponseSearchCrmContacts} ResponseSearchCrmContacts
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ResponseSearchCrmContacts.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ResponseSearchCrmContacts();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    if (!(message.Entries && message.Entries.length))
                        message.Entries = [];
                    message.Entries.push($root.Contact.decode(reader, reader.uint32()));
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    /**
     * Converts a ResponseSearchCrmContacts message to GenericMessage
     * @function toGenericMessage
     * @memberof ResponseSearchCrmContacts
     * @instance
     * @returns {GenericMessage} GenericMessage
     */
    ResponseSearchCrmContacts.prototype.toGenericMessage = function toGenericMessage() {
        return new $root.GenericMessage({MessageId: 260, FoundCrmContacts : this});
    };

    /**
     * ResponseSearchCrmContacts typeId
     * @member {number} typeId
     * @memberof ResponseSearchCrmContacts
     * @instance
     */
    ResponseSearchCrmContacts.prototype.typeId = 260;
    /**
     * ResponseSearchCrmContacts typeName
     * @member {string} typeName
     * @memberof ResponseSearchCrmContacts
     * @instance
     */
    ResponseSearchCrmContacts.prototype.typeName = "FoundCrmContacts";

    return ResponseSearchCrmContacts;
})();

export const GenericMessage = $root.GenericMessage = (() => {

    /**
     * Properties of a GenericMessage.
     * @exports IGenericMessage
     * @property {number} MessageId GenericMessage MessageId
     * @property {Login} [LoginRequest] GenericMessage LoginRequest
     * @property {Logout} [LogoutRequest] GenericMessage LogoutRequest
     * @property {RequestMyInfo} [GetMyInfo] GenericMessage GetMyInfo
     * @property {RequestChangeStatus} [ChangeStatus] GenericMessage ChangeStatus
     * @property {RequestLookupContact} [LookupContact] GenericMessage LookupContact
     * @property {RequestGetFile} [GetFile] GenericMessage GetFile
     * @property {RequestCallHistory} [GetCallHistory] GenericMessage GetCallHistory
     * @property {RequestCallHistoryCount} [GetCallHistoryCount] GenericMessage GetCallHistoryCount
     * @property {RequestUpdateFwdProfile} [UpdateFwdProfiles] GenericMessage UpdateFwdProfiles
     * @property {RequestGetExtensions} [GetExtensions] GenericMessage GetExtensions
     * @property {RequestSendChatMessage} [SendChatMessage] GenericMessage SendChatMessage
     * @property {RequestGetMyMessages} [GetMyMessages] GenericMessage GetMyMessages
     * @property {RequestSetChatReceived} [MessagesReceived] GenericMessage MessagesReceived
     * @property {RequestPutFile} [PutFile] GenericMessage PutFile
     * @property {RequestSetVoiceMailHeard} [SetVMRead] GenericMessage SetVMRead
     * @property {RequestDropCall} [DropCall] GenericMessage DropCall
     * @property {RequestDivertCall} [DivertCall] GenericMessage DivertCall
     * @property {RequestPickupCall} [PickupCall] GenericMessage PickupCall
     * @property {RequestTransferCall} [TransferCall] GenericMessage TransferCall
     * @property {RequestMakeCall} [MakeCall] GenericMessage MakeCall
     * @property {RequestUpdateContact} [UpdateContact] GenericMessage UpdateContact
     * @property {RequestChangeConference} [ChangeConference] GenericMessage ChangeConference
     * @property {RequestGetSystemParameters} [GetSystemParameters] GenericMessage GetSystemParameters
     * @property {RequestBargeInCall} [BargeInCall] GenericMessage BargeInCall
     * @property {RequestConnectionAccessRights} [GetAccessRights] GenericMessage GetAccessRights
     * @property {RequestResetMyMissedCalls} [ResetMyMissedCalls] GenericMessage ResetMyMissedCalls
     * @property {RequestDeleteVoiceMail} [DeleteVM] GenericMessage DeleteVM
     * @property {RequestPlayVoiceMail} [PlayVM] GenericMessage PlayVM
     * @property {RequestGetFolder} [GetFolderRequest] GenericMessage GetFolderRequest
     * @property {RequestDeleteFile} [DeleteFile] GenericMessage DeleteFile
     * @property {RequestPlayRecording} [PlayRec] GenericMessage PlayRec
     * @property {RequestServerTime} [ServerTimeRequest] GenericMessage ServerTimeRequest
     * @property {RequestSetQueueStatus} [SetQueueStatus] GenericMessage SetQueueStatus
     * @property {RequestUpsertScheduledConference} [ScheduleConferenceRequest] GenericMessage ScheduleConferenceRequest
     * @property {RequestGetConferenceSchedule} [GetSchedule] GenericMessage GetSchedule
     * @property {RequestCreateConferenceScheduleTemplate} [CreateConferenceScheduleTemplate] GenericMessage CreateConferenceScheduleTemplate
     * @property {RequestJoinCallParticipantsToConference} [JoinCallParticipantsToConference] GenericMessage JoinCallParticipantsToConference
     * @property {RequestRecordCall} [RecordCall] GenericMessage RecordCall
     * @property {RequestMyChatParties} [GetMyChatParties] GenericMessage GetMyChatParties
     * @property {RequestDeleteChat} [DeleteChat] GenericMessage DeleteChat
     * @property {RequestGetMyRights} [GetMyRights] GenericMessage GetMyRights
     * @property {RequestGetGreetings} [GetGreetings] GenericMessage GetGreetings
     * @property {RequestSetGreetings} [SetGreetings] GenericMessage SetGreetings
     * @property {RequestPlayVMGreetingOnPhone} [PlayVMGreetingOnPhone] GenericMessage PlayVMGreetingOnPhone
     * @property {RequestRecordVMGreetingFromPhone} [RecordVMGreetingFromPhone] GenericMessage RecordVMGreetingFromPhone
     * @property {RequestQueueCalls} [GetQueueCalls] GenericMessage GetQueueCalls
     * @property {RequestQueueCallsCount} [GetQueueCallsCount] GenericMessage GetQueueCallsCount
     * @property {RequestSetQueueCallData} [SetQueueCallData] GenericMessage SetQueueCallData
     * @property {RequestDeleteCallHistory} [DeleteCallHistory] GenericMessage DeleteCallHistory
     * @property {RequestSetQueueCallDataBulk} [SetQueueCallDataBulk] GenericMessage SetQueueCallDataBulk
     * @property {RequestUpdateWebRtcInboundRules} [UpdateWebRtcInboundRules] GenericMessage UpdateWebRtcInboundRules
     * @property {RequestWelcomeEmail} [SendWelcomeEmail] GenericMessage SendWelcomeEmail
     * @property {RequestAutoAnswerConnection} [ConvertToAutoAnswer] GenericMessage ConvertToAutoAnswer
     * @property {WebMeetingState} [UpsertWebMeeting] GenericMessage UpsertWebMeeting
     * @property {RequestGetWebMeetings} [GetWebMeetings] GenericMessage GetWebMeetings
     * @property {RequestPutWebMeetingReports} [PutWebMeetingReports] GenericMessage PutWebMeetingReports
     * @property {RequestWebMeetingPrivateRoom} [CreatePrivateRoom] GenericMessage CreatePrivateRoom
     * @property {RequestWebMeetingError} [SendError] GenericMessage SendError
     * @property {RequestWmReinviteOrganizer} [ReinviteOrganizer] GenericMessage ReinviteOrganizer
     * @property {RequestSetSharedBlfs} [SetSharedBlfs] GenericMessage SetSharedBlfs
     * @property {RequestGetMyLastMessages} [GetMyLastMessages] GenericMessage GetMyLastMessages
     * @property {RequestRegisterWebRTCEndpoint} [registerWebRTC] GenericMessage registerWebRTC
     * @property {RequestWebRTCChangeSDPState} [ChangeSDPState] GenericMessage ChangeSDPState
     * @property {RequestUnreadMessagesCount} [GetUnreadMessagesCount] GenericMessage GetUnreadMessagesCount
     * @property {RequestJoinCalls} [JoinCalls] GenericMessage JoinCalls
     * @property {RequestControlCallRecording} [RecordingControl] GenericMessage RecordingControl
     * @property {RequestChangeWebClientPassword} [ChangeWCPassword] GenericMessage ChangeWCPassword
     * @property {RequestUpdateFavorites} [UpdateFavorites] GenericMessage UpdateFavorites
     * @property {RequestContactsByRef} [GetContactsByRef] GenericMessage GetContactsByRef
     * @property {RequestCleanExtension} [CleanExtension] GenericMessage CleanExtension
     * @property {RequestAssignExtension} [AssignExtension] GenericMessage AssignExtension
     * @property {RequestSetWakeupCall} [SetWakeupCall] GenericMessage SetWakeupCall
     * @property {RequestDeleteWakeupCall} [DeleteWakeupCall] GenericMessage DeleteWakeupCall
     * @property {RequestGetWakeupCalls} [GetWakeupCalls] GenericMessage GetWakeupCalls
     * @property {RequestOneTimeLogin} [GetOneTimeLogin] GenericMessage GetOneTimeLogin
     * @property {RequestUpdatePushSubscriptions} [UpdatePushSubscriptions] GenericMessage UpdatePushSubscriptions
     * @property {PushSubscriptionData} [RefreshSubscription] GenericMessage RefreshSubscription
     * @property {RequestSendChatFile} [SendChatFile] GenericMessage SendChatFile
     * @property {ChatTyping} [UserTypingChat] GenericMessage UserTypingChat
     * @property {ConferenceState} [UpdateWebMeetingParticipants] GenericMessage UpdateWebMeetingParticipants
     * @property {RequestBlacklistAnonymous} [BlockAnonymous] GenericMessage BlockAnonymous
     * @property {RequestDisconnectAnonymous} [DisconnectAnonymous] GenericMessage DisconnectAnonymous
     * @property {RequestGetRecordings} [GetRecordings] GenericMessage GetRecordings
     * @property {RequestDeleteRecording} [DeleteRecording] GenericMessage DeleteRecording
     * @property {RequestUpdateConversation} [UpdateConversation] GenericMessage UpdateConversation
     * @property {RequestTransferChatTo} [TransferChatTo] GenericMessage TransferChatTo
     * @property {RequestAgentTakesOverChat} [AgentTakesOverChat] GenericMessage AgentTakesOverChat
     * @property {RequestCreateConversation} [CreateConversation] GenericMessage CreateConversation
     * @property {RequestSendChatMessageToConversation} [SendMessageToConversation] GenericMessage SendMessageToConversation
     * @property {RequestChatRating} [SendChatRating] GenericMessage SendChatRating
     * @property {RequestUpdateUserSettings} [UpdateUserSettings] GenericMessage UpdateUserSettings
     * @property {RequestExactMatch} [ExactMatchRequest] GenericMessage ExactMatchRequest
     * @property {RequestGetContactById} [GetContactById] GenericMessage GetContactById
     * @property {RequestStartChatMonitoring} [StartChatMonitoring] GenericMessage StartChatMonitoring
     * @property {RequestStopChatMonitoring} [StopChatMonitoring] GenericMessage StopChatMonitoring
     * @property {RequestWhisperMessage} [WhisperMessage] GenericMessage WhisperMessage
     * @property {RequestCloseConversation} [CloseConversation] GenericMessage CloseConversation
     * @property {RequestProviderMapping} [ProviderMapping] GenericMessage ProviderMapping
     * @property {RequestAvailableProviders} [GetMyProviders] GenericMessage GetMyProviders
     * @property {RequestMyQueues} [GetMyQueues] GenericMessage GetMyQueues
     * @property {RequestGetQMLastMessages} [GetQmLastMessages] GenericMessage GetQmLastMessages
     * @property {RequestGetConversationEvents} [GetConversationEvents] GenericMessage GetConversationEvents
     * @property {RequestGetQMConversationEvents} [GetQMConversationEvents] GenericMessage GetQMConversationEvents
     * @property {RequestRefreshConversation} [RefreshConversation] GenericMessage RefreshConversation
     * @property {RequestUpdateMeetingDocuments} [UpdateMeetingDocuments] GenericMessage UpdateMeetingDocuments
     * @property {WmPollings} [WebMeetingPollings] GenericMessage WebMeetingPollings
     * @property {RequestForwardChatMessage} [ForwardMessage] GenericMessage ForwardMessage
     * @property {RequestPutWebMeetingReports2} [PutWebMeetingReports2] GenericMessage PutWebMeetingReports2
     * @property {RequestGetConversations} [GetConversations] GenericMessage GetConversations
     * @property {RequestCloseMeeting} [CloseMeeting] GenericMessage CloseMeeting
     * @property {RequestSetOfficeHoursMode} [SetOfficeHoursMode] GenericMessage SetOfficeHoursMode
     * @property {RequestCheckIpBlocked} [RqCheckIpBlocked] GenericMessage RqCheckIpBlocked
     * @property {RequestSetLanguage} [SetLanguage] GenericMessage SetLanguage
     * @property {RequestPreviousInteractions} [GetPreviousInteractions] GenericMessage GetPreviousInteractions
     * @property {RequestUpdateGroup} [UpdateGroup] GenericMessage UpdateGroup
     * @property {RequestGetChatTemplateCategories} [GetChatTemplateCategories] GenericMessage GetChatTemplateCategories
     * @property {RequestGetChatTemplateLanguages} [GetChatTemplateLanguages] GenericMessage GetChatTemplateLanguages
     * @property {RequestGetChatTemplates} [GetChatTemplates] GenericMessage GetChatTemplates
     * @property {RequestUpdateCategory} [UpdateCategory] GenericMessage UpdateCategory
     * @property {RequestUpdateChatTemplate} [UpdateTemplate] GenericMessage UpdateTemplate
     * @property {RequestBulkUpdateCategory} [BulkUpdateCategory] GenericMessage BulkUpdateCategory
     * @property {RequestGetMeetingDetails} [GetMeetingDetails] GenericMessage GetMeetingDetails
     * @property {RequestSetMessageEmoji} [SetMessageEmoji] GenericMessage SetMessageEmoji
     * @property {RequestEditChatMessage} [EditChatMessage] GenericMessage EditChatMessage
     * @property {RequestGetMessages} [GetMessagesByIds] GenericMessage GetMessagesByIds
     * @property {RequestGetCrmContacts} [GetCrmContacts] GenericMessage GetCrmContacts
     * @property {RequestSearchCrmContacts} [SearchCrmContacts] GenericMessage SearchCrmContacts
     * @property {RequestBatchExactMatch} [BatchExactMatch] GenericMessage BatchExactMatch
     * @property {RequestCallReport} [CallReport] GenericMessage CallReport
     * @property {RequestMonitor} [Monitor] GenericMessage Monitor
     * @property {LoginInfo} [LoginResponse] GenericMessage LoginResponse
     * @property {MyExtensionInfo} [MyInfo] GenericMessage MyInfo
     * @property {ResponseLookup} [LookupResult] GenericMessage LookupResult
     * @property {ResponseFile} [FileResult] GenericMessage FileResult
     * @property {ResponseCallHistory} [CallHistory] GenericMessage CallHistory
     * @property {ResponseCallHistoryCount} [CallHistoryCount] GenericMessage CallHistoryCount
     * @property {ResponseGetExtensions} [Extensions] GenericMessage Extensions
     * @property {ResponseAcknowledge} [Acknowledge] GenericMessage Acknowledge
     * @property {Groups} [GroupsInfo] GenericMessage GroupsInfo
     * @property {ResponseMyMessages} [MyChatMessages] GenericMessage MyChatMessages
     * @property {ResponseSystemParameters} [SystemParameters] GenericMessage SystemParameters
     * @property {Queues} [QueuesInfo] GenericMessage QueuesInfo
     * @property {Parkings} [ParkingsInfo] GenericMessage ParkingsInfo
     * @property {IVRs} [IVRsInfo] GenericMessage IVRsInfo
     * @property {ResponseConnectionAccessRights} [ConnectionCapabilityMask] GenericMessage ConnectionCapabilityMask
     * @property {ResponseExtensionsChanged} [ExtensionsChangedEvent] GenericMessage ExtensionsChangedEvent
     * @property {ResponseGetFolder} [GetFolderResponse] GenericMessage GetFolderResponse
     * @property {ResponseContactChanged} [ContactChangedEvent] GenericMessage ContactChangedEvent
     * @property {ResponseServerTime} [ServerTimeResponse] GenericMessage ServerTimeResponse
     * @property {Conferences} [ActiveConferences] GenericMessage ActiveConferences
     * @property {ResponseMyChatParties} [MyChatParties] GenericMessage MyChatParties
     * @property {ResponseGetMyRights} [MyRights] GenericMessage MyRights
     * @property {ResponseGreetings} [Greetings] GenericMessage Greetings
     * @property {ResponseQueueCalls} [QueueCalls] GenericMessage QueueCalls
     * @property {WebMeetings} [ActiveWebMeetings] GenericMessage ActiveWebMeetings
     * @property {ResponseWebMeetingPrivateRoom} [PrivateRoom] GenericMessage PrivateRoom
     * @property {MyWebRTCEndpoint} [webRTCEndpoint] GenericMessage webRTCEndpoint
     * @property {ResponseWebRTCChangeSDPState} [ChangeSDPStateResponse] GenericMessage ChangeSDPStateResponse
     * @property {ResponseUnreadMessagesCount} [UnreadMessagesCount] GenericMessage UnreadMessagesCount
     * @property {ResponseWakeupCalls} [WakeupCalls] GenericMessage WakeupCalls
     * @property {ResponseOneTimeLogin} [OneTimeLogin] GenericMessage OneTimeLogin
     * @property {NotificationChatFileProgress} [ChatFileProgress] GenericMessage ChatFileProgress
     * @property {NotificationC2CState} [C2cState] GenericMessage C2cState
     * @property {ResponseRecordings} [ResponseRecordings] GenericMessage ResponseRecordings
     * @property {NotificationConversationRemoved} [ConversationRemoved] GenericMessage ConversationRemoved
     * @property {ResponseConversationInfo} [ConversationInfo] GenericMessage ConversationInfo
     * @property {NotificationChatTransferred} [ChatTransferred] GenericMessage ChatTransferred
     * @property {NotificationChatRating} [ChatRating] GenericMessage ChatRating
     * @property {AnonymousSessionClosed} [AnonymousClosed] GenericMessage AnonymousClosed
     * @property {ResponseExactMatch} [ExactMatchResponse] GenericMessage ExactMatchResponse
     * @property {NotificationChatMessageStatus} [DeliveryStatus] GenericMessage DeliveryStatus
     * @property {ResponseChatMonitoring} [ChatMonitoringFullState] GenericMessage ChatMonitoringFullState
     * @property {ChatMonitoringEvent} [ChatMonitoringNotification] GenericMessage ChatMonitoringNotification
     * @property {ResponseAvailableProviders} [MyProviders] GenericMessage MyProviders
     * @property {ResponseMyQueues} [MyQueues] GenericMessage MyQueues
     * @property {ResponseConversationEvents} [ConversationEvents] GenericMessage ConversationEvents
     * @property {WmBridgeParameters} [WmParams] GenericMessage WmParams
     * @property {MeetingRows} [NotificationMeetingRows] GenericMessage NotificationMeetingRows
     * @property {ResponseGetConversations} [ResponseConversations] GenericMessage ResponseConversations
     * @property {MeetingStateNotification} [MeetingState] GenericMessage MeetingState
     * @property {MeetingStateNotifications} [MeetingStates] GenericMessage MeetingStates
     * @property {ResponseCheckIpBlocked} [RespCheckIpBlocked] GenericMessage RespCheckIpBlocked
     * @property {ResponsePreviousInteractions} [PreviousInteractions] GenericMessage PreviousInteractions
     * @property {ResponseGetChatTemplateCategories} [ChatTemplateCategories] GenericMessage ChatTemplateCategories
     * @property {ResponseGetChatTemplateLanguages} [ChatTemplateLanguages] GenericMessage ChatTemplateLanguages
     * @property {ResponseGetChatTemplates} [ChatTemplates] GenericMessage ChatTemplates
     * @property {ResponseMeetingDetails} [MeetingDetails] GenericMessage MeetingDetails
     * @property {NotificationChatMessageChanged} [ChatMessageChanged] GenericMessage ChatMessageChanged
     * @property {ResponseCrmContacts} [CrmContactList] GenericMessage CrmContactList
     * @property {ResponseSearchCrmContacts} [FoundCrmContacts] GenericMessage FoundCrmContacts
     * @property {ResponseCallControl} [CallControlResponse] GenericMessage CallControlResponse
     * @property {QualityReport} [CallQualityReport] GenericMessage CallQualityReport
     * @property {ResponseBatchExactMatch} [BatchExactMatchResult] GenericMessage BatchExactMatchResult
     */

    /**
     * Constructs a new GenericMessage.
     * @exports GenericMessage
     * @classdesc Represents a GenericMessage.
     * @constructor
     * @param {Partial<GenericMessage>} [properties] Properties to set
     */
    function GenericMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * GenericMessage MessageId.
     * @member {number} MessageId
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage LoginRequest.
     * @member {Login} LoginRequest
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage LogoutRequest.
     * @member {Logout} LogoutRequest
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetMyInfo.
     * @member {RequestMyInfo} GetMyInfo
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChangeStatus.
     * @member {RequestChangeStatus} ChangeStatus
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage LookupContact.
     * @member {RequestLookupContact} LookupContact
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetFile.
     * @member {RequestGetFile} GetFile
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetCallHistory.
     * @member {RequestCallHistory} GetCallHistory
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetCallHistoryCount.
     * @member {RequestCallHistoryCount} GetCallHistoryCount
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateFwdProfiles.
     * @member {RequestUpdateFwdProfile} UpdateFwdProfiles
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetExtensions.
     * @member {RequestGetExtensions} GetExtensions
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SendChatMessage.
     * @member {RequestSendChatMessage} SendChatMessage
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetMyMessages.
     * @member {RequestGetMyMessages} GetMyMessages
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MessagesReceived.
     * @member {RequestSetChatReceived} MessagesReceived
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage PutFile.
     * @member {RequestPutFile} PutFile
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetVMRead.
     * @member {RequestSetVoiceMailHeard} SetVMRead
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DropCall.
     * @member {RequestDropCall} DropCall
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DivertCall.
     * @member {RequestDivertCall} DivertCall
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage PickupCall.
     * @member {RequestPickupCall} PickupCall
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage TransferCall.
     * @member {RequestTransferCall} TransferCall
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MakeCall.
     * @member {RequestMakeCall} MakeCall
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateContact.
     * @member {RequestUpdateContact} UpdateContact
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChangeConference.
     * @member {RequestChangeConference} ChangeConference
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetSystemParameters.
     * @member {RequestGetSystemParameters} GetSystemParameters
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage BargeInCall.
     * @member {RequestBargeInCall} BargeInCall
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetAccessRights.
     * @member {RequestConnectionAccessRights} GetAccessRights
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ResetMyMissedCalls.
     * @member {RequestResetMyMissedCalls} ResetMyMissedCalls
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DeleteVM.
     * @member {RequestDeleteVoiceMail} DeleteVM
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage PlayVM.
     * @member {RequestPlayVoiceMail} PlayVM
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetFolderRequest.
     * @member {RequestGetFolder} GetFolderRequest
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DeleteFile.
     * @member {RequestDeleteFile} DeleteFile
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage PlayRec.
     * @member {RequestPlayRecording} PlayRec
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ServerTimeRequest.
     * @member {RequestServerTime} ServerTimeRequest
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetQueueStatus.
     * @member {RequestSetQueueStatus} SetQueueStatus
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ScheduleConferenceRequest.
     * @member {RequestUpsertScheduledConference} ScheduleConferenceRequest
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetSchedule.
     * @member {RequestGetConferenceSchedule} GetSchedule
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CreateConferenceScheduleTemplate.
     * @member {RequestCreateConferenceScheduleTemplate} CreateConferenceScheduleTemplate
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage JoinCallParticipantsToConference.
     * @member {RequestJoinCallParticipantsToConference} JoinCallParticipantsToConference
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage RecordCall.
     * @member {RequestRecordCall} RecordCall
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetMyChatParties.
     * @member {RequestMyChatParties} GetMyChatParties
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DeleteChat.
     * @member {RequestDeleteChat} DeleteChat
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetMyRights.
     * @member {RequestGetMyRights} GetMyRights
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetGreetings.
     * @member {RequestGetGreetings} GetGreetings
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetGreetings.
     * @member {RequestSetGreetings} SetGreetings
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage PlayVMGreetingOnPhone.
     * @member {RequestPlayVMGreetingOnPhone} PlayVMGreetingOnPhone
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage RecordVMGreetingFromPhone.
     * @member {RequestRecordVMGreetingFromPhone} RecordVMGreetingFromPhone
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetQueueCalls.
     * @member {RequestQueueCalls} GetQueueCalls
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetQueueCallsCount.
     * @member {RequestQueueCallsCount} GetQueueCallsCount
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetQueueCallData.
     * @member {RequestSetQueueCallData} SetQueueCallData
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DeleteCallHistory.
     * @member {RequestDeleteCallHistory} DeleteCallHistory
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetQueueCallDataBulk.
     * @member {RequestSetQueueCallDataBulk} SetQueueCallDataBulk
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateWebRtcInboundRules.
     * @member {RequestUpdateWebRtcInboundRules} UpdateWebRtcInboundRules
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SendWelcomeEmail.
     * @member {RequestWelcomeEmail} SendWelcomeEmail
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ConvertToAutoAnswer.
     * @member {RequestAutoAnswerConnection} ConvertToAutoAnswer
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpsertWebMeeting.
     * @member {WebMeetingState} UpsertWebMeeting
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetWebMeetings.
     * @member {RequestGetWebMeetings} GetWebMeetings
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage PutWebMeetingReports.
     * @member {RequestPutWebMeetingReports} PutWebMeetingReports
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CreatePrivateRoom.
     * @member {RequestWebMeetingPrivateRoom} CreatePrivateRoom
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SendError.
     * @member {RequestWebMeetingError} SendError
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ReinviteOrganizer.
     * @member {RequestWmReinviteOrganizer} ReinviteOrganizer
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetSharedBlfs.
     * @member {RequestSetSharedBlfs} SetSharedBlfs
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetMyLastMessages.
     * @member {RequestGetMyLastMessages} GetMyLastMessages
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage registerWebRTC.
     * @member {RequestRegisterWebRTCEndpoint} registerWebRTC
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChangeSDPState.
     * @member {RequestWebRTCChangeSDPState} ChangeSDPState
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetUnreadMessagesCount.
     * @member {RequestUnreadMessagesCount} GetUnreadMessagesCount
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage JoinCalls.
     * @member {RequestJoinCalls} JoinCalls
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage RecordingControl.
     * @member {RequestControlCallRecording} RecordingControl
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChangeWCPassword.
     * @member {RequestChangeWebClientPassword} ChangeWCPassword
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateFavorites.
     * @member {RequestUpdateFavorites} UpdateFavorites
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetContactsByRef.
     * @member {RequestContactsByRef} GetContactsByRef
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CleanExtension.
     * @member {RequestCleanExtension} CleanExtension
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage AssignExtension.
     * @member {RequestAssignExtension} AssignExtension
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetWakeupCall.
     * @member {RequestSetWakeupCall} SetWakeupCall
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DeleteWakeupCall.
     * @member {RequestDeleteWakeupCall} DeleteWakeupCall
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetWakeupCalls.
     * @member {RequestGetWakeupCalls} GetWakeupCalls
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetOneTimeLogin.
     * @member {RequestOneTimeLogin} GetOneTimeLogin
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdatePushSubscriptions.
     * @member {RequestUpdatePushSubscriptions} UpdatePushSubscriptions
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage RefreshSubscription.
     * @member {PushSubscriptionData} RefreshSubscription
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SendChatFile.
     * @member {RequestSendChatFile} SendChatFile
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UserTypingChat.
     * @member {ChatTyping} UserTypingChat
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateWebMeetingParticipants.
     * @member {ConferenceState} UpdateWebMeetingParticipants
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage BlockAnonymous.
     * @member {RequestBlacklistAnonymous} BlockAnonymous
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DisconnectAnonymous.
     * @member {RequestDisconnectAnonymous} DisconnectAnonymous
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetRecordings.
     * @member {RequestGetRecordings} GetRecordings
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DeleteRecording.
     * @member {RequestDeleteRecording} DeleteRecording
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateConversation.
     * @member {RequestUpdateConversation} UpdateConversation
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage TransferChatTo.
     * @member {RequestTransferChatTo} TransferChatTo
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage AgentTakesOverChat.
     * @member {RequestAgentTakesOverChat} AgentTakesOverChat
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CreateConversation.
     * @member {RequestCreateConversation} CreateConversation
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SendMessageToConversation.
     * @member {RequestSendChatMessageToConversation} SendMessageToConversation
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SendChatRating.
     * @member {RequestChatRating} SendChatRating
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateUserSettings.
     * @member {RequestUpdateUserSettings} UpdateUserSettings
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ExactMatchRequest.
     * @member {RequestExactMatch} ExactMatchRequest
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetContactById.
     * @member {RequestGetContactById} GetContactById
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage StartChatMonitoring.
     * @member {RequestStartChatMonitoring} StartChatMonitoring
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage StopChatMonitoring.
     * @member {RequestStopChatMonitoring} StopChatMonitoring
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage WhisperMessage.
     * @member {RequestWhisperMessage} WhisperMessage
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CloseConversation.
     * @member {RequestCloseConversation} CloseConversation
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ProviderMapping.
     * @member {RequestProviderMapping} ProviderMapping
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetMyProviders.
     * @member {RequestAvailableProviders} GetMyProviders
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetMyQueues.
     * @member {RequestMyQueues} GetMyQueues
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetQmLastMessages.
     * @member {RequestGetQMLastMessages} GetQmLastMessages
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetConversationEvents.
     * @member {RequestGetConversationEvents} GetConversationEvents
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetQMConversationEvents.
     * @member {RequestGetQMConversationEvents} GetQMConversationEvents
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage RefreshConversation.
     * @member {RequestRefreshConversation} RefreshConversation
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateMeetingDocuments.
     * @member {RequestUpdateMeetingDocuments} UpdateMeetingDocuments
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage WebMeetingPollings.
     * @member {WmPollings} WebMeetingPollings
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ForwardMessage.
     * @member {RequestForwardChatMessage} ForwardMessage
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage PutWebMeetingReports2.
     * @member {RequestPutWebMeetingReports2} PutWebMeetingReports2
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetConversations.
     * @member {RequestGetConversations} GetConversations
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CloseMeeting.
     * @member {RequestCloseMeeting} CloseMeeting
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetOfficeHoursMode.
     * @member {RequestSetOfficeHoursMode} SetOfficeHoursMode
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage RqCheckIpBlocked.
     * @member {RequestCheckIpBlocked} RqCheckIpBlocked
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetLanguage.
     * @member {RequestSetLanguage} SetLanguage
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetPreviousInteractions.
     * @member {RequestPreviousInteractions} GetPreviousInteractions
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateGroup.
     * @member {RequestUpdateGroup} UpdateGroup
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetChatTemplateCategories.
     * @member {RequestGetChatTemplateCategories} GetChatTemplateCategories
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetChatTemplateLanguages.
     * @member {RequestGetChatTemplateLanguages} GetChatTemplateLanguages
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetChatTemplates.
     * @member {RequestGetChatTemplates} GetChatTemplates
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateCategory.
     * @member {RequestUpdateCategory} UpdateCategory
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UpdateTemplate.
     * @member {RequestUpdateChatTemplate} UpdateTemplate
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage BulkUpdateCategory.
     * @member {RequestBulkUpdateCategory} BulkUpdateCategory
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetMeetingDetails.
     * @member {RequestGetMeetingDetails} GetMeetingDetails
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SetMessageEmoji.
     * @member {RequestSetMessageEmoji} SetMessageEmoji
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage EditChatMessage.
     * @member {RequestEditChatMessage} EditChatMessage
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetMessagesByIds.
     * @member {RequestGetMessages} GetMessagesByIds
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetCrmContacts.
     * @member {RequestGetCrmContacts} GetCrmContacts
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SearchCrmContacts.
     * @member {RequestSearchCrmContacts} SearchCrmContacts
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage BatchExactMatch.
     * @member {RequestBatchExactMatch} BatchExactMatch
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CallReport.
     * @member {RequestCallReport} CallReport
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage Monitor.
     * @member {RequestMonitor} Monitor
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage LoginResponse.
     * @member {LoginInfo} LoginResponse
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MyInfo.
     * @member {MyExtensionInfo} MyInfo
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage LookupResult.
     * @member {ResponseLookup} LookupResult
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage FileResult.
     * @member {ResponseFile} FileResult
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CallHistory.
     * @member {ResponseCallHistory} CallHistory
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CallHistoryCount.
     * @member {ResponseCallHistoryCount} CallHistoryCount
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage Extensions.
     * @member {ResponseGetExtensions} Extensions
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage Acknowledge.
     * @member {ResponseAcknowledge} Acknowledge
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GroupsInfo.
     * @member {Groups} GroupsInfo
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MyChatMessages.
     * @member {ResponseMyMessages} MyChatMessages
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage SystemParameters.
     * @member {ResponseSystemParameters} SystemParameters
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage QueuesInfo.
     * @member {Queues} QueuesInfo
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ParkingsInfo.
     * @member {Parkings} ParkingsInfo
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage IVRsInfo.
     * @member {IVRs} IVRsInfo
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ConnectionCapabilityMask.
     * @member {ResponseConnectionAccessRights} ConnectionCapabilityMask
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ExtensionsChangedEvent.
     * @member {ResponseExtensionsChanged} ExtensionsChangedEvent
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage GetFolderResponse.
     * @member {ResponseGetFolder} GetFolderResponse
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ContactChangedEvent.
     * @member {ResponseContactChanged} ContactChangedEvent
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ServerTimeResponse.
     * @member {ResponseServerTime} ServerTimeResponse
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ActiveConferences.
     * @member {Conferences} ActiveConferences
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MyChatParties.
     * @member {ResponseMyChatParties} MyChatParties
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MyRights.
     * @member {ResponseGetMyRights} MyRights
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage Greetings.
     * @member {ResponseGreetings} Greetings
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage QueueCalls.
     * @member {ResponseQueueCalls} QueueCalls
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ActiveWebMeetings.
     * @member {WebMeetings} ActiveWebMeetings
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage PrivateRoom.
     * @member {ResponseWebMeetingPrivateRoom} PrivateRoom
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage webRTCEndpoint.
     * @member {MyWebRTCEndpoint} webRTCEndpoint
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChangeSDPStateResponse.
     * @member {ResponseWebRTCChangeSDPState} ChangeSDPStateResponse
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage UnreadMessagesCount.
     * @member {ResponseUnreadMessagesCount} UnreadMessagesCount
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage WakeupCalls.
     * @member {ResponseWakeupCalls} WakeupCalls
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage OneTimeLogin.
     * @member {ResponseOneTimeLogin} OneTimeLogin
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChatFileProgress.
     * @member {NotificationChatFileProgress} ChatFileProgress
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage C2cState.
     * @member {NotificationC2CState} C2cState
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ResponseRecordings.
     * @member {ResponseRecordings} ResponseRecordings
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ConversationRemoved.
     * @member {NotificationConversationRemoved} ConversationRemoved
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ConversationInfo.
     * @member {ResponseConversationInfo} ConversationInfo
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChatTransferred.
     * @member {NotificationChatTransferred} ChatTransferred
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChatRating.
     * @member {NotificationChatRating} ChatRating
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage AnonymousClosed.
     * @member {AnonymousSessionClosed} AnonymousClosed
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ExactMatchResponse.
     * @member {ResponseExactMatch} ExactMatchResponse
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage DeliveryStatus.
     * @member {NotificationChatMessageStatus} DeliveryStatus
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChatMonitoringFullState.
     * @member {ResponseChatMonitoring} ChatMonitoringFullState
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChatMonitoringNotification.
     * @member {ChatMonitoringEvent} ChatMonitoringNotification
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MyProviders.
     * @member {ResponseAvailableProviders} MyProviders
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MyQueues.
     * @member {ResponseMyQueues} MyQueues
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ConversationEvents.
     * @member {ResponseConversationEvents} ConversationEvents
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage WmParams.
     * @member {WmBridgeParameters} WmParams
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage NotificationMeetingRows.
     * @member {MeetingRows} NotificationMeetingRows
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ResponseConversations.
     * @member {ResponseGetConversations} ResponseConversations
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MeetingState.
     * @member {MeetingStateNotification} MeetingState
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MeetingStates.
     * @member {MeetingStateNotifications} MeetingStates
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage RespCheckIpBlocked.
     * @member {ResponseCheckIpBlocked} RespCheckIpBlocked
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage PreviousInteractions.
     * @member {ResponsePreviousInteractions} PreviousInteractions
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChatTemplateCategories.
     * @member {ResponseGetChatTemplateCategories} ChatTemplateCategories
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChatTemplateLanguages.
     * @member {ResponseGetChatTemplateLanguages} ChatTemplateLanguages
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChatTemplates.
     * @member {ResponseGetChatTemplates} ChatTemplates
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage MeetingDetails.
     * @member {ResponseMeetingDetails} MeetingDetails
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage ChatMessageChanged.
     * @member {NotificationChatMessageChanged} ChatMessageChanged
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CrmContactList.
     * @member {ResponseCrmContacts} CrmContactList
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage FoundCrmContacts.
     * @member {ResponseSearchCrmContacts} FoundCrmContacts
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CallControlResponse.
     * @member {ResponseCallControl} CallControlResponse
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage CallQualityReport.
     * @member {QualityReport} CallQualityReport
     * @memberof GenericMessage
     * @instance
     */

    /**
     * GenericMessage BatchExactMatchResult.
     * @member {ResponseBatchExactMatch} BatchExactMatchResult
     * @memberof GenericMessage
     * @instance
     */

    /**
     * Encodes the specified GenericMessage message. Does not implicitly {@link GenericMessage.verify|verify} messages.
     * @function encode
     * @memberof GenericMessage
     * @static
     * @param {GenericMessage} message GenericMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    GenericMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        writer.uint32(/* id 1, wireType 0 =*/8).int32(message.MessageId);
        if (message.LoginRequest != null && Object.hasOwnProperty.call(message, "LoginRequest"))
            $root.Login.encode(message.LoginRequest, writer.uint32(/* id 100, wireType 2 =*/802).fork()).ldelim();
        if (message.LogoutRequest != null && Object.hasOwnProperty.call(message, "LogoutRequest"))
            $root.Logout.encode(message.LogoutRequest, writer.uint32(/* id 101, wireType 2 =*/810).fork()).ldelim();
        if (message.GetMyInfo != null && Object.hasOwnProperty.call(message, "GetMyInfo"))
            $root.RequestMyInfo.encode(message.GetMyInfo, writer.uint32(/* id 102, wireType 2 =*/818).fork()).ldelim();
        if (message.ChangeStatus != null && Object.hasOwnProperty.call(message, "ChangeStatus"))
            $root.RequestChangeStatus.encode(message.ChangeStatus, writer.uint32(/* id 103, wireType 2 =*/826).fork()).ldelim();
        if (message.LookupContact != null && Object.hasOwnProperty.call(message, "LookupContact"))
            $root.RequestLookupContact.encode(message.LookupContact, writer.uint32(/* id 104, wireType 2 =*/834).fork()).ldelim();
        if (message.GetFile != null && Object.hasOwnProperty.call(message, "GetFile"))
            $root.RequestGetFile.encode(message.GetFile, writer.uint32(/* id 105, wireType 2 =*/842).fork()).ldelim();
        if (message.GetCallHistory != null && Object.hasOwnProperty.call(message, "GetCallHistory"))
            $root.RequestCallHistory.encode(message.GetCallHistory, writer.uint32(/* id 106, wireType 2 =*/850).fork()).ldelim();
        if (message.GetCallHistoryCount != null && Object.hasOwnProperty.call(message, "GetCallHistoryCount"))
            $root.RequestCallHistoryCount.encode(message.GetCallHistoryCount, writer.uint32(/* id 107, wireType 2 =*/858).fork()).ldelim();
        if (message.UpdateFwdProfiles != null && Object.hasOwnProperty.call(message, "UpdateFwdProfiles"))
            $root.RequestUpdateFwdProfile.encode(message.UpdateFwdProfiles, writer.uint32(/* id 108, wireType 2 =*/866).fork()).ldelim();
        if (message.GetExtensions != null && Object.hasOwnProperty.call(message, "GetExtensions"))
            $root.RequestGetExtensions.encode(message.GetExtensions, writer.uint32(/* id 109, wireType 2 =*/874).fork()).ldelim();
        if (message.SendChatMessage != null && Object.hasOwnProperty.call(message, "SendChatMessage"))
            $root.RequestSendChatMessage.encode(message.SendChatMessage, writer.uint32(/* id 110, wireType 2 =*/882).fork()).ldelim();
        if (message.GetMyMessages != null && Object.hasOwnProperty.call(message, "GetMyMessages"))
            $root.RequestGetMyMessages.encode(message.GetMyMessages, writer.uint32(/* id 111, wireType 2 =*/890).fork()).ldelim();
        if (message.MessagesReceived != null && Object.hasOwnProperty.call(message, "MessagesReceived"))
            $root.RequestSetChatReceived.encode(message.MessagesReceived, writer.uint32(/* id 112, wireType 2 =*/898).fork()).ldelim();
        if (message.PutFile != null && Object.hasOwnProperty.call(message, "PutFile"))
            $root.RequestPutFile.encode(message.PutFile, writer.uint32(/* id 113, wireType 2 =*/906).fork()).ldelim();
        if (message.SetVMRead != null && Object.hasOwnProperty.call(message, "SetVMRead"))
            $root.RequestSetVoiceMailHeard.encode(message.SetVMRead, writer.uint32(/* id 114, wireType 2 =*/914).fork()).ldelim();
        if (message.DropCall != null && Object.hasOwnProperty.call(message, "DropCall"))
            $root.RequestDropCall.encode(message.DropCall, writer.uint32(/* id 115, wireType 2 =*/922).fork()).ldelim();
        if (message.DivertCall != null && Object.hasOwnProperty.call(message, "DivertCall"))
            $root.RequestDivertCall.encode(message.DivertCall, writer.uint32(/* id 116, wireType 2 =*/930).fork()).ldelim();
        if (message.PickupCall != null && Object.hasOwnProperty.call(message, "PickupCall"))
            $root.RequestPickupCall.encode(message.PickupCall, writer.uint32(/* id 117, wireType 2 =*/938).fork()).ldelim();
        if (message.TransferCall != null && Object.hasOwnProperty.call(message, "TransferCall"))
            $root.RequestTransferCall.encode(message.TransferCall, writer.uint32(/* id 118, wireType 2 =*/946).fork()).ldelim();
        if (message.MakeCall != null && Object.hasOwnProperty.call(message, "MakeCall"))
            $root.RequestMakeCall.encode(message.MakeCall, writer.uint32(/* id 119, wireType 2 =*/954).fork()).ldelim();
        if (message.UpdateContact != null && Object.hasOwnProperty.call(message, "UpdateContact"))
            $root.RequestUpdateContact.encode(message.UpdateContact, writer.uint32(/* id 120, wireType 2 =*/962).fork()).ldelim();
        if (message.ChangeConference != null && Object.hasOwnProperty.call(message, "ChangeConference"))
            $root.RequestChangeConference.encode(message.ChangeConference, writer.uint32(/* id 121, wireType 2 =*/970).fork()).ldelim();
        if (message.GetSystemParameters != null && Object.hasOwnProperty.call(message, "GetSystemParameters"))
            $root.RequestGetSystemParameters.encode(message.GetSystemParameters, writer.uint32(/* id 122, wireType 2 =*/978).fork()).ldelim();
        if (message.BargeInCall != null && Object.hasOwnProperty.call(message, "BargeInCall"))
            $root.RequestBargeInCall.encode(message.BargeInCall, writer.uint32(/* id 123, wireType 2 =*/986).fork()).ldelim();
        if (message.GetAccessRights != null && Object.hasOwnProperty.call(message, "GetAccessRights"))
            $root.RequestConnectionAccessRights.encode(message.GetAccessRights, writer.uint32(/* id 124, wireType 2 =*/994).fork()).ldelim();
        if (message.ResetMyMissedCalls != null && Object.hasOwnProperty.call(message, "ResetMyMissedCalls"))
            $root.RequestResetMyMissedCalls.encode(message.ResetMyMissedCalls, writer.uint32(/* id 125, wireType 2 =*/1002).fork()).ldelim();
        if (message.DeleteVM != null && Object.hasOwnProperty.call(message, "DeleteVM"))
            $root.RequestDeleteVoiceMail.encode(message.DeleteVM, writer.uint32(/* id 126, wireType 2 =*/1010).fork()).ldelim();
        if (message.PlayVM != null && Object.hasOwnProperty.call(message, "PlayVM"))
            $root.RequestPlayVoiceMail.encode(message.PlayVM, writer.uint32(/* id 127, wireType 2 =*/1018).fork()).ldelim();
        if (message.GetFolderRequest != null && Object.hasOwnProperty.call(message, "GetFolderRequest"))
            $root.RequestGetFolder.encode(message.GetFolderRequest, writer.uint32(/* id 128, wireType 2 =*/1026).fork()).ldelim();
        if (message.DeleteFile != null && Object.hasOwnProperty.call(message, "DeleteFile"))
            $root.RequestDeleteFile.encode(message.DeleteFile, writer.uint32(/* id 129, wireType 2 =*/1034).fork()).ldelim();
        if (message.PlayRec != null && Object.hasOwnProperty.call(message, "PlayRec"))
            $root.RequestPlayRecording.encode(message.PlayRec, writer.uint32(/* id 130, wireType 2 =*/1042).fork()).ldelim();
        if (message.ServerTimeRequest != null && Object.hasOwnProperty.call(message, "ServerTimeRequest"))
            $root.RequestServerTime.encode(message.ServerTimeRequest, writer.uint32(/* id 131, wireType 2 =*/1050).fork()).ldelim();
        if (message.SetQueueStatus != null && Object.hasOwnProperty.call(message, "SetQueueStatus"))
            $root.RequestSetQueueStatus.encode(message.SetQueueStatus, writer.uint32(/* id 132, wireType 2 =*/1058).fork()).ldelim();
        if (message.ScheduleConferenceRequest != null && Object.hasOwnProperty.call(message, "ScheduleConferenceRequest"))
            $root.RequestUpsertScheduledConference.encode(message.ScheduleConferenceRequest, writer.uint32(/* id 133, wireType 2 =*/1066).fork()).ldelim();
        if (message.GetSchedule != null && Object.hasOwnProperty.call(message, "GetSchedule"))
            $root.RequestGetConferenceSchedule.encode(message.GetSchedule, writer.uint32(/* id 134, wireType 2 =*/1074).fork()).ldelim();
        if (message.CreateConferenceScheduleTemplate != null && Object.hasOwnProperty.call(message, "CreateConferenceScheduleTemplate"))
            $root.RequestCreateConferenceScheduleTemplate.encode(message.CreateConferenceScheduleTemplate, writer.uint32(/* id 135, wireType 2 =*/1082).fork()).ldelim();
        if (message.JoinCallParticipantsToConference != null && Object.hasOwnProperty.call(message, "JoinCallParticipantsToConference"))
            $root.RequestJoinCallParticipantsToConference.encode(message.JoinCallParticipantsToConference, writer.uint32(/* id 136, wireType 2 =*/1090).fork()).ldelim();
        if (message.RecordCall != null && Object.hasOwnProperty.call(message, "RecordCall"))
            $root.RequestRecordCall.encode(message.RecordCall, writer.uint32(/* id 137, wireType 2 =*/1098).fork()).ldelim();
        if (message.GetMyChatParties != null && Object.hasOwnProperty.call(message, "GetMyChatParties"))
            $root.RequestMyChatParties.encode(message.GetMyChatParties, writer.uint32(/* id 138, wireType 2 =*/1106).fork()).ldelim();
        if (message.DeleteChat != null && Object.hasOwnProperty.call(message, "DeleteChat"))
            $root.RequestDeleteChat.encode(message.DeleteChat, writer.uint32(/* id 139, wireType 2 =*/1114).fork()).ldelim();
        if (message.GetMyRights != null && Object.hasOwnProperty.call(message, "GetMyRights"))
            $root.RequestGetMyRights.encode(message.GetMyRights, writer.uint32(/* id 140, wireType 2 =*/1122).fork()).ldelim();
        if (message.GetGreetings != null && Object.hasOwnProperty.call(message, "GetGreetings"))
            $root.RequestGetGreetings.encode(message.GetGreetings, writer.uint32(/* id 141, wireType 2 =*/1130).fork()).ldelim();
        if (message.SetGreetings != null && Object.hasOwnProperty.call(message, "SetGreetings"))
            $root.RequestSetGreetings.encode(message.SetGreetings, writer.uint32(/* id 142, wireType 2 =*/1138).fork()).ldelim();
        if (message.PlayVMGreetingOnPhone != null && Object.hasOwnProperty.call(message, "PlayVMGreetingOnPhone"))
            $root.RequestPlayVMGreetingOnPhone.encode(message.PlayVMGreetingOnPhone, writer.uint32(/* id 143, wireType 2 =*/1146).fork()).ldelim();
        if (message.RecordVMGreetingFromPhone != null && Object.hasOwnProperty.call(message, "RecordVMGreetingFromPhone"))
            $root.RequestRecordVMGreetingFromPhone.encode(message.RecordVMGreetingFromPhone, writer.uint32(/* id 144, wireType 2 =*/1154).fork()).ldelim();
        if (message.GetQueueCalls != null && Object.hasOwnProperty.call(message, "GetQueueCalls"))
            $root.RequestQueueCalls.encode(message.GetQueueCalls, writer.uint32(/* id 145, wireType 2 =*/1162).fork()).ldelim();
        if (message.GetQueueCallsCount != null && Object.hasOwnProperty.call(message, "GetQueueCallsCount"))
            $root.RequestQueueCallsCount.encode(message.GetQueueCallsCount, writer.uint32(/* id 146, wireType 2 =*/1170).fork()).ldelim();
        if (message.SetQueueCallData != null && Object.hasOwnProperty.call(message, "SetQueueCallData"))
            $root.RequestSetQueueCallData.encode(message.SetQueueCallData, writer.uint32(/* id 147, wireType 2 =*/1178).fork()).ldelim();
        if (message.DeleteCallHistory != null && Object.hasOwnProperty.call(message, "DeleteCallHistory"))
            $root.RequestDeleteCallHistory.encode(message.DeleteCallHistory, writer.uint32(/* id 148, wireType 2 =*/1186).fork()).ldelim();
        if (message.SetQueueCallDataBulk != null && Object.hasOwnProperty.call(message, "SetQueueCallDataBulk"))
            $root.RequestSetQueueCallDataBulk.encode(message.SetQueueCallDataBulk, writer.uint32(/* id 149, wireType 2 =*/1194).fork()).ldelim();
        if (message.UpdateWebRtcInboundRules != null && Object.hasOwnProperty.call(message, "UpdateWebRtcInboundRules"))
            $root.RequestUpdateWebRtcInboundRules.encode(message.UpdateWebRtcInboundRules, writer.uint32(/* id 150, wireType 2 =*/1202).fork()).ldelim();
        if (message.SendWelcomeEmail != null && Object.hasOwnProperty.call(message, "SendWelcomeEmail"))
            $root.RequestWelcomeEmail.encode(message.SendWelcomeEmail, writer.uint32(/* id 151, wireType 2 =*/1210).fork()).ldelim();
        if (message.ConvertToAutoAnswer != null && Object.hasOwnProperty.call(message, "ConvertToAutoAnswer"))
            $root.RequestAutoAnswerConnection.encode(message.ConvertToAutoAnswer, writer.uint32(/* id 152, wireType 2 =*/1218).fork()).ldelim();
        if (message.UpsertWebMeeting != null && Object.hasOwnProperty.call(message, "UpsertWebMeeting"))
            $root.WebMeetingState.encode(message.UpsertWebMeeting, writer.uint32(/* id 153, wireType 2 =*/1226).fork()).ldelim();
        if (message.GetWebMeetings != null && Object.hasOwnProperty.call(message, "GetWebMeetings"))
            $root.RequestGetWebMeetings.encode(message.GetWebMeetings, writer.uint32(/* id 154, wireType 2 =*/1234).fork()).ldelim();
        if (message.PutWebMeetingReports != null && Object.hasOwnProperty.call(message, "PutWebMeetingReports"))
            $root.RequestPutWebMeetingReports.encode(message.PutWebMeetingReports, writer.uint32(/* id 157, wireType 2 =*/1258).fork()).ldelim();
        if (message.CreatePrivateRoom != null && Object.hasOwnProperty.call(message, "CreatePrivateRoom"))
            $root.RequestWebMeetingPrivateRoom.encode(message.CreatePrivateRoom, writer.uint32(/* id 158, wireType 2 =*/1266).fork()).ldelim();
        if (message.SendError != null && Object.hasOwnProperty.call(message, "SendError"))
            $root.RequestWebMeetingError.encode(message.SendError, writer.uint32(/* id 159, wireType 2 =*/1274).fork()).ldelim();
        if (message.ReinviteOrganizer != null && Object.hasOwnProperty.call(message, "ReinviteOrganizer"))
            $root.RequestWmReinviteOrganizer.encode(message.ReinviteOrganizer, writer.uint32(/* id 160, wireType 2 =*/1282).fork()).ldelim();
        if (message.SetSharedBlfs != null && Object.hasOwnProperty.call(message, "SetSharedBlfs"))
            $root.RequestSetSharedBlfs.encode(message.SetSharedBlfs, writer.uint32(/* id 161, wireType 2 =*/1290).fork()).ldelim();
        if (message.GetMyLastMessages != null && Object.hasOwnProperty.call(message, "GetMyLastMessages"))
            $root.RequestGetMyLastMessages.encode(message.GetMyLastMessages, writer.uint32(/* id 162, wireType 2 =*/1298).fork()).ldelim();
        if (message.registerWebRTC != null && Object.hasOwnProperty.call(message, "registerWebRTC"))
            $root.RequestRegisterWebRTCEndpoint.encode(message.registerWebRTC, writer.uint32(/* id 163, wireType 2 =*/1306).fork()).ldelim();
        if (message.ChangeSDPState != null && Object.hasOwnProperty.call(message, "ChangeSDPState"))
            $root.RequestWebRTCChangeSDPState.encode(message.ChangeSDPState, writer.uint32(/* id 164, wireType 2 =*/1314).fork()).ldelim();
        if (message.GetUnreadMessagesCount != null && Object.hasOwnProperty.call(message, "GetUnreadMessagesCount"))
            $root.RequestUnreadMessagesCount.encode(message.GetUnreadMessagesCount, writer.uint32(/* id 165, wireType 2 =*/1322).fork()).ldelim();
        if (message.JoinCalls != null && Object.hasOwnProperty.call(message, "JoinCalls"))
            $root.RequestJoinCalls.encode(message.JoinCalls, writer.uint32(/* id 166, wireType 2 =*/1330).fork()).ldelim();
        if (message.RecordingControl != null && Object.hasOwnProperty.call(message, "RecordingControl"))
            $root.RequestControlCallRecording.encode(message.RecordingControl, writer.uint32(/* id 167, wireType 2 =*/1338).fork()).ldelim();
        if (message.ChangeWCPassword != null && Object.hasOwnProperty.call(message, "ChangeWCPassword"))
            $root.RequestChangeWebClientPassword.encode(message.ChangeWCPassword, writer.uint32(/* id 168, wireType 2 =*/1346).fork()).ldelim();
        if (message.UpdateFavorites != null && Object.hasOwnProperty.call(message, "UpdateFavorites"))
            $root.RequestUpdateFavorites.encode(message.UpdateFavorites, writer.uint32(/* id 169, wireType 2 =*/1354).fork()).ldelim();
        if (message.GetContactsByRef != null && Object.hasOwnProperty.call(message, "GetContactsByRef"))
            $root.RequestContactsByRef.encode(message.GetContactsByRef, writer.uint32(/* id 170, wireType 2 =*/1362).fork()).ldelim();
        if (message.CleanExtension != null && Object.hasOwnProperty.call(message, "CleanExtension"))
            $root.RequestCleanExtension.encode(message.CleanExtension, writer.uint32(/* id 171, wireType 2 =*/1370).fork()).ldelim();
        if (message.AssignExtension != null && Object.hasOwnProperty.call(message, "AssignExtension"))
            $root.RequestAssignExtension.encode(message.AssignExtension, writer.uint32(/* id 172, wireType 2 =*/1378).fork()).ldelim();
        if (message.SetWakeupCall != null && Object.hasOwnProperty.call(message, "SetWakeupCall"))
            $root.RequestSetWakeupCall.encode(message.SetWakeupCall, writer.uint32(/* id 173, wireType 2 =*/1386).fork()).ldelim();
        if (message.DeleteWakeupCall != null && Object.hasOwnProperty.call(message, "DeleteWakeupCall"))
            $root.RequestDeleteWakeupCall.encode(message.DeleteWakeupCall, writer.uint32(/* id 174, wireType 2 =*/1394).fork()).ldelim();
        if (message.GetWakeupCalls != null && Object.hasOwnProperty.call(message, "GetWakeupCalls"))
            $root.RequestGetWakeupCalls.encode(message.GetWakeupCalls, writer.uint32(/* id 175, wireType 2 =*/1402).fork()).ldelim();
        if (message.GetOneTimeLogin != null && Object.hasOwnProperty.call(message, "GetOneTimeLogin"))
            $root.RequestOneTimeLogin.encode(message.GetOneTimeLogin, writer.uint32(/* id 176, wireType 2 =*/1410).fork()).ldelim();
        if (message.UpdatePushSubscriptions != null && Object.hasOwnProperty.call(message, "UpdatePushSubscriptions"))
            $root.RequestUpdatePushSubscriptions.encode(message.UpdatePushSubscriptions, writer.uint32(/* id 177, wireType 2 =*/1418).fork()).ldelim();
        if (message.RefreshSubscription != null && Object.hasOwnProperty.call(message, "RefreshSubscription"))
            $root.PushSubscriptionData.encode(message.RefreshSubscription, writer.uint32(/* id 178, wireType 2 =*/1426).fork()).ldelim();
        if (message.SendChatFile != null && Object.hasOwnProperty.call(message, "SendChatFile"))
            $root.RequestSendChatFile.encode(message.SendChatFile, writer.uint32(/* id 179, wireType 2 =*/1434).fork()).ldelim();
        if (message.UserTypingChat != null && Object.hasOwnProperty.call(message, "UserTypingChat"))
            $root.ChatTyping.encode(message.UserTypingChat, writer.uint32(/* id 180, wireType 2 =*/1442).fork()).ldelim();
        if (message.UpdateWebMeetingParticipants != null && Object.hasOwnProperty.call(message, "UpdateWebMeetingParticipants"))
            $root.ConferenceState.encode(message.UpdateWebMeetingParticipants, writer.uint32(/* id 181, wireType 2 =*/1450).fork()).ldelim();
        if (message.BlockAnonymous != null && Object.hasOwnProperty.call(message, "BlockAnonymous"))
            $root.RequestBlacklistAnonymous.encode(message.BlockAnonymous, writer.uint32(/* id 182, wireType 2 =*/1458).fork()).ldelim();
        if (message.DisconnectAnonymous != null && Object.hasOwnProperty.call(message, "DisconnectAnonymous"))
            $root.RequestDisconnectAnonymous.encode(message.DisconnectAnonymous, writer.uint32(/* id 183, wireType 2 =*/1466).fork()).ldelim();
        if (message.GetRecordings != null && Object.hasOwnProperty.call(message, "GetRecordings"))
            $root.RequestGetRecordings.encode(message.GetRecordings, writer.uint32(/* id 184, wireType 2 =*/1474).fork()).ldelim();
        if (message.DeleteRecording != null && Object.hasOwnProperty.call(message, "DeleteRecording"))
            $root.RequestDeleteRecording.encode(message.DeleteRecording, writer.uint32(/* id 185, wireType 2 =*/1482).fork()).ldelim();
        if (message.UpdateConversation != null && Object.hasOwnProperty.call(message, "UpdateConversation"))
            $root.RequestUpdateConversation.encode(message.UpdateConversation, writer.uint32(/* id 186, wireType 2 =*/1490).fork()).ldelim();
        if (message.TransferChatTo != null && Object.hasOwnProperty.call(message, "TransferChatTo"))
            $root.RequestTransferChatTo.encode(message.TransferChatTo, writer.uint32(/* id 187, wireType 2 =*/1498).fork()).ldelim();
        if (message.AgentTakesOverChat != null && Object.hasOwnProperty.call(message, "AgentTakesOverChat"))
            $root.RequestAgentTakesOverChat.encode(message.AgentTakesOverChat, writer.uint32(/* id 188, wireType 2 =*/1506).fork()).ldelim();
        if (message.CreateConversation != null && Object.hasOwnProperty.call(message, "CreateConversation"))
            $root.RequestCreateConversation.encode(message.CreateConversation, writer.uint32(/* id 189, wireType 2 =*/1514).fork()).ldelim();
        if (message.SendMessageToConversation != null && Object.hasOwnProperty.call(message, "SendMessageToConversation"))
            $root.RequestSendChatMessageToConversation.encode(message.SendMessageToConversation, writer.uint32(/* id 190, wireType 2 =*/1522).fork()).ldelim();
        if (message.SendChatRating != null && Object.hasOwnProperty.call(message, "SendChatRating"))
            $root.RequestChatRating.encode(message.SendChatRating, writer.uint32(/* id 191, wireType 2 =*/1530).fork()).ldelim();
        if (message.UpdateUserSettings != null && Object.hasOwnProperty.call(message, "UpdateUserSettings"))
            $root.RequestUpdateUserSettings.encode(message.UpdateUserSettings, writer.uint32(/* id 192, wireType 2 =*/1538).fork()).ldelim();
        if (message.ExactMatchRequest != null && Object.hasOwnProperty.call(message, "ExactMatchRequest"))
            $root.RequestExactMatch.encode(message.ExactMatchRequest, writer.uint32(/* id 193, wireType 2 =*/1546).fork()).ldelim();
        if (message.GetContactById != null && Object.hasOwnProperty.call(message, "GetContactById"))
            $root.RequestGetContactById.encode(message.GetContactById, writer.uint32(/* id 194, wireType 2 =*/1554).fork()).ldelim();
        if (message.StartChatMonitoring != null && Object.hasOwnProperty.call(message, "StartChatMonitoring"))
            $root.RequestStartChatMonitoring.encode(message.StartChatMonitoring, writer.uint32(/* id 195, wireType 2 =*/1562).fork()).ldelim();
        if (message.StopChatMonitoring != null && Object.hasOwnProperty.call(message, "StopChatMonitoring"))
            $root.RequestStopChatMonitoring.encode(message.StopChatMonitoring, writer.uint32(/* id 196, wireType 2 =*/1570).fork()).ldelim();
        if (message.WhisperMessage != null && Object.hasOwnProperty.call(message, "WhisperMessage"))
            $root.RequestWhisperMessage.encode(message.WhisperMessage, writer.uint32(/* id 197, wireType 2 =*/1578).fork()).ldelim();
        if (message.CloseConversation != null && Object.hasOwnProperty.call(message, "CloseConversation"))
            $root.RequestCloseConversation.encode(message.CloseConversation, writer.uint32(/* id 198, wireType 2 =*/1586).fork()).ldelim();
        if (message.ProviderMapping != null && Object.hasOwnProperty.call(message, "ProviderMapping"))
            $root.RequestProviderMapping.encode(message.ProviderMapping, writer.uint32(/* id 199, wireType 2 =*/1594).fork()).ldelim();
        if (message.LoginResponse != null && Object.hasOwnProperty.call(message, "LoginResponse"))
            $root.LoginInfo.encode(message.LoginResponse, writer.uint32(/* id 200, wireType 2 =*/1602).fork()).ldelim();
        if (message.MyInfo != null && Object.hasOwnProperty.call(message, "MyInfo"))
            $root.MyExtensionInfo.encode(message.MyInfo, writer.uint32(/* id 201, wireType 2 =*/1610).fork()).ldelim();
        if (message.LookupResult != null && Object.hasOwnProperty.call(message, "LookupResult"))
            $root.ResponseLookup.encode(message.LookupResult, writer.uint32(/* id 202, wireType 2 =*/1618).fork()).ldelim();
        if (message.FileResult != null && Object.hasOwnProperty.call(message, "FileResult"))
            $root.ResponseFile.encode(message.FileResult, writer.uint32(/* id 203, wireType 2 =*/1626).fork()).ldelim();
        if (message.CallHistory != null && Object.hasOwnProperty.call(message, "CallHistory"))
            $root.ResponseCallHistory.encode(message.CallHistory, writer.uint32(/* id 204, wireType 2 =*/1634).fork()).ldelim();
        if (message.CallHistoryCount != null && Object.hasOwnProperty.call(message, "CallHistoryCount"))
            $root.ResponseCallHistoryCount.encode(message.CallHistoryCount, writer.uint32(/* id 205, wireType 2 =*/1642).fork()).ldelim();
        if (message.Extensions != null && Object.hasOwnProperty.call(message, "Extensions"))
            $root.ResponseGetExtensions.encode(message.Extensions, writer.uint32(/* id 206, wireType 2 =*/1650).fork()).ldelim();
        if (message.Acknowledge != null && Object.hasOwnProperty.call(message, "Acknowledge"))
            $root.ResponseAcknowledge.encode(message.Acknowledge, writer.uint32(/* id 207, wireType 2 =*/1658).fork()).ldelim();
        if (message.GroupsInfo != null && Object.hasOwnProperty.call(message, "GroupsInfo"))
            $root.Groups.encode(message.GroupsInfo, writer.uint32(/* id 208, wireType 2 =*/1666).fork()).ldelim();
        if (message.MyChatMessages != null && Object.hasOwnProperty.call(message, "MyChatMessages"))
            $root.ResponseMyMessages.encode(message.MyChatMessages, writer.uint32(/* id 209, wireType 2 =*/1674).fork()).ldelim();
        if (message.SystemParameters != null && Object.hasOwnProperty.call(message, "SystemParameters"))
            $root.ResponseSystemParameters.encode(message.SystemParameters, writer.uint32(/* id 210, wireType 2 =*/1682).fork()).ldelim();
        if (message.QueuesInfo != null && Object.hasOwnProperty.call(message, "QueuesInfo"))
            $root.Queues.encode(message.QueuesInfo, writer.uint32(/* id 211, wireType 2 =*/1690).fork()).ldelim();
        if (message.ParkingsInfo != null && Object.hasOwnProperty.call(message, "ParkingsInfo"))
            $root.Parkings.encode(message.ParkingsInfo, writer.uint32(/* id 212, wireType 2 =*/1698).fork()).ldelim();
        if (message.IVRsInfo != null && Object.hasOwnProperty.call(message, "IVRsInfo"))
            $root.IVRs.encode(message.IVRsInfo, writer.uint32(/* id 213, wireType 2 =*/1706).fork()).ldelim();
        if (message.ConnectionCapabilityMask != null && Object.hasOwnProperty.call(message, "ConnectionCapabilityMask"))
            $root.ResponseConnectionAccessRights.encode(message.ConnectionCapabilityMask, writer.uint32(/* id 214, wireType 2 =*/1714).fork()).ldelim();
        if (message.ExtensionsChangedEvent != null && Object.hasOwnProperty.call(message, "ExtensionsChangedEvent"))
            $root.ResponseExtensionsChanged.encode(message.ExtensionsChangedEvent, writer.uint32(/* id 215, wireType 2 =*/1722).fork()).ldelim();
        if (message.GetFolderResponse != null && Object.hasOwnProperty.call(message, "GetFolderResponse"))
            $root.ResponseGetFolder.encode(message.GetFolderResponse, writer.uint32(/* id 216, wireType 2 =*/1730).fork()).ldelim();
        if (message.ContactChangedEvent != null && Object.hasOwnProperty.call(message, "ContactChangedEvent"))
            $root.ResponseContactChanged.encode(message.ContactChangedEvent, writer.uint32(/* id 217, wireType 2 =*/1738).fork()).ldelim();
        if (message.ServerTimeResponse != null && Object.hasOwnProperty.call(message, "ServerTimeResponse"))
            $root.ResponseServerTime.encode(message.ServerTimeResponse, writer.uint32(/* id 218, wireType 2 =*/1746).fork()).ldelim();
        if (message.ActiveConferences != null && Object.hasOwnProperty.call(message, "ActiveConferences"))
            $root.Conferences.encode(message.ActiveConferences, writer.uint32(/* id 219, wireType 2 =*/1754).fork()).ldelim();
        if (message.MyChatParties != null && Object.hasOwnProperty.call(message, "MyChatParties"))
            $root.ResponseMyChatParties.encode(message.MyChatParties, writer.uint32(/* id 220, wireType 2 =*/1762).fork()).ldelim();
        if (message.MyRights != null && Object.hasOwnProperty.call(message, "MyRights"))
            $root.ResponseGetMyRights.encode(message.MyRights, writer.uint32(/* id 221, wireType 2 =*/1770).fork()).ldelim();
        if (message.Greetings != null && Object.hasOwnProperty.call(message, "Greetings"))
            $root.ResponseGreetings.encode(message.Greetings, writer.uint32(/* id 222, wireType 2 =*/1778).fork()).ldelim();
        if (message.QueueCalls != null && Object.hasOwnProperty.call(message, "QueueCalls"))
            $root.ResponseQueueCalls.encode(message.QueueCalls, writer.uint32(/* id 223, wireType 2 =*/1786).fork()).ldelim();
        if (message.ActiveWebMeetings != null && Object.hasOwnProperty.call(message, "ActiveWebMeetings"))
            $root.WebMeetings.encode(message.ActiveWebMeetings, writer.uint32(/* id 224, wireType 2 =*/1794).fork()).ldelim();
        if (message.PrivateRoom != null && Object.hasOwnProperty.call(message, "PrivateRoom"))
            $root.ResponseWebMeetingPrivateRoom.encode(message.PrivateRoom, writer.uint32(/* id 226, wireType 2 =*/1810).fork()).ldelim();
        if (message.webRTCEndpoint != null && Object.hasOwnProperty.call(message, "webRTCEndpoint"))
            $root.MyWebRTCEndpoint.encode(message.webRTCEndpoint, writer.uint32(/* id 227, wireType 2 =*/1818).fork()).ldelim();
        if (message.ChangeSDPStateResponse != null && Object.hasOwnProperty.call(message, "ChangeSDPStateResponse"))
            $root.ResponseWebRTCChangeSDPState.encode(message.ChangeSDPStateResponse, writer.uint32(/* id 228, wireType 2 =*/1826).fork()).ldelim();
        if (message.UnreadMessagesCount != null && Object.hasOwnProperty.call(message, "UnreadMessagesCount"))
            $root.ResponseUnreadMessagesCount.encode(message.UnreadMessagesCount, writer.uint32(/* id 229, wireType 2 =*/1834).fork()).ldelim();
        if (message.WakeupCalls != null && Object.hasOwnProperty.call(message, "WakeupCalls"))
            $root.ResponseWakeupCalls.encode(message.WakeupCalls, writer.uint32(/* id 230, wireType 2 =*/1842).fork()).ldelim();
        if (message.OneTimeLogin != null && Object.hasOwnProperty.call(message, "OneTimeLogin"))
            $root.ResponseOneTimeLogin.encode(message.OneTimeLogin, writer.uint32(/* id 231, wireType 2 =*/1850).fork()).ldelim();
        if (message.ChatFileProgress != null && Object.hasOwnProperty.call(message, "ChatFileProgress"))
            $root.NotificationChatFileProgress.encode(message.ChatFileProgress, writer.uint32(/* id 232, wireType 2 =*/1858).fork()).ldelim();
        if (message.C2cState != null && Object.hasOwnProperty.call(message, "C2cState"))
            $root.NotificationC2CState.encode(message.C2cState, writer.uint32(/* id 233, wireType 2 =*/1866).fork()).ldelim();
        if (message.ResponseRecordings != null && Object.hasOwnProperty.call(message, "ResponseRecordings"))
            $root.ResponseRecordings.encode(message.ResponseRecordings, writer.uint32(/* id 234, wireType 2 =*/1874).fork()).ldelim();
        if (message.ConversationRemoved != null && Object.hasOwnProperty.call(message, "ConversationRemoved"))
            $root.NotificationConversationRemoved.encode(message.ConversationRemoved, writer.uint32(/* id 235, wireType 2 =*/1882).fork()).ldelim();
        if (message.ConversationInfo != null && Object.hasOwnProperty.call(message, "ConversationInfo"))
            $root.ResponseConversationInfo.encode(message.ConversationInfo, writer.uint32(/* id 236, wireType 2 =*/1890).fork()).ldelim();
        if (message.ChatTransferred != null && Object.hasOwnProperty.call(message, "ChatTransferred"))
            $root.NotificationChatTransferred.encode(message.ChatTransferred, writer.uint32(/* id 237, wireType 2 =*/1898).fork()).ldelim();
        if (message.ChatRating != null && Object.hasOwnProperty.call(message, "ChatRating"))
            $root.NotificationChatRating.encode(message.ChatRating, writer.uint32(/* id 238, wireType 2 =*/1906).fork()).ldelim();
        if (message.AnonymousClosed != null && Object.hasOwnProperty.call(message, "AnonymousClosed"))
            $root.AnonymousSessionClosed.encode(message.AnonymousClosed, writer.uint32(/* id 239, wireType 2 =*/1914).fork()).ldelim();
        if (message.ExactMatchResponse != null && Object.hasOwnProperty.call(message, "ExactMatchResponse"))
            $root.ResponseExactMatch.encode(message.ExactMatchResponse, writer.uint32(/* id 240, wireType 2 =*/1922).fork()).ldelim();
        if (message.DeliveryStatus != null && Object.hasOwnProperty.call(message, "DeliveryStatus"))
            $root.NotificationChatMessageStatus.encode(message.DeliveryStatus, writer.uint32(/* id 241, wireType 2 =*/1930).fork()).ldelim();
        if (message.ChatMonitoringFullState != null && Object.hasOwnProperty.call(message, "ChatMonitoringFullState"))
            $root.ResponseChatMonitoring.encode(message.ChatMonitoringFullState, writer.uint32(/* id 242, wireType 2 =*/1938).fork()).ldelim();
        if (message.ChatMonitoringNotification != null && Object.hasOwnProperty.call(message, "ChatMonitoringNotification"))
            $root.ChatMonitoringEvent.encode(message.ChatMonitoringNotification, writer.uint32(/* id 243, wireType 2 =*/1946).fork()).ldelim();
        if (message.MyProviders != null && Object.hasOwnProperty.call(message, "MyProviders"))
            $root.ResponseAvailableProviders.encode(message.MyProviders, writer.uint32(/* id 244, wireType 2 =*/1954).fork()).ldelim();
        if (message.MyQueues != null && Object.hasOwnProperty.call(message, "MyQueues"))
            $root.ResponseMyQueues.encode(message.MyQueues, writer.uint32(/* id 245, wireType 2 =*/1962).fork()).ldelim();
        if (message.ConversationEvents != null && Object.hasOwnProperty.call(message, "ConversationEvents"))
            $root.ResponseConversationEvents.encode(message.ConversationEvents, writer.uint32(/* id 246, wireType 2 =*/1970).fork()).ldelim();
        if (message.WmParams != null && Object.hasOwnProperty.call(message, "WmParams"))
            $root.WmBridgeParameters.encode(message.WmParams, writer.uint32(/* id 247, wireType 2 =*/1978).fork()).ldelim();
        if (message.NotificationMeetingRows != null && Object.hasOwnProperty.call(message, "NotificationMeetingRows"))
            $root.MeetingRows.encode(message.NotificationMeetingRows, writer.uint32(/* id 248, wireType 2 =*/1986).fork()).ldelim();
        if (message.ResponseConversations != null && Object.hasOwnProperty.call(message, "ResponseConversations"))
            $root.ResponseGetConversations.encode(message.ResponseConversations, writer.uint32(/* id 249, wireType 2 =*/1994).fork()).ldelim();
        if (message.MeetingState != null && Object.hasOwnProperty.call(message, "MeetingState"))
            $root.MeetingStateNotification.encode(message.MeetingState, writer.uint32(/* id 250, wireType 2 =*/2002).fork()).ldelim();
        if (message.MeetingStates != null && Object.hasOwnProperty.call(message, "MeetingStates"))
            $root.MeetingStateNotifications.encode(message.MeetingStates, writer.uint32(/* id 251, wireType 2 =*/2010).fork()).ldelim();
        if (message.RespCheckIpBlocked != null && Object.hasOwnProperty.call(message, "RespCheckIpBlocked"))
            $root.ResponseCheckIpBlocked.encode(message.RespCheckIpBlocked, writer.uint32(/* id 252, wireType 2 =*/2018).fork()).ldelim();
        if (message.PreviousInteractions != null && Object.hasOwnProperty.call(message, "PreviousInteractions"))
            $root.ResponsePreviousInteractions.encode(message.PreviousInteractions, writer.uint32(/* id 253, wireType 2 =*/2026).fork()).ldelim();
        if (message.ChatTemplateCategories != null && Object.hasOwnProperty.call(message, "ChatTemplateCategories"))
            $root.ResponseGetChatTemplateCategories.encode(message.ChatTemplateCategories, writer.uint32(/* id 254, wireType 2 =*/2034).fork()).ldelim();
        if (message.ChatTemplateLanguages != null && Object.hasOwnProperty.call(message, "ChatTemplateLanguages"))
            $root.ResponseGetChatTemplateLanguages.encode(message.ChatTemplateLanguages, writer.uint32(/* id 255, wireType 2 =*/2042).fork()).ldelim();
        if (message.ChatTemplates != null && Object.hasOwnProperty.call(message, "ChatTemplates"))
            $root.ResponseGetChatTemplates.encode(message.ChatTemplates, writer.uint32(/* id 256, wireType 2 =*/2050).fork()).ldelim();
        if (message.MeetingDetails != null && Object.hasOwnProperty.call(message, "MeetingDetails"))
            $root.ResponseMeetingDetails.encode(message.MeetingDetails, writer.uint32(/* id 257, wireType 2 =*/2058).fork()).ldelim();
        if (message.ChatMessageChanged != null && Object.hasOwnProperty.call(message, "ChatMessageChanged"))
            $root.NotificationChatMessageChanged.encode(message.ChatMessageChanged, writer.uint32(/* id 258, wireType 2 =*/2066).fork()).ldelim();
        if (message.CrmContactList != null && Object.hasOwnProperty.call(message, "CrmContactList"))
            $root.ResponseCrmContacts.encode(message.CrmContactList, writer.uint32(/* id 259, wireType 2 =*/2074).fork()).ldelim();
        if (message.FoundCrmContacts != null && Object.hasOwnProperty.call(message, "FoundCrmContacts"))
            $root.ResponseSearchCrmContacts.encode(message.FoundCrmContacts, writer.uint32(/* id 260, wireType 2 =*/2082).fork()).ldelim();
        if (message.CallControlResponse != null && Object.hasOwnProperty.call(message, "CallControlResponse"))
            $root.ResponseCallControl.encode(message.CallControlResponse, writer.uint32(/* id 261, wireType 2 =*/2090).fork()).ldelim();
        if (message.CallQualityReport != null && Object.hasOwnProperty.call(message, "CallQualityReport"))
            $root.QualityReport.encode(message.CallQualityReport, writer.uint32(/* id 262, wireType 2 =*/2098).fork()).ldelim();
        if (message.BatchExactMatchResult != null && Object.hasOwnProperty.call(message, "BatchExactMatchResult"))
            $root.ResponseBatchExactMatch.encode(message.BatchExactMatchResult, writer.uint32(/* id 263, wireType 2 =*/2106).fork()).ldelim();
        if (message.GetMyProviders != null && Object.hasOwnProperty.call(message, "GetMyProviders"))
            $root.RequestAvailableProviders.encode(message.GetMyProviders, writer.uint32(/* id 500, wireType 2 =*/4002).fork()).ldelim();
        if (message.GetMyQueues != null && Object.hasOwnProperty.call(message, "GetMyQueues"))
            $root.RequestMyQueues.encode(message.GetMyQueues, writer.uint32(/* id 501, wireType 2 =*/4010).fork()).ldelim();
        if (message.GetQmLastMessages != null && Object.hasOwnProperty.call(message, "GetQmLastMessages"))
            $root.RequestGetQMLastMessages.encode(message.GetQmLastMessages, writer.uint32(/* id 502, wireType 2 =*/4018).fork()).ldelim();
        if (message.GetConversationEvents != null && Object.hasOwnProperty.call(message, "GetConversationEvents"))
            $root.RequestGetConversationEvents.encode(message.GetConversationEvents, writer.uint32(/* id 503, wireType 2 =*/4026).fork()).ldelim();
        if (message.GetQMConversationEvents != null && Object.hasOwnProperty.call(message, "GetQMConversationEvents"))
            $root.RequestGetQMConversationEvents.encode(message.GetQMConversationEvents, writer.uint32(/* id 504, wireType 2 =*/4034).fork()).ldelim();
        if (message.RefreshConversation != null && Object.hasOwnProperty.call(message, "RefreshConversation"))
            $root.RequestRefreshConversation.encode(message.RefreshConversation, writer.uint32(/* id 505, wireType 2 =*/4042).fork()).ldelim();
        if (message.UpdateMeetingDocuments != null && Object.hasOwnProperty.call(message, "UpdateMeetingDocuments"))
            $root.RequestUpdateMeetingDocuments.encode(message.UpdateMeetingDocuments, writer.uint32(/* id 506, wireType 2 =*/4050).fork()).ldelim();
        if (message.WebMeetingPollings != null && Object.hasOwnProperty.call(message, "WebMeetingPollings"))
            $root.WmPollings.encode(message.WebMeetingPollings, writer.uint32(/* id 507, wireType 2 =*/4058).fork()).ldelim();
        if (message.ForwardMessage != null && Object.hasOwnProperty.call(message, "ForwardMessage"))
            $root.RequestForwardChatMessage.encode(message.ForwardMessage, writer.uint32(/* id 508, wireType 2 =*/4066).fork()).ldelim();
        if (message.PutWebMeetingReports2 != null && Object.hasOwnProperty.call(message, "PutWebMeetingReports2"))
            $root.RequestPutWebMeetingReports2.encode(message.PutWebMeetingReports2, writer.uint32(/* id 509, wireType 2 =*/4074).fork()).ldelim();
        if (message.GetConversations != null && Object.hasOwnProperty.call(message, "GetConversations"))
            $root.RequestGetConversations.encode(message.GetConversations, writer.uint32(/* id 510, wireType 2 =*/4082).fork()).ldelim();
        if (message.CloseMeeting != null && Object.hasOwnProperty.call(message, "CloseMeeting"))
            $root.RequestCloseMeeting.encode(message.CloseMeeting, writer.uint32(/* id 511, wireType 2 =*/4090).fork()).ldelim();
        if (message.SetOfficeHoursMode != null && Object.hasOwnProperty.call(message, "SetOfficeHoursMode"))
            $root.RequestSetOfficeHoursMode.encode(message.SetOfficeHoursMode, writer.uint32(/* id 512, wireType 2 =*/4098).fork()).ldelim();
        if (message.RqCheckIpBlocked != null && Object.hasOwnProperty.call(message, "RqCheckIpBlocked"))
            $root.RequestCheckIpBlocked.encode(message.RqCheckIpBlocked, writer.uint32(/* id 513, wireType 2 =*/4106).fork()).ldelim();
        if (message.SetLanguage != null && Object.hasOwnProperty.call(message, "SetLanguage"))
            $root.RequestSetLanguage.encode(message.SetLanguage, writer.uint32(/* id 514, wireType 2 =*/4114).fork()).ldelim();
        if (message.GetPreviousInteractions != null && Object.hasOwnProperty.call(message, "GetPreviousInteractions"))
            $root.RequestPreviousInteractions.encode(message.GetPreviousInteractions, writer.uint32(/* id 515, wireType 2 =*/4122).fork()).ldelim();
        if (message.UpdateGroup != null && Object.hasOwnProperty.call(message, "UpdateGroup"))
            $root.RequestUpdateGroup.encode(message.UpdateGroup, writer.uint32(/* id 516, wireType 2 =*/4130).fork()).ldelim();
        if (message.GetChatTemplateCategories != null && Object.hasOwnProperty.call(message, "GetChatTemplateCategories"))
            $root.RequestGetChatTemplateCategories.encode(message.GetChatTemplateCategories, writer.uint32(/* id 517, wireType 2 =*/4138).fork()).ldelim();
        if (message.GetChatTemplateLanguages != null && Object.hasOwnProperty.call(message, "GetChatTemplateLanguages"))
            $root.RequestGetChatTemplateLanguages.encode(message.GetChatTemplateLanguages, writer.uint32(/* id 518, wireType 2 =*/4146).fork()).ldelim();
        if (message.GetChatTemplates != null && Object.hasOwnProperty.call(message, "GetChatTemplates"))
            $root.RequestGetChatTemplates.encode(message.GetChatTemplates, writer.uint32(/* id 519, wireType 2 =*/4154).fork()).ldelim();
        if (message.UpdateCategory != null && Object.hasOwnProperty.call(message, "UpdateCategory"))
            $root.RequestUpdateCategory.encode(message.UpdateCategory, writer.uint32(/* id 520, wireType 2 =*/4162).fork()).ldelim();
        if (message.UpdateTemplate != null && Object.hasOwnProperty.call(message, "UpdateTemplate"))
            $root.RequestUpdateChatTemplate.encode(message.UpdateTemplate, writer.uint32(/* id 521, wireType 2 =*/4170).fork()).ldelim();
        if (message.BulkUpdateCategory != null && Object.hasOwnProperty.call(message, "BulkUpdateCategory"))
            $root.RequestBulkUpdateCategory.encode(message.BulkUpdateCategory, writer.uint32(/* id 522, wireType 2 =*/4178).fork()).ldelim();
        if (message.GetMeetingDetails != null && Object.hasOwnProperty.call(message, "GetMeetingDetails"))
            $root.RequestGetMeetingDetails.encode(message.GetMeetingDetails, writer.uint32(/* id 523, wireType 2 =*/4186).fork()).ldelim();
        if (message.SetMessageEmoji != null && Object.hasOwnProperty.call(message, "SetMessageEmoji"))
            $root.RequestSetMessageEmoji.encode(message.SetMessageEmoji, writer.uint32(/* id 524, wireType 2 =*/4194).fork()).ldelim();
        if (message.EditChatMessage != null && Object.hasOwnProperty.call(message, "EditChatMessage"))
            $root.RequestEditChatMessage.encode(message.EditChatMessage, writer.uint32(/* id 525, wireType 2 =*/4202).fork()).ldelim();
        if (message.GetMessagesByIds != null && Object.hasOwnProperty.call(message, "GetMessagesByIds"))
            $root.RequestGetMessages.encode(message.GetMessagesByIds, writer.uint32(/* id 526, wireType 2 =*/4210).fork()).ldelim();
        if (message.GetCrmContacts != null && Object.hasOwnProperty.call(message, "GetCrmContacts"))
            $root.RequestGetCrmContacts.encode(message.GetCrmContacts, writer.uint32(/* id 527, wireType 2 =*/4218).fork()).ldelim();
        if (message.SearchCrmContacts != null && Object.hasOwnProperty.call(message, "SearchCrmContacts"))
            $root.RequestSearchCrmContacts.encode(message.SearchCrmContacts, writer.uint32(/* id 528, wireType 2 =*/4226).fork()).ldelim();
        if (message.BatchExactMatch != null && Object.hasOwnProperty.call(message, "BatchExactMatch"))
            $root.RequestBatchExactMatch.encode(message.BatchExactMatch, writer.uint32(/* id 529, wireType 2 =*/4234).fork()).ldelim();
        if (message.CallReport != null && Object.hasOwnProperty.call(message, "CallReport"))
            $root.RequestCallReport.encode(message.CallReport, writer.uint32(/* id 555, wireType 2 =*/4442).fork()).ldelim();
        if (message.Monitor != null && Object.hasOwnProperty.call(message, "Monitor"))
            $root.RequestMonitor.encode(message.Monitor, writer.uint32(/* id 556, wireType 2 =*/4450).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a GenericMessage message from the specified reader or buffer.
     * @function decode
     * @memberof GenericMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {GenericMessage} GenericMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    GenericMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.GenericMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1: {
                    message.MessageId = reader.int32();
                    break;
                }
            case 100: {
                    message.LoginRequest = $root.Login.decode(reader, reader.uint32());
                    break;
                }
            case 101: {
                    message.LogoutRequest = $root.Logout.decode(reader, reader.uint32());
                    break;
                }
            case 102: {
                    message.GetMyInfo = $root.RequestMyInfo.decode(reader, reader.uint32());
                    break;
                }
            case 103: {
                    message.ChangeStatus = $root.RequestChangeStatus.decode(reader, reader.uint32());
                    break;
                }
            case 104: {
                    message.LookupContact = $root.RequestLookupContact.decode(reader, reader.uint32());
                    break;
                }
            case 105: {
                    message.GetFile = $root.RequestGetFile.decode(reader, reader.uint32());
                    break;
                }
            case 106: {
                    message.GetCallHistory = $root.RequestCallHistory.decode(reader, reader.uint32());
                    break;
                }
            case 107: {
                    message.GetCallHistoryCount = $root.RequestCallHistoryCount.decode(reader, reader.uint32());
                    break;
                }
            case 108: {
                    message.UpdateFwdProfiles = $root.RequestUpdateFwdProfile.decode(reader, reader.uint32());
                    break;
                }
            case 109: {
                    message.GetExtensions = $root.RequestGetExtensions.decode(reader, reader.uint32());
                    break;
                }
            case 110: {
                    message.SendChatMessage = $root.RequestSendChatMessage.decode(reader, reader.uint32());
                    break;
                }
            case 111: {
                    message.GetMyMessages = $root.RequestGetMyMessages.decode(reader, reader.uint32());
                    break;
                }
            case 112: {
                    message.MessagesReceived = $root.RequestSetChatReceived.decode(reader, reader.uint32());
                    break;
                }
            case 113: {
                    message.PutFile = $root.RequestPutFile.decode(reader, reader.uint32());
                    break;
                }
            case 114: {
                    message.SetVMRead = $root.RequestSetVoiceMailHeard.decode(reader, reader.uint32());
                    break;
                }
            case 115: {
                    message.DropCall = $root.RequestDropCall.decode(reader, reader.uint32());
                    break;
                }
            case 116: {
                    message.DivertCall = $root.RequestDivertCall.decode(reader, reader.uint32());
                    break;
                }
            case 117: {
                    message.PickupCall = $root.RequestPickupCall.decode(reader, reader.uint32());
                    break;
                }
            case 118: {
                    message.TransferCall = $root.RequestTransferCall.decode(reader, reader.uint32());
                    break;
                }
            case 119: {
                    message.MakeCall = $root.RequestMakeCall.decode(reader, reader.uint32());
                    break;
                }
            case 120: {
                    message.UpdateContact = $root.RequestUpdateContact.decode(reader, reader.uint32());
                    break;
                }
            case 121: {
                    message.ChangeConference = $root.RequestChangeConference.decode(reader, reader.uint32());
                    break;
                }
            case 122: {
                    message.GetSystemParameters = $root.RequestGetSystemParameters.decode(reader, reader.uint32());
                    break;
                }
            case 123: {
                    message.BargeInCall = $root.RequestBargeInCall.decode(reader, reader.uint32());
                    break;
                }
            case 124: {
                    message.GetAccessRights = $root.RequestConnectionAccessRights.decode(reader, reader.uint32());
                    break;
                }
            case 125: {
                    message.ResetMyMissedCalls = $root.RequestResetMyMissedCalls.decode(reader, reader.uint32());
                    break;
                }
            case 126: {
                    message.DeleteVM = $root.RequestDeleteVoiceMail.decode(reader, reader.uint32());
                    break;
                }
            case 127: {
                    message.PlayVM = $root.RequestPlayVoiceMail.decode(reader, reader.uint32());
                    break;
                }
            case 128: {
                    message.GetFolderRequest = $root.RequestGetFolder.decode(reader, reader.uint32());
                    break;
                }
            case 129: {
                    message.DeleteFile = $root.RequestDeleteFile.decode(reader, reader.uint32());
                    break;
                }
            case 130: {
                    message.PlayRec = $root.RequestPlayRecording.decode(reader, reader.uint32());
                    break;
                }
            case 131: {
                    message.ServerTimeRequest = $root.RequestServerTime.decode(reader, reader.uint32());
                    break;
                }
            case 132: {
                    message.SetQueueStatus = $root.RequestSetQueueStatus.decode(reader, reader.uint32());
                    break;
                }
            case 133: {
                    message.ScheduleConferenceRequest = $root.RequestUpsertScheduledConference.decode(reader, reader.uint32());
                    break;
                }
            case 134: {
                    message.GetSchedule = $root.RequestGetConferenceSchedule.decode(reader, reader.uint32());
                    break;
                }
            case 135: {
                    message.CreateConferenceScheduleTemplate = $root.RequestCreateConferenceScheduleTemplate.decode(reader, reader.uint32());
                    break;
                }
            case 136: {
                    message.JoinCallParticipantsToConference = $root.RequestJoinCallParticipantsToConference.decode(reader, reader.uint32());
                    break;
                }
            case 137: {
                    message.RecordCall = $root.RequestRecordCall.decode(reader, reader.uint32());
                    break;
                }
            case 138: {
                    message.GetMyChatParties = $root.RequestMyChatParties.decode(reader, reader.uint32());
                    break;
                }
            case 139: {
                    message.DeleteChat = $root.RequestDeleteChat.decode(reader, reader.uint32());
                    break;
                }
            case 140: {
                    message.GetMyRights = $root.RequestGetMyRights.decode(reader, reader.uint32());
                    break;
                }
            case 141: {
                    message.GetGreetings = $root.RequestGetGreetings.decode(reader, reader.uint32());
                    break;
                }
            case 142: {
                    message.SetGreetings = $root.RequestSetGreetings.decode(reader, reader.uint32());
                    break;
                }
            case 143: {
                    message.PlayVMGreetingOnPhone = $root.RequestPlayVMGreetingOnPhone.decode(reader, reader.uint32());
                    break;
                }
            case 144: {
                    message.RecordVMGreetingFromPhone = $root.RequestRecordVMGreetingFromPhone.decode(reader, reader.uint32());
                    break;
                }
            case 145: {
                    message.GetQueueCalls = $root.RequestQueueCalls.decode(reader, reader.uint32());
                    break;
                }
            case 146: {
                    message.GetQueueCallsCount = $root.RequestQueueCallsCount.decode(reader, reader.uint32());
                    break;
                }
            case 147: {
                    message.SetQueueCallData = $root.RequestSetQueueCallData.decode(reader, reader.uint32());
                    break;
                }
            case 148: {
                    message.DeleteCallHistory = $root.RequestDeleteCallHistory.decode(reader, reader.uint32());
                    break;
                }
            case 149: {
                    message.SetQueueCallDataBulk = $root.RequestSetQueueCallDataBulk.decode(reader, reader.uint32());
                    break;
                }
            case 150: {
                    message.UpdateWebRtcInboundRules = $root.RequestUpdateWebRtcInboundRules.decode(reader, reader.uint32());
                    break;
                }
            case 151: {
                    message.SendWelcomeEmail = $root.RequestWelcomeEmail.decode(reader, reader.uint32());
                    break;
                }
            case 152: {
                    message.ConvertToAutoAnswer = $root.RequestAutoAnswerConnection.decode(reader, reader.uint32());
                    break;
                }
            case 153: {
                    message.UpsertWebMeeting = $root.WebMeetingState.decode(reader, reader.uint32());
                    break;
                }
            case 154: {
                    message.GetWebMeetings = $root.RequestGetWebMeetings.decode(reader, reader.uint32());
                    break;
                }
            case 157: {
                    message.PutWebMeetingReports = $root.RequestPutWebMeetingReports.decode(reader, reader.uint32());
                    break;
                }
            case 158: {
                    message.CreatePrivateRoom = $root.RequestWebMeetingPrivateRoom.decode(reader, reader.uint32());
                    break;
                }
            case 159: {
                    message.SendError = $root.RequestWebMeetingError.decode(reader, reader.uint32());
                    break;
                }
            case 160: {
                    message.ReinviteOrganizer = $root.RequestWmReinviteOrganizer.decode(reader, reader.uint32());
                    break;
                }
            case 161: {
                    message.SetSharedBlfs = $root.RequestSetSharedBlfs.decode(reader, reader.uint32());
                    break;
                }
            case 162: {
                    message.GetMyLastMessages = $root.RequestGetMyLastMessages.decode(reader, reader.uint32());
                    break;
                }
            case 163: {
                    message.registerWebRTC = $root.RequestRegisterWebRTCEndpoint.decode(reader, reader.uint32());
                    break;
                }
            case 164: {
                    message.ChangeSDPState = $root.RequestWebRTCChangeSDPState.decode(reader, reader.uint32());
                    break;
                }
            case 165: {
                    message.GetUnreadMessagesCount = $root.RequestUnreadMessagesCount.decode(reader, reader.uint32());
                    break;
                }
            case 166: {
                    message.JoinCalls = $root.RequestJoinCalls.decode(reader, reader.uint32());
                    break;
                }
            case 167: {
                    message.RecordingControl = $root.RequestControlCallRecording.decode(reader, reader.uint32());
                    break;
                }
            case 168: {
                    message.ChangeWCPassword = $root.RequestChangeWebClientPassword.decode(reader, reader.uint32());
                    break;
                }
            case 169: {
                    message.UpdateFavorites = $root.RequestUpdateFavorites.decode(reader, reader.uint32());
                    break;
                }
            case 170: {
                    message.GetContactsByRef = $root.RequestContactsByRef.decode(reader, reader.uint32());
                    break;
                }
            case 171: {
                    message.CleanExtension = $root.RequestCleanExtension.decode(reader, reader.uint32());
                    break;
                }
            case 172: {
                    message.AssignExtension = $root.RequestAssignExtension.decode(reader, reader.uint32());
                    break;
                }
            case 173: {
                    message.SetWakeupCall = $root.RequestSetWakeupCall.decode(reader, reader.uint32());
                    break;
                }
            case 174: {
                    message.DeleteWakeupCall = $root.RequestDeleteWakeupCall.decode(reader, reader.uint32());
                    break;
                }
            case 175: {
                    message.GetWakeupCalls = $root.RequestGetWakeupCalls.decode(reader, reader.uint32());
                    break;
                }
            case 176: {
                    message.GetOneTimeLogin = $root.RequestOneTimeLogin.decode(reader, reader.uint32());
                    break;
                }
            case 177: {
                    message.UpdatePushSubscriptions = $root.RequestUpdatePushSubscriptions.decode(reader, reader.uint32());
                    break;
                }
            case 178: {
                    message.RefreshSubscription = $root.PushSubscriptionData.decode(reader, reader.uint32());
                    break;
                }
            case 179: {
                    message.SendChatFile = $root.RequestSendChatFile.decode(reader, reader.uint32());
                    break;
                }
            case 180: {
                    message.UserTypingChat = $root.ChatTyping.decode(reader, reader.uint32());
                    break;
                }
            case 181: {
                    message.UpdateWebMeetingParticipants = $root.ConferenceState.decode(reader, reader.uint32());
                    break;
                }
            case 182: {
                    message.BlockAnonymous = $root.RequestBlacklistAnonymous.decode(reader, reader.uint32());
                    break;
                }
            case 183: {
                    message.DisconnectAnonymous = $root.RequestDisconnectAnonymous.decode(reader, reader.uint32());
                    break;
                }
            case 184: {
                    message.GetRecordings = $root.RequestGetRecordings.decode(reader, reader.uint32());
                    break;
                }
            case 185: {
                    message.DeleteRecording = $root.RequestDeleteRecording.decode(reader, reader.uint32());
                    break;
                }
            case 186: {
                    message.UpdateConversation = $root.RequestUpdateConversation.decode(reader, reader.uint32());
                    break;
                }
            case 187: {
                    message.TransferChatTo = $root.RequestTransferChatTo.decode(reader, reader.uint32());
                    break;
                }
            case 188: {
                    message.AgentTakesOverChat = $root.RequestAgentTakesOverChat.decode(reader, reader.uint32());
                    break;
                }
            case 189: {
                    message.CreateConversation = $root.RequestCreateConversation.decode(reader, reader.uint32());
                    break;
                }
            case 190: {
                    message.SendMessageToConversation = $root.RequestSendChatMessageToConversation.decode(reader, reader.uint32());
                    break;
                }
            case 191: {
                    message.SendChatRating = $root.RequestChatRating.decode(reader, reader.uint32());
                    break;
                }
            case 192: {
                    message.UpdateUserSettings = $root.RequestUpdateUserSettings.decode(reader, reader.uint32());
                    break;
                }
            case 193: {
                    message.ExactMatchRequest = $root.RequestExactMatch.decode(reader, reader.uint32());
                    break;
                }
            case 194: {
                    message.GetContactById = $root.RequestGetContactById.decode(reader, reader.uint32());
                    break;
                }
            case 195: {
                    message.StartChatMonitoring = $root.RequestStartChatMonitoring.decode(reader, reader.uint32());
                    break;
                }
            case 196: {
                    message.StopChatMonitoring = $root.RequestStopChatMonitoring.decode(reader, reader.uint32());
                    break;
                }
            case 197: {
                    message.WhisperMessage = $root.RequestWhisperMessage.decode(reader, reader.uint32());
                    break;
                }
            case 198: {
                    message.CloseConversation = $root.RequestCloseConversation.decode(reader, reader.uint32());
                    break;
                }
            case 199: {
                    message.ProviderMapping = $root.RequestProviderMapping.decode(reader, reader.uint32());
                    break;
                }
            case 500: {
                    message.GetMyProviders = $root.RequestAvailableProviders.decode(reader, reader.uint32());
                    break;
                }
            case 501: {
                    message.GetMyQueues = $root.RequestMyQueues.decode(reader, reader.uint32());
                    break;
                }
            case 502: {
                    message.GetQmLastMessages = $root.RequestGetQMLastMessages.decode(reader, reader.uint32());
                    break;
                }
            case 503: {
                    message.GetConversationEvents = $root.RequestGetConversationEvents.decode(reader, reader.uint32());
                    break;
                }
            case 504: {
                    message.GetQMConversationEvents = $root.RequestGetQMConversationEvents.decode(reader, reader.uint32());
                    break;
                }
            case 505: {
                    message.RefreshConversation = $root.RequestRefreshConversation.decode(reader, reader.uint32());
                    break;
                }
            case 506: {
                    message.UpdateMeetingDocuments = $root.RequestUpdateMeetingDocuments.decode(reader, reader.uint32());
                    break;
                }
            case 507: {
                    message.WebMeetingPollings = $root.WmPollings.decode(reader, reader.uint32());
                    break;
                }
            case 508: {
                    message.ForwardMessage = $root.RequestForwardChatMessage.decode(reader, reader.uint32());
                    break;
                }
            case 509: {
                    message.PutWebMeetingReports2 = $root.RequestPutWebMeetingReports2.decode(reader, reader.uint32());
                    break;
                }
            case 510: {
                    message.GetConversations = $root.RequestGetConversations.decode(reader, reader.uint32());
                    break;
                }
            case 511: {
                    message.CloseMeeting = $root.RequestCloseMeeting.decode(reader, reader.uint32());
                    break;
                }
            case 512: {
                    message.SetOfficeHoursMode = $root.RequestSetOfficeHoursMode.decode(reader, reader.uint32());
                    break;
                }
            case 513: {
                    message.RqCheckIpBlocked = $root.RequestCheckIpBlocked.decode(reader, reader.uint32());
                    break;
                }
            case 514: {
                    message.SetLanguage = $root.RequestSetLanguage.decode(reader, reader.uint32());
                    break;
                }
            case 515: {
                    message.GetPreviousInteractions = $root.RequestPreviousInteractions.decode(reader, reader.uint32());
                    break;
                }
            case 516: {
                    message.UpdateGroup = $root.RequestUpdateGroup.decode(reader, reader.uint32());
                    break;
                }
            case 517: {
                    message.GetChatTemplateCategories = $root.RequestGetChatTemplateCategories.decode(reader, reader.uint32());
                    break;
                }
            case 518: {
                    message.GetChatTemplateLanguages = $root.RequestGetChatTemplateLanguages.decode(reader, reader.uint32());
                    break;
                }
            case 519: {
                    message.GetChatTemplates = $root.RequestGetChatTemplates.decode(reader, reader.uint32());
                    break;
                }
            case 520: {
                    message.UpdateCategory = $root.RequestUpdateCategory.decode(reader, reader.uint32());
                    break;
                }
            case 521: {
                    message.UpdateTemplate = $root.RequestUpdateChatTemplate.decode(reader, reader.uint32());
                    break;
                }
            case 522: {
                    message.BulkUpdateCategory = $root.RequestBulkUpdateCategory.decode(reader, reader.uint32());
                    break;
                }
            case 523: {
                    message.GetMeetingDetails = $root.RequestGetMeetingDetails.decode(reader, reader.uint32());
                    break;
                }
            case 524: {
                    message.SetMessageEmoji = $root.RequestSetMessageEmoji.decode(reader, reader.uint32());
                    break;
                }
            case 525: {
                    message.EditChatMessage = $root.RequestEditChatMessage.decode(reader, reader.uint32());
                    break;
                }
            case 526: {
                    message.GetMessagesByIds = $root.RequestGetMessages.decode(reader, reader.uint32());
                    break;
                }
            case 527: {
                    message.GetCrmContacts = $root.RequestGetCrmContacts.decode(reader, reader.uint32());
                    break;
                }
            case 528: {
                    message.SearchCrmContacts = $root.RequestSearchCrmContacts.decode(reader, reader.uint32());
                    break;
                }
            case 529: {
                    message.BatchExactMatch = $root.RequestBatchExactMatch.decode(reader, reader.uint32());
                    break;
                }
            case 555: {
                    message.CallReport = $root.RequestCallReport.decode(reader, reader.uint32());
                    break;
                }
            case 556: {
                    message.Monitor = $root.RequestMonitor.decode(reader, reader.uint32());
                    break;
                }
            case 200: {
                    message.LoginResponse = $root.LoginInfo.decode(reader, reader.uint32());
                    break;
                }
            case 201: {
                    message.MyInfo = $root.MyExtensionInfo.decode(reader, reader.uint32());
                    break;
                }
            case 202: {
                    message.LookupResult = $root.ResponseLookup.decode(reader, reader.uint32());
                    break;
                }
            case 203: {
                    message.FileResult = $root.ResponseFile.decode(reader, reader.uint32());
                    break;
                }
            case 204: {
                    message.CallHistory = $root.ResponseCallHistory.decode(reader, reader.uint32());
                    break;
                }
            case 205: {
                    message.CallHistoryCount = $root.ResponseCallHistoryCount.decode(reader, reader.uint32());
                    break;
                }
            case 206: {
                    message.Extensions = $root.ResponseGetExtensions.decode(reader, reader.uint32());
                    break;
                }
            case 207: {
                    message.Acknowledge = $root.ResponseAcknowledge.decode(reader, reader.uint32());
                    break;
                }
            case 208: {
                    message.GroupsInfo = $root.Groups.decode(reader, reader.uint32());
                    break;
                }
            case 209: {
                    message.MyChatMessages = $root.ResponseMyMessages.decode(reader, reader.uint32());
                    break;
                }
            case 210: {
                    message.SystemParameters = $root.ResponseSystemParameters.decode(reader, reader.uint32());
                    break;
                }
            case 211: {
                    message.QueuesInfo = $root.Queues.decode(reader, reader.uint32());
                    break;
                }
            case 212: {
                    message.ParkingsInfo = $root.Parkings.decode(reader, reader.uint32());
                    break;
                }
            case 213: {
                    message.IVRsInfo = $root.IVRs.decode(reader, reader.uint32());
                    break;
                }
            case 214: {
                    message.ConnectionCapabilityMask = $root.ResponseConnectionAccessRights.decode(reader, reader.uint32());
                    break;
                }
            case 215: {
                    message.ExtensionsChangedEvent = $root.ResponseExtensionsChanged.decode(reader, reader.uint32());
                    break;
                }
            case 216: {
                    message.GetFolderResponse = $root.ResponseGetFolder.decode(reader, reader.uint32());
                    break;
                }
            case 217: {
                    message.ContactChangedEvent = $root.ResponseContactChanged.decode(reader, reader.uint32());
                    break;
                }
            case 218: {
                    message.ServerTimeResponse = $root.ResponseServerTime.decode(reader, reader.uint32());
                    break;
                }
            case 219: {
                    message.ActiveConferences = $root.Conferences.decode(reader, reader.uint32());
                    break;
                }
            case 220: {
                    message.MyChatParties = $root.ResponseMyChatParties.decode(reader, reader.uint32());
                    break;
                }
            case 221: {
                    message.MyRights = $root.ResponseGetMyRights.decode(reader, reader.uint32());
                    break;
                }
            case 222: {
                    message.Greetings = $root.ResponseGreetings.decode(reader, reader.uint32());
                    break;
                }
            case 223: {
                    message.QueueCalls = $root.ResponseQueueCalls.decode(reader, reader.uint32());
                    break;
                }
            case 224: {
                    message.ActiveWebMeetings = $root.WebMeetings.decode(reader, reader.uint32());
                    break;
                }
            case 226: {
                    message.PrivateRoom = $root.ResponseWebMeetingPrivateRoom.decode(reader, reader.uint32());
                    break;
                }
            case 227: {
                    message.webRTCEndpoint = $root.MyWebRTCEndpoint.decode(reader, reader.uint32());
                    break;
                }
            case 228: {
                    message.ChangeSDPStateResponse = $root.ResponseWebRTCChangeSDPState.decode(reader, reader.uint32());
                    break;
                }
            case 229: {
                    message.UnreadMessagesCount = $root.ResponseUnreadMessagesCount.decode(reader, reader.uint32());
                    break;
                }
            case 230: {
                    message.WakeupCalls = $root.ResponseWakeupCalls.decode(reader, reader.uint32());
                    break;
                }
            case 231: {
                    message.OneTimeLogin = $root.ResponseOneTimeLogin.decode(reader, reader.uint32());
                    break;
                }
            case 232: {
                    message.ChatFileProgress = $root.NotificationChatFileProgress.decode(reader, reader.uint32());
                    break;
                }
            case 233: {
                    message.C2cState = $root.NotificationC2CState.decode(reader, reader.uint32());
                    break;
                }
            case 234: {
                    message.ResponseRecordings = $root.ResponseRecordings.decode(reader, reader.uint32());
                    break;
                }
            case 235: {
                    message.ConversationRemoved = $root.NotificationConversationRemoved.decode(reader, reader.uint32());
                    break;
                }
            case 236: {
                    message.ConversationInfo = $root.ResponseConversationInfo.decode(reader, reader.uint32());
                    break;
                }
            case 237: {
                    message.ChatTransferred = $root.NotificationChatTransferred.decode(reader, reader.uint32());
                    break;
                }
            case 238: {
                    message.ChatRating = $root.NotificationChatRating.decode(reader, reader.uint32());
                    break;
                }
            case 239: {
                    message.AnonymousClosed = $root.AnonymousSessionClosed.decode(reader, reader.uint32());
                    break;
                }
            case 240: {
                    message.ExactMatchResponse = $root.ResponseExactMatch.decode(reader, reader.uint32());
                    break;
                }
            case 241: {
                    message.DeliveryStatus = $root.NotificationChatMessageStatus.decode(reader, reader.uint32());
                    break;
                }
            case 242: {
                    message.ChatMonitoringFullState = $root.ResponseChatMonitoring.decode(reader, reader.uint32());
                    break;
                }
            case 243: {
                    message.ChatMonitoringNotification = $root.ChatMonitoringEvent.decode(reader, reader.uint32());
                    break;
                }
            case 244: {
                    message.MyProviders = $root.ResponseAvailableProviders.decode(reader, reader.uint32());
                    break;
                }
            case 245: {
                    message.MyQueues = $root.ResponseMyQueues.decode(reader, reader.uint32());
                    break;
                }
            case 246: {
                    message.ConversationEvents = $root.ResponseConversationEvents.decode(reader, reader.uint32());
                    break;
                }
            case 247: {
                    message.WmParams = $root.WmBridgeParameters.decode(reader, reader.uint32());
                    break;
                }
            case 248: {
                    message.NotificationMeetingRows = $root.MeetingRows.decode(reader, reader.uint32());
                    break;
                }
            case 249: {
                    message.ResponseConversations = $root.ResponseGetConversations.decode(reader, reader.uint32());
                    break;
                }
            case 250: {
                    message.MeetingState = $root.MeetingStateNotification.decode(reader, reader.uint32());
                    break;
                }
            case 251: {
                    message.MeetingStates = $root.MeetingStateNotifications.decode(reader, reader.uint32());
                    break;
                }
            case 252: {
                    message.RespCheckIpBlocked = $root.ResponseCheckIpBlocked.decode(reader, reader.uint32());
                    break;
                }
            case 253: {
                    message.PreviousInteractions = $root.ResponsePreviousInteractions.decode(reader, reader.uint32());
                    break;
                }
            case 254: {
                    message.ChatTemplateCategories = $root.ResponseGetChatTemplateCategories.decode(reader, reader.uint32());
                    break;
                }
            case 255: {
                    message.ChatTemplateLanguages = $root.ResponseGetChatTemplateLanguages.decode(reader, reader.uint32());
                    break;
                }
            case 256: {
                    message.ChatTemplates = $root.ResponseGetChatTemplates.decode(reader, reader.uint32());
                    break;
                }
            case 257: {
                    message.MeetingDetails = $root.ResponseMeetingDetails.decode(reader, reader.uint32());
                    break;
                }
            case 258: {
                    message.ChatMessageChanged = $root.NotificationChatMessageChanged.decode(reader, reader.uint32());
                    break;
                }
            case 259: {
                    message.CrmContactList = $root.ResponseCrmContacts.decode(reader, reader.uint32());
                    break;
                }
            case 260: {
                    message.FoundCrmContacts = $root.ResponseSearchCrmContacts.decode(reader, reader.uint32());
                    break;
                }
            case 261: {
                    message.CallControlResponse = $root.ResponseCallControl.decode(reader, reader.uint32());
                    break;
                }
            case 262: {
                    message.CallQualityReport = $root.QualityReport.decode(reader, reader.uint32());
                    break;
                }
            case 263: {
                    message.BatchExactMatchResult = $root.ResponseBatchExactMatch.decode(reader, reader.uint32());
                    break;
                }
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };
    /**
     * GenericMessage typeMap
     * @member {Array.<any>} typeMap
     * @memberof GenericMessage
     * @static
     */
    GenericMessage.typeMap = {
        100 : Login,
        101 : Logout,
        102 : RequestMyInfo,
        103 : RequestChangeStatus,
        104 : RequestLookupContact,
        105 : RequestGetFile,
        106 : RequestCallHistory,
        107 : RequestCallHistoryCount,
        108 : RequestUpdateFwdProfile,
        109 : RequestGetExtensions,
        110 : RequestSendChatMessage,
        111 : RequestGetMyMessages,
        112 : RequestSetChatReceived,
        113 : RequestPutFile,
        114 : RequestSetVoiceMailHeard,
        115 : RequestDropCall,
        116 : RequestDivertCall,
        117 : RequestPickupCall,
        118 : RequestTransferCall,
        119 : RequestMakeCall,
        120 : RequestUpdateContact,
        121 : RequestChangeConference,
        122 : RequestGetSystemParameters,
        123 : RequestBargeInCall,
        124 : RequestConnectionAccessRights,
        125 : RequestResetMyMissedCalls,
        126 : RequestDeleteVoiceMail,
        127 : RequestPlayVoiceMail,
        128 : RequestGetFolder,
        129 : RequestDeleteFile,
        130 : RequestPlayRecording,
        131 : RequestServerTime,
        132 : RequestSetQueueStatus,
        133 : RequestUpsertScheduledConference,
        134 : RequestGetConferenceSchedule,
        135 : RequestCreateConferenceScheduleTemplate,
        136 : RequestJoinCallParticipantsToConference,
        137 : RequestRecordCall,
        138 : RequestMyChatParties,
        139 : RequestDeleteChat,
        140 : RequestGetMyRights,
        141 : RequestGetGreetings,
        142 : RequestSetGreetings,
        143 : RequestPlayVMGreetingOnPhone,
        144 : RequestRecordVMGreetingFromPhone,
        145 : RequestQueueCalls,
        146 : RequestQueueCallsCount,
        147 : RequestSetQueueCallData,
        148 : RequestDeleteCallHistory,
        149 : RequestSetQueueCallDataBulk,
        150 : RequestUpdateWebRtcInboundRules,
        151 : RequestWelcomeEmail,
        152 : RequestAutoAnswerConnection,
        153 : WebMeetingState,
        154 : RequestGetWebMeetings,
        157 : RequestPutWebMeetingReports,
        158 : RequestWebMeetingPrivateRoom,
        159 : RequestWebMeetingError,
        160 : RequestWmReinviteOrganizer,
        161 : RequestSetSharedBlfs,
        162 : RequestGetMyLastMessages,
        163 : RequestRegisterWebRTCEndpoint,
        164 : RequestWebRTCChangeSDPState,
        165 : RequestUnreadMessagesCount,
        166 : RequestJoinCalls,
        167 : RequestControlCallRecording,
        168 : RequestChangeWebClientPassword,
        169 : RequestUpdateFavorites,
        170 : RequestContactsByRef,
        171 : RequestCleanExtension,
        172 : RequestAssignExtension,
        173 : RequestSetWakeupCall,
        174 : RequestDeleteWakeupCall,
        175 : RequestGetWakeupCalls,
        176 : RequestOneTimeLogin,
        177 : RequestUpdatePushSubscriptions,
        178 : PushSubscriptionData,
        179 : RequestSendChatFile,
        180 : ChatTyping,
        181 : ConferenceState,
        182 : RequestBlacklistAnonymous,
        183 : RequestDisconnectAnonymous,
        184 : RequestGetRecordings,
        185 : RequestDeleteRecording,
        186 : RequestUpdateConversation,
        187 : RequestTransferChatTo,
        188 : RequestAgentTakesOverChat,
        189 : RequestCreateConversation,
        190 : RequestSendChatMessageToConversation,
        191 : RequestChatRating,
        192 : RequestUpdateUserSettings,
        193 : RequestExactMatch,
        194 : RequestGetContactById,
        195 : RequestStartChatMonitoring,
        196 : RequestStopChatMonitoring,
        197 : RequestWhisperMessage,
        198 : RequestCloseConversation,
        199 : RequestProviderMapping,
        500 : RequestAvailableProviders,
        501 : RequestMyQueues,
        502 : RequestGetQMLastMessages,
        503 : RequestGetConversationEvents,
        504 : RequestGetQMConversationEvents,
        505 : RequestRefreshConversation,
        506 : RequestUpdateMeetingDocuments,
        507 : WmPollings,
        508 : RequestForwardChatMessage,
        509 : RequestPutWebMeetingReports2,
        510 : RequestGetConversations,
        511 : RequestCloseMeeting,
        512 : RequestSetOfficeHoursMode,
        513 : RequestCheckIpBlocked,
        514 : RequestSetLanguage,
        515 : RequestPreviousInteractions,
        516 : RequestUpdateGroup,
        517 : RequestGetChatTemplateCategories,
        518 : RequestGetChatTemplateLanguages,
        519 : RequestGetChatTemplates,
        520 : RequestUpdateCategory,
        521 : RequestUpdateChatTemplate,
        522 : RequestBulkUpdateCategory,
        523 : RequestGetMeetingDetails,
        524 : RequestSetMessageEmoji,
        525 : RequestEditChatMessage,
        526 : RequestGetMessages,
        527 : RequestGetCrmContacts,
        528 : RequestSearchCrmContacts,
        529 : RequestBatchExactMatch,
        555 : RequestCallReport,
        556 : RequestMonitor,
        200 : LoginInfo,
        201 : MyExtensionInfo,
        202 : ResponseLookup,
        203 : ResponseFile,
        204 : ResponseCallHistory,
        205 : ResponseCallHistoryCount,
        206 : ResponseGetExtensions,
        207 : ResponseAcknowledge,
        208 : Groups,
        209 : ResponseMyMessages,
        210 : ResponseSystemParameters,
        211 : Queues,
        212 : Parkings,
        213 : IVRs,
        214 : ResponseConnectionAccessRights,
        215 : ResponseExtensionsChanged,
        216 : ResponseGetFolder,
        217 : ResponseContactChanged,
        218 : ResponseServerTime,
        219 : Conferences,
        220 : ResponseMyChatParties,
        221 : ResponseGetMyRights,
        222 : ResponseGreetings,
        223 : ResponseQueueCalls,
        224 : WebMeetings,
        226 : ResponseWebMeetingPrivateRoom,
        227 : MyWebRTCEndpoint,
        228 : ResponseWebRTCChangeSDPState,
        229 : ResponseUnreadMessagesCount,
        230 : ResponseWakeupCalls,
        231 : ResponseOneTimeLogin,
        232 : NotificationChatFileProgress,
        233 : NotificationC2CState,
        234 : ResponseRecordings,
        235 : NotificationConversationRemoved,
        236 : ResponseConversationInfo,
        237 : NotificationChatTransferred,
        238 : NotificationChatRating,
        239 : AnonymousSessionClosed,
        240 : ResponseExactMatch,
        241 : NotificationChatMessageStatus,
        242 : ResponseChatMonitoring,
        243 : ChatMonitoringEvent,
        244 : ResponseAvailableProviders,
        245 : ResponseMyQueues,
        246 : ResponseConversationEvents,
        247 : WmBridgeParameters,
        248 : MeetingRows,
        249 : ResponseGetConversations,
        250 : MeetingStateNotification,
        251 : MeetingStateNotifications,
        252 : ResponseCheckIpBlocked,
        253 : ResponsePreviousInteractions,
        254 : ResponseGetChatTemplateCategories,
        255 : ResponseGetChatTemplateLanguages,
        256 : ResponseGetChatTemplates,
        257 : ResponseMeetingDetails,
        258 : NotificationChatMessageChanged,
        259 : ResponseCrmContacts,
        260 : ResponseSearchCrmContacts,
        261 : ResponseCallControl,
        262 : QualityReport,
        263 : ResponseBatchExactMatch,
    };

    return GenericMessage;
})();

export { $root as default };
