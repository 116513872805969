import { convertTextToEmoji } from '../../emojione-light/emojione-light';
import linkifyHtml from 'linkify-html';
import { escapeHtml } from '@webclient/chat/chat-service/escape';

/**
 * Warning. This function return unsafe string if used with unsafe string.
 * Don't use it directly for display as html
 * @param str Unsafe string
 */
function expandLinks(str: string) {
    try {
        return str ? linkifyHtml(str, {
            target: '_blank',
            rel: 'noopener noreferrer',
        }) : str;
    }
    catch (e) {
        // Avoid failures on malformed items
        return str;
    }
}

export function processChatMessage(unsafe: string, linkify?: boolean) {
    unsafe = escapeHtml(unsafe).trim();
    if (linkify) {
        unsafe = expandLinks(unsafe);
    }
    return convertTextToEmoji(unsafe);
}
