import { Pipe, PipeTransform } from '@angular/core';

export enum TimeIntervalMinutes {
    M15 = 15,
    M30 = 30,
    H1 = 60,
    H2 = 120,
    H12 = 720,
    D1 = 1440,
    D2 = 2880,
    W1 = 10080,
    W2 = 20160,
    W3 = 30240,
    M1 = 40320,
}

export const TIME_INTERVAL_TRANSLATIONS: Record<TimeIntervalMinutes, string> = {
    [TimeIntervalMinutes.M15]: '_i18n.OverrideProfile15min',
    [TimeIntervalMinutes.M30]: '_i18n.OverrideProfile30min',
    [TimeIntervalMinutes.H1]: '_i18n.OverrideProfile1hour',
    [TimeIntervalMinutes.H2]: '_i18n.OverrideProfile2hours',
    [TimeIntervalMinutes.H12]: '_i18n.OverrideProfile12hours',
    [TimeIntervalMinutes.D1]: '_i18n.OverrideProfile1day',
    [TimeIntervalMinutes.D2]: '_i18n.OverrideProfile2days',
    [TimeIntervalMinutes.W1]: '_i18n.OverrideProfile1week',
    [TimeIntervalMinutes.W2]: '_i18n.OverrideProfile2week',
    [TimeIntervalMinutes.W3]: '_i18n.OverrideProfile3week',
    [TimeIntervalMinutes.M1]: '_i18n.OverrideProfile1month',
};

@Pipe({ name: 'appTimeIntervalText', standalone: true })
export class AppTimeIntervalTextPipe implements PipeTransform {
    transform(interval: TimeIntervalMinutes | null | undefined): string {
        return (interval && TIME_INTERVAL_TRANSLATIONS[interval]) || '';
    }
}
