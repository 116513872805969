/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PbxScheduledReportType = 'None' | 'CallLogs' | 'ChatLogs' | 'AuditLogs' | 'InboundRules' | 'QueueAbandonedCalls' | 'QueueAnsweredCallsByWaitingTime' | 'QueueCallbacks' | 'QueueFailedCallbacks' | 'QueuePerformanceOverview' | 'QueueDetailedStatistics' | 'QueueTeamGeneralStatistics' | 'SlaStatistics' | 'SlaBreaches' | 'AgentInQueueStatistics' | 'AgentLoginHistory' | 'ExtensionsStatisticsByRingGroups' | 'ExtensionStatistics' | 'CallCostByExtensionDept' | 'QueueChatPerformance' | 'QueueAgentsChat' | 'AbandonedChats' | 'RingGroups';

export const PbxScheduledReportType = {
    None: 'None' as PbxScheduledReportType,
    CallLogs: 'CallLogs' as PbxScheduledReportType,
    ChatLogs: 'ChatLogs' as PbxScheduledReportType,
    AuditLogs: 'AuditLogs' as PbxScheduledReportType,
    InboundRules: 'InboundRules' as PbxScheduledReportType,
    QueueAbandonedCalls: 'QueueAbandonedCalls' as PbxScheduledReportType,
    QueueAnsweredCallsByWaitingTime: 'QueueAnsweredCallsByWaitingTime' as PbxScheduledReportType,
    QueueCallbacks: 'QueueCallbacks' as PbxScheduledReportType,
    QueueFailedCallbacks: 'QueueFailedCallbacks' as PbxScheduledReportType,
    QueuePerformanceOverview: 'QueuePerformanceOverview' as PbxScheduledReportType,
    QueueDetailedStatistics: 'QueueDetailedStatistics' as PbxScheduledReportType,
    QueueTeamGeneralStatistics: 'QueueTeamGeneralStatistics' as PbxScheduledReportType,
    SlaStatistics: 'SlaStatistics' as PbxScheduledReportType,
    SlaBreaches: 'SlaBreaches' as PbxScheduledReportType,
    AgentInQueueStatistics: 'AgentInQueueStatistics' as PbxScheduledReportType,
    AgentLoginHistory: 'AgentLoginHistory' as PbxScheduledReportType,
    ExtensionsStatisticsByRingGroups: 'ExtensionsStatisticsByRingGroups' as PbxScheduledReportType,
    ExtensionStatistics: 'ExtensionStatistics' as PbxScheduledReportType,
    CallCostByExtensionDept: 'CallCostByExtensionDept' as PbxScheduledReportType,
    QueueChatPerformance: 'QueueChatPerformance' as PbxScheduledReportType,
    QueueAgentsChat: 'QueueAgentsChat' as PbxScheduledReportType,
    AbandonedChats: 'AbandonedChats' as PbxScheduledReportType,
    RingGroups: 'RingGroups' as PbxScheduledReportType
};

