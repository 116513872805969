import { BridgesContactDb } from './bridges-contact-db';
import { ContactDb, IContactDb } from './contact-db';
import { MergedContactDb } from './merged-contact-db';
import { Observable } from 'rxjs';
import { Contact, DnType, ResponseSystemParameters } from '@myphone';
import { AppContactType, localBridgeId } from '@webclient/myphone/app-contact-type';
import {
    AppContact, defaultContact, myPhoneContactBridgeId, uniqueId
} from './contact';

export class ContactMatcher {
    // Use this to seek general phone number
    public readonly contactDb: IContactDb;
    // Use this to seek number for a local line call
    public readonly localContactDb: IContactDb;

    // TODO hide implementation from application except join code
    // All known extensions with presence including bridge/local extensions
    public readonly bridgesContactDb: BridgesContactDb;
    // All known extension mobile phones with presence grouped by bridge including bridge/local extensions
    public readonly bridgesMobilesContactDb: ContactDb;
    // All known queue with presence
    public readonly queueContactDb: ContactDb;

    constructor(systemParameters$: Observable<ResponseSystemParameters>) {
        this.bridgesContactDb = new BridgesContactDb(systemParameters$);
        this.bridgesMobilesContactDb = new ContactDb(systemParameters$);
        this.queueContactDb = new ContactDb(systemParameters$);

        this.contactDb = new MergedContactDb([
            this.bridgesContactDb,
            this.bridgesMobilesContactDb,
        ]);

        this.localContactDb = new MergedContactDb([
            this.bridgesContactDb,
            this.queueContactDb
        ]);
    }

    findContactByDn(num: string, dnType: DnType, dn?: string) {
        if (dnType === DnType.ExternalLine) {
            return this.contactDb.findPhone(num, dn);
        }
        else {
            return this.localContactDb.findPhone(num, localBridgeId);
        }
    }

    public createMergedContact(contact: Contact | undefined): AppContact {
        if (contact && AppContact.myPhoneTypeContactMapper(contact) === AppContactType.Extension) {
            const bridge = this.bridgesContactDb._bridges[myPhoneContactBridgeId(contact)];
            const groupContact = bridge ? bridge.contacts.findById(uniqueId(contact)) : undefined;
            return groupContact || AppContact.create(contact);
        }
        return contact ? AppContact.create(contact) : new AppContact(defaultContact.id, defaultContact.type, defaultContact.bridgeId, {
            isDummy: true
        });
        // return new AppContact(defaultContact.id, defaultContact.type, defaultContact.bridgeId, {
        //     isDummy: true
        // });
    }
}
