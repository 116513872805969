import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MyCall } from '@webclient/phone/mycall';
import { SettingsService } from '@webclient/settings.service';
import { Observable, of, Subscription, tap } from 'rxjs';
import { adaptiveFontSize } from './dialer-utility.service';
import { IntegrationService } from '@webclient/layout/integration.service';
import { DnType } from '@myphone';
import { delay, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppContact, defaultContact } from '@webclient/myphone/contact';
import { PhonebookEditorService } from '@webclient/phonebook-editor/phonebook-editor.service';
import { ContactSearcherService } from '@webclient/shared/service/contact-searcher.service';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { ModalService } from '@webclient/modal/app-modal.service';
import { ContactViewMode } from '@webclient/phonebook-editor/contact-view-mode';
import {
    ContactCreationStatus,
    ContactCreationType
} from '@webclient/phonebook/contact-creation-error/contact-creation-result';
import { ContactViewModel } from '@webclient/phonebook-editor/contact-view-model';
import { AppContactType } from '@webclient/myphone/app-contact-type';
import { String } from '@webclient/shared/string.utils';

export enum CallerInfoMode {
    Normal = 0,
    InCallKeyPad = 1,
    OnlyDuration = 2,
    NoInfo = 3
}

@Component({
    selector: 'caller-info',
    templateUrl: 'caller-info.component.html',
    styleUrls: ['caller-info.component.scss'],
})
export class CallerInfoComponent implements OnInit, OnDestroy {
    public hasClientCrmIntegration$: Observable<boolean>;

    constructor(
        private settingsService: SettingsService,
        private integrationService: IntegrationService,
        private router: Router,
        private phonebook: PhonebookEditorService,
        private myPhone: MyPhoneService,
        private modalService: ModalService,
        private contactSearcherService: ContactSearcherService,
    ) {}

    ngOnInit(): void {
        this.hasClientCrmIntegration$ = this.integrationService.hasClientCrmIntegration$;
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    @Input()
    public myCall: MyCall;

    @Input()
    public mode:CallerInfoMode = CallerInfoMode.Normal;

    @ViewChild('callNumber', { static: false })
    callNumber: ElementRef;

    @Input()
    public callState: string;

    private readonly subscriptions = new Subscription();

    public get adaptiveFontSize(): number {
        if (this.myCall && this.myCall.phone) {
            return adaptiveFontSize(this.myCall.phone.length, 24, 13);
        }
        else {
            return 24;
        }
    }

    public CallerInfoMode = CallerInfoMode;

    get canOpenClientCrmUrl() {
        return (this.myCall.phone !== 'MakeCall') &&
            (this.myCall.otherPartyDnType === DnType.ExternalLine) &&
            ((this.myCall.phone !== 'LiveChat' && !!this.myCall.phone) || (this.myCall.phone === 'LiveChat' && !!this.myCall.contact.emailAddress));
    }

    openClientCrmUrl() {
        if (this.myCall.phone && this.myCall.phone !== 'LiveChat') {
            this.integrationService.openClientCrmUrl(this.myCall.phone, undefined);
        }
        else if (this.myCall.contact.emailAddress) {
            this.integrationService.openClientCrmUrl(undefined, this.myCall.contact.emailAddress);
        }
    }

    // explicitly checks also the case where we have "Last, First" in the display name
    private matchesName(part: string) {
        return part === this.myCall.contact.firstNameLastName ||
            part === this.myCall.contact.lastNameFirstName ||
            part === this.lastFirstSplitByComma(this.myCall.contact);
    }

    showCrmUrlInPhonebookContactName() {
        const displayNameParts = this.myCall.displayName.split(':');
        return !!this.myCall.contact?.crmUrl && (
            this.myCall.displayName === this.myCall.phone ||
            (displayNameParts.some((part) => this.matchesName(part)) && this.myCall.contact?.type === AppContactType.CompanyPhonebook) ||
            (displayNameParts.filter((part) => this.matchesName(part)).length > 1 && this.myCall.contact?.type === AppContactType.PersonalPhonebook)
        );
    }

    get isExternalCrmContact() {
        return this.myCall.contact?.type === AppContactType.External;
    }

    get isShowUnknown() {
        return String.isNullOrEmpty(this.myCall.phone);
    }

    get displayedParts(): string[] {
        const displayNameParts = this.myCall.displayName?.split(':') ?? [];
        return displayNameParts.filter((part) => {
            return part !== this.myCall.phone && !(this.myCall.contact.isPhonebookContact() && this.matchesName(part));
        });
    }

    // Special case to check: when the call contact is from Company Phonebook and the ordering is LN/FN
    // the server returns the call display name as "LastName, FirstName" so we have to check for that explicitly
    lastFirstSplitByComma(contact: AppContact) {
        return [contact.lastName, contact.firstName].filter(Boolean).join(', ');
    }

    viewContact(contact: AppContact) {
        if (!contact || !contact.id) {
            return;
        }

        this.router.navigate(['contacts/view', contact.type, contact.id]);
    }

    get canBeAdded() {
        return this.myCall.contact.canBeAddedToPhonebook() &&
            !this.myCall.contact.crmUrl && // TODO: Why?
            this.myCall.phone !== 'LiveChat' &&
            this.myCall.otherPartyDnType === DnType.ExternalLine &&
            this.myCall.phone.length !== 0;
    }

    addToContacts(myCall: MyCall) {
        // const contactUpdated$ = this.myPhone.myPhoneSession.pipe(switchMap((session) => session.contactUpdated$));
        const isLastFirst$ = this.myPhone.myPhoneSession.pipe(map((session) => session.systemParameters.IsLastFirst));

        this.phonebook.addToContact(myCall.phone, ContactViewMode.Compact).pipe(
            tap(() => {
                this.myCall.isUrlAutoOpened = true;
            }),
            delay(500),
            switchMap(({ model, creationResults }) => {
                const isLocalContactCreated = creationResults.some(result =>
                    (result.type === ContactCreationType.Personal || result.type === ContactCreationType.Company) &&
                        result.status === ContactCreationStatus.Success
                );
                const externalContactCreated = creationResults.find(result => (result.type === ContactCreationType.Crm || result.type === ContactCreationType.Microsoft365) && result.status === ContactCreationStatus.Success)?.createdContact;
                if (model && isLocalContactCreated) {
                    return this.requestLocalContactCreated$(myCall, model).pipe(
                        map((localContactCreated) => {
                            localContactCreated.crmUrl = externalContactCreated?.crmUrl ?? '';
                            return localContactCreated;
                        })
                    );
                }
                else {
                    return of(externalContactCreated);
                }
            }),
            withLatestFrom(isLastFirst$),
            take(1)
        ).subscribe({
            next: ([contactCreated, isLastFirst]) => {
                if (contactCreated && !contactCreated.isDummy) {
                    if (this.myCall.contact.crmUrl) {
                        contactCreated.crmUrl = this.myCall.contact.crmUrl;
                    }
                    this.myCall.contact = contactCreated;
                    const append = (isLastFirst ? contactCreated.lastNameFirstName : contactCreated.firstNameLastName).trim();
                    this.myCall.displayName = `${myCall.displayName}${append ? ':' + append : ''}`;
                }
            },
            error: (error: unknown) => {
                this.modalService.error(error);
            }
        });
    }

    private requestLocalContactCreated$(myCall: MyCall, model: ContactViewModel) {
        if (model && model.hasPhoneNumber(myCall.phone)) {
            return this.contactSearcherService.requestContactByNumber(myCall.phone);
        }
        else {
            return of(defaultContact);
        }
    }
}
