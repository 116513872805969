import { MyCall } from '../phone/mycall';
import {
    defer, Observable, race, Subject, timer
} from 'rxjs';
import {
    actionAnswer,
    callNotificationDisplayTimeout,
    chatNotificationDisplayTimeout,
    LocalConnectionAction,
    NotificationServiceImpl
} from './notification-service-interface';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import {
    filter, map, switchMap, take
} from 'rxjs/operators';
import { PushChatMessage } from '@webclient/notifications/push-chat-message';
import { unescapeHtml } from '../chat/chat-service/escape';

export class WebNotificationService implements NotificationServiceImpl {
    public readonly notificationActions$: Observable<LocalConnectionAction>;
    private readonly notificationActionsSubject$ = new Subject<LocalConnectionAction>();
    private readonly messageNotificationRemover$: Subject<number>;
    private readonly messageAllNotificationsRemover$: Subject<void>;

    // Promise chain - we use it prevent race conditions in notification sequence http://mantis.3cx.info/view.php?id=20086
    // some time instead of sequence: create -> update -> close
    // next sequence was happened   : create -> close -> update(that actually create new one)
    // Because of attempt to close notification that doesn't exist yet
    private promise: Promise<any> = Promise.resolve();

    constructor(private translate: TranslateService, private router: Router) {
        this.notificationActions$ = this.notificationActionsSubject$.asObservable();
        this.messageNotificationRemover$ = new Subject<number>();
        this.messageAllNotificationsRemover$ = new Subject();
    }

    public createChatNotification(message: PushChatMessage, silent = true) {
        this.chatNotificationBuilder(message, silent).pipe(
            switchMap((notification) => {
                return race(
                    timer(chatNotificationDisplayTimeout),
                    this.messageNotificationRemover$.pipe(filter(x => x === message.conversationId)),
                    this.messageAllNotificationsRemover$
                ).pipe(map(() => {
                    return notification;
                }));
            }),
            take(1)
        ).subscribe({
            next: (notification) => {
                notification.close();
            },
            error: () => {}
        });
    }

    private chatNotificationBuilder(message: PushChatMessage, silent: boolean): Observable<Notification> {
        return defer(() => new Promise<Notification>((resolve) => {
            // here set options.silent
            const options: NotificationOptions = {
                timestamp: message.eventTime,
                renotify: true,
                body: unescapeHtml(message.text),
                tag: message.chatLink,
                silent
            };
            if (message.avatar) {
                options.icon = message.avatar;
            }
            const notification = new Notification(`${message.sender}`, options);

            notification.addEventListener('click', () => {
                notification.close();
                this.router.navigateByUrl(message.chatLink);
                window.focus();
            });
            resolve(notification);
        }));
    }

    // Attach new item to promise chain
    next(func: () => (PromiseLike<any>|any)|undefined|null) {
        this.promise = this.promise.then(func, func);
    }

    createCallNotification(myCall: MyCall, icon: string, silent: boolean) {
        this.next(() => new Promise((resolve) => {
            const incomingCallTranslationKey = myCall.isQueueCall ? '_i18n.Incoming_queue_call' : '_i18n.Incoming_call';
            this.translate.get([incomingCallTranslationKey, '_i18n.Unknown']).subscribe(translatedText => {
                const localConnectionId = myCall.localConnectionId;
                const body = myCall.phone || myCall.displayName ? `${myCall.phone} ${myCall.displayName}` : translatedText['_i18n.Unknown'];

                const options: NotificationOptions = {
                    requireInteraction: true,
                    body,
                    tag: '' + localConnectionId,
                    silent
                };
                if (icon) {
                    options.icon = icon;
                }

                try {
                    const notification = new Notification(translatedText[incomingCallTranslationKey], options);
                    notification.addEventListener('show', () => {
                        setTimeout(() => this.next(() => notification.close()), callNotificationDisplayTimeout);

                        notification.addEventListener('click', () => {
                            this.next(() => notification.close());
                            this.notificationActionsSubject$.next(new LocalConnectionAction({
                                action: actionAnswer,
                                localConnectionId
                            }));
                        });

                        notification.addEventListener('close', () => {
                            myCall.notification = undefined;
                        });

                        myCall.notification = notification;
                    });
                }
                catch (e) {
                    // We failed to send notification
                }
                resolve(myCall);
            });
        })
        );
    }

    public removeChatNotification(conversationId?: number): void {
        if (conversationId) {
            this.messageNotificationRemover$.next(conversationId);
        }
        else {
            this.messageAllNotificationsRemover$.next();
        }
    }

    removeCallNotification(myCall: MyCall) {
        this.next(() => {
            if (myCall.notification) {
                myCall.notification.close();
            }
        });
    }
}
