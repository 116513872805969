import { Component, Input } from '@angular/core';

@Component({
    selector: 'show-error-warning',
    template: `
        <div class="val-errors" *ngIf="showErrorMsgIf === undefined ? errorMessage : showErrorMsgIf">
            {{ errorMessage | translate}}
        </div>
    `
})
export class ShowErrorWarningComponent {
    @Input()
    errorMessage: string;

    @Input()
    showErrorMsgIf?: boolean;
}
