import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, Self,
    HostBinding, ViewChild, OnChanges, SimpleChanges,
} from '@angular/core';
import { ContactSearcherService } from '@webclient/shared/service/contact-searcher.service';
import { combineLatest, Observable, of, ReplaySubject, switchMap, takeUntil } from 'rxjs';
import { distinctUntilChanged, finalize, map, take } from 'rxjs/operators';
import { AppContactType } from '../../myphone/app-contact-type';
import { SearchContext } from '../search/search-context';
import { SearchResult } from '../search/search-result';
import { isValidEmail } from '../utils.service';
import { SearchRuntimeContextService } from '@webclient/shared/service/search-runtime-context.service';
import { publishRef } from '@webclient/rx-share-utils';
import { DestroyService } from '@webclient/services/destroy.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FOCUSABLE, Focusable } from '@webclient/standalones/directives';

@Component({
    selector: 'find-contact',
    template: `
        <field-wrapper class="mb-0">
            <div class="input-group">
                <div class="position-relative flex-1">
                <ng-select
                    [items]="suggestions$ | async"
                    notFoundText=""
                    loadingText=""
                    [placeholder]="placeholder || ''"
                    typeToSearchText=""
                    [typeahead]="runtimeSearch.search$"
                    [disabled]="disabled"
                    [clearable]="false"
                    (search)="search($event.term)"
                    (keydown.enter)="trySearchAndSelectContact($event)"
                    [ngModel]="''"
                    (change)="onItemSelected($event)"
                    data-qa="select">
                    <ng-template ng-option-tmp let-item="item">
                        <div *ngIf="item" class="contact-suggest-item d-flex mw-0 align-items-center gap-1 p-1"
                             data-qa="contact-suggest-item">
                            <app-avatar [ext]="item.contact" class="m-0" size="s"></app-avatar>
                            <div class="d-flex flex-column gap-1">
                                <span data-qa="item-name" class="text-sm" [truncatedText]="item.contact | lforfl:item.name | async"></span>
                                <span data-qa="item-number" *ngIf="!item.isVoicemail" class="text-xs"
                                      truncatedText='{{item.number}} {{(item.contact | contactPhoneType : item.number | async)||(item.contact.profile | userProfile: item.contact.isBusy : item.contact.isRegistered |async)}}'>
                                </span>
                                <span data-qa="item-vmail" class="text-xs" *ngIf="item.isVoicemail"
                                      [truncatedText]='(isSystemExtension(item) ? "_i18n.GroupVoicemail" : "_i18n.VoiceMail") | translate'>
                                </span>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template ng-label-tmp>
                        <!--never show selected item-->
                    </ng-template>
                </ng-select>
                </div>
                <button *ngIf="showAddButton" class="btn btn-border border-start-0" [disabled]="disabled"
                        type="button" data-qa="add-contact" (click)="trySearchAndSelectContact($event)" app-plus-solid-icon>
                </button>
            </div>
        </field-wrapper>
    `,
    styleUrls: ['./find-contact.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [SearchRuntimeContextService, DestroyService, { provide: FOCUSABLE, useExisting: FindContactComponent }]
})
export class FindContactComponent implements OnChanges, Focusable {
    @Input() showAddButton?: boolean;
    @Input() disabled?: boolean;
    @Input() placeholder?: string | null;
    @Input({ required: true }) searchContext: SearchContext | null;

    @Output() readonly contactSelected = new EventEmitter<SearchResult>();

    @HostBinding('class.form-input') readonly formInput = true;

    @ViewChild(NgSelectComponent, { static: true }) ngSelect: NgSelectComponent;

    private readonly searchContext$ = new ReplaySubject<SearchContext>(1);

    readonly suggestions$: Observable<SearchResult[]> = this.searchContext$.pipe(
        distinctUntilChanged(),
        switchMap(ctx => combineLatest([
            this.runtimeSearch.getContacts(ctx),
            this.runtimeSearch.getCrmContacts(ctx)
        ])),
        map(([local, crm]) => local.concat(crm.contacts)),
        publishRef()
    );

    searchText = '';

    constructor(
        public runtimeSearch: SearchRuntimeContextService,
        private contactSearcher: ContactSearcherService,
        private cd: ChangeDetectorRef,
        @Self() private destroy$: DestroyService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchContext && this.searchContext) {
            this.searchContext$.next(this.searchContext);
        }
    }

    isSystemExtension(item: SearchResult) {
        return item.contact?.type === AppContactType.SystemExtension;
    }

    focus() {
        this.ngSelect.focus();
    }

    onItemSelected(item: SearchResult | undefined) {
        // item is undefined on clear clicked
        if (item) {
            this.contactSelected.next(item);
        }
        this.search('');
    }

    search(text: string) {
        // memorize the text for extended contact search, when suggestions are empty
        this.searchText = text?.trim() || '';
    }

    trySearchAndSelectContact(event: Event) {
        event.stopPropagation();

        // if ng-select selected item on enter, then it cleared the search, and next method will do nothing
        const search = this.searchText;

        if (!search) {
            return;
        }
        const isEmail = isValidEmail(search);

        this.suggestions$.pipe(
            switchMap((suggestions) =>
                (suggestions.length > 0
                    // we already have results so on Enter or add button click just add the first one
                    ? of(suggestions[0])
                    // no search results so attempt to explicitly search for contacts
                    : this.searchContext$.pipe(
                        switchMap(ctx => (
                            isEmail
                                ? this.contactSearcher.requestContactByEmail(search, ctx)
                                : this.contactSearcher.requestContactByNumber(search, ctx)
                        )),
                        map((contact) => new SearchResult({
                            name: contact.firstNameLastName,
                            number: search,
                            // empty in case of no matching internal contact
                            contact: contact.isDummy ? undefined : contact,
                            isEmail
                        }))
                    ))
            ),
            take(1),
            finalize(() => this.search('')),
            takeUntil(this.destroy$)
        ).subscribe((searchResult => {
            this.onItemSelected(searchResult);
        }));
    }
}
