import type { FilterValue, SerializedFilter } from '../../types';
import type { ValidationErrors } from '@angular/forms';
import type { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';

export enum DateRangeType {
    Last30Days,
    LastMonth,
    LastYear,
    Custom,
    ThisMonth,
    ThisYear,
    Today,
    Yesterday,
    Last7Days,
    Last12Hours,
    Last24Hours
}

export interface DateRangeFilterParams {
    periodType: DateRangeType | null;
    periodFromTo: [Date, Date] | null;
}

type DateRangeFilterExactParams = { periodType: DateRangeType.Custom; periodFromTo: [Date, Date] } | { periodType: DateRangeType };

export type NonNullableProps<T> = {
    [P in keyof T]: NonNullable<T[P]>;
};

const DATE_RANGE_LABEL: Record<DateRangeType, string> = {
    [DateRangeType.Today]: '_i18n.Today',
    [DateRangeType.Yesterday]: '_i18n.Yesterday',
    [DateRangeType.Last7Days]: '_i18n.Last7Days',
    [DateRangeType.Last30Days]: '_i18n.ReportsFilterLast30Days',
    [DateRangeType.ThisMonth]: '_i18n.ThisMonth',
    [DateRangeType.LastMonth]: '_i18n.ReportsFilterLastMonth',
    [DateRangeType.ThisYear]: '_i18n.ThisYear',
    [DateRangeType.LastYear]: '_i18n.ReportsFilterLastYear',
    [DateRangeType.Custom]: '_i18n.ReportsFilterDateRange',
    [DateRangeType.Last12Hours]: '_i18n.Last12Hours',
    [DateRangeType.Last24Hours]: '_i18n.Last24Hours',
};

export const translateDateRangeType = (value: DateRangeType | null | undefined) => {
    return value != null ? DATE_RANGE_LABEL[value] : '';
};

function isDateRangeType(value: unknown): value is DateRangeType {
    return typeof value === 'number' && Object.values(DateRangeType).includes(value);
}

export function dateRangeDeserializeDate(date: string): Date | null {
    if (!/^\d{4}-\d{2}-\d{2}$/.test(date)) {
        return null;
    }
    const [year, month, day] = date.split('-');
    return new Date(+year, +month - 1, +day);
}

function twoDigits(num: number) {
    return num < 10 ? '0' + num : num.toString();
}

export function dateRangeSerializeDate(date: Date): string {
    return date.getFullYear() + '-' + twoDigits(date.getMonth() + 1) + '-' + twoDigits(date.getDate());
}

/**
 * Query param name of reference date for floating period calculation.
 * We do not use two different params with same purpose, so the name is fixed.
 */
export const REF_DATE_PARAM = 'refDate';

export class DateRangeFilterValue implements FilterValue<DateRangeFilterParams> {
    private periodType: DateRangeFilterParams['periodType'];
    private periodFromTo: DateRangeFilterParams['periodFromTo'];

    readonly label = '_i18n.ReportsRange';

    constructor(value: DateRangeFilterExactParams = { periodType: DateRangeType.Today }) {
        // construction value should not be autocorrected, only on changes from user
        this.periodType = value.periodType;
        this.periodFromTo = 'periodFromTo' in value ? value.periodFromTo : null;
    }

    getDisplayValue(translate: TranslateService): string {
        const { periodType } = this.viewValue;

        return periodType === DateRangeType.Custom
            ? this.periodDisplayValue
            : translate.instant(translateDateRangeType(periodType));
    }

    getDisplayValueForPrint(translate: TranslateService): string {
        const { periodType } = this.value;
        const period = this.periodDisplayValue;

        return `${translate.instant(translateDateRangeType(periodType))}${period ? ` (${period})` : ''}`;
    }

    private extractValue() : NonNullableProps<DateRangeFilterParams> {
        const noErrors = !this.validate();
        // if period is supplied then use it, even if it does not match the type
        const periodType = (noErrors && this.periodType !== null) ? this.periodType : DateRangeType.Today;
        const [periodFrom, periodTo] = (noErrors && this.periodFromTo) || DateRangeFilterValue.getPeriodFromTo(periodType);

        return {
            periodType,
            periodFromTo: [periodFrom, periodTo]
        };
    }

    // used only for data source parameters
    get value(): NonNullableProps<DateRangeFilterParams> {
        const { periodType, periodFromTo } = this.extractValue();
        const [periodFrom, periodTo] = periodFromTo;

        // for days periods:
        // start date should be a day with local time 00:00:00
        // end date should be the next of the last day with local time 00:00:00 to cover the whole range
        // dates will go to server as UTC with shifted hours
        // for hours periods dates are already exact
        return {
            periodType,
            periodFromTo: DateRangeFilterValue.isHoursPeriodType(periodType) ? periodFromTo : [
                new Date(periodFrom.getFullYear(), periodFrom.getMonth(), periodFrom.getDate()),
                new Date(periodTo.getFullYear(), periodTo.getMonth(), periodTo.getDate() + 1)
            ]
        };
    }

    set value(value: DateRangeFilterParams) {
        this.periodType = value.periodType;
        // here we drop irrelevant data from filter form
        this.setPeriodFromTo(value.periodType === DateRangeType.Custom ? value.periodFromTo : null);
    }

    // use viewValue only for labels, serialization and display
    get viewValue(): NonNullableProps<DateRangeFilterParams> {
        const { periodType, periodFromTo } = this.extractValue();
        const [periodFrom, periodTo] = periodFromTo;

        // for days periods:
        // start date should be a day with local time 00:00:00
        // end date should be a day with local time 23:59:59
        // for hours periods dates are already exact
        return {
            periodType,
            periodFromTo: DateRangeFilterValue.isHoursPeriodType(periodType) ? [periodFrom, periodTo] : [
                new Date(periodFrom.getFullYear(), periodFrom.getMonth(), periodFrom.getDate()),
                new Date(periodTo.getFullYear(), periodTo.getMonth(), periodTo.getDate(), 23, 59, 59)
            ]
        };
    }

    private setPeriodFromTo(periodFromTo: DateRangeFilterParams['periodFromTo']) {
        if (periodFromTo) {
            const [from, to] = periodFromTo;
            this.periodFromTo = [from > to ? to : from, to];
        }
        else {
            this.periodFromTo = null;
        }
    }

    private get periodDisplayValue(): string {
        const { periodFromTo } = this.viewValue;

        return periodFromTo ? `${dayjs(periodFromTo[0]).format('L')} - ${dayjs(periodFromTo[1]).format('L')}` : '';
    }

    /** Deserialize all parameters as is, period may not match periodType for old links and should be preserved */
    static deserialize(serializedValue: SerializedFilter, initialValue?: DateRangeFilterParams): DateRangeFilterValue {
        const value = new DateRangeFilterValue();

        if (initialValue) {
            value.value = initialValue;
        }

        if ('periodType' in serializedValue && isDateRangeType(serializedValue.periodType)) {
            value.periodType = serializedValue.periodType;
        }

        if (
            value.periodType === DateRangeType.Custom
            && 'periodFromTo' in serializedValue
            && Array.isArray(serializedValue.periodFromTo)
            && serializedValue.periodFromTo.length === 2
        ) {
            const from = dateRangeDeserializeDate(serializedValue.periodFromTo[0]);
            const to = dateRangeDeserializeDate(serializedValue.periodFromTo[1]);

            if (from && to && !isNaN(from.getTime()) && !isNaN(to.getTime())) {
                value.setPeriodFromTo([from, to]);
            }
        }
        else if (
            value.periodType !== DateRangeType.Custom
            && REF_DATE_PARAM in serializedValue
            && typeof serializedValue[REF_DATE_PARAM] === 'string'
        ) {
            const reportDate = dateRangeDeserializeDate(serializedValue[REF_DATE_PARAM]);

            if (reportDate != null) {
                value.setPeriodFromTo(this.getPeriodFromTo(value.periodType!, reportDate));
            }
        }

        return value;
    }

    deserialize(serializedValue: SerializedFilter): FilterValue<DateRangeFilterParams> {
        return DateRangeFilterValue.deserialize(serializedValue, this.value);
    }

    serialize(): Record<string, unknown> {
        const value = this.viewValue;

        return {
            periodType: value.periodType,
            periodFromTo: value.periodFromTo.map(dateRangeSerializeDate)
        };
    }

    validate(): ValidationErrors | null {
        if (this.periodType === DateRangeType.Custom) {
            if (!this.periodFromTo) {
                return { periodFromTo: { required: true } };
            }
            if (!this.periodFromTo.every(Boolean)) {
                return { periodFromTo: { invalid: true } };
            }
        }
        return null;
    }

    private static getPeriodFromTo(periodType: DateRangeType, today = new Date()): [Date, Date] {
        const year = today.getFullYear();
        const month = today.getMonth();
        const date = today.getDate();

        switch (periodType) {
            case DateRangeType.ThisMonth:
                return [new Date(year, month, 1), new Date(year, month + 1, 0)];
            case DateRangeType.ThisYear:
                return [new Date(year, 0, 1), new Date(year + 1, 0, 0)];
            case DateRangeType.Last12Hours: {
                const start = new Date(today);
                start.setHours(start.getHours() - 12);
                return [start, today];
            }
            case DateRangeType.Last24Hours: {
                const start = new Date(today);
                start.setHours(start.getHours() - 24);
                return [start, today];
            }
            case DateRangeType.Today:
                return [new Date(year, month, date), new Date(year, month, date)];
            case DateRangeType.Yesterday:
                return [new Date(year, month, date - 1), new Date(year, month, date - 1)];
            case DateRangeType.Last7Days:
                // last 7 including today which means subtract 6 and include today to have a total of 7
                return [new Date(year, month, date - 6), new Date(year, month, date)];
            case DateRangeType.Last30Days:
                // last 30 including today which means subtract 29 and include today to have a total of 30
                return [new Date(year, month, date - 29), new Date(year, month, date)];
            case DateRangeType.LastMonth:
                return [new Date(year, month - 1, 1), new Date(year, month, 0)];
            case DateRangeType.LastYear:
                return [new Date(year - 1, 0, 1), new Date(year, 0, 0)];
            default:
                throw new Error('Not implemented (Custom should not come here)');
        }
    }

    private static isHoursPeriodType(periodType: DateRangeType) {
        switch (periodType) {
            case DateRangeType.Last12Hours:
            case DateRangeType.Last24Hours: return true;
            default:
                return false;
        }
    }
}
