<div class="call-actions-container" [ngSwitch]="type">
    <div *ngSwitchCase="CallButtonsType.EstablishedCallActions" class="established-call-actions">
        <button *ngIf="isHoldButton()" id="btnHold" class="btnImage" (click)="HoldCall()" type="button"
                [disabled]="!myCall.isHoldEnabled">
            <span class="svg-wrapper svg-md" app-pause-solid-icon></span>
            <p>{{ '_i18n.Hold' | translate}}</p>
        </button>

        <button *ngIf="!isHoldButton()" id="btnResume" class="btnImage button-pressed" (click)="ResumeCall()" type="button"
                [disabled]="myCall.media.isNegotiationInProgress">
            <span class="svg-wrapper svg-md" app-pause-solid-icon></span>
            <p>{{ '_i18n.Resume' | translate}}</p>
        </button>

        <button id="btnMute" class="btnImage" [disabled]="!myCall.isMuteEnabled" type="button"
                [ngClass]="{'button-pressed': myCall.media.isMuted && myCall.isMuteEnabled}"
                (click)="MuteCall()">

            <span class="svg-wrapper" app-microphone-alt-light-icon></span>
            <p>{{ '_i18n.Mute' | translate}}</p>
        </button>

        <button (click)="openNewCall()" [disabled]="isDialing()" class="btnImage" id="btnNewCall" type="button">
            <span class="svg-wrapper svg-md" app-plus-light-icon></span>
            <p>{{'_i18n.Dialer_NewCall'|translate}}</p>
        </button>

        <button id="btnConference" class="btnImage" (click)="openConference()" [disabled]="!myCall.isConferenceEnabled || (myCall.isConferenceCall && !(meetingListService.anyActivePrivateOrAudio$|async))" type="button">
            <span class="svg-wrapper svg-md" app-webmeeting-custom-icon></span>
            <p>{{ '_i18n.Conference' | translate}}</p>
        </button>

        <button id="btnTransfer" class="btnImage" (click)="openTransfer()" [disabled]="!myCall.isTransferEnabled" type="button"
                *ngIf="!myCall.joinToConnectionId">
            <span class="svg-wrapper" app-call-transfer-custom-icon></span>
            <p>{{ '_i18n.Transfer' | translate}}</p>
        </button>

        <button id="btnJoin" class="btnImage" (click)="JoinCall()" [disabled]="!myCall.isTransferEnabled" type="button"
                *ngIf="myCall.joinToConnectionId">
            <span class="svg-wrapper" app-random-regular-icon></span>
            <p>{{ '_i18n.Join' | translate}}</p>
        </button>

        <button id="btnAttTransfer" class="btnImage" (click)="openAttTransfer()" [disabled]="!myCall.isAttTransferEnabled" type="button">
            <span class="svg-wrapper svg-md" app-random-regular-icon></span>
            <p>{{ '_i18n.AttTransfer' | translate}}</p>
        </button>

        <button id="btnRecord" class="btnImage" type="button"
                [ngClass]="{'button-enabled': myCall.isRecording && !myCall.isRecordingPaused}"
                (click)="callsService.record(myCall)" [disabled]="!myCall.isRecordingEnabled">
            <span class="svg-wrapper svg-md" app-waveform-lines-light-icon></span>
            <p>{{ '_i18n.Recording' | translate}}</p>
        </button>

        <button id="btnKeypad" class="btnImage" [disabled]="!canSendDtmf()" (click)="ChangeKeyPadVisibility(true)" type="button">
            <span class="svg-wrapper svg-md" app-th-solid-icon></span>
            <p>{{ '_i18n.Keypad' | translate}}</p>
        </button>

        <button id="btnVideo" class="btnImage" [disabled]="!myCall.isVideoEnabled" (click)="videoCall()" type="button"
                [ngClass]="{'button-enabled': isVideoActive()}">
            <span class="svg-wrapper svg-md" app-video-light-icon></span>
            <p>{{ '_i18n.Video' | translate}}</p>
        </button>

        <div class="empty-row">
        </div>

        <button id="btnCallDecline" class="btnImage" (click)="DropCall()" [disabled]="myCall.isTrying" type="button">
            <span class="svg-wrapper" app-phone-circle-red-background-custom-icon></span>
        </button>

        <button *ngIf="isVideoActive()" id="btnForward" class="btnImage" (click)="ChangeVideoVisibility(true)" type="button">
            <span class="svg-wrapper" app-go-forward-custom-icon></span>
        </button>

    </div>

    <div *ngSwitchCase="CallButtonsType.InCallKeyPadActions" class="in-call-keypad-actions" >
        <call-dialpad #inCallDialPad [disabled]="!canSendDtmf()"
                      (buttonPressed)="callsService.sendDtmf(myCall, $event)">
            <button id="btnKeyPadDecline" class="btnImage" (click)="DropCall()" type="button"
                    [disabled]="myCall.isTrying">
                <span class="svg-wrapper" app-phone-circle-red-background-custom-icon></span>
            </button>
            <button class="btnImage" id="btnKeyPadBack" (click)="ChangeKeyPadVisibility(false)" type="button">
                <span class="svg-wrapper svg-md" app-go-back-custom-icon></span>
            </button>
        </call-dialpad>
    </div>

    <div *ngSwitchCase="CallButtonsType.InCallSearchContactActions" class="in-call-search-contact-actions" >
        <call-dialpad #inCallSearchContactDialPad [disabled]="false" (buttonPressed)="sendSearchKey($event)">
            <button id="btnSearchPerformAction" class="btnImage" (click)="inCallSearchActionFired()" type="button">
                <span *ngIf="searchTextPopulated" class="svg-wrapper" app-phone-circle-green-background-custom-icon></span>
                <span *ngIf="!searchTextPopulated" class="svg-wrapper" app-disabled-phone-custom-icon></span>
            </button>
            <button class="btnImage" id="btnSearchContactBack" (click)="setInCallSearchAction(InCallSearchAction.None)" type="button">
                <span class="svg-wrapper svg-md" app-go-back-custom-icon></span>
            </button>
        </call-dialpad>
    </div>

    <div *ngSwitchCase="CallButtonsType.VideoCallActions" class="video-call-actions">
        <button id="btnVideoMute" class="btnImage" [disabled]="!myCall.isMuteEnabled" type="button"
                [ngClass]="{'button-pressed': myCall.media.isMuted && myCall.isMuteEnabled}"
                (click)="MuteCall()">
            <span class="svg-wrapper" app-microphone-alt-light-icon></span>
        </button>
        <button id="btnVideoDecline" class="btnImage" (click)="DropCall()" type="button"
                [disabled]="myCall.isTrying">
            <span class="svg-wrapper" app-phone-circle-red-background-custom-icon></span>
        </button>
        <button class="btnImage" id="btnVideoBack" (click)="ChangeVideoVisibility(false)" type="button">
            <span class="svg-wrapper" app-go-back-custom-icon></span>
        </button>
    </div>

    <div *ngSwitchCase="CallButtonsType.IncomingCallActions"  class="incoming-call-actions">
        <button id="btnAnswerWithVideo" class="btnImage" (click)="AnswerWithVideo()" type="button"
                [disabled]="!myCall.isAnswerEnabled || !isVideoAnswerAllowed() || myCall.isSendingWebrtcRequest">
            <span class="svg-wrapper" app-video-light-icon></span>
            <p>{{ '_i18n.Video' | translate}}</p>
        </button>
        <button id="btnDivertToVoicemail" class="btnImage" (click)="DivertToVoiceMail()" type="button">
            <span class="svg-wrapper fillIconWhite" app-envelope-light-icon></span>
            <p>{{ '_i18n.SendToVmail' | translate}}</p>
        </button>
        <button id="btnAnswer" class="btnImage" (click)="AnswerCall()" type="button"
                [disabled]="!myCall.isAnswerEnabled || myCall.isSendingWebrtcRequest" [title]="(!myCall.deviceDirectControl ? '_i18n.CantAnswerWithoutDirectCallControl' : '') | translate">
            <span class="svg-wrapper" app-phone-circle-green-background-custom-icon></span>
        </button>
        <button id="btnDecline" class="btnImage" (click)="DropCall()" type="button"
                [disabled]="myCall.isTrying">
            <span class="svg-wrapper" app-phone-circle-red-background-custom-icon></span>
        </button>
    </div>

    <div *ngSwitchDefault></div>
</div>

