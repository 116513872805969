import { ActiveCallHandler } from './active-call-handler';
import { CallDb, ConnectionMap, connectionMapToActiveCallsMap } from './call-db';
import {
    ActionType, GroupMember, Groups, IVRs, MyExtensionInfo, Parkings
} from '@myphone';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

type CallDictionary = {[id: number]: number[]}
type GroupConnDictionary = {[id: number]: CallDictionary}

export class ActiveCallsVM {
    // / <summary>
    // / Parking id -> List of Localconnection id
    // / </summary>
    private _prksCalls: CallDictionary = {};

    // / <summary>
    // / IVR's id -> List of Localconnection id
    // / </summary>
    private _ivrsCalls: CallDictionary = {};

    // / <summary>
    // / List id of local connections that connected with current extension
    // / </summary>
    private _myCalls: number[] = [];

    // / <summary>
    // / Map of group id -> ( group_member_id -> list of local connections id)
    // / </summary>
    private _grpcons: GroupConnDictionary = {};

    private _myExtensionInfoFullUpdate: boolean;
    private _IVRFullUpdate: boolean;
    private _parkingFullUpdate: boolean;
    private _localGroupsUpdate: boolean;

    private readonly _db: CallDb;

    // / <summary>
    // / Collection of view models
    // / </summary>
    public readonly activeCalls$: Observable<ConnectionMap>;

    constructor(private activeCallHandler: ActiveCallHandler) {
        this._db = new CallDb();
        this.activeCalls$ = this._db.activeCalls$.asObservable();
    }

    public getCallById(callId: number) {
        return this.activeCalls$.pipe(
            take(1),
            map(calls => connectionMapToActiveCallsMap(this.activeCallHandler, calls)),
            map(calls => calls['' + callId]));
    }

    // / <summary>
    // / Update inforamation about active calls from Parkings message
    // / </summary>
    // / <param name="prks"></param>
    public ACUpdateParkings(prks: Parkings) {
        // TODO: SY - If this method handles Parkings using this approach then it MUST handle:
        // 1. prks.Action == ActionType.FullUpdate
        //   cleanup all connections which where previously taken from any ParkInfo objects and add all which are delivered by this update
        //
        // 2. Items in the list of ParkInfo objects can have item.Action==ActionType.Deleted or item.Action==ActionType.FullUpdate
        //   cleanup all connections which where previously taken from the item
        //
        if (prks.Action === ActionType.FullUpdate) {
            this._parkingFullUpdate = true;
            Object.values(this._prksCalls).forEach((x: number[]) => x.forEach(lc => this._db.processDeleted(lc)));
            this._prksCalls = {};
        }
        else if (!this._parkingFullUpdate) {
            // console.log("Parking update skipped because no snapshot");
            return;
        }
        if (prks.Items === undefined) {
            // No items found
            return;
        }
        prks.Items.forEach(pi => {
            if (pi.Action === ActionType.Deleted || pi.Action === ActionType.FullUpdate) {
                const toDel = this._prksCalls[pi.Id];
                if (toDel !== undefined) {
                    toDel.forEach(lc => this._db.processDeleted(lc));
                    delete this._prksCalls[pi.Id];
                }
            }
            if (pi.Action === ActionType.FullUpdate || pi.Action === ActionType.Updated) {
                let toUpd = this._prksCalls[pi.Id];
                if (toUpd === undefined) {
                    this._prksCalls[pi.Id] = [];
                    toUpd = this._prksCalls[pi.Id];
                }
                this._db.updateConnections(pi.WaitingCalls, toUpd);
            }
        });
    }

    // / <summary>
    // / Update inforamation about active calls from MyExtensionInfo message
    // / </summary>
    // / <param name="myInfo"></param>
    public ACUpdateMyExtensionInfo(myInfo: MyExtensionInfo) {
        if (myInfo.Action === ActionType.FullUpdate) {
            this._myExtensionInfoFullUpdate = true;
            Object.values(this._myCalls).forEach((id) => this._db.processDeleted(id));
            this._myCalls = [];
        }
        else if (!this._myExtensionInfoFullUpdate) {
            // console.log("MyExtensionInfo update skipped because no snapshot");
            return;
        }
        this._db.updateConnections(myInfo.Connections, this._myCalls);
    }

    public ACUpdateIVRs(ivrs: IVRs) {
        // TODO: SY - If this method handles IVRs using this approach then
        // it MUST handle
        // 1. ivrs.Action==ActionType.FullUpdate
        //   cleanup all connections which where previously taken from any IVRInfo objects and add all which are delivered by this update
        // 2. Items in the list of IVRInfo objects can have item.Action==ActionType.Deleted or item.Action==ActionType.FullUpdate
        //   cleanup all connections which where previously taken from the item
        if (ivrs.Action === ActionType.FullUpdate) {
            this._IVRFullUpdate = true;
            Object.values(this._ivrsCalls).forEach((x: number[]) => x.forEach(lc => this._db.processDeleted(lc)));
            this._ivrsCalls = {};
        }
        else if (!this._IVRFullUpdate) {
            // console.log("IVR update skipped because no snapshot");
            return;
        }
        if (ivrs.Items === undefined) {
            // No items found
            return;
        }
        ivrs.Items.forEach(pi => {
            if (pi.Action === ActionType.Deleted || pi.Action === ActionType.FullUpdate) {
                const toDel = this._ivrsCalls[pi.Id];
                if (toDel !== undefined) {
                    toDel.forEach(lc => this._db.processDeleted(lc));
                    delete this._ivrsCalls[pi.Id];
                }
            }
            if (pi.Action === ActionType.FullUpdate || pi.Action === ActionType.Updated) {
                let toUpd = this._ivrsCalls[pi.Id];
                if (toUpd === undefined) {
                    this._ivrsCalls[pi.Id] = [];
                    toUpd = this._ivrsCalls[pi.Id];
                }
                this._db.updateConnections(pi.WaitingCalls, toUpd);
            }
        });
    }

    private deleteGroupConnections(groupId: number) {
        const groupConnections = this._grpcons[groupId];
        if (groupConnections !== undefined) {
            Object.values(groupConnections).forEach((grpCon: number[]) =>
                grpCon.forEach(conn => this._db.processDeleted(conn)));
            delete this._grpcons[groupId];
        }
    }

    // / <summary>
    // / Update from Groups message
    // / </summary>
    // / <param name="grps"></param>
    public ACUpdateGroups(grps: Groups) {
        if (!grps.FromLocalPbx) {
            return;
        }
        try {
            if (grps.Action === ActionType.FullUpdate) {
                this._localGroupsUpdate = true;
                this._grpcons = {};
            }
            else if (!this._localGroupsUpdate) {
                // console.log("Local groups update skipped because no snapshot");
                return;
            }
            if (grps.Items === undefined) {
                // No items found
                return;
            }
            grps.Items.forEach(grp => {
                if (grp.Action === ActionType.Deleted) {
                    this.deleteGroupConnections(grp.Id);
                }
                if (grp.Members !== undefined) {
                    // TODO I though that ActionType.Deleted means delete what is specified but here it's 'delete all'?
                    if (grp.Members.Action === ActionType.Deleted) {
                        this.deleteGroupConnections(grp.Id);
                    }
                    let groupConnections = this._grpcons[grp.Id];
                    if (groupConnections === undefined) {
                        // Init new group connections
                        this._grpcons[grp.Id] = {};
                        groupConnections = this._grpcons[grp.Id];
                    }
                    grp.Members.Items.forEach(gm => this.processGroupMember(gm, groupConnections));
                }
            });
        }
        catch (exception) {
            // TODO log exception
        }
    }

    processGroupMember(gm : GroupMember, groupConnections: CallDictionary) {
        if (!gm.Connections) {
            return;
        }
        let memberConnections = groupConnections[gm.Id];
        if (gm.Connections.Action === ActionType.FullUpdate || memberConnections === undefined) {
            if (memberConnections !== undefined) {
                memberConnections.forEach(conn => this._db.processDeleted(conn));
            }
            // Init new member connections
            groupConnections[gm.Id] = [];
            memberConnections = groupConnections[gm.Id];
        }
        if (gm.Connections) {
            this._db.updateConnections(gm.Connections, memberConnections);
        }
    }
}
