import {
    ConferenceParticipants, GroupIds, LocalConnections, MyExtensionInfo, Registrations, VoiceMails
} from '@myphone';
import { BehaviorSubject, Observable } from 'rxjs';

export class MyExtensionInfoEx extends MyExtensionInfo {
    public readonly VoiceMailBox$: Observable<VoiceMails>
        = new BehaviorSubject<VoiceMails>(new VoiceMails());

    public readonly Connections$: Observable<LocalConnections>
        = new BehaviorSubject<LocalConnections>(new LocalConnections());

    public readonly ActiveDevices$: Observable<Registrations>
        = new BehaviorSubject<Registrations>(new Registrations());

    public readonly AllowWebrtcEndpoint$: Observable<boolean>
        = new BehaviorSubject<boolean>(false);

    public readonly PrivateConference$: Observable<ConferenceParticipants>
        = new BehaviorSubject<ConferenceParticipants>(new ConferenceParticipants());

    public readonly MyGroups$: Observable<GroupIds>
        = new BehaviorSubject<GroupIds>(new GroupIds());
}
