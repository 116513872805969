export type HidConnectionAction = {
    type: 'init' | 'connect' | 'disconnect' | 'request'; device: HIDDevice | null
}

export enum UsagePage {
    TELEPHONY = 0x0b,
    LED = 0x08,
    BUTTON =0x09
}

export enum InputUsages {
    // Telephony page
    PHONE = 0x0b0001,
    HOOK_SWITCH = 0x0b0020,
    FLASH = 0x0b0021,
    FEATURE = 0x0b0022,
    HOLD = 0x0b0023,
    REDIAL = 0x0b0024,
    DROP = 0x0b0026,
    PHONE_MUTE = 0x0b002f,
    ANSWER_ON_OFF = 0x0b0074,
    LINE_BUSY_TONE = 0x0b0097,
    RINGER = 0x0b009e,
    REJECT_VENDOR = 0x0bFFFD,
    // Button page
    PROGRAMMABLE_TELEPHONY_BUTTON = 0x0b0007,
    PROGRAMMABLE_BUTTON = 0x090007,
}

export enum OnOffControlType {
    Undefined = 0,
    OnOffButtons,
    ToggleButton,
    ToggleSwitch
}

export enum OutputUsages {
    MUTE = 0x080009,
    OFF_HOOK = 0x080017,
    RING = 0x080018,
    RINGER = 0x0b009e,
    HOLD = 0x080020,
}

/**
 * OUTPUT USAGES
 */
export const OUTPUT_USAGES = [
    OutputUsages.OFF_HOOK,
    OutputUsages.RING,
    OutputUsages.MUTE,
    OutputUsages.RINGER,
    OutputUsages.HOLD,
    // TelephonyUsage.HOOK_SWITCH
    // LedUsage.MICROPHONE,
] as const;

export type OutputUsage = typeof OUTPUT_USAGES[number];

export interface OutputEventInfo {
    name: string;
    offset: number;
    reportId: number;
    state: boolean;
    generator: OutputEventGenerator;
    setter: OutputEventDataSetter;
}

export interface OutputEventData {
    reportId: number;
    data: Uint8Array;
}

interface OutputEventGenerator {
    (): OutputEventData;
}

interface OutputEventDataSetter {
    (val: boolean, data: Uint8Array): Uint8Array;
}

/**
 * OUTPUT USAGES
 */
export const INPUT_USAGES = [
    // TelephonyUsage.PHONE,
    InputUsages.PROGRAMMABLE_BUTTON,
    InputUsages.PROGRAMMABLE_TELEPHONY_BUTTON,
    InputUsages.HOOK_SWITCH,
    InputUsages.FLASH,
    // TelephonyUsage.REDIAL,
    // TelephonyUsage.DROP,
    InputUsages.PHONE_MUTE,
    // TelephonyUsage.ANSWER_ON_OFF,
    InputUsages.LINE_BUSY_TONE,
    // TelephonyUsage.REJECT_VENDOR
];

export type InputUsage = typeof INPUT_USAGES[number];

export interface InputEventInfo {
    name: string;
    reportId: number;
    offset: number;
    controlType: OnOffControlType;
    size: number;
    expectedValue: number;
}

export interface ObserverCallback {
    (val: boolean, controlType: OnOffControlType): void;
}

export type HIDReportCollectionMap = { [id: number]: HIDReport[] }
export type HIDReport = { mask: number, usagePage?: number, usages: number[] }
