import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { observe } from '@webclient/rx-utils';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { LocalStorageService } from 'ngx-webstorage';
import { ModalService } from '@webclient/modal/app-modal.service';
import { publishRef } from '@webclient/rx-share-utils';
import { ExtendedSwPushService } from '@webclient/notifications/extended-sw-push.service';

@Injectable({
    providedIn: 'root'
})
export class SilentModeService {
    public readonly silentMode$: Observable<boolean>;

    constructor(
        private localStorageService: LocalStorageService,
        private extendedSwPushService: ExtendedSwPushService,
        private modalService: ModalService
    ) {
        this.silentMode$ = observe<boolean|null>(this.localStorageService, LocalStorageKeys.SilentMode, false).pipe(
            map((IsSilentMode) => Boolean(IsSilentMode)),
            distinctUntilChanged(),
            publishRef()
        );
    }

    public setSilentMode(enable: boolean) {
        if (enable) {
            this.modalService.confirmation('_i18n.SilentModeEnableConfirmationWebclient')
                .subscribe(() => {
                    this.localStorageService.store(LocalStorageKeys.SilentMode, enable);
                });
        }
        else {
            this.localStorageService.store(LocalStorageKeys.SilentMode, enable);
        }
    }
}
