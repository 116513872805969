import { Component, HostBinding } from '@angular/core';
import { LocalConnectionState, MyExtensionInfo } from '@myphone';
import { AppContact, defaultContact } from '@webclient/myphone/contact';
import { AppContactType, localBridgeId } from '@webclient/myphone/app-contact-type';
import { userTransparentImage } from '@webclient/assets';
import { Utils } from '@webclient/myphone/join/utils';
import { Observable } from 'rxjs';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { MyPhoneSession } from '../../myphone/myphone-session';
import { Profile } from '@webclient/myphone/profile';
import { CommonModule } from '@angular/common';
import { NameOrderPipe } from '@webclient/shared/pipes/name-order.pipe';
import { myPhoneTimeToDate } from '@webclient/myphone/time-util';

export function convertExtensionInfoToContact(myInfo: MyExtensionInfo): AppContact {
    const myContact = new AppContact('0', AppContactType.Extension, localBridgeId);

    myContact.phones.extension = myInfo.Number;
    myContact.phones.mobile = myInfo.MobileNumber;
    myContact.nativeMobileNumber = myInfo.MobileNumber;
    myContact.isRegistered = true;

    myContact.firstName = myInfo.FirstName || '';
    myContact.lastName = myInfo.LastName || '';
    myContact.queueStatus = myInfo.QueueStatus;

    myContact.statusTemporarilyChanged = myInfo.HasCurrentProfileOverride;
    myContact.currentProfile = new Profile({
        internalName: myInfo.MyProfiles.Items.find(item => item.Id === myInfo.CurrentProfile)?.Name
    });
    myContact.overrideCurrentProfile = new Profile({
        internalName: myInfo.MyProfiles.Items.find(item => item.Id === myInfo.CurrentProfileOverride)?.Name
    });
    myContact.profilePicture = myInfo.ContactImage ? myInfo.ContactImage : userTransparentImage;

    myContact.isBusy = myInfo.Connections.Items.filter(connection => connection.OwnerDn === myInfo.Number).length > 0;
    myContact.isRinging = myInfo.Connections.Items.some(conn => conn.State === LocalConnectionState.Ringing);

    myContact.monitorExpiration = myPhoneTimeToDate(myInfo.MonitorExpiration);

    Utils.onContactNameChanged(myContact);
    return myContact;
}

export const myContact = () => (source: Observable<MyPhoneSession>) =>
    source.pipe(
        switchMap(session => session.myInfo$),
        map(convertExtensionInfoToContact)
    );

@Component({
    selector: 'app-my-name',
    template: `
        <ng-container *ngIf="myContact$ | async as myContact">
            <span class="text-truncate">{{myContact | lforfl | async}}</span>
            <span>{{myContact.phones.extension}}</span>
        </ng-container>
    `,
    standalone: true,
    imports: [CommonModule, NameOrderPipe]
})
export class MyNameComponent {
    readonly myContact$: Observable<AppContact> = this.myPhone.myPhoneSession.pipe(myContact(), startWith(defaultContact));

    @HostBinding('class')
    readonly containerClass = 'd-flex gap-2';

    constructor(private myPhone: MyPhoneService) {
    }
}
