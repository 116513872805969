import { ActiveCallState } from './active-call-state';
import { LocalConnectionEx } from '../local-connection-ex';
import { DnType, LocalConnectionState } from '@myphone';

export type ContactSource = (callerId: string, displayName: string, dnType: DnType, dn: string) => string;

export class ActiveCallInfo {
    public Callee: string;
    public Caller: string;

    public StartedAt: number;
    public IsBreached: boolean;
    public IsWaiting: boolean;

    public RawState: LocalConnectionState;
    public Connections: LocalConnectionEx[] = [];

    public CanShow: boolean;
    // private _myCall: boolean;
    public Participants: string[] = [];

    public CallerType: DnType;
    public CalleeType: DnType;

    public OriginatorType: DnType;

    // Queue name of this call if it's a queue call
    public QueueName?: string;
    public IsQueueCall: boolean;

    constructor(public readonly id: string, init?: Partial<ActiveCallInfo>) {
        Object.assign(this, init);
    }

    get State() {
        if (this.IsBreached) {
            return ActiveCallState.Breached;
        }
        if (this.IsWaiting) {
            return ActiveCallState.WaitingInQueue;
        }

        if (this.RawState === LocalConnectionState.WaitingForNewParty) {
            return ActiveCallState.Connecting;
        }

        if (this.RawState === LocalConnectionState.Connected || this.RawState === LocalConnectionState.TryingToTransfer) {
            return ActiveCallState.Connected;
        }

        if (this.Connections.length === 1) {
            if (this.RawState === LocalConnectionState.Dialing) {
                return ActiveCallState.Dialing;
            }
            else if (this.RawState === LocalConnectionState.Ringing) {
                return ActiveCallState.Ringing;
            }
        }

        if (this.RawState === LocalConnectionState.Dialing || this.RawState === LocalConnectionState.Ringing) {
            return ActiveCallState.Connecting;
        }

        return ActiveCallState.UnknownState;
    }

    Dispose() {
        /*
         if (_timer != null)
         {
         _timer.Stop();
         _timer = null;
         }
         */
        this.Connections = [];
    }

    get CallDetails() {
        const result: string[] = [];

        if (this.CallerType === DnType.ExternalLine || this.CalleeType === DnType.ExternalLine) {
            result.push('_i18n.ExternalCall');
        }

        // Some body call to IVR
        if (this.CalleeType === DnType.IVR) {
            result.push('_i18n.IVR');
        }

        // Some body call to the queue - mean he waiting
        if (this.CalleeType === DnType.Queue) {
            if (this.IsBreached) {
                result.push('_i18n.Breached');
            }
            else {
                result.push('_i18n.WaitingInQueue');
            }
        }
        // Some body receive call from the queue - mean he connected to Queue
        else if (this.OriginatorType === DnType.Queue) {
            if (this.RawState === LocalConnectionState.Ringing) {
                result.push('_i18n.ConnectedToQueue');
            }
        }

        // Originator of some of local connections is RingGroup
        // if (_conns.Any(c => c.OriginatorType != null && c.OriginatorType.Value == DnType.RingGroup))
        //   result.Add( _source.TryFindResource("RingGroup"));

        if (this.CalleeType === DnType.Parking) {
            result.push('_i18n.Parked');
        }

        return result;
    }
}
