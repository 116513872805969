import { HeadsetContact, HeadsetInterface } from '../headset-interface';
import { NEVER, Observable, of } from 'rxjs';
import { HeadsetCallCommand } from '../headset-call-command';
import { HeadsetDiagnostics } from '../headset-diagnostics';

export class DummyService implements HeadsetInterface {
    public readonly diagnostics$ = of(HeadsetDiagnostics.Ok);
    headsetEvents$: Observable<HeadsetCallCommand> = NEVER;

    connectDevice(): void {
    }

    holdCall(callId: number): void {
    }

    incomingCall(callId: number, contact: HeadsetContact): void {
    }

    incomingCallAccepted(callId: number): void {
    }

    mute(callId: number, isMuted: boolean): void {
    }

    outgoingCall(callId: number, contact: HeadsetContact): void {
    }

    outgoingCallAccepted(callId: number): void {
    }

    resumeCall(callId: number): void {
    }

    terminateCall(callId: number): void {
    }
}
