import { Component, ElementRef, HostListener } from '@angular/core';
import { AppState } from '@webclient/app.service';

@Component({
    selector: 'aside',
    template: `
        <div class="aside-wrap h-100">
            <!-- if you want to use a custom scroll when aside fixed, use the slimScroll
            <div class="navi-wrap" ui-jq="slimScroll" ui-options="{height:'100%', size:'8px'}">
            -->
            <div class="navi-wrap h-100">
                <!-- nav -->
                <nav ui-nav class="navi clearfix h-100"></nav>
                <!-- nav -->
            </div>
        </div>
    `,
    styleUrls: ['./aside.component.scss']
})
export class AsideComponent {
    @HostListener('document:mousedown', ['$event'])
    onGlobalClick(event: MouseEvent): void {
        if (this.app.asideMenuVisible && !this.elementRef.nativeElement.contains(event.target)) {
            // clicked outside => close dropdown list
            this.app.asideMenuVisible = false;
        }
    }

    constructor(private app: AppState, private elementRef: ElementRef) {
    }
}
