import { Injectable } from '@angular/core';
import { ContactViewModel } from '@webclient/phonebook-editor/contact-view-model';
import { isValidEmail, UtilsService } from '@webclient/shared/utils.service';
import { ContactViewMode } from '@webclient/phonebook-editor/contact-view-mode';

@Injectable()
export class PhonebookContactEditorLocalService {
    public readonly errorText: { [ id: string ]: string } = {
        name: '',
        company: '',
        mobile: '',
        mobile2: '',
        home: '',
        title: '',
        business: '',
        business2: '',
        emailAddress: '',
        businessFax: '',
        department: '',
        other: '',
        noCreationMethodSelected: ''
    };

    public nameIsEmpty = false;
    public phoneNumberIsEmpty = false;
    public noCreationMethodIsSelected = false;

    obtainContact(vm: ContactViewModel, mode: ContactViewMode = ContactViewMode.Normal): ContactViewModel|undefined {
        if (mode === ContactViewMode.Compact) {
            return this.isCompactContactValid(vm) ? vm : undefined;
        }
        else {
            return this.isNormalContactValid(vm) ? vm : undefined;
        }
    }

    private isNormalContactValid(vm: ContactViewModel): boolean {
        let validationError = false;

        this.nameIsEmpty = this.isNameInvalid(vm);
        this.errorText.name = this.nameIsEmpty ? this.nameErrorText(vm) : '';

        this.phoneNumberIsEmpty = vm.isCrmOrMicrosoft365CreationActivated ? !vm.mobile : !vm.mobile && !vm.business && !vm.home;
        this.errorText.mobile = this.phoneNumberIsEmpty ?
            vm.isCrmOrMicrosoft365CreationActivated ? '_i18n.MustSpecifyTheMobileNumber' : '_i18n.MustSpecifyAtLeastOnePhoneNumber'
            : '';

        this.noCreationMethodIsSelected = vm.isNew && !vm.createCrmContact && !vm.createMicrosoft365Contact && !vm.createCompanyContact && !vm.createPersonalContact;
        this.errorText.noCreationMethodSelected = this.noCreationMethodIsSelected ? '_i18n.MustSpecifyAtLeastOneContactCreationMethod' : '';

        if (this.nameIsEmpty || this.phoneNumberIsEmpty || this.noCreationMethodIsSelected) {
            validationError = true;
        }

        const phones = vm;
        const email = vm.emailAddress;
        if (email && !isValidEmail(email)) {
            this.errorText.emailAddress = '_i18n.PleaseEnterCorrectEmail';
            validationError = true;
        }

        if (phones.mobile && !UtilsService.isValidPhoneNumber(phones.mobile)) {
            this.errorText.mobile = '_i18n.MobileNotValid';
            validationError = true;
        }

        if (phones.mobile2 && !UtilsService.isValidPhoneNumber(phones.mobile2)) {
            this.errorText.mobile2 = '_i18n.Mobile2NotValid';
            validationError = true;
        }

        if (phones.home && !UtilsService.isValidPhoneNumber(phones.home)) {
            this.errorText.home = '_i18n.HomeNotValid';
            validationError = true;
        }

        if (phones.business && !UtilsService.isValidPhoneNumber(phones.business)) {
            this.errorText.business = '_i18n.BusinessNotValid';
            validationError = true;
        }

        if (phones.business2 && !UtilsService.isValidPhoneNumber(phones.business2)) {
            this.errorText.business2 = '_i18n.Business2NotValid';
            validationError = true;
        }

        if (phones.businessFax && !UtilsService.isValidPhoneNumber(phones.businessFax)) {
            this.errorText.businessFax = '_i18n.BusinessFax';
            validationError = true;
        }

        if (phones.other && !UtilsService.isValidPhoneNumber(phones.other)) {
            this.errorText.other = '_i18n.OtherNotValid';
            validationError = true;
        }

        return !validationError;
    }

    private isCompactContactValid(vm: ContactViewModel): boolean {
        let validationError = false;

        this.nameIsEmpty = this.isNameInvalid(vm);
        this.errorText.name = this.nameIsEmpty ? this.nameErrorText(vm) : '';

        this.phoneNumberIsEmpty = !vm.mobile;
        this.errorText.mobile = this.phoneNumberIsEmpty ? '_i18n.MustSpecifyThePhoneNumber' : '';

        this.noCreationMethodIsSelected = vm.isNew && !vm.createCrmContact && !vm.createMicrosoft365Contact && !vm.createCompanyContact && !vm.createPersonalContact;
        this.errorText.noCreationMethodSelected = this.noCreationMethodIsSelected ? '_i18n.MustSpecifyAtLeastOneContactCreationMethod' : '';

        if (this.nameIsEmpty || this.phoneNumberIsEmpty || this.noCreationMethodIsSelected) {
            validationError = true;
        }

        const phones = vm;
        const email = vm.emailAddress;
        if (email && !isValidEmail(email)) {
            this.errorText.emailAddress = '_i18n.PleaseEnterCorrectEmail';
            validationError = true;
        }

        if (phones.mobile && !UtilsService.isValidPhoneNumber(phones.mobile)) {
            this.errorText.mobile = '_i18n.PleaseSpecifyCorrectNumber';
            validationError = true;
        }

        return !validationError;
    }

    private isNameInvalid(vm: ContactViewModel): boolean {
        return vm.createMicrosoft365Contact ? !vm.firstName
            : vm.createCrmContact ?
                !vm.firstName || !vm.lastName
                : !vm.firstName && !vm.lastName && !vm.company;
    }

    private nameErrorText(vm: ContactViewModel): string {
        return vm.createMicrosoft365Contact ? '_i18n.MustSpecifyFirstName'
            : vm.createCrmContact ?
                '_i18n.MustSpecifyFirstNameAndLastName'
                : '_i18n.FirstNameLastNameCompanyEmpty';
    }

    private nameChanged() {
        this.nameIsEmpty = false;
        this.errorText.name = '';
    }

    private phoneNumberChanged() {
        this.phoneNumberIsEmpty = false;
        this.errorText.mobile = '';
    }

    private creationMethodChanged() {
        this.noCreationMethodIsSelected = false;
        this.errorText.noCreationMethodSelected = '';
    }

    fieldChanged(vm: ContactViewModel, fieldName: string) {
        switch (fieldName) {
            case 'firstName': {
                this.nameChanged();
                break;
            }
            case 'company':
            case 'lastName': {
                if (!vm.createMicrosoft365Contact) {
                    this.nameChanged();
                }
                break;
            }
            case 'mobile':
            case 'business':
            case 'home': {
                this.phoneNumberChanged();
                break;
            }
            case 'createCrm': {
                this.creationMethodChanged();
                this.nameChanged();
                this.phoneNumberChanged();
                break;
            }
            case 'createMicrosoft365': {
                this.creationMethodChanged();
                this.nameChanged();
                this.phoneNumberChanged();
                break;
            }
            case 'createCompany':
            case 'createPersonal': {
                this.creationMethodChanged();
                break;
            }
        }

        this.errorText[fieldName] = '';
    }
}
