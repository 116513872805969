/** @returns error message (translation key) for invalid file, undefined for valid */
export function validateFileForPromptUpload(file: File): string | undefined {
    if (!/\.wav$/i.test(file.name)) {
        return '_i18n.IncorrectAudioFormat';
    }
    if (file.name.length > 50) {
        return '_i18n.FileNameCannotBeMoreThan50';
    }
    if (file.size > 10 * 1024 * 1024) {
        return '_i18n.FileSizeCannotBeMoreThan10MB';
    }
    return undefined;
}
