import { WorkerTypeMessage } from '@webclient/worker/protocol/worker-type-message';
import { LoginData } from '../../../generated/fetch';

interface ResponseTypesMap {
    notification: WorkerTypeMessage[];
    connection: LoginData;
    get: WorkerTypeMessage;
}

export class SharedWorkerResponse<T extends keyof ResponseTypesMap> {
    type: T;
    payload?: ResponseTypesMap[T];
    id?: string;
    public constructor(init?: Partial<SharedWorkerResponse<T>>) {
        Object.assign(this, init);
    }
}
