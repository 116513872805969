import { NgModule } from '@angular/core';
import { PhoneService } from './phone.service';
import { DeviceService } from './device.service';
import { HeadsetService } from './headsets/headset.service';
import { CallAnswerService } from './call-answer.service';
import { DeviceMediaService } from './device-media.service';
import { CallsStateService } from '@webclient/phone/calls-state.service';

@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [PhoneService, DeviceService, HeadsetService, CallAnswerService, DeviceMediaService, CallsStateService],
})
export class PhoneModule {

}
