import { EnumFilterValue } from '@office/standalones/odata-search';
import type { CheckOption } from '@webclient/fields/types';

export enum CallClassType {
    All,
    Local,
    National,
    Mobile,
    International,
}

const options: CheckOption<CallClassType>[] = [
    { value: CallClassType.All, label: '_i18n.ReportsCallClassTypeAll' },
    { value: CallClassType.Local, label: '_i18n.ReportsCallClassTypeLocal' },
    { value: CallClassType.National, label: '_i18n.ReportsCallClassTypeNational' },
    { value: CallClassType.Mobile, label: '_i18n.ReportsCallClassTypeMobile' },
    { value: CallClassType.International, label: '_i18n.ReportsCallClassTypeInternational' },
];

export interface CallClassFilterParams {
    callClass: CallClassType | null
}

export function newCallClassFilterValue() {
    return new EnumFilterValue('_i18n.ReportsCallType', 'callClass', options);
}
