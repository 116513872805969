/// <reference types="w3c-web-hid" />

import {
    TelephonyDevicePage,
    YealinkDevicePage,
    YealinkHidKeypadDigit, YealinkLogTitle
} from '@webclient/phone/headsets/yealink/yealink-hid.service';
import { logHeadsetEvent } from '@webclient/phone/headsets/device-helpers';

export type HIDReportCollectionMap = { [id: number]: HIDReport[] }
export type HIDReport = { mask: number, usagePage?: number, usages: number[] }

export function generateHidReportMap(device: HIDDevice): HIDReportCollectionMap {
    const reports: HIDReportCollectionMap = {};
    logHeadsetEvent('Yealink Integration -', 'device collections: ', device.collections);
    for (const collection of device.collections) {
        // A HID collection includes usage, usage page, reports, and subcollections.
        if (collection.inputReports) {
            const usagePage = collection.usagePage;
            for (const inputReport of collection.inputReports) {
                let mask = 1;
                if (inputReport.reportId && inputReport.items) {
                    reports[inputReport.reportId] =
                        inputReport.items.reduce<HIDReport[]>((acc, next) => {
                            if (next.reportSize === 1 && next.reportCount && next.usages && next.usages.length > 0) {
                                // Only support fields with reportSize 1
                                for (const usage of next.usages) {
                                    acc.push({
                                        mask,
                                        usagePage,
                                        usages: [usage]
                                    });
                                    mask <<= (1);
                                }
                                // acc.push({ mask, usages: next.usages });
                                // mask <<= (next.reportSize * next.reportCount);
                            }
                            return acc;
                        }, []);
                }
            }
        }
    }
    logHeadsetEvent(YealinkLogTitle, ' reports extracted:', reports);
    return reports;
}

// extract number of bits from a value starting at a zero based position
// https://www.geeksforgeeks.org/extract-k-bits-given-position-number/
export function extractSpecificBits(value: number, position: number, numOfDigits: number) : number {
    const shiftRightDigits = (value >> (position));
    const maskNumberOfDigits = ((1 << numOfDigits) - 1);
    return (maskNumberOfDigits & shiftRightDigits);
}

export function extractYealinkKeypadDigit(value: number) : YealinkHidKeypadDigit {
    // the 8th to 11th digit represent the keypad digit pressed
    // e.g  xx0001xxxxxxx -> '0' , xx0010xxxxxxx -> '1', etc..
    const valueExtracted = extractSpecificBits(value, 7, 4);
    const validDigitValues = Object.values(YealinkHidKeypadDigit).filter(digit => !isNaN(Number(digit))) as number[];

    return validDigitValues.some(digit => digit === valueExtracted) ? valueExtracted : YealinkHidKeypadDigit.None;
}

export function decodeUsagesFromHidReports(reportsCollectionMap: HIDReportCollectionMap, reportId: number, reportValue: number, supportedUsages: number[]): number[] {
    const reports: HIDReport[] = reportsCollectionMap[reportId];
    // Unknown report
    if (!reports) {
        return [];
    }

    logHeadsetEvent(YealinkLogTitle, `processing reportId: ${reportId}, value: ${reportValue}`);
    const usages: number[] = [];
    for (const report of reports) {
        if (report.mask & reportValue) {
            const usage = report.usages[0] ?? 0;
            const usagePage = report.usagePage ?? 0;
            // clear the usage page from the usage
            const usageValueExtracted = (~(usagePage << 16)) & usage;
            const isSupportedUsage = supportedUsages.some(val => val === usageValueExtracted);
            if (isSupportedUsage) {
                usages.push(usageValueExtracted);
            }
        }
    }
    return usages;
}

export function constructYealinkReportData(command: number): Uint8Array {
    const value = command || 0x00;
    const data = [
        value & 0xff
    ];
    return new Uint8Array(data);
}

export async function requestDevice(options: HIDDeviceRequestOptions) : Promise<HIDDevice | null> {
    const devices = await navigator.hid.requestDevice(options);
    const device = devices[0] ?? null;
    logHeadsetEvent(YealinkLogTitle, 'device requested:', device?.productName);
    return device;
}

export async function openHidDevice(device: HIDDevice): Promise<HIDDevice> {
    if (!device.opened) {
        await device.open();
    }
    logHeadsetEvent(YealinkLogTitle, 'device opened:', device.productName);
    return device;
}

export async function getAvailableDevice(filters: HIDDeviceFilter[]) : Promise<HIDDevice | null> {
    const device_list = await navigator.hid.getDevices();
    logHeadsetEvent(YealinkLogTitle, 'available devices list:', device_list.map((device) => device.productName));
    return device_list.find(device => isValidHIDDevice(device, filters)) ?? null;
}

export function isValidHIDDevice(device: HIDDevice, filters: HIDDeviceFilter[]): boolean {
    const isSupportedDevice = filters.some((filter) => {
        return ((filter.vendorId === undefined) || device.vendorId === filter.vendorId) &&
            ((filter.productId === undefined) || device.productId === filter.productId) &&
            deviceSupportsStandardHID(device);
    });
    return device.productName !== 'HID debug interface' && isSupportedDevice;
}

export function deviceSupportsYealinkHID(device: HIDDevice): boolean {
    return device.collections.some((collection) => collection.usagePage === YealinkDevicePage);
}

export function deviceSupportsStandardHID(device: HIDDevice): boolean {
    return device.collections.some((collection) => collection.usagePage === TelephonyDevicePage);
}

export function extractReportId(device: HIDDevice, usagePage: number): number | undefined {
    const collectionInfo: HIDCollectionInfo|undefined = device.collections.find((collection) => collection.usagePage === usagePage);
    return collectionInfo?.inputReports?.[0].reportId;
}

export function extractYealinkReportId(device: HIDDevice): number {
    const yealinkHIDReportId = extractReportId(device, YealinkDevicePage);
    const standardHIDReportId = extractReportId(device, TelephonyDevicePage);
    return yealinkHIDReportId ?? standardHIDReportId ?? -1;
}
