import {
    Component, ElementRef, Input, OnChanges, SimpleChanges
} from '@angular/core';

@Component({
    selector: 'show-svg-images',
    template: '',
})
export class ShowSvgImagesComponent implements OnChanges {
    @Input()
    svg: any;

    constructor(private el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.el.nativeElement.innerHTML = this.svg;
    }
}
