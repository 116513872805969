import { NgModule } from '@angular/core';
import { DateFormatPipe } from '@webclient/dayjs/pipes/date-format.pipe';
import { LocalTimePipe } from '@webclient/dayjs/pipes/local.pipe';
import { DateTodayYesterdayPipe } from '@webclient/dayjs/pipes/date-today-yesterday.pipe';

@NgModule({
    declarations: [DateFormatPipe, LocalTimePipe, DateTodayYesterdayPipe],
    exports: [DateFormatPipe, LocalTimePipe, DateTodayYesterdayPipe]
})

export class DayJsModule {
}
