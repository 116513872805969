<div class="content" #callContent tabindex="0" >
    <div class="call-view-header">
        <caller-info [ngClass]="getCallInfoMode(myCall)===CallerInfoMode.NoInfo ? '':'caller-info-expanded'" [myCall]="myCall" [mode]="getCallInfoMode(myCall)"
                     [callState]="getStateName(myCall)|translate"></caller-info>
        <ng-template [ngIf]="isInCallSearchVisible()">
            <div class='in-call-search-area'>
                <div class="search-input-area">
                    <div class="search-input">
                        <input #searchInput type="text" autocomplete="off" spellcheck="false" id="search-input"
                           placeholder="{{'_i18n.EnterNameOrNumber' | translate}}" [ngModel]='searchNumber'
                           (ngModelChange)="searchNumberChanged($event)" tabindex="1">
                        <span *ngIf="!searchNumber" class="customSVGIcons svg-xs fillIconWithTertiary" app-search-regular-icon></span>
                    </div>
                </div>
                <call-search-list #searchList class="searchList" [hidden]="!searchNumber" [searchContext]="inCallSearchContext()"
                                  (contactSelected)="onContactSelected($event)">
                </call-search-list>
                <div class="expander"></div>
            </div>
        </ng-template>
        <ng-template [ngIf]="utilityService.isWebRTCCall(myCall)">
            <div *ngIf="getCallButtonsType(myCall) === CallButtonsType.VideoCallActions" class="call-actions-video-wrapper">
                <video-output [media]="myCall.media" (dropClick)="callsService.drop(myCall)" id="video"
                              backgroundColor="transparent"></video-output>
                <ngx-loading [show]="isLoadingVisible(myCall)"></ngx-loading>
            </div>
        </ng-template>
    </div>
    <call-buttons #callButtons [myCall]="myCall" [type]="getCallButtonsType(myCall)" [searchTextPopulated]='!!searchNumber'
                  (inCallKeyPadVisibilityChange)="onInCallKeyPadVisibilityChange($event)"
                  (inCallSearchActionChange)='onInCallSearchActionChange($event)'
                  (videoVisibilityChange)="OnVideoVisibilityChange($event)"
                  (searchKeyPressed)='onSearchKeyPressed($event)'
                  (searchActionFired)='onSearchActionFired()'
                  (callActionFired)='onCallActionFired()'>
    </call-buttons>
</div>
