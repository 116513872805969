import { ProfileNames } from '@webclient/myphone/profile';
import type { PbxPeer } from '@xapi';

export enum BlfType {
    BLF,
    SpeedDial,
    CustomSpeedDial,
    SharedParking,
    QueueLogin,
    ProfileStatus,
    Line,
    None
}

const profileXmlValueMapping: ProfileNames[] = [
    ProfileNames.Available,
    ProfileNames.Away,
    ProfileNames.OutOfOffice,
    ProfileNames.Custom1,
    ProfileNames.Custom2
];

export class Blf {
    peer: PbxPeer | null = null;
    type: BlfType | null = BlfType.None;
    value: string | null = '';
    // For custom speed dial
    firstName: string | null = '';
    lastName: string | null = '';

    constructor(init?: Partial<Blf>) {
        Object.assign(this, init);
    }
}

function parseBlfItem(xmlBlf: Element): [Blf, number] {
    const position = parseInt(xmlBlf.attributes.getNamedItem('BLFNo')!.value, 10);
    const id = xmlBlf.attributes.getNamedItem('ID')!.value;
    const textContent = xmlBlf.textContent || '';

    const blf = new Blf({
        type: parseInt(xmlBlf.attributes.getNamedItem('BLFTypeID')!.value, 10),
    });

    switch (blf.type) {
        case BlfType.SharedParking:
        case BlfType.BLF:
        case BlfType.SpeedDial: {
            blf.peer = textContent ? { Number: textContent, Id: parseInt(id, 10) } : null;
            blf.value = textContent;
            break;
        }
        case BlfType.CustomSpeedDial: {
            if (textContent) {
                const [value, firstName = '', lastName = ''] = textContent.split('\n');
                Object.assign(blf, { value, firstName, lastName });
            }
            break;
        }
        case BlfType.QueueLogin: {
            blf.value = id;
            break;
        }
        case BlfType.ProfileStatus: {
            blf.value = profileXmlValueMapping[parseInt(textContent, 10)] || '';
            break;
        }
    }

    return [blf, position];
}

export function xmlStringToBlf(blfXmlConfig: string): Blf[] {
    if (!blfXmlConfig) {
        return [];
    }
    const parsedBlf = new DOMParser().parseFromString(blfXmlConfig, 'text/xml');
    const errorNode = parsedBlf.querySelector('parsererror');
    if (errorNode) {
        // parsing failed
        return [];
    }

    const blfs = parsedBlf.getElementsByTagName('BLF');
    const convertedBlf: Blf[] = [];

    for (let i = 0; i < blfs.length; ++i) {
        try {
            const [nextBlf, position] = parseBlfItem(blfs[i]);
            while (position > convertedBlf.length + 1) {
                convertedBlf.push(new Blf());
            }

            if (position < convertedBlf.length + 1) {
                // Insert at specific position
                convertedBlf[position - 1] = nextBlf;
            }
            else {
                // Add in the end
                convertedBlf.push(nextBlf);
            }
        }
        catch {
            // Some invalid data provided
        }
    }
    return convertedBlf;
}

export function blfToXmlString(list: Blf[]): string {
    const xmlDoc = document.implementation.createDocument(null, 'PhoneDevice', null);
    const root = xmlDoc.createElement('BLFS');

    list.forEach((item, index) => {
        if (item.type == null || item.type === BlfType.None) {
            return;
        }
        const position = index + 1;
        const node = xmlDoc.createElement('BLF');
        let id = '-1';
        let textContent = '';

        switch (item.type) {
            case BlfType.SharedParking:
            case BlfType.BLF:
            case BlfType.SpeedDial: {
                id = String(item.peer?.Id ?? -1);
                textContent = item.peer?.Number || '';
                break;
            }
            case BlfType.CustomSpeedDial: {
                textContent = [item.value || '', item.firstName || '', item.lastName || ''].join('\n').trim();
                break;
            }
            case BlfType.QueueLogin: {
                id = item.value ?? '-1';
                textContent = 'DEFINED BY ID';
                break;
            }
            case BlfType.ProfileStatus: {
                textContent = String(Math.max(profileXmlValueMapping.indexOf(item.value as ProfileNames), 0));
                break;
            }
            case null:
                return;
        }

        // BLF/SpeedDial ID = contact id, QueueLogin ID = LOGGEDINQUEUE, LOGGEDOUTQUEUE else -1
        node.setAttribute('ID', id);
        node.textContent = textContent;
        node.setAttribute('BLFNo', '' + position);
        node.setAttribute('BLFType', BlfType[item.type]);
        node.setAttribute('BLFTypeID', '' + item.type);
        root.appendChild(node);
    });

    xmlDoc.children[0].appendChild(root);
    const serializer = new XMLSerializer();
    return serializer.serializeToString(xmlDoc);
}
