import { ResponseServerTime } from '@myphone';
import { getTimeDelta } from '@webclient/myphone/time-util';

export class TimeSync {
    public requestServerTimeSent: number;

    // Set delta to zero so all functions
    // at least will not return NaN values even if they will not be correct
    private tsDelta = 0;

    public get delta() {
        return this.tsDelta;
    }

    public millisecondsToClientTime(dtServerTime: number): Date {
        return new Date(dtServerTime - this.tsDelta);
    }

    public toClientTime(dtServerTime: Date): Date {
        return this.millisecondsToClientTime(dtServerTime.valueOf());
    }

    public toServerTime(dtClientTime: Date): Date {
        return new Date(dtClientTime.valueOf() + this.tsDelta);
    }

    public refresh(responseServerTime: ResponseServerTime) {
        this.tsDelta = getTimeDelta(this.requestServerTimeSent, responseServerTime);
    }
}
