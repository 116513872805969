import { combineLatest, Observable, ReplaySubject } from 'rxjs';
import { map, share, startWith } from 'rxjs/operators';

export const publishRef = <T>() => (source: Observable<T>) => source.pipe(share({
    // It might not be fully correct here
    // as we replace publishReplay(1), refCount()
    // see https://github.com/ReactiveX/rxjs/discussions/6438
    connector: () => new ReplaySubject(1),
    resetOnError: false,
    resetOnComplete: false,
}));

export const repeatLatestWhen = <T>(notifier$: Observable<any>) =>
    (source: Observable<T>) =>
        combineLatest([
            source,
            notifier$.pipe(startWith(null)),
        ]).pipe(
            map(([val]) => val),
        );
