import {
    BehaviorSubject, from, Observable, of, throwError
} from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ModalService } from '../modal/app-modal.service';
import { MyPhoneService } from '../myphone/myphone.service';
import { Injectable } from '@angular/core';

import {
    RequestGetFolder, RequestPutFile, ResponseGetFolder, UsersFolder
} from '@myphone';
import {
    catchError, distinctUntilChanged, map, switchMap, take, tap
} from 'rxjs/operators';
import soundDefault from 'notifications/Default.mp3';
import soundBobby from 'notifications/Bobby.mp3';
import soundDaisy from 'notifications/Daisy.mp3';
import soundDong from 'notifications/Dong.mp3';
import soundHarp from 'notifications/Harp.mp3';
import soundPling from 'notifications/Pling.mp3';
import soundSparkle from 'notifications/Sparkle.mp3';
import soundSwish from 'notifications/Swish.mp3';
import soundTeeting from 'notifications/Teeting.mp3';
import soundTwinkle from 'notifications/Twinkle.mp3';

interface Notification {
    readonly name: string;
    readonly label: string;
    readonly data: any;
    readonly predefined: boolean;
}

const predefinedNotifications : Notification[] = [
    {
        name: 'Default', label: '_i18n.Voicemail_GreetingsDefaultTitle', data: soundDefault, predefined: true
    },
    {
        name: 'Bobby', label: 'Bobby', data: soundBobby, predefined: true
    },
    {
        name: 'Daisy', label: 'Daisy', data: soundDaisy, predefined: true
    },
    {
        name: 'Dong', label: 'Dong', data: soundDong, predefined: true
    },
    {
        name: 'Harp', label: 'Harpe', data: soundHarp, predefined: true
    },
    {
        name: 'Pling', label: 'Pling', data: soundPling, predefined: true
    },
    {
        name: 'Sparkle', label: 'Sparkle', data: soundSparkle, predefined: true
    },
    {
        name: 'Swish', label: 'Swish', data: soundSwish, predefined: true
    },
    {
        name: 'Teeting', label: 'Teeting', data: soundTeeting, predefined: true
    },
    {
        name: 'Twinkle', label: 'Twinkle', data: soundTwinkle, predefined: true
    }
];

@Injectable()
export class SoundService {
    private notifications: Notification[];
    public readonly notifications$: BehaviorSubject<Notification[]>;

    private audioCache: { [key: string]: string };

    constructor(private myPhoneService: MyPhoneService, private modalService: ModalService, private http: HttpClient) {
        this.audioCache = {};
        this.notifications = predefinedNotifications;
        this.notifications$ = new BehaviorSubject<Notification[]>(this.notifications);

        this.downloadNotificationList().pipe(take(1)).subscribe(externalNotifications => {
            this.notifications.push(...externalNotifications);
            this.notifications$.next(this.notifications);
        });
    }

    private downloadNotificationList(): Observable<Notification[]> {
        const getFolderRequest = new RequestGetFolder();
        getFolderRequest.Folder = UsersFolder.NotificationsFolder;

        return this.myPhoneService.get<ResponseGetFolder>(getFolderRequest).pipe(
            map((response: ResponseGetFolder) => response.Files
                .map(file => this.getExternalNotificationByFilename(file))),
            catchError(() => of([])),
            distinctUntilChanged((x, y) => {
                return JSON.stringify(x) === JSON.stringify(y);
            }));
    }

    public playNotificationByName(name: string): void {
        const notif = this.notifications.find(notif => notif.name === name);
        if (notif) {
            if (notif.predefined) {
                new Audio(notif.data).play();
            }
            else if (this.audioCache[name] === undefined) {
                this.http.get(notif.data, { responseType: 'blob' }).pipe(take(1)).subscribe(data => {
                    const objectUrl = window.URL.createObjectURL(data);
                    this.audioCache[name] = objectUrl;
                    new Audio(objectUrl).play();
                });
            }
            else {
                new Audio(this.audioCache[name]).play();
            }
        }
    }

    public uploadNotificationFile(contents: ArrayBuffer, filename: string): Observable<Notification> {
        return of(contents).pipe(switchMap(wavContents => {
            if (wavContents.byteLength > 2097152) {
                return throwError(() => new Error('_i18n.MaximumSizeIsExceeded'));
            }

            const putFile = new RequestPutFile();
            putFile.Folder = UsersFolder.NotificationsFolder;
            putFile.FileName = filename;
            putFile.Content = new Uint8Array(wavContents);

            return from(new AudioContext().decodeAudioData(contents)).pipe(switchMap(p => {
                return this.myPhoneService.get(putFile);
            }));
        }),
        map(f => this.getExternalNotificationByFilename(filename)),
        tap({
            next: (notification: Notification) => {
                this.audioCache[notification.name] = window.URL.createObjectURL(new Blob([contents]));
                if (this.notifications.findIndex(n => n.name === notification.name) < 0) {
                    this.notifications.push(notification);
                    this.notifications$.next(this.notifications);
                }
            // Finished
            },
            error: (error: unknown) => this.modalService.error(error)
        }));
    }

    private getExternalNotificationByFilename(filename: string): Notification {
        return {
            name: filename, label: filename, data: '/webclient/api/currentuser/notification/' + filename, predefined: false
        };
    }
}
