import type { FilterValue, SerializedFilter } from '@office/standalones/odata-search/types';
import { TranslateService } from '@ngx-translate/core';
import { ValidationErrors } from '@angular/forms';
import { AuditActionList, getActionTranslationKey, } from '@office/reports/audit-logs/audit-logs-enums';

export interface AuditActionFilterParams {
    auditActionIndex: number | null
}

function isAuditActionType(value: unknown): value is AuditActionFilterParams['auditActionIndex'] {
    return AuditActionList.some(({ id }) => id === value);
}

const QUERY_PARAM_AUDIT_ACTION_TYPE_INDEX = 'auditActionIndex';

export class AuditActionFilterValue implements FilterValue<AuditActionFilterParams> {
    private auditActionIndex: AuditActionFilterParams['auditActionIndex'] = null;

    get value(): AuditActionFilterParams {
        return { auditActionIndex: this.auditActionIndex };
    }

    set value(value: AuditActionFilterParams) {
        this.auditActionIndex = value.auditActionIndex === -1 ? null : value.auditActionIndex;
    }

    readonly label = '_i18n.ReportsAuditAction';

    deserialize(serializedValue: SerializedFilter): FilterValue<AuditActionFilterParams> {
        return AuditActionFilterValue.deserialize(serializedValue, this.value);
    }

    getDisplayValue(translate: TranslateService): string {
        const value = this.value;

        return translate.instant(getActionTranslationKey(value.auditActionIndex ?? undefined));
    }

    getDisplayValueForPrint(translate: TranslateService): string {
        return this.getDisplayValue(translate);
    }

    serialize(): SerializedFilter {
        const value = this.value;

        return value.auditActionIndex == null ? {} : { [QUERY_PARAM_AUDIT_ACTION_TYPE_INDEX]: value.auditActionIndex };
    }

    validate(): ValidationErrors | null {
        return null;
    }

    static deserialize(serializedValue: SerializedFilter, initialValue?: AuditActionFilterParams): FilterValue<AuditActionFilterParams> {
        const result = new AuditActionFilterValue();
        if (initialValue) {
            result.value = initialValue;
        }
        if (QUERY_PARAM_AUDIT_ACTION_TYPE_INDEX in serializedValue && isAuditActionType(serializedValue[QUERY_PARAM_AUDIT_ACTION_TYPE_INDEX])) {
            result.auditActionIndex = serializedValue[QUERY_PARAM_AUDIT_ACTION_TYPE_INDEX] ?? -1;
        }
        return result;
    }
}
