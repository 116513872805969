import { NgModule } from '@angular/core';
import { ProfileImageDialogComponent } from './profile-image-dialog.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { ImageCropperComponent } from '../third-party/ng2-img-cropper';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { ProfileImageComponent } from './profile-image.component';
import { FieldsModule } from '@webclient/fields/fields.module';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog';

@NgModule({
    imports: [ModalModule, CommonModule, ImageCropperComponent, TranslateModule, IconsModule, FieldsModule, ModalDialogComponent],
    exports: [ProfileImageComponent],
    declarations: [ProfileImageDialogComponent, ProfileImageComponent],
})
export class ProfileImageModule {

}
