/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PbxGroupHoursMode = 'Default' | 'ForceOpened' | 'ForceClosed' | 'ForceBreak' | 'ForceCustomOperator' | 'ForceHoliday' | 'HasForcedMask';

export const PbxGroupHoursMode = {
    Default: 'Default' as PbxGroupHoursMode,
    ForceOpened: 'ForceOpened' as PbxGroupHoursMode,
    ForceClosed: 'ForceClosed' as PbxGroupHoursMode,
    ForceBreak: 'ForceBreak' as PbxGroupHoursMode,
    ForceCustomOperator: 'ForceCustomOperator' as PbxGroupHoursMode,
    ForceHoliday: 'ForceHoliday' as PbxGroupHoursMode,
    HasForcedMask: 'HasForcedMask' as PbxGroupHoursMode
};

