<ng-container [ngSwitch]="mode" >
    <div *ngSwitchCase="CallerInfoMode.Normal" class="call-info">
        <div [style.font-size.px]="adaptiveFontSize" class="callNumber">{{myCall.phone}}</div>
        <ng-container *ngTemplateOutlet="contactActions"></ng-container>
        <ng-container *ngTemplateOutlet="displayName"></ng-container>
        <ng-container *ngTemplateOutlet="phonebookContactLink"></ng-container>
        <ng-container *ngTemplateOutlet="callDuration"></ng-container>
    </div>

    <div *ngSwitchCase="CallerInfoMode.InCallKeyPad" class="call-info">
        <div class="callNumber" >{{myCall.media.toneSend$|async}}</div>
        <ng-container *ngTemplateOutlet="contactActions"></ng-container>
        <ng-container *ngTemplateOutlet="displayName"></ng-container>
        <ng-container *ngTemplateOutlet="phonebookContactLink"></ng-container>
        <ng-container *ngTemplateOutlet="callDuration"></ng-container>
    </div>

    <div *ngSwitchCase="CallerInfoMode.OnlyDuration" class="call-info">
        <ng-container *ngTemplateOutlet="callDuration"></ng-container>
    </div>

    <div *ngSwitchDefault class="call-info"></div>
</ng-container>

<ng-template #displayName>
    <ul *ngIf="displayedParts.length > 0; else externalCrmContact" class="callDisplayName call-info-section">
        <li *ngFor="let part of displayedParts; let isFirst = first; let isLast = last" [title]="part" class="d-flex align-items-center justify-content-center mb-1 gap-1">
            <span [title]="part" class="text-truncate">{{part}}</span>
            <a *ngIf="!!myCall.contact?.crmUrl && (myCall.prependNameToCID ? isLast : isFirst) && !(myCall.contact.isPhonebookContact() && showCrmUrlInPhonebookContactName())"
               class="pointer" data-qa="crm-link" target="_blank"
               [href]="myCall.contact?.crmUrl" title="{{'_i18n.CrmContactPage'|translate}}">
                (<span class="text-underline">CRM</span>)
            </a>
        </li>
    </ul>
    <ng-template #externalCrmContact>
        <div *ngIf="isExternalCrmContact" class="callDisplayName call-info-section d-flex align-items-center justify-content-center mb-1 gap-1">
            <span class="text-truncate">{{myCall.contact | lforfl | async}}</span>
            <a *ngIf="!!myCall.contact?.crmUrl" class="pointer" data-qa="crm-link" target="_blank"
               [href]="myCall.contact?.crmUrl" title="{{'_i18n.CrmContactPage'|translate}}">
                (<span class="text-underline">CRM</span>)
            </a>
        </div>
    </ng-template>
    <ng-template #unknown>
        <div *ngIf="isShowUnknown" class="callDisplayName call-info-section">
            <span [translate]="'_i18n.Unknown'">Unknown</span>
        </div>
    </ng-template>
</ng-template>

<ng-template #contactActions>
    <div class="call-info-section d-flex flex-column gap-1 mb-1">
        <a *ngIf="(hasClientCrmIntegration$ | async) && canOpenClientCrmUrl"
           class="action-link pointer d-flex align-items-center justify-content-center text-truncate"
           data-qa="client-crm-link"
           (click)="openClientCrmUrl()">
            <span class="text-truncate">{{'_i18n.LookUp' | translate}}</span>
            <span class="svg-xxs ms-1" app-search-regular-icon></span>
        </a>
        <a *ngIf="canBeAdded" (click)="addToContacts(myCall)"
           class="action-link pointer d-flex align-items-center justify-content-center text-truncate"
           data-qa="add-contact-link" >
            <span class="svg-xs me-1" app-plus-solid-icon></span>
            <span class="text-truncate">{{'_i18n.MenuAddContact' | translate}}</span>
        </a>
    </div>
</ng-template>

<ng-template #phonebookContactLink>
    <div *ngIf="myCall.contact.isPhonebookContact() && (myCall.contact | lforfl | async) as contactName"
         class="d-flex flex-column justify-content-center mb-1 gap-1 call-info-section">
        <div *ngIf="showCrmUrlInPhonebookContactName()" class="d-flex align-items-center justify-content-center gap-1">
            <span [title]="contactName" class="text-truncate">{{contactName}}</span>
            <a class="pointer" data-qa="phonebook-crm-link" target="_blank"
               [href]="myCall.contact?.crmUrl" title="{{'_i18n.CrmContactPage'|translate}}">
                (<span class="text-underline">CRM</span>)
            </a>
        </div>
        <div class="d-flex align-items-center justify-content-center gap-1">
            <span [title]="contactName" class="text-truncate">{{contactName}}</span>
            <a class="pointer" data-qa="phonebook-contact-link" (click)="viewContact(myCall.contact)" title="{{contactName}}">
                (<span class="text-underline">3CX</span>)
            </a>
        </div>
    </div>
</ng-template>

<ng-template #callDuration>
    <div class="call-info-section d-flex align-items-center justify-content-center">
        <duration class="call-duration" [startedAt]="myCall.startedAt"></duration>
    </div>
</ng-template>
