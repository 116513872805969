import { AppContact } from '@webclient/myphone/contact';
import { DnType, ForwardDestination, ForwardDestinationType, ResponseLookup } from '@myphone';
import { MyPhoneSession } from '@webclient/myphone/myphone-session';
import { ContactSearcherService } from '@webclient/shared/service/contact-searcher.service';
import { Observable, of } from 'rxjs';
import { SearchContext } from '@webclient/shared/search/search-context';
import { map } from 'rxjs/operators';
import { AppContactType, localBridgeId } from '@webclient/myphone/app-contact-type';
import { PbxPeer, PbxPeerType } from '@xapi';

export enum ForwardDestinationViewType {
    SameAsAllCalls = 1,
    MyVoicemail = 2,
    Extension = 3,
    MyMobile = 4,
    External = 5,
    SystemExtension = 6,
    SendBusy = 7,
    PlayAnnouncement = 8,
}

export class ForwardDestinationPreview {
    type: ForwardDestinationViewType | null = null;
    number = '';

    extensionContact: AppContact|null = null;
    systemContact: AppContact|null = null;
    rebound = false;
    use302 = false;
    voicemail = false;

    constructor(data: Partial<ForwardDestinationPreview>) {
        Object.assign(this, data);
    }
}

/** Forward destination accessor inner value type */
interface ForwardDestinationFormValue {
    type: ForwardDestinationViewType | null
    number: string | null
    extensionContact: PbxPeer | null
    systemContact: PbxPeer | null
    rebound: boolean | null
    use302: boolean | null
    voicemail: boolean | null
}

export interface ExtensionInfo {
    Number: string;
    MobileNumber: string;
}

/** Contains additional values for conversion to PbxDestination */
export interface ConvertableForwardDestination extends ForwardDestination {
    PeerName?: string
    PeerType?: PbxPeerType
    ToMyMobile?: boolean
}

function convertToPeerType(dnType: DnType): PbxPeerType {
    switch (dnType) {
        case DnType.None:
            return PbxPeerType.None;
        case DnType.Extension:
            return PbxPeerType.Extension;
        case DnType.Queue:
            return PbxPeerType.Queue;
        case DnType.RingGroup:
            return PbxPeerType.RingGroup;
        case DnType.IVR:
            return PbxPeerType.Ivr;
        case DnType.Fax:
            return PbxPeerType.Fax;
        case DnType.Conference:
            return PbxPeerType.Conference;
        case DnType.Parking:
            return PbxPeerType.Parking;
        case DnType.ExternalLine:
            return PbxPeerType.ExternalLine;
        case DnType.SpecialMenu:
            return PbxPeerType.SpecialMenu;
        case DnType.Service:
            return PbxPeerType.RoutePoint;
        default:
            throw new Error(`DnType ${dnType} conversion to PbxPeerType is not supported`);
    }
}

function convertToPeer(contact: Pick<AppContact, 'nativeExtensionNumber' | 'firstNameLastName' | 'dnType'> | null | undefined): PbxPeer | null {
    return contact ? {
        Name: contact.firstNameLastName,
        Number: contact.nativeExtensionNumber,
        Type: convertToPeerType(contact.dnType || DnType.Extension)
    } : null;
}

export function createForwardDestinationFormValue({ extensionContact, systemContact, ...rest }: Partial<ForwardDestinationPreview>): ForwardDestinationFormValue {
    return {
        type: null,
        number: '',
        rebound: false,
        use302: false,
        voicemail: false,
        ...rest,
        extensionContact: convertToPeer(extensionContact),
        systemContact: convertToPeer(systemContact)
    };
}

/** Forward destination accessor outer value type */
export interface ForwardDestinationValue {
    forwardDestination: ConvertableForwardDestination;
    sameAsAllCalls?: boolean;
    playAnnouncement?: boolean;
}

type PartialNullable<T> = { [P in keyof T]?: T[P] | null };

function specifyExtensionType$(numb: string, session: MyPhoneSession, contactService: ContactSearcherService, voicemail: boolean): Observable<ForwardDestinationPreview> {
    return session.get<ResponseLookup>(
        contactService.requestFactory({ Input: numb, Count: 1 }, SearchContext.forwardingExtAndSystemExt())
    ).pipe(
        map(response => (response.TotalCount ? response.Entries[0] : undefined)),
        map(contact => {
            if (contact && contact.DnType !== DnType.Extension) {
                return new ForwardDestinationPreview({
                    type: ForwardDestinationViewType.SystemExtension,
                    systemContact: session.createMergedContact(contact),
                    voicemail
                });
            }
            // regular extension
            return new ForwardDestinationPreview({
                type: ForwardDestinationViewType.Extension,
                extensionContact: contact ? session.createMergedContact(contact) : new AppContact('-1', AppContactType.Extension, localBridgeId, {
                    nativeExtensionNumber: numb,
                    lastNameFirstName: '',
                    firstNameLastName: ''
                }),
                voicemail
            });
        })
    );
}

export function convertToForwardDestinationPreview$(session: MyPhoneSession, searcher: ContactSearcherService, src: ForwardDestinationValue | null, extensionInfo: ExtensionInfo | null, prevExtensionInfo?: ExtensionInfo | null): Observable<ForwardDestinationPreview> {
    if (!src) {
        return of(new ForwardDestinationPreview({}));
    }
    if (src.sameAsAllCalls) {
        return of(new ForwardDestinationPreview({
            type: ForwardDestinationViewType.SameAsAllCalls
        }));
    }
    if (src.playAnnouncement) {
        return of(new ForwardDestinationPreview({
            type: ForwardDestinationViewType.PlayAnnouncement
        }));
    }
    const dest = src.forwardDestination;

    if (!dest) {
        return of(new ForwardDestinationPreview({}));
    }

    switch (dest.FwdType) {
        case ForwardDestinationType.FD_Disconnect: {
            return of(new ForwardDestinationPreview({
                type: ForwardDestinationViewType.SendBusy
            }));
        }
        case ForwardDestinationType.FD_VoiceMail:
        case ForwardDestinationType.FD_Internal: {
            if (dest.Number) {
                const voicemail = dest.FwdType === ForwardDestinationType.FD_VoiceMail;
                if (voicemail && extensionInfo?.Number === dest.Number) {
                    return of(new ForwardDestinationPreview({
                        type: ForwardDestinationViewType.MyVoicemail,
                        voicemail: true
                    }));
                }
                return specifyExtensionType$(dest.Number, session, searcher, voicemail);
            }
            break;
        }
        case ForwardDestinationType.FD_External:
        case ForwardDestinationType.FD_Rebound:
        case ForwardDestinationType.FD_Deflect: {
            return of(new ForwardDestinationPreview({
                // on extensionInfo change we need to keep myMobile forwarding
                type: dest.Number && (extensionInfo?.MobileNumber === dest.Number || prevExtensionInfo?.MobileNumber === dest.Number)
                    ? ForwardDestinationViewType.MyMobile
                    : ForwardDestinationViewType.External,
                use302: dest.FwdType === ForwardDestinationType.FD_Deflect,
                rebound: dest.FwdType === ForwardDestinationType.FD_Rebound,
                number: dest.Number,
            }));
        }
    }
    return of(new ForwardDestinationPreview({}));
}

export function convertToForwardDestinationFormValue$(session: MyPhoneSession, searcher: ContactSearcherService, src: ForwardDestinationValue | null, extensionInfo: ExtensionInfo | null, prevExtensionInfo: ExtensionInfo | null): Observable<ForwardDestinationFormValue> {
    return convertToForwardDestinationPreview$(session, searcher, src, extensionInfo, prevExtensionInfo).pipe(map(createForwardDestinationFormValue));
}

function convertToForwardDestination(value: PartialNullable<ForwardDestinationFormValue>, extensionInfo: ExtensionInfo): ConvertableForwardDestination {
    let newNumber: string | null | undefined;
    let newType = ForwardDestinationType.FD_Disconnect;
    let contact: PbxPeer | undefined | null;

    switch (value.type) {
        case ForwardDestinationViewType.SameAsAllCalls:
        case ForwardDestinationViewType.MyVoicemail:
            newNumber = extensionInfo.Number;
            newType = ForwardDestinationType.FD_VoiceMail;
            break;
        case ForwardDestinationViewType.MyMobile:
        case ForwardDestinationViewType.External:
            newNumber = value.type === ForwardDestinationViewType.MyMobile ? extensionInfo.MobileNumber : value.number;
            newType = value.rebound
                ? ForwardDestinationType.FD_Rebound
                : newType = value.use302 ? ForwardDestinationType.FD_Deflect : ForwardDestinationType.FD_External;
            break;
        case ForwardDestinationViewType.SystemExtension:
        case ForwardDestinationViewType.Extension: {
            contact = value.type === ForwardDestinationViewType.SystemExtension
                ? value.systemContact : value.extensionContact;

            newNumber = contact?.Number;
            newType = value.voicemail ? ForwardDestinationType.FD_VoiceMail : ForwardDestinationType.FD_Internal;
            break;
        }
    }

    const destination: ConvertableForwardDestination = new ForwardDestination({ Number: newNumber || '', FwdType: newType });

    if (contact) {
        destination.PeerName = contact.Name ?? undefined;
        destination.PeerType = contact.Type ?? undefined;
    }
    destination.ToMyMobile = value.type === ForwardDestinationViewType.MyMobile;
    return destination;
}

export function convertToForwardDestinationValue(value: PartialNullable<ForwardDestinationFormValue>, extensionInfo: ExtensionInfo): ForwardDestinationValue | null {
    return value.type == null ? null : {
        forwardDestination: convertToForwardDestination(value, extensionInfo),
        sameAsAllCalls: value.type === ForwardDestinationViewType.SameAsAllCalls,
        playAnnouncement: value.type === ForwardDestinationViewType.PlayAnnouncement
    };
}

export function isWithVoicemail(systemContact?: Pick<PbxPeer, 'Type'> | null) {
    switch (systemContact?.Type) {
        case PbxPeerType.Extension:
        case PbxPeerType.Queue:
        case PbxPeerType.RingGroup:
            return true;
    }
    return false;
}
