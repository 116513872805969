import { Chat } from '../chat';
import { ChatListUpdater } from '@webclient/chat/chat-service/chat-list.service';
import { of } from 'rxjs';

export class ChatRemoved implements ChatListUpdater {
    constructor(private readonly conversationId: number) { }

    public updateChatList(chatList: Chat[]) {
        return of(chatList.filter(c => c.id !== this.conversationId));
    }
}
