import { satisfies } from 'compare-versions';

export interface Capabilities {
    readonly pickupEnabled: boolean
}

export function getCapabilities(version: string): Capabilities {
    return {
        pickupEnabled: satisfies(version, '>=18.0.3')
    };
}
