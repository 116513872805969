import { Component, OnDestroy, OnInit } from '@angular/core';
import { MyPhoneService } from './myphone/myphone.service';
import { SplashService } from './splash.service';
import {
    filter, pairwise, take
} from 'rxjs/operators';
import { combineLatest, Subscription } from 'rxjs';
import { observe } from '@webclient/rx-utils';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { LangService } from '@webclient/lang.service';

@Component({
    template: `
        <banner-container></banner-container>
        <router-outlet></router-outlet>
    `
})
export class MyphoneSessionComponent implements OnDestroy, OnInit {
    private sub: Subscription;
    private logoutTimestampSubscription : Subscription;
    private languageSubscription : Subscription;

    constructor(private myPhoneService: MyPhoneService, private splashService: SplashService,
                private localStorageService: LocalStorageService, private router: Router, private lang: LangService,) {
    }

    ngOnInit() {
        this.myPhoneService.connect();
        this.sub = this.myPhoneService.myPhoneSession.pipe(take(1)).subscribe(() => {
            this.splashService.remove();
        });

        this.languageSubscription = combineLatest([this.lang.sessionLanguage$, this.lang.isExtensionLanguageOverwritten$])
            .subscribe(([sessionLanguage, isExtensionLanguageOverwritten]) => {
                const localStorageLang = this.localStorageService.retrieve(LocalStorageKeys.Language);
                if (!isExtensionLanguageOverwritten && !!localStorageLang && localStorageLang !== '') {
                    this.lang.requestSetSystemLanguage(localStorageLang);
                }
                else {
                    this.localStorageService.store(LocalStorageKeys.Language, sessionLanguage);
                }
            });

        this.logoutTimestampSubscription = observe(this.localStorageService, LocalStorageKeys.LogoutTimestamp, -1).pipe(
            pairwise(),
            filter(([, newTimeStamp]) => newTimeStamp > 0)
        ).subscribe(() => {
            this.router.navigate(['/login']);
        });
    }

    ngOnDestroy() {
        this.sub?.unsubscribe();
        this.logoutTimestampSubscription?.unsubscribe();
        this.languageSubscription?.unsubscribe();
        this.myPhoneService.disconnect();
    }
}
