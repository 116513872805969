import { Pipe, PipeTransform } from '@angular/core';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { Chat } from '@webclient/chat/chat';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { ResponseSystemParameters } from '@myphone';
import { AppContact } from '@webclient/myphone/contact';
import { TranslateService } from '@ngx-translate/core';
import { AppContactType } from '@webclient/myphone/app-contact-type';

export function chatParticipantId(params: ResponseSystemParameters, contact: AppContact) {
    let name = params.IsLastFirst ? contact.lastNameFirstName : contact.firstNameLastName;
    if (contact.type === AppContactType.Extension) {
        if (!name) {
            name = `${contact.extensionNumber}`;
        }
        else {
            name = `${name} ${contact.extensionNumber}`;
        }
    }
    if (!name) {
        name = contact.emailAddress;
    }
    if (!name) {
        name = contact.bestPhone;
    }
    return name ?? '';
}

@Pipe({ name: 'chatTitle' })
export class ChatTitlePipe implements PipeTransform {
    public constructor(private service: MyPhoneService, private translateService: TranslateService) {

    }

    public transform(chat: Chat): Observable<string> {
        return this.service.myPhoneSession.pipe(
            switchMap(session => session.systemParameters$),
            switchMap((params): Observable<string> => {
                const externalContact = chat.participants.externalParticipant?.contact;
                if (externalContact) {
                    if (chat.privateName) {
                        return of(chat.privateName);
                    }
                    if (chat.publicName) {
                        return of(chat.publicName);
                    }
                    return of(chatParticipantId(params, externalContact));
                }
                else {
                    // only for a group that has more than 2 participant.
                    if (chat.isGroupChat) {
                        if (chat.privateName) {
                            return of(chat.privateName);
                        }
                        if (chat.publicName) {
                            return of(chat.publicName);
                        }
                    }
                    return of(chat.activeParticipants
                        .filter(x => {
                            if (chat.activeParticipants.size > 1) {
                                return !x.isMe;
                            }

                            return true;
                        })
                        .map(ex => chatParticipantId(params, ex.contact))
                        .filter(Boolean)
                        .join(', '));
                }
            })
        );
    }
}
