import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConferenceWrapper } from '@webclient/meeting/conference-wrapper';
import { ModalService } from '@webclient/modal/app-modal.service';
import { SearchResult } from '@webclient/shared/search/search-result';
import { UtilsService } from '@webclient/shared/utils.service';
import { MyPhoneService } from '../../myphone/myphone.service';
import {
    ActionType,
    RequestGetConferenceSchedule,
    RequestUpsertScheduledConference,
    WebMeetingParticipant,
    WebMeetingState
} from '@myphone';
import { EMPTY, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable()
export class VideoConferenceService {
    constructor(private myPhoneService: MyPhoneService,
                private modalService: ModalService,
                private translate: TranslateService) {
    }

    updateScheduledVideoConferenceData(request: WebMeetingState): Observable<number> {
        return this.myPhoneService.get<WebMeetingState>(request).pipe(
            map((response: WebMeetingState) => {
                return response.Id;
            })
        );
    }

    addWebmeetingContact(conference: ConferenceWrapper, result: SearchResult) {
        if (!result.isEmail) {
            this.modalService.error('_i18n.Conference_Schedule_ErrorInvalidEmail');
            return EMPTY;
        }

        const email = result.number;
        const name = result.name === email ? UtilsService.substrEmailToName(email) : result.name;

        const webMeeting$ = this.myPhoneService.get<RequestUpsertScheduledConference>(new RequestGetConferenceSchedule({ Id: conference.audioConference.ScheduleID, PinCode: conference.audioConference.PinCode })).pipe(
            map(response => response.webMeetingScheduleState)
        );
        return webMeeting$.pipe(switchMap(x => {
            if (!x) {
                return EMPTY;
            }
            const wmState = new WebMeetingState(x);

            if (!wmState.Participants.hasOwnProperty('Items')) {
                wmState.Participants.Items = [];
            }

            const wmParticipants = wmState.Participants.Items;

            wmParticipants.forEach(p => p.Action = ActionType.NoUpdates);

            if (wmState.Organizer && wmState.Organizer.Email === email) {
                return this.translate.get('_i18n.ParticipantWithEmailAlreadyAddedAsOrganizer', { email })
                    .pipe(tap(t => this.modalService.error(t)));
            }

            const i = wmParticipants.findIndex((participant) => participant.Email === email);
            if (i !== -1) {
                return this.translate.get('_i18n.ParticipantWithEmailAlreadyAdded', { email })
                    .pipe(tap(t => this.modalService.error(t)));
            }

            wmParticipants.push(new WebMeetingParticipant({
                Action: ActionType.Inserted,
                Email: email,
                Name: name
            }));

            return this.updateScheduledVideoConferenceData(wmState);
        }));
    }
}
