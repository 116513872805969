import { Component } from '@angular/core';
import { WebmeetingHolderService } from '@webclient/layout/webmeeting-holder/webmeeting-holder.service';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { conferenceBaseUrl } from '@webclient/meeting/meeting-consts';

@Component({
    selector: 'app-webmeeting-activator',
    template: `
        <div *ngIf="(isVisible$|async)" class="btn-group" role="group" dropdown>
            <a id="button-back-to-meeting" class="header-button text-button themify" role="button" (click)="backToMeeting()" [title]="'_i18n.BackToMeet'| translate" >
                <i class="customSVGIcons svg-sm" app-webmeeting-custom-icon></i>
            </a>
            <a id="button-split" type="button" dropdownToggle class=" header-button text-button themify dropdown-toggle dropdown-toggle-split"
               aria-controls="dropdown-split">
                <span class="caret"></span>
            </a>
            <ul id="dropdown-split" *dropdownMenu class="dropdown-menu black-menu"
                role="menu" aria-labelledby="button-split">
                <li role="menuitem">
                    <a (click)="endMeeting()" [translate]="'_i18n.EndMeeting'" class="dropdown-item">Action</a>
                </li>
            </ul>
        </div>
    `,
    styles: [`
    `]
})
export class WebmeetingActivatorComponent {
    readonly isVisible$: Observable<boolean>;

    constructor(private service: WebmeetingHolderService, private router: Router) {
        this.isVisible$ = combineLatest([service.isActive, service.isMeetingMainScreen]).pipe(map(([active, meetingMainScreen]) => active && !meetingMainScreen));
    }

    backToMeeting() {
        this.router.navigate([conferenceBaseUrl]);
    }

    endMeeting() {
        this.service.deactivate();
    }
}
