import {
    distinctUntilChanged, map, share, switchMap, take
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MyPhoneSession } from '../myphone/myphone-session';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '../settings/local-storage-keys';
import { UtilsService } from '../shared/utils.service';

import {
    Registration, Registrations, ResponseSystemParameters
} from '@myphone';
import { MyPhoneService } from '../myphone/myphone.service';
import {
    combineLatest, EMPTY, Observable, ReplaySubject
} from 'rxjs';
import { observe } from '@webclient/rx-utils';

const ipRegExp = new RegExp(/\b\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\b/);

const ipv6RegExp = new RegExp(/\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*/);

export const AgentPushService = '3CX Push Service';
export const AgentMobileClient = '3CX Mobile Client';
export const AgentWebclient = '3CX WebRTC proxy';

export interface SelectedDevice {
    userAgent: string;
    ipAddress: string;
}

// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
export function b64EncodeUnicode(str: string) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return window.btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        (match, p1) => String.fromCharCode(('0x' + p1) as any as number)
    ));
}

function is3CXPhoneRegistration(reg: Registration): boolean {
    return reg.UserAgent.toLowerCase().indexOf('3cx') !== -1;
}

const systemRegistrations = ['3cx teams agent', '3cx dialer', '3cxphone for android', '3cxphone for iphone'];

// Ring my mobile simultaneously - registation
function is3CXSystemRegistration(reg: Registration): boolean {
    const agent = reg.UserAgent.toLowerCase();
    return systemRegistrations.some(reg => agent.startsWith(reg));
}

function extractRegistrations(registrations: Registrations): Registration[] {
    if (!registrations || !registrations.Items) {
        return [];
    }
    return registrations.Items.filter(x => x.UserAgent && !is3CXSystemRegistration(x));
}

function hardPhonesFirst(regisrations: Registration[]): Registration[] {
    const otherPhones: Registration[] = [];
    const webRtcPhones: Registration[] = [];
    const hardPhones: Registration[] = [];

    regisrations.forEach(reg => {
        if (isWebRTCEndpoint(reg)) {
            webRtcPhones.push(reg);
        }
        else if (is3CXPhoneRegistration(reg)) {
            otherPhones.push(reg);
        }
        else {
            hardPhones.push(reg);
        }
    });

    return hardPhones.concat(webRtcPhones).concat(otherPhones);
}

export function isWebRtcDevice(device: Registration) {
    return device.UserAgent === AgentWebclient;
}

export function isPushDevice(device: Registration | undefined) {
    return !!(device && (device.UserAgent === AgentMobileClient));
}

export function isWebRTCEndpoint(device: Registration | undefined) {
    return !!(device && (isWebRtcDevice(device)));
}

export function isWebRtcOrNoDevice(selectedDevice: Registration | undefined) {
    return !selectedDevice || isWebRtcDevice(selectedDevice);
}

function arrangePhoneDevices(isWebRtcAllowed: boolean, regList: Registrations, cstaSupprotedDeviceTypes: string[], baseUrl: URL, webRTCDeviceContact?: string, pushEnabled?: boolean): Registration[] {
    const registrations = extractRegistrations(regList);
    registrations.push(new Registration({
        Id: 0,
        UserAgent: AgentWebclient,
        Contact: 'Browser'
    }));
    return hardPhonesFirst(registrations);
}

function userSelectedPhoneDevice(extNumber: string, localStorageService: LocalStorageService): Observable<SelectedDevice> {
    const selectedDeviceKey = UtilsService.getUserScopedLocalStorageKey(extNumber, LocalStorageKeys.SelectedPhoneDevice);
    return observe<any>(localStorageService, selectedDeviceKey).pipe(
        map(value => {
            if (value === null || !(typeof value === 'object')) {
                return { userAgent: value };
            }
            else {
                return value;
            }
        })
    );
}

export function extractIpAddress(contact: string) {
    return contact?.match(ipRegExp)?.[0].trim() || contact?.match(ipv6RegExp)?.[0].trim() || undefined;
}

export function systemSelectedPhoneDeviceByRegistrations(devices$: Observable<Registrations>,
    isWebRtcAllowed$: Observable<boolean>,
    cstaSupportedDevices$: Observable<string[]>,
    webRTCDeviceContact$: Observable<string|undefined>,
    extNumber: string,
    localStorageService: LocalStorageService,
    baseUrl: URL): Observable<Registration | undefined> {
    const arrangedDevices$ = combineLatest([isWebRtcAllowed$, devices$, cstaSupportedDevices$, webRTCDeviceContact$]).pipe(map(x => {
        const [isWebRtcAllowed, devices, cstaSupportedDevices, webRTCDeviceContact] = x;
        return arrangePhoneDevices(isWebRtcAllowed, devices, cstaSupportedDevices, baseUrl, webRTCDeviceContact);
    }));

    return combineLatest([arrangedDevices$, userSelectedPhoneDevice(extNumber, localStorageService)])
        .pipe(map(values => {
            const [devices, selectedPhoneDevice] = values;
            // Try to find exact match
            const device = devices.find(dev => dev.UserAgent === selectedPhoneDevice.userAgent && extractIpAddress(dev.Contact) === selectedPhoneDevice.ipAddress);
            if (device !== undefined) {
                return device;
            }
            else {
                // Match user agent only
                const userAgentMatch = devices.find(dev => dev.UserAgent === selectedPhoneDevice.userAgent);
                // Match webrtc Device (if it was push, then we will pick browser if it's presented)
                const webRtcMatch = (selectedPhoneDevice.userAgent === AgentWebclient || selectedPhoneDevice.userAgent === AgentMobileClient) ? devices.find(dev => isWebRTCEndpoint(dev)) : undefined;
                if (userAgentMatch) {
                    return userAgentMatch;
                }
                else if (webRtcMatch) {
                    return webRtcMatch;
                }
                else if (devices.length > 0) {
                    return devices[0];
                }
                else {
                    return undefined;
                }
            }
        }));
}

export function splitAndTrim(x: string) {
    return x.split(',').map(devType => devType.trim());
}

export function extractCSTASupportedDevices(systemParameters: ResponseSystemParameters): string[] {
    if (!systemParameters.CstaUserAgents) {
        return [];
    }
    return splitAndTrim(systemParameters.CstaUserAgents);
}

function systemSelectedPhoneDevice(session: MyPhoneSession, localStorageService: LocalStorageService, baseUrl: URL): Observable<Registration | undefined> {
    return systemSelectedPhoneDeviceByRegistrations(
        session.myInfo.ActiveDevices$,
        session.myInfo.AllowWebrtcEndpoint$,
        session.systemParameters$.pipe(map(systemParameters => extractCSTASupportedDevices(systemParameters))),
        session.webRTCEndpoint$.pipe(map(x => x.DeviceContact), distinctUntilChanged()),
        session.myInfo.Number,
        localStorageService,
        baseUrl);
}

@Injectable()
export class DeviceService {
    public readonly phones$: Observable<Registration[]>;
    public readonly isWebRTCSelected$: Observable<boolean>;
    public readonly selectedPhoneDevice$: Observable<Registration | undefined>;
    public readonly selectedPhoneDeviceAgent$: Observable<string>;

    constructor(private myphone: MyPhoneService,
                private localStorageService: LocalStorageService) {
        this.selectedPhoneDevice$ = myphone.myPhoneSession.pipe(
            switchMap(session => (session.domainUrl ? systemSelectedPhoneDevice(session, localStorageService, new URL(session.domainUrl)) : EMPTY)),
            distinctUntilChanged((x: string | undefined, y: string | undefined) => x === y,
                device => (device ? device.Contact : undefined)),
            share({ connector: () => new ReplaySubject<Registration | undefined>(1) }),
        );

        this.selectedPhoneDeviceAgent$ = this.selectedPhoneDevice$.pipe(
            map(device => (device ? device.UserAgent : '')));

        this.isWebRTCSelected$ = this.selectedPhoneDevice$.pipe(map(device => {
            return isWebRTCEndpoint(device);
        }), distinctUntilChanged(), share({ connector: () => new ReplaySubject<boolean>(1) }));

        this.phones$ = myphone.myPhoneSession.pipe(
            switchMap(session => {
                const cstaSupportedDevices = extractCSTASupportedDevices(session.systemParameters);
                return combineLatest([session.myInfo.AllowWebrtcEndpoint$, session.myInfo.ActiveDevices$, session.webRTCEndpoint$.pipe(map(x => x.DeviceContact), distinctUntilChanged())]).pipe(map(values => {
                    const [isWebRtcAllowed, devices, webRTCDeviceContact] = values;
                    return session.domainUrl ? arrangePhoneDevices(isWebRtcAllowed, devices, cstaSupportedDevices, new URL(session.domainUrl), webRTCDeviceContact) : [];
                }));
            }),
            share({ connector: () => new ReplaySubject<Registration[]>(1) }),
        );
    }

    public selectPhoneDevice(registration: Registration) {
        this.myphone.myPhoneSession.pipe(take(1)).subscribe(session => {
            const userScopedKey = UtilsService.getUserScopedLocalStorageKey(session.myInfo.Number, LocalStorageKeys.SelectedPhoneDevice);
            const deviceInfo = {
                userAgent: registration.UserAgent,
                ipAddress: extractIpAddress(registration.Contact)
            };
            this.localStorageService.store(userScopedKey, deviceInfo);
        });
    }
}
