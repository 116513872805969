import { LocalConnectionAction, NotificationServiceImpl } from '@webclient/notifications/notification-service-interface';
import { PushChatMessage } from '@webclient/notifications/push-chat-message';
import { MyCall } from '@webclient/phone/mycall';
import {
    EMPTY, Observable, of
} from 'rxjs';
import {
    switchMap
} from 'rxjs/operators';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { ExtendedSwPushService } from '@webclient/notifications/extended-sw-push.service';

export class NgswNotificationService implements NotificationServiceImpl {
    public readonly notificationActions$: Observable<LocalConnectionAction>;

    constructor(
        myPhoneService: MyPhoneService,
        private swPush: ExtendedSwPushService
    ) {
        this.notificationActions$ = swPush.notificationClicks.pipe(switchMap(notif => {
            if ((notif.notification.data.call !== undefined) && (notif.action === 'answer' || notif.action === 'decline')) {
                return of(new LocalConnectionAction({
                    action: notif.action,
                    callId: +notif.notification.data.call
                }));
            }
            else {
                return EMPTY;
            }
        }));
    }

    public createChatNotification(message: PushChatMessage, silent: boolean) {
    }

    public createCallNotification(myCall: MyCall, icon: string, silent: boolean) {
    }

    public removeChatNotification(conversationId?: number): void {
        if (conversationId) {
            this.swPush.closeNotifications('chat-' + conversationId.toString(10));
        }
    }

    public removeCallNotification(myCall: MyCall) {
        if (myCall.callId) {
            this.swPush.closeNotifications('call-' + myCall.callId.toString(10));
        }
    }
}
