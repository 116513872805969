import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { ListPeerRequestParams, PbxPeer, PbxPeerType, PeersApiService } from '@xapi';
import {
    BehaviorSubject,
    combineLatest, concat, EMPTY,
    Observable,
    of,
    ReplaySubject,
    switchMap,
    switchScan,
    takeUntil
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    skipWhile,
    tap
} from 'rxjs/operators';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldValueAccessor } from '@webclient/fields';
import { PagedResultWithTotal } from '@webclient/pagedResultWithTotal';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { debouncedSearch } from '@webclient/rx-utils';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { escapeODataSearch } from '@office/reports/helpers';

export const departmentName = 'Dept';

interface PeerAccumulatedResult {
    peerList: PbxPeer[],
    total: number,
    page: number
}

function getNumericPart(str: string | null | undefined): number {
    return parseInt((str || '').replace(/\D/g, ''), 10);
}

@Component({
    selector: 'app-dn-select',
    templateUrl: 'dn-select.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: DnSelectComponent }],
    styleUrls: ['dn-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, FormsModule, TranslateModule, NgSelectModule],
})
export class DnSelectComponent extends FieldValueAccessor<string|PbxPeer|null> implements OnInit, ControlValueAccessor, OnChanges {
    @Input()
    autoOpen = false;

    @Input()
    public searchDnTypes: PbxPeerType[] = [];

    @Input()
    clearable = false;

    @Input()
    placeholder = '';

    @Input()
    showPlaceholderEllipsis = true;

    /** Part of the request filter, rule applied with and */
    @Input() peerFilter?: string;

    @Output()
    selectClosed = new EventEmitter();

    @ViewChild(NgSelectComponent, { static: true })
    ngSelect: NgSelectComponent;

    /** Sort by numeric part of a peer's Number */
    @Input() orderByNumericNumber?: boolean;

    /** Filter out peers, marked as Hidden on server, when select is used for forwarding */
    @Input() isForwarding?: boolean;

    @Input() isObject = true;

    // Always resolve name if not provided.
    // Required in the situation when you know there's no name provided by the model
    // TODO remove because if string is provided we always resolve and for object we should not resolve (BLF case workaround)
    @Input() forceResolveName = false;

    @Input() hideHotdesking = true;

    @Input() isReportPeers = false;

    @Input() hideGroupType = false;

    /** additional props to be present for added peers */
    @Input() extendPeer?: Array<keyof Omit<PbxPeer, 'Id' | 'Number' | 'Name' | 'Type'>>;

    /** Show null value as indeterminate input state. Changed should not be counted, as it is intentional empty value. */
    @Input() nullIsIndeterminate = false;
    @Input() indeterminatePlaceholder = '_i18n.LeaveUnchanged';

    public selectedNumber: string | null;

    public groupMembers$: Observable<PbxPeer[] | null>;
    public loading = false;
    itemsPerPage = 50;
    readonly requestMore$ = new BehaviorSubject<boolean>(true);
    readonly hasBeenTouched$ = new BehaviorSubject<boolean>(false);
    readonly searchSubject$ = new BehaviorSubject('');
    private searchDnTypesSubject: BehaviorSubject<PbxPeerType[]>;
    private writtenDn$ = new ReplaySubject<PbxPeer | string | null>(1);

    public constructor(private peerService: PeersApiService, cd: ChangeDetectorRef) {
        super(cd);
        this.touchOnChange = true;
    }

    get indeterminate() {
        return this.nullIsIndeterminate && this.value == null && !this.changed;
    }

    ngOnInit() {
        this.searchDnTypesSubject = new BehaviorSubject<PbxPeerType[]>(this.searchDnTypes);

        const searchDnTypes$ = this.searchDnTypesSubject.asObservable().pipe(
            distinctUntilChanged((oldTypes, newTypes) => {
                return oldTypes.join('') === newTypes.join('');
            }),
        );

        const search$ = this.searchSubject$.pipe(
            debouncedSearch(),
            map((searchTerm) => searchTerm.trim()),
            distinctUntilChanged()
        );

        const defaultDn$: Observable<PbxPeer[]> = this.writtenDn$.pipe(
            switchMap(dn => {
                if (!dn) {
                    return EMPTY;
                }
                if (typeof dn === 'string') {
                    return this.peerService.getPeerByNumber({ number: dn })
                        .pipe(catchError(() => of({ Number: dn })));
                }
                else if (!dn.Name && this.forceResolveName && dn.Number) {
                    return this.peerService.getPeerByNumber({ number: dn.Number })
                        .pipe(catchError(() => of(dn)));
                }
                return dn.Number ? of(dn) : EMPTY;
            }),
            map(item => [item]),
            takeUntil(this.hasBeenTouched$.pipe(filter(Boolean))),
        );

        const requestParameters$ = this.hasBeenTouched$.pipe(
            skipWhile(touched => !touched),
            switchMap(() => combineLatest([search$, searchDnTypes$]))
        );

        const regularSearch = requestParameters$.pipe(
            switchMap(([search, searchTypes]) =>
                this.requestMore$.pipe(
                    tap(() => this.loading = true),
                    switchScan((acc : PeerAccumulatedResult, _) => {
                        // initial search
                        if (acc.total === -1) {
                            return this.getPagedResponse(search, searchTypes, 0).pipe(
                                map((pagedResult) => ({
                                    peerList: acc.peerList.concat(pagedResult.value || []),
                                    total: pagedResult.totalItems,
                                    page: 0
                                }))
                            );
                        }
                        // more items to request
                        else if (acc.peerList.length < acc.total) {
                            const nextPage = acc.page + 1;
                            return this.getPagedResponse(search, searchTypes, nextPage).pipe(
                                map((pagedResult) => ({
                                    peerList: acc.peerList.concat(pagedResult.value || []),
                                    total: pagedResult.totalItems,
                                    page: nextPage
                                }))
                            );
                        }
                        else { // no more items => return existing results
                            return of(acc);
                        }
                    }, { peerList: [] as PbxPeer[], total: -1, page: -1 }),
                    tap(() => this.loading = false),
                    map((result) => result.peerList)
                )
            ),
            shareReplay({ bufferSize: 1, refCount: true })
        );

        this.groupMembers$ = concat(defaultDn$, regularSearch);

        if (this.autoOpen) {
            this.ngSelect.open();
        }
    }

    getPagedResponse(searchTerm: string, searchTypes: PbxPeerType[], page: number): Observable<PagedResultWithTotal<PbxPeer[]>> {
        const requestParameters: ListPeerRequestParams = {
            // Remove holiday from dropdowns for now
            $filter: `not (Number eq 'HOL')${this.isForwarding ? ' and not Hidden' : ''}`,
            $select: ['Number', 'Name', 'Type', 'Id', ...(this.extendPeer ?? [])],
            $orderby: ['Number, Name'],
            ...(this.extendPeer?.includes('MemberOf') ? { $expand: ['MemberOf($select=Name)'] } : {}),
            $count: true,
            $top: this.itemsPerPage,
            $skip: page * this.itemsPerPage,
        };
        if (searchTerm) {
            requestParameters.$search = escapeODataSearch(searchTerm);
        }
        if (searchTypes && searchTypes.length) {
            requestParameters.$filter += ' and (' + searchTypes.map((type) => (type === PbxPeerType.Parking ? `(Type eq '${type}' and startsWith(Number,'SP'))` : `(Type eq '${type}')`)).join(' or ') + ')';
        }
        if (this.peerFilter) {
            requestParameters.$filter += ' and ' + this.peerFilter;
        }
        if (this.hideHotdesking) {
            requestParameters.$filter += ' and not startsWith(Number,\'HD\')';
        }
        return (this.isReportPeers ? this.peerService.getReportPeers(requestParameters) : this.peerService.listPeer(requestParameters)).pipe(
            map(response => ({ value: response.value || [], totalItems: +((response as any)['@odata.count']) })),
            map(response => {
                if (this.orderByNumericNumber) {
                    response.value.sort((a, b) => getNumericPart(a.Number) - getNumericPart(b.Number));
                }
                return response;
            }),
            catchError(() => of(({ value: [], totalItems: 0 })))
        );
    }

    customSearchFn() {
        return true;
    }

    onScrollToEnd() {
        this.requestMore$.next(true);
    }

    clearSearch() {
        this.searchSubject$.next('');
    }

    searching(input : { term: string; items: any[];}) {
        this.searchSubject$.next(input.term);
    }

    writeValue(value: string | PbxPeer | null) {
        super.writeValue(value);
        // treat partial dn as string to show the full displayName in select; if dn has Name then it's full
        this.writtenDn$.next(value);
        this.selectedNumber = typeof value === 'string' ? value : value?.Number || '';
    }

    onItemSelected(item: PbxPeer | null) {
        this.valueChanged(this.isObject ? item : item?.Number || '');
    }

    open() {
        this.hasBeenTouched$.next(true);
    }

    close() {
        this.clearSearch();
        this.markAsTouched();
        this.selectClosed.next(true);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.searchDnTypes && !changes.searchDnTypes.firstChange) {
            this.searchDnTypesSubject.next(this.searchDnTypes);
        }
    }

    getDnDisplay(item: PbxPeer): string {
        return [
            item.Type === PbxPeerType.Group ? !this.hideGroupType ? departmentName : '' : item.Number,
            item.Name,
        ].join(' ').trim();
    }
}
