import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import type { ContainerState } from '@webclient/standalones/toast-container';
import { DialerVisibilityInfo } from '@webclient/call-adapter/dialer-visibility-info';
import { shareReplay } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DialerVisibilityService {
    public readonly toggleDialer = new BehaviorSubject<boolean | null>(false);
    public readonly dialerRealState = new BehaviorSubject<ContainerState>('hidden');
    public readonly dialerVisibilityInfo = new BehaviorSubject<DialerVisibilityInfo>(new DialerVisibilityInfo(false));

    public readonly dialerToggle: Observable<boolean | null>;

    constructor() {
        this.dialerToggle = this.toggleDialer.pipe(
            shareReplay({ refCount: false, bufferSize: 1 })
        );
    }
}
