import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'videoOnly' })
export class VideoOnlyPipe implements PipeTransform {
    transform(input: MediaStream|null): MediaStream|null {
        if (!input) {
            return null;
        }
        const tracks = input.getVideoTracks();
        // Edge crashes when MediaStream assigned to Video doesn't have any tracks
        if (tracks.length === 0) {
            return null;
        }
        return new MediaStream(tracks);
    }
}
