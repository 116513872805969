// eslint-disable-next-line max-classes-per-file
import { AppContact } from '@webclient/myphone/contact';
import {
    LocalConnection, MyWebRTCEndpoint,
    Registration
} from '@myphone';
import { MediaDescription } from '@webclient/phone/media-description';

/**
 * Action, used for
 * Creating new myCall in case of request
 */
export class RequestTryingCall {
    constructor(public readonly phoneNumber: string, public isWebRTCCall: boolean, public callId: number, public readonly tapiCallId?: string) {
    }
}
/**
 * Action, used for
 * Removing call before it has been connected
 */
export class RemoveTryingCall {
    constructor(public myCallId: number) {
    }
}

/**
 * Action, used for
 * Assigning WebRtcId on early stage to work with myCall properly
 */
export class RequestProcessingWebRtcCall {
    constructor(public myCallId: number, public webRtcId: number) {
    }
}
/**
 * Action, used for
 * Assigning LocalConnectionId on early stage to work with myCall properly
 */
export class RequestProcessingLocalConnectionCall {
    constructor(public myCallId: number, public localConnectionId: number) {
    }
}
/**
 * Action, used for
 * Requesting attended transfer
 */
export class RequestAttTransfer {
    constructor(public readonly leftMyCallId: number, public readonly rightMyCallId: number) {
    }
}
/**
 * Action, used for
 * Removing dropping call
 */
export class RemoveDroppingCall {
    constructor(public readonly myCallId: number) {
    }
}

export enum PushAction {
    Answer,
    Decline
}

export class ProcessPickUpCallFromPushWakeUp {
    constructor(
        public readonly connection: LocalConnection,
        public readonly isAnswer: boolean,
    ) {
    }
}

export class RequestAttachFoundedContact {
    constructor(public readonly myCallId: number, public readonly contact: AppContact) {
    }
}

export type CallControlMessage =
    RequestAttTransfer
    | RequestTryingCall
    | RemoveTryingCall
    | RemoveDroppingCall
    | RequestProcessingWebRtcCall
    | ProcessPickUpCallFromPushWakeUp
    | RequestAttachFoundedContact

export type InternalCallsSource = CallControlMessage | [LocalConnection[], Registration | undefined, Registration | undefined, MediaDescription[], MyWebRTCEndpoint, LocalConnection[]];

export type ConnectionsMap = { [id: number]: LocalConnection }

export type SipDialogMap = { [id: number]: string[] }
