import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import getFromMicrosoftStoreImageData from '../../img/getFromMicrosoftStore.png';
import { AppsUtilityService } from '@webclient/services/apps-utility.service';
import { takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';
import { DestroyService } from '@webclient/services/destroy.service';

@Component({
    template: `
        <div class="d-flex flex-row">
            <div>
                <p class="ps-2">1. <span [innerHTML]="'_i18n.InstallWindows' | translate"></span></p>
                <div class="ps-2 mb-3 d-flex gap-2 justify-content-center">
                    <a href="{{'_i18n.MsWindowsStoreClientLink' | translate}}" target="_blank">
                        <img [src]="getFromMicrosoftStoreIcon" height="30" alt="MsWindowsStore">
                    </a>
                </div>
                <p class="ps-2 mb-2">
                    2. {{'_i18n.ProvisionWindowsApp' | translate}}
                </p>
                <div class="d-flex flex-column align-items-center ">
                    <button type="button" class="btn btn-primary my-2" (click)="provisionWinApp()">
                        {{"_i18n.Provision" | translate}}
                    </button>
                </div>
                <div class="d-none">
                    <iframe #appProtocol></iframe>
                </div>
            </div>
            <!-- hidden on width < 576px -->
            <span class="customSVGIcons android-phone d-none d-sm-block ps-4" app-ms-client-custom-icon></span>
        </div>
    `,
    styleUrls: ['./apps-dialog-content.component.scss'],
    selector: 'app-windows-dialog-content',
    providers: [DestroyService],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WindowsDialogContentComponent {
    readonly getFromMicrosoftStoreIcon = getFromMicrosoftStoreImageData;

    /** for another user in admin view */
    @Input() userId?: number;

    @ViewChild('appProtocol')
    private winAppProtocol: ElementRef;

    constructor(private appsUtility: AppsUtilityService, private destroy$: DestroyService) { }

    provisionWinApp() {
        this.appsUtility.getWindowsProvisionUrl$(this.userId).pipe(take(1), takeUntil(this.destroy$))
            .subscribe(x => this.winAppProtocol.nativeElement.src = x);
    }
}
