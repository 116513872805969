/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PbxLiveChatCommunication = 'ChatOnly' | 'PhoneAndChat' | 'PhoneOnly' | 'VideoPhoneAndChat';

export const PbxLiveChatCommunication = {
    ChatOnly: 'ChatOnly' as PbxLiveChatCommunication,
    PhoneAndChat: 'PhoneAndChat' as PbxLiveChatCommunication,
    PhoneOnly: 'PhoneOnly' as PbxLiveChatCommunication,
    VideoPhoneAndChat: 'VideoPhoneAndChat' as PbxLiveChatCommunication
};

