import { startWith, switchMap } from 'rxjs/operators';
import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { dummyMediaDescription, MediaDescription } from '../phone/media-description';
import { Observable, of } from 'rxjs';
import fullscreen from 'fullscreen';
import { Router } from '@angular/router';

function hasVideo(stream: MediaStream) {
    if (!stream) {
        return false;
    }
    return stream.getVideoTracks().some(track => !track.muted);
}

@Component({
    selector: 'video-output',
    styles: [`
        .containerNormal {
        }

        .containerFullscreen {
            width: 100%;
            height: 100%;
        }

        .awayVideo {
            width:100%;
            height: inherit
        }

        .homeVideo {
            max-width:256px;
            width:25%;
            position: absolute;
            bottom: 10px;
            right: 10px;
        }

        .mirrorVideo {
            transform: rotateY(180deg);
            -webkit-transform:rotateY(180deg); /* Safari and Chrome */
            -moz-transform:rotateY(180deg); /* Firefox */
        }
    `],
    template: `
        <div [ngClass]="{ 'containerNormal' : !isFullscreen, 'containerFullscreen' : isFullscreen }" class="w-100" (click)="openFullscreen($event)" #container >
            <div [ngClass]="{ 'toaster-inner' : !isFullscreen }"  style="position: relative;width: 100%; height: 100%" [style.background-color]="this.backgroundColor || '#222121'">
                <i *ngIf="isAudioOnly()" class="fa fa-volume-up fa-2x" style="position: absolute; left: 50%; top: 50%; margin-left: -10px; margin-top: -10px;"></i>
                <!--
                <i [hidden]="isVideoActive$|async" class="fa fa-ban fa-2x text-danger" style="position: absolute; left: 50%; top: 50%; margin-left: -10px; margin-top: -10px;"></i>
                -->
                <video *ngIf="media.isVideoReceived || !media.isVideoSend"
                       class="awayVideo" autoplay playsinline [srcObject]="media?.remoteStream$|async|videoOnly"></video>

                <video *ngIf="media.isVideoSend" class="mirrorVideo"
                       [ngClass]="{'homeVideo': media.isVideoReceived, 'awayVideo': !media.isVideoReceived}"
                       autoplay playsinline [srcObject]="media?.localStream|videoOnly"></video>

                <ng-template [ngIf]="isFullscreen">
                    <fullscreen-control-buttons (dropClick)="drop()"
                                                (exitFullscreenClick)="exitFullscreen($event)"></fullscreen-control-buttons>
                </ng-template>
            </div>
        </div>
    `
    // changeDetection: ChangeDetectionStrategy.OnPush
})
export class VideoOutputComponent implements OnChanges, AfterViewInit, OnDestroy {
    @Input()
    media: MediaDescription = dummyMediaDescription;

    @Input()
    backgroundColor = '';

    @ViewChild('container', { static: false })
    container!: ElementRef;

    @Output()
    dropClick = new EventEmitter<boolean>();

    isFullscreen = false;

    private fs: any;
    isVideoActive$: Observable<boolean> = of(false);

    constructor(private cd: ChangeDetectorRef, private router: Router) {

    }

    isAudioOnly() {
        return (!this.media.isNegotiationInProgress) && (!this.media.isVideoReceived);
    }

    drop() {
        this.dropClick.emit(true);
    }

    exitFullscreen(event: any) {
        event.stopPropagation();
        this.removeFullscreen();
    }

    openFullscreen(event: MouseEvent) {
        if (event.shiftKey) {
            this.router.navigate(['/webrtc', this.media.lastWebRTCState.Id]);
        }
        else {
            try {
                this.fs.request();
            }
            catch (e) {
                // Just in case
            }
        }
    }

    ngAfterViewInit() {
        this.fs = fullscreen(this.container.nativeElement);
        this.fs.on('attain', () => {
            // attained fullscreen
            this.isFullscreen = true;
            this.cd.markForCheck();
        });

        this.fs.on('release', () => {
            // released fullscreen
            this.isFullscreen = false;
            this.cd.markForCheck();
        });
    }

    ngOnDestroy() {
        this.removeFullscreen();
    }

    removeFullscreen() {
        if (this.isFullscreen) {
            try {
                if (this.fs) {
                    this.fs.release();
                }
            }
            catch (e) {
                // Ignore error here
                // Chrome throws error if we're not fullscreen now
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.media) {
            // Exit fullscreen when call changes
            this.removeFullscreen();

            if (this.media) {
                this.isVideoActive$ = this.media.remoteStream$.pipe(switchMap(stream => {
                    if (stream) {
                        return new Observable<boolean>(subscriber => {
                            const checkVideo = () => subscriber.next(hasVideo(stream));
                            stream.getTracks().forEach(track => {
                                track.onmute = checkVideo;
                                track.onunmute = checkVideo;
                            });
                            return () => {};
                        }).pipe(startWith(hasVideo(stream)));
                    }
                    else {
                        return of(false);
                    }
                }));
            }
            else {
                this.isVideoActive$ = of(false);
            }
        }
    }
}
