/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { GroupsDeleteCompanyByIdRequestBody } from '../model/groups-delete-company-by-id-request-body';
// @ts-ignore
import { GroupsDeleteCompanyByNumberRequestBody } from '../model/groups-delete-company-by-number-request-body';
// @ts-ignore
import { GroupsLinkGroupPartnerRequestBody } from '../model/groups-link-group-partner-request-body';
// @ts-ignore
import { GroupsReplaceGroupLicenseKeyRequestBody } from '../model/groups-replace-group-license-key-request-body';
// @ts-ignore
import { PbxGroup } from '../model/pbx-group';
// @ts-ignore
import { PbxGroupCollectionResponse } from '../model/pbx-group-collection-response';
// @ts-ignore
import { PbxODataErrorsODataError } from '../model/pbx-o-data-errors-o-data-error';
// @ts-ignore
import { PbxRestrictions } from '../model/pbx-restrictions';
// @ts-ignore
import { PbxRightsCollectionResponse } from '../model/pbx-rights-collection-response';
// @ts-ignore
import { PbxUserGroupCollectionResponse } from '../model/pbx-user-group-collection-response';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface CreateGroupRequestParams {
    /** New entity */
    pbxGroup: PbxGroup;
}

export interface DeleteCompanyByIdRequestParams {
    /** Action parameters */
    groupsDeleteCompanyByIdRequestBody: GroupsDeleteCompanyByIdRequestBody;
}

export interface DeleteCompanyByNumberRequestParams {
    /** Action parameters */
    groupsDeleteCompanyByNumberRequestBody: GroupsDeleteCompanyByNumberRequestBody;
}

export interface DeleteGroupRequestParams {
    /** The unique identifier of Group */
    id: number;
    /** ETag */
    ifMatch?: string;
}

export interface GetGroupRequestParams {
    /** The unique identifier of Group */
    id: number;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface GetRestrictionsRequestParams {
    /** The unique identifier of Group */
    id: number;
}

export interface LinkGroupPartnerRequestParams {
    /** Action parameters */
    groupsLinkGroupPartnerRequestBody: GroupsLinkGroupPartnerRequestBody;
}

export interface ListGroupRequestParams {
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ListMembersRequestParams {
    /** The unique identifier of Group */
    id: number;
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ListRightsRequestParams {
    /** The unique identifier of Group */
    id: number;
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ReplaceGroupLicenseKeyRequestParams {
    /** Action parameters */
    groupsReplaceGroupLicenseKeyRequestBody: GroupsReplaceGroupLicenseKeyRequestBody;
}

export interface UpdateGroupRequestParams {
    /** The unique identifier of Group */
    id: number;
    /** New property values */
    pbxGroup: PbxGroup;
}


@Injectable({
  providedIn: 'root'
})
export class GroupsApiService {

    protected basePath = '/xapi/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Add new entity to Groups
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createGroup(requestParameters: CreateGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxGroup>;
    public createGroup(requestParameters: CreateGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxGroup>>;
    public createGroup(requestParameters: CreateGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxGroup>>;
    public createGroup(requestParameters: CreateGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const pbxGroup = requestParameters.pbxGroup;
        if (pbxGroup === null || pbxGroup === undefined) {
            throw new Error('Required parameter pbxGroup was null or undefined when calling createGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups`;
        return this.httpClient.request<PbxGroup>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pbxGroup,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public createGroupQuery(requestParameters: CreateGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public createGroupQuery(requestParameters: CreateGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public createGroupQuery(requestParameters: CreateGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public createGroupQuery(requestParameters: CreateGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const pbxGroup = requestParameters.pbxGroup;
        if (pbxGroup === null || pbxGroup === undefined) {
        throw new Error('Required parameter pbxGroup was null or undefined when calling createGroup.');
        }

    return `/Groups`;
}

    /**
     * Invoke action DeleteCompanyById
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyById(requestParameters: DeleteCompanyByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteCompanyById(requestParameters: DeleteCompanyByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteCompanyById(requestParameters: DeleteCompanyByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteCompanyById(requestParameters: DeleteCompanyByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const groupsDeleteCompanyByIdRequestBody = requestParameters.groupsDeleteCompanyByIdRequestBody;
        if (groupsDeleteCompanyByIdRequestBody === null || groupsDeleteCompanyByIdRequestBody === undefined) {
            throw new Error('Required parameter groupsDeleteCompanyByIdRequestBody was null or undefined when calling deleteCompanyById.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups/Pbx.DeleteCompanyById`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: groupsDeleteCompanyByIdRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public deleteCompanyByIdQuery(requestParameters: DeleteCompanyByIdRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteCompanyByIdQuery(requestParameters: DeleteCompanyByIdRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteCompanyByIdQuery(requestParameters: DeleteCompanyByIdRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteCompanyByIdQuery(requestParameters: DeleteCompanyByIdRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const groupsDeleteCompanyByIdRequestBody = requestParameters.groupsDeleteCompanyByIdRequestBody;
        if (groupsDeleteCompanyByIdRequestBody === null || groupsDeleteCompanyByIdRequestBody === undefined) {
        throw new Error('Required parameter groupsDeleteCompanyByIdRequestBody was null or undefined when calling deleteCompanyById.');
        }

    return `/Groups/Pbx.DeleteCompanyById`;
}

    /**
     * Invoke action DeleteCompanyByNumber
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteCompanyByNumber(requestParameters: DeleteCompanyByNumberRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteCompanyByNumber(requestParameters: DeleteCompanyByNumberRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteCompanyByNumber(requestParameters: DeleteCompanyByNumberRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteCompanyByNumber(requestParameters: DeleteCompanyByNumberRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const groupsDeleteCompanyByNumberRequestBody = requestParameters.groupsDeleteCompanyByNumberRequestBody;
        if (groupsDeleteCompanyByNumberRequestBody === null || groupsDeleteCompanyByNumberRequestBody === undefined) {
            throw new Error('Required parameter groupsDeleteCompanyByNumberRequestBody was null or undefined when calling deleteCompanyByNumber.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups/Pbx.DeleteCompanyByNumber`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: groupsDeleteCompanyByNumberRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public deleteCompanyByNumberQuery(requestParameters: DeleteCompanyByNumberRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteCompanyByNumberQuery(requestParameters: DeleteCompanyByNumberRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteCompanyByNumberQuery(requestParameters: DeleteCompanyByNumberRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteCompanyByNumberQuery(requestParameters: DeleteCompanyByNumberRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const groupsDeleteCompanyByNumberRequestBody = requestParameters.groupsDeleteCompanyByNumberRequestBody;
        if (groupsDeleteCompanyByNumberRequestBody === null || groupsDeleteCompanyByNumberRequestBody === undefined) {
        throw new Error('Required parameter groupsDeleteCompanyByNumberRequestBody was null or undefined when calling deleteCompanyByNumber.');
        }

    return `/Groups/Pbx.DeleteCompanyByNumber`;
}

    /**
     * Delete entity from Groups
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteGroup(requestParameters: DeleteGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteGroup(requestParameters: DeleteGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteGroup(requestParameters: DeleteGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteGroup(requestParameters: DeleteGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteGroup.');
        }
        const ifMatch = requestParameters.ifMatch;

        let localVarHeaders = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            localVarHeaders = localVarHeaders.set('If-Match', String(ifMatch));
        }

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public deleteGroupQuery(requestParameters: DeleteGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteGroupQuery(requestParameters: DeleteGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteGroupQuery(requestParameters: DeleteGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteGroupQuery(requestParameters: DeleteGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling deleteGroup.');
        }
            const ifMatch = requestParameters.ifMatch;

    return `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
}

    /**
     * Get entity from Groups by key
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getGroup(requestParameters: GetGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxGroup>;
    public getGroup(requestParameters: GetGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxGroup>>;
    public getGroup(requestParameters: GetGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxGroup>>;
    public getGroup(requestParameters: GetGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getGroup.');
        }
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
        return this.httpClient.request<PbxGroup>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getGroupQuery(requestParameters: GetGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getGroupQuery(requestParameters: GetGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getGroupQuery(requestParameters: GetGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getGroupQuery(requestParameters: GetGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling getGroup.');
        }
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke function GetRestrictions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getRestrictions(requestParameters: GetRestrictionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxRestrictions>;
    public getRestrictions(requestParameters: GetRestrictionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxRestrictions>>;
    public getRestrictions(requestParameters: GetRestrictionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxRestrictions>>;
    public getRestrictions(requestParameters: GetRestrictionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getRestrictions.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.GetRestrictions()`;
        return this.httpClient.request<PbxRestrictions>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getRestrictionsQuery(requestParameters: GetRestrictionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getRestrictionsQuery(requestParameters: GetRestrictionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getRestrictionsQuery(requestParameters: GetRestrictionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getRestrictionsQuery(requestParameters: GetRestrictionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling getRestrictions.');
        }

    return `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.GetRestrictions()`;
}

    /**
     * Invoke action LinkGroupPartner
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public linkGroupPartner(requestParameters: LinkGroupPartnerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public linkGroupPartner(requestParameters: LinkGroupPartnerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public linkGroupPartner(requestParameters: LinkGroupPartnerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public linkGroupPartner(requestParameters: LinkGroupPartnerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const groupsLinkGroupPartnerRequestBody = requestParameters.groupsLinkGroupPartnerRequestBody;
        if (groupsLinkGroupPartnerRequestBody === null || groupsLinkGroupPartnerRequestBody === undefined) {
            throw new Error('Required parameter groupsLinkGroupPartnerRequestBody was null or undefined when calling linkGroupPartner.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups/Pbx.LinkGroupPartner`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: groupsLinkGroupPartnerRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public linkGroupPartnerQuery(requestParameters: LinkGroupPartnerRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public linkGroupPartnerQuery(requestParameters: LinkGroupPartnerRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public linkGroupPartnerQuery(requestParameters: LinkGroupPartnerRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public linkGroupPartnerQuery(requestParameters: LinkGroupPartnerRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const groupsLinkGroupPartnerRequestBody = requestParameters.groupsLinkGroupPartnerRequestBody;
        if (groupsLinkGroupPartnerRequestBody === null || groupsLinkGroupPartnerRequestBody === undefined) {
        throw new Error('Required parameter groupsLinkGroupPartnerRequestBody was null or undefined when calling linkGroupPartner.');
        }

    return `/Groups/Pbx.LinkGroupPartner`;
}

    /**
     * Get entities from Groups
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listGroup(requestParameters: ListGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxGroupCollectionResponse>;
    public listGroup(requestParameters: ListGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxGroupCollectionResponse>>;
    public listGroup(requestParameters: ListGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxGroupCollectionResponse>>;
    public listGroup(requestParameters: ListGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups`;
        return this.httpClient.request<PbxGroupCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listGroupQuery(requestParameters: ListGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listGroupQuery(requestParameters: ListGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listGroupQuery(requestParameters: ListGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listGroupQuery(requestParameters: ListGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Groups`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Get Members from Groups
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMembers(requestParameters: ListMembersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxUserGroupCollectionResponse>;
    public listMembers(requestParameters: ListMembersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxUserGroupCollectionResponse>>;
    public listMembers(requestParameters: ListMembersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxUserGroupCollectionResponse>>;
    public listMembers(requestParameters: ListMembersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listMembers.');
        }
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Members`;
        return this.httpClient.request<PbxUserGroupCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listMembersQuery(requestParameters: ListMembersRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMembersQuery(requestParameters: ListMembersRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMembersQuery(requestParameters: ListMembersRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMembersQuery(requestParameters: ListMembersRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling listMembers.');
        }
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Members`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Get Rights from Groups
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listRights(requestParameters: ListRightsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxRightsCollectionResponse>;
    public listRights(requestParameters: ListRightsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxRightsCollectionResponse>>;
    public listRights(requestParameters: ListRightsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxRightsCollectionResponse>>;
    public listRights(requestParameters: ListRightsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listRights.');
        }
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Rights`;
        return this.httpClient.request<PbxRightsCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listRightsQuery(requestParameters: ListRightsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listRightsQuery(requestParameters: ListRightsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listRightsQuery(requestParameters: ListRightsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listRightsQuery(requestParameters: ListRightsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling listRights.');
        }
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Rights`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke action ReplaceGroupLicenseKey
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public replaceGroupLicenseKey(requestParameters: ReplaceGroupLicenseKeyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public replaceGroupLicenseKey(requestParameters: ReplaceGroupLicenseKeyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public replaceGroupLicenseKey(requestParameters: ReplaceGroupLicenseKeyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public replaceGroupLicenseKey(requestParameters: ReplaceGroupLicenseKeyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const groupsReplaceGroupLicenseKeyRequestBody = requestParameters.groupsReplaceGroupLicenseKeyRequestBody;
        if (groupsReplaceGroupLicenseKeyRequestBody === null || groupsReplaceGroupLicenseKeyRequestBody === undefined) {
            throw new Error('Required parameter groupsReplaceGroupLicenseKeyRequestBody was null or undefined when calling replaceGroupLicenseKey.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups/Pbx.ReplaceGroupLicenseKey`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: groupsReplaceGroupLicenseKeyRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public replaceGroupLicenseKeyQuery(requestParameters: ReplaceGroupLicenseKeyRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public replaceGroupLicenseKeyQuery(requestParameters: ReplaceGroupLicenseKeyRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public replaceGroupLicenseKeyQuery(requestParameters: ReplaceGroupLicenseKeyRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public replaceGroupLicenseKeyQuery(requestParameters: ReplaceGroupLicenseKeyRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const groupsReplaceGroupLicenseKeyRequestBody = requestParameters.groupsReplaceGroupLicenseKeyRequestBody;
        if (groupsReplaceGroupLicenseKeyRequestBody === null || groupsReplaceGroupLicenseKeyRequestBody === undefined) {
        throw new Error('Required parameter groupsReplaceGroupLicenseKeyRequestBody was null or undefined when calling replaceGroupLicenseKey.');
        }

    return `/Groups/Pbx.ReplaceGroupLicenseKey`;
}

    /**
     * Invoke action UnlinkGroupPartner
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public unlinkGroupPartner(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public unlinkGroupPartner(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public unlinkGroupPartner(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public unlinkGroupPartner(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups/Pbx.UnlinkGroupPartner`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public unlinkGroupPartnerQuery(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public unlinkGroupPartnerQuery(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public unlinkGroupPartnerQuery(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public unlinkGroupPartnerQuery(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {

    return `/Groups/Pbx.UnlinkGroupPartner`;
}

    /**
     * Update entity in Groups
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateGroup(requestParameters: UpdateGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateGroup(requestParameters: UpdateGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateGroup(requestParameters: UpdateGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateGroup(requestParameters: UpdateGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateGroup.');
        }
        const pbxGroup = requestParameters.pbxGroup;
        if (pbxGroup === null || pbxGroup === undefined) {
            throw new Error('Required parameter pbxGroup was null or undefined when calling updateGroup.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pbxGroup,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public updateGroupQuery(requestParameters: UpdateGroupRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateGroupQuery(requestParameters: UpdateGroupRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateGroupQuery(requestParameters: UpdateGroupRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateGroupQuery(requestParameters: UpdateGroupRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling updateGroup.');
        }
            const pbxGroup = requestParameters.pbxGroup;
        if (pbxGroup === null || pbxGroup === undefined) {
        throw new Error('Required parameter pbxGroup was null or undefined when calling updateGroup.');
        }

    return `/Groups(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
}

}
