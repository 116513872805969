        <div class="toast-content mb-2">
            <app-avatar [ext]="message?.participant?.contact"></app-avatar>

            <div class='sender d-flex align-items-center gap-2'>
                <provider-image [participant]="message?.participant"></provider-image>
                    <a *ngIf="chatContact && chatContact.isPhonebookContact(); else noPhonebookContact"
                        class="action-link"
                        data-qa="contact-link"
                       (click)="viewContact(chatContact); $event.stopPropagation();"
                        [truncatedText]="chatContact | lforfl | async">
                    </a>
                <ng-template #noPhonebookContact>
                    <span data-qa="sender" class="text-truncate">{{message.sender}}</span>
                </ng-template>
            </div>

            <div class="crm-links d-flex align-items-center gap-1">
                <a *ngIf="(hasClientCrmIntegration$|async) && canOpenClientCrmUrl"
                   data-qa="crm-lookup-option"
                   target="_blank" (click)='openClientCrmUrl(); $event.stopPropagation();'
                   title="{{'_i18n.CRMLookUpOption'|translate}}"
                   class="action-link">
                    <span class="fillIconWhite svg-sm" app-user-magnifying-glass-solid-icon></span>
                </a>
                <a *ngIf="chatContact?.crmUrl"
                   target="_blank" [href]="chatContact?.crmUrl"
                   (click)='$event.stopPropagation();'
                   title="{{'_i18n.OpenContact'|translate}}"
                   class="action-link text-underline" data-qa="crm-link">
                    CRM
                </a>
            </div>

            <span data-qa="message-text" class="block-with-text" [innerHTML]="message.text"></span>

            <a *ngIf="canBeAdded" (click)="addContact(); $event.stopPropagation();"
               class="add-link d-flex my-1 gap-1 align-items-center text-truncate"
               data-qa="add-contact-link">
                <span class="svg-xs" app-plus-solid-icon></span>
                <span class="text-truncate">{{'_i18n.MenuAddContact' | translate}}</span>
            </a>
        </div>
        <div class="d-flex gap-1">
            <button class="btn btn-primary flex-grow-1" (click)="reply($event)" type="button" data-qa="reply-in-chat">
                {{ '_i18n.Reply' | translate}}
            </button>
            <button class="btn btn-gray flex-grow-1" (click)="ignore($event)" type="button" data-qa="ignore-chat">
                {{ '_i18n.Ignore' | translate}}
            </button>
        </div>
