import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, EventEmitter,
    HostBinding,
    HostListener, Output,
    ViewEncapsulation
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FieldValueAccessor } from '@webclient/fields';

/** Checkbox without label and validation, with extended click area */
@Component({
    selector: 'app-checker',
    styleUrls: ['./checker.component.scss'],
    encapsulation: ViewEncapsulation.None,
    template: `
        <input class="form-check-input" type="checkbox" data-qa="checker"
               [disabled]="disabled" [ngModel]="value" (blur)="onBlur($event)" (click)="onClick($event)"/>
    `,
    providers: [{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CheckerComponent }],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule]
})
export class CheckerComponent extends FieldValueAccessor<boolean> {
    @HostBinding('class.form-check')
    containerClassFormCheck = true;

    /** Notify about click in a custom way to avoid native click handlers on parent containers */
    @Output() checkerClick = new EventEmitter<{checked: boolean, event: MouseEvent}>();

    @HostBinding('class.disabled')
    get containerClassDisabled() {
        return this.disabled;
    }

    // onClick also added to input, because container does not catch clicks with shift on input
    @HostListener('click', ['$event'])
    onClick($event: MouseEvent) {
        if (!this.disabled) {
            this.valueChanged(!this.value);
            $event.stopPropagation();
            this.checkerClick.emit({ checked: this.value, event: $event });
        }
    }

    constructor(cdr: ChangeDetectorRef) {
        super(cdr);
    }
}
