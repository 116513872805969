import { RequestAttTransfer } from '@webclient/phone/phone.interface';
import { MyCall } from './mycall';
import { List, Record } from 'immutable';

export class MyCallsInternal extends Record({
    hasNewCalls: false,
    requestAttTransfer: <RequestAttTransfer|undefined>undefined,
    calls: List<MyCall>()
}) {
}
