import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ValdemortModule } from 'ngx-valdemort';
import { NgSelectModule } from '@ng-select/ng-select';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { FieldCheckboxComponent } from './field-checkbox/field-checkbox.component';
import { FieldCheckboxGroupComponent } from './field-checkbox-group/field-checkbox-group.component';
import { FieldRadioGroupComponent } from './field-radio-group/field-radio-group.component';
import { FieldSwitchComponent } from './field-switch/field-switch.component';
import { FieldInputComponent } from './field-input/field-input.component';
import { FieldInputPasswordComponent } from './field-input-password/field-input-password.component';
import { FieldInputNumberComponent } from './field-input-number/field-input-number.component';
import { FieldInputPhoneComponent } from './field-input-phone/field-input-phone.component';
import { FieldWrapperComponent } from './field-wrapper/field-wrapper.component';
import { FieldHintComponent } from './field-hint/field-hint.component';
import { FieldInputListComponent } from './field-input-list/field-input-list.component';
import { FieldSelectComponent } from './field-select/field-select.component';
import { FieldInputFileComponent } from './field-input-file/field-input-file.component';

@NgModule({
    imports: [
        ReactiveFormsModule,
        TranslateModule,
        FormsModule,
        CommonModule,
        IconsModule,
        TooltipModule,
        NgSelectModule,
        ValdemortModule,
    ],
    declarations: [
        FieldCheckboxComponent,
        FieldCheckboxGroupComponent,
        FieldRadioGroupComponent,
        FieldSwitchComponent,
        FieldInputComponent,
        FieldInputPasswordComponent,
        FieldInputNumberComponent,
        FieldInputPhoneComponent,
        FieldWrapperComponent,
        FieldHintComponent,
        FieldInputListComponent,
        FieldSelectComponent,
        FieldInputFileComponent,
    ],
    exports: [
        FieldCheckboxComponent,
        FieldCheckboxGroupComponent,
        FieldRadioGroupComponent,
        FieldSwitchComponent,
        FieldInputComponent,
        FieldInputPasswordComponent,
        FieldInputNumberComponent,
        FieldInputPhoneComponent,
        FieldWrapperComponent,
        FieldHintComponent,
        FieldInputListComponent,
        FieldSelectComponent,
        FieldInputFileComponent,
    ]
})
export class FieldsModule {}
