import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostBinding, Input, ViewChild
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { getUniqueFieldId } from '../fields.utils';
// noinspection ES6PreferShortImport
import { FieldValueAccessor } from '../field-value-accessor';
import { FOCUSABLE, Focusable } from '@webclient/standalones/directives';

@Component({
    selector: 'field-checkbox',
    styleUrls: ['./field-checkbox.component.scss'],
    template: `
        <div class="d-flex flex-row" [class.gap-2]="label">
            <input class="form-check-input checkbox-input" type="checkbox" [id]="labelToInputLinkId" data-qa="input"
                   [disabled]="disabled" [ngModel]="value" (ngModelChange)="valueChanged($event)"
                   [indeterminate]="indeterminate"
                   (blur)="onBlur($event)" (click)="$event.stopPropagation()" #checkbox/>
            <div *ngIf="label" [tooltip]="tooltipText | translate">
                <label
                    class="form-check-label d-flex gap-2"
                    [for]="labelToInputLinkId"
                    data-qa="label"
                    (click)="$event.stopPropagation()">
                    <span>{{label | translate}} <ng-content select="[label]"></ng-content></span>
                    <field-hint [text]="hint"></field-hint>
                </label>
                <div *ngIf="description" class="form-text d-flex align-items-center gap-1" data-qa="description">
                    <span *ngIf="description !== 'true'">{{description | translate}}</span>
                    <ng-content select="[description]"></ng-content>
                </div>
            </div>
        </div>
        <div class="form-control-validation" data-qa="validation">
            <ng-content select="val-errors"></ng-content>
        </div>
    `,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: FieldCheckboxComponent },
        { provide: FOCUSABLE, useExisting: FieldCheckboxComponent }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldCheckboxComponent extends FieldValueAccessor<boolean | null> implements Focusable {
    readonly labelToInputLinkId: string = getUniqueFieldId('checkbox');

    @Input() label: string;
    @Input() description?: string;
    @Input() hint?: string;

    @Input() tooltipWhenDisabled?: string;
    @Input() tooltipWhenEnabled?: string;

    /** Show null value as indeterminate checkbox state */
    @Input() nullIsIndeterminate = false;

    @ViewChild('checkbox', { static: true }) checkbox: ElementRef<HTMLInputElement>;

    @HostBinding('class.form-check')
    get containerClassFormCheck() {
        return true;
    }

    @HostBinding('class.disabled')
    get containerClassDisabled() {
        return this.disabled;
    }

    get indeterminate() {
        return this.nullIsIndeterminate && this.value == null && !this.changed;
    }

    get tooltipText(): string {
        return (this.disabled ? this.tooltipWhenDisabled : this.tooltipWhenEnabled) ?? '';
    }

    constructor(cdr: ChangeDetectorRef) {
        super(cdr);
    }

    focus(): void {
        this.checkbox.nativeElement.focus();
    }
}
