import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppContact } from '@webclient/myphone/contact';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from '@webclient/standalones/avatar/avatar.component';
import { FormsModule } from '@angular/forms';
import { CheckerComponent } from '@office/standalones/checker';

@Component({
    selector: 'app-checked-avatar',
    template: `
        <div class="checkbox" [ngClass]="{ view: isSelected }">
            <app-checker [ngModel]="isSelected" (ngModelChange)="onSelectedChange($event)" (click)="$event.stopPropagation();">
            </app-checker>
        </div>
        <app-avatar [ext]="contact" (click)="onSelectedChange(true, $event)" [ngClass]="{ view: !isSelected }"></app-avatar>
    `,
    styleUrls: ['./checked-avatar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, FormsModule, AvatarComponent, CheckerComponent]
})
export class CheckedAvatarComponent {
    @Input()
    contact: AppContact;

    @Input()
    isSelected: boolean;

    @Output()
    isSelectedChange = new EventEmitter<boolean>();

    onSelectedChange(value: boolean, event?: Event) {
        event?.preventDefault();
        event?.stopPropagation();
        this.isSelected = value;
        this.isSelectedChange.emit(value);
    }
}
