import {
    ErrorHandler, Inject, Injectable, isDevMode
} from '@angular/core';
import { BASE_PATH } from '@api';
import { Configuration, TokenApi } from '../generated/fetch';

export function reload(timeout: number) {
    setTimeout(() => {
        if (document.location) {
            document.location.href = window.location.pathname;
        }
    }, timeout);
}

@Injectable()
export class MyErrorHandler implements ErrorHandler {
    private readonly originalHandler = new ErrorHandler();

    public constructor(@Inject(BASE_PATH) private basePath: string) {
    }

    handleError(error: any) {
        if (localStorage.getItem('wc.keeplogs') || isDevMode()) {
            console.error('CRITICAL ERROR HANDLER TRIGGERED: PAGE WILL RELOAD ON PROD', error);
            this.originalHandler.handleError(error);
        }
        else if (fetch && this.basePath) {
            const stack = error.stack ?? error.message ?? 'Error message is not set';
            const tokenApi = new TokenApi(new Configuration({
                basePath: this.basePath
            }));
            tokenApi.tokenToken({ grantType: 'refresh_token' }).then(
                (response => {
                    return fetch(new URL('/webclient/api/MyPhone/log', this.basePath).href, {
                        method: 'POST',
                        headers: new Headers({
                            Accept: 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + response.access_token,
                        }),
                        body: JSON.stringify({
                            message: 'Webclient error',
                            stack: stack.substring(0, 2048)
                        }),
                        credentials: 'include'
                    });
                })
            ).finally(() => this.reload());
        }
        else {
            this.reload();
        }
    }

    reload() {
        reload(5000);
    }
}
