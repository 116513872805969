import { Directive, ElementRef, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
    standalone: true,
    selector: '[truncatedText]',
})
export class TruncatedTextDirective implements OnChanges {
    @Input() truncatedText: string | null;

    @HostBinding('class.text-truncate') readonly elementsClass = true;

    constructor(private el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.truncatedText) {
            const text = changes.truncatedText.currentValue || '';

            this.el.nativeElement.setAttribute('title', text);
            this.el.nativeElement.innerText = text;
        }
    }
}
