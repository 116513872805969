import { ContactsMap } from './bridge';
import { AppContact } from './contact';
import { sanitizePhoneNumber } from '../phone/phone-funcs';
import { String } from '../shared/string.utils';
import { ResponseSystemParameters } from '@myphone';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

export interface IContactDb {
    findPhone(phoneNumber: string, hintBridgeId?: string, includeHiddenContacts?: boolean): AppContact|undefined;
    findByEmail(email: string): AppContact|undefined;
}

export class ContactDb implements IContactDb {
    readonly contactDb: ContactsMap = {};
    readonly contactDbById: ContactsMap = {};

    private doNotMatch: boolean;
    private matchExactly: boolean;
    private matchAtLeast: boolean;
    private digitCountMatch: number;

    private setDefaultMatchOptions() {
        this.doNotMatch = false;
        this.matchExactly = false;
        this.matchAtLeast = false;
        this.digitCountMatch = 6;
    }

    public constructor(systemParameters$?: Observable<ResponseSystemParameters>) {
        this.setDefaultMatchOptions();

        if (systemParameters$ === undefined) {
            return;
        } // TODO: check when that is possible?

        systemParameters$.pipe(take(1)).subscribe(parameters => {
            this.setDefaultMatchOptions();

            if (parameters.PhonebookMinMatch === -1) {
                this.matchExactly = true;
            }
            else if (parameters.PhonebookMinMatch === 0) {
                this.doNotMatch = true;
            }
            else {
                this.matchAtLeast = true;
                this.digitCountMatch = parameters.PhonebookMinMatch;
            }
        });
    }

    private prepareNumber(phoneNumber: string) {
        let sanitized = sanitizePhoneNumber(phoneNumber);

        const length = sanitized.length;
        if ((this.matchAtLeast || this.doNotMatch) && (length > this.digitCountMatch)) {
            sanitized = sanitized.substring(length - this.digitCountMatch);
        }

        return String.reverse(sanitized);
    }

    public addPhone(phoneNumber: string, contact: AppContact) {
        this.contactDbById[contact.id] = contact;
        phoneNumber = this.prepareNumber(phoneNumber);
        if (phoneNumber) {
            this.contactDb[phoneNumber] = contact;
        }
    }

    // TODO: here should be one improvement -> match by the rest of the numbers (store set of numbers)
    public findPhone(phoneNumber: string, hintBridgeId?: string, includeHiddenContacts?: boolean): AppContact|undefined {
        phoneNumber = this.prepareNumber(phoneNumber);
        if (phoneNumber) {
            const contact = this.contactDb[phoneNumber];
            return (contact && (includeHiddenContacts || !contact.hideInPhonebook)) ? contact : undefined;
        }
        else {
            return undefined;
        }
    }

    public findById(id: string) {
        return this.contactDbById[id];
    }

    public removeIfSelf(phoneNumber: string, contact : AppContact) : boolean {
        phoneNumber = this.prepareNumber(phoneNumber);
        if (!!phoneNumber && this.contactDb[phoneNumber] === contact) {
            delete this.contactDb[phoneNumber];
            return true;
        }
        else {
            return false;
        }
    }

    // Be aware O^2, and also we searching only in contacts with number, If contact without number but with email will be created(Office 365 or similar),
    // that can cause a problem
    // TODO: Remove this - create email DB
    public findByEmail(email: string) {
        if (!email) {
            return undefined;
        }
        const all = Object.values(this.contactDb);
        return all.find(x => x.emailAddress === email);
    }
}
