export const observersRole = 'observers';

export type RoleId = 'system' | 'users' | 'receptionists' | 'managers' |'group_admins' | 'group_owners' | 'system_admins' | 'system_owners' | 'observers';

export interface Role {
    readonly Id: RoleId;
    readonly Name: string;
    readonly CanControl: RoleId[];
}

export const UsersRole: Role = {
    Id: 'users',
    Name: '_i18n.User',
    CanControl: []
};

export const CanDoAnything: RoleId[] = ['system_owners', 'system_admins'];

export const CanAdminAccess: RoleId[] = ['managers', 'group_admins', 'group_owners', ...CanDoAnything];

export const CanManageGroups: RoleId[] = ['receptionists', 'managers', 'group_admins', 'group_owners', ...CanDoAnything];

export const CanCallHandling: RoleId[] = ['group_admins', 'group_owners', ...CanDoAnything];

export const CanVoiceAndChat: RoleId[] = CanCallHandling;

export const CanReportsAndRecordings: RoleId[] = ['group_owners', 'managers', 'system_owners'];

export const CanLicenseInformation: RoleId[] = ['system_owners', 'system_admins'];

export const CanEventLog: RoleId[] = ['system_owners', 'system_admins', 'group_admins', 'group_owners', 'managers'];

export const CanMCMLicenseInformation: RoleId[] = ['group_admins', 'group_owners'];

export const Roles: Role[] = [
    UsersRole,
    {
        Id: 'system',
        Name: '_i18n.System',
        CanControl: []
    },
    {
        Id: observersRole,
        Name: '_i18n.Observer',
        CanControl: []
    },
    {
        Id: 'receptionists',
        Name: '_i18n.Receptionist',
        CanControl: []
    }, {
        Id: 'group_admins',
        Name: '_i18n.GroupAdmins',
        CanControl: ['users', 'receptionists', observersRole]
    }, {
        Id: 'managers',
        Name: '_i18n.Manager',
        CanControl: ['users', 'receptionists', 'group_admins', observersRole]
    }, {
        Id: 'group_owners',
        Name: '_i18n.Owners',
        CanControl: ['group_owners', 'users', 'receptionists', 'managers', 'group_admins', observersRole]
    }, {
        Id: 'system_admins',
        Name: '_i18n.SysAdmins',
        CanControl: ['users', 'receptionists', 'group_admins', observersRole]
    }, {
        Id: 'system_owners',
        Name: '_i18n.SysOwners',
        CanControl: ['group_owners', 'users', 'receptionists', 'managers', 'group_admins', 'system_admins', 'system_owners', observersRole]
    }];

export function getRoleTranslation(roleId: RoleId | null | undefined) {
    return roleId ? Roles.find(role => role.Id === roleId)?.Name ?? '' : '';
}

export function maxRole(roles: RoleId[]): Role {
    return roles.filter(role => role !== observersRole).reduce((prev, cur) => {
        const leftRole = Roles.indexOf(prev);
        const rightRole = Roles.findIndex(role => role.Id === cur);
        if (rightRole === -1) {
            // Some not found role
            return Roles[leftRole];
        }

        return (leftRole - rightRole > 0) ? Roles[leftRole] : Roles[rightRole];
    },
    UsersRole);
}
