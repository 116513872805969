import { MyphoneMessageEncoder } from '@webclient/myphone/myphone-message-encoder';
import { LoginData } from '../../generated/fetch';
import { concat, EMPTY, merge as observableMerge, Observable, of } from 'rxjs';
import {
    DnType,
    RequestAvailableProviders,
    RequestGetSystemParameters,
    RequestLookupContact,
    RequestMyInfo,
    RequestRegisterWebRTCEndpoint,
    RequestServerTime,
    ResponseAcknowledge
} from '@myphone';
import { catchError, concatMap, filter, skipWhile, timeout } from 'rxjs/operators';
import {
    createNotificationChannelFromWebsocket
} from '@webclient/myphone/create-webscoket-notification-chanel.func';
import { bufferTimeOptimized } from '@webclient/myphone/buffer-time-optimized';
import { GenericMessageType } from '@webclient/shared/myphone-types';

export const myPhoneMessageBufferTimeMs = 100;
const addpDefaultTimeout = 1000;

export type SessionGet = {
    get<T extends GenericMessageType>(request: GenericMessageType): Observable<T>
}

export function establishNotificationChanel(_encoder: MyphoneMessageEncoder, sessionParams: LoginData, factory: (url:string) => WebSocket, basePath: string) {
    const addpTimeout: number = sessionParams.addpTimeout || addpDefaultTimeout;
    return createNotificationChannelFromWebsocket(sessionParams, factory, new URL(basePath)).pipe(
        timeout(addpTimeout * 2),
        // Skip ADDP from this point onwards
        filter(x => x !== 'ADDP'),
        skipWhile(x => x !== 'START'),
        filter(x => x !== 'START'),
        concatMap((x) => {
            // Wrap all other data to fake observables and decode them
            const message = _encoder.decode(x);
            // In case unknown notification received
            return message ? of(message) : EMPTY;
        }),
        bufferTimeOptimized(myPhoneMessageBufferTimeMs),
        filter(buffer => buffer.length > 0)
    );
}

export function bootstrapNotificationChanel(_encoder: MyphoneMessageEncoder, sessionParams: LoginData, session: SessionGet, factory: (url:string) => WebSocket, basePath: string): Observable<any> {
    const addpTimeout: number = sessionParams.addpTimeout || addpDefaultTimeout;
    return createNotificationChannelFromWebsocket(sessionParams, factory, new URL(basePath)).pipe(
        timeout(addpTimeout * 2),
        // Skip ADDP from this point onwards
        filter(x => x !== 'ADDP'),
        skipWhile(x => x !== 'START'),
        // On start issue RequestMyInfo
        concatMap(x => {
            // Extend MyPhoneNotificationMessage with additional params
            if (x === 'START') {
                return getSessionStartupParams(session);
            }
            else {
                // Wrap all other data to fake observables and decode them
                const message = _encoder.decode(x);
                // In case unknown notification received
                return message ? of(message) : EMPTY;
            }
        }),
        bufferTimeOptimized(myPhoneMessageBufferTimeMs),
        filter(buffer => buffer.length > 0),
    );
}

// Get additional session params on startup
export function getSessionStartupParams(session: SessionGet): Observable<any> {
    const sessionBootstrap = [
        // No need to create a fake subscription for now as WebRTC registration is still in place
        // Create a fake subscription
        // session.get(new PushSubscriptionData({
        //     DeliveryType: 'F',
        //     ModelName: browserOnOS,
        // })),
        session.get(new RequestMyInfo()),
        session.get(new RequestRegisterWebRTCEndpoint({
            register: true,
            NeedsRegistration: false,
        })).pipe(
            catchError(() => EMPTY)
        ),
        session.get(new RequestServerTime()),
        session.get(new RequestAvailableProviders()).pipe(
            catchError(() => EMPTY)
        ),
        session.get(new RequestLookupContact({
            ExtIncluded: false,
            SearchBridge: false,
            SearchCompany: false,
            SearchPersonal: false,
            SysExtsMask: DnType.Conference | DnType.SpecialMenu,
            Count: 2,
            Offset: 0
        }))
    ];

    // System parameters go first
    return concat(session.get(new RequestGetSystemParameters()),
        // Order doesn't matter
        observableMerge(...sessionBootstrap).pipe(filter(x => !(x instanceof ResponseAcknowledge)))
    );
}
