import { ChangeDetectorRef, Injectable } from '@angular/core';
import { extractErrorMessage, ModalService } from '@webclient/modal/app-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import camelCase from 'camelcase';
import { take } from 'rxjs/operators';
import { PbxODataErrorsODataError, PbxWarnings } from '@xapi';

function findControl(group: FormGroup, path: string[], camelize: boolean | null): AbstractControl | null {
    if (typeof camelize === 'boolean') {
        return findControlCaseMatching(group, path, camelize);
    }
    return findControlCaseMatching(group, path, true) ?? findControlCaseMatching(group, path, false);
}

function findControlCaseMatching(group: FormGroup, path: string[], camelize: boolean): AbstractControl | null {
    if (path.length === 0) {
        return null;
    }
    const path0 = camelize ? camelCase(path[0]) : path[0];
    const matches = path0.match(/(.+)\[(.+)]/);
    if (matches && matches.length === 3) {
        // We matched index
        const control = group.get(matches[1]);
        if (control instanceof FormArray) {
            // We found a control so ignore the rest path
            const groupedControl = control.controls[+matches[2]];
            // Can happen if array control doesn't have corresponding items
            return groupedControl instanceof FormGroup ? (findControl(groupedControl, path.slice(1), camelize) ?? groupedControl) : null;
        }
    }
    else {
        // No index found
        const control = group.get(path0);
        if (control instanceof FormControl) {
            // We found a control so ignore the rest path
            return control;
        }
        else if (control instanceof FormGroup) {
            // We found a group
            return findControl(control, path.slice(1), camelize) ?? control;
        }
    }
    return null;
}

/** Some xapi errors match simple field validators */
const XAPI_ERROR_TO_VALIDATOR_KEY: Partial<Record<PbxWarnings, string>> = {
    'WARNINGS.XAPI.REQUIRED': 'required',
    'WARNINGS.XAPI.INVALID_PASSWORD': 'password',
    'WARNINGS.XAPI.ALREADY_IN_USE': 'unique',
    'WARNINGS.XAPI.INVALID': 'invalid',
    'WARNINGS.XAPI.NOT_FOUND': 'notFound',
    'WARNINGS.XAPI.INVALID_CREDENTIALS': 'invalidCredentials',
    'WARNINGS.XAPI.DUPLICATE': 'duplicate',
};

export function addStickyValidator(control: AbstractControl, validationKey: string, err: string | boolean = true) {
    const curValue = control.value;
    const validationError: ValidationErrors = { [XAPI_ERROR_TO_VALIDATOR_KEY[String(err) as PbxWarnings] || validationKey]: err };

    // This validator complains until value changes
    const stickyValidator = ({ value }: AbstractControl) => (curValue === value ? validationError : null);

    control.addValidators(stickyValidator);
    control.updateValueAndValidity();
    control.markAsTouched();

    control.valueChanges.pipe(take(1)).subscribe(() => {
        // Remove it just in case
        control.removeValidators(stickyValidator);
    });
}

// second plain constant is only in default-validation-errors template
export const XAPI_ERROR_VALIDATOR = 'xapiError';

export interface XapiError {
    error: PbxODataErrorsODataError;
}

export function isXapiError(err: unknown): err is XapiError {
    return err != null && typeof err === 'object'
        && 'status' in err && err.status === 400
        && 'error' in err && typeof err.error === 'object';
}

@Injectable({
    providedIn: 'root'
})
export class XapiErrorService {
    constructor(private modalService: ModalService, private translate: TranslateService) { }

    error(cd: ChangeDetectorRef, err: unknown, form: FormGroup|FormGroup[], camelize: boolean | null = true, showModalForNonXapi = true) {
        if (isXapiError(err) && err.error?.error?.details?.length) {
            const errorsWithoutControls: { target?: string | null; errorMessage: string }[] = [];

            err.error.error.details.forEach(myErr => {
                if (myErr.target) {
                    const targetPath = myErr.target.split('.');
                    let control: AbstractControl|null;
                    if (Array.isArray(form)) {
                        control = form.map(frm => findControl(frm, targetPath, camelize)).find(Boolean) ?? null;
                    }
                    else {
                        control = findControl(form, targetPath, camelize);
                    }
                    if (control) {
                        // We found the designated control

                        addStickyValidator(control, XAPI_ERROR_VALIDATOR, myErr.message);
                        cd.markForCheck();
                        return;
                    }
                }
                errorsWithoutControls.push({
                    target: myErr.target,
                    errorMessage: extractErrorMessage(myErr.message) // convert to translation key
                });
            });
            if (!errorsWithoutControls.length) {
                return true;
            }
            if (!showModalForNonXapi) {
                // error is not handled by xapi service
                return false;
            }

            this.translate.get(errorsWithoutControls.map(err => err.errorMessage))
                .subscribe(translated => {
                    const error = errorsWithoutControls
                        .map(err => `${err.target}: ${translated[err.errorMessage] || err.errorMessage}`)
                        .join('\n');

                    this.modalService.error(error);
                });
            return true;
        }
        if (showModalForNonXapi) {
            this.modalService.error(err);
            return true;
        }
        // error is not handled by xapi service
        return false;
    }
}
