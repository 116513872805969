import { ActiveCallHandler } from './active-call-handler';
import {
    ActionType, DnType, LocalConnection, LocalConnections, LocalConnectionState
} from '@myphone';
import { BehaviorSubject, Subject } from 'rxjs';
import { JoinTool } from '../join/join';
import { ActiveCallInfo } from './active-call-info';

export type ConnectionMap = {[id: number]: LocalConnection};

export type ActiveCallMap = {[id: string]: ActiveCallInfo};

/**
 * This important function will convert local connection map to active calls map.
 * It will group polling calls by dn to show only one and ignore others.
 * Polling call id will be callId+dn. For example '123+000'
 * Queue calls will replace calls with the same callId. This will hide situation which we're not intrested in when
 * extension calls to the queue by itself.
 * @param {ActiveCallHandler} handler
 * @param {ConnectionMap} connMap
 * @returns {ActiveCallMap}
 */
export function connectionMapToActiveCallsMap(handler: ActiveCallHandler, connMap: ConnectionMap) : ActiveCallMap {
    return Object.values(connMap).reduce((acc: ActiveCallMap, lc) => {
        let queueName : string|undefined;
        let callId = '' + lc.CallId;
        const isQueueOriginated = lc.OriginatorType === DnType.Queue;
        const isQueuePollingCall = isQueueOriginated && lc.State === LocalConnectionState.Ringing;
        const isQueue = lc.OwnerType === DnType.Queue || isQueueOriginated;

        if (isQueueOriginated) {
            queueName = `${lc.OriginatorDn} ${lc.OriginatorName}`;
            if (isQueuePollingCall) {
                callId = `${lc.CallId}+${lc.OwnerDn}`;
            }
        }
        else if (isQueue) {
            queueName = `${lc.OwnerDn} ${lc.OwnerDisplayName}`;
        }

        const ac = acc[callId];
        if (!ac) {
            acc[callId] = handler.createActiveCall(callId, lc, isQueue, queueName);
        }
        else {
            handler.updateActiveCallConnection(ac, lc);
            if (isQueue) {
                ac.QueueName = queueName;
                ac.IsQueueCall = isQueue;
            }
        }
        return acc;
    }, {});
}

export function connectionMapToActiveCalls(handler: ActiveCallHandler, connMap: ConnectionMap) : ActiveCallInfo[] {
    return Object.values(connectionMapToActiveCallsMap(handler, connMap)).filter(call => call.CanShow);
}

export class CallDb {
    private _idToLocalConnection: ConnectionMap = {};

    // / <summary>
    // / Collection of view models
    // / </summary>
    public readonly activeCalls$: Subject<ConnectionMap> = new BehaviorSubject<ConnectionMap>({});

    constructor() {
    }

    public reset() {
        this._idToLocalConnection = {};
        this.next();
    }

    private next() {
        this.activeCalls$.next({ ...this._idToLocalConnection });
    }

    public processDeletedItems(ids: number[]) {
        ids.forEach(id => {
            delete this._idToLocalConnection[id];
        });
        if (ids.length > 0) {
            this.next();
        }
    }

    public processDeleted(id: number) {
        delete this._idToLocalConnection[id];
        this.next();
    }

    public updateConnections(cons: LocalConnections, calllist: number[]) {
        // SY - If this method cares about updates of IVRInfo.WaitingCalls and ParkInfo.WaitingCalls it should be ready to receive null (no updates)
        // nothing to update
        if (cons === undefined || cons.Items === undefined) {
            return;
        }

        cons.Items.forEach(lc => this.updateConnection(lc, calllist));
        if (cons.Items.length > 0) {
            this.next();
        }
    }

    private updateConnection(lc: LocalConnection, calllist: number[]) {
        if (lc.Action === ActionType.FullUpdate) {
            delete this._idToLocalConnection[lc.Id];
            this._idToLocalConnection[lc.Id] = lc;
            if (calllist !== undefined) {
                // Add if not exists
                const index = calllist.indexOf(lc.Id);
                if (index === -1) {
                    calllist.push(lc.Id);
                }
            }
        }
        else if (lc.Action === ActionType.Deleted) {
            delete this._idToLocalConnection[lc.Id];
            if (calllist !== undefined) {
                // Delete if exists
                const index = calllist.indexOf(lc.Id);
                if (index !== -1) {
                    calllist.splice(index, 1);
                }
            }
        }
        else if (lc.Action === ActionType.Updated) {
            const conn = this._idToLocalConnection[lc.Id];
            if (conn !== undefined) {
                JoinTool.Merge(conn, lc);
            }
        }
    }
}
