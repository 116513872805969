import {
    AfterViewInit, ChangeDetectorRef,
    Component, OnDestroy, ViewChild
} from '@angular/core';
import { DialerService } from './dialer.service';
import { CallViewComponent } from './call-view.component';
import { DeviceMediaService } from '../phone/device-media.service';
import {
    concat, Observable, of, Subscription, combineLatest, concatMap, EMPTY
} from 'rxjs';
import { MyCall } from '../phone/mycall';
import { DialerUtilityService } from './dialer-utility.service';
import {
    catchError, filter, map, switchMap, take, withLatestFrom,
    distinctUntilChanged
} from 'rxjs/operators';
import { HeadsetCallAction } from '../phone/headsets/headset-call-command';
import { HeadsetService } from '../phone/headsets/headset.service';
import { CallControlService } from './call-control.service';
import { WebRTCControlService } from '../webrtc/webrtc-control.service';
import { actionAnswer, actionDecline } from '../notifications/notification-service-interface';
import { NotificationService } from '../notifications/notification.service';
import {
    LocalConnectionState,
    WebRTCEndpointSDPState
} from '@myphone';
import alertingSound from '../../notifications/Alerting.mp3';
import tweetingSound from '../../notifications/Tweeting.mp3';
import ringtoneSound from '../../notifications/Ringtone.mp3';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { stripSpecialCharaceters } from '@webclient/shared/utils.service';
import { String } from '@webclient/shared/string.utils';
import {
    DeviceService, isWebRTCEndpoint
} from '@webclient/phone/device.service';
import { ActivatedRoute } from '@angular/router';
import { SilentModeService } from '@webclient/layout/silent-mode.service';
import { convertExtensionInfoToContact } from '@webclient/standalones/my-name';
import { CallsStateService, CallStateContainer } from '@webclient/phone/calls-state.service';
import { AppContact } from '@webclient/myphone/contact';
import { canSendDtmf, notEmpty, truthy } from '@webclient/phone/phone.helpers';
import { PhoneService } from '@webclient/phone/phone.service';

@Component({
    selector: 'app-call-control',
    styleUrls: ['call-control.component.scss'],
    templateUrl: 'call-control.component.html'
})
export class CallControlComponent implements AfterViewInit, OnDestroy {
    public isCallsBarVisible = true;

    readonly soundNotification$: Observable<{url: string, isRinging: boolean } | undefined>;
    readonly activeMediaStream$: Observable<MediaStream>;
    readonly activeCall$: Observable<MyCall | null>;
    private readonly subs = new Subscription();

    @ViewChild('callView', { static: false })
    callView: CallViewComponent;

    constructor(public dialerService: DialerService,
                public deviceMediaService: DeviceMediaService,
                public silentModeService: SilentModeService,
                private route: ActivatedRoute,
                private deviceService: DeviceService,
                private headsetService: HeadsetService,
                private callControlService: CallControlService,
                private webRtcCallService: WebRTCControlService,
                private phoneService: PhoneService,
                private myPhoneService: MyPhoneService,
                private notificationService: NotificationService,
                public utilityService: DialerUtilityService,
                private callStateService: CallsStateService,
                private cd: ChangeDetectorRef,
    ) {
        this.activeCall$ = dialerService.activeCall$;

        this.soundNotification$ = this.dialerService.calls$.pipe(
            map(myCalls => {
                const dialingCall = myCalls.find(call => call.isWebRTCCall && call.state === LocalConnectionState.Dialing &&
                    (call.media.lastWebRTCState.sdpType !== WebRTCEndpointSDPState.WRTCAnswerProvided &&
                        call.media.lastWebRTCState.sdpType !== WebRTCEndpointSDPState.WRTCConfirmed
                    ));

                const ringingCall = myCalls.find(call => call.state === LocalConnectionState.Ringing);

                if (dialingCall) {
                    return { url: alertingSound, isRinging: false };
                }
                else if (ringingCall && ringingCall.isPushCall) {
                    const hasEstablishedCall = myCalls.find(call => call.state === LocalConnectionState.Connected);
                    const hasMultipleCalls = myCalls.size > 1;
                    if (hasMultipleCalls && hasEstablishedCall) {
                        return { url: tweetingSound, isRinging: true };
                    }
                    else {
                        return { url: ringtoneSound, isRinging: true };
                    }
                }
                else {
                    return undefined;
                }
            })
        );
        this.activeMediaStream$ = dialerService.activeCall$.pipe(
            filter(truthy),
            switchMap((call: MyCall) => call.media.remoteStream$),
            filter(truthy)
        );
    }

    // calls bar is not visible when the incall keypad is visible
    InCallKeyPadVisibilityChange(inCallKeyPadVisibility: boolean) {
        this.isCallsBarVisible = !inCallKeyPadVisibility;
    }

    // calls bar is not visible when the incall keypad is visible
    InCallSearchVisibilityChange(inCallSearchVisibility: boolean) {
        this.isCallsBarVisible = !inCallSearchVisibility;
    }

    onCallActionFired() {
        setTimeout(() => {
            if (this.callView) {
                this.callView.onCallActionFired();
            }
        }, 350);
    }

    ngAfterViewInit(): void {
        this.subs.add(this.myPhoneService.myPhoneSession
            .pipe(switchMap(session => {
                return concat(of(session), session.isActive$.pipe(filter(active => !active), map(() => session)));
            }))
            .subscribe(() => {
                // Reset headset on disconnect or new session
                this.headsetService.reset();
            }));

        this.subs.add(this.headsetService.headsetEvents$.pipe(withLatestFrom(this.phoneService.myCalls$))
            .subscribe((values) => {
                const [command, calls] = values;
                // make call does not rely on existing calls
                if (command.Action === HeadsetCallAction.MakeCall) {
                    const phoneNumber = stripSpecialCharaceters(command.Value);
                    if (!String.isNullOrEmpty(phoneNumber)) {
                        this.phoneService.call(phoneNumber, false);
                    }
                }
                else {
                    let myCall : MyCall|undefined;
                    if (command.CallId !== -1) {
                        myCall = calls.calls.find(x => x.myCallId === command.CallId);
                    }
                    else {
                        myCall = calls.calls.find(x => x.media.isActive);
                        if (!myCall && !calls.calls.isEmpty()) {
                            myCall = calls.calls.get(0);
                        }
                    }
                    if (myCall) {
                        /**
                         * WebRtcId indicates that we already started process of answering, so we are not going to fire it twice
                         * This is the race condition because of ambiguous universal device implementation,
                         * it's sending unexpected extra answer command because of special conditions, not from explicit pushed button on device
                         */
                        if (command.Action === HeadsetCallAction.AcceptCall && !myCall.webRTCId) {
                            this.callControlService.answer(myCall, false);
                        }
                        else if (command.Action === HeadsetCallAction.TerminateCall || command.Action === HeadsetCallAction.RejectCall) {
                            this.callControlService.drop(myCall);
                        }
                        else if (command.Action === HeadsetCallAction.HoldCall) {
                            this.callControlService.hold(myCall);
                        }
                        else if (command.Action === HeadsetCallAction.ResumeCall) {
                            this.callControlService.resume(myCall);
                        }
                        else if (command.Action === HeadsetCallAction.ToggleMuteCall) {
                            this.callControlService.mute(myCall);
                        }
                        else if (command.Action === HeadsetCallAction.FlashCall) {
                            if (calls.calls.size === 1) {
                                // Only one call so let's play with it
                                if (myCall.isHold) {
                                    this.callControlService.resume(myCall);
                                }
                                else {
                                    this.callControlService.hold(myCall);
                                }
                            }
                            else {
                                const myIndex = calls.calls.indexOf(myCall);
                                const nextCallIndex = myIndex + 1;
                                const nextCall = calls.calls.get(nextCallIndex >= calls.calls.size ? 0 : nextCallIndex)!;
                                if (nextCall.state === LocalConnectionState.Ringing) {
                                    this.callControlService.answer(nextCall, false);
                                }
                                else {
                                    this.callControlService.resume(nextCall);
                                }
                            }
                        }
                        else if (command.Action === HeadsetCallAction.SendDtmf) {
                            if (canSendDtmf(myCall) &&
                                ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#'].indexOf(command.Value) !== -1
                            ) {
                                this.callControlService.sendDtmf(myCall, command.Value);
                            }
                        }
                    }
                }
            }));

        this.subs.add(
            this.deviceMediaService.mediaPeerConstraints$.pipe(
                concatMap(() => this.phoneService.myCalls$.pipe(
                    take(1),
                    switchMap(myCalls => {
                        const activeCall = myCalls.calls.find(call => call.media.isActive);

                        return activeCall ? this.webRtcCallService.reactivate(activeCall.media) : EMPTY;
                    }),
                    // Dont break pipeline on errors
                    catchError(() => EMPTY)
                ))
            ).subscribe(() => { }
            ));

        const connections$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.myInfo.Connections$),
            map(conn => (conn && conn.Items ? conn.Items : []))
        );

        const actionPipe = (action: string, isAnswer: boolean) => this.route.queryParams.pipe(
            map(params => <string|undefined>params[action]),
            distinctUntilChanged(),
            filter(notEmpty),
            switchMap(answer => combineLatest([
                connections$,
                this.phoneService.pushDeviceRegistration$,
                this.deviceService.selectedPhoneDevice$,
            ]).pipe(
                map(([connections, pushReg, device]) => {
                    return connections.find(conn => conn.CallId === +answer &&
                        (isWebRTCEndpoint(device) ?
                            conn.DeviceContact === pushReg?.Contact /** In case of webRtc, we are selecting connection from push (not webclient proxy) */
                            : conn.DeviceContact === device?.Contact /** In case of External Device, we are selecting connection from external device */
                        ));
                }),
                filter(notEmpty),
                take(1)
            ))
        ).subscribe(conn => {
            this.phoneService.requestMakePushWakeUpCall(conn, isAnswer);
        });

        this.subs.add(actionPipe('answer', true));
        this.subs.add(actionPipe('decline', false));

        this.subs.add(
            this.notificationService
                .notificationActions$.pipe(
                    withLatestFrom(this.phoneService.myCalls$))
                .subscribe(values => {
                    const [localConnectionAction, calls] = values;
                    const myCall = calls.calls.find(x => x.localConnectionId === localConnectionAction.localConnectionId || x.callId === localConnectionAction.callId);
                    if (myCall) {
                        if (localConnectionAction.action === actionDecline) {
                            this.callControlService.drop(myCall);
                        }
                        else if (localConnectionAction.action === actionAnswer) {
                            this.callControlService.answer(myCall, false);
                        }
                    }
                }),
        );

        // TAPI report call state
        this.subs.add(
            this.callStateService.callState$.pipe(
                switchMap(state => {
                    return this.myPhoneService.myPhoneSession.pipe(
                        map(session => convertExtensionInfoToContact(session.myInfo)),
                        map(contact => {
                            return [state, contact] as [CallStateContainer, AppContact];
                        })
                    );
                })
            ).subscribe({
                next: (callstate) => {
                    const [state, contact] = callstate;
                }
            })
        );
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
