export interface CrmHint {
    name: string,
    value: string,
    locale: string
}

export interface CrmVariableValue {
    variableId: string,
    variableValue: string
}

export interface CrmVariable {
    variableId: string,
    isEncode: number,
    hints: CrmHint[];
    variableValue: string
}

export interface CrmIntegration {
    name: string,
    phone_lookup_url: string,
    email_lookup_url: string,
    pbx_version: string,
    variables: CrmVariable[]
}

export interface CrmIntegrationData {
    data: CrmIntegration[];
}

export enum CrmUrlEncoding {
    Decoded = 0,
    Encoded = 1
}
