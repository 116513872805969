import { catchError, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { ContactViewModel } from './contact-view-model';
import { Injectable } from '@angular/core';
import { extractErrorMessage, ModalService } from '../modal/app-modal.service';
import { AppContact } from '../myphone/contact';
import { MyPhoneService } from '../myphone/myphone.service';
import {
    ActionType,
    Contact,
    ContactAddedByEnum,
    ContactType,
    RequestUpdateContact,
    ResponseAcknowledge,
    ResponseLookup
} from '@myphone';
import { AppContactType, phonebookBridgeId } from '../myphone/app-contact-type';
import { combineLatest, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { PhonebookEditorDialogComponent } from '@webclient/modal/wc-phonebook-editor/wc-phonebook-editor.component';
import { ModalResult } from '@webclient/modal/message-box';
import { ContactViewMode } from '@webclient/phonebook-editor/contact-view-mode';
import {
    ContactCreationErrorComponent
} from '@webclient/phonebook/contact-creation-error/contact-creation-error.component';
import {
    ContactCreationResult,
    ContactCreationStatus,
    ContactCreationType
} from '@webclient/phonebook/contact-creation-error/contact-creation-result';

function toContactCreationResult(type: ContactCreationType) {
    return (source: Observable<ResponseAcknowledge | ResponseLookup>): Observable<ContactCreationResult> =>
        source.pipe(
            map((response) => {
                if (response instanceof ResponseAcknowledge) {
                    return new ContactCreationResult({
                        type,
                        status: response.Success ? ContactCreationStatus.Success : ContactCreationStatus.Failure,
                        errorMessage: response.ExceptionMessage,
                    });
                }
                // response instanceof ResponseLookup
                else {
                    return new ContactCreationResult({
                        type,
                        status: ContactCreationStatus.Success,
                        createdContact: response.Entries.length > 0 ? AppContact.create(response.Entries[0]) : undefined
                    });
                }
            }),
            catchError((err: unknown, sourceCaught) => {
                return of(new ContactCreationResult({
                    type,
                    status: ContactCreationStatus.Failure,
                    errorMessage: extractErrorMessage(err),
                    originalError: err,
                    failedRequest$: sourceCaught
                }));
            })
        );
}

@Injectable()
export class PhonebookEditorService {
    constructor(
        private modalService: ModalService,
        private myPhone: MyPhoneService
    ) {
    }

    deleteContact(contact: AppContact): Observable<any> {
        const myPhoneContact = new Contact();
        if (contact.type === AppContactType.Extension) {
            myPhoneContact.ContactType = ContactType.LocalUser;
        }
        else if (contact.type === AppContactType.PersonalPhonebook) {
            myPhoneContact.ContactType = ContactType.PersonalPhonebook;
        }
        else if (contact.type === AppContactType.CompanyPhonebook) {
            myPhoneContact.ContactType = ContactType.CompanyPhonebook;
        }
        else {
            throw new Error('Unkown contact type');
        }
        myPhoneContact.Id = +contact.id;
        return this.updateContact(myPhoneContact, ActionType.Deleted);
    }

    private updateContact(myPhoneContact: Contact, action: ActionType, picture?: Uint8Array) {
        const request = new RequestUpdateContact({
            NewContact: myPhoneContact,
            Action: action,
            ImageContent: picture
        });
        return this.myPhone.get<ResponseAcknowledge>(request).pipe(catchError(error => {
            if (extractErrorMessage(error).startsWith('This contact entry cannot be modified because it was created / syncronized by 3CX Phone System Company Directory Manager via Microsoft Exchange / LDAP Services.')) {
                throw new Error('_i18n.ContactCannotBeModified');
            }
            throw error;
        }));
    }

    private createContact(myPhoneContact: Contact, picture?: Uint8Array) {
        return this.updateContact(myPhoneContact, ActionType.Inserted, picture);
    }

    // Create many new contacts potentially (personal/company/crm/microsoft365)
    private createContactsByModel(contactModel: ContactViewModel, mode: ContactViewMode = ContactViewMode.Normal): Observable<ContactCreationResult[]> {
        const contact = contactModel.getMyPhoneContact(mode);

        const createCrmContact$ = contactModel.crmCreationEnabled && contactModel.createCrmContact
            ? this.createContact({
                ...contact,
                AddedBy: ContactAddedByEnum.AB_Crm,
                ContactType: ContactType.ExternalContact
            }, contactModel.image).pipe(toContactCreationResult(ContactCreationType.Crm))
            : of(new ContactCreationResult({ type: ContactCreationType.Crm, status: ContactCreationStatus.NoRequest }));

        const createMicrosoft365Contact$ = contactModel.microsoft365CreationEnabled && contactModel.hasExchangeLicense && contactModel.createMicrosoft365Contact
            ? this.createContact({
                ...contact,
                AddedBy: ContactAddedByEnum.AB_Office365,
                ContactType: ContactType.ExternalContact
            }, contactModel.image).pipe(toContactCreationResult(ContactCreationType.Microsoft365))
            : of(new ContactCreationResult({ type: ContactCreationType.Microsoft365, status: ContactCreationStatus.NoRequest }));

        const createCompanyContact$ = contactModel.companyPhonebookCreationEnabled && contactModel.createCompanyContact
            ? this.createContact({
                ...contact,
                ContactType: ContactType.CompanyPhonebook
            }, contactModel.image).pipe(toContactCreationResult(ContactCreationType.Company))
            : of(new ContactCreationResult({ type: ContactCreationType.Company, status: ContactCreationStatus.NoRequest }));

        const createPersonalContact$ = contactModel.createPersonalContact
            ? this.createContact({
                ...contact,
                ContactType: ContactType.PersonalPhonebook
            }, contactModel.image).pipe(toContactCreationResult(ContactCreationType.Personal))
            : of(new ContactCreationResult({ type: ContactCreationType.Personal, status: ContactCreationStatus.NoRequest }));

        return forkJoin([
            createPersonalContact$,
            createCompanyContact$,
            createCrmContact$,
            createMicrosoft365Contact$
        ]).pipe(
            switchMap((creationResults: ContactCreationResult[]) => {
                const successesAndFailures = creationResults.filter((result) => result.status === ContactCreationStatus.Success || result.status === ContactCreationStatus.Failure);
                if (successesAndFailures.some((result) => result.status === ContactCreationStatus.Failure)) {
                    return this.modalService.showComponent(ContactCreationErrorComponent, {
                        initialState: { contactCreationResults: successesAndFailures },
                        ignoreBackdropClick: true
                    }).pipe(
                        switchMap(result => {
                            return result.decision === ModalResult.Ok && result.payload ? of(result.payload) : EMPTY;
                        })
                    );
                }
                else {
                    return of(successesAndFailures);
                }
            })
        );
    }

    updateContactByModel(contactModel: ContactViewModel, mode: ContactViewMode = ContactViewMode.Normal): Observable<ResponseAcknowledge> {
        // Update existing contact
        if (!contactModel.isNew) {
            const contact = contactModel.getMyPhoneContact(mode);
            return this.updateContact(contact, ActionType.Updated, contactModel.image).pipe(
                catchError((err: unknown) => {
                    this.modalService.error(err);
                    return of(new ResponseAcknowledge({ Success: false }));
                })
            );
        }
        // Create many new contacts potentially (personal/company/crm/microsoft365)
        else {
            return this.createContactsByModel(contactModel, mode).pipe(
                map((results: ContactCreationResult[]) => {
                    const hasFailures = results.some((result) => result.status === ContactCreationStatus.Failure);
                    return new ResponseAcknowledge({ Success: !hasFailures });
                })
            );
        }
    }

    private newPhonebookRecord(contact: ContactViewModel, mode: ContactViewMode = ContactViewMode.Normal): Observable<ContactViewModel> {
        return this.modalService.showComponent(PhonebookEditorDialogComponent, {
            initialState: { contact, mode },
            ignoreBackdropClick: true
        }).pipe(
            switchMap(result => (result.decision === ModalResult.Ok && result.payload ? of(result.payload) : EMPTY))
        );
    }

    public addToContact(init: string|AppContact, mode: ContactViewMode = ContactViewMode.Normal): Observable<{model: ContactViewModel | null, creationResults: ContactCreationResult[]}> {
        const initContact: Partial<AppContact> = (typeof init === 'object') ? {
            firstName: init.firstName,
            lastName: init.lastName,
            emailAddress: init.emailAddress,
            company: init.company,
            department: init.department,
            title: init.department,
            phones: {
                mobile: init.phones.mobile,
                mobile2: init.phones.mobile2,
                business: init.phones.business,
                business2: init.phones.business2,
                home: init.phones.home,
                businessFax: init.phones.businessFax,
                other: init.phones.other
            }
        } : {
            phones: {
                mobile: init
            }
        };

        return this.myPhone.myPhoneSession.pipe(
            switchMap(session =>
                combineLatest([
                    session.isMcmMode$,
                    session.canEditCompanyPhonebook$,
                    session.isCrmCreationAllowed$,
                    session.myInfo$.pipe(map(info => info.Office365Enabled)),
                    session.myInfo$.pipe(map(info => info.HasExchangeLicense)),
                ])
            ),
            distinctUntilChanged(),
        ).pipe(
            take(1),
            switchMap(([isMcmMode, canEditCompanyPhonebook, crmCreationEnabled, microsoft365CreationEnabled, hasExchangeLicense]) => {
                const contactViewModel = new ContactViewModel(new AppContact('-1', AppContactType.PersonalPhonebook, phonebookBridgeId, initContact));
                const companyEnabled = !isMcmMode && canEditCompanyPhonebook;
                const crmEnabled = !isMcmMode && crmCreationEnabled;
                const microsoft365Enabled = !isMcmMode && microsoft365CreationEnabled;
                contactViewModel.companyPhonebookCreationEnabled = companyEnabled;
                contactViewModel.crmCreationEnabled = crmEnabled;
                contactViewModel.microsoft365CreationEnabled = microsoft365Enabled;
                contactViewModel.hasExchangeLicense = hasExchangeLicense;
                // if only personal is allowed then should it be selected by default
                contactViewModel.createPersonalContact = !companyEnabled && !crmEnabled && !microsoft365Enabled;
                return this.newPhonebookRecord(contactViewModel, mode);
            }),
            switchMap(model => this.createContactsByModel(model, mode).pipe(
                map((creationResults) => {
                    return ({
                        // if any contact is created successfully return the model
                        model: creationResults.some((result) => result.status === ContactCreationStatus.Success) ? model : null,
                        creationResults
                    });
                })
            ))
        );
    }
}
