import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import infoCircleIcon from 'img/icons/font-awesome/regular/info-circle.svg';

/** Icon showing tooltip on click and hiding on mouse leave. Supports html.
 * triggers='focus' does not work in ngx-bootstrap@9, so we emulate desired behaviour with less possible code
 * triggers='click:mouseleave' has issue with bubbling click event to parent */
@Component({
    selector: 'field-hint',
    template: `
        <ng-template #hintTemplate>
            <div *ngIf="text" [innerHtml]="text | translate"></div>
        </ng-template>
        <span *ngIf="text" [tooltip]="hintTemplate" role="button" placement="right"
              triggers="click:mouseleave" (click)="$event.preventDefault()">
            <show-svg-images class="customSVGIcons svg-xs fillIconBlue" [svg]="hintIcon"></show-svg-images>
        </span>
    `,
    styles: ['span { display: flex; }'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FieldHintComponent {
    readonly hintIcon = infoCircleIcon;

    @Input() text = '';
}
