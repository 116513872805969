<div class="navbar-collapse w-100 justify-content-end pos-rlt pe-0">
    <div class="d-flex w-100 flex-row justify-content-end align-items-center">
        <a href="{{'_i18n.AboutUri' | translate}}" target="_blank" class="header-button logo-button" >
            <show-3cx-logo-icon class="d-flex themify header-logo"></show-3cx-logo-icon>
        </a>

        <div class="flex-grow-1 text-truncate px-1" [title]='sectionTitle$ | async | translate'>{{sectionTitle$ | async | translate}}</div>

        <app-webmeeting-activator></app-webmeeting-activator>

        <a class='header-button' role="button" *ngIf='silentMode$ | async' data-qa="button-disable-silent-mode" id="buttonDisableSilentMode"
           (click)='disableSilentMode()' [title]="'_i18n.SetRingerOn' | translate">
            <i class="customSVGIcons svg-md" app-bell-slash-solid-solid-icon></i>
        </a>

        <ongoing-call-button></ongoing-call-button>

        <app-install-mobile-app-button></app-install-mobile-app-button>

        <app-help-header></app-help-header>

        <wc-account-menu [disabled]="isSessionExpired$|async" [myContact]="contact$|async" ></wc-account-menu>
    </div>
</div>
