import { Component, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { Router, RouterLink } from '@angular/router';
import { CdkScrollable } from '@angular/cdk/overlay';

@Component({
    selector: 'app-page',
    template: `
        <ng-template #content>
            <ng-content></ng-content>
        </ng-template>
        <div *ngIf="!disableHeader" class="card-header d-flex align-items-center aside-align-panel-header border-bottom gap-2 px-4 py-1">
            <button *ngIf="back.observed && !hideBack" type="button" class="btn btn-plain" app-arrow-left-light-icon
                    (click)="handleBackClick()" data-qa="back-btn">
            </button>
            <a [routerLink]="backRouterLink" *ngIf="backRouterLink" class="btn btn-plain" app-arrow-left-light-icon
               data-qa="back-to-parent">
            </a>
            <h4 *ngIf="header" class="mb-0 me-2" data-qa="header">{{ header | translate }}</h4>
            <ng-content select=".header,[header]"></ng-content>
            <span *ngIf="isAdminSectionActive" class="help-btn" data-qa="header-help-guide-btn">
                <a *ngIf="!helpUrl" class="btn btn-plain" target="_blank" [href]="'_i18n.OfficeUsersAdmin' | translate" data-qa="header-help-guide-link">
                    <span>{{ '_i18n.HelpPage' | translate }}</span>
                    <app-question-circle-regular-icon></app-question-circle-regular-icon>
                </a>
                <a *ngIf="helpUrl" class="btn btn-plain" target="_blank" [href]="helpUrl | translate" data-qa="header-help-guide-link-tab">
                    <span>{{ '_i18n.HelpPage' | translate }}</span>
                    <app-question-circle-regular-icon></app-question-circle-regular-icon>
                </a>
            </span>
        </div>
        <ng-container *ngIf="!containsInnerScrollable; else content">
            <ng-content select="[top-bar]"></ng-content>
            <div class="flex-grow-1 overflow-auto" cdkScrollable>
                <ng-content select="[full-width]"></ng-content>
                <div class="card-body px-4">
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                </div>
            </div>
        </ng-container>
    `,
    standalone: true,
    imports: [TranslateModule, CommonModule, IconsModule, RouterLink, CdkScrollable],
    styles: [`
        :host { border-top: 0; }
        :host ::ng-deep tabset:first-child:not([full-width]) {
            display: block;
            margin-top: -.5em;
        }

        .card-header {
            text-wrap: nowrap;
            overflow-x: auto;
            overflow-y: hidden;
            scrollbar-width: none;
        }

        .card-body {
            max-width: 1000px;
        }

        .card-body:empty {
            display: none;
        }

        .help-btn {
            margin-left: auto;
            font-weight: normal;
        }

        /*:host ::ng-deep .help-btn svg {*/
        /*    fill: #0096d1;*/
        /*}*/
    `]
})
export class PageComponent implements OnInit {
    @Input() header: string | null;
    @Input() containsInnerScrollable: boolean;
    @Input() disableHeader = false;
    @Input() hideBack = false;
    @Input() helpUrl: string | null;

    /** Show back button using provided route */
    @Input() backRouterLink?: string | string[];

    @Output() readonly back = new EventEmitter<void>();

    private backEnabled: boolean;

    handleBackClick() {
        if (this.backEnabled) {
            this.back.emit();
            this.backEnabled = false;
        }
    }

    constructor(private router: Router) {
    }

    get isAdminSectionActive() {
        return this.router.url.indexOf('/office') !== -1;
    }

    @HostBinding('class')
    get hostClass() {
        return 'card h-100 mb-0 border-sm-0';
    }

    ngOnInit(): void {
        this.backEnabled = true;
    }
}
