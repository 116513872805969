import { type CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { TokenInfoService } from '@webclient/auth/token-info.service';
import { MyToken } from '@webclient/auth/token-funcs';

type PickProperties<T, TFilter> = { [K in keyof T as (T[K] extends TFilter ? K : never)]: T[K] }

export type Permission = keyof PickProperties<MyToken, boolean>;

/** Guard factory to check boolean parameter in token */
export const hasPermissionGuard: (...flags: Permission[]) => CanActivateFn = (...flags) =>
    () => {
        const tokenInfo = inject(TokenInfoService);
        const router = inject(Router);

        return tokenInfo.myToken$.pipe(
            take(1),
            map(token => flags.some(flag => token[flag] === true) || router.parseUrl('error'))
        );
    };
