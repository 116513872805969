import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, HostBinding,
    Inject,
    Injector,
    Input,
    OnInit, Self,
    ViewChild
} from '@angular/core';
import type { ElementRef } from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import type { AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgSelectComponent, NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalService } from '@webclient/modal/app-modal.service';
import { DeviceMediaService } from '@webclient/phone/device-media.service';
import { blockedByLength } from '@webclient/phone/device-media-utils';
import { noEmitAndShowMessageOnError, switchToDialogPayload } from '@webclient/rx-utils';
import { FieldValueAccessor, FieldsModule } from '@webclient/fields';
import { getComponentControl, setControlEnabled } from '@webclient/fields/fields.utils';
import { Focusable, FOCUSABLE } from '@webclient/standalones/directives';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { PbxCustomPrompt } from '@xapi';
import { PromptRecorderComponent } from './prompt-recorder/prompt-recorder.component';
import { PROMPT_SERVICE, PromptService } from './prompt.service';
import { auditTime, combineLatestWith, EMPTY, takeUntil } from 'rxjs';
import { DestroyService } from '@webclient/services/destroy.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { ValdemortModule } from 'ngx-valdemort';
import { ActivatedRoute } from '@angular/router';
import { validateFileForPromptUpload } from './utils';
import { FileDownloadService } from '@webclient/shared/service/file-download.service';
import { TruncatePipe } from '@webclient/standalones/pipes/truncate.pipe';
import { PromptCstaRecordingComponent } from './prompt-csta-recording/prompt-csta-recording.component';

export type PromptMatchedProperty = 'Filename' | 'Fullpath'

@Component({
    selector: 'app-prompt-selector',
    templateUrl: './prompt-selector.component.html',
    styleUrls: ['./prompt-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: PromptSelectorComponent },
        { provide: NG_VALIDATORS, multi: true, useExisting: PromptSelectorComponent },
        { provide: FOCUSABLE, useExisting: PromptSelectorComponent },
        DestroyService
    ],
    standalone: true,
    imports: [CommonModule, TranslateModule, IconsModule, TooltipModule, NgSelectModule, ReactiveFormsModule, ValdemortModule, FieldsModule, TruncatePipe]
})

export class PromptSelectorComponent extends FieldValueAccessor<string | null> implements Validator, OnInit, Focusable, AfterViewInit {
    @Input() label?: string | null;
    @Input() inlineLabel?: boolean;
    @Input() clearable?: boolean;
    @Input() iconButtons: boolean = true;
    @Input() restrictedAccess?: boolean | null;
    @Input() description?: string;
    @Input() matchedProperty: PromptMatchedProperty = 'Filename';
    @Input() isExtVoicemailPromptRestriction = false;
    @Input() isAdminView = false;
    @Input() isRecordingAllowed = true;
    @Input() isCstaRecordingAllowed = true;

    // comes from async pipe, we should support null
    @Input() maxPrompts: number | null;

    /** Show null value as indeterminate input state. Changed should not be counted, as it is intentional empty value. */
    @Input() nullIsIndeterminate = false;
    @Input() indeterminatePlaceholder = '_i18n.LeaveUnchanged';

    @ViewChild('file', { static: false }) fileUpload: ElementRef;
    @ViewChild('ngSelect', { static: true }) selectComponent: NgSelectComponent;

    @HostBinding('class.form-input') readonly containerClass = true;

    readonly formControl = new FormControl<string | null>(null, ({ value }) => (this.isPromptMissing(value) ? { missing: true } : null));

    readonly isMicrophoneDisabled$ = this.media.microphoneDeviceList$.pipe(blockedByLength);

    readonly prompts$ = this.service.availablePrompts$;

    private prompts?: PbxCustomPrompt[];

    getFallbackDisplayName(prompt: PbxCustomPrompt) {
        return prompt.Filename || (prompt.Fullpath ? prompt.Fullpath.substring(prompt.Fullpath.lastIndexOf('/') + 1) : undefined);
    }

    constructor(
        @Inject(PROMPT_SERVICE) private service: PromptService,
        private translate: TranslateService,
        private injector: Injector,
        private modalService: ModalService,
        private media: DeviceMediaService,
        private route: ActivatedRoute,
        private fileDownloadService: FileDownloadService,
        @Self() private destroy$: DestroyService,
        cd: ChangeDetectorRef,
    ) {
        super(cd);
        this.touchOnChange = true;
    }

    get maxPromptsReached() {
        return this.maxPrompts && this.prompts && this.maxPrompts > 0 && this.prompts.length >= this.maxPrompts;
    }

    get indeterminate() {
        return this.nullIsIndeterminate && this.value == null && !this.changed;
    }

    writeValue(value: string) {
        super.writeValue(value ?? '');
        this.formControl.setValue(this.value, { emitEvent: false });
    }

    setDisabledState(disabled: boolean) {
        super.setDisabledState(disabled);
        setControlEnabled(this.formControl, !this.disabled);
    }

    focus() {
        this.selectComponent.focus();
    }

    ngOnInit(): void {
        const control = getComponentControl(this.injector);

        if (control?.hasValidator(Validators.required)) {
            this.formControl.addValidators(Validators.required);
        }

        this.formControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            // use force, Luke, to make this control revalidate properly (issues 36241, 36294, 36057)
            .subscribe(value => this.valueChanged(value, Boolean(value)));
    }

    ngAfterViewInit() {
        // after angular init cycle complete we can call updateValueAndValidity on prompts change and expect next steps:
        // 1) prompt-selector value change -> inner control revalidated
        // 2) prompt-selector validate -> outer control revalidated
        // 3) call-prompts value change -> inner control is the same as outer for prompt-selector - revalidated
        // 4) call-prompts validate -> outer control revalidated, we successfully have proxied the state all up
        // solved issues 36241, 36294, 36057
        this.prompts$.pipe(auditTime(0), takeUntil(this.destroy$)).subscribe(prompts => {
            this.prompts = prompts;
            if (this.formControl.hasError('missing') !== this.isPromptMissing(this.value)) {
                // control becomes dirty, that's why we should check if revalidation is needed
                this.formControl.updateValueAndValidity();
            }
            if (this.formControl.invalid) {
                this.formControl.markAsTouched();
            }
            this.cd.markForCheck();
        });
    }

    downloadFile(prompt: PbxCustomPrompt, event: Event) {
        event.stopPropagation();
        const fileLink = prompt?.FileLink;
        if (fileLink) {
            this.fileDownloadService.download(fileLink, { addXapiPath: false, filename: prompt.Filename });
        }
    }

    record() {
        this.modalService.showComponent(PromptRecorderComponent)
            .pipe(switchToDialogPayload())
            .subscribe((file) => {
                this.uploadFile(file);
            });
    }

    recordCsta() {
        this.modalService.showComponent(PromptCstaRecordingComponent, {
            initialState: { dn: this.route.snapshot.data.myUser, id: +this.route.snapshot.params.id, isAdminView: this.isAdminView }
        }).subscribe();
    }

    get canDeleteSelectedPrompt() {
        return !this.disabled && this.value && this.prompts?.find(prompt => this.getPromptValue(prompt) === this.value)?.CanBeDeleted;
    }

    isPromptMissing(value: string | null | undefined) {
        return Boolean(value && this.prompts && !this.prompts.some(p => (this.getPromptValue(p) === value)));
    }

    /**
     * Returns either the prompt Filename or the prompt Fullpath according to which is the matchedProperty
     */
    private getPromptValue(prompt: PbxCustomPrompt) {
        return prompt[this.matchedProperty]!;
    }

    /**
     * finds the prompt whose matchedProperty (Filename or Fullpath) is equal to the value
     */
    private findPromptByValue(value: string | null) : PbxCustomPrompt | undefined {
        return value ? this.prompts?.find((prompt) => (prompt[this.matchedProperty] === value)) : undefined;
    }

    uploadPrompt() {
        // User initiated file upload procedure
        this.fileUpload.nativeElement.value = '';
        this.fileUpload.nativeElement.click();
    }

    onFileSelected(files: any) {
        if (files && files.length > 0) {
            const file = files[0] as File;
            this.uploadFile(file);
        }
    }

    deletePromptFromMenu($event: Event, pbxPromptToDelete: PbxCustomPrompt) {
        $event.stopPropagation();
        this.selectComponent.close();
        this.deletePbxPrompt(pbxPromptToDelete, this.getPromptValue(pbxPromptToDelete) === this.value);
    }

    deleteSelectedPrompt() {
        const pbxPromptToDelete = this.findPromptByValue(this.value);

        if (pbxPromptToDelete) {
            this.deletePbxPrompt(pbxPromptToDelete, true);
        }
    }

    private deletePbxPrompt(pbxPromptToDelete: PbxCustomPrompt, isSelected: boolean) {
        this.modalService.confirmation('_i18n.AreYouSureYouWantToDeletePrompt')
            .subscribe(() => {
                this.handleDelete(pbxPromptToDelete, isSelected);
            });
    }

    search = (searchText: string, item: PbxCustomPrompt) => {
        const searchBy: string = ((item.DisplayName ? this.translate.instant(item.DisplayName) : null) ?? item.Filename ?? '');
        return searchBy.toLowerCase().includes(searchText.trim().toLowerCase());
    };

    typify(item: unknown): PbxCustomPrompt {
        return item as PbxCustomPrompt;
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.formControl.errors;
    }

    private uploadFile(file: File) {
        const error = validateFileForPromptUpload(file);

        if (error) {
            this.modalService.error(error);
            return;
        }
        if (this.isExtVoicemailPromptRestriction && !this.route.snapshot.params.id) {
            this.formControl.setErrors({ ExtNotSaved: true });
            return;
        }

        this.service.uploadPrompt$(file)
            .pipe(
                catchError((err: unknown) => {
                    const error = err instanceof HttpErrorResponse
                        ? err.status === 415 ? '_i18n.IncorrectAudioFormat' : '_i18n.UploadingAudioFailed'
                        : err;
                    this.modalService.error(error);
                    return EMPTY;
                }),
                // wait for list update before setting value, otherwise it will be dropped
                combineLatestWith(this.prompts$)
            )
            .subscribe(([_, customPrompts]) => {
                const uploadedPrompt = customPrompts.find((prompt) => prompt.Filename === file.name);
                if (uploadedPrompt) {
                    this.formControl.setValue(this.getPromptValue(uploadedPrompt));
                }
            });
    }

    private handleDelete(pbxPromptToDelete: PbxCustomPrompt, isSelected: boolean) {
        // the deletion of the prompt is always by filename as the filename if the key of the prompt in xapi
        this.service.deletePrompt$(pbxPromptToDelete.Filename!)
            .pipe(noEmitAndShowMessageOnError(this.modalService))
            .subscribe(() => {
                if (isSelected) {
                    this.formControl.setValue('');
                }
            });
    }
}
