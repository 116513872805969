import { MyCall } from './mycall';
import { List, Record } from 'immutable';

export class MyCalls extends Record({
    hasNewCalls: false,
    isNewCallAllowed: true,
    calls: List<MyCall>(),
    hiddenCalls: List<MyCall>(),
}) {
}
