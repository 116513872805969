import { Component, HostBinding, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppsUtilityService } from '../../services/apps-utility.service';
import { DialogComponent } from '@webclient/modal/dialog';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog/modal-dialog.component';

@Component({
    template: `
        <app-modal-dialog [buttons]="0" #dialog>
            <h4 dialog-header>{{ dialogTitle| translate }}</h4>
            <p *ngIf="dialogMainInfo as text">{{ text | translate }}</p>
            <div class="row">
                <div class="col-12 mb-3">
                    <div>
                            <ul class="mb-3">
                                <li [translate]="'_i18n.Install3cxPwaAppInfo1'">Eliminates login and tabs.</li>
                                <li [translate]="'_i18n.Install3cxPwaAppInfo2'">Get notified for incoming calls or chats.</li>
                                <li [translate]="'_i18n.Install3cxPwaAppInfo3'">Automatic updates.</li>
                                <li [translate]="'_i18n.Install3cxPwaAppInfo4'">Runs securely within your browser.</li>
                                <li [translate]="'_i18n.Install3cxPwaAppInfo5'">Use in tandem with iOS, Android & deskphone.</li>
                                <li><span [innerHTML]="'_i18n.Install3cxPwaAppInfo6' | translate"></span></li>
                            </ul>
                            <div class="text-center mb-3">
                                            <button class="btn btn-primary actionButton" type="button"
                                                    [disabled]="!(appsUtilityService.isPwaInstallAvailable$ | async)"
                                                    (click)="installPwaApp()">
                                                <span class='text-truncate' [title]="'_i18n.InstallNow' | translate">{{'_i18n.InstallNow' | translate}}</span>
                                            </button>
                            </div>
                    </div>
                </div>
            </div>
        </app-modal-dialog>
    `,
    styleUrls: ['show-apps-dialog.component.scss'],
    standalone: true,
    imports: [CommonModule, TranslateModule, ModalDialogComponent,]
})
export class ShowAppsDialogComponent extends DialogComponent {
    showCeDeprecation = false;

    @ViewChild('dialog')
    private dialog: ModalDialogComponent;

    @HostBinding('class') get containerClass() {
        return 'modal-pwa';
    }

    constructor(
        public appsUtilityService: AppsUtilityService
    ) {
        super();
    }

    installPwaApp() {
        this.dialog.submit(); // close with Ok
        this.appsUtilityService.firePwaInstallation();
    }

    get dialogTitle(): string {
        return this.showCeDeprecation ? '_i18n.DeprecateExtensionTitlePwa' : '_i18n.Install3cxPwa';
    }

    get dialogMainInfo(): string {
        return this.showCeDeprecation ? '_i18n.DeprecateExtensionMainInfoPwa' : '';
    }
}
