import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Injector,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validators
} from '@angular/forms';
import { getComponentControl, getUniqueFieldId } from '../fields.utils';
// noinspection ES6PreferShortImport
import { FieldValueAccessor } from '../field-value-accessor';
import { Focusable, FOCUSABLE } from '@webclient/standalones/directives/autofocus/focusable';
import { invalidHexValidator } from '@webclient/validators';

@Component({
    selector: 'field-input',
    styleUrls: ['./field-input.component.scss'],
    templateUrl: './field-input.component.html',
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: FieldInputComponent },
        { provide: NG_VALIDATORS, multi: true, useExisting: FieldInputComponent },
        { provide: FOCUSABLE, useExisting: FieldInputComponent }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldInputComponent<TValue = string, TInnerValue = TValue> extends FieldValueAccessor<TValue, TInnerValue> implements OnInit, Focusable {
    readonly labelToInputLinkId: string = getUniqueFieldId('text-input');

    @Input() label = '';
    @Input() description = '';
    @Input() hint = '';
    @Input() placeholder = '';
    @Input() maxlength = 255;
    @Input() minlength = 0;
    @Input() autocomplete = '';
    @Input() name?: string;
    @Input() inlineButtonIcon?: string;
    @Input() inlineButtonDisabled?: boolean;
    @Input() readonly?: boolean;
    @Input() hideAsterisk?: boolean;
    @Input() inlineLabel?: boolean;
    @Input() inlineLabelAuto?: boolean;
    @Input() allowedInputPattern?: string;

    /** Show null value as indeterminate input state. Changed should not be counted, as it is intentional empty value. */
    @Input() nullIsIndeterminate = false;
    @Input() indeterminatePlaceholder = '_i18n.LeaveUnchanged';

    /** For inputs with control buttons provided */
    @Input() noManualInput?: boolean;

    /** Field allows only digits in its string value */
    @Input() numeric?: boolean;

    /** Custom case when field is required, but can not know it from its validators */
    @Input() required = false;

    @Output() inlineButtonClick = new EventEmitter<void>();

    @ViewChild('inputElement') inputElement: ElementRef<HTMLInputElement>;

    get indeterminate() {
        return this.nullIsIndeterminate && this.value == null && !this.changed;
    }

    get type(): string {
        // with generic type binding to 'number' value stays as string
        return this.numeric ? 'number' : 'text';
    }

    get inlineBtnIcon(): string {
        return this.inlineButtonIcon || '';
    }

    get isRequired() {
        if (this.readonly || this.disabled || this.hideAsterisk) {
            return false;
        }
        return this.required || this.control?.hasValidator(Validators.required) === true;
    }

    protected control?: FormControl;

    focus() {
        this.inputElement.nativeElement.focus();
    }

    inlineBtnClick(): void {
        this.inlineButtonClick.emit();
    }

    constructor(cdr: ChangeDetectorRef, private injector: Injector) {
        super(cdr);
        this.touchOnChange = false;
    }

    ngOnInit(): void {
        this.control = getComponentControl(this.injector);
    }

    onFocus() {
    }

    onPaste(event: ClipboardEvent) {
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return invalidHexValidator(control);
    }

    preventInvalidInput(event: KeyboardEvent) {
        if (this.allowedInputPattern && !event.ctrlKey && /^(\w|\s)$/.test(event.key) && !new RegExp(this.allowedInputPattern).test(event.key)) {
            event.preventDefault();
        }
    }
}
