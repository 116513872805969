import { SortedContactsMap } from './sorted-contacts-map';
import { AppContact } from './contact';
import { Bridge } from './bridge';
import { ReplaySubject } from 'rxjs';
import {
    DateTime, GroupHoursMode, GroupId
} from '@myphone';

export class Group {
    // Group id
    public readonly id: string;
    // Group name
    public name: string;
    public language: string;
    public language$: ReplaySubject<string> = new ReplaySubject(1);
    public readonly bridge: Bridge;
    public groupId = new GroupId();

    public currentGroupHours: GroupHoursMode;
    public overrideExpiresAt: DateTime;
    public disableCustomPrompt: boolean;
    public allowCallService: boolean;
    public allowCallService$: ReplaySubject<boolean> = new ReplaySubject(1);

    // Available group contacts
    public contactsMap: SortedContactsMap = new SortedContactsMap();
    public contactsMap$: ReplaySubject<SortedContactsMap> = new ReplaySubject(1);
    isHidden = false; // Ui property - stored in local storage

    constructor(id: string, bridge: Bridge) {
        this.id = id;
        this.bridge = bridge;
        this.onContactsChanged();
    }

    public onContactsChanged() {
        this.contactsMap$.next(this.contactsMap);
    }

    // Get all groups
    get contacts(): AppContact[] {
        return this.contactsMap.getSortedContacts();
    }

    isFavorite(): boolean {
        return !!(this.name && (this.name.indexOf('___FAVORITES___') === 0));
    }

    onGroupLanguageChanged() {
        this.language$.next(this.language);
    }

    onAllowCallServiceChanged() {
        this.allowCallService$.next(this.allowCallService);
    }
}
