/// <reference types="w3c-web-hid" />
import { HeadsetInterface } from '@webclient/phone/headsets/headset-interface';
import {
    catchError,
    concat,
    defer,
    EMPTY,
    from,
    fromEvent,
    merge,
    Observable,
    of, ReplaySubject, retry,
    share,
    Subject,
    switchMap,
    throwError
} from 'rxjs';
import { HeadsetDiagnostics } from '@webclient/phone/headsets/headset-diagnostics';
import {
    HeadsetCallAction,
    HeadsetCallCommand
} from '@webclient/phone/headsets/headset-call-command';
import {
    delay, distinctUntilChanged,
    filter, finalize,
    map,
    mergeScan,
    startWith,
    take,
    tap,
    timeout
} from 'rxjs/operators';
import {
    constructYealinkReportData,
    decodeUsagesFromHidReports, deviceSupportsStandardHID,
    deviceSupportsYealinkHID, extractReportId,
    extractYealinkKeypadDigit, extractYealinkReportId,
    generateHidReportMap,
    getAvailableDevice,
    HIDReportCollectionMap,
    isValidHIDDevice,
    openHidDevice,
    requestDevice
} from '@webclient/phone/headsets/yealink/hid-integration-helper';
import { HeadsetCallState } from '@webclient/phone/headsets/headset-call-state';
import { logHeadsetEvent, logHeadsetWarn } from '@webclient/phone/headsets/device-helpers';

export const YealinkVendorId = 0x6993;
export const TelephonyDevicePage = 0x000B;
export const YealinkDevicePage = 0xFF30;

export enum YealinkHidHeadsetSendAction {
    OnHook = 0,
    OffHook = 1 << 0,
    Mute = 1 << 1,
    Ring = 1 << 2,
    Hold = 1 << 3
}

export enum YealinkHidHeadsetState {
    HookSwitch = 0x0020,
    Flash = 0x0021,
    // Redial = 0x0024,
    PhoneMute = 0x002F,
    LineBusy = 0x0097,
    // VolumeDecrement = 0xC00EA,
    // VolumeIncrement = 0xC00E9,
    Reject = 0x0007,
    RejectYL = 0xFFFD,
    // RejectChromeOS = 0x0007,
}

export enum YealinkHidKeypadDigit {
    None = 0b0000, // 0
    Key0 = 0b0001, // 1
    Key1 = 0b0010, // 2
    Key2 = 0b0011, // 3
    Key3 = 0b0100, // 4
    Key4 = 0b0101, // 5
    Key5 = 0b0110, // 6
    Key6 = 0b0111, // 7
    Key7 = 0b1000, // 8
    Key8 = 0b1001, // 9
    Key9 = 0b1010, // 10
    KeyStar = 0b1011, // 11
    KeyPound = 0b1100, // 12
}

export type YealinkHidConnectionAction = {
    type: 'init' | 'connect' | 'disconnect' | 'request'; device: HIDDevice | null
}

export const YealinkHIDDeviceFilters: HIDDeviceFilter[] = [
    {
        vendorId: YealinkVendorId,
        usagePage: TelephonyDevicePage,
        usage: 0x0005,
    },
];

const YealinkCommandTimeoutMs = 5000;
const MaxConfirmationDelayMs = 2000;
export const YealinkLogTitle = 'Yealink -';

export class YealinkHidService implements HeadsetInterface {
    private skipConfirmation = 0;
    private readonly requestedDevice$: Subject<HIDDevice|null> = new Subject<HIDDevice | null>();
    private readonly callStateHelper = new HeadsetCallState();
    private readonly device$: Observable<HIDDevice | null>;
    private next = Promise.resolve();
    private keypadDigitsPressed : string[] = [];

    private readonly digitButtonTypesMapper: Map<YealinkHidKeypadDigit, string> = new Map<YealinkHidKeypadDigit, string>([
        [YealinkHidKeypadDigit.Key0, '0'],
        [YealinkHidKeypadDigit.Key1, '1'],
        [YealinkHidKeypadDigit.Key2, '2'],
        [YealinkHidKeypadDigit.Key3, '3'],
        [YealinkHidKeypadDigit.Key4, '4'],
        [YealinkHidKeypadDigit.Key5, '5'],
        [YealinkHidKeypadDigit.Key6, '6'],
        [YealinkHidKeypadDigit.Key7, '7'],
        [YealinkHidKeypadDigit.Key8, '8'],
        [YealinkHidKeypadDigit.Key9, '9'],
        [YealinkHidKeypadDigit.KeyStar, '*'],
        [YealinkHidKeypadDigit.KeyPound, '#'],
    ]);

    diagnostics$: Observable<HeadsetDiagnostics>;
    headsetEvents$: Observable<HeadsetCallCommand>;

    constructor() {
        const initialize$: Observable<YealinkHidConnectionAction> = defer(() => this.init()).pipe(
            map((device: HIDDevice | null) => {
                return { type: 'init', device };
            }),
        );

        const deviceRequest$: Observable<YealinkHidConnectionAction> = this.requestedDevice$.pipe(
            filter((device: HIDDevice|null) => !!device && isValidHIDDevice(device, YealinkHIDDeviceFilters)),
            map((device: HIDDevice | null) => {
                return { type: 'request', device };
            }),
        );

        const deviceConnect$: Observable<YealinkHidConnectionAction> = fromEvent<HIDConnectionEvent>(navigator.hid, 'connect').pipe(
            map((event : HIDConnectionEvent) => {
                return <YealinkHidConnectionAction>{ type: 'connect', device: event.device };
            }),
        );
        const deviceDisconnect$: Observable<YealinkHidConnectionAction> = fromEvent<HIDConnectionEvent>(navigator.hid, 'disconnect').pipe(
            filter((event: HIDConnectionEvent) => !!event.device && isValidHIDDevice(event.device, YealinkHIDDeviceFilters)),
            map((event : HIDConnectionEvent) => {
                return { type: 'disconnect', device: event.device };
            })
        );

        this.device$ = merge(initialize$, deviceRequest$, deviceConnect$, deviceDisconnect$).pipe(
            mergeScan((device: HIDDevice|null, connectionAction: YealinkHidConnectionAction) => {
                if (connectionAction.type === 'init') {
                    logHeadsetEvent(YealinkLogTitle, ' device init:', connectionAction.device?.productName);
                    return of(connectionAction.device);
                }
                else if (connectionAction.type === 'request') {
                    logHeadsetEvent(YealinkLogTitle, 'device paired:', connectionAction.device?.productName);
                    return connectionAction.device ? of(connectionAction.device) : device ? of(device) : from(getAvailableDevice(YealinkHIDDeviceFilters));
                }
                else if (connectionAction.type === 'connect' && !device) {
                    logHeadsetEvent(YealinkLogTitle, 'device connected:', connectionAction.device?.productName);
                    return of(connectionAction.device);
                }
                else if (connectionAction.type === 'disconnect' && connectionAction.device === device) {
                    logHeadsetEvent(YealinkLogTitle, 'device disconnected:', connectionAction.device?.productName);
                    // check if there are other available devices
                    return from(getAvailableDevice(YealinkHIDDeviceFilters));
                }
                return of(device);
            }, null, 1),
            switchMap((device: HIDDevice|null) => {
                if (device) {
                    if (!deviceSupportsStandardHID(device) && !deviceSupportsYealinkHID(device)) {
                        return throwError(() => new Error(`Device "${device.productName}" does not support neither Standard telephony page (${TelephonyDevicePage}) nor Yealink telephony page (${YealinkDevicePage}).`));
                    }
                    else if (!isValidHIDDevice(device, YealinkHIDDeviceFilters)) {
                        return throwError(() => new Error(`Device "${device.productName}" is not a a supported HID device.`));
                    }
                    else if (extractYealinkReportId(device) < 0) {
                        return throwError(() => new Error('Report id for communication could not be extracted'));
                    }
                    // open device if necessary
                    else if (!device.opened) {
                        return from(openHidDevice(device));
                    }
                }
                return of(device);
            }),
            distinctUntilChanged((previousDevice, currentDevice) => previousDevice?.productId === currentDevice?.productId),
            catchError((error: unknown) => {
                logHeadsetWarn(YealinkLogTitle, error);
                throw error;
            }),
            retry({ delay: YealinkCommandTimeoutMs }),
            share({ connector: () => new ReplaySubject<HIDDevice|null>(1) }),
        );

        this.headsetEvents$ = this.device$.pipe(
            filter((device: HIDDevice | null) => !!device),
            switchMap((device) => {
                if (device) {
                    const reportCollectionMap: HIDReportCollectionMap = generateHidReportMap(device);
                    const standardHIDReportId = extractReportId(device, TelephonyDevicePage);
                    const yealinkHIDReportId = extractReportId(device, YealinkDevicePage);
                    logHeadsetEvent(YealinkLogTitle, `device supports ${deviceSupportsYealinkHID(device) ? 'Yealink' : 'Standard'} Telephony Interface on report id ${extractYealinkReportId(device)}`);
                    return fromEvent<HIDInputReportEvent>(device, 'inputreport')
                        .pipe(
                            switchMap((event: HIDInputReportEvent) => {
                                const { data, device, reportId } = event;

                                // accept events only from supported report ids
                                if (reportId !== standardHIDReportId && reportId !== yealinkHIDReportId) {
                                    return EMPTY;
                                }
                                const headsetStateValue = data.getUint8(0);
                                const digitPressedValue = extractYealinkKeypadDigit(headsetStateValue + data.getUint8(1) * (1 << 8));
                                const availableUsages = Object.values(YealinkHidHeadsetState).filter(value => !isNaN(Number(value))) as number[];
                                const headsetStatesDecoded :YealinkHidHeadsetState[] = decodeUsagesFromHidReports(reportCollectionMap, reportId, headsetStateValue, availableUsages) as YealinkHidHeadsetState[];

                                if (headsetStatesDecoded.length === 0 && digitPressedValue === YealinkHidKeypadDigit.None) {
                                    return EMPTY;
                                }

                                const digitInfo = `\n Digit pressed: ${YealinkHidKeypadDigit[digitPressedValue]}`;
                                const headsetStateInfo = `\n Received States: ${headsetStatesDecoded.length > 0 ? headsetStatesDecoded.map((headsetState) => YealinkHidHeadsetState[headsetState] + ':' + headsetState.toString(16)).join(', ') : 'None'}`;
                                logHeadsetEvent(YealinkLogTitle, `${headsetStateInfo}${digitInfo}`);
                                if (digitPressedValue !== YealinkHidKeypadDigit.None) {
                                    const dtmfDigit: string | undefined = this.digitButtonTypesMapper.get(digitPressedValue);
                                    this.skipConfirmation = Date.now();
                                    if (dtmfDigit) {
                                        // if dialpad digit is pressed while on a call send dtmf
                                        if (this.callStateHelper.activeCall !== HeadsetCallState.NO_CALL) {
                                            return of(<HeadsetCallCommand>{
                                                CallId: this.callStateHelper.activeCall,
                                                Action: HeadsetCallAction.SendDtmf,
                                                Value: dtmfDigit
                                            });
                                        }
                                        // if no call then save phone digits pressed to form a phone number
                                        else {
                                            this.keypadDigitsPressed.push(dtmfDigit);
                                        }
                                    }
                                }
                                else if (headsetStatesDecoded.length === 1 && headsetStatesDecoded.includes(YealinkHidHeadsetState.HookSwitch)) {
                                    // if no active call
                                    if (this.callStateHelper.activeCall !== HeadsetCallState.NO_CALL) {
                                        if (this.skipConfirmation > 0 && (Date.now() - this.skipConfirmation) < MaxConfirmationDelayMs) {
                                            this.skipConfirmation = 0;
                                            return EMPTY;
                                        }
                                        return of(<HeadsetCallCommand>{ CallId: this.callStateHelper.activeCall, Action: HeadsetCallAction.TerminateCall });
                                    }
                                    else { // if there is an active call
                                        const ringingCallId = this.callStateHelper.ringingCalls.values().next()?.value;
                                        if (ringingCallId !== undefined) {
                                            return of(<HeadsetCallCommand>{ CallId: ringingCallId, Action: HeadsetCallAction.AcceptCall });
                                        }
                                        else if (this.callStateHelper.activeCall === HeadsetCallState.NO_CALL && this.keypadDigitsPressed.length > 0) {
                                            const phoneNumber = this.keypadDigitsPressed.join('');
                                            return of(<HeadsetCallCommand>{ CallId: -1, Action: HeadsetCallAction.MakeCall, Value: phoneNumber });
                                        }
                                    }
                                }
                                else if (headsetStatesDecoded.length === 1 && headsetStatesDecoded.includes(YealinkHidHeadsetState.LineBusy)) {
                                    const ringingCallId = this.callStateHelper.ringingCalls.values().next()?.value;
                                    if (ringingCallId !== undefined) {
                                        return of(<HeadsetCallCommand>{ CallId: ringingCallId, Action: HeadsetCallAction.AcceptCall });
                                    }
                                }
                                else if (/* headsetStatesDecoded.length === 2 && headsetStatesDecoded.includes(YealinkHeadsetState.HookSwitch) && */ headsetStatesDecoded.includes(YealinkHidHeadsetState.PhoneMute)) {
                                    // ignore HookSwitch that comes afterwards
                                    if (this.callStateHelper.activeCall !== HeadsetCallState.NO_CALL) {
                                        this.skipConfirmation = Date.now();
                                        return of(<HeadsetCallCommand>{ CallId: this.callStateHelper.activeCall, Action: HeadsetCallAction.ToggleMuteCall });
                                    }
                                }
                                else if (headsetStatesDecoded.includes(YealinkHidHeadsetState.Reject) || headsetStatesDecoded.includes(YealinkHidHeadsetState.RejectYL)) {
                                    const ringingCallId = this.callStateHelper.ringingCalls.values().next()?.value;
                                    if (ringingCallId !== undefined) {
                                        this.skipConfirmation = Date.now();
                                        return of(<HeadsetCallCommand>{ CallId: ringingCallId, Action: HeadsetCallAction.RejectCall });
                                    }
                                }
                                else if (/* headsetStatesDecoded.length === 2 && headsetStatesDecoded.includes(YealinkHeadsetState.HookSwitch) && */ headsetStatesDecoded.includes(YealinkHidHeadsetState.Flash)) {
                                    // ignore HookSwitch that comes afterwards
                                    this.skipConfirmation = Date.now();

                                    const ringingCallId = this.callStateHelper.ringingCalls.values().next()?.value;
                                    if (ringingCallId !== undefined) {
                                        return of(<HeadsetCallCommand>{ CallId: ringingCallId, Action: HeadsetCallAction.AcceptCall });
                                    }
                                    else {
                                        return of(<HeadsetCallCommand>{ CallId: -1, Action: HeadsetCallAction.FlashCall });
                                    }
                                }
                                return EMPTY;
                            }),
                            finalize(() => {
                                logHeadsetEvent(YealinkLogTitle, 'device closing:', device.productName);
                                device.close().catch(() => {
                                    // Ignore here
                                });
                            })
                        );
                }
                else {
                    return EMPTY;
                }
            }),
            tap(() => {
                this.keypadDigitsPressed = [];
            }),
            tap((command) => logHeadsetEvent(YealinkLogTitle, `Headset Command Fired ${HeadsetCallAction[command.Action]} (Value:${command.Value}) with CallId ${command.CallId}`))
        );

        this.diagnostics$ = this.device$.pipe(
            map((device) => {
                if (device) {
                    const deviceStatus = `${device.productName} ${device.opened ? 'connected' : 'not connected'}`;
                    return new HeadsetDiagnostics({ alertClass: 'success', text: deviceStatus });
                }
                else {
                    return HeadsetDiagnostics.error('No device is connected');
                }
            }),
            startWith(HeadsetDiagnostics.Progress),
        );
    }

    private async init(): Promise<HIDDevice | null> {
        const device = await getAvailableDevice(YealinkHIDDeviceFilters);
        return device ?? null;
    }

    private sendCommand(actions: YealinkHidHeadsetSendAction[], enableSkip = true): Observable<void> {
        let commandCombination = 0;
        for (const action of actions) {
            commandCombination |= action;
        }

        return this.device$.pipe(
            filter((device) => !!device),
            switchMap((device) => {
                if (!device) {
                    throw new Error('No device is connected:');
                }

                if ((commandCombination & YealinkHidHeadsetSendAction.OffHook) && enableSkip) {
                    // Skip confirmation from device for 2 seconds
                    this.skipConfirmation = Date.now();
                }

                const reportData: Uint8Array = constructYealinkReportData(commandCombination);
                // const reportId = deviceSupportsYealinkHID(device) ? 0x04 : 0x02;
                const reportId = extractYealinkReportId(device);
                logHeadsetEvent(YealinkLogTitle, `Send Report "${reportId}" with data: "${reportData}" To "${device.productName}" HID device`);
                return from(device.sendReport(reportId, reportData)).pipe(
                    timeout({
                        each: YealinkCommandTimeoutMs,
                        with: () => throwError(() => new Error(`${YealinkLogTitle} Yealink did not respond in the expected time`))
                    })
                );
            }),
            tap(() => logHeadsetEvent(YealinkLogTitle, `Send Actions to headset(Combined bitwise):\n ${actions.map((action) => YealinkHidHeadsetSendAction[action] + ':' + action).join(', ')}`)),
            take(1),
        );
    }

    private nextCommand(action: () => Observable<void>) {
        this.next = this.next.then(() => new Promise(resolve => {
            action().subscribe({
                error: (err: unknown) => {
                    logHeadsetWarn(YealinkLogTitle, err);
                    resolve();
                },
                complete: resolve
            });
        }));
    }

    holdCall(callId: number): void {
        logHeadsetEvent(YealinkLogTitle, `pbx call command: holdCall (${callId})`);
        this.nextCommand(() => {
            this.callStateHelper.holdCalls.add(callId);
            if (callId !== this.callStateHelper.activeCall) {
                // Only active call can go on hold
                // fixes race condition when resume is called before hold
                return EMPTY;
            }
            this.callStateHelper.activeCall = HeadsetCallState.NO_CALL;
            this.callStateHelper.isActiveCallMuted = false;
            return this.sendCommand([YealinkHidHeadsetSendAction.Hold, YealinkHidHeadsetSendAction.OnHook]);
        });
    }

    //     fixed issue 30622: Yealink Webclient & Desktop App
    // - On Hold/Resume introduce a delay between hold and resume to give time to headset to consume command.

    resumeCall(callId: number): void {
        logHeadsetEvent(YealinkLogTitle, `pbx call command: resumeCall (${callId})`);
        this.nextCommand(() => {
            this.callStateHelper.holdCalls.delete(callId);
            this.callStateHelper.activeCall = callId;
            return concat(
                ...this.callStateHelper.callCount > 1 ?
                    [this.sendCommand([YealinkHidHeadsetSendAction.Hold, YealinkHidHeadsetSendAction.OnHook]).pipe(delay(1000)),]
                    : [EMPTY],
                this.sendCommand([YealinkHidHeadsetSendAction.OffHook])
            );
        });
    }

    incomingCall(callId: number): void {
        logHeadsetEvent(YealinkLogTitle, `pbx call command: incomingCall (${callId})`);
        this.nextCommand(() => {
            const actions = [
                // if there is already an active call then set to offhook
                ...this.callStateHelper.activeCall !== HeadsetCallState.NO_CALL ?
                    [YealinkHidHeadsetSendAction.OffHook] :
                    [],
                YealinkHidHeadsetSendAction.Ring,
                ...this.callStateHelper.isActiveCallMuted ? [YealinkHidHeadsetSendAction.Mute] : []
            ];
            this.callStateHelper.ringingCalls.add(callId);
            this.callStateHelper.callCount += 1;

            return concat(
                this.sendCommand(actions, false)
            );
        });
    }

    incomingCallAccepted(callId: number): void {
        logHeadsetEvent(YealinkLogTitle, `pbx call command: incomingCallAccepted (${callId})`);
        this.nextCommand(() => {
            this.callStateHelper.activeCall = callId;
            this.callStateHelper.isActiveCallMuted = false;
            this.callStateHelper.ringingCalls.delete(callId);

            return concat(
                this.sendCommand([YealinkHidHeadsetSendAction.OffHook]).pipe(delay(1000)),
                ...this.callStateHelper.ringingCalls.size > 0 ?
                    [this.sendCommand([YealinkHidHeadsetSendAction.OffHook, YealinkHidHeadsetSendAction.Ring])]
                    : [EMPTY],
            );
        });
    }

    mute(callId: number, isMuted: boolean): void {
        logHeadsetEvent(YealinkLogTitle, `pbx call command mute (${callId}) with value ${isMuted}`);
        this.nextCommand(() => {
            this.callStateHelper.isActiveCallMuted = isMuted;
            const actions = [
                YealinkHidHeadsetSendAction.OffHook,
                ...isMuted ? [YealinkHidHeadsetSendAction.Mute] : []
            ];
            return this.sendCommand(actions, false);
        });
    }

    outgoingCall(callId: number): void {
        logHeadsetEvent(YealinkLogTitle, `pbx call command: outgoingCall (${callId})`);
        this.nextCommand(() => {
            this.callStateHelper.callCount += 1;
            this.callStateHelper.activeCall = callId;
            this.callStateHelper.isActiveCallMuted = false;
            return this.sendCommand([YealinkHidHeadsetSendAction.OffHook]);
        });
    }

    outgoingCallAccepted(callId: number): void {
        logHeadsetEvent(YealinkLogTitle, `pbx call command: outgoingCallAccepted (${callId})`);
        // Nothing needs to be done here
    }

    terminateCall(callId: number): void {
        logHeadsetEvent(YealinkLogTitle, `pbx call command: terminateCall (${callId})`);
        this.nextCommand(() => {
            this.callStateHelper.ringingCalls.delete(callId);
            this.callStateHelper.holdCalls.delete(callId);
            this.callStateHelper.callCount--;
            const terminateActiveCall = this.callStateHelper.activeCall === callId;
            // Reset all states
            if (terminateActiveCall) {
                this.callStateHelper.activeCall = HeadsetCallState.NO_CALL;
                this.callStateHelper.isActiveCallMuted = false;
            }

            // 1. No Calls OR
            // 2.There is no active call OR
            // 3. After termination of a call all calls are on hold
            const isOnHook = (this.callStateHelper.noCalls || this.callStateHelper.activeCall === HeadsetCallState.NO_CALL || this.callStateHelper.callCount === this.callStateHelper.holdCalls.size);
            const actions = this.callStateHelper.noCalls ? [YealinkHidHeadsetSendAction.OnHook] :
                [
                    ...this.callStateHelper.hasRingingCalls ? [YealinkHidHeadsetSendAction.Ring] : [],
                    ...this.callStateHelper.isActiveCallMuted ? [YealinkHidHeadsetSendAction.Mute] : [],
                    ...isOnHook ?
                        [YealinkHidHeadsetSendAction.OnHook] :
                        [YealinkHidHeadsetSendAction.OffHook],
                    ...(this.callStateHelper.hasHoldCalls) ?
                        [YealinkHidHeadsetSendAction.Hold] : []
                ];

            return this.sendCommand(actions);
        });
    }

    connectDevice(): void {
        defer(() => requestDevice({
            filters: YealinkHIDDeviceFilters,
        })).pipe(
            take(1)
        ).subscribe({
            next: (device) => {
                this.requestedDevice$.next(device);
            }
        });
    }
}
