import { map, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MyPhoneService } from '../myphone/myphone.service';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

import { MyPhoneSession } from '../myphone/myphone-session';
import { AppContact } from '../myphone/contact';
import { ContactAndUrls } from './meeting.service';
import {
    ActionType,
    CalendarServiceType,
    WebMeetingParticipant,
    WebMeetingParticipants,
    WebMeetingState
} from '@myphone';
import { localBridgeId } from '@webclient/myphone/app-contact-type';
import { dateFormat, instantMeetingName } from '@webclient/meeting/meeting-consts';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class InstantMeetingService {
    constructor(private myPhoneService: MyPhoneService, private translate: TranslateService) {
    }

    private meetingMessageCompose(otherGuyJoinLink: string, otherGuyInternalJoinLink: string): Observable<string> {
        if (!otherGuyInternalJoinLink || otherGuyJoinLink === otherGuyInternalJoinLink) {
            return this.translate.get('_i18n.JoinLink', {
                p0: otherGuyJoinLink
            });
        }
        else {
            return this.translate.get('_i18n.JoinLinkForEmployee', {
                p0: otherGuyJoinLink,
                p1: otherGuyInternalJoinLink
            });
        }
    }

    // Creation of instant meeting
    createWebmeetingWithContact(contact: AppContact): Observable<ContactAndUrls> {
        const participant = new WebMeetingParticipant();
        participant.Action = ActionType.Inserted;
        if (contact.bridgeId === localBridgeId) {
            participant.ExtensionNumber = contact.extensionNumber;
        }
        participant.Name = contact.firstNameLastName || contact.company;
        participant.Email = contact.emailAddress;

        const request = new WebMeetingState({
            Participants: new WebMeetingParticipants({
                Items: [participant]
            }),
            CalendarType: CalendarServiceType.Tcx,
            // TODO: check about this 1 minute
            StartAtUTC: dayjs().add(1, 'minute').utc().format(dateFormat),
            Name: instantMeetingName,
            Description: '',
            Duration: 15,
            HideParticipants: false,
            NeedOrganizer: false,
            CanChangeMedia: true,
            ParticipantsProperties: 'AVT'
        });

        return this.myPhoneService.myPhoneSession.pipe(take(1), switchMap((session: MyPhoneSession) => {
            return session.get<WebMeetingState>(request).pipe(switchMap((response: WebMeetingState) => {
                const otherGuy = response.Participants.Items.find(item => item.Email === contact.emailAddress);

                const buildJoinLink = (uri: string, p: WebMeetingParticipant) => new URL(`/meet/${p.Key}`, uri).href;

                const myJoinLink = buildJoinLink(session.domainUrl, response.Organizer);
                // TODO : if otherGuy is empty probably better to throw an error and handle it downstream
                const otherGuyJoinLink = otherGuy ? buildJoinLink(`https://${session.systemParameters.PbxExternalHost}`, otherGuy) : '';
                const otherGuyInternalJoinLink = otherGuy && session.systemParameters.PbxInternalHost ? buildJoinLink(`https://${session.systemParameters.PbxInternalHost}`, otherGuy) : '';

                return this.meetingMessageCompose(otherGuyJoinLink, otherGuyInternalJoinLink).pipe(
                    map(instantMeetingChatMessage => ({
                        instantMeetingChatMessage, contact, myJoinLink, otherGuyJoinLink, otherGuyInternalJoinLink
                    })
                    ));
            }));
        }));
    }
}
