import { AppContact } from '../../myphone/contact';
import { String } from '../string.utils';
import { AppContactType, localBridgeId } from '../../myphone/app-contact-type';
import { SearchResult } from './search-result';
import { SearchContext } from './search-context';
import { AnnotatedResult, AnnotatedResultType } from './anotated-result';

import { DnType } from '@myphone';
import { sanitizePhoneNumber } from '../../phone/phone-funcs';

type NumberProvider = (contact: AppContact, context: SearchContext) => AnnotatedResult;

function isVoicemailAllowed(p: AppContact, c: SearchContext) {
    if (p.bridgeId !== localBridgeId || c.vMailCode === undefined) {
        return false;
    }
    if (p.type === AppContactType.SystemExtension) {
        // For system extensions only queues and ring groups allowed
        return p.dnType === DnType.Queue || p.dnType === DnType.RingGroup;
    }
    return p.type === AppContactType.Extension;
}

export class ContactSearcher {
    private numberProviders: NumberProvider[];

    constructor() {
        this.numberProviders = [];

        // Search by extension number
        this.numberProviders.push((p: AppContact, c: SearchContext) =>
            new AnnotatedResult(AnnotatedResultType.internalNumber, p, p.phones.extension));

        // Search by voicemail
        this.numberProviders.push((p: AppContact, c: SearchContext) => {
            if (!c.vMailCode || !isVoicemailAllowed(p, c)) {
                return new AnnotatedResult(AnnotatedResultType.none, p);
            }

            const extNum = p.phones.extension;
            const vMail = c.vMailCode + p.phones.extension;
            return new AnnotatedResult(AnnotatedResultType.voicemail, p, vMail, extNum);
        });

        // Search in phones
        this.numberProviders.push((p: AppContact, c: SearchContext) =>
            new AnnotatedResult(AnnotatedResultType.externalNumber, p, c.useNativeMobile ? sanitizePhoneNumber(p.nativeMobileNumber) : p.sanitizedPhones.mobile, c.useNativeMobile ? p.nativeMobileNumber : p.phones.mobile));

        this.numberProviders.push((p: AppContact, c: SearchContext) =>
            new AnnotatedResult(AnnotatedResultType.externalNumber, p, p.sanitizedPhones.mobile2, p.phones.mobile2));

        this.numberProviders.push((p: AppContact, c: SearchContext) =>
            new AnnotatedResult(AnnotatedResultType.externalNumber, p, p.sanitizedPhones.home, p.phones.home));

        this.numberProviders.push((p: AppContact, c: SearchContext) =>
            new AnnotatedResult(AnnotatedResultType.externalNumber, p, p.sanitizedPhones.business, p.phones.business));

        this.numberProviders.push((p: AppContact, c: SearchContext) =>
            new AnnotatedResult(AnnotatedResultType.externalNumber, p, p.sanitizedPhones.business2, p.phones.business2));

        // this.numberProviders.push((p: AppContact, c: SearchContext) =>
        //     new AnnotatedResult(AnnotatedResultType.businessFax, p, p.sanitizedPhones.businessFax, p.phones.businessFax));

        // Search in email
        this.numberProviders.push((p: AppContact, c: SearchContext) =>
            new AnnotatedResult(AnnotatedResultType.email, p, p.emailAddress, p.emailAddress));
    }

    search(contacts: AppContact[], searchValue: string, context: SearchContext): SearchResult[] {
        const result: SearchResult[] = [];
        searchValue = searchValue ? searchValue.toLowerCase() : '';

        const phoneSearchValue = searchValue ? searchValue.replace(new RegExp('\\D', 'g'), '') : '';

        for (const contact of contacts) {
            const name = contact.firstNameLastName;
            const nameMetric = this.getMetricFromParts(searchValue, contact.firstNameLastName) + this.getMetricFromParts(searchValue, contact.lastNameFirstName);
            const emailMetric = this.getMetric(searchValue, contact.emailAddress);
            const companyMetric = this.getMetricFromParts(searchValue, contact.company);
            const contactMetric = Math.max(nameMetric, emailMetric, companyMetric);

            // ({x: Ann}): SearchResult
            // type x = {[id: AnnotatedResultType]: SearchResult};
            const set = new Set();

            this.numberProviders.forEach((provider, phoneOrder) => {
                let labeledNumber = provider(contact, context);

                // Custom converter
                if (context.converter) {
                    labeledNumber = context.converter(labeledNumber);
                }

                // None filter
                if (labeledNumber.type === AnnotatedResultType.none) {
                    return;
                }

                // Custom filter
                if (context.resultFilter && !context.resultFilter(labeledNumber)) {
                    return;
                }

                const text = labeledNumber.searchText;
                if (text !== undefined && !String.isNullOrEmpty(text)) {
                    let searchResultMetric: number;
                    if (contactMetric > 0) {
                        searchResultMetric = contactMetric;
                    }
                    else if (!String.isNullOrEmpty(phoneSearchValue)) {
                        searchResultMetric = this.getMetric(phoneSearchValue, text);
                    }
                    else {
                        searchResultMetric = 0;
                    }

                    if (searchResultMetric > 0) {
                        const key = labeledNumber.assignResult + labeledNumber.type;
                        if (set.has(key)) {
                            return;
                        }
                        set.add(key);

                        if (contact.isRegistered !== undefined) {
                            searchResultMetric += 0.3;

                            if (contact.bridgeId === localBridgeId) {
                                searchResultMetric += 0.4;
                            }
                        }

                        const isVoicemail = labeledNumber.type === AnnotatedResultType.voicemail;
                        const isEmail = labeledNumber.type === AnnotatedResultType.email;

                        const assignResult = labeledNumber.assignResult;
                        const searchResult = new SearchResult({
                            name,
                            number: assignResult,
                            contact,
                            isEmail,
                            isVoicemail,
                            phoneOrder
                        });
                        result.push(searchResult);
                    }
                }
            });
        }
        return result;
    }

    private getMetric(searchValue: string, val?: string): number {
        // Search value is not set means that anything matches
        if (!searchValue) {
            return 1;
        }
        if (val === undefined || String.isNullOrEmpty(val)) {
            return 0;
        }

        const index = val.toLowerCase().indexOf(searchValue);
        if (index < 0) {
            return 0;
        }
        else if (index > 0) {
            return 1;
        }
        else {
            return 2;
        }
    }

    private getMetricFromParts(searchValue: string, val?: string): number {
        // Search value is not set means that anything matches
        if (!searchValue) {
            return 1;
        }
        if (val === undefined || String.isNullOrEmpty(val)) {
            return 0;
        }

        const searchTerms = searchValue.split(' ');
        let searchTermMetric = 0;
        for (const term of searchTerms) {
            if (searchTermMetric > 0) {
                break;
            }
            const valNormalized = val.normalize('NFD').replace(/\p{Diacritic}/gu, '').toLowerCase();
            const termNormalized = term.normalize('NFD').replace(/\p{Diacritic}/gu, '');
            const index = valNormalized.indexOf(termNormalized);
            if (index < 0) {
                searchTermMetric = 0;
            }
            else if (index > 0) {
                searchTermMetric = 1;
            }
            else {
                searchTermMetric = 2;
            }
        }
        return searchTermMetric;
    }
}
