import { Injectable } from '@angular/core';
import {
    combineLatest, EMPTY, from, fromEvent, merge, NEVER, Observable, Subject
} from 'rxjs';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '../settings/local-storage-keys';
import {
    catchError, distinctUntilChanged, map, shareReplay, startWith, switchMap
} from 'rxjs/operators';
import { observe } from '@webclient/rx-utils';
import { chooseDevice } from '@webclient/webrtc/media-tools';

export interface SelectedMediaDevice{
    media: MediaDeviceInfo | undefined,
    labelId: string | undefined
}

export interface FullMediaTrack {
    audio: boolean | MediaTrackConstraints,
    video: boolean | MediaTrackConstraints
}

export interface SelectedConstraints{
    mic: string | undefined,
    cam: string | undefined
}

@Injectable()
export class DeviceMediaService {
    public readonly permissionsRequested$ = new Subject<boolean>();

    public readonly onDeviceChanged$: Observable<any>;
    public readonly deviceList$: Observable<MediaDeviceInfo[]>;
    public readonly audioDeviceList$: Observable<MediaDeviceInfo[]>;
    public readonly videoDeviceList$: Observable<MediaDeviceInfo[]>;
    public readonly microphoneDeviceList$: Observable<MediaDeviceInfo[]>;
    public readonly hasPermissions$: Observable<boolean>;

    // do not set sinkId to audio tag if device was not selected by user
    public readonly selectedInputAudioDevice$: Observable<SelectedMediaDevice>;
    public readonly selectedOutputAudioDevice$: Observable<SelectedMediaDevice>;
    public readonly selectedInputVideoDevice$: Observable<SelectedMediaDevice>;
    public readonly selectedRingerDevice$: Observable<SelectedMediaDevice>;
    public readonly mediaPeerConstraints$: Observable<SelectedConstraints>;

    public constructor(private localStorageService: LocalStorageService) {
        if (navigator.mediaDevices) {
            this.onDeviceChanged$ = merge(
                fromEvent<Event>(navigator.mediaDevices, 'devicechange').pipe(catchError((error: unknown) => NEVER)),
                this.permissionsRequested$
            );
            this.deviceList$ = this.onDeviceChanged$.pipe(
                startWith(true),
                switchMap(() => this.getDevices()),
                shareReplay({ refCount: false, bufferSize: 1 }),
                distinctUntilChanged((oDevices, nDevices) => {
                    const oDevNames = oDevices.map(x => x.label);
                    const nDevNames = nDevices.map(x => x.label);
                    const oldInNew = oDevNames.every((old) => nDevNames.includes(old));
                    const newInOld = nDevNames.every((old) => oDevNames.includes(old));
                    return oldInNew && newInOld;
                })
            );
        }
        else {
            this.onDeviceChanged$ = EMPTY;
            this.deviceList$ = EMPTY;
        }

        this.audioDeviceList$ = this.deviceList$.pipe(
            map(list => list.filter(this.checkDeviceInfo('audiooutput')))
        );
        this.videoDeviceList$ = this.deviceList$.pipe(
            map(list => list.filter(this.checkDeviceInfo('videoinput')))
        );
        this.microphoneDeviceList$ = this.deviceList$.pipe(
            map(list => list.filter(this.checkDeviceInfo('audioinput')))
        );

        this.selectedInputAudioDevice$ =
            combineLatest([
                this.getSelected(LocalStorageKeys.SelectedMicrophone),
                this.microphoneDeviceList$]).pipe(
                map(([selectedMicrophone, deviceList]) =>
                    chooseDevice(deviceList, 'audioinput', selectedMicrophone)
                ),
                shareReplay({ refCount: false, bufferSize: 1 }),
                distinctUntilChanged(DeviceMediaService.distSelectedDev)
            );

        this.selectedOutputAudioDevice$ =
            combineLatest([
                this.getSelected(LocalStorageKeys.SelectedSpeaker),
                this.audioDeviceList$]).pipe(
                map(([selectedSpeaker, deviceList]) => chooseDevice(deviceList, 'audiooutput', selectedSpeaker)),
                shareReplay({ refCount: false, bufferSize: 1 }),
                distinctUntilChanged(DeviceMediaService.distSelectedDev)
            );

        this.selectedInputVideoDevice$ =
            combineLatest([
                this.getSelected(LocalStorageKeys.SelectedCamera),
                this.videoDeviceList$]).pipe(
                map(([selectedCamera, deviceList]) =>
                    chooseDevice(deviceList, 'videoinput', selectedCamera)
                ),
                shareReplay({ refCount: false, bufferSize: 1 }),
                distinctUntilChanged(DeviceMediaService.distSelectedDev)
            );

        this.selectedRingerDevice$ = combineLatest([
            this.getSelected(LocalStorageKeys.SelectedRinger),
            this.audioDeviceList$]).pipe(
            map(([selectedRinger, deviceList]) =>
                chooseDevice(deviceList, 'audiooutput', selectedRinger)
            ),
            shareReplay({ refCount: false, bufferSize: 1 }),
            distinctUntilChanged(DeviceMediaService.distSelectedDev)
        );

        this.mediaPeerConstraints$ = combineLatest([this.selectedInputVideoDevice$, this.selectedInputAudioDevice$]).pipe(
            switchMap(([video, audio]) => from(navigator.mediaDevices.enumerateDevices()).pipe(map(devices => {
                const cam = chooseDevice(devices, 'videoinput', video.labelId ?? null).media?.deviceId;
                const mic = chooseDevice(devices, 'audioinput', audio.labelId ?? null).media?.deviceId;
                return { mic, cam };
            })))
        );
    }

    public save(key:string, deviceLabel: string) {
        this.localStorageService.store(key, deviceLabel);
    }

    public requestPermissions(): void {
        if (navigator.mediaDevices) {
            from(navigator.mediaDevices.getUserMedia({ audio: true, video: true })).pipe(
                // Retry with no video if webcam is not available at the moment
                catchError((err: unknown) => {
                    console.log(err);
                    return from(navigator.mediaDevices.getUserMedia({ audio: true, video: false }));
                }),
            ).subscribe({
                next: (stream) => {
                    if (stream.active) {
                        stream.getTracks().forEach(track => track.stop());
                    }
                    this.permissionsRequested$.next(true);
                },
                error: (error: unknown) => {
                    console.log('PERMISSIONS WAS NOT GRANTED', error);
                }
            });
        }
    }

    private getDevices(): Observable<MediaDeviceInfo[]> {
        return from(navigator.mediaDevices.enumerateDevices()).pipe(catchError((error: unknown) => NEVER));
    }

    private getSelected(mediaType: string) {
        return observe<string|null>(this.localStorageService, mediaType).pipe(
            distinctUntilChanged()
        );
    }

    private static distSelectedDev(o: SelectedMediaDevice, n: SelectedMediaDevice) {
        return Boolean(n.media && o.media && o.media.label === n.media.label);
    }

    private checkDeviceInfo(groupType: MediaDeviceKind): (mdi: MediaDeviceInfo) => boolean {
        return (mdi):boolean => mdi.kind === groupType && Boolean(mdi.label);
    }
}
