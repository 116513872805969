import {
    ChangeDetectorRef,
    Component, HostBinding,
    Injector,
    Input,
    OnChanges,
    OnInit, Optional,
    Self,
    SimpleChanges, SkipSelf,
    ViewChild
} from '@angular/core';
import { FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import type { AbstractControl, ValidationErrors, Validator } from '@angular/forms';
import { CallPromptsService } from './call-prompts.service';
import { DestroyService } from '@webclient/services/destroy.service';
import { FieldValueAccessor, getComponentControl, setControlEnabled } from '@webclient/fields';
import { Focusable, FOCUSABLE } from '@webclient/standalones/directives/autofocus/focusable';
import { PromptSelectorComponent, PromptService, PROMPT_SERVICE } from '@webclient/standalones/prompt-selector';
import type { PromptMatchedProperty } from '@webclient/standalones/prompt-selector';
import { BehaviorSubject, Observable, takeUntil, combineLatest } from 'rxjs';
import { ValdemortModule } from 'ngx-valdemort';
import { ModalService } from '@webclient/modal/app-modal.service';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import type { PbxCustomPrompt } from '@xapi';
import { map } from 'rxjs/operators';

// component is a proxy value accessor, providing instance-bound prompt service, which depends on control's params
@Component({
    selector: 'app-call-prompts',
    template: `
        <app-prompt-selector
            data-qa="call-prompts"
            class="mb-0"
            [label]="label"
            [isCstaRecordingAllowed]="false"
            [inlineLabel]="inlineLabel"
            [maxPrompts]="maxPrompts$ | async"
            [clearable]="clearable"
            [iconButtons]="iconButtons"
            [restrictedAccess]="restrictedAccess"
            [formControl]="formControl"
            [matchedProperty]="matchedProperty"
            [description]="showFileHint ? '_i18n.FileFormatAllowed' : ''">
        </app-prompt-selector>
    `,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: CallPromptsComponent },
        { provide: NG_VALIDATORS, multi: true, useExisting: CallPromptsComponent },
        { provide: FOCUSABLE, useExisting: CallPromptsComponent, multi: false },
        { provide: PROMPT_SERVICE, useExisting: CallPromptsComponent, multi: false },
        CallPromptsService,
        DestroyService
    ],
    standalone: true,
    imports: [CommonModule, PromptSelectorComponent, ReactiveFormsModule, TranslateModule, ValdemortModule]
})

export class CallPromptsComponent extends FieldValueAccessor<string> implements OnInit, Validator, OnChanges, Focusable, PromptService {
    @Input() label?: string;
    @Input() inlineLabel?: boolean;

    @Input() clearable?: boolean;

    @Input() iconButtons: boolean = true;

    @Input() allowPlaylists?: boolean;

    @Input() restrictedAccess?: boolean | null;

    @Input() showFileHint?: boolean;

    @Input() matchedProperty: PromptMatchedProperty = 'Filename';

    @ViewChild(PromptSelectorComponent, { static: true }) promptSelector: PromptSelectorComponent;

    @HostBinding('class') readonly containerClass = 'form-input w-100';

    readonly formControl = new FormControl<string>('');

    private readonly allowPlaylists$ = new BehaviorSubject<boolean>(false);
    private readonly service: CallPromptsService;

    readonly maxPrompts$: Observable<number>;
    readonly availablePrompts$: Observable<PbxCustomPrompt[]>;

    constructor(
        @Self() private destroy$: DestroyService,
        private injector: Injector,
        private modalService: ModalService,
        cd: ChangeDetectorRef,
        // own service is used only if there is no parent service
        @Self() ownCallPromptsService: CallPromptsService,
        // parent service is provided by a page, which contains more than one app-call-prompts and wants to keep them in sync
        @Optional() @SkipSelf() parentCallPromptsService?: CallPromptsService,
    ) {
        super(cd);
        this.service = parentCallPromptsService ?? ownCallPromptsService;
        this.maxPrompts$ = this.service.maxPrompts$;
        this.availablePrompts$ = combineLatest([this.service.availablePrompts$, this.allowPlaylists$]).pipe(
            map(([prompts, allowPlaylists]) => (allowPlaylists ? prompts : prompts.filter(p => p.PromptType !== 'Playlist')))
        );
    }

    ngOnInit(): void {
        const control = getComponentControl(this.injector);

        if (control?.hasValidator(Validators.required)) {
            this.formControl.addValidators(Validators.required);
        }
        this.formControl.valueChanges
            .pipe(takeUntil(this.destroy$))
            // use force, Luke, to make this control revalidate properly (issues 36241, 36294, 36057)
            .subscribe(value => this.valueChanged(value ?? '', Boolean(value)));
    }

    writeValue(value: string) {
        super.writeValue(value ?? '');
        this.formControl.setValue(this.value, { emitEvent: false });
    }

    setDisabledState(disabled: boolean) {
        super.setDisabledState(disabled);
        setControlEnabled(this.formControl, !this.disabled);
    }

    markAsTouched(blur: boolean = false) {
        super.markAsTouched(blur);
        this.formControl.markAsTouched();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.allowPlaylists) {
            this.allowPlaylists$.next(this.allowPlaylists ?? false);
        }
    }

    focus() {
        this.promptSelector.focus();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.formControl.errors;
    }

    deletePrompt$(filename: string): Observable<void> {
        return this.service.deletePrompt$(filename);
    }

    uploadPrompt$(file: File): Observable<void> {
        return this.service.uploadPrompt$(file);
    }
}
