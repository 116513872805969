import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { GeneralSettings } from './settings/general-settings';
import { LocalStorageKeys } from './settings/local-storage-keys';
import { sanitizePhoneNumber } from './phone/phone-funcs';
import { Observable } from 'rxjs';
import {
    map, take
} from 'rxjs/operators';
import { observe } from '@webclient/rx-utils';
import { publishRef } from '@webclient/rx-share-utils';
import {
    CrmVariableValue
} from '@webclient/settings/integrations/crm-integration-interface';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {
    public readonly generalSettings$: Observable<GeneralSettings>;

    constructor(private localStorage: LocalStorageService) {
        this.generalSettings$ = observe<GeneralSettings | null>(localStorage, LocalStorageKeys.GeneralSettings)
            .pipe(
                map(values => new GeneralSettings(values)), publishRef()
            );
    }

    public preprocessPhoneNumber(phoneNumber: string) {
        return this.generalSettings$.pipe(take(1), map(settings => {
            if (!phoneNumber) {
                return phoneNumber;
            }
            phoneNumber = sanitizePhoneNumber(phoneNumber);
            return settings.expandPhoneBookPlusEnabled ? phoneNumber.replace('+', settings.expandPhoneBookPlus) : phoneNumber;
        }));
    }

    public setField<T extends number | string | boolean | CrmVariableValue[]>(fieldName: keyof GeneralSettings, value: T) {
        this.generalSettings$.pipe(take(1))
            .subscribe((status: any) => {
                status[fieldName] = value;
                this.localStorage.store(LocalStorageKeys.GeneralSettings, status);
            });
    }
}
