<field-wrapper [formGroup]="form" [label]="label">
    <div class="row">
        <div class="col-6">
        <ng-select formControlName="type" data-qa="type-select" [searchable]="false" [clearable]="false"
                   [placeholder]="isIndeterminate(f.type.value) ? (indeterminatePlaceholder | translate) : ''">
            <ng-option *ngFor="let value of destinationTypes" [value]="value">{{value | appForwardDestinationType | translate}}</ng-option>
        </ng-select>
        </div>
        <div class="col-6" [ngSwitch]="form.value.type">
            <ng-container *ngSwitchCase="ForwardDestinationViewType.Extension">
                <app-dn-select formControlName="extensionContact" [searchDnTypes]="searchContactExtensions"
                               [nullIsIndeterminate]="indeterminate" [indeterminatePlaceholder]="indeterminatePlaceholder"
                               data-qa="extension-contact" [isForwarding]="true">
                </app-dn-select>

                <ng-container *ngTemplateOutlet="voicemail"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="ForwardDestinationViewType.SystemExtension">
                <app-dn-select formControlName="systemContact" [searchDnTypes]="searchSystemExtensions"
                               [nullIsIndeterminate]="indeterminate" [indeterminatePlaceholder]="indeterminatePlaceholder"
                               data-qa="system-contact" [isForwarding]="true">
                </app-dn-select>
                <ng-container *ngIf="isSystemContactWithVoicemail">
                    <ng-container *ngTemplateOutlet="voicemail"></ng-container>
                </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="ForwardDestinationViewType.External">
                <field-input
                    maxlength="50"
                    formControlName="number"
                    placeholder="_i18n.ExternalNum"
                    [nullIsIndeterminate]="indeterminate"
                    [indeterminatePlaceholder]="indeterminatePlaceholder"
                    data-qa="external-number">
                    <val-errors controlName="number">
                        <ng-template valError="invalidDirectNumber">{{'_i18n.InvalidDirectNumber' | translate}}</ng-template>
                        <ng-template valError="required"><!-- do not show just like for other fields here --></ng-template>
                    </val-errors>
                </field-input>
                <ng-container *ngTemplateOutlet="myMobileOrExternal"></ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="ForwardDestinationViewType.MyMobile">
                <ng-container *ngTemplateOutlet="myMobileOrExternal"></ng-container>
            </ng-container>

            <ng-template #myMobileOrExternal>
                <field-checkbox
                    data-qa="checkbox-rebound"
                    formControlName="rebound"
                    [nullIsIndeterminate]="indeterminate"
                    label="_i18n.Rebound">
                </field-checkbox>
                <field-checkbox
                    *ngIf="enableUse302"
                    data-qa="checkbox-use302"
                    formControlName="use302"
                    [nullIsIndeterminate]="indeterminate"
                    label="_i18n.Use302">
                </field-checkbox>
            </ng-template>

            <ng-template #voicemail>
                <field-checkbox
                    data-qa="checkbox-voicemail"
                    formControlName="voicemail"
                    [nullIsIndeterminate]="indeterminate"
                    label="_i18n.VoiceMail">
                </field-checkbox>
            </ng-template>

            <ng-content select="field-checkbox"></ng-content>
        </div>
    </div>
    <val-errors controlName="type" data-qa="type-validation">
        <ng-template valError="required"><!-- do not show just like for other fields here --></ng-template>
        <ng-template valError="myMobileIsForwardedToAndEmpty">{{'_i18n.ForwardingToEmptyMyMobile' | translate}}</ng-template>
        <ng-template valError="myMobileIsForwardedToAndInvalid">{{'_i18n.ForwardingToInvalidMyMobile' | translate}}</ng-template>
    </val-errors>
</field-wrapper>
