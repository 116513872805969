import { PhonebookEditorService } from './phonebook-editor.service';
import { SharedModule } from '../shared/shared.module';
import { PhonebookContactEditorComponent } from './phonebook-contact-editor.component';
import { NgModule } from '@angular/core';
import { SmsModule } from '@webclient/sms/sms.module';
import { ProfileImageModule } from '@webclient/profile-image/profile-image.module';
import { FieldsModule } from '@webclient/fields/fields.module';
import { AppContactImagePipe } from '@webclient/standalones/pipes/contact-image.pipe';

@NgModule({
    declarations: [
        // Components / Directives/ Pipes
        PhonebookContactEditorComponent,
    ],
    imports: [
        ProfileImageModule,
        SmsModule,
        SharedModule,
        FieldsModule,
        AppContactImagePipe
    ],
    exports: [
        PhonebookContactEditorComponent
    ],
    providers: [
        PhonebookEditorService
    ]
})
export class PhonebookContactEditorModule {
}
