import {
    ChatPartyInfo, ChatRecipient, ChatRecipientEx, ChatRecipientType, ContactType
} from '@myphone';
import { AppContact, defaultContact } from '@webclient/myphone/contact';
import { AppContactType, localBridgeId } from '@webclient/myphone/app-contact-type';
import { MyPhoneSession } from '@webclient/myphone/myphone-session';
import { Record } from 'immutable';
import { createChatProvider, defaultChatProvider } from '@webclient/chat/chat-provider';
import { userImage } from '../assets';
import { UtilsService } from '@webclient/shared/utils.service';

function isExternalRecipient(type: ChatRecipientType) {
    return type === ChatRecipientType.CRT_External || type === ChatRecipientType.CRT_Anonymous;
}

function isProviderRecipient(type: ChatRecipientType) {
    return type === ChatRecipientType.CRT_External;
}

export class ChatParticipant extends Record({
    id: 0,
    contact: defaultContact,
    isAgent: false,
    phoneNumber: '',
    isWhisperer: false,
    isModerator: false,
    isMe: false,
    ipAddress: '',
    canAddUsers: false,
    canBeRemoved: false,
    anonymousSessionId: '',
    gid: '',
    provider: defaultChatProvider,
    type: ChatRecipientType.CRT_Local
}) {
    public get isExternal() {
        return isExternalRecipient(this.type);
    }

    public get isProvider() {
        return isProviderRecipient(this.type);
    }
}

function recipientBridgeId(recipient: ChatRecipient) {
    switch (recipient.RecipientType) {
        case ChatRecipientType.CRT_Local: return localBridgeId;
        default: return recipient.BridgeNumber;
    }
}

function recipientTypeContactMapper(recipient: ChatRecipient): AppContactType {
    switch (recipient.RecipientType) {
        case ChatRecipientType.CRT_3cxBridge: return AppContactType.Extension;
        case ChatRecipientType.CRT_Anonymous: return AppContactType.External;
        case ChatRecipientType.CRT_External: return AppContactType.External;
        case ChatRecipientType.CRT_System: return AppContactType.SystemExtension;
        // ChatRecipientType.CRT_Local expected here
        default: return AppContactType.Extension;
    }
}

export function createContactFromRecipient(session: MyPhoneSession, recipient: ChatRecipient) {
    if (recipient.Contact) {
        // Contact provided
        // Show live presence only for non-external participants
        if (!isExternalRecipient(recipient.RecipientType) &&
            (recipient.Contact.ContactType === ContactType.LocalUser || recipient.Contact.ContactType === ContactType.BridgeExtension)) {
            // Create from local data
            return session.createMergedContact(recipient.Contact);
        }
        else {
            // Create from provided data
            return AppContact.create(recipient.Contact);
        }
    }
    else {
        let extension: string|undefined;
        let name = recipient.Name;
        let mobile: string|undefined = recipient.PhoneNumber;

        switch (recipient.RecipientType) {
            case ChatRecipientType.CRT_3cxBridge:
            case ChatRecipientType.CRT_Local:
            case ChatRecipientType.CRT_System:
                extension = recipient.ExtNumber;
                break;
            case ChatRecipientType.CRT_External:
                if (!name && !mobile) {
                    if (UtilsService.isValidPhoneNumber(recipient.ExtNumber)) {
                        mobile = recipient.ExtNumber;
                    }
                    else {
                        name = recipient.ExtNumber;
                    }
                }
                break;
            case ChatRecipientType.CRT_Anonymous:
                if (!name) {
                    // Choose email if no name specified
                    if (recipient.Email) {
                        name = recipient.Email;
                    }
                    // Choose mobile if no name or email specified
                    else if (mobile) {
                        name = mobile;
                    }
                    // Show default name
                    else {
                        name = 'WebVisitor';
                    }
                }
                break;
        }
        // Contact not provided
        return new AppContact(
            '',
            recipientTypeContactMapper(recipient),
            recipientBridgeId(recipient),
            {
                profilePicture: userImage,
                lastNameFirstName: name,
                firstNameLastName: name,
                emailAddress: recipient.Email,
                lastName: '',
                firstName: name,
                nativeExtensionNumber: extension,
                nativeMobileNumber: mobile,
                phones: {
                    mobile,
                    extension
                }
            }
        );
    }
}

export function createChatParticipant(chatInfo: ChatPartyInfo, recipientEx: ChatRecipientEx, session: MyPhoneSession) {
    const recipient = recipientEx.Recipient;

    const contact = createContactFromRecipient(session, recipient);
    if (isExternalRecipient(recipient.RecipientType)) {
        const isExternalChatActive = chatInfo.IsExternal && chatInfo.CloseReason === undefined;
        contact.setContactProfile(isExternalChatActive);
    }

    return new ChatParticipant({
        contact,
        canAddUsers: recipientEx.CanAddUsers,
        canBeRemoved: recipientEx.CanBeDeleted,
        ipAddress: recipient.IpAddress,
        phoneNumber: recipient.PhoneNumber,
        anonymousSessionId: recipient.RecipientType === ChatRecipientType.CRT_Anonymous ? recipient.ExtNumber : '',
        gid: recipient.MemberGid,
        id: recipient.IdRecipient,
        isAgent: !recipientEx.IsRemoved,
        isWhisperer: recipientEx.IsWhisperer,
        isModerator: recipient.IsModerator,
        provider: createChatProvider(recipient.BridgeNumber, recipient.UserData),
        type: recipient.RecipientType,
        isMe: !recipient.BridgeNumber && contact.nativeExtensionNumber === session.myInfo.Number && contact.bridgeId === localBridgeId
    });
}
