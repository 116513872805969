import { Component } from '@angular/core';
import { ModalService } from '@webclient/modal/app-modal.service';
import { PromoteAppDialogComponent } from '@webclient/promoted-apps/promote-app-dialog.component';

@Component({
    selector: 'app-install-mobile-app-button',
    template: `
        <a class="header-button themify" id="menuOpenSmartphoneModal" (click)="openSmartphoneModal()"  title="{{'_i18n.Get3cxApp' | translate}}" role="button">
            <i class="customSVGIcons svg-sm" app-qr-custom-icon> </i>
        </a>
    `
})
export class InstallMobileAppButtonComponent {
    constructor(private modalService: ModalService) {
    }

    openSmartphoneModal() {
        this.modalService.showComponent(PromoteAppDialogComponent);
    }
}
