<div class='call-list'>
    <div *ngFor="let myCall of dialerService.calls$|async;" class="call-item">
        <div class="caller-info-container" >
            <app-avatar [ext]='myCall.contact'></app-avatar>
            <div class='caller-info'>
                <ul class='caller-name' *ngIf="CallerName(myCall); let callerName" >
                    <li *ngFor="let part of callerName.split(':')" [title]="part" class="text-truncate">
                        {{part}}
                    </li>
                </ul>
                <div class='caller-number'>
                    <span class='caller-message text-truncate' [title]='CallerNumber(myCall)'>
                        {{CallerNumber(myCall)}}
                    </span>
                </div>
            </div>
        </div>

        <div class='call-actions'>
            <button id="btnAnswer" class="call-action" (click)="AnswerCall(myCall)" [disabled]="!myCall.isAnswerEnabled || myCall.isSendingWebrtcRequest" type="button">
                <span class="svg-wrapper" app-phone-circle-green-background-custom-icon></span>
                <span class='call-action-text text-truncate' title="{{ '_i18n.Answer' | translate}}">
                    {{ '_i18n.Answer' | translate}}
                </span>
            </button>
            <button id="btnDecline" class="call-action" (click)="DropCall(myCall)" [disabled]="myCall.isTrying" type="button">
                <span class="svg-wrapper" app-phone-circle-red-background-custom-icon></span>
                <span class='call-action-text text-truncate' title="{{ '_i18n.Decline' | translate}}">
                    {{ '_i18n.Decline' | translate}}
                </span>
            </button>
            <button id="btnDivertToVoicemail" class="call-action" (click)="DivertToVoiceMail(myCall)" type="button">
                <span class="svg-wrapper fillIconWhite" app-envelope-light-icon></span>
                <span class='call-action-text' title="{{ '_i18n.SendToVmail' | translate}}">
                    {{ '_i18n.SendToVmail' | translate}}
                </span>
            </button>
        </div>
    </div>
</div>
