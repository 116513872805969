import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PushChatMessage } from '@webclient/notifications/push-chat-message';
import { processChatMessage } from '../chat/chat-service/escape-chat-message';

@Injectable()
export class ChatToastService {
    private readonly chatsSubject$: Subject<PushChatMessage> = new Subject<PushChatMessage>();
    public readonly chats$ = this.chatsSubject$.asObservable();

    private readonly removeChatSubject$: Subject<number> = new Subject<number>();
    public readonly removeChat$ = this.removeChatSubject$.asObservable();

    public pop(message: PushChatMessage) {
        this.chatsSubject$.next(message.merge({
            text: processChatMessage(message.text, false)
        }));
    }

    public removeChat(conversationId: number) {
        this.removeChatSubject$.next(conversationId);
    }
}
