import {
    ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import {
    BehaviorSubject, combineLatest, Observable, of, switchMap, timer
} from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'snackbar',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div id="snackbar" [ngClass]="{'show' : isVisible}">
            <ng-container *ngIf="reconnectTime === 0">
                <span [translate]="'_i18n.Reconnecting'">Reconnecting</span>
            </ng-container>
            <ng-container *ngIf="reconnectTime > 0">
                <span>{{text$|async}}</span>
                <a class="ms-1 dashed_link" [translate]="'_i18n.TryNow'" *ngIf="reconnectTime > 0"
                   (click)="retryNow.emit()">Try Now</a>
            </ng-container>
        </div>
    `,
    styles: [`
/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    background-color: var(--bg-primary-hover); /* Black background color */
    color: var(--text-primary); /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 1200; /* Add a z-index if needed */
    left: 0; /* Center the snackbar */
    transform: translate(calc(50vw - 50%));
    bottom: 20px; /* 20px from the bottom */
}

/* Show the snackbar when clicking on a button (class added with JavaScript) */
#snackbar.show {
    visibility: visible; /* Show the snackbar */

/* Add animation: Take 0.5 seconds to fade in and out the snackbar.
However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s;
    animation: fadein 0.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}

@keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
}
`]
})
export class SnackbarComponent implements OnChanges {
    @Input()
    public isVisible : boolean;

    @Input()
    public reconnectTime: number;

    @Output()
    public retryNow = new EventEmitter();

    readonly text$: Observable<string>;

    private readonly isVisible$ = new BehaviorSubject<boolean>(false);
    private readonly reconnectTime$ = new BehaviorSubject<number>(0);

    constructor(private translateService: TranslateService) {
        this.text$ = combineLatest([this.isVisible$, this.reconnectTime$]).pipe(
            switchMap(([isVisible, reconnectTime]) =>
                ((isVisible) ? timer(0, 1000).pipe(
                    switchMap(val =>
                        this.translateService.get('_i18n.ReconnectTime', {
                            sec: Math.floor(Math.max(reconnectTime - Date.now(), 0) / 1000)
                        })
                    )
                ) : of(''))
            )
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isVisible) {
            this.isVisible$.next(this.isVisible);
        }
        if (changes.reconnectTime) {
            this.reconnectTime$.next(this.reconnectTime);
        }
    }
}
