import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, Input
} from '@angular/core';
import { TimeService } from '../time.service';
import dayjs from 'dayjs';

import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
    selector: 'duration',
    template: '{{duration|async}}',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DurationComponent {
    @Input()
    public startedAt?: number;

    public readonly duration: Observable<string>;

    constructor(private cd: ChangeDetectorRef, timeService: TimeService) {
        this.duration = timeService.durationTimer$.pipe(startWith(Date.now()), map(now => {
            if (this.startedAt === undefined) {
                return '';
            }

            const span = Math.abs(now - this.startedAt) / 1000;
            const duration = dayjs().startOf('day').second(span);
            return duration.hour() === 0 ? duration.format('mm:ss') : duration.format('HH:mm:ss');
        }), startWith(''));
    }
}
