import { userTransparentImage as userImagePngUrl } from '@webclient/assets';
import {
    from as observableFrom, merge, Observable, of
} from 'rxjs';
import { ChatMessage, translateSystemMessages } from '../chat/chat-service/chat-message';
import { MyPhoneService } from '../myphone/myphone.service';
import { Injectable } from '@angular/core';
import {
    catchError, distinctUntilChanged, filter, map, mergeScan, switchMap, withLatestFrom
} from 'rxjs/operators';
import { Chat } from '@webclient/chat/chat';
import { PushChatMessage } from '@webclient/notifications/push-chat-message';
import { RequestCreateConversation, ResponseConversationInfo } from '@myphone';
import { getNotificationImage } from '@webclient/notifications/notification.service';
import { UpdateChatInfo } from '@webclient/chat/chat-service/update-chat-info';
import { ChatRemoved } from '@webclient/chat/chat-service/chat-removed';
import { TranslateService } from '@ngx-translate/core';
import { chatParticipantId } from '@webclient/chat-shared/chat-title/chat-title.pipe';

@Injectable()
export class ChatUnhandledMessageService {
    private chatSubscribers: Set<number> = new Set<number>();
    public isChatOnPbxEnabled$: Observable<boolean>;

    private latestMessagePerConversation(messages: ChatMessage[]): ChatMessage[] {
        const uniqueConversations = messages.map(x => x.IdConversation)
            .filter((value, index, self) => self.indexOf(value) === index);
        return uniqueConversations.map(conversationId => {
            const chatMessages = messages.filter(msg => msg.IdConversation === conversationId);
            return chatMessages.reduce((max, p) => (p.Id > max.Id ? p : max), chatMessages[0]);
        });
    }

    constructor(private myPhoneSvc: MyPhoneService, private translateService: TranslateService) {
        this.isChatOnPbxEnabled$ = this.myPhoneSvc.myPhoneSession.pipe(switchMap(session => session.chatIsAllowed$));
    }

    public getUnhandledMessages(): Observable<PushChatMessage> {
        return this.myPhoneSvc.myPhoneSession.pipe(switchMap(session => {
            const messageNotification = session.newChatMessages$.pipe(switchMap((messages: ChatMessage[]) => observableFrom(this.latestMessagePerConversation(messages))),
                filter((message: ChatMessage) =>
                    document.hidden || !this.chatSubscribers.has(message.IdConversation)
                ));
            const activeChats = merge(
                messageNotification, // add if it is needed
                session.conversationInfo$,
                session.conversationRemoved$ // remove
            ).pipe(
                mergeScan((accumulator, event) => {
                    if (event instanceof ChatMessage) {
                        if (accumulator.chats.find(x => x.id === event.IdConversation)) {
                            return of({
                                chats: accumulator.chats,
                                chatMessage: event
                            });
                        }
                        else {
                            return session.get<ResponseConversationInfo>(
                                new RequestCreateConversation({ IdConversation: event.IdConversation })
                            ).pipe(
                                map((response: ResponseConversationInfo) => Chat.FromPartyInfo(response.Conversation, session)),
                                map(chat => {
                                    return {
                                        chats: accumulator.chats.concat(chat),
                                        chatMessage: event
                                    };
                                }),
                                catchError((error: unknown) => {
                                    // Can get here if chat is no longer available
                                    console.warn(error);
                                    return of({
                                        chats: accumulator.chats,
                                        chatMessage: undefined
                                    });
                                })
                            );
                        }
                    }
                    else if (event instanceof ResponseConversationInfo) {
                        return new UpdateChatInfo(event.IsNew, event.Conversation, session).updateChatList(accumulator.chats).pipe(
                            map(chats => ({
                                chats,
                                chatMessage: undefined
                            }))
                        );
                    }
                    else /* if (event instanceof NotificationConversationRemoved) */ {
                        return new ChatRemoved(event.IdConversation).updateChatList(accumulator.chats).pipe(
                            map(chats => ({
                                chats,
                                chatMessage: undefined
                            }))
                        );
                    }
                }, { chats: [], chatMessage: undefined } as {chats: Chat[], chatMessage: ChatMessage | undefined}, 1)
            );

            return activeChats.pipe(
                filter(chatMessageObject => {
                    const chats = chatMessageObject.chats;
                    const message = chatMessageObject.chatMessage;
                    if (message) {
                        return !chats.find(chat => chat.id === message.IdConversation)?.getParticipant(message.Sender)?.isMe;
                    }
                    else {
                        return false;
                    }
                }),
                withLatestFrom(session.systemParameters$),
                switchMap(([chatMessageObject, parameters]) => {
                    const chats = chatMessageObject.chats;
                    const message = chatMessageObject.chatMessage!;
                    const currentChat = chats.find(x => x.id === message.IdConversation);
                    // session closed by web visitor, MyPhone removed visitor from the participants list, sender eq -1
                    const sender = message.Sender === -1 ? currentChat?.participants?.externalParticipant : currentChat?.getParticipant(message.Sender);
                    const name = sender?.contact ? chatParticipantId(parameters, sender.contact) : '';
                    const img = session.domainUrl ? getNotificationImage(sender?.contact?.profilePicture, session.domainUrl) : userImagePngUrl;
                    const onTheSamePage = this.chatSubscribers.has(message.IdConversation);
                    return translateSystemMessages(this.translateService, message, message.UnsafeMessage, false).pipe(
                        map(translatedMessage => new PushChatMessage({
                            id: message.Id,
                            eventTime: message.Sended.getTime(),
                            text: translatedMessage,
                            sender: name ?? '',
                            conversationId: message.IdConversation,
                            avatar: img,
                            participant: sender,
                            onChatPage: onTheSamePage
                        }))
                    );
                }),
                distinctUntilChanged((x, y) => x.id === y.id)
            );
        }));
    }

    public subscribeChat(id: number) {
        this.chatSubscribers.add(id);
    }

    public unsubscribeChat(id: number) {
        this.chatSubscribers.delete(id);
    }
}
