import { Component } from '@angular/core';

@Component({
    selector: 'no-content',
    template: `
        <standalone-view>
            <div class="text-center">
                <h2 class="mb-4 themeColor" [translate]="'_i18n.PageMissing'">404: Page Not Found </h2>
                <a routerLink="/people" class="dashed_link" [translate]="'_i18n.Home'">Home</a>
            </div>
        </standalone-view>
  `,
    styles: ['.themeColor {color: var(--text-secondary);}']
})
export class NoContentComponent {
    constructor() {

    }
}
