import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, OnDestroy, Self, ViewChild } from '@angular/core';
import type { RecordedAudioOutput } from './types';
import { RecordingService } from './recording.service';
import { BehaviorSubject, from, of, switchMap, takeUntil } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { DestroyService } from '@webclient/services/destroy.service';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog/modal-dialog.component';
import { DialogComponent } from '@webclient/modal/dialog';
import { extractErrorMessage } from '@webclient/modal/app-modal.service';
import microphoneIcon from 'img/icons/font-awesome/solid/microphone-alt.svg';
import { FieldsModule } from '@webclient/fields/fields.module';
import { CommonModule } from '@angular/common';
import { ValdemortModule } from 'ngx-valdemort';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { AudioPlayerComponent, AudioPlayerAction } from '../audio-player/audio-player.component';

@Component({
    selector: 'app-prompt-recorder',
    templateUrl: './prompt-recorder.component.html',
    styleUrls: ['./prompt-recorder.component.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [ // :enter is alias to 'void => *'
                style({ opacity: 0 }),
                animate('500ms', style({ opacity: 1 }))
            ]),
            transition(':leave', [ // :leave is alias to '* => void'
                style({ opacity: 1 }),
                animate('500ms', style({ opacity: 0 }))
            ])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    providers: [RecordingService, DestroyService],
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, IconsModule, ModalDialogComponent, FieldsModule, ValdemortModule, AudioPlayerComponent]
})

export class PromptRecorderComponent extends DialogComponent<File> implements OnDestroy {
    recordIconHovered: boolean;

    readonly record$ = new BehaviorSubject<{ blobUrl: string; dataUrl: string; audio: RecordedAudioOutput } | null>(null);
    readonly isRecording$ = new BehaviorSubject(false);
    readonly error$ = new BehaviorSubject('');

    @ViewChild('dialog', { static: true })
    dialog: ModalDialogComponent;

    readonly recordFileName = new FormControl<string>('', {
        nonNullable: true,
        validators: [Validators.required, Validators.pattern(/^[^<>:"/\\?*|]*$/), Validators.maxLength(45)]
    });

    readonly recordingTime$ = this.recordingService.recordingTime$;
    readonly svgButtonIcon = microphoneIcon;

    constructor(
        private recordingService: RecordingService,
        @Self() private destroy$: DestroyService
    ) {
        super();

        this.recordingService.recordingFinished$
            .pipe(takeUntil(destroy$))
            .subscribe(() => this.isRecording$.next(false));

        this.recordingService.recordedBlob$
            .pipe(
                switchMap(audioOutput =>
                    from(this.recordingService.blobToDataURL(audioOutput.blob))
                        .pipe(
                            map(dataUrl => ({
                                audio: audioOutput,
                                dataUrl,
                                blobUrl: URL.createObjectURL(audioOutput.blob)
                            })))
                ),
                catchError((error: unknown) => {
                    this.error$.next(extractErrorMessage(error));
                    return of(undefined);
                }),
                takeUntil(destroy$)
            )
            .subscribe(record => this.record$.next(record ?? null));
    }

    ngOnDestroy(): void {
        if (this.isRecording$.value) {
            this.recordingService.abortRecording();
        }
    }

    startRecording() {
        if (!this.isRecording$.value && this.recordFileName.valid) {
            this.error$.next('');
            this.recordingService.startRecording(this.recordFileName.value);
            this.isRecording$.next(true);
        }
    }

    stopRecording() {
        if (this.isRecording$.value) {
            this.isRecording$.next(false); // immediate off to prevent double click
            this.recordingService.stopRecording(this.recordFileName.value);
        }
    }

    onAudioPlayerAction(action: AudioPlayerAction) {
        switch (action) {
            case AudioPlayerAction.upload: {
                this.dialog.submit();
                break;
            }
            case AudioPlayerAction.reset: {
                this.isRecording$.next(false);
                this.recordIconHovered = false;
                this.record$.next(null);
                break;
            }
        }
    }

    protected getPayload(): File | undefined {
        if (!this.record$.value) {
            return undefined;
        }
        const { blob, title } = this.record$.value.audio;
        const lastModified = new Date().getTime();

        return new File([blob], title, { lastModified, type: blob.type });
    }
}
