import { Group } from './group';
import { Phones } from './phones';
import { Profile, ProfileNames } from './profile';
import { AppContactType, externalBridgeId, localBridgeId, phonebookBridgeId } from './app-contact-type';
import { Utils } from './join/utils';
import {
    Contact,
    ContactAddedByEnum,
    ContactType,
    DnType,
    LocalConnections,
    ResponseLookup
} from '@myphone';
import { LocalConnectionEx } from './local-connection-ex';
import { ReplaySubject } from 'rxjs';
import { groupImage } from '../assets';
import { sanitizePhoneNumber } from '@webclient/phone/phone-funcs';

export const avatarMainColor = '#58666e';

export function myPhoneContactBridgeId(myPhoneContact: Contact): string {
    switch (myPhoneContact.ContactType) {
        case ContactType.CompanyPhonebook:
            return phonebookBridgeId;
        case ContactType.ExternalContact:
            return externalBridgeId;
        case ContactType.PersonalPhonebook: return localBridgeId;
        case ContactType.BridgeExtension: return myPhoneContact.AddressNumberOrData9;
        // ContactType.LocalUser
        default: return localBridgeId;
    }
}

export function uniqueId(contact: Contact) {
    if (contact.ContactType === ContactType.LocalUser || contact.ContactType === ContactType.BridgeExtension) {
        return `${contact.Id}@${myPhoneContactBridgeId(contact)}`;
    }
    else {
        return contact.Id.toString();
    }
}

export class AppContact {
    public readonly id: string;
    public readonly identity: ReplaySubject<AppContact> = new ReplaySubject(1);
    public readonly connections: LocalConnections = new LocalConnections();
    public displayedConnection?: LocalConnectionEx;

    public readonly phones: Partial<Phones> = {};
    public readonly sanitizedPhones: Partial<Phones> = {};

    public addedBy: ContactAddedByEnum = ContactAddedByEnum.AB_Tcx;
    public myContact: boolean;
    public chatEnabled: boolean;
    public hideInPhonebook: boolean;
    public firstName = '';
    public lastName = '';
    public firstNameLastName = '';
    public lastNameFirstName = '';
    public isRegistered: boolean;
    public isDnd: boolean;
    public readonly isDummy: boolean;
    public currentProfile = new Profile();
    public queueStatus: boolean;
    public isQueueAgent: boolean;
    public monitorExpiration?: Date;
    public isBusy: boolean;
    public isEditable: boolean;
    public isFavorite: boolean;
    public dnType: DnType;
    public availableForTransfer : boolean;

    public nativeExtensionNumber = '';
    public nativeMobileNumber = '';
    get extensionNumber(): string {
        return this.phones.extension || '';
    }

    // TODO ChatStatus
    public profilePicture = '';
    public emailAddress: string;
    public company = '';

    public title: string;
    public department: string;

    public statusTemporarilyChanged: boolean;
    public overrideCurrentProfile = new Profile();
    public overrideExpiresUTCTime: Date;
    public overrideAttachedData: string;
    public isRinging: boolean;
    public crmUrl: string;

    public groups: Group[] = [];

    public hasAvatarLetters = true;
    public avatarColor = avatarMainColor;
    public forceShowProfile = false;

    get queueCssClassName() {
        if (this.type !== AppContactType.Extension) {
            return '';
        }
        return this.queueStatus ? 'on' : 'off_queue';
    }

    // returns css class name for contact status icon
    // TODO: move to pipe or some kind of extension method
    get statusCssClassName(): string {
        if (this.type !== AppContactType.Extension && !this.forceShowProfile) {
            return '';
        }

        if (this.isRinging) {
            return 'ringing';
        }
        if (this.isBusy) {
            return 'busy';
        }

        // TODO: check is it possible that extension registered but doesn't have a profile
        return Utils.getProfileCssClass(this.isRegistered ? this.profile?.internalName : undefined);
    }

    get bestPhone(): string {
        return this.phones.extension || this.bestMobile;
    }

    get bestMobile(): string {
        return this.phones.mobile || this.phones.mobile2 || this.phones.business || this.phones.home || this.phones.business2 || '';
    }

    // Use nativeMobileNumber here because phones.mobile can be prefixed
    get bestSmsMobile(): string {
        return this.nativeMobileNumber || this.phones.mobile2 || this.phones.business || this.phones.home || this.phones.business2 || '';
    }

    get isAvailableForTransfer(): boolean {
        return this.availableForTransfer === undefined || this.availableForTransfer;
    }

    private static updatePhone<K extends keyof Phones>(contact: AppContact, phone: K, value: string): void {
        contact.phones[phone] = value;
        contact.sanitizedPhones[phone] = sanitizePhoneNumber(value);
    }

    public static myPhoneTypeContactMapper(contact: Contact): AppContactType {
        switch (contact.ContactType) {
            case ContactType.CompanyPhonebook: return AppContactType.CompanyPhonebook;
            case ContactType.PersonalPhonebook: return AppContactType.PersonalPhonebook;
            case ContactType.BridgeExtension: return AppContactType.Extension;
            case ContactType.LocalUser:
                if (contact.DnType === undefined || contact.DnType === DnType.Extension) {
                    return AppContactType.Extension;
                }
                else {
                    return AppContactType.SystemExtension;
                }
            default:
            // Server returns undefined contact type when it doesn't know what it is
            // in this case return external type so a user can add a contact
                return AppContactType.External;
        }
    }

    public static create(entry: Contact): AppContact {
        const isBridgedExtension = entry.ContactType === ContactType.BridgeExtension;
        const contact = new AppContact(
            uniqueId(entry),
            AppContact.myPhoneTypeContactMapper(entry),
            myPhoneContactBridgeId(entry),
            {
                dnType: entry.DnType,
                firstName: entry.FirstName || '',
                lastName: (entry.DnType === DnType.Extension || !entry.DnType) ? (entry.LastName || '') : '',
                company: entry.Company || '',
                title: entry.AddressNumberOrData2,
                department: isBridgedExtension ? '' : entry.AddressNumberOrData8,
                addedBy: entry.AddedBy,
                isEditable: entry.IsEditable,
                crmUrl: entry.CrmContactData,
                emailAddress: entry.AddressNumberOrData5,
                profilePicture: entry.ContactImage,
                availableForTransfer: entry.AvailableForTransfer
            }
        );

        Utils.onContactNameChanged(contact);

        AppContact.updatePhone(contact, 'extension', entry.ExtensionNumber);
        contact.nativeExtensionNumber = isBridgedExtension ?
            entry.AddressNumberOrData8 : entry.ExtensionNumber;
        contact.nativeMobileNumber = isBridgedExtension ?
            entry.AddressNumberOrData7 : entry.Number;

        AppContact.updatePhone(contact, 'extension', entry.ExtensionNumber);
        AppContact.updatePhone(contact, 'mobile', entry.Number);
        AppContact.updatePhone(contact, 'mobile2', entry.AddressNumberOrData0);
        AppContact.updatePhone(contact, 'home', entry.AddressNumberOrData1);
        AppContact.updatePhone(contact, 'business', entry.AddressNumberOrData3);
        AppContact.updatePhone(contact, 'business2', entry.AddressNumberOrData4);

        AppContact.updatePhone(contact, 'other', entry.AddressNumberOrData6);
        AppContact.updatePhone(contact, 'businessFax', entry.AddressNumberOrData7);
        if (entry.ContactType !== ContactType.BridgeExtension) {
            // For bridged extension this is a bridge id
            AppContact.updatePhone(contact, 'pager', entry.AddressNumberOrData9);
        }
        return contact;
    }

    public static responseContacts(response: ResponseLookup): AppContact[] {
        return response.Entries.map(entry => AppContact.create(entry));
    }

    constructor(id: string, public readonly type: AppContactType, public readonly bridgeId: string, init?: Partial<AppContact>) {
        this.id = id;
        if (init) {
            Object.assign(this, init);
        }
        this.onIdentityChanged();
    }

    public setContactProfile(isAvailable: boolean|undefined) {
        this.forceShowProfile = true;
        let newProfile: Profile;
        if (isAvailable) {
            this.isRegistered = true;
            newProfile = this.profile.merge({
                internalName: ProfileNames.Available
            });
        }
        else {
            this.isRegistered = false;
            newProfile = this.profile.merge({
                internalName: ProfileNames.Unregistered
            });
        }
        if (this.statusTemporarilyChanged) {
            this.overrideCurrentProfile = newProfile;
        }
        else {
            this.currentProfile = newProfile;
        }
    }

    public onIdentityChanged() {
        this.identity.next(this);
    }

    get profile(): Profile {
        return this.statusTemporarilyChanged ?
            this.overrideCurrentProfile : this.currentProfile;
    }

    public canBeAddedToPhonebook(): boolean {
        return this.isDummy;
    }

    public isPhonebookContact(): boolean {
        return !this.isDummy && (this.type === AppContactType.PersonalPhonebook || this.type === AppContactType.CompanyPhonebook);
    }
}

export const defaultContact = new AppContact('', AppContactType.CompanyPhonebook, phonebookBridgeId, {
    isDummy: true
});

export const defaultQueueContact = new AppContact('', AppContactType.Extension, localBridgeId, {
    isDummy: true,
});

export const groupContact = new AppContact('', AppContactType.CompanyPhonebook, phonebookBridgeId, {
    profilePicture: groupImage,
    hasAvatarLetters: false
});
