import { Injectable } from '@angular/core';
import { LocalConnectionState, WebRTCEndpointSDPState, WebRTCHoldState } from '@myphone';
import { MyCall } from '@webclient/phone/mycall';
import { remoteEndpointReceiveState } from '@webclient/webrtc/webrtc-control.service';

export enum InCallSearchAction {
    None = 0,
    Transfer = 1,
    AttTransfer = 2,
    NewCall = 3,
    Conference = 4,
}

export function getCallStateName(myCall: MyCall) : string {
    if (myCall.isTrying) {
        return '_i18n.Trying';
    }
    switch (myCall.state) {
        case LocalConnectionState.UnknownState:
            return '_i18n.Unknown';
        case LocalConnectionState.Ringing:
            return myCall.isQueueCall ? '_i18n.Incoming_queue_call' : '_i18n.Incoming_call';
        case LocalConnectionState.Dialing:
            return '_i18n.Dialing';
        case LocalConnectionState.Connected:
            if (myCall.isWebRTCCall) {
                switch (myCall.media.lastWebRTCState.holdState) {
                    case WebRTCHoldState.WebRTCHoldState_HELD:
                        return '_i18n.PutOnHold';
                    case WebRTCHoldState.WebRTCHoldState_HOLD:
                    case WebRTCHoldState.WebRTCHoldState_BOTH:
                        return '_i18n.OnHold';
                    default:
                        return '_i18n.Connected';
                }
            }
            else {
                return '_i18n.Connected';
            }
        case LocalConnectionState.WaitingForNewParty:
            return '_i18n.WaitingForOtherParty';
        case LocalConnectionState.TryingToTransfer:
            return '_i18n.TryingToTransfer';
        default:
            return '' + myCall.state;
    }
}

/**
 * Maps dialer in call search action to the respective title translation key
 *
 */
export function getCallSearchTitle(action: InCallSearchAction): string {
    switch (action) {
        case InCallSearchAction.Transfer:
            return '_i18n.TransferTo';
        case InCallSearchAction.AttTransfer:
            return '_i18n.AttTransfer';
        case InCallSearchAction.NewCall:
            return '_i18n.Dialer_NewCall';
        case InCallSearchAction.Conference:
            return '_i18n.SelectParticipant';
        default:
            return '';
    }
}

/**
 * Returns the font size based on the number of characters.
 *
 * @param numberOfChars - The number of characters in the text
 * @param initialFontSize - The initial font size to be applied on text
 * @param baseVisibleChars - The number of visible characters above which the font will become smaller
 *
 *
 */
export function adaptiveFontSize(numberOfChars: number, initialFontSize: number, baseVisibleChars:number): number {
    const adaptiveFontSizes = [
        { numberOfChars: baseVisibleChars, fontSize: initialFontSize },
        { numberOfChars: baseVisibleChars + 1, fontSize: initialFontSize - 1 },
        { numberOfChars: baseVisibleChars + 2, fontSize: initialFontSize - 2 },
        { numberOfChars: baseVisibleChars + 3, fontSize: initialFontSize - 3 },
        { numberOfChars: baseVisibleChars + 4, fontSize: initialFontSize - 4 },
        { numberOfChars: baseVisibleChars + 5, fontSize: initialFontSize - 5 },
        { numberOfChars: baseVisibleChars + 6, fontSize: initialFontSize - 6 },
        { numberOfChars: baseVisibleChars + 7, fontSize: initialFontSize - 7 },
        { numberOfChars: Number.MAX_VALUE, fontSize: initialFontSize - 8 },
    ];

    for (const fontSizePair of adaptiveFontSizes) {
        if (numberOfChars <= fontSizePair.numberOfChars) {
            return fontSizePair.fontSize >= 16 ? fontSizePair.fontSize : 16;
        }
    }
    return initialFontSize;
}

export function getRandomHash() {
    const array = new Uint32Array(8);
    window.crypto.getRandomValues(array);
    let str = '';
    for (let i = 0; i < array.length; i++) {
        str += (i < 2 || i > 5 ? '' : '-') + array[i].toString(16).slice(-4);
    }
    return str;
}

/**
 * Replaces the text in the selected interval with the new text
 */
export function replaceSelectedInterval(initialText:string|undefined, selectionStart:number, selectionEnd: number, toReplace: string) {
    const text = initialText || '';
    if (selectionStart < 0 || selectionStart > text.length || selectionEnd < 0 || selectionEnd > text.length || selectionStart > selectionEnd) {
        return text;
    }

    return text.slice(0, selectionStart) + toReplace + text.slice(selectionEnd);
}

@Injectable({
    providedIn: 'root'
})
export class DialerUtilityService {
    public isWebRTCCall(myCall: MyCall|null): boolean {
        return Boolean(myCall?.isWebRTCCall && myCall?.media.isActive);
    }

    public isVideoActive(myCall: MyCall) {
        return myCall.media.isVideoCall && (myCall.media.isVideoSend || myCall.media.isVideoReceived);
    }

    isDialing(myCall: MyCall): boolean {
        return myCall.state === LocalConnectionState.Dialing || myCall.isTrying;
    }

    isOnHold(myCall: MyCall): boolean {
        return !(myCall.media.lastWebRTCState.holdState === WebRTCHoldState.WebRTCHoldState_HOLD);
    }

    public isVideoAnswerAllowed(myCall: MyCall): boolean {
        if (!myCall) {
            return false;
        }
        const [audio, video] = remoteEndpointReceiveState(myCall.media.lastWebRTCState.sdp);
        return myCall.media.lastWebRTCState.sdpType === WebRTCEndpointSDPState.WRTCRequestForOffer || video;
    }
}
