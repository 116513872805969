import {
    Component, OnInit
} from '@angular/core';
import {
    BehaviorSubject, combineLatest, Observable, of
} from 'rxjs';
import { filter, map, startWith } from 'rxjs/operators';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import {
    animate, style, transition, trigger
} from '@angular/animations';

@Component({
    selector: 'app-update-notification-bar',
    template: `
        <div @updateNotificationTrigger *ngIf="(updateNotification$ | async) && showUpdateNotification">
            <div class="d-flex flex-row gap-2 py-1 justify-content-center align-items-center">
                <span [translate]="label"></span>
                    <button type="button" class="btn btn-primary" (click)="installApp()">
                        {{'_i18n.Yes' | translate}}
                    </button>
                    <button type="button" class="btn btn-primary" (click)="isUpdateClosed$.next(true)">
                        {{'_i18n.No' | translate}}
                    </button>
            </div>
        </div>
    `,
    styles: ['button { padding: 0; height: auto; width: 3em; }'],
    animations: [
        trigger('updateNotificationTrigger', [
            transition(':enter', [
                style({ height: 0 }),
                animate('100ms ease-in-out', style({ height: '*' })),
            ]),
            transition(':leave', [
                animate('100ms ease-in-out', style({ height: 0 }))
            ])
        ])
    ],
})
export class UpdateNotificationBarComponent implements OnInit {
    readonly label = '_i18n.UpdatePwaIsAvailable';

    public updateNotification$: Observable<boolean>;
    public isUpdateClosed$ = new BehaviorSubject(false);

    constructor(
        public swUpdate: SwUpdate
    ) {
    }

    ngOnInit(): void {
        const updatesAvailable$ = this.swUpdate.isEnabled
            ? this.swUpdate.versionUpdates.pipe(
                filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
                map(() => true),
                startWith(false)
            )
            : of(false);

        this.updateNotification$ = combineLatest([updatesAvailable$, this.isUpdateClosed$])
            .pipe(
                map(([updateAvailable, isClosed]) => updateAvailable && !isClosed),
                startWith(false)
            );
    }

    get showUpdateNotification() {
        return this.swUpdate.isEnabled;
    }

    installApp() {
        if (this.swUpdate.isEnabled) {
            this.swUpdate.activateUpdate()
                .catch(() => true)
                .then((activated) => {
                    if (activated) {
                        window.location.reload();
                    }
                });
        }
    }
}
