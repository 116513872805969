import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SearchContext } from '@webclient/shared/search/search-context';
import { SearchResult } from '@webclient/shared/search/search-result';
import { take } from 'rxjs/operators';
import { isValidEmail } from '@webclient/shared/utils.service';
import { Observable } from 'rxjs';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { ContactSearcherService } from '@webclient/shared/service/contact-searcher.service';
import { AppContactType } from '@webclient/myphone/app-contact-type';
import { AppContact } from '@webclient/myphone/contact';
import { SearchRuntimeContextService } from '@webclient/shared/service/search-runtime-context.service';
import { CrmSearchPage } from '@webclient/shared/service/crm-contacts.response';

@Component({
    selector: 'call-search-list',
    template: `
        <div class='contact-search-list-container'>
            <ul class='search-nav-ul'
                [style.max-height.px]="listMaxHeight()"
                id='scrollableList'
                #scrollableElement>
                <li *ngFor="let result of searchResult$ | async;" class='search-result pointer'
                    (click)='itemSelected(result)'>
                    <ng-container
                        *ngTemplateOutlet="dialerContactsListTemplate; context: {item: result}"></ng-container>
                </li>
                <ng-container *ngIf="crmSearchResult$|async; let crmSearchResult">
                    <ng-container *ngIf="crmSearchResult.status !== 'unavailable'">
                        <li *ngIf="crmSearchResult.status === 'error'"
                            class="crm-tile d-flex justify-content-center p-3 gap-2">
                            <span class="customSVGIcons svg-sm" app-exclamation-circle-solid-icon
                                  [title]="crmSearchResult.error"></span>
                        </li>
                        <li *ngIf="crmSearchResult.status === 'loading'"
                            class="crm-tile d-flex justify-content-center p-3 gap-2">
                            <span class="customSVGIcons svg-sm" app-spinner-regular-icon></span>
                        </li>
                        <li *ngFor="let result of crmSearchResult.contacts" class='search-result pointer'
                            (click)='itemSelected(result)'>
                            <ng-container
                                *ngTemplateOutlet="dialerContactsListTemplate; context: {item: result}"></ng-container>
                        </li>
                    </ng-container>
                </ng-container>
            </ul>
        </div>

        <ng-template #dialerContactsListTemplate let-item="item">
            <app-avatar [ext]='item.contact'></app-avatar>
            <div class='search-result-info'>
                <div class='search-result-name'>
                                <span class='search-result-message text-truncate'
                                      title='{{item.contact | lforfl:item.name | async}}'>
                                    {{item.contact | lforfl:item.name | async}}
                                </span>
                </div>
                <div class='search-result-number'>
                                <span class='search-result-message text-truncate'
                                      *ngIf="item.isVoicemail"
                                      title='{{(item.contact.type === ContactType.SystemExtension ? "_i18n.GroupVoicemail" : "_i18n.VoiceMail") | translate}}'>
                                    {{(item.contact.type === ContactType.SystemExtension ? "_i18n.GroupVoicemail" : "_i18n.VoiceMail") | translate}}
                                </span>
                    <span class='search-result-message text-truncate'
                          *ngIf="!item.isVoicemail"
                          title='{{item.number}} {{(item.contact | contactPhoneType : item.number | async)?(item.contact | contactPhoneType : item.number | async):(item.contact.profile | userProfile: item.contact.isBusy : item.contact.isRegistered |async)}}'>
                                    {{item.number}} {{(item.contact | contactPhoneType : item.number | async) ? (item.contact | contactPhoneType : item.number | async) : (item.contact.profile | userProfile: item.contact.isBusy : item.contact.isRegistered |async)}}
                                </span>
                </div>
            </div>
        </ng-template>
    `,
    styleUrls: ['call-search-list.component.scss'],
    providers: [
        { provide: 'requestCount', useValue: 20 },
        SearchRuntimeContextService
    ]
})
export class CallSearchListComponent implements OnInit {
    @ViewChild('scrollableElement', { static: false })
    scrollableList: ElementRef;

    @Input()
    searchContext: SearchContext;

    @Input()
    numberOfVisibleContacts: number;

    @Output()
    contactSelected: EventEmitter<SearchResult|undefined> = new EventEmitter<SearchResult|undefined>();

    readonly ContactType = AppContactType;

    public searchResult$: Observable<SearchResult[]>;
    public crmSearchResult$: Observable<CrmSearchPage>;

    private readonly requestCount: number = 5;

    constructor(private myPhone: MyPhoneService,
        private contactSearcher: ContactSearcherService,
        private runtimeSearch: SearchRuntimeContextService
    ) {
    }

    ngOnInit() {
        this.searchResult$ = this.runtimeSearch.getContacts(this.searchContext);
        this.crmSearchResult$ = this.runtimeSearch.getCrmContacts(this.searchContext);
    }

    search(newValue: string) {
        if (this.scrollableList !== undefined) {
            this.scrollableList.nativeElement.scrollTop = 0;
        }
        this.runtimeSearch.search$.next(newValue);
    }

    itemSelected(item: SearchResult| AppContact | undefined) {
        if (item instanceof SearchResult) {
            this.contactSelected.next(item);
        }
        else if (item instanceof AppContact) {
            this.contactSelected.next(new SearchResult({ contact: item }));
        }
        else {
            this.contactSelected.next(item);
        }
    }

    requestContact(textToSearch: string) {
        const textTrimmed = (textToSearch || '').trim();
        if (!textTrimmed) {
            this.itemSelected(undefined);
        }

        // Else here - we don't have selected item
        if (isValidEmail(textTrimmed)) {
            this.contactSearcher.requestContactByEmail(textTrimmed, this.searchContext).pipe(take(1)).subscribe(
                contact => {
                    // contact could be undefined here and that's ok
                    const x = new SearchResult({
                        name: contact.firstNameLastName,
                        number: textTrimmed,
                        contact: contact.isDummy ? undefined : contact,
                        isEmail: true
                    });
                    this.itemSelected(x);
                }
            );
        }
        else {
            // If that is not an email(in text input) try to find guy in phone book by number - may be it's our extension or contact
            this.contactSearcher.requestContactByNumber(textTrimmed, this.searchContext).pipe(take(1)).subscribe(
                contact => {
                    if (!contact.isDummy) {
                        const searchResult = new SearchResult({
                            name: contact.firstNameLastName,
                            number: textTrimmed,
                            contact
                        });
                        this.itemSelected(searchResult);
                    }
                    else {
                        const searchResult = new SearchResult({
                            name: '',
                            number: textTrimmed
                        });
                        this.itemSelected(searchResult);
                    }
                }
            );
        }
    }

    listMaxHeight() : number {
        return !this.numberOfVisibleContacts ? 3 * 35 : this.numberOfVisibleContacts * 35;
    }
}
