import { Injectable } from '@angular/core';
import { TokenApiService } from '@api';
import { ModalService } from '@webclient/modal/app-modal.service';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { map, of, Subscription, switchMap } from 'rxjs';
import { getAccessToken$ } from '@office/reports/helpers';
import { HttpClient } from '@angular/common/http';
import { odataPath } from '@webclient/app-tokens';
import { downloadURL } from '@webclient/shared/utils.service';
import { take } from 'rxjs/operators';

export const buildDownloadUrl = (url: string, token: string, addXapiPath: boolean) => {
    return `${addXapiPath ? odataPath : ''}${url}${url.indexOf('?') > -1 ? '&' : '?'}access_token=${token}`;
};

type DownloadConfig = { filename?: string, dryRun?: boolean, addXapiPath?: boolean }

@Injectable({ providedIn: 'root' })
export class FileDownloadService {
    constructor(
        private tokenApiService: TokenApiService,
        private modalService: ModalService,
        private myPhoneService: MyPhoneService,
        private httpClient: HttpClient
    ) {}

    getDownloadLink$(rawLink: string, config?: DownloadConfig) {
        return getAccessToken$(this.tokenApiService)
            .pipe(map(token => buildDownloadUrl(rawLink, token, config?.addXapiPath ?? true)), take(1));
    }

    download(rawLink: string, config?: DownloadConfig) {
        this.getDownloadLink$(rawLink, config).pipe(
            switchMap(link => {
                return config?.dryRun ? this.dryRun(link).pipe(map(() => link)) : of(link);
            })
        ).subscribe({
            next: (link) => {
                downloadURL(link, config?.filename);
            },
            error: (err: unknown) => {
                this.modalService.error(err);
            }
        });
    }

    private postDownloadIframe?: HTMLIFrameElement;
    private postDownloadSubscription?: Subscription;

    downloadWithPost(rawLink: string, postData: FormData) {
        this.postDownloadSubscription?.unsubscribe();
        if (this.postDownloadIframe) {
            document.body.removeChild(this.postDownloadIframe);
        }

        const iframe = document.createElement('iframe');

        iframe.setAttribute('name', 'hidden-frame');
        iframe.style.display = 'none';
        document.body.appendChild(iframe);
        this.postDownloadIframe = iframe;

        this.postDownloadSubscription = this.getDownloadLink$(rawLink).subscribe(url => {
            const iDocument = iframe.contentWindow!.document;
            const form = iDocument.createElement('form');

            form.setAttribute('method', 'POST');
            form.setAttribute('action', url);
            form.setAttribute('target', 'hidden-frame');

            postData.forEach((value, key) => {
                if (typeof value !== 'string') {
                    throw new Error('Not implemented for blob value of key: ' + key);
                }
                const input = iDocument.createElement('input');

                input.setAttribute('name', key);
                input.value = value;
                form.appendChild(input);
            });

            iDocument.body.appendChild(form);
            form.submit();
        });
    }

    // checks that a file is available before a request
    private dryRun(link: string) {
        return this.httpClient.get(link, {
            headers: {
                'X-Dry-Run': 'true'
            }
        });
    }
}
