export type AlertClass = 'success'|'danger'|'info';

export class HeadsetDiagnostics {
    constructor(init?: Partial<HeadsetDiagnostics>) {
        Object.assign(this, init);
    }

    static Ok = new HeadsetDiagnostics({
        alertClass: 'success',
        text: '_i18n.HeadsetReady'
    });

    static Progress = new HeadsetDiagnostics({
        alertClass: 'info',
        text: '_i18n.HeadsetDiagnosticsProgress'
    });

    static error(text: string) : HeadsetDiagnostics {
        return new HeadsetDiagnostics({
            alertClass: 'danger',
            text
        });
    }

    alertClass: AlertClass;
    text: string;
}
