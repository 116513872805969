import { DateTime, ResponseServerTime, Timestamp } from '@myphone';

const zeroDate = new Date(0);

export function millisecondsToMyPhoneTime(ts: number) {
    const time = new Date(ts);
    return new DateTime({
        Year: time.getUTCFullYear(),
        Day: time.getUTCDate(),
        Month: time.getUTCMonth() + 1,
        Hour: time.getUTCHours(),
        Minute: time.getUTCMinutes(),
        Second: time.getUTCSeconds()
    });
}

export function myPhoneTimeToMilliseconds(dt: DateTime|undefined): number {
    return dt ? Date.UTC(dt.Year, dt.Month - 1, dt.Day, dt.Hour, dt.Minute, dt.Second) : 0;
}

export function timestampToJsDateTime(dt: Timestamp|undefined): Date {
    return dt ? new Date(dt.seconds * 1000 + dt.nanos / 1000000) : zeroDate;
}

export function myPhoneTimeToDate(dt: DateTime|undefined): Date {
    return dt ? new Date(myPhoneTimeToMilliseconds(dt)) : zeroDate;
}

export function getTimeDelta(requestServerTimeSent: number, responseServerTime: ResponseServerTime) {
    const c1 = requestServerTimeSent;
    const c2 = Date.now();

    let s1 = myPhoneTimeToDate(responseServerTime.ArrivedAtServerLocalUTCtime).valueOf();
    s1 += responseServerTime.ArrivedAtMSecs;

    let s2 = myPhoneTimeToDate(responseServerTime.SentAtServerLocalUTCtime).valueOf();
    s2 += responseServerTime.SentAtMSecs;

    const delta = (((s1 - c1) + (s2 - c2)) / 2);
    console.log('Delta time', delta);
    return delta;
}
