import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import {
    BehaviorSubject,
    combineLatest,
    EMPTY, from, fromEvent, Observable, of, switchMap
} from 'rxjs';
import {
    catchError,
    map, startWith, take, tap
} from 'rxjs/operators';
import { publishRef } from '@webclient/rx-share-utils';
import { MyPhoneSession } from '@webclient/myphone/myphone-session';
import {
    ActionType, PushSubscription as MyPhonePushSubscription, PushSubscriptionData,
    PushSubscriptions,
    RequestUpdatePushSubscriptions
} from '@myphone';
import { noEmitAndConsoleWarnOnError } from '@webclient/rx-utils';
import { browserOnOS } from '@webclient/browser-type';
import { isSelenium } from '@webclient/environment';
import { hasPermission } from '@webclient/notifications/notification-funcs';

export function IsNgswEnabled() {
    return Boolean(navigator?.serviceWorker?.controller);
}
export const ExtendedServiceWorker = '3cx-worker.js';

function postSubscription(pJson: PushSubscriptionJSON) {
    return new PushSubscriptionData({
        DeliveryType: 'V',
        ModelName: browserOnOS,
        Instance: pJson.endpoint,
        DestinationAddress: pJson.keys?.p256dh,
        Options: pJson.keys?.auth
    });
}

function getPermissionState(): PermissionState {
    if ('Notification' in window) {
        // Map permissions
        if (Notification.permission === 'granted') {
            return 'granted';
        }
        else if (Notification.permission === 'denied') {
            return 'denied';
        }
        else {
            return 'prompt';
        }
    }
    else {
        // Nothing works so denied
        return 'denied';
    }
}

@Injectable({
    providedIn: 'root'
})
export class ExtendedSwPushService {
    // is push enabled
    public readonly isEnabled$: Observable<boolean>;
    private readonly registration$: Observable<ServiceWorkerRegistration>;
    private pushSubscription: PushSubscription|null = null;
    public readonly permissionState$: Observable<PermissionState>;
    public readonly refreshPermissions$ = new BehaviorSubject<boolean>(true);

    public constructor(private swPush: SwPush) {
        if ('permissions' in navigator && !isSelenium) {
            this.permissionState$ = from(navigator.permissions.query({ name: 'notifications' })).pipe(
                switchMap(notificationPerm => fromEvent(notificationPerm, 'change').pipe(
                    map(() => notificationPerm.state),
                    startWith(notificationPerm.state)
                )),
                // Safari 16 BETA throws exception here so workaround it if it's not working
                catchError(() => this.refreshPermissions$.pipe(map(() => getPermissionState()))),
            );
        }
        else {
            this.permissionState$ = this.refreshPermissions$.pipe(map(() => getPermissionState()));
        }

        const sw = navigator.serviceWorker;
        if (sw && swPush.isEnabled && !hasPermission('denied')) {
            this.registration$ = from(sw.ready).pipe(publishRef());
            this.isEnabled$ = this.registration$.pipe(
                map(registration => Boolean(registration.pushManager))
            );
            this.isEnabled$.pipe(
                switchMap(isEnabled => (isEnabled ? this.swPush.subscription : of(null)))
            ).subscribe({
                next: (subs) => {
                    this.pushSubscription = subs;
                },
            });
        }
        else {
            this.isEnabled$ = of(false);
            this.registration$ = EMPTY;
        }
    }

    pushUnsubscribe(session: MyPhoneSession): Observable<any> {
        const p256dh = this.pushSubscription?.toJSON().keys?.p256dh;
        if (!p256dh) {
            return EMPTY;
        }
        return combineLatest([from(this.swPush.unsubscribe()).pipe(
            // Well if a subscription is reported most probably you're subscribed
            // but anyway force unsubscribe here and ignore the error
            noEmitAndConsoleWarnOnError('push unsubscribe')
        ), session.get(new RequestUpdatePushSubscriptions({
            Subscriptions: new PushSubscriptions({
                Action: ActionType.Updated,
                Items: [
                    new MyPhonePushSubscription({
                        Action: ActionType.Deleted,
                        Subscription: new PushSubscriptionData({
                            DestinationAddress: p256dh
                        })
                    })
                ]

            })
        })).pipe(
            noEmitAndConsoleWarnOnError('myphone push unsubscribe')
        )]);
    }

    // boot push subscription to init PUSH device or to resubscribe user
    pushSubscribe(session: MyPhoneSession): Observable<any> {
        // Informs server about my VAPID information
        return session.systemParameters$.pipe(
            // Wait for system parameters
            take(1),
            switchMap(parameters => this.swPush.requestSubscription({ serverPublicKey: parameters.VapidPublicKey })),
            map(x => postSubscription(x.toJSON())),
            switchMap(subscriptionRequest => session.get(subscriptionRequest)),
            // ServiceWorker is enabled but PushSubscriptions is not supported.
            // This happens in Incognito mode, Selenium, maybe somewhere else
            // Also happens when notification permission is set to denied
            // but default permission causes a delay and dialog
            noEmitAndConsoleWarnOnError('push subscribe')
        );
    }

    // firePwaInstallation() {
    //     // https://www.amitmerchant.com/adding-custom-install-button-in-progressive-web-apps/
    //     // https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
    //     // https://web.dev/customize-install/
    //     this.pwaInstallPrompt.pipe(
    //         take(1),
    //         switchMap((prompt :any) => {
    //             if (prompt){
    //                 prompt.prompt();
    //                 return prompt.userChoice;
    //             }
    //             else {
    //                 return Promise.resolve(null);
    //             }
    //         }),
    //         catchError((err) => {
    //             console.log(err);
    //             return of(null);
    //         })
    //     ).subscribe((result :any) => {
    //         if (result){
    //             const { outcome } = result;
    //             if (outcome && outcome === 'accepted') {
    //                 this.pwaInstallPrompt.next(null);
    //             }
    //         }
    //     });
    // }

    get notificationClicks() {
        return this.swPush.notificationClicks;
    }

    public getNotifications(tag: string): Observable<any> {
        return this.registration$.pipe(switchMap(registration =>
            // getNotifications is missing in Safari, WebDriver
            (registration.getNotifications ? from(registration.getNotifications({ tag })) : EMPTY)
        ));
    }

    public closeNotifications(tag: string) {
        this.getNotifications(tag)
            .pipe(take(1))
            .subscribe({
                next: (notifications: Notification[]) => {
                    notifications.forEach(notification => {
                        notification.close();
                    });
                }
            });
    }

    public setLanguage(lang: string) {
        this.registration$.pipe(
            take(1),
            tap(registration => registration?.active?.postMessage({
                type: 'lang',
                value: lang
            })),
            take(1)
        ).subscribe();
    }

    public setSilentMode(silentMode: boolean) {
        this.registration$.pipe(
            take(1),
            tap(registration => registration?.active?.postMessage({
                type: 'silentMode',
                value: silentMode
            })),
            take(1)
        ).subscribe();
    }
}
