import { defaultContact } from '@webclient/myphone/contact';
import { Record } from 'immutable';
import { defaultChatProvider } from '@webclient/chat/chat-provider';

export enum ChatDestinationType {
    External,
    Group,
    Extension
}

export class ChatDestination extends Record({
    provider: defaultChatProvider,
    type: ChatDestinationType.Extension,
    phoneNumber: '',
    ipAddress: '',
    anonymousSessionId: '',
    contact: defaultContact
}) {
}
