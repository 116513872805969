import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ObjectFilterPipe } from './pipes/object-filter.pipe';
import { SecondsToHHmmss } from './pipes/seconds-to-hh-mm-ss.pipe';
import { UtilsService } from './utils.service';
import { OrderBy } from './pipes/order-by.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { FindContactComponent } from './components/find-contact.component';
import { FindContactModalComponent } from './components/find-contact-modal.component';
import { ContextMenuModule } from '../third-party/angular2-contextmenu/angular2-contextmenu';
import { SnackbarComponent } from './snackbar.component';
import { StandaloneViewComponent } from './standalone-view.component';
import { InfiniteScrollModule } from './directives/ng-infinite-scroll.module';
import { FilterPipe } from './pipes/filter.pipe';
import { ActiveCallMenuComponent } from './components/active-call-menu.component';
import { NotFavoritesGroupPipe } from './pipes/not-favorites-group.pipe';
import { ForwardingProfileService } from './forwarding-profile.service';
import { PeopleOrderBy } from '../people/people-list/people-order-by.pipe';
import { HelpButtonComponent } from './components/help-button.component';
import { ProfessionalOnlyDirective } from './directives/professional-only.directive';
import { AvatarComponent, CheckedAvatarComponent } from '../standalones/avatar';
import { NameOrderPipe } from '@webclient/shared/pipes/name-order.pipe';
import { ShowErrorWarningComponent } from '@webclient/shared/components/show-error-warning.component';
import { NullToEmptyArrayPipe } from '@webclient/shared/null-to-empty-array.pipe';
import { SexySearchComponent } from './sexy-search/sexy-search.component';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { TruncatedTextDirective } from '@webclient/standalones/directives';
import { RouteTitleComponent } from '@webclient/shared/route-title/route-title.component';
import { ExtensionLineViewComponent } from '@webclient/shared/extension-line-view/extension-line-view.component';
import { MobileCloseButtonComponent } from '@webclient/shared/mobile-close-button/mobile-close-button.component';
import { UserProfilePipe } from '@webclient/people/user-profile.pipe';
import { NameOrderUntypedPipe } from '@webclient/shared/pipes/name-order-untyped.pipe';
import { ContactNamePipe } from '@webclient/shared/pipes/contact-name.pipe';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProVersionOverlayComponent } from '@webclient/shared/components/pro-version-overlay.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TitleBarComponent } from '@webclient/shared/components/title-bar.component';
import { DayJsModule } from '@webclient/dayjs';
import { ContactPhoneTypePipe } from '@webclient/call/contact-phone-type.pipe';
import { NgSelectModule } from '@ng-select/ng-select';
import { UpdateNotificationBarComponent } from '@webclient/shared/components/update-notification-bar.component';
import { ValdemortModule } from 'ngx-valdemort';
import { HighlighterPipe } from '@webclient/shared/pipes/highlighter.pipe';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog';
import { FieldsModule } from '@webclient/fields/fields.module';
import { AppTimeIntervalTextPipe } from '@webclient/standalones/pipes';
import { TabOrderDirective } from '@webclient/shared/directives/tab-order.directive';
import { StickyHeaderDirective } from '@webclient/shared/directives/sticky-header.directive';
import { IsEllipsisDirective } from '@webclient/shared/directives/is-ellipsis.directive';
import { BannerContainerComponent } from '@webclient/shared/components/banner-container.component';
import { DefaultValidationErrorsComponent } from '@webclient/layout/default-validation-errors.component';

@NgModule({
    declarations: [
        UserProfilePipe,
        NotFavoritesGroupPipe,
        SexySearchComponent,
        HelpButtonComponent,
        SecondsToHHmmss,
        ActiveCallMenuComponent,
        OrderBy,
        FilterPipe,
        ObjectFilterPipe,
        SafeUrlPipe,
        ExtensionLineViewComponent,
        RouteTitleComponent,
        NullToEmptyArrayPipe,
        ContactNamePipe,
        NameOrderUntypedPipe,
        PeopleOrderBy,
        SnackbarComponent,
        StandaloneViewComponent,
        MobileCloseButtonComponent,
        FindContactComponent,
        FindContactModalComponent,
        ProfessionalOnlyDirective,
        ShowErrorWarningComponent,
        ProVersionOverlayComponent,
        SafeHtmlPipe,
        TitleBarComponent,
        UpdateNotificationBarComponent,
        ContactPhoneTypePipe,
        HighlighterPipe,
        TabOrderDirective,
        StickyHeaderDirective,
        IsEllipsisDirective,
        BannerContainerComponent,
    ],
    imports: [
        IconsModule,
        AvatarComponent,
        CheckedAvatarComponent,
        TranslateModule,
        CommonModule,
        TooltipModule,
        PaginationModule,
        FormsModule,
        ReactiveFormsModule,
        ProgressbarModule,
        ModalModule,
        InfiniteScrollModule,
        BsDropdownModule,
        DayJsModule,
        ContextMenuModule,
        NgSelectModule,
        ValdemortModule,
        FieldsModule,
        TruncatedTextDirective,
        ModalDialogComponent,
        AppTimeIntervalTextPipe,
        NameOrderPipe,
        DefaultValidationErrorsComponent,
    ],
    exports: [
        UserProfilePipe,
        IconsModule,
        SexySearchComponent,
        RouteTitleComponent,
        NotFavoritesGroupPipe,
        CommonModule,
        FilterPipe,
        NameOrderPipe,
        ContactNamePipe,
        NameOrderUntypedPipe,
        ObjectFilterPipe,
        ExtensionLineViewComponent,
        PeopleOrderBy,
        SafeUrlPipe,
        FormsModule,
        ReactiveFormsModule,
        AvatarComponent,
        TranslateModule,
        // NgbModule,
        TooltipModule,
        PaginationModule,
        NullToEmptyArrayPipe,
        ModalModule,
        ProgressbarModule,
        ButtonsModule,
        BsDropdownModule,
        TabsModule,
        ContextMenuModule,

        ProfessionalOnlyDirective,
        FindContactComponent,
        ActiveCallMenuComponent,
        FindContactModalComponent,
        SnackbarComponent,
        HelpButtonComponent,
        StandaloneViewComponent,
        SecondsToHHmmss,
        OrderBy,
        DayJsModule,
        ReactiveFormsModule,
        InfiniteScrollModule,
        MobileCloseButtonComponent,

        ShowErrorWarningComponent,
        ProVersionOverlayComponent,
        SafeHtmlPipe,
        TitleBarComponent,
        UpdateNotificationBarComponent,
        ContactPhoneTypePipe,
        NgSelectModule,
        HighlighterPipe,
        ModalDialogComponent,
        TabOrderDirective,
        IsEllipsisDirective,
        StickyHeaderDirective,
        BannerContainerComponent,
    ],
    providers: [
        UtilsService,
        ForwardingProfileService,
    ]
})
export class SharedModule {
}
