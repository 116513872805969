import { Component, Input } from '@angular/core';
import { MyCall } from '@webclient/phone/mycall';
import { DialerService } from './dialer.service';
import { getCallStateName } from './dialer-utility.service';
import { CallControlService } from '@webclient/call/call-control.service';
import { LocalConnectionState } from '@myphone';

@Component({
    selector: 'calls-bar',
    template: `
        <div class="active-calls-bar-container" *ngIf="dialerService.hasMultipleCallsNotAllIncoming$|async">
            <div class="active-calls-bar">
                <div *ngFor="let myCall of dialerService.calls$|async;" class="active-call-item"
                     (click)="setPrimaryCall(myCall)"
                     [ngClass]="{'primary-active-call-item': myCall.myCallId===(activeCall ? activeCall.myCallId: -1)}">
                    <div class="call-item-display-name text-truncate" [title]="myCall.displayName ? myCall.displayName : myCall.phone">
                        {{myCall.displayName ? myCall.displayName : myCall.phone}}
                    </div>
                    <div class="call-item-status text-truncate" [title]="getStateName(myCall)|translate">{{getStateName(myCall)|translate}}</div>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['calls-bar.component.scss'],
})
export class CallsBarComponent {
    @Input()
    public activeCall: MyCall|undefined;

    constructor(public dialerService: DialerService,
                private callService: CallControlService) {
    }

    setPrimaryCall(myCall: MyCall) {
        // Auto resume selected call
        if (myCall.state !== LocalConnectionState.Ringing) {
            this.callService.resume(myCall);
        }
        else {
            // Ringing call is not auto-answered here
            this.dialerService.setPrimaryCall(myCall.myCallId);
        }
    }

    getStateName(myCall: MyCall) {
        return getCallStateName(myCall);
    }
}
