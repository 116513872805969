import { Injectable } from '@angular/core';
import { interval, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class TimeService {
    public readonly durationTimer$: Observable<number>;

    constructor() {
        this.durationTimer$ = interval(1000).pipe(
            map(() => Date.now()), share());
    }
}
