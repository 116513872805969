<div class="blf-list">
    <call-blf-item *ngFor="let blf of pagedBlfs$|async; let i = index" [blf]='blf' [index]='i'>
    </call-blf-item>
</div>

<app-paging (pageChanged)="pageChanged($event)"
            [currentPage]="currentPage$|async"
            [itemsPerPage]="itemsPerPage"
            [maxVisiblePages]="3"
            [size]="'small'"
            [totalItems]="blfsCount$ | async">
</app-paging>
