import { LocalConnection } from '@myphone';

export class LocalConnectionEx extends LocalConnection {
    constructor(info?: Partial<LocalConnectionEx>) {
        super(info);
    }

    public OwnerPresentationString : string;
    public OtherPartyPresentationString : string;
}
