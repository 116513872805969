import { finalize, ignoreElements, map, scan, take } from 'rxjs/operators';
import { ComponentRef, Injectable, TemplateRef, ViewContainerRef } from '@angular/core';
import { MessageBoxComponent } from './wc-messagebox/wc-messagebox.component';
import { ModalButtons, ModalResult } from './message-box';
import { FindContactModalComponent } from '../shared/components/find-contact-modal.component';
import { SearchResult } from '../shared/search/search-result';
import { ProgressModalComponent } from './progress-modal.component';
import { merge, Observable, timer } from 'rxjs';
import type { ModalOptions } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import type { DialogComponent, DialogOutput } from './dialog';
import { extractErrorMessage } from '../convert-server-error';
import { filterDialogOk } from '../rx-utils';
import { TranslateService } from '@ngx-translate/core';

const ProgressCreateWindow = -1;
const ProgressModalDelayMs = 1000;

export { extractErrorMessage };

type DialogContent<TComponent> = { new (...args: any[]): TComponent }

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    public applicationViewContainerRef: ViewContainerRef;

    constructor(private bsModalService: BsModalService, private translate: TranslateService) {}

    // General message box
    messageBox(message: string, header: string, messageTranslateParams?: unknown, buttons = ModalButtons.Ok, customText = '', customClass = 'btn-border'): Observable<DialogOutput> {
        return this.showDialog(MessageBoxComponent, { header, message, messageTranslateParams, buttons, customText, customClass });
    }

    // Informational message box with message and Ok button
    information(message: string, messageTranslateParams?: unknown): void {
        this.messageBox(message, '_i18n.InformationHeaderOfDialog', messageTranslateParams);
    }

    // Informational message box with message and Ok button
    error(error: unknown, errorTranslateParams?: unknown): void {
        this.messageBox(extractErrorMessage(error, this.translate), '_i18n.ErrorHeaderOfDialog', errorTranslateParams);
    }

    warning(message: string, messageTranslateParams?: unknown): void {
        this.messageBox(message, '_i18n.WarningHeaderOfDialog', messageTranslateParams);
    }

    /** Show messageBox with ok and cancel buttons and emit result only for ok pressed */
    confirmation(message: string, header = '_i18n.ConfirmationRequired', messageTranslateParams: unknown = undefined): Observable<void> {
        return this.messageBox(message, header, messageTranslateParams, ModalButtons.OkCancel)
            .pipe(filterDialogOk);
    }

    private createFindContactModal() {
        return this.applicationViewContainerRef.createComponent(FindContactModalComponent);
    }

    findContact(header: string): Observable<SearchResult|undefined> {
        return new Observable<SearchResult>(subscriber => {
            const component = this.createFindContactModal();

            const dialog = component.instance;
            Object.assign(dialog, { header, text: 'text', autoShow: true });
            const subscription = dialog.result$.pipe(take(1)).subscribe(subscriber);

            return () => {
                subscription.unsubscribe();
                component.destroy();
            };
        });
    }

    progress(header: string, max: number, progressEvents: Observable<number>) {
        let component: ComponentRef<ProgressModalComponent>|undefined;
        return merge(
            timer(ProgressModalDelayMs).pipe(map(() => ProgressCreateWindow)),
            progressEvents
        )
            .pipe(
                scan((acc, value) => {
                    if (value === ProgressCreateWindow) {
                        // Time to create a window
                        component = this.applicationViewContainerRef.createComponent(ProgressModalComponent);
                        const dialog = component.instance;
                        Object.assign(dialog, {
                            header,
                            max,
                            value: acc
                        });
                        // Keep the previous value
                        return acc;
                    }
                    else if (component) {
                        component.instance.value = value;
                    }
                    return value;
                }, 0),
                finalize(() => component?.destroy()),
                ignoreElements()
            );
    }

    /**  For non extends components (DialogComponent) */
    show<T>(content: string | TemplateRef<any> | {
        new (...args: any[]): T;
    }, config?: ModalOptions<T>) {
        return this.bsModalService.show(content, config);
    }

    /** Shows dialog with a custom component. Emits content component output on hide in resulting observable. */
    showComponent<TPayload, TCustomPayload, TComponent extends DialogComponent<TPayload, TCustomPayload>>(
        content: DialogContent<TComponent>,
        // no class is allowed, component should bind modal-lg/modal-xl class to its host when needed
        config?: Omit<ModalOptions<TComponent>, 'class'>
    ): Observable<TComponent['output']> {
        const bsModalRef = this.bsModalService.show(content, { ...config, class: 'modal-component' });
        const contentComponent = bsModalRef.content!;

        return bsModalRef.onHide!.pipe(
            take(1),
            // output is empty on Esc
            map(() => (contentComponent.output || { decision: ModalResult.Cancel }))
        );
    }

    /** Shows dialog with a custom component. Emits content component output on hide in resulting observable. */
    showDialog<TPayload, TCustomPayload, TComponent extends DialogComponent<TPayload, TCustomPayload>>(
        content: DialogContent<TComponent>,
        initialState?: ModalOptions<TComponent>['initialState'],
        // no class is allowed, component should bind modal-lg/modal-xl class to its host when needed
        config?: Omit<ModalOptions<TComponent>, 'class' | 'initialState'>
    ): Observable<TComponent['output']> {
        return this.showComponent(content, { ...config, initialState });
    }

    /** Shows confirmation dialog with a custom component. Emits only for ok pressed. */
    showConfirmationComponent<TComponent extends DialogComponent>(content: DialogContent<TComponent>,): Observable<void> {
        return this.showDialog(content).pipe(filterDialogOk);
    }

    closeAllDialogs() {
        this.bsModalService.hide();
    }
}
