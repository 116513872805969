import { SoundService } from './sound.service';
import { ChatUnhandledMessageService } from './chat-unhandled-message.service';
import { AppState } from '../app.service';
import {
    AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewContainerRef
} from '@angular/core';
// Import components one by one cause an errors
import { PhoneService } from '../phone/phone.service';
import { SettingsService } from '../settings.service';
import { IntegrationService } from './integration.service';
import { NotificationService } from '../notifications/notification.service';
import {
    combineLatest, concatMap, EMPTY, Observable, Subscription
} from 'rxjs';
import { ChatToastService } from '../chat-toaster/chat-toast.service';

import {
    distinctUntilChanged, map, startWith, switchMap, take, withLatestFrom
} from 'rxjs/operators';
import { DeviceService } from '../phone/device.service';
import { ModalService } from '../modal/app-modal.service';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '../settings/local-storage-keys';
import { UnreadNotificationsService } from '@webclient/layout/unread-notifications.service';
import { LoginWelcome } from '../../generated/fetch';
import { SwUpdate } from '@angular/service-worker';
import { ExtendedSwPushService } from '@webclient/notifications/extended-sw-push.service';
import { observe, noEmitAndShowMessageOnError } from '@webclient/rx-utils';
import { AppsUtilityService } from '@webclient/services/apps-utility.service';
import { SilentModeService } from '@webclient/layout/silent-mode.service';
import { PromoteAppDialogComponent } from '@webclient/promoted-apps/promote-app-dialog.component';

@Component({
    selector: 'layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy, OnInit, AfterViewInit {
    private subs = new Subscription();

    private currentCallCount: number;
    public chatState$: Observable<boolean>;

    @LocalStorage(LocalStorageKeys.AsideFolded, false)
    asideFolded: boolean;

    @ViewChild('integrationUrl', { static: false })
    integrationUrl: ElementRef;

    constructor(public app: AppState,
                // private idleService: IdleService,
                private settingsService: SettingsService,
                private vcRef: ViewContainerRef,
                private chatToasterService: ChatUnhandledMessageService,
                private phoneService: PhoneService,
                private deviceService: DeviceService,
                private myPhoneService: MyPhoneService,
                private modalService: ModalService,
                private localStorage: LocalStorageService,
                private chatToastService: ChatToastService,
                private notificationService: NotificationService,
                private pushService: ExtendedSwPushService,
                private unreadNotifications: UnreadNotificationsService,
                private soundService: SoundService,
                private silentModeService: SilentModeService,
                private integrationService:IntegrationService,
                private swUpdate: SwUpdate,
                private appsUtilityService: AppsUtilityService) {
    }

    ngAfterViewInit() {
        this.subs.add(this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.conversationRemoved$)
        ).subscribe(removed => {
            this.chatToastService.removeChat(removed.IdConversation);
            this.notificationService.removeChatNotification(removed.IdConversation);
        }));

        this.subs.add(this.chatToasterService.isChatOnPbxEnabled$.subscribe((isOnPbxEnabled) => {
            if (!isOnPbxEnabled) {
                this.notificationService.removeChatNotification();
            }
        }
        ));

        this.subs.add(this.chatToasterService.getUnhandledMessages().pipe(
            withLatestFrom(this.settingsService.generalSettings$, this.silentModeService.silentMode$),
        ).subscribe(values => {
            const [message, settings, silentMode] = values;
            if (settings.showChatToaster) {
                if (!message.onChatPage) {
                    this.chatToastService.pop(message);
                }
            }
            this.notificationService.createChatNotification(message);
            this.playUnhandledChatMessageNotificationIfNeeded(settings.playNewChatMessageSound && !silentMode, settings.chatSoundFile);
        }));

        this.subs.add(this.phoneService.myCalls$.subscribe(c => this.currentCallCount = c.calls.size));

        this.subs.add(this.myPhoneService.myPhoneSession.pipe(
            take(1),
            map(session => ({
                hasSubscriptions: session.myInfo.Subscriptions?.Items?.length > 0,
                welcome: session.sessionParam.welcome
            }))
        ).subscribe(({ hasSubscriptions, welcome }) => {
            if (welcome === LoginWelcome.ProvisionSmartphone && !hasSubscriptions) {
                this.modalService.showComponent(PromoteAppDialogComponent);
            }
            else if (welcome === LoginWelcome.BrowserExtensionDeprecated) {
                this.appsUtilityService.showPWAModal(true);
            }
        }));
    }

    public ngOnInit(): void {
        this.subs.add(this.integrationService.openUrl$.subscribe(url => {
            this.integrationUrl.nativeElement.href = url;
            this.integrationUrl.nativeElement.click();
        }));

        this.chatState$ = this.chatToasterService.isChatOnPbxEnabled$;

        const unreadNotifications$: Observable<number> = combineLatest([
            this.unreadNotifications.combinedUnreadNotification$.pipe(distinctUntilChanged()),
            this.myPhoneService.myPhoneSession,
        ]).pipe(
            map(([unreadNotifications]) => unreadNotifications),
            startWith(0)
        );

        if (navigator?.setAppBadge) {
            this.subs.add(
                unreadNotifications$
                    .pipe(noEmitAndShowMessageOnError(this.modalService))
                    .subscribe((unreadNotifications) => {
                        navigator?.setAppBadge(Math.min(unreadNotifications, 99));
                    })
            );
        }

        if (this.swUpdate.isEnabled) {
            this.subs.add(this.myPhoneService.myPhoneSession.pipe(
                distinctUntilChanged()
            ).subscribe(() => {
                console.log('checking for pwa update...');
                this.swUpdate.checkForUpdate();
            }));
        }

        this.pushService.isEnabled$.pipe(
            switchMap(isEnabled => {
                if (isEnabled) {
                    const pushDisabled$ = observe(this.localStorage, LocalStorageKeys.PushDisabled, false);
                    return combineLatest([this.myPhoneService.myPhoneSession, pushDisabled$, this.pushService.permissionState$]).pipe(
                        concatMap(([session, pushDisabled, permissionState]) => {
                            if (pushDisabled || permissionState !== 'granted') {
                                return this.pushService.pushUnsubscribe(session);
                            }
                            else {
                                return this.pushService.pushSubscribe(session);
                            }
                        })
                    );
                }
                else {
                    return EMPTY;
                }
            })
        ).subscribe();
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }

    private playUnhandledChatMessageNotificationIfNeeded(playSound: boolean, notificationName: string): void {
        const playMessages = playSound && this.currentCallCount === 0;
        if (playMessages) {
            this.soundService.playNotificationByName(notificationName);
        }
    }
}
