import {
    map, shareReplay, startWith, take
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConferenceWrapper } from '../conference-wrapper';
import { ConferenceViewService } from '../conference-view/conference-view.service';
import { PrivateConferenceService } from '../private-conference/private-conference.service';
import dayjs from 'dayjs';

import { combineLatest, interval, Observable } from 'rxjs';

// C# System.DateTime.MaxValue equivanlent in javascript
// http://stackoverflow.com/questions/36934527/c-sharp-datetime-maxvalue-equivanlent-in-javascript
const mDate = new Date(9999, 12, 31, 23, 59, 59, 9999999);
const maxDate = dayjs(mDate);

@Injectable({
    providedIn: 'root'
})
export class MeetingListService {
    readonly allConferences$: Observable<ConferenceWrapper[]>;
    readonly anyConferences$: Observable<boolean>;
    readonly anyActivePrivateOrAudio$: Observable<boolean>;
    readonly sortedConferences$: Observable<ConferenceWrapper[]>;

    constructor(audioConferenceService: ConferenceViewService,
        privateConferenceService: PrivateConferenceService) {
        const audioConferences$ = audioConferenceService.audioConferences$;
        const privateConference$ = privateConferenceService.privateConference$;

        // TODO: mix with timer and smart sorting
        this.allConferences$ = combineLatest([privateConference$, audioConferences$]).pipe(
            map(x => {
                const [privateConference, audioConferences] = x;
                return privateConference.concat(audioConferences);
            }));

        this.anyConferences$ = this.allConferences$.pipe(map((x: ConferenceWrapper[]) => {
            return x.length > 0;
        }));

        this.anyActivePrivateOrAudio$ = this.allConferences$.pipe(map((x: ConferenceWrapper[]) => {
            return x.some(conf => conf.isActive && (conf.isPrivate || conf.isAudio));
        }));

        const sortByTime = (list : ConferenceWrapper[], now : dayjs.Dayjs) => {
            list.sort((a, b) => MeetingListService.conferencesComparator(a, b, now));
        };

        // Sorting dependent on current time, so mix with timer
        this.sortedConferences$ = combineLatest([this.allConferences$, interval(10000).pipe(startWith(0))]).pipe(
            map(x => {
                const [list] = x;

                const now = dayjs();
                sortByTime(list, now);
                return list;
            }),
            shareReplay({ refCount: true, bufferSize: 1 }));
    }

    checkAnyConferences(): Observable<boolean> {
        return this.anyConferences$.pipe(take(1));
    }

    // Normalize for easier debugging
    static normalize(value: number) {
        if (value < 0) {
            return -1;
        }

        if (value > 0) {
            return 1;
        }

        return 0;
    }

    testForConferencesComparator(x: ConferenceWrapper, y: ConferenceWrapper, now: dayjs.Dayjs) {
        return MeetingListService.conferencesComparator(x, y, now);
    }

    // / Comparer for confernces, the result of sorting should like this:
    // /
    // / F      - Nearest future
    // / FF
    // / FFF
    // / FFFF   - Far away in future
    // / -----  - Virtual border (current time)
    // / P      - Nearest past
    // / PP
    // / PPP
    // / PPPP   - Far away in the past
    static conferencesComparator(x: ConferenceWrapper, y: ConferenceWrapper, now: dayjs.Dayjs) {
        const leftConf = x;
        const rightConf = y;

        if ((leftConf == null) || (rightConf == null)) {
            return 0;
        }

        // Start at can be null for example for private conference
        const leftStartAt = leftConf.startAtDate || maxDate;
        const rightStartAt = rightConf.startAtDate || maxDate;

        // ---- Active conferences on top
        if (leftConf.isActive && rightConf.isActive) {
            return MeetingListService.normalize(leftStartAt.diff(rightStartAt));
        }

        if (leftConf.isActive) {
            return -1;
        }

        if (rightConf.isActive) {
            return 1;
        }
        // ----

        if (leftStartAt.isSameOrAfter(now) && rightStartAt.isSameOrAfter(now)) {
            // if both conferences starts in future compare them by date
            // Show nearest conferences first(on top)
            return MeetingListService.normalize(leftStartAt.diff(rightStartAt)); // * (-1);
        }

        if (leftStartAt.isSameOrBefore(now) && rightStartAt.isSameOrBefore(now)) {
            // if both conferences starts in future compare them by date
            // Show nearest conferences first(on top)
            return MeetingListService.normalize(rightStartAt.diff(leftStartAt));// * (-1);
        }

        if (leftStartAt.isAfter(now) && rightStartAt.isBefore(now)) {
            return -1;
        }

        return 1;
    }
}
