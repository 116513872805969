import { Injectable } from '@angular/core';
import {
    concat,
    defer,
    Observable,
    of, race, ReplaySubject, Subject,
    switchMap, throwError
} from 'rxjs';
import { AuthResponse, TokenApiService } from '@api';
import {
    delay, share, take
} from 'rxjs/operators';
import { appName } from '@webclient/worker/fingerprint';

export const SecondsInMin = 60000;

@Injectable({
    providedIn: 'root'
})
export class TokenService {
    readonly accessToken$: Observable<string>;

    private readonly _invalidate$ = new Subject<string>();

    constructor(private refreshService: TokenApiService) {
        this.accessToken$ = defer(() => this.refreshService.tokenToken('refresh_token', undefined, appName)).pipe(
            switchMap(accessToken => {
                if (accessToken?.access_token) {
                    return concat(
                        of(accessToken?.access_token),
                        // Throw expiration error in 30 minutes
                        race(
                            of('expired').pipe(
                                delay((accessToken.expires_in * SecondsInMin) / 2),
                            ),
                            this._invalidate$
                        ).pipe(
                            switchMap(err => throwError(() => new Error(err)))
                        )
                    );
                }
                else {
                    return throwError(() => new Error('Server returned empty access token'));
                }
            }),
            share({
                connector: () => new ReplaySubject<string>(1),
                resetOnComplete: false,
                resetOnRefCountZero: false
            }),
            // Just in case to indicate we expect only one emit from here
            take(1)
        );
    }

    public onLogin(response: AuthResponse|undefined): void {
    }

    public invalidate(): void {
        this._invalidate$.next('logout');
    }
}
