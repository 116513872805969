import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../environments/environment';
import { isValid } from 'ipaddr.js';

@Component({
    selector: 'app-push-status-disclaimer',
    template: `
        <div *ngIf="insecure" class="d-flex flex-row gap-2 py-1 justify-content-center align-items-center">
            <span [translate]="'_i18n.3CXRequiresSecureConnection'"></span>
        </div>
  `,
    styles: [`
        :host {
            background-color: red;
            color: white;
            font-weight: bold;
        }
    `],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PushStatusDisclaimerComponent {
    readonly insecure: boolean;
    constructor(
    ) {
        let hostname = window.location.hostname;
        if (hostname.startsWith('[') && hostname.endsWith(']')) {
            hostname = hostname.substring(1, hostname.length - 1);
        }
        this.insecure = environment.production && (
            window.location.protocol === 'http:' || isValid(hostname) || hostname.toLowerCase() === 'localhost'
        );
    }
}
