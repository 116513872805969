import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import {
    filter, map, shareReplay
} from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { defaultTeamLink } from '@webclient/settings/local-storage-keys';

@Injectable({
    providedIn: 'root'
})
export class NavigationHistoryService {
    readonly historySize = 20;
    private navigationSubscription?: Subscription;
    private readonly historySubj = new BehaviorSubject<string[]>([]);
    public readonly history$: Observable<string[]>;
    public readonly previousUrl$: Observable<string | undefined>;
    constructor(private router: Router) {
        this.history$ = this.historySubj.asObservable().pipe(
            shareReplay({ refCount: true, bufferSize: 1 })
        );
        this.previousUrl$ = this.history$.pipe(
            map((history) => this.previousUrl(history))
        );
    }

    public navigatePreviousUrl$(defaultRedirect = defaultTeamLink, extras: NavigationExtras | undefined = undefined): Observable<boolean> {
        const currentHistory = this.getHistory();
        // remove last item from navigation history
        const historyUpdated = currentHistory.slice(0, currentHistory.length - 1);
        this.historySubj.next(historyUpdated);
        if (historyUpdated.length > 0) {
            return from(this.router.navigateByUrl(historyUpdated[historyUpdated.length - 1], extras));
        }
        else {
            return from(this.router.navigate(defaultRedirect, extras));
        }
    }

    public startNavigationHistory() {
        if (this.navigationSubscription) {
            return;
        }

        this.navigationSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
        ).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                const currentHistory = this.getHistory();

                // do not add url if it is already in the last position
                if (currentHistory[currentHistory.length - 1] !== event.urlAfterRedirects) {
                    // if max size reached remove first element and add 1 to the end
                    const newHistory = currentHistory.length < this.historySize ? currentHistory : currentHistory.slice(1);
                    this.historySubj.next([...newHistory, event.urlAfterRedirects]);
                }
            }
        });
    }

    public stopNavigationHistory() {
        this.navigationSubscription?.unsubscribe();
        this.navigationSubscription = undefined;
        this.historySubj.next([]);
    }

    public getHistory(): string[] {
        return this.historySubj.value;
    }

    public getPreviousUrl(): string|undefined {
        return this.previousUrl(this.historySubj.value);
    }

    private previousUrl(history: string[]): string | undefined {
        if (history.length > 1) {
            return history[history.length - 2];
        }
        return undefined;
    }
}
