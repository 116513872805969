import { Param } from '@xapi';

export function odataParamEncoder(param: Param) {
    // Copy from defaultEncodeParam
    let value = param.value;

    if (param.dataType === 'string' && typeof param.value === 'string') {
        value = `'${param.value.replaceAll("'", "''")}'`;
    }
    else
        if (param.dataFormat === 'date-time' && param.value instanceof Date) {
            value = (param.value).toISOString();
        }

    return encodeURIComponent(String(value));
}
