import { Injectable } from '@angular/core';
import { isChrome, isEdge, isMacOS, isMobileDevice, isWindows } from '@webclient/browser-type';
import { ShowAppsDialogComponent } from '@webclient/standalones/show-apps-dialog/show-apps-dialog.component';
import { ModalService } from '@webclient/modal/app-modal.service';
import { MediaMatcher } from '@angular/cdk/layout';
import { BehaviorSubject, fromEvent, merge, Observable, of, Subscription, switchMap } from 'rxjs';
import { catchError, map, shareReplay, take } from 'rxjs/operators';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { MyUserApiService, UsersApiService } from '@xapi';

@Injectable({
    providedIn: 'root'
})
export class AppsUtilityService {
    private isPwaStandAloneQuery: MediaQueryList;
    private pwaInstallPrompt: BehaviorSubject<any|null> = new BehaviorSubject<any|null>(null);
    isPwaInstallAvailable$: Observable<boolean>;
    private pwaInstallationEventsSubscription: Subscription;

    readonly isWindows = isWindows;

    constructor(
        private myUserApi: MyUserApiService,
        private usersApi: UsersApiService,
        private modalService: ModalService,
        private myPhoneService: MyPhoneService,
        private mdr: MediaMatcher
    ) {
        this.isPwaStandAloneQuery = this.mdr.matchMedia('(display-mode: standalone)');
        this.isPwaInstallAvailable$ = this.pwaInstallPrompt.asObservable().pipe(
            map((prompt) => !!prompt),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }

    getProvisionUrl$(userId?: number) {
        return this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => (
                userId
                    ? this.usersApi.generateProvLink({ id: userId })
                    : this.myUserApi.myUserGenerateProvLink()
            ).pipe(
                catchError(() => of(undefined)),
                map(link => {
                    if (link && session.domainUrl) {
                        const domain = new URL(session.domainUrl);
                        return (domain.protocol + '//' + domain.host + link.value).toUpperCase();
                    }
                    return '';
                }))
            )
        );
    }

    getWindowsProvisionUrl$(userId?: number) {
        return this.getProvisionUrl$(userId).pipe(map(url => `tcx+app:${url}`));
    }

    listenPwaInstallationEvents() {
        if (this.pwaInstallationEventsSubscription) {
            return;
        }
        this.pwaInstallationEventsSubscription = merge(
            fromEvent<any>(window, 'beforeinstallprompt').pipe(
                map((installPrompt) => installPrompt)
            ),
            fromEvent<any>(window, 'appinstalled').pipe(
                map(() => null)
            ),
        ).subscribe((installPrompt) => {
            this.pwaInstallPrompt.next(installPrompt ?? null);
        });
    }

    showPWAModal(showCeDeprecation = false) {
        if (this.isProgressiveWebAppVisible()) {
            this.modalService.showComponent(ShowAppsDialogComponent, { initialState: { showCeDeprecation } });
        }
    }

    firePwaInstallation() {
        // https://www.amitmerchant.com/adding-custom-install-button-in-progressive-web-apps/
        // https://developer.mozilla.org/en-US/docs/Web/API/BeforeInstallPromptEvent
        // https://web.dev/customize-install/
        this.pwaInstallPrompt.pipe(
            take(1),
            switchMap((prompt :any) => {
                if (prompt) {
                    prompt.prompt();
                    return prompt.userChoice;
                }
                else {
                    return Promise.resolve(null);
                }
            }),
            catchError((err: unknown) => {
                console.log(err);
                return of(null);
            })
        ).subscribe((result :any) => {
            if (result) {
                const { outcome } = result;
                if (outcome && outcome === 'accepted') {
                    this.pwaInstallPrompt.next(null);
                }
            }
        });
    }

    isPwaInstallAvailable() : boolean {
        return !!this.pwaInstallPrompt.getValue();
    }

    isDesktopAppSupportedInOs() {
        return (isWindows || isMacOS);
    }

    isProgressiveWebAppVisible() {
        return !isMobileDevice() && (isChrome || isEdge) && !this.isPwaStandAlone() && this.isPwaInstallAvailable();
    }

    isChromeExtAppVisible() {
        return isChrome && !isMobileDevice();
    }

    isEdgeExtAppVisible() {
        return isEdge && !isMobileDevice();
    }

    isWindowsPhoneAppVisible() {
        return isWindows;
    }

    // for more look at https://web.dev/customize-install/
    isPwaStandAlone(): boolean {
        const isAndroid = document.referrer.startsWith('android-app://');
        const isStandalone = (navigator as any).standalone || this.isPwaStandAloneQuery.matches;
        return isAndroid || isStandalone;
    }
}
