import { Injectable } from '@angular/core';
import { ModalService } from '../../modal/app-modal.service';
import { CalendarServiceType } from '@myphone';
import { CalendarUtils } from '../calendar-utils';
import {
    downloadURL,
    openNewWindowWhenPopupIsBlocked,
    openUrlWithMessageBoxIfPopupBlocked
} from '../../shared/utils.service';

@Injectable()
export class ExternalCalendarService {
    constructor(private modalService: ModalService) {
    }

    private trimNameOfConference(name: string) {
        return name.substring(0, 80);
    }

    openExternalCalendar(calendarType: CalendarServiceType, startDate: Date, endDate: Date, nameOfConference: string,
        description: string) {
        if ((calendarType === CalendarServiceType.Google || calendarType === CalendarServiceType.Office365 || calendarType === CalendarServiceType.Outlook)) {
            const url = CalendarUtils.buildExternalCalendarUrl(calendarType, startDate, endDate, nameOfConference, description);
            const isOpened = openNewWindowWhenPopupIsBlocked(url);
            if (!isOpened) {
                // Convert calendar type id(number) to text for message box
                const calendarTypeText = ({
                    1: 'Google Calendar',
                    2: 'Office365 Calendar',
                    3: 'Outlook Calendar',
                } as any)[calendarType];

                openUrlWithMessageBoxIfPopupBlocked(url, this.modalService, '_i18n.PopupBeingBlocked', '_i18n.OpenCalendar', { calendarType: calendarTypeText });
            }
        }
        else if (calendarType === CalendarServiceType.IcsFile) {
            if (CalendarUtils.isIOS(window)) {
                const calendar = CalendarUtils.buildIcsData(startDate, endDate, nameOfConference, description);
                const url = 'data:text/calendar,' + encodeURIComponent(calendar);

                openUrlWithMessageBoxIfPopupBlocked(url, this.modalService, '_i18n.PressOkToAddMeeting', '_i18n.AddMeetingToCalendar');
            }
            else {
                const blob = CalendarUtils.buildIcsFile(startDate, endDate, nameOfConference, description);
                const url = window.URL.createObjectURL(blob);
                downloadURL(url, `${this.trimNameOfConference(nameOfConference)}.ics`);
            }
        }
    }
}
