/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { BatchDelete200Response } from '../model/batch-delete200-response';
// @ts-ignore
import { CollectionOfUser } from '../model/collection-of-user';
// @ts-ignore
import { GenerateApiKey200Response } from '../model/generate-api-key200-response';
// @ts-ignore
import { GetAudioFiles200Response } from '../model/get-audio-files200-response';
// @ts-ignore
import { GetCanCreateBackup200Response } from '../model/get-can-create-backup200-response';
// @ts-ignore
import { GetMonitorStatus200Response } from '../model/get-monitor-status200-response';
// @ts-ignore
import { GetPhoneRegistrars200Response } from '../model/get-phone-registrars200-response';
// @ts-ignore
import { MakeCallUserRecordGreetingRequestBody } from '../model/make-call-user-record-greeting-request-body';
// @ts-ignore
import { PbxFirstAvailableNumber } from '../model/pbx-first-available-number';
// @ts-ignore
import { PbxForwardingProfileCollectionResponse } from '../model/pbx-forwarding-profile-collection-response';
// @ts-ignore
import { PbxGreetingCollectionResponse } from '../model/pbx-greeting-collection-response';
// @ts-ignore
import { PbxODataErrorsODataError } from '../model/pbx-o-data-errors-o-data-error';
// @ts-ignore
import { PbxPhoneRegistrar } from '../model/pbx-phone-registrar';
// @ts-ignore
import { PbxUser } from '../model/pbx-user';
// @ts-ignore
import { PbxUserCollectionResponse } from '../model/pbx-user-collection-response';
// @ts-ignore
import { PbxUserGroupCollectionResponse } from '../model/pbx-user-group-collection-response';
// @ts-ignore
import { RegenerateRequestBody } from '../model/regenerate-request-body';
// @ts-ignore
import { SetMonitorStatusRequestBody } from '../model/set-monitor-status-request-body';
// @ts-ignore
import { UsersBatchDeleteRequestBody } from '../model/users-batch-delete-request-body';
// @ts-ignore
import { UsersBulkUpdateRequestBody } from '../model/users-bulk-update-request-body';
// @ts-ignore
import { UsersGetDuplicatedEmailsRequestBody } from '../model/users-get-duplicated-emails-request-body';
// @ts-ignore
import { UsersGetPhoneRegistrarsRequestBody } from '../model/users-get-phone-registrars-request-body';
// @ts-ignore
import { UsersRebootPhoneRequestBody } from '../model/users-reboot-phone-request-body';
// @ts-ignore
import { UsersRegeneratePasswordsRequestBody } from '../model/users-regenerate-passwords-request-body';
// @ts-ignore
import { UsersReprovisionPhoneRequestBody } from '../model/users-reprovision-phone-request-body';
// @ts-ignore
import { UsersUpgradePhoneRequestBody } from '../model/users-upgrade-phone-request-body';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface BatchDeleteRequestParams {
    /** Action parameters */
    usersBatchDeleteRequestBody: UsersBatchDeleteRequestBody;
}

export interface BulkUpdateRequestParams {
    /** Action parameters */
    usersBulkUpdateRequestBody: UsersBulkUpdateRequestBody;
}

export interface CreateUserRequestParams {
    /** New entity */
    pbxUser: PbxUser;
}

export interface DeleteUserRequestParams {
    /** The unique identifier of User */
    id: number;
    /** ETag */
    ifMatch?: string;
}

export interface DownloadGreetingRequestParams {
    /** Usage: userId&#x3D;{userId} */
    userId: number;
    /** Usage: fileName&#x3D;{fileName} */
    fileName: string;
}

export interface ExportExtensionsRequestParams {
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface GenerateProvLinkRequestParams {
    /** The unique identifier of User */
    id: number;
}

export interface GetDuplicatedEmailsRequestParams {
    /** Action parameters */
    usersGetDuplicatedEmailsRequestBody: UsersGetDuplicatedEmailsRequestBody;
}

export interface GetMonitorStatusRequestParams {
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
}

export interface GetPhoneRegistrarRequestParams {
    /** Usage: mac&#x3D;{mac} */
    mac: string;
}

export interface GetPhoneRegistrarsRequestParams {
    /** Action parameters */
    usersGetPhoneRegistrarsRequestBody: UsersGetPhoneRegistrarsRequestBody;
}

export interface GetPhoneSecretRequestParams {
    /** The unique identifier of User */
    id: number;
}

export interface GetUserRequestParams {
    /** The unique identifier of User */
    id: number;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface GetUserByNumberRequestParams {
    /** Usage: number&#x3D;{number} */
    number: string;
}

export interface HasDuplicatedEmailRequestParams {
    /** The unique identifier of User */
    id: number;
}

export interface ListForwardingProfilesRequestParams {
    /** The unique identifier of User */
    id: number;
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ListGreetingsRequestParams {
    /** The unique identifier of User */
    id: number;
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ListGroupsRequestParams {
    /** The unique identifier of User */
    id: number;
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ListUserRequestParams {
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface MakeCallUserRecordGreetingRequestParams {
    /** The unique identifier of User */
    id: number;
    makeCallUserRecordGreetingRequestBody?: MakeCallUserRecordGreetingRequestBody;
}

export interface RebootPhoneRequestParams {
    /** Action parameters */
    usersRebootPhoneRequestBody: UsersRebootPhoneRequestBody;
}

export interface RegenerateRequestParams {
    /** The unique identifier of User */
    id: number;
    regenerateRequestBody?: RegenerateRequestBody;
}

export interface RegeneratePasswordsRequestParams {
    /** Action parameters */
    usersRegeneratePasswordsRequestBody: UsersRegeneratePasswordsRequestBody;
}

export interface ReprovisionPhoneRequestParams {
    /** Action parameters */
    usersReprovisionPhoneRequestBody: UsersReprovisionPhoneRequestBody;
}

export interface SendWelcomeEmailRequestParams {
    /** The unique identifier of User */
    id: number;
}

export interface SetMonitorStatusRequestParams {
    /** The unique identifier of User */
    id: number;
    setMonitorStatusRequestBody?: SetMonitorStatusRequestBody;
}

export interface UpdateUserRequestParams {
    /** The unique identifier of User */
    id: number;
    /** New property values */
    pbxUser: PbxUser;
}

export interface UpgradePhoneRequestParams {
    /** Action parameters */
    usersUpgradePhoneRequestBody: UsersUpgradePhoneRequestBody;
}


@Injectable({
  providedIn: 'root'
})
export class UsersApiService {

    protected basePath = '/xapi/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Invoke action BatchDelete
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public batchDelete(requestParameters: BatchDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<BatchDelete200Response>;
    public batchDelete(requestParameters: BatchDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<BatchDelete200Response>>;
    public batchDelete(requestParameters: BatchDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<BatchDelete200Response>>;
    public batchDelete(requestParameters: BatchDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const usersBatchDeleteRequestBody = requestParameters.usersBatchDeleteRequestBody;
        if (usersBatchDeleteRequestBody === null || usersBatchDeleteRequestBody === undefined) {
            throw new Error('Required parameter usersBatchDeleteRequestBody was null or undefined when calling batchDelete.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.BatchDelete`;
        return this.httpClient.request<BatchDelete200Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: usersBatchDeleteRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public batchDeleteQuery(requestParameters: BatchDeleteRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public batchDeleteQuery(requestParameters: BatchDeleteRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public batchDeleteQuery(requestParameters: BatchDeleteRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public batchDeleteQuery(requestParameters: BatchDeleteRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const usersBatchDeleteRequestBody = requestParameters.usersBatchDeleteRequestBody;
        if (usersBatchDeleteRequestBody === null || usersBatchDeleteRequestBody === undefined) {
        throw new Error('Required parameter usersBatchDeleteRequestBody was null or undefined when calling batchDelete.');
        }

    return `/Users/Pbx.BatchDelete`;
}

    /**
     * Invoke action BulkUpdate
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public bulkUpdate(requestParameters: BulkUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public bulkUpdate(requestParameters: BulkUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public bulkUpdate(requestParameters: BulkUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public bulkUpdate(requestParameters: BulkUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const usersBulkUpdateRequestBody = requestParameters.usersBulkUpdateRequestBody;
        if (usersBulkUpdateRequestBody === null || usersBulkUpdateRequestBody === undefined) {
            throw new Error('Required parameter usersBulkUpdateRequestBody was null or undefined when calling bulkUpdate.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.BulkUpdate`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: usersBulkUpdateRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public bulkUpdateQuery(requestParameters: BulkUpdateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public bulkUpdateQuery(requestParameters: BulkUpdateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public bulkUpdateQuery(requestParameters: BulkUpdateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public bulkUpdateQuery(requestParameters: BulkUpdateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const usersBulkUpdateRequestBody = requestParameters.usersBulkUpdateRequestBody;
        if (usersBulkUpdateRequestBody === null || usersBulkUpdateRequestBody === undefined) {
        throw new Error('Required parameter usersBulkUpdateRequestBody was null or undefined when calling bulkUpdate.');
        }

    return `/Users/Pbx.BulkUpdate`;
}

    /**
     * Add new entity to Users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public createUser(requestParameters: CreateUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxUser>;
    public createUser(requestParameters: CreateUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxUser>>;
    public createUser(requestParameters: CreateUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxUser>>;
    public createUser(requestParameters: CreateUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const pbxUser = requestParameters.pbxUser;
        if (pbxUser === null || pbxUser === undefined) {
            throw new Error('Required parameter pbxUser was null or undefined when calling createUser.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users`;
        return this.httpClient.request<PbxUser>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pbxUser,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public createUserQuery(requestParameters: CreateUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public createUserQuery(requestParameters: CreateUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public createUserQuery(requestParameters: CreateUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public createUserQuery(requestParameters: CreateUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const pbxUser = requestParameters.pbxUser;
        if (pbxUser === null || pbxUser === undefined) {
        throw new Error('Required parameter pbxUser was null or undefined when calling createUser.');
        }

    return `/Users`;
}

    /**
     * Delete entity from Users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteUser(requestParameters: DeleteUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public deleteUser(requestParameters: DeleteUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public deleteUser(requestParameters: DeleteUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public deleteUser(requestParameters: DeleteUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling deleteUser.');
        }
        const ifMatch = requestParameters.ifMatch;

        let localVarHeaders = this.defaultHeaders;
        if (ifMatch !== undefined && ifMatch !== null) {
            localVarHeaders = localVarHeaders.set('If-Match', String(ifMatch));
        }

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
        return this.httpClient.request<any>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public deleteUserQuery(requestParameters: DeleteUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteUserQuery(requestParameters: DeleteUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteUserQuery(requestParameters: DeleteUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public deleteUserQuery(requestParameters: DeleteUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling deleteUser.');
        }
            const ifMatch = requestParameters.ifMatch;

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
}

    /**
     * Invoke function DownloadGreeting
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public downloadGreeting(requestParameters: DownloadGreetingRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<object>;
    public downloadGreeting(requestParameters: DownloadGreetingRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<object>>;
    public downloadGreeting(requestParameters: DownloadGreetingRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<object>>;
    public downloadGreeting(requestParameters: DownloadGreetingRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling downloadGreeting.');
        }
        const fileName = requestParameters.fileName;
        if (fileName === null || fileName === undefined) {
            throw new Error('Required parameter fileName was null or undefined when calling downloadGreeting.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.DownloadGreeting(userId=${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})},fileName=${this.configuration.encodeParam({name: "fileName", value: fileName, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
        return this.httpClient.request<object>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public downloadGreetingQuery(requestParameters: DownloadGreetingRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public downloadGreetingQuery(requestParameters: DownloadGreetingRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public downloadGreetingQuery(requestParameters: DownloadGreetingRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public downloadGreetingQuery(requestParameters: DownloadGreetingRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const userId = requestParameters.userId;
        if (userId === null || userId === undefined) {
        throw new Error('Required parameter userId was null or undefined when calling downloadGreeting.');
        }
            const fileName = requestParameters.fileName;
        if (fileName === null || fileName === undefined) {
        throw new Error('Required parameter fileName was null or undefined when calling downloadGreeting.');
        }

    return `/Users/Pbx.DownloadGreeting(userId=${this.configuration.encodeParam({name: "userId", value: userId, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})},fileName=${this.configuration.encodeParam({name: "fileName", value: fileName, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
}

    /**
     * Invoke function ExportExtensions
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public exportExtensions(requestParameters: ExportExtensionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<CollectionOfUser>;
    public exportExtensions(requestParameters: ExportExtensionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<CollectionOfUser>>;
    public exportExtensions(requestParameters: ExportExtensionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<CollectionOfUser>>;
    public exportExtensions(requestParameters: ExportExtensionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $select = requestParameters.$select;
        const $orderby = requestParameters.$orderby;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.ExportExtensions()`;
        return this.httpClient.request<CollectionOfUser>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public exportExtensionsQuery(requestParameters: ExportExtensionsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public exportExtensionsQuery(requestParameters: ExportExtensionsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public exportExtensionsQuery(requestParameters: ExportExtensionsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public exportExtensionsQuery(requestParameters: ExportExtensionsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $select = requestParameters.$select;
            const $orderby = requestParameters.$orderby;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Users/Pbx.ExportExtensions()`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke function GenerateProvLink
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public generateProvLink(requestParameters: GenerateProvLinkRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GenerateApiKey200Response>;
    public generateProvLink(requestParameters: GenerateProvLinkRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GenerateApiKey200Response>>;
    public generateProvLink(requestParameters: GenerateProvLinkRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GenerateApiKey200Response>>;
    public generateProvLink(requestParameters: GenerateProvLinkRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling generateProvLink.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.GenerateProvLink()`;
        return this.httpClient.request<GenerateApiKey200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public generateProvLinkQuery(requestParameters: GenerateProvLinkRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public generateProvLinkQuery(requestParameters: GenerateProvLinkRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public generateProvLinkQuery(requestParameters: GenerateProvLinkRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public generateProvLinkQuery(requestParameters: GenerateProvLinkRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling generateProvLink.');
        }

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.GenerateProvLink()`;
}

    /**
     * Invoke action GetDuplicatedEmails
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getDuplicatedEmails(requestParameters: GetDuplicatedEmailsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetAudioFiles200Response>;
    public getDuplicatedEmails(requestParameters: GetDuplicatedEmailsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetAudioFiles200Response>>;
    public getDuplicatedEmails(requestParameters: GetDuplicatedEmailsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetAudioFiles200Response>>;
    public getDuplicatedEmails(requestParameters: GetDuplicatedEmailsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const usersGetDuplicatedEmailsRequestBody = requestParameters.usersGetDuplicatedEmailsRequestBody;
        if (usersGetDuplicatedEmailsRequestBody === null || usersGetDuplicatedEmailsRequestBody === undefined) {
            throw new Error('Required parameter usersGetDuplicatedEmailsRequestBody was null or undefined when calling getDuplicatedEmails.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.GetDuplicatedEmails`;
        return this.httpClient.request<GetAudioFiles200Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: usersGetDuplicatedEmailsRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getDuplicatedEmailsQuery(requestParameters: GetDuplicatedEmailsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getDuplicatedEmailsQuery(requestParameters: GetDuplicatedEmailsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getDuplicatedEmailsQuery(requestParameters: GetDuplicatedEmailsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getDuplicatedEmailsQuery(requestParameters: GetDuplicatedEmailsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const usersGetDuplicatedEmailsRequestBody = requestParameters.usersGetDuplicatedEmailsRequestBody;
        if (usersGetDuplicatedEmailsRequestBody === null || usersGetDuplicatedEmailsRequestBody === undefined) {
        throw new Error('Required parameter usersGetDuplicatedEmailsRequestBody was null or undefined when calling getDuplicatedEmails.');
        }

    return `/Users/Pbx.GetDuplicatedEmails`;
}

    /**
     * Invoke function GetFirstAvailableExtensionNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFirstAvailableExtensionNumber(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxFirstAvailableNumber>;
    public getFirstAvailableExtensionNumber(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxFirstAvailableNumber>>;
    public getFirstAvailableExtensionNumber(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxFirstAvailableNumber>>;
    public getFirstAvailableExtensionNumber(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.GetFirstAvailableExtensionNumber()`;
        return this.httpClient.request<PbxFirstAvailableNumber>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getFirstAvailableExtensionNumberQuery(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getFirstAvailableExtensionNumberQuery(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getFirstAvailableExtensionNumberQuery(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getFirstAvailableExtensionNumberQuery(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {

    return `/Users/Pbx.GetFirstAvailableExtensionNumber()`;
}

    /**
     * Invoke function GetFirstAvailableHotdeskingNumber
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getFirstAvailableHotdeskingNumber(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxFirstAvailableNumber>;
    public getFirstAvailableHotdeskingNumber(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxFirstAvailableNumber>>;
    public getFirstAvailableHotdeskingNumber(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxFirstAvailableNumber>>;
    public getFirstAvailableHotdeskingNumber(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.GetFirstAvailableHotdeskingNumber()`;
        return this.httpClient.request<PbxFirstAvailableNumber>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getFirstAvailableHotdeskingNumberQuery(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getFirstAvailableHotdeskingNumberQuery(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getFirstAvailableHotdeskingNumberQuery(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getFirstAvailableHotdeskingNumberQuery(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {

    return `/Users/Pbx.GetFirstAvailableHotdeskingNumber()`;
}

    /**
     * Invoke function GetMonitorStatus
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMonitorStatus(requestParameters: GetMonitorStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetMonitorStatus200Response>;
    public getMonitorStatus(requestParameters: GetMonitorStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetMonitorStatus200Response>>;
    public getMonitorStatus(requestParameters: GetMonitorStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetMonitorStatus200Response>>;
    public getMonitorStatus(requestParameters: GetMonitorStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.GetMonitorStatus()`;
        return this.httpClient.request<GetMonitorStatus200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getMonitorStatusQuery(requestParameters: GetMonitorStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMonitorStatusQuery(requestParameters: GetMonitorStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMonitorStatusQuery(requestParameters: GetMonitorStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMonitorStatusQuery(requestParameters: GetMonitorStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }

    return `/Users/Pbx.GetMonitorStatus()`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke function GetPhoneRegistrar
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPhoneRegistrar(requestParameters: GetPhoneRegistrarRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxPhoneRegistrar>;
    public getPhoneRegistrar(requestParameters: GetPhoneRegistrarRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxPhoneRegistrar>>;
    public getPhoneRegistrar(requestParameters: GetPhoneRegistrarRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxPhoneRegistrar>>;
    public getPhoneRegistrar(requestParameters: GetPhoneRegistrarRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const mac = requestParameters.mac;
        if (mac === null || mac === undefined) {
            throw new Error('Required parameter mac was null or undefined when calling getPhoneRegistrar.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.GetPhoneRegistrar(mac=${this.configuration.encodeParam({name: "mac", value: mac, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
        return this.httpClient.request<PbxPhoneRegistrar>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getPhoneRegistrarQuery(requestParameters: GetPhoneRegistrarRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getPhoneRegistrarQuery(requestParameters: GetPhoneRegistrarRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getPhoneRegistrarQuery(requestParameters: GetPhoneRegistrarRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getPhoneRegistrarQuery(requestParameters: GetPhoneRegistrarRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const mac = requestParameters.mac;
        if (mac === null || mac === undefined) {
        throw new Error('Required parameter mac was null or undefined when calling getPhoneRegistrar.');
        }

    return `/Users/Pbx.GetPhoneRegistrar(mac=${this.configuration.encodeParam({name: "mac", value: mac, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
}

    /**
     * Invoke action GetPhoneRegistrars
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPhoneRegistrars(requestParameters: GetPhoneRegistrarsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetPhoneRegistrars200Response>;
    public getPhoneRegistrars(requestParameters: GetPhoneRegistrarsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetPhoneRegistrars200Response>>;
    public getPhoneRegistrars(requestParameters: GetPhoneRegistrarsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetPhoneRegistrars200Response>>;
    public getPhoneRegistrars(requestParameters: GetPhoneRegistrarsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const usersGetPhoneRegistrarsRequestBody = requestParameters.usersGetPhoneRegistrarsRequestBody;
        if (usersGetPhoneRegistrarsRequestBody === null || usersGetPhoneRegistrarsRequestBody === undefined) {
            throw new Error('Required parameter usersGetPhoneRegistrarsRequestBody was null or undefined when calling getPhoneRegistrars.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.GetPhoneRegistrars`;
        return this.httpClient.request<GetPhoneRegistrars200Response>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: usersGetPhoneRegistrarsRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getPhoneRegistrarsQuery(requestParameters: GetPhoneRegistrarsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getPhoneRegistrarsQuery(requestParameters: GetPhoneRegistrarsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getPhoneRegistrarsQuery(requestParameters: GetPhoneRegistrarsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getPhoneRegistrarsQuery(requestParameters: GetPhoneRegistrarsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const usersGetPhoneRegistrarsRequestBody = requestParameters.usersGetPhoneRegistrarsRequestBody;
        if (usersGetPhoneRegistrarsRequestBody === null || usersGetPhoneRegistrarsRequestBody === undefined) {
        throw new Error('Required parameter usersGetPhoneRegistrarsRequestBody was null or undefined when calling getPhoneRegistrars.');
        }

    return `/Users/Pbx.GetPhoneRegistrars`;
}

    /**
     * Invoke function GetPhoneSecret
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getPhoneSecret(requestParameters: GetPhoneSecretRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GenerateApiKey200Response>;
    public getPhoneSecret(requestParameters: GetPhoneSecretRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GenerateApiKey200Response>>;
    public getPhoneSecret(requestParameters: GetPhoneSecretRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GenerateApiKey200Response>>;
    public getPhoneSecret(requestParameters: GetPhoneSecretRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getPhoneSecret.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.GetPhoneSecret()`;
        return this.httpClient.request<GenerateApiKey200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getPhoneSecretQuery(requestParameters: GetPhoneSecretRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getPhoneSecretQuery(requestParameters: GetPhoneSecretRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getPhoneSecretQuery(requestParameters: GetPhoneSecretRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getPhoneSecretQuery(requestParameters: GetPhoneSecretRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling getPhoneSecret.');
        }

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.GetPhoneSecret()`;
}

    /**
     * Get entity from Users by key
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUser(requestParameters: GetUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxUser>;
    public getUser(requestParameters: GetUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxUser>>;
    public getUser(requestParameters: GetUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxUser>>;
    public getUser(requestParameters: GetUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling getUser.');
        }
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
        return this.httpClient.request<PbxUser>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getUserQuery(requestParameters: GetUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getUserQuery(requestParameters: GetUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getUserQuery(requestParameters: GetUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getUserQuery(requestParameters: GetUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling getUser.');
        }
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke function GetByNumber
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getUserByNumber(requestParameters: GetUserByNumberRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxUser>;
    public getUserByNumber(requestParameters: GetUserByNumberRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxUser>>;
    public getUserByNumber(requestParameters: GetUserByNumberRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxUser>>;
    public getUserByNumber(requestParameters: GetUserByNumberRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const number = requestParameters.number;
        if (number === null || number === undefined) {
            throw new Error('Required parameter number was null or undefined when calling getUserByNumber.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.GetByNumber(number=${this.configuration.encodeParam({name: "number", value: number, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
        return this.httpClient.request<PbxUser>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getUserByNumberQuery(requestParameters: GetUserByNumberRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getUserByNumberQuery(requestParameters: GetUserByNumberRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getUserByNumberQuery(requestParameters: GetUserByNumberRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getUserByNumberQuery(requestParameters: GetUserByNumberRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const number = requestParameters.number;
        if (number === null || number === undefined) {
        throw new Error('Required parameter number was null or undefined when calling getUserByNumber.');
        }

    return `/Users/Pbx.GetByNumber(number=${this.configuration.encodeParam({name: "number", value: number, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})})`;
}

    /**
     * Invoke function HasDuplicatedEmail
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public hasDuplicatedEmail(requestParameters: HasDuplicatedEmailRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GetCanCreateBackup200Response>;
    public hasDuplicatedEmail(requestParameters: HasDuplicatedEmailRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GetCanCreateBackup200Response>>;
    public hasDuplicatedEmail(requestParameters: HasDuplicatedEmailRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GetCanCreateBackup200Response>>;
    public hasDuplicatedEmail(requestParameters: HasDuplicatedEmailRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling hasDuplicatedEmail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.HasDuplicatedEmail()`;
        return this.httpClient.request<GetCanCreateBackup200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public hasDuplicatedEmailQuery(requestParameters: HasDuplicatedEmailRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public hasDuplicatedEmailQuery(requestParameters: HasDuplicatedEmailRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public hasDuplicatedEmailQuery(requestParameters: HasDuplicatedEmailRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public hasDuplicatedEmailQuery(requestParameters: HasDuplicatedEmailRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling hasDuplicatedEmail.');
        }

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.HasDuplicatedEmail()`;
}

    /**
     * Get ForwardingProfiles from Users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listForwardingProfiles(requestParameters: ListForwardingProfilesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxForwardingProfileCollectionResponse>;
    public listForwardingProfiles(requestParameters: ListForwardingProfilesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxForwardingProfileCollectionResponse>>;
    public listForwardingProfiles(requestParameters: ListForwardingProfilesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxForwardingProfileCollectionResponse>>;
    public listForwardingProfiles(requestParameters: ListForwardingProfilesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listForwardingProfiles.');
        }
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/ForwardingProfiles`;
        return this.httpClient.request<PbxForwardingProfileCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listForwardingProfilesQuery(requestParameters: ListForwardingProfilesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listForwardingProfilesQuery(requestParameters: ListForwardingProfilesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listForwardingProfilesQuery(requestParameters: ListForwardingProfilesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listForwardingProfilesQuery(requestParameters: ListForwardingProfilesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling listForwardingProfiles.');
        }
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/ForwardingProfiles`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Get Greetings from Users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listGreetings(requestParameters: ListGreetingsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxGreetingCollectionResponse>;
    public listGreetings(requestParameters: ListGreetingsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxGreetingCollectionResponse>>;
    public listGreetings(requestParameters: ListGreetingsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxGreetingCollectionResponse>>;
    public listGreetings(requestParameters: ListGreetingsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listGreetings.');
        }
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Greetings`;
        return this.httpClient.request<PbxGreetingCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listGreetingsQuery(requestParameters: ListGreetingsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listGreetingsQuery(requestParameters: ListGreetingsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listGreetingsQuery(requestParameters: ListGreetingsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listGreetingsQuery(requestParameters: ListGreetingsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling listGreetings.');
        }
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Greetings`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Get Groups from Users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listGroups(requestParameters: ListGroupsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxUserGroupCollectionResponse>;
    public listGroups(requestParameters: ListGroupsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxUserGroupCollectionResponse>>;
    public listGroups(requestParameters: ListGroupsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxUserGroupCollectionResponse>>;
    public listGroups(requestParameters: ListGroupsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling listGroups.');
        }
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Groups`;
        return this.httpClient.request<PbxUserGroupCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listGroupsQuery(requestParameters: ListGroupsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listGroupsQuery(requestParameters: ListGroupsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listGroupsQuery(requestParameters: ListGroupsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listGroupsQuery(requestParameters: ListGroupsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling listGroups.');
        }
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Groups`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Get entities from Users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listUser(requestParameters: ListUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxUserCollectionResponse>;
    public listUser(requestParameters: ListUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxUserCollectionResponse>>;
    public listUser(requestParameters: ListUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxUserCollectionResponse>>;
    public listUser(requestParameters: ListUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users`;
        return this.httpClient.request<PbxUserCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listUserQuery(requestParameters: ListUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listUserQuery(requestParameters: ListUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listUserQuery(requestParameters: ListUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listUserQuery(requestParameters: ListUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/Users`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke action MakeCallUserRecordGreeting
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public makeCallUserRecordGreeting(requestParameters: MakeCallUserRecordGreetingRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public makeCallUserRecordGreeting(requestParameters: MakeCallUserRecordGreetingRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public makeCallUserRecordGreeting(requestParameters: MakeCallUserRecordGreetingRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public makeCallUserRecordGreeting(requestParameters: MakeCallUserRecordGreetingRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling makeCallUserRecordGreeting.');
        }
        const makeCallUserRecordGreetingRequestBody = requestParameters.makeCallUserRecordGreetingRequestBody;

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.MakeCallUserRecordGreeting`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: makeCallUserRecordGreetingRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public makeCallUserRecordGreetingQuery(requestParameters: MakeCallUserRecordGreetingRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public makeCallUserRecordGreetingQuery(requestParameters: MakeCallUserRecordGreetingRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public makeCallUserRecordGreetingQuery(requestParameters: MakeCallUserRecordGreetingRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public makeCallUserRecordGreetingQuery(requestParameters: MakeCallUserRecordGreetingRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling makeCallUserRecordGreeting.');
        }
            const makeCallUserRecordGreetingRequestBody = requestParameters.makeCallUserRecordGreetingRequestBody;

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.MakeCallUserRecordGreeting`;
}

    /**
     * Invoke action RebootPhone
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public rebootPhone(requestParameters: RebootPhoneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public rebootPhone(requestParameters: RebootPhoneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public rebootPhone(requestParameters: RebootPhoneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public rebootPhone(requestParameters: RebootPhoneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const usersRebootPhoneRequestBody = requestParameters.usersRebootPhoneRequestBody;
        if (usersRebootPhoneRequestBody === null || usersRebootPhoneRequestBody === undefined) {
            throw new Error('Required parameter usersRebootPhoneRequestBody was null or undefined when calling rebootPhone.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.RebootPhone`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: usersRebootPhoneRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public rebootPhoneQuery(requestParameters: RebootPhoneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public rebootPhoneQuery(requestParameters: RebootPhoneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public rebootPhoneQuery(requestParameters: RebootPhoneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public rebootPhoneQuery(requestParameters: RebootPhoneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const usersRebootPhoneRequestBody = requestParameters.usersRebootPhoneRequestBody;
        if (usersRebootPhoneRequestBody === null || usersRebootPhoneRequestBody === undefined) {
        throw new Error('Required parameter usersRebootPhoneRequestBody was null or undefined when calling rebootPhone.');
        }

    return `/Users/Pbx.RebootPhone`;
}

    /**
     * Invoke action Regenerate
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public regenerate(requestParameters: RegenerateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public regenerate(requestParameters: RegenerateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public regenerate(requestParameters: RegenerateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public regenerate(requestParameters: RegenerateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling regenerate.');
        }
        const regenerateRequestBody = requestParameters.regenerateRequestBody;

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.Regenerate`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: regenerateRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public regenerateQuery(requestParameters: RegenerateRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public regenerateQuery(requestParameters: RegenerateRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public regenerateQuery(requestParameters: RegenerateRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public regenerateQuery(requestParameters: RegenerateRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling regenerate.');
        }
            const regenerateRequestBody = requestParameters.regenerateRequestBody;

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.Regenerate`;
}

    /**
     * Invoke action RegeneratePasswords
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public regeneratePasswords(requestParameters: RegeneratePasswordsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public regeneratePasswords(requestParameters: RegeneratePasswordsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public regeneratePasswords(requestParameters: RegeneratePasswordsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public regeneratePasswords(requestParameters: RegeneratePasswordsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const usersRegeneratePasswordsRequestBody = requestParameters.usersRegeneratePasswordsRequestBody;
        if (usersRegeneratePasswordsRequestBody === null || usersRegeneratePasswordsRequestBody === undefined) {
            throw new Error('Required parameter usersRegeneratePasswordsRequestBody was null or undefined when calling regeneratePasswords.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.RegeneratePasswords`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: usersRegeneratePasswordsRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public regeneratePasswordsQuery(requestParameters: RegeneratePasswordsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public regeneratePasswordsQuery(requestParameters: RegeneratePasswordsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public regeneratePasswordsQuery(requestParameters: RegeneratePasswordsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public regeneratePasswordsQuery(requestParameters: RegeneratePasswordsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const usersRegeneratePasswordsRequestBody = requestParameters.usersRegeneratePasswordsRequestBody;
        if (usersRegeneratePasswordsRequestBody === null || usersRegeneratePasswordsRequestBody === undefined) {
        throw new Error('Required parameter usersRegeneratePasswordsRequestBody was null or undefined when calling regeneratePasswords.');
        }

    return `/Users/Pbx.RegeneratePasswords`;
}

    /**
     * Invoke action ReprovisionPhone
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public reprovisionPhone(requestParameters: ReprovisionPhoneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public reprovisionPhone(requestParameters: ReprovisionPhoneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public reprovisionPhone(requestParameters: ReprovisionPhoneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public reprovisionPhone(requestParameters: ReprovisionPhoneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const usersReprovisionPhoneRequestBody = requestParameters.usersReprovisionPhoneRequestBody;
        if (usersReprovisionPhoneRequestBody === null || usersReprovisionPhoneRequestBody === undefined) {
            throw new Error('Required parameter usersReprovisionPhoneRequestBody was null or undefined when calling reprovisionPhone.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.ReprovisionPhone`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: usersReprovisionPhoneRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public reprovisionPhoneQuery(requestParameters: ReprovisionPhoneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public reprovisionPhoneQuery(requestParameters: ReprovisionPhoneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public reprovisionPhoneQuery(requestParameters: ReprovisionPhoneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public reprovisionPhoneQuery(requestParameters: ReprovisionPhoneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const usersReprovisionPhoneRequestBody = requestParameters.usersReprovisionPhoneRequestBody;
        if (usersReprovisionPhoneRequestBody === null || usersReprovisionPhoneRequestBody === undefined) {
        throw new Error('Required parameter usersReprovisionPhoneRequestBody was null or undefined when calling reprovisionPhone.');
        }

    return `/Users/Pbx.ReprovisionPhone`;
}

    /**
     * Invoke action SendWelcomeEmail
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public sendWelcomeEmail(requestParameters: SendWelcomeEmailRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public sendWelcomeEmail(requestParameters: SendWelcomeEmailRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public sendWelcomeEmail(requestParameters: SendWelcomeEmailRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public sendWelcomeEmail(requestParameters: SendWelcomeEmailRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling sendWelcomeEmail.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.SendWelcomeEmail`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public sendWelcomeEmailQuery(requestParameters: SendWelcomeEmailRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public sendWelcomeEmailQuery(requestParameters: SendWelcomeEmailRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public sendWelcomeEmailQuery(requestParameters: SendWelcomeEmailRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public sendWelcomeEmailQuery(requestParameters: SendWelcomeEmailRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling sendWelcomeEmail.');
        }

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.SendWelcomeEmail`;
}

    /**
     * Invoke action SetMonitorStatus
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setMonitorStatus(requestParameters: SetMonitorStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public setMonitorStatus(requestParameters: SetMonitorStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public setMonitorStatus(requestParameters: SetMonitorStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public setMonitorStatus(requestParameters: SetMonitorStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling setMonitorStatus.');
        }
        const setMonitorStatusRequestBody = requestParameters.setMonitorStatusRequestBody;

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.SetMonitorStatus`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: setMonitorStatusRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public setMonitorStatusQuery(requestParameters: SetMonitorStatusRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public setMonitorStatusQuery(requestParameters: SetMonitorStatusRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public setMonitorStatusQuery(requestParameters: SetMonitorStatusRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public setMonitorStatusQuery(requestParameters: SetMonitorStatusRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling setMonitorStatus.');
        }
            const setMonitorStatusRequestBody = requestParameters.setMonitorStatusRequestBody;

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})/Pbx.SetMonitorStatus`;
}

    /**
     * Update entity in Users
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateUser(requestParameters: UpdateUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateUser(requestParameters: UpdateUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateUser(requestParameters: UpdateUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateUser(requestParameters: UpdateUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const id = requestParameters.id;
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling updateUser.');
        }
        const pbxUser = requestParameters.pbxUser;
        if (pbxUser === null || pbxUser === undefined) {
            throw new Error('Required parameter pbxUser was null or undefined when calling updateUser.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pbxUser,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public updateUserQuery(requestParameters: UpdateUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateUserQuery(requestParameters: UpdateUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateUserQuery(requestParameters: UpdateUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateUserQuery(requestParameters: UpdateUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const id = requestParameters.id;
        if (id === null || id === undefined) {
        throw new Error('Required parameter id was null or undefined when calling updateUser.');
        }
            const pbxUser = requestParameters.pbxUser;
        if (pbxUser === null || pbxUser === undefined) {
        throw new Error('Required parameter pbxUser was null or undefined when calling updateUser.');
        }

    return `/Users(${this.configuration.encodeParam({name: "id", value: id, in: "path", style: "simple", explode: false, dataType: "number", dataFormat: "int32"})})`;
}

    /**
     * Invoke action UpgradePhone
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public upgradePhone(requestParameters: UpgradePhoneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public upgradePhone(requestParameters: UpgradePhoneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public upgradePhone(requestParameters: UpgradePhoneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public upgradePhone(requestParameters: UpgradePhoneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const usersUpgradePhoneRequestBody = requestParameters.usersUpgradePhoneRequestBody;
        if (usersUpgradePhoneRequestBody === null || usersUpgradePhoneRequestBody === undefined) {
            throw new Error('Required parameter usersUpgradePhoneRequestBody was null or undefined when calling upgradePhone.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/Users/Pbx.UpgradePhone`;
        return this.httpClient.request<any>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: usersUpgradePhoneRequestBody,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public upgradePhoneQuery(requestParameters: UpgradePhoneRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public upgradePhoneQuery(requestParameters: UpgradePhoneRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public upgradePhoneQuery(requestParameters: UpgradePhoneRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public upgradePhoneQuery(requestParameters: UpgradePhoneRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const usersUpgradePhoneRequestBody = requestParameters.usersUpgradePhoneRequestBody;
        if (usersUpgradePhoneRequestBody === null || usersUpgradePhoneRequestBody === undefined) {
        throw new Error('Required parameter usersUpgradePhoneRequestBody was null or undefined when calling upgradePhone.');
        }

    return `/Users/Pbx.UpgradePhone`;
}

}
