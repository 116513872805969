import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import getItGooglePlayPlayImageData from '../../img/getFromGooglePlay.png';
import downloadOnAppleStoreImageData from '../../img/getFromAppleStore.png';
import getFromMicrosoftStoreImageData from '../../img/getFromMicrosoftStore.png';
import { AppsUtilityService } from '@webclient/services/apps-utility.service';

@Component({
    template: `
        <div class="d-flex flex-row">
            <div>
                <p class="ps-2">1.
                    <span *ngIf="isWindows" [innerHTML]="'_i18n.install3cxApp' | translate"></span>
                    <span *ngIf="!isWindows" [innerHTML]="'_i18n.InstallIosAndroid' | translate"></span>
                </p>
                <div class="ps-2 mb-3 d-flex gap-2 justify-content-center">
                    <a href="{{'_i18n.GooglePlayStoreClientLink' | translate}}" target="_blank">
                        <img [src]="getItGooglePlayPlayIcon" height="30" alt="GooglePlay">
                    </a>
                    <a href="{{'_i18n.AppleStoreClientLink' | translate}}" target="_blank">
                        <img [src]="downloadOnAppleStoreImageData" height="30" alt="AppleStore">
                    </a>
                    <a href="{{'_i18n.MsWindowsStoreClientLink' | translate}}" target="_blank">
                        <img *ngIf="isWindows" [src]="getFromMicrosoftStoreIcon" height="30" alt="MsWindowsStore">
                    </a>
                </div>
                <p class="ps-2 mb-2">
                    2.
                    <span [innerHTML]="'_i18n.WelcomeProvisionAndroidAndIos' | translate"></span>
                    <span *ngIf="isWindows" (click)="spanClicked($event)" [innerHTML]="'_i18n.WelcomeProvisionWindows' | translate"></span>
                </p>
                <div class="d-flex flex-column align-items-center ">
                    <app-provision-qrcode [userId]="userId"></app-provision-qrcode>
                </div>
            </div>
            <!-- hidden on width < 576px -->
            <span class="customSVGIcons android-phone d-none d-sm-block ps-4" app-andriod-client-custom-icon></span>
        </div>
    `,
    styleUrls: ['./apps-dialog-content.component.scss'],
    selector: 'app-welcome-dialog-content',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WelcomeDialogContentComponent {
    readonly downloadOnAppleStoreImageData = downloadOnAppleStoreImageData;
    readonly getItGooglePlayPlayIcon = getItGooglePlayPlayImageData;
    readonly getFromMicrosoftStoreIcon = getFromMicrosoftStoreImageData;

    readonly isWindows = this.appsUtilityService.isWindows;

    /** for another user in admin view */
    @Input() userId?: number;

    @Output()
    public readonly showWindows = new EventEmitter<boolean>();

    public constructor(private appsUtilityService: AppsUtilityService) {
    }

    spanClicked(event: MouseEvent) {
        if (event.target instanceof HTMLAnchorElement) {
            event.preventDefault();
            console.log('Link clicked');
            this.showWindows.emit(true);
        }
    }
}
