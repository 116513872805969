import type { FilterValue, SerializedFilter } from '../../types';
import type { TranslateService } from '@ngx-translate/core';
import type { ValidationErrors } from '@angular/forms';
import type { CheckOption } from '@webclient/fields/types';

export interface EnumFilterParams<TEnum> {
    selectedValue: TEnum | null
}

export class EnumFilterValue<TEnum> implements FilterValue<EnumFilterParams<TEnum>> {
    private selectedValue: TEnum;

    /** First option in list should refer not applied filter (All/Any) */
    constructor(readonly label: string, private readonly queryParam: string, readonly options: CheckOption<TEnum>[]) {
        this.selectedValue = options[0].value;
    }

    get value(): { selectedValue: TEnum } {
        return { selectedValue: this.selectedValue };
    }

    set value(value: EnumFilterParams<TEnum>) {
        this.selectedValue = value.selectedValue ?? this.options[0].value;
    }

    deserialize(serializedValue: SerializedFilter): FilterValue<EnumFilterParams<TEnum>> {
        return EnumFilterValue.deserialize(serializedValue, this.label, this.queryParam, this.options, this.value);
    }

    getDisplayValue(translate: TranslateService): string {
        const value = this.value;
        return translate.instant(this.options.find(item => item.value === value.selectedValue)!.label);
    }

    getDisplayValueForPrint(translate: TranslateService): string {
        return this.getDisplayValue(translate);
    }

    serialize(): SerializedFilter {
        // first value is auto-selected, so we do not serialize it
        return this.selectedValue === this.options[0].value ? {} : { [this.queryParam]: this.selectedValue };
    }

    validate(): ValidationErrors | null {
        return null;
    }

    static deserialize<TEnum>(serializedValue: SerializedFilter, label: string, queryParam: string, options: CheckOption<TEnum>[], initialValue?: EnumFilterParams<TEnum>) {
        const value = new EnumFilterValue<TEnum>(label, queryParam, options);
        if (initialValue) {
            value.value = initialValue;
        }
        if (queryParam in serializedValue && options.some(item => item.value === serializedValue[queryParam])) {
            value.selectedValue = serializedValue[queryParam] as TEnum;
        }
        return value;
    }
}
