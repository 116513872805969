import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import {
    map, shareReplay, switchMap
} from 'rxjs/operators';
import { RequestCloseMeeting } from '@myphone';
import { ModalService } from '@webclient/modal/app-modal.service';

@Injectable({
    providedIn: 'root'
})
export class WebmeetingHolderService {
    /// Meeting is currently active
    readonly isActive: Observable<boolean>;
    /// User is on the meeting main screen
    readonly isMeetingMainScreen = new BehaviorSubject(false);
    /// Meeting conditions are invalid
    readonly isInvalid$: Observable<boolean>;

    constructor(private myPhoneService: MyPhoneService, private modalService: ModalService) {
        this.isActive = myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.quickMeetingActive$)
        );

        this.isInvalid$ = myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.myInfo$),
            map(myInfo => !((myInfo.FirstName || myInfo.LastName) && myInfo.Email && myInfo.WmFriendlyName)),
            shareReplay({ refCount: true, bufferSize: 1 })
        );
    }

    deactivate() {
        this.myPhoneService.get(new RequestCloseMeeting()).subscribe({ error: (error: unknown) => this.modalService.error(error) });
    }
}
