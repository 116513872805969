import { NgModule } from '@angular/core';
import { ChatToastComponent } from './chat-toast.component';
import { ChatToastService } from './chat-toast.service';
import { ChatToastContainerComponent } from './chat-toast-container.component';
import { ToastContainerComponent } from '@webclient/standalones/toast-container';
import { TruncatedTextDirective } from '@webclient/standalones/directives';
import { CommonModule } from '@angular/common';
import { AvatarComponent } from '@webclient/standalones/avatar';
import { TranslateModule } from '@ngx-translate/core';
import { ProviderImageComponent } from '../chat-shared/provider-image/provider-image.component';
import { NameOrderPipe } from '@webclient/shared/pipes/name-order.pipe';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';

@NgModule({
    declarations: [
        ChatToastContainerComponent,
        ChatToastComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        ToastContainerComponent,
        TruncatedTextDirective,
        AvatarComponent,
        ProviderImageComponent,
        NameOrderPipe,
        IconsModule
    ],
    providers: [
        ChatToastService
    ],
    exports: [
        ChatToastContainerComponent
    ]
})
export class ChatToasterModule {
}
