import { Record } from 'immutable';

export class ChatProvider extends Record({
    id: '',
    externalNumber: '',
    name: '',
    type: '',
}) {
    get imageIdent() {
        const imageIdent = (this.name && this.type) ? `${this.name}.${this.type}` : this.name || this.type || '';
        Object.defineProperty(this, 'imageIdent', {
            get: () => imageIdent
        });
        return imageIdent;
    }
}

export const defaultChatProvider = new ChatProvider();

export function createChatProvider(provider: string|undefined, userData: string | undefined) {
    const providerId = userData && JSON.parse(userData)?.provider_id;
    if (provider) {
        const parts = provider.split('.');
        if (parts.length === 3) {
            // messaging service
            return new ChatProvider({
                externalNumber: parts[0],
                name: parts[1],
                type: parts[2],
                id: providerId,
            });
        }
        else if (parts.length === 2) {
            // trunk provider
            return new ChatProvider({
                externalNumber: parts[0],
                type: parts[1],
                id: providerId
            });
        }
        else {
            // For example WebRTC
            return new ChatProvider({
                type: parts[0],
                id: providerId,
            });
        }
    }
    else {
        return defaultChatProvider;
    }
}
