import { extraHeaders } from '@webclient/extra-headers';

const ptotobufMIMEType = 'application/octet-stream';

export type MyphoneHeaders = { [header: string] : string};
export const myPhoneEndPoint = '/MyPhone/MPWebService.asmx';

export function getMyPhoneHeaders(sessionKey: string): MyphoneHeaders {
    return {
        Accept: ptotobufMIMEType,
        'Content-Type': ptotobufMIMEType,
        MyPhoneSession: sessionKey,
        ...extraHeaders
    };
}
