import { BrowserModule } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { ApplicationRef, ErrorHandler, NgModule, } from '@angular/core';
import { RouterModule } from '@angular/router';
/*
 * Platform and Environment providers/directives/pipes
 */
import { isSelenium } from './environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent } from './app.component';
import { AppState, InternalStateType } from './app.service';
import { NoContentComponent } from './no-content';
import { LocalStorageService, NgxWebstorageModule } from 'ngx-webstorage';
import { ContextMenuModule } from './third-party/angular2-contextmenu/angular2-contextmenu';
import { NgIdleModule } from '@ng-idle/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MyErrorHandler } from './my-error-handler';
import { AnimationDriver, NoopAnimationDriver, ɵWebAnimationsDriver } from '@angular/animations/browser';
import { MyphoneSessionComponent } from './myphone-session-component';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { XSRFInterceptor } from './xsrf-interceptor';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { ApiModule as XapiModule, BASE_PATH as XAPI_BASE_PATH, Configuration as XapiConfiguration, Param } from '@xapi';
import { ApiModule, BASE_PATH, Configuration } from '@api';
import { setTheme } from 'ngx-bootstrap/utils';
import { basePathFactory, xapiBasePathFactory } from '@webclient/app-tokens';
import { DialerModule } from './call-adapter/dialer.module';
import { ErrorPageComponent } from '@webclient/error-page/error-page.component';
import { ThemeDomFactoryService, ThemeDomService } from './services/theme-dom.service';
import { DOCUMENT } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { ExtendedServiceWorker } from '@webclient/notifications/extended-sw-push.service';
import { AuthTokenInterceptor } from '@webclient/auth/auth-token-interceptor';
import { NgSelectModule } from '@ng-select/ng-select';
import { BundleTranslateLoader } from '@webclient/bundle-translate-loader';
import { LangService } from '@webclient/lang.service';
import { SharedModule } from '@webclient/shared/shared.module';
import { LayoutModule } from '@webclient/layout/layout.module';
import { AppModalModule } from '@webclient/modal/app-modal.module';
import { odataParamEncoder } from '@webclient/shared/odata-key';
import { PushStatusDisclaimerComponent } from './push-status-disclaimer/push-status-disclaimer.component';

export function animationDriverFactory() {
    if (!isSelenium) {
        // eslint-disable-next-line new-cap
        return new ɵWebAnimationsDriver();
    }
    // Disable angular animations if running under selenium control
    return new NoopAnimationDriver();
}

// Application wide providers
const APP_PROVIDERS: any[] = [
    AppState,
    LangService,
    {
        provide: ErrorHandler,
        useClass: MyErrorHandler
    },
    {
        provide: AnimationDriver,
        useFactory: animationDriverFactory
    },
];

type StoreType = {
    state: InternalStateType,
    restoreInputValues: () => void,
    disposeOldHosts: () => void
};

export function translateHttpFactory(http: HttpClient) {
    return new BundleTranslateLoader(http);
}

// Explicitly set the bootstrap theme style
setTheme('bs5');

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
    bootstrap: [AppComponent],
    declarations: [
        MyphoneSessionComponent,
        AppComponent,
        NoContentComponent,
        ErrorPageComponent,
        PushStatusDisclaimerComponent,
    ],
    imports: [ // import Angular's modules
        DialerModule.forRoot(),
        ApiModule.forRoot(() => new Configuration({
            withCredentials: false
        })),
        XapiModule.forRoot(() => new XapiConfiguration({
            withCredentials: false,
            encodeParam: (param: Param) => odataParamEncoder(param),
        })),
        NgxWebstorageModule.forRoot({ prefix: 'wc', separator: '.' }),
        BrowserModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.pulse,
            // backdropBackgroundColour: 'rgba(0,0,0,0.1)',
            // backdropBorderRadius: '4px',
            // primaryColour: '#ffffff',
            // secondaryColour: '#ffffff',
            // tertiaryColour: '#ffffff'
        }),
        BrowserAnimationsModule,
        SharedModule,
        HttpClientModule,
        NgSelectModule,
        LayoutModule,
        AppModalModule,

        // ngx-bootstrap
        TimepickerModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AlertModule.forRoot(),
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        ButtonsModule.forRoot(),
        BsDropdownModule.forRoot(),
        AccordionModule.forRoot(),
        TabsModule.forRoot(),
        NgIdleModule.forRoot(),
        ProgressbarModule.forRoot(),
        ContextMenuModule.forRoot({ useBootstrap4: false }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateHttpFactory,
                deps: [HttpClient]
            }
        }),
        RouterModule.forRoot(ROUTES, { useHash: true }),
        ServiceWorkerModule.register(ExtendedServiceWorker, {
            scope: window.location.pathname,
            enabled: 'serviceWorker' in navigator, // environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [ // expose our Services and Providers into Angular's dependency injection
        APP_PROVIDERS,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthTokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: XSRFInterceptor,
            multi: true
        },
        {
            provide: BASE_PATH,
            deps: [LocalStorageService],
            useFactory: basePathFactory
        },
        {
            provide: XAPI_BASE_PATH,
            deps: [LocalStorageService],
            useFactory: xapiBasePathFactory
        },
        {
            provide: ThemeDomService,
            useFactory: ThemeDomFactoryService,
            deps: [DOCUMENT]
        }
    ]
})
export class AppModule {
    constructor(public appRef: ApplicationRef,
                public appState: AppState) {
        if (isSelenium) {
            console.log('Disabling CSS animations');
            document.body.classList.add('notransition');
        }
    }
}
