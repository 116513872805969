export class String {
    public static isNullOrWhitespace(str: string) {
        if (typeof str === 'undefined' || str == null) {
            return true;
        }
        return str.replace(/\s/g, '').length < 1;
    }

    public static empty():string {
        return '';
    }

    public static isNullOrEmpty(str?: string): str is undefined {
        return str == null || str === '';
    }

    public static format(str: string, ...args: string[]) {
        let s = str;
        let i = args.length;

        while (i > 0) {
            i--;
            let arg = args[i];
            if (arg === undefined) {
                arg = '';
            }
            s = s.replace(new RegExp('\\{' + (i) + '\\}', 'gm'), arg);
        }

        return s;
    }

    public static join(separator: string, args: string[]) {
        let result = '';

        for (const s of args) {
            if (result !== '') {
                result += separator;
            }
            result += s;
        }

        return result;
    }

    public static reverse(s: string): string {
        let o = '';
        for (let i = s.length - 1; i >= 0; o += s[i--]) {
            // Intentional modify string in cycle
        }
        return o;
    }
}
