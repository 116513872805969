import { IDataTransport } from '@webclient/myphone/i-data-transport';
import {
    asyncScheduler, Observable
} from 'rxjs';
import { MyphoneHeaders } from '@webclient/myphone/myphone-header.func';
import { MessageEncoder } from '@webclient/myphone/message-encoder';
import { NgZone } from '@angular/core';
import { SharedWorkerRequest } from '@webclient/worker/protocol/shared-worker-request';
import { getRandomHash } from '../call/dialer-utility.service';
import { ResponseAcknowledge } from '@myphone';
import { SharedWorkerResponse } from '@webclient/worker/protocol/shared-worker-response';
import { WorkerTypeMessage } from '@webclient/worker/protocol/worker-type-message';
import { sharedWorkerMyphoneMapperFunc } from '@webclient/worker/shared-worker-myphone-mapper.func';
import {
    map, observeOn, subscribeOn, take
} from 'rxjs/operators';
import { enterZone, leaveZone } from '@webclient/myphone/zone-utils';
import { GenericMessageType } from '@webclient/shared/myphone-types';
import { basePost } from '@webclient/myphone/base-post.func';

export class WorkerTransportService implements IDataTransport {
    constructor(private _encoder: MessageEncoder,
                private zone: NgZone,
                private worker: SharedWorker) {
    }

    fetchPost(link: string, content: any, headers: MyphoneHeaders, domain: string): Observable<any> {
        return basePost({
            link,
            basePath: domain,
            body: content,
            headers: new Headers(headers)
        });
    }

    transport<T extends GenericMessageType>(request: GenericMessageType, headers: MyphoneHeaders): Observable<T> {
        return new Observable<SharedWorkerResponse<'get'>>(subscriber => {
            const id = getRandomHash();
            const port = this.worker.port;

            let responseProvided = false;
            const handler = (messageEvent: MessageEvent) => {
                const response = new SharedWorkerResponse<'get'>(messageEvent.data);
                if (response.type === 'get' && response.id === id) {
                    responseProvided = true;
                    subscriber.next(response);
                }
            };

            port.addEventListener('message', handler);
            port.postMessage(new SharedWorkerRequest({
                type: 'get-request',
                payload: new WorkerTypeMessage({
                    ofType: request.typeId,
                    message: request
                }),
                id
            }));
            return () => {
                port.removeEventListener('message', handler);
                if (!responseProvided) {
                    port.postMessage(new SharedWorkerRequest({
                        type: 'cancel-request',
                        id
                    }));
                }
            };
        }).pipe(
            take(1),
            map(response => sharedWorkerMyphoneMapperFunc(response)),
            map(message => {
                if (message instanceof ResponseAcknowledge && !message.Success) {
                    // Throw if server response contains error
                    const error = new Error(message.Message || `Received unsuccessful ack for ${request.constructor.name}`);
                    (error as any).source = message;
                    throw error;
                }
                else {
                    return message as T;
                }
            }),
            observeOn(enterZone(this.zone, asyncScheduler)),
            subscribeOn(leaveZone(this.zone, asyncScheduler)),
        );
    }
}
