import { Component } from '@angular/core';
import { DialerVisibilityService } from '../../../call-adapter/dialer-visibility.service';

@Component({
    selector: 'ongoing-call-button',
    template: `
        <a class='header-button themify' id="menuDialer" (click)='toggleDialer()' title="{{'_i18n.Dialer'|translate}}" role="button">
            <i class="customSVGIcons svg-sm" style="transform: rotate(-90deg)" app-phone-alt-solid-icon></i>
        </a>
    `
})
export class OngoingCallButtonComponent {
    constructor(private callsAdapterService: DialerVisibilityService) {
    }

    toggleDialer() {
        this.callsAdapterService.toggleDialer.next(null);
    }
}
