import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallModule } from '@webclient/call/call.module';
import { WebDialerComponent } from './web-dialer.component';
import { ToastContainerComponent } from '@webclient/standalones/toast-container';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { StandaloneDialerComponent } from './standalone-dialer.component';
import { SharedModule } from '../shared/shared.module';
import { BlfModule } from '@webclient/call-blf/blf.module';

@NgModule({
    declarations: [
        StandaloneDialerComponent,
        WebDialerComponent,
    ],
    exports: [
        StandaloneDialerComponent,
        WebDialerComponent
    ],
    imports: [
        ToastContainerComponent,
        IconsModule,
        CallModule,
        BlfModule,
        SharedModule,
        CommonModule
    ]
})
export class DialerModule {
    static forRoot(): ModuleWithProviders<DialerModule> {
        return {
            ngModule: DialerModule,
        };
    }
}
