import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Show3cxLogoIconComponent } from '@webclient/shared/components/icons/show-3cx-logo-icon.component';
import { ShowSvgImagesComponent } from '@webclient/shared/components/icons/show-svg-images.component';
import { AddressBookLightIconComponent } from '@webclient/shared/components/icons/address-book-light-icon.component';
import { AddressCardLightIconComponent } from '@webclient/shared/components/icons/address-card-light-icon.component';
import { ArrowLeftLightIconComponent } from '@webclient/shared/components/icons/arrow-left-light-icon.component';
import { ArrowRightLightIconComponent } from '@webclient/shared/components/icons/arrow-right-light-icon.component';
import { BarsLightIconComponent } from '@webclient/shared/components/icons/bars-light-icon.component';
import { BuildingLightIconComponent } from '@webclient/shared/components/icons/building-light-icon.component';
import { CalendarAltLightIconComponent } from '@webclient/shared/components/icons/calendar-alt-light-icon.component';
import { ClockLightIconComponent } from '@webclient/shared/components/icons/clock-light-icon.component';
import { CogsLightIconComponent } from '@webclient/shared/components/icons/cogs-light-icon.component';
import { CommentAltLightIconComponent } from '@webclient/shared/components/icons/comment-alt-light-icon.component';
import { CommentLinesLightIconComponent } from '@webclient/shared/components/icons/comment-lines-light-icon.component';
import { CommentsAltLightIconComponent } from '@webclient/shared/components/icons/comments-alt-light-icon.component';
import { CopyLightIconComponent } from '@webclient/shared/components/icons/copy-light-icon.component';
import { EnvelopeLightIconComponent } from '@webclient/shared/components/icons/envelope-light-icon.component';
import { ExclamationCircleLightIconComponent } from '@webclient/shared/components/icons/exclamation-circle-light-icon.component';
import { FaxLightIconComponent } from '@webclient/shared/components/icons/fax-light-icon.component';
import { FileAltLightIconComponent } from '@webclient/shared/components/icons/file-alt-light-icon.component';
import { HistoryLightIconComponent } from '@webclient/shared/components/icons/history-light-icon.component';
import { LongArrowDownLightIconComponent } from '@webclient/shared/components/icons/long-arrow-down-light-icon.component';
import { LongArrowLeftLightIconComponent } from '@webclient/shared/components/icons/long-arrow-left-light-icon.component';
import { MicrophoneAltLightIconComponent } from '@webclient/shared/components/icons/microphone-alt-light-icon.component';
import { MinusLightIconComponent } from '@webclient/shared/components/icons/minus-light-icon.component';
import { MobileAndroidAltLightIconComponent } from '@webclient/shared/components/icons/mobile-android-alt-light-icon.component';
import { MonitorWaveformLightIconComponent } from '@webclient/shared/components/icons/monitor-waveform-light-icon.component';
import { PaletteLightIconComponent } from '@webclient/shared/components/icons/palette-light-icon.component';
import { PaperPlaneLightIconComponent } from '@webclient/shared/components/icons/paper-plane-light-icon.component';
import { PaperclipLightIconComponent } from '@webclient/shared/components/icons/paperclip-light-icon.component';
import { PencilAltLightIconComponent } from '@webclient/shared/components/icons/pencil-alt-light-icon.component';
import { PhoneAltLightIconComponent } from '@webclient/shared/components/icons/phone-alt-light-icon.component';
import { PhoneOfficeLightIconComponent } from '@webclient/shared/components/icons/phone-office-light-icon.component';
import { PhoneRotaryLightIconComponent } from '@webclient/shared/components/icons/phone-rotary-light-icon.component';
import { PlayLightIconComponent } from '@webclient/shared/components/icons/play-light-icon.component';
import { PlusLightIconComponent } from '@webclient/shared/components/icons/plus-light-icon.component';
import { SaveLightIconComponent } from '@webclient/shared/components/icons/save-light-icon.component';
import { SearchLightIconComponent } from '@webclient/shared/components/icons/search-light-icon.component';
import { ShareAltLightIconComponent } from '@webclient/shared/components/icons/share-alt-light-icon.component';
import { SignOutAltLightIconComponent } from '@webclient/shared/components/icons/sign-out-alt-light-icon.component';
import { SmileLightIconComponent } from '@webclient/shared/components/icons/smile-light-icon.component';
import { StarLightIconComponent } from '@webclient/shared/components/icons/star-light-icon.component';
import { TimesLightIconComponent } from '@webclient/shared/components/icons/times-light-icon.component';
import { TrashAltLightIconComponent } from '@webclient/shared/components/icons/trash-alt-light-icon.component';
import { UserAltLightIconComponent } from '@webclient/shared/components/icons/user-alt-light-icon.component';
import { UserClockLightIconComponent } from '@webclient/shared/components/icons/user-clock-light-icon.component';
import { UserFriendsLightIconComponent } from '@webclient/shared/components/icons/user-friends-light-icon.component';
import { UserLightIconComponent } from '@webclient/shared/components/icons/user-light-icon.component';
import { UsersLightIconComponent } from '@webclient/shared/components/icons/users-light-icon.component';
import { VideoLightIconComponent } from '@webclient/shared/components/icons/video-light-icon.component';
import { VoicemailLightIconComponent } from '@webclient/shared/components/icons/voicemail-light-icon.component';
import { VolumeUpLightIconComponent } from '@webclient/shared/components/icons/volume-up-light-icon.component';
import { WaveformLinesLightIconComponent } from '@webclient/shared/components/icons/waveform-lines-light-icon.component';
import { WrenchLightIconComponent } from '@webclient/shared/components/icons/wrench-light-icon.component';
import { AddressBookSolidIconComponent } from '@webclient/shared/components/icons/address-book-solid-icon.component';
import { AddressCardSolidIconComponent } from '@webclient/shared/components/icons/address-card-solid-icon.component';
import { ArchiveSolidIconComponent } from '@webclient/shared/components/icons/archive-solid-icon.component';
import { ArrowAltCircleDownSolidIconComponent } from '@webclient/shared/components/icons/arrow-alt-circle-down-solid-icon.component';
import { ArrowAltCircleUpSolidIconComponent } from '@webclient/shared/components/icons/arrow-alt-circle-up-solid-icon.component';
import { ArrowAltDownSolidIconComponent } from '@webclient/shared/components/icons/arrow-alt-down-solid-icon.component';
import { ArrowAltLeftSolidIconComponent } from '@webclient/shared/components/icons/arrow-alt-left-solid-icon.component';
import { ArrowAltUpSolidIconComponent } from '@webclient/shared/components/icons/arrow-alt-up-solid-icon.component';
import { ArrowFromBottomSolidIconComponent } from '@webclient/shared/components/icons/arrow-from-bottom-solid-icon.component';
import { ArrowRightArrowLeftSolidIconComponent } from '@webclient/shared/components/icons/arrow-right-arrow-left-solid-icon.component';
import { ArrowToBottomSolidIconComponent } from '@webclient/shared/components/icons/arrow-to-bottom-solid-icon.component';
import { ArrowUpFromBracketSolidIconComponent } from '@webclient/shared/components/icons/arrow-up-from-bracket-solid-icon.component';
import { BarsSolidIconComponent } from '@webclient/shared/components/icons/bars-solid-icon.component';
import { BellOnSolidSolidIconComponent } from '@webclient/shared/components/icons/bell-on-solid-solid-icon.component';
import { BellSlashSolidSolidIconComponent } from '@webclient/shared/components/icons/bell-slash-solid-solid-icon.component';
import { BellSolidIconComponent } from '@webclient/shared/components/icons/bell-solid-icon.component';
import { BoxOpenSolidIconComponent } from '@webclient/shared/components/icons/box-open-solid-icon.component';
import { BuildingSolidIconComponent } from '@webclient/shared/components/icons/building-solid-icon.component';
import { CheckSquareSolidIconComponent } from '@webclient/shared/components/icons/check-square-solid-icon.component';
import { CheckSolidIconComponent } from '@webclient/shared/components/icons/check-solid-icon.component';
import { ChevronDownSolidIconComponent } from '@webclient/shared/components/icons/chevron-down-solid-icon.component';
import { ChevronLeftSolidIconComponent } from '@webclient/shared/components/icons/chevron-left-solid-icon.component';
import { ChevronRightSolidIconComponent } from '@webclient/shared/components/icons/chevron-right-solid-icon.component';
import { CircleCheckSolidIconComponent } from '@webclient/shared/components/icons/circle-check-solid-icon.component';
import { CircleExclamationSolidIconComponent } from '@webclient/shared/components/icons/circle-exclamation-solid-icon.component';
import { CirclePhoneSolidIconComponent } from '@webclient/shared/components/icons/circle-phone-solid-icon.component';
import { CirclePlusSolidIconComponent } from '@webclient/shared/components/icons/circle-plus-solid-icon.component';
import { CircleSolidIconComponent } from '@webclient/shared/components/icons/circle-solid-icon.component';
import { ClipboardCheckSolidIconComponent } from '@webclient/shared/components/icons/clipboard-check-solid-icon.component';
import { ClipboardSolidIconComponent } from '@webclient/shared/components/icons/clipboard-solid-icon.component';
import { ClockSolidIconComponent } from '@webclient/shared/components/icons/clock-solid-icon.component';
import { CloudArrowDownSolidIconComponent } from '@webclient/shared/components/icons/cloud-arrow-down-solid-icon.component';
import { CloudCheckSolidIconComponent } from '@webclient/shared/components/icons/cloud-check-solid-icon.component';
import { CogSolidIconComponent } from '@webclient/shared/components/icons/cog-solid-icon.component';
import { CommentAltSlashSolidIconComponent } from '@webclient/shared/components/icons/comment-alt-slash-solid-icon.component';
import { CommentAltSolidIconComponent } from '@webclient/shared/components/icons/comment-alt-solid-icon.component';
import { CommentDotsSolidIconComponent } from '@webclient/shared/components/icons/comment-dots-solid-icon.component';
import { CommentLinesSolidIconComponent } from '@webclient/shared/components/icons/comment-lines-solid-icon.component';
import { CommentsAltSolidIconComponent } from '@webclient/shared/components/icons/comments-alt-solid-icon.component';
import { DatabaseSolidIconComponent } from '@webclient/shared/components/icons/database-solid-icon.component';
import { DesktopSolidIconComponent } from '@webclient/shared/components/icons/desktop-solid-icon.component';
import { DotCircleSolidIconComponent } from '@webclient/shared/components/icons/dot-circle-solid-icon.component';
import { DownloadSolidIconComponent } from '@webclient/shared/components/icons/download-solid-icon.component';
import { EllipsisVSolidIconComponent } from '@webclient/shared/components/icons/ellipsis-v-solid-icon.component';
import { EnvelopeSolidIconComponent } from '@webclient/shared/components/icons/envelope-solid-icon.component';
import { EuroSignSolidIconComponent } from '@webclient/shared/components/icons/euro-sign-solid-icon.component';
import { ExclamationCircleSolidIconComponent } from '@webclient/shared/components/icons/exclamation-circle-solid-icon.component';
import { ExclamationTriangleSolidIconComponent } from '@webclient/shared/components/icons/exclamation-triangle-solid-icon.component';
import { ExternalLinkAltSolidIconComponent } from '@webclient/shared/components/icons/external-link-alt-solid-icon.component';
import { EyeSlashSolidIconComponent } from '@webclient/shared/components/icons/eye-slash-solid-icon.component';
import { EyeSolidIconComponent } from '@webclient/shared/components/icons/eye-solid-icon.component';
import { FaxSolidIconComponent } from '@webclient/shared/components/icons/fax-solid-icon.component';
import { FileAltSolidIconComponent } from '@webclient/shared/components/icons/file-alt-solid-icon.component';
import { FileCertificateSolidIconComponent } from '@webclient/shared/components/icons/file-certificate-solid-icon.component';
import { FileLinesSolidIconComponent } from '@webclient/shared/components/icons/file-lines-solid-icon.component';
import { FilterSolidIconComponent } from '@webclient/shared/components/icons/filter-solid-icon.component';
import { FolderOpenSolidIconComponent } from '@webclient/shared/components/icons/folder-open-solid-icon.component';
import { GearSolidIconComponent } from '@webclient/shared/components/icons/gear-solid-icon.component';
import { GearsSolidIconComponent } from '@webclient/shared/components/icons/gears-solid-icon.component';
import { GlobeAmericasSolidIconComponent } from '@webclient/shared/components/icons/globe-americas-solid-icon.component';
import { HeadsetSolidIconComponent } from '@webclient/shared/components/icons/headset-solid-icon.component';
import { InfoCircleSolidIconComponent } from '@webclient/shared/components/icons/info-circle-solid-icon.component';
import { KeyboardSolidIconComponent } from '@webclient/shared/components/icons/keyboard-solid-icon.component';
import { LightEmergencyOnSolidIconComponent } from '@webclient/shared/components/icons/light-emergency-on-solid-icon.component';
import { LightEmergencySolidIconComponent } from '@webclient/shared/components/icons/light-emergency-solid-icon.component';
import { LongArrowRightSolidIconComponent } from '@webclient/shared/components/icons/long-arrow-right-solid-icon.component';
import { MessageSolidIconComponent } from '@webclient/shared/components/icons/message-solid-icon.component';
import { MicrochipAiSolidIconComponent } from '@webclient/shared/components/icons/microchip-ai-solid-icon.component';
import { MicrophoneAltSlashSolidIconComponent } from '@webclient/shared/components/icons/microphone-alt-slash-solid-icon.component';
import { MicrophoneAltSolidIconComponent } from '@webclient/shared/components/icons/microphone-alt-solid-icon.component';
import { MobileAltSolidIconComponent } from '@webclient/shared/components/icons/mobile-alt-solid-icon.component';
import { MobileAndroidAltSolidIconComponent } from '@webclient/shared/components/icons/mobile-android-alt-solid-icon.component';
import { PaperPlaneSolidIconComponent } from '@webclient/shared/components/icons/paper-plane-solid-icon.component';
import { PauseSolidIconComponent } from '@webclient/shared/components/icons/pause-solid-icon.component';
import { PencilAltSolidIconComponent } from '@webclient/shared/components/icons/pencil-alt-solid-icon.component';
import { PencilSolidIconComponent } from '@webclient/shared/components/icons/pencil-solid-icon.component';
import { PhoneAltSolidIconComponent } from '@webclient/shared/components/icons/phone-alt-solid-icon.component';
import { PhoneArrowDownLeftSolidIconComponent } from '@webclient/shared/components/icons/phone-arrow-down-left-solid-icon.component';
import { PhoneOfficeSolidIconComponent } from '@webclient/shared/components/icons/phone-office-solid-icon.component';
import { PhoneSlashSolidIconComponent } from '@webclient/shared/components/icons/phone-slash-solid-icon.component';
import { PhoneXmarkSolidIconComponent } from '@webclient/shared/components/icons/phone-xmark-solid-icon.component';
import { PhoneSolidIconComponent } from '@webclient/shared/components/icons/phone-solid-icon.component';
import { PlaySolidIconComponent } from '@webclient/shared/components/icons/play-solid-icon.component';
import { PlusSolidIconComponent } from '@webclient/shared/components/icons/plus-solid-icon.component';
import { PowerOffSolidIconComponent } from '@webclient/shared/components/icons/power-off-solid-icon.component';
import { PrintSolidIconComponent } from '@webclient/shared/components/icons/print-solid-icon.component';
import { PuzzlePieceSolidIconComponent } from '@webclient/shared/components/icons/puzzle-piece-solid-icon.component';
import { QrcodeSolidIconComponent } from '@webclient/shared/components/icons/qrcode-solid-icon.component';
import { RotateLeftSolidIconComponent } from '@webclient/shared/components/icons/rotate-left-solid-icon.component';
import { SaveSolidIconComponent } from '@webclient/shared/components/icons/save-solid-icon.component';
import { ScreenUsersSolidIconComponent } from '@webclient/shared/components/icons/screen-users-solid-icon.component';
import { ScrewdriverWrenchSolidIconComponent } from '@webclient/shared/components/icons/screwdriver-wrench-solid-icon.component';
import { ShareAltSolidIconComponent } from '@webclient/shared/components/icons/share-alt-solid-icon.component';
import { ShareSolidIconComponent } from '@webclient/shared/components/icons/share-solid-icon.component';
import { SignalBarsSlashSolidIconComponent } from '@webclient/shared/components/icons/signal-bars-slash-solid-icon.component';
import { SignalBarsSolidIconComponent } from '@webclient/shared/components/icons/signal-bars-solid-icon.component';
import { SquareListSolidIconComponent } from '@webclient/shared/components/icons/square-list-solid-icon.component';
import { SquareSolidIconComponent } from '@webclient/shared/components/icons/square-solid-icon.component';
import { StarSolidIconComponent } from '@webclient/shared/components/icons/star-solid-icon.component';
import { StopSolidIconComponent } from '@webclient/shared/components/icons/stop-solid-icon.component';
import { ThSolidIconComponent } from '@webclient/shared/components/icons/th-solid-icon.component';
import { ToolsSolidIconComponent } from '@webclient/shared/components/icons/tools-solid-icon.component';
import { TrademarkSolidIconComponent } from '@webclient/shared/components/icons/trademark-solid-icon.component';
import { TrashAltSolidIconComponent } from '@webclient/shared/components/icons/trash-alt-solid-icon.component';
import { UpToLineSolidIconComponent } from '@webclient/shared/components/icons/up-to-line-solid-icon.component';
import { UploadSolidIconComponent } from '@webclient/shared/components/icons/upload-solid-icon.component';
import { UserAltSolidIconComponent } from '@webclient/shared/components/icons/user-alt-solid-icon.component';
import { UserFriendsSolidIconComponent } from '@webclient/shared/components/icons/user-friends-solid-icon.component';
import { UserLockSolidIconComponent } from '@webclient/shared/components/icons/user-lock-solid-icon.component';
import { UserMagnifyingGlassSolidIconComponent } from '@webclient/shared/components/icons/user-magnifying-glass-solid-icon.component';
import { UserPlusSolidIconComponent } from '@webclient/shared/components/icons/user-plus-solid-icon.component';
import { UsersSolidIconComponent } from '@webclient/shared/components/icons/users-solid-icon.component';
import { VideoSolidIconComponent } from '@webclient/shared/components/icons/video-solid-icon.component';
import { VolumeDownSolidIconComponent } from '@webclient/shared/components/icons/volume-down-solid-icon.component';
import { VolumeUpSolidIconComponent } from '@webclient/shared/components/icons/volume-up-solid-icon.component';
import { WaveformLinesSolidIconComponent } from '@webclient/shared/components/icons/waveform-lines-solid-icon.component';
import { WrenchSolidIconComponent } from '@webclient/shared/components/icons/wrench-solid-icon.component';
import { XmarkSolidIconComponent } from '@webclient/shared/components/icons/xmark-solid-icon.component';
import { ArrowAltCircleDownRegularIconComponent } from '@webclient/shared/components/icons/arrow-alt-circle-down-regular-icon.component';
import { ArrowFromLeftRegularIconComponent } from '@webclient/shared/components/icons/arrow-from-left-regular-icon.component';
import { ArrowFromRightRegularIconComponent } from '@webclient/shared/components/icons/arrow-from-right-regular-icon.component';
import { ArrowsRotateRegularRegularIconComponent } from '@webclient/shared/components/icons/arrows-rotate-regular-regular-icon.component';
import { BackspaceRegularIconComponent } from '@webclient/shared/components/icons/backspace-regular-icon.component';
import { CalendarAltRegularIconComponent } from '@webclient/shared/components/icons/calendar-alt-regular-icon.component';
import { CartShoppingRegularIconComponent } from '@webclient/shared/components/icons/cart-shopping-regular-icon.component';
import { ChartBarRegularIconComponent } from '@webclient/shared/components/icons/chart-bar-regular-icon.component';
import { CheckSquareRegularIconComponent } from '@webclient/shared/components/icons/check-square-regular-icon.component';
import { CheckRegularIconComponent } from '@webclient/shared/components/icons/check-regular-icon.component';
import { ChevronDownRegularIconComponent } from '@webclient/shared/components/icons/chevron-down-regular-icon.component';
import { ChevronUpRegularIconComponent } from '@webclient/shared/components/icons/chevron-up-regular-icon.component';
import { CircleRegularIconComponent } from '@webclient/shared/components/icons/circle-regular-icon.component';
import { CompressArrowsAltRegularIconComponent } from '@webclient/shared/components/icons/compress-arrows-alt-regular-icon.component';
import { DotCircleRegularIconComponent } from '@webclient/shared/components/icons/dot-circle-regular-icon.component';
import { EllipsisHRegularIconComponent } from '@webclient/shared/components/icons/ellipsis-h-regular-icon.component';
import { EllipsisVRegularIconComponent } from '@webclient/shared/components/icons/ellipsis-v-regular-icon.component';
import { EuroSignRegularIconComponent } from '@webclient/shared/components/icons/euro-sign-regular-icon.component';
import { ExclamationTriangleRegularIconComponent } from '@webclient/shared/components/icons/exclamation-triangle-regular-icon.component';
import { ExternalLinkAltRegularIconComponent } from '@webclient/shared/components/icons/external-link-alt-regular-icon.component';
import { FileCodeRegularIconComponent } from '@webclient/shared/components/icons/file-code-regular-icon.component';
import { HistoryRegularIconComponent } from '@webclient/shared/components/icons/history-regular-icon.component';
import { HourglassHalfRegularIconComponent } from '@webclient/shared/components/icons/hourglass-half-regular-icon.component';
import { InfoCircleRegularIconComponent } from '@webclient/shared/components/icons/info-circle-regular-icon.component';
import { QuestionCircleRegularIconComponent } from '@webclient/shared/components/icons/question-circle-regular-icon.component';
import { QuestionSquareRegularIconComponent } from '@webclient/shared/components/icons/question-square-regular-icon.component';
import { QuestionRegularIconComponent } from '@webclient/shared/components/icons/question-regular-icon.component';
import { RandomRegularIconComponent } from '@webclient/shared/components/icons/random-regular-icon.component';
import { RotateRegularIconComponent } from '@webclient/shared/components/icons/rotate-regular-icon.component';
import { SearchRegularIconComponent } from '@webclient/shared/components/icons/search-regular-icon.component';
import { SpinnerThirdRegularIconComponent } from '@webclient/shared/components/icons/spinner-third-regular-icon.component';
import { SpinnerRegularIconComponent } from '@webclient/shared/components/icons/spinner-regular-icon.component';
import { SquareRegularIconComponent } from '@webclient/shared/components/icons/square-regular-icon.component';
import { StopwatchRegularIconComponent } from '@webclient/shared/components/icons/stopwatch-regular-icon.component';
import { TimesRegularIconComponent } from '@webclient/shared/components/icons/times-regular-icon.component';
import { WindowMinimizeRegularIconComponent } from '@webclient/shared/components/icons/window-minimize-regular-icon.component';
import { AndroidBrandsIconComponent } from '@webclient/shared/components/icons/android-brands-icon.component';
import { AppleBrandsIconComponent } from '@webclient/shared/components/icons/apple-brands-icon.component';
import { ChromeBrandsIconComponent } from '@webclient/shared/components/icons/chrome-brands-icon.component';
import { EdgeLegacyBrandsIconComponent } from '@webclient/shared/components/icons/edge-legacy-brands-icon.component';
import { EdgeBrandsIconComponent } from '@webclient/shared/components/icons/edge-brands-icon.component';
import { FirefoxBrowserBrandsIconComponent } from '@webclient/shared/components/icons/firefox-browser-brands-icon.component';
import { SafariBrandsIconComponent } from '@webclient/shared/components/icons/safari-brands-icon.component';
import { WindowsBrandsIconComponent } from '@webclient/shared/components/icons/windows-brands-icon.component';
import { WordpressBrandsIconComponent } from '@webclient/shared/components/icons/wordpress-brands-icon.component';
import { PauseDuotoneIconComponent } from '@webclient/shared/components/icons/pause-duotone-icon.component';
import { AndriodClientCustomIconComponent } from '@webclient/shared/components/icons/andriod-client-custom-icon.component';
import { CallTransferCustomIconComponent } from '@webclient/shared/components/icons/call-transfer-custom-icon.component';
import { ChatTransferCustomIconComponent } from '@webclient/shared/components/icons/chat-transfer-custom-icon.component';
import { ChromeArrowCustomIconComponent } from '@webclient/shared/components/icons/chrome-arrow-custom-icon.component';
import { ConferenceCustomIconComponent } from '@webclient/shared/components/icons/conference-custom-icon.component';
import { ConnectedDeviceCustomIconComponent } from '@webclient/shared/components/icons/connected-device-custom-icon.component';
import { DialpadPhoneCustomIconComponent } from '@webclient/shared/components/icons/dialpad_phone-custom-icon.component';
import { DisabledPhoneCustomIconComponent } from '@webclient/shared/components/icons/disabled-phone-custom-icon.component';
import { EdgePhoneCustomIconComponent } from '@webclient/shared/components/icons/edgePhone-custom-icon.component';
import { EdgeIconCustomIconComponent } from '@webclient/shared/components/icons/edge_icon-custom-icon.component';
import { FirefoxPhoneCustomIconComponent } from '@webclient/shared/components/icons/firefoxPhone-custom-icon.component';
import { FirefoxIconCustomIconComponent } from '@webclient/shared/components/icons/firefox_icon-custom-icon.component';
import { GoBackCustomIconComponent } from '@webclient/shared/components/icons/go-back-custom-icon.component';
import { GoForwardCustomIconComponent } from '@webclient/shared/components/icons/go-forward-custom-icon.component';
import { GoogleMonoCustomIconComponent } from '@webclient/shared/components/icons/google-mono-custom-icon.component';
import { GoogleCustomIconComponent } from '@webclient/shared/components/icons/google-custom-icon.component';
import { IosClientCustomIconComponent } from '@webclient/shared/components/icons/ios-client-custom-icon.component';
import { LiveChatCloseCustomIconComponent } from '@webclient/shared/components/icons/live-chat-close-custom-icon.component';
import { LiveChatPaperPlaneCustomIconComponent } from '@webclient/shared/components/icons/live-chat-paper-plane-custom-icon.component';
import { LiveChatSoundActiveIconCustomIconComponent } from '@webclient/shared/components/icons/live-chat-sound-active-icon-custom-icon.component';
import { LiveChatUserCustomIconComponent } from '@webclient/shared/components/icons/live-chat-user-custom-icon.component';
import { LiveChatCustomIconComponent } from '@webclient/shared/components/icons/live-chat-custom-icon.component';
import { LogoLightCustomIconComponent } from '@webclient/shared/components/icons/logo-light-custom-icon.component';
import { LogoCustomIconComponent } from '@webclient/shared/components/icons/logo-custom-icon.component';
import { MicrosoftOfficeCustomIconComponent } from '@webclient/shared/components/icons/microsoft-office-custom-icon.component';
import { MicrosoftTeamsCustomIconComponent } from '@webclient/shared/components/icons/microsoft-teams-custom-icon.component';
import { MonitorCallsCustomIconComponent } from '@webclient/shared/components/icons/monitor-calls-custom-icon.component';
import { MonitorQueuesCustomIconComponent } from '@webclient/shared/components/icons/monitor-queues-custom-icon.component';
import { MsClientCustomIconComponent } from '@webclient/shared/components/icons/ms-client-custom-icon.component';
import { Office365CustomIconComponent } from '@webclient/shared/components/icons/office365-custom-icon.component';
import { PhoneCircleGreenBackgroundCustomIconComponent } from '@webclient/shared/components/icons/phone-circle-green-background-custom-icon.component';
import { PhoneCircleRedBackgroundCustomIconComponent } from '@webclient/shared/components/icons/phone-circle-red-background-custom-icon.component';
import { QrCustomIconComponent } from '@webclient/shared/components/icons/qr-custom-icon.component';
import { SquareChatCustomIconComponent } from '@webclient/shared/components/icons/square-chat-custom-icon.component';
import { TakeCustomIconComponent } from '@webclient/shared/components/icons/take-custom-icon.component';
import { UnarchiveChatCustomIconComponent } from '@webclient/shared/components/icons/unarchive-chat-custom-icon.component';
import { VoicemailHeardCustomIconComponent } from '@webclient/shared/components/icons/voicemail-heard-custom-icon.component';
import { WebmeetingCustomIconComponent } from '@webclient/shared/components/icons/webmeeting-custom-icon.component';
import { WplcIconCustomIconComponent } from '@webclient/shared/components/icons/wplc_icon-custom-icon.component';
import { WplcIconBubbleCustomIconComponent } from '@webclient/shared/components/icons/wplc_icon_bubble-custom-icon.component';
import { WplcIconDoubleBubbleCustomIconComponent } from '@webclient/shared/components/icons/wplc_icon_double_bubble-custom-icon.component';

const declarations = [
    ShowSvgImagesComponent,
    Show3cxLogoIconComponent,
    AddressBookLightIconComponent,
    AddressCardLightIconComponent,
    ArrowLeftLightIconComponent,
    ArrowRightLightIconComponent,
    BarsLightIconComponent,
    BuildingLightIconComponent,
    CalendarAltLightIconComponent,
    ClockLightIconComponent,
    CogsLightIconComponent,
    CommentAltLightIconComponent,
    CommentLinesLightIconComponent,
    CommentsAltLightIconComponent,
    CopyLightIconComponent,
    EnvelopeLightIconComponent,
    ExclamationCircleLightIconComponent,
    FaxLightIconComponent,
    FileAltLightIconComponent,
    HistoryLightIconComponent,
    LongArrowDownLightIconComponent,
    LongArrowLeftLightIconComponent,
    MicrophoneAltLightIconComponent,
    MinusLightIconComponent,
    MobileAndroidAltLightIconComponent,
    MonitorWaveformLightIconComponent,
    PaletteLightIconComponent,
    PaperPlaneLightIconComponent,
    PaperclipLightIconComponent,
    PencilAltLightIconComponent,
    PhoneAltLightIconComponent,
    PhoneOfficeLightIconComponent,
    PhoneRotaryLightIconComponent,
    PlayLightIconComponent,
    PlusLightIconComponent,
    SaveLightIconComponent,
    SearchLightIconComponent,
    ShareAltLightIconComponent,
    SignOutAltLightIconComponent,
    SmileLightIconComponent,
    StarLightIconComponent,
    TimesLightIconComponent,
    TrashAltLightIconComponent,
    UserAltLightIconComponent,
    UserClockLightIconComponent,
    UserFriendsLightIconComponent,
    UserLightIconComponent,
    UsersLightIconComponent,
    VideoLightIconComponent,
    VoicemailLightIconComponent,
    VolumeUpLightIconComponent,
    WaveformLinesLightIconComponent,
    WrenchLightIconComponent,
    AddressBookSolidIconComponent,
    AddressCardSolidIconComponent,
    ArchiveSolidIconComponent,
    ArrowAltCircleDownSolidIconComponent,
    ArrowAltCircleUpSolidIconComponent,
    ArrowAltDownSolidIconComponent,
    ArrowAltLeftSolidIconComponent,
    ArrowAltUpSolidIconComponent,
    ArrowFromBottomSolidIconComponent,
    ArrowRightArrowLeftSolidIconComponent,
    ArrowToBottomSolidIconComponent,
    ArrowUpFromBracketSolidIconComponent,
    BarsSolidIconComponent,
    BellOnSolidSolidIconComponent,
    BellSlashSolidSolidIconComponent,
    BellSolidIconComponent,
    BoxOpenSolidIconComponent,
    BuildingSolidIconComponent,
    CheckSquareSolidIconComponent,
    CheckSolidIconComponent,
    ChevronDownSolidIconComponent,
    ChevronLeftSolidIconComponent,
    ChevronRightSolidIconComponent,
    CircleCheckSolidIconComponent,
    CircleExclamationSolidIconComponent,
    CirclePhoneSolidIconComponent,
    CirclePlusSolidIconComponent,
    CircleSolidIconComponent,
    ClipboardCheckSolidIconComponent,
    ClipboardSolidIconComponent,
    ClockSolidIconComponent,
    CloudArrowDownSolidIconComponent,
    CloudCheckSolidIconComponent,
    CogSolidIconComponent,
    CommentAltSlashSolidIconComponent,
    CommentAltSolidIconComponent,
    CommentDotsSolidIconComponent,
    CommentLinesSolidIconComponent,
    CommentsAltSolidIconComponent,
    DatabaseSolidIconComponent,
    DesktopSolidIconComponent,
    DotCircleSolidIconComponent,
    DownloadSolidIconComponent,
    EllipsisVSolidIconComponent,
    EnvelopeSolidIconComponent,
    EuroSignSolidIconComponent,
    ExclamationCircleSolidIconComponent,
    ExclamationTriangleSolidIconComponent,
    ExternalLinkAltSolidIconComponent,
    EyeSlashSolidIconComponent,
    EyeSolidIconComponent,
    FaxSolidIconComponent,
    FileAltSolidIconComponent,
    FileCertificateSolidIconComponent,
    FileLinesSolidIconComponent,
    FilterSolidIconComponent,
    FolderOpenSolidIconComponent,
    GearSolidIconComponent,
    GearsSolidIconComponent,
    GlobeAmericasSolidIconComponent,
    HeadsetSolidIconComponent,
    InfoCircleSolidIconComponent,
    KeyboardSolidIconComponent,
    LightEmergencyOnSolidIconComponent,
    LightEmergencySolidIconComponent,
    LongArrowRightSolidIconComponent,
    MessageSolidIconComponent,
    MicrochipAiSolidIconComponent,
    MicrophoneAltSlashSolidIconComponent,
    MicrophoneAltSolidIconComponent,
    MobileAltSolidIconComponent,
    MobileAndroidAltSolidIconComponent,
    PaperPlaneSolidIconComponent,
    PauseSolidIconComponent,
    PencilAltSolidIconComponent,
    PencilSolidIconComponent,
    PhoneAltSolidIconComponent,
    PhoneArrowDownLeftSolidIconComponent,
    PhoneOfficeSolidIconComponent,
    PhoneSlashSolidIconComponent,
    PhoneXmarkSolidIconComponent,
    PhoneSolidIconComponent,
    PlaySolidIconComponent,
    PlusSolidIconComponent,
    PowerOffSolidIconComponent,
    PrintSolidIconComponent,
    PuzzlePieceSolidIconComponent,
    QrcodeSolidIconComponent,
    RotateLeftSolidIconComponent,
    SaveSolidIconComponent,
    ScreenUsersSolidIconComponent,
    ScrewdriverWrenchSolidIconComponent,
    ShareAltSolidIconComponent,
    ShareSolidIconComponent,
    SignalBarsSlashSolidIconComponent,
    SignalBarsSolidIconComponent,
    SquareListSolidIconComponent,
    SquareSolidIconComponent,
    StarSolidIconComponent,
    StopSolidIconComponent,
    ThSolidIconComponent,
    ToolsSolidIconComponent,
    TrademarkSolidIconComponent,
    TrashAltSolidIconComponent,
    UpToLineSolidIconComponent,
    UploadSolidIconComponent,
    UserAltSolidIconComponent,
    UserFriendsSolidIconComponent,
    UserLockSolidIconComponent,
    UserMagnifyingGlassSolidIconComponent,
    UserPlusSolidIconComponent,
    UsersSolidIconComponent,
    VideoSolidIconComponent,
    VolumeDownSolidIconComponent,
    VolumeUpSolidIconComponent,
    WaveformLinesSolidIconComponent,
    WrenchSolidIconComponent,
    XmarkSolidIconComponent,
    ArrowAltCircleDownRegularIconComponent,
    ArrowFromLeftRegularIconComponent,
    ArrowFromRightRegularIconComponent,
    ArrowsRotateRegularRegularIconComponent,
    BackspaceRegularIconComponent,
    CalendarAltRegularIconComponent,
    CartShoppingRegularIconComponent,
    ChartBarRegularIconComponent,
    CheckSquareRegularIconComponent,
    CheckRegularIconComponent,
    ChevronDownRegularIconComponent,
    ChevronUpRegularIconComponent,
    CircleRegularIconComponent,
    CompressArrowsAltRegularIconComponent,
    DotCircleRegularIconComponent,
    EllipsisHRegularIconComponent,
    EllipsisVRegularIconComponent,
    EuroSignRegularIconComponent,
    ExclamationTriangleRegularIconComponent,
    ExternalLinkAltRegularIconComponent,
    FileCodeRegularIconComponent,
    HistoryRegularIconComponent,
    HourglassHalfRegularIconComponent,
    InfoCircleRegularIconComponent,
    QuestionCircleRegularIconComponent,
    QuestionSquareRegularIconComponent,
    QuestionRegularIconComponent,
    RandomRegularIconComponent,
    RotateRegularIconComponent,
    SearchRegularIconComponent,
    SpinnerThirdRegularIconComponent,
    SpinnerRegularIconComponent,
    SquareRegularIconComponent,
    StopwatchRegularIconComponent,
    TimesRegularIconComponent,
    WindowMinimizeRegularIconComponent,
    AndroidBrandsIconComponent,
    AppleBrandsIconComponent,
    ChromeBrandsIconComponent,
    EdgeLegacyBrandsIconComponent,
    EdgeBrandsIconComponent,
    FirefoxBrowserBrandsIconComponent,
    SafariBrandsIconComponent,
    WindowsBrandsIconComponent,
    WordpressBrandsIconComponent,
    PauseDuotoneIconComponent,
    AndriodClientCustomIconComponent,
    CallTransferCustomIconComponent,
    ChatTransferCustomIconComponent,
    ChromeArrowCustomIconComponent,
    ConferenceCustomIconComponent,
    ConnectedDeviceCustomIconComponent,
    DialpadPhoneCustomIconComponent,
    DisabledPhoneCustomIconComponent,
    EdgePhoneCustomIconComponent,
    EdgeIconCustomIconComponent,
    FirefoxPhoneCustomIconComponent,
    FirefoxIconCustomIconComponent,
    GoBackCustomIconComponent,
    GoForwardCustomIconComponent,
    GoogleMonoCustomIconComponent,
    GoogleCustomIconComponent,
    IosClientCustomIconComponent,
    LiveChatCloseCustomIconComponent,
    LiveChatPaperPlaneCustomIconComponent,
    LiveChatSoundActiveIconCustomIconComponent,
    LiveChatUserCustomIconComponent,
    LiveChatCustomIconComponent,
    LogoLightCustomIconComponent,
    LogoCustomIconComponent,
    MicrosoftOfficeCustomIconComponent,
    MicrosoftTeamsCustomIconComponent,
    MonitorCallsCustomIconComponent,
    MonitorQueuesCustomIconComponent,
    MsClientCustomIconComponent,
    Office365CustomIconComponent,
    PhoneCircleGreenBackgroundCustomIconComponent,
    PhoneCircleRedBackgroundCustomIconComponent,
    QrCustomIconComponent,
    SquareChatCustomIconComponent,
    TakeCustomIconComponent,
    UnarchiveChatCustomIconComponent,
    VoicemailHeardCustomIconComponent,
    WebmeetingCustomIconComponent,
    WplcIconCustomIconComponent,
    WplcIconBubbleCustomIconComponent,
    WplcIconDoubleBubbleCustomIconComponent,
];

@NgModule({
    declarations,
    imports: [
        CommonModule
    ],
    exports: declarations
})
export class IconsModule {
}
