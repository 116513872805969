import { Pipe, PipeTransform } from '@angular/core';
import { ForwardDestinationViewType } from './forward-destination.form';

const TEXTS: Record<ForwardDestinationViewType, string> = {
    [ForwardDestinationViewType.SameAsAllCalls]: '_i18n.SameAsAllCalls',
    [ForwardDestinationViewType.MyVoicemail]: '_i18n.VoiceMail',
    [ForwardDestinationViewType.Extension]: '_i18n.ExtNumber',
    [ForwardDestinationViewType.MyMobile]: '_i18n.MyMobile',
    [ForwardDestinationViewType.External]: '_i18n.ExternalNum',
    [ForwardDestinationViewType.SendBusy]: '_i18n.SendBusy',
    [ForwardDestinationViewType.PlayAnnouncement]: '_i18n.PlayAnnouncementAndEndCall',
    [ForwardDestinationViewType.SystemExtension]: '_i18n.SystemExtension',
};

@Pipe({ name: 'appForwardDestinationType', standalone: true })
export class ForwardDestinationTypePipe implements PipeTransform {
    transform(src: ForwardDestinationViewType): string {
        return TEXTS[src] || '';
    }
}
