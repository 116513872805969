import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { WcAccountMenuComponent } from './wc-account-menu/wc-account-menu.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgSelectModule } from '@ng-select/ng-select';
import { ValdemortModule } from 'ngx-valdemort';
import { FieldsModule } from '@webclient/fields';
import { DayJsModule } from '@webclient/dayjs';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { HelpHeaderComponent } from './help-header/help-header.component';
import { InstallMobileAppButtonComponent } from './install-mobile-app-button/install-mobile-app-button.component';
import { OngoingCallButtonComponent } from './ongoing-call-button/ongoing-call-button.component';
import { WebmeetingActivatorComponent } from './webmeeting-activator/webmeeting-activator.component';
import { ProfileCssClassPipe } from '@webclient/standalones/pipes';
import { MyNameComponent } from '@webclient/standalones/my-name';
import { ForwardDestinationComponent } from '@webclient/standalones/forwarding-rules';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog';
import { AvatarComponent } from '@webclient/standalones/avatar';
import { CallPromptsComponent } from '@office/standalones/call-prompts';
import { SetTemporaryProfileDialogComponent } from './set-temporary-profile-dialog/set-temporary-profile-dialog.component';
import { GroupHoursModePipe } from './override-office-hours-modal/group-hours-mode.pipe';
import { OverrideOfficeHoursDialogComponent } from './override-office-hours-modal/override-office-hours-dialog.component';
import { SetProfileStatusDialogComponent } from './set-profile-status-dialog/set-profile-status-dialog.component';

@NgModule({
    declarations: [
        WcAccountMenuComponent,
        HeaderComponent,
        HelpHeaderComponent,
        InstallMobileAppButtonComponent,
        OngoingCallButtonComponent,
        WebmeetingActivatorComponent,
        SetTemporaryProfileDialogComponent,
        OverrideOfficeHoursDialogComponent,
        SetProfileStatusDialogComponent,
        GroupHoursModePipe
    ],
    exports: [
        HeaderComponent
    ],
    imports: [
        TranslateModule,
        FormsModule,
        FieldsModule,
        ModalDialogComponent,
        DayJsModule,
        AvatarComponent,
        BsDropdownModule,
        IconsModule,
        ReactiveFormsModule,
        NgSelectModule,
        CallPromptsComponent,
        ValdemortModule,
        ForwardDestinationComponent,
        RouterModule,
        CommonModule,
        ProfileCssClassPipe,
        MyNameComponent
    ]
})
export class HeaderModule { }
