import {
    NotificationConversationRemoved,
    PartyWithUnreadMessages,
    RequestUnreadMessagesCount,
    ResponseConversationInfo,
    ResponseUnreadMessagesCount
} from '@myphone';
import { Injectable } from '@angular/core';
import { merge, Observable, of, ReplaySubject } from 'rxjs';
import { MyPhoneService } from '../myphone/myphone.service';
import { catchError, distinctUntilChanged, filter, map, scan, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { ChatMessagesArray } from '@webclient/chat-data/chat-messages-array';
import { UnreadMessagesArray } from '@webclient/chat-data/unread-messages-array';

@Injectable({
    providedIn: 'root'
})
export class ChatUnreadNotificationService {
    public unreadConversationCount$: Observable<number>;
    public lastError = new ReplaySubject<any>();
    public unreadMessagesCount$: Observable<ResponseUnreadMessagesCount>;

    private readonly defaultMessageCount = new ResponseUnreadMessagesCount({ Items: [] });

    constructor(private myPhoneSvc: MyPhoneService) {
        const conversationCounter$ = this.myPhoneSvc.get<ResponseUnreadMessagesCount>(new RequestUnreadMessagesCount()).pipe(
            catchError((error: unknown) => {
                return of(this.defaultMessageCount);
            }),
            switchMap((scratchCount: ResponseUnreadMessagesCount) => {
                return this.myPhoneSvc.myPhoneSession.pipe(
                    switchMap(session =>
                        merge(
                            session.newChatMessages$.pipe(map(x => new ChatMessagesArray(x))),
                            session.unreadChatCount$.pipe(map(x => new UnreadMessagesArray(x))),
                            session.conversationRemoved$.pipe(
                                // TODO it filters !taken by me but we shouldn't receive conv removed in this case
                                filter(deleteChat => !(
                                    deleteChat.TakenBy &&
                                    !deleteChat.TakenBy.BridgeNumber &&
                                    deleteChat.TakenBy.ExtNumber === session.myInfo.Number)
                                )),
                            session.conversationInfo$
                        ).pipe(
                            scan((accumulator: ResponseUnreadMessagesCount, event: ChatMessagesArray | UnreadMessagesArray| NotificationConversationRemoved | ResponseConversationInfo) => {
                                if (event instanceof ChatMessagesArray) {
                                    event.msg.forEach(msg => {
                                        if (msg.IsNew) {
                                            if (accumulator.Items.find(x => x.IdConversation === msg.IdConversation)) {
                                                accumulator.Items = accumulator.Items.map(acc => {
                                                    if (acc.IdConversation === msg.IdConversation) {
                                                        acc.Count++;
                                                        acc.IsArchived = false;
                                                    }
                                                    return acc;
                                                });
                                            }
                                            else {
                                                accumulator.Items.push(
                                                    new PartyWithUnreadMessages({
                                                        Count: 1,
                                                        IdConversation: msg.IdConversation
                                                    }));
                                            }
                                        }
                                    });
                                }
                                else if (event instanceof UnreadMessagesArray) {
                                    accumulator.Items = event.msg;
                                }
                                else if (event instanceof NotificationConversationRemoved) {
                                    accumulator.Items = accumulator.Items.filter(
                                        x => x.IdConversation !== event.IdConversation);
                                }
                                else if (event instanceof ResponseConversationInfo) {
                                    accumulator.Items = accumulator.Items.map(x => {
                                        if (x.IdConversation === event.Conversation.IdConversation) {
                                            x.IsArchived = event.Conversation.IsArchived;
                                        }
                                        return x;
                                    });
                                }
                                return accumulator;
                            }, scratchCount || new ResponseUnreadMessagesCount({ Items: [] })),
                            startWith(scratchCount)
                        ),
                    ),
                );
            }),
            catchError((error: unknown) => {
                this.lastError.next(error);
                return of(this.defaultMessageCount);
            }),
            shareReplay({ refCount: true, bufferSize: 1 })
        );

        this.unreadMessagesCount$ = this.myPhoneSvc.myPhoneSession.pipe(
            switchMap(session => session.chatIsAllowed$),
            switchMap(isAllowed => (isAllowed ? conversationCounter$ : of(new ResponseUnreadMessagesCount())))
        );

        this.unreadConversationCount$ = this.unreadMessagesCount$.pipe(
            map(x => (x && x.Items ? x.Items.filter(x => !x.IsArchived).length : 0)),
            distinctUntilChanged(),
            // shareReplay({refCount: false, bufferSize: 1}),
        );
    }
}
