import { Injectable } from '@angular/core';
import {
    catchError, distinctUntilChanged, switchMap
} from 'rxjs/operators';
import {
    combineLatest, map, Observable, of
} from 'rxjs';
import { publishRef } from '@webclient/rx-share-utils';
import {
    GroupId, RequestGetFolder, ResponseGetFolder, UsersFolder
} from '@myphone';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { BridgesMap, GroupsMap } from '@webclient/myphone/bridge';
import { ExtensionBridge } from '@webclient/myphone/extension-bridge';
import { Group } from '@webclient/myphone/group';

export type PromptFile = {promptFullPath: string, promptFileName:string }
export function areSetsEqual<T>(a: Set<T>, b: Set<T>) {
    return a.size === b.size && [...a].every(value => b.has(value));
}

@Injectable({
    providedIn: 'root'
})
export class McmGroupService {
    readonly groupsParticipating$: Observable<Group[]>;
    readonly myGroupIds$: Observable<GroupId[]>;

    constructor(private myPhoneService: MyPhoneService) {
        this.myGroupIds$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.myInfo$),
            switchMap(myInfo => myInfo.MyGroups$),
            map(groupIds => groupIds.Items.filter((group) => !group.Name.includes('___FAVORITES___'))),
            publishRef()
        );

        const localGroups$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.bridgesMap$),
            switchMap((bridges : BridgesMap) => {
                const localBridge = Object.values(bridges).find((bridge: ExtensionBridge) => bridge.id === 'local');
                return localBridge?.groupsMap$ ?? of(undefined);
            }),
            map((groupsMap : GroupsMap | undefined) => {
                return groupsMap ? Object.values<Group>(groupsMap) : [];
            }),
            // distinctUntilChanged(),
            // publishRef()
        );

        // Groups that the user actually participates
        this.groupsParticipating$ = combineLatest([localGroups$, this.myGroupIds$]).pipe(
            map(([localGroups, groupsParticipating]) => {
                return localGroups.filter((group) => groupsParticipating.some((participatingId) => participatingId.Id === +group.id));
            }),
            publishRef()
        );
    }

    public getGroupPrompts$(groupId: number): Observable<PromptFile[]> {
        return this.myPhoneService.get<ResponseGetFolder>(this.getGroupFolderRequest(groupId)).pipe(
            map((response) => {
                return response.Files.map(fullFilePath => {
                    const filePathParts = fullFilePath.replace(/\\/g, '/').split('/');
                    return { promptFullPath: fullFilePath, promptFileName: filePathParts[filePathParts.length - 1] };
                });
            }),
            catchError(() => of([])),
            distinctUntilChanged((x, y) => {
                return JSON.stringify(x) === JSON.stringify(y);
            }),
        );
    }

    private getGroupFolderRequest(groupId: number): RequestGetFolder {
        const getGroupFolderRequest = new RequestGetFolder();
        getGroupFolderRequest.Folder = UsersFolder.GroupPrompts;
        getGroupFolderRequest.IdGroup = groupId;
        return getGroupFolderRequest;
    }
}
