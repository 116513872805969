import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { PbxCustomPrompt } from '@xapi';

export const PROMPT_SERVICE = new InjectionToken<PromptService>('PROMPT_SERVICE');

export interface PromptService {
    readonly availablePrompts$: Observable<PbxCustomPrompt[]>;
    uploadPrompt$(file: File): Observable<void>
    deletePrompt$(filename: string): Observable<void>
}
