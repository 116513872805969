export enum HeadsetCallAction {
    AcceptCall,
    TerminateCall,
    RejectCall,
    HoldCall,
    ResumeCall,
    ToggleMuteCall,
    FlashCall,
    SendDtmf,
    MakeCall,
    Unknown
}

export interface HeadsetCallCommand {
    CallId: number;
    Action: HeadsetCallAction;
    Value?: any;
}
