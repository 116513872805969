import { Component, OnInit, Self } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ForwardDestination, ForwardDestinationType, GroupHoursMode, RequestUpdateGroup, } from '@myphone';
import { ModalButtons } from '@webclient/modal/message-box';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { Observable, switchMap, takeUntil } from 'rxjs';
import { take } from 'rxjs/operators';
import { publishRef } from '@webclient/rx-share-utils';
import { MyExtensionInfoEx } from '@webclient/myphone/my-extension-info-ex';
import { McmGroupService, PromptFile } from '@webclient/auth/mcm-group.service';
import {
    constructExpirationDateInUTC,
    convertToMyPhoneGroupHours,
    isOfficeHoursOverrideExpired
} from './override-hours-funcs';
import { OverrideHoursTimeout } from './override-hours-timeout';
import { DestroyService } from '@webclient/services/destroy.service';
import { setControlEnabled } from '@webclient/fields/fields.utils';
import { ForwardDestinationValue } from '@webclient/standalones/forwarding-rules';
import { DialogComponent } from '@webclient/modal/dialog';
import { noEmitAndShowMessageOnError } from '@webclient/rx-utils';
import { ModalService } from '@webclient/modal/app-modal.service';
import type { CheckOption } from '@webclient/fields/types';
import { PbxGroup } from '@xapi';

const DEFAULT_FORWARD = new ForwardDestination({ Number: '', FwdType: ForwardDestinationType.FD_Disconnect });
const DEFAULT_OVERRIDE_TIMEOUT = OverrideHoursTimeout.M15;

@Component({
    selector: 'app-override-office-hours-dialog',
    providers: [DestroyService],
    templateUrl: './override-office-hours-dialog.component.html',
})

export class OverrideOfficeHoursDialogComponent extends DialogComponent implements OnInit {
    readonly buttons = ModalButtons.OkCancel;
    readonly groups: PbxGroup[] = [];

    // Dropdowns options
    readonly groupHoursModeOptions = [
        { value: GroupHoursMode.GHM_ForceOpened },
        { value: GroupHoursMode.GHM_ForceClosed },
        { value: GroupHoursMode.GHM_ForceBreak },
        { value: GroupHoursMode.GHM_ForceHoliday },
        { value: GroupHoursMode.GHM_ForceCustomOperator },
        { value: GroupHoursMode.GHM_Default, label: '_i18n.SetOfficeBackToDefaultHours' }
    ];

    readonly overrideExpireOptions: CheckOption<OverrideHoursTimeout>[] = [
        { value: OverrideHoursTimeout.M15, label: '_i18n.OverrideProfile15min' },
        { value: OverrideHoursTimeout.M30, label: '_i18n.OverrideProfile30min' },
        { value: OverrideHoursTimeout.H1, label: '_i18n.OverrideProfile1hour' },
        { value: OverrideHoursTimeout.H2, label: '_i18n.OverrideProfile2hours' },
        { value: OverrideHoursTimeout.H12, label: '_i18n.OverrideProfile12hours' },
        { value: OverrideHoursTimeout.D1, label: '_i18n.OverrideProfile1day' },
        { value: OverrideHoursTimeout.D2, label: '_i18n.OverrideProfile2days' },
        { value: OverrideHoursTimeout.W1, label: '_i18n.OverrideProfile1week' },
        { value: OverrideHoursTimeout.W2, label: '_i18n.OverrideProfile2week' },
        { value: OverrideHoursTimeout.W3, label: '_i18n.OverrideProfile3week' },
        { value: OverrideHoursTimeout.M1, label: '_i18n.OverrideProfile1month' }
    ];

    readonly form = this.fb.nonNullable.group({
        Group: this.fb.control<PbxGroup|null>(null, Validators.required),
        GroupHoursMode: [GroupHoursMode.GHM_ForceOpened],
        OverrideExpiresAt: [DEFAULT_OVERRIDE_TIMEOUT],
        selection: [],
        customOperator: new FormControl<ForwardDestinationValue>({ forwardDestination: DEFAULT_FORWARD }),
        CustomPrompt: new FormControl({ value: '', disabled: true }, {
            nonNullable: true,
            validators: Validators.required
        })
    });

    readonly myInfo$: Observable<MyExtensionInfoEx>;

    showValidation = false;

    constructor(
        @Self() private destroy$: DestroyService,
        private myPhoneService: MyPhoneService,
        private mcmGroupService: McmGroupService,
        private modalService: ModalService,
        private fb: FormBuilder
    ) {
        super();

        this.myInfo$ = this.myPhoneService.myPhoneSession.pipe(switchMap(session => session.myInfo$), publishRef());
    }

    get group() {
        return this.form.value.Group;
    }

    ngOnInit(): void {
        const { customOperator, GroupHoursMode, CustomPrompt } = this.form.controls;

        this.form.patchValue({
            Group: this.groups[0]
        });

        customOperator.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(value => {
            setControlEnabled(CustomPrompt, value?.playAnnouncement);
        });

        GroupHoursMode.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
            customOperator.setValue({ forwardDestination: DEFAULT_FORWARD });
        });
    }

    private validateFormOnSubmit = this.createDialogValidate(this.form);

    validateBeforeSubmit = (): boolean => {
        this.showValidation = true;
        return this.validateFormOnSubmit();
    };

    submit$ = () => {
        const group = this.form.value.Group!;
        const id = +group.Id!;

        return this.mcmGroupService.getGroupPrompts$(id)
            .pipe(
                take(1),
                switchMap(prompts => this.myPhoneService.get(this.generateUpdateRequest(id, prompts))),
                noEmitAndShowMessageOnError(this.modalService)
            );
    };

    private generateUpdateRequest(groupId: number, groupPromptInfo: PromptFile[]): RequestUpdateGroup {
        const {
            customOperator,
            GroupHoursMode: currentGroupHours = GroupHoursMode.GHM_Default,
            CustomPrompt: customPromptFile,
            OverrideExpiresAt
        } = this.form.value;

        const requestUpdateGroup = new RequestUpdateGroup({
            IdGroup: groupId,
            CurrentGroupHours: currentGroupHours
        });

        if (currentGroupHours !== GroupHoursMode.GHM_Default) {
            requestUpdateGroup.OverrideExpiresAt = constructExpirationDateInUTC(OverrideExpiresAt ?? DEFAULT_OVERRIDE_TIMEOUT);
        }
        if (currentGroupHours === GroupHoursMode.GHM_ForceCustomOperator) {
            const isAnnouncementEnabled = customOperator?.playAnnouncement;

            // need to find the prompt full path
            requestUpdateGroup.CustomPrompt = (isAnnouncementEnabled && groupPromptInfo.find(p => p.promptFileName === customPromptFile)?.promptFullPath) || '';
            requestUpdateGroup.DisableCustomPrompt = !isAnnouncementEnabled;
            requestUpdateGroup.CustomOperator = customOperator?.forwardDestination ?? DEFAULT_FORWARD;
        }
        return requestUpdateGroup;
    }

    /* Getters */
    get isAnnouncementEnabled() {
        return this.form.value.customOperator?.playAnnouncement;
    }

    get isCustomMode() {
        return this.form.value.GroupHoursMode === GroupHoursMode.GHM_ForceCustomOperator;
    }

    get isSetToDefaultHours() {
        return this.form.value.GroupHoursMode === GroupHoursMode.GHM_Default;
    }

    isOfficeHoursOverrideExpired(group: PbxGroup) {
        return isOfficeHoursOverrideExpired(group);
    }

    officeHoursModeDisplay(group: PbxGroup) {
        return this.isOfficeHoursOverrideExpired(group) ? GroupHoursMode.GHM_Default : convertToMyPhoneGroupHours(group.CurrentGroupHours);
    }

    expirationTimeDisplay(group: PbxGroup): Date | null {
        if (!group.OverrideExpiresAt || this.isOfficeHoursOverrideExpired(group)) {
            return null;
        }
        // Important: received expiration date is in UTC (e.g OverrideExpiresAt = 2023-09-21T09:45:08Z)
        return new Date(group.OverrideExpiresAt);
    }
}
