import { Component, Input } from '@angular/core';
import { DialogComponent } from '@webclient/modal/dialog';
import { ModalButtons } from '@webclient/modal/message-box';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CheckOption } from '@webclient/fields/types';

type DnNumber = {
    number: string;
}

type QMonitorPayload = {
    dnNumber: string;
    dayCount: number;
}
@Component({
    template: `
        <app-modal-dialog [header]="'_i18n.SetQMonitorHeader' | translate:DN" [buttons]="buttons" (submitted)="onSubmit()" [formGroup]="form">
                <field-select label="_i18n.ForPeriod" [options]="periodOptions"  formControlName="duration">
                </field-select>
        </app-modal-dialog>
    `
})
export class SetQualityMonitorDialogComponent extends DialogComponent<QMonitorPayload> {
    readonly buttons = ModalButtons.OkCancel;
    readonly form = new FormGroup({
        duration: new FormControl(1, { nonNullable: true, validators: Validators.required }),
    });

    @Input() DN: DnNumber;

    readonly periodOptions: CheckOption<number>[] = [
        { value: 1, label: '_i18n.CallMonitorPeriod1day' },
        { value: 3, label: '_i18n.CallMonitorPeriod3days' },
        { value: 5, label: '_i18n.CallMonitorPeriod5days' },
        { value: 7, label: '_i18n.CallMonitorPeriod7days' },
    ];

    protected getPayload(): QMonitorPayload | undefined {
        return {
            dnNumber: this.DN.number,
            dayCount: this.form.value.duration!
        };
    }
}
