<app-modal-dialog header="_i18n.RecordMediaPromptFile" [buttons]="0" (submitted)="onSubmit()" #dialog [disableSubmitOnEnter]="true">
    <div class="pb-2">
        <p data-qa="recording-error" class="text-error" *ngIf="error$ | async as error">{{error}}</p>

        <ng-template #noRecord>
            <ng-template #recordNameInput>
            <field-input
                class="mb-0"
                [formControl]="recordFileName"
                label="_i18n.RecordMediaPromptFileInfo"
                maxlength="45"
                data-qa="file-name-recording"
                placeholder="_i18n.AudioFileName"
                [inlineButtonIcon]="svgButtonIcon"
                [inlineButtonDisabled]="recordFileName.invalid"
                (inlineButtonClick)="startRecording()">
                <val-errors [control]="recordFileName">
                    <ng-template valError='required'>{{'_i18n.FileNameIsRequired' | translate}}</ng-template>
                    <ng-template valError='pattern'>{{'_i18n.FileNameCannotContainFollowingCharacters' | translate }}</ng-template>
                </val-errors>
            </field-input>
            </ng-template>

            <div *ngIf="isRecording$ | async else recordNameInput" class="recording"
                 (mouseover)="recordIconHovered=true" (mouseout)="recordIconHovered=false"
                 (click)="stopRecording()" role="button" data-qa="stop-record">
                <span [@fadeIn]="true" [hidden]="recordIconHovered" class="customSVGIcons svg-xxl" app-microphone-alt-solid-icon></span>
                <span [@fadeIn]="true" [hidden]="!recordIconHovered" class="customSVGIcons svg-xxl" app-stop-solid-icon></span>
                <span>{{recordingTime$ | async}}</span>
            </div>
        </ng-template>

        <app-audio-player
            *ngIf="record$ | async as record; else noRecord"
            [src]="record.dataUrl"
            [showResetUploadButtons]="true"
            (action)="onAudioPlayerAction($event)">
        </app-audio-player>
    </div>
</app-modal-dialog>
