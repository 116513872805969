import { IContactDb } from './contact-db';
import { AppContact } from './contact';

export class MergedContactDb implements IContactDb {
    constructor(private readonly contactDb : IContactDb[]) {
    }

    public findPhone(phoneNumber: string, hintBridgeId?: string, includeHiddenContacts?: boolean): AppContact|undefined {
        for (const db of this.contactDb) {
            const contact = db.findPhone(phoneNumber, hintBridgeId, includeHiddenContacts);
            if (contact) {
                return contact;
            }
        }
        return undefined;
    }

    // / Be aware that we searching only in contacts with number,
    // If contact without number but with email will be created, that can cause a problem
    public findByEmail(email: string) {
        for (const db of this.contactDb) {
            const contact = db.findByEmail(email);
            if (contact) {
                return contact;
            }
        }
        return undefined;
    }
}
