import {
    ChangeDetectorRef,
    Component,
    EventEmitter, HostBinding,
    HostListener,
    Input,
    OnInit,
    Output
} from '@angular/core';
import { ChatLocatorService } from '../chat-data/chat-locator.service';
import { PushChatMessage } from '@webclient/notifications/push-chat-message';
import { IntegrationService } from '@webclient/layout/integration.service';
import { combineLatest, Observable, of } from 'rxjs';
import { delay, switchMap, take, tap } from 'rxjs/operators';
import { PhonebookEditorService } from '@webclient/phonebook-editor/phonebook-editor.service';
import { ContactViewModel } from '@webclient/phonebook-editor/contact-view-model';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import {
    ChatMonitoringEvent,
    RequestCreateConversation,
    ResponseConversationInfo
} from '@myphone';
import { AppContactType } from '@webclient/myphone/app-contact-type';
import { ContactSearcherService } from '@webclient/shared/service/contact-searcher.service';
import { AppContact } from '@webclient/myphone/contact';
import { Router } from '@angular/router';
import { ContactViewMode } from '@webclient/phonebook-editor/contact-view-mode';
import { ContactCreationStatus,
    ContactCreationType
} from '@webclient/phonebook/contact-creation-error/contact-creation-result';
import { defaultWidthInPixels } from '@webclient/standalones/toast-container';
import { ModalService } from '@webclient/modal/app-modal.service';

@Component({
    selector: 'chat-toast-component',
    templateUrl: 'chat-toast.component.html',
    styleUrls: ['chat-toast.component.scss']
})
export class ChatToastComponent implements OnInit {
    public createdContact: AppContact | null = null;

    @Input()
    message: PushChatMessage;

    @Output()
    readonly remove = new EventEmitter();

    @HostBinding('style.width.px')
    readonly chatToastWidth = defaultWidthInPixels;

    public hasClientCrmIntegration$: Observable<boolean>;

    get participantContact() {
        return this.message?.participant?.contact;
    }

    get chatContact() {
        return this.createdContact ?? this.participantContact;
    }

    get canOpenClientCrmUrl() {
        return this.message?.participant?.isExternal && this.integrationService.canOpenContactClientSideCrmUrl(this.participantContact);
    }

    get canBeAdded() {
        return !!this.chatContact &&
            !this.chatContact.crmUrl &&
            this.chatContact.type === AppContactType.External &&
            (this.chatContact.bestPhone.length > 0 || Boolean(this.chatContact.emailAddress));
    }

    @HostListener('click', ['$event'])
    reply(event: MouseEvent) {
        event.preventDefault();
        this.chatLocatorService.openChatById(this.message.conversationId);
        this.remove.next(this.message);
    }

    constructor(
        private chatLocatorService: ChatLocatorService,
        private integrationService: IntegrationService,
        private router: Router,
        private myPhoneService: MyPhoneService,
        private cd: ChangeDetectorRef,
        private phonebookEditorService: PhonebookEditorService,
        private modalService: ModalService,
        private contactSearcherService: ContactSearcherService) {
    }

    public openClientCrmUrl() {
        this.integrationService.openClientCrmUrl(this.message?.participant?.contact.bestPhone, this.message?.participant?.contact.emailAddress);
    }

    ignore(event: MouseEvent) {
        event.preventDefault();
        this.remove.next(this.message);
    }

    ngOnInit(): void {
        this.hasClientCrmIntegration$ = this.integrationService.hasClientCrmIntegration$;
    }

    addContact() {
        if (!this.participantContact || (!this.participantContact.bestPhone && !this.participantContact.emailAddress)) {
            return;
        }
        combineLatest([
            this.myPhoneService.myPhoneSession,
            this.phonebookEditorService.addToContact(this.participantContact, ContactViewMode.Compact).pipe(
                // wait a bit after the creation is confirmed and then request for the new contact
                // because the request might not return anything even though the create request has received a success response
                delay(500),
                switchMap(({ model, creationResults }) => {
                    const isLocalContactCreated = creationResults.some(result =>
                        (result.type === ContactCreationType.Personal || result.type === ContactCreationType.Company) &&
                        result.status === ContactCreationStatus.Success
                    );
                    if (model && isLocalContactCreated) {
                        return this.requestLocalContactCreated$(model);
                    }
                    else {
                        const externalContactCreated = creationResults.find(result => (result.type === ContactCreationType.Crm || result.type === ContactCreationType.Microsoft365) && result.status === ContactCreationStatus.Success)?.createdContact;
                        return of(externalContactCreated);
                    }
                }),
                tap((contactCreated) => {
                    if (contactCreated && !contactCreated?.isDummy) {
                        this.createdContact = contactCreated;
                    }
                    this.cd.markForCheck();
                })
            )
        ]).pipe(
            take(1),
            switchMap(([session]) => {
                return session.get<ResponseConversationInfo>(new RequestCreateConversation({
                    IdConversation: this.message.conversationId
                })).pipe(
                    tap(response => {
                        // todo - ca - to be decided on update of conversation I do not get back a contact (external) associated with the conversation therefore the add contact will be shown again on next message!!!
                        // Notify everybody on chat change
                        session.conversationInfo$.next(response);
                        session.chatMonitoringEvent$.next(new ChatMonitoringEvent({
                            OnConversationChanged: response
                        }));
                    })
                );
            }),
        ).subscribe({
            error: (error: unknown) => {
                this.modalService.error(error);
            }
        });
    }

    requestLocalContactCreated$(model: ContactViewModel): Observable<AppContact|null> {
        if (model && this.participantContact) {
            if (!!this.participantContact.nativeMobileNumber && model.hasPhoneNumber(this.participantContact.nativeMobileNumber)) {
                return this.contactSearcherService.requestContactByNumber(this.participantContact?.nativeMobileNumber ?? '');
            }
            else if (!!this.participantContact.emailAddress && model.emailAddress === this.participantContact.emailAddress) {
                return this.contactSearcherService.requestContactByEmail(this.participantContact?.emailAddress ?? '');
            }
        }
        return of(null);
    }

    viewContact(contact: AppContact) {
        if (!contact || !contact.id) {
            return;
        }
        this.router.navigate(['contacts/view', contact.type, contact.id]);
    }
}
