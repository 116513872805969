/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { GenerateApiKey200Response } from '../model/generate-api-key200-response';
// @ts-ignore
import { PbxForwardingProfileCollectionResponse } from '../model/pbx-forwarding-profile-collection-response';
// @ts-ignore
import { PbxGreetingCollectionResponse } from '../model/pbx-greeting-collection-response';
// @ts-ignore
import { PbxODataErrorsODataError } from '../model/pbx-o-data-errors-o-data-error';
// @ts-ignore
import { PbxUser } from '../model/pbx-user';
// @ts-ignore
import { PbxUserGroupCollectionResponse } from '../model/pbx-user-group-collection-response';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


export interface GetMyUserRequestParams {
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ListMyUserForwardingProfilesRequestParams {
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ListMyUserGreetingsRequestParams {
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface ListMyUserGroupsRequestParams {
    /** Show only the first n items */
    $top?: number;
    /** Skip the first n items */
    $skip?: number;
    /** Search items by search phrases */
    $search?: string;
    /** Filter items by property values */
    $filter?: string;
    /** Include count of items */
    $count?: boolean;
    /** Order items by property values */
    $orderby?: Array<string>;
    /** Select properties to be returned */
    $select?: Array<string>;
    /** Expand related entities */
    $expand?: Array<string>;
}

export interface UpdateMyUserRequestParams {
    /** New property values */
    pbxUser: PbxUser;
}


@Injectable({
  providedIn: 'root'
})
export class MyUserApiService {

    protected basePath = '/xapi/v1';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Get MyUser
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getMyUser(requestParameters: GetMyUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxUser>;
    public getMyUser(requestParameters: GetMyUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxUser>>;
    public getMyUser(requestParameters: GetMyUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxUser>>;
    public getMyUser(requestParameters: GetMyUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyUser`;
        return this.httpClient.request<PbxUser>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public getMyUserQuery(requestParameters: GetMyUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyUserQuery(requestParameters: GetMyUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyUserQuery(requestParameters: GetMyUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public getMyUserQuery(requestParameters: GetMyUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/MyUser`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Get ForwardingProfiles from MyUser
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMyUserForwardingProfiles(requestParameters: ListMyUserForwardingProfilesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxForwardingProfileCollectionResponse>;
    public listMyUserForwardingProfiles(requestParameters: ListMyUserForwardingProfilesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxForwardingProfileCollectionResponse>>;
    public listMyUserForwardingProfiles(requestParameters: ListMyUserForwardingProfilesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxForwardingProfileCollectionResponse>>;
    public listMyUserForwardingProfiles(requestParameters: ListMyUserForwardingProfilesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyUser/ForwardingProfiles`;
        return this.httpClient.request<PbxForwardingProfileCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listMyUserForwardingProfilesQuery(requestParameters: ListMyUserForwardingProfilesRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyUserForwardingProfilesQuery(requestParameters: ListMyUserForwardingProfilesRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyUserForwardingProfilesQuery(requestParameters: ListMyUserForwardingProfilesRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyUserForwardingProfilesQuery(requestParameters: ListMyUserForwardingProfilesRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/MyUser/ForwardingProfiles`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Get Greetings from MyUser
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMyUserGreetings(requestParameters: ListMyUserGreetingsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxGreetingCollectionResponse>;
    public listMyUserGreetings(requestParameters: ListMyUserGreetingsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxGreetingCollectionResponse>>;
    public listMyUserGreetings(requestParameters: ListMyUserGreetingsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxGreetingCollectionResponse>>;
    public listMyUserGreetings(requestParameters: ListMyUserGreetingsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyUser/Greetings`;
        return this.httpClient.request<PbxGreetingCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listMyUserGreetingsQuery(requestParameters: ListMyUserGreetingsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyUserGreetingsQuery(requestParameters: ListMyUserGreetingsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyUserGreetingsQuery(requestParameters: ListMyUserGreetingsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyUserGreetingsQuery(requestParameters: ListMyUserGreetingsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/MyUser/Greetings`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Get Groups from MyUser
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public listMyUserGroups(requestParameters: ListMyUserGroupsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<PbxUserGroupCollectionResponse>;
    public listMyUserGroups(requestParameters: ListMyUserGroupsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<PbxUserGroupCollectionResponse>>;
    public listMyUserGroups(requestParameters: ListMyUserGroupsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<PbxUserGroupCollectionResponse>>;
    public listMyUserGroups(requestParameters: ListMyUserGroupsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const $top = requestParameters.$top;
        const $skip = requestParameters.$skip;
        const $search = requestParameters.$search;
        const $filter = requestParameters.$filter;
        const $count = requestParameters.$count;
        const $orderby = requestParameters.$orderby;
        const $select = requestParameters.$select;
        const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if ($top !== undefined && $top !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
        }
        if ($skip !== undefined && $skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
        }
        if ($search !== undefined && $search !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
        }
        if ($filter !== undefined && $filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
        }
        if ($count !== undefined && $count !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
        }
        if ($orderby) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
        }
        if ($select) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$select].join(COLLECTION_FORMATS['csv']), '$select');
        }
        if ($expand) {
            localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyUser/Groups`;
        return this.httpClient.request<PbxUserGroupCollectionResponse>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public listMyUserGroupsQuery(requestParameters: ListMyUserGroupsRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyUserGroupsQuery(requestParameters: ListMyUserGroupsRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyUserGroupsQuery(requestParameters: ListMyUserGroupsRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public listMyUserGroupsQuery(requestParameters: ListMyUserGroupsRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const $top = requestParameters.$top;
            const $skip = requestParameters.$skip;
            const $search = requestParameters.$search;
            const $filter = requestParameters.$filter;
            const $count = requestParameters.$count;
            const $orderby = requestParameters.$orderby;
            const $select = requestParameters.$select;
            const $expand = requestParameters.$expand;

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
                if ($top !== undefined && $top !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$top, '$top');
                }
                if ($skip !== undefined && $skip !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$skip, '$skip');
                }
                if ($search !== undefined && $search !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$search, '$search');
                }
                if ($filter !== undefined && $filter !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$filter, '$filter');
                }
                if ($count !== undefined && $count !== null) {
                localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>$count, '$count');
                }
        if ($orderby) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$orderby].join(COLLECTION_FORMATS['csv']), '$orderby');
            }
        if ($select) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$select].join(COLLECTION_FORMATS['csv']), '$select');
            }
        if ($expand) {
                    localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
                    [...$expand].join(COLLECTION_FORMATS['csv']), '$expand');
            }

    return `/MyUser/Groups`+ '?' +localVarQueryParameters.toString();
}

    /**
     * Invoke function GenerateProvLink
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public myUserGenerateProvLink(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<GenerateApiKey200Response>;
    public myUserGenerateProvLink(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<GenerateApiKey200Response>>;
    public myUserGenerateProvLink(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<GenerateApiKey200Response>>;
    public myUserGenerateProvLink(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyUser/Pbx.GenerateProvLink()`;
        return this.httpClient.request<GenerateApiKey200Response>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public myUserGenerateProvLinkQuery(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public myUserGenerateProvLinkQuery(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public myUserGenerateProvLinkQuery(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public myUserGenerateProvLinkQuery(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {

    return `/MyUser/Pbx.GenerateProvLink()`;
}

    /**
     * Update MyUser
     * @param requestParameters
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateMyUser(requestParameters: UpdateMyUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any>;
    public updateMyUser(requestParameters: UpdateMyUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpResponse<any>>;
    public updateMyUser(requestParameters: UpdateMyUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<HttpEvent<any>>;
    public updateMyUser(requestParameters: UpdateMyUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): Observable<any> {
        const pbxUser = requestParameters.pbxUser;
        if (pbxUser === null || pbxUser === undefined) {
            throw new Error('Required parameter pbxUser was null or undefined when calling updateMyUser.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarCredential: string | undefined;
        // authentication (Application) required
        localVarCredential = this.configuration.lookupCredential('Application');
        if (localVarCredential) {
            localVarHeaders = localVarHeaders.set('Authorization', 'Bearer ' + localVarCredential);
        }

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/MyUser`;
        return this.httpClient.request<any>('patch', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: pbxUser,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }
    public updateMyUserQuery(requestParameters: UpdateMyUserRequestParams, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateMyUserQuery(requestParameters: UpdateMyUserRequestParams, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateMyUserQuery(requestParameters: UpdateMyUserRequestParams, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string;
    public updateMyUserQuery(requestParameters: UpdateMyUserRequestParams, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json', context?: HttpContext}): string {
            const pbxUser = requestParameters.pbxUser;
        if (pbxUser === null || pbxUser === undefined) {
        throw new Error('Required parameter pbxUser was null or undefined when calling updateMyUser.');
        }

    return `/MyUser`;
}

}
