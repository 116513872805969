import { SchedulerLike } from 'rxjs';
import { NgZone } from '@angular/core';
import { LeaveZoneScheduler } from '@webclient/myphone/leave-zone-scheduler';
import { EnterZoneScheduler } from '@webclient/myphone/enter-zone-scheduler';

export function leaveZone(zone: NgZone, scheduler: SchedulerLike): SchedulerLike {
    return new LeaveZoneScheduler(zone, scheduler);
}

export function enterZone(zone: NgZone, scheduler: SchedulerLike): SchedulerLike {
    return new EnterZoneScheduler(zone, scheduler);
}
