import { Inject, Injectable, NgZone, Optional } from '@angular/core';
import {
    concatMap,
    connectable,
    from,
    merge,
    NEVER,
    Observable,
    of,
    race,
    ReplaySubject,
    Subject,
    Subscription,
    tap,
    timer,
    zip
} from 'rxjs';
import { MyPhoneSession } from './myphone-session';
import { Router } from '@angular/router';
import { BASE_PATH, LoginData } from '@api';
import { deepCopy, Logger, MY_PHONE_LOG } from '@webclient/myphone/logger';
import {
    filter,
    finalize,
    map,
    retry,
    switchMap,
    take,
    takeWhile,
    withLatestFrom
} from 'rxjs/operators';
import {
    SharedWorkerConnect,
    SharedWorkerRequest
} from '@webclient/worker/protocol/shared-worker-request';
import { SharedWorkerResponse } from '@webclient/worker/protocol/shared-worker-response';
import { MyPhoneService, retryTimeoutMs } from '@webclient/myphone/myphone.service';
import { WorkerResponseError } from '@webclient/worker/protocol/worker-response-error';
import { Groups, Logout, MyExtensionInfo, ResponseServerTime } from '@myphone';
import { DataTransportService } from '@webclient/myphone/data-transport.service';
import { workerTypeMessageMapperFunc } from '@webclient/worker/shared-worker-myphone-mapper.func';
import { GenericMessageType } from '@webclient/shared/myphone-types';
import { getSessionStartupParams } from '@webclient/myphone/bootstrap-notification-chanel.func';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { observe } from '@webclient/rx-utils';
import { reload } from '@webclient/my-error-handler';
import { ConnectableObservableLike } from './connectableObservableLike';
import { publishRef } from '@webclient/rx-share-utils';
import { WorkerTypeMessage } from '@webclient/worker/protocol/worker-type-message';
import { ImmediateReconnectError } from '@webclient/myphone/immediate-reconnect-error';

function createObservableFromSharedWorker(worker: SharedWorker, payload: SharedWorkerConnect): Observable<SharedWorkerResponse<'notification' | 'connection'>> {
    return new Observable<SharedWorkerResponse<'notification' | 'connection'>>(observer => {
        const messageHandler = (message: MessageEvent) => {
            if (message.data.error) {
                observer.error(new WorkerResponseError(message.data.error));
            }
            else {
                observer.next(new SharedWorkerResponse(message.data));
            }
        };
        const errorHandler = (error: MessageEvent) => observer.error(error);

        const port = worker.port;
        port.addEventListener('message', messageHandler);
        port.addEventListener('messageerror', errorHandler);
        port.postMessage(new SharedWorkerRequest({
            type: 'connect',
            payload
        }));
        // Port can be already started by this time
        port.start();

        return () => {
            port.removeEventListener('message', messageHandler);
            port.removeEventListener('messageerror', errorHandler);
        };
    });
}

@Injectable()
export class SharedMyphoneService {
    public retryNow$ = new Subject<void>();
    public reconnectTime: number;
    protected _logout: boolean;
    readonly hasQueues$: Observable<boolean>;
    readonly isPro$: Observable<boolean>;
    isReconnecting: boolean;
    readonly licenseHasHotelFeatures$: Observable<boolean>;
    readonly licenseHasWebinarFeatures$: Observable<boolean>;
    readonly myPhoneSession: Observable<MyPhoneSession>;
    private readonly _myPhoneSession: ConnectableObservableLike<MyPhoneSession>;
    private connectStartTime: number|undefined;
    public myPhoneSessionInit$ = new Subject<boolean>();

    constructor(private router: Router, private zone: NgZone,
        private localStorageService: LocalStorageService,
                private dataTransport: DataTransportService,
                private logger: Logger,
                @Optional()@Inject(BASE_PATH) private basePath: string,
                private sharedWorker: SharedWorker
    ) {
        const login = () => {
            // We're not authenticated
            this._logout = true;
            this.gotoLogin();
            return NEVER;
        };

        const provisionBootstrap$: Observable<ClientProvisionStorage> = observe<ClientProvisionStorage>(localStorageService, LocalStorageKeys.Provision, { domain: basePath });

        const sharedWorker$ = provisionBootstrap$.pipe(
            switchMap((provision) => {
                // No PBX domain reported and nowhere to connect
                if (!provision || !provision.domain) {
                    return login();
                }
                this.connectStartTime = Date.now();
                return createObservableFromSharedWorker(sharedWorker, { basePath: provision.domain, username: provision.username ?? '', loggerEnabled: localStorageService.retrieve(LocalStorageKeys.LoggerEnabled) }).pipe(
                    retry({ delay: (error, attempt) => {
                        // Tell user what's happened
                        console.log(error);
                        if (error instanceof WorkerResponseError && (error.status === 1006)) {
                            // TODO this error comes if PBX is down also - no need to disable worker in this case
                            // Indicates that we can't load due to SSL errors
                            localStorageService.store(LocalStorageKeys.SharedWorker, false);
                            reload(0);
                        }
                        else if (error instanceof WorkerResponseError && (error.status === 401 || error.status === 403)) {
                            // Nothing can be done just go to login
                            return login();
                            // socket was closed
                        }
                        else if (error instanceof WorkerResponseError && (error.status === 1000)) {
                            // Nothing can be done just go to login
                            return login();
                        }
                        // An error happened but we can retry connection
                        this.isReconnecting = true;
                        return this.retry();
                    } }),
                );
            }),
            publishRef()
        );

        const messageChanel$ = sharedWorker$.pipe(
            filter(data => data.type === 'notification'),
            concatMap(response => from((<WorkerTypeMessage[]>response.payload).map(workerTypeMessageMapperFunc)))
        );

        const bootChanel$ = (session: MyPhoneSession) =>
            merge(getSessionStartupParams(session), messageChanel$);

        this._myPhoneSession = connectable(sharedWorker$.pipe(
            filter(data => data.type === 'connection'),
            withLatestFrom(provisionBootstrap$),
            map(([response, provision]) =>
                // Time to create new session
                new MyPhoneSession(response.payload as LoginData, logger, this.dataTransport, provision.domain!)
            ),
            switchMap(session => merge(
                session.errorDetected$,
                this.processMyPhoneMessages(session, bootChanel$(session))
            )),
            // Retry if any error occurred
            retry({
                delay: error => {
                    // Tell user what's happened
                    console.log(error);
                    this.isReconnecting = true;
                    this.reconnectTime = 0;

                    const retryInvoker = error instanceof ImmediateReconnectError ? of<0>(0) : this.retry();

                    return retryInvoker.pipe(takeWhile(() => !this._logout));
                }
            }),
            finalize(() => this.isReconnecting = false),
            map(x => x as any),
        ), {
            connector: () => new ReplaySubject<MyPhoneSession>(1)
        });

        this.myPhoneSession = this._myPhoneSession;

        this.isPro$ = this.myPhoneSession.pipe(map(session => session.isPro()));

        this.licenseHasHotelFeatures$ = this.myPhoneSession.pipe(map(session => session.licenseHasHotelFeatures()));
        this.licenseHasWebinarFeatures$ = this.licenseHasHotelFeatures$;

        this.hasQueues$ = this.myPhoneSession.pipe(switchMap(session => session.queues$), map(queues => Object.keys(queues).length > 0));
    }

    sessionSubscription?: Subscription | undefined;

    retry() {
        const timeout = retryTimeoutMs();
        this.reconnectTime = Date.now() + timeout;
        return race([timer(timeout), this.retryNow$]).pipe(
            tap(() => {
                this.reconnectTime = 0;
            })
        );
    }

    public connect() {
        if (!this.sessionSubscription) {
            this._logout = false;
            this.sessionSubscription = this._myPhoneSession.connect();
            this.myPhoneSessionInit$.next(true);
        }
    }

    public disconnect() {
        this.sessionSubscription?.unsubscribe();
        this.sessionSubscription = undefined;
    }

    public get<T extends GenericMessageType>(request: GenericMessageType): Observable<T> {
        return this.myPhoneSession.pipe(take(1), switchMap(session => session.get<T>(request)));
    }

    public httpPost<T>(requestUrl: string, body: any): Observable<T> {
        return this.myPhoneSession.pipe(take(1), switchMap(session => session.fetchPost(requestUrl, body)));
    }

    private gotoLogin(): void {
        this.router.navigate(['/login']);
    }

    // Create notification channel
    public logout() {
        this._logout = true;
        zip(
            this.get(new Logout()),
            of(this.sharedWorker.port.postMessage(new SharedWorkerRequest({ type: 'logout' })))
        )
            .subscribe({
                next: () => {
                    this.localStorageService.store(LocalStorageKeys.LogoutTimestamp, new Date().getTime());
                    this.gotoLogin();
                },
                error: () => {
                    this.localStorageService.store(LocalStorageKeys.LogoutTimestamp, new Date().getTime());
                    this.gotoLogin();
                }
            });
    }

    private processMyPhoneMessages(myPhoneSession: MyPhoneSession, source: Observable<any>): Observable<MyPhoneSession> {
        let myExtensionInfoReceived = false;
        let timeReceived = false;
        let groupsReceived = false;
        let myPhoneSessionReported = false;

        return new Observable((subscriber: any) => source.subscribe({
            next: (message: unknown) => {
                try {
                    if (this.logger.logEnabled) {
                        const proto = Object.getPrototypeOf(message);
                        if (proto.typeName) {
                            this.logger.showInfo({
                                loggingLevel: MY_PHONE_LOG,
                                typeName: proto.typeName,
                                optionalParams: [deepCopy(message)]
                            });
                        }
                    }
                    // prevents expose the notifications in an abandoned session
                    if (!myPhoneSession.isActive) {
                        return;
                    }
                    MyPhoneService.join(myPhoneSession, message);
                    if (!myPhoneSessionReported) {
                        // MyExtensionInfo received
                        if (message instanceof MyExtensionInfo) {
                            myExtensionInfoReceived = true;
                        }
                        // Groups received
                        else if (message instanceof Groups && message.FromLocalPbx) {
                            groupsReceived = true;
                        }
                        else if (message instanceof ResponseServerTime) {
                            timeReceived = true;
                        }
                        if (myExtensionInfoReceived && groupsReceived && timeReceived) {
                            if (this.logger.logEnabled) {
                                this.logger.showInfo({
                                    loggingLevel: MY_PHONE_LOG,
                                    message: `Session initiated in ${(Date.now() - (this.connectStartTime ?? 0)) / 1000}`
                                });
                            }

                            this.isReconnecting = false;
                            myPhoneSessionReported = true;
                            subscriber.next(myPhoneSession);
                        }
                    }
                }
                catch (error) {
                // MyPhone session is inactive now and we will retry a connection
                    if (myPhoneSession !== undefined) {
                        myPhoneSession.deactivate();
                    }
                    subscriber.error(error);
                }
            },
            error: (err: unknown) => {
                if (myPhoneSession !== undefined) {
                    myPhoneSession.deactivate();
                }
                subscriber.error(err);
            },
            complete: () => subscriber.complete()
        }));
    }
}
