export class DisplayedProfile {
    public readonly id: number;
    public readonly name: string;
    public readonly internalName: string;
    public readonly customMessage: string;

    constructor(init?: Partial<DisplayedProfile>) {
        Object.assign(this, init);
    }
}
