import {
    EMPTY,
    forkJoin,
    Observable,
    of,
    tap
} from 'rxjs';
import { expand, last, map, skip } from 'rxjs/operators';
import { TokenApiService } from '@api';
import { appName } from '@webclient/worker/fingerprint';
import { PbxReportScheduleType, PbxScheduledReportType } from '@xapi';
import { AppliedFilter, DateRangeFilterValue } from '@office/standalones/odata-search';
import { ScheduleReportDialogComponent } from '@office/reports/schedule-report-dialog/schedule-report-dialog.component';
import { filterDialogOk } from '@webclient/rx-utils';
import { ModalService } from '@webclient/modal/app-modal.service';
import type { FilterValue, SerializedFilter } from '@office/standalones/odata-search/types';
import { newCallTypeFilterValue } from '@office/reports/filter/filters-fields/call-type-filter';
import { SourceOrDestinationFilterValue } from '@office/reports/filter/filters-fields/source-or-destination';
import { IntervalFilterValue } from '@office/reports/filter/filters-fields/interval-filter';
import { PbxQueueFilterValue } from '@office/reports/filter/filters-fields/pbx-queue-filter';
import { newParticipantTypeFilterValue } from '@office/reports/filter/filters-fields/participant-type';
import { Wtf } from '@office/admin-utils';
import { AuditActionFilterValue } from '@office/reports/filter/filters-fields/audit-action-type-filter';
import { AuditObjTypeFilterValue } from '@office/reports/filter/filters-fields/audit-obj-type-filter';
import { newCallClassFilterValue } from '@office/reports/filter/filters-fields/call-class-filter';
import { PbxGroupFilterValue } from '@office/reports/filter/filters-fields/pbx-group-filter';
import { ChatParticipantTypeFilterValue } from '@office/reports/filter/filters-fields/chat-participant-type-filter';
import { newCallAreaFilterValue } from '@office/reports/filter/filters-fields/call-area';

export interface IsReportAvailableParam {
    canViewReport: boolean
}

interface LoadAllPagesContext<TRow> {
    nextPage: number
    itemsPerPage: number
    hasNextPage: boolean,
    data: TRow[]
}

export const loadAllPages = <TRow>(itemsPerPage: number, loadPage: (page: number, itemsPerPage: number) => Observable<TRow[]>, maxRowsToLoad = 20000): Observable<TRow[]> => {
    return new Observable(observer => {
        of<[LoadAllPagesContext<TRow>, TRow[]]>([{
            data: [],
            nextPage: 0,
            hasNextPage: true,
            itemsPerPage,
        }, []]).pipe(
            expand(([context]) => {
                return context.hasNextPage && context.data.length < maxRowsToLoad ? forkJoin([of(context), loadPage(context.nextPage, context.itemsPerPage)]) : EMPTY;
            }),
            skip(1),
            tap(([context, page]) => {
                if (page.length < context.itemsPerPage) {
                    context.hasNextPage = false;
                }

                context.nextPage += 1;
                context.data = context.data.concat(page);
            }),
            last()
        ).subscribe({
            next: ([context]) => {
                observer.next(context.data);
                observer.complete();
            },
            error: (err: unknown) => observer.error(err)
        });
    });
};

export const getAccessToken$ = (tokenApiService: TokenApiService) => {
    return tokenApiService.tokenToken('refresh_token', appName, undefined, undefined, 1).pipe(map(token => token.access_token));
};

export const WtfTranslation = '???';

export function escapeODataSearch(search: string|null|undefined) {
    if (!search) {
        return undefined;
    }
    return `"${search.replaceAll('"', '\\"')}"`;
}

export const escapeODATAString = (str: string, toLowerCase = false) => {
    let result = str.replace(/'/g, "''");

    if (toLowerCase) {
        result = result.toLowerCase();
    }

    return result;
};

export function getReportScheduleTypeTranslation(scheduleType: PbxReportScheduleType | null |undefined): string {
    switch (scheduleType) {
        case PbxReportScheduleType.Daily:
            return '_i18n.Daily';
        case PbxReportScheduleType.Weekly:
            return '_i18n.WeeklyFirstDay';
        case PbxReportScheduleType.Monthly:
            return '_i18n.MonthlyFirstDay';
        case PbxReportScheduleType.NotScheduled:
            return '_i18n.SaveToMyReportsNoSchedule';
        default:
            return Wtf;
    }
}

export function getReportFilterValues(reportType: PbxScheduledReportType | null |undefined, serializedFilter: SerializedFilter): Record<string, FilterValue> {
    switch (reportType) {
        case PbxScheduledReportType.CallLogs:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                callType: newCallTypeFilterValue().deserialize(serializedFilter),
                from: SourceOrDestinationFilterValue.createSource().deserialize(serializedFilter),
                to: SourceOrDestinationFilterValue.createDestination().deserialize(serializedFilter)
            };
        case PbxScheduledReportType.ChatLogs:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                from: SourceOrDestinationFilterValue.deserialize(serializedFilter, 'from', '_i18n.FromWho'),
                to: SourceOrDestinationFilterValue.deserialize(serializedFilter, 'to', '_i18n.ToWhom'),
                participantType: ChatParticipantTypeFilterValue.deserialize(serializedFilter),
            };
        case PbxScheduledReportType.AuditLogs:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                auditActionIndex: AuditActionFilterValue.deserialize(serializedFilter),
                auditObjTypeIndex: AuditObjTypeFilterValue.deserialize(serializedFilter),
            };
        case PbxScheduledReportType.QueueAbandonedCalls:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                interval: IntervalFilterValue.deserialize(serializedFilter, '_i18n.ReportsExcludeCallsDroppedBefore'),
                pbxQueue: PbxQueueFilterValue.deserialize(serializedFilter)
            };
        case PbxScheduledReportType.QueueAnsweredCallsByWaitingTime:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                interval: IntervalFilterValue.deserialize(serializedFilter, '_i18n.ReportsServicedAfter'),
                pbxQueue: PbxQueueFilterValue.deserialize(serializedFilter)
            };
        case PbxScheduledReportType.QueueCallbacks:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
            };
        case PbxScheduledReportType.QueueFailedCallbacks:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                pbxQueue: PbxQueueFilterValue.deserialize(serializedFilter)
            };
        case PbxScheduledReportType.QueuePerformanceOverview:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                interval: IntervalFilterValue.deserialize(serializedFilter, '_i18n.ReportsExcludeCallsDroppedBefore')
            };
        case PbxScheduledReportType.QueueDetailedStatistics:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                interval: IntervalFilterValue.deserialize(serializedFilter, '_i18n.ReportsExcludeCallsDroppedBefore')
            };
        case PbxScheduledReportType.QueueTeamGeneralStatistics:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                interval: IntervalFilterValue.deserialize(serializedFilter, '_i18n.ReportsExcludeCallsDroppedBefore')
            };
        case PbxScheduledReportType.SlaStatistics:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                interval: IntervalFilterValue.deserialize(serializedFilter, '_i18n.ReportsExcludeCallsDroppedBefore')
            };
        case PbxScheduledReportType.SlaBreaches:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                interval: IntervalFilterValue.deserialize(serializedFilter, '_i18n.ReportsExcludeCallsDroppedBefore'),
                pbxQueue: PbxQueueFilterValue.deserialize(serializedFilter)
            };
        case PbxScheduledReportType.AgentInQueueStatistics:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                interval: IntervalFilterValue.deserialize(serializedFilter, '_i18n.ReportsExcludeCallsDroppedBefore'),
                pbxQueue: PbxQueueFilterValue.deserialize(serializedFilter)
            };
        case PbxScheduledReportType.AgentLoginHistory:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                pbxQueue: PbxQueueFilterValue.deserialize(serializedFilter)
            };
        case PbxScheduledReportType.ExtensionsStatisticsByRingGroups:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
            };
        case PbxScheduledReportType.ExtensionStatistics:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                callArea: newCallAreaFilterValue().deserialize(serializedFilter),
                pbxGroup: PbxGroupFilterValue.deserialize(serializedFilter),
            };
        case PbxScheduledReportType.CallCostByExtensionDept:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                callClass: newCallClassFilterValue().deserialize(serializedFilter),
                pbxGroup: PbxGroupFilterValue.deserialize(serializedFilter)
            };
        case PbxScheduledReportType.QueueChatPerformance:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                participant: newParticipantTypeFilterValue().deserialize(serializedFilter),
            };
        case PbxScheduledReportType.QueueAgentsChat:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                participant: newParticipantTypeFilterValue().deserialize(serializedFilter),
            };
        case PbxScheduledReportType.AbandonedChats:
            return {
                dateRange: DateRangeFilterValue.deserialize(serializedFilter),
                participant: newParticipantTypeFilterValue().deserialize(serializedFilter),
                pbxQueue: PbxQueueFilterValue.deserialize(serializedFilter)
            };
        default:
            return {};
    }
}

export function saveScheduledReport(modalService: ModalService, reportType: PbxScheduledReportType, reportFilter: AppliedFilter, reportParams: string) {
    modalService.showComponent(ScheduleReportDialogComponent, { initialState: {
        reportType,
        reportFilter,
        reportParams
    } })
        .pipe(filterDialogOk)
        .subscribe(() => {
            modalService.information('_i18n.ReportScheduledMessage', { link: '/#/office/reports/my-reports' });
        });
}
