import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MyPhoneService } from '../../../myphone/myphone.service';
import { AppContact } from '../../../myphone/contact';
import { ForwardingProfileService } from '@webclient/shared/forwarding-profile.service';
import { combineLatest, EMPTY, Observable } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { DisplayedProfile } from '@webclient/shared/displayed-profile';
import { OverrideOfficeHoursDialogComponent } from '../override-office-hours-modal/override-office-hours-dialog.component';
import { ModalService } from '@webclient/modal/app-modal.service';
import { SetTemporaryProfileDialogComponent } from '../set-temporary-profile-dialog/set-temporary-profile-dialog.component';
import { RequestChangeStatus, RequestMonitor } from '@myphone';
import { noEmitAndShowMessageOnError } from '@webclient/rx-utils';
import { SilentModeService } from '@webclient/layout/silent-mode.service';
import { TokenInfoService } from '@webclient/auth/token-info.service';
import { GroupsApiService } from '@xapi';
import dayjs from 'dayjs';
import {
    SetQualityMonitorDialogComponent
} from '@webclient/people/people-list/set-quality-monitor-dialog/set-quality-monitor-dialog.component';
import { ModalResult } from '@webclient/modal/message-box';
import { localBridgeId } from '@webclient/myphone/app-contact-type';
import { SetProfileStatusDialogComponent } from '../set-profile-status-dialog/set-profile-status-dialog.component';

@Component({
    providers: [ForwardingProfileService],
    selector: 'wc-account-menu',
    template: `
        <div dropdown class="dropdown d-flex justify-content-center" placement="bottom right">
            <app-avatar [ext]="myContact" alignment="right" dropdownToggle></app-avatar>
            <ul *dropdownMenu role="menu" class="dropdown-menu dropdown-menu-right black-menu">
                <li role="menuitem">
                    <a class="dropdown-item d-flex flex-column mw-0" [routerLink]="'/settings'">
                        <div class="d-flex">
                            <app-my-name></app-my-name>
                        </div>
                        <span *ngIf="currentStatusPresentation$ | async as currentStatusPresentation"
                              class="text-truncate" [title]="currentStatusPresentation">
                            {{currentStatusPresentation}}
                        </span>
                    </a>
                </li>
                <li class="divider dropdown-divider"></li>
                <li *ngFor="let profile of (profileService.statuses$|async)" role="menuitem">
                    <div class="dropdown-item d-flex justify-content-between pe-0 py-0">
                        <span id="menu{{profileService.toId(profile.internalName)}}"
                              class="status-menu-item flex-grow-1 py-1" style="max-width: 250px"
                              (click)="profileService.selectStatus(profile)"
                              [title]="profileService.profilePresentation(profile)">
                            <span class="big status-indicator {{profile.internalName | appProfileCssClass}} presence"></span>
                            <span class="text-truncate px-2">{{profileService.profilePresentation(profile)}}</span>
                        </span>
                        <span id="menu{{profileService.toId(profile.internalName)}}SetStatus"
                              class="d-flex align-items-center w-auto p-2 menu-nested-action"
                              (click)="setProfileStatus(profile)">
                            <span class="svg-xs" app-pencil-solid-icon></span>
                        </span>
                    </div>
                </li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem">
                    <a (click)="setTemporaryProfile()" class="dropdown-item d-flex align-items-center gap-2">
                        <span class="svg-xxs" app-clock-light-icon></span>
                        <span [translate]="'_i18n.TimeBasedStatus'">Time Based Status</span>
                    </a>
                </li>
                <ng-container *ngIf="tokenInfoService.myToken$ | async; let access">
                    <li *ngIf="access.officeHours" role="menuitem">
                        <a (click)="setOverrideOfficeHours()"
                           class="dropdown-item d-flex align-items-center gap-2">
                            <span class="svg-xxs" app-calendar-alt-light-icon></span>
                            <span [translate]="'_i18n.OverrideOfficeHours'">Override Office Hours</span>
                        </a>
                    </li>
                </ng-container>
                <ng-container *ngIf="canMonitorMySelf">
                    <li role="menuitem">
                        <a (click)="handleCallQualityMonitoring()"
                           class="dropdown-item d-flex align-items-center gap-2">
                            <span *ngIf="validMonitoring" class="svg-xxs" app-signal-bars-slash-solid-icon></span>
                            <span *ngIf="!validMonitoring" class="svg-xxs" app-signal-bars-solid-icon></span>
                            <span>{{'_i18n.RequestCallReport' | translate}}</span>
                        </a>
                    </li>
                </ng-container>
                <li class="divider dropdown-divider"></li>
                <ng-container *ngIf="hasQ$ | async">
                    <li role="menuitem" [ngSwitch]="queueStatus$|async">
                        <a id="menuQueue" class="dropdown-item d-flex gap-2" (click)="setQueueStatus(false)" *ngSwitchCase="true">
                            <span>Q</span>
                            <span [translate]="'_i18n.LogoutFromQueue'">Logout</span>
                        </a>
                        <a id="menuQueue" class="dropdown-item d-flex gap-2" (click)="setQueueStatus(true)" *ngSwitchDefault>
                            <span>Q</span>
                            <span [translate]="'_i18n.LoginToQueue'">Login</span>
                        </a>
                    </li>
                </ng-container>
                <li role="menuitem" [ngSwitch]="silentMode$ | async">
                    <a *ngSwitchCase="true" id="menuDisableSilentMode"
                       data-qa="menu-disable-silent-mode"
                       class="dropdown-item d-flex align-items-center gap-2"
                       (click)="silentModeService.setSilentMode(false)">
                        <span class="svg-xs" app-bell-on-solid-solid-icon></span>
                        <span [translate]="'_i18n.RingerOn'">Ringer On</span>
                    </a>
                    <a *ngSwitchDefault id="menuEnableSilentMode"
                       data-qa="menu-enable-silent-mode"
                       class="dropdown-item d-flex align-items-center gap-2"
                       (click)="silentModeService.setSilentMode(true)">
                        <span class="svg-xs" app-bell-slash-solid-solid-icon></span>
                        <span [translate]="'_i18n.RingerOff'">Ringer Off</span>
                    </a>
                </li>
                <li role="menuitem">
                    <a id="settings" data-qa='settings' class="dropdown-item d-flex align-items-center gap-2" routerLink="/settings">
                        <span class="svg-xs" app-wrench-solid-icon></span>
                        <span [translate]="'_i18n.Settings'">Settings</span>
                    </a>
                </li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem">
                    <a id="menuLogout" class="dropdown-item d-flex align-items-center gap-2" (click)="logout()">
                        <span class="svg-xs" app-sign-out-alt-light-icon></span>
                        <span [translate]="'_i18n.Logout'">Logout</span>
                    </a>
                </li>
            </ul>
        </div>
    `,
    styles: [`
        .dropdown-menu {
            margin-top: 0;
            max-height: 450px;
        }
    `]
})
export class WcAccountMenuComponent {
    @Input()
    public disabled: boolean | null;

    @Output()
    public readonly uploadImage = new EventEmitter();

    @Input()
    myContact: AppContact | null;

    readonly hasQ$: Observable<boolean>;
    readonly queueStatus$: Observable<boolean>;
    readonly silentMode$: Observable<boolean>;
    readonly currentStatusPresentation$: Observable<string>;

    constructor(
        private myPhoneService: MyPhoneService,
        public profileService: ForwardingProfileService,
        private modalService: ModalService,
        public silentModeService: SilentModeService,
        public tokenInfoService: TokenInfoService,
        public groupsApiService: GroupsApiService,
    ) {
        this.hasQ$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => combineLatest([session.myInfo$, session.queues$])),
            map(([{ Number }, queues]) =>
                Object.values(queues).some(queue => queue.agents.some(agent => agent.agentNumber === Number))
            )
        );

        this.silentMode$ = silentModeService.silentMode$;

        this.queueStatus$ = this.myPhoneService.myPhoneSession
            .pipe(switchMap(session => session.myInfo$), map(info => info.QueueStatus));

        this.currentStatusPresentation$ = profileService.currentStatus$.pipe(
            map((currentStatus) => (currentStatus ? profileService.profilePresentation(currentStatus) : ''))
        );
    }

    logout() {
        this.myPhoneService.logout();
    }

    isValidMonitoring(monitoringExpiration?: Date) {
        return dayjs(monitoringExpiration).isAfter(dayjs());
    }

    get validMonitoring() {
        return this.isValidMonitoring(this.myContact?.monitorExpiration);
    }

    get canMonitorMySelf() {
        const contact = this.myContact;
        return !(!contact || contact.bridgeId !== localBridgeId);
    }

    handleCallQualityMonitoring() {
        const contact = this.myContact;
        if (contact) {
            if (this.isValidMonitoring(contact.monitorExpiration)) {
                this.disableCallQualityMonitoring$(contact).pipe(take(1)).subscribe();
            }
            else {
                this.setupCallQualityMonitoring$(contact).pipe(take(1)).subscribe();
            }
        }
    }

    disableCallQualityMonitoring$(contact: AppContact) {
        const extNumber = contact.extensionNumber;
        const request = new RequestMonitor({ DN: extNumber, Days: 0 });
        return this.modalService.confirmation('_i18n.ClearQMonitorMessage', '_i18n.ClearQMonitorHeader', { extNumber }).pipe(
            switchMap(() => this.myPhoneService.get(request)),
            noEmitAndShowMessageOnError(this.modalService)
        );
    }

    setupCallQualityMonitoring$(contact: AppContact) {
        const extNumber = contact.extensionNumber;
        const DN = { number: extNumber };

        return this.modalService.showComponent(SetQualityMonitorDialogComponent, { initialState: { DN } })
            .pipe(
                switchMap(output => {
                    if (output.decision === ModalResult.Ok && output.payload) {
                        const payload = output.payload;
                        const request = new RequestMonitor({ DN: payload.dnNumber, Days: payload.dayCount });
                        return this.myPhoneService.get(request);
                    }
                    return EMPTY;
                }),
                noEmitAndShowMessageOnError(this.modalService)
            );
    }

    setProfileStatus(currentProfile: DisplayedProfile) {
        this.modalService.showComponent(SetProfileStatusDialogComponent, { initialState: { currentProfile } });
    }

    setTemporaryProfile() {
        this.modalService.showComponent(SetTemporaryProfileDialogComponent);
    }

    setOverrideOfficeHours() {
        this.groupsApiService.listGroup({
            $select: ['Id', 'IsDefault', 'Number', 'Name', 'CurrentGroupHours', 'OverrideExpiresAt'],
            $filter: "not startsWith(Name, '___FAVORITES___')"
        }).pipe(
            map(({ value }) => value ?? []),
            switchMap((groups) =>
                this.modalService.showComponent(OverrideOfficeHoursDialogComponent, { initialState: { groups } })
            ),
            noEmitAndShowMessageOnError(this.modalService)
        ).subscribe();
    }

    setQueueStatus(status: boolean) {
        this.myPhoneService.get(new RequestChangeStatus({ QueueStatus: status }))
            .pipe(noEmitAndShowMessageOnError(this.modalService))
            .subscribe();
    }
}
