<div class='d-flex flex-column vh-100 vw-100'>
    <app-update-notification-bar ></app-update-notification-bar>
    <app-push-status-disclaimer></app-push-status-disclaimer>
    <div class='pos-rlt h-100 w-100'>
        <div class='pos-abt h-100 w-100'>
            <router-outlet></router-outlet>
            <snackbar [reconnectTime]="myPhoneService.reconnectTime"
                      (retryNow)="myPhoneService.retryNow$.next ()"
                      [isVisible]="myPhoneService.isReconnecting" ></snackbar>
        </div>
    </div>
</div>
