<div dropdown class="dropdown dropdown-select-phone" placement="bottom right" *ngIf="userSelectedPhoneDevice$|async; let device">
    <a dropdownToggle class="menu-title" [ngClass]="{'dropdown-toggle': (hasPhones$|async), 'disabled': !(hasPhones$|async)}" >
        <span *ngIf='hasPhones$|async; else disabled' class='menu-title-text' title='{{device?.UserAgent|stripUserAgent|async|nullToEmpty|translate}}'>
            {{'_i18n.CallUsing'|translate}} {{device?.UserAgent|stripUserAgent|async|nullToEmpty|translate}}
        </span>
        <ng-template #disabled>
            <span class='menu-title-text' title="{{'_i18n.DisabledByAdmin'|translate}}">
                {{'_i18n.DisabledByAdmin'|translate}}
            </span>
        </ng-template>
    </a>
    <ul *dropdownMenu role="menu" class="dropdown-menu dropdown-menu-middle">
        <ng-container *ngIf='hasPhones$|async' >
            <li class="divider dropdown-divider"></li>
            <li *ngFor="let registration of (phones$| async)" role="menuitem">
                <a (click)="selectedPhonesDeviceChange(registration)" class="d-flex align-items-center dropdown-item" >
                    <show-svg-images *ngIf="device?.Contact === registration.Contact" class="svg-xxs left"
                                     [svg]="selectPhoneIcon"></show-svg-images>
                    <show-svg-images *ngIf="device?.Contact !== registration.Contact" class="svg-xxs left"
                                     [svg]="unselectedPhoneIcon"></show-svg-images>
                    <span class="dropdown-item-text ps-2" style="vertical-align: middle;"
                          title={{registration.UserAgent|stripUserAgent|async|nullToEmpty|translate}}>
                        {{registration.UserAgent|stripUserAgent|async|nullToEmpty|translate}}
                    </span>
                </a>
            </li>
        </ng-container>
        <li class="divider dropdown-divider"></li>
        <a class="d-flex align-items-center dropdown-item" >
            <span class="dropdown-item-text ps-0" style="vertical-align: middle;"
                  (click)="goToMediaDeviceOptions()"
                  title="{{'_i18n.MediaDeviceOptions'|translate}} {{'_i18n.Settings'|translate}}" >
                {{'_i18n.MediaDeviceOptions'|translate}} {{'_i18n.Settings'|translate}}
            </span>
        </a>
    </ul>
</div>
