import * as Bowser from 'bowser';

const userAgent = navigator.userAgent;
const parser = Bowser.getParser(userAgent);

export const isSafari = parser.satisfies({ safari: '>0' }) || false;

export const isFirefox = parser.satisfies({ firefox: '>0' }) || false;

export const isChrome = parser.satisfies({ chrome: '>0' }) || false;

export const isEdge = parser.satisfies({ edge: '>0' }) || false;

export const browserOnOS = `${parser.getBrowserName()} on ${parser.getOSName()}`;

function OSNameMapper(osName: string): string {
    switch (osName) {
        case 'macOS':
            return 'Mac';
        default:
            return osName;
    }
}

export const OSName = OSNameMapper(parser.getOSName());

export const isMacOS = parser.getOSName() === 'macOS';

export const isWindows = parser.getOSName() === 'Windows';

export function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|PlayBook|Opera Mini/i.test(userAgent);
}
