import { CommonModule } from '@angular/common';
import {
    AfterContentInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { TranslateModule } from '@ngx-translate/core';
import { ModalButtons, ModalResult } from '@webclient/modal/message-box';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { extractErrorMessage } from '@webclient/convert-server-error';

@Component({
    selector: 'app-modal-dialog',
    template: `
        <div class="modal-header">
            <h4 class="modal-title" data-qa="modal-title">
                <span>{{header | translate}}</span>
                <ng-content select="[dialog-header]"></ng-content>
            </h4>
            <button *ngIf="!closeDisabled" type="button" class="btn-close" aria-label="Close" (click)="cancel()" data-qa="modal-cross"></button>
        </div>
        <div class="modal-body" #body>
            <ng-content></ng-content>
            <p *ngIf="error" class="text-error" style="max-height: 2.8em; overflow: hidden" data-qa="modal-error">
                {{error | translate}}
            </p>
        </div>

        <div class="modal-footer" *ngIf="buttons">
            <button type="button" *ngIf="isBackVisible" class="btn btn-border me-auto"
                    data-qa="modal-back" (click)="backClicked.emit()" [disabled]="handlingButtonClick">
                {{backText | translate}}
            </button>
            <button type="button" *ngIf="isNextVisible" class="btn btn-primary"
                    data-qa="modal-next" (click)="nextClicked.emit()" [disabled]="handlingButtonClick">
                {{nextText | translate}}
            </button>
            <button type="button" #okBtn *ngIf="isOkVisible" class="btn btn-primary"
                    data-qa="modal-ok" (click)="submit()" [disabled]="okDisabled || handlingButtonClick">
                {{okText | translate}}
            </button>
            <button type="button" #cancelBtn *ngIf="isCancelVisible" class="btn btn-border" data-qa="modal-cancel"
                    (click)="cancel()" [disabled]="handlingButtonClick">
                {{cancelText | translate}}
            </button>
            <button type="button" #customBtn *ngIf="isCustomVisible" class="btn {{customClass}}" data-qa="modal-custom"
                    (click)="custom()" [disabled]="customDisabled || handlingButtonClick">
                {{customText | translate}}
            </button>
            <ng-content select="[extraButton]"></ng-content>
        </div>
    `,
    standalone: true,
    imports: [CommonModule, TranslateModule, ModalModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalDialogComponent implements AfterContentInit {
    @Input() closeDisabled = false;
    /** Plain string header. For html header define child element with attribute "dialog-header" inside the dialog. */
    @Input() header = '';
    @Input() buttons: ModalButtons | null = ModalButtons.Ok;
    @Input() disableSubmitOnEnter = false;

    @Input() okDisabled = false;
    @Input() customDisabled = false;

    @Input() okText = '_i18n.OK';
    @Input() cancelText = '_i18n.Cancel';
    @Input() backText = '_i18n.Back';
    @Input() nextText = '_i18n.Next';
    @Input() autofocus: boolean;

    /** Remember to catch `this` context inside the function, handed to this input. */
    @Input() validateBeforeSubmit: () => boolean = () => true;

    /*
     * Submit async callback:
     * - handles validation errors by itself without emitting to prevent dialog close
     * - throws error to show it as red message at the bottom of dialog
     */
    @Input() submit$?: () => Observable<unknown>;

    /** Wait a little bit on save before checking form state and performing action (for heavy form with additional logic) */
    @Input() waitRecalculationsBeforeSave?: boolean;

    @Input() customText = '';
    @Input() customClass = 'btn-border';

    @Input() @HostBinding('class.scrollable') scrollable: boolean;
    @Input() @HostBinding('class.contains-inner-scrollable') containsInnerScrollable: boolean;
    @Input() @HostBinding('class.show-validation') showValidation: boolean;

    /** This event handler should be provided when dialog decision is checked after dialog closure */
    @Output() submitted = new EventEmitter<void>();

    @Output() submitResponse = new EventEmitter<unknown>();

    /** This event handler should be provided when dialog decision is checked after dialog closure */
    @Output() customClicked = new EventEmitter<void>();

    @Output() backClicked = new EventEmitter<void>();
    @Output() nextClicked = new EventEmitter<void>();

    @ViewChild('body', { static: true }) private dialogBody: ElementRef;

    handlingButtonClick = false;
    error: string;

    constructor(private bsModalRef: BsModalRef, private cd: ChangeDetectorRef) {}

    ngAfterContentInit() {
        if (this.autofocus) {
            setTimeout(() => {
                const element = this.dialogBody.nativeElement.querySelector('input:not(:disabled), textarea:not(:disabled), field-wrapper:not(.disabled) ng-select input');

                element?.focus();
            }, 100);
        }
    }

    @HostListener('document:keydown.enter')
    submitOnEnter() {
        if (!this.disableSubmitOnEnter) {
            this.submit();
        }
    }

    submit() {
        if (this.waitRecalculationsBeforeSave) {
            setTimeout(() => this.doSubmit());
        }
        else {
            this.doSubmit();
        }
    }

    private doSubmit() {
        this.handleButtonClick(ModalResult.Ok, this.submitted);
    }

    // host listener is added to override default bsModalRef closing by escape without dialog destruction
    @HostListener('document:keydown.esc')
    cancel() {
        if (this.closeDisabled) {
            return;
        }
        this.handleButtonClick(ModalResult.Cancel);
    }

    custom() {
        this.handleButtonClick(ModalResult.Custom, this.customClicked);
    }

    get isOkVisible() {
        return this.isButtonVisible(ModalButtons.Ok);
    }

    get isCancelVisible() {
        return this.isButtonVisible(ModalButtons.Cancel);
    }

    get isCustomVisible() {
        return this.customText && this.isButtonVisible(ModalButtons.Custom);
    }

    get isBackVisible() {
        return this.isButtonVisible(ModalButtons.Back);
    }

    get isNextVisible() {
        return this.isButtonVisible(ModalButtons.Next);
    }

    private isButtonVisible(button: ModalButtons): boolean {
        return typeof this.buttons === 'number' && (this.buttons & button) !== 0;
    }

    private handleButtonClick(result: ModalResult, specificCloseEvent?: EventEmitter<void>) {
        if (this.handlingButtonClick) {
            return;
        }
        if (result === ModalResult.Ok && !this.validateBeforeSubmit()) {
            this.showValidation = true;
            this.cd.markForCheck();
            return;
        }
        this.handlingButtonClick = true;
        this.error = '';
        this.cd.markForCheck();
        if (result === ModalResult.Ok && this.submit$) {
            this.submit$().pipe(take(1)).subscribe({
                next: (value) => {
                    if (value != null) {
                        this.submitResponse.emit(value);
                    }
                    this.closeDialog(specificCloseEvent);
                },
                error: (error: unknown) => {
                    // if error was not handled, we show it as red message on the bottom
                    this.error = extractErrorMessage(error);
                    this.handlingButtonClick = false;
                    this.cd.markForCheck();
                },
                complete: () => {
                    this.handlingButtonClick = false;
                    this.cd.markForCheck();
                }
            });
            return;
        }
        this.closeDialog(specificCloseEvent);
    }

    private closeDialog(specificCloseEvent?: EventEmitter<void>) {
        specificCloseEvent?.emit();

        // there is a bug in bsModalRef.hide - it destroys ModalDirective without waiting for its smooth hide
        // close with delay to prevent underlying button from taking focus and catching same enter event, which submitted the dialog
        // when bsModalRef fixes smooth closing, we can remove timeout from here (will I ever see that?)
        setTimeout(() => {
            this.bsModalRef.hide();
        }, 10);
    }
}
