import { Observable } from 'rxjs';
import { AppContact } from '@webclient/myphone/contact';

export enum ContactCreationStatus {
    NoRequest,
    Success,
    Failure,
    Retrying,
}

export enum ContactCreationType {
    Personal,
    Company,
    Crm,
    Microsoft365
}

export class ContactCreationResult {
    public status = ContactCreationStatus.NoRequest;
    public type = ContactCreationType.Personal;
    public errorMessage = '';
    public originalError : any;
    public createdContact? : AppContact;
    public failedRequest$ : Observable<ContactCreationResult>;
    constructor(init?: Partial<ContactCreationResult>) {
        Object.assign(this, init);
    }
}
