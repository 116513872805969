import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';
import { CropperSettings, ImageCropperComponent } from '../third-party/ng2-img-cropper';
import { DialogComponent } from '@webclient/modal/dialog';
import { ModalButtons } from '@webclient/modal/message-box';

export const allowedFiletypes = ['jpg', 'jpeg', 'png'];

@Component({
    templateUrl: './profile-image-dialog.component.html',
    styles: [`
    .cropArea {
        background: var(--bg-primary);
        overflow: hidden;
        width: 350px;
        height: 250px;
    }
    `]
})
export class ProfileImageDialogComponent extends DialogComponent<string> implements AfterViewInit {
    @Input() public myImage: HTMLImageElement;
    @Input() public header: string;

    readonly buttons = ModalButtons.OkCancel;

    @ViewChild('cropper', { static: false })
    cropper: ImageCropperComponent;

    readonly cropperSettings: CropperSettings = Object.assign(new CropperSettings(), {
        noFileInput: true,
        croppedHeight: 128,
        croppedWidth: 128,
        canvasHeight: 250,
        canvasWidth: 350,
        fileType: 'image/png'
    });

    readonly myCroppedImage: { image?: string } = {};

    protected getPayload(): string | undefined {
        return this.myCroppedImage.image;
    }

    ngAfterViewInit() {
        this.cropper.setImage(this.myImage);
    }
}
