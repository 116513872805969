import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { publishRef } from '@webclient/rx-share-utils';

@Injectable({
    providedIn: 'root'
})
export class SplashService {
    private readonly _splashScreen : HTMLElement|null;
    private readonly splashScreenVisibilitySubj = new BehaviorSubject<boolean>(true);
    public readonly splashScreenVisibility$ :Observable<boolean>;

    constructor() {
        this._splashScreen = document.getElementById('splash-screen');
        this.splashScreenVisibility$ = this.splashScreenVisibilitySubj.asObservable().pipe(publishRef());
    }

    public show() {
        this.splashScreenVisibilitySubj.next(true);
        if (this._splashScreen !== null && this._splashScreen.classList.contains('loading-finished')) {
            this._splashScreen.classList.remove('loading-finished');
        }
    }

    public remove() {
        this.splashScreenVisibilitySubj.next(false);
        if (this._splashScreen !== null && !this._splashScreen.classList.contains('loading-finished')) {
            this._splashScreen.classList.add('loading-finished');
        }
    }
}
