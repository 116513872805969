import { CallDb } from './active-calls/call-db';
import { QueueStatistics } from '@myphone';
import { Agent } from '@webclient/myphone/agent';

export type QueuesMap = {[id: string]: Queue};

export class Queue {
    public readonly callDb = new CallDb();
    public agents: Agent[] = [];
    public stat: QueueStatistics;

    get displayName() {
        return `${this.queueNumber} ${this.name}`;
    }

    constructor(public id: number, public queueNumber: string, public name: string) {
    }
}
