import type { FilterValue, SerializedFilter } from '@office/standalones/odata-search/types';
import type { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

export interface IntervalFilterParams {
    /** interval in seconds */
    interval: number | null
}

export const INTERVAL_MAX_VALUE = 86400;

export class IntervalFilterValue implements FilterValue<IntervalFilterParams> {
    interval: IntervalFilterParams['interval'] = null;

    readonly units = '_i18n.ReportsSeconds';

    constructor(public readonly label: string) {
    }

    getDisplayValue(translate: TranslateService): string {
        return IntervalFilterValue.getDisplayValue(translate, this.interval, this.units);
    }

    getDisplayValueForPrint(translate: TranslateService): string {
        return IntervalFilterValue.getDisplayValue(translate, this.interval ?? 0, this.units);
    }

    get value(): IntervalFilterParams {
        return { interval: this.interval };
    }

    set value(value: IntervalFilterParams) {
        this.interval = value.interval;
    }

    deserialize(serializedValue: SerializedFilter): FilterValue<IntervalFilterParams> {
        return IntervalFilterValue.deserialize(serializedValue, this.label, this.value);
    }

    serialize(): SerializedFilter {
        return this.interval == null ? {} : { interval: this.interval };
    }

    validate(): ValidationErrors | null {
        return (this.interval ?? 0) > INTERVAL_MAX_VALUE ? { interval: { max: true } } : null;
    }

    static deserialize(serializedValue: SerializedFilter, label: string, initialValue?: IntervalFilterParams): IntervalFilterValue {
        const value = new IntervalFilterValue(label);

        if (initialValue) {
            value.value = initialValue;
        }
        if ('interval' in serializedValue && typeof serializedValue.interval === 'number') {
            value.interval = serializedValue.interval;
        }
        return value;
    }

    private static getDisplayValue(translate: TranslateService, interval: number | null, units: string) {
        return interval != null ? `${interval} ${translate.instant(units).toLowerCase()}` : '';
    }
}
