import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef, HostBinding,
    Input,
    OnInit, Self,
    ViewChild
} from '@angular/core';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validator
} from '@angular/forms';
import './locales';
import dayjs from 'dayjs';
import { Focusable, FOCUSABLE } from '@webclient/standalones/directives/autofocus/focusable';
import { CommonModule } from '@angular/common';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FieldValueAccessor, FieldsModule, setControlEnabled } from '@webclient/fields';
import { DestroyService } from '@webclient/services/destroy.service';
import { auditTime, takeUntil } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';

export function hasMeridian() {
    return /a/i.test(dayjs.localeData().longDateFormat('LT'));
}

interface InnerValue { date: Date | null; time: Date | null }

@Component({
    selector: 'app-date-time-picker',
    template: `
        <field-wrapper [label]="label" [inlineLabel]="inlineLabel" [disabled]="disabled" [formGroup]="form"
                       [required]="required && !disabled" class="mb-0">
            <div class="d-flex flex-row flex-wrap align-items-center gap-2">
                <input
                    data-qa="date"
                    #input
                    type="text"
                    formControlName="date"
                    class="form-control flex-basis-100"
                    bsDatepicker
                    [bsConfig]="bsConfig"
                    placement="bottom"
                    [required]="required"/>
                <timepicker formControlName="time" [showMeridian]="useAmPmFormat" [required]="required"></timepicker>
            </div>
            <ng-container ngProjectAs="val-errors">
                <ng-content select="val-errors"></ng-content>
            </ng-container>
        </field-wrapper>
    `,
    styles: [`
        :host.ng-invalid.ng-touched .form-control.ng-valid:has(+ timepicker.ng-valid),
        :host.ng-invalid.ng-touched .form-control.ng-valid + timepicker.ng-valid ::ng-deep input {
            border-color: var(--error) !important;
        }
    `],
    providers: [
        DestroyService,
        { provide: NG_VALUE_ACCESSOR, useExisting: DatetimepickerComponent, multi: true },
        { provide: NG_VALIDATORS, useExisting: DatetimepickerComponent, multi: true },
        { provide: FOCUSABLE, useExisting: DatetimepickerComponent }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, TimepickerModule, BsDatepickerModule, FieldsModule]
})
export class DatetimepickerComponent extends FieldValueAccessor<Date | null, InnerValue> implements Focusable, Validator, OnInit {
    @Input() label?: string;
    @Input() inlineLabel?: boolean;
    @Input() minDateToday?: boolean;
    @Input() required = true;
    @Input() datePickerConfig?: Partial<BsDatepickerConfig>;

    @ViewChild('input', { static: true }) input: ElementRef;

    @HostBinding('class')
    readonly containerClass = 'form-input';

    readonly useAmPmFormat = hasMeridian();

    readonly form = new FormGroup({
        date: new FormControl<Date | null>(null),
        time: new FormControl<Date | null>(null)
    });

    bsConfig: Partial<BsDatepickerConfig>;

    constructor(cd: ChangeDetectorRef, @Self() private destroy$: DestroyService) {
        super(cd);
        this.touchOnChange = true;
    }

    ngOnInit() {
        const today = new Date();
        const minDate = this.minDateToday ? new Date(today.getFullYear(), today.getMonth(), today.getDate()) : undefined;

        this.bsConfig = { customTodayClass: 'today', showWeekNumbers: false, minDate, ...this.datePickerConfig };

        this.form.controls.date.valueChanges
            .pipe(
                filter(date => Boolean(date && minDate && date < minDate)),
                takeUntil(this.destroy$)
            )
            .subscribe(() => {
                // when user inputs data as text, and date is less than minDate
                // datepicker will set visible input to min date, but not control value
                // this code is intended to sync state - both input and validation
                this.form.patchValue({ date: minDate });
                this.cd.markForCheck();
            });

        this.form.valueChanges
            .pipe(
                auditTime(0),
                distinctUntilChanged(
                    (prev, next) =>
                        prev?.date?.toDateString() === next?.date?.toDateString() &&
                        prev?.time?.toTimeString() === next?.time?.toTimeString()
                ),
                takeUntil(this.destroy$)
            )
            .subscribe(value => {
                this.valueChanged(value as Required<typeof this.form.value>);
            });
    }

    focus() {
        this.input.nativeElement.focus();
    }

    setDisabledState(disabled: boolean) {
        super.setDisabledState(disabled);
        setControlEnabled(this.form, !disabled);
    }

    writeValue(value: Date|null) {
        const syncWithDatepicker = Boolean(value && this.bsConfig.minDate && value < this.bsConfig.minDate);

        super.writeValue(value);
        this.form.setValue(this.value, { emitEvent: syncWithDatepicker });
    }

    parse(value: Date | null): InnerValue {
        return value ? { date: new Date(value), time: new Date(value) } : { date: null, time: null };
    }

    format(value: InnerValue): Date | null {
        const { date, time } = value ?? {};

        if (!date || !time) {
            return null;
        }
        if (isNaN(date.getTime()) || isNaN(time.getTime())) {
            return new Date(NaN);
        }
        return new Date(date.getFullYear(), date.getMonth(), date.getDate(), time.getHours(), time.getMinutes());
    }

    validate({ value }: AbstractControl): ValidationErrors | null {
        if (!value) {
            return this.required ? { required: true } : null;
        }
        if (isNaN(value.getTime())) {
            return { invalidDate: true };
        }
        if (this.minDateToday && value <= new Date()) {
            return { dateInThePast: true };
        }
        return null;
    }

    markAsTouched(blur: boolean = false) {
        super.markAsTouched(blur);
        this.form.markAllAsTouched();
    }
}
