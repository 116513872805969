import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { MyCall } from '@webclient/phone/mycall';
import { LocalConnectionState, WebRTCEndpointSDPState } from '@myphone';
import { CallControlService } from '@webclient/call/call-control.service';
import { CallButtonsComponent, CallButtonsType } from './call-buttons.component';
import {
    DialerUtilityService,
    getCallStateName,
    InCallSearchAction,
    replaceSelectedInterval
} from './dialer-utility.service';
import { CallerInfoMode } from './caller-info.component';
import { CallSearchListComponent } from '@webclient/call/call-search-list.component';
import { SearchContext } from '@webclient/shared/search/search-context';
import { SearchResult } from '@webclient/shared/search/search-result';
import { stripSpecialCharaceters } from '@webclient/shared/utils.service';
import { String } from '@webclient/shared/string.utils';
import { DialerService } from './dialer.service';
import { merge, Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import {
    DialerVisibilityService
} from '../call-adapter/dialer-visibility.service';
import { DialerAction } from '@webclient/shared/dialer-action';
import { ActivatedRoute, Router } from '@angular/router';
import { DialerVisibilityInfo } from '@webclient/call-adapter/dialer-visibility-info';

@Component({
    selector: 'call-view',
    templateUrl: 'call-view.component.html',
    styleUrls: ['call-view.component.scss']
})
export class CallViewComponent implements OnInit, OnDestroy {
    @ViewChild('callContent', { static: false })
    callContent: ElementRef;

    @ViewChild('callButtons', { static: false })
    callButtons: CallButtonsComponent;

    @ViewChild('searchList', { static: false })
    public searchList: CallSearchListComponent;

    @ViewChild('searchInput', { static: false })
    searchInput: ElementRef;

    @Input()
    public myCall: MyCall;

    @Input()
    public hasSingleCall: boolean;

    @Output()
    public readonly inCallKeyPadVisible = new EventEmitter<boolean>();

    @Output()
    public readonly inCallSearchVisible = new EventEmitter<boolean>();

    public LocalConnectionState = LocalConnectionState;
    public CallerInfoMode = CallerInfoMode;
    public CallButtonsType = CallButtonsType;
    private isInCallKeyPadVisible = false;
    private inCallSearchAction = InCallSearchAction.None;
    public isVideoVisible = true;
    private dialpadkeys: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#'];

    searchNumber: string | undefined = '';
    private activeCallChangedSubscription: Subscription;
    private stealFocusSubscription: Subscription;
    private dialerActionSubscription: Subscription;
    private queryPhoneNumberSubscription: Subscription;

    constructor(
        public callsService: CallControlService,
        public dialerService: DialerService,
        public utilityService: DialerUtilityService,
        private callsAdapterService: DialerVisibilityService,
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.queryPhoneNumberSubscription = this.dialerService.queryPhoneNumber$.subscribe((number) => {
            // trigger new call search action and set the search number
            if (!this.utilityService.isDialing(this.myCall)) {
                this.isVideoVisible = false;
                if (!this.isInCallSearchVisible()) {
                    this.onInCallSearchActionChange(InCallSearchAction.NewCall, true);
                }

                setTimeout(() => {
                    this.searchNumberChanged(number);
                });
            }

            // remove the phone param after consumption
            const params = { ...this.route.snapshot.queryParams };
            delete params.phone;
            this.router.navigate([], { queryParams: params });
        });

        this.activeCallChangedSubscription = this.dialerService.activeCall$.pipe(
            filter(activeCall => !!activeCall),
            distinctUntilChanged((oldCall, newCall) => oldCall?.myCallId === newCall?.myCallId
            )).subscribe(() => {
            this.resetView();
        });

        this.stealFocusSubscription = merge(
            this.dialerService.newPrimaryCallSelected$,
            this.callsAdapterService.dialerVisibilityInfo.pipe(
                filter((visibility: DialerVisibilityInfo) => visibility.stealFocus)
            )
        ).subscribe(() => {
            this.focus();
        });

        this.dialerActionSubscription = this.dialerService.dialerActionEmitted$.subscribe((dialerAction) => {
            this.onDialerAction(dialerAction);
        });

        this.dialerService.setSearchAction(InCallSearchAction.None);
    }

    private onDialerAction(dialerAction: DialerAction) {
        if (this.myCall.state !== LocalConnectionState.Connected) {
            return;
        }

        switch (dialerAction) {
            case 'newCall':
            // do not show the video if it was visible on the active call
                this.isVideoVisible = false;
                this.onInCallSearchActionChange(InCallSearchAction.NewCall, true);
                break;
            case 'focus':
                this.focus();
                break;
        }
    }

    ngOnDestroy(): void {
        this.activeCallChangedSubscription.unsubscribe();
        this.stealFocusSubscription.unsubscribe();
        this.dialerActionSubscription.unsubscribe();
        this.dialerService.setSearchAction(InCallSearchAction.None);
        this.queryPhoneNumberSubscription.unsubscribe();
    }

    private resetView() {
        this.onInCallSearchActionChange(InCallSearchAction.None, false);
        this.onInCallKeyPadVisibilityChange(false, false);
        this.isVideoVisible = true;
    }

    @HostListener('keydown', ['$event'])
    onKeyDown(keyboardEvent: KeyboardEvent) {
        // Enter/Escape pressed
        if (keyboardEvent.key === 'Enter' || keyboardEvent.key === 'Escape') {
            if (this.myCall.isTrying) {
                return;
            }

            const isRinging: boolean = this.myCall.state === LocalConnectionState.Ringing;
            const isDialing: boolean =
                this.myCall.isWebRTCCall && this.myCall.state === LocalConnectionState.Dialing &&
                this.myCall.media.lastWebRTCState.sdpType !== WebRTCEndpointSDPState.WRTCAnswerProvided &&
                this.myCall.media.lastWebRTCState.sdpType !== WebRTCEndpointSDPState.WRTCConfirmed;

            if (isRinging) {
                if (keyboardEvent.key === 'Enter') {
                    this.callsService.answer(this.myCall, false);
                }
                else if (keyboardEvent.key === 'Escape') {
                    this.callsService.drop(this.myCall);
                }
            }
            else if (isDialing) {
                this.callsService.drop(this.myCall);
            }
            else if (this.isInCallSearchVisible()) {
                if (keyboardEvent.key === 'Enter' && !!this.searchNumber) {
                    this.searchList.requestContact(this.searchNumber);
                }
                else if (keyboardEvent.key === 'Escape') {
                    if (String.isNullOrEmpty(this.searchNumber)) {
                        this.onInCallSearchActionChange(InCallSearchAction.None);
                    }
                    else {
                        this.searchNumberChanged('');
                    }
                }
            }
            else {
                this.callsService.drop(this.myCall);
            }
        }
        // DialPad keys pressed - disabled for now
        else if (this.dialpadkeys.indexOf(keyboardEvent.key) !== -1) {
            if (this.isInCallKeyPadVisible && this.callButtons.canSendDtmf()) {
                this.callButtons.playDtmfSound(keyboardEvent.key);
                this.callsService.sendDtmf(this.myCall, keyboardEvent.key);
            }
            else if (this.isInCallSearchVisible()) {
                this.callButtons.playDtmfSound(keyboardEvent.key);
            }
        }
    }

    public focus() {
        setTimeout(() => {
            if (this.callContent && !this.isInCallSearchVisible()) {
                this.callContent.nativeElement.focus();
            }
            if (this.searchInput && this.isInCallSearchVisible()) {
                this.searchInput.nativeElement.focus();
            }
        }, 350);
    }

    isLoadingVisible(myCall: MyCall) {
        return myCall.media.isNegotiationInProgress || myCall.state !== LocalConnectionState.Connected;
    }

    onInCallKeyPadVisibilityChange(keyPadVisible: boolean, shouldFocus = true) {
        this.isInCallKeyPadVisible = keyPadVisible;
        this.inCallKeyPadVisible.emit(keyPadVisible);
        if (shouldFocus) {
            this.focus();
        }
    }

    isInCallSearchVisible():boolean {
        return this.inCallSearchAction !== InCallSearchAction.None;
    }

    onInCallSearchActionChange(searchAction: InCallSearchAction, shouldFocus = true) {
        this.inCallSearchAction = searchAction;
        this.inCallSearchVisible.emit(this.isInCallSearchVisible());
        if (shouldFocus) {
            this.focus();
        }
        this.searchNumberChanged('');
        this.dialerService.setSearchAction(searchAction);
    }

    OnVideoVisibilityChange(videoVisible: boolean) {
        this.isVideoVisible = videoVisible;
        this.focus();
    }

    getCallInfoMode(myCall: MyCall): CallerInfoMode {
        if (this.isInCallSearchVisible()) {
            return CallerInfoMode.NoInfo;
        }
        else if (this.isInCallKeyPadVisible) {
            return CallerInfoMode.InCallKeyPad;
        }
        else if (this.utilityService.isVideoActive(myCall) && !this.hasSingleCall) {
            return CallerInfoMode.OnlyDuration;
        }
        else if (!this.hasSingleCall && !this.isInCallKeyPadVisible) {
            return CallerInfoMode.OnlyDuration;
        }

        return CallerInfoMode.Normal;
    }

    getCallButtonsType(myCall: MyCall): CallButtonsType {
        if (myCall.state === LocalConnectionState.Ringing) {
            return CallButtonsType.IncomingCallActions;
        }
        else if (this.isInCallSearchVisible()) {
            return CallButtonsType.InCallSearchContactActions;
        }
        else if (this.isInCallKeyPadVisible) {
            return CallButtonsType.InCallKeyPadActions;
        }
        else if (this.isVideoVisible && this.utilityService.isVideoActive(myCall)) {
            return CallButtonsType.VideoCallActions;
        }
        else {
            return CallButtonsType.EstablishedCallActions;
        }
    }

    getStateName(myCall: MyCall) {
        return getCallStateName(myCall);
    }

    public onContactSelected(searchResult: SearchResult|undefined) {
        // perform the connecting call action based on the state
        if (searchResult instanceof SearchResult) {
            switch (this.inCallSearchAction) {
                case InCallSearchAction.Transfer: {
                    this.callsService.transfer(this.myCall, searchResult);
                    break;
                }
                case InCallSearchAction.AttTransfer: {
                    this.callsService.attTransfer(this.myCall, searchResult);
                    break;
                }
                case InCallSearchAction.NewCall: {
                    const phoneNumber = stripSpecialCharaceters(searchResult.number);
                    const videoCall = false;
                    if (!String.isNullOrEmpty(phoneNumber)) {
                        this.dialerService.makeCall({ phoneNumber, videoCall });
                    }
                    break;
                }
                case InCallSearchAction.Conference: {
                    this.callsService.conference(this.myCall, searchResult);
                    break;
                }
            }
        }
        this.onInCallSearchActionChange(InCallSearchAction.None);
    }

    searchNumberChanged(newNumber: string) {
        this.searchNumber = newNumber;
        if (this.searchList) {
            this.searchList.search(newNumber);
        }
    }

    private readonly defaultSearchContext = SearchContext.default();
    private readonly headerSearchContext = SearchContext.headerSearch();

    inCallSearchContext(): SearchContext {
        switch (this.inCallSearchAction) {
            case InCallSearchAction.NewCall:
            case InCallSearchAction.Conference:
                return this.headerSearchContext;
            default:
                return this.defaultSearchContext;
        }
    }

    onSearchKeyPressed(value: string) {
        const input = this.searchInput.nativeElement;
        if (typeof input.selectionStart === 'number') {
            const newNumber = replaceSelectedInterval(this.searchNumber, input.selectionStart, input.selectionEnd, value);
            this.searchNumberChanged(newNumber);
            this.setPhoneInputCaretPosition(input.selectionStart + 1);
        }
        else {
            this.searchNumberChanged((this.searchNumber || '') + value);
        }
        this.focus();
    }

    onSearchActionFired() {
        if (this.searchNumber) {
            this.searchList.requestContact(this.searchNumber);
        }
    }

    setPhoneInputCaretPosition(position:number) {
        const input = this.searchInput.nativeElement;
        setTimeout(() => {
            input.setSelectionRange(position, position);
        });
    }

    onCallActionFired() {
        this.focus();
    }
}
