import { AppState } from '../../app.service';
import { Component, OnInit } from '@angular/core';
import { MyPhoneService } from '../../myphone/myphone.service';
import { DeviceService } from '../../phone/device.service';
import { EMPTY, Observable } from 'rxjs';
import { filter, map, startWith, switchMap } from 'rxjs/operators';
import { LocalStorage } from 'ngx-webstorage';
import { LocalStorageKeys } from '../../settings/local-storage-keys';
import { myContact } from '@webclient/standalones/my-name';
import { AppContact } from '@webclient/myphone/contact';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SilentModeService } from '../silent-mode.service';

@Component({
    selector: 'header',
    templateUrl: 'header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @LocalStorage(LocalStorageKeys.AsideFolded, false)
    asideFolded: boolean;

    readonly isSessionExpired$: Observable<boolean>;
    dialerIsOpened = false;

    public selectedPhoneDevice$: Observable<string>;
    contact$: Observable<AppContact>;
    sectionTitle$: Observable<any>;
    public silentMode$: Observable<boolean>;

    constructor(
        public app: AppState,
        myPhoneService: MyPhoneService,
        deviceService: DeviceService,
        private silentModeService: SilentModeService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        this.contact$ = myPhoneService.myPhoneSession.pipe(myContact());
        this.isSessionExpired$ = myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.isActive$),
            map(isActive => !isActive));

        this.selectedPhoneDevice$ = deviceService.selectedPhoneDeviceAgent$;
        this.silentMode$ = silentModeService.silentMode$;
    }

    ngOnInit() {
        this.sectionTitle$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            switchMap(() => this.route.firstChild?.data ?? EMPTY),
            map(data => data.title),
            startWith(this.route.firstChild?.snapshot.data.title)
        );
    }

    public hasHiddenCalls() {
        return true;
    }

    public dialpad() {
        this.dialerIsOpened = !this.dialerIsOpened;
    }

    public closeDialer() {
        this.dialerIsOpened = false;
    }

    public toggleHeaderMenu() {
        this.app.headerMenuVisible = !this.app.headerMenuVisible;
    }

    public toggleAsideMenu() {
        this.app.asideMenuVisible = !this.app.asideMenuVisible;
    }

    disableSilentMode() {
        this.silentModeService.setSilentMode(false);
    }
}
