import { AppContact } from '../myphone/contact';
import { AppContactType } from '../myphone/app-contact-type';
import { Contact, ContactType } from '@myphone';
import { dataURItoArray } from '@webclient/profile-image/profile-image.utils';
import { ContactViewMode } from '@webclient/phonebook-editor/contact-view-mode';

export class ContactViewModel {
    id: string;
    type: AppContactType;
    firstName: string;
    lastName: string;
    company: string;
    mobile: string;
    mobile2: string;
    home: string;
    title: string;
    business: string;
    business2: string;
    emailAddress: string;
    other: string;
    businessFax: string;
    department: string;
    image: Uint8Array|undefined;
    imageSource: string;

    companyPhonebookCreationEnabled = false;
    crmCreationEnabled = false;
    microsoft365CreationEnabled = false;
    hasExchangeLicense = false;

    createPersonalContact = false;
    createCompanyContact = false;
    createCrmContact = false;
    createMicrosoft365Contact = false;

    get isNew(): boolean {
        return +this.id === -1;
    }

    get isCrmOrMicrosoft365CreationActivated() : boolean {
        return this.createCrmContact || this.createMicrosoft365Contact;
    }

    constructor(private contact: AppContact) {
        this.id = contact.id;
        this.type = contact.type;
        this.firstName = contact.firstName;
        this.lastName = contact.lastName;
        this.company = contact.company;
        this.mobile = contact.phones.mobile || '';
        this.mobile2 = contact.phones.mobile2 || '';
        this.home = contact.phones.home || '';
        this.title = contact.title || '';
        this.business = contact.phones.business || '';
        this.business2 = contact.phones.business2 || '';
        this.emailAddress = contact.emailAddress;
        this.other = contact.phones.other || '';
        this.businessFax = contact.phones.businessFax || '';
        this.department = contact.department || '';
        this.image = undefined;
        this.imageSource = contact.profilePicture;
    }

    onContactImageChange(imgData: string) {
        // on delete we will get empty string input
        this.imageSource = imgData;
        this.image = dataURItoArray(imgData);
    }

    getMyPhoneContact(mode: ContactViewMode = ContactViewMode.Normal): Contact {
        const dst = new Contact();

        dst.Id = +this.id;
        dst.FirstName = this.firstName;
        dst.LastName = this.lastName;
        if (this.type === AppContactType.Extension) {
            dst.ContactType = ContactType.LocalUser;
        }
        else if (this.type === AppContactType.CompanyPhonebook) {
            dst.ContactType = ContactType.CompanyPhonebook;
        }
        else if (this.type === AppContactType.PersonalPhonebook) {
            dst.ContactType = ContactType.PersonalPhonebook;
        }
        else {
            throw new Error('Unknown contact type');
        }

        if (this.mobile) {
            dst.Number = this.mobile;
        }

        if (this.emailAddress) {
            dst.AddressNumberOrData5 = this.emailAddress;
        }
        /*
        if (this.department) {
            dst.AddressNumberOrData8 = this.department;
        }
        if (this.title) {
            dst.AddressNumberOrData2 = this.title;
        }
*/

        dst.Company = this.company;

        if (mode !== ContactViewMode.Compact) {
            if (this.mobile2) {
                dst.AddressNumberOrData0 = this.mobile2;
            }

            if (this.home) {
                dst.AddressNumberOrData1 = this.home;
            }

            if (this.business) {
                dst.AddressNumberOrData3 = this.business;
            }

            if (this.business2) {
                dst.AddressNumberOrData4 = this.business2;
            }

            if (this.other) {
                dst.AddressNumberOrData6 = this.other;
            }

            if (this.businessFax) {
                dst.AddressNumberOrData7 = this.businessFax;
            }

            if (this.department) {
                dst.AddressNumberOrData8 = this.department;
            }
            if (this.title) {
                dst.AddressNumberOrData2 = this.title;
            }
        }

        return dst;
    }

    public hasPhoneNumber(phone: string): boolean {
        return (this.mobile === phone) ||
            (this.mobile2 === phone) ||
            (this.home === phone) ||
            (this.business === phone) ||
            (this.business2 === phone) ||
            (this.other === phone) ||
            (this.businessFax === phone);
    }
}
