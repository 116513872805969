import { List, Record } from 'immutable';
import { ChatParticipant } from '@webclient/chat/chat-participant';
import { ChatRecipientRef, ChatRecipientType } from '@myphone';

export class ChatParticipants extends Record({
    items: List<ChatParticipant>()
}) {
    public get isMySelfParticipant() {
        return Boolean(this.items.find(part => part.isMe && part.isAgent));
    }

    public get isMySelfSpectator() {
        const schizophrenia = this.items.filter(part => part.isMe);
        return (schizophrenia.size === 0 || schizophrenia.some(part => part.isWhisperer));
    }

    public get canAddParticipants() {
        return this.items.some(part => part.isMe && part.canAddUsers);
    }

    // the temporary solution to prevent add/delete actions if a chat has a bridge extension(s)
    public get hasBridge() {
        return this.items.find((participant) => participant.type === ChatRecipientType.CRT_3cxBridge);
    }

    public get activeParticipants(): List<ChatParticipant> {
        return this.items.filter(x => x.isAgent);
    }

    public get isGroupChat(): boolean {
        return this.activeParticipants.size > 2;
    }

    public get isOverallGroupChat() {
        return this.items.size > 2;
    }

    private get isLocalGroup() {
        return this.isOverallGroupChat && !this.externalParticipant;
    }

    public get externalParticipant() {
        return this.items.find(x => x.isAgent && x.isExternal);
    }

    public get providerParticipant() {
        return this.items.find(x => x.isAgent && x.isProvider);
    }

    public getParticipant(participantId: number): ChatParticipant|undefined {
        return this.items.find(participant =>
            participant.id === participantId
        );
    }

    public getMessageSender(participantRefs: List<ChatRecipientRef>): ChatParticipant|undefined {
        const senderRef = participantRefs.find(x => x.IsSender);
        return senderRef ? this.getParticipant(senderRef.IdRecipient) : undefined;
    }

    public isSentByMe(participantRefs: List<ChatRecipientRef>): boolean {
        return Boolean(this.getMessageSender(participantRefs)?.isMe);
    }
}
