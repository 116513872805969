import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorage } from 'ngx-webstorage';
import { defaultTeamLink, LocalStorageKeys } from '@webclient/settings/local-storage-keys';

@Component({
    template: '',
})
export class InitialRedirectComponent implements OnInit {
    @LocalStorage(LocalStorageKeys.SavePeople, defaultTeamLink)
    peopleLink: any;

    constructor(private router: Router) { }

    ngOnInit(): void {
        this.router.navigate((this.peopleLink instanceof Array) ? this.peopleLink : [this.peopleLink]);
    }
}
