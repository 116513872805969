import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { BASE_PATH, CustomPromptsApiService, MyGroupApiService, PbxCustomPrompt } from '@xapi';
import { TokenInfoService } from '@webclient/auth/token-info.service';
import { PromptService } from '@webclient/standalones/prompt-selector';
import { publishRef } from '@webclient/rx-share-utils';

@Injectable()
export class CallPromptsService implements PromptService {
    constructor(
        private http: HttpClient,
        private api: CustomPromptsApiService,
        @Inject(BASE_PATH) private basePath: string,
        private tokenInfo: TokenInfoService,
        private myGroupApi: MyGroupApiService
    ) {}

    private readonly refreshPrompts$ = new Subject<void>();

    readonly maxPrompts$: Observable<number> = this.tokenInfo.hasMyGroupRole$.pipe(
        switchMap((hasMyGroup) => (
            hasMyGroup
                ? this.myGroupApi.getMyGroupRestrictions().pipe(catchError(() => of(undefined)))
                : of(undefined)
        )),
        map(restr => restr?.MaxPrompts ?? -1),
        distinctUntilChanged(),
        publishRef()
    );

    readonly availablePrompts$: Observable<PbxCustomPrompt[]> = this.refreshPrompts$.pipe(
        startWith(undefined),
        switchMap(() => this.api.listCustomPrompt({}).pipe(catchError(() => of({ value: [] })))),
        map(response => response.value || []),
        distinctUntilChanged((prev, next) => !prev.length && !next.length),
        publishRef()
    );

    uploadPrompt$(file: File): Observable<void> {
        const formData: FormData = new FormData();

        formData.append('file', file, file.name);

        return this.http.post<void>(this.basePath + '/customPrompts', formData).pipe(
            tap(() => {
                this.refreshPrompts$.next();
            })
        );
    }

    deletePrompt$(promptKey: string): Observable<void> {
        return this.api.deleteCustomPrompt({ filename: promptKey }).pipe(
            tap(() => {
                this.refreshPrompts$.next();
            })
        );
    }

    refreshPrompts() {
        this.refreshPrompts$.next();
    }
}
