import { Record } from 'immutable';
import { AppContact } from '@webclient/myphone/contact';
import { SearchResult } from '@webclient/shared/search/search-result';

export type CrmResponseStatus = 'unavailable' | 'loading' | 'ok' | 'error';

export interface CrmContactsView<T> {
    status: CrmResponseStatus;
    contacts: T[];
    error: string;
}

export type CrmSearchPage = CrmContactsView<SearchResult>;

export class CrmContactsPage extends Record<CrmContactsView<AppContact>>({
    status: 'loading',
    contacts: [],
    error: ''
}) {
}
