import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    ViewChild,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';

export function getTimeCodeFromNum(duration: number) {
    if (isNaN(duration) || !isFinite(duration)) {
        return '';
    }
    const seconds = Math.round(duration) % 60;
    const foo = Math.round(duration) - seconds;
    const minutes = foo / 60;
    let secToString = '';
    if (seconds < 10) {
        secToString = '0' + seconds.toFixed()
            .toString();
        return minutes.toFixed() + ':' + secToString;
    }
    else {
        return minutes + ':' + seconds.toFixed();
    }
}

export enum AudioPlayerAction {
    reset = 'reset',
    upload = 'upload',
    download = 'download',
}

@Component({
    selector: 'app-audio-player',
    templateUrl: './audio-player.component.html',
    styleUrls: ['./audio-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [CommonModule, TranslateModule, IconsModule],
})

export class AudioPlayerComponent implements AfterViewInit, OnDestroy {
    readonly AudioPlayerAction = AudioPlayerAction;

    @Input() showResetUploadButtons: boolean;
    @Input() showDownloadButtons: boolean;
    @Input() src: string;

    @Output() action = new EventEmitter<AudioPlayerAction>();

    @Output() download = new EventEmitter<void>();

    playButton = true;

    private interval: any;
    private audio: HTMLAudioElement;
    public errorMessage: string | null = null;

    length: string;
    current = '0:00';

    @ViewChild('timeline') timeline: ElementRef;

    @ViewChild('progress') progress: ElementRef;

    constructor(private cd: ChangeDetectorRef) {
    }

    ngAfterViewInit(): void {
        this.audio = new Audio(this.src);
        this.audio.addEventListener(
            'loadeddata',
            () => {
                this.length = getTimeCodeFromNum(this.audio.duration);
                this.audio.volume = 1;
                this.cd.markForCheck();
            },
            false
        );

        this.audio.addEventListener(
            'ended',
            () => {
                this.playButton = true;
                this.cd.markForCheck();
            },
            false
        );

        this.interval = setInterval(() => {
            const currentTime = this.audio.currentTime;
            const totalDuration = this.audio.duration;
            const progressBar = this.progress?.nativeElement;

            if (progressBar) {
                progressBar.style.width = (currentTime / totalDuration) * 100 + '%';
                this.current = getTimeCodeFromNum(currentTime);
                this.cd.markForCheck();
            }
        }, 100);

        setTimeout(() => {
            this.onPlayPause();
        });
    }

    handleAction(action: AudioPlayerAction) {
        this.action.emit(action);
        if (action === AudioPlayerAction.download) {
            this.download.emit();
        }
    }

    onTimelineClick(event: MouseEvent) {
        const timelineWidth = window.getComputedStyle(this.timeline.nativeElement).width;
        this.audio.currentTime = (event.offsetX / parseInt(timelineWidth, 10)) * this.audio.duration;
        this.cd.markForCheck();
    }

    onPlayPause() {
        if (this.audio.paused) {
            this.playButton = false;
            this.audio.play()
                .catch(() => {
                    this.errorMessage = '_i18n.PlayerNotSupportFileFormat';
                    this.cd.markForCheck();
                });
        }
        else {
            this.playButton = true;
            this.audio.pause();
        }
        this.cd.markForCheck();
    }

    onRestart() {
        this.audio.currentTime = 0;
        this.cd.markForCheck();
    }

    ngOnDestroy(): void {
        this.audio.pause();
        clearInterval(this.interval);
    }
}
