import { LayoutComponent } from './layout/layout.component';
import { InitialRedirectComponent } from '@webclient/layout/initial-redirect/initial-redirect.component';
import { Route } from '@angular/router';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { inject } from '@angular/core';
import { adminSectionGuard } from '@office/guards';

export const LAYOUT_ROUTES: Route =
    {
        path: '',
        component: LayoutComponent,
        canActivate: [() => {
            // this code is executed before any other guard or resolver of any child route
            // so this is the main and only place to connect service
            inject(MyPhoneService).connect();
            return true;
        }],
        children: [
            {
                path: '', component: InitialRedirectComponent, pathMatch: 'full'
            },
            {
                path: 'people',
                data: {
                    helpUri: '_i18n.PeopleUri',
                    title: '_i18n.Team'
                },
                //                loadChildren: () => PeopleModule
                loadChildren: () => import('./people/people.module').then(m => m.PeopleModule)
            },

            {
                path: 'contacts',
                data: {
                    helpUri: '_i18n.ContactsUri',
                    title: '_i18n.Contacts'
                },
                //                loadChildren: () => PhonebookModule
                loadChildren: () => import('./phonebook/phonebook.module').then(m => m.PhonebookModule)
            },

            {
                path: 'chat',
                data: {
                    helpUri: '_i18n.ChatUri',
                    title: '_i18n.Chat'
                },
                loadChildren: () => import('./chat/conversation.module').then(m => m.ConversationModule)
            },
            {
                path: 'call_history',
                data: {
                    helpUri: '_i18n.CallHistoryUri',
                    title: '_i18n.AllowpushCalls'
                },
                //                loadChildren: () => CallHistoryModule
                loadChildren: () => import('./call-history/call-history.module').then(m => m.CallHistoryModule)
            },
            {
                path: 'voicemails',
                data: {
                    helpUri: '_i18n.VoicemailsUri',
                    title: '_i18n.VoiceMail'
                },
                loadChildren: () => import('./voicemails/voicemails.module').then(m => m.VoicemailsModule)
            },
            {
                path: 'conferences',
                data: {
                    helpUri: '_i18n.ConferenceUri',
                    title: '_i18n.Meet'
                },
                //                loadChildren: () => MeetingModule
                loadChildren: () => import('./meeting/meeting.module').then(m => m.MeetingModule)
            },
            {
                path: 'switchboard',
                data: {
                    helpUri: '_i18n.SwitchboardUrl',
                    title: '_i18n.Panel'
                },
                //                loadChildren: () => SwitchboardModule
                loadChildren: () => import('./switchboard/switchboard.module').then(m => m.SwitchboardModule)
            },
            {
                path: 'recordings',
                data: {
                    helpUri: '_i18n.RecordingsUri',
                    title: '_i18n.Recordings'
                },
                loadChildren: () => import('./recordings/recordings.module').then(m => m.RecordingsModule)
            },
            {
                path: 'settings',
                data: {
                    helpUri: '_i18n.SettingsUri',
                    title: '_i18n.Settings'
                },
                //                loadChildren: () => SettingsModule
                loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
            },
            {
                path: 'apps',
                data: {
                    helpUri: '_i18n.PeopleUri',
                    title: '_i18n.Apps'
                },
                loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule)
            },
            {
                path: 'office',
                canActivate: [adminSectionGuard],
                data: {
                    helpUri: '_i18n.AdminManualUri',
                    title: '_i18n.AdminSectionHeader'
                },
                loadChildren: () => import('@office/office.module').then(m => m.OfficeModule)
            },
        ]
    };
