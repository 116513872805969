import { Injectable } from '@angular/core';
import {
    combineLatest, defer,
    map, Observable, of, Subject
} from 'rxjs';
import {
    CrmIntegrationData,
    CrmIntegration,
    CrmUrlEncoding
} from '@webclient/settings/integrations/crm-integration-interface';
import { HttpClient } from '@angular/common/http';
import {
    catchError, distinctUntilChanged, shareReplay, take, withLatestFrom
} from 'rxjs/operators';
import { SettingsService } from '@webclient/settings.service';
import { AutoOpenUrl } from '@webclient/settings/general-settings';
import { sanitizePhoneNumber } from '@webclient/phone/phone-funcs';
import { AppContact } from '@webclient/myphone/contact';

@Injectable({
    providedIn: 'root'
})
export class IntegrationService {
    public readonly openUrl$: Observable<string>;
    private readonly openUrlSubject = new Subject<string>();
    public clientCrmList$: Observable<CrmIntegration[]>;
    public selectedClientCrm$: Observable<CrmIntegration | undefined>;
    public hasClientCrmIntegration$: Observable<boolean>;

    constructor(private http: HttpClient, private settingsService: SettingsService) {
        this.openUrl$ = this.openUrlSubject.asObservable();
        this.clientCrmList$ = defer(() => this.http.get<CrmIntegrationData>('./assets/crm.json'))
            .pipe(
                map((crmData) => crmData.data.sort((crm1, crm2) => crm1.name.localeCompare(crm2.name))),
                catchError((error: unknown) => {
                    console.error(error);
                    return of([]);
                }),
                shareReplay(1)
            );

        this.selectedClientCrm$ = combineLatest([
            settingsService.generalSettings$.pipe(map(s => s.crmName), distinctUntilChanged()),
            this.settingsService.generalSettings$.pipe(map(s => s.autoOpenUrl), distinctUntilChanged()),
            this.clientCrmList$
        ])
            .pipe(
                map(([selectedCrmName, autoOpenUrl, crmList]) => (autoOpenUrl === AutoOpenUrl.ClientCrmUrl ? crmList.find((crmItem) => crmItem.name === selectedCrmName) : undefined)),
                shareReplay(1)
            );

        this.hasClientCrmIntegration$ = this.selectedClientCrm$.pipe(
            map((selectedCrmIntegration) => !!selectedCrmIntegration),
            shareReplay(1)
        );
    }

    public canOpenContactClientSideCrmUrl(contact?: AppContact) {
        return !!contact && (contact.bestPhone.length > 0 || Boolean(contact.emailAddress));
    }

    public openUrl(url: string) {
        this.openUrlSubject.next(url);
    }

    public clientSideCrmUrl$(phone: string | undefined, email: string | undefined): Observable<string | undefined> {
        return this.selectedClientCrm$.pipe(
            withLatestFrom(this.settingsService.generalSettings$),
            map(([selectedClientCrm, settings]) => {
                if (selectedClientCrm) {
                    let crmUrl = phone ? selectedClientCrm.phone_lookup_url.replace('%phone%', encodeURIComponent(sanitizePhoneNumber(phone))) :
                        email ? selectedClientCrm.email_lookup_url.replace('%email%', encodeURIComponent(email)) : '';

                    selectedClientCrm.variables.forEach((crmVariable) => {
                        const variableId = crmVariable.variableId;
                        let variableValue = settings.crmVariables.find((savedVariable) => savedVariable.variableId === variableId)?.variableValue ?? '';
                        if (crmVariable.isEncode === CrmUrlEncoding.Encoded) {
                            variableValue = encodeURIComponent(variableValue);
                        }
                        crmUrl = crmUrl.replace(`%${variableId}%`, variableValue);
                    });
                    return crmUrl;
                }
                return undefined;
            })
        );
    }

    public openClientCrmUrl(phone: string | undefined, email: string | undefined) {
        this.clientSideCrmUrl$(phone, email).pipe(take(1)).subscribe((clientCrmUrl) => {
            if (clientCrmUrl) {
                this.openUrl(clientCrmUrl);
            }
        });
    }
}
