import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import downloadOnAppleStoreImageData from '../../img/getFromAppleStore.png';

@Component({
    template: `
        <div class="d-flex flex-row">
            <div>
                <p class="ps-2">1. <span [innerHTML]="'_i18n.InstallIos' | translate"></span></p>
                <div class="ps-2 mb-3 d-flex gap-2 justify-content-center">
                    <a href="{{'_i18n.AppleStoreClientLink' | translate}}" target="_blank">
                        <img [src]="downloadOnAppleStoreImageData" height="30" alt="AppleStore">
                    </a>
                </div>
                <p class="ps-2 mb-2">
                    2. {{'_i18n.OpenAndScanFrom3cxApp' | translate}}
                </p>
                <div class="d-flex flex-column align-items-center ">
                    <app-provision-qrcode [userId]="userId"></app-provision-qrcode>
                </div>
            </div>
            <!-- hidden on width < 576px -->
            <span class="customSVGIcons android-phone d-none d-sm-block ps-4" app-ios-client-custom-icon></span>
        </div>
    `,
    styleUrls: ['apps-dialog-content.component.scss'],
    selector: 'app-ios-dialog-content',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class IosDialogContentComponent {
    protected readonly downloadOnAppleStoreImageData = downloadOnAppleStoreImageData;

    /** for another user in admin view */
    @Input() userId?: number;
}
