import {
    ChangeDetectorRef,
    Component, HostListener, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { CallControlService } from '@webclient/call/call-control.service';
import { type ContainerState, ToastContainerComponent } from '@webclient/standalones/toast-container';
import { BehaviorSubject, Subscription } from 'rxjs';
import {
    filter, map, pairwise, skip, startWith, take, withLatestFrom
} from 'rxjs/operators';
import { PhoneService } from '@webclient/phone/phone.service';
import { DialerVisibilityService } from './dialer-visibility.service';
import { DialerUtilityService } from '@webclient/call/dialer-utility.service';
import { DialerService } from '@webclient/call/dialer.service';
import { ActivatedRoute } from '@angular/router';
import { DialerVisibilityInfo } from '@webclient/call-adapter/dialer-visibility-info';
import { LocalStorage } from 'ngx-webstorage';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { LocalConnectionState } from '@myphone';

@Component({
    selector: 'app-web-dialer',
    providers: [
        CallControlService
    ],
    template: `
        <toaster-container class="theme-dark" (toggleClick)="onToasterToggle($event)" [maxWidthInPixels]="380" [maximized]="showBlf" (animationDone)="onAnimationDone($event)" [state]="containerState$ | async" [showClose]="dialerService.doesNotHaveCalls$ | async">
            <button data-qa="dialer-show-blf-panel" toast-maximized-button *ngIf='!showBlf' class="btn border-0 shadow-none btn-transparent-dark" type="button" draggable="false" (click)="togglePanel($event)"
                    title="{{'_i18n.DialerShowBLFPanel'|translate}}">
                <span class="customSVGIcons svg-xs fillIconWithTertiary" app-arrow-from-left-regular-icon></span>
            </button>
            <button data-qa="dialer-hide-blf-panel" toast-maximized-button *ngIf='showBlf' class="btn border-0 shadow-none btn-transparent-dark" type="button" (click)="togglePanel($event)"
                    title="{{'_i18n.DialerHideBLFPanel'|translate}}">
                <span class="customSVGIcons svg-xs fillIconWithTertiary" app-arrow-from-right-regular-icon></span>
            </button>
            <div class="d-flex flex-row" toast-content>
                <div class="arrow-container">
                    <app-call-control class="application-wrapper"></app-call-control>
                </div>
                <div class="blf-container mt-2">
                    <call-blf class="blf-wrapper"></call-blf>
                </div>
            </div>
        </toaster-container>
    `,
    styleUrls: ['./web-dialer.component.scss']
})
export class WebDialerComponent implements OnInit, OnDestroy {
    @LocalStorage(LocalStorageKeys.ShowBlf)
    showBlf: boolean;

    @ViewChild(ToastContainerComponent, { static: false })
    toasterContainer!: ToastContainerComponent;

    private sub: Subscription[];

    public containerState$ = new BehaviorSubject<ContainerState>('hidden');

    constructor(
        private phoneService: PhoneService,
        public callsAdapterService: DialerVisibilityService,
        public utilityService: DialerUtilityService,
        public dialerService: DialerService,
        private route: ActivatedRoute,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
    }

    @HostListener('window:beforeunload')
    closeAllNotifications() {
        this.phoneService.myCalls$.pipe(take(1)).subscribe(calls => {
            calls.calls.forEach(call => {
                // all calls were stacking on device after f5/browser close.
                this.phoneService.terminateHeadsetCall(call.myCallId);
                if (call.notification) {
                    call.notification.close();
                }
            });
        });
    }

    public togglePanel(event: MouseEvent) {
        this.containerState$.pipe(take(1)).subscribe({
            next: (value) => {
                if (value !== 'hidden') {
                    if (!this.showBlf) {
                        this.showBlf = true;
                        this.containerState$.next('maximized');
                    }
                    else {
                        this.showBlf = false;
                        this.containerState$.next('open');
                    }
                }
            }
        });
    }

    get openDialerState(): ContainerState {
        if (this.showBlf) {
            return 'maximized';
        }
        else {
            return 'open';
        }
    }

    public onAnimationDone(event: ContainerState) {
        this.callsAdapterService.dialerRealState.next(event);
    }

    ngOnInit(): void {
        const queryPhone$ = this.route.queryParams.pipe(map(params => params.phone));

        this.sub = [
            queryPhone$.pipe(
                filter(phone => phone),
                withLatestFrom(this.containerState$)
            ).subscribe(([_, containerState]) => {
                if (containerState === 'hidden') {
                    this.containerState$.next(this.openDialerState);
                    this.callsAdapterService.dialerVisibilityInfo.next(new DialerVisibilityInfo(true, true));
                }
            }),
            this.callsAdapterService.dialerToggle.pipe(
                skip(1),
                withLatestFrom(this.phoneService.myCalls$, this.containerState$),
            ).subscribe(([show, calls, container]) => {
                show = show ?? this.callsAdapterService.dialerRealState.value === 'hidden';

                if (show) {
                    this.containerState$.next(this.openDialerState);
                    this.callsAdapterService.dialerVisibilityInfo.next(new DialerVisibilityInfo(true, true));
                }
                else {
                    this.containerState$.next('hidden');
                    this.callsAdapterService.dialerVisibilityInfo.next(new DialerVisibilityInfo(false));
                }

                this.changeDetectorRef.detectChanges();
            }),
            this.callsAdapterService.dialerVisibilityInfo.pipe(
                withLatestFrom(this.phoneService.myCalls$)
            ).subscribe(([toasterVisibility, calls]) => {
                if (!toasterVisibility.isVisible && calls.calls.isEmpty()) {
                    this.containerState$.next('hidden');
                }
            }),
            // do not hide the toaster when device changes and the myCalls is updated 0 Calls => 0 Calls
            this.phoneService.myCalls$.pipe(startWith(null), pairwise(), withLatestFrom(queryPhone$)).subscribe(
                ([myCallsPair, queryPhone]) => {
                    const oldMyCalls = myCallsPair[0];
                    const newMyCalls = myCallsPair[1];
                    if ((oldMyCalls === null && newMyCalls && newMyCalls.calls.isEmpty()) || (oldMyCalls && newMyCalls && oldMyCalls.calls.isEmpty() && newMyCalls.calls.isEmpty())) {
                        return;
                    }
                    if (newMyCalls) {
                        if (newMyCalls.calls.isEmpty() && !queryPhone) {
                            this.containerState$.next('hidden');
                            this.callsAdapterService.dialerVisibilityInfo.next(new DialerVisibilityInfo(false));
                        }
                        else if (newMyCalls.hasNewCalls) {
                            this.containerState$.next(this.openDialerState);

                            const dialingCall = newMyCalls.calls.find(call => this.utilityService.isDialing(call));
                            this.callsAdapterService.dialerVisibilityInfo.next(new DialerVisibilityInfo(true, !!dialingCall));
                        }
                        if (this.toasterContainer) {
                            const ringingCall = newMyCalls.calls.find(call => call.state === LocalConnectionState.Ringing);
                            this.toasterContainer.isActive = !!ringingCall;
                        }
                    }
                })
        ];
    }

    ngOnDestroy(): void {
        this.sub?.forEach(item => item.unsubscribe());
    }

    onToasterToggle(toggleState: boolean) {
        this.callsAdapterService.dialerVisibilityInfo.next(new DialerVisibilityInfo(toggleState, true));
    }
}
