import { Pipe, PipeTransform } from '@angular/core';
import { Profile, ProfileNames } from '@webclient/myphone/profile';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ForwardingProfileService } from '@webclient/shared/forwarding-profile.service';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'userProfile'
})
export class UserProfilePipe implements PipeTransform {
    constructor(private translate: TranslateService) {
    }

    transform(value: Profile, isBusy: boolean, isRegistered: boolean): Observable<string> {
        if (!value) {
            return of('');
        }
        if (isBusy) {
            return this.translate.stream('_i18n.InCall');
        }
        if (!isRegistered && value.internalName) {
            return this.translate.stream('_i18n.NotRegistered').pipe(map(trans => {
                return [trans, value.status].filter(x => x).join(', ');
            }));
        }
        if (value.status.trim()) {
            return of(value.status);
        }
        if (value.internalName === ProfileNames.Custom1 || value.internalName === ProfileNames.Custom2) {
            return of(value.name);
        }
        else {
            const translationKey = ForwardingProfileService.translationKeys[value.internalName];
            return translationKey ? this.translate.stream(translationKey) : of('');
        }
    }
}
