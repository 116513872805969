import { Pipe, PipeTransform } from '@angular/core';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { AppContact } from '@webclient/myphone/contact';
import { Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { regexForNonAlphaNum } from '@webclient/myphone/join/utils';
import { Agent } from '@webclient/myphone/agent';

function firstLetter(value: string | undefined): string {
    return (value?.[0] || '').replace(regexForNonAlphaNum, '');
}

@Pipe({ name: 'appAvatarLetters$', standalone: true })
export class AvatarLettersPipe implements PipeTransform {
    private readonly isLastFirst$ = this.myPhone.myPhoneSession.pipe(
        switchMap(session => session.systemParameters$),
        map(params => params.IsLastFirst),
        take(1)
    );

    constructor(private myPhone: MyPhoneService) {
    }

    transform(contact: AppContact | Agent | null | undefined, name?: string): Observable<string> {
        if (!contact || (<AppContact>contact).isDummy) {
            return of(firstLetter(name));
        }

        if (!contact.firstName || !contact.lastName) {
            return of(firstLetter(contact.firstName || contact.lastName || (<AppContact>contact).company || ''));
        }

        const letters = [firstLetter(contact.firstName), firstLetter(contact.lastName)];

        return this.isLastFirst$.pipe(map(isLastFirst => (isLastFirst ? letters.reverse() : letters).join('')));
    }
}
