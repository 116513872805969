import { Pipe, PipeTransform } from '@angular/core';

/*
 * Convert bytes into largest possible unit.
 * Takes an precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 |  fileSize}}
 *   formats to: 1 KB
*/
@Pipe({ name: 'fileSize', standalone: true })
export class FileSizePipe implements PipeTransform {
    private units = [
        'bytes',
        'kB',
        'MB',
        'GB',
        'TB',
        'FB'
    ];

    transform(bytes = 0, precision = 2, defaultWhenZero = '') : string {
        if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) {
            return '?';
        }
        if (bytes === 0) {
            return defaultWhenZero;
        }
        let unit = 0;
        while (bytes >= 1024) {
            bytes /= 1024;
            unit++;
        }
        return bytes.toFixed(+precision) + ' ' + this.units[unit];
    }
}
