export interface SharedWorkerConnect {
    basePath: string;
    loggerEnabled: boolean;
    username?: string;
}

export class SharedWorkerRequest {
    type: 'connect' | 'logout' | 'get-request' | 'disconnect' | 'cancel-request';
    payload?: any;
    id?: string;
    public constructor(init?: Partial<SharedWorkerRequest>) {
        Object.assign(this, init);
    }
}
