import { Pipe, PipeTransform } from '@angular/core';
import { AgentMobileClient, AgentPushService, AgentWebclient } from '../../../phone/device.service';
import { of } from 'rxjs';

function transformUserAgent(userAgent: string|undefined): string {
    if (!userAgent) {
        return '';
    }
    const pos = userAgent.indexOf('/');
    userAgent = (pos >= 0) ? userAgent.substring(0, pos) : userAgent;

    return userAgent
        .replace(new RegExp('^(3CXPhone for Windows)+.*$', 'igm'), '_i18n.3CXPhoneForWindows')
        .replace('3CXPhone ', '3CX Client ')
        .replace(AgentPushService, '_i18n.Enable3CXSmartphone')
        .replace(AgentMobileClient, '_i18n.Enable3CXSmartphone')
        .replace(AgentWebclient, '_i18n.Browser');
}

@Pipe({
    name: 'stripUserAgent', standalone: true
})
export class StripUserAgentPipe implements PipeTransform {
    transform(userAgent: string|undefined) {
        return of(transformUserAgent(userAgent));
    }
}
