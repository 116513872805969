/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PbxSRTPModeType = 'SRTPDisabled' | 'SRTPEnabled' | 'SRTPEnforced';

export const PbxSRTPModeType = {
    SrtpDisabled: 'SRTPDisabled' as PbxSRTPModeType,
    SrtpEnabled: 'SRTPEnabled' as PbxSRTPModeType,
    SrtpEnforced: 'SRTPEnforced' as PbxSRTPModeType
};

