import { LocalStorageService } from 'ngx-webstorage';
import { EMPTY, Observable, of, switchMap } from 'rxjs';
import { catchError, delay, map } from 'rxjs/operators';
import { ModalService } from '@webclient/modal/app-modal.service';
import { DialogOutput } from '@webclient/modal/dialog';
import { ModalResult } from '@webclient/modal/message-box';
import {
    LocalConnection,
    RequestConnectionAccessRights,
    ResponseConnectionAccessRights
} from '@myphone';
import { MyPhoneSession } from '@webclient/myphone/myphone-session';

export const SearchDebounceTime = 300;

export const debouncedSearch = () => (source: Observable<string>) => source.pipe(
    switchMap(value => (value ? of(value).pipe(delay(SearchDebounceTime)) : of(value)))
);

export function observe<T>(localStorageService: LocalStorageService, key: string, defaultValue?: T) {
    return new Observable<T>(subscriber => {
        subscriber.next(localStorageService.retrieve(key) ?? defaultValue);
        return localStorageService.observe(key).pipe(map(value => value ?? defaultValue)).subscribe(subscriber);
    });
}

export const noEmitAndConsoleWarnOnError = (tag: string) => <T>(source: Observable<T>) => source.pipe(
    catchError((err: unknown) => {
        console.warn(tag, err);
        return EMPTY;
    })
);

export const noEmitOnError = () => <T>(source: Observable<T>) => source.pipe(catchError(() => EMPTY));

export const noEmitAndShowMessageOnError = (modalService: ModalService) => <T>(source: Observable<T>) => source.pipe(
    catchError((error: unknown) => {
        modalService.error(error);
        return EMPTY;
    })
);

export const switchToDialogPayload = <T, Custom = never>(defaultPayload?: T) => (source: Observable<DialogOutput<T, Custom>>): Observable<T> => source.pipe(
    switchMap(dialog => (dialog.decision === ModalResult.Ok && (dialog.payload ?? defaultPayload) != null ? of(dialog.payload ?? defaultPayload as T) : EMPTY))
);

export const filterDialogOk = (source: Observable<DialogOutput>): Observable<void> => source.pipe(
    switchMap(({ decision }) => (decision === ModalResult.Ok ? of(undefined) : EMPTY))
);

export function addConnectionCapabilities<T extends LocalConnection>(source: Observable<[T | undefined, MyPhoneSession]>): Observable<T> {
    return source.pipe(switchMap(([conn, session]) => {
        if (conn) {
            // If connection owner is current user, we don't need to Request capabilities, they are as is
            if (conn?.OwnerDn === session.myInfo.Number) {
                return of(conn);
            }
            else {
                const request = new RequestConnectionAccessRights();
                request.LocalConnectionId = conn.Id;
                return session.get<ResponseConnectionAccessRights>(request).pipe(
                    map((res) => {
                        conn.CallCapabilitiesMask = res.ConnectionCapabilities;
                        return conn;
                    })
                );
            }
        }
        else {
            return EMPTY;
        }
    }));
}
