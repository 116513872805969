<ng-container *ngIf="blf" [ngSwitch]="blf.type">
    <ng-template #defaultBlf>
        <app-call-blf-indicator (click)='blfService.stealFocus()'></app-call-blf-indicator>
    </ng-template>

    <ng-container *ngSwitchCase="BlfType.None">
        <ng-container *ngTemplateOutlet="defaultBlf"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="BlfType.Line">
        <ng-container *ngTemplateOutlet="defaultBlf"></ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="BlfType.BLF">
        <app-call-blf-indicator
            *ngIf="contact$ | async as contact; else defaultBlf"
            [cssStatus]="contact.data?.statusCssClassName"
            [text]="contact.data | lforfl : blf.value | async"
            (click)="blfService.performBlfAction(blf, contact.data)">
        </app-call-blf-indicator>
    </ng-container>

    <ng-container *ngSwitchCase="BlfType.SpeedDial">
        <app-call-blf-indicator
            *ngIf="contact$ | async as contact; else defaultBlf"
            cssStatus="available"
            [text]="contact.data | lforfl : blf.value | async"
            (click)="blfService.performDialAction(blf)">
        </app-call-blf-indicator>
    </ng-container>

    <ng-container *ngSwitchCase="BlfType.CustomSpeedDial">
        <app-call-blf-indicator
            cssStatus="available"
            [text]="customDialContact | lforfl : blf.value | async"
            (click)="blfService.performDialAction(blf)">
        </app-call-blf-indicator>
    </ng-container>

    <ng-container *ngSwitchCase="BlfType.SharedParking">
        <app-call-blf-indicator
            *ngIf="parking$ | async as parking; else defaultBlf"
            [cssStatus]="parking.data ? parking.data.WaitingCalls?.Items?.length ? 'busy' : 'available' : 'off'"
            [text]="blf.value"
            (click)="blfService.performParkingAction(blf)">
        </app-call-blf-indicator>
    </ng-container>

    <ng-container *ngSwitchCase="BlfType.ProfileStatus">
        <app-call-blf-indicator
            *ngIf="profile$ | async as profile; else defaultBlf"
            [cssStatus]="profile.internalName | appProfileCssClass"
            [text]="profile.name"
            (click)="blfService.performProfileStatusAction(blf, profile)">
        </app-call-blf-indicator>
    </ng-container>

    <ng-container *ngSwitchCase="BlfType.QueueLogin">
        <app-call-blf-indicator
            [cssStatus]="blf.value === 'LOGGEDINQUEUE' ? 'on' : blf.value === 'LOGGEDOUTQUEUE' ? 'off' : ''"
            [text]="blf.value === 'LOGGEDINQUEUE' ? '_i18n.LoginToQueue' : blf.value === 'LOGGEDOUTQUEUE' ? '_i18n.LogoutFromQueue' : ''"
            (click)="blfService.performQueueLoginAction(blf)">
        </app-call-blf-indicator>
    </ng-container>

</ng-container>
