import { Inject, Injectable, Optional } from '@angular/core';
import { ContactSearcherService } from '@webclient/shared/service/contact-searcher.service';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, combineLatest, merge, Observable, of, ReplaySubject } from 'rxjs';
import { Contact, RequestSearchCrmContacts, ResponseLookup, SortContactsBy } from '@myphone';
import { sortMixedListBy } from '@webclient/shared/utils.service';
import { publishRef } from '@webclient/rx-share-utils';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { ContactSearcher } from '@webclient/shared/search/contact-searcher';
import { ModalService } from '@webclient/modal/app-modal.service';
import { catchError, debounceTime, distinctUntilChanged, map, scan, startWith, switchMap } from 'rxjs/operators';
import { SearchContext } from '@webclient/shared/search/search-context';
import { SearchDebounceTime } from '@webclient/rx-utils';
import { CrmSearchPage } from '@webclient/shared/service/crm-contacts.response';
import { SearchResult } from '@webclient/shared/search/search-result';

@Injectable()
export class SearchRuntimeContextService {
    public readonly search$ = new ReplaySubject<string>(1);
    public readonly userInput$ = new BehaviorSubject<string>('');
    private readonly userInputChanged$: Observable<string>;

    private readonly searcher: ContactSearcher = new ContactSearcher();
    private readonly sortingType$: Observable<SortContactsBy>;
    private get count() {
        return this.requestCount || 20;
    }

    constructor(private contactSearcher: ContactSearcherService,
                private localStorage: LocalStorageService,
                private myPhone: MyPhoneService,
                private modalService: ModalService,
                @Optional()@Inject('requestCount') private requestCount: number
    ) {
        this.sortingType$ = myPhone.myPhoneSession.pipe(
            sortMixedListBy(localStorage),
            publishRef()
        );
        this.userInputChanged$ = merge(
            this.userInput$,
            this.search$.pipe(debounceTime(SearchDebounceTime))
        ).pipe(
            distinctUntilChanged(),
        );
    }

    public getContacts(searchContext: SearchContext): Observable<SearchResult[]> {
        const bulkResponse$ = combineLatest([
            this.myPhone.myPhoneSession,
            this.userInputChanged$,
            this.sortingType$
        ])
            .pipe(
                switchMap(x => {
                    const [session, text, sorting] = x;

                    if (!text || text.length === 0) {
                        return of({
                            response: new ResponseLookup(),
                            input: text
                        });
                    }

                    return this.contactSearcher.requestContact(this.contactSearcher.requestFactory({
                        Input: text,
                        Offset: 0,
                        Count: this.count,
                        SortBy: sorting
                    }, searchContext))
                        .pipe(
                            catchError((error: unknown) => {
                                this.modalService.error(error);
                                return of(new ResponseLookup());
                            }),
                            switchMap(response => {
                                return merge(session.contactDeleted$, of(response)).pipe(
                                    scan((acc, action: Contact | ResponseLookup) => {
                                        if (action instanceof Contact) {
                                            if (acc.Entries.some(c => c.Id === action.Id)) {
                                                acc.TotalCount--;
                                                acc.Entries = acc.Entries.filter(c => c.Id !== action.Id);
                                            }
                                            return acc;
                                        }
                                        else if (action instanceof ResponseLookup) {
                                            return action;
                                        }
                                        return acc;
                                    }, new ResponseLookup())
                                );
                            }),
                            map(response => {
                                return {
                                    response,
                                    input: text
                                };
                            })
                        );
                }),
                publishRef()
            );

        return combineLatest([bulkResponse$, this.myPhone.myPhoneSession])
            .pipe(
                map(([bulk, session]) => {
                    const contacts = bulk.response.Entries.map(x => session.createMergedContact(x));
                    return this.searcher.search(contacts, bulk.input, searchContext.merge({
                        vMailCode: session.systemParameters.VmailDialCode
                    }));
                })
            );
    }

    public getCrmContacts(searchContext: SearchContext): Observable<CrmSearchPage> {
        const defaultStatus = {
            contacts: [],
            error: '',
            status: 'ok'
        } as CrmSearchPage;

        return this.userInputChanged$.pipe(
            switchMap(search => {
                if (search && searchContext.contextRequestHasCRM()) {
                    return this.contactSearcher.requestCrmContact(new RequestSearchCrmContacts({
                        InputString: search,
                        MaxContactCount: this.count
                    })).pipe(map((response) => {
                        return {
                            status: response.status,
                            error: response.error,
                            contacts: this.searcher.search(response.contacts, search, searchContext),
                        };
                    }), startWith(defaultStatus));
                }
                else {
                    return of(defaultStatus);
                }
            }),
            publishRef()
        );
    }
}
