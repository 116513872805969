import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Injector,
    Input,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { getComponentControl, getUniqueFieldId } from '../fields.utils';
// noinspection ES6PreferShortImport
import { FieldValueAccessor } from '../field-value-accessor';
import { Focusable, FOCUSABLE } from '@webclient/standalones/directives/autofocus/focusable';
import { NgSelectComponent } from '@ng-select/ng-select';
import { CheckOption } from '@webclient/fields/types';
import { TranslateService } from '@ngx-translate/core';

type SelectOption<T> = Omit<CheckOption<T>, 'description'>;

/**
 *  Selector supports only a list of CheckOption,
 *  anything else should be done through combination of field-wrapper and ng-select.
 */
@Component({
    selector: 'field-select',
    template: `
        <field-wrapper
            [label]="label"
            [labelFor]="labelToInputLinkId"
            [disabled]="disabled"
            [required]="isRequired"
            [description]="description"
            [inlineLabel]="inlineLabel"
            [inlineLabelAuto]="inlineLabelAuto"
            [hint]="hint">
            <div class="input-group">
            <ng-select
                #ngSelect
                data-qa="select"
                [readonly]="readonly"
                [disabled]="disabled"
                [ngModel]="value"
                [required]="isRequired"
                (ngModelChange)="valueChanged($event)"
                [clearable]="clearable"
                [searchable]="searchable"
                class="flex-grow-1"
                [searchFn]="search"
                [placeholder]="indeterminate ? (indeterminatePlaceholder | translate) : ''"
                (blur)="onBlur($event)">
                <ng-option *ngFor="let opt of (options ?? [])" [value]="opt.value" [disabled]="opt.disabled" [attr.data-qa]="opt.dataQa">
                    {{opt.label | translate}}
                </ng-option>
            </ng-select>
            </div>
            <ng-container ngProjectAs="val-errors"><ng-content select="val-errors"></ng-content></ng-container>
            <ng-container ngProjectAs="[description]"><ng-content select="[description]"></ng-content></ng-container>
        </field-wrapper>
    `,
    styleUrls: ['./field-select.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: FieldSelectComponent },
        { provide: FOCUSABLE, useExisting: FieldSelectComponent }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FieldSelectComponent<TValue extends string | number | boolean> extends FieldValueAccessor<TValue> implements OnInit, Focusable {
    readonly labelToInputLinkId: string = getUniqueFieldId('text-input');

    @Input() label = '';
    @Input() description?: string;
    @Input() hint = '';
    @Input() placeholder = '';
    @Input() inlineLabel?: boolean;
    @Input() inlineLabelAuto?: boolean;

    @Input() autocomplete = '';

    @Input() readonly?: boolean;
    @Input() hideAsterisk?: boolean;

    @Input() clearable = false;
    @Input() searchable = false;

    /** Custom case when field is required, but can not know it from its validators */
    @Input() required = false;

    @Input() options: SelectOption<TValue>[] | null;

    /** Show null value as indeterminate input state. Changed should not be counted, as it is intentional empty value. */
    @Input() nullIsIndeterminate = false;
    @Input() indeterminatePlaceholder = '_i18n.LeaveUnchanged';

    @ViewChild('ngSelect') ngSelectComponent: NgSelectComponent;

    get isRequired() {
        if (this.readonly || this.disabled || this.hideAsterisk) {
            return false;
        }
        return this.required || this.control?.hasValidator(Validators.required) === true;
    }

    get indeterminate() {
        return this.nullIsIndeterminate && this.value == null && !this.changed;
    }

    protected control?: FormControl;

    focus() {
        this.ngSelectComponent.focus();
    }

    constructor(cdr: ChangeDetectorRef, private injector: Injector, private translate: TranslateService) {
        super(cdr);
    }

    ngOnInit(): void {
        this.control = getComponentControl(this.injector);
    }

    search = (searchText: string, value: TValue) => {
        const item = this.options?.find(opt => opt.value === value);
        const label = item?.label && this.translate.instant(item.label);

        return label?.toLowerCase().includes(searchText.trim().toLowerCase());
    };
}
