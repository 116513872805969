import {
    ChangeDetectorRef,
    Component,
    HostBinding,
    Input,
    OnChanges,
    OnDestroy,
    SimpleChanges
} from '@angular/core';
import { type AppContact } from '../../myphone/contact';
import { LocalStorage } from 'ngx-webstorage';
import { LocalStorageKeys } from '../../settings/local-storage-keys';
import { Subscription } from 'rxjs';
import { ContactAddedByEnum } from '@myphone';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { AppContactImagePipe } from '@webclient/standalones/pipes';
import { AvatarLettersPipe } from '@webclient/standalones/avatar/avatar-letters/avatar-letters.pipe';

@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    standalone: true,
    imports: [CommonModule, IconsModule, AppContactImagePipe, AvatarLettersPipe]
})
export class AvatarComponent implements OnChanges, OnDestroy {
    @Input()
    public alignment: 'left' | 'right' = 'left';

    @Input() size: 'm' | 's' = 'm';

    @HostBinding('class')
    public get classAttribute(): string {
        return ['avatar', this.alignment === 'left' ? 'me-1' : 'ms-1', this.size === 's' ? 'small' : ''].join(' ');
    }

    @Input()
    public ext?: AppContact | null | undefined;

    @LocalStorage(LocalStorageKeys.ActiveHost)
    public activeHost: string;

    ContactAddedByEnum = ContactAddedByEnum;
    private cdSub?: Subscription;

    constructor(private cd: ChangeDetectorRef) {
    }

    profilePictureLoadingFailed() {
        // Reset profile picture cause it's invalid
        if (this.ext) {
            this.ext.profilePicture = '';
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.ext?.currentValue) {
            this.cdSub?.unsubscribe();
            this.cdSub = this.ext?.identity.subscribe(() => {
                this.cd.markForCheck();
            });
        }
    }

    ngOnDestroy(): void {
        this.cdSub?.unsubscribe();
    }
}
