import { Injectable } from '@angular/core';
import { Blf, BlfType } from '@webclient/standalones/blf-config-editor';
import { DisplayedProfile } from '@webclient/shared/displayed-profile';
import { distinctUntilChanged, map, startWith, switchMap, take } from 'rxjs/operators';
import { ForwardingProfileService } from '@webclient/shared/forwarding-profile.service';
import { stripSpecialCharaceters } from '@webclient/shared/utils.service';
import { String } from '@webclient/shared/string.utils';
import { DialerService } from '@webclient/call/dialer.service';
import {
    LocalConnectionState,
    ParkingInfo,
    RequestChangeStatus,
    RequestDivertCall
} from '@myphone';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { ActiveCallsService } from '@webclient/switchboard/active-calls/active-calls.service';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { ModalService } from '@webclient/modal/app-modal.service';
import { MyCall } from '@webclient/phone/mycall';
import { DialerVisibilityService } from '@webclient/call-adapter/dialer-visibility.service';
import { InCallSearchAction } from '@webclient/call/dialer-utility.service';
import { CallControlService } from '@webclient/call/call-control.service';
import { SearchResult } from '@webclient/shared/search/search-result';
import { DialerVisibilityInfo } from '@webclient/call-adapter/dialer-visibility-info';
import { AppContact } from '@webclient/myphone/contact';
import { localBridgeId } from '@webclient/myphone/app-contact-type';
import { publishRef } from '@webclient/rx-share-utils';
import { addConnectionCapabilities, noEmitAndShowMessageOnError } from '@webclient/rx-utils';

@Injectable()
export class BlfService {
    public parkings$: Observable<ParkingInfo[]>;
    public isParkingAvailable$: Observable<boolean>;
    private readonly pageOffSet = new Subject<number>();
    public pageOffSet$: Observable<number>;

    constructor(
        private myPhoneService: MyPhoneService,
        private activeCallsService: ActiveCallsService,
        private fwdProfilesService: ForwardingProfileService,
        private dialerService: DialerService,
        public callsService: CallControlService,
        private dialerVisibilityService:DialerVisibilityService,
        private modalService: ModalService
    ) {
        this.pageOffSet$ = this.pageOffSet.pipe(
            startWith(0),
            distinctUntilChanged(),
            publishRef()
        );

        this.parkings$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.parkings$),
            map(parking => parking.Items.filter(x => x.Name.startsWith('SP'))),
            publishRef()
        );

        this.isParkingAvailable$ = this.parkings$.pipe(map(x => x.length > 0), publishRef());
    }

    trackProfile$(blf: Blf | undefined): Observable<DisplayedProfile | undefined> {
        if (!blf || blf.type !== BlfType.ProfileStatus || !blf.value) {
            return of(undefined);
        }
        return this.fwdProfilesService.statuses$
            .pipe(map(statuses => statuses.find(st => st.internalName === blf.value)));
    }

    trackContact$(blf: Blf | undefined): Observable<AppContact | undefined> {
        const phoneNumber = blf?.peer?.Number;

        if (!phoneNumber) {
            return of(undefined);
        }
        return this.myPhoneService.myPhoneSession
            .pipe(
                map(session => session.contactMatcher.localContactDb.findPhone(phoneNumber, localBridgeId, true)),
                switchMap(contact => contact?.identity ?? of(undefined))
            );
    }

    trackParking$(blf: Blf | undefined): Observable<ParkingInfo | undefined> {
        if (!blf || blf.type !== BlfType.SharedParking || !blf.value) {
            return of(undefined);
        }
        return this.parkings$
            .pipe(map(parkings => parkings.find(parking => parking.Number.indexOf(blf.value!) === 0)));
    }

    trackEnabled$(blf: Blf|undefined) {
        return blf?.type === BlfType.SharedParking
            ? this.isParkingAvailable$
            : of(Boolean(blf?.type != null && blf.type !== BlfType.None && blf.type !== BlfType.Line));
    }

    setPageOffSet(offset: number) {
        this.pageOffSet.next(offset);
    }

    stealFocus() {
        this.dialerVisibilityService.dialerVisibilityInfo.next(new DialerVisibilityInfo(true, true));
    }

    performQueueLoginAction(blf: Blf) {
        if (blf.type === BlfType.QueueLogin) {
            if (blf.value === 'LOGGEDINQUEUE') {
                this.setQueueStatus(true);
            }
            else if (blf.value === 'LOGGEDOUTQUEUE') {
                this.setQueueStatus(false);
            }
        }
        this.stealFocus();
    }

    performProfileStatusAction(blf: Blf, profileStatus: DisplayedProfile | undefined) {
        if (blf.type === BlfType.ProfileStatus && profileStatus) {
            this.fwdProfilesService.selectStatus(profileStatus);
        }
        this.stealFocus();
    }

    performBlfAction(blf: Blf, ext: AppContact | undefined) {
        if (blf.type === BlfType.BLF) {
            if (ext?.isRinging) {
                const connection = ext.displayedConnection;
                this.myPhoneService.myPhoneSession.pipe(
                    switchMap((session) => {
                        if (connection?.State === LocalConnectionState.Ringing) {
                            return combineLatest([of(connection), of(session)]);
                        }
                        else {
                            const queues = Object.values(session.queues);
                            const connMap$ = queues.length > 0 ?
                                combineLatest(queues.map(queue => queue.callDb.activeCalls$)) : of([]);

                            return connMap$.pipe(
                                switchMap(connectionMaps => {
                                    const ringingConn = connectionMaps.map(m => Object.values(m)).flat().find(conn => conn.OwnerDn === ext.nativeExtensionNumber &&
                                        conn.State === LocalConnectionState.Ringing);
                                    return combineLatest([of(ringingConn), of(session)]);
                                })
                            );
                        }
                    }),
                    addConnectionCapabilities,
                    take(1)
                ).subscribe(conn => {
                    this.activeCallsService.pickup(conn);
                });
            }
            else {
                this.callOrActiveCallAction(blf);
            }
        }
        this.stealFocus();
    }

    performParkingAction(blf: Blf) {
        if (blf.type === BlfType.SharedParking) {
            this.isParkingAvailable$.pipe(take(1))
                .subscribe((hasAccess) => {
                    if (hasAccess) {
                        this.callOrActiveCallAction(blf);
                    }
                });
        }
        this.stealFocus();
    }

    performDialAction(blf: Blf) {
        this.callOrActiveCallAction(blf);
        this.stealFocus();
    }

    private callOrActiveCallAction(blf: Blf) {
        this.dialerService.activeCall$.pipe(take(1))
            .subscribe((activeCall: MyCall | null) => {
                if (activeCall) {
                    this.activeCallAction(activeCall, blf);
                }
                // call
                else {
                    this.makeCall(blf.value);
                }
            });
    }

    // action on active call based on call status and search action
    // --> Divert, Transfer, Att.Transfer, NewCall, Conference
    private activeCallAction(activeCall: MyCall, blf: Blf) {
        console.log(activeCall, blf);
        if (activeCall.state === LocalConnectionState.Ringing) {
            this.divert(activeCall, blf);
        }
        else {
            this.dialerService.callSearchAction$.pipe(take(1)).subscribe((searchAction) => {
                const searchResultConstructed = new SearchResult({ number: blf.value! });
                switch (searchAction) {
                    case InCallSearchAction.Transfer: {
                        this.callsService.transfer(activeCall, searchResultConstructed);
                        break;
                    }
                    case InCallSearchAction.AttTransfer: {
                        this.callsService.attTransfer(activeCall, searchResultConstructed);
                        break;
                    }
                    case InCallSearchAction.NewCall: {
                        this.makeCall(blf.value);
                        break;
                    }
                    case InCallSearchAction.Conference: {
                        this.callsService.conference(activeCall, searchResultConstructed);
                        break;
                    }
                    default:
                        this.callsService.transfer(activeCall, searchResultConstructed);
                        break;
                }
            });
        }
    }

    private divert(activeCall:MyCall, blf:Blf) {
        if (!activeCall?.isDivertToVmAllowed) {
            return;
        }
        this.myPhoneService.myPhoneSession.pipe(take(1), switchMap(session => {
            const request = new RequestDivertCall();
            request.IsLocal = false;
            request.LocalConnectionId = activeCall.localConnectionId;
            request.Destination = blf.value ?? '';
            return session.get(request);
        })).subscribe({
            error: (error: unknown) => {
                this.modalService.error(error);
            }
        });
    }

    private makeCall(number: string | null) {
        const phoneNumber = stripSpecialCharaceters(number || '');
        const videoCall = false;
        if (!String.isNullOrEmpty(phoneNumber)) {
            this.dialerService.makeCall({
                phoneNumber,
                videoCall
            });
        }
    }

    private setQueueStatus(status: boolean) {
        this.myPhoneService.get(new RequestChangeStatus({ QueueStatus: status }))
            .pipe(noEmitAndShowMessageOnError(this.modalService))
            .subscribe();
    }
}
