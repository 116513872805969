import {
    Component, ElementRef, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { DeviceService } from '@webclient/phone/device.service';
import { PhoneService } from '@webclient/phone/phone.service';
import { String } from '@webclient/shared/string.utils';
import { stripSpecialCharaceters } from '@webclient/shared/utils.service';
import { Subscription } from 'rxjs';
import { DialerService } from './dialer.service';
import { replaceSelectedInterval } from './dialer-utility.service';
import { CallDialpadComponent } from './call-dialpad.component';
import { SearchContext } from '@webclient/shared/search/search-context';
import { SearchResult } from '@webclient/shared/search/search-result';
import {
    delay, withLatestFrom
} from 'rxjs/operators';
import { CallSearchListComponent } from '@webclient/call/call-search-list.component';
import {
    DialerVisibilityService
} from '../call-adapter/dialer-visibility.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DialerAction } from '@webclient/shared/dialer-action';
import { DialerVisibilityInfo } from '@webclient/call-adapter/dialer-visibility-info';

@Component({
    selector: 'dialer-view',
    template: `
        <div class="dialer-wrapper" (keydown)="onKeyDown($event)" (keyup)="onKeyUp($event)" (focus)="focus()"
             tabindex="0">
            <div class="dialer-input-area">
                <div class="dialer-input">
                    <input #phoneNumberInput autofocus type="text" autocomplete="off" spellcheck="false" id="dialpad-input"
                           placeholder="{{'_i18n.EnterNameOrNumber' | translate}}" [ngModel]='phoneNumber'
                           (ngModelChange)="numberChanged($event)" tabindex="1">
                    <span *ngIf="!phoneNumber" class="customSVGIcons svg-xs fillIconWithTertiary" app-search-regular-icon></span>
                </div>
                <dialer-select-phone *ngIf="!phoneNumber"></dialer-select-phone>
            </div>
            <call-search-list #searchList class="searchList" [hidden]="!phoneNumber" [searchContext]="searchContext"
                              (contactSelected)="onContactSelected($event)">
            </call-search-list>
            <div class="expander"></div>
            <call-dialpad id="dialpad" #dialpad (buttonPressed)="onButtonPress($event)">
                <button id="btnVideo" *ngIf="deviceService.isWebRTCSelected$ | async" class="btnNum" type="button"
                        (click)="makeCall(true)">
                    <span app-video-light-icon></span>
                </button>
                <button id="btnCall" class="btnNum" (click)="makeCall(false)" type="button">
                    <span *ngIf="!!phoneNumber" app-phone-circle-green-background-custom-icon></span>
                    <span *ngIf="!!!phoneNumber" app-disabled-phone-custom-icon></span>
                </button>
                <button class="btnNum" id="dialpadBackspace" (click)="onBackSpacePress()" type="button">
                    <span app-backspace-regular-icon></span>
                </button>
            </call-dialpad>
        </div>
    `,
    styleUrls: ['dialer-view.component.scss'],
})
export class DialerViewComponent implements OnInit, OnDestroy {
    @ViewChild('phoneNumberInput', { static: false })
    phoneNumberInput: ElementRef;

    @ViewChild('searchList', { static: false })
    public searchList: CallSearchListComponent;

    @ViewChild('dialpad', { static: false })
    dialpad: CallDialpadComponent;

    phoneNumber: string|undefined = '';
    numberSubscription: Subscription;
    focusSubscription: Subscription;
    dialerVisibilitySubscription: Subscription;
    queryPhoneNumberSubscription: Subscription;
    dialerActionSubscription: Subscription;

    private dialpadkeys: string[] = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '*', '#'];
    private readonly keyId: string = 'dialpad-';
    public searchContext: SearchContext;

    public constructor(
        public dialerService: DialerService,
        public deviceService: DeviceService,
        private phoneService: PhoneService,
        private route: ActivatedRoute,
        private callsAdapterService: DialerVisibilityService,
        private router: Router
    ) {
        this.searchContext = SearchContext.headerSearch();
    }

    public ngOnInit(): void {
        this.queryPhoneNumberSubscription = this.dialerService.queryPhoneNumber$.subscribe((number) => {
            setTimeout(() => {
                this.numberChanged(number);
                // remove the phone param after consumption
                const params = { ...this.route.snapshot.queryParams };
                delete params.phone;
                this.router.navigate([], { queryParams: params });
            });
        });

        this.numberSubscription = this.dialerService.currentPhoneNumber.pipe(
            withLatestFrom(this.callsAdapterService.dialerRealState)
        ).subscribe(([phn, realState]) => {
            this.phoneNumber = phn;
            if (realState === 'open') {
                this.focus();
            }
        });

        this.focusSubscription = this.callsAdapterService.dialerRealState.pipe(delay(350)).subscribe(realState => {
            if (realState === 'open') {
                this.focus();
            }
        });

        this.dialerActionSubscription = this.dialerService.dialerActionEmitted$.subscribe((dialerAction) => {
            this.onDialerAction(dialerAction);
        });

        this.dialerVisibilitySubscription = this.callsAdapterService.dialerVisibilityInfo.pipe(delay(350)).subscribe((visibilityInfo:DialerVisibilityInfo) => {
            if (visibilityInfo.stealFocus) {
                this.focus();
            }
        });
    }

    public ngOnDestroy(): void {
        this?.queryPhoneNumberSubscription.unsubscribe();
        this?.numberSubscription.unsubscribe();
        this?.dialerVisibilitySubscription.unsubscribe();
        this?.focusSubscription.unsubscribe();
        this?.dialerActionSubscription.unsubscribe();
    }

    public focus() {
        if (this.phoneNumberInput) {
            this.phoneNumberInput.nativeElement.focus();
        }
    }

    private onDialerAction(dialerAction: DialerAction) {
        switch (dialerAction) {
            case 'focus':
                this.focus();
                break;
        }
    }

    makeCall(videoCall: boolean) {
        if (String.isNullOrEmpty(this.phoneNumber)) {
            this.numberChanged(this.phoneService.lastDialerNumber);
        }
        else {
            const phoneNumber = stripSpecialCharaceters(this.phoneNumber);
            if (!String.isNullOrEmpty(phoneNumber)) {
                this.dialerService.makeCall({ phoneNumber, videoCall });
            }
        }
    }

    onButtonPress(value: string) {
        const input = this.phoneNumberInput.nativeElement;
        if (typeof input.selectionStart === 'number') {
            const newNumber = replaceSelectedInterval(this.phoneNumber, input.selectionStart, input.selectionEnd, value);
            this.numberChanged(newNumber);
            this.setPhoneInputCaretPosition(input.selectionStart + 1);
        }
        else {
            this.numberChanged((this.phoneNumber || '') + value);
        }
        this.focus();
    }

    onBackSpacePress() {
        if (this.phoneNumber) {
            const input = this.phoneNumberInput.nativeElement;
            if (typeof input.selectionStart === 'number') {
                // delete the selected interval
                const startPosition = input.selectionStart === input.selectionEnd && input.selectionStart > 0 ? input.selectionStart - 1 : input.selectionStart;
                const newNumber = replaceSelectedInterval(this.phoneNumber, startPosition, input.selectionEnd, '');
                this.numberChanged(newNumber);
                this.setPhoneInputCaretPosition(startPosition);
            }
            else {
                this.numberChanged(this.phoneNumber.slice(0, -1));
            }
        }
        this.focus();
    }

    setPhoneInputCaretPosition(position:number) {
        const input = this.phoneNumberInput.nativeElement;
        setTimeout(() => {
            input.setSelectionRange(position, position);
        });
    }

    onKeyDown(ev: KeyboardEvent) {
        if (ev.key === 'Enter') {
            this.makeCall(false);
        }
        else if (ev.key === 'Escape') {
            if (String.isNullOrEmpty(this.phoneNumber)) {
                this.callsAdapterService.toggleDialer.next(false);
            }
            else {
                this.numberChanged('');
            }
        }
        else if (this.dialpadkeys.indexOf(ev.key) !== -1) {
            this.dialpad.play$.next(ev.key);
            document.getElementById(this.keyId + ev.key)!.classList.add('btn-active');
        }
        this.focus();
    }

    onKeyUp(ev: KeyboardEvent) {
        if (this.dialpadkeys.indexOf(ev.key) !== -1) {
            document.getElementById(this.keyId + ev.key)!.classList.remove('btn-active');
        }
    }

    numberChanged(newNumber: string) {
        this.dialerService.setTypedPhone(newNumber);
        this.searchList.search(newNumber);
    }

    public onContactSelected(searchResult: SearchResult|undefined) {
        if (searchResult instanceof SearchResult) {
            this.makeContactCall(searchResult);
        }
    }

    public makeContactCall(searchResult: SearchResult) {
        if (searchResult === undefined) {
            return;
        }

        if (searchResult.isVoicemail) {
            this.dialerService.leaveVoiceMailNumber(searchResult.number);
        }
        else {
            this.dialerService.makeCall({
                phoneNumber: searchResult.number,
                videoCall: false
            });
        }
    }
}
