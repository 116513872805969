import { MyPhoneSession } from '../myphone-session';

import { Bridge } from '../bridge';
import { Group } from '../group';
import { AppContact } from '../contact';
import { AppContactType, localBridgeId } from '../app-contact-type';
import { Utils } from './utils';
import { ExtensionBridge } from '../extension-bridge';
import { sanitizePhoneNumber } from '../../phone/phone-funcs';
import {
    ActionType,
    DnType,
    Group as MyPhoneGroup,
    GroupMember,
    GroupMembers,
    Groups
} from '@myphone';
import { Injectable } from '@angular/core';
import { JoinTool } from './join';
import { LocalConnectionEx } from '../local-connection-ex';
import { updateConnectionPresentationNames } from '../../shared/utils.service';
import { myPhoneTimeToDate } from '@webclient/myphone/time-util';

@Injectable()
export class GroupsJoin {
    constructor(private myPhoneSession: MyPhoneSession) {
    }

    public Merge(groups: Groups) {
        // Select bridge to work with
        const bridgeNumber = groups.FromLocalPbx ? localBridgeId : groups.BridgeNumber;
        if (bridgeNumber === undefined) {
            console.log('Update skipped because bridgeNumber undefined');
            return;
        }

        switch (groups.Action) {
            case ActionType.Deleted:
                this.deleteBridge(bridgeNumber);
                this.myPhoneSession.onBridgesChanged();
                break;
            case ActionType.FullUpdate: {
                this.deleteBridge(bridgeNumber);
                const bridge = new ExtensionBridge(bridgeNumber);
                this.myPhoneSession.bridgesMap[bridgeNumber] = this.updateBridge(bridge, groups);
                this.myPhoneSession.onBridgesChanged();
            }
                break;
            case ActionType.Updated: {
                const updatedBridge = this.myPhoneSession.bridgesMap[bridgeNumber];
                if (updatedBridge === undefined) {
                // Known phonesystem issue
                // console.log(`Update for bridge ${bridgeNumber} skipped cause bridge doesn't exist`);
                    return;
                }
                this.updateBridge(updatedBridge, groups);
            }
                break;
        }
    }

    private deleteBridge(bridgeNumber: string) {
        const bridge = this.myPhoneSession.bridgesMap[bridgeNumber];
        if (bridge === undefined) {
            return;
        }

        bridge.contactsMap.getContacts().forEach(GroupsJoin.contactDeleted);
        delete this.myPhoneSession.bridgesMap[bridgeNumber];
    }

    private static contactDeleted(contact: AppContact) {
        // AppContact might stay in ui and in contact db but it will be unregistered now
        contact.isRegistered = false;
    }

    private static contactAdded(contact: AppContact) {
        // TODO reaction on contact deleted
    }

    private static contactUpdated(contact: AppContact) {
        // TODO reaction on contact deleted
    }

    private updateBridge(bridge: ExtensionBridge, myPhoneBridge: Groups): ExtensionBridge {
        if (myPhoneBridge.RemotePbx !== undefined) {
            bridge.name = myPhoneBridge.RemotePbx;
        }

        if (myPhoneBridge.Prefix !== undefined && bridge.prefix !== myPhoneBridge.Prefix) {
            bridge.prefix = myPhoneBridge.Prefix;
            bridge.contactsMap.getContacts().forEach(contact => {
                contact.phones.extension = bridge.prefix + contact.nativeExtensionNumber;
                if (contact.nativeMobileNumber) {
                    contact.phones.mobile = bridge.prefix + contact.nativeMobileNumber;
                }
                contact.onIdentityChanged();
            });
        }

        if (myPhoneBridge.IsConnected !== undefined) {
            bridge.isConnected = myPhoneBridge.IsConnected;
        }

        if (myPhoneBridge.IsAllowed !== undefined) {
            bridge.isAllowed = myPhoneBridge.IsAllowed;
        }

        let groupsListIsChanged = false;

        // If it's a FullUpdate we need to delete all groups
        if (myPhoneBridge.Action === ActionType.FullUpdate) {
            Object.keys(bridge.groupsMap).forEach(id => GroupsJoin.deleteGroup(bridge, id));
            groupsListIsChanged = true;
        }

        if (myPhoneBridge.Items !== undefined) {
            myPhoneBridge.Items.forEach(myPhoneGroup => {
                switch (myPhoneGroup.Action) {
                    case ActionType.Deleted:
                        GroupsJoin.deleteGroup(bridge, myPhoneGroup.Id.toString());
                        groupsListIsChanged = true;
                        break;
                    case ActionType.FullUpdate:
                        GroupsJoin.deleteGroup(bridge, myPhoneGroup.Id.toString());
                        this.addGroup(bridge, myPhoneGroup);
                        groupsListIsChanged = true; // Seems that is replace so send groups change event
                        break;
                    case ActionType.Updated:
                        this.updateGroup(bridge, bridge.groupsMap[myPhoneGroup.Id], myPhoneGroup);
                        break;
                    case ActionType.Inserted:
                        this.addGroup(bridge, myPhoneGroup);
                        groupsListIsChanged = true;
                        break;
                }
            });
        }

        if (groupsListIsChanged) {
            bridge.onGroupChanged();
        }

        return bridge;
    }

    private static deleteGroup(bridge: ExtensionBridge, id: string) {
        const group = bridge.groupsMap[id];
        if (group === undefined) {
            return;
        }
        GroupsJoin.deleteMembersFromGroup(bridge, group, group.contactsMap.getIds());
        delete bridge.groupsMap[id];
    }

    private addGroup(bridge: ExtensionBridge, myPhoneGroup: MyPhoneGroup) {
        const group = new Group('' + myPhoneGroup.Id, bridge);
        group.language = myPhoneGroup.Language;
        bridge.groupsMap[group.id] = this.updateGroup(bridge, group, myPhoneGroup);
    }

    private updateGroup(bridge: ExtensionBridge, group: Group, myPhoneGroup: MyPhoneGroup): Group {
        if (myPhoneGroup.Name !== undefined) {
            group.name = myPhoneGroup.Name;
        }
        if (myPhoneGroup.CurrentGroupHours !== undefined) {
            group.currentGroupHours = myPhoneGroup.CurrentGroupHours;
        }
        if (myPhoneGroup.OverrideExpiresAt !== undefined) {
            group.overrideExpiresAt = myPhoneGroup.OverrideExpiresAt;
        }
        if (myPhoneGroup.DisableCustomPrompt !== undefined) {
            group.disableCustomPrompt = myPhoneGroup.DisableCustomPrompt;
        }
        if (myPhoneGroup.AllowCallService !== undefined) {
            group.allowCallService = myPhoneGroup.AllowCallService;
            group.onAllowCallServiceChanged();
        }

        group.language = myPhoneGroup.Language;
        group.onGroupLanguageChanged();

        if (myPhoneGroup.Members !== undefined) {
            this.updateGroupMembers(bridge, group, myPhoneGroup.Members);
            group.onContactsChanged();
            bridge.onContactsChanged();
        }
        return group;
    }

    private updateGroupMembers(bridge: ExtensionBridge, group: Group, myPhoneGroupMembers: GroupMembers) {
        if (!myPhoneGroupMembers) {
            return;
        }
        switch (myPhoneGroupMembers.Action) {
            case ActionType.Deleted:
            // Delete specific members
                GroupsJoin.deleteMembersFromGroup(bridge, group, myPhoneGroupMembers.Items.map(x => `${x.Id}@${bridge.id}`));
                break;
            case ActionType.FullUpdate:
            // Delete all members
                GroupsJoin.deleteMembersFromGroup(bridge, group, group.contactsMap.getIds());
                this.addMembers(bridge, group, myPhoneGroupMembers.Items);
                break;
            case ActionType.Updated:
                this.updateMembers(bridge, group, myPhoneGroupMembers);
                break;
            case ActionType.Inserted:
                this.addMembers(bridge, group, myPhoneGroupMembers.Items);
                break;
        }
    }

    private static deleteMembersFromGroup(bridge: ExtensionBridge, group: Group, deleteContactKeys: string[]) {
        let contactDeleted = false;
        group.contactsMap.getIds().filter(x => deleteContactKeys.indexOf(x) !== -1)
            .forEach(x => {
                // Delete group from contact
                const contact = group.contactsMap.getContactById(x);
                const index = contact.groups.indexOf(group);

                if (index !== -1) {
                    contact.groups.splice(index, 1);
                    if (contact.isFavorite && group.isFavorite()) {
                        contact.isFavorite = false;
                    }
                }
                // Delete contact from group
                group.contactsMap.removeContact(x);

                // Delete contact from bridge
                if (contact.groups.length === 0) {
                    contactDeleted = true;
                    GroupsJoin.contactDeleted(contact);
                    delete bridge.contactsByExtensionMap[contact.nativeExtensionNumber];
                    bridge.contactsMap.removeContact(x);
                }
                else {
                    contact.onIdentityChanged();
                }
            });
        if (contactDeleted) {
            bridge.onContactsChanged();
        }
    }

    private updateMembers(bridge: ExtensionBridge, group: Group, myPhoneGroupMembers: GroupMembers) {
        if (!myPhoneGroupMembers || !myPhoneGroupMembers.Items) {
            return;
        }
        myPhoneGroupMembers.Items.forEach(member => {
            const memberId = `${member.Id}@${bridge.id}`;
            switch (member.Action) {
                case ActionType.Deleted:
                // Delete specific members
                    GroupsJoin.deleteMembersFromGroup(bridge, group, [memberId]);
                    break;
                case ActionType.FullUpdate:
                // Delete all members
                    GroupsJoin.deleteMembersFromGroup(bridge, group, [memberId]);
                    this.addMembers(bridge, group, [member]);
                    break;
                case ActionType.Updated: {
                    const contact = group.contactsMap.getContactById(memberId);
                    this.updateMember(bridge, contact, member);
                    GroupsJoin.contactUpdated(contact);
                    group.contactsMap.setContact(contact);
                }
                    break;
                case ActionType.Inserted:
                    this.addMembers(bridge, group, [member]);
                    break;
            }
        });
    }

    private addMembers(bridge: ExtensionBridge, group: Group, myPhoneGroupMembers: GroupMember[]) {
        if (!myPhoneGroupMembers) {
            return;
        }
        let contactAdded = false;
        myPhoneGroupMembers.forEach(member => {
            const id = `${member.Id}@${bridge.id}`;
            let newContact = false;
            let contact = bridge.contactsMap.getContactById(id);
            if (contact === undefined) {
                contact = new AppContact(id, AppContactType.Extension, bridge.id);
                contact.myContact = member.ExtensionNumber === this.myPhoneSession.myInfo.Number && contact.bridgeId === localBridgeId;
                contact.chatEnabled = !contact.myContact;
                newContact = true;
            }
            this.updateMember(bridge, contact, member);

            if (newContact) {
                // Add new contact to bridge
                bridge.contactsMap.setContact(contact);
                bridge.contactsByExtensionMap[contact.nativeExtensionNumber] = contact;
                contactAdded = true;
            }
            // Add contact to group
            group.contactsMap.setContact(contact);
            // Add group to contact
            contact.groups.push(group);
            if (!contact.isFavorite && group.isFavorite()) {
                contact.isFavorite = true;
            }
            if (newContact) {
                GroupsJoin.contactAdded(contact);
            }
        });
        bridge.onContactsChanged();
    }

    private updateMember(bridge: Bridge, contact: AppContact, member: GroupMember) {
        const contactMatcher = this.myPhoneSession.contactMatcher;
        if (member.ExtensionNumber !== undefined) {
            contact.nativeExtensionNumber = member.ExtensionNumber;
            contact.phones.extension = bridge.prefix + member.ExtensionNumber;
            contact.sanitizedPhones.extension = sanitizePhoneNumber(bridge.prefix + member.ExtensionNumber);

            contactMatcher.bridgesContactDb.addPhone(bridge.id, bridge.prefix, contact.phones.extension, contact);
        }
        if (member.MobileNumber !== undefined) {
            contact.nativeMobileNumber = member.MobileNumber;

            const oldMobile = contact.phones.mobile;
            if (oldMobile) {
                contactMatcher.bridgesMobilesContactDb.removeIfSelf(oldMobile, contact);
            }

            if (member.MobileNumber) {
                const newMobile = bridge.prefix + member.MobileNumber;
                contact.phones.mobile = newMobile;
                contact.sanitizedPhones.mobile = sanitizePhoneNumber(newMobile);

                contactMatcher.bridgesMobilesContactDb.addPhone(newMobile, contact);
            }
            else {
                contact.phones.mobile = '';
                contact.sanitizedPhones.mobile = '';
            }
        }

        if (member.ContactImage !== undefined) {
            const img = member.ContactImage;
            // Bypass SW for remote images to avoid CSP troubles
            if ((img.startsWith('http://') || img.startsWith('https://'))) {
                contact.profilePicture = member.ContactImage + '?ngsw-bypass';
            }
            else {
                contact.profilePicture = member.ContactImage;
            }
        }

        let nameUpdated = false;

        if (member.FirstName !== undefined) {
            nameUpdated = true;
            contact.firstName = member.FirstName;
        }
        if (member.LastName !== undefined) {
            nameUpdated = true;
            contact.lastName = member.LastName;
        }
        if (nameUpdated) {
            Utils.onContactNameChanged(contact);
        }
        if (member.IsRegistered !== undefined) {
            contact.isRegistered = member.IsRegistered;
        }
        if (member.IsDnd !== undefined) {
            contact.isDnd = member.IsDnd;
        }
        if (member.QueueStatus !== undefined) {
            contact.queueStatus = member.QueueStatus;
        }
        if (member.IsQueueAgent !== undefined) {
            contact.isQueueAgent = member.IsQueueAgent;
        }
        if (member.IsBusy !== undefined) {
            contact.isBusy = member.IsBusy;
        }
        if (member.MonitorExpiration !== undefined) {
            contact.monitorExpiration = myPhoneTimeToDate(member.MonitorExpiration);
        }

        if (member.Connections !== undefined) {
            JoinTool.Merge(contact.connections, member.Connections);

            const displayedConnection = contact.connections.Items.find((lc) => lc.OwnerType === DnType.Extension && lc.OwnerDn === contact.extensionNumber && lc.OtherPartyDisplayName !== 'Barge In');
            if (!displayedConnection) {
                contact.displayedConnection = undefined;
            }
            else {
                contact.displayedConnection = new LocalConnectionEx(displayedConnection);
                updateConnectionPresentationNames(
                    (num, defaultName, dnType, dn) => this.myPhoneSession.contactSource(num, defaultName, dnType, dn), contact.displayedConnection);
            }
        }

        if (member.EmailAddress !== undefined) {
            contact.emailAddress = member.EmailAddress;
        }
        if (member.StatusTemporarilyChanged !== undefined) {
            contact.statusTemporarilyChanged = member.StatusTemporarilyChanged;
        }
        if (member.OverrideExpiresAtUTCTime !== undefined) {
            contact.overrideExpiresUTCTime = myPhoneTimeToDate(member.OverrideExpiresAtUTCTime);
        }
        if (member.OverrideAttachedData !== undefined) {
            contact.overrideAttachedData = member.OverrideAttachedData;
        }
        if (member.IsRinging !== undefined) {
            contact.isRinging = member.IsRinging;
        }

        // Current profile
        const original = contact.currentProfile.toJSON();
        if (member.CurrentProfile !== undefined) {
            original.name = member.CurrentProfile;
        }
        if (member.CurrentProfileInternalName !== undefined) {
            original.internalName = member.CurrentProfileInternalName;
        }
        if (member.AwayFwdType !== undefined) {
            original.fwdType = member.AwayFwdType;
        }
        if (member.CurrentStatus !== undefined) {
            original.status = member.CurrentStatus;
        }
        if (member.AwayNumber !== undefined) {
            original.awayNumber = member.AwayNumber;
        }
        contact.currentProfile = contact.currentProfile.merge(original);

        // Override current profile
        const originalOverride = contact.overrideCurrentProfile.toJSON();
        if (member.OverrideCurrentProfile !== undefined) {
            originalOverride.name = member.OverrideCurrentProfile;
        }
        if (member.OverrideCurrentProfileInternalName !== undefined) {
            originalOverride.internalName = member.OverrideCurrentProfileInternalName;
        }
        if (member.OverrideFwdType !== undefined) {
            originalOverride.fwdType = member.OverrideFwdType;
        }
        if (member.OverrideCurrentStatus !== undefined) {
            originalOverride.status = member.OverrideCurrentStatus;
        }
        if (member.OverrideNumber !== undefined) {
            originalOverride.awayNumber = member.OverrideNumber;
        }
        contact.overrideCurrentProfile = contact.overrideCurrentProfile.merge(originalOverride);

        contact.onIdentityChanged();
    }
}
