<ng-container *ngIf="soundNotification$|async; let sound">
    <audio-sink *ngIf="!sound.isRinging" [loop]="true" [src]="sound.url" [sink]="deviceMediaService.selectedOutputAudioDevice$"></audio-sink>
    <audio-sink *ngIf="sound.isRinging && !(silentModeService.silentMode$|async)" [loop]="true" [src]="sound.url" [sink]="deviceMediaService.selectedRingerDevice$"></audio-sink>
</ng-container>

<ng-container *ngIf="(activeCall$|async) && utilityService.isWebRTCCall(activeCall$|async)">
    <audio-sink *ngIf="activeMediaStream$|async as activeMediaStream"
                [sink]="deviceMediaService.selectedOutputAudioDevice$"
                [srcObject]="activeMediaStream">
    </audio-sink>
</ng-container>

<calls-bar *ngIf="isCallsBarVisible" [activeCall]="dialerService.activeCall$|async"></calls-bar>

<div class="calls-wrapper">
    <ng-container *ngIf="dialerService.activeCall$|async; let activeCall">
        <call-view *ngIf="!(dialerService.hasMultipleCallsAllIncoming$|async)" #callView tabindex="0"
                   [myCall]="activeCall"
                   [hasSingleCall]="dialerService.hasSingleCall$|async"
                   (inCallKeyPadVisible)="InCallKeyPadVisibilityChange($event)"
                   (inCallSearchVisible)="InCallSearchVisibilityChange($event)"></call-view>
    </ng-container>
    <multiple-incoming-calls *ngIf="(dialerService.hasMultipleCallsAllIncoming$|async)"
                             (callActionFired)='onCallActionFired()' >
    </multiple-incoming-calls>
    <dialer-view *ngIf="dialerService.doesNotHaveCalls$|async"></dialer-view>
</div>
