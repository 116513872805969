import { PeopleService } from '@webclient/people/people.service';
import { PhonebookContactEditorModule } from '../phonebook-editor/phonebook-contact-editor.module';
import { PhonebookEditorDialogComponent } from './wc-phonebook-editor/wc-phonebook-editor.component';
import { MessageBoxComponent } from './wc-messagebox/wc-messagebox.component';
import { NgModule } from '@angular/core';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ProgressModalComponent } from './progress-modal.component';
import { QrcodeModule } from '../qrcode/qrcode-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatetimepickerComponent } from '@webclient/standalones/date-time-picker';
import { DeckOfCardModule } from './deck-of-cards/deck-of-card.module';
import { ShowAppsDialogComponent } from '@webclient/standalones/show-apps-dialog';
import { ValdemortModule } from 'ngx-valdemort';
import { FieldsModule } from '@webclient/fields/fields.module';
import {
    ContactCreationErrorComponent
} from '@webclient/phonebook/contact-creation-error/contact-creation-error.component';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TranslateModule } from '@ngx-translate/core';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';

@NgModule({
    imports: [
        IconsModule,
        CommonModule,
        ShowAppsDialogComponent,
        ModalModule,
        FormsModule,
        AlertModule,
        QrcodeModule,
        PhonebookContactEditorModule,
        DeckOfCardModule,
        CarouselModule,
        ValdemortModule,
        FieldsModule,
        ProgressbarModule,
        TranslateModule,
        ModalDialogComponent,
        ReactiveFormsModule,
        DatetimepickerComponent
    ],
    exports: [
        MessageBoxComponent,
        PhonebookEditorDialogComponent,
        ContactCreationErrorComponent,
    ],
    declarations: [
        ProgressModalComponent,
        MessageBoxComponent,
        PhonebookEditorDialogComponent,
        ContactCreationErrorComponent,
    ],
    providers: [
        PeopleService,
    ],
})
export class AppModalModule {

}
