import { MyPhoneService } from '../myphone/myphone.service';
import { Router } from '@angular/router';
import { AppContact } from '../myphone/contact';
import { Injectable } from '@angular/core';
import { switchMap, take } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import {
    RequestCreateConversation,
    RequestSendChatMessageToConversation,
    RequestUpdateConversation,
    ResponseAcknowledge,
    ResponseConversationInfo
} from '@myphone';

import { NewGroupChat } from '../myphone/new-group-chat';
import { MyPhoneSession } from '../myphone/myphone-session';
import { AppContactType } from '../myphone/app-contact-type';
import { ModalService } from '@webclient/modal/app-modal.service';
import { contactToChatAddress } from '@webclient/chat-data/chat-utils';

export function getPartyByContacts(session: MyPhoneSession, contacts: AppContact[]): string {
    const extensionsOnly = contacts.filter(x => x.type === AppContactType.Extension);

    // You can chat only with extensions
    const exts = extensionsOnly.map(x => contactToChatAddress(x));

    const myNumber = session.myInfo.Number;
    if (!extensionsOnly.some(x => x.phones.extension === myNumber)) {
        exts.unshift(myNumber);
    }

    exts.sort();

    return exts.join(':').replace(new RegExp('#', 'g'), '@');
}

@Injectable({
    providedIn: 'root'
})
export class ChatLocatorService {
    constructor(private myPhoneSvc: MyPhoneService, private router: Router, private modalService: ModalService) {
    }

    public updateConversation(existInfo:ResponseConversationInfo): Observable<ResponseAcknowledge> {
        const requestUpdate = new RequestUpdateConversation();
        requestUpdate.IdConversation = existInfo.Conversation.IdConversation;
        requestUpdate.PrivateName = existInfo.Conversation.PrivateName;
        requestUpdate.IsArchived = existInfo.Conversation.IsArchived;
        requestUpdate.PublicName = existInfo.Conversation.PublicName;
        return this.myPhoneSvc.get(requestUpdate);
    }

    public createExternalConversation(phone: string, bridge: string): Observable<ResponseConversationInfo> {
        return this.myPhoneSvc.myPhoneSession.pipe(take(1), switchMap((session) => {
            const conversationRequest = new RequestCreateConversation();
            conversationRequest.Party = `${session.myInfo.Number}:${phone}@${bridge}`;
            conversationRequest.PrivateName = '';
            return this.myPhoneSvc.get<ResponseConversationInfo>(conversationRequest);
        }));
    }

    public createConversation(contacts: AppContact[], groupName?: string): Observable<ResponseConversationInfo> {
        return this.myPhoneSvc.myPhoneSession.pipe(take(1), switchMap((session) => {
            const conversationRequest = new RequestCreateConversation();
            conversationRequest.Party = getPartyByContacts(session, contacts);
            conversationRequest.PrivateName = '';
            if (groupName) {
                conversationRequest.PublicName = groupName;
            }
            return this.myPhoneSvc.get<ResponseConversationInfo>(conversationRequest);
        }));
    }

    public sendMessage(conversation: { contacts: AppContact[], group?: string, }, message: string):Observable<any> {
        return this.createConversation(conversation.contacts, conversation.group).pipe(
            take(1),
            switchMap(conversationInfo => {
                const request = new RequestSendChatMessageToConversation();
                request.Message = message;
                request.IdConversation = conversationInfo.Conversation.IdConversation;
                return this.myPhoneSvc.get(request);
            })
        );
    }

    public openChat(newChat: NewGroupChat): Subscription {
        return this.createConversation(newChat.contacts, newChat.groupName).pipe(take(1)).subscribe({
            next: (info) => {
                this.openChatById(info.Conversation.IdConversation);
            },
            error: (error: unknown) => this.modalService.error(error)
        });
    }

    public openChatById(chatId: number, isMonitoring?: boolean): void {
        if (isMonitoring) {
            this.router.navigate([`/chat/monitor/${chatId}`]);
        }
        else {
            this.router.navigate([`/chat/${chatId}`]);
        }
    }
}
