import { enableProdMode, NgModuleRef } from '@angular/core';
import { Environment } from './model';

// Error.stackTraceLimit = 50;
enableProdMode();

export const environment: Environment = {
    production: true,

    /** Angular debug tools in the dev console
   * https://github.com/angular/angular/blob/86405345b781a9dc2438c0fbe3e9409245647019/TOOLS_JS.md
   * @param modRef
   * @return {any}
   */
    decorateModuleRef(modRef: NgModuleRef<any>) {
        return modRef;
    }
};
