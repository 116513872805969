import {
    Component, EventEmitter, Output
} from '@angular/core';
import { DialerService } from './dialer.service';
import { MyCall } from '@webclient/phone/mycall';
import { CallControlService } from '@webclient/call/call-control.service';

@Component({
    selector: 'multiple-incoming-calls',
    styleUrls: ['multiple-incoming-calls.component.scss'],
    templateUrl: 'multiple-incoming-calls.component.html'
})
export class MultipleIncomingCallsComponent {
    @Output()
    public readonly callActionFired = new EventEmitter();

    constructor(public dialerService: DialerService, public callsService: CallControlService) {
    }

    CallerName(myCall: MyCall) {
        return myCall.displayName ? myCall.displayName : myCall.phone;
    }

    CallerNumber(myCall: MyCall) {
        return this.CallerName(myCall) === myCall.phone ? '' : myCall.phone;
    }

    AnswerCall(myCall: MyCall) {
        this.callsService.answer(myCall, false);
        this.callActionFired.emit();
    }

    DropCall(myCall: MyCall) {
        this.callsService.drop(myCall);
        this.callActionFired.emit();
    }

    DivertToVoiceMail(myCall: MyCall) {
        this.callsService.divertToVoicemail(myCall);
        this.callActionFired.emit();
    }
}
