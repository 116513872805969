import { SimpleControlValueAccessor } from '@office/simple-control-value-accessor';
import { ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

export class FieldValueAccessor<TValue, TInnerValue = TValue> extends SimpleControlValueAccessor<TValue> implements ControlValueAccessor {
    constructor(cd: ChangeDetectorRef) {
        super(cd);
    }

    value: TInnerValue;
    protected changed = false;

    /** Mark control as touched on value change, false is needed only on text inputs */
    protected touchOnChange = true;

    parse(value: TValue): TInnerValue {
        // by default types are equal
        return value as unknown as TInnerValue;
    }

    format(value: TInnerValue): TValue {
        // by default types are equal
        return value as unknown as TValue;
    }

    writeValue(value: TValue) {
        this.value = this.parse(value);
        this.changed = false;

        // custom controls should mark themselves for check manually on programmatic update
        this.cd.markForCheck();
    }

    valueChanged(value: TInnerValue, force?: boolean) {
        const cleanValue = value && typeof value === 'string' ? value.trim() as unknown as TInnerValue : value;
        if (this.value === cleanValue && !force) {
            return;
        }
        this.value = cleanValue;
        this.changed = true;
        this.onChange(this.format(this.value));
        if (this.touchOnChange) {
            // forcing event is important for value accessors, bound to { updateOn: blur } controls
            this.markAsTouched(true);
        }
        this.cd.markForCheck();
    }
}
