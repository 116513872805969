import { ActiveCallInfo, ContactSource } from './active-call-info';

import { JoinTool } from '../join/join';
import { TimeSync } from '../time-sync';
import {
    ActionType, DnType, LocalConnection, LocalConnectionState
} from '@myphone';
import { LocalConnectionEx } from '../local-connection-ex';
import { updateConnectionPresentationNames } from '../../shared/utils.service';
import { myPhoneTimeToDate } from '@webclient/myphone/time-util';

export class ActiveCallHandler {
    constructor(private timeSync: TimeSync, private _contactSource: ContactSource) {
    }

    private addNewConnection(lc: LocalConnection, ac: ActiveCallInfo) {
        const conn = new LocalConnectionEx(lc);
        ac.Connections.push(conn);
        updateConnectionPresentationNames(this._contactSource, conn);
        this.showCall(ac);
    }

    public createActiveCall(callId: string, lc: LocalConnection, isQueueCall: boolean, queueName?:string): ActiveCallInfo {
        const ac = new ActiveCallInfo(callId, { QueueName: queueName, IsQueueCall: isQueueCall });
        this.addNewConnection(lc, ac);
        return ac;
    }

    public updateActiveCallConnection(ac: ActiveCallInfo, lc: LocalConnection) {
        const localCon = ac.Connections.find(x => x.Id === lc.Id);
        if (localCon === undefined) {
            // skip updates without fullupdate
            if (lc.Action !== ActionType.FullUpdate) {
                return;
            }
            this.addNewConnection(lc, ac);
            return;
        }
        JoinTool.Merge(localCon, lc);
        updateConnectionPresentationNames(this._contactSource, localCon);

        this.showCall(ac);
    }

    public deleteActiveCallConnection(ac: ActiveCallInfo, id: number): boolean {
        const localCon = ac.Connections.find(x => x.Id === id);
        if (localCon) {
            ac.Connections.splice(ac.Connections.indexOf(localCon), 1);
        }
        if (ac.Connections.length === 0) {
            ac.CanShow = false;
        }
        else {
            this.showCall(ac);
        }
        return (ac.Connections.length === 0);
    }

    private showCall(ac: ActiveCallInfo) {
        if (ac.Connections.length === 0) {
            ac.CanShow = false;
            return;
        }
        if (ac.Connections.length === 1) {
            if (ac.Connections[0].IsIncoming !== undefined) {
                this.showOneConnection(ac, ac.Connections[0], ac.Connections[0].IsIncoming);
            }

            return;
        }

        const srtLst = ac.Connections
            .filter(x => x.State !== undefined && x.State !== LocalConnectionState.TryingToTransfer && x.State !== LocalConnectionState.UnknownState)
            .sort((x, y) => x.LegId - y.LegId);

        if (srtLst.length === 0) {
            return;
        }

        const first = srtLst[0];
        if (first.State === LocalConnectionState.Ringing) {
            const cn = srtLst.find(x => x.State === LocalConnectionState.Connected);
            if (cn !== undefined) {
                this.showOneConnection(ac, cn, false);
            }
            else {
                this.showOneConnection(ac, first, true);
            }

            return;
        }

        this.showOneConnection(ac, first, false);
    }

    private showOneConnection(ac: ActiveCallInfo, lc: LocalConnectionEx, callerIsOtherParty: boolean) {
        // SY------------------
        // "on behalf of" connections are with external numbers
        ac.CanShow = false;
        if (lc.State === undefined || lc.State === LocalConnectionState.UnknownState) {
            return;
        }
        ac.CanShow = true;

        // this._myCall = ((lc.OtherPartyCallerId !== undefined && lc.OtherPartyCallerId === this._myNumber) || (lc.OwnerDn !== undefined && lc.OwnerDn === this._myNumber));
        ac.Participants = [];
        if (lc.OtherPartyCallerId) {
            ac.Participants.push(lc.OtherPartyCallerId);
        }

        // SY: replaced
        // if (lc.OwnerDn )
        //    participants.Add(lc.OwnerDn);
        if (lc.OwnerCallerId) {
            ac.Participants.push(lc.OwnerCallerId);
        }
        //-----------------------

        if (callerIsOtherParty) {
            ac.Caller = lc.OtherPartyPresentationString;
            ac.CallerType = lc.OtherPartyType;

            ac.Callee = ActiveCallHandler.GetOriginator(ac, lc) + lc.OwnerPresentationString;
            ac.CalleeType = lc.OwnerType;
        }
        else {
            ac.Caller = lc.OwnerPresentationString;
            ac.CallerType = lc.OwnerType;

            ac.Callee = ActiveCallHandler.GetOriginator(ac, lc) + lc.OtherPartyPresentationString;
            ac.CalleeType = lc.OtherPartyType;
        }
        ac.OriginatorType = lc.OriginatorType;

        ac.IsBreached = ac.CalleeType === DnType.Queue && ac.Connections.some(x => x.SLABreach);
        ac.IsWaiting = ac.CalleeType === DnType.Queue;
        ac.RawState = lc.State;
        if (lc.AnsweredAt) {
            ac.StartedAt = this.timeSync.toClientTime(myPhoneTimeToDate(lc.AnsweredAt)).getTime();
        }
        else if (lc.StartedAt) {
            ac.StartedAt = this.timeSync.toClientTime(myPhoneTimeToDate(lc.StartedAt)).getTime();
        }
    }

    static GetOriginator(ac: ActiveCallInfo, lc: LocalConnection): string {
        if (lc.State === LocalConnectionState.Ringing && lc.OriginatorType !== undefined && lc.OriginatorType === DnType.RingGroup) {
            let s = lc.OriginatorName;
            if (lc.OriginatorDn !== undefined) {
                s += ' ' + lc.OriginatorDn;
            }
            return s + ': ';
        }

        if (lc.State === LocalConnectionState.Dialing || lc.State === LocalConnectionState.WaitingForNewParty) {
            const found = ac.Connections.find(x => x.State === LocalConnectionState.Ringing && x.OriginatorType === DnType.RingGroup);
            if (found !== undefined) {
                let s = found.OriginatorName;
                if (found.OriginatorDn) {
                    s += ' ' + found.OriginatorDn;
                }
                return s + ': ';
            }
        }
        return '';
    }
}
