<field-wrapper [label]="label">
    <div class="d-inline-block">
        <img class="img-thumbnail extension-avatar mb-3" [src]="thumbnailSrc" alt="default">
        <div class="text-center">
            <button class="btn btn-plain" type="button" (click)="upload()" data-qa="upload"
                    title="{{ '_i18n.SelectImage' | translate}}" app-folder-open-solid-icon>
            </button>
            <button class="btn btn-plain" type="button" (click)="remove()" data-qa="remove"
                    title="{{ '_i18n.DeleteImage' | translate}}" app-times-light-icon>
            </button>
        </div>
    </div>
    <input data-qa="file" id="file" type="file" hidden accept="image/*" #file (change)="onFileSelected()">
</field-wrapper>

