import { Component, Input } from '@angular/core';
import { ModalButtons } from '@webclient/modal/message-box';
import { DialogComponent } from '@webclient/modal/dialog';

@Component({
    selector: 'app-messagebox',
    template: `
        <app-modal-dialog [header]="header" [buttons]="buttons" [customText]="customText" [customClass]='customClass' (submitted)="onSubmit()" [scrollable]="true">
            <p data-qa="modal-text" [innerHTML]="message | translate:messageTranslateParams"></p>
        </app-modal-dialog>
    `
})
export class MessageBoxComponent extends DialogComponent {
    @Input() header = '';
    @Input() message = '';
    @Input() customText = '';
    @Input() customClass = 'btn-border';
    @Input() buttons: ModalButtons = ModalButtons.Ok;
    @Input() messageTranslateParams?: unknown;
}
