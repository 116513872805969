import {
    AfterViewInit, Component, Input, OnDestroy, Output, ViewChild
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SearchResult } from '../search/search-result';
import { FindContactComponent } from './find-contact.component';
import { SearchContext } from '../search/search-context';
import { Observable, Subject, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
    selector: 'find-contact-modal',
    template: `
        <div bsModal #childModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
             aria-labelledby="mySmallModalLabel" aria-hidden="true">
            <div id="modalFindContact" class="modal-dialog modal-sm">
                <div class='modal-content'>
                    <div class="modal-header">
                        <h4 class="modal-title" data-qa="modal-title">{{header|translate}}</h4>
                        <button type="button" class="btn-close" aria-label="Close" (click)="hide()"></button>
                    </div>
                    <div class="modal-body">
                        <find-contact #findContact
                                      [searchContext]="searchContext"
                                      (contactSelected)="contactSelected($event)"
                                      placeholder="{{placeholder || '_i18n.SearchOrEnterNumber' | translate}} ..."></find-contact>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class FindContactModalComponent implements AfterViewInit, OnDestroy {
    @Output()
    public readonly result$ = new Subject<SearchResult|undefined>();

    @Input()
    public header: string;

    @Input()
    public placeholder: string;

    @Input()
    public autoShow: boolean;

    @ViewChild('childModal', { static: false })
    public childModal: ModalDirective;

    @ViewChild('findContact', { static: false })
    public findContact: FindContactComponent;

    @Input()
    searchContext: SearchContext = SearchContext.default();

    @Input()
    type = '';

    private modalShownSubscription: Subscription;
    private modalHiddenSubscription: Subscription;

    cssHeader: string;
    cssBody: string;

    ngOnDestroy() {
        if (this.modalShownSubscription) {
            this.modalShownSubscription.unsubscribe();
        }
        if (this.modalHiddenSubscription) {
            this.modalHiddenSubscription.unsubscribe();
        }
    }

    ngAfterViewInit() {
        // Focus contact element on show
        this.modalShownSubscription = this.childModal.onShown.subscribe(() => this.findContact.focus());
        this.modalHiddenSubscription = this.childModal.onHidden.subscribe(() => this.result$.next(undefined));
        if (this.autoShow) {
            this.show(this.header);
        }
    }

    public contactSelected(contact?: SearchResult) {
        // AppContact was selected in search
        this.result$.next(contact);
        this.hide();
    }

    public show(header: string): Observable<any> {
        this.header = header;
        this.childModal.show();

        return this.result$.pipe(take(1));
    }

    public hide(): void {
        if (this.childModal.isShown) {
            this.childModal.hide();
        }
    }
}
