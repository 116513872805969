import { Component, Input } from '@angular/core';
import { DialogComponent } from '@webclient/modal/dialog';
import { ModalButtons } from '@webclient/modal/message-box';

export type PromoteAppType = 'Windows' | 'Ios' | 'Android';
@Component({
    selector: 'app-promote-apps-dialog',
    template: `
        <app-modal-dialog [buttons]="buttons">
            <div dialog-header>
                <h4 class="fw-bold">{{'_i18n.getThe3CXApp'| translate}}</h4>
                <div>{{'_i18n.3cxAppVoiceVideoCall' | translate}}</div>
            </div>
            <ng-container [ngSwitch]="app">
                <app-ios-dialog-content *ngSwitchCase="'Ios'" [userId]="userId"></app-ios-dialog-content>
                <app-android-dialog-content *ngSwitchCase="'Android'" [userId]="userId"></app-android-dialog-content>
                <app-windows-dialog-content *ngSwitchCase="'Windows'" [userId]="userId"></app-windows-dialog-content>
                <app-welcome-dialog-content *ngSwitchDefault (showWindows)="changeType('Windows')" [userId]="userId"></app-welcome-dialog-content>
            </ng-container>
        </app-modal-dialog>
    `,
    styles: ['@media (min-width: 576px) { :host { --bs-modal-width: 560px; } }']
})
export class PromoteAppDialogComponent extends DialogComponent {
    readonly buttons = ModalButtons.Ok;

    @Input()
    public app: PromoteAppType;

    /** for another user in admin view */
    @Input() userId?: number;

    changeType(type: PromoteAppType) {
        this.app = type;
    }
}
