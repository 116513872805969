import { Component } from '@angular/core';
import { DialogComponent } from '@webclient/modal/dialog';
import notificationHelpImage from 'img/notif-help.png';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog/modal-dialog.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    template: `
        <app-modal-dialog header="_i18n.AllowNotifications">
            <img [src]="notificationHelpImage" class="d-none d-md-block mb-4 w-100" alt="">
            <p>{{'_i18n.AllowNotificationsDisclaimer'|translate}}</p>
        </app-modal-dialog>
    `,
    standalone: true,
    imports: [ModalDialogComponent, TranslateModule]
})
export class NotificationsHelpDialogComponent extends DialogComponent {
    readonly notificationHelpImage = notificationHelpImage;
}
