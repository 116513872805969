import { ContactViewModel } from './contact-view-model';
import { Component, Input } from '@angular/core';
import { AppContact } from '../myphone/contact';
import { AppContactType, phonebookBridgeId } from '../myphone/app-contact-type';
import { PhonebookContactEditorLocalService } from './phonebook-contact-editor-local.service';
import { ContactViewMode } from '@webclient/phonebook-editor/contact-view-mode';
import { userTransparentImage } from '@webclient/assets';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'contact-editor',
    templateUrl: 'phonebook-contact-editor.component.html',
    providers: [PhonebookContactEditorLocalService],
})
export class PhonebookContactEditorComponent {
    @Input() mode: ContactViewMode = ContactViewMode.Normal;
    @Input() vm: ContactViewModel = new ContactViewModel(new AppContact('-1', AppContactType.PersonalPhonebook, phonebookBridgeId));

    ContactEditorMode = ContactViewMode;
    userTransparentImage = userTransparentImage;
    public isMcmMode$: Observable<boolean>;

    constructor(public localService: PhonebookContactEditorLocalService, myPhoneService: MyPhoneService) {
        this.isMcmMode$ = myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.isMcmMode$),
            distinctUntilChanged(),
        );
    }

    obtainContact() {
        return this.localService.obtainContact(this.vm, this.mode);
    }
}
