import { switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MyPhoneService } from '../myphone/myphone.service';
import { ModalService } from '../modal/app-modal.service';

import { MyCall } from '../phone/mycall';
import { MyPhoneSession } from '../myphone/myphone-session';
import { SettingsService } from '../settings.service';
import {
    LocalConnectionState,
    OptConnectParticipant,
    RecordingAction,
    RequestControlCallRecording,
    RequestDivertCall,
    RequestJoinCallParticipantsToConference,
    RequestTransferCall,
    ResponseAcknowledge, WebRTCCall,
    WebRTCEndpointSDPState
} from '@myphone';
import { SearchResult } from '../shared/search/search-result';
import {
    combineLatest, Observable, Observer, of
} from 'rxjs';
import { WebRTCControlService } from '../webrtc/webrtc-control.service';
import { dummyMediaDescription } from '../phone/media-description';
import { DialerService } from '@webclient/call/dialer.service';
import { PhoneService } from '@webclient/phone/phone.service';
import { List } from 'immutable';

@Injectable()
export class CallControlService {
    constructor(private myphone: MyPhoneService, private modal: ModalService, private phoneService: PhoneService,
                private dialerService: DialerService,
                private settingsService: SettingsService, private webRTCControlService: WebRTCControlService) {
    }

    private onError(error : any) {
        if (error && error.source instanceof ResponseAcknowledge) {
            switch (error.source.ErrorType) {
                case 42:
                case 2:
                case 9:
            }
        }
        else if (error.message !== 'Invalid connection Id') {
            this.modal.error(error);
        }
    }

    divertToVoicemail(myCall: MyCall) {
        this.myphone.myPhoneSession.pipe(take(1), switchMap(session => {
            const request = new RequestDivertCall();
            request.LocalConnectionId = myCall.localConnectionId;
            request.IsLocal = true;
            request.vmail = true;
            request.Destination = session.myInfo.Number;

            return session.get(request);
        }))
            .subscribe({ error: (err: unknown) => this.onError(err) });
    }

    answer(myCall: MyCall, video: boolean) {
        this.phoneService.answer(myCall, video);
        this.dialerService.setPrimaryCall(myCall.myCallId);
    }

    join(myCall: MyCall) {
        if (!myCall.joinToConnectionId) {
            return;
        }
        this.phoneService.join$(myCall.joinToConnectionId, myCall.localConnectionId, myCall.DeviceID)
            .subscribe({ error: (err: unknown) => this.onError(err) });
    }

    transfer(myCall: MyCall, value: SearchResult) {
        this.settingsService.preprocessPhoneNumber(value.number).pipe(switchMap(phone => {
            const isVoicemail = value.isVoicemail;
            const destination = phone;
            return this.myphone.myPhoneSession.pipe(take(1),
                switchMap(session => {
                    const request = new RequestTransferCall();
                    request.IsLocal = false;
                    request.LocalConnectionId = myCall.localConnectionId;
                    request.Destination = isVoicemail ? session.systemParameters.VmailDialCode + destination : destination;

                    return session.get(request);
                }));
        }))
            .subscribe({ error: (err: unknown) => this.onError(err) });
    }

    private makeConnectedCall(myCall: MyCall, phoneNumber: string) {
        const initConnId = myCall.localConnectionId;
        // First make a call
        return this.phoneService.call$(phoneNumber).pipe(
            switchMap((waitingConnId) =>
                new Observable((observer: Observer<[MyCall, MyCall]>) => this.phoneService.myCalls$.subscribe({
                    next: myCalls => {
                        // Build all connections map
                        const connectionsMap: { [id: number]: MyCall } = myCalls.calls.reduce((result: { [id: number]: MyCall }, item) => {
                            result[item.localConnectionId] = item;
                            return result;
                        }, {});
                        const initConn = connectionsMap[initConnId];
                        let waitingConn;
                        if (waitingConnId instanceof WebRTCCall) { // case of webrtc
                            waitingConn = List(Object.values(connectionsMap)).find(call => call.webRTCId === waitingConnId?.Id);
                        }
                        else { // case of local connection
                            waitingConn = List(Object.values(connectionsMap)).find(call => call.localConnectionId === waitingConnId);
                        }

                        if (!initConn || !waitingConn) {
                            // Connections are not found
                            observer.complete();
                            return;
                        }
                        // Wait a bit more
                        if (waitingConn.state !== LocalConnectionState.Connected) {
                            return;
                        }
                        // Wait a bit more if it's a WebRTC call and it's not yet confirmed
                        if (waitingConn.isWebRTCCall && waitingConn.media.lastWebRTCState.sdpType !== WebRTCEndpointSDPState.WRTCConfirmed) {
                            return;
                        }

                        // It's time to join connections if it's possible
                        // if (waitinConn.isTransferEnabled && initConn.isTransferEnabled)
                        observer.next([initConn, waitingConn]);
                        // Transfer not enabled
                        // observer.complete();
                    },
                    error: (error: unknown) => observer.error(error),
                    complete: () => observer.complete()
                })
                )
            ));
    }

    attTransfer(myCall: MyCall, value: SearchResult) {
        combineLatest([this.settingsService.preprocessPhoneNumber(value.number), this.myphone.myPhoneSession]).pipe(
            take(1),
            switchMap(([number, session]) => this.makeConnectedCall(myCall, value.isVoicemail ? session.systemParameters.VmailDialCode + number : number)),
            take(1),
        ).subscribe({
            next: values => {
                const [initConn, waitinConn] = values;
                this.phoneService.requestAttTransfer(initConn.myCallId, waitinConn.myCallId);
            },
            error: (err: unknown) => this.onError(err)
        });
    }

    conference(myCall: MyCall, value: SearchResult) {
        this.settingsService.preprocessPhoneNumber(value.number).pipe(
            switchMap(phone => this.makeConnectedCall(myCall, phone).pipe(take(1))),
            switchMap(values => {
                const [initConn, waitingConn] = values;

                const waitingReq = new RequestJoinCallParticipantsToConference();
                waitingReq.LocalConnectionId = waitingConn.localConnectionId;
                waitingReq.IsLocal = true;
                waitingReq.Participants = OptConnectParticipant.BOTH_CONNECTIONS;

                if (!waitingConn.isConnCapabilitiesTransfer) {
                    return of('');
                } // We can't transfer to unregistered extension, or IVR

                if (myCall.isConferenceCall) {
                    waitingReq.Participants = OptConnectParticipant.OTHER_CONNECTION;
                    return this.myphone.get(waitingReq).pipe(switchMap(x =>
                        // Need to unhold the call because it will be processed futher
                        (myCall.isWebRTCCall ? this.webRTCControlService.hold(myCall.media, true) : of(true))
                    ));
                }
                else {
                    // No need to unhold if WebRTC because calls will be lost
                    const initReq = new RequestJoinCallParticipantsToConference();
                    initReq.LocalConnectionId = initConn.localConnectionId;
                    initReq.IsLocal = true;
                    initReq.Participants = OptConnectParticipant.OTHER_CONNECTION;
                    return combineLatest([this.myphone.get(initReq), this.myphone.get(waitingReq)]);
                }
            }),)
            .subscribe({ error: (err: unknown) => this.onError(err) });
    }

    sendDtmf(myCall: MyCall, code: string) {
        try {
            this.webRTCControlService.sendDtmf(myCall.media, code);
        }
        catch (error) {
            this.onError(error);
        }
    }

    drop(myCall: MyCall) {
        this.phoneService.drop(myCall);
    }

    video(myCall: MyCall) {
        if (myCall.media !== dummyMediaDescription) {
            this.webRTCControlService.video(myCall.media)
                .subscribe({ error: (err: unknown) => this.onError(err) });
        }
    }

    mute(myCall: MyCall) {
        if (myCall.media !== dummyMediaDescription) {
            this.webRTCControlService.mute(myCall.media, myCall.myCallId);
        }
    }

    hold(myCall: MyCall) {
        this.webRTCControlService.hold(myCall.media, false)
            .subscribe({ error: (err: unknown) => this.onError(err) });
    }

    resume(myCall: MyCall) {
        this.dialerService.setPrimaryCall(myCall.myCallId);
        this.webRTCControlService.hold(myCall.media, true)
            .subscribe({ error: (err: unknown) => this.onError(err) });
    }

    record(myCall: MyCall) {
        this.myphone.myPhoneSession.pipe(take(1),
            switchMap((session: MyPhoneSession) => {
                const request = new RequestControlCallRecording();
                request.LocalConnectionId = myCall.localConnectionId;
                request.IsLocal = true;
                if (myCall.isRecording) {
                    request.RecAction = myCall.isRecordingPaused ? RecordingAction.Resume : RecordingAction.Pause;
                }
                else {
                    request.RecAction = RecordingAction.Start;
                }
                request.Folder = session.myInfo.Number;

                return session.get(request);
            }))
            .subscribe({ error: (err: unknown) => this.onError(err) });
    }
}
