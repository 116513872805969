import { Observable, Observer } from 'rxjs';

/**
 * Optimized version of buffer time buffers only if a number of notifications was received
 * in a specified timeframe.
 * An element is emitted immediately and other elements are delayed for a time frame.
 * In case something is received it is passed and next time frame evaluated.
 * In case nothing is received operator waits for next emission.
 * @param bufferTimeSpan
 */
export const bufferTimeOptimized = (bufferTimeSpan: number) => <T>(source: Observable<T>) =>
    new Observable((notificationObserver: Observer<T[]>) => {
        let timeout: number|undefined;
        let buffer: any[] = [];

        const stopBuffering = () => {
            self.clearInterval(timeout);
            timeout = undefined;
        };

        const startBuffering = () => {
            timeout = self.setInterval(() => {
                if (buffer.length > 0) {
                    // Flush buffer & continue buffering
                    notificationObserver.next(buffer);
                    buffer = [];
                }
                else {
                    // None
                    stopBuffering();
                }
            }, bufferTimeSpan);
        };

        const sourceSubscription = source.subscribe({
            next: value => {
                if (timeout === undefined) {
                    // We're not even buffering
                    notificationObserver.next([value]);
                    startBuffering();
                }
                else {
                    buffer.push(value);
                }
            },
            error: (err: unknown) => {
                stopBuffering();
                notificationObserver.error(err);
            },
            complete: () => {
                stopBuffering();
                notificationObserver.complete();
            }
        });

        return () => {
            stopBuffering();
            sourceSubscription.unsubscribe();
        };
    });
