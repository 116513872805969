import { NgModule } from '@angular/core';

import { InfiniteScrollDirective } from './ng-infinite-scroll';

@NgModule({
    imports: [],
    exports: [InfiniteScrollDirective],
    declarations: [InfiniteScrollDirective],
    providers: [],
})
export class InfiniteScrollModule { }
