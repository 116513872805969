/// <reference types="w3c-web-hid" />

import {
    InputUsage,
    INPUT_USAGES,
    OutputUsages,
    OnOffControlType,
    OutputUsage,
    OUTPUT_USAGES,
    InputUsages,
    UsagePage, OutputEventInfo, InputEventInfo
} from '@webclient/phone/headsets/device-interface';
import { UniversalHeadsetLogTitle } from '@webclient/phone/headsets/webhib-headset.service';
import { YealinkVendorId } from '@webclient/phone/headsets/yealink/yealink-hid.service';

/**
 * Check HID in Navigator
 */
export function hidNavigator() {
    return 'hid' in navigator;
}

/**
 * Check if is a valid HID Device
 * @param device HIDDevice
 * @returns Boolean
 */
export function isValidHIDDevice(device: HIDDevice): boolean {
    return device.productName !== 'HID debug interface' && device.vendorId !== YealinkVendorId;
}

/**
 * Check if device supports requesting Usage HID
 * @param device HIDDevice
 * @param usage UsagePage
 * @returns boolean
 */
export function deviceSupportsRequestingUsage(device: HIDDevice, usage: UsagePage): boolean {
    return device.collections.some(collection => collection.usagePage === usage && (collection.inputReports?.length ?? 0) > 0);
}

/**
 * Get HID Telephony in case of multiple Interfaces
 * @param deviceList
 * @returns {HIDDevice || null }
 */
function getDeviceTelephonyInterface(deviceList: HIDDevice[]) {
    const deviceFound: HIDDevice | undefined = deviceList.find(dev => isValidHIDDevice(dev) && deviceSupportsRequestingUsage(dev, UsagePage.TELEPHONY));
    return deviceFound ?? null;
}

export function getHIDDevice(): Promise<HIDDevice | null> {
    if (hidNavigator()) {
        return navigator.hid
            .getDevices()
            .then(list => getDeviceTelephonyInterface(list));
    }
    else {
        return Promise.resolve(null);
    }
}

/**
 * logs headset events to console
 */
export function logHeadsetEvent(message?: any, ...optionalParams: any[]) {
    const headsetLogEnabled = localStorage.getItem('wc.loggerenabled') === 'true';
    if (headsetLogEnabled) {
        console.log(message, ...optionalParams);
    }
}

export function logHeadsetWarn(message?: any, ...optionalParams: any[]) {
    const headsetLogEnabled = localStorage.getItem('wc.loggerenabled') === 'true';
    if (headsetLogEnabled) {
        console.warn(message, ...optionalParams.map(param => (param instanceof Error ? param.message : param)));
    }
}

/**
 * Open HID Device
 * @param device HIDDevice
 * @returns Promise<HIDDevice>
 */
export function openHidDevice(device: HIDDevice): Promise<HIDDevice> {
    return device.opened ? Promise.resolve(device) : device.open().then(() => device);
}

/**
 * Get Requested Device
 * @returns Promise<HIDDevice>
 */
export function requestHIDDevice(): Promise<HIDDevice | null> {
    if (hidNavigator()) {
        return navigator.hid
            .requestDevice({
                filters: [{
                    usagePage: UsagePage.TELEPHONY
                }]
            }/* { filters: [{ vendorId }] } */)
            .then(list => getDeviceTelephonyInterface(list));
    }
    else {
        return Promise.resolve(null);
    }
}

/**
 * Convert the usage page ID to string.
 * @param {number} usagePage
 * @return {string}
 */
export function usagePageToString(usagePage: number): string {
    const str = UsagePage[usagePage] ?? 'UNKNOWN';
    return `${str}(0x${usagePage.toString(16)
        .padStart(4, '0')})`;
}

/**
 * Cast usage field to human-readable string. This only handle usages we care.
 * @param {number} usage
 * @return {string}
 */
export function usageToString(usage: number): string {
    const usagePage = getUsagePage(usage);
    const usageId = getUsageId(usage);
    const str = OutputUsages[usage] ?? InputUsages[usage] ?? 'UNKNOWN';
    return `${usagePageToString(usagePage)}.${str}(0x${usageId.toString(16).padStart(4, '0')})`;
}

/**
 * Get the usage page ID from usage field.
 * @param {number} usage
 * @return {number} usagePage
 */
export function getUsagePage(usage: number): number {
    return usage >>> 16;
}

/**
 * Get the usage ID from usage field.
 * @param {number} usage
 * @return {number} usageId
 */
export function getUsageId(usage: number): number {
    return usage & 0xffff;
}

/**
 * Checks if input is a telephony input usage
 * @param x
 * @returns { boolean }
 */
export const isTelephonyInputUsage = (x: number): x is InputUsage => INPUT_USAGES.includes(x);

/**
 * Checks if input is a telephony output usage
 * @param x
 */
export const isTelephonyOutputUsage = (x: number): x is OutputUsage => OUTPUT_USAGES.includes(x);

/**
 * Get the type of the on/off control of the given report item.
 * @param {HIDReportItem} item
 * @return {OnOffControlType} type
 */
export function getOnOffControlType(item: HIDReportItem): OnOffControlType {
    if (
        item.isAbsolute === undefined ||
        item.hasPreferredState === undefined ||
        item.logicalMinimum === undefined ||
        item.logicalMaximum === undefined
    ) {
        return OnOffControlType.Undefined;
    }
    if (!item.isAbsolute && !item.hasPreferredState && item.logicalMinimum === -1 && item.logicalMaximum === 1) {
        return OnOffControlType.OnOffButtons;
    }
    if (!item.isAbsolute && item.hasPreferredState && item.logicalMinimum === 0 && item.logicalMaximum === 1) {
        return OnOffControlType.ToggleButton;
    }
    if (item.isAbsolute && !item.hasPreferredState && item.logicalMinimum === 0 && item.logicalMaximum === 1) {
        return OnOffControlType.ToggleSwitch;
    }
    return OnOffControlType.Undefined;
}

/**
 * Get Device Collection
 * @param device HIDDevice
 * @returns HIDCollectionInfo
 */
export function getDeviceCollection(device: HIDDevice) {
    const collection = device.collections.find(info => info.usagePage === UsagePage.TELEPHONY);

    if (collection === undefined) {
        throw new Error('getDeviceCollection: No collection for ' + UsagePage.TELEPHONY);
    }
    return collection;
}

const initialItem = (usage: number, item: HIDReportItem): HIDReportItem => {
    return {
        ...item,
        isRange: false,
        reportCount: 1,
        reportSize: 1,
        usages: [usage],
    };
};

export function updateReportItems(reports: HIDReportInfo[]): HIDReportInfo[] {
    return reports.map(report => ({
        ...report,
        items: report.items?.map(item => {
            const { usageMinimum, usageMaximum } = item;
            if (!item.isRange || !usageMinimum || !usageMaximum || item.reportSize !== 1) {
                return [item];
            }
            return Array.from({ length: item.reportCount! }, (_, i) => initialItem(usageMinimum + i, item));
        }).flat()
    }));
}

/**
 * Helper to parse the input report.
 * @param {HIDReportInfo[]} inputReports The array of input reports.
 */
export function parseInputReport(inputReports: HIDReportInfo[]): Record<InputUsage, InputEventInfo | undefined> {
    const inputEventInfos : Record<InputUsage, InputEventInfo | undefined> = INPUT_USAGES.reduce((record, usage) => {
        return {
            ...record,
            [usage]: undefined
        };
    }, {} as Record<InputUsage, InputEventInfo | undefined>);

    for (const report of inputReports) {
        let offset = 0;
        if (report.items === undefined || report.reportId === undefined) {
            continue;
        }

        for (const item of report.items) {
            const { usageMinimum = 0, usageMaximum = 0 } = item;
            const isRange = item.usages === undefined && item.isRange && usageMinimum && usageMaximum;
            if (
                (item.usages === undefined && !isRange) ||
                item.reportSize === undefined ||
                item.reportCount === undefined ||
                item.isAbsolute === undefined
            ) {
                continue;
            }

            // construct the usages from usageMinimum,usageMaximum if the report item is range
            const itemUsages = item.usages !== undefined
                ? item.usages
                : isRange
                    ? Array.from({ length: usageMaximum - usageMinimum + 1 }, (_, i) => i + usageMinimum)
                    : [];

            // Normally usage count should equal to reportCount
            // but on one of my devices they do not fit. In this case consider they're on the same bit
            const usagesFit = itemUsages.length === item.reportCount;
            for (const [i, usage] of itemUsages.entries()) {
                if (isTelephonyInputUsage(usage)) {
                    inputEventInfos[usage] = {
                        name: usageToString(usage),
                        reportId: report.reportId,
                        offset: offset + ((!isRange && usagesFit) ? i * item.reportSize : 0),
                        size: item.reportSize,
                        // if we have range the value that will enable the usage is a number of size reportSize
                        // if we do not have range but single bit (reportSize = 1) the value that will enable the usage is 1
                        expectedValue: isRange ? i + 1 : 1,
                        controlType: getOnOffControlType(item)
                    };
                }
            }
            offset += item.reportCount * item.reportSize;
        }
    }
    logHeadsetEvent(UniversalHeadsetLogTitle, 'inputUsages', inputEventInfos);
    return inputEventInfos;
}
/**
 * Helper to parse the output report.
 * @param {HIDReportInfo[]} outputReports The array of output reports.
 */
export function parseOutputReport(outputReports: HIDReportInfo[]): Record<OutputUsage, OutputEventInfo | undefined> {
    const outputEventInfos:Record<OutputUsage, OutputEventInfo | undefined> = OUTPUT_USAGES.reduce((record, usage) => {
        return {
            ...record,
            [usage]: undefined
        };
    }, {} as Record<OutputUsage, OutputEventInfo | undefined>);

    for (const report of outputReports) {
        if (report.items === undefined || report.reportId === undefined) {
            continue;
        }

        let offset = 0;
        let outUsageOffsets: Array<[OutputUsage, number]> = [];

        for (const item of report.items) {
            if (item.usages === undefined || item.reportSize === undefined || item.reportCount === undefined) {
                outUsageOffsets = [];
                break;
            }

            // Normally usage count should equal to reportCount
            // but on one of my devices they do not fit. In this case consider they're on the same bit
            const usagesFit = item.usages.length === item.reportCount;
            for (const [i, usage] of item.usages.entries()) {
                if (isTelephonyOutputUsage(usage)) {
                    outUsageOffsets.push([usage, offset + (usagesFit ? i * item.reportSize : 0)]);
                }
            }
            offset += item.reportCount * item.reportSize;
        }

        const length = offset;
        for (const [usageId, usageOffset] of outUsageOffsets) {
            outputEventInfos[usageId] = {
                name: usageToString(usageId),
                offset: usageOffset,
                reportId: report.reportId,
                state: false,
                generator: () => {
                    const reportData = new Uint8Array(length / 8);
                    return { reportId: report.reportId, data: reportData };
                },
                setter: (val: boolean, data: Uint8Array) => {
                    if (usageOffset >= 0) {
                        const byteIndex = Math.trunc(usageOffset / 8);
                        const bitPosition = usageOffset % 8;
                        data[byteIndex] |= (val ? 1 : 0) << bitPosition;
                    }
                    return data;
                }
            } as OutputEventInfo;
        }
    }
    logHeadsetEvent(UniversalHeadsetLogTitle, 'outputUsages', outputEventInfos);
    return outputEventInfos;
}

/**
 * Extract the values received for each InputUsage.
 * @param event HIDInputReportEvent
 * @param inputEventInfos
 * @private
 */
export function extractInputUsageValues(event: HIDInputReportEvent,
    inputEventInfos: Record<InputUsage, InputEventInfo | undefined> | undefined): { usage: InputUsage; isSet: boolean }[] {
    const usageValues: { usage: InputUsage, isSet: boolean }[] = [];
    INPUT_USAGES.forEach(usage => {
        const eventInfo = inputEventInfos?.[usage];
        if (eventInfo !== undefined && event.reportId === eventInfo.reportId) {
            if (eventInfo.offset + eventInfo.size <= event.data.byteLength * 8) {
                usageValues.push({
                    usage,
                    isSet: extractUsageValue(event.data, eventInfo.offset, eventInfo.size) === eventInfo.expectedValue
                });
            }
            else {
                console.warn(UniversalHeadsetLogTitle, 'Miscalculated offset for INPUT_USAGE', usage);
            }
        }
    });
    return usageValues;
}

function extractUsageValue(data: DataView, offset: number, size: number) : number {
    let value = 0;
    for (let index = offset; index < offset + size; index += 1) {
        const byteIndex = Math.trunc(index / 8);
        const bytePos = index % 8;
        value += ((data.getUint8(byteIndex) >> bytePos) & 1) << (index - offset);
    }
    return value;
}
