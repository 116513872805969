import { WtfTranslation } from '@office/reports/helpers';

// For details @see https://mantis.corp.3cx.com/view.php?id=37819

export interface AuditListItem { id: number | undefined; translation: string; searchable: boolean }
export type AuditListItemWithDetails = AuditListItem & {details?: boolean, newDataOnly?: boolean};

export const EmptyItemTypeList: AuditListItem = { id: undefined, translation: '_i18n.AuditCfgItemAny', searchable: true };

export const CfgItemTypeList: Array<AuditListItem> = [
    EmptyItemTypeList,
    { id: 0, translation: '_i18n.AuditCfgItemEmpty', searchable: false },
    { id: 1, translation: '_i18n.AuditCfgItemWrapper', searchable: false },
    { id: 2, translation: '_i18n.AuditCfgItemRoot', searchable: false },
    { id: 3, translation: '_i18n.AuditCfgItemDn', searchable: true },
    { id: 4, translation: '_i18n.AuditCfgItemSpecialDn', searchable: true },
    { id: 5, translation: '_i18n.AuditCfgItemConnection', searchable: false },
    { id: 6, translation: '_i18n.AuditCfgItemExternalAgent', searchable: false },
    { id: 7, translation: '_i18n.AuditCfgItemExtension', searchable: true },
    { id: 8, translation: '_i18n.AuditCfgItemHotdesking', searchable: false },
    { id: 9, translation: '_i18n.AuditCfgItemExtensionForwarding', searchable: false },
    { id: 10, translation: '_i18n.AuditCfgItemExtensionAwayRule', searchable: false },
    { id: 11, translation: '_i18n.AuditCfgItemExtensionAvailableRule', searchable: false },
    { id: 12, translation: '_i18n.AuditCfgItemExtensionExceptionRule', searchable: false },
    { id: 13, translation: '_i18n.AuditCfgItemEmergencyNotificationsSettings', searchable: true },
    { id: 14, translation: '_i18n.AuditCfgItemGroup', searchable: true },
    { id: 15, translation: '_i18n.AuditCfgItemGroupMember', searchable: false },
    { id: 16, translation: '_i18n.AuditCfgItemQueue', searchable: true },
    { id: 17, translation: '_i18n.AuditCfgItemRingGroup', searchable: true },
    { id: 18, translation: '_i18n.AuditCfgItemRoutePoint', searchable: true },
    { id: 19, translation: '_i18n.AuditCfgItemCodec', searchable: false },
    { id: 20, translation: '_i18n.AuditCfgItemUserRights', searchable: false },
    { id: 21, translation: '_i18n.AuditCfgItemDestination', searchable: false },
    { id: 22, translation: '_i18n.AuditCfgItemPhoneVlan', searchable: false },
    { id: 23, translation: '_i18n.AuditCfgItemPhoneLldp', searchable: false },
    { id: 24, translation: '_i18n.AuditCfgItemFax', searchable: true },
    { id: 25, translation: '_i18n.AuditCfgItemIvr', searchable: true },
    { id: 26, translation: '_i18n.AuditCfgItemGateway', searchable: false },
    { id: 27, translation: '_i18n.AuditCfgItemFxsDevice', searchable: true },
    { id: 28, translation: '_i18n.AuditCfgItemFxsLine', searchable: false },
    { id: 29, translation: '_i18n.AuditCfgItemVoipProvider', searchable: false },
    { id: 30, translation: '_i18n.AuditCfgItemTrunk', searchable: true },
    { id: 32, translation: '_i18n.AuditCfgItemInboundRule', searchable: true },
    { id: 33, translation: '_i18n.AuditCfgItemOutboundRule', searchable: true },
    { id: 34, translation: '_i18n.AuditCfgItemResetQueueStatisticsSchedule', searchable: false },
    { id: 35, translation: '_i18n.AuditCfgItemPhoneDevice', searchable: false },
    { id: 36, translation: '_i18n.AuditCfgItemPhonebookEntry', searchable: true },
    { id: 37, translation: '_i18n.AuditCfgItemExtensionEditor', searchable: false },
    { id: 38, translation: '_i18n.AuditCfgItemExternalAccess', searchable: false },
    { id: 39, translation: '_i18n.AuditCfgItemIvrEditor', searchable: false },
    { id: 40, translation: '_i18n.AuditCfgItemBulkExtensionEditor', searchable: false },
    { id: 41, translation: '_i18n.AuditCfgItemBulkExtensionAwayRule', searchable: false },
    { id: 42, translation: '_i18n.AuditCfgItemBulkExtensionAvailableRule', searchable: false },
    { id: 43, translation: '_i18n.AuditCfgItemBulkExtensionForwarding', searchable: false },
    { id: 44, translation: '_i18n.AuditCfgItemPromptSet', searchable: true },
    { id: 45, translation: '_i18n.AuditCfgItemPrompt', searchable: false },
    { id: 46, translation: '_i18n.AuditCfgItemBlacklistEntry', searchable: true },
    { id: 47, translation: '_i18n.AuditCfgItemInboundParameter', searchable: false },
    { id: 48, translation: '_i18n.AuditCfgItemOutboundParameter', searchable: false },
    { id: 49, translation: '_i18n.AuditCfgItemSourceIdParameter', searchable: false },
    { id: 50, translation: '_i18n.AuditCfgItemSettings', searchable: true },
    { id: 51, translation: '_i18n.AuditCfgItemGcmSettings', searchable: true },
    { id: 52, translation: '_i18n.AuditCfgItemNotificationSettings', searchable: true },
    { id: 53, translation: '_i18n.AuditCfgItemNetworkSettings', searchable: true },
    { id: 54, translation: '_i18n.AuditCfgItemCdrSettings', searchable: true },
    { id: 55, translation: '_i18n.AuditCfgItemDirSearchSettings', searchable: false },
    { id: 56, translation: '_i18n.AuditCfgItemLdapSettings', searchable: false },
    { id: 57, translation: '_i18n.AuditCfgItemPhoneBookSettings', searchable: true },
    { id: 58, translation: '_i18n.AuditCfgItemSecuritySettings', searchable: false },
    { id: 59, translation: '_i18n.AuditCfgItemRecordingSettings', searchable: true },
    { id: 60, translation: '_i18n.AuditCfgItemRecordingPathSettings', searchable: true },
    { id: 61, translation: '_i18n.AuditCfgItemE164settings', searchable: true },
    { id: 62, translation: '_i18n.AuditCfgItemOfficeHoursSettings', searchable: true },
    { id: 63, translation: '_i18n.AuditCfgItemConferenceSettings', searchable: true },
    { id: 64, translation: '_i18n.AuditCfgItemMusicOnHoldSettings', searchable: true },
    { id: 65, translation: '_i18n.AuditCfgItemCallParkingSettings', searchable: true },
    { id: 66, translation: '_i18n.AuditCfgItemDialSettings', searchable: true },
    { id: 67, translation: '_i18n.AuditCfgItemVmailSettings', searchable: true },
    { id: 68, translation: '_i18n.AuditCfgItemStringItem', searchable: true },
    { id: 69, translation: '_i18n.AuditCfgItemPhoneBlacklistEntry', searchable: true },
    { id: 70, translation: '_i18n.AuditCfgItemBackupSettings', searchable: true },
    { id: 71, translation: '_i18n.AuditCfgItemLocationSettings', searchable: false },
    { id: 72, translation: '_i18n.AuditCfgItemFailoverSettings', searchable: true },
    { id: 73, translation: '_i18n.AuditCfgItemExternalAccessSettings', searchable: false },
    { id: 74, translation: '_i18n.AuditCfgItemScheduledRestoreSettings', searchable: true },
    { id: 75, translation: '_i18n.AuditCfgItemScheduledBackupSettings', searchable: true },
    { id: 76, translation: '_i18n.AuditCfgItemBlf', searchable: false },
    { id: 77, translation: '_i18n.AuditCfgItemFaxSettings', searchable: true },
    { id: 78, translation: '_i18n.AuditCfgItemClickToCallSettings', searchable: false },
    { id: 79, translation: '_i18n.AuditCfgItemClickToCallDestination', searchable: false },
    { id: 80, translation: '_i18n.AuditCfgItemCidFormatting', searchable: false },
    { id: 81, translation: '_i18n.AuditCfgItemHoliday', searchable: false },
    { id: 82, translation: '_i18n.AuditCfgItemPlaylist', searchable: true },
    { id: 83, translation: '_i18n.AuditCfgItemRouting', searchable: false },
    { id: 84, translation: '_i18n.AuditCfgItemGroupMemberRole', searchable: false },
    { id: 85, translation: '_i18n.AuditCfgItemFixedStringItem', searchable: false },
    { id: 86, translation: '_i18n.AuditCfgItemAutoUpdate', searchable: true },
    { id: 87, translation: '_i18n.AuditCfgItemHotelServices', searchable: true },
    { id: 88, translation: '_i18n.AuditCfgItemCrmIntegration', searchable: true },
    { id: 89, translation: '_i18n.AuditCfgItemHostingModeSettings', searchable: false },
    { id: 90, translation: '_i18n.AuditCfgItemQueueAgent', searchable: false },
    { id: 91, translation: '_i18n.AuditCfgItemIvrForward', searchable: false },
    { id: 92, translation: '_i18n.AuditCfgItemGoogle', searchable: true },
    { id: 93, translation: '_i18n.AuditCfgItemAntiHacking', searchable: true },
    { id: 94, translation: '_i18n.AuditCfgItemAdminCredentials', searchable: false },
    { id: 95, translation: '_i18n.AuditCfgItemConsoleRestrictions', searchable: true },
    { id: 96, translation: '_i18n.AuditCfgItemSecureSip', searchable: true },
    { id: 97, translation: '_i18n.AuditCfgItemAllowedCountryCodes', searchable: true },
    { id: 98, translation: '_i18n.AuditCfgItemTunnel', searchable: false },
    { id: 99, translation: '_i18n.AuditCfgItemExtensionRights', searchable: false },
    { id: 100, translation: '_i18n.AuditCfgItemOptions', searchable: true },
    { id: 101, translation: '_i18n.AuditCfgItemCodecs', searchable: true },
    { id: 102, translation: '_i18n.AuditCfgItemTeamsIntegration', searchable: true },
    { id: 103, translation: '_i18n.AuditCfgItemMessaging', searchable: false },
    { id: 104, translation: '_i18n.AuditCfgItemMessagingTrunk', searchable: false },

    { id: 1000, translation: '_i18n.AuditCfgItemActivityLog', searchable: true },
    { id: 1001, translation: '_i18n.AuditCfgItemEventLog', searchable: true },
    { id: 1002, translation: '_i18n.AuditCfgItemCallHistory', searchable: true },
    { id: 1003, translation: '_i18n.AuditCfgItemChatMessages', searchable: true },
    { id: 1004, translation: '_i18n.AuditCfgItemServices', searchable: true },
    { id: 1005, translation: '_i18n.AuditCfgItemFirewall', searchable: true },
    { id: 1006, translation: '_i18n.AuditCfgItemLicense', searchable: true },
    { id: 1007, translation: '_i18n.AuditCfgItemMessaging', searchable: true },
    { id: 1008, translation: '_i18n.AuditCfgItemReport', searchable: true },
    { id: 1009, translation: '_i18n.AuditCfgItemParameter', searchable: true },
    { id: 1010, translation: '_i18n.AuditCfgItemCallLog', searchable: true },
    { id: 1011, translation: '_i18n.AuditCfgItemWebMeeting', searchable: false },
    { id: 1012, translation: '_i18n.AuditCfgItemOffice365', searchable: true },
    { id: 1013, translation: '_i18n.AuditCfgItemInstanceManager', searchable: false },
    { id: 1014, translation: '_i18n.AuditCfgItemTenantProperty', searchable: true },
    { id: 1015, translation: '_i18n.AuditCfgItemGlobalParameters', searchable: false },
    { id: 1016, translation: '_i18n.AuditCfgItemOperatingSystem', searchable: true },
    { id: 1017, translation: '_i18n.AuditCfgItemPhoneTemplates', searchable: true },
    { id: 1018, translation: '_i18n.AuditCfgItemDectTemplates', searchable: true },
    { id: 1019, translation: '_i18n.AuditCfgItemProviderTemplates', searchable: true },
    { id: 1020, translation: '_i18n.AuditCfgItemEmailTemplates', searchable: true },
    { id: 1021, translation: '_i18n.AuditCfgItemCapture', searchable: true },
    { id: 1022, translation: '_i18n.ScheduledReports', searchable: true },

    { id: 10000, translation: '_i18n.AuditCfgItemAuditSettings', searchable: false }

];

export const EmptyAuditAction: AuditListItemWithDetails = { id: undefined, translation: '_i18n.AuditActionAny', searchable: true };
export const AuditActionList: Array<AuditListItemWithDetails> = [
    EmptyAuditAction,
    { id: 0, translation: '_i18n.AuditActionCreate', searchable: true, details: true, newDataOnly: true },
    { id: 1, translation: '_i18n.AuditActionUpdate', searchable: true, details: true },
    { id: 2, translation: '_i18n.AuditActionDelete', searchable: true, details: true },
    { id: 3, translation: '_i18n.AuditActionPurge', searchable: true, details: true, newDataOnly: true },
    { id: 4, translation: '_i18n.AuditActionStart', searchable: true },
    { id: 5, translation: '_i18n.AuditActionStop', searchable: true },
    { id: 6, translation: '_i18n.AuditActionRestart', searchable: true },
    { id: 7, translation: '_i18n.AuditActionImport', searchable: true, details: true, newDataOnly: true },
    { id: 8, translation: '_i18n.AuditActionEnable', searchable: true },
    { id: 9, translation: '_i18n.AuditActionDisable', searchable: true },
    { id: 10, translation: '_i18n.AuditActionRestore', searchable: true },
    { id: 11, translation: '_i18n.AuditActionLogout', searchable: true },
    { id: 12, translation: '_i18n.AuditActionArchive', searchable: true },
    { id: 13, translation: '_i18n.AuditActionWelcome', searchable: true },
    { id: 14, translation: '_i18n.AuditActionPush', searchable: true },
    { id: 16, translation: '_i18n.AuditActionExport', searchable: true },
    // 15 refresh
    { id: 17, translation: '_i18n.AuditActionReset', searchable: true },
    { id: 18, translation: '_i18n.AuditActionLogin', searchable: true },
    { id: 19, translation: '_i18n.AuditActionClear', searchable: true },
    { id: 20, translation: '_i18n.AuditActionAssign', searchable: true, details: true },
    { id: 21, translation: '_i18n.AuditActionRegenerate', searchable: true, details: true },
    { id: 22, translation: '_i18n.AuditActionToken', searchable: true },
    { id: 23, translation: '_i18n.AuditActionSupport', searchable: true }
];

export const getCfgTypeTranslationKey = (id: number | null | undefined) => {
    const item = CfgItemTypeList.find(item => item.id === id);

    if (item && item.translation) {
        return item.translation;
    }
    else {
        return WtfTranslation;
    }
};

export const getActionTranslationKey = (id: number | null | undefined) => {
    const item = AuditActionList.find(item => item.id === id);

    if (item && item.translation) {
        return item.translation;
    }
    else {
        return WtfTranslation;
    }
};
