export function dataURItoArray(dataURI: any): Uint8Array {
    const binary = window.atob(dataURI.split(',')[1] || '');
    const array = [];
    for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
    }
    return new Uint8Array(array);
}

/**
 * Converts data uri to Blob. Necessary for uploading.
 * @see http://stackoverflow.com/questions/4998908/convert-data-uri-to-file-then-append-to-formdata
 * @param  {String} dataURI
 * @return {Blob}
 */
export function dataURItoBlob(dataURI: string) {
    if (!dataURI) {
        return new Blob();
    }
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    return new Blob([dataURItoArray(dataURI)], { type: mimeString });
}
