import { CalendarServiceType } from '@myphone';
import { VideoConferenceProfileType } from '@webclient/meeting/video-conference-profile-type';

export enum VideoConferenceProfileTypeEnum {
    WebMeeting = 0,
    Webinar = 1
}

export const videoConferenceProfileTypes: VideoConferenceProfileType[] = [
    {
        value: VideoConferenceProfileTypeEnum.WebMeeting,
        name: 'WebMeeting'
    },
    {
        value: VideoConferenceProfileTypeEnum.Webinar,
        name: 'Webinar (Classroom)'
    }];

// todo - apply in conference-editor.component.html
// todo - maybe create a separate type for conferenceCalendar like VideoConferenceProfileType
export const conferenceCalendarTypes: {value: CalendarServiceType, name: string}[] = [
    {
        value: CalendarServiceType.Google,
        name: 'Google'
    },
    {
        value: CalendarServiceType.Office365,
        name: 'Office365'
    },
    {
        value: CalendarServiceType.Outlook,
        name: 'Outlook Online'
    },
    {
        value: CalendarServiceType.IcsFile,
        name: 'iCal'
    },
    {
        value: CalendarServiceType.Tcx,
        name: '3CX (direct eMail)'
    },
];

export const dateFormat = 'YYYY-MM-DD HH:mm:ss';
export const newConferenceUrl = '/conferences/schedule/new'; // '/conferences/new';
export const conferenceBaseUrl = '/conferences';
export const conferenceScheduleBaseUrl = '/conferences/schedule';
export const privateConferenceBaseUrl = '/conferences/schedule/private';
export const audioConferenceBaseUrl = '/conferences/schedule';
export const instantMeetingName = 'Instant Meeting';
