import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { CallBlfComponent } from '@webclient/call-blf/call-blf.component';
import { CallBlfItemComponent } from '@webclient/call-blf/call-blf-item.component';
import { BlfService } from '@webclient/call-blf/blf-service';
import { ActiveCallsService } from '@webclient/switchboard/active-calls/active-calls.service';
import { CallControlService } from '@webclient/call/call-control.service';
import { CallBlfIndicatorComponent } from '@webclient/call-blf/call-blf-indicator.component';
import { ProfileCssClassPipe } from '@webclient/standalones/pipes/profile-css-class.pipe';
import { PagingComponent } from '@webclient/standalones/layout';
import { NameOrderPipe } from '@webclient/shared/pipes/name-order.pipe';

@NgModule({
    declarations: [
        CallBlfComponent,
        CallBlfItemComponent,
        CallBlfIndicatorComponent,
    ],
    exports: [
        CallBlfComponent
    ],
    imports: [
        IconsModule,
        TranslateModule,
        CommonModule,
        ProfileCssClassPipe,
        PagingComponent,
        NameOrderPipe
    ],
    providers: [ActiveCallsService, BlfService, CallControlService]
})
export class BlfModule {
}
