import { Pipe, PipeTransform } from '@angular/core';
import { convertServerErrors } from './convert-server-error.utils';

@Pipe({
    name: 'convertServerError',
    standalone: true
})
export class ConvertServerErrorPipe implements PipeTransform {
    transform(value: string): string {
        return convertServerErrors[value] || value;
    }
}
