import {
    Component, EventEmitter, HostBinding, OnDestroy, Output
} from '@angular/core';
import {
    animate, state, style, transition, trigger
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

@Component({
    selector: 'fullscreen-control-buttons',
    styles: [`
        :host {
            display: flex;
            position: absolute;
            bottom: 50px;
            left: 50%;
            transform: translateX(-50%);
        }

        .fullScreenBtn:hover {
            background-color:rgba(0, 0, 0, 0.9);
        }

        .fullScreenBtn {
            width: 100px;
            height: 100px;
            background-color:rgba(0, 0, 0, 0.3);
            border-radius: 50%;
            border: solid white;
        }
    `],
    template: `
        <button class="fullScreenBtn" style="margin-right: 10px;" (click)="dropClick.emit($event)" type="button">
            <i class="customSVGIcons svg-xl fillIconRed" style="transform: rotate(135deg)" app-phone-alt-solid-icon></i>
        </button>

        <button class="fullScreenBtn" style="margin-left: 10px;" (click)="exitFullscreenClick.emit($event)" type="button">
            <i class="customSVGIcons svg-xl fillIconWhite" app-compress-arrows-alt-regular-icon></i>
        </button>
    `,
    animations: [
        trigger('linearAnimation', [
            state('hidden', style({
                bottom: '-300px'
            })),
            state('open', style({
                bottom: '50px'
            })),
            transition('open => hidden', animate('300ms linear')),
            transition('hidden => open', animate('100ms linear')),
        ]),
    ],
})
export class FullscreenControlButtonsComponent implements OnDestroy {
    @HostBinding('@linearAnimation')
    state = 'open';

    @Output()
    dropClick: EventEmitter<Event> = new EventEmitter();

    @Output()
    exitFullscreenClick: EventEmitter<Event> = new EventEmitter();

    onIdleEndSubscription: Subscription;
    onIdleStartSubscription: Subscription;

    constructor(private idle: Idle) {
        idle.setIdle(3);
        idle.setTimeout(false);
        idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.onIdleEndSubscription = idle.onIdleEnd.subscribe(() => {
            this.state = 'open';
        });
        this.onIdleStartSubscription = idle.onIdleStart.subscribe(() => {
            this.state = 'hidden';
        });

        this.idle.watch();
    }

    ngOnDestroy() {
        this.idle.stop();
        this.onIdleEndSubscription.unsubscribe();
        this.onIdleStartSubscription.unsubscribe();
    }
}
