import {
    Directive, ElementRef, Input, OnChanges, SimpleChanges
} from '@angular/core';

@Directive({
    selector: '[srcObject]'
})
export class SrcObjectDirective implements OnChanges {
    @Input()
    srcObject: any;

    constructor(private el: ElementRef) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.srcObject && this.srcObject !== undefined) {
            this.el.nativeElement.srcObject = this.srcObject;
        }
    }
}
