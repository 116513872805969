import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router, Scroll } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    filter, map, shareReplay, switchMap
} from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RouterTitleService {
    title$: Observable<string>;

    constructor(router: Router, translate: TranslateService) {
        this.title$ = router.events.pipe(
            map(event => (event instanceof NavigationEnd ? event : event instanceof Scroll && event.routerEvent instanceof NavigationEnd ? event.routerEvent : null)),
            filter(Boolean),
            switchMap(event => {
                const state = router.getCurrentNavigation()?.extras.state;
                if (state?.title) {
                    return translate.stream(state?.title);
                }
                else {
                    const title = this.getDeepestTitle(router.routerState.snapshot.root);
                    return title ? translate.stream(title) : of('');
                }
            }),
            shareReplay({ refCount: false, bufferSize: 1 })
        );
    }

    private getDeepestTitle(routeSnapshot: ActivatedRouteSnapshot) {
        let title = routeSnapshot.data ? routeSnapshot.data.title : '';
        if (routeSnapshot.firstChild) {
            title = this.getDeepestTitle(routeSnapshot.firstChild) || title;
        }
        return title;
    }
}
