import {
    combineLatest, Observable, of, throwError, zip
} from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LocalConnectionActions } from './local-connection-actions';
import { ActiveCallInfo } from '../../myphone/active-calls/active-call-info';
import { MyPhoneService } from '../../myphone/myphone.service';
import { ActiveCallActions } from './active-call-actions';
import { extractErrorMessage, ModalService } from '../../modal/app-modal.service';
import { DeviceService, isWebRTCEndpoint } from '../../phone/device.service';
import { SettingsService } from '../../settings.service';
import { SearchResult } from '../../shared/search/search-result';
import {
    BargeInMode,
    ConnectionCapabilities,
    DnType,
    LocalConnection,
    LocalConnectionState,
    RecordingAction,
    RejectAction,
    RequestBargeInCall, RequestCallReport,
    RequestConnectionAccessRights,
    RequestControlCallRecording,
    RequestDivertCall,
    RequestDropCall,
    RequestPickupCall,
    RequestTransferCall
} from '@myphone';
import { LocalConnectionEx } from '../../myphone/local-connection-ex';
import { TranslateService } from '@ngx-translate/core';
import { WebRTCControlService } from '@webclient/webrtc/webrtc-control.service';
import { PhoneService } from '@webclient/phone/phone.service';
import { addConnectionCapabilities } from '@webclient/rx-utils';

@Injectable()
export class ActiveCallsService {
    constructor(private myPhoneService: MyPhoneService,
                private modalService: ModalService,
                private deviceService: DeviceService,
                private settingsService: SettingsService,
                private webRtcService: WebRTCControlService,
                private translate: TranslateService,
                private phoneService: PhoneService
    ) {
    }

    public getCallActions(activeCall: ActiveCallInfo): Observable<ActiveCallActions> {
        return this.myPhoneService.myPhoneSession.pipe(take(1), switchMap(session => {
            const connCapabilities = activeCall.Connections.map(conn => {
                const request = new RequestConnectionAccessRights();
                request.LocalConnectionId = conn.Id;

                return addConnectionCapabilities(of([conn, session]));
            });

            const hasParkAccess$ = session.parkings$.pipe(map(parkings =>
                parkings && parkings.Items && parkings.Items.length > 0
            ));
            // Get all connection capabilities
            return combineLatest([hasParkAccess$, zip(...connCapabilities), this.deviceService.selectedPhoneDeviceAgent$]).pipe(
                map(([hasParkAccess, connections, selectedUserAgent]) => {
                    const actions = new LocalConnectionActions(false, connections, hasParkAccess, session.myInfo.Number);
                    const callActions = new ActiveCallActions();

                    // Pickups
                    callActions.pickupConnection = actions.pickups.length > 0 ? actions.pickups[0] : undefined;

                    // Diverts
                    callActions.divertConnection = actions.diverts.length > 0 ? actions.diverts[0] : undefined;

                    // Call Quality Reports
                    callActions.requestCallReport = actions.callReports.length > 0 ? actions.callReports[0] : undefined;

                    // Conference
                    if (activeCall.RawState === LocalConnectionState.Connected &&
                        actions.transfers.length > 0 &&
                        (activeCall.CalleeType === DnType.Extension || activeCall.CalleeType === DnType.ExternalLine) &&
                        (activeCall.CallerType === DnType.Extension || activeCall.CallerType === DnType.ExternalLine)) {
                        callActions.conferenceCall = activeCall;
                    }
                    else {
                        callActions.conferenceCall = undefined;
                    }

                    // Park & Unpark
                    if (hasParkAccess) {
                        if (actions.unparking.length > 0) {
                            callActions.unparkConnection = actions.unparking[0];
                        }
                        else {
                            callActions.unparkConnection = undefined;
                        }
                        callActions.parkConnections = actions.parking;
                    }
                    else {
                        callActions.unparkConnection = undefined;
                        callActions.parkConnections = [];
                    }

                    // Record
                    const notAllowedCount = actions.transfers.filter(x => x.OwnerType === DnType.Parking || x.OwnerType === DnType.IVR).length;
                    const isQueueCallConnected = true;
                    const queueLocalConnection = actions.transfers.find(x => x.OwnerType === DnType.Queue);
                    if (queueLocalConnection !== undefined) {
                        const queueInfo = Object.values(session.queues).filter(x => x.queueNumber === queueLocalConnection.OwnerDn);
                        if (queueInfo !== undefined) {
                            // TODO find queue call connected
                            // var queueActiveCall = queueInfo.ActiveCalls.FirstOrDefault(x => x.LocalConnection.Id == queueLocalConnection.Id);
                            // if (queueActiveCall != null)
                            // {
                            //     if (queueActiveCall.State != ActiveQueueCallState.Connected)
                            //         isQueueCallConnected = false;
                            // }
                        }
                    }

                    if (actions.transfers.length > 0 && notAllowedCount === 0 && isQueueCallConnected) {
                        const lc = actions.transfers.find(x => x.OwnerDn === session.myInfo.Number);
                        const conn = lc || actions.transfers[0];
                        if (conn) {
                            if (conn.OwnerDn === session.myInfo.Number && !session.myInfo.AllowControlOwnRecordings) {
                                // Can't record own connection
                            }
                            else if (!conn.Recording || conn.RecordingPaused) {
                                callActions.recordConnection = conn;
                            }
                            else if (conn.Recording && !conn.RecordingPaused) {
                                callActions.stopRecordingConnection = conn;
                            }
                        }
                    }

                    // Transfers
                    callActions.transferConnections = actions.transfers;

                    // SY: here is a try to fight against "simplicity" of drop operation.
                    // exclude TryingToTransfer from the list
                    const drops = actions.drops;
                    callActions.dropConnection = drops[0];
                    callActions.bargeInConnection = undefined;
                    callActions.listenConnection = undefined;
                    callActions.whisperConnections = [];
                    if (actions.bargeins.length > 0) {
                        if (actions.bargeins.length === 1 && actions.bargeins[0].OwnerType === DnType.SpecialMenu) {
                            // only listen is available
                            callActions.listenConnection = actions.bargeins[0];
                        }
                        else {
                            callActions.listenConnection = actions.bargeins[0];
                            callActions.bargeInConnection = actions.bargeins[0];
                            callActions.whisperConnections = actions.bargeins;
                        }
                    }

                    return callActions;
                })
            );
        }));
    }

    listen(lc: LocalConnection | undefined) {
        // Should be tested earlier
        if (!lc) {
            return;
        }
        this.doBargeIn(lc, BargeInMode.Listen);
    }

    whisper(lc: LocalConnection) {
        this.doBargeIn(lc, BargeInMode.Whisper);
    }

    bargeIn(lc: LocalConnection | undefined) {
        // Should be tested earlier
        if (!lc) {
            return;
        }
        this.doBargeIn(lc, BargeInMode.BargeIn);
    }

    requestCallReport(lc: LocalConnection | undefined) {
        if (!lc) {
            return;
        }
        const request = new RequestCallReport({ ActiveConnection: lc.Id });
        this.myPhoneService.get(request)
            .pipe(take(1))
            .subscribe({
                error: (error: unknown) => this.modalService.error(error),
            });
    }

    private doBargeIn(lc: LocalConnection, mode: BargeInMode) {
        if ((lc.CallCapabilitiesMask & ConnectionCapabilities.CC_BargeIn) === ConnectionCapabilities.CC_BargeIn) {
            this.deviceService.selectedPhoneDevice$.pipe(
                take(1),
                switchMap((device) => {
                    if (isWebRTCEndpoint(device)) {
                        return this.phoneService.bargeIn$(lc.Id, mode);
                    }
                    else {
                        if (!device?.Contact) {
                            throw new Error('_i18n.PhoneNotConnected');
                        }
                        const request = new RequestBargeInCall({
                            LocalConnectionId: lc.Id,
                            mode,
                            DeviceContact: device.Contact
                        });
                        return this.myPhoneService.get(request);
                    }
                })
            ).subscribe({
                error: (error: unknown) => {
                    if (extractErrorMessage(error) === 'Unspecified') {
                        this.modalService.error('_i18n.OperationCannotBePerformed');
                    }
                    else {
                        this.modalService.error(error);
                    }
                }
            });
        }
    }

    pickup(lc: LocalConnection | undefined) {
        // Should be tested earlier
        if (!lc) {
            return;
        }
        if ((lc.CallCapabilitiesMask & ConnectionCapabilities.CC_Pickup) === ConnectionCapabilities.CC_Pickup) {
            /**
             * New PickUP
             */
            this.deviceService.selectedPhoneDevice$.pipe(take(1), switchMap((device) => {
                if (isWebRTCEndpoint(device)) {
                    return this.phoneService.pickUpNewCall$(lc.Id, lc.OtherPartyCallerId, false);
                }
                else {
                    if (!device?.Contact) {
                        throw new Error('_i18n.PhoneNotConnected');
                    }
                    const request = new RequestPickupCall({
                        EnableCallControl: true,
                        LocalConnectionId: lc.Id,
                        DeviceID: device.Contact
                    });
                    return this.myPhoneService.get(request);
                }
            })).subscribe({ error: (error: unknown) => this.modalService.error(error) });
        }
        else if (lc.OwnerType === DnType.IVR || lc.OwnerType === DnType.SpecialMenu) {
            if ((lc.CallCapabilitiesMask & ConnectionCapabilities.CC_Transfer) === ConnectionCapabilities.CC_Transfer) {
                this.unpark(lc);
            }
        }
        else {
            this.modalService.error('_i18n.PickUpPermissionError');
        }
    }

    divert(lc: LocalConnection | undefined, destination: string, isVoicemail: boolean) {
        // Should be tested earlier
        if (!lc) {
            return;
        }
        if ((lc.CallCapabilitiesMask & ConnectionCapabilities.CC_Divert) === ConnectionCapabilities.CC_Divert) {
            this.settingsService.preprocessPhoneNumber(destination).pipe(switchMap(dest => {
                const request = new RequestDivertCall();
                request.IsLocal = false;
                request.LocalConnectionId = lc.Id;
                request.Destination = dest;
                request.vmail = isVoicemail;
                return this.myPhoneService.get(request);
            })).subscribe({ error: (error: unknown) => this.modalService.error(error) });
        }
        else if (lc.OwnerType === DnType.IVR || lc.OwnerType === DnType.SpecialMenu) {
            this.transfer(lc, destination, isVoicemail);
        }
    }

    transfer(lc: LocalConnection, destination: string, isVoicemail: boolean) {
        if ((lc.CallCapabilitiesMask & ConnectionCapabilities.CC_Transfer) === ConnectionCapabilities.CC_Transfer) {
            this.settingsService.preprocessPhoneNumber(destination).pipe(switchMap(dest =>
                this.myPhoneService.myPhoneSession.pipe(take(1), switchMap(session => {
                    const request = new RequestTransferCall();
                    request.IsLocal = false;
                    request.LocalConnectionId = lc.Id;
                    request.Destination = isVoicemail ? session.systemParameters.VmailDialCode + dest : dest;
                    return session.get(request);
                })))).subscribe({ error: (error: unknown) => this.modalService.error(error) });
        }
    }

    conference(_: ActiveCallInfo) {

    }

    drop(lc: LocalConnection | undefined, rejectAction?: RejectAction) {
        // Should be tested earlier
        if (!lc) {
            return;
        }
        if ((lc.CallCapabilitiesMask & ConnectionCapabilities.CC_Drop) === ConnectionCapabilities.CC_Drop) {
            this.myPhoneService.get(new RequestDropCall({
                IsLocal: false,
                ActionIfRinging: rejectAction ?? RejectAction.RA_Busy,
                LocalConnectionId: lc.Id
            })).subscribe({ error: (error: unknown) => this.modalService.error(error) });
        }
    }

    park(lc: LocalConnection) {
        this.myPhoneService.myPhoneSession.pipe(take(1), switchMap(session => {
            // Park
            const emptyParkingSlot = session.parkings && session.parkings.Items ? session.parkings.Items.find(x =>
                (x.Number.indexOf('SP') === 0) && (x.WaitingCalls === undefined || x.WaitingCalls.Items === undefined || x.WaitingCalls.Items.length === 0)) : undefined;
            if (emptyParkingSlot === undefined) {
                return throwError(() => new Error('_i18n.NoAvailableParkingSlotsFound'));
            }
            const request = new RequestTransferCall();
            request.IsLocal = false;
            request.LocalConnectionId = lc.Id;
            request.Destination = emptyParkingSlot.Number;
            return session.get(request);
        })).subscribe({ error: (error: unknown) => this.modalService.error(error) });
    }

    unpark(lc: LocalConnection | undefined) {
        // Should be tested earlier
        if (!lc) {
            return;
        }
        this.myPhoneService.myPhoneSession.pipe(take(1), switchMap(session => {
            const request = new RequestTransferCall();
            request.IsLocal = false;
            request.LocalConnectionId = lc.Id;
            request.Destination = session.myInfo.Number;

            return session.get(request);
        })).subscribe({ error: (error: unknown) => this.modalService.error(error) });
    }

    record(lc: LocalConnection | undefined) {
        // Should be tested earlier
        if (!lc) {
            return;
        }
        if ((lc.CallCapabilitiesMask & ConnectionCapabilities.CC_Transfer) === ConnectionCapabilities.CC_Transfer && (!lc.Recording || lc.RecordingPaused)) {
            this.myPhoneService.myPhoneSession.pipe(take(1), switchMap(session => {
                const request = new RequestControlCallRecording();
                request.LocalConnectionId = lc.Id;
                request.IsLocal = lc.OwnerDn === session.myInfo.Number;
                request.RecAction = lc.Recording ? RecordingAction.Resume : RecordingAction.Start;
                request.Folder = session.myInfo.Number;

                return session.get(request);
            })).subscribe({ error: (error: unknown) => this.modalService.error(error) });
        }
    }

    stopRecording(lc: LocalConnection | undefined) {
        // Should be tested earlier
        if (!lc) {
            return;
        }
        if ((lc.CallCapabilitiesMask & ConnectionCapabilities.CC_Transfer) === ConnectionCapabilities.CC_Transfer && lc.Recording && !lc.RecordingPaused) {
            this.myPhoneService.myPhoneSession.pipe(take(1), switchMap(session => {
                const request = new RequestControlCallRecording();
                request.LocalConnectionId = lc.Id;
                request.IsLocal = lc.OwnerDn === session.myInfo.Number;
                request.RecAction = RecordingAction.Pause;
                request.Folder = session.myInfo.Number;

                return session.get(request);
            })).subscribe({ error: (error: unknown) => this.modalService.error(error) });
        }
    }

    GetOtherPartyPresentationString(lc: LocalConnectionEx) {
        return lc.OtherPartyPresentationString;
    }

    GetOwnerPresentationString(lc: LocalConnectionEx) {
        return lc.OwnerPresentationString;
    }

    openDivertDialog(lc: LocalConnectionEx | undefined) {
        // Should be tested earlier
        if (!lc) {
            return;
        }
        // this.activeCallInModal = activeCall;

        this.translate.get('_i18n.Divert').pipe(
            switchMap(translated => {
                return this.modalService.findContact(`${translated} ${this.GetOtherPartyPresentationString(lc)}`);
            })
        ).subscribe(value => {
            // this.activeCallInModal = undefined;
            if (value instanceof SearchResult) {
                this.divert(lc, value.number, value.isVoicemail);
            }
        });
    }

    openTransferDialog(lc: LocalConnectionEx) {
        // this.activeCallInModal = activeCall;

        this.translate.get('_i18n.Transfer').pipe(
            switchMap(translated => {
                return this.modalService.findContact(`${translated} ${this.GetOtherPartyPresentationString(lc)}`);
            })
        ).subscribe(value => {
            // this.activeCallInModal = undefined;
            if (value instanceof SearchResult) {
                this.transfer(lc, value.number, value.isVoicemail);
            }
        });
    }
}
