import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, Self, SimpleChanges } from '@angular/core';
import { Blf, BlfType } from '@webclient/standalones/blf-config-editor';
import { Observable, ReplaySubject, switchMap, takeUntil } from 'rxjs';
import { BlfService } from '@webclient/call-blf/blf-service';
import { ParkingInfo } from '@myphone';
import { DestroyService } from '@webclient/services/destroy.service';
import { map } from 'rxjs/operators';
import { AppContact } from '@webclient/myphone/contact';
import { DisplayedProfile } from '@webclient/shared/displayed-profile';
import { Utils } from '@webclient/myphone/join/utils';

@Component({
    providers: [DestroyService],
    selector: 'call-blf-item',
    styleUrls: ['call-blf-item.component.scss'],
    templateUrl: 'call-blf-item.component.html',
    // change parking.WaitingCalls can not be tracked
    changeDetection: ChangeDetectionStrategy.Default
})
export class CallBlfItemComponent implements OnChanges {
    @Input() blf: Blf;
    @Input() index: number;

    @HostBinding('class.blf-enabled')
    blfEnabled = false;

    @HostBinding('class.right-aligned')
    get isRightAligned() {
        return this.index % 2 !== 0;
    }

    get customDialContact(): AppContact {
        const contact = { firstName: this.blf.firstName || '', lastName: this.blf.lastName || '' } as AppContact;

        Utils.onContactNameChanged(contact);
        return contact;
    }

    private readonly blf$ = new ReplaySubject<Blf>();

    readonly BlfType = BlfType;
    readonly contact$: Observable<{ data?: AppContact }>;
    readonly profile$: Observable<DisplayedProfile | undefined>;
    readonly parking$: Observable<{ data?: ParkingInfo }>;

    constructor(public blfService: BlfService, @Self() destroy$: DestroyService) {
        this.contact$ = this.blf$.pipe(switchMap(blf => this.blfService.trackContact$(blf)), map(data => ({ data })));
        this.profile$ = this.blf$.pipe(switchMap(blf => this.blfService.trackProfile$(blf)));
        this.parking$ = this.blf$.pipe(switchMap(blf => this.blfService.trackParking$(blf)), map(data => ({ data })));

        this.blf$
            .pipe(switchMap(blf => blfService.trackEnabled$(blf)), takeUntil(destroy$))
            .subscribe(enabled => {
                // HostBinding to Observable is not supported
                this.blfEnabled = enabled;
            });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.blf) {
            this.blf$.next(changes.blf.currentValue);
        }
    }
}
