import type { FilterValue, SerializedFilter } from '@office/standalones/odata-search/types';
import type { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PbxPeer } from '@xapi';

export interface PbxGroupFilterParams {
    pbxGroup: PbxPeer | null
}

const QUERY_PARAM_PBX_GROUP = 'pbxGroup';

export class PbxGroupFilterValue implements FilterValue<PbxGroupFilterParams> {
    private pbxGroup: PbxGroupFilterParams['pbxGroup'] = null;

    constructor(initialValue?: PbxGroupFilterParams) {
        if (initialValue) {
            this.value = initialValue;
        }
    }

    deserialize(serializedValue: SerializedFilter): FilterValue<PbxGroupFilterParams> {
        return PbxGroupFilterValue.deserialize(serializedValue, this.value);
    }

    static deserialize(serializedValue: SerializedFilter, initialValue?: PbxGroupFilterParams): FilterValue<PbxGroupFilterParams> {
        const value = new PbxGroupFilterValue();

        if (initialValue) {
            value.value = initialValue;
        }

        if (QUERY_PARAM_PBX_GROUP in serializedValue && typeof serializedValue[QUERY_PARAM_PBX_GROUP] === 'object') {
            // case when we set value manually and filter tries to clean it from queue on page load
            value.pbxGroup = serializedValue[QUERY_PARAM_PBX_GROUP] ?? value.pbxGroup;
        }
        return value;
    }

    getDisplayValue(translate: TranslateService): string {
        return this.pbxGroup?.Name ?? 'none';
    }

    getDisplayValueForPrint(translate: TranslateService): string {
        return this.getDisplayValue(translate);
    }

    readonly label = '_i18n.ReportsGroup';

    serialize(): SerializedFilter {
        return this.pbxGroup == null ? {} : { [QUERY_PARAM_PBX_GROUP]: this.pbxGroup };
    }

    validate(): ValidationErrors | null {
        return null;
    }

    get value(): PbxGroupFilterParams {
        return { pbxGroup: this.pbxGroup };
    }

    set value(value: PbxGroupFilterParams) {
        this.pbxGroup = value.pbxGroup;
    }
}
