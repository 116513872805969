export enum AppContactType {
    Extension = 0,
    CompanyPhonebook = 1,
    PersonalPhonebook = 2,
    SystemExtension = 3,
    External
}

export const localBridgeId = 'local';
export const phonebookBridgeId = 'phonebook';
export const externalBridgeId = 'external';
export const anonymousBridgeId = 'webrtc';

export const localLiveChatProviderType = anonymousBridgeId;
export const messagingLiveChatProviderType = 'livechat';
export const messengerProviderType = 'messenger';
export const smsProviderType = 'sms';
