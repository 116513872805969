<field-wrapper
    [label]="label"
    [labelFor]="labelToInputLinkId"
    [inlineLabel]="inlineLabel"
    [inlineLabelAuto]="inlineLabelAuto"
    [disabled]="disabled"
    [required]="isRequired"
    [description]="description"
    [hint]="hint">
    <div class="input-group">
        <ng-content select="input[hidden]"></ng-content>
        <input
            #inputElement
            data-qa="input"
            class="form-control"
            [class.text-truncate]="readonly"
            [type]="type"
            [id]="labelToInputLinkId"
            [maxlength]="maxlength"
            [minlength]="minlength"
            [autocomplete]="autocomplete"
            [name]="name"
            [disabled]="disabled"
            [readonly]="readonly || noManualInput"
            [placeholder]="(indeterminate ? indeterminatePlaceholder : placeholder || '') | translate"
            [ngModel]="value"
            (ngModelChange)="valueChanged($event)"
            (keydown)="preventInvalidInput($event)"
            (paste)="onPaste($event)"
            (focus)="onFocus()"
            (blur)="onBlur($event)">
        <ng-content select=".input-group-append"></ng-content>
        <ng-content select="button"></ng-content>
        <button *ngIf="inlineBtnIcon" class="btn btn-border" type="button" (click)="inlineBtnClick()" data-qa="btn"
                [disabled]="disabled || inlineButtonDisabled">
            <show-svg-images class="svg-sm" [svg]="inlineBtnIcon"></show-svg-images>
        </button>
    </div>
    <ng-container ngProjectAs="val-errors">
        <ng-content select="val-errors,show-error-warning"></ng-content>
    </ng-container>
    <ng-container ngProjectAs="[description]">
        <ng-content select="[description]"></ng-content>
    </ng-container>
</field-wrapper>
