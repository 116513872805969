export enum OverrideHoursTimeout {
    M15 = 1,
    M30 = 2,
    H1 = 3,
    H2 = 4,
    H12 = 5,
    D1 = 6,
    D2 = 7,
    W1 = 8,
    W2 = 9,
    W3 = 10,
    M1 = 11
}
