import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Data, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
    filter, map, startWith, switchMap
} from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';
import headerHelpImage from 'img/help.png';

const DefaultUri = 'https://www.3cx.com/user-manual/web-client/';

@Component({
    selector: 'app-help-header',
    template: `
        <a class="header-button themify" [href]="sectionTitle$|async|translate" target="_blank" title="{{'_i18n.help'|translate}}" data-id="helpBtn">
            <img [src]="headerHelpImage">
        </a>
    `,
})
export class HelpHeaderComponent implements OnInit {
    headerHelpImage = headerHelpImage;

    sectionTitle$: Observable<any>;

    constructor(private route: ActivatedRoute, private router: Router, private translate: TranslateService) {
    }

    ngOnInit(): void {
        this.sectionTitle$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            // take the helpUri from the most nested activated route that has helpUri
            switchMap(() =>
                combineLatest([
                    ...this.route.children.length > 0 ?
                        this.activatedRoutesSequence(this.route).reverse().map((child) => child.data)
                        : [of({} as Data)]
                ])),
            map((childDataArray) => childDataArray.find(data => !!data.helpUri)?.helpUri ?? DefaultUri),
            startWith(this.activatedRouteSnapshotsSequence(this.route.snapshot).reverse().find(child => !!child.data.helpUri)?.data.helpUri ?? DefaultUri),
        );
    }

    private activatedRoutesSequence(route: ActivatedRoute) : ActivatedRoute[] {
        const activatedRoutes: ActivatedRoute[] = [];
        let currentRoute = route;

        while (currentRoute.firstChild) {
            activatedRoutes.push(currentRoute.firstChild);
            currentRoute = currentRoute.firstChild;
        }
        return activatedRoutes;
    }

    private activatedRouteSnapshotsSequence(routeSnapshot: ActivatedRouteSnapshot) : ActivatedRouteSnapshot[] {
        const activatedRouteSnapshots: ActivatedRouteSnapshot[] = [];
        let currentRoute = routeSnapshot;

        while (currentRoute.firstChild) {
            activatedRouteSnapshots.push(currentRoute.firstChild);
            currentRoute = currentRoute.firstChild;
        }
        return activatedRouteSnapshots;
    }
}
