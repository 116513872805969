import { HeadsetContact, HeadsetInterface } from '@webclient/phone/headsets/headset-interface';
import { HeadsetDiagnostics } from '@webclient/phone/headsets/headset-diagnostics';
import { HeadsetCallAction, HeadsetCallCommand } from '@webclient/phone/headsets/headset-call-command';
import {
    catchError,
    defer,
    forkJoin,
    from,
    fromEvent,
    merge,
    Observable,
    of,
    ReplaySubject,
    retry,
    share,
    Subject,
    switchMap,
    throwError
} from 'rxjs';
import { distinctUntilChanged, filter, map, mergeScan, startWith, take, tap } from 'rxjs/operators';
import { HeadsetCallState } from '@webclient/phone/headsets/headset-call-state';
import {
    HidConnectionAction,
    InputEventInfo,
    InputUsage,
    InputUsages,
    OUTPUT_USAGES,
    OutputEventData,
    OutputEventInfo,
    OutputUsage,
    OutputUsages,
    UsagePage
} from './device-interface';
import {
    deviceSupportsRequestingUsage,
    extractInputUsageValues,
    getDeviceCollection,
    getHIDDevice,
    isValidHIDDevice,
    logHeadsetEvent,
    logHeadsetWarn,
    openHidDevice,
    parseInputReport,
    parseOutputReport,
    requestHIDDevice,
    updateReportItems
} from '@webclient/phone/headsets/device-helpers';
import { notEmpty } from '@webclient/phone/phone.helpers';

export const UniversalHeadsetLogTitle = 'Universal Headset -';
const UniversalHeadsetCommandTimeoutMs = 5000;

interface MyDevice {
    hookState: boolean;
    device: HIDDevice;
    inputEventInfos: Record<InputUsage, InputEventInfo | undefined> | undefined;
    outputEventInfos: Record<OutputUsage, OutputEventInfo | undefined> | undefined;
    commandMode: boolean;
}

/**
 * Parsing device descriptors for collections.
 * @param device HIDDevice
 * @private
 */
function createMyDevice(device: HIDDevice): MyDevice {
    logHeadsetEvent(UniversalHeadsetLogTitle, device.productName + ' device collections:', device.collections);
    if (device.collections === undefined) {
        throw new Error('getDeviceCollection: Undefined device collection');
    }

    const telephonyCollection = getDeviceCollection(device);
    let inputEventInfos: Record<InputUsage, InputEventInfo | undefined>|undefined;
    if (telephonyCollection.inputReports) {
        inputEventInfos = parseInputReport(telephonyCollection.inputReports);
    }

    let outputEventInfos: Record<OutputUsage, OutputEventInfo | undefined>|undefined;
    if (telephonyCollection.outputReports) {
        outputEventInfos = parseOutputReport(updateReportItems(telephonyCollection.outputReports));
    }
    // Check if all reports are on the same reportId and if a usage is undefined remove it
    const commandMode = new Set(
        Object.values(outputEventInfos ?? {})
            .map(info => info?.reportId)
            .filter((reportId) => reportId !== undefined)
    ).size > 1;
    logHeadsetEvent(UniversalHeadsetLogTitle, `Command Mode is ${commandMode ? 'enabled' : 'disabled'}`);

    return {
        hookState: false,
        device,
        commandMode,
        inputEventInfos,
        outputEventInfos
    };
}

export class WebhidHeadsetService implements HeadsetInterface {
    diagnostics$: Observable<HeadsetDiagnostics>;
    headsetEvents$: Observable<HeadsetCallCommand>;
    private readonly device$: Observable<MyDevice | null>;
    private readonly requestedDevice$: Subject<HIDDevice|null> = new Subject<HIDDevice | null>();
    private readonly callState = new HeadsetCallState();

    constructor() {
        const initialize$ = defer(() => getHIDDevice()).pipe(
            map((device): HidConnectionAction => ({ type: 'init', device })),
        );

        const deviceRequest$ = this.requestedDevice$.pipe(
            filter((device) => !!device && isValidHIDDevice(device)),
            map((device): HidConnectionAction => ({ type: 'request', device })),
        );

        const deviceConnect$ = fromEvent<HIDConnectionEvent>(navigator.hid, 'connect').pipe(
            map((event): HidConnectionAction => ({ type: 'connect', device: event.device })),
        );

        const deviceDisconnect$ = fromEvent<HIDConnectionEvent>(navigator.hid, 'disconnect').pipe(
            filter((event) => !!event.device && isValidHIDDevice(event.device)),
            map((event): HidConnectionAction => ({ type: 'disconnect', device: event.device }))
        );

        this.device$ = merge(initialize$, deviceRequest$, deviceConnect$, deviceDisconnect$).pipe(
            mergeScan((device: HIDDevice|null, connectionAction: HidConnectionAction) => {
                if (connectionAction.type === 'init') {
                    logHeadsetEvent(UniversalHeadsetLogTitle, 'device init:', connectionAction.device?.productName);
                    return of(connectionAction.device);
                }
                else if (connectionAction.type === 'request') {
                    logHeadsetEvent(UniversalHeadsetLogTitle, 'device paired:', connectionAction.device?.productName);
                    return connectionAction.device ? of(connectionAction.device) : device ? of(device) : from(getHIDDevice());
                }
                else if (connectionAction.type === 'connect' && !device) {
                    logHeadsetEvent(UniversalHeadsetLogTitle, 'device connected:', connectionAction.device?.productName);
                    return of(connectionAction.device);
                }
                else if (connectionAction.type === 'disconnect' && connectionAction.device === device) {
                    logHeadsetEvent(UniversalHeadsetLogTitle, 'device disconnected:', connectionAction.device?.productName);
                    // check if there are other available devices
                    return from(getHIDDevice());
                }
                return of(device);
            }, null, 1),
            distinctUntilChanged(),
            switchMap((device) => {
                if (device) {
                    if (!deviceSupportsRequestingUsage(device, UsagePage.TELEPHONY)) {
                        return throwError(() => new Error(`Device "${device.productName}" does not support Standard telephony page (${UsagePage.TELEPHONY}).`));
                    }
                    else if (!isValidHIDDevice(device)) {
                        return throwError(() => new Error(`Device "${device.productName}" is not a a supported HID device.`));
                    }
                    // open device if necessary
                    else if (!device.opened) {
                        logHeadsetEvent(UniversalHeadsetLogTitle, 'device opening:', device.productName);
                        return from(openHidDevice(device));
                    }
                }
                return of(device);
            }),
            map((device) =>
                (device ? createMyDevice(device) : null)
            ),
            catchError((error: unknown) => {
                logHeadsetWarn(UniversalHeadsetLogTitle, error);
                throw error;
            }),
            retry({ delay: UniversalHeadsetCommandTimeoutMs }),
            share({ connector: () => new ReplaySubject<MyDevice|null>(1) }),
        );

        this.headsetEvents$ = this.device$.pipe(
            filter(notEmpty),
            switchMap((myDevice) =>
                fromEvent<HIDInputReportEvent>(myDevice.device, 'inputreport').pipe(
                    switchMap((event: HIDInputReportEvent) => this.onInputReport(myDevice, event, extractInputUsageValues(event, myDevice.inputEventInfos))),
                    // finalize(() => {
                    //     logHeadsetEvent(UniversalHeadsetLogTitle, 'device closing:', myDevice.device.productName);
                    //     myDevice.device.close().catch(() => {
                    //         // Ignore here
                    //     });
                    // })
                )
            ),
            tap((command) => logHeadsetEvent(UniversalHeadsetLogTitle, `Headset Command Fired ${HeadsetCallAction[command.Action]} (Value:${command.Value}) with CallId ${command.CallId}`))
        );

        this.diagnostics$ = this.device$.pipe(
            map((myDevice) => {
                if (myDevice) {
                    const deviceStatus = `${myDevice.device.productName} ${myDevice.device.opened ? 'connected' : 'not connected'}`;
                    return new HeadsetDiagnostics({ alertClass: 'success', text: deviceStatus });
                }
                else {
                    return HeadsetDiagnostics.error('No device is connected');
                }
            }),
            startWith(HeadsetDiagnostics.Progress),
        );
    }

    connectDevice(): void {
        defer(() => requestHIDDevice()).pipe(
            take(1)
        ).subscribe({
            next: (device) => this.requestedDevice$.next(device)
        });
    }

    holdCall(callId: number): void {
        logHeadsetEvent(UniversalHeadsetLogTitle, `pbx call command: holdCall (${callId})`);
        this.callState.holdCalls.add(callId);
        const isHoldingActiveCall = callId === this.callState.activeCall;
        if (isHoldingActiveCall) {
            // We're holding now active call
            this.callState.activeCall = HeadsetCallState.NO_CALL;
            this.callState.isActiveCallMuted = false;
        }

        this.synchronizeState([
            [OutputUsages.OFF_HOOK, this.callState.activeCall !== HeadsetCallState.NO_CALL],
            [OutputUsages.HOLD, true]
        ]);
    }

    incomingCall(callId: number, contact: HeadsetContact): void {
        logHeadsetEvent(UniversalHeadsetLogTitle, `pbx call command: incomingCall (${callId})`);
        this.callState.ringingCalls.add(callId);
        this.callState.callCount += 1;
        this.synchronizeState([
            [OutputUsages.RING, true],
            [OutputUsages.RINGER, true],
        ]);
    }

    incomingCallAccepted(callId: number): void {
        logHeadsetEvent(UniversalHeadsetLogTitle, `pbx call command: incomingCallAccepted (${callId})`);
        this.callState.activeCall = callId;
        this.callState.isActiveCallMuted = false;
        this.callState.ringingCalls.delete(callId);

        this.synchronizeState([
            [OutputUsages.RING, this.callState.hasRingingCalls],
            [OutputUsages.RINGER, this.callState.hasRingingCalls],
            [OutputUsages.OFF_HOOK, true],
            [OutputUsages.MUTE, false],
        ]);
    }

    mute(callId: number, isMuted: boolean): void {
        logHeadsetEvent(UniversalHeadsetLogTitle, `pbx call command mute (${callId}) with value ${isMuted}`);
        this.callState.isActiveCallMuted = isMuted;
        this.synchronizeState([
            [OutputUsages.MUTE, isMuted],
        ]);
    }

    outgoingCall(callId: number, contact: HeadsetContact): void {
        logHeadsetEvent(UniversalHeadsetLogTitle, `pbx call command: outgoingCall (${callId})`);

        this.callState.callCount += 1;
        this.callState.activeCall = callId;
        this.callState.isActiveCallMuted = false;

        this.synchronizeState([
            [OutputUsages.OFF_HOOK, true],
            [OutputUsages.MUTE, false]
        ]);
    }

    outgoingCallAccepted(callId: number): void {
        logHeadsetEvent(UniversalHeadsetLogTitle, `pbx call command: outgoingCallAccepted (${callId})`);
    }

    resumeCall(callId: number): void {
        logHeadsetEvent(UniversalHeadsetLogTitle, `pbx call command: resumeCall (${callId})`);
        this.callState.holdCalls.delete(callId);
        this.callState.activeCall = callId;
        this.synchronizeState([
            [OutputUsages.OFF_HOOK, true],
            [OutputUsages.HOLD, false],
            [OutputUsages.MUTE, false]
        ]);
    }

    terminateCall(callId: number): void {
        logHeadsetEvent(UniversalHeadsetLogTitle, `pbx call command: terminateCall (${callId})`);

        this.callState.ringingCalls.delete(callId);
        this.callState.holdCalls.delete(callId);
        this.callState.callCount--;

        const terminateActiveCall = this.callState.activeCall === callId;
        // Reset all states
        if (terminateActiveCall) {
            this.callState.activeCall = HeadsetCallState.NO_CALL;
            this.callState.isActiveCallMuted = false;
        }
        this.synchronizeState(this.buildFullState());
    }

    private buildFullState():([OutputUsage, boolean])[] {
        return [
            // 1. No Calls OR
            // 2.There is no active call OR
            // 3. After termination of a call all calls are on hold
            [OutputUsages.OFF_HOOK, !(this.callState.noCalls ||
                this.callState.activeCall === HeadsetCallState.NO_CALL ||
                this.callState.callCount === this.callState.holdCalls.size)],
            [OutputUsages.RING, this.callState.hasRingingCalls],
            [OutputUsages.RINGER, this.callState.hasRingingCalls],
            [OutputUsages.MUTE, this.callState.isActiveCallMuted],
            [OutputUsages.HOLD, this.callState.hasHoldCalls]
        ];
    }

    private synchronizeState(actions: ([OutputUsage, boolean])[]) {
        return this.device$.pipe(
            take(1),
            switchMap(myDevice => {
                if (!myDevice || !myDevice.device.opened) {
                    throw new Error('No device is connected');
                }
                const usages = myDevice.commandMode ? [...actions] : this.buildFullState();
                const ringing = usages.find(usage => usage[0] === OutputUsages.RING);
                const offHook = usages.find(usage => usage[0] === OutputUsages.OFF_HOOK);
                const hold = usages.find(usage => usage[0] === OutputUsages.HOLD);
                // ringing workaround
                if (offHook?.[1] &&
                    ringing?.[1] &&
                    !myDevice.outputEventInfos?.[OutputUsages.OFF_HOOK]?.state &&
                    myDevice.outputEventInfos?.[OutputUsages.RING]?.state) {
                    logHeadsetEvent(UniversalHeadsetLogTitle, 'Ringing workaround in effect');
                    // On device, we have offhook=false,ringing=true, and we want to go offhook=true, ringing=true
                    // Workaround: we need to drop ringing first
                    return this.send$(myDevice, myDevice.commandMode ?
                        [[OutputUsages.RING, false], [OutputUsages.RINGER, false]] :
                        usages.map(usage => (usage[0] === OutputUsages.RING ? [OutputUsages.RING, false] : usage[0] === OutputUsages.RINGER ? [OutputUsages.RINGER, false] : usage)))
                        .pipe(
                            // Usages contains already ringing=true, offhook=true
                            switchMap(() => this.send$(myDevice, usages))
                        );
                }
                // hold workaround
                else if (!offHook?.[1] &&
                    hold?.[1] &&
                    myDevice.outputEventInfos?.[OutputUsages.OFF_HOOK]?.state &&
                    !myDevice.outputEventInfos?.[OutputUsages.HOLD]?.state) {
                    logHeadsetEvent(UniversalHeadsetLogTitle, 'Hold workaround in effect');
                    // We were on call, and we hold which means on device we have offhook=true, hold=false, and we want to go offhook=false , hold= true
                    // We need to implement it in 2 steps: offhook=false and then hold=true
                    return this.send$(myDevice, myDevice.commandMode ?
                        [[OutputUsages.HOLD, false]] :
                        usages.map(usage => (usage[0] === OutputUsages.HOLD ? [OutputUsages.HOLD, false] : usage)))
                        .pipe(
                            // Usages contains hold=true
                            switchMap(() => this.send$(myDevice, usages))
                        );
                }
                // resume workaround
                else if (offHook?.[1] &&
                    !hold?.[1] &&
                    !myDevice.outputEventInfos?.[OutputUsages.OFF_HOOK]?.state &&
                    myDevice.outputEventInfos?.[OutputUsages.HOLD]?.state) {
                    logHeadsetEvent(UniversalHeadsetLogTitle, 'Resume workaround in effect');
                    // We were on hold, and we resume which means on device we have offhook=false, hold=true, and we want to go offhook=true , hold= false
                    // We need to implement it in 2 steps: hold=false and then offhook=true
                    return this.send$(myDevice, myDevice.commandMode ?
                        [[OutputUsages.OFF_HOOK, false]] :
                        usages.map(usage => (usage[0] === OutputUsages.OFF_HOOK ? [OutputUsages.OFF_HOOK, false] : usage)))
                        .pipe(
                            // Usages contains offhook=true
                            switchMap(() => this.send$(myDevice, usages))
                        );
                }
                else {
                    // Just send normally
                    return this.send$(myDevice, usages);
                }
            })
        ).subscribe({
            next: () => {
            },
            error: (err: unknown) => {
                logHeadsetWarn(UniversalHeadsetLogTitle, err);
            },
        });
    }

    /**
     * Send Method for Device.
     * Used to send usages to the device
     * @param myDevice
     * @param usages
     */
    private send$(myDevice: MyDevice, usages: ([OutputUsage, boolean])[]) {
        const outputReports: Array<OutputEventData> = [];

        for (const [usage, val] of usages) {
            const eventInfo = myDevice.outputEventInfos?.[usage];
            if (eventInfo === undefined) {
                continue;
            }

            const existingReport = outputReports.find(report => report.reportId === eventInfo?.reportId);
            if (existingReport === undefined) {
                outputReports.push(eventInfo?.generator());
            }
            eventInfo.state = val;
        }
        return forkJoin(outputReports.map(report => {
            const outputUsagesSend: { usage: OutputUsage; isSet: boolean }[] = [];
            OUTPUT_USAGES.forEach(usage => {
                const eventInfo = myDevice.outputEventInfos?.[usage];
                if (eventInfo !== undefined && eventInfo.reportId === report.reportId) {
                    outputUsagesSend.push({ usage, isSet: eventInfo?.state ?? false });
                    if (eventInfo.state) {
                        report.data = eventInfo.setter(eventInfo.state, report.data);
                    }
                }
            });

            return from(myDevice.device.sendReport(report.reportId, report.data)).pipe(
                tap(() => {
                    logHeadsetEvent(UniversalHeadsetLogTitle,
                        `Send Actions to Headset on report ${report.reportId}:`,
                        outputUsagesSend.map(
                            (outputUsageValue) => `${OutputUsages[outputUsageValue.usage]} (0x${outputUsageValue.usage.toString(16).padStart(2, '0')}) : ${outputUsageValue.isSet}`
                        ).join(', ')
                    );
                    logHeadsetEvent(UniversalHeadsetLogTitle, 'Send report with', `reportId: ${report.reportId} `, `data: ${report.data}`);
                })
            );
        }));
    }

    /**
     * Get reporting for event inputs
     * @param myDevice
     * @param event HIDInputReportEvent
     * @param inputUsageValuesReceived
     * @private
     */
    private onInputReport(myDevice: MyDevice,
        event: HIDInputReportEvent,
        inputUsageValuesReceived: { usage: InputUsage; isSet: boolean }[]): HeadsetCallCommand[] {
        if (inputUsageValuesReceived.length > 0) {
            logHeadsetEvent(UniversalHeadsetLogTitle,
                `Received states on report id ${event.reportId}:`,
                inputUsageValuesReceived.map(
                    (headsetState) => `${InputUsages[headsetState.usage]} (0x${headsetState.usage.toString(16).padStart(2, '0')}) : ${headsetState.isSet}`).join(', ')
            );
        }

        const hasCall = this.callState.activeCall !== HeadsetCallState.NO_CALL;
        const ringingCallId = this.callState.ringingCalls.values().next()?.value;
        return inputUsageValuesReceived.map(({ usage, isSet }) => {
            switch (usage) {
                case InputUsages.HOOK_SWITCH:
                    if (myDevice.hookState !== isSet) {
                        // Store the new state
                        myDevice.hookState = isSet;
                        // Hook state was altered
                        if (isSet) {
                            if (ringingCallId !== undefined) {
                                return [{ CallId: ringingCallId, Action: HeadsetCallAction.AcceptCall }];
                            }
                            // applicable only for yealink (for future)
                            // else if (!hasCall) {
                            //     return [{ CallId: -1, Action: HeadsetCallAction.MakeCall }];
                            // }
                        }
                        else if (hasCall) {
                            return [{ CallId: this.callState.activeCall, Action: HeadsetCallAction.TerminateCall }];
                        }
                    }

                    break;
                case InputUsages.PHONE_MUTE:
                    if (hasCall && isSet) {
                        // Mute is toggled on isSet
                        return [{ CallId: this.callState.activeCall, Action: HeadsetCallAction.ToggleMuteCall }];
                    }
                    break;
                // case TelephonyUsage.DROP:
                //     if (isSet) {
                //         if (ringingCallId !== undefined) {
                //             this.callState.callPendingTermination = ringingCallId;
                //             return [{ CallId: ringingCallId, Action: HeadsetCallAction.RejectCall }];
                //         }
                //         else if (hasCall) {
                //             this.callState.callPendingTermination = this.callState.activeCall;
                //             return [{ CallId: this.callState.activeCall, Action: HeadsetCallAction.TerminateCall }];
                //         }
                //     }
                //     break;
                case InputUsages.PROGRAMMABLE_TELEPHONY_BUTTON:
                case InputUsages.PROGRAMMABLE_BUTTON:
                    // Jabra & Yealink reports double click like this
                    if (isSet && ringingCallId !== undefined) {
                        return [{ CallId: ringingCallId, Action: HeadsetCallAction.RejectCall }];
                    }
                    break;
                case InputUsages.FLASH:
                    if (isSet) {
                        if (ringingCallId !== undefined) {
                            return [{
                                CallId: ringingCallId,
                                Action: HeadsetCallAction.AcceptCall
                            }];
                        }
                        else {
                            return [{ CallId: -1, Action: HeadsetCallAction.FlashCall }];
                        }
                    }
                    break;
            }
            return [];
        }).flat();
    }
}
