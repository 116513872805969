import { Group } from './group';
import { Bridge } from './bridge';
import { AppContactType, phonebookBridgeId } from './app-contact-type';

export const companyGroupId = String(AppContactType.CompanyPhonebook);
export const personalGroupId = String(AppContactType.PersonalPhonebook);

export class Phonebook extends Bridge {
    public readonly companyPhonebook: Group;
    public readonly personalPhonebook: Group;

    constructor() {
        super(phonebookBridgeId);

        this.companyPhonebook = new Group(companyGroupId, this);
        this.companyPhonebook.name = '_i18n.CompanyPhonebook';
        this.groupsMap[companyGroupId] = this.companyPhonebook;

        this.personalPhonebook = new Group(personalGroupId, this);
        this.personalPhonebook.name = '_i18n.PersonalPhonebook';
        this.groupsMap[personalGroupId] = this.personalPhonebook;
    }
}
