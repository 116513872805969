import { distinctUntilChanged, filter, map, skip, startWith, switchMap } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { AppState } from '../../app.service';
import { NavigationEnd, Router } from '@angular/router';
import { ModalService } from '../../modal/app-modal.service';
import { MyPhoneService } from '../../myphone/myphone.service';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { isChrome, isEdge, isMacOS, isWindows } from '../../browser-type';
import { LocalStorage } from 'ngx-webstorage';
import {
    defaultContactsQueryParams,
    defaultTeamLink,
    LocalStorageKeys
} from '../../settings/local-storage-keys';
import { MeetingListService } from '@webclient/meeting/meeting-list/meeting-list.service';
import { conferenceBaseUrl } from '@webclient/meeting/meeting-consts';
import { UnreadNotificationsService } from '@webclient/layout/unread-notifications.service';
import { publishRef } from '@webclient/rx-share-utils';
import { ExtendedSwPushService } from '@webclient/notifications/extended-sw-push.service';
import { AppsUtilityService } from '@webclient/services/apps-utility.service';
import { TokenInfoService } from '@webclient/auth/token-info.service';
import { hasPermission } from '@webclient/notifications/notification-funcs';
import { NotificationsHelpDialogComponent } from './notifications-help-dialog.component';
import { areSetsEqual, McmGroupService } from '@webclient/auth/mcm-group.service';
import { RoleId } from '@office/users/user-general/roles';

@Component({
    selector: 'nav',
    styles: [`
        .nav-image-wrapper .badge {
            position: absolute;
            top: 0;
            right: 5px;
        }
    `],
    templateUrl: 'nav.component.html'
})
export class NavComponent implements OnDestroy {
    @LocalStorage(LocalStorageKeys.SaveContactsQueryParams, defaultContactsQueryParams)
    contactsQueryParams: string;

    @LocalStorage(LocalStorageKeys.SavePeople, defaultTeamLink)
    peopleLink: string;

    // readonly mobileStoreVisible$: Observable<boolean>;

    readonly notificationsAsk$: Observable<boolean>;
    private sub = new Subscription();

    @LocalStorage(LocalStorageKeys.AsideFolded, false)
    asideFolded: boolean;

    // Use to set active class on link in nav
    // TODO: refactor
    readonly conferencesIsActive$: Observable<boolean>;

    readonly recordingsVisible$: Observable<boolean>;

    readonly callHistorySubState$: Observable<string>;

    readonly chatVisible$: Observable<boolean>;

    readonly isChrome = isChrome;

    readonly isEdge = isEdge;

    readonly isWindows = isWindows;

    readonly isMacOS = isMacOS;

    readonly conferenceLink$: Observable<any[]>;

    constructor(
        public app: AppState,
        public router: Router,
        public notifications: UnreadNotificationsService,
        private myPhoneService: MyPhoneService,
        private rolesService: McmGroupService,
        private meetingService: MeetingListService,
        private modalService: ModalService,
        private swPush: ExtendedSwPushService,
        public appsUtilityService: AppsUtilityService,
        public tokenInfoService: TokenInfoService,
    ) {
        this.conferenceLink$ = of([conferenceBaseUrl]);/* this.meetingService.sortedConferences$.pipe(map(conferences => (conferences.length > 0 ?
            conferences[0].routerLink : [newConferenceUrl]))); */

        this.notificationsAsk$ = this.swPush.permissionState$.pipe(
            map(state => state === 'prompt')
        );

        this.conferencesIsActive$ = router.events.pipe(
            filter((x): x is NavigationEnd => {
                return x instanceof NavigationEnd;
            }), map((x: NavigationEnd) => {
                // Better to replace with isActive()
                return x.url.indexOf('/conferences') !== -1;
            }));

        const myInfo$ = myPhoneService.myPhoneSession.pipe(switchMap(session => session.myInfo$), publishRef());

        const missedCalls$ = myInfo$.pipe(map(info => info.MissedCallsCount));

        const abandonedQueueCalls$ = myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.newAbandonedQueueCallsAmount$));

        this.recordingsVisible$ = combineLatest([
            myPhoneService.isPro$,
            myInfo$.pipe(map(info => info.CanSeeRecordings))
        ]).pipe(map(([isPro, canSeeRecordings]) => isPro && canSeeRecordings));

        // this.mobileStoreVisible$ = myInfo$.pipe(map(info => !info.Subscriptions?.Items?.length));

        this.chatVisible$ = myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.systemParameters$),
            map(params => params.ChatIsEnabled)
        );

        this.callHistorySubState$ = combineLatest([missedCalls$, abandonedQueueCalls$]).pipe(
            map(x => {
                const [missed, abandoned] = x;
                if (abandoned > 0) {
                    return '/call_history/abandoned';
                }
                else if (missed) {
                    return '/call_history/missed';
                }
                else {
                    return '/call_history';
                }
            }),
            startWith('/call_history'),
            publishRef()
        );

        // take the groups participating as manager and if there is ONLY 1 check if the allo call service is enabled
        this.sub.add(this.rolesService.myGroupIds$.pipe(
            map(groupIds => new Set(groupIds.map(groupId => JSON.stringify({
                // setup changes that update the access token
                RoleName: groupId.RoleName as RoleId,
                CanSeeGroupRecordings: groupId.CanSeeGroupRecordings
            })))),
            distinctUntilChanged(areSetsEqual),
            skip(1)
        ).subscribe(() => {
            this.tokenInfoService.getNewAccessToken();
        }));
    }

    showPWAModal() {
        this.appsUtilityService.showPWAModal();
    }

    openNotificationModal() {
        if (hasPermission('default')) {
            // shows browser permission request
            Notification.requestPermission();
            // shows our help window about permission
            this.modalService.showComponent(NotificationsHelpDialogComponent).subscribe(() => {
                this.swPush.refreshPermissions$.next(true);
            });
        }
    }

    ngOnDestroy(): void {
        this.sub.unsubscribe();
    }

    protected readonly Boolean = Boolean;
}
