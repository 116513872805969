import hash from 'object-hash';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

interface ICacheStorage{
    maxCountInBranch: number
    data: Map<any, Observable<any>>;
}

interface DbConfig {
    name: Brunch
    maxCount?: number
}

export type Brunch = 'RequestLookupContact'

// small Map DB for storing requests in cache
export class BranchCacheManager {
    private readonly cacheDb: Map<string, ICacheStorage>;
    private readonly defaultCacheRows = 200;

    constructor(...configs: DbConfig[]) {
        this.cacheDb = this.cacheDb ? this.cacheDb : new Map<string, ICacheStorage>();
        configs.forEach((config) => this.createBrunch(config));
    }

    public deleteBrunch(name: Brunch): boolean {
        return this.cacheDb.delete(name);
    }

    // returns a cached result if it exists
    // otherwise executes the request and sets result to cache storage
    public cacheRequest<Response>(name: Brunch, key: any, request: () => Observable<Response>): Observable<Response> {
        const cachedBrunch = this.getOrCreate(name);
        const sha1Key = hash.sha1(key);
        let value = cachedBrunch.data.get(sha1Key);
        // move key-value pair to top
        cachedBrunch.data.delete(sha1Key);
        if (!value) {
            if (cachedBrunch.data.size >= cachedBrunch.maxCountInBranch && cachedBrunch.data.size > 0) {
                const nextKey = cachedBrunch.data.keys().next();
                cachedBrunch.data.delete(nextKey.value);
            }
            value = request().pipe(
                shareReplay(1),
                map(item => Object.assign(Object.create(Object.getPrototypeOf(item)), item))
            );
        }
        cachedBrunch.data.set(sha1Key, value);
        return value;
    }

    private getOrCreate(name: Brunch): ICacheStorage {
        let cachedBrunch: ICacheStorage;
        if (this.cacheDb.has(name)) {
            cachedBrunch = this.cacheDb.get(name)!;
        }
        else {
            this.createBrunch({ name });
            cachedBrunch = this.cacheDb.get(name)!;
        }
        return cachedBrunch;
    }

    private createBrunch(config: DbConfig): void {
        this.cacheDb.set(config.name, {
            maxCountInBranch: config.maxCount ? config.maxCount : this.defaultCacheRows,
            data: new Map()
        });
    }
}
