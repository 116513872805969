import { SchedulerAction, SchedulerLike, Subscription } from 'rxjs';
import { NgZone } from '@angular/core';

export class EnterZoneScheduler implements SchedulerLike {
    constructor(private zone: NgZone, private scheduler: SchedulerLike) {
    }

    schedule(...args: any[]): Subscription {
        return this.zone.run(() =>
            // eslint-disable-next-line prefer-spread
            this.scheduler.schedule.apply(
                this.scheduler,
                <[(this: SchedulerAction<any>, state?: unknown) => void, number | undefined, unknown]>args
            )
        );
    }

    now(): number {
        return this.scheduler.now();
    }
}
