<ul class="nav flex-column h-100">

<!--    <li class="nav-item" *ngIf="!notificationsAsk && (mobileStoreVisible$|async)">-->
<!--        <a (click)="openQrCodeDialog();" id="showQrCodeId" style="background-color: #0096d1; color: white;">-->
<!--            <i class="customSVGIcons svg-lg fillIconWhite" app-mobile-android-alt-solid-icon>-->
<!--            </i>-->
<!--        </a>-->
<!--    </li>-->

        <li class="nav-item" *ngIf="notificationsAsk$|async">
            <a (click)="openNotificationModal()" id="showNotificationDialogId" class="bg-brand" role="button" data-qa="allow-notifications-link">
                <i class="customSVGIcons svg-lg fillIconWhite" app-bell-solid-icon></i>
            </a>
        </li>

        <li class="nav-item" *ngIf="appsUtilityService.isProgressiveWebAppVisible()">
            <a (click)="showPWAModal()" class="showAppsModal" role="button" data-qa="client-apps-link">
                <i *ngIf="isChrome" data-qa="edge-icon" class="customSVGIcons svg-md fillIconProvisionColor" app-chrome-brands-icon></i>
                <i *ngIf="isEdge" data-qa="chrome-icon" class="customSVGIcons svg-md fillIconProvisionColor" app-edge-brands-icon></i>
            </a>
        </li>

    <li class="nav-item">
        <a [routerLink]="peopleLink" routerLinkActive="active" id="menuPeople" data-qa="people-link">
            <div class="nav-image-wrapper">
                <i class="svg-sm" app-user-friends-solid-icon></i>
                <span [translate]="'_i18n.Team'" class='nav-title'></span>
            </div>
        </a>
    </li>

    <li class="nav-item" *ngIf="chatVisible$|async">
        <a routerLink="/chat" routerLinkActive="active" id="menuChat" data-qa="chat-link">
            <div class="nav-image-wrapper">
                <div class="svg-sm" app-comments-alt-solid-icon></div>
                <b *ngIf="notifications.unreadConversationCount$|async as count" class="badge bg-brand">{{count}}</b>
                <span [translate]="'_i18n.Chat'" class='nav-title'></span>
            </div>
        </a>
    </li>

    <li class="nav-item">
        <a [routerLink]="conferenceLink$|async" id="menuConferencesClick" routerLinkActive="active" data-qa="conference-link">
            <div class="nav-image-wrapper">
                <div class="svg-sm" app-webmeeting-custom-icon></div>
                <span [translate]="'_i18n.Meet'" class='nav-title'></span>
            </div>
        </a>
    </li>

    <li class="nav-item">
        <a [routerLink]="callHistorySubState$ | async" routerLinkActive="active"  id="menuCallHistory" data-qa="call-history-link">
            <div class="nav-image-wrapper">
                <div class="svg-sm" app-history-regular-icon></div>
                <b *ngIf="notifications.missedAndAbandonedCalls$|async as count" class="badge bg-red">{{count}}</b>
                <span [translate]="'_i18n.AllowpushCalls'" class='nav-title'></span>
            </div>
        </a>
    </li>

    <li class="nav-item">
        <a routerLink="/switchboard" routerLinkActive="active" id="menuSwitchboard" data-qa="switchboard-link">
            <div class="nav-image-wrapper">
                <div class="svg-sm" app-chart-bar-regular-icon></div>
                <span [translate]="'_i18n.Panel'" class='nav-title'></span>
            </div>
        </a>
    </li>


    <li class="nav-item">
        <a routerLink="/contacts" routerLinkActive="active" id="menuContacts" [queryParams]="contactsQueryParams" data-qa="contacts-link">
            <div class="nav-image-wrapper">
                <div class="svg-sm" app-address-book-solid-icon></div>
                <span [translate]="'_i18n.Contacts'" class='nav-title'></span>
            </div>
        </a>
    </li>

    <div  dropdown class="dropdown nav" container="body" placement='top right'>
        <li class="nav-item" dropdownToggle data-qa="additional-links">
            <a id="menuMore" role="button">
                <div class="nav-image-wrapper">
                    <div class="svg-sm" app-ellipsis-h-regular-icon
                         [ngClass]="!!(notifications.unheardVoicemails$ | async) ? 'fillIconBlue' : 'fillIconGray'">
                    </div>
                </div>
            </a>
        </li>
        <ul *dropdownMenu role="menu" class="dropdown-menu side-menu nav">
            <li role="menuitem" >
                <a routerLink="/voicemails" id="menuVoicemails" class="dropdown-item side-item d-flex align-items-center" data-qa="voicemails-link">
                    <span class="svg-xs" app-envelope-solid-icon></span>
                    <span class="text-truncate px-2 flex-grow-1">{{'_i18n.VoiceMail' | translate}}</span>
                    <b *ngIf="notifications.unheardVoicemails$|async as count" class="badge bg-brand">{{count}}</b>
                </a>
            </li>
            <li role="menuitem" *ngIf="recordingsVisible$ | async" >
                <a routerLink="/recordings" id="menuRecordings" class="dropdown-item side-item d-flex align-items-center" data-qa="recordings-link">
                    <span class="svg-xs" app-microphone-alt-solid-icon></span>
                    <span class="text-truncate px-2 flex-grow-1">{{'_i18n.Recordings' | translate}}</span>
                </a>
            </li>
            <li role="menuitem">
                <a routerLink="/settings" id="menuSettings" class="dropdown-item side-item d-flex align-items-center" data-qa="settings-link">
                    <span class="svg-xs" app-wrench-solid-icon></span>
                    <span class="text-truncate px-2 flex-grow-1">{{'_i18n.Settings' | translate}}</span>
                </a>
            </li>
        </ul>
    </div>

    <div class='flex-grow-1'></div>
    <ng-container *ngIf="tokenInfoService.myToken$|async; let access">
        <li *ngIf="access.adminSection" class="nav-item">
            <a routerLink="/office" routerLinkActive="active" [routerLinkActiveOptions]="{exact:false}"  id="menuAdmin" data-qa="admin-link">
                <div class="nav-image-wrapper">
                    <div class="svg-sm" app-gear-solid-icon></div>
                    <span class='nav-title' >{{'_i18n.Admin' | translate}}</span>
                </div>
            </a>
        </li>
    </ng-container>

    <li class="nav-item">
        <a routerLink="/apps" routerLinkActive="active"  id="menuApps" data-qa="apps-link">
            <div class="nav-image-wrapper">
                <div class="svg-sm" app-box-open-solid-icon></div>
                <span class='nav-title'>{{'_i18n.Apps' | translate}}</span>
            </div>
        </a>
    </li>
</ul>
