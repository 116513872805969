import { Component, OnInit } from '@angular/core';
import {
    catchError,
    map, startWith,
    switchMap
} from 'rxjs/operators';
import { Blf, xmlStringToBlf } from '@webclient/standalones/blf-config-editor';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import {
    combineLatest, distinctUntilChanged, Observable
} from 'rxjs';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { BlfService } from '@webclient/call-blf/blf-service';
import { McmGroupService } from '@webclient/auth/mcm-group.service';
import { GroupId } from '@myphone';
import { publishRef } from '@webclient/rx-share-utils';

@Component({
    selector: 'call-blf',
    styleUrls: ['call-blf.component.scss'],
    templateUrl: 'call-blf.component.html'
})
export class CallBlfComponent implements OnInit {
    itemsPerPage = 24;
    blfsCount$: Observable<number>;
    pagedBlfs$: Observable<Blf[]>;
    currentPage$: Observable<number>;

    public constructor(private myPhoneService: MyPhoneService, private blfService: BlfService, private mcmGroupsService : McmGroupService) {
    }

    ngOnInit(): void {
        const groupsContactsMapChanged$ = this.mcmGroupsService.groupsParticipating$.pipe(
            switchMap(groups => combineLatest(groups.map(group => group.contactsMap$))),
            map((contactMaps) => JSON.stringify(contactMaps.flatMap(groupMap => groupMap.getIds()))),
            distinctUntilChanged((previousContactsIds, currentContactsIds) => previousContactsIds === currentContactsIds),
            startWith(''),
        );

        const sharedBlfs$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.myInfo$),
            map((myInfo) => myInfo.SharedBlfs),
            distinctUntilChanged()
        );

        const myGroupsChanged$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.myInfo$.pipe(switchMap(session => session.MyGroups$))),
            map((groups) => JSON.stringify(groups.Items.map(groupId => ({ ...groupId }) as GroupId))),
            distinctUntilChanged((previousGroupIds, currentGroupIds) => previousGroupIds === currentGroupIds),
        );

        const blf$ = this.myPhoneService.myPhoneSession.pipe(
            switchMap(session => {
                // blf should be updated on change of sharedBlfs, myGroup, contactsMap
                return combineLatest([sharedBlfs$, groupsContactsMapChanged$, myGroupsChanged$]).pipe(
                    map(([sharedBlfs]) => xmlStringToBlf(sharedBlfs)),
                    catchError(() => []),
                    map(sharedBls => {
                        const itemsToFillPage = (Math.max(Math.ceil(sharedBls.length / this.itemsPerPage), 1)) * this.itemsPerPage;
                        while (sharedBls.length < itemsToFillPage) {
                            sharedBls.push(new Blf());
                        }
                        return sharedBls;
                    })
                );
            }),
            publishRef()
        );

        this.currentPage$ = this.blfService.pageOffSet$.pipe(
            map((offSet) => {
                return Math.max(1, Math.floor(offSet / this.itemsPerPage) + 1);
            }),
            publishRef()
        );

        this.pagedBlfs$ = combineLatest([blf$, this.blfService.pageOffSet$]).pipe(
            map(([blfs, offset]) => {
                const start = Math.max(0, Math.min(offset, blfs.length - this.itemsPerPage));
                return blfs.slice(start, start + this.itemsPerPage);
            })
        );

        this.blfsCount$ = blf$.pipe(
            map((blfs: Blf[]) => blfs.length)
        );
    }

    pageChanged(event: PageChangedEvent) {
        const offset = (event.page - 1) * event.itemsPerPage;
        this.blfService.setPageOffSet(offset);
    }
}
