import {
    Component, EventEmitter, Input, Output, ViewChild
} from '@angular/core';
import { CallControlService } from '@webclient/call/call-control.service';
import { MyCall } from '@webclient/phone/mycall';
import { canSendDtmf } from '@webclient/phone/phone.helpers';
import { CallDialpadComponent } from './call-dialpad.component';
import { DialerUtilityService, InCallSearchAction } from '@webclient/call/dialer-utility.service';
import { Router } from '@angular/router';
import { MeetingListService } from '@webclient/meeting/meeting-list/meeting-list.service';
import { map, take } from 'rxjs/operators';

export enum CallButtonsType {
    EstablishedCallActions = 1,
    IncomingCallActions = 2,
    VideoCallActions = 3,
    InCallKeyPadActions = 4,
    InCallSearchContactActions = 5,
}

@Component({
    selector: 'call-buttons',
    templateUrl: 'call-buttons.component.html',
    styleUrls: ['call-buttons.component.scss'],
})
export class CallButtonsComponent {
    @Input()
    public myCall: MyCall;

    @Input()
    public type: CallButtonsType;

    @Input()
    public searchTextPopulated: boolean;

    @Output()
    public readonly inCallKeyPadVisibilityChange = new EventEmitter<boolean>();

    @Output()
    public readonly inCallSearchActionChange = new EventEmitter<InCallSearchAction>();

    @Output()
    public readonly searchKeyPressed = new EventEmitter<string>();

    @Output()
    public readonly searchActionFired = new EventEmitter();

    @Output()
    public readonly videoVisibilityChange = new EventEmitter<boolean>();

    @Output()
    public readonly callActionFired = new EventEmitter();

    @ViewChild('inCallDialPad', { static: false })
    inCallDialPad: CallDialpadComponent;

    @ViewChild('inCallSearchContactDialPad', { static: false })
    inCallSearchContactDialPad: CallDialpadComponent;

    public CallButtonsType = CallButtonsType;
    public InCallSearchAction = InCallSearchAction;

    constructor(
        public meetingListService: MeetingListService,
        private router: Router,
        public callsService: CallControlService,
        public utilityService: DialerUtilityService) {
    }

    private nav(commands: any[]) {
        this.router.navigate(commands);
    }

    openConference() {
        if (this.myCall.isConferenceCall) {
            // TODO where's my conf?
            this.meetingListService.sortedConferences$.pipe(
                take(1),
                map(list => list.filter(conf => conf.isAudio || conf.isPrivate))
            ).subscribe(conf => {
                const privateConf = conf.find(item => item.isPrivate);
                if (privateConf) {
                    this.nav(privateConf.routerLink);
                }
                else if (conf.length > 0) {
                    const active = conf.find(item => item.isActive) ?? conf[0];
                    this.nav(active.routerLink);
                }
            });
        }
        else {
            this.setInCallSearchAction(InCallSearchAction.Conference);
        }
    }

    openTransfer() {
        this.setInCallSearchAction(InCallSearchAction.Transfer);
    }

    openAttTransfer() {
        this.setInCallSearchAction(InCallSearchAction.AttTransfer);
    }

    openNewCall() {
        this.setInCallSearchAction(InCallSearchAction.NewCall);
    }

    isVideoActive() {
        return this.utilityService.isVideoActive(this.myCall);
    }

    isHoldButton() {
        return this.utilityService.isOnHold(this.myCall);
    }

    isVideoAnswerAllowed(): boolean {
        return this.utilityService.isVideoAnswerAllowed(this.myCall);
    }

    isDialing(): boolean {
        return this.utilityService.isDialing(this.myCall);
    }

    videoCall() {
        if (!this.isVideoActive()) {
            this.ChangeVideoVisibility(true);
        }
        this.callsService.video(this.myCall);
    }

    AnswerWithVideo() {
        if (!this.isVideoActive()) {
            this.ChangeVideoVisibility(true);
        }
        this.callsService.answer(this.myCall, true);
    }

    ChangeVideoVisibility(videoVisibility: boolean) {
        this.videoVisibilityChange.emit(videoVisibility);
    }

    ChangeKeyPadVisibility(keypadVisibility: boolean) {
        this.inCallKeyPadVisibilityChange.emit(keypadVisibility);
    }

    AnswerCall() {
        this.callsService.answer(this.myCall, false);
        this.callActionFired.emit();
    }

    HoldCall() {
        this.callsService.hold(this.myCall);
        this.callActionFired.emit();
    }

    ResumeCall() {
        this.callsService.resume(this.myCall);
        this.callActionFired.emit();
    }

    DivertToVoiceMail() {
        this.callsService.divertToVoicemail(this.myCall);
        this.callActionFired.emit();
    }

    DropCall() {
        this.callsService.drop(this.myCall);
        this.callActionFired.emit();
    }

    MuteCall() {
        this.callsService.mute(this.myCall);
        this.callActionFired.emit();
    }

    JoinCall() {
        this.callsService.join(this.myCall);
        this.callActionFired.emit();
    }

    canSendDtmf() {
        return canSendDtmf(this.myCall);
    }

    playDtmfSound(value: string) {
        if (this.inCallDialPad && this.type === CallButtonsType.InCallKeyPadActions && this.canSendDtmf()) {
            this.inCallDialPad.play$.next(value);
        }
        else if (this.inCallSearchContactDialPad && this.type === CallButtonsType.InCallSearchContactActions) {
            this.inCallSearchContactDialPad.play$.next(value);
        }
    }

    setInCallSearchAction(searchAction: InCallSearchAction) {
        this.inCallSearchActionChange.emit(searchAction);
    }

    sendSearchKey(value: string) {
        this.searchKeyPressed.emit(value);
    }

    inCallSearchActionFired() {
        this.searchActionFired.emit();
    }
}
