<app-modal-dialog [formGroup]="form" header="_i18n.OverrideOfficeHours" [buttons]="buttons" [submit$]="submit$" [validateBeforeSubmit]="validateBeforeSubmit" [autofocus]="true">
        <p>{{'_i18n.OverrideOfficeHoursStatusDescription' | translate}}</p>

        <field-wrapper label="_i18n.Department" data-qa="group">
            <ng-select data-qa="select"
                       formControlName="Group"
                       [readonly]="groups.length <= 1"
                       [clearable]="false"
                       [searchable]="false"
                       [items]="groups" bindLabel="Name">
            </ng-select>
            <val-errors controlName="Group"></val-errors>
        </field-wrapper>

        <field-wrapper label="_i18n.OverrideOfficeHoursTo" data-qa="group-hours-mode">
            <ng-select id="GroupHoursMode" data-qa="select" formControlName="GroupHoursMode" [clearable]="false" [searchable]="false">
                <ng-option *ngFor="let option of groupHoursModeOptions" [value]="option.value">
                    {{(option.label || (option.value | appGroupHoursMode)) | translate}}
                </ng-option>
            </ng-select>
        </field-wrapper>

        <app-forward-destination
            *ngIf="isCustomMode"
            data-qa="custom-operator"
            label="_i18n.ForwardTo"
            formControlName="customOperator"
            [isMcmMode]="true"
            [extensionInfo]="myInfo$|async"
            [showValidation]="showValidation">
        </app-forward-destination>

        <app-call-prompts *ngIf="isAnnouncementEnabled" label="_i18n.SelectPrompt" formControlName="CustomPrompt">
        </app-call-prompts>

        <field-select
            *ngIf="!isSetToDefaultHours"
            formControlName="OverrideExpiresAt"
            label="_i18n.For"
            data-qa="timeout"
            [options]="overrideExpireOptions">
        </field-select>

        <label *ngIf="group && !isOfficeHoursOverrideExpired(group)">
            <span>{{'_i18n.CurrentOfficeHours'|translate}}</span>&nbsp;
            <span>{{officeHoursModeDisplay(group) | appGroupHoursMode | translate}}</span>&nbsp;
            <ng-container *ngIf="expirationTimeDisplay(group)">
                <span>{{'_i18n.Until' | translate}}</span>&nbsp;
                <span>{{expirationTimeDisplay(group) | amDateFormat: 'L LT'}}</span>&nbsp;
            </ng-container>
        </label>
</app-modal-dialog>
