import { protoBufToJsDateTime } from '../shared/utils.service';
import {
    ChatMessage as MyPhoneChatMessage,
    ChatPartyInfo,
    ChatRecipientRef,
    ChatRecipientType,
    ExternalChatCloseReason
} from '@myphone';
import { AppContact, defaultContact, groupContact } from '../myphone/contact';
import { ChatParticipant, createChatParticipant, createContactFromRecipient } from '@webclient/chat/chat-participant';
import { MyPhoneSession } from '@webclient/myphone/myphone-session';
import { ChatMessage } from '@webclient/chat/chat-service/chat-message';
import { List, Record } from 'immutable';
import { ChatDestination, ChatDestinationType } from '@webclient/chat/chat-destination';
import { ChatParticipants } from '@webclient/chat/chat-participants';

interface ChatProps {
    id: number;
    isExternal: boolean;
    isProvider: boolean;
    typing?: AppContact;
    lastSended: Date;
    hasUnread: boolean;
    unreadCount: number;
    participants: ChatParticipants;

    lastMessageObject?: ChatMessage;
    destination: ChatDestination;
    queueName: string;
    isArchived: boolean;
    privateName: string;
    publicName: string;
    queueNo: string;
    takenBy?: AppContact;
    closeReason?: ExternalChatCloseReason;
}

const defaultChatProps: ChatProps = {
    id: 0,
    isExternal: false,
    isProvider: false,
    typing: undefined,
    lastSended: new Date(),
    hasUnread: false,
    unreadCount: 0,
    participants: new ChatParticipants(),

    lastMessageObject: undefined,
    destination: new ChatDestination(),
    queueName: '',
    isArchived: false,
    privateName: '',
    publicName: '',
    queueNo: '',
    takenBy: undefined,
    closeReason: undefined
};

export class Chat extends Record(defaultChatProps) {
    public get isArchiveAvailable() {
        return !this.isArchived && this.isMySelfParticipant;
    }

    public get isUnarchiveAvailable() {
        return this.isArchived && this.isMySelfParticipant;
    }

    public get isDeleteAvailable() {
        return this.isMySelfParticipant && (!this.isExternal || this.closeReason !== undefined);
    }

    public get isMySelfParticipant() {
        return this.participants.isMySelfParticipant;
    }

    /**
     * I can transfer if it's external
     * and it was taken by or not a queue at all
     */
    public get isTransferAvailable() {
        return this.isExternal && Boolean(this.takenBy || !this.queueNo);
    }

    /**
     * I can dealt with if it's external
     * and it was taken by or not a queue at all
     */
    public get isDealtWithAvailable() {
        return this.isTransferAvailable && this.closeReason !== ExternalChatCloseReason.ECCR_IsHandled;
    }

    /**
     * I can take ownership if it's external
     * and it's a queue that wasn't taken by
     */
    public get isTakeOwnershipAvailable() {
        return this.isExternal && !this.takenBy && !!this.queueNo;
    }

    /**
     * I can block chat if it's any external
     * from LiveChat and it's still active
     */
    public get isBlockChatAvailable() {
        return this.isExternal && this.closeReason === undefined &&
            this.participants.items.some(part => Boolean(part.ipAddress));
    }

    public get participantToBlock() {
        return this.participants.items.find(x => Boolean(x.ipAddress))?.gid;
    }

    /**
     * I can end session if it's any external
     * from LiveChat and it's still active
     */
    public get isEndSessionAvailable() {
        return this.isExternal && this.closeReason === undefined &&
            this.participants.items.some(part => part.type === ChatRecipientType.CRT_Anonymous);
    }

    public get isChatClosed() {
        return this.closeReason !== undefined;
    }

    public get isMySelfSpectator() {
        return this.participants.isMySelfSpectator;
    }

    public get lastMessage(): string {
        return this.lastMessageObject?.UnsafeMessage ?? '';
    }

    public get activeParticipants(): List<ChatParticipant> {
        return this.participants.activeParticipants;
    }

    constructor(obj?: Partial<ChatProps>) {
        super(obj);
    }

    public static FromPartyInfo(chatInfo: ChatPartyInfo, session: MyPhoneSession, message?: Partial<MyPhoneChatMessage>): Chat {
        const participants = new ChatParticipants({
            items: List<ChatParticipant>(chatInfo.Recipients.map(recipient => createChatParticipant(chatInfo, recipient, session)))
        });

        const chat = new Chat({
            participants,
            privateName: chatInfo.PrivateName,
            publicName: chatInfo.PublicName,
            queueName: chatInfo.QueueName,
            queueNo: chatInfo.QueueNo,
            id: chatInfo.IdConversation,
            // Well I do understand that chat is external but if external participant is removed chat becomes local
            isExternal: !!participants.externalParticipant /* chatInfo.IsExternal */,
            isProvider: !!participants.providerParticipant,
            isArchived: chatInfo.IsArchived,
            lastSended: message && message.Time ? protoBufToJsDateTime(message.Time) : undefined,
            lastMessageObject: message && (message.Message || message.File) ? new ChatMessage(message as MyPhoneChatMessage) : undefined,
            hasUnread: message?.IsNew,
            takenBy: chatInfo.TakenBy ? createContactFromRecipient(session, chatInfo.TakenBy) : undefined,
            closeReason: chatInfo.CloseReason
        });

        const externalParticipant = participants.externalParticipant;
        if (externalParticipant) {
            return chat.merge({
                destination: new ChatDestination({
                    type: ChatDestinationType.External,
                    phoneNumber: externalParticipant.phoneNumber,
                    ipAddress: externalParticipant.ipAddress,
                    anonymousSessionId: externalParticipant.anonymousSessionId,
                    provider: externalParticipant.provider,
                    contact: externalParticipant.contact
                })
            });
        }
        else if (!chat.participants.isGroupChat) {
            const chatParticipant = chat.participants.items.find(x => x.isAgent && !x.isMe);
            return chat.merge({
                destination: new ChatDestination({
                    phoneNumber: chatParticipant?.phoneNumber,
                    type: ChatDestinationType.Extension,
                    contact: chatParticipant?.contact ?? defaultContact,
                })
            });
        }
        else {
            // Check anon provider
            return chat.merge({
                destination: new ChatDestination({
                    type: ChatDestinationType.Group,
                    contact: groupContact
                })
            });
        }
    }

    // More than 2 participants
    public get isGroupChat(): boolean {
        return this.participants.isGroupChat;
    }

    public get isOverallGroupChat() {
        return this.participants.isOverallGroupChat;
    }

    public get isLastMessageMine(): boolean {
        return this.lastMessageObject ? this.isSentByMe(this.lastMessageObject.Participants) : false;
    }

    public getParticipant(participantId: number): ChatParticipant|undefined {
        return this.participants.getParticipant(participantId);
    }

    public isSentByMe(participantRefs: List<ChatRecipientRef>): boolean {
        return this.participants.isSentByMe(participantRefs);
    }
}

export const NoConversationId = -1;

export const defaultChat = new Chat({
    id: NoConversationId
});
