import {
    Component
} from '@angular/core';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { animate, style, transition, trigger } from '@angular/animations';
import { protoBufToJsDateTime } from '@webclient/shared/utils.service';
import { TokenInfoService } from '@webclient/auth/token-info.service';

@Component({
    selector: 'banner-container',
    animations: [
        trigger(
            'fadeInOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({ opacity: 0 }),
                        animate('2s 0.5s', style({ opacity: 0.75 }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        style({ opacity: 0.75 }),
                        animate('2s 0.5s', style({ opacity: 0 }))
                    ]
                )
            ]
        )
    ],
    template: `
        <div class='d-flex flex-column align-items-end justify-content-center gap-2 me-1'>
            <div *ngIf='showRegistrationRequired$| async' [@fadeInOutAnimation]='true'
                 class='banner mb-0 p-2 d-flex gap-2'>
                <span class='banner-icon svg-sm' app-exclamation-triangle-regular-icon></span>
                <div>
                    <span>{{'_i18n.FreeEditionRegistrationRequired' | translate}}.</span>
                    <a [href]="'_i18n.FreeEditionRegistrationRequiredGuideLink' | translate"
                       target='_blank' rel='noopener'
                       class='ms-1 text-decoration-underline'>{{'_i18n.MoreInfo' | translate}}.</a>
                </div>
            </div>
            <div *ngIf='showSystemOwnerWarning$| async' [@fadeInOutAnimation]='true'
                 class='banner mb-0 p-2 d-flex gap-2'>
                <span class='banner-icon svg-sm' app-exclamation-triangle-regular-icon></span>
                <div>
                    <span>{{'_i18n.NoSystemOwnerSet' | translate}}.</span>
                    <a [href]="'_i18n.NoSystemOwnerSetGuideLink' | translate" target='_blank'
                       rel='noopener'
                       class='ms-1 text-decoration-underline'>{{'_i18n.MoreInfo' | translate}}.</a>
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./banner-container.component.scss']
})
export class BannerContainerComponent {
    readonly showSystemOwnerWarning$: Observable<boolean>;
    readonly showRegistrationRequired$: Observable<boolean>;

    constructor(private myPhoneService: MyPhoneService, private tokenInfoService: TokenInfoService) {
        const systemParameters$ = myPhoneService.myPhoneSession.pipe(
            switchMap((session) => session.systemParameters$)
        );
        const myRole$ = this.tokenInfoService.myToken$.pipe(
            map((myToken) => myToken.myRole)
        );

        // show in non-mcm mode when there is no system owner && and my role is not 'users'
        this.showSystemOwnerWarning$ = combineLatest([systemParameters$, myRole$]).pipe(
            map(([systemParameters, myRole]) => {
                return !systemParameters.MultiCompanyMode && !systemParameters.HasSystemOwner && myRole !== 'users';
            })
        );

        // show when standard annual license and expires within range: 30/12/2023 to 31/12/2023 UTC Timezone
        this.showRegistrationRequired$ = systemParameters$.pipe(
            map((systemParameters) => {
                const licenseExpirationDate = protoBufToJsDateTime(systemParameters.LicenseExpiredAt);
                return systemParameters.LicenseProduct === '3CXPSSPLA' && this.isFreeLicenseExpirationDate(licenseExpirationDate);
            })
        );
    }

    private isFreeLicenseExpirationDate(expirationDate: Date | undefined) {
        if (!expirationDate) {
            return false;
        }
        const startDate = new Date(Date.UTC(2023, 11, 30, 0, 0, 0));
        const endDate = new Date(Date.UTC(2023, 11, 31, 23, 59, 59));
        return (expirationDate >= startDate && expirationDate <= endDate);
    }
}
