import { LoginData } from '../../generated/fetch';
import { Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';

export class WebSocketError extends Error {
    constructor(message: string, public status: number) {
        super(message);
        // Set the prototype explicitly.
        Object.setPrototypeOf(this, WebSocketError.prototype);
    }
}

export function createNotificationChannelFromWebsocket(sessionParams: LoginData, factory: (url:string) => WebSocket, baseUrl: URL): Observable<any> {
    // Session successfully created
    const channel = new Observable((notificationObserver: Observer<any>) => {
        const protocol = baseUrl.protocol.replace('http', 'ws') + '//';
        const url = '/ws/webclient?sessionId=' +
            sessionParams.sessionKey + '&pass=' + sessionParams.pass;
        const host = baseUrl.host;
        const socket = factory(protocol + host + url);
        socket.binaryType = 'arraybuffer';

        socket.onopen = () => {
        };
        socket.onmessage = (evt) => {
            if (evt.data === 'NOT AUTH' || evt.data === 'STOP') {
                notificationObserver.error(new WebSocketError('Notification channel cancelled by server', 1000));
            }
            else {
                notificationObserver.next(evt.data);
            }
        };
        // socket.onerror = (evt) =>  notificationObserver.error(evt);

        socket.onclose = (evt) => {
            notificationObserver.error(evt);
        };
        return () => socket.close();
    });

    return channel.pipe(map(data => ((data instanceof ArrayBuffer) ? new Uint8Array(data) : data)));
}
