import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnInit,
    Self,
    SimpleChange,
    SimpleChanges
} from '@angular/core';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    ValidationErrors,
    Validator
} from '@angular/forms';
import { auditTime, combineLatest, merge, ReplaySubject, takeUntil, tap } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { ContactSearcherService } from '@webclient/shared/service/contact-searcher.service';
import { DestroyService } from '@webclient/services/destroy.service';
import {
    convertToForwardDestinationFormValue$,
    convertToForwardDestinationValue,
    createForwardDestinationFormValue,
    ExtensionInfo,
    ForwardDestinationValue,
    ForwardDestinationViewType,
    isWithVoicemail,
} from './forward-destination.form';
import { ModalService } from '@webclient/modal/app-modal.service';
import { PbxPeer, PbxPeerType } from '@xapi';
import { setControlEnabled } from '@webclient/fields/fields.utils';
import { forwardingDNValidator, myMobileForwardingValidator, toggleError } from '@webclient/validators';
import { NgSelectModule } from '@ng-select/ng-select';
import { DnSelectComponent } from '@webclient/standalones/dn-select';
import { FieldsModule } from '@webclient/fields/fields.module';
import { ValdemortModule } from 'ngx-valdemort';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ForwardDestinationTypePipe } from './forward-destination-type.pipe';
import { LocalStorage } from 'ngx-webstorage';
import { LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { FieldValueAccessor } from '@webclient/fields';
import { noEmitAndShowMessageOnError } from '@webclient/rx-utils';
import { ForwardDestinationType } from '@myphone';
import { FormModel } from 'ngx-mf';

interface ForwardDestinationFormValue {
    type: ForwardDestinationViewType | null
    number: string | null
    extensionContact: PbxPeer | null
    systemContact: PbxPeer | null
    rebound: boolean | null
    use302: boolean | null
    voicemail: boolean | null
}

type ForwardDestinationForm = FormModel<ForwardDestinationFormValue>;

@Component({
    selector: 'app-forward-destination',
    templateUrl: 'forward-destination.component.html',
    styleUrls: ['forward-destination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ForwardDestinationComponent },
        { provide: NG_VALIDATORS, multi: true, useExisting: ForwardDestinationComponent },
        DestroyService
    ],
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        NgSelectModule,
        DnSelectComponent,
        FieldsModule,
        ValdemortModule,
        ForwardDestinationTypePipe,
    ]
})
export class ForwardDestinationComponent extends FieldValueAccessor<ForwardDestinationValue | null> implements OnChanges, OnInit, Validator {
    @Input() label: string;
    @Input() showSameAsAllCalls: boolean;
    @Input() isMcmMode: boolean;
    @Input() extensionInfo: ExtensionInfo;
    @Input() enableUse302: boolean;

    @LocalStorage(LocalStorageKeys.Lab)
    lab: boolean;

    /**
     * Shows current errors, sets immediate display for future errors.
     * Workaround for bound control.markAsTouched detection.
     */
    @Input() set showValidation(value: boolean) {
        if (value) {
            this.form.markAllAsTouched();
        }
    }

    /** Allow edit values partially, showing nulls as undetermined and to be skipped on validation and save */
    @Input() partialEdit = false;
    @Input() indeterminatePlaceholder = '_i18n.LeaveUnchanged';

    private readonly extensionInfoInput$ = new ReplaySubject<SimpleChange>(1);
    private readonly valueInput$ = new ReplaySubject<void>(1);

    readonly searchContactExtensions = [PbxPeerType.Extension];
    readonly searchSystemExtensions = [PbxPeerType.Queue, PbxPeerType.RingGroup, PbxPeerType.Ivr, PbxPeerType.SpecialMenu, PbxPeerType.Conference, PbxPeerType.RoutePoint];
    readonly ForwardDestinationViewType = ForwardDestinationViewType;

    // updateOn: blur because of autosave on profile change in myUser/settings/callForwarding
    readonly form = new FormGroup<ForwardDestinationForm['controls']>({
        type: new FormControl<ForwardDestinationViewType | null>({ value: null, disabled: true }, {
            validators: [
                control => (
                    control.value === ForwardDestinationViewType.MyMobile
                        ? myMobileForwardingValidator({ value: this.extensionInfo.MobileNumber } as AbstractControl)
                        : null
                )
            ]
        }),
        number: new FormControl<string | null>({ value: null, disabled: true }, {
            updateOn: 'blur',
            validators: forwardingDNValidator,
        }),
        extensionContact: new FormControl<PbxPeer|null>({ value: null, disabled: true }),
        systemContact: new FormControl<PbxPeer|null>({ value: null, disabled: true }),
        rebound: new FormControl<boolean | null>(null),
        use302: new FormControl<boolean | null>(null),
        voicemail: new FormControl<boolean | null>(null),
    }, control => {
        const { controls, value } = control as ForwardDestinationForm;
        const noNulls = !this.indeterminate;

        toggleError(controls.type, { required: true }, noNulls && value.type == null);
        // no need to check type here, controls are disabled when irrelevant
        toggleError(controls.extensionContact, { required: true }, noNulls && value.extensionContact == null);
        toggleError(controls.systemContact, { required: true }, noNulls && value.systemContact == null);
        toggleError(controls.number, { required: true }, (noNulls && value.number == null) || value.number === '');

        // one checkbox is automatically switched off when another is clicked, catch middle state here
        return value.rebound && value.use302 ? { invalidMiddleState: true } : null;
    });

    constructor(
        cd: ChangeDetectorRef,
        private myPhone: MyPhoneService,
        private modalService: ModalService,
        private contactSearcher: ContactSearcherService,
        @Self() private destroy$: DestroyService,
    ) {
        super(cd);
        this.touchOnChange = true;
        if (this.lab) {
            this.searchSystemExtensions.push(PbxPeerType.Parking);
        }
    }

    get f() {
        return this.form.controls;
    }

    protected get indeterminate() {
        return this.partialEdit && !this.changed;
    }

    isIndeterminate(value: unknown) {
        return this.indeterminate && value == null;
    }

    get destinationTypes(): ForwardDestinationViewType[] {
        return [
            ...(this.showSameAsAllCalls ? [ForwardDestinationViewType.SameAsAllCalls] : []),
            ...(!this.isMcmMode ? [ForwardDestinationViewType.MyVoicemail] : []),
            ForwardDestinationViewType.Extension,
            ...(!this.isMcmMode ? [ForwardDestinationViewType.MyMobile, ForwardDestinationViewType.External] : []),
            ForwardDestinationViewType.SystemExtension,
            ForwardDestinationViewType.SendBusy,
            ...(this.isMcmMode ? [ForwardDestinationViewType.PlayAnnouncement] : [])
        ];
    }

    get isSystemContactWithVoicemail() {
        return isWithVoicemail(this.form.value.systemContact);
    }

    /**
     * Flag to skip value change when value is from writeValue and form is valid.
     * For invalid state we should notify parent about our custom validation errors.
     */
    private externalValueChange: boolean;

    ngOnInit(): void {
        const writtenValue$ = combineLatest([
            this.myPhone.myPhoneSession,
            this.extensionInfoInput$,
            this.valueInput$,
        ]).pipe(
            switchMap(([session, { currentValue: extensionInfo, previousValue: prevExtensionInfo }]) =>
                convertToForwardDestinationFormValue$(session, this.contactSearcher, this.value, extensionInfo, prevExtensionInfo)
            ),
            noEmitAndShowMessageOnError(this.modalService),
            // when we wait for value - exactly case, when we need to get contact and determine forwarding type
            // control is blocked initially to not jump in with validation
            tap(() => setControlEnabled(this.f.type, true))
        );

        const { type: typeControl, rebound, use302, systemContact, voicemail } = this.form.controls;

        const typeChangeValue$ = typeControl.valueChanges.pipe(map(type => {
            // can not set type - it will go into this handler again (eternal cycle),
            // and can not use distinctUntilChanged - it results in error behaviour on component reload with different data
            // when first change equals to value of previous component state
            const { type: ignore, ...resetValues } = createForwardDestinationFormValue({ type });

            return resetValues;
        }));

        merge(writtenValue$, typeChangeValue$)
            .pipe(takeUntil(this.destroy$))
            .subscribe(value => {
                this.form.patchValue(value, { emitEvent: false });
                // will emit valueChanged for component revalidation
                this.onTypeChanged(typeControl.value);
            });

        rebound.valueChanges
            .pipe(filter(value => Boolean(value && use302.value)), takeUntil(this.destroy$))
            .subscribe(() => {
                use302.setValue(false);
            });

        use302.valueChanges
            .pipe(filter(value => Boolean(value && rebound.value)), takeUntil(this.destroy$))
            .subscribe(() => {
                rebound.setValue(false);
            });

        systemContact.valueChanges
            .pipe(filter(value => Boolean(voicemail.value && !isWithVoicemail(value))), takeUntil(this.destroy$))
            .subscribe(() => {
                voicemail.setValue(false);
            });

        this.form.valueChanges
            .pipe(auditTime(0), takeUntil(this.destroy$))
            .subscribe(value => {
                if (this.externalValueChange) {
                    this.externalValueChange = false;
                    if (this.form.valid) {
                        return;
                    }
                }
                this.valueChanged(convertToForwardDestinationValue(value, this.extensionInfo));
            });
    }

    writeValue(value: ForwardDestinationValue | null) {
        super.writeValue(value ?? (this.partialEdit ? null : <ForwardDestinationValue>{ forwardDestination: { FwdType: ForwardDestinationType.FD_Disconnect } }));
        this.externalValueChange = true;
        this.valueInput$.next();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.extensionInfo) {
            this.extensionInfoInput$.next(changes.extensionInfo);
        }
    }

    private onTypeChanged(type: ForwardDestinationViewType | null) {
        const { extensionContact, systemContact, number } = this.form.controls;

        setControlEnabled(extensionContact, type === ForwardDestinationViewType.Extension);
        setControlEnabled(systemContact, type === ForwardDestinationViewType.SystemExtension);
        setControlEnabled(number, type === ForwardDestinationViewType.External);
        this.form.updateValueAndValidity();
        this.cd.markForCheck();
    }

    validate(): ValidationErrors | null {
        this.form.updateValueAndValidity({ emitEvent: false });
        return this.form.valid ? null : { invalidForwardDestination: true };
    }
}
