import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'nullToEmpty', standalone: true
})
export class NullToEmptyPipe implements PipeTransform {
    transform(value: string|null|undefined): string {
        return value ?? '';
    }
}
