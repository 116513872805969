import { NoContentComponent } from './no-content';
import { LAYOUT_ROUTES } from './layout.routes';
import { MyphoneSessionComponent } from './myphone-session-component';
import { StandaloneDialerComponent } from './call-adapter/standalone-dialer.component';
import { loadChildrenActiveCalls } from '@webclient/switchboard/active-calls/active-calls.import';
import { loadChildrenAllQueueCalls } from '@webclient/switchboard/queues/all-queue-calls.import';
import { ErrorPageComponent } from '@webclient/error-page/error-page.component';
import { loadChildrenReceptionist } from './switchboard/receptionist/receptionist.import';

export const ROUTES = [
    {
        path: '',
        component: MyphoneSessionComponent,
        children: [
            LAYOUT_ROUTES
        ],
    },
    {
        path: 'wallboard',
        component: MyphoneSessionComponent,
        data: {
            fullScreen: true
        },
        loadChildren: () => import('./wallboard/wallboard.module').then(m => m.WallboardModule),
    },
    {
        path: 'wallboard/:id',
        component: MyphoneSessionComponent,
        data: {
            fullScreen: true
        },
        loadChildren: () => import('./wallboard/wallboard.module').then(m => m.WallboardModule)
    },
    {
        path: 'reports',
        component: MyphoneSessionComponent,
        loadChildren: () => import('./admin/reports-print/reports-print.module').then(m => m.ReportsPrintModule),
    },
    {
        path: 'ActiveCalls',
        component: MyphoneSessionComponent,
        data: {
            fullScreen: true
        },
        loadChildren: loadChildrenActiveCalls,
    },
    {
        path: 'queue_calls',
        component: MyphoneSessionComponent,
        data: {
            fullScreen: true
        },
        loadChildren: loadChildrenAllQueueCalls
    },
    {
        path: 'receptionist',
        component: MyphoneSessionComponent,
        data: {
            fullScreen: true
        },
        loadChildren: loadChildrenReceptionist
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    },
    {
        path: 'reset',
        loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule),
    },
    {
        path: 'forgot',
        loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    },
    {
        path: 'call',
        component: StandaloneDialerComponent,
    },
    {
        path: 'app/settings/office365',
        redirectTo: 'office/integrations/microsoft365'
    },
    {
        path: 'dialer',
        component: StandaloneDialerComponent,
        data: {
            title: '_i18n.Dialer'
        }
    },
    { path: 'tel', redirectTo: '/' },
    { path: 'error', component: ErrorPageComponent },
    { path: '**', component: NoContentComponent },
];
