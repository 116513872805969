import { map } from 'rxjs/operators';
import { Component } from '@angular/core';
import { MyPhoneService } from '../../myphone/myphone.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'webmeeting',
    templateUrl: './webmeeting.component.html',
    styleUrls: ['../../webmeeting/webmeeting.style.scss']
})
export class WebmeetingComponent {
    WebMeetingUrl$: Observable<string>;

    constructor(private myPhoneService: MyPhoneService) {
        this.WebMeetingUrl$ = this.myPhoneService.myPhoneSession.pipe(map(session => {
            try {
                const url = new URL(session.myInfo.QuickMeetingLink);
                const baseURL = new URL(session.domainUrl);
                url.host = baseURL.host;
                url.protocol = baseURL.protocol;
                url.searchParams.append('embed', 'true');
                return url.href;
            }
            catch (error) {
                return '';
            }
        }));
    }
}
