import { MediaDescription } from '@webclient/phone/media-description';
import {
    from, fromEvent, Observable, of
} from 'rxjs';
import { SelectedConstraints, SelectedMediaDevice } from '@webclient/phone/device-media.service';
import {
    catchError, scan, switchMap, take, tap
} from 'rxjs/operators';
import { mapSelectedDevices } from '@webclient/phone/device-media-utils';

export function chooseDevice(deviceList: MediaDeviceInfo[], devType: MediaDeviceKind, selectedLabel: string | null): SelectedMediaDevice {
    const selected = deviceList.find(device => device.label === selectedLabel && device.kind === devType);
    if (selected) {
        return { media: selected, labelId: selectedLabel ?? '' };
    }
    const defDevice = deviceList ? deviceList.find(device => device.deviceId === 'default' && device.kind === devType) : deviceList;
    if (defDevice && defDevice instanceof MediaDeviceInfo) {
        return { media: defDevice, labelId: selectedLabel ?? '' };
    }
    if (deviceList && deviceList.length > 0) {
        return { media: deviceList[0], labelId: selectedLabel ?? '' };
    }
    return { media: undefined, labelId: selectedLabel ?? '' };
}

function replaceTrack(connection: RTCPeerConnection, track: MediaStreamTrack, stream: MediaStream, kind: 'audio'|'video'): RTCRtpSender {
    const senders = connection.getSenders();
    if (senders.length === 0) {
        return connection.addTrack(track, stream);
    }
    const sameKind = senders.find(sender => Boolean(sender.track && sender.track.kind === kind));
    if (!sameKind) {
        return connection.addTrack(track, stream);
    }
    else {
        sameKind.replaceTrack(track);
        return sameKind;
    }
}

/**
 * Stop all tracks of a stream
 * @param {MediaStream} stream
 */
export function stopStream(stream: MediaStream) {
    stream.getAudioTracks().forEach(track => track.stop());
    stream.getVideoTracks().forEach(track => track.stop());
}

function getUserMedia(constraints: MediaStreamConstraints) {
    return (constraints.video || constraints.audio) ? navigator.mediaDevices.getUserMedia(constraints) : Promise.resolve(new MediaStream());
}

/**
 * Call this function to add user media tracks to PeerConnection.
 * Function supposes that if localStream is setup than audio track is already there.
 * Function will not recreate stream in case video is requested and provided.
 * If video is requested but not provided stream should be recreated.
 * If video is not requested but provided video track will be stopped to ensure webcam is not lit.
 * @param {MediaDescription} media
 * @param {boolean} video
 * @returns {any}
 */
export function setupMediaForPeerConnection(media: MediaDescription, audio: boolean, video: boolean, device: Observable<SelectedConstraints>, replace = false) {
    const peerConnection = media.peerConnection;
    const setupStream = (localStream: MediaStream) => {
        // This is what I'm going to send
        media.localStream = localStream;

        localStream.getTracks().forEach((track: MediaStreamTrack) => {
            if (track.kind === 'video') {
                if (video && track.readyState === 'live') {
                    media.video = replaceTrack(peerConnection, track, localStream, 'video');
                }
            }
            else if (audio && track.readyState === 'live') {
                track.enabled = !media.isMuted;
                media.audio = replaceTrack(peerConnection, track, localStream, 'audio');
            }
        });

        if (media.audio && media.audio.dtmf) {
            media.toneSend$ = fromEvent<RTCDTMFToneChangeEvent>(media.audio.dtmf, 'tonechange')
                .pipe(scan((acc: string, value: RTCDTMFToneChangeEvent) => {
                    return acc + value.tone;
                }, ''));
        }
    };

    if (media.localStream && !replace) {
        if (!video) {
            // User doesn't want video so let's stop it
            media.localStream.getVideoTracks().forEach(track => track.stop());
        }
        if (!audio) {
            // User doesn't want audio so let's stop it
            media.localStream.getAudioTracks().forEach(track => track.stop());
        }
        const hasVideoTracks = media.localStream.getVideoTracks().some(track => track.readyState === 'live');
        const hasAudioTracks = media.localStream.getAudioTracks().some(track => track.readyState === 'live');
        if ((!video || (video && hasVideoTracks)) && (!audio || (audio && hasAudioTracks))) {
            // User wants video and it's presented or video is not required
            setupStream(media.localStream);
            return of(media.localStream);
        }
        // Teardown and setup new localstream
        stopStream(media.localStream);
    }
    // stop all streams and let user change device
    if (replace && media.localStream) {
        media.localStream.getVideoTracks().forEach(track => track.stop());
        media.localStream.getAudioTracks().forEach(track => track.stop());
    }
    return device.pipe(
        take(1),
        mapSelectedDevices(audio, video),
        switchMap(devices => from(getUserMedia({ audio: devices.audio, video: devices.video }))),
        // Retry with no video if webcam is not available at the moment
        catchError((err: unknown) => {
            console.log(err);
            return from(getUserMedia({ audio, video: false }));
        }),
        tap(setupStream)
    );
    // TODO theoretically would be nice to create silent media stream though dont want to use AudioContext for it
    // Retry with no video and audio if mic and webcam is not available at the moment
    // .catch(err => {
    //     setupStream(new MediaStream());
    //     return Observable.of(media.localStream);
    // })
}
