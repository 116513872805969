import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppContact } from '@webclient/myphone/contact';
import { AppContactType } from '@webclient/myphone/app-contact-type';
import { map } from 'rxjs/operators';

@Pipe({
    name: 'contactPhoneType'
})
export class ContactPhoneTypePipe implements PipeTransform {
    constructor(private translate: TranslateService) {
    }

    transform(value: AppContact| undefined, number: string): Observable<string> {
        let translationKey = '';
        if (value &&
            (value.type === AppContactType.PersonalPhonebook || value.type === AppContactType.CompanyPhonebook || value.type === AppContactType.Extension)
        ) {
            if (value.phones.mobile === number) {
                translationKey = '_i18n.MobileNumber';
            }
            else if (value.phones.mobile2 === number) {
                translationKey = '_i18n.ContactData0';
            }
            else if (value.phones.home === number) {
                translationKey = '_i18n.ContactData1';
            }
            else if (value.phones.business === number) {
                translationKey = '_i18n.ContactData3';
            }
            else if (value.phones.business2 === number) {
                translationKey = '_i18n.ContactData4';
            }
        }

        if (translationKey) {
            return this.translate.stream(translationKey).pipe(map(trans => {
                return `(${trans})`;
            }));
        }
        else {
            return of('');
        }
    }
}
