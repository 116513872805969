import { GenericMessage } from '@myphone';

export class MyphoneMessageEncoder {
    public encode<T>(request: { toGenericMessage : () => GenericMessage }) : Uint8Array {
        // Encode to binary
        return GenericMessage.encode(request.toGenericMessage()).finish();
    }

    public decode(binary: Uint8Array) {
        try {
            const genericMessage = GenericMessage.decode(binary, binary.length);
            return Object.entries(genericMessage).find(([key]) => key !== 'MessageId')![1];
        }
        catch {
            console.warn('Invalid notification');
            return null;
        }
    }
}
