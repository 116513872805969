import type { FilterValue, SerializedFilter } from '@office/standalones/odata-search/types';
import type { ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { PbxPeer } from '@xapi';

export interface PbxQueueFilterParams {
    pbxQueue: PbxPeer | null
}

export class PbxQueueFilterValue implements FilterValue<PbxQueueFilterParams> {
    private pbxQueue: PbxQueueFilterParams['pbxQueue'] = null;

    deserialize(serializedValue: SerializedFilter): FilterValue<PbxQueueFilterParams> {
        return PbxQueueFilterValue.deserialize(serializedValue, this.value);
    }

    static deserialize(serializedValue: SerializedFilter, initialValue?: PbxQueueFilterParams): FilterValue<PbxQueueFilterParams> {
        const value = new PbxQueueFilterValue();

        if (initialValue) {
            value.value = initialValue;
        }

        if ('queue' in serializedValue && typeof serializedValue.queue === 'object') {
            // case when we set value manually and filter tries to clean it from queue on page load
            value.pbxQueue = serializedValue.queue ?? value.pbxQueue;
        }
        return value;
    }

    getDisplayValue(translate: TranslateService): string {
        return this.pbxQueue?.Name ?? '';
    }

    getDisplayValueForPrint(translate: TranslateService): string {
        return this.getDisplayValue(translate);
    }

    readonly label = '_i18n.ReportsQueue';

    serialize(): SerializedFilter {
        return this.pbxQueue == null ? {} : { queue: this.pbxQueue };
    }

    validate(): ValidationErrors | null {
        return null;
    }

    get value(): PbxQueueFilterParams {
        return { pbxQueue: this.pbxQueue };
    }

    set value(value: PbxQueueFilterParams) {
        this.pbxQueue = value.pbxQueue;
    }
}
