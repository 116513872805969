import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AppliedFilter } from '@office/standalones/odata-search';
import { DialogComponent } from '@webclient/modal/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ModalButtons } from '@webclient/modal/message-box';
import { Observable, of } from 'rxjs';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import {
    MyUserApiService,
    PbxReportScheduleType,
    PbxScheduledReport,
    PbxScheduledReportType,
    ScheduledReportsApiService
} from '@xapi';
import { CheckOption } from '@webclient/fields/types';
import { getReportScheduleTypeTranslation } from '@office/reports/helpers';
import { catchError, map } from 'rxjs/operators';

@Component({
    selector: 'app-schedule-report-dialog',
    template: `
        <app-modal-dialog [header]="report?.Name || '_i18n.SaveReport'" [validateBeforeSubmit]="validateBeforeSubmit" [submit$]="submit$" [buttons]="buttons" [formGroup]="form" (submitted)="onSubmit()">
            <field-input [maxlength]="50" formControlName="name" label="_i18n.Name" placeholder="_i18n.ScheduledReportNamePlaceholder">
                <val-errors controlName="name"></val-errors>
            </field-input>

            <field-select data-qa="scheduleType" [options]="scheduleTypeOptions" formControlName="scheduleType">
                <val-errors controlName="scheduleType">
                    <ng-template valError="scheduledReportsNoEmail">{{ '_i18n.SchedulerReportsNoEmailAddressWarning' | translate}}</ng-template>
                </val-errors>
            </field-select>

<!--            <p class="mb-2">{{"_i18n.ScheduleReportMessageAboutFilters" | translate }}</p>-->
<!--            <table>-->
<!--                <tr *ngFor="let filterLabel of filterLabels">-->
<!--                    <td>{{filterLabel.label | translate}}:</td>-->
<!--                    <td class="ps-3">{{filterLabel.value}}</td>-->
<!--                </tr>-->
<!--            </table>-->
        </app-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleReportDialogComponent extends DialogComponent implements OnInit {
    readonly buttons = ModalButtons.OkCancel;

    // either this parameter is supplied
    @Input() report: PbxScheduledReport;

    // or these parameters
    @Input() reportFilterQueryParameter = 'filter';
    @Input() reportFilter: AppliedFilter;
    @Input() reportParams: string = JSON.stringify({});
    @Input() reportType: PbxScheduledReportType;

    readonly scheduleTypeOptions: CheckOption<PbxReportScheduleType>[] = [
        PbxReportScheduleType.NotScheduled,
        PbxReportScheduleType.Monthly,
        PbxReportScheduleType.Weekly,
        PbxReportScheduleType.Daily,
    ].map(value => ({ value, label: getReportScheduleTypeTranslation(value) }));

    readonly form = new FormGroup({
        name: new FormControl('', { nonNullable: true, validators: [Validators.required] }),
        scheduleType: new FormControl(PbxReportScheduleType.NotScheduled, { nonNullable: true }),
    });

    constructor(
        private translate: TranslateService,
        private cd: ChangeDetectorRef,
        private api: ScheduledReportsApiService,
        private myUserApiService: MyUserApiService,
    ) {
        super();
    }

    // get filterLabels() {
    //     if (this.report) {
    //         return this.report.FilterDescription!.split(',\n').map(part => {
    //             const [label, value] = part.split(': ');
    //             return { label, value };
    //         });
    //     }
    //     return this.reportFilter?.labels ?? [];
    // }

    private scheduleReportsEmailValidator(hasEmailAddress: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const isScheduledType = [PbxReportScheduleType.Daily, PbxReportScheduleType.Weekly, PbxReportScheduleType.Monthly].includes(control.value as PbxReportScheduleType);
            return !!control.value && !hasEmailAddress && isScheduledType ? { scheduledReportsNoEmail: true } : null;
        };
    }

    ngOnInit() {
        this.myUserApiService.getMyUser({
            $select: ['EmailAddress']
        }).pipe(
            map(user => Boolean(user.EmailAddress)),
            catchError(() => of(false)),
        ).subscribe(
            (hasEmailAddress) => {
                this.form.controls.scheduleType.setValidators(this.scheduleReportsEmailValidator(hasEmailAddress));
                if (this.report) {
                    this.form.patchValue({
                        name: this.report.Name,
                        scheduleType: this.report.ScheduleType,
                    });
                    this.form.updateValueAndValidity();
                    this.form.markAllAsTouched();
                }
                this.cd.markForCheck();
            }
        );
    }

    readonly validateBeforeSubmit = this.createDialogValidate(this.form, this.cd);

    readonly submit$ = (): Observable<unknown> => {
        const { name, scheduleType } = this.form.value;

        if (this.report) {
            return this.api.updateScheduledReport({
                id: this.report.Id!,
                pbxScheduledReport: { Name: name, ScheduleType: scheduleType }
            });
        }

        const filterQuery = this.reportFilterQueryParameter + '=' + encodeURIComponent(JSON.stringify(this.reportFilter.serializedFilter));
        // store the url after the hash without the origin so that it can be dynamically reconstructed afterwards
        const reportLink = '/' + location.hash.split('?')[0] + '?' + filterQuery;
        const filterDescription = this.reportFilter.labels
            .map(item => `${this.translate.instant(item.label)}: ${item.value}`)
            .join(',\n');

        return this.api.createScheduledReport({
            pbxScheduledReport: {
                ReportType: this.reportType,
                ReportLink: reportLink,
                FilterDescription: filterDescription,
                Name: name,
                ScheduleType: scheduleType,
                ReportParams: this.reportParams
            }
        });
    };
}
