import { Pipe, PipeTransform } from '@angular/core';
import { map, Observable, of } from 'rxjs';
import { basePathUrl } from '@webclient/standalones/avatar/url-utils';
import { userTransparentImage, userImage } from '@webclient/assets';
import { MyPhoneService } from '@webclient/myphone/myphone.service';

@Pipe({ name: 'appContactImage$', standalone: true })
export class AppContactImagePipe implements PipeTransform {
    constructor(private myPhone: MyPhoneService) {
    }

    transform(contactImage: string, colorfulDefault = false): Observable<string> {
        return contactImage
            ? this.myPhone.myPhoneSession.pipe(map(({ domainUrl }) => basePathUrl(domainUrl, contactImage)))
            : of(colorfulDefault ? userImage : userTransparentImage);
    }
}
