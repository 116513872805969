import { asyncScheduler, Observable } from 'rxjs';
import { observeOn, subscribeOn } from 'rxjs/operators';
import { MyPhoneSession } from './myphone-session';
import { WebSocketFactory } from './websocket-factory';
import { NgZone } from '@angular/core';
import { enterZone, leaveZone } from '@webclient/myphone/zone-utils';
import { MyphoneMessageEncoder } from '@webclient/myphone/myphone-message-encoder';
import { bootstrapNotificationChanel } from '@webclient/myphone/bootstrap-notification-chanel.func';

export function startNotificationChannel(zone: NgZone, _encoder: MyphoneMessageEncoder, session: MyPhoneSession, factory: WebSocketFactory): Observable<any> {
    return bootstrapNotificationChanel(_encoder, session.sessionParam, session, factory, session.domainUrl).pipe(
        observeOn(enterZone(zone, asyncScheduler)),
        subscribeOn(leaveZone(zone, asyncScheduler)),
    );
}
