import { map } from 'rxjs/operators';
import dayjs from 'dayjs';

import { ConferenceParticipant, ConferenceState } from '@myphone';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {
    audioConferenceBaseUrl,
    dateFormat,
    newConferenceUrl,
    privateConferenceBaseUrl
} from '@webclient/meeting/meeting-consts';

enum PayloadType{
    privateConf,
    audioConf,
    webMeeting,
    newConf
}

export class ConferenceWrapper {
    payloadType: PayloadType;
    payload: any;
    privateConfName: string;
    _startAtDate: dayjs.Dayjs;
    _endAtDate: dayjs.Dayjs;

    get label() {
        if (this.isAudio || this.isWebMeeting) {
            return this.audioConference.Name;
        }
        else if (this.isPrivate) {
            return this.privateConfName;
        }
        if (this.isNew) {
            return '_i18n.NewConference';
        }
        else {
            return '';
        }
    }

    getPinCode() {
        return this.audioConference && this.audioConference.PinCode;
    }

    get routerLink() {
        if (this.isAudio || this.isWebMeeting) {
            return [audioConferenceBaseUrl, this.audioConference.Id];
        }
        else if (this.isPrivate) {
            return [privateConferenceBaseUrl];
        }
        else if (this.isNew) {
            return [newConferenceUrl];
        }
        return [];
    }

    get routerNavigateParams() {
        return this.routerLink;
    }

    get startAtDate(): dayjs.Dayjs | undefined {
        if (this._startAtDate) {
            return this._startAtDate;
        }
        if ((this.isAudio || this.isWebMeeting) && this.audioConference.StartAt) {
            this._startAtDate = dayjs.utc(this.audioConference.StartAt);
        }
        return this._startAtDate;
    }

    get endAtDate(): dayjs.Dayjs | undefined {
        if (this._endAtDate) {
            return this._endAtDate;
        }
        if ((this.isAudio || this.isWebMeeting) && this.audioConference.EndAt) {
            this._endAtDate = dayjs.utc(this.audioConference.EndAt);
        }
        return this._endAtDate;
    }

    get startAt(): string {
        const date = this.startAtDate;
        return date ? date.local().format(dateFormat) : '';
    }

    get isActive(): boolean {
        if (this.isPrivate) {
            return true;
        }
        else if (this.isAudio || this.isWebMeeting) {
            return this.audioConference.IsActive;
        }
        return false;
    }

    get description(): string {
        if (this.isAudio || this.isWebMeeting) {
            return this.audioConference.Description;
        }
        else {
            return '';
        }
    }

    // This method only for audio or private conferences
    get audioOrPrivateParticipants(): ConferenceParticipant[] {
        if (this.isPrivate) {
            return this.privateConferenceParticipants;
        }
        else if (this.isAudio || this.isWebMeeting) {
            const participantsObj = this.audioConference.Participants;
            if (participantsObj && participantsObj.Items) {
                return this.audioConference.Participants.Items;
            }
        }

        // In case that is not private or video. Or Participants.Items undefined
        return [];
    }

    get isPrivate(): boolean {
        return this.payloadType === PayloadType.privateConf;
    }

    get isWebMeeting(): boolean {
        return this.payloadType === PayloadType.webMeeting;
    }

    get isAudio(): boolean {
        return this.payloadType === PayloadType.audioConf;
    }

    get isNew(): boolean {
        return this.payloadType === PayloadType.newConf;
    }

    // isStarted will be set for audio conferences in service
    isStarted = false;

    get audioConference(): ConferenceState {
        return this.payload as ConferenceState;
    }

    get privateConferenceParticipants(): ConferenceParticipant[] {
        return this.payload as ConferenceParticipant[];
    }

    private static newWrapper(payloadType: PayloadType, payload: any) {
        const wrapper = new ConferenceWrapper();
        wrapper.payloadType = payloadType;
        wrapper.payload = payload;
        return wrapper;
    }

    // This use hold private conference - IsPrivate = true
    static forPrivateConference(translateService : TranslateService, privateConferenceParticipants: ConferenceParticipant[])
        : Observable<ConferenceWrapper> {
        return translateService.get('_i18n.ConfPrivateConferenceTitle').pipe(
            map(name => {
                const wrapper = ConferenceWrapper.newWrapper(PayloadType.privateConf, privateConferenceParticipants);
                wrapper.privateConfName = name;
                return wrapper;
            }));
    }

    static forAudioConference(state: ConferenceState): ConferenceWrapper {
        return ConferenceWrapper.newWrapper(PayloadType.audioConf, state);
    }

    static forWebmeeting(state: ConferenceState): ConferenceWrapper {
        return ConferenceWrapper.newWrapper(PayloadType.webMeeting, state);
    }

    static forNewConference(): ConferenceWrapper {
        return ConferenceWrapper.newWrapper(PayloadType.newConf, null);
    }

    hasSameContent(other: ConferenceWrapper) {
        if (this.payloadType !== other.payloadType) {
            return false;
        }
        else if (this.isPrivate) {
            return true;
        }
        else if (this.isNew) {
            return true;
        }
        else if (this.isAudio || this.isWebMeeting) {
            return this.audioConference.Id === other.audioConference.Id;
        }
        else {
            console.error('Unexpected input hasSameContent', this, other);
            return false;
        }
    }
}
