import { Component, HostBinding, Input } from '@angular/core';
import { Chat } from '@webclient/chat/chat';
import { ChatParticipant } from '@webclient/chat/chat-participant';
import { anonymousBridgeId } from '@webclient/myphone/app-contact-type';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { providerImageFullLink } from '@webclient/shared/utils.service';
import { map } from 'rxjs/operators';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';

@Component({
    selector: 'provider-image',
    template: `
        <i class="provider me-1" *ngIf="isAnonymous; else image" app-live-chat-custom-icon></i>
        <ng-template #image>
        <i *ngIf="providerImage | async as src" class="provider me-1">
            <img *ngIf="!hasLoadError" width="18" [src]="src" (error)="onloadError()" alt="">
        </i>
        </ng-template>
    `,
    styles: [
        `
            :host:empty { display: none; }
            .provider {
                display: inline-block;
                width: 18px;
                height: 18px;
            }
            img { background: transparent; }
        `
    ],
    standalone: true,
    imports: [CommonModule, IconsModule]
})
export class ProviderImageComponent {
    @Input() public chat: Chat|undefined;
    // participant input field override basic chat field
    @Input() public participant: ChatParticipant|undefined;

    @HostBinding('class.d-none')
    public hasLoadError = false;

    get provider() {
        return this.participant?.provider || this.chat?.destination?.provider;
    }

    public constructor(private myPhoneSvc: MyPhoneService) {

    }

    public readonly providerImage = this.myPhoneSvc.myPhoneSession.pipe(map(session => {
        const provider = this.provider;
        return providerImageFullLink(provider?.imageIdent, session);
    }));

    public get isAnonymous() {
        return this.provider?.type === anonymousBridgeId;
    }

    public onloadError() {
        this.hasLoadError = true;
    }
}
