import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalButtons } from '@webclient/modal/message-box';
import { DialogComponent } from '@webclient/modal/dialog';
import { ForwardingProfileService } from '@webclient/shared/forwarding-profile.service';
import type { DisplayedProfile } from '@webclient/shared/displayed-profile';

@Component({
    selector: 'app-set-profile-status-dialog',
    template: `
        <app-modal-dialog header="_i18n.SetStatusMessage" [buttons]="buttons" [submit$]="submit$" [autofocus]="true">
            <field-input data-qa="custom-message" [formControl]="customMessage" label="_i18n.CustomStatus" [maxlength]="128">
            </field-input>
        </app-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetProfileStatusDialogComponent extends DialogComponent<string> implements OnInit {
    @Input() currentProfile: DisplayedProfile;

    readonly buttons = ModalButtons.OkCancel;
    readonly customMessage = new FormControl('', { nonNullable: true });

    constructor(private profileService: ForwardingProfileService,) {
        super();
    }

    ngOnInit() {
        this.customMessage.setValue(this.currentProfile.customMessage || '');
    }

    readonly submit$ = () => this.profileService.setProfileStatus$(this.currentProfile, this.customMessage.value || '');
}
