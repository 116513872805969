import { SelectedMediaDevice } from '@webclient/phone/device-media.service';
import { chooseDevice } from '@webclient/webrtc/media-tools';

export class DtmfPlayer {
    static counter = 0;
    private oscillator1: OscillatorNode;
    private oscillator2: OscillatorNode;
    private audio: HTMLAudioElement = new Audio();

	constructor(private audioContext: AudioContext, private outputAudioDevice: SelectedMediaDevice | undefined) {
	}

	playTone(freq1 : number, freq2 : number) {
		this.stop();

        const destination = this.audioContext.createMediaStreamDestination();
        let gainNode = this.audioContext.createGain();
        gainNode.gain.value = .1;

        this.oscillator1 = this.audioContext.createOscillator();
        this.oscillator1.type = 'sine';
        this.oscillator1.frequency.value = freq1;
        this.oscillator1.connect(gainNode,0,0);
        gainNode.connect(destination);

        this.oscillator2 = this.audioContext.createOscillator();
        this.oscillator2.type = 'sine';
        this.oscillator2.frequency.value = freq2;
        this.oscillator2.connect(gainNode);
        gainNode.connect(destination);

        this.audio.srcObject = destination.stream;

        this.trySetOutputDeviceSink().then(() => {
            this.audio.play().catch(() => {
                // Suppress error here when fast typing DTMFs
            })
            this.oscillator1.start(0);
            this.oscillator2.start(0);
        }).catch((err: any) => console.error(err));
    }

    async trySetOutputDeviceSink(): Promise<void> {
        const audioSink = this.audio as any;
        // try to set the sink on the selected output audio device
        if (this?.outputAudioDevice?.labelId && this.outputAudioDevice?.media) {
            const label = this.outputAudioDevice?.labelId;
            /**
             * Workaround: re-select device because id might be already changed
             * if reprovision called
             */
            const devices = await navigator.mediaDevices.enumerateDevices();
            const selectedMediaDevice = chooseDevice(devices, 'audiooutput', label);
            if (selectedMediaDevice?.media?.deviceId) {
                if (audioSink.setSinkId) {
                    await audioSink.setSinkId(selectedMediaDevice.media.deviceId);
                }
            }
        }
    }

	play(key : string) {
		switch (String(key)) {
			case '1':
				this.playTone(697, 1209);
				break;
			case '2':
				this.playTone(697, 1336);
				break;
			case '3':
				this.playTone(697, 1477);
				break;
			case '4':
				this.playTone(770, 1209);
				break;
			case '5':
				this.playTone(770, 1336);
				break;
			case '6':
				this.playTone(770, 1477);
				break;
			case '7':
				this.playTone(852, 1209);
				break;
			case '8':
				this.playTone(852, 1336);
				break;
			case '9':
				this.playTone(852, 1477);
				break;
			case '*':
				this.playTone(941, 1209);
				break;
			case '0':
				this.playTone(941, 1336);
				break;
			case '#':
				this.playTone(941, 1477);
				break;
			case 'A':
				this.playTone(697, 1633);
				break;
			case 'B':
				this.playTone(770, 1633);
				break;
			case 'C':
				this.playTone(852, 1633);
				break;
			case 'D':
				this.playTone(941, 1633);
				break;
		}
	}

	stop() {
		if (this.oscillator1) {
			this.oscillator1.disconnect();
		}
		if (this.oscillator2) {
			this.oscillator2.disconnect();
		}
	}

	close () {
		if (this.audioContext) {
			this.audioContext.close();
		}
        if(this.audio){
            this.audio.srcObject = null;
        }
	}

}
