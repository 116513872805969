import { NgModule } from '@angular/core';
import { ProvisionQrcodeComponent as ProvisioningQrCodeComponent } from './provision-qrcode.component';
import { QrcodeComponent } from '@webclient/standalones/qrcode';
import { PageComponent } from '@webclient/standalones/layout';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        QrcodeComponent,
        PageComponent,
        ModalDialogComponent,
        IconsModule
    ],
    exports: [
        ProvisioningQrCodeComponent,
    ],
    declarations: [
        ProvisioningQrCodeComponent,
    ],
})
export class QrcodeModule {

}
