export enum ModalResult {
    Ok = 1,
    Cancel = 0,
    Custom = 2
}

export enum ModalButtons {
    Ok = 1,
    Cancel = 2,
    OkCancel = 3,
    Custom = 4,
    OkCustom = 5,
    CancelCustom = 6,
    OkCancelCustom = 7,
    Back = 8,
    BackOk = 9,
    Next = 16,
    BackNext = 24
}
