import { Component, OnInit } from '@angular/core';
import { SplashService } from '../splash.service';

@Component({
    selector: 'standalone-view',
    template: `
    <default-validation-errors></default-validation-errors>
    <div class="login">
        <div class="container w-xxl">
            <div class="p-3 text-center">
                <a href="{{'_i18n.LoginLink' | translate}}" target="_blank" class="logo standalone-logo" show-3cx-logo-icon></a>
            </div>
            <ng-content></ng-content>
        </div>
    </div>`,
    styleUrls: ['./standalone-view.component.scss']

})
export class StandaloneViewComponent implements OnInit {
    constructor(private splashService: SplashService) { }

    ngOnInit() {
        this.splashService.remove();
    }
}
