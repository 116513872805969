import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CallButtonsComponent } from './call-buttons.component';
import { CallViewComponent } from './call-view.component';
import { CallControlComponent } from './call-control.component';
import { CallerInfoComponent } from './caller-info.component';
import { CallsBarComponent } from './calls-bar.component';
import { DialerViewComponent } from './dialer-view.component';
import { CallDialpadComponent } from './call-dialpad.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DurationModule } from '@webclient/duration/duration.module';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { CallSearchListComponent } from '@webclient/call/call-search-list.component';
import { VideoOutputComponent } from '@webclient/call/video-output.component';
import { VideoOnlyPipe } from '@webclient/call/video-only.pipe';
import { SrcObjectDirective } from '@webclient/call/src-object.directive';
import { ScrollingLineComponent } from '@webclient/call/scrolling-line.component';
import { FullscreenControlButtonsComponent } from '@webclient/call/fullscreen-control-buttons.component';
import { AudioSinkComponent } from '@webclient/call/audio-sink.component';
import { NgxLoadingModule } from 'ngx-loading';
import { SharedModule } from '@webclient/shared/shared.module';
import { DialerSelectPhoneComponent } from '@webclient/call/dialer-select-phone.component';
import { MultipleIncomingCallsComponent } from '@webclient/call/multiple-incoming-calls.component';
import { BlfModule } from '@webclient/call-blf/blf.module';
import { NullToEmptyPipe, StripUserAgentPipe } from '@webclient/standalones/pipes';

@NgModule({
    declarations: [
        VideoOutputComponent,
        VideoOnlyPipe,
        SrcObjectDirective,
        ScrollingLineComponent,
        FullscreenControlButtonsComponent,
        AudioSinkComponent,
        CallButtonsComponent,
        CallViewComponent,
        CallControlComponent,
        CallerInfoComponent,
        CallsBarComponent,
        DialerViewComponent,
        CallDialpadComponent,
        CallSearchListComponent,
        DialerSelectPhoneComponent,
        MultipleIncomingCallsComponent,
    ],
    exports: [
        CallControlComponent,
    ],
    imports: [
        BlfModule,
        NgxLoadingModule,
        SharedModule,
        IconsModule,
        DurationModule,
        TranslateModule,
        FormsModule,
        CommonModule,
        NullToEmptyPipe,
        StripUserAgentPipe
    ]
})
export class CallModule {
}
