import {
    Component, ElementRef, Input, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import {
    combineLatest, EMPTY, from, fromEvent, Observable, of, Subscription
} from 'rxjs';
import {
    distinctUntilChanged, filter, map, switchMap
} from 'rxjs/operators';
import { ModalService } from '../modal/app-modal.service';
import { SelectedMediaDevice } from '../phone/device-media.service';
import { chooseDevice } from '@webclient/webrtc/media-tools';

@Component({
    selector: 'audio-sink',
    template: `
        <audio #sink [loop]="loop" [src]="src" autoplay [srcObject]="srcObject"></audio>
    `
})
export class AudioSinkComponent implements OnInit, OnDestroy {
    @Input() loop = false;
    @Input() sink: Observable<SelectedMediaDevice> = EMPTY;
    @Input() src: any;
    @Input() srcObject: any;
    @ViewChild('sink', { static: true }) audioSink!: ElementRef;

    private sinkSubscriber: Subscription|undefined;

    public constructor(private modalService: ModalService) {}

    ngOnInit(): void {
        const element = this.audioSink.nativeElement as HTMLAudioElement;
        this.sinkSubscriber = fromEvent(element, 'loadeddata').pipe(
            switchMap((x: Event) => {
                return combineLatest([
                    this.sink.pipe(distinctUntilChanged()),
                    of(x.target as any).pipe(filter(Boolean))
                ]);
            }),
            switchMap(([selectedDevice, audio]) => {
                if (selectedDevice.labelId && selectedDevice.media) {
                    const label = selectedDevice.labelId;
                    /**
                     * Workaround: re-select device because id might be already changed
                     * if reprovision called
                     */
                    return from(navigator.mediaDevices.enumerateDevices()).pipe(
                        map(devices => chooseDevice(devices, 'audiooutput', label)),
                        switchMap(device => {
                            return device.media ? from(audio.setSinkId(device.media.deviceId)) : EMPTY;
                        })
                    );
                }
                else {
                    return EMPTY;
                }
            })
        ).subscribe({
            error: (error: unknown) => {
                this.modalService.error(error);
            }
        });
    }

    ngOnDestroy(): void {
        this.sinkSubscriber?.unsubscribe();
    }
}
