import type { FilterValue, SerializedFilter } from '@office/standalones/odata-search/types';
import { TranslateService } from '@ngx-translate/core';
import { ValidationErrors } from '@angular/forms';
import { CfgItemTypeList, getCfgTypeTranslationKey } from '@office/reports/audit-logs/audit-logs-enums';

export interface AuditObjTypeFilterParams {
    auditObjTypeIndex: number | null;
}

function isAuditObjType(value: unknown): value is AuditObjTypeFilterParams['auditObjTypeIndex'] {
    return value != null && typeof value === 'number' && CfgItemTypeList.some(({ id }) => id === value);
}

const QUERY_PARAM_AUDIT_OBJ_TYPE_INDEX = 'auditObjTypeIndex';

export class AuditObjTypeFilterValue implements FilterValue<AuditObjTypeFilterParams> {
    private auditObjTypeIndex: AuditObjTypeFilterParams['auditObjTypeIndex'] = null;

    get value(): AuditObjTypeFilterParams {
        return { auditObjTypeIndex: this.auditObjTypeIndex };
    }

    set value(value: AuditObjTypeFilterParams) {
        this.auditObjTypeIndex = value.auditObjTypeIndex === -1 ? null : value.auditObjTypeIndex;
    }

    readonly label = '_i18n.ReportsAuditObjectType';

    deserialize(serializedValue: SerializedFilter): FilterValue<AuditObjTypeFilterParams> {
        return AuditObjTypeFilterValue.deserialize(serializedValue, this.value);
    }

    getDisplayValue(translate: TranslateService): string {
        const value = this.value;

        return translate.instant(getCfgTypeTranslationKey(value.auditObjTypeIndex ?? undefined));
    }

    getDisplayValueForPrint(translate: TranslateService): string {
        return this.getDisplayValue(translate);
    }

    serialize(): SerializedFilter {
        const value = this.value;

        return value.auditObjTypeIndex == null ? {} : { [QUERY_PARAM_AUDIT_OBJ_TYPE_INDEX]: value.auditObjTypeIndex };
    }

    validate(): ValidationErrors | null {
        return null;
    }

    static deserialize(serializedValue: SerializedFilter, initialValue?: AuditObjTypeFilterParams): FilterValue<AuditObjTypeFilterParams> {
        const result = new AuditObjTypeFilterValue();
        if (initialValue) {
            result.value = initialValue;
        }
        if (QUERY_PARAM_AUDIT_OBJ_TYPE_INDEX in serializedValue && isAuditObjType(serializedValue[QUERY_PARAM_AUDIT_OBJ_TYPE_INDEX])) {
            result.auditObjTypeIndex = serializedValue[QUERY_PARAM_AUDIT_OBJ_TYPE_INDEX] ?? -1;
        }
        return result;
    }
}
