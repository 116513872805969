import { Injectable } from '@angular/core';
import { DisplayedProfile } from './displayed-profile';
import { MyPhoneService } from '../myphone/myphone.service';
import { TranslateService } from '@ngx-translate/core';
import {
    ActionType,
    ForwardDestination,
    ForwardDestinationType,
    ForwardingProfile, ForwardingProfiles,
    MyExtensionInfo,
    RequestChangeStatus, RequestUpdateFwdProfile,
    ResponseSystemParameters
} from '@myphone';
import { MyPhoneSession } from '../myphone/myphone-session';
import { combineLatest, Observable, of, ReplaySubject, share } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { getCustomProfileName, ProfileNames } from '@webclient/myphone/profile';

const spaceRegex = new RegExp(/ /g);

@Injectable()
export class ForwardingProfileService {
    public readonly statuses$: Observable<DisplayedProfile[]>;
    public currentStatus$: Observable<DisplayedProfile | undefined>;
    public readonly visibleProfiles$: Observable<ForwardingProfile[]>;
    private readonly systemParameters$: Observable<ResponseSystemParameters>;

    static readonly translationKeys: Record<string, string> = {
        [ProfileNames.Available]: '_i18n.Available',
        [ProfileNames.Away]: '_i18n.Away',
        [ProfileNames.OutOfOffice]: '_i18n.DND',
        [ProfileNames.Exceptions]: '_i18n.Exceptions'
    };

    constructor(private myphoneSvc: MyPhoneService, private translate: TranslateService) {
        this.visibleProfiles$ = this.getFwdProfiles();

        this.systemParameters$ = this.myphoneSvc.myPhoneSession.pipe(
            switchMap(session => combineLatest([session.myInfo$, session.systemParameters$])),
            map(x => x[1]));

        this.statuses$ = this.visibleProfiles$.pipe(switchMap(visibleProfiles => {
            const profiles: Array<Observable<DisplayedProfile>>
                = visibleProfiles.map(profile => this.convertForwardingProfile(profile));

            return combineLatest(profiles);
        }),
        catchError((err: unknown) => {
            console.log(err);
            return of([]);
        }));

        this.currentStatus$ = combineLatest([
            myphoneSvc.myPhoneSession.pipe(switchMap(session => session.myInfo$)),
            this.statuses$
        ]).pipe(
            map(([myInfo, statuses]) => {
                const currentProfile = myInfo.HasCurrentProfileOverride ? myInfo.CurrentProfileOverride : myInfo.CurrentProfile;
                return statuses.find(x => x.id === currentProfile);
            }),
            catchError((err: unknown) => {
                return of(new DisplayedProfile());
            })
        );
    }

    public profilePresentation(status: DisplayedProfile) {
        return status.customMessage.trim() ? `${status.name} - ${status.customMessage}` : status.name;
    }

    public toId(name: string) {
        return name.replace(spaceRegex, '');
    }

    public selectStatus(status: DisplayedProfile) {
        const requestChangeStatus = new RequestChangeStatus();
        requestChangeStatus.ProfileId = status.id;

        this.myphoneSvc.get(requestChangeStatus).subscribe({
            error: (err: unknown) => {
                console.log(err);
            }
        });
    }

    /** Get all display names for profiles. Given customProfiles are used to get profile name overrides. */
    getProfileDisplayNames$(customProfiles: Pick<ForwardingProfile, 'Name' | 'CustomName'>[] = []): Observable<Record<ProfileNames, string>> {
        return combineLatest([
            this.translate.get(Object.values(ForwardingProfileService.translationKeys)),
            this.systemParameters$
        ]).pipe(
            map(([translations, systemParameters]) => {
                const memo = {} as Record<ProfileNames, string>;

                Object.values(ProfileNames).forEach(profileName => {
                    const translationKey = ForwardingProfileService.translationKeys[profileName];

                    memo[profileName as ProfileNames] = translationKey
                        ? translations[translationKey]
                        : getCustomProfileName(profileName, customProfiles.find(p => p.Name === profileName)?.CustomName || '', systemParameters);
                });
                return memo;
            })
        );
    }

    public convertForwardingProfile(profile: ForwardingProfile): Observable<DisplayedProfile> {
        const translationKey: any = ForwardingProfileService.translationKeys[profile.Name];

        if (translationKey !== undefined) {
            // Needs translation
            return this.translate.get(translationKey).pipe(
                map(translation => new DisplayedProfile({
                    id: profile.Id,
                    name: translation,
                    internalName: profile.Name,
                    customMessage: profile.CustomMessage,
                })));
        }
        else {
            // Translation is not needed
            return this.systemParameters$.pipe(map(systemParameters => {
                return new DisplayedProfile({
                    id: profile.Id,
                    internalName: profile.Name,
                    customMessage: profile.CustomMessage,
                    name: getCustomProfileName(profile.Name, profile.CustomName, systemParameters)
                });
            }));
        }
    }

    public getFwdExceptionProfileForwardedToMobile$(): Observable<ForwardingProfile| undefined> {
        const mobile$ = this.myphoneSvc.myPhoneSession.pipe(switchMap((session: MyPhoneSession) => session.myInfo$),
            map((myInfo: MyExtensionInfo) => myInfo.MobileNumber));
        return combineLatest([mobile$, this.getFwdProfiles(true)]).pipe(
            map(([mobile, profiles]) => {
                if (!mobile) {
                    return undefined;
                }
                const isUsingMobile = (dest: ForwardDestination) => {
                    return dest.FwdType === ForwardDestinationType.FD_External && dest.Number === mobile;
                };

                return profiles.find((p: ForwardingProfile) => {
                    return p.Exceptions && p.Exceptions.Exceptions.some(e => isUsingMobile(e.Destination));
                });
            }));
    }

    public getFwdProfileWhichForwardsToMobile$(): Observable<ForwardingProfile| null> {
        const mobile$ = this.myphoneSvc.myPhoneSession.pipe(switchMap((session: MyPhoneSession) => session.myInfo$),
            map((myInfo: MyExtensionInfo) => myInfo.MobileNumber));

        return combineLatest([mobile$, this.getFwdProfiles(true)]).pipe(
            map(([mobile, profiles]) => {
                if (!mobile) {
                    return null;
                }

                const isUsingMobile = (dest: ForwardDestination) => {
                    return dest.FwdType === ForwardDestinationType.FD_External && dest.Number === mobile;
                };

                const profile = profiles.find((p: ForwardingProfile) => {
                    if (p.Available) {
                        const av = p.Available;

                        return av.RingMyMobile
                            || [
                                av.NoAnswerExternal,
                                av.NoAnswerInternal,
                                av.NotRegisteredExternal,
                                av.NotRegisteredInternal,
                                av.BusyExternal,
                                av.BusyInternal
                            ].some(isUsingMobile);
                    }
                    if (p.Away) {
                        return [p.Away.ExternalRule, p.Away.InternalRule].some(isUsingMobile);
                    }
                    if (p.Exceptions) {
                        return p.Exceptions.Exceptions.some(e => isUsingMobile(e.Destination));
                    }

                    return false;
                });

                return profile || null;
            }));
    }

    public getFwdProfiles(includeExceptions = false): Observable<ForwardingProfile[]> {
        return this.myphoneSvc.myPhoneSession.pipe(
            switchMap((session: MyPhoneSession) => session.myInfo$),
            map((myInfo: MyExtensionInfo) => myInfo?.MyProfiles?.Items),
            filter(Boolean),
            map((profiles: ForwardingProfile[]) => {
                const result: ForwardingProfile[] = [];

                const addProfile = (name: string) => {
                    const p = profiles.find(x => x.Name === name);
                    if (p) {
                        result.push(p);
                    }
                };

                // Sort & filter
                addProfile(ProfileNames.Available);
                addProfile(ProfileNames.Away);
                addProfile(ProfileNames.OutOfOffice);
                addProfile(ProfileNames.Custom1);
                addProfile(ProfileNames.Custom2);

                if (includeExceptions) {
                    addProfile('Exceptions');
                }

                return result;
            }),
            catchError((err: unknown) => {
                console.log(err);
                return of([] as ForwardingProfile[]);
            }),
            share({ connector: () => new ReplaySubject<ForwardingProfile[]>(1) }));
    }

    setProfileStatus$(currentProfile: DisplayedProfile, customMessage: string) {
        const profiles = new ForwardingProfiles();
        profiles.Action = ActionType.Updated;

        const profile = new ForwardingProfile();
        profile.Id = currentProfile.id;
        profile.CustomMessage = customMessage;
        profile.Action = ActionType.Updated;

        profiles.Items = [profile];

        const request = new RequestUpdateFwdProfile();
        request.Update = profiles;

        return this.myphoneSvc.myPhoneSession.pipe(take(1), switchMap(session => session.get(request)));
    }
}
