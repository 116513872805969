/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PbxUpdateType = 'Release' | 'Beta' | 'MajorRelease' | 'Alpha' | 'Hotfix';

export const PbxUpdateType = {
    Release: 'Release' as PbxUpdateType,
    Beta: 'Beta' as PbxUpdateType,
    MajorRelease: 'MajorRelease' as PbxUpdateType,
    Alpha: 'Alpha' as PbxUpdateType,
    Hotfix: 'Hotfix' as PbxUpdateType
};

