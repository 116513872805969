import { NgModule } from '@angular/core';
import { CardComponent } from './card.component';
import { CardsDeckComponent } from './cards-deck.component';
import { MoveCardDirective } from './move-card.directive';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        CardComponent,
        CardsDeckComponent,
        MoveCardDirective,
    ],
    exports: [
        CardComponent,
        CardsDeckComponent,
        MoveCardDirective,
    ]
})
export class DeckOfCardModule {

}
