import { AppContact } from '../../myphone/contact';

export enum AnnotatedResultType {
    none = 0,
    internalNumber = 1,
    voicemail = 2, // That is also internal number
    externalNumber = 3,
    email = 4,
    chatAddress = 5,
}

export class AnnotatedResult {
    public assignResult: string;

    constructor(public readonly type: AnnotatedResultType,
                public readonly contact: AppContact,
                public readonly searchText?: string,
                assignResult?: string) {
        if (assignResult !== undefined) {
            this.assignResult = assignResult;
        }
        else if (searchText !== undefined) {
            this.assignResult = searchText;
        }
        else {
            this.assignResult = '';
        }
    }
}
