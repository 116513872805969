// TODO: redesign this shit
import { WebSocketError } from '@webclient/myphone/create-webscoket-notification-chanel.func';

export class WorkerResponseError {
    public readonly statusText: string;
    public type = 'shared-worker-error';
    public url = location.origin;
    public status = 900;
    constructor(init: Partial<WorkerResponseError> | Response | any) {
        if (init instanceof Response) {
            this.statusText = init.statusText;
            this.type = init.type;
            this.url = init.url;
            this.status = init.status;
        }
        else if (init instanceof CloseEvent) {
            if (init.currentTarget instanceof WebSocket) {
                this.url = init.currentTarget.url;
            }
            this.statusText = init.reason;
            this.status = init.code;
        }
        else if (init instanceof WebSocketError) {
            this.status = init.status;
            this.statusText = init.message;
        }
        else if (init && init.message) {
            this.statusText = init.message;
        }
        else {
            Object.assign(this, init);
        }
    }
}
