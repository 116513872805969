import { Injectable } from '@angular/core';
import { AppContact } from '../myphone/contact';
import { ChatLocatorService } from '../chat-data/chat-locator.service';
import { PhoneService } from '../phone/phone.service';
import { MyPhoneService } from '../myphone/myphone.service';
import { webMeetingInitializationDelay } from '../meeting/meeting.service';
import { localBridgeId } from '../myphone/app-contact-type';
import { InstantMeetingService } from '../meeting/instant-meeting.service';
import { ModalService } from '../modal/app-modal.service';
import {
    ActionType,
    RequestAssignExtension,
    RequestChangeStatus,
    RequestCleanExtension,
    RequestUpdateFavorites
} from '@myphone';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { DeviceService } from '../phone/device.service';
import { delay, take } from 'rxjs/operators';
import { NewGroupChat } from '../myphone/new-group-chat';
import { Router } from '@angular/router';
import { defaultTeamLink, LocalStorageKeys } from '@webclient/settings/local-storage-keys';
import { LocalStorage } from 'ngx-webstorage';
import { clickLink, openUrlWithMessageBoxIfPopupBlocked } from '@webclient/shared/utils.service';
import { NavigationHistoryService } from '@webclient/navigation-history.service';

@Injectable()
export class PeopleService {
    @LocalStorage(LocalStorageKeys.SavePeople, defaultTeamLink)
    peopleLink: any;

    public lastSearchedText = '';
    private readonly _currentExtension$ = new BehaviorSubject<AppContact|undefined>(undefined);
    readonly isVideoCallEnabled$ : Observable<boolean>;

    constructor(private phoneService: PhoneService,
                private chatLocatorService: ChatLocatorService,
                private myPhoneService: MyPhoneService,
                private locatorService: ChatLocatorService,
                deviceService: DeviceService,
                private modalService: ModalService,
                private meetingService: InstantMeetingService,
                private navigationService :NavigationHistoryService,
                private router: Router) {
        this.isVideoCallEnabled$ = deviceService.isWebRTCSelected$;
    }

    get currentExtension$() {
        return this._currentExtension$.asObservable();
    }

    viewExtension(contact: AppContact) {
        this._currentExtension$.next(contact);
        this.router.navigate(['people/view']);
    }

    // Context menu is Visible
    public hasMoreActions(contact: AppContact): boolean {
        return this.isLocal(contact) || this.hasEmail(contact) || this.hasMobile(contact) || this.isReceptionist(contact);
    }

    navigatePreviousUrl() {
        this.navigationService.navigatePreviousUrl$(this.peopleLink instanceof Array ? this.peopleLink : [this.peopleLink]).pipe(
            take(1)
        ).subscribe(() => {
            this._currentExtension$.next(undefined);
        });
    }

    navigatePeople() {
        this.router.navigate(this.peopleLink instanceof Array ? this.peopleLink : [this.peopleLink]).then(() => {
            this._currentExtension$.next(undefined);
        });
    }

    public callToExtensionNumber(contact: AppContact, videoCall?: boolean) {
        if (contact === undefined || contact.phones.extension === undefined) {
            return;
        }
        this.phoneService.call(contact.phones.extension, videoCall);
    }

    public chatWithExtension(contact: AppContact): Subscription | undefined {
        if (contact === undefined || contact.phones.extension === undefined) {
            return undefined;
        }

        return this.chatLocatorService.openChat(new NewGroupChat([contact]));
    }

    public meetingWithExtension({ contact }: { contact: AppContact; }): Subscription {
        const newWebMeeting$ = this.meetingService.createWebmeetingWithContact(contact);
        return newWebMeeting$.pipe(
            delay(webMeetingInitializationDelay))
            .subscribe({
                next: x => {
                    openUrlWithMessageBoxIfPopupBlocked(x.myJoinLink, this.modalService, '_i18n.PopupBlockedWebmeeting', '_i18n.OpenWebmeeting');
                },
                error: (error: unknown) => this.modalService.error(error)
            });
    }

    public addToBuddyList(contact: AppContact): void {

    }

    public isLocal(contact: AppContact) : boolean {
        return contact.bridgeId === localBridgeId && !contact.myContact;
    }

    public leaveVoicemail(contact: AppContact) {
        this.myPhoneService.myPhoneSession.pipe(take(1)).subscribe(session => {
            this.phoneService.call(session.systemParameters.VmailDialCode + contact.phones.extension);
        });
    }

    public intercom(contact: AppContact) {
        this.myPhoneService.myPhoneSession.pipe(take(1)).subscribe(session => {
            this.phoneService.call(session.systemParameters.IntercomDialCode + contact.phones.extension);
        });
    }

    public canWebmeet(contact: AppContact) : boolean {
        return contact && (!!contact.emailAddress || contact.bridgeId === localBridgeId);
    }

    public hasEmail(contact: AppContact) : boolean {
        return contact && !!contact.emailAddress;
    }

    public email(contact: AppContact) {
        clickLink('mailto:' + contact.emailAddress);
    }

    public hasMobile(contact: AppContact) : boolean {
        return contact && !!contact.phones.mobile;
    }

    public getMobilePhone(contact: AppContact) : string|undefined {
        if (!this.hasMobile(contact)) {
            return undefined;
        }
        return contact.phones.mobile;
    }

    public getNativeMobilePhone(contact: AppContact) : string|undefined {
        if (!this.hasMobile(contact)) {
            return undefined;
        }
        return contact.nativeMobileNumber;
    }

    public callMobile(contact: AppContact) {
        const phone = this.getMobilePhone(contact);
        if (phone) {
            this.phoneService.call(phone);
        }
    }

    // We need this, because context menu library, calling callbacks with 'undefined' 'this'
    public static IsFavorite(contact: AppContact) {
        return contact.isFavorite;
    }

    public isFavorite(contact: AppContact) {
        return PeopleService.IsFavorite(contact);
    }

    // Yes, we need this - we can't write '!isFavorite(...)' in context menu library template
    public isNotFavorite(contact: AppContact) {
        return !PeopleService.IsFavorite(contact);
    }

    public updateFavorites(actionType: ActionType, contacts: AppContact[]) {
        const req = new RequestUpdateFavorites();
        req.Action = actionType;
        req.ExtensionNumbers = contacts.map(c => c.extensionNumber);
        this.myPhoneService.get(req).subscribe({
            error: (resp: unknown) => {
                this.modalService.error(resp);
            }
        });
    }

    public addToFavorites(contact: AppContact): void {
        this.updateFavorites(ActionType.Inserted, [contact]);
    }

    public removeFromFavorites(contact: AppContact): void {
        this.updateFavorites(ActionType.Deleted, [contact]);
    }

    public setStatus(extensionNumber: string, internalProfileName: string): Observable<any> {
        const reqChangeStatus = new RequestChangeStatus({
            TargetExtension: extensionNumber
        });
        reqChangeStatus.InternalProfileName = internalProfileName;

        return this.myPhoneService.get(reqChangeStatus);
    }

    // / Assign
    public assignExtension(extensionNumber:string, extension: { firstName: string; lastName: string; mobile: string; email: string }) {
        return this.myPhoneService.get(new RequestAssignExtension({
            TargetExtension: extensionNumber,
            FirstName: extension.firstName,
            LastName: extension.lastName,
            Email: extension.email,
            Mobile: extension.mobile
        }));
    }

    // Clear
    public clearExtension(extensionNumber: string): Observable<any> {
        const req = new RequestCleanExtension({ TargetExtension: extensionNumber });
        return this.myPhoneService.get(req);
    }

    // canWeSetReminder$
    public isReceptionist(contact: AppContact): boolean {
        if (!contact || contact.bridgeId !== localBridgeId) {
            return false;
        }
        return contact.groups.some(group => group.groupId.AssignClearOperations);
    }

    public isLocalContact(contact: AppContact) {
        return contact.bridgeId === localBridgeId;
    }

    public isExtensionLocal(contact: AppContact): boolean {
        return this.isLocalContact(contact) && !contact.myContact;
    }

    public isAddToFavorites(contact: AppContact): boolean {
        return this.isExtensionLocal(contact) && !this.isFavorite(contact);
    }

    public isRemoveFromFavorites(contact: AppContact): boolean {
        return this.isExtensionLocal(contact) && this.isFavorite(contact);
    }
}
