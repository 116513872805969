import { ChatPartyInfo, RequestGetMyLastMessages, RequestGetQMLastMessages, ResponseMyMessages } from '@myphone';
import { Chat } from '../chat';
import { MyPhoneSession } from '../../myphone/myphone-session';
import { ChatUpdater } from '@webclient/chat/chat-service/chat-accessor.service';
import { ChatListUpdater } from '@webclient/chat/chat-service/chat-list.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export class UpdateChatInfo implements ChatUpdater, ChatListUpdater {
    public constructor(private isNew: boolean, private readonly update: ChatPartyInfo, private readonly session: MyPhoneSession, private readonly updateOnly?: boolean, private readonly isMonitoring = false) {}

    public updateChatList(chatList: Chat[]) {
        const newArray = [...chatList];
        const oldChatIndex = chatList.findIndex(chat => chat.id === this.update.IdConversation);
        if (oldChatIndex >= 0) {
            // Update existing chat
            newArray.splice(oldChatIndex, 1, this.updateChat(chatList[oldChatIndex]));
        }
        else if (!this.updateOnly) {
            // Add new chat
            if (this.isNew) {
                newArray.push(Chat.FromPartyInfo(this.update, this.session));
            }
            else {
                return this.session.get<ResponseMyMessages>(
                    !this.isMonitoring ? new RequestGetMyLastMessages({
                        Count: 1,
                        IdConversation: this.update.IdConversation,
                    }) : new RequestGetQMLastMessages({
                        Count: 1,
                        IdConversation: this.update.IdConversation,
                    })
                ).pipe(
                    catchError(() => of(new ResponseMyMessages())),
                    map(lastMessage => {
                        newArray.push(Chat.FromPartyInfo(this.update, this.session, lastMessage.Messages[0]));
                        return newArray;
                    })
                );
            }
        }
        return of(newArray);
    }

    public updateChat(chat: Chat): Chat {
        return Chat.FromPartyInfo(this.update, this.session).merge({
            hasUnread: chat.hasUnread,
            unreadCount: chat.unreadCount,
            lastSended: chat.lastSended,
            lastMessageObject: chat.lastMessageObject
        });
    }
}
