import {
    Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import {
    NEVER, Subject, Subscription, timer as observableTimer
} from 'rxjs';
import {
    finalize, switchMap, withLatestFrom
} from 'rxjs/operators';
import { DtmfPlayer } from '@webclient/third-party/play-dtmf/DtmfPlayer';
import { DeviceMediaService } from '@webclient/phone/device-media.service';

@Component({
    selector: 'call-dialpad',
    template: `
        <button class="btnNum" [disabled]="disabled" id="dialpad-1" (click)="onButtonPress('1')" type="button">
            <div class="btnNumDigit">1</div>
            <div class="btnNumText">&nbsp;</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-2" (click)="onButtonPress('2')" type="button">
            <div class="btnNumDigit">2</div>
            <div class="btnNumText">ABC</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-3" (click)="onButtonPress('3')" type="button">
            <div class="btnNumDigit">3</div>
            <div class="btnNumText">DEF</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-4" (click)="onButtonPress('4')" type="button">
            <div class="btnNumDigit">4</div>
            <div class="btnNumText">GHI</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-5" (click)="onButtonPress('5')" type="button">
            <div class="btnNumDigit">5</div>
            <div class="btnNumText">JKL</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-6" (click)="onButtonPress('6')" type="button">
            <div class="btnNumDigit">6</div>
            <div class="btnNumText">MNO</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-7" (click)="onButtonPress('7')" type="button">
            <div class="btnNumDigit">7</div>
            <div class="btnNumText">PQRS</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-8" (click)="onButtonPress('8')" type="button">
            <div class="btnNumDigit">8</div>
            <div class="btnNumText">TUV</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-9" (click)="onButtonPress('9')" type="button">
            <div class="btnNumDigit">9</div>
            <div class="btnNumText">WXYZ</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-*" (click)="onButtonPress('*')" type="button">
            <div class="btnNumDigit" style="font-size: 30px; margin-top: 3px;">*</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-0" (click)="onButtonPress('0')" type="button">
            <div class="btnNumDigit">0</div>
            <div class="btnNumText">+</div>
        </button>
        <button class="btnNum" [disabled]="disabled" id="dialpad-#" (click)="onButtonPress('#')" type="button">
            <div class="btnNumDigit" style="margin-bottom: 10px">#</div>
        </button>
        <ng-content></ng-content>
    `,
    styles: [
        `
            :host {
                width: 100%;
                display: grid;
                grid-column-gap: 0;
                grid-row-gap: 10px;
                grid-template-columns: repeat(3, minmax(33.3%, 33.3%));
            }

            .btnNum {
                display: inline-block;
                background-color: transparent;
                border: 0;
                padding: 0;
                height: 28px;
                color: var(--text-secondary);
            }

            .btnNum:hover {
                opacity: 0.7;
                transition: .2s;
            }

            .btnNum:active {
                color: var(--bg-secondary);
                transition: 0.1s;
            }

            .btnNumDigit {
                font-family: Arial, serif;
                font-size: 20px;
                line-height: 14px;
            }

            .btnNumText {
                font-family: Arial, serif;
                line-height: 14px;
                font-size: 9px;
            }
        `
    ],
})
export class CallDialpadComponent implements OnInit, OnDestroy {
    @Input()
    disabled: boolean;

    @Output()
    readonly buttonPressed = new EventEmitter<string>();

    private playSubscription: Subscription;
    readonly play$ = new Subject<string>();

    constructor(public deviceMediaService: DeviceMediaService,) {

    }

    onButtonPress(value: string) {
        this.play$.next(value);
        this.buttonPressed.next(value);
    }

    ngOnInit(): void {
        this.playSubscription = this.play$.pipe(
            withLatestFrom(this.deviceMediaService.selectedOutputAudioDevice$),
            switchMap(value => {
                const [soundValue, outputAudioDevice] = value;
                try {
                    if (/Edge/.test(navigator.userAgent)) {
                        // Edge has issues with AudioContext
                        return NEVER;
                    }
                    // Create DTMF player
                    const dtmfPlayer = new DtmfPlayer(new AudioContext(), outputAudioDevice);
                    dtmfPlayer.play(soundValue);
                    return observableTimer(200)
                        .pipe(finalize(() => {
                            // Anyway stop
                            dtmfPlayer.stop();
                            dtmfPlayer.close();
                        }));
                }
                catch (exception) {
                    // Can't play sound
                    console.error(exception);
                    return NEVER;
                }
            })).subscribe(() => {
            // Just ignore
        });
    }

    ngOnDestroy(): void {
        if (this.playSubscription) {
            this.playSubscription.unsubscribe();
        }
    }
}
