<ng-container *ngIf="!ext?.profilePicture && ext?.hasAvatarLetters; else profileImage">
    <p *ngIf="ext | appAvatarLetters$ | async as letters; else profileImage" class="avatar-circle"
       [style.background-color]="ext?.avatarColor" data-qa="avatar-letters">
        {{letters}}
    </p>
</ng-container>

<ng-template #profileImage>
    <img [alt]="ext?.bestPhone" [src]="ext?.profilePicture | appContactImage$ : true | async"
         (error)="profilePictureLoadingFailed()" data-qa="profile-image"/>
</ng-template>

<i *ngIf="ext?.statusCssClassName as statusCss; else addedByExternal" [class.big]="size !== 's'" class="bottom status-indicator {{statusCss}}"
   data-qa="status-indicator"></i>

<ng-template #addedByExternal>
    <ng-container [ngSwitch]="ext?.addedBy">
        <span *ngSwitchCase="ContactAddedByEnum.AB_Crm" class="added-by-external text-icon"
              data-qa="added-by-crm">CRM</span>
        <span *ngSwitchCase="ContactAddedByEnum.AB_Office365" class="added-by-external svg-wrapper fillIconWhite"
              app-office365-custom-icon data-qa="added-by-office-365"></span>
    </ng-container>
</ng-template>
