import { ContactViewModel } from '../../phonebook-editor/contact-view-model';
import { Component, HostBinding, Input, ViewChild } from '@angular/core';
import { ModalButtons } from '../message-box';
import { PhonebookContactEditorComponent } from '../../phonebook-editor/phonebook-contact-editor.component';
import { DialogComponent } from '@webclient/modal/dialog';
import { ContactViewMode } from '@webclient/phonebook-editor/contact-view-mode';

@Component({
    template: `
        <app-modal-dialog header="_i18n.MenuAddContact" [buttons]="buttons"
                          [validateBeforeSubmit]="validateBeforeSubmit" (submitted)="onSubmit()">
            <contact-editor #contactEditor [vm]="contact" [mode]="mode"></contact-editor>
        </app-modal-dialog>
    `
})
export class PhonebookEditorDialogComponent extends DialogComponent<ContactViewModel> {
    readonly buttons = ModalButtons.OkCancel;

    @Input() contact: ContactViewModel;

    @Input() mode: ContactViewMode = ContactViewMode.Normal;

    @HostBinding('class.modal-lg')
    get isLargeDialog() {
        return this.mode !== ContactViewMode.Compact;
    }

    @ViewChild('contactEditor', { static: false })
    contactEditor!: PhonebookContactEditorComponent;

    validateBeforeSubmit = () => {
        return Boolean(this.contactEditor.obtainContact());
    };

    protected getPayload(): ContactViewModel | undefined {
        return this.contactEditor.obtainContact();
    }
}
