import { ConnectionCapabilities, DnType } from '@myphone';
import { LocalConnectionEx } from '../../myphone/local-connection-ex';

export class LocalConnectionActions {
    public readonly pickups: LocalConnectionEx[] = [];
    public readonly diverts: LocalConnectionEx[] = [];
    public readonly transfers: LocalConnectionEx[] = [];
    public readonly drops: LocalConnectionEx[] = [];
    public readonly bargeins: LocalConnectionEx[] = [];
    public readonly unparking: LocalConnectionEx[] = [];
    public readonly parking: LocalConnectionEx[] = [];
    public readonly callReports: LocalConnectionEx[] = [];

    constructor(isPushDevice: boolean, connections: LocalConnectionEx[], parkaccess: boolean, myNumber: string) {
        connections.forEach(lc => {
            if (lc == null) {
                return;
            }

            const caps: number = lc.CallCapabilitiesMask;
            if (caps !== 0) {
                // IVR connection which can be transferred should be listed in pickups.
                // but if connection is from extension - no any reason to allow this operation
                // pay attension that divert operation is allowed!!!
                if ((caps & ConnectionCapabilities.CC_Pickup) === ConnectionCapabilities.CC_Pickup || ((lc.OwnerType === DnType.IVR || lc.OwnerType === DnType.SpecialMenu) && (caps & ConnectionCapabilities.CC_Transfer) === ConnectionCapabilities.CC_Transfer)) {
                    if (lc.OtherPartyDn !== myNumber && lc.OwnerDn !== myNumber) {
                        this.pickups.push(lc);
                    }
                    // divert is allowed only if it has capabilities or call is redirected from the IVR/specialmenu with transfer capabilities
                    if (((lc.OwnerType === DnType.IVR || lc.OwnerType === DnType.SpecialMenu) && (caps & ConnectionCapabilities.CC_Transfer) === ConnectionCapabilities.CC_Transfer) || (caps & ConnectionCapabilities.CC_Divert) === ConnectionCapabilities.CC_Divert) {
                        this.diverts.push(lc);
                    }
                    // SY:fuzzy logic of pick-up menu requires fuzzy logic in code :(
                    // call of IVR can be replaced with parking place
                    if (lc.OwnerType === DnType.IVR || lc.OwnerType === DnType.SpecialMenu) {
                        this.parking.push(lc);
                    }
                }
                else if ((caps & ConnectionCapabilities.CC_Transfer) === ConnectionCapabilities.CC_Transfer && lc.OtherPartyType !== DnType.SpecialMenu) {
                    if (parkaccess && lc.OwnerType === DnType.Parking) {
                        // SY: definitelly subject for unpark. Mast not be listed as the transfer operation.
                        this.unparking.push(lc);
                        // SY: and parking place can be replaced with any other destination
                        this.transfers.push(lc);
                    }
                    else if (lc.OtherPartyType !== DnType.Parking && lc.OtherPartyType !== DnType.IVR) {
                        // may be transferred
                        this.transfers.push(lc);
                        // and
                        if (parkaccess && lc.OtherPartyDn !== myNumber) {
                            // can be parked
                            this.parking.push(lc);
                        }
                    }
                }
                if ((caps & ConnectionCapabilities.CC_Quality) === ConnectionCapabilities.CC_Quality) {
                    this.callReports.push(lc);
                }

                if ((caps & ConnectionCapabilities.CC_Drop) === ConnectionCapabilities.CC_Drop) {
                    this.drops.push(lc);
                }

                if (!isPushDevice && (caps & ConnectionCapabilities.CC_BargeIn) === ConnectionCapabilities.CC_BargeIn) {
                    this.bargeins.push(lc);
                }
            }
        });
    }
}
