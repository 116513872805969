import { ChatParticipant } from '@webclient/chat/chat-participant';
import { Record } from 'immutable';
import { NoConversationId } from '@webclient/chat/chat';

export function getChatLink(conversationId: number) {
    return `/chat/${conversationId}`;
}

export class PushChatMessage extends Record(<{
        id: number;
        text: string;
        eventTime: number;
        sender: string;
        conversationId: number;
        avatar?: string;
        participant?: ChatParticipant;
        onChatPage: boolean;
}>{
    id: -1,
    text: '',
    sender: '',
    eventTime: 0,
    conversationId: NoConversationId,
    avatar: undefined,
    participant: undefined,
    onChatPage: false
}) {
    get chatLink() {
        return getChatLink(this.conversationId);
    }
}
