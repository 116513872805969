import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Self } from '@angular/core';
import type { CheckOption } from '@webclient/fields/types';
import { ModalButtons } from '@webclient/modal/message-box';
import { ForwardingProfileService } from '@webclient/shared/forwarding-profile.service';
import { DisplayedProfile } from '@webclient/shared/displayed-profile';
import { FPStatusOverrideAction, RequestChangeStatus } from '@myphone';
import { map, Observable, of, takeUntil } from 'rxjs';
import { filter, startWith, switchMap, take } from 'rxjs/operators';
import { ProfileNames } from '@webclient/myphone/profile';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { toDate } from '@webclient/layout/header/override-office-hours-modal/override-hours-funcs';
import { TIME_INTERVAL_TRANSLATIONS, TimeIntervalMinutes } from '@webclient/standalones/pipes';
import { DialogComponent } from '@webclient/modal/dialog';
import { noEmitAndShowMessageOnError } from '@webclient/rx-utils';
import { ModalService } from '@webclient/modal/app-modal.service';
import { publishRef } from '@webclient/rx-share-utils';
import { FormControl, FormGroup } from '@angular/forms';
import { DestroyService } from '@webclient/services/destroy.service';

@Component({
    selector: 'app-set-temporary-profile-dialog',
    providers: [ForwardingProfileService, DestroyService],
    template: `
        <app-modal-dialog [buttons]="buttons" header="_i18n.TimeBasedStatus" [submit$]="submit$">
            <p>{{'_i18n.TimeBasedStatusDescription' | translate}}</p>

            <field-select
                label="_i18n.IdleChangeStatusTo"
                data-qa="idle-change-status-to"
                [options]="statusOptions$ | async"
                [formControl]="f.selectedStatus">
            </field-select>

            <field-select label="_i18n.For" data-qa="timeout" [options]="timeoutOptions" [formControl]="f.timeout">
            </field-select>

            <field-input label="_i18n.SetStatusMessage" data-qa="custom-message" [maxlength]="128"
                           [formControl]="f.customMessage">
                <val-errors [control]="f.customMessage"></val-errors>
            </field-input>

            <p *ngIf="statusOverride$|async as statusOverride">
                {{'_i18n.CurrentStatus'|translate}}: {{statusOverride.name}}
                {{'_i18n.Until' | translate | lowercase}} {{statusOverride.expiresAt | amDateFormat: 'L LT'}}
            </p>
        </app-modal-dialog>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetTemporaryProfileDialogComponent extends DialogComponent<RequestChangeStatus> {
    readonly buttons = ModalButtons.OkCancel;

    readonly form = new FormGroup({
        customMessage: new FormControl(''),
        timeout: new FormControl(TimeIntervalMinutes.M15),
        selectedStatus: new FormControl(ProfileNames.Away),
    });

    private readonly statusesExceptAvailable$: Observable<DisplayedProfile[]>;
    readonly statusOptions$: Observable<CheckOption<string>[]>;
    readonly statusOverride$: Observable<{ name: string; expiresAt: Date } | null>;

    readonly timeoutOptions: CheckOption<TimeIntervalMinutes>[] = [
        TimeIntervalMinutes.M15,
        TimeIntervalMinutes.M30,
        TimeIntervalMinutes.H1,
        TimeIntervalMinutes.H2,
        TimeIntervalMinutes.H12,
        TimeIntervalMinutes.D1,
        TimeIntervalMinutes.D2,
        TimeIntervalMinutes.W1,
    ].map(value => ({ value, label: TIME_INTERVAL_TRANSLATIONS[value] }));

    get f() {
        return this.form.controls;
    }

    constructor(
        @Self() profileService: ForwardingProfileService,
        @Self() destroy$: DestroyService,
        private myPhoneService: MyPhoneService,
        private modalService: ModalService,
        private cdr: ChangeDetectorRef
    ) {
        super();

        this.statusesExceptAvailable$ = profileService.statuses$.pipe(
            map(statuses => statuses.filter(status => status.internalName !== ProfileNames.Available)),
            publishRef()
        );

        this.statusOverride$ = myPhoneService.myPhoneSession.pipe(switchMap(s => s.myInfo$)).pipe(
            switchMap(myInfo => {
                if (myInfo.HasCurrentProfileOverride && myInfo.OverrideExpiresAtUTCTime) {
                    const expiresAt = toDate(myInfo.OverrideExpiresAtUTCTime);

                    if (expiresAt > new Date()) {
                        return this.statusesExceptAvailable$.pipe(map(statuses => {
                            const name = statuses.find(s => s.id === myInfo.CurrentProfileOverride)?.name;

                            return name ? { name, expiresAt } : null;
                        }));
                    }
                }
                return of(null);
            })
        );

        this.statusOptions$ = this.statusesExceptAvailable$.pipe(
            map(list => list.map(status => ({ value: status.internalName, label: status.name })))
        );

        this.f.selectedStatus.valueChanges.pipe(
            startWith(this.f.selectedStatus.value),
            switchMap((value) => this.getStatus$(value)),
            filter(Boolean),
            takeUntil(destroy$)
        ).subscribe(profile => {
            this.form.patchValue({ customMessage: profile.customMessage });
            this.cdr.markForCheck();
        });
    }

    submit$ = () => {
        return this.getStatus$().pipe(
            // should emit on empty status to close dialog
            switchMap(status => (status ? this.changeStatus$(status) : of(undefined))),
            noEmitAndShowMessageOnError(this.modalService)
        );
    };

    private changeStatus$(profile: DisplayedProfile) {
        const { customMessage, timeout } = this.form.value;
        const request = new RequestChangeStatus({
            ProfileId: profile.id,
            CustomMessage: customMessage!,
            OverrideProfileAction: FPStatusOverrideAction.FPOverride_SetAsOverrideProfile,
            OverrideExpiresSeconds: timeout! * 60,
            OverrideAttachedData: timeout!.toString(),
        });

        return this.myPhoneService.get(request);
    }

    private getStatus$(selectedStatus = this.form.value.selectedStatus): Observable<DisplayedProfile | undefined> {
        return this.statusesExceptAvailable$.pipe(take(1), map(list => list.find(s => s.internalName === selectedStatus)));
    }
}
