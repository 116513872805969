import type { CheckOption } from '@webclient/fields/types';
import { EnumFilterValue } from '@office/standalones/odata-search';

export enum CallAreaType {
    All,
    Internal,
    External,
}

const options: CheckOption<CallAreaType>[] = [
    { value: CallAreaType.All, label: '_i18n.ReportsCallAreaTypeAll' },
    { value: CallAreaType.Internal, label: '_i18n.ReportsCallAreaTypeInternal' },
    { value: CallAreaType.External, label: '_i18n.ReportsCallAreaTypeExternal' },
];

export interface CallAreaFilterParams {
    callArea: CallAreaType | null
}

export function newCallAreaFilterValue() {
    return new EnumFilterValue('_i18n.ReportsCallType', 'callArea', options);
}
