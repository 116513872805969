import { Component } from '@angular/core';
import { DialogComponent } from '@webclient/modal/dialog';
import { ContactCreationResult, ContactCreationStatus, ContactCreationType } from './contact-creation-result';
import { take } from 'rxjs/operators';

@Component({
    selector: 'app-contact-creation-error',
    template: `
        <app-modal-dialog header="_i18n.ContactCreationError" [scrollable]="true" (submitted)="onSubmit()">
            <p class="mb-2" [translate]="'_i18n.ContactCreationErrorLabel'"></p>
            <ng-container *ngIf="contactCreationResults.length > 0">
                <div class="contact-result-grid">
                    <span translate="_i18n.Contacts"></span>
                    <span translate="_i18n.Status"></span>
                </div>
                <div *ngFor="let result of contactCreationResults; let index = index"
                     class="contact-result-grid" [attr.data-qa]="'result-'+ index">
                    <div class="d-flex gap-2 align-items-center">
                        <ng-container [ngSwitch]="result.status">
                            <i *ngSwitchCase="ContactCreationStatus.Failure"
                               class="customSVGIcons svg-sm fillIconRed ms-1" app-exclamation-triangle-regular-icon></i>
                            <i *ngSwitchCase="ContactCreationStatus.Success"
                               class="customSVGIcons svg-sm fillIconGreen ms-1" app-check-regular-icon></i>
                            <i *ngSwitchCase="ContactCreationStatus.Retrying"
                               class="customSVGIcons svg-sm fillIconBlue ms-1 retrying" app-rotate-regular-icon></i>
                            <span *ngSwitchDefault>-</span>
                        </ng-container>
                        <span data-qa="contact-type">{{creationTypeLabel(result) | translate}}</span>
                    </div>
                    <div class="d-flex gap-1 align-items-center justify-content-between" data-qa="status-message">
                        <span>{{statusMessage(result) | translate}}</span>
                        <button *ngIf="result.status === ContactCreationStatus.Failure && result.failedRequest$"
                                (click)="retryRequest(index)"
                                data-qa='retry' class='btn btn-plain-sm link' type='button'
                                title="{{'_i18n.QrcodeRetry' | translate}}" app-rotate-regular-icon>
                        </button>
                    </div>
                </div>
            </ng-container>
        </app-modal-dialog>
    `,
    styles: [`
        .contact-result-grid {
            width: 100%;
            display: grid;
            align-items: center;
            grid-template-columns: 3fr 5fr;
            grid-column-gap: 0.5rem;
            padding: 0.5rem 0;
            border-bottom: 1px solid var(--border-color);
        }
    `]
})
export class ContactCreationErrorComponent extends DialogComponent<ContactCreationResult[]> {
    contactCreationResults: ContactCreationResult[] = [];
    ContactCreationStatus = ContactCreationStatus;

    creationTypeLabel(creationResult: ContactCreationResult) {
        switch (creationResult.type) {
            case ContactCreationType.Personal:
                return '_i18n.PbtypePersonal';
            case ContactCreationType.Company:
                return '_i18n.Company';
            case ContactCreationType.Crm:
                return '_i18n.CRM';
            case ContactCreationType.Microsoft365:
                return '365';
        }
        return '';
    }

    statusMessage(creationResult: ContactCreationResult) {
        switch (creationResult.status) {
            case ContactCreationStatus.Failure:
                return '_i18n.ContactCreationErrorMessage';
            case ContactCreationStatus.Success:
                return '_i18n.AddedSuccessfully';
            case ContactCreationStatus.Retrying:
                return '_i18n.Retrying';
            default:
                return '';
        }
    }

    retryRequest(resultIndex: number) {
        const result = this.contactCreationResults[resultIndex];
        if (result && result.failedRequest$) {
            result.status = ContactCreationStatus.Retrying;
            result.failedRequest$.pipe(take(1)).subscribe({
                // will always have next since the source transforms any error thrown to ContactCreationResult
                next: (newContactCreationResult) => {
                    this.contactCreationResults[resultIndex] = newContactCreationResult;
                },
            });
        }
    }

    protected getPayload(): ContactCreationResult[] {
        return this.contactCreationResults;
    }
}
