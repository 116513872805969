<app-modal-dialog [header]="header || '_i18n.ChangeProfile'" (submitted)="onSubmit()" [buttons]="buttons">
    <div class="d-flex justify-content-around">
        <div class="cropArea">
            <img-cropper [image]="myCroppedImage" [settings]="cropperSettings" #cropper></img-cropper>
        </div>
        <div class="d-none d-sm-flex d-md-flex d-lg-flex flex-column align-items-center justify-content-center">
            <p [translate]="'_i18n.Preview'" class="break-w" style="max-width: 105px;">Preview</p>
            <div class="avatar thumb">
                <img [src]="myCroppedImage.image"
                     [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight"/>
            </div>
        </div>
    </div>
</app-modal-dialog>
