import { SharedWorkerResponse } from '@webclient/worker/protocol/shared-worker-response';
import { WorkerTypeMessage } from '@webclient/worker/protocol/worker-type-message';
import { SharedWorkerRequest } from '@webclient/worker/protocol/shared-worker-request';
import { GenericMessageType } from '@webclient/shared/myphone-types';
import { GenericMessage } from '@myphone';

export function workerTypeMessageMapperFunc(response: WorkerTypeMessage): GenericMessageType {
    const typeMessage = new WorkerTypeMessage(response);
    return new GenericMessage.typeMap[typeMessage.ofType](typeMessage.message);
}

export function sharedWorkerMyphoneMapperFunc(response: SharedWorkerResponse<any> | SharedWorkerRequest): GenericMessageType {
    return workerTypeMessageMapperFunc(response.payload);
}
