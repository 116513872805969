<div
    *ngIf="currentPage !== null && totalItems !== null && itemsPerPage !== null && totalItems > (showPageSizes ? PAGE_SIZES[0] : itemsPerPage)"
    data-qa="paging"
    class='pagination-container d-flex align-items-center justify-content-center flex-wrap w-100 my-2 gap-1'
    [class.small]="isSmall">
    <pagination (pageChanged)="pageChanged.emit($event)"
                [ngModel]="currentPage"
                [rotate]="true"
                [maxSize]="maxVisiblePages"
                [itemsPerPage]="itemsPerPage!"
                [totalItems]="totalItems!"
                [boundaryLinks]="areBoundaryLinksVisible"
                [directionLinks]="totalPages > 1"
                [ngClass]="{ disabled: totalPages <= 1 }"
                [customFirstTemplate]="firstTemplate" [customLastTemplate]="lastTemplate"
                [customPreviousTemplate]="prevTemplate" [customNextTemplate]="nextTemplate">
    </pagination>

    <ng-template #firstTemplate>
        <ng-container *ngIf="showFirstPageLink">
            <span class="page-link-content">1</span>
            <span *ngIf="showFirstPageLinkEllipsis" class="page-link-ellipsis">...</span>
        </ng-container>
    </ng-template>
    <ng-template #prevTemplate>
        <i *ngIf="isSmall" class="customSVGIcons svg-xs fillIconWhite" app-chevron-left-solid-icon></i>
        <ng-container *ngIf="!isSmall">
            <span class="d-none d-sm-block">{{'_i18n.Previous'| translate}}</span>
            <i class="customSVGIcons svg-xs d-sm-none" app-chevron-left-solid-icon></i>
        </ng-container>
    </ng-template>
    <ng-template #nextTemplate>
        <i *ngIf="isSmall" class="customSVGIcons svg-xs fillIconWhite" app-chevron-right-solid-icon></i>
        <ng-container *ngIf="!isSmall">
            <span class="d-none d-sm-block">{{'_i18n.Next'| translate}}</span>
            <i class="customSVGIcons svg-xs d-sm-none" app-chevron-right-solid-icon></i>
        </ng-container>
    </ng-template>
    <ng-template #lastTemplate>
        <ng-container *ngIf="showLastPageLink">
            <span class="page-link-ellipsis" *ngIf="showLastPageLinkEllipsis">...</span>
            <span class="page-link-content"> {{totalPages}}</span>
        </ng-container>
    </ng-template>

    <ng-container *ngIf="showPageIndexes">
        <small>{{pageIndexes.startIndex}}
            - {{pageIndexes.endIndex}} {{'_i18n.RadDataPagerOf'| translate}} {{totalItems}}</small>
    </ng-container>

    <ng-select class="page-sizes" *ngIf="showPageSizes" [formControl]="pageSizeControl" [clearable]="false"
               [searchable]="false"
               [dropdownPosition]="itemsOnThisPage >= PAGE_SIZES.length ? 'top' : 'bottom'" appendTo=".page-sizes">
        <ng-option *ngFor="let size of PAGE_SIZES" [value]="size">{{size}}</ng-option>
    </ng-select>
</div>
