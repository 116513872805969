import { Component, OnDestroy, OnInit } from '@angular/core';
import { MyPhoneService } from '../myphone/myphone.service';
import { CallControlService } from '../call/call-control.service';
import { ModalService } from '../modal/app-modal.service';
import { Subscription } from 'rxjs';
import { DialerService } from '../call/dialer.service';
import { DeviceService } from '../phone/device.service';
import { SplashService } from '../splash.service';
import {
    distinctUntilChanged, filter, map
} from 'rxjs/operators';
import { DialerVisibilityService } from '@webclient/call-adapter/dialer-visibility.service';
import { PhoneService } from '@webclient/phone/phone.service';
import { LocalConnectionState } from '@myphone';
import { DialerVisibilityInfo } from '@webclient/call-adapter/dialer-visibility-info';
import { SilentModeService } from '@webclient/layout/silent-mode.service';

@Component({
    template: `
        <div class="theme-dark bg-dialer-dark d-flex justify-content-center align-items-center h-100">
            <div class="arrow-container">
                <app-call-control class="application-wrapper"></app-call-control>
            </div>
        </div>
    `,
    providers: [CallControlService],
    styleUrls: ['./web-dialer.component.scss', './standalone-dialer.component.scss']
})
export class StandaloneDialerComponent implements OnInit, OnDestroy {
    private readonly subs = new Subscription();

    public constructor(
        private callControlService: CallControlService,
        private myPhoneService: MyPhoneService,
        private dialerService: DialerService,
        private modalService: ModalService,
        private deviceService: DeviceService,
        private silentModeService: SilentModeService,
        private splashService: SplashService,
        private phoneService: PhoneService,
        private dialerVisibilityService: DialerVisibilityService,
    ) {
    }

    ngOnInit() {
        this.myPhoneService.connect();
        this.splashService.remove();
        this.subs.add(
            this.phoneService.myCalls$.pipe(
                map((myCalls) => {
                    if (myCalls.hasNewCalls) {
                        const dialingCall = myCalls.calls.find(call => call.state === LocalConnectionState.Dialing || call.isTrying);
                        const ringingCall = myCalls.calls.find(call => call.state === LocalConnectionState.Ringing);
                        return !!dialingCall || (!!ringingCall);
                    }
                    return false;
                }),
                distinctUntilChanged(),
                filter((hasNewDialingCall) => hasNewDialingCall)
            ).subscribe(() => {
                // focus on dialer
                this.dialerVisibilityService.dialerVisibilityInfo.next(new DialerVisibilityInfo(true, true));
            }));
    }

    ngOnDestroy() {
        this.myPhoneService.disconnect();
        this.subs.unsubscribe();
    }
}
