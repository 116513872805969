// Service
import { Observable, Subject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

/**
 * Service encapsulating destroy logic.
 *
 * Usage:
 * class MyDirective {
 *     constructor(@Self() destroy$: DestroyService) {
 *         observable.pipe(takeUntil(destroy$)).subscribe(() => { ... }))
 *     }
 * }
 * */
@Injectable()
export class DestroyService extends Observable<void> implements OnDestroy {
    private readonly _life$ = new Subject<void>();

    constructor() {
        super(subscriber => this._life$.subscribe(subscriber));
    }

    ngOnDestroy() {
        this._life$.next();
        this._life$.complete();
    }
}
