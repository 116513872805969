/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PbxReportScheduleType = 'Daily' | 'Weekly' | 'Monthly' | 'Hourly' | 'NotScheduled';

export const PbxReportScheduleType = {
    Daily: 'Daily' as PbxReportScheduleType,
    Weekly: 'Weekly' as PbxReportScheduleType,
    Monthly: 'Monthly' as PbxReportScheduleType,
    Hourly: 'Hourly' as PbxReportScheduleType,
    NotScheduled: 'NotScheduled' as PbxReportScheduleType
};

