import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SmsPhoneNumberModalComponent } from './sms-phone-number-modal/sms-phone-number-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValdemortModule } from 'ngx-valdemort';
import { ModalDialogComponent } from '@webclient/standalones/modal-dialog/modal-dialog.component';
import { FieldsModule } from '@webclient/fields/fields.module';

@NgModule({
    declarations: [
        SmsPhoneNumberModalComponent,
    ],
    imports: [
        TranslateModule,
        FormsModule,
        IconsModule,
        ModalModule,
        CommonModule,
        ReactiveFormsModule,
        ValdemortModule,
        ModalDialogComponent,
        FieldsModule
    ]
})
export class SmsModule { }
