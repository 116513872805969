import { ChangeDetectionStrategy, Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { toDataURL as QrCodeToDataUrl } from 'qrcode';
import { ReplaySubject } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-qrcode',
    template: '<img *ngIf="src$ | async as src" [src]="src" alt="qrcode">',
    styles: [':host { display: block; } img { max-width: 100%; }'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule]
})
export class QrcodeComponent implements OnChanges {
    @Input() url?: string | null;

    @HostListener('contextmenu', ['$event'])
    onContextMenu(event: MouseEvent) {
        event.preventDefault();
    }

    readonly src$ = new ReplaySubject<string>();

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes.url) {
            return;
        }
        if (!this.url) {
            this.src$.next('');
            return;
        }
        QrCodeToDataUrl(this.url, { errorCorrectionLevel: 'H' }, (err: unknown, url: string) => {
            this.src$.next(url ?? '');
            if (err) {
                console.error(err);
            }
        });
    }
}
