import { MyPhoneSession } from '../myphone-session';

import { Queue } from '../queue';
import { JoinTool } from './join';
import { ActionType, QueueAgent, QueueAgents, Queues, QueueStat } from '@myphone';
import { Agent } from '@webclient/myphone/agent';
import { AppContact } from '@webclient/myphone/contact';
import { AppContactType } from '@webclient/myphone/app-contact-type';

export class QueuesJoin {
    constructor(private session: MyPhoneSession) {

    }

    public Merge(queues: Queues) {
        let queueListChanged = false;

        switch (queues.Action) {
            case ActionType.Deleted:
                queueListChanged = true;
                this.deleteQueues();
                break;
            case ActionType.FullUpdate:
                queueListChanged = true;
                this.deleteQueues();
                if (queues.Items) {
                    queues.Items.forEach(queue => this.addQueue(queue));
                }
                break;
            case ActionType.Inserted:
                queueListChanged = true;
                if (queues.Items) {
                    queues.Items.forEach(queue => this.addQueue(queue));
                }
                break;
            case ActionType.Updated:
                if (queues.Items) {
                    queues.Items.forEach(queue => {
                        if (this.updateQueue(queue)) {
                            queueListChanged = true;
                        }
                    });
                }
                break;
        }

        if (queueListChanged) {
            this.session.onQueuesChanged();
        }
    }

    private deleteQueues() {
        Object.values(this.session.queues).forEach(queue => this.deleteQueue(queue));
    }

    private deleteQueue(queue: Queue) {
        if (!queue) {
            return;
        }
        this.deleteAgents(queue);
        delete this.session.queues[queue.id];
    }

    private addQueue(queueStat: QueueStat) {
        const contactMatcher = this.session.contactMatcher;
        // Create new queue
        const queue = new Queue(queueStat.Id, queueStat.Number, queueStat.Name);
        const contact = this.createQueueContact(queueStat);
        this.session.queues[queueStat.Id] = queue;
        contactMatcher.queueContactDb.addPhone(queue.queueNumber, contact);
        this.updateQueueData(queue, queueStat);
    }

    private createQueueContact(queueStat: QueueStat) {
        return new AppContact(
            queueStat.Id.toString(),
            AppContactType.Extension,
            'local',
            {
                firstNameLastName: queueStat.Name,
                lastNameFirstName: queueStat.Name
            }
        );
    }

    private updateQueue(queueStat: QueueStat): boolean {
        let queueListChanged = false;
        const contactMatcher = this.session.contactMatcher;
        const originalQueue = this.session.queues[queueStat.Id];
        const contact = contactMatcher.queueContactDb.findById(originalQueue?.queueNumber);

        switch (queueStat.Action) {
            case ActionType.Deleted:
                this.deleteQueue(originalQueue);
                contactMatcher.queueContactDb.removeIfSelf(originalQueue?.queueNumber, contact);
                queueListChanged = true;
                break;
            case ActionType.FullUpdate:
                this.deleteQueue(originalQueue);
                contactMatcher.queueContactDb.removeIfSelf(originalQueue?.queueNumber, contact);
                this.addQueue(queueStat);
                contactMatcher.queueContactDb.addPhone(queueStat.Number, this.createQueueContact(queueStat));
                queueListChanged = true;
                break;
            case ActionType.Inserted:
                this.addQueue(queueStat);
                contactMatcher.queueContactDb.addPhone(queueStat.Number, this.createQueueContact(queueStat));
                queueListChanged = true;
                break;
            case ActionType.Updated:
            // ACHTUNG SERVER BUG: server sends update on non-existing object
                if (originalQueue) {
                    this.updateQueueData(originalQueue, queueStat);
                    contactMatcher.queueContactDb.removeIfSelf(originalQueue?.queueNumber, contact);
                    contactMatcher.queueContactDb.addPhone(queueStat.Number, this.createQueueContact(queueStat));
                }
                break;
        }

        return queueListChanged;
    }

    private updateQueueData(queue: Queue, queueStat: QueueStat) {
        if (queueStat.Agents) {
            this.joinAgents(queue, queueStat.Agents);
        }
        if (queueStat.Stat) {
            if (queueStat.Stat.Action === ActionType.Deleted) {
                // Should we do it?
                // queue.stat = undefined;
            }
            // TODO check for full update?
            else {
                queue.stat = queue.stat ? JoinTool.Merge(queue.stat, queueStat.Stat) : queueStat.Stat;
            }
        }
        if (queueStat.Name !== undefined) {
            queue.name = queueStat.Name;
        }
        // JoinTool.Merge(queue, queueStat);
        // Join calls into CallDb
        queue.callDb.updateConnections(queueStat.WaitingCalls, []);
    }

    private joinAgents(queue: Queue, agents: QueueAgents) {
        if (agents === undefined) {
            return;
        }
        switch (agents.Action) {
            case ActionType.Deleted:
                this.deleteAgents(queue);
                break;
            case ActionType.FullUpdate:
                this.deleteAgents(queue);
                this.updateAgents(queue, agents);
                break;
            case ActionType.Inserted:
            case ActionType.Updated:
                this.updateAgents(queue, agents);
                break;
        }
    }

    private updateAgents(queue: Queue, agents: QueueAgents) {
        if (!agents.Items) {
            return;
        }
        switch (agents.Action) {
            case ActionType.Deleted:
                this.deleteAgents(queue);
                break;
            case ActionType.FullUpdate:
                this.deleteAgents(queue);
                this.updateAllAgents(queue, agents.Items);
                break;
            case ActionType.Inserted:
            case ActionType.Updated:
                this.updateAllAgents(queue, agents.Items);
                break;
        }
    }

    private updateAllAgents(queue: Queue, agents: QueueAgent[]) {
        if (!agents) {
            return;
        }
        agents.forEach(agent => {
            const originalAgent = queue.agents.find(x => x.id === agent.Id);
            switch (agent.Action) {
                case ActionType.Deleted:
                    this.deleteAgent(queue, originalAgent);
                    break;
                case ActionType.FullUpdate:
                    this.deleteAgent(queue, originalAgent);
                    this.updateAgent(queue, agent);
                    break;
                case ActionType.Inserted:
                case ActionType.Updated:
                    this.updateAgent(queue, agent);
                    break;
            }
        });
    }

    private deleteAgents(queue: Queue) {
        queue.agents.forEach(agent => this.deleteAgent(queue, agent));
        queue.agents = [];
    }

    private deleteAgent(queue: Queue, agent: Agent|undefined) {
        if (agent) {
            queue.callDb.processDeletedItems(agent.activeCalls.Items.map(item => item.Id));
            const index = queue.agents.findIndex(item => item.id === agent.id);
            if (index >= 0) {
                queue.agents.splice(index, 1);
            }
        }
    }

    private updateAgent(queue: Queue, agent: QueueAgent) {
        if (queue && agent) {
            queue.callDb.updateConnections(agent.ActiveCalls, []);
            let localAgent = queue.agents.find(item => item.id === agent.Id);
            if (!localAgent) {
                // Agent not found
                localAgent = new Agent(agent.Id);
                queue.agents.push(localAgent);
            }
            // Update agent fields
            let nameChanged = false;
            if (agent.FirstName !== undefined) {
                localAgent.firstName = agent.FirstName;
                nameChanged = true;
            }
            if (agent.LastName !== undefined) {
                localAgent.lastName = agent.LastName;
                nameChanged = true;
            }
            if (nameChanged) {
                localAgent.firstNameLastName = `${localAgent.firstName} ${localAgent.lastName}`.trim();
                localAgent.lastNameFirstName = `${localAgent.lastName} ${localAgent.firstName}`.trim();
            }
            if (agent.Number !== undefined) {
                localAgent.agentNumber = agent.Number;
            }
            if (agent.QueueStatus !== undefined) {
                localAgent.queueStatus = agent.QueueStatus;
            }
            if (agent.Stat) {
                if (agent.Stat.Action === ActionType.Deleted) {
                    localAgent.stat = undefined;
                }
                // TODO check for full update?
                else {
                    localAgent.stat = localAgent.stat ? JoinTool.Merge(localAgent.stat, agent.Stat) : agent.Stat;
                }
            }
            if (agent.ActiveCalls) {
                JoinTool.Merge(localAgent.activeCalls, agent.ActiveCalls);
            }
        }
    }
}
