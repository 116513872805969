import { DateTime, GroupHoursMode } from '@myphone';
import dayjs, { Dayjs } from 'dayjs';
import { OverrideHoursTimeout } from '@webclient/layout/header/override-office-hours-modal/override-hours-timeout';
import { PbxGroup, PbxGroupHoursMode } from '@xapi';

export function isOfficeHoursOverrideExpired(group: PbxGroup) : boolean {
    return (group.OverrideExpiresAt && isDateExpired(new Date(group.OverrideExpiresAt))) || group.CurrentGroupHours === PbxGroupHoursMode.Default;
}

export function toDate(dateInUTC: DateTime): Date {
    return new Date(Date.UTC(dateInUTC.Year, dateInUTC.Month - 1, dateInUTC.Day, dateInUTC.Hour, dateInUTC.Minute, dateInUTC.Second));
}

export function isDateExpired(date: Date) : boolean {
    return new Date() >= date;
}

export function convertToMyPhoneGroupHours(xapiGroupHoursMode: PbxGroupHoursMode | null | undefined) : GroupHoursMode {
    switch (xapiGroupHoursMode) {
        case 'Default':
            return GroupHoursMode.GHM_Default;
        case 'ForceOpened':
            return GroupHoursMode.GHM_ForceOpened;
        case 'ForceClosed':
            return GroupHoursMode.GHM_ForceClosed;
        case 'ForceBreak':
            return GroupHoursMode.GHM_ForceBreak;
        case 'ForceCustomOperator':
            return GroupHoursMode.GHM_ForceCustomOperator;
        case 'ForceHoliday':
            return GroupHoursMode.GHM_ForceHoliday;
        default:
            return GroupHoursMode.GHM_Default;
    }
}

export function constructExpirationDateInUTC(overrideHoursTimeout: OverrideHoursTimeout): DateTime {
    let dayjsExpiration: Dayjs = dayjs();
    switch (overrideHoursTimeout) {
        case OverrideHoursTimeout.M15: {
            dayjsExpiration = dayjsExpiration.add(15, 'minute');
            break;
        }
        case OverrideHoursTimeout.M30: {
            dayjsExpiration = dayjsExpiration.add(30, 'minute');
            break;
        }
        case OverrideHoursTimeout.H1: {
            dayjsExpiration = dayjsExpiration.add(1, 'hour');
            break;
        }
        case OverrideHoursTimeout.H2: {
            dayjsExpiration = dayjsExpiration.add(2, 'hour');
            break;
        }
        case OverrideHoursTimeout.H12: {
            dayjsExpiration = dayjsExpiration.add(12, 'hour');
            break;
        }
        case OverrideHoursTimeout.D1: {
            dayjsExpiration = dayjsExpiration.add(1, 'day');
            break;
        }
        case OverrideHoursTimeout.D2: {
            dayjsExpiration = dayjsExpiration.add(2, 'day');
            break;
        }
        case OverrideHoursTimeout.W1: {
            dayjsExpiration = dayjsExpiration.add(1, 'week');
            break;
        }
        case OverrideHoursTimeout.W2: {
            dayjsExpiration = dayjsExpiration.add(2, 'week');
            break;
        }
        case OverrideHoursTimeout.W3: {
            dayjsExpiration = dayjsExpiration.add(3, 'week');
            break;
        }
        case OverrideHoursTimeout.M1: {
            dayjsExpiration = dayjsExpiration.add(1, 'month');
            break;
        }
    }

    const expirationDate = dayjsExpiration.toDate();
    return new DateTime({
        Year: expirationDate.getUTCFullYear(),
        Month: expirationDate.getUTCMonth() + 1,
        Day: expirationDate.getUTCDate(),
        Hour: expirationDate.getUTCHours(),
        Minute: expirationDate.getUTCMinutes(),
        Second: expirationDate.getUTCSeconds(),
    });
}
