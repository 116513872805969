import type { PbxPeer } from '@xapi';
import type { FilterValue, SerializedFilter } from '@office/standalones/odata-search/types';
import type { TranslateService } from '@ngx-translate/core';
import type { ValidationErrors } from '@angular/forms';

export enum SourceOrDestinationType {
    Any = 0,
    User = 1,
    Queue = 2,
    RingGroup = 3,
    NumberContains = 4,
    MatchBillingCode = 5,
    MatchNumber,
    NumberStartsWith
}

const FROM_TO_TYPE_LABEL: Record<SourceOrDestinationType, string> = {
    [SourceOrDestinationType.Any]: '_i18n.ReportsFilterAny',
    [SourceOrDestinationType.User]: '_i18n.ReportsFilterUsers',
    [SourceOrDestinationType.Queue]: '_i18n.ReportsFilterQueue',
    [SourceOrDestinationType.RingGroup]: '_i18n.RingGroup',
    [SourceOrDestinationType.NumberContains]: '_i18n.ReportsFilterContains',
    [SourceOrDestinationType.MatchBillingCode]: '_i18n.ReportsFilterMatchBillingCode',
    [SourceOrDestinationType.MatchNumber]: '_i18n.ReportsMatchNumber',
    [SourceOrDestinationType.NumberStartsWith]: '_i18n.ReportsNumberStartsWith'
};

export function translateSourceOrDestinationType(value: SourceOrDestinationType | null | undefined) {
    return value != null ? FROM_TO_TYPE_LABEL[value] : '';
}

export interface SourceOrDestinationFilterParams {
    type: SourceOrDestinationType | null
    text: string | null
    extension: PbxPeer | null
}

// This value is universal for call / chat source and target, possible type differs - that's resolved in the view
export class SourceOrDestinationFilterValue implements FilterValue<SourceOrDestinationFilterParams> {
    private type: SourceOrDestinationFilterParams['type'] = SourceOrDestinationType.Any;
    private extension: SourceOrDestinationFilterParams['extension'] = null;
    private text: SourceOrDestinationFilterParams['text'] = null;

    constructor(private readonly queryParamName: string, public readonly label: string) {
    }

    static createSource(): SourceOrDestinationFilterValue {
        return new SourceOrDestinationFilterValue('from', '_i18n.FromWho');
    }

    static createDestination(): SourceOrDestinationFilterValue {
        return new SourceOrDestinationFilterValue('to', '_i18n.ToWhom');
    }

    get value(): SourceOrDestinationFilterParams {
        const value = { type: this.type, extension: this.extension, text: this.text };

        return SourceOrDestinationFilterValue.getCleanValue(value, true);
    }

    set value(value: SourceOrDestinationFilterParams) {
        const cleanValue = SourceOrDestinationFilterValue.getCleanValue(value);

        this.type = cleanValue.type;
        this.extension = cleanValue.extension;
        this.text = cleanValue.text;
    }

    deserialize(serializedValue: SerializedFilter): FilterValue<SourceOrDestinationFilterParams> {
        return SourceOrDestinationFilterValue.deserialize(serializedValue, this.queryParamName, this.label, this.value);
    }

    serialize(): SerializedFilter {
        const { type, text, extension } = this.value;

        return type === SourceOrDestinationType.Any
            ? {}
            : { [this.queryParamName]: { type, ...(text && { text }), ...(extension && { extension }) } };
    }

    getDisplayValue(translate: TranslateService): string {
        const value = this.value;

        switch (value.type) {
            case SourceOrDestinationType.User:
            case SourceOrDestinationType.RingGroup:
            case SourceOrDestinationType.Queue:
                return `${translate.instant(translateSourceOrDestinationType(value.type))} ${this.extension!.Name}`;

            case SourceOrDestinationType.NumberContains:
            case SourceOrDestinationType.MatchBillingCode:
            case SourceOrDestinationType.NumberStartsWith:
            case SourceOrDestinationType.MatchNumber:
                return `${translate.instant(translateSourceOrDestinationType(value.type))} ${this.text}`;
        }

        return '';
    }

    getDisplayValueForPrint(translate: TranslateService): string {
        return this.getDisplayValue(translate) || translate.instant(translateSourceOrDestinationType(SourceOrDestinationType.Any));
    }

    validate(): ValidationErrors | null {
        switch (this.type) {
            case null:
            case SourceOrDestinationType.Any:
                return null;
            case SourceOrDestinationType.User:
            case SourceOrDestinationType.Queue:
            case SourceOrDestinationType.RingGroup:
                return this.extension ? null : { extension: { required: true } };
            case SourceOrDestinationType.NumberContains:
            case SourceOrDestinationType.MatchBillingCode:
            case SourceOrDestinationType.NumberStartsWith:
            case SourceOrDestinationType.MatchNumber:
                return this.text ? null : { text: { required: true } };
            default:
                return { type: { notSupported: true } };
        }
    }

    static deserialize(serializedValue: SerializedFilter, queryParamName: string, label: string, initialValue?: SourceOrDestinationFilterParams): FilterValue<SourceOrDestinationFilterParams> {
        const result = new SourceOrDestinationFilterValue(queryParamName, label);
        const param = queryParamName in serializedValue && typeof serializedValue[queryParamName] === 'object'
            ? serializedValue[queryParamName] as SourceOrDestinationFilterParams | null
            : null;

        if (initialValue) {
            result.value = initialValue;
        }
        if (param) {
            result.value = param;
        }
        return result;
    }

    private static getCleanValue(value: SourceOrDestinationFilterParams | null | undefined, makeValid = false): SourceOrDestinationFilterParams {
        if (value) {
            switch (value.type) {
                case SourceOrDestinationType.User:
                case SourceOrDestinationType.Queue:
                case SourceOrDestinationType.RingGroup:
                    if (value.extension || !makeValid) {
                        return { type: value.type, extension: value.extension, text: null };
                    }
                    break;
                case SourceOrDestinationType.NumberContains:
                case SourceOrDestinationType.MatchBillingCode:
                case SourceOrDestinationType.NumberStartsWith:
                case SourceOrDestinationType.MatchNumber:
                    if (value.text || !makeValid) {
                        return { type: value.type, extension: null, text: value.text };
                    }
                    break;
            }
        }
        return { type: SourceOrDestinationType.Any, extension: null, text: null };
    }
}
