import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'app-call-blf-indicator',
    template: `
        <span class="status-indicator {{cssStatus || ''}}"></span>
        <span class="text-truncate" title="{{(text || '') | translate}}">
            {{(text || '') | translate}}
        </span>
    `,
    styleUrls: ['./call-blf-indicator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CallBlfIndicatorComponent {
    @Input() cssStatus: string | null | undefined;
    @Input() text: string | null | undefined;
}
