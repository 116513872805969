import { ActiveCallInfo } from '../../myphone/active-calls/active-call-info';
import { LocalConnectionEx } from '../../myphone/local-connection-ex';

export class ActiveCallActions {
    public pickupConnection ?: LocalConnectionEx;
    public divertConnection ?: LocalConnectionEx;
    public dropConnection ?: LocalConnectionEx;

    public listenConnection ?: LocalConnectionEx;
    public whisperConnections ?: LocalConnectionEx[];
    public bargeInConnection ?: LocalConnectionEx;

    public parkConnections ?: LocalConnectionEx[];
    public transferConnections ?: LocalConnectionEx[];
    public conferenceCall ?: ActiveCallInfo;
    public recordConnection ?: LocalConnectionEx;
    public stopRecordingConnection ?: LocalConnectionEx;
    public unparkConnection ?: LocalConnectionEx;

    public requestCallReport ?: LocalConnectionEx;
}
