import { Pipe, PipeTransform } from '@angular/core';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { AppContact } from '@webclient/myphone/contact';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Pipe({ name: 'lforfl', standalone: true })
export class NameOrderPipe implements PipeTransform {
    public constructor(private service: MyPhoneService) {
    }

    public transform(contact: { firstNameLastName: string, lastNameFirstName: string } | null | undefined, name?: string | null, dummy?: number): Observable<string> {
        if (contact && !(<AppContact>contact).isDummy) {
            return this.service.myPhoneSession.pipe(
                switchMap(session => session.systemParameters$),
                map(params =>
                    (params.IsLastFirst ? contact.lastNameFirstName : contact.firstNameLastName)
                )
            );
        }
        else {
            return of(name || '');
        }
    }
}
