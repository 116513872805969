<div class="d-flex flex-row flex-wrap flex-lg-nowrap  gap-3 bg-tiles px-3">
    <ng-container [ngSwitch]="mode">
        <ng-container *ngSwitchCase="ContactEditorMode.Compact">
            <div class="flex-grow-1">
                <div class="row">
                    <field-input id="fieldMobile" label="_i18n.Number" [(ngModel)]="vm.mobile" data-qa="mobile"
                                 (ngModelChange)="localService.fieldChanged(vm,'mobile')" [maxlength]="50"
                                 [ngClass]="{invalid: localService.phoneNumberIsEmpty || localService.errorText['mobile']}">
                        <show-error-warning errorMessage="{{localService.errorText['mobile']}}"></show-error-warning>
                    </field-input>
                    <div class="col-sm-6">
                        <field-input id="fieldFirstName" label="_i18n.FirstName" [(ngModel)]="vm.firstName"
                                     (ngModelChange)="localService.fieldChanged(vm,'firstName')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.nameIsEmpty}" data-qa="first-name">
                            <show-error-warning errorMessage="{{localService.errorText['name']}}">
                            </show-error-warning>
                        </field-input>
                    </div>
                    <div class="col-sm-6">
                        <field-input id="fieldLastName" label="_i18n.LastName" [(ngModel)]="vm.lastName" data-qa="last-name"
                                     (ngModelChange)="localService.fieldChanged(vm,'lastName')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.nameIsEmpty && !vm.createMicrosoft365Contact}">
                        </field-input>
                    </div>
                    <div class="col-sm-6">
                        <field-input id="fieldCompany" label="_i18n.Company" [(ngModel)]="vm.company" data-qa="company"
                                     (ngModelChange)="localService.fieldChanged(vm,'company')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.nameIsEmpty && !vm.isCrmOrMicrosoft365CreationActivated}">
                        </field-input>
                    </div>
<!--                    <div class="col-sm-6">
                        <field-input id="fieldTitle" label="_i18n.TitleLabel" [(ngModel)]="vm.title" data-qa="title"
                                     (ngModelChange)="localService.fieldChanged(vm,'title')" [maxlength]="50"
                        >
                        </field-input>
                    </div>-->
<!--                    <div class="col-sm-6">
                        <field-input id="fieldDepartment" label="_i18n.Department" [(ngModel)]="vm.department" data-qa="department"
                                     (ngModelChange)="localService.fieldChanged(vm,'department')" [maxlength]="50"
                        >
                        </field-input>
                    </div>-->
                    <div class="col-sm-6">
                        <field-input id="fieldEmail" label="_i18n.Email" [(ngModel)]="vm.emailAddress" data-qa="email"
                                     [ngClass]="{invalid: !!localService.errorText['emailAddress']}"
                                     (ngModelChange)="localService.fieldChanged(vm,'emailAddress')" [maxlength]="254">
                            <show-error-warning errorMessage="{{localService.errorText['emailAddress']}}"></show-error-warning>
                        </field-input>
                    </div>
                    <ng-container *ngTemplateOutlet="addContactOptions" ></ng-container>
                </div>
            </div>
        </ng-container>

        <ng-container *ngSwitchDefault>
            <app-profile-image [ngModel]="vm?.imageSource | appContactImage$ | async" [defaultImage]="userTransparentImage" (ngModelChange)="vm.onContactImageChange($event)">
            </app-profile-image>

            <div class="flex-grow-1" style="max-width: 992px">
                <div class="row">
                    <div class="col-md-6">
                        <field-input id="fieldFirstName" label="_i18n.FirstName" [(ngModel)]="vm.firstName"
                                     (ngModelChange)="localService.fieldChanged(vm,'firstName')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.nameIsEmpty}" data-qa="first-name">
                            <show-error-warning errorMessage="{{localService.errorText['name']}}">
                            </show-error-warning>
                        </field-input>
                        <field-input id="fieldLastName" label="_i18n.LastName" [(ngModel)]="vm.lastName" data-qa="last-name"
                                     (ngModelChange)="localService.fieldChanged(vm,'lastName')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.nameIsEmpty && !vm.createMicrosoft365Contact}">
                        </field-input>
                        <field-input id="fieldCompany" label="_i18n.Company" [(ngModel)]="vm.company" data-qa="company"
                                     (ngModelChange)="localService.fieldChanged(vm,'company')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.nameIsEmpty && !vm.isCrmOrMicrosoft365CreationActivated}">
                        </field-input>
                        <field-input id="fieldTitle" label="_i18n.TitleLabel" [(ngModel)]="vm.title" data-qa="title"
                                     (ngModelChange)="localService.fieldChanged(vm,'title')" [maxlength]="50">
                        </field-input>
                        <field-input id="fieldDepartment" label="_i18n.Department" [(ngModel)]="vm.department" data-qa="department"
                                     (ngModelChange)="localService.fieldChanged(vm,'department')" [maxlength]="50">
                        </field-input>
                        <field-input id="fieldEmail" label="_i18n.Email" [(ngModel)]="vm.emailAddress" data-qa="email"
                                     [ngClass]="{invalid: !!localService.errorText['emailAddress']}"
                                     (ngModelChange)="localService.fieldChanged(vm,'emailAddress')" [maxlength]="254">
                            <show-error-warning errorMessage="{{localService.errorText['emailAddress']}}"></show-error-warning>
                        </field-input>
                        <field-input id="fieldMobile" label="_i18n.MobileNumber" [(ngModel)]="vm.mobile" data-qa="mobile"
                                     (ngModelChange)="localService.fieldChanged(vm,'mobile')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.phoneNumberIsEmpty || !!localService.errorText['mobile']}">
                            <show-error-warning errorMessage="{{localService.errorText['mobile']}}"></show-error-warning>
                        </field-input>
                        <field-input id="fieldMobile2" label="_i18n.ContactData0" [(ngModel)]="vm.mobile2" data-qa="mobile2"
                                     (ngModelChange)="localService.fieldChanged(vm,'mobile2')" [maxlength]="50"
                                     [ngClass]="{invalid: !!localService.errorText['mobile2']}">
                            <show-error-warning errorMessage="{{localService.errorText['mobile2']}}"></show-error-warning>
                        </field-input>
                        <field-input id="fieldHome" label="_i18n.ContactData1" [(ngModel)]="vm.home" data-qa="home"
                                     (ngModelChange)="localService.fieldChanged(vm,'home')" [maxlength]="50"
                                     [ngClass]="{invalid: (localService.phoneNumberIsEmpty && !vm.isCrmOrMicrosoft365CreationActivated) || localService.errorText['home']}">
                            <show-error-warning errorMessage="{{localService.errorText['home']}}"></show-error-warning>
                        </field-input>
                    </div>
                    <div class="col-md-6">
                        <field-input id="fieldBusiness" label="_i18n.ContactData3" [(ngModel)]="vm.business" data-qa="business"
                                     (ngModelChange)="localService.fieldChanged(vm,'business')" [maxlength]="50"
                                     [ngClass]="{invalid: (localService.phoneNumberIsEmpty && !vm.isCrmOrMicrosoft365CreationActivated) || localService.errorText['business']}">
                            <show-error-warning errorMessage="{{localService.errorText['business']}}"></show-error-warning>
                        </field-input>
                        <field-input id="fieldBusiness2" label="_i18n.ContactData4" [(ngModel)]="vm.business2" data-qa="business2"
                                     (ngModelChange)="localService.fieldChanged(vm,'business2')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.errorText['business2']}">
                            <show-error-warning errorMessage="{{localService.errorText['business2']}}"></show-error-warning>
                        </field-input>
                        <field-input id="fieldBusinessFax" label="_i18n.ContactData7" [(ngModel)]="vm.businessFax" data-qa="business-fax"
                                     (ngModelChange)="localService.fieldChanged(vm,'businessFax')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.errorText['businessFax']}">
                            <show-error-warning errorMessage="{{localService.errorText['businessFax']}}"></show-error-warning>
                        </field-input>
                        <field-input id="fieldOther" label="_i18n.ContactData6" [(ngModel)]="vm.other" data-qa="other"
                                     (ngModelChange)="localService.fieldChanged(vm,'other')" [maxlength]="50"
                                     [ngClass]="{invalid: localService.errorText['other']}">
                            <show-error-warning errorMessage="{{localService.errorText['other']}}"></show-error-warning>
                        </field-input>
                        <ng-container *ngTemplateOutlet="addContactOptions"></ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #addContactOptions>
        <field-wrapper *ngIf="vm.isNew && !(isMcmMode$ | async)" label="_i18n.AddContactTo">
            <div class="d-flex flex-row flex-wrap justify-content-between gap-3 pt-1">
                <field-checkbox
                    class="mb-0"
                    [class.invalid]="localService.errorText['noCreationMethodSelected']"
                    [disabled]="!vm.crmCreationEnabled"
                    data-qa="add-crm"
                    [(ngModel)]="vm.createCrmContact"
                    (ngModelChange)="localService.fieldChanged(vm,'createCrm')"
                    label="_i18n.CRM">
                </field-checkbox>
                <field-checkbox
                    class="mb-0"
                    [class.invalid]="localService.errorText['noCreationMethodSelected']"
                    [disabled]="!vm.microsoft365CreationEnabled || !vm.hasExchangeLicense"
                    data-qa="add-office"
                    [(ngModel)]="vm.createMicrosoft365Contact"
                    (ngModelChange)="localService.fieldChanged(vm,'createMicrosoft365')"
                    label="_i18n.Microsoft365">
                </field-checkbox>
                <field-checkbox
                    class="mb-0"
                    [class.invalid]="localService.errorText['noCreationMethodSelected']"
                    [disabled]="!vm.companyPhonebookCreationEnabled"
                    data-qa="add-company"
                    [(ngModel)]="vm.createCompanyContact"
                    (ngModelChange)="localService.fieldChanged(vm,'createCompany')"
                    label="_i18n.Company">
                </field-checkbox>
                <field-checkbox
                    class="mb-0"
                    [class.invalid]="localService.errorText['noCreationMethodSelected']"
                    data-qa="add-personal"
                    [(ngModel)]="vm.createPersonalContact"
                    (ngModelChange)="localService.fieldChanged(vm,'createPersonal')"
                    label="_i18n.PbtypePersonal">
                </field-checkbox>
            </div>
            <ng-container *ngIf="localService.errorText['noCreationMethodSelected'] as error" ngProjectAs="val-errors">
                <div class="val-errors">{{ error | translate }}</div>
            </ng-container>
        </field-wrapper>
    </ng-template>
</div>


