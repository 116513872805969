import {
    combineLatest,
    EMPTY,
    merge,
    Observable,
    of,
    ReplaySubject,
    Subject,
    Subscription,
    tap,
    throwError,
    TimeoutError
} from 'rxjs';
import {
    catchError,
    distinctUntilChanged,
    map,
    mergeScan,
    share,
    startWith,
    switchMap,
    take,
    withLatestFrom
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ModalService } from '../modal/app-modal.service';
import { MyPhoneService } from '../myphone/myphone.service';
import { String } from '@webclient/shared/string.utils';
import {
    AgentMobileClient,
    AgentWebclient,
    DeviceService,
    isWebRTCEndpoint,
    isWebRtcOrNoDevice
} from './device.service';
import { MyCall } from './mycall';
import { AppContact, defaultContact } from '../myphone/contact';
import { MyPhoneSession } from '../myphone/myphone-session';
import { MyCalls } from './mycalls';
import { SettingsService } from '../settings.service';
import { MyCallsInternal } from './mycalls-internal';
import { AutoOpenUrl, GeneralSettings } from '../settings/general-settings';
import { IntegrationService } from '../layout/integration.service';
import { NotificationService } from '../notifications/notification.service';
import { destroyMedia, WebRTCControlService } from '../webrtc/webrtc-control.service';
import {
    BargeInMode,
    ConnectionCapabilities,
    DnType,
    LocalConnection,
    LocalConnectionState,
    Registration,
    RequestAutoAnswerConnection,
    RequestJoinCalls,
    RequestMakeCall,
    ResponseAcknowledge,
    ResponseCallControl,
    WebRTCHoldState
} from '@myphone';
import { dummyMediaDescription, MediaDescription } from './media-description';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '../settings/local-storage-keys';
import { ContactSearcherService } from '@webclient/shared/service/contact-searcher.service';
import { List } from 'immutable';
import { isSharedWorkerEnabled } from '@webclient/myphone/shared-worker-state';
import { observe } from '@webclient/rx-utils';
import { RxLock } from '@webclient/rx-lock';
import { CallsStateService } from '@webclient/phone/calls-state.service';
import {
    CallControlMessage,
    ConnectionsMap,
    InternalCallsSource,
    ProcessPickUpCallFromPushWakeUp,
    PushAction,
    RemoveDroppingCall,
    RemoveTryingCall,
    RequestAttachFoundedContact,
    RequestAttTransfer,
    RequestProcessingLocalConnectionCall,
    RequestProcessingWebRtcCall,
    RequestTryingCall,
    SipDialogMap
} from './phone.interface';
import {
    createRequestDropMyCall,
    notEmpty,
    processClientCrmUrl,
    processCrmUrl,
    processIntegrationUrl,
} from '@webclient/phone/phone.helpers';
import { AppContactType } from '@webclient/myphone/app-contact-type';
import { HeadsetService } from '@webclient/phone/headsets/headset.service';
import {
    Log,
    Logger,
    PHONE_SERVICE_LOG, REQ_FAILED, REQ_FULFILLED, REQ_PENDING,
    RequestLog
} from '@webclient/myphone/logger';
import { repeatLatestWhen } from '@webclient/rx-share-utils';
import { appendPersonalContactInformation } from '@webclient/shared/utils.service';

@Injectable()
export class PhoneService {
    public readonly myCalls$: Observable<MyCalls>;
    private readonly lock = new RxLock();
    private callControl = new Subject<CallControlMessage>();
    private myCallId = 0;
    readonly refresh$ = new Subject<void>();
    public lastDialerNumber: string;
    public pushDeviceRegistration$: Observable<Registration | undefined>;

    constructor(
        private modalService: ModalService,
        private myphone: MyPhoneService,
        private settingsService: SettingsService,
        private callState: CallsStateService,
        private webrtcService: WebRTCControlService,
        private deviceService: DeviceService,
        private storage: LocalStorageService,
        private contactService: ContactSearcherService,
        private notificationService: NotificationService,
        private integrationService: IntegrationService,
        private headsetService: HeadsetService,
        private logger: Logger,
    ) {
        const autoAnswer$ = observe(this.storage, LocalStorageKeys.AutoAnswer, false);

        const activeDevices$ = this.myphone.myPhoneSession.pipe(
            switchMap(session => session.myInfo.ActiveDevices$));
        /**
         * Push device reg persists
         */
        this.pushDeviceRegistration$ = activeDevices$.pipe(
            map(devices => devices.Items?.find(reg => reg.UserAgent === AgentMobileClient))
        );

        const completedConnections$ = this.myphone.myPhoneSession.pipe(switchMap(session => session.connectionsDeleted$));
        // Main pipe with myCalls, represented in client
        this.myCalls$ = this.myphone.myPhoneSession.pipe(switchMap(session => {
            const allowControlOwnRecordings$ = session.myInfo$.pipe(map(info => info.AllowControlOwnRecordings), distinctUntilChanged());
            const isMcmMode$ = session.systemParameters$.pipe(map(params => params.MultiCompanyMode));
            const connections$ = session.myInfo.Connections$.pipe(
                map(conn => (conn && conn.Items ? conn.Items : []),
                ));

            const myCallsInternal$ = merge(combineLatest([
                connections$.pipe(repeatLatestWhen(this.refresh$)),
                this.pushDeviceRegistration$,
                deviceService.selectedPhoneDevice$,
                this.webrtcService.mediaDevice$,
                this.webrtcService.webRTCEndpoint$,
                session.connectionsDeleted$.pipe(startWith([])),
            ]), this.callControl).pipe(
                mergeScan((myCalls: MyCallsInternal, message: InternalCallsSource) => {
                    if (message instanceof RequestTryingCall) {
                        return this.contactService.requestContactByNumber(message.phoneNumber).pipe(
                            map(contact => this.handleNewCallReq(myCalls, message, session, contact)));
                    }
                    else if (message instanceof ProcessPickUpCallFromPushWakeUp) {
                        /** Attach lc id and create new call. It's need because new myCall will not be emitted when selected device is not a push(device contact inconsistent),
                        * but we HAVE to handle calls from push, regardless selected device, that's why we need separate procedure
                        *  So we are attaching id, then answer or drop call */
                        return of(this.handleRequestWakeUpPush(myCalls, message));
                    }
                    else if (message instanceof RequestProcessingWebRtcCall) {
                        /** Attach lc id to myCall ( we have strict association, now don`t need to wait when first incoming connection will appear) */
                        return of(this.assignWebRTCId(myCalls, message));
                    }
                    else if (message instanceof RequestProcessingLocalConnectionCall) {
                        /**  Attach lc id to myCall ( we have strict association, now don`t need to wait when first incoming connection will appear) */
                        return of(this.assignLocalConnectionId(myCalls, message));
                    }
                    else if (message instanceof RemoveTryingCall) {
                        return of(this.handleRemoveNewCallReq(myCalls, message));
                    }
                    else if (message instanceof RemoveDroppingCall) {
                        return of(this.handleRemoveDroppingCallReq(myCalls, message));
                    }
                    else if (message instanceof RequestAttTransfer) {
                        return of(this.handleRequestAttTransfer(myCalls, message));
                    }
                    else if (message instanceof RequestAttachFoundedContact) {
                        // Attaching contact to the call (moved from local connections because 1000times performing lookup was ambigous)
                        return of(this.assignFoundContact(myCalls, message, session));
                    }
                    else {
                        const [
                            connections,
                            pushDeviceReg,
                            device,
                            mediaDescription,
                            webrtcEndpoint,
                            deletedConnections,
                        ] = message;

                        const registered = !webrtcEndpoint.DeviceContact && webrtcEndpoint.DeviceContact !== '';
                        if (connections.length > 0) {
                            return of(this.handleCalls(myCalls, connections, session, device, mediaDescription, pushDeviceReg?.Contact, registered, deletedConnections));
                        }
                        // empty update
                        else {
                            return of(this.handleCalls(
                                myCalls,
                                [],
                                session,
                                device,
                                mediaDescription,
                                pushDeviceReg?.Contact,
                                registered,
                                deletedConnections
                            ));
                        }
                    }
                }, new MyCallsInternal(), 1));

            // Start with session
            return combineLatest([myCallsInternal$, allowControlOwnRecordings$]).pipe(
                withLatestFrom(this.settingsService.generalSettings$, autoAnswer$, isMcmMode$),
                map((values: [[MyCallsInternal, boolean], GeneralSettings, boolean, boolean]) => {
                    const [[myCalls, allowControlOwnRecordings], settings, autoAnswer, isMcmMode] = values;
                    const calls = myCalls.calls;

                    let leftCall = myCalls.requestAttTransfer &&
                            calls.find(call => !call.isHidden && call.myCallId === myCalls.requestAttTransfer?.leftMyCallId);
                    let rightCall = myCalls.requestAttTransfer &&
                            calls.find(call => !call.isHidden && call.myCallId === myCalls.requestAttTransfer?.rightMyCallId);
                    const attTransferInProgress = leftCall && rightCall;
                    if (!attTransferInProgress) {
                        leftCall = undefined;
                        rightCall = undefined;
                    }

                    // Check if new calls allowed
                    const isNewCallAllowed = !calls.some(call => call.isTrying || call.state === LocalConnectionState.Dialing);
                    // Set conference enabled state
                    calls.forEach(call => {
                        const mediaProvided = !call.isWebRTCCall || (call.media !== dummyMediaDescription);
                        const hold = mediaProvided && call.media.lastWebRTCState.holdState === WebRTCHoldState.WebRTCHoldState_HOLD;
                        const established = mediaProvided && call.media.lastWebRTCState.holdState === WebRTCHoldState.WebRTCHoldState_NOHOLD;

                        if (call.state === LocalConnectionState.Connected) {
                            if (call.isHold && !hold) {
                                if (call.isWebRTCCall) {
                                    this.headsetService.resumeCall(call.myCallId);
                                }
                                this.callState.resumeCall(call);
                            }
                            else if (!call.isHold && hold) {
                                if (call.isWebRTCCall) {
                                    this.headsetService.holdCall(call.myCallId);
                                }
                                this.callState.holdCall(call);
                            }
                        }

                        call.isHold = hold;

                        const holdOrEstablished = hold || established;

                        call.isTransferEnabled = holdOrEstablished && (call.state === LocalConnectionState.Connected) && call.isConnCapabilitiesTransfer;
                        call.isRecordingEnabled = allowControlOwnRecordings && call.isTransferEnabled && !hold;
                        switch (settings.autoOpenUrl) {
                            case AutoOpenUrl.Off:
                                break;
                            case AutoOpenUrl.CrmUrl:
                                processCrmUrl(integrationService, call, isMcmMode);
                                break;
                            case AutoOpenUrl.IntegrationsUrl:
                                processIntegrationUrl(integrationService, call, settings);
                                break;
                            case AutoOpenUrl.ClientCrmUrl:
                                processClientCrmUrl(integrationService, call, settings);
                                break;
                        }

                        call.isAttTransferEnabled = holdOrEstablished && call.deviceDirectControl && call.isTransferEnabled && isNewCallAllowed && !attTransferInProgress;
                        if (call === rightCall && leftCall) {
                            call.joinToConnectionId = leftCall.localConnectionId;
                        }
                        else {
                            call.joinToConnectionId = undefined;
                        }
                        call.isConferenceEnabled = holdOrEstablished && call.deviceDirectControl && call.isTransferEnabled && isNewCallAllowed;
                        call.isConferenceCall = call.phone === session.conferenceGateway;
                        call.isHoldEnabled = !call.media.isNegotiationInProgress && established && call.isWebRTCCall && call.state === LocalConnectionState.Connected;
                        call.isMuteEnabled = call.isHoldEnabled;
                        call.isVideoEnabled = call.isHoldEnabled;
                        // TODO answer button should be enabled if we have a device to answer?
                        call.isAnswerEnabled = (call.state === LocalConnectionState.Ringing) && call.deviceDirectControl; // TODO: MADE TO PREVENT ANSWERING FOR CALLS TO ANOTHER DEVICE LIKE SMARTPHONE

                        if (autoAnswer && call.isAnswerEnabled && !call.isAutoAnswered && !isSharedWorkerEnabled(this.storage)) {
                            if (calls.size === 1) {
                                this.answer(call, false);
                            }
                            call.isAutoAnswered = true;
                        }
                    });
                    // New calls on top
                    calls.sort((x, y) => y.myCallId - x.myCallId);

                    const hiddenCall = (call: MyCall) => call.isHidden || call === leftCall;
                    // Remove dropped calls
                    return new MyCalls({
                        calls: calls.filter(call => !hiddenCall(call)),
                        hiddenCalls: calls.filter(call => hiddenCall(call)),
                        hasNewCalls: myCalls.hasNewCalls,
                        isNewCallAllowed
                    });
                }));
        }),
        // tap(val => this.logger.info(val.calls)),
        startWith(new MyCalls()),
        share({ connector: () => new ReplaySubject<MyCalls>(1) }));
    }

    private handleRequestAttTransfer(myCalls: MyCallsInternal, message: RequestAttTransfer): MyCallsInternal {
        return myCalls.merge({
            requestAttTransfer: message
        });
    }

    private handleRemoveDroppingCallReq(myCalls: MyCallsInternal, message: RemoveDroppingCall): MyCallsInternal {
        const dropping = myCalls.calls.find(call => call.myCallId === message.myCallId);
        if (dropping) {
            dropping.isHidden = true;
        }
        return myCalls;
    }

    private handleNewCallReq(myCalls: MyCallsInternal, request: RequestTryingCall, session: MyPhoneSession, contact: AppContact): MyCallsInternal {
        // New call request
        const myCall = new MyCall(request.callId, request.isWebRTCCall);
        myCall.isTrying = true;
        myCall.phone = request.phoneNumber;
        myCall.tapiCallId = request.tapiCallId;
        myCall.contact = contact;
        myCall.displayName = session.systemParameters.IsLastFirst ? contact.lastNameFirstName : contact.firstNameLastName;
        this.logger.showInfo({
            loggingLevel: PHONE_SERVICE_LOG,
            message: `Creating new trying outgoing call ${myCall.myCallId} to following destination: ${myCall.phone}`
        });
        return myCalls.merge({
            calls: myCalls.calls.insert(0, myCall),
            hasNewCalls: true,
        });
    }

    /**
     * Function, that handle local connection from push wake up client
     * @param myCalls
     * @param message
     * @private
     */
    private handleRequestWakeUpPush(myCalls: MyCallsInternal, message: ProcessPickUpCallFromPushWakeUp) {
        const existingCall = myCalls.calls.find(call => call.localConnectionId === message.connection.Id);
        /**
         * If we have call with this localConnection Id, just answer or drop. if no - emit new myCall and answer/drop then
         * It`s possible that we don't have myCall if we have both webrtc registration and push registration and call is on push device,
         */
        if (!existingCall) {
            const myCall = new MyCall(this.myCallId++, true);
            myCall.localConnectionId = message.connection.Id;
            myCall.phone = message.connection.OtherPartyDisplayName;
            myCall.displayName = message.connection.OtherPartyDisplayName;
            myCall.isAnswerEnabled = false;
            myCall.fromPushWakeUpAction = message.isAnswer ? PushAction.Answer : PushAction.Decline;
            return myCalls.merge({
                calls: myCalls.calls.insert(0, myCall),
                hasNewCalls: true,
            });
        }
        else {
            if (message.isAnswer) {
                this.answer(existingCall, false);
            }
            else {
                this.drop(existingCall);
            }
            return myCalls;
        }
    }

    private assignLocalConnectionId(myCalls: MyCallsInternal, request: RequestProcessingLocalConnectionCall) {
        const localConnectionCall = myCalls.calls.find(call => call.myCallId === request.myCallId);
        if (localConnectionCall) {
            if (localConnectionCall.deletedConnections.includes(request.localConnectionId)) {
                this.callControl.next(new RemoveTryingCall(localConnectionCall.myCallId));
                return myCalls;
            }
            localConnectionCall.localConnectionId = request.localConnectionId;
        }
        return myCalls;
    }

    private assignWebRTCId(myCalls: MyCallsInternal, request: RequestProcessingWebRtcCall) {
        const webrtcCall = myCalls.calls.find(call => call.myCallId === request.myCallId);
        if (webrtcCall) {
            webrtcCall.webRTCId = request.webRtcId;
        }
        return myCalls;
    }

    private assignPersonalInformationFromContact(myCall: MyCall, session: MyPhoneSession) {
        const otherPartyDisplayNameLC = myCall.otherPartyDisplayNameLC;
        const log: Log = {
            loggingLevel: PHONE_SERVICE_LOG,
            operationName: 'AppendCallDisplayName',
        };
        if (!String.isNullOrEmpty(otherPartyDisplayNameLC)) {
            myCall.displayName = otherPartyDisplayNameLC;
            if (myCall.contact && myCall.contact.type === AppContactType.PersonalPhonebook) {
                myCall.displayName = appendPersonalContactInformation(myCall.displayName, myCall.contact, session.systemParameters.IsLastFirst);
            }
            log.message = `Call display name is ruled by Local Connection, appended: ${myCall.displayName}`;
        }
        else if (myCall.contact) { // Same condition in old source code when !conn.OtherPartyDisplayName
            myCall.displayName = session.systemParameters.IsLastFirst ? myCall.contact.lastNameFirstName : myCall.contact.firstNameLastName;
            if (myCall.contact.isDummy) {
                log.message = 'Call display name is ruled by not founded contact';
            }
            else {
                log.message = `Call display name is ruled by founded contact, appended: ${myCall.displayName}`;
            }
        }
        this.logger.showInfo(log);
        if (myCall.lcCrmUrl) { // set CRM url together with display name to avoid de-synchronization
            myCall.contact.crmUrl = myCall.lcCrmUrl;
            this.logger.showInfo({
                ...log,
                operationName: 'AppendCallCrmURl',
                message: `CrmUrl appended: ${myCall.lcCrmUrl}`
            });
        }
        else {
            myCall.contact.crmUrl = '';
        }
    }

    private assignFoundContact(myCalls: MyCallsInternal, request: RequestAttachFoundedContact, session: MyPhoneSession) {
        const lookUpCall = myCalls.calls.find(call => call.myCallId === request.myCallId);
        if (lookUpCall) {
            lookUpCall.contact = request.contact;
            this.assignPersonalInformationFromContact(lookUpCall, session);
        }
        return myCalls;
    }

    private handleRemoveNewCallReq(myCalls: MyCallsInternal, message: RemoveTryingCall): MyCallsInternal {
        this.logger.showInfo({
            loggingLevel: PHONE_SERVICE_LOG,
            message: `Removing trying outgoing call ${message.myCallId}`
        });
        return myCalls.merge({
            hasNewCalls: false,
            calls: myCalls.calls.filter(call => call.myCallId !== message.myCallId)
        });
    }

    drop(myCall: MyCall) {
        const log: RequestLog = {
            loggingLevel: PHONE_SERVICE_LOG,
            requestState: REQ_PENDING,
            reqIndex: myCall.myCallId,
        };
        myCall.replacingConnectionId = myCall.localConnectionId;
        this.deviceService.selectedPhoneDevice$.pipe(
            take(1),
            switchMap((selectedDevice) => {
                // we have webrtc call
                if (isWebRTCEndpoint(selectedDevice) && !!myCall.media && myCall.media !== dummyMediaDescription) {
                    log.operationName = 'TerminateCallWebRTC';
                    this.logger.showRequest({
                        ...log,
                        message: `Sending request to terminate webRTC call ${myCall.idCallLegInfo}`
                    });
                    return this.webrtcService.drop(myCall);
                }
                // TODO FOR LATER we have unstable call, that didn't receive media, but it has attached webRtcConnectionId
                /*                 else if (isWebRTCEndpoint(selectedDevice) && !!myCall.webRTCId && !myCall.isWebrtcLcConnectionCompared) {
                    return this.webrtcService.dropUnstableCall(myCall);
                } */
                // we don't have webrtc call
                else {
                    const myCallDrop = createRequestDropMyCall(myCall);
                    log.operationName = 'RequestDropCall';
                    this.logger.showRequest({
                        ...log,
                        message: `Sending request to call control API ${myCall.idCallLegInfo}`
                    });
                    return this.myphone.get(myCallDrop);
                }
            }),
        ).subscribe({
            next: () => {
                this.logger.showRequest({
                    ...log,
                    requestState: REQ_FULFILLED,
                    message: `Call terminated ${myCall.idCallLegInfo}`
                });
            },
            error: (err: unknown) => {
                this.logError(myCall.myCallId, err, log);
                this.onError(err);
            },
        });
    }

    public requestAttTransfer(leftMyCallId: number, rightMyCallId: number) {
        this.callControl.next(new RequestAttTransfer(leftMyCallId, rightMyCallId));
    }

    public requestMakePushWakeUpCall(conn: LocalConnection, isAnswer: boolean) {
        this.callControl.next(new ProcessPickUpCallFromPushWakeUp(conn, isAnswer));
    }

    public join$(leftMyCallId: number, rightMyCallId: number, deviceID: string) {
        const log: RequestLog = {
            loggingLevel: PHONE_SERVICE_LOG,
            requestState: REQ_PENDING,
            reqIndex: leftMyCallId,
            operationName: 'JoinCalls'
        };
        return this.myphone.myPhoneSession.pipe(take(1), switchMap(session => {
            // First let's get device and current session
            if (!deviceID) {
                throw new Error('_i18n.PhoneNotConnected');
            }
            const request = new RequestJoinCalls();
            request.MovePartyOfLocalConnectionId = leftMyCallId;
            request.ReplaceLocalConnectionIdWithMovedParty = rightMyCallId;
            request.DeviceContact = deviceID;
            this.logger.showRequest({
                ...log,
                message: `Sending request to join calls LeftCallId:${leftMyCallId} RightCallId:${rightMyCallId} DeviceId:${deviceID}`
            });
            return session.get(request).pipe(
                tap(val => {
                    this.logger.showRequest({
                        ...log,
                        requestState: REQ_FULFILLED,
                        message: `Calls with ids ${leftMyCallId}, ${rightMyCallId} joined on Device:${deviceID}`
                    });
                }),
                catchError((error: unknown) => {
                    return this.logError(leftMyCallId, error, log);
                })
            );
        }));
    }

    /**
     * Lookup contact for existing myCall, no need to always lookup contacts for every single connection
     * @param myCall
     * @private
     */
    private lookUpContact$(myCall: MyCall) {
        const log: RequestLog = {
            loggingLevel: PHONE_SERVICE_LOG,
            requestState: REQ_PENDING,
            reqIndex: myCall.myCallId,
            message: `Sending request for call with id ${myCall.myCallId}`,
            operationName: 'LookupContact'
        };
        this.logger.showRequest(log);
        return this.contactService.requestContactByNumber(myCall.phone).pipe(take(1)).subscribe({
            next: contact => {
                this.callControl.next(new RequestAttachFoundedContact(myCall.myCallId, contact));
                this.logger.showRequest({
                    ...log,
                    requestState: REQ_FULFILLED,
                    message: `Contact attached to call ${myCall.idCallLegInfo}`,
                    optionalParams: [contact]
                });
            },
            error: (err: unknown) => {
                this.logError(myCall.callId, err, log);
                this.onError(err);
            }
        });
    }

    /**
     * New answer implementation (webrtc answer if we have registration / webrtc pickup if we don't)
     * @param call
     * @param video
     */
    answer(call: MyCall, video: boolean) {
        this.deviceService.selectedPhoneDevice$.pipe(
            take(1),
            switchMap((selectedDevice) => {
                const isWebRTC = isWebRTCEndpoint(selectedDevice);
                // WebRTC case
                if (isWebRTC) {
                    // If incoming call has media it means, that webRTC registration persists
                    if (call.media && call.media !== dummyMediaDescription) {
                        return this.answerWithRegistration$(call, video);
                    }
                    // Else we are gonna to use pickUp with localConnectionId
                    else if (!call.isSendingWebrtcRequest) { // prevent multiple pickup request for each single call
                        call.isSendingWebrtcRequest = true;
                        return this.pickUpIncomingCall$(call, video);
                    }
                    else {
                        return EMPTY;
                    }
                }
                // Else we are using call control API
                else {
                    const log: RequestLog = {
                        loggingLevel: PHONE_SERVICE_LOG,
                        operationName: 'AutoAnswer',
                        reqIndex: call.myCallId,
                        requestState: REQ_PENDING,
                        message: `Sending request to Call control API, Call ${call.idCallLegInfo}, Local Connection "${call.localConnectionId}"`
                    };
                    this.logger.showRequest(log);
                    return this.myphone.get<ResponseAcknowledge>(new RequestAutoAnswerConnection({ LocalConnectionId: call.localConnectionId })).pipe(tap(value => {
                        if (value.Success) {
                            this.logger.showRequest({
                                ...log,
                                requestState: REQ_FULFILLED,
                                message: `Call ${call.idCallLegInfo}, answered with Call Control API`
                            });
                        }
                        else {
                            this.logger.showRequest({
                                ...log,
                                requestState: REQ_FAILED,
                                message: `Request "${call.myCallId}" failed`
                            });
                        }
                    }));
                }
            })
        ).subscribe({ error: (err: unknown) => {
            call.isSendingWebrtcRequest = false;
            this.onError(err);
        } });
    }

    /**
     * new implementation of answer webRtc registration mode
     * @param call
     * @param video
     */
    answerWithRegistration$(call: MyCall, video: boolean) {
        const log: RequestLog = {
            loggingLevel: PHONE_SERVICE_LOG,
            requestState: REQ_PENDING,
            reqIndex: call.myCallId,
            message: `Sending request to answer MyCall ${call.idCallLegInfo}`,
            operationName: 'Answer'
        };
        this.logger.showRequest(log);
        return this.webrtcService.answer(call.media, video).pipe(
            tap(val => {
                call.webRTCId = val.CallId;
                this.logger.showRequest({
                    ...log,
                    requestState: REQ_FULFILLED,
                    message: `WebRTC connection attached to MyCall ${call.idCallLegInfo}`
                });
            }),
            catchError((error: unknown) => {
                return this.logError(call.myCallId, error, log);
            })
        );
    }

    /**
     * Pickup with emitting new call (For panel pickup) (We need to emit new call when we have don't have incoming call, when, for example, we are picking alien connection)
     * @param localConnectionId
     * @param phoneNumber
     * @param video
     */
    pickUpNewCall$(localConnectionId: number, phoneNumber: string, video: boolean) {
        const id = this.myCallId++;
        this.callControl.next(new RequestTryingCall(phoneNumber, true, id));
        const log: RequestLog = {
            loggingLevel: PHONE_SERVICE_LOG,
            requestState: REQ_PENDING,
            reqIndex: id,
            message: `Sending request to pickup existing call with Local Connection "${localConnectionId}"`,
            operationName: 'PickUP'
        };
        this.logger.showRequest(log);
        return this.webrtcService.pickupCall(localConnectionId, id, video)
            .pipe(
                tap(val => {
                    this.callControl.next(new RequestProcessingWebRtcCall(id, val.lastWebRTCState.Id));
                    this.logger.showRequest({
                        ...log,
                        requestState: REQ_FULFILLED,
                        message: `WebRTC connection with ID ${val.lastWebRTCState.Id} attached to MyCall with ID ${id}`
                    });
                }),
                catchError((error: unknown) => {
                    return this.handleTryingCallError(id, error, log);
                })
            );
    }

    /**
     * Pickup with updating existing call (Don't need to emit new call, because we already have one, which is incoming,)
     * @param myCall
     * @param video
     */
    pickUpIncomingCall$(myCall: MyCall, video: boolean) {
        myCall.replacingConnectionId = myCall.localConnectionId;
        myCall.isPickedUp = true;
        const log:RequestLog = {
            loggingLevel: PHONE_SERVICE_LOG,
            requestState: REQ_PENDING,
            reqIndex: myCall.myCallId,
            message: `Sending request to pickup from Local Connection ${myCall.localConnectionId} MyCall ${myCall.idCallLegInfo}`,
            operationName: 'PickUp'
        };
        this.logger.showRequest(log);
        return this.webrtcService.pickupCall(myCall.localConnectionId, myCall.myCallId, video)
            .pipe(tap(val => {
                this.callControl.next(new RequestProcessingWebRtcCall(myCall.myCallId, val.lastWebRTCState.Id));
                myCall.isSendingWebrtcRequest = false;
                this.logger.showRequest({
                    ...log,
                    requestState: REQ_FULFILLED,
                    message: `WebRTC connection with ID ${val.lastWebRTCState.Id} attached to MyCall ${myCall.idCallLegInfo}`
                });
            }),
            catchError((error: unknown) => {
                return this.logError(myCall.myCallId, error, log);
            }));
    }

    /**
     * New implementation of bargeIn webrtc Mode (not delivering bargeIn, just outgoing call)
     * @param localConnectionId
     * @param mode
     */
    bargeIn$(localConnectionId: number, mode: BargeInMode) {
        const id = this.myCallId++;
        const log: RequestLog = {
            loggingLevel: PHONE_SERVICE_LOG,
            requestState: REQ_PENDING,
            reqIndex: id,
            message: `Sending request to BargeIn into call with Local Connection ${localConnectionId}`,
            operationName: 'BargeIn'
        };
        this.logger.showRequest(log);
        this.callControl.next(new RequestTryingCall(BargeInMode[mode], true, id));
        return this.webrtcService.makeBargeInCall(localConnectionId, id, mode)
            .pipe(
                tap(val => {
                    this.callControl.next(new RequestProcessingWebRtcCall(id, val.lastWebRTCState.Id));
                    this.logger.showRequest({
                        ...log,
                        requestState: REQ_FULFILLED,
                        message: `WebRTC connection with ID ${val.lastWebRTCState.Id} attached to MyCall with ID ${id}`
                    });
                }),
                catchError((error: unknown) => {
                    return this.logError(id, error, log);
                })
            );
    }

    private onError(error : any) {
        if (error && error.source instanceof ResponseAcknowledge) {
            switch (error.source.ErrorType) {
                case 42:
                case 2:
                case 9:
            }
        }
        else if (error.message !== 'Invalid connection Id') {
            this.modalService.error(error);
        }
    }

    public call$(phoneNumber: string, video?: boolean, tapiCallId?: string) {
        if (phoneNumber) {
            this.lastDialerNumber = phoneNumber;
        }
        const log: RequestLog = {
            loggingLevel: PHONE_SERVICE_LOG,
            requestState: REQ_PENDING,
        };
        return this.settingsService.preprocessPhoneNumber(phoneNumber).pipe(
            switchMap(phoneNumber =>
                combineLatest([this.myCalls$, this.deviceService.selectedPhoneDevice$, this.myphone.myPhoneSession])
                    .pipe(take(1), switchMap(values => {
                        const [myCalls, device, session] = values;
                        if (!myCalls.isNewCallAllowed) {
                            return EMPTY;
                        }
                        const isWebRTC = isWebRTCEndpoint(device) || !device;

                        const id = this.myCallId++;
                        log.reqIndex = id;
                        this.callControl.next(new RequestTryingCall(phoneNumber, isWebRTC, id, tapiCallId));
                        let request: Observable<any>;
                        // WebRTC case, we are using makeCall and assigning webRTCId to mark myCall, which is associated with requested webRTC call
                        if (isWebRTC) {
                            log.operationName = 'WebRTCMakeCall';
                            this.logger.showRequest({
                                ...log,
                                message: `Sending request to Destination: ${phoneNumber}`
                            });
                            request = this.webrtcService.makeCall(phoneNumber, id, video || false)
                                .pipe(
                                    // using tap because we have to make attaching request id like side effect, because we don't want to wait response before show trying call
                                    tap(val => {
                                        this.callControl.next(new RequestProcessingWebRtcCall(id, val.lastWebRTCState.Id));
                                        this.logger.showRequest({
                                            ...log,
                                            requestState: REQ_FULFILLED,
                                            message: `WebRTC connection "${val.lastWebRTCState.Id}" attached to MyCall "${id}"`
                                        });
                                    }),
                                    map(val => val.lastWebRTCState),
                                );
                        }
                        // External Device case, we are using callControl API and assigning localConnectionId to mark myCall, which is associated with incoming local connection
                        // (that's why from now we don't need to wait this.newCallConnectionId with delay(8000))
                        else {
                            log.operationName = 'RequestMakeCall';
                            this.logger.showRequest({
                                ...log,
                                message: `Sending request to Destination: ${phoneNumber} and device: ${device.Contact}`
                            });
                            request = session.get<ResponseCallControl>(new RequestMakeCall({ Destination: phoneNumber, DeviceID: device.Contact, EnableCallControl: true }))
                                .pipe(
                                    tap(val => {
                                        this.callControl.next(new RequestProcessingLocalConnectionCall(id, val.LocalConnectionId));
                                        this.refresh$.next();
                                        this.logger.showRequest({
                                            ...log,
                                            requestState: REQ_FULFILLED,
                                            message: `Local Connection "${val.LocalConnectionId}" attached to MyCall "${id}" and device: ${device.Contact}`
                                        });
                                    }),
                                    map(val => val.LocalConnectionId),
                                );
                        }
                        return request.pipe(
                            take(1),
                            catchError((error: unknown) => {
                                return this.handleTryingCallError(id, error, log);
                            })
                        );
                    })))
        );
    }

    public call(phoneNumber: string, video?: boolean): Subscription | undefined {
        return this.call$(phoneNumber, video).pipe(take(1)).subscribe({
            error: (error: unknown) => {
                this.modalService.error(error);
            }
        });
    }

    private updateMyCallFromLocalConnection(myCall: MyCall, conn: LocalConnection, session: MyPhoneSession, sipDialogs: string[] | undefined, pushRegDeviceContact: string | undefined) {
        const phoneNumber = conn.OtherPartyCallerId;
        myCall.isPushCall = conn.DeviceContact === pushRegDeviceContact;
        myCall.localConnectionId = conn.Id;
        myCall.SIPDialogID = sipDialogs ?? [];
        myCall.deviceDirectControl = myCall.isWebRTCCall ? true : conn.DeviceDirectControl;
        myCall.isRecording = conn.Recording;
        myCall.isRecordingPaused = conn.RecordingPaused;
        myCall.legId = conn.LegId;
        myCall.isIncoming = conn.IsIncoming;
        myCall.callId = conn.CallId;
        myCall.isQueueCall = conn.OriginatorType === DnType.Queue;
        myCall.isDivertToVmAllowed = conn.OriginatorType !== DnType.Queue;
        myCall.DeviceID = conn.DeviceContact;
        myCall.prependNameToCID = session.systemParameters.PrependNameToCID;

        if (conn.State === LocalConnectionState.UnknownState) {
            return;
        }
        const oldPhone = myCall.phone;
        const oldState = myCall.state;
        const oldCrmUrl = myCall.lcCrmUrl;
        const oldOtherPartyDisplayName = myCall.otherPartyDisplayNameLC;

        const firstContactRef = (conn.OtherPartyCompanyContactRefs === undefined) ? '' : conn.OtherPartyCompanyContactRefs.split(',')[0];

        if (myCall.phone !== phoneNumber || myCall.otherPartyCompanyContactRefs !== firstContactRef || myCall.otherPartyDnType !== conn.OtherPartyType || myCall.otherPartyDn !== conn.OtherPartyDn) {
            myCall.phone = phoneNumber;
            myCall.otherPartyDn = conn.OtherPartyDn;
            myCall.otherPartyDnType = conn.OtherPartyType;
            myCall.otherPartyCompanyContactRefs = firstContactRef;

            myCall.integrationUrl = undefined;
        }
        // set CrmContactUrl from  local connection
        const oldDisplayName = myCall.displayName;

        if (conn.CrmContactUrl && conn.CrmContactUrl !== oldCrmUrl) {
            // myCall.contact.crmUrl = conn.CrmContactUrl; do it later
            myCall.lcCrmUrl = conn.CrmContactUrl;
            // If CRM URL CHANGED And contact is not extension, we will use crm contact name, delivered by local connection
            myCall.otherPartyDisplayNameLC = conn.OtherPartyDisplayName;
        }
        else if (!conn.CrmContactUrl && !String.isNullOrEmpty(myCall.lcCrmUrl)) {
            myCall.lcCrmUrl = '';
        }/**
         * If conn has other party display name, it should be the most important name from outside (except personal phonebook), but if it's not, and we found contact
         * when lookup, we will try to append personal contact info
         */
        if (conn.OtherPartyDisplayName) {
            myCall.otherPartyDisplayNameLC = conn.OtherPartyDisplayName;
        }
        else {
            myCall.otherPartyDisplayNameLC = '';
        }

        const log: Log = {
            loggingLevel: PHONE_SERVICE_LOG
        };

        if (myCall.state === LocalConnectionState.Ringing && (conn.State === LocalConnectionState.Connected || conn.State === LocalConnectionState.WaitingForNewParty)) {
            this.callState.incomingCallAccepted(myCall);
            this.logger.showInfo({
                loggingLevel: PHONE_SERVICE_LOG,
                message: `Incoming call accepted, ${myCall.idCallLegInfo}`
            });
        }
        if (myCall.state === LocalConnectionState.Dialing && conn.State === LocalConnectionState.Connected) {
            this.callState.outgoingCallAccepted(myCall);
            this.logger.showInfo({
                loggingLevel: PHONE_SERVICE_LOG,
                message: `Outgoing call accepted, ${myCall.idCallLegInfo}`
            });
        }
        if (myCall.state === LocalConnectionState.Dialing && conn.State === LocalConnectionState.Dialing) {
            this.callState.outgoingCall(myCall);
        }

        myCall.state = conn.State;
        if (myCall.state === LocalConnectionState.Connected && oldState !== LocalConnectionState.Connected && myCall.isWebRTCCall) {
            // perform single operation accept call on headset if myCall state updated to connected
            this.headsetService.callAccepted(myCall.myCallId);
            this.logger.showInfo({
                loggingLevel: PHONE_SERVICE_LOG,
                message: `webRTC Call established with SIPDialogID ${myCall.SIPDialogID?.[0]}, ${myCall.idCallLegInfo}`
            });
        }

        if (conn.AnsweredAt) {
            myCall.startedAt = session.protobufToClientTime(conn.AnsweredAt).getTime();
        }
        myCall.isConnCapabilitiesTransfer = (conn.CallCapabilitiesMask & ConnectionCapabilities.CC_Transfer) === ConnectionCapabilities.CC_Transfer;

        if (myCall.state === LocalConnectionState.Ringing) {
            if (oldPhone !== myCall.phone || oldDisplayName !== myCall.displayName) {
                this.notificationService.createCallNotification(myCall, session.domainUrl);
            }
        }
        else {
            this.notificationService.removeCallNotification(myCall);
        }

        if (oldPhone !== myCall.phone) {
            myCall.contact = defaultContact;
            this.lookUpContact$(myCall);
        }
        else if (myCall.otherPartyDisplayNameLC !== oldOtherPartyDisplayName || myCall.lcCrmUrl !== oldCrmUrl) {
            this.assignPersonalInformationFromContact(myCall, session);
        }
    }

    private handleCalls(
        myCalls: MyCallsInternal,
        connections: LocalConnection[],
        session: MyPhoneSession,
        device: Registration | undefined,
        mediaDescriptions: MediaDescription[],
        pushRegContact: string | undefined,
        isWebRtcEndpointRegistered: boolean,
        deletedConnections: LocalConnection[]
    ): MyCallsInternal {
        /**
         * We are not filtering connections at this point, because now, flow should be simpler:
         * 1. Handle Existing Calls:
         *      1. We take all connections, associate them with existing calls (updated calls)
         *      2. If we have connections, that still didn't update any call, but they will update it soon, we need to skip such connections to prevent emitting new calls,
         *      we have such connections if:
         *                  1) we have at least 1 not updated myCall (unUpdatedCallsMap), when only webrtc state has been attached to call for example or any request is processing.
         *                  2) we have incoming connection that should replace another connections, or it will disappear
         *                  after drop call, in this case myCall will have replacingConnectionId, attached to this call earlier when pickup or drop
         *                  3) there is trying call, that is skipped for now
         * 2. Handle New calls:
         *      1. In case of new incoming connections and stable state of all calls, emitting new one if it corresponds to selected device
         *      2. Unassociated, if after all procedures because of any reason, we have a pair of
         *      LocalConnection + webrtcCall and don't have myCall for them, we will produce a new call
         */

        // Build all connections map
        const isWebRTC = isWebRTCEndpoint(device);
        const connectionsMap: ConnectionsMap =
            connections
                .reduce((result: ConnectionsMap, item) => {
                    result[item.Id] = item;
                    return result;
                }, {});
        const sipDialogsByCallId =
            connections
                .reduce((result: SipDialogMap, item) => {
                    let dialogs = result[item.CallId];
                    if (!dialogs) {
                        dialogs = [];
                        result[item.Id] = dialogs;
                    }
                    dialogs.push(item.SIPDialogID);
                    return result;
                }, {});
        const unUpdatedCallsMap: { [id: number]: MyCall } = {};

        // Update existing calls
        const updatedCalls = myCalls.calls
            .map(myCall => {
                // SKIP HANDLING TRYING CALLS
                if (myCall.isTrying) {
                    myCall.deletedConnections = myCall.deletedConnections.concat(deletedConnections.map(conn => conn.Id));
                    return myCall;
                }
                else {
                    myCall.deletedConnections = [];
                }
                // OPERATIONS WITH CALLS FROM PUSH WC LAUNCH (in case of push wakeup, we attached localConnectionId to this call, so this connection is strictly associated with this call,
                // we dont need to wait for something, so performing update)
                if (myCall.localConnectionId && !myCall.callId) {
                    const conn = Object.values(connectionsMap).find(connection => connection.Id === myCall.localConnectionId);
                    if (conn) {
                        this.updateMyCallFromLocalConnection(myCall, conn, session, sipDialogsByCallId[conn.Id], pushRegContact);
                        if (myCall.fromPushWakeUpAction === PushAction.Answer) {
                            this.answer(myCall, false);
                        }
                        if (myCall.fromPushWakeUpAction === PushAction.Decline) {
                            this.drop(myCall);
                        }
                        delete connectionsMap[myCall.callId];
                    }
                }
                // OPERATIONS WITH WEBRTC CALLS
                if (isWebRTC && myCall.webRTCId) {
                    // If call is webrtc we are following logic with leading webrtc calls. WEBRTC OUTGOING
                    const webRTCCallMedia = mediaDescriptions.find(x => myCall.webRTCId === x.lastWebRTCState.Id);
                    if (!webRTCCallMedia) {
                        this.headsetService.terminateCall(myCall.myCallId);
                        return undefined;
                    }
                    const webRTCCall = webRTCCallMedia.lastWebRTCState;

                    if (webRTCCall.SIPDialogID) {
                        if (!myCall.SIPDialogID?.includes(webRTCCall.SIPDialogID)) { // webrtc state attached, perform single update (to not update always when connections or something else changing)
                            myCall.SIPDialogID = [webRTCCall.SIPDialogID];
                            myCall.media = webRTCCallMedia;
                            myCall.isWebRTCCall = true;
                        }
                    }
                    const conn = Object.values(connectionsMap).find(connection => myCall.SIPDialogID?.includes(connection.SIPDialogID));
                    // If we have relevant localConnections (SipId of myCall is equals local connection SipId),
                    // we are going to update myCall with local connection. else we are going to work only with webRtc call
                    if (conn) {
                        this.updateMyCallFromLocalConnection(myCall, conn, session, [webRTCCall.SIPDialogID], pushRegContact);
                        // myCall.isWebrtcLcConnectionCompared = true; TODO FOR LATER
                        delete connectionsMap[myCall.localConnectionId];
                        delete unUpdatedCallsMap[myCall.myCallId];
                    }
                    else {
                        unUpdatedCallsMap[myCall.myCallId] = myCall;
                    }
                }
                // OPERATIONS WITH EXTERNAL DEVICE CALLS
                else {
                    const initialConnection = connectionsMap[myCall.localConnectionId];
                    if (!initialConnection) {
                        this.headsetService.terminateCall(myCall.myCallId); // IF incoming call dropped it's this exact case when lc gone(doesn't matter that it isn't webrtc)
                        return undefined;
                    }
                    delete connectionsMap[initialConnection.Id];
                    this.updateMyCallFromLocalConnection(myCall, initialConnection, session, sipDialogsByCallId[initialConnection.Id], pushRegContact);
                }

                return myCall;
            })
            .filter(notEmpty);

        // Bind trying call and process replaced calls
        const tryingCall = updatedCalls.find(call => call.isTrying);

        // HANDLING WEBRTC TRYING CALLS (after request has attached WebrtcId)
        if (isWebRTC) { //! device to be able to call even if we don`t have device
            mediaDescriptions.forEach(media => {
                if (tryingCall && media.lastWebRTCState.Id === tryingCall.webRTCId) {
                    tryingCall.isTrying = false;
                }
            });
        }
        // HANDLING LC TRYING CALLS (after request has attached lcId)
        else {
            Object.values(connectionsMap).forEach(conn => {
                if (tryingCall && conn.Id === tryingCall.localConnectionId) {
                    tryingCall.isTrying = false;
                    unUpdatedCallsMap[tryingCall.myCallId] = tryingCall; // we started to handle new lc call, it's not new, will update next iteration
                    this.updateMyCallFromLocalConnection(tryingCall, conn, session, sipDialogsByCallId[conn.Id], pushRegContact);
                }
            });
        }
        // HANDLING REPLACE LOCAL CONNECTIONS
        Object.values(connectionsMap).forEach((conn: LocalConnection) => {
            // Process replaces
            if (conn.ReplacesCall && conn.ReplacesLeg) {
                const myReplacedCall = updatedCalls.find(call => call.callId === conn.ReplacesCall && call.legId === conn.ReplacesLeg);
                if (myReplacedCall) {
                    // We need to shadow a connection which was replaced so it won't reappear
                    // as a new call next time
                    const shadowCall = new MyCall(this.myCallId++, myReplacedCall.isWebRTCCall);
                    Object.assign(shadowCall, myReplacedCall);
                    shadowCall.isHidden = true;
                    shadowCall.isReplaced = true;
                    updatedCalls.push(shadowCall);

                    // Update replaced call
                    this.updateMyCallFromLocalConnection(myReplacedCall, conn, session, sipDialogsByCallId[conn.Id], pushRegContact);
                    delete connectionsMap[conn.Id];
                }
            }
        });
        // Check which calls are missing and report them
        // need to do it here because of replaced calls //TODO: review ??
        const updatedCallsMap = updatedCalls.reduce<{ [id: number]: MyCall }>((result, item) => {
            result[item.myCallId] = item;
            return result;
        }, {});
        myCalls.calls.forEach(myCall => {
            if (!updatedCallsMap[myCall.myCallId] && !myCall.isReplaced) {
                this.callState.terminateCall(myCall);
                this.notificationService.removeCallNotification(myCall);
            }
        });

        // HANDLING NEW CALLS (FROM LOCAL CONNECTIONS) WEBRTC-NOREG/EXTERNAL CASES

        // Calls which still hasn't update from local connection but it's coming soon so we can't emit new call from local connection when it's processing
        // Trying calls are also unstable state, becase when we have trying call we are waiting for response from webrtc or call control API
        const stable = Object.values(unUpdatedCallsMap).length === 0 && !updatedCalls.find(call => call.isTrying);
        const newCalls = (stable ? List(Object.values(connectionsMap))
            .filter(conn => {
                // CHECK device compliance. We are getting connections from our selected device;
                if (isWebRTC && !isWebRtcEndpointRegistered) {
                    return conn.DeviceContact === pushRegContact;
                }
                return conn.DeviceContact === device?.Contact;
            })
            .map((initialConnection: LocalConnection) => {
                if (!initialConnection || myCalls.calls.some(call => call.replacingConnectionId === initialConnection?.Id)) {
                    // This call either ended or replaced
                    return undefined;
                }
                const myCall = new MyCall(this.myCallId++, device?.UserAgent === AgentWebclient);
                this.updateMyCallFromLocalConnection(myCall, initialConnection, session, sipDialogsByCallId[initialConnection.Id], pushRegContact);
                this.logger.showInfo({
                    loggingLevel: PHONE_SERVICE_LOG,
                    message: `New incoming call from Local Connection, ${myCall.idCallLegInfo}`
                });
                if (isWebRTC) {
                    // WEBRTC NO REGISTRATION CASE HEADSET NOTIFICATION
                    this.headsetService.incomingCall(myCall.myCallId, myCall.media.lastWebRTCState);
                }
                return myCall;
            })
            : List<MyCall>([])).filter(notEmpty);

        /**
         * There are calls, which haven't been handled for any reason (they are not represented in myCalls), but they are valid, because they have actual SipDialogId pair;
         */
        const unassociatedWebRtcCalls = mediaDescriptions.map(media => {
            const unassociatedConnection = Object.values(connectionsMap).find(connection => {
                return connection.SIPDialogID === media.lastWebRTCState.SIPDialogID
                    && !myCalls.calls.some(call => call.SIPDialogID?.includes(connection.SIPDialogID));
            });
            if (!unassociatedConnection) {
                return undefined;
            }
            const myCall = new MyCall(this.myCallId++, isWebRtcOrNoDevice(device));
            myCall.media = media;
            this.updateMyCallFromLocalConnection(myCall, unassociatedConnection, session, sipDialogsByCallId[unassociatedConnection.Id], pushRegContact);
            // WEBRTC REGISTRATION CASE HEADSET NOTIFICATION
            this.headsetService.incomingCall(myCall.myCallId, myCall.media.lastWebRTCState);
            return myCall;
        }).filter(notEmpty);
        // Show new calls on top
        const newCallsWithUnassociated = newCalls.concat(unassociatedWebRtcCalls);
        const allCalls = newCallsWithUnassociated.concat(updatedCalls);
        return myCalls.merge({ calls: allCalls, hasNewCalls: !newCallsWithUnassociated.isEmpty() });
    }

    terminateHeadsetCall(myCallId: number) {
        this.headsetService.terminateCall(myCallId);
    }

    logError(callId: number, error: unknown, log?: RequestLog) {
        if (log) {
            this.logger.showRequest({
                ...log,
                loggingLevel: PHONE_SERVICE_LOG,
                requestState: REQ_FAILED,
                message: `Request "${callId}" failed`
            });
        }
        return (error instanceof TimeoutError) ? EMPTY : throwError(() => error);
    }

    handleTryingCallError(callId: number, error: unknown, log?: RequestLog) {
        // Remove call because media devices will stay open
        destroyMedia(this.webrtcService.getLastOutgoingMedia());
        // Remove trying call asap
        this.headsetService.terminateCall(callId);
        this.callControl.next(new RemoveTryingCall(callId));
        if (log) {
            return this.logError(callId, error, log);
        }
        return (error instanceof TimeoutError) ? EMPTY : throwError(() => error);
    }
}
