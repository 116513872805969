import dayjs from 'dayjs';
import { CalendarServiceType } from '@myphone';

export type CalendarUrlData = {
    startDate: string,
    endDate: string,
    description: string,
    title: string,
    location: string
}

export class CalendarUtils {
    static testBuildExternalCalendarUrl: CalendarUrlData;

    public static accessTestBuildExternalCalendarUrl() {
        return CalendarUtils.testBuildExternalCalendarUrl;
    }

    static buildExternalCalendarUrl(calendarType: CalendarServiceType,
        startDate: Date, endDate: Date,
        nameOfConference: string, descriptionOfConference: string): string {
        if (calendarType === CalendarServiceType.Office365 || calendarType === CalendarServiceType.Outlook) {
            descriptionOfConference = descriptionOfConference.replace(/\n/g, '<br>');
        }
        const data = CalendarUtils.getSanitizedData(startDate, endDate, descriptionOfConference, nameOfConference);
        if (calendarType === CalendarServiceType.Google) {
            return CalendarUtils.getGoogleCalendarUrl(data);
        }
        else {
            // Use UTC time for outlook and office
            data.startDate = dayjs(startDate).format('YYYY-MM-DDTHH:mm:ss');
            data.endDate = dayjs(endDate).format('YYYY-MM-DDTHH:mm:ss');

            if (calendarType === CalendarServiceType.Outlook) {
                return CalendarUtils.getOutlookCalendarUrl(data);
            }
            else if (calendarType === CalendarServiceType.Office365) {
                return CalendarUtils.getOffice365CalendarUrl(data);
            }
        }

        this.testBuildExternalCalendarUrl = data;
        return '';
    }

    static buildIcsData(startDate: Date, endDate: Date, nameOfConference: string, descriptionOfConference: string) {
        const data = {
            startDate: CalendarUtils.toUniversalTime(startDate),
            endDate: CalendarUtils.toUniversalTime(endDate),
            description: descriptionOfConference as any || '',
            title: nameOfConference || '',
            location: '',
        };

        return CalendarUtils.getIcsCalendar(data);
    }

    static buildIcsFile(startDate: Date, endDate: Date, nameOfConference: string, descriptionOfConference: string) {
        const data = {
            startDate: CalendarUtils.toUniversalTime(startDate),
            endDate: CalendarUtils.toUniversalTime(endDate),
            description: descriptionOfConference as any || '',
            title: nameOfConference || '',
            location: '',
        };

        const fileName = CalendarUtils.getIcsFileName(nameOfConference);
        const icsData = CalendarUtils.getIcsCalendar(data);

        return CalendarUtils.getIcsBlob(icsData);
    }

    static getSanitizedData(startDate: Date, endDate: Date, descriptionOfConference: string, nameOfConference: string): CalendarUrlData {
        return {
            startDate: CalendarUtils.toUniversalTime(startDate),
            endDate: CalendarUtils.toUniversalTime(endDate),
            description: encodeURIComponent(descriptionOfConference as any || ''),
            title: encodeURIComponent(nameOfConference || ''),
            location: encodeURIComponent(''),
        };
    }

    // static getYahooCalendarUrl(data: CalendarUrlData) {
    //     let yahooCalendarUrl = 'http://calendar.yahoo.com/?v=60&view=d&type=20';
    //     let duration = CalendarUtils.getHoursDuration(data.startDate, data.endDate);
    //
    //     yahooCalendarUrl += '&TITLE=' + data.title;
    //     yahooCalendarUrl += '&ST=' + data.startDate + '&DUR=' + duration;
    //     yahooCalendarUrl += '&DESC=' + data.description;
    //     yahooCalendarUrl += '&in_loc=' + data.location;
    //
    //     return yahooCalendarUrl;
    // }

    static getOutlookCalendarUrl(data: CalendarUrlData) {
        return CalendarUtils.getMicrosoftCalendarUrl(data, 'https://outlook.live.com/owa/?path=/calendar/action/compose&rru=addevent');
    }

    static getOffice365CalendarUrl(data: CalendarUrlData) {
        return CalendarUtils.getMicrosoftCalendarUrl(data, 'https://outlook.office365.com/owa/?path=/calendar/action/compose&rru=addevent');
    }

    private static getMicrosoftCalendarUrl(data: CalendarUrlData, microsoftCalendarUrl: string) {
        microsoftCalendarUrl += '&subject=' + data.title;
        microsoftCalendarUrl += '&startdt=' + encodeURIComponent(data.startDate) + '&enddt=' + encodeURIComponent(data.endDate);
        microsoftCalendarUrl += '&body=' + data.description.replaceAll('%2B', '%252B'); // Microsoft calendar double decodes URL, so make "+" double encoded
        microsoftCalendarUrl += '&location=' + data.location;
        microsoftCalendarUrl += '&allday=false';

        return microsoftCalendarUrl;
    }

    static getGoogleCalendarUrl(data: CalendarUrlData) {
        let googleCalendarUrl = 'https://www.google.com/calendar/render?action=TEMPLATE';
        googleCalendarUrl += '&text=' + data.title;
        googleCalendarUrl += '&dates=' + data.startDate + '/' + data.endDate;
        googleCalendarUrl += '&details=' + data.description;
        googleCalendarUrl += '&location=' + data.location;
        return googleCalendarUrl;
    }

    static getIcsCalendar(data: CalendarUrlData) {
        return [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            'BEGIN:VEVENT',
            'DTSTAMP:' + CalendarUtils.toUniversalTime(new Date()),
            'UID:' + CalendarUtils.getUid(),
            'CLASS:PUBLIC',
            'DESCRIPTION:' + CalendarUtils.formatIcsText(data.description, 10000),
            'DTSTART:' + data.startDate,
            'DTEND:' + data.endDate,
            'LOCATION:' + CalendarUtils.formatIcsText(data.location, 64),
            'SUMMARY:' + CalendarUtils.formatIcsText(data.title, 10000),
            'TRANSP:TRANSPARENT',
            'END:VEVENT',
            'END:VCALENDAR',
            'DTSTAMP:' + CalendarUtils.getTimeCreated(),
            'PRODID:webclient3cx'
        ].join('\r\n');
    }

    /**
     * Return 12-hour format to 24-hour.
     *
     * @param  {Number} hours
     * @return {String}
     */
    static getMilitaryHours(hours: number) {
        if (hours % 1 === 0.5) {
            return `${Math.floor(hours)}30`;
        }
        return `${Math.round(hours)}00`;
    }

    /**
     * Gets the duration between dates.
     *
     * @param  {String} startDate
     * @param  {String} endDate
     * @param  {Number} timezone
     * @return {String}
     */
    static getHoursDuration(startDate: Date, endDate: Date, timezone?: number): string {
        const start = dayjs(startDate);
        const end = dayjs(endDate);

        if (timezone) {
            start.utcOffset(timezone);
            end.utcOffset(timezone);
        }

        const hours = dayjs
            .duration(end.diff(start))
            .asHours();

        return this.getMilitaryHours(hours);
    }

    /**
     * Removes line breaks and ensures that the string is no
     * longer than maxLength chars (or 75 chars if none specified).
     *
     * @param  {String} string to sanitize
     * @param  {Number} maxLength index of string to truncate at
     * @return {String}
     */
    static formatIcsText(str: string, maxLength: number): string {
        if (!str) {
            return '';
        }
        str = str.replace(/\n/g, '\\n');
        str = str.substring(0, maxLength);

        return str;
    }

    /**
     * Format time as a universal timestamp format w.r.t. the given timezone.
     *
     * @param  timestamp valid RFC-2822 string timestamp
     * @param  {String} timezone  tz offset (in minutes) (optional)
     * @return {String}
     */
    static toUniversalTime(date: Date, timezone?: string): string {
        const dt = dayjs(date);

        if (timezone) {
            dt.utcOffset(timezone);
        }
        return dt.format('YYYYMMDDTHHmmss');
    }

    /**
     * The name of the file will be the event title with alphanumeric chars
     * having the extension `.ics`.
     *
     * @param  {String} icsData
     * @return {Blob}
     */
    static getIcsBlob(icsData: string): Blob {
        return new Blob([icsData], {
            type: 'application/octet-stream'
        });
    }

    /**
     * Transforms given string to be valid file name.
     *
     * @param  {String} title
     * @return {String}
     */
    static getIcsFileName(title: string): string {
        if (!title) {
            return 'event.ics';
        }
        return `${title.replace(/[^\w ]+/g, '')}.ics`;
    }

    /**
     * Returns a random base 36 hash for iCal UID.
     *
     * @return {String}
     */
    static getUid(): string {
        return Math.random().toString(36).substring(2);
    }

    /**
     * Returns a universal timestamp of current time.
     *
     * @return {String}
     */
    static getTimeCreated(): string {
        return dayjs().format('YYYYMMDDTHHmmss');
    }

    static isIOS(window: Window & { MSStream?: unknown }) {
        // eslint-disable-next-line deprecation/deprecation
        const { platform } = window.navigator;

        if (typeof window.MSStream !== 'undefined') {
            // filter out IE (adds "iPhone" to user-agent on Windows Phone 8.1)
            return false;
        }

        if (/iPad|iPhone|iPod/.test(platform)) {
            return true;
        }

        if (platform === 'MacIntel') {
            // iPad OS 13
            return window.navigator.maxTouchPoints > 1;
        }

        return false;
    }
}
