import type { CheckOption } from '@webclient/fields/types';
import { EnumFilterValue } from '@office/standalones/odata-search';

export enum ParticipantType {
    All,
    Facebook,
    SmsMms,
    LiveChat,
    WhatsApp
}

export interface ParticipantTypeFilterParams {
    participant: ParticipantType | null
}

export const participantTypeOptions: CheckOption<ParticipantType>[] = [
    { value: ParticipantType.All, label: '_i18n.ReportsParticipantTypeAll' },
    { value: ParticipantType.Facebook, label: '_i18n.ReportsParticipantTypeFacebook' },
    { value: ParticipantType.SmsMms, label: '_i18n.ReportsParticipantTypeSmsMms' },
    { value: ParticipantType.LiveChat, label: '_i18n.ReportsParticipantTypeLiveChat' },
    { value: ParticipantType.WhatsApp, label: '_i18n.ReportsParticipantTypeWhatsApp' },
];

export function isParticipantType(value: unknown): value is ParticipantType {
    return participantTypeOptions.some(item => item.value === value);
}

export function translateParticipantType(value: ParticipantType | null): string {
    return participantTypeOptions.find(item => item.value === value)?.label || '';
}

export function newParticipantTypeFilterValue(): EnumFilterValue<ParticipantType> {
    return new EnumFilterValue<ParticipantType>('_i18n.ReportsChannel', 'participant', participantTypeOptions);
}
