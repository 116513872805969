import { Injectable } from '@angular/core';
import {
    RequestExactMatch,
    RequestLookupContact,
    RequestSearchCrmContacts,
    ResponseExactMatch,
    ResponseLookup,
    ResponseSearchCrmContacts,
    SearchByMask
} from '@myphone';
import { SearchContext } from '../search/search-context';
import { merge, Observable, of, takeUntil } from 'rxjs';
import { AppContact, defaultContact } from '../../myphone/contact';
import { catchError, delay, map, share, switchMap, take } from 'rxjs/operators';
import { MyPhoneService } from '../../myphone/myphone.service';
import { CrmContactsPage } from '@webclient/shared/service/crm-contacts.response';

const MinCrmSearchLength = 2;

@Injectable({ providedIn: 'root' })
export class ContactSearcherService {
    private readonly SHOW_LOADING_IN = 300;
    constructor(private myPhone: MyPhoneService) {
    }

    public requestFactory(params: Partial<RequestLookupContact>, context: SearchContext): RequestLookupContact {
        const req = Object.assign(new RequestLookupContact(context.request), params);
        if (req.Input) {
            if (context.isInValidForNumberRequst(req.Input)) {
                req.SearchBy = (req.SearchBy & SearchByMask.SBM_ExtNumber) === SearchByMask.SBM_ExtNumber ?
                    req.SearchBy ^ SearchByMask.SBM_ExtNumber : req.SearchBy;
                req.SearchBy = (req.SearchBy & SearchByMask.SBM_OtherNumber) === SearchByMask.SBM_OtherNumber ?
                    req.SearchBy ^ SearchByMask.SBM_OtherNumber : req.SearchBy;
            }
        }
        return req;
    }

    public requestContact(request: RequestLookupContact): Observable<ResponseLookup> {
        return this.myPhone.myPhoneSession.pipe(
            take(1),
            switchMap(session => session.get(request)),
            map(response => response as ResponseLookup)
        );
    }

    public requestCrmContact(request: RequestSearchCrmContacts): Observable<CrmContactsPage> {
        return this.myPhone.myPhoneSession.pipe(
            take(1),
            switchMap(session => {
                if (session.systemParameters.CrmConfigured && request.InputString?.length >= MinCrmSearchLength) {
                    const request$ = session.get<ResponseSearchCrmContacts>(request).pipe(
                        map(action => new CrmContactsPage({
                            status: 'ok',
                            contacts: action.Entries.map(AppContact.create)
                        })), share(),
                    );
                    // if the request does not answer in TIME then return the loading status
                    return merge(request$, of(new CrmContactsPage()).pipe(delay(this.SHOW_LOADING_IN), takeUntil(request$)));
                }
                else {
                    return of(new CrmContactsPage({
                        status: 'unavailable'
                    }));
                }
            }
            ),
            catchError((err: unknown) => of(new CrmContactsPage({
                status: 'error',
                // TODO check errors
                error: (err as Error).message
            })))
        );
    }

    public requestContactByNumber(phoneNumber: string, context: SearchContext = SearchContext.headerSearch()): Observable<AppContact> {
        if (context.isInValidForNumberRequst(phoneNumber) || !phoneNumber.match(/\d+/g)) {
            return of(defaultContact);
        }
        const request = Object.assign(new RequestExactMatch({ Number: phoneNumber }), context.exactMatchType);
        return this.myPhone.myPhoneSession.pipe(
            take(1),
            switchMap(session => {
                return session.get<ResponseExactMatch>(request).pipe(
                    map((response: ResponseExactMatch) => session.createMergedContact(response.Contact)),
                    catchError((error: unknown) => {
                        return of(session.createMergedContact(undefined));
                    }),
                );
            })
        );
    }

    public requestContactByEmail(email: string, context: SearchContext = SearchContext.allEmails()): Observable<AppContact> {
        if (!context.contextRequestHasEmail()) {
            return of(defaultContact);
        }

        return this.myPhone.myPhoneSession.pipe(take(1), switchMap(session => {
            return session.get<ResponseLookup>(Object.assign(
                new RequestLookupContact(context.request),
                new RequestLookupContact({ Input: email, SearchBy: SearchByMask.SBM_Email }))
            ).pipe(
                map((response: ResponseLookup) => {
                    if (response.TotalCount === 0) {
                        return defaultContact;
                    }
                    else {
                        return session.createMergedContact(
                            response.Entries.find(
                                entr => entr.AddressNumberOrData5.toLowerCase() === email.toLowerCase()
                            )
                        );
                    }
                }),
                catchError((error: unknown) => {
                    return of(session.createMergedContact(undefined));
                }),
            );
        }));
    }
}
