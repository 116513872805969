import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { WebmeetingLayoutComponent } from '@webclient/webmeeting/webmeeting-layout.component';

@NgModule({
    declarations: [
        // Components / Directives/ Pipes
        WebmeetingLayoutComponent
    ],
    exports: [
        WebmeetingLayoutComponent
    ],
    imports: [
        SharedModule,
    ]
})
export class WebmeetingModule {
}
