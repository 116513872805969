import {
    combineLatest, concat, from, Observable, of
} from 'rxjs';
import { MyPhoneService } from '../myphone/myphone.service';
import { Injectable } from '@angular/core';
import dayjs from 'dayjs';

import { extractErrorMessage, ModalService } from '../modal/app-modal.service';
import { String } from '../shared/string.utils';
import { VideoConferenceService } from './video-conference/video-conference.service';
import { AppContact } from '../myphone/contact';
import { DetailedConferenceInfoVM } from './detailed-conference-info-vm';
import { SimpleConferenceParticipantVM } from './simple-conference-participant-vm';
import {
    ActionType,
    CalendarServiceType,
    ConferenceState,
    MyExtensionInfo,
    RequestChangeConference,
    RequestCreateConferenceScheduleTemplate,
    RequestGetConferenceSchedule,
    RequestUpsertScheduledConference,
    WebMeetingParticipant,
    WebMeetingParticipants,
    WebMeetingState
} from '@myphone';
import { ExternalCalendarService } from './conference-editor/external-calendar.service';
import { ConferenceWrapper } from './conference-wrapper';
import { MeetingListService } from './meeting-list/meeting-list.service';
import { ConferenceViewService } from './conference-view/conference-view.service';
import { TranslateService } from '@ngx-translate/core';
import {
    catchError, filter, map, switchMap, take, tap, withLatestFrom
} from 'rxjs/operators';
import { UtilsService } from '../shared/utils.service';
import { dateFormat } from '@webclient/meeting/meeting-consts';

enum Mode {
    Edit,
    New,
    View,
    Hidden
}

export type ContactAndUrls = {
    contact: AppContact,
    myJoinLink: string,
    otherGuyJoinLink: string,
    otherGuyInternalJoinLink: string,
    instantMeetingChatMessage: string
};

export const webMeetingInitializationDelay = 2000;

export type NewConferenceDescriptor = {
    isAudio: boolean,
    id: number
};

@Injectable()
export class MeetingService {
    public mode: Mode;

    constructor(private myPhoneService: MyPhoneService,
                private modalService: ModalService,
                private translateService: TranslateService,
                private meetingListService: MeetingListService,
                private audioConferenceService: ConferenceViewService,
                private externalCalendarService: ExternalCalendarService,
                private videoConferenceService: VideoConferenceService) {
    }

    getConferenceSchedule(conferenceState: ConferenceState): Observable<RequestUpsertScheduledConference> {
        const request = new RequestGetConferenceSchedule();
        request.Id = conferenceState.ScheduleID;
        request.PinCode = conferenceState.PinCode;

        return this.myPhoneService.get(request);
    }

    createOrUpdateConference(conference: DetailedConferenceInfoVM): Observable<NewConferenceDescriptor> {
        if (conference.IsVideoConference) {
            return this.createOrUpdateWebMeeting(conference).pipe(map((newConferenceId: any) => {
                return { isAudio: false, id: newConferenceId };
            }));
        }
        else {
            return this.createOrUpdateAudioConference(conference).pipe(map((newConferenceId: any) => {
                return { isAudio: true, id: newConferenceId };
            }));
        }
    }

    // Actually that could be update with external calendar or not, both cases are possible
    createOrUpdateConferenceWithExternalCalendar(conference: DetailedConferenceInfoVM) {
        return this.createOrUpdateConference(conference).pipe(
            switchMap((x: NewConferenceDescriptor) =>
                this.meetingListService.allConferences$.pipe(
                    map((list: ConferenceWrapper[]) => {
                        return list.find(wrapper => (x.isAudio && wrapper.isAudio && (+wrapper.audioConference.PinCode === x.id)) || (!x.isAudio && wrapper.isWebMeeting && wrapper.audioConference.ScheduleID === x.id))!;
                    }),
                    filter(Boolean),
                    take(1))
            ),
            switchMap((wrapper: ConferenceWrapper) => {
                // For webinar don't we always use Tcx type
                if (conference.calendarType === CalendarServiceType.Tcx) {
                    return of(wrapper);
                } // Just do nothing

                //
                const joinDetailsText$ =
                    this.audioConferenceService.getScheduledConferenceDetailsObservable(wrapper.audioConference.Id).pipe(map(x => (wrapper.isAudio ? x.EmailBody : x.webMeetingScheduleState.EmailBody)), take(1));

                return combineLatest([from(import(/* webpackChunkName: "sanitize-email" */ './sanitize-email-body')), joinDetailsText$]).pipe(tap((values) => {
                    const [sanitizeHtml, joinDetailsText] = values;
                    this.externalCalendarService.openExternalCalendar(conference.calendarType, conference.StartDate, conference.EndDate, conference.NameOfConference, sanitizeHtml.sanitizeEmailBody(joinDetailsText));
                }), map(x => wrapper));
            }),
            take(1)
        );
    }

    createOrUpdateWebMeeting(conference: DetailedConferenceInfoVM): Observable<number> {
        const request = this.createOrUpdateVideoConferenceHelper(conference, conference.WebMeetingState);
        return this.videoConferenceService.updateScheduledVideoConferenceData(request);
    }

    createOrUpdateAudioConference(viewModel: DetailedConferenceInfoVM): Observable<number> {
        let template$: Observable<RequestUpsertScheduledConference>;
        if (viewModel.IsNew) {
            template$ = this.getTemplate();
        }
        else {
            const request = new RequestGetConferenceSchedule();
            request.Id = viewModel.ScheduleId;
            request.PinCode = viewModel.PinCode;

            template$ = this.myPhoneService.get(request);
        }

        return template$.pipe(withLatestFrom(this.myPhoneService.myPhoneSession.pipe(take(1))), switchMap(values => {
            const [template, session] = values;

            const request: RequestUpsertScheduledConference
                = this.createOrUpdateAudioConferenceHelper(session.myInfo, template, viewModel);

            // That is critical line - ExternalNumberOfConference should be set other wise crash
            request.ExternalNumberOfConference = template.ExternalNumberOfConference || '';

            request.ConferencePIN = template.ConferencePIN;
            return this.updateScheduledAudioConferenceData(request).pipe(map(x => +request.ConferencePIN));
        }));
    }

    getTemplate(): Observable<any> {
        const request = new RequestCreateConferenceScheduleTemplate();
        return this.myPhoneService.get(request).pipe(catchError((error: unknown) => {
            this.modalService.error(error);
            return of(false);
        }));
    }

    updateScheduledAudioConferenceData(request: RequestUpsertScheduledConference): Observable<any> {
        return this.myPhoneService.get(request);
    }

    // Create webmeeting request object from the view model
    createOrUpdateVideoConferenceHelper(conference: DetailedConferenceInfoVM,
        webMeetingState: WebMeetingState): WebMeetingState {
        let wm: WebMeetingState = webMeetingState;

        if (wm === undefined) {
            wm = new WebMeetingState();
            wm.Participants = new WebMeetingParticipants();
            wm.Participants.Items = [];
        }

        const request = new WebMeetingState();
        const startDate = dayjs(conference.StartDate).utc().format(dateFormat);

        request.StartAtUTC = (wm.StartAtUTC === startDate)
            ? request.StartAtUTC
            : startDate;

        request.Name = (wm.Name === conference.NameOfConference)
            ? request.Name
            : conference.NameOfConference;

        request.Description = (wm.Description === conference.DescriptionOfConference)
            ? request.Description
            : conference.DescriptionOfConference;

        request.Duration = (wm.Duration === conference.Duration)
            ? request.Duration
            : conference.Duration;

        request.HideParticipants = (wm.HideParticipants === conference.HideNames)
            ? request.HideParticipants
            : conference.HideNames;

        request.MeetingProfile = (wm.MeetingProfile === conference.VideoConferenceProfileType.value)
            ? request.MeetingProfile
            : conference.VideoConferenceProfileType.value;

        request.Id = conference.Id;// or wm.Id ????
        request.NeedOrganizer = (wm.NeedOrganizer === conference.OrganizerConnectsFirst)
            ? request.NeedOrganizer
            : conference.OrganizerConnectsFirst;

        request.CanChangeMedia = (wm.CanChangeMedia === conference.AllowParticipantControl)
            ? request.CanChangeMedia
            : conference.AllowParticipantControl;

        const a = `${conference.ParticipantRightsAudio ? 'A' : ''}`;
        const v = `${conference.ParticipantRightsVideo ? 'V' : ''}`;
        const t = `${conference.ParticipantRightsChat ? 'T' : ''}`;
        const newParticipantProperties = `${a}${v}${t}`;

        request.ParticipantsProperties = (wm.ParticipantsProperties === newParticipantProperties)
            ? request.ParticipantsProperties
            : newParticipantProperties;

        request.ForceModerator = (wm.ForceModerator === conference.EveryoneIsAnOrganizer)
            ? request.ForceModerator
            : conference.EveryoneIsAnOrganizer;

        request.DisableUserInteractions = !conference.EnableAnnouncements;

        wm.Participants.Items.forEach(oldPart => {
            const i = conference.Participants.findIndex(exp => exp.Email === oldPart.Email);
            if (i === -1) {
                oldPart.Action = ActionType.Deleted;
            }
        });

        conference.Participants.forEach(p => {
            let participantToUpdate: WebMeetingParticipant;
            const i = wm.Participants.Items.findIndex(exp => exp.Email === p.Email);
            if (i === -1) {
                participantToUpdate = new WebMeetingParticipant();
                participantToUpdate.Action = ActionType.Inserted;
                participantToUpdate.Email = p.Email;
            }
            else {
                participantToUpdate = wm.Participants.Items[i];
                if ((participantToUpdate.ExtensionNumber === p.Number) && participantToUpdate.Name === p.Name) {
                    return;
                }

                participantToUpdate.Action = ActionType.Updated;
            }

            // Fill in extension number only for known extensions
            // otherwise MyPhone errors
            if (p.IsExtension) {
                participantToUpdate.ExtensionNumber = p.Number;
            }
            participantToUpdate.Name = p.Name;

            if ((participantToUpdate.ExtensionNumber === p.Number) && String.isNullOrWhitespace(participantToUpdate.Name)) {
                participantToUpdate.Name = UtilsService.substrEmailToName(participantToUpdate.Email);
            }
            wm.Participants.Items.push(participantToUpdate);
        });

        wm.Participants.Items.filter(f => f.Action === undefined).forEach(p => {
            p.Action = ActionType.NoUpdates;
        });

        request.CalendarType = conference.calendarType;
        request.Participants = wm.Participants;
        return request;
    }

    /*
    * UpdateList of emails in scheduled conference
    */
    updateScheduledConferenceEmails(conferenceState: ConferenceState, newEmails: string[]): Observable<any> {
        return this.getConferenceSchedule(conferenceState).pipe(
            switchMap((response: RequestUpsertScheduledConference) => {
                const oldEmails = response.Emails.split(',').filter(x => !!x);
                // ne - new email, oe - old email
                const reallyNewEmails = newEmails.filter((ne: string) => !oldEmails.find((oe: string) => oe === ne));
                response.Emails = [...oldEmails, ...reallyNewEmails].join(',');

                return this.updateScheduledAudioConferenceData(response);
            }));
    }

    forTestingcreateOrUpdateAudioConferenceHelper(myInfo: MyExtensionInfo,
        template: RequestUpsertScheduledConference,
        viewModel: DetailedConferenceInfoVM) {
        return this.createOrUpdateAudioConferenceHelper(myInfo, template, viewModel);
    }

    private createOrUpdateAudioConferenceHelper(myInfo: MyExtensionInfo,
        template: RequestUpsertScheduledConference,
        viewModel: DetailedConferenceInfoVM): RequestUpsertScheduledConference {
        const participantsWithoutOrganizer = viewModel.Participants !== undefined
            ? viewModel.Participants.filter(f => f.Number !== myInfo.Number)
            : [];

        const organizer = new SimpleConferenceParticipantVM({
            Name: myInfo.WmFriendlyName,
            Number: myInfo.Number,
            IsExtension: myInfo.Number !== '',
            Email: myInfo.Email
        });

        const participantsWithOrganizer = [...participantsWithoutOrganizer, organizer];

        const reqSchedule = new RequestUpsertScheduledConference();
        reqSchedule.Id = viewModel.IsNew ? reqSchedule.Id : viewModel.ScheduleId;
        reqSchedule.StartAtUTC = dayjs(viewModel.StartDate).utc().format(dateFormat);

        const duration = viewModel.Duration;
        reqSchedule.EndAtUTC = dayjs(dayjs(reqSchedule.StartAtUTC).add(duration, 'minutes')).format(dateFormat);

        reqSchedule.Name = viewModel.NameOfConference;
        reqSchedule.Description = viewModel.DescriptionOfConference;
        reqSchedule.DisableUserInteractions = !viewModel.EnableAnnouncements;

        if (viewModel.calendarType === CalendarServiceType.Tcx) {
            // Conference will call to participantsWithOrganizer now but webclient works different - it calls to conference place
            const internalParticipants = (/* viewModel.StartNow ? participantsWithoutOrganizer : */ participantsWithOrganizer)
                .filter(f => f.IsExtension)
                .map(m => m.Number)
                .join(',');

            const externalParticipants: string = participantsWithoutOrganizer
                .filter(f => !f.IsExtension && !String.isNullOrEmpty(f.Number)).map(m => m.Number).join(',');

            const emailsList = participantsWithOrganizer
                .filter(p => !String.isNullOrEmpty(p.Email) && !p.IsExtension)
                .map(p => p.Email);

            if (viewModel.StartNow) {
                emailsList.push(organizer.Email);
            }

            reqSchedule.Emails = emailsList.join(',');
            reqSchedule.InternalParticipants = internalParticipants;
            reqSchedule.CallToExternalNumbers = externalParticipants;
        }
        else {
            reqSchedule.Emails = '';
            reqSchedule.InternalParticipants = '';
            reqSchedule.CallToExternalNumbers = '';
        }

        reqSchedule.ConferencePIN = viewModel.PinCode;

        reqSchedule.Moderators = template.Moderators;
        reqSchedule.EmailBody = template.EmailBody;

        reqSchedule.ExternalNumberOfConference = viewModel.MainDidNumber;
        reqSchedule.CalendarType = viewModel.calendarType;

        return reqSchedule;
    }

    public stopPrivateConference() {

    }

    createConferenceScheduleTemplate(): Observable<any> {
        const request = new RequestCreateConferenceScheduleTemplate();
        return this.myPhoneService.get(request).pipe(catchError((error: unknown) => {
            this.modalService.error(error);
            return of(false);
        }));
    }

    sendDeleteActiveConferenceRequest(pin: string, showError = true) {
        const request: RequestChangeConference = new RequestChangeConference();
        request.method = 'delete';
        request.pin = pin;
        request.hold = '0';

        return this.myPhoneService.get(request).pipe(catchError((error: unknown) => {
            if (showError) {
                this.modalService.error(error);
            }
            return of([]);
        }));
    }

    sendDeleteScheduleConferenceRequest(pin: string, scheduledId: number) {
        const deleteScheduleReq = new RequestUpsertScheduledConference();
        deleteScheduleReq.Id = scheduledId;
        deleteScheduleReq.ConferencePIN = pin;
        deleteScheduleReq.DeleteSchedule = true;

        return this.myPhoneService.get(deleteScheduleReq);
    }

    sendDeleteVideoConferenceRequest(conference: ConferenceState): Observable<any> {
        const request = new WebMeetingState({
            Id: conference.ScheduleID,
            Action: ActionType.Deleted
        });
        const deleteSchedule$ = this.myPhoneService.get(request).pipe(
            catchError((error: unknown) => {
                if (extractErrorMessage(error) === 'Access denied.') {
                    this.modalService.error('_i18n.OnlyOrganizerDeletesConference');
                }
                else {
                    this.modalService.error(error);
                }
                return of([]);
            }));

        // We throttle error if conference is scheduled
        const deleteActive$ = this.sendDeleteActiveConferenceRequest(conference.PinCode, !conference.IsScheduled);
        return concat(deleteSchedule$, deleteActive$);
    }

    sendDeleteAudioConferenceRequest(conference: ConferenceState): Observable<any> {
        const deleteSchedule$ = this.sendDeleteScheduleConferenceRequest(conference.PinCode, conference.ScheduleID)
            .pipe(catchError(() => of(true)));

        // We throttle error if conference is scheduled
        const deleteActive$ = this.sendDeleteActiveConferenceRequest(conference.PinCode, !conference.IsScheduled);
        return concat(deleteSchedule$, deleteActive$);
    }
}
