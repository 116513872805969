import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DurationComponent } from '@webclient/duration/duration.component';

@NgModule({
    declarations: [
        DurationComponent
    ],
    exports: [
        DurationComponent
    ],
    imports: [
        CommonModule
    ]
})
export class DurationModule { }
