import { HttpErrorResponse } from '@angular/common/http';
import { PbxWarnings } from '@xapi';
import { TranslateService } from '@ngx-translate/core';

const SERVER_WARNING_TRANSLATIONS: Record<PbxWarnings, string> = {
    'WARNINGS.XAPI.INVALID_FILE_NAME': '_i18n.InvalidFileName',
    'WARNINGS.XAPI.INVALID_TIMEZONE': '_i18n.InvalidTimeZone',
    'WARNINGS.CSV_IMPORT_LIMIT_REACHED': '_i18n.MaximumNumberOfRowsAllowed',
    'WARNINGS.CSV_LINE_CORRUPTED': '_i18n.CsvLineCorrupted',
    'WARNINGS.FAX_SERVER_CANNOT_BE_DELETED': '_i18n.FaxServerCantBeDeleted',
    'WARNINGS.GROUP_CANNOT_BE_DELETED': '_i18n.DefaultGroupCantBeDeleted',
    'WARNINGS.GROUP_WITH_MEMBERS_CANNOT_BE_DELETED': '_i18n.DepartmentWithMembersCantBeDeleted',
    'WARNINGS.INTERNATIONALPREFIX_IS_MISSING': '_i18n.InternationalPrefixIsMissing',
    'WARNINGS.RING_GROUP_ENABLE_PAGING': '_i18n.XapiRingGroupEnablePaging',
    'WARNINGS.TIMEZONEID_IS_MISSING': '_i18n.TimezoneIsMissing',
    'WARNINGS.WAKEUP_IVR_EXISTS': '_i18n.XapiWakeUpIvrExists',
    'WARNINGS.WRONG_CSV_FILE_REQUIRED_COLUMNS_NOT_FOUND': '_i18n.RequiredColumnsNotFound',
    'WARNINGS.XAPI.ALREADY_IN_USE': '_i18n.XapiInUse',
    'WARNINGS.XAPI.CANNOT_CONNECT_FTP': '_i18n.CannotConnectFtp',
    'WARNINGS.XAPI.CANNOT_CONNECT_GOOGLE_BUCKET': '_i18n.CannotConnectGb',
    'WARNINGS.XAPI.CANNOT_CONNECT_SFTP': '_i18n.CannotConnectSftp',
    'WARNINGS.XAPI.CANNOT_CONNECT_SMB': '_i18n.CannotConnectSmb',
    'WARNINGS.XAPI.CAN_NOT_BE_EMPTY_STRING': '_i18n.DefaultErrorsRequired',
    'WARNINGS.XAPI.CHAT_LOG_IS_DISABLED': '_i18n.ChatLogIsDisabled',
    'WARNINGS.XAPI.CREATE_1_SIP_TRUCK_EMERGENCY': '_i18n.Create1SIPTrunkEm',
    'WARNINGS.XAPI.DUPLICATE': '_i18n.Duplicate',
    'WARNINGS.XAPI.FILE_FORMAT_IS_INCORRECT': '_i18n.IncorrectFileFormat',
    'WARNINGS.XAPI.FILE_IS_TOO_LARGE': '_i18n.FileSizeIsTooLarge',
    'WARNINGS.XAPI.FILE_NOT_FOUND': '_i18n.FileNotFound',
    'WARNINGS.XAPI.FORBIDDEN_CHANGE': '_i18n.ForbiddenChange',
    'WARNINGS.XAPI.INVALID': '_i18n.XapiInvalid',
    'WARNINGS.XAPI.INVALID_CREDENTIALS': '_i18n.XapiInvalidCredentials',
    'WARNINGS.XAPI.INVALID_HEX_CHARACTER': '_i18n.InvalidHexCharacter',
    'WARNINGS.XAPI.INVALID_LICENSE_TYPE': '_i18n.FeatureUnavailableOnCurrentEdition',
    'WARNINGS.XAPI.INVALID_PASSWORD': '_i18n.XapiInvalidPassword',
    'WARNINGS.XAPI.INVALID_PATH': '_i18n.XapiInvalidPath',
    'WARNINGS.XAPI.INVALID_PIN_NUMBER': '_i18n.InvalidPinNumber',
    'WARNINGS.XAPI.LENGTH_NOT_MORE_2048_CHARS': '_i18n.XapiStringTooLong2048',
    'WARNINGS.LENGTH_NOT_MORE_255_CHARS': '_i18n.XapiStringTooLong255',
    'WARNINGS.LENGTH_NOT_MORE_50_CHARS': '_i18n.XapiStringTooLong50',
    'WARNINGS.XAPI.MCM_MODE_REQUIRED': '_i18n.McmModeRequired',
    'WARNINGS.XAPI.NON_EXISTENT_EXT_NUMBER': '_i18n.ExtensionNumberNotFound',
    'WARNINGS.XAPI.NOT_FOUND': '_i18n.XapiNotFound',
    'WARNINGS.XAPI.NOT_SUPPORTED': '_i18n.XapiNotSupported',
    'WARNINGS.NO_MORE_NUMBERS_AVAILABLE': '_i18n.XapiNoMoreNumbersAvailable',
    'WARNINGS.XAPI.NO_USERS_IN_TEAMS': '_i18n.NoUsersInTeams',
    'WARNINGS.XAPI.NUMBER_IGNORED': '_i18n.XapiNumberIgnored',
    'WARNINGS.XAPI.OTHER_USER_ROLE_DOWNGRADE': '_i18n.OtherUserRoleDowngrade',
    'WARNINGS.XAPI.OUT_OF_THE_RANGE': '_i18n.XapiOutOfRange',
    'WARNINGS.XAPI.PATH_SHOULD_NOT_CONTAIN_SPACES': '_i18n.XapiInvalidPath',
    'WARNINGS.XAPI.PLAYLIST_IN_USE': '_i18n.PlaylistInUse',
    'WARNINGS.XAPI.PLAYLIST_NO_SOURCE': '_i18n.PlaylistSourceNotFound',
    'WARNINGS.XAPI.REQUIRED': '_i18n.DefaultErrorsRequired',
    'WARNINGS.XAPI.SBC_CERT_EXPIRED': '_i18n.CertificateExpired',
    'WARNINGS.XAPI.SBC_CERT_FQDN_MISMATCH': '_i18n.SbcCertFqdnMismatch',
    'WARNINGS.XAPI.SBC_KEY_CERT_MISMATCH': '_i18n.SbcKeyCertMismatch',
    'WARNINGS.XAPI.TOO_MANY_PHONES': '_i18n.TooManyPhonesForUser',
    'WARNINGS.XAPI.TOO_MANY_SBC': '_i18n.ErrorTooManySbcs',
    'WARNINGS.XAPI.USER_ROLE_DOWNGRADE': '_i18n.XapiNotAllowedRoleDowngrade',
    'WARNINGS.SUPPORTED_MEDIA_FORMAT_WAV': '_i18n.SupportedMediaFormatWav',
    'WARNINGS.WRONG_CSV_FILE_REQUIRED_HEADER_NOT_FOUND': '_i18n.WrongCsvFileRequiredHeaderNotFound',
    'WARNINGS.CSV_INVALID_FILE_FORMAT': '_i18n.IncorrectFileFormatCsv',
    'WARNINGS.INVALID_IP_MASK': '_i18n.InvalidIpMask',
    'WARNINGS.ALREADY_EXPIRED': '_i18n.AlreadyExpired',
    'WARNINGS.BACKUP_NOT_FOUND_OR_INVALID': '_i18n.BackupNotFoundOrInvalid',
    'WARNINGS.NO_SECRET_DEFINED': '_i18n.NoSecretDefined',
    'WARNINGS.INVALID_SECURITY_CODE': '_i18n.InvalidSecurityCode',
    'WARNINGS.INVALID_CALL_FLOW_FILE': '_i18n.InvalidCallFlowFile',
    'WARNINGS.CALL_FLOW_MUST_BE_ALPHANUMERIC': '_i18n.CallFlowMustBeAlphanumeric',
    'WARNINGS.EXTRACTING_OUTSIDE_THE_DESTINATION_DIRECTORY': '_i18n.ExtractingOutsideDestinationDirectory',
    'WARNINGS.INVALID_EXTENSION_NUMBER_LENGTH': '_i18n.InvalidExtensionNumberLength',
    'WARNINGS.DN_NUMBER_CANNOT_BE_USED': '_i18n.DnNumberCannotBeUsed',
    'WARNINGS.WIRESHARK_NOT_FOUND': '_i18n.WiresharkNotFound',
    'WARNINGS.CAPTURE_LOCALHOST_NOT_ALLOWED': '_i18n.CaptureLocalhostNotAllowed',
    'WARNINGS.CAPTURE_ONGOING': '_i18n.CaptureOngoing',
    'WARNINGS.CANNOT_DELETE_TRUNKS_BINDED_ERMERGENCY_NUMBER': '_i18n.EmergencyTrunkCantBeDeleted',
    'WARNINGS.OPERATOR_CANNOT_BE_DELETED': '_i18n.OperatorCantBeDeleted',
    'WARNINGS.USER_EXTENSION_CANNOT_BE_DELETED': '_i18n.SelfCantBeDeleted',
    'WARNINGS.TOO_MANY_BACKUPS': '_i18n.XapiTooManyBackups',
    'WARNINGS.CONTACTS_SPECIFY_PHONE_NUMBER': '_i18n.MustSpecifyAtLeastOnePhoneNumber',
    'WARNINGS.CONTACTS_SPECIFY_NAME_SURNAME_COMPANY': '_i18n.MustSpecifyAtLeastOneContactName',
    'WARNINGS.BACKUP_LOCATION_CONFIG_ERROR': '_i18n.CantLocateBackupConfig',
    'WARNINGS.DELETING_ALREADY_IN_PROGRESS': '_i18n.DeletingAlreadyInProgress',
    'WARNINGS.ERROR_DOWNLOADING_FROM_UPDATES_SERVER': '_i18n.ErrorDownloadingFromUpdatesServer',
    'WARNINGS.UNABLE_REACH_UPDATES_SERVER': '_i18n.UpdatesServerUnreachable',
    'WARNINGS.BLACKLIST_NUMBER_LIMIT_EXCEEDED': '_i18n.BlackListNumberLimitExceeded',
    'WARNINGS.DOUBLE_QUOTES_NOT_ALLOWED': '_i18n.DoubleQuotesAreNotAllowed',
    'WARNINGS.MCU_REQUEST_ALREADY_IN_PROGRESS': '_i18n.MCUMultipleRequestRestricted',
    'WARNINGS.MCU_LIMIT_REACHED': '_i18n.MCUDisabledAddMeetingServer',
    'WARNINGS.MCU_REQUEST_NOT_FOUND': '_i18n.MCURequestNotFound',
    'WARNINGS.MCU_REQUEST_TIMEOUT': '_i18n.MCURequestTimeout',
    'WARNINGS.MCU_WEBMEETING_BRIDGE_NOT_FOUND': '_i18n.MCUWebmeetingBridgeNotFound'
};

export const convertServerErrors: Record<string, string> = {
    ...SERVER_WARNING_TRANSLATIONS,

    // TODO add keys on backend
    'license_error.CannotReplaceKeyToNfrKey': '_i18n.LicenseCannotReplaceKeyToNfrKey',
    'license_error.MacLimitReached': '_i18n.LicenseMacLimitReached',
    'license_error.KeyNotEnabled': '_i18n.LicenseKeyNotEnabled',
    'license_error.VersionMismatch': '_i18n.LicenseVersionMismatch',
    'license_error.UnknownErpFailure401': '_i18n.LicenseUnknownErpFailure401',
    'license_error.KeyNotFound': '_i18n.LicenseKeyNotFound',
    'license_error.KeyNotLatest': '_i18n.LicenseKeyNotLatest',
    'license_error.ErpServerError': '_i18n.LicenseErpServerError',
    'license_error.PhoneSystemEditionAlreadyActivated': '_i18n.LicensePhoneSystemEditionAlreadyActivated',
    'license_error.HttpsErr_InvalidResponseHeader': '_i18n.LicenseHttpsErr_InvalidResponseHeader',
    'license_error.HttpsErr_ErrorTCP': '_i18n.LicenseHttpsErr_ErrorTCP',
    'license_error.HttpsErr_TlsHandshakeFailed': '_i18n.LicenseHttpsErr_TlsHandshakeFailed',
    'license_error.HttpsErr_InvalidHost': '_i18n.LicenseHttpsErr_InvalidHost',
    'license_error.HttpsErr_RecvTimeout': '_i18n.LicenseHttpsErr_RecvTimeout',
    'license_error.Unknown': '_i18n.LicenseUnknown',
    'license_error.FailedToFindSuitableNetworkAdapter': '_i18n.LicenseFailedToFindSuitableNetworkAdapter',
    'license_error.OtherError': '_i18n.LicenseOtherError',
    'license_error.NewLicenseKeyIsNotFound': '_i18n.LicenseNewLicenseKeyIsNotFound',
    'license_error.OldLicenseKeyIsNotFound': '_i18n.LicenseOldLicenseKeyIsNotFound',
    'license_error.NewLicenseKeyIsEqualToOldLicenseKey': '_i18n.LicenseNewLicenseKeyIsEqualToOldLicenseKey',
    'license_error.NewKeyIsNotEnabled': '_i18n.LicenseNewKeyIsNotEnabled',
    'license_error.NewKeyMaintenanceIsExpired': '_i18n.LicenseNewKeyMaintenanceIsExpired',
    'license_error.NewKeyIsExpired': '_i18n.LicenseNewKeyIsExpired',
    'license_error.NewKeyIsBoundToFqdn': '_i18n.LicenseNewKeyIsBoundToFqdn',
    'license_error.NewKeyIsFree': '_i18n.LicenseNewKeyIsFree',
    'license_error.ProductTypeIsDeprecated': '_i18n.LicenseProductTypeIsDeprecated',
    'license_error.OldKeyIsNotFound': '_i18n.LicenseOldKeyIsNotFound',
    'license_error.CannotReplaceCommercialKeyWithFreeKey': '_i18n.LicenseCannotReplaceCommercialKeyWithFreeKey',
    'license_error.CannotReplaceMcmKeyWithNonMcmKey': '_i18n.LicenseCannotReplaceMcmKeyWithNonMcmKey',
    'license_error.NewKeyCannotBePerpetual': '_i18n.LicenseNewKeyCannotBePerpetual',
    'license_error.KeyIsNotFound': '_i18n.LicensePartnerKeyIsNotFound',
    'license_error.KeyIsNullOrInvalid': '_i18n.LicensePartnerKeyIsNullOrInvalid',
    'license_error.ResellerAlreadyLinked': '_i18n.LicensePartnerResellerAlreadyLinked',
    'license_error.KeyOrPartnerIdIsNullOrInvalid': '_i18n.LicensePartnerKeyOrPartnerIdIsNullOrInvalid',
    'license_error.KeyCannotBeLinkedToReseller': '_i18n.LicensePartnerKeyCannotBeLinkedToReseller',
    'license_error.KeyOrParentIdNotFound': '_i18n.LicensePartnerKeyOrParentIdNotFound',
    'license_error.CannotUnlinkResellerFromNfrKey': '_i18n.LicensePartnerCannotUnlinkResellerFromNfrKey',
    'license_error.ResellerOtherError': '_i18n.LicensePartnerResellerOtherError',
    'license_error.LinkedResellerNotFound': '_i18n.LicenseLinkedResellerNotFoundError',
};

export function extractErrorMessage(error: unknown, translate?: TranslateService): string {
    let errMsg: string;

    if (!error) {
        return '';
    }

    if (error instanceof HttpErrorResponse) {
        switch (error.status) {
            case 0:
                return '_i18n.GeneralNetworkError';
            case 413:
                return '_i18n.FileTooBig';
            case 403:
                // Blacklisted
                return '_i18n.ForbiddenResource';
            case 404:
                return '_i18n.Response404Message';
            case 429:
                return '_i18n.TooManyRequests';
            case 502:
                return '_i18n.Response502Message';
            // Gateway timeout can occur in normally working app also, when request gets stuck
            case 504:
                return '_i18n.Response504Message';
            case 527:
                return error.error;
            default: {
                errMsg = (
                    // validation error from backend before rps service is invoked
                    (typeof error.error === 'string' && error.error) ||
                    // Problem() return from ASP.NET Core
                    // validation error from backend before rps service is invoked
                    error.error?.detail ||
                    // Problem() return from ASP.NET Core
                    error.error?.Detail ||
                    // OData cryptic error
                    error.error?.error?.message ||
                    // OData cryptic error
                    error.error?.Message ||
                    // error.error is a Record<string, string | string[]> validation model
                    extractNonOdataValidation(error) ||
                    // Unparsed 500
                    (error.status === 500 && '_i18n.Response500Message') ||
                    // Unparsed other
                    `${error.status} ${error.statusText}`
                );
                break;
            }
        }
    }
    else if (typeof error === 'string') {
        errMsg = error;
    }
    else if (error instanceof Error) {
        errMsg = error.message;
        if (error.name === 'NotAllowedError' || error.name === 'NotFoundError') {
            // WebRTC error
            errMsg = '_i18n.WebRtc' + error.name;
        }
    }
    else if (typeof error === 'object' && 'message' in error && error.message && typeof error.message === 'string') {
        errMsg = error.message;
    }
    else {
        errMsg = 'Unspecified error';
    }
    const converted = convertServerErrors[errMsg];

    if (converted) {
        return converted;
    }
    if (translate) {
        return Object.entries(convertServerErrors).reduce((str, [backendKey, frontendKey]) => {
            const regexp = new RegExp('\\b' + backendKey + '\\b', 'g');
            return regexp.test(str) ? str.replaceAll(regexp, translate.instant(frontendKey)) : str;
        }, errMsg);
    }
    return errMsg;
}

function extractNonOdataValidation(response: HttpErrorResponse) {
    if (!response.error || typeof response.error !== 'object') {
        return undefined;
    }
    return Object.entries(response.error as Record<string, unknown>)
        .map(([key, value]) => `${key}: ${Array.isArray(value) ? value.join() : value}`).join('<br/>');
}
