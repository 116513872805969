import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding, HostListener,
    Input,
    Output
} from '@angular/core';
import {
    animate, state, style, transition, trigger
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { IconsModule } from '@webclient/shared/components/icons/icons.module';

export type ContainerState = 'hidden'|'maximized'| 'open';
export const defaultWidthInPixels = 250;
@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <div [ngClass]="isToasterVisible?'openContainer':'closedContainer'" >
            <div class="buttons">
                <ng-content select='[toast-maximized-button]'></ng-content>
                <button data-qa="toggle-toast-container" *ngIf='isHeaderActionPanelVisible' class="btn border-0 shadow-none btn-transparent-dark" type="button" (click)="toggle($event)">
                    <span class="customSVGIcons svg-xs fillIconWithTertiary" app-times-light-icon></span>
                </button>
            </div>

            <ng-content select='[toast-content]'></ng-content>
        </div>
    `,
    selector: 'toaster-container',
    animations: [
        trigger('flyInOut', [
            state('*', style({
                width: '0px',
                display: 'none'
            })),
            state('hidden', style({
                width: '0px',
                display: 'none'
            })),
            state('maximized', style({
                width: '{{maxWidthInPixels}}px',
                display: 'block'
            }), { params: { maxWidthInPixels: defaultWidthInPixels } }),
            state('open', style({
                width: '{{widthInPixels}}px',
                display: 'block'
            }), { params: { widthInPixels: defaultWidthInPixels } }),
            transition('hidden => *', [style({ display: 'block' }), animate('300ms ease-in')]),
            transition('* => *', animate('300ms ease-in')),
        ]),
    ],
    styleUrls: ['./toast-container.component.scss'],
    standalone: true,
    imports: [CommonModule, IconsModule]
})
export class ToastContainerComponent {
    @Input()
    state: ContainerState;

    @Input()
    widthInPixels = defaultWidthInPixels;

    @Input()
    maxWidthInPixels?: number;

    @Input()
    maximized = false;

    /** controls animation */
    @HostBinding('@flyInOut')
    get fn() {
        return {
            value: this.state ?? 'hidden',
            params: {
                widthInPixels: this.widthInPixels,
                maxWidthInPixels: this.maxWidthInPixels
            }
        };
    }

    @Input()
    showClose = true;

    private _isActive: boolean;
    @Input() set isActive(newValue: boolean) {
        if (this._isActive !== newValue) {
            this._isActive = newValue;
            this.cd.markForCheck();
        }
    }

    get isActive() {
        return this._isActive;
    }

    @HostListener('@flyInOut.start')
    onAnimationStart() {
        this.animationOngoing = true;
    }

    @HostListener('@flyInOut.done')
    onAnimationEnd() {
        this.animationOngoing = false;
        this.animationDone.next(this.state);
    }

    @Input()
    isDiscreet: boolean;

    @Output()
    toggleClick = new EventEmitter<boolean>();

    @Output()
    animationDone = new EventEmitter<ContainerState>();

    animationOngoing = false;

    get isOpen() {
        return this.state === 'maximized' || this.state === 'open';
    }

    constructor(private cd: ChangeDetectorRef) {}
    toggle(event: MouseEvent) {
        event.preventDefault();
        if (this.isOpen) {
            this.isDiscreet = true;
            this.state = 'hidden';
            this.toggleClick.next(false);
        }
        else if (this.state === 'hidden') {
            this.isDiscreet = false;
            this.state = 'open';
            this.toggleClick.next(true);
        }
    }

    get isToasterVisible() : boolean {
        return this.isOpen || this.animationOngoing;
    }

    get isHeaderActionPanelVisible() : boolean {
        return this.isOpen && this.showClose;
    }
}
