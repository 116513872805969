import { ChangeDetectorRef } from '@angular/core';

export class SimpleControlValueAccessor<T> {
    disabled = false;
    touched = false;

    onChange = (val: T) => { };
    onTouched = () => { };

    constructor(protected cd: ChangeDetectorRef) {
    }

    registerOnChange(fn: (val: T) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => void): void {
        this.onTouched = fn;
    }

    // have to force event on blur, otherwise model update on blur won't work
    markAsTouched(blur = false) {
        if (!this.touched || blur) {
            this.onTouched();
            this.touched = true;
        }
        this.cd.markForCheck();
    }

    setDisabledState(disabled: boolean) {
        this.disabled = disabled;
        this.cd.markForCheck();
    }

    onBlur(event: Event) {
        this.markAsTouched(true);
    }
}
