import { Injectable } from '@angular/core';
import { MyCall } from '@webclient/phone/mycall';
import { merge, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

export type CallState = 'Terminated' | 'Resume' | 'Hold' | 'Dialing' | 'Ringing' | 'AcceptedIncoming' | 'AcceptedOutgoing'
export interface CallStateContainer { call: MyCall; state: CallState; }
@Injectable()
export class CallsStateService {
    public onTerminate$ = new Subject<MyCall>();
    public onResume$ = new Subject<MyCall>();
    public onHold$ = new Subject<MyCall>();
    public onDialing$ = new Subject<MyCall>();
    public onRinging$ = new Subject<MyCall>();
    public onIncomingAccepted$ = new Subject<MyCall>();
    public onOutgoingAccepted$ = new Subject<MyCall>();
    public callState$: Observable<CallStateContainer>;
    constructor() {
        this.callState$ = merge(
            this.onIncomingAccepted$.pipe(map(call => ({ call, state: 'AcceptedIncoming' } as CallStateContainer))),
            this.onOutgoingAccepted$.pipe(map(call => ({ call, state: 'AcceptedOutgoing' } as CallStateContainer))),
            this.onRinging$.pipe(map(call => ({ call, state: 'Ringing' } as CallStateContainer))),
            this.onDialing$.pipe(
                distinctUntilChanged((pCall, nCall) => pCall.myCallId === nCall.myCallId),
                map(call => ({ call, state: 'Dialing' } as CallStateContainer))
            ),
            this.onHold$.pipe(map(call => ({ call, state: 'Hold' } as CallStateContainer))),
            this.onResume$.pipe(map(call => ({ call, state: 'Resume' } as CallStateContainer))),
            this.onTerminate$.pipe(map(call => ({ call, state: 'Terminated' } as CallStateContainer))),
        );
    }

    terminateCall(call: MyCall) {
        this.onTerminate$.next(call);
    }

    resumeCall(call: MyCall) {
        this.onResume$.next(call);
    }

    holdCall(call: MyCall) {
        this.onHold$.next(call);
    }

    outgoingCall(call: MyCall) {
        this.onDialing$.next(call);
    }

    incomingCall(call: MyCall) {
        this.onRinging$.next(call);
    }

    incomingCallAccepted(call: MyCall) {
        this.onIncomingAccepted$.next(call);
    }

    outgoingCallAccepted(call: MyCall) {
        this.onOutgoingAccepted$.next(call);
    }
}
