import { Injectable } from '@angular/core';
import { ChatUnreadNotificationService } from '@webclient/chat-data/chat-unread-notification.service';
import {
    combineLatest, Observable
} from 'rxjs';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import {
    map, startWith, switchMap
} from 'rxjs/operators';
import { publishRef } from '@webclient/rx-share-utils';

@Injectable()
export class UnreadNotificationsService {
    public readonly unreadConversationCount$: Observable<number>;
    public readonly missedAndAbandonedCalls$: Observable<number>;
    public readonly combinedUnreadNotification$: Observable<number>;
    public readonly unheardVoicemails$ : Observable<number>;

    public constructor(public chatService: ChatUnreadNotificationService, myPhoneService: MyPhoneService) {
        this.unheardVoicemails$ = myPhoneService.myPhoneSession.pipe(switchMap(session => session.myInfo$),
            map(myInfo => ((myInfo === undefined || myInfo.VoiceMailBox === undefined || myInfo.VoiceMailBox.Items === undefined) ? 0 : myInfo.VoiceMailBox.Items.filter(voicemail => voicemail.IsNew).length)));

        const missedCalls$ = myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.myInfo$), map(info => info.MissedCallsCount));

        const abandonedQueueCalls$ = myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.newAbandonedQueueCallsAmount$));

        this.missedAndAbandonedCalls$ = combineLatest([missedCalls$, abandonedQueueCalls$]).pipe(
            map(x => {
                const [missed, abandoned] = x;
                return Number(missed) + abandoned;
            }), publishRef()
        );

        this.unreadConversationCount$ = chatService.unreadConversationCount$.pipe(
            publishRef()
        );
        this.combinedUnreadNotification$ = combineLatest([
            this.unreadConversationCount$.pipe((startWith(0))),
            this.missedAndAbandonedCalls$.pipe(startWith(0))]).pipe(
            map(([chats, calls]) => chats + calls)
        );
    }
}
