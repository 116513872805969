/**
 * XAPI
 * This OData service is located at /xapi/v1
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type PbxPeerType = 'None' | 'Extension' | 'Queue' | 'RingGroup' | 'IVR' | 'Fax' | 'Conference' | 'Parking' | 'ExternalLine' | 'SpecialMenu' | 'Group' | 'RoutePoint';

export const PbxPeerType = {
    None: 'None' as PbxPeerType,
    Extension: 'Extension' as PbxPeerType,
    Queue: 'Queue' as PbxPeerType,
    RingGroup: 'RingGroup' as PbxPeerType,
    Ivr: 'IVR' as PbxPeerType,
    Fax: 'Fax' as PbxPeerType,
    Conference: 'Conference' as PbxPeerType,
    Parking: 'Parking' as PbxPeerType,
    ExternalLine: 'ExternalLine' as PbxPeerType,
    SpecialMenu: 'SpecialMenu' as PbxPeerType,
    Group: 'Group' as PbxPeerType,
    RoutePoint: 'RoutePoint' as PbxPeerType
};

