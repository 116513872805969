import {
    HttpErrorResponse,
    HttpEvent, HttpHandler, HttpInterceptor, HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    Observable, of, retry, switchMap, throwError
} from 'rxjs';
import { TokenService } from '@webclient/auth/token.service';
import { catchError, take } from 'rxjs/operators';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { Router } from '@angular/router';
import { defaultTeamLink } from '@webclient/settings/local-storage-keys';

const extraHeaders = {
    'Cache-Control': 'no-store',
    Pragma: 'no-cache',
};

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    constructor(private tokenService: TokenService, private myPhoneService: MyPhoneService,
                private router: Router) {
    }

    private navigateLogin() {
        // Something is wrong with our authorization
        // ask for a different auth here
        this.myPhoneService.gotoLogin();
        this.myPhoneService.disconnect();
    }

    intercept(interceptedRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // skips interception for a refresh token request
        if (!/webclient\/api|xapi/.test(interceptedRequest.url) || interceptedRequest.url.endsWith('GetAccessToken')) {
            return next.handle(interceptedRequest.clone({
                setHeaders: extraHeaders
            })).pipe(
                catchError((error: unknown) => {
                    /**
                     * This request should always succeed. If it fails it means out refresh token is invalid
                     * or we're really blacklisted
                     */
                    if (error instanceof HttpErrorResponse && (error.status === 401 || error.status === 403)) {
                        this.navigateLogin();
                    }
                    return throwError(() => error);
                })
            );
        }
        return this.tokenService.accessToken$.pipe(
            take(1),
            switchMap(accessToken => next.handle(interceptedRequest.clone({
                setHeaders: {
                    ...extraHeaders,
                    Authorization: 'Bearer ' + accessToken
                }
            }))),
            retry({
                delay: (error: unknown, retryCount) => {
                    if (retryCount === 0 && error instanceof HttpErrorResponse && error.status === 401) {
                        // Invalidate access token
                        this.tokenService.invalidate();
                        // Retry the request immediately
                        return of(true);
                    }
                    throw error;
                }
            }),
            catchError((error: unknown) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        // We retried access token and still got 401
                        // should not be like this but go to login in this case
                        this.navigateLogin();
                    }
                    else if (error.status === 403) {
                        // Access token is valid but we access forbidden resource
                        // If we're really blacklisted we still can go to teams
                        this.router.navigate(defaultTeamLink);
                    }
                }
                return throwError(() => error);
            }),
        );
    }
}
