export class HeadsetCallState {
    public static NO_CALL = -1;
    public activeCall = HeadsetCallState.NO_CALL;
    public isActiveCallMuted = false;
    public callCount = 0;
    public readonly ringingCalls = new Set<number>();
    public readonly holdCalls = new Set<number>();

    public get hasRingingCalls() {
        return this.ringingCalls.size > 0;
    }

    public get hasHoldCalls() {
        return this.holdCalls.size > 0;
    }

    public get noCalls() {
        return this.callCount === 0;
    }
}
