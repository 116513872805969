/* tslint:disable */
/* eslint-disable */
/**
 * 3CX PhoneSystem Webclient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const LoginWelcome = {
    NoDialog: 0,
    ProvisionSmartphone: 1,
    BrowserExtensionDeprecated: 2
} as const;
export type LoginWelcome = typeof LoginWelcome[keyof typeof LoginWelcome];


export function LoginWelcomeFromJSON(json: any): LoginWelcome {
    return LoginWelcomeFromJSONTyped(json, false);
}

export function LoginWelcomeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginWelcome {
    return json as LoginWelcome;
}

export function LoginWelcomeToJSON(value?: LoginWelcome | null): any {
    return value as any;
}

