import { Pipe, PipeTransform } from '@angular/core';
import { GroupHoursMode } from '@myphone';

const TEXTS: Record<GroupHoursMode, string> = {
    [GroupHoursMode.GHM_Default]: '_i18n.OverrideDefaultHours',
    [GroupHoursMode.GHM_ForceOpened]: '_i18n.OverrideIsOpen',
    [GroupHoursMode.GHM_ForceClosed]: '_i18n.OverrideIsClosed',
    [GroupHoursMode.GHM_ForceBreak]: '_i18n.OverrideIsBreak',
    [GroupHoursMode.GHM_ForceHoliday]: '_i18n.OverrideIsHoliday',
    [GroupHoursMode.GHM_ForceCustomOperator]: '_i18n.OverrideCustomHours'
};

@Pipe({ name: 'appGroupHoursMode' })
export class GroupHoursModePipe implements PipeTransform {
    transform(groupHoursMode: GroupHoursMode): string {
        return TEXTS[groupHoursMode] || '_i18n.Unknown';
    }
}
