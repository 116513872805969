import { LocalConnections, QueueAgentStat } from '@myphone';

export class Agent {
    queueStatus = false;
    stat ?: QueueAgentStat;
    firstName = '';
    lastName = '';
    firstNameLastName = '';
    lastNameFirstName = '';
    agentNumber = '';
    activeCalls = new LocalConnections();

    constructor(public id: number) {
    }
}
