import { defineLocale } from 'ngx-bootstrap/chronos';
import {
    csLocale,
    deLocale,
    enGbLocale,
    esLocale,
    frLocale,
    itLocale, nlLocale, plLocale,
    ptBrLocale, ruLocale, trLocale, zhCnLocale,
} from 'ngx-bootstrap/locale';

/**
 * Chronos locales are required for ngx-bootstrap-datepicker.js
 */
defineLocale('en-gb', enGbLocale);
defineLocale('en'); // fixed us
defineLocale('de', deLocale);
defineLocale('fr', frLocale);
defineLocale('es', esLocale);
defineLocale('it', itLocale);
defineLocale('pt-br', ptBrLocale);
defineLocale('nl', nlLocale);
defineLocale('ru', ruLocale);
defineLocale('pl', plLocale);
defineLocale('zh-cn', zhCnLocale);
defineLocale('tr', trLocale);
defineLocale('cs', csLocale);
