import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { BehaviorSubject, switchMap } from 'rxjs';
import { AppsUtilityService } from '@webclient/services/apps-utility.service';

@Component({
    selector: 'app-provision-qrcode',
    template: `
        <app-qrcode [url]="provisionUrl$ | async"></app-qrcode>
        <p class="mt-2 ps-2 text-start">
            <span class="customSVGIcons svg-sm me-1" app-exclamation-triangle-solid-icon></span>
            <span class="me-1" [translate]="'_i18n.QRCodeRefreshWarningPart1'"></span>
            <a class="link me-1" (click)="refresh$.next();" data-qa="refresh">{{ '_i18n.Refresh' | translate }}</a>
            <span class="me-1" [translate]="'_i18n.QRCodeRefreshWarningPart2'"></span>
        </p>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProvisionQrcodeComponent {
    /** for another user in admin view */
    @Input() userId?: number;

    @HostBinding('class') readonly containerClass = 'text-center';

    readonly refresh$ = new BehaviorSubject<void>(undefined);
    readonly provisionUrl$ = this.refresh$.pipe(switchMap(() => this.appUtility.getProvisionUrl$(this.userId)));

    constructor(private appUtility: AppsUtilityService) { }
}
