<default-validation-errors></default-validation-errors>
<a #integrationUrl target="_blank" style="visibility: hidden"></a>
<div class="d-flex flex-column h-100">
    <div class="app-wrapper flex-shrink-1 flex-grow-1 h-100 overflow-auto">

        <header class="navbar navbar-expand-md p-0"></header>
        <!-- menu -->
        <div class="app-aside">
            <aside class='h-100'></aside>
        </div>
        <!-- / menu -->

        <!-- view -->
            <div id="app-container" class="app-content-body app-content-full">
                <router-outlet></router-outlet>
            </div>
        <!-- / view -->

        <div class="right-toast-placeholder">
            <app-web-dialer class="align-self-end position-relative"></app-web-dialer>
            <chat-toast-container *ngIf="chatState$ | async"></chat-toast-container>
        </div>
        <app-webmeeting-holder></app-webmeeting-holder>

    </div>
</div>
