import { Injectable } from '@angular/core';
import { AutoResume, DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

import { EMPTY, merge, Observable } from 'rxjs';
import { MyPhoneService } from '../myphone/myphone.service';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '../settings/local-storage-keys';
import { GeneralSettings } from '../settings/general-settings';
import { RequestChangeStatus } from '@myphone';
import { catchError, switchMap, take } from 'rxjs/operators';
import { ProfileNames } from '@webclient/myphone/profile';
import { observe } from '@webclient/rx-utils';

@Injectable()
export class IdleService {
    public readonly idle$: Observable<any>;

    constructor(private idle: Idle, private myPhoneService: MyPhoneService, localStorageService: LocalStorageService) {
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.setAutoResume(AutoResume.idle);

        const idleStart = observe(localStorageService, LocalStorageKeys.GeneralSettings, new GeneralSettings()).pipe(
            switchMap((settings) => {
                if (settings.useIdleStatus) {
                    this.idle.setIdle(settings.idleStatusTime * 60);
                    this.idle.watch();

                    return this.idle.onIdleStart.pipe(switchMap(() => this.setStatusTo(settings.idleStatus)));
                }
                else {
                    this.idle.stop();
                    return EMPTY;
                }
            }));

        const idleEnd = this.idle.onIdleEnd.pipe(switchMap(() => this.setStatusTo(ProfileNames.Available)));
        this.idle$ = merge(idleStart, idleEnd);
    }

    private setStatusTo(statusName: string) {
        return this.myPhoneService.myPhoneSession.pipe(take(1), switchMap(
            session => {
                const myInfo = session.myInfo;
                const status = myInfo.MyProfiles.Items.find(profile => profile.Name === statusName);
                if (!status || myInfo.CurrentProfile === status.Id) {
                    return EMPTY;
                }
                return session.get(new RequestChangeStatus({ ProfileId: status.Id })).pipe(catchError(() => EMPTY));
            }));
    }
}
