import { TranslateService } from '@ngx-translate/core';
import type { FilterValue, SerializedFilter } from '@office/standalones/odata-search/types';
import { ValidationErrors } from '@angular/forms';
import { isParticipantType, ParticipantType, translateParticipantType } from '../participant-type';

export enum ChatType {
    All,
    Internal,
    External,
}

const CHAT_TYPE_LABEL: Record<ChatType, string> = {
    [ChatType.All]: '_i18n.ReportsCallAreaTypeAll',
    [ChatType.Internal]: '_i18n.ReportsCallAreaTypeInternal',
    [ChatType.External]: '_i18n.ReportsCallAreaTypeExternal'
};

export function translateChatType(value: ChatType | null | undefined) {
    return value != null ? CHAT_TYPE_LABEL[value] : '';
}

function isChatType(value: unknown): value is ChatType {
    return value != null && typeof value === 'number' && Object.values(ChatType).includes(value);
}

export interface ChatParticipantTypeFilterParams {
    chatType: ChatType | null;
    /** for External chat type */
    participantType: ParticipantType | null;
}

const QUERY_PARAM_CHAT_TYPE = 'chatType';
const QUERY_PARAM_PARTICIPANT_TYPE = 'participantType';

export class ChatParticipantTypeFilterValue implements FilterValue<ChatParticipantTypeFilterParams> {
    private chatType: ChatParticipantTypeFilterParams['chatType'] = ChatType.All;
    private participantType: ChatParticipantTypeFilterParams['participantType'] = null;

    readonly label = '_i18n.ReportsChannel';

    get value(): ChatParticipantTypeFilterParams {
        return { chatType: this.chatType, participantType: this.participantType };
    }

    set value(value: ChatParticipantTypeFilterParams) {
        this.chatType = value.chatType ?? ChatType.All;
        this.participantType = value.chatType === ChatType.External ? value.participantType ?? ParticipantType.All : null;
    }

    deserialize(serializedValue: SerializedFilter): FilterValue<ChatParticipantTypeFilterParams> {
        return ChatParticipantTypeFilterValue.deserialize(serializedValue, this.value);
    }

    getDisplayValue(translate: TranslateService): string {
        const { chatType, participantType } = this.value;

        switch (chatType) {
            case ChatType.External:
                return `${translate.instant(translateChatType(chatType))} - ${translate.instant(translateParticipantType(participantType))}`;
            case ChatType.Internal:
                return translate.instant(translateChatType(chatType));
            default:
                return '';
        }
    }

    getDisplayValueForPrint(translate: TranslateService): string {
        return this.getDisplayValue(translate) || translate.instant(translateChatType(ChatType.All));
    }

    serialize(): SerializedFilter {
        const value = this.value;

        return value.chatType === ChatType.All ? {} : {
            [QUERY_PARAM_CHAT_TYPE]: value.chatType,
            [QUERY_PARAM_PARTICIPANT_TYPE]: value.participantType
        };
    }

    validate(): ValidationErrors | null {
        return null;
    }

    static deserialize(serializedValue:SerializedFilter, initialValue?: ChatParticipantTypeFilterParams): FilterValue<ChatParticipantTypeFilterParams> {
        const result = new ChatParticipantTypeFilterValue();
        if (initialValue) {
            result.value = initialValue;
        }
        if (QUERY_PARAM_CHAT_TYPE in serializedValue && isChatType(serializedValue[QUERY_PARAM_CHAT_TYPE])) {
            result.chatType = serializedValue[QUERY_PARAM_CHAT_TYPE];
        }
        if (QUERY_PARAM_PARTICIPANT_TYPE in serializedValue && isParticipantType(serializedValue[QUERY_PARAM_PARTICIPANT_TYPE])) {
            result.participantType = serializedValue[QUERY_PARAM_PARTICIPANT_TYPE];
        }
        if (result.chatType === ChatType.External) {
            result.participantType = result.participantType ?? ParticipantType.All;
        }
        return result;
    }
}
