import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ChatToastService } from './chat-toast.service';
import { merge, Observable, Subject } from 'rxjs';
import { map, scan, share } from 'rxjs/operators';
import { ContainerState } from '@webclient/standalones/toast-container';
import { PushChatMessage } from '@webclient/notifications/push-chat-message';

interface ChatCommand {
    action: string;
    // Filled in for delete
    IdConversation?: number;
    chatMessage?: PushChatMessage;
}

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'chat-toast-container',
    styles: [' .move-up { margin-top: -24px; } :host {align-self: flex-end; }'],
    template: `
        <toaster-container [state]="state$|async" [isActive]="isActive$|async" (toggleClick)="onToggle($event)">
            <chat-toast-component *ngFor="let chat of (chats$|async); let isFirst = first" [message]="chat" (remove)="removeChat(chat)"
                                  [class.move-up]="isFirst"
                                  toast-content></chat-toast-component>
        </toaster-container>
    `
})
export class ChatToastContainerComponent {
    public readonly chats$: Observable<PushChatMessage[]>;
    public readonly state$: Observable<ContainerState>;
    public readonly isActive$: Observable<boolean>;
    private readonly removeChat$ = new Subject<PushChatMessage>();
    private readonly onToggle$ = new Subject<boolean>();

    constructor(private chatToastService: ChatToastService) {
        const addChat = this.chatToastService.chats$.pipe(map(chatMessage => {
            return { action: 'add', chatMessage };
        }));

        const clearChats = this.onToggle$.pipe(map(() => ({ action: 'deleteAll' })));

        // this.isActive$ = merge(
        //     addChat.pipe(map(() => true)),
        //     this.onToggle$.pipe(map(() => false))
        // );

        const removeChat = this.removeChat$.pipe(map(chatMessage => {
            return { action: 'delete', IdConversation: chatMessage.conversationId };
        }));

        const externalCommandToRemoveChat = this.chatToastService.removeChat$.pipe(map((id) => {
            return { action: 'delete', IdConversation: id };
        }));

        this.chats$ = merge(addChat, removeChat, externalCommandToRemoveChat, clearChats).pipe(
            scan((chats: PushChatMessage[], chatCmd: ChatCommand) => {
                if (chatCmd.action === 'delete') {
                    // If we asked to delete or chat exists than let's delete it
                    chats = chats.filter(chat => chat.conversationId !== chatCmd.IdConversation);
                }
                if (chatCmd.action === 'add') {
                    chats = chats.filter(chat => chat.conversationId !== chatCmd.chatMessage!.conversationId);
                    chats = [chatCmd.chatMessage!, ...chats];
                }
                if (chatCmd.action === 'deleteAll') {
                    chats = [];
                }

                return chats;
            }, []),
            share()
        );

        this.state$ = this.chats$.pipe(map(chats => {
            return chats.length > 0 ? 'open' : 'hidden';
        }));
    }

    removeChat(chat: PushChatMessage) {
        this.removeChat$.next(chat);
    }

    onToggle(toggleState: boolean) {
        this.onToggle$.next(toggleState);
    }
}
