import { MyCall } from '@webclient/phone/mycall';
import {
    DnType,
    LocalConnectionState, RejectAction,
    RequestDropCall, WebRTCEndpointSDPState
} from '@myphone';
import { IntegrationService } from '@webclient/layout/integration.service';
import {
    AutoOpenUrl, CrmLookUpMode,
    GeneralSettings,
    LaunchIntegrationWhen
} from '@webclient/settings/general-settings';
import { sanitizePhoneNumber } from '@webclient/phone/phone-funcs';
import { isChrome, isEdge } from '@webclient/browser-type';

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined;
}

export function truthy<TValue>(value: TValue | null | undefined): value is TValue {
    return Boolean(value);
}
export function createRequestDropMyCall(myCall: MyCall) {
    const request = new RequestDropCall();
    // Terminate everything fow now
    request.ActionIfRinging = RejectAction.RA_Busy;
    request.LocalConnectionId = myCall.localConnectionId;
    request.IsLocal = true;

    return request;
}
export function processCrmUrl(integrationService: IntegrationService, call: MyCall, isMcmMode: boolean) {
    if ((call.isIncoming || call.isPickedUp) &&
        !!call.contact.crmUrl &&
        !call.isUrlAutoOpened &&
        call.phone !== 'MakeCall' &&
        call.phone !== 'LiveChat' &&
        call.state === LocalConnectionState.Connected &&
        call.otherPartyDnType === DnType.ExternalLine &&
        !isMcmMode
    ) {
        integrationService.openUrl(call.contact.crmUrl);
        call.isUrlAutoOpened = true;
    }
}

export function canSendDtmf(myCall: MyCall) {
    const media = myCall.media;
    const supportedBrowser = isChrome || isEdge;
    return !myCall.isHold &&
        myCall.media.lastWebRTCState.sdpType === WebRTCEndpointSDPState.WRTCConfirmed && media.audio && media.audio.dtmf &&
        (!supportedBrowser || media.audio.dtmf.canInsertDTMF);
}

export function processIntegrationUrl(integrationService: IntegrationService, call: MyCall, settings: GeneralSettings) {
    /**
     * Now always updating integration Link when name is different from name in the URL, so, when we have onConnected open, we always will have a actual link
     * In case of OnRinging, we just put what we have at this moment and it's ok;
     * TODO: In the Future it will be useful to have centralized contact match service for client, because now we have big problems with Sources of contact display name,
     * TODO: It can arrive both from server and client and there is a big mess with understanding when DisplayName is finally resolved.
     * TODO: When we will have chain of client lookups, we can know for sure when lookup is finished and when display name persists or not.
     * TODO: For now its just constantly updated variable.
     */
    function makeUrl() {
        const sanitizedPhone = sanitizePhoneNumber(call.phone);
        call.integrationUrl = settings.integrationsUrl.replace('%CallerNumber%', sanitizedPhone)
            .replace('%CallerDisplayName%', call.displayName || sanitizedPhone || '');
    }

    if (call.displayName !== undefined && call.phone !== 'LiveChat') {
        if (call.integrationUrl) {
            try {
                const url = new URL(call.integrationUrl);
                const dp = url.searchParams.get('displayName');
                if (dp !== call.displayName) {
                    makeUrl();
                }
            }
            catch {
                // URL is invalod here
            }
        }
        else {
            makeUrl();
        }
    }

    // For Custom CRM
    if ((call.isIncoming || call.isPickedUp) &&
        call.integrationUrl &&
        !call.isUrlAutoOpened &&
        call.phone !== 'MakeCall' &&
        call.phone !== 'LiveChat' &&
        ((settings.launchIntegrationWhen === LaunchIntegrationWhen.Ringing && call.state === LocalConnectionState.Ringing) ||
            (settings.launchIntegrationWhen === LaunchIntegrationWhen.Connected && call.state === LocalConnectionState.Connected)) &&
        call.otherPartyDnType === DnType.ExternalLine
    ) {
        integrationService.openUrl(call.integrationUrl);
        call.isUrlAutoOpened = true;
    }
}

export function processClientCrmUrl(integrationService: IntegrationService, call: MyCall, settings: GeneralSettings) {
    if ((call.isIncoming || call.isPickedUp) &&
        settings.autoOpenUrl === AutoOpenUrl.ClientCrmUrl &&
        !call.isUrlAutoOpened &&
        call.phone !== 'MakeCall' &&
        ((settings.callLookup === CrmLookUpMode.Ringing && call.state === LocalConnectionState.Ringing) ||
            (settings.callLookup === CrmLookUpMode.Connected && call.state === LocalConnectionState.Connected)) &&
        call.otherPartyDnType === DnType.ExternalLine
    ) {
        if (call.phone !== 'LiveChat' && call.phone) {
            // process on webclient or electron main window only (prevent multiple opening when on dialer window)
            if (call.phone !== 'LiveChat' && call.phone) {
                integrationService.openClientCrmUrl(call.phone, undefined);
                call.isUrlAutoOpened = true;
            }
            else if (call.contact.emailAddress) {
                integrationService.openClientCrmUrl(undefined, call.contact.emailAddress);
                call.isUrlAutoOpened = true;
            }
        }
        else if (call.contact.emailAddress) {
            integrationService.openClientCrmUrl(undefined, call.contact.emailAddress);
            call.isUrlAutoOpened = true;
        }
    }
}
