import { defer, EMPTY, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export class RxLock {
    private locked = false;

    sync<T>(src: Observable<T>) {
        return defer(() => {
            if (this.locked) {
                return EMPTY;
            }
            this.locked = true;
            return src.pipe(finalize(() => {
                this.locked = false;
            }));
        });
    }
}
