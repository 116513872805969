import { AppContact } from '../../myphone/contact';
import { Record } from 'immutable';

interface SearchResultProps {
    name: string;
    number: string;
    contact?: AppContact;
    isEmail: boolean;
    isVoicemail: boolean;
    phoneOrder: number
}

const defaultSearchResultProps: SearchResultProps = {
    name: '',
    number: '',
    contact: undefined,
    isEmail: false,
    isVoicemail: false,
    phoneOrder: 0
};

export class SearchResult extends Record(defaultSearchResultProps) {
    constructor(props?: Partial<SearchResultProps>) {
        super(props ? {
            ...props,
            name: props.name ? props.name : props.number
        } : undefined);
    }

    // if (String.isNullOrEmpty(name))
    //     this.name = number;
}

export const defaultSearchResult = new SearchResult();
