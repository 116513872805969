/* tslint:disable */
/* eslint-disable */
/**
 * 3CX PhoneSystem Webclient API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    token_type: string;
    /**
     * 
     * @type {number}
     * @memberof TokenResponse
     */
    expires_in: number;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    access_token: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    refresh_token: string;
}

/**
 * Check if a given object implements the TokenResponse interface.
 */
export function instanceOfTokenResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "token_type" in value;
    isInstance = isInstance && "expires_in" in value;
    isInstance = isInstance && "access_token" in value;
    isInstance = isInstance && "refresh_token" in value;

    return isInstance;
}

export function TokenResponseFromJSON(json: any): TokenResponse {
    return TokenResponseFromJSONTyped(json, false);
}

export function TokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TokenResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'token_type': json['token_type'],
        'expires_in': json['expires_in'],
        'access_token': json['access_token'],
        'refresh_token': json['refresh_token'],
    };
}

export function TokenResponseToJSON(value?: TokenResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'token_type': value.token_type,
        'expires_in': value.expires_in,
        'access_token': value.access_token,
        'refresh_token': value.refresh_token,
    };
}

