// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { LocalStorageKeys } from '../settings/local-storage-keys';
import { observe } from '@webclient/rx-utils';

const OPERATION_TEXT = '#e04aa4';

export function deepCopy<T>(source: T): T {
    return Array.isArray(source)
        ? source.map(item => deepCopy(item))
        : source instanceof Date
            ? new Date(source.getTime())
            : source && typeof source === 'object'
                ? Object.getOwnPropertyNames(source).reduce((o, prop) => {
                    Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(source, prop)!);
                    o[prop] = deepCopy((source as { [key: string]: any })[prop]);
                    return o;
                }, Object.create(Object.getPrototypeOf(source)))
                : source;
}
export enum LoggingLevel {
    MyPhoneSession,
    PhoneService,
    WebRtcService
}
export enum ReqState {
    Pending,
    Fulfilled,
    Rejected
}
/**
 * Constant Objects which contains default labels and colors for logging services
 */
type TLogLevel = {
    color: string;
    label: string;
    value: LoggingLevel;
}
export const MY_PHONE_LOG: TLogLevel = {
    color: 'color: #E8B311;',
    label: 'MyPhoneSession',
    value: LoggingLevel.MyPhoneSession,
};

export const PHONE_SERVICE_LOG: TLogLevel = {
    color: 'color: #0096D1;',
    label: 'PhoneService',
    value: LoggingLevel.PhoneService,
};

export const WEBRTC_SERVICE_LOG: TLogLevel = {
    color: 'color: #D63004;',
    label: 'WebRTCService',
    value: LoggingLevel.WebRtcService,
};

/**
 * Constant Objects which contains default labels and colors for requests
 */

type TLogRequest = {
    color: string;
    label: string;
    value: ReqState;
}

export const REQ_PENDING: TLogRequest = {
    value: ReqState.Pending,
    color: 'color: #B5B5B5;',
    label: 'get-request',
};

export const REQ_FULFILLED: TLogRequest = {
    value: ReqState.Fulfilled,
    color: 'color: #36B213;',
    label: 'get-response',
};

export const REQ_FAILED: TLogRequest = {
    value: ReqState.Rejected,
    color: 'color: #E05B4A;',
    label: 'get-failed',
};
function getOperationNameColor(name?: string) {
    return name ? `color: ${OPERATION_TEXT}` : '';
}
export interface Log {
    loggingLevel: TLogLevel,
    typeName?: string
    operationName?: string,
    optionalParams?: any[],
    message?: string,
}
export interface RequestLog extends Log {
    requestState: TLogRequest,
    reqIndex?: number | string,
}
@Injectable({
    providedIn: 'root'
})
export class Logger {
    logEnabled: boolean;
    enabledLevel = new Map<number, LoggingLevel>();
    constructor(private storage: LocalStorageService) {
        observe<boolean|null>(this.storage, LocalStorageKeys.LoggerEnabled).subscribe((value) => {
            this.logEnabled = value ?? false;
        });

        observe<string|null>(this.storage, LocalStorageKeys.LoggerLevel).subscribe((value) => {
            if (value?.includes('myphone')) {
                if (!this.enabledLevel.get(LoggingLevel.MyPhoneSession)) {
                    this.enabledLevel.set(LoggingLevel.MyPhoneSession, LoggingLevel.MyPhoneSession);
                }
            }
            else {
                this.enabledLevel.delete(LoggingLevel.MyPhoneSession);
            }
            if (value?.includes('phonesvc')) {
                if (!this.enabledLevel.get(LoggingLevel.PhoneService)) {
                    this.enabledLevel.set(LoggingLevel.PhoneService, LoggingLevel.PhoneService);
                }
            }
            else {
                this.enabledLevel.delete(LoggingLevel.PhoneService);
            }
            if (value?.includes('webrtcsvc')) {
                if (!this.enabledLevel.get(LoggingLevel.WebRtcService)) {
                    this.enabledLevel.set(LoggingLevel.WebRtcService, LoggingLevel.WebRtcService);
                }
            }
            else {
                this.enabledLevel.delete(LoggingLevel.WebRtcService);
            }
        });
    }

    info(message?: any, ...optionalParams: any[]) {
        if (this.logEnabled) {
            console.log(message, ...optionalParams);
        }
    }

    getLogLevelEnabled(loggingLevel: LoggingLevel) {
        return this.logEnabled && (this.enabledLevel.size < 1 || this.enabledLevel.get(loggingLevel) !== undefined);
    }

    showRequest(log: RequestLog) {
        if (this.getLogLevelEnabled(log.loggingLevel.value)) {
            console.log(
                `%c[${log.loggingLevel.label}]%c${log.requestState.label}[${log.reqIndex}]${log.operationName ? ` %c[${log.operationName}]` : ''}`,
                log.loggingLevel.color,
                log.requestState.color,
                getOperationNameColor(log.operationName),
                `${log.message ?? ''}${log.typeName ?? ''}`,
                ...log.optionalParams ?? [],
            );
        }
    }

    showInfo(log: Log) {
        if (this.getLogLevelEnabled(log.loggingLevel.value)) {
            console.log(
                `%c[${log.loggingLevel.label}]${log.operationName ? ` %c[${log.operationName}]` : ''}`,
                log.loggingLevel.color,
                getOperationNameColor(log.operationName),
                `${log.message ?? ''}${log.typeName ?? ''}`,
                ...log.optionalParams ?? [],
            );
        }
    }
}
