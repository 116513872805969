import { ConferenceParticipant } from '@myphone';

export class SimpleConferenceParticipantVM {
    constructor(partial?: Partial<SimpleConferenceParticipantVM>) {
        if (partial) {
            Object.assign(this, partial);
        }
    }

    public Name: string;
    public Email: string;
    public Number: string;
    public IsExtension: boolean;
    public IsVisible: boolean;

    public toString = (): string => {
        let label = '';
        let details = '';

        // Normal case
        if (this.Name) {
            label = this.Name;
            details = [this.Number, this.Email].filter((x) => x).join(', ');
        }
        else if (this.Number) {
            label = this.Number;
            details = this.Email;
        }
        else if (this.Email) {
            // We have only one hope that we have email
            label = this.Email;
        }

        return details ? `${label} (${details})` : label;
    };

    model: ConferenceParticipant;

    /*    public HasNumberOrEmail: boolean = !String.isNullOrEmpty(this.Number) || !String.isNullOrEmpty(this.Email);
     public HasNumberAndEmail: boolean = !String.isNullOrEmpty(this.Number) && !String.isNullOrEmpty(this.Email);
     public NumberOrEmail: string = !String.isNullOrWhitespace(this.Number) ? this.Number : this.Email; */
}
