import { map, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ConferenceWrapper } from '../conference-wrapper';
import { MyPhoneService } from '../../myphone/myphone.service';

import { ConferenceParticipant, ConferenceParticipants } from '@myphone';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PrivateConferenceService {
    privateConference$ : Observable<ConferenceWrapper[]>;

    constructor(myPhoneService: MyPhoneService,
        translateService : TranslateService,
    ) {
        this.privateConference$ = myPhoneService.myPhoneSession.pipe(
            switchMap(session => session.myInfo.PrivateConference$),
            switchMap(privateConf => {
                const participants = PrivateConferenceService.extractParticipants(privateConf);
                if (participants.length > 0) {
                    return ConferenceWrapper.forPrivateConference(translateService, participants).pipe(map(x => [x]));
                }
                else {
                    return of([]); // Private conf isn't active
                }
            }));
    }

    static extractParticipants(praticipants: ConferenceParticipants): ConferenceParticipant[] {
        if (!praticipants || !praticipants.Items) {
            return [];
        }

        return praticipants.Items;
    }
}
