import { map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MyCall } from '../phone/mycall';
import { TranslateService } from '@ngx-translate/core';
import { connectable, Observable, ReplaySubject } from 'rxjs';
import { WebNotificationService } from './web-notification-service';
import { LocalConnectionAction, NotificationServiceImpl } from './notification-service-interface';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
// Service worker
import { SettingsService } from '../settings.service';
import { PushChatMessage } from '@webclient/notifications/push-chat-message';
import { basePathUrl } from '@webclient/standalones/avatar/url-utils';
import { userImage, userTransparentImage as userImagePngUrl } from '@webclient/assets';
import { ConnectableObservableLike } from '@webclient/myphone/connectableObservableLike';
import { NgswNotificationService } from '@webclient/notifications/ngsw-notification-service';
import { MyPhoneService } from '@webclient/myphone/myphone.service';
import { ExtendedSwPushService } from '@webclient/notifications/extended-sw-push.service';
import { hasPermission } from '@webclient/notifications/notification-funcs';
import { SilentModeService } from '@webclient/layout/silent-mode.service';

let a: HTMLAnchorElement;

export function getNotificationImage(imageUrl: string|undefined, baseUrl: string) {
    // Please note that returning data blobs is not supported by standard
    // though works in Firefox and Chrome but breaks Edge for example.
    // We do understand that full urls which are coming from bridges do work
    // but for some situations they cause unneeded delay in showing notification so we prohibit it as well
    if (!imageUrl || imageUrl.startsWith('http://') || imageUrl.startsWith('https://') || (imageUrl === userImage)) {
        imageUrl = userImagePngUrl;
    }
    // Though Chrome and Firefox take relative URL's Edge insists that this should be absolute
    if (!a) {
        a = document.createElement('a');
    }
    a.href = basePathUrl(baseUrl, imageUrl)!;
    return a.href;
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private _callsWaitingForImage: { [id: string]: MyCall } = {};

    private readonly impl$: ConnectableObservableLike<NotificationServiceImpl>;
    public readonly notificationActions$: Observable<LocalConnectionAction>;

    constructor(translate: TranslateService,
        router: Router,
        myPhoneService: MyPhoneService,
        swPush: ExtendedSwPushService,
        private http: HttpClient,
        private silentModeService: SilentModeService,
        private settingsService: SettingsService) {
        // checks registered service workers
        // chooses a notification implementation
        this.impl$ = connectable(
            swPush.isEnabled$.pipe(
                map(pushAvailable => (pushAvailable ?
                    new NgswNotificationService(myPhoneService, swPush) :
                    new WebNotificationService(translate, router)))
            ),
            {
                connector: () => new ReplaySubject<NgswNotificationService|WebNotificationService>(1),
                resetOnDisconnect: false
            });

        this.notificationActions$ = this.impl$.pipe(switchMap(impl => impl.notificationActions$));

        this.impl$.connect();
    }

    public createChatNotification(message: PushChatMessage) {
        // Permission not granted
        if (!hasPermission('granted')) {
            return;
        }
        this.settingsService.generalSettings$.pipe(
            take(1),
            switchMap((settings) => {
            // For now always return to show fallback web notifications
                return this.impl$.pipe(take(1));
            // if (settings.showChatNotifications === ChatNotifications.ShowBoth ||
            //     settings.showChatNotifications === ChatNotifications.ShowWebOnly)
            //     return this.impl$.pipe(take(1));
            // else
            //     return EMPTY;
            }),
            withLatestFrom(this.silentModeService.silentMode$)
        ).subscribe({
            next: ([impl, silentMode]) => {
                impl.createChatNotification(message, silentMode);
            },
            error: () => {
            // Suppress error
            }
        }
        );
    }

    public removeChatNotification(conversationId?: number) {
        this.impl$.pipe(take(1)).subscribe({
            next: impl => impl.removeChatNotification(conversationId),
            error: (error: unknown) => {
            // Suppress error
            }
        });
    }

    public createCallNotification(myCall: MyCall, baseUrl?: string) {
        // Permission not granted
        if (!hasPermission('granted')) {
            return;
        }
        if (document.hasFocus()) {
            return;
        }

        this._callsWaitingForImage[myCall.myCallId] = myCall;

        this.impl$.pipe(
            withLatestFrom(this.silentModeService.silentMode$),
            take(1)
        ).subscribe({
            next: ([impl, silent]) => {
                // Call already deleted
                if (!this._callsWaitingForImage[myCall.myCallId]) {
                    return;
                }
                delete this._callsWaitingForImage[myCall.myCallId];
                if (baseUrl) {
                    impl.createCallNotification(myCall, getNotificationImage(myCall.contact.profilePicture, baseUrl), silent);
                }
            },
            error: () => {
            // Suppress error
                delete this._callsWaitingForImage[myCall.myCallId];
            }
        });
    }

    public removeCallNotification(myCall: MyCall) {
        delete this._callsWaitingForImage[myCall.myCallId];
        this.impl$.pipe(take(1)).subscribe({
            next: impl => impl.removeCallNotification(myCall),
            error: () => {
            // Suppress error
            }
        });
    }
}
